import React, { useMemo, useState } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Label, Input, CardBody, Col, InputGroup, InputGroupText } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

const TableCalculationCriteria = ({ filteredProducts }) => {
	const columns = [
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
		},

		{
			name: 'Период',
			selector: (row) => `${row.period}`,
			sortable: true,
		},
		{
			name: 'Условия',
			selector: (row) => `Если ${row.conditions.effect_on} для ${row.conditions.consideration_target}а за ${row.conditions.consideration_source} превышает ${row.conditions.exceeding_amount}₽ ${row.conditions.consider_discounts ? 'с учетом скидок' : 'без учета скидок'}`,
			sortable: true,
			width: '40%',
		},

		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {});
	};

	const [edit, setEdit] = useState(null);

	const [name, setName] = useState('');

	const [Id, setId] = useState('');

	const send = () => {
		if (name == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			if (!Id) {
				setId(uuid());
			}
			const newEvent = {
				// id: Id,
				// type: selectType.value,
				// name: name,
				// inn: inn,
				// upp: upp,
				// contact_person: contactPerson,
				// phone: phone,
				// email: email,
				// address: address,
				// comment: comment,
			};
			console.log(newEvent);
			// axios
			// 	.post(`/api/clientsDemandsForm.json`, newEvent)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Календарь добавлен!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 	});
		}
	};

	const [modal, setModal] = useState(false);

	const [period, setPeriod] = useState({ label: null, value: null });
	const [effectOn, setEffectOn] = useState({ label: null, value: null });
	const [considerationTarget, setConsiderationTarget] = useState({ label: null, value: null });
	const [considerationSource, setConsiderationSource] = useState({ label: null, value: null });
	const [exceedingAmount, setExceedingAmount] = useState('');
	const [considerDiscounts, setConsiderDiscounts] = useState({ label: null, value: null });

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.name);
			setId(ObjectId.id);
			setPeriod({ label: ObjectId.period, value: ObjectId.period });
			setEffectOn({ label: ObjectId.conditions.effect_on, value: ObjectId.conditions.effect_on });
			setConsiderationTarget({ label: ObjectId.conditions.consideration_target, value: ObjectId.conditions.consideration_target });
			setConsiderationSource({ label: ObjectId.conditions.consideration_source, value: ObjectId.conditions.consideration_source });
			setExceedingAmount(ObjectId.conditions.exceeding_amount);
			setConsiderDiscounts({ label: ObjectId.conditions.consider_discounts ? 'Да' : 'Нет', value: ObjectId.conditions.consider_discounts });

			setEdit(true);
		} else {
			setName('');
			setId('');
			setPeriod({ label: null, value: null });
			setEffectOn({ label: null, value: null });
			setConsiderationTarget({ label: null, value: null });
			setConsiderationSource({ label: null, value: null });
			setExceedingAmount('');
			setConsiderDiscounts({ label: null, value: null });

			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Период</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={period}
								options={[
									{ label: 'Месяц', value: 'Месяц' },
									{ label: 'День', value: 'День' },
								]}
								onChange={(option) => setPeriod(option)}
							/>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Если:</Label>
					</Col>
					<Col className="row col-sm-11 mb-2 ms-4">
						<Label className="col-sm-9 col-form-label">Что</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={effectOn}
								options={[
									{ label: 'Прибыль', value: 'Прибыль' },
									{ label: 'Оборот', value: 'Оборот' },
									{ label: 'Количество', value: 'Количество' },
								]}
								onChange={(option) => setEffectOn(option)}
							/>
						</Col>
					</Col>
					<Col className="row col-sm-11 mb-2 ms-4">
						<Label className="col-sm-9 col-form-label">Для кого</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={considerationTarget}
								options={[
									{ label: 'Филиал', value: 'Филиал' },
									{ label: 'Сотрудник', value: 'Сотрудник' },
								]}
								onChange={(option) => setConsiderationTarget(option)}
							/>
						</Col>
					</Col>
					<Col className="row col-sm-11 mb-2 ms-4">
						<Label className="col-sm-9 col-form-label">За что</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={considerationSource}
								options={[
									{ label: 'Оказание услуг', value: 'Оказание услуг' },
									{ label: 'Продажа товаров', value: 'Продажа товаров' },
									{ label: 'Оказание услуг и продажа товаров', value: 'Оказание услуг и продажа товаров' },
								]}
								onChange={(option) => setConsiderationSource(option)}
							/>
						</Col>
					</Col>
					<Col className="row col-sm-11 mb-2 ms-4">
						<Label className="col-sm-9 col-form-label">Превышает</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={exceedingAmount} onChange={(e) => setExceedingAmount(e.target.value)} />
								<InputGroupText>{'₽'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className="row col-sm-11 mb-2 ms-4">
						<Label className="col-sm-9 col-form-label">С учетом скидок</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={considerDiscounts}
								options={[
									{ label: 'Да', value: 'Да' },
									{ label: 'Нет', value: 'Нет' },
								]}
								onChange={(option) => setConsiderDiscounts(option)}
							/>
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableCalculationCriteria;
