import React, { useEffect, Fragment, useContext, useState } from 'react';
import { UL, LI, Btn } from '../../../../AbstractElements';
import { Col, Card, CardBody, Media } from 'reactstrap';
import user from '../../../../assets/images/user/1.jpg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { useKeycloak } from '@react-keycloak/web';
import { EmailsApi } from '../../../../api';
import EmailContext from '../../../../_helper/Email';
import CustomizerContext from '../../../../_helper/Customizer';
import uuid from "react-uuid";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const Email = ({ selectedEmailId, onEmailSelect }) => {
	const { allEmails, types, setType, type, getAllType, groupBy, setCompose, fetchAllEmailAsyn } = useContext(EmailContext);
	const [isOpen, setIsOpen] = useState(false);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	let currentUserName = `${keycloak.tokenParsed.name}`;
	let currentUserId = `${keycloak.tokenParsed.sub}`;

	useEffect(() => {
		if (allEmails !== null) {
			const result = groupBy(allEmails, (item) => {
				return [item.type.id];
			});
			getAllType(result);
		}
	}, [allEmails]);

	useEffect(() => {
		fetchAllEmailAsyn();
	}, [companyData]);

	const clickCompose = () => {
		setCompose(true);
	};

	const selectTypes = (types) => {
		setType(types);
	};

	const [menuItems, setMenuItems] = useState([]);
	const [accessEmails, setAccessEmails] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {

			const responseEmails = await axios.get(`${EmailsApi}getUserEmails`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { userId: currentUserId } })
			if (concatResults(responseEmails).emails.length > 0) {

				const emailsData = concatResults(responseEmails).emails.map(email => ({
					label: email.email,
					value: email.id
				}));

				setAccessEmails(emailsData);
				var defaultEmail = emailsData[0];
				setCurrentEmail(defaultEmail);
				loadFolders(defaultEmail.value);
				onEmailSelect(defaultEmail.value);
			}
		}
		catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};


	const loadFolders = async (emailId) => {
		try {
			const response = await axios.get(`${EmailsApi}getMessages`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { emailId: emailId } })
			const foldersData = concatResults(response).messages.map(folder => ({
				id: folder.id,
				icon: "icon-folder",
				label: folder.name,
				numberMessages: folder.messages.length
			}));
			setMenuItems(foldersData);
		}
		catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}

	}


	const [currentEmail, setCurrentEmail] = useState({});

	return (
		<Fragment>
			<Col xl="3" md="6 box-col-6">
				<div className="md-sidebar">
					<Btn attrBtn={{ onClick: (e) => setIsOpen(!isOpen), color: 'primary', className: 'md-sidebar-toggle' }}>email filter</Btn>
					<div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && 'open'} `}>
						<div className="email-left-aside">
							<div className="email-left-aside">
								<Card>
									<CardBody>
										<div className="email-app-sidebar">
											<Media className="align-items-center">
												<div className="media-size-email">
													<Media body className="me-3 rounded-circle img-50" src={user} alt="" />
												</div>
												<Media body>
													<Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}>
														<h6 className="f-w-600">{currentUserName}</h6>
													</Link>
													<p>{currentEmail.value}</p>
												</Media>
											</Media>
											<Select
												placeholder="Начните вводить..."
												noOptionsMessage={() => 'Нет результатов'}
												options={accessEmails}
												value={currentEmail}
												theme={(theme) => ({
													...theme,
													colors: {
														...theme.colors,
														primary25: '#7366ff80',
														primary: '#7366ff',
													},
												})}
												className="js-example-basic-single col-md-12 mt-2"
												onChange={(option) => { setCurrentEmail(option); loadFolders(option.value); onEmailSelect(option.value) }}
											/>
											<UL attrUL={{ className: 'nav main-menu flex-row', role: 'tablist' }}>
												<LI attrLI={{ className: 'nav-item border-0' }}>
													<Btn attrBtn={{ color: 'primary', className: 'w-100 btn-block btn-mail show', onClick: clickCompose }}>
														<i className="icofont icofont-envelope me-2"></i>
														Новое сообщение
													</Btn>
												</LI>
												{menuItems.map((item) => (
													<li key={item.id} className="nav-item border-0" onClick={() => selectTypes(item)}>
														<a href="#javascript" className={`${type.id === item.id ? 'active' : ''}`}>
															<span className="title">
																<i className={item.icon}></i>
																{item.label}
															</span>
															{item.numberMessages !== 0 && item.numberMessages && <span className="badge pull-right digits">({item.numberMessages})</span>}
														</a>
													</li>
												))}
											</UL>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</Col>
		</Fragment>
	);
};
export default Email;
