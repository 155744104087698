import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableFileDataTypes from './TableFileDataTypes';
import axios from 'axios';

const FileDataTypes = () => {

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Настройка справочника типов файлов данных" parent="Экспорт данных" title="Настройка справочника типов файлов данных" />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <TableFileDataTypes />
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default FileDataTypes;
