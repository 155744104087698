import React, { Fragment, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

import { TwinAuthApi } from "../../../../api";
import { Btn } from "../../../../AbstractElements";
import {useDocumentManagementContext} from "../../../../_helper/DocumentManagement/DocumentManagementProvider";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const CreateAgreement = ({ participants, setParticipants, kindDocument, setKindDocument, setOwner, owner, typeDocument, setTypeDocument }) => {
	const [allUsersNames, setAllUsersNames] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [typesDocuments, setTypesDocuments] = useState([]);
	const [viewsDocuments, setViewsDocuments] = useState([]);

	const { documentTypes, documentView, getDocumentTypes, getDocumentViews } = useDocumentManagementContext();

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
				}));
				setAllUsersNames(selectOptions);
				const fetchData = async () => {
					const result = await getUserName(keycloak.tokenParsed.sub);
					setOwner({ label: result, value: keycloak.tokenParsed.sub });
				};
				fetchData();
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		getDocumentTypes();
		getDocumentViews();
	}, []);

	async function getUserName(userId) {
		try {
			const response = await axios.get(`${TwinAuthApi}get-user?id=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const userData = JSON.parse(response.data);
			return userData.firstName + userData.lastName;
		} catch (error) {
			console.error("Ошибка получения данных:", error);
			return null;
		}
	}
	const colourStyles = {
		control: (styles) => ({ ...styles, border: "none", boxShadow: "none", fontSize: "14px", padding: "0.22rem" }),
	};

	return (
		<Fragment>
			<Row>
				<Col sm="8">
					<FormGroup>
						<Label>Согласующие</Label>
						<Select
							required
							placeholder="Начните вводить имя пользователя"
							isMulti
							noOptionsMessage={() => "Нет результатов"}
							options={allUsersNames}
							value={participants}
							onChange={(option) => setParticipants(option)}
							className="form-control p-0 col-sm-12"
							styles={colourStyles}
						/>
					</FormGroup>
				</Col>
				<Col sm="4">
					<FormGroup>
						<Label>Владелец</Label>
						<Select
							placeholder="Начните вводить..."
							noOptionsMessage={() => "Нет результатов"}
							options={allUsersNames}
							value={owner}
							className="form-control p-0 col-sm-12"
							styles={colourStyles}
							onChange={(option) => setOwner(option)}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row style={{ alignItems: "end" }} className="mb-4">
				<Col sm="4" xl="5">
					<FormGroup>
						<Label>Типы документов</Label>
						<Select
							required
							placeholder="Начните вводить имя пользователя"
							isMulti
							noOptionsMessage={() => "Нет результатов"}
							options={documentTypes}
							value={typeDocument}
							onChange={(option) => setTypeDocument(option)}
							className="form-control p-0 col-sm-12"
							styles={colourStyles}
						/>
					</FormGroup>
				</Col>
				<Col sm="4" xl="5">
					<FormGroup>
						<Label>Виды документов</Label>
						<Select
							placeholder="Начните вводить..."
							noOptionsMessage={() => "Нет результатов"}
							options={documentView}
							value={kindDocument}
							className="form-control p-0 col-sm-12"
							styles={colourStyles}
							onChange={(option) => setKindDocument(option)}
						/>
					</FormGroup>
				</Col>
				<Col sm="4" xl="2" className="row">
					<Btn attrBtn={{ color: "primary", outline: true, className: "ms-2 mb-3 pt-2 pb-2" }}>Шаблон задач</Btn>
				</Col>
			</Row>
			{/* <Row>
				<Col sm="6">
					<FormGroup>
						<Label>Шаблон статусов</Label>
						<Input disabled value={pattern} onChange={(e) => setPattern(e.target.value)} type="select" name="issues" placeholder="Select Issues" className="form-control digits" required>
							<option value={0}>По умолчанию</option>
						</Input>
					</FormGroup>
				</Col>
			</Row> */}
		</Fragment>
	);
};

export default CreateAgreement;
