import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Select from 'react-select';
import SweetAlert from 'sweetalert2';
import { CardBody, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import FilterComponent from '../FilterComponent';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Btn } from '../../../../../AbstractElements';
import { Plus } from 'react-feather';
import { TwinAuthApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults } from "../../../../../concatResults";

const CompanyUsers = ({ companyId, groupData }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [modal, setModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [data, setData] = useState();
	const [username, setUsername] = useState(null);
	const [editUsername, setEditUsername] = useState();
	const [userGroups, setUserGroups] = useState([]);
	const [selectedGroup, setSelectedGroup] = useState();
	const [groupList, setGroupList] = useState();
	const [editUserId, setEditUserId] = useState();

	useEffect(() => {
		if (companyId) {
			axios
				.get(`${TwinAuthApi}get-group-users/${companyId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					setData(JSON.parse(response.data));
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
	}, [companyId])

	useEffect(() => {
		groupData && groupData.subGroups !== undefined && setGroupList(groupData.subGroups.map((group) => ({
			label: group.name,
			value: group.name,
		})));
	}, [groupData]);

	const toggle = () => {
		setModal(!modal);
	};

	const toggleEdit = (usernameEdit, userNameId) => {
		setEditModal(!editModal);
		setEditUsername(usernameEdit);
		setEditUserId(userNameId);
	};

	useEffect(() => {
		if (editModal) {
			axios
				.get(`${TwinAuthApi}get-user-subgroups-ingroup?groupName=${groupData.name}&userId=${editUserId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					setUserGroups(JSON.parse(response.data).map((group) => ({
						label: group.name,
						value: group.name,
					})));
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
	}, [editModal]);

	const columns = [
		{
			name: 'Фамилия Имя',
			selector: (row) => `${row.lastName} ${row.firstName}`,
			sortable: true,
			hide: 'sm',
			width: '50%',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => toggleEdit(row.name, row.id)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteRow(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
			width: '5%',
		},
	];

	const deleteRow = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then(function (isConfirm) {
			if (isConfirm) {
				axios
					.delete(`${TwinAuthApi}remove-user-from-group?groupName=${groupData.name}&userId=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
					.then((response) => {
					})
					.catch((error) => {
						console.error('Ошибка получения данных:', error);
					});
			}
		});
	};

	const promiseOptions = (inputValue, callback) => {
		console.log(inputValue);
		axios
			.get(`${TwinAuthApi}search-users?search=${inputValue}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				const selectOptions = JSON.parse(concatResults(response)).map((option) => ({
					label: option.firstName + ' ' + option.lastName,
					value: option.username,
				}));
				callback(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	};

	function FullNameForm() {
		return (
			<Col className="row col-sm-12 mb-3">
				<Label className="col-form-label">ФИО или логин</Label>
				<Row sm="12">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<AsyncSelect
							className="me-2 col-xl-10 col-md-8"
							onChange={(option) => setUsername(option)}
							value={username}
							placeholder="Введите ФИО или логин"
							loadOptions={promiseOptions}
							noOptionsMessage={() => "Нет подходящих под запрос пользователей"} />
						<Btn attrBtn={{ color: 'white', className: 'btn btn-primary', outline: true, onClick: () => addUserToCompanyByUsername(username.value) }}>
							<Plus size={'18px'} style={{ margin: '0px 0px -5px 0px' }} /> Добавить
						</Btn>
					</div>
				</Row>
			</Col>
		);
	}

	const EditUserForm = () => {
		return (
			<Col className="row col-sm-12 mb-3">
				<Label className="col-form-label">Название роли</Label>
				<Row sm="12">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Col sm="11">
							<Select type="tel" placeholder="Выберите группу" value={selectedGroup} options={groupList.filter((group) => !userGroups.map((list) => list.value).includes(group.value))} onChange={(option) => setSelectedGroup(option)} />
						</Col>
						<Btn attrBtn={{ color: 'white', className: 'btn btn-primary', outline: true, onClick: () => addUserToSubgroup(selectedGroup) }}>
							<Plus size={'18px'} style={{ margin: '0px 0px -5px 0px' }} /> Добавить
						</Btn>
					</div>
				</Row>
				<DataTable columns={tableColumns} data={userGroups} noDataComponent={'В данной таблице ещё нет записей.'} defaultSortField="name" striped />
			</Col>
		);
	}

	const removeUserFromSubgroup = (subgroupNameDelete) => {
		axios
			.delete(`${TwinAuthApi}remove-user-from-subgroup?subgroupName=${groupData.name}&groupName=${subgroupNameDelete}&userId=${editUserId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then(() => {
				toast.success('Пользователь добавлен.', {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				toast.error('Ошибка при добавлении пользователя.', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error('Ошибка получения данных:', error);
			});
	}

	const addUserToCompanyByUsername = (username) => {
		axios
			.get(`${TwinAuthApi}get-user?login=${username}`)
			.then((response) => {
				const user = JSON.parse(response.data);
				axios
					.post(`${TwinAuthApi}add-user-to-group?groupName=${groupData.name}&userId=${user.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
					.then(() => {
						toast.success('Пользователь добавлен.', {
							position: toast.POSITION.TOP_RIGHT,
						});
					})
					.catch((error) => {
						toast.error('Ошибка при добавлении пользователя.', {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error('Ошибка получения данных:', error);
					});
			})
			.catch((error) => {
				toast.error('Ошибка при добавлении пользователя.', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error('Ошибка получения данных:', error);
			});
	};

	const addUserToSubgroup = () => {
		axios
			.post(`${TwinAuthApi}add-user-to-subgroup?groupName=${groupData.name}&subgroupName=${selectedGroup.value}&userId=${editUserId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then(() => {
				toast.success('Группа добавлена.', {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				toast.error('Ошибка при добавлении группы.', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error('Ошибка получения данных:', error);
			});
	};

	let numberedData = data;

	if (Array.isArray(data)) {
		numberedData = data.map((row, index) => {
			return {
				...row,
				rowNumber: index + 1,
			};
		});
	}

	const tableColumns = [
		{
			name: 'Название',
			selector: (row) => `${row.value}`,
			sortable: true,
			center: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => removeUserFromSubgroup(row.value)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	let filteredItems = numberedData;
	if (Array.isArray(numberedData)) {
		filteredItems = numberedData.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			{filteredItems && <DataTable columns={columns} data={filteredItems} noDataComponent={'В данной таблице ещё нет записей.'} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />}
			<CommonModal isOpen={modal} title={'Добавить пользователя'} toggler={toggle} togglerClose={toggle} saveText="Готово" size="xl">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<FullNameForm />
				</CardBody>
			</CommonModal>
			<CommonModal isOpen={editModal} title={'Редактирование пользователя'} toggler={toggleEdit} togglerClose={toggleEdit} saveText="Готово" size="xl">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<EditUserForm />
				</CardBody>
			</CommonModal>
		</>
	);
};

export default CompanyUsers;
