import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import { Btn, H1, H2, H3, H4, H5, P } from "../../../AbstractElements";
import Bg1 from "../../../assets/images/educational-platform/typeawaynumerodos.png";
import Icon1 from "../../../assets/images/educational-platform/graduation-hat.png";
import Icon2 from "../../../assets/images/educational-platform/benchmark.png";
import Icon3 from "../../../assets/images/educational-platform/double-click.png";

import Icon4 from "../../../assets/images/educational-platform/calendar_1.png";
import Img1 from "../../../assets/images/educational-platform/4froooooont.png";

import Icon5 from "../../../assets/images/educational-platform/number-1.png";
import Icon6 from "../../../assets/images/educational-platform/number-2.png";
import Icon7 from "../../../assets/images/educational-platform/number-3.png";

import Icon8 from "../../../assets/images/educational-platform/control.png";
import Img4 from "../../../assets/images/educational-platform/right.png";
import Img3 from "../../../assets/images/educational-platform/front.png";

import Form from "../Form";
import { ChevronDown } from "react-feather";
import NavMenu from "../NavMenu";

const EducationalPlatform = () => {
	return (
		<Fragment>
			<Container fluid={true} className="g-0">
				<NavMenu />
				<Row style={{ backgroundImage: `url(${Bg1})`, height: "945px", color: "#ffffff" }} className="t-cover__carrier g-0">
					<Row className="t-cover__filter g-0" style={{ backgroundImage: ` -webkit-linear-gradient(top, rgba(115, 102, 255, 0.7), rgba(22, 199, 249, 0.7))`, height: "945px" }}>
						<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "30vh 30vh 2vh", justifyContent: "space-between" }}>
							<H1 attrH1={{ className: "f-w-600 mb-4 f-60" }}>Образовательная платформа</H1>
							<P attrPara={{ className: "f-22" }}>Полный набор инновационных сервисов, которые помогут вам создать идеальное образовательное пространство.</P>
							<div style={{ flex: "1", alignItems: "center", display: "flex" }}>
								<button style={{ backgroundColor: "#ffffff", border: "solid 1px #ffffff", color: "#7366ff", padding: "1rem 4rem", fontSize: "20px", borderRadius: "15px" }}>
									Начать сейчас
								</button>
							</div>
							<ChevronDown size={"72px"} />
						</Col>
					</Row>
				</Row>
				<Row style={{ backgroundColor: "#f3f3f3" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16vh 20vh 10vh" }}>
						<H1 attrH1={{ className: "f-w-600 mb-3" }}>Что включает в себя наше решение?</H1>
						<div style={{ display: "flex", margin: "4rem auto", height: "32vh" }}>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon1} alt="icon1" style={{ width: "120px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Персонализированное обучение</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Создавайте уникальные курсы и задания, подстраиваясь под потребности каждого ученика. Максимизируйте успех всех участников благодаря индивидуальному подходу.
								</p>
							</Col>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon2} alt="icon1" style={{ width: "120px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Аналитика и отчётность</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Отслеживайте успехи и тренды обучения с помощью обширной аналитики. Принимайте информированные решения для улучшения образовательного процесса.
								</p>
							</Col>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon3} alt="icon1" style={{ width: "120px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Гибкость и удобство использования</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Интуитивный интерфейс и гибкие настройки делают обучение удобным и приятным. Легко создавайте и администрируйте образовательные материалы без лишних сложностей.
								</p>
							</Col>
						</div>
					</Col>
				</Row>
				<Row style={{ height: "50vh" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16vh 40vh 4vh", justifyContent: "space-between" }}>
						<img src={Icon4} alt="Icon4" style={{ width: "100px", borderRadius: "50%" }} />
						<H2 attrH2={{ className: "f-w-600 mt-4" }}>Эффективное планирование с календарём</H2>
						<p className="f-18 text-center">
							Наша образовательная платформа предлагает интегрированный календарь, который обеспечивает эффективное планирование и организацию учебного процесса.{" "}
						</p>
					</Col>
				</Row>
				<Row>
					<Col sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
						<img src={Img1} alt="Image1" />
					</Col>
				</Row>
				<Row style={{ backgroundColor: "#f3f3f3" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16vh 20vh 10vh" }}>
						<H1 attrH1={{ className: "f-w-600 mb-3" }}>Начать обучаться просто!</H1>
						<div style={{ display: "flex", margin: "4rem auto", height: "32vh" }}>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon5} alt="icon1" style={{ width: "100px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Просмотр материалов курса</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Ученик изучает доступные ресурсы, включая видеоуроки, тексты и интерактивные задания, в удобное для себя время.{" "}
								</p>
							</Col>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon6} alt="icon2" style={{ width: "100px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Прохождение тестирования по курсу</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									После изучения материалов ученик проходит разнообразные тесты для проверки понимания и применения знаний.{" "}
								</p>
							</Col>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon7} alt="icon3" style={{ width: "100px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Получение диплома о прохождении курса</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Успешное завершение тестирования награждается дипломом, подтверждающим усвоение материала и успешное обучение.{" "}
								</p>
							</Col>
						</div>
					</Col>
				</Row>
				<Row style={{ height: "50vh" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16vh 30vh 4vh", justifyContent: "space-between" }}>
						<img src={Icon8} alt="Icon" style={{ width: "100px", borderRadius: "50%" }} />
						<H2 attrH2={{ className: "f-w-600 mt-4" }}>Контроль</H2>
						<p className="f-18 text-center">
							Наш сервис позволяет моментально отслеживать успехи учеников и их прогресс в обучении, предоставляя персонализированную статистику и аналитику для лучшего понимания каждого
							студента. Это обеспечивает адаптивность и эффективность в образовательном процессе.{" "}
						</p>
					</Col>
				</Row>
				<Row>
					<Col sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
						<img src={Img3} alt="Image4" />
						{/* заменить картинку */}
					</Col>
				</Row>
				<Row style={{ backgroundColor: "#f3f3f3", height: "90vh", paddingLeft: "20vh", paddingRight: "20vh" }}>
					<Col md="5" sm="12" style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingRight: "2rem" }}>
						<H3 attrH3={{ className: "f-w-600 mb-4" }}>Удобно в любом месте, с любого устройства</H3>
						<p className="f-18">
							Используйте любое удобное устройство. Ноутбук, планшет или телефон — наша платформа работает одинаково хорошо на любом экране. В любой момент вы в курсе происходящего!{" "}
						</p>
					</Col>
					<Col md="7" sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
						<img src={Img4} alt="Image5" style={{ maxHeight: "800px" }} />
						{/* заменить картинку */}
					</Col>
				</Row>
				<Form color={"#ffffff"} />
			</Container>
		</Fragment>
	);
};
export default EducationalPlatform;
