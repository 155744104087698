import React, { useMemo, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import FilterComponent from '../../../Goods/PurchaseHistory/FilterComponent';

import { H6, P } from '../../../../../AbstractElements';

import { AnalyticsApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const TableFullness = () => {
	const [data, setData] = useState([]);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${AnalyticsApi}getFullnessReports`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				setData(concatResults(response).fulnessReports);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	function countUniqueDays(visitHistory) {
		const uniqueDays = {};
		if (!visitHistory) {
			return 0;
		}
		visitHistory.forEach((entry) => {
			const date = new Date(entry.date_time);
			const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
			uniqueDays[formattedDate] = true;
		});
		console.log(visitHistory);

		return Object.keys(uniqueDays).length;
	}

	function calculateTotalDuration(visitHistory) {
		let totalDuration = 0;
		if (!visitHistory) {
			return 0;
		}
		visitHistory.forEach((entry) => {
			totalDuration += entry.duration;
		});

		return totalDuration;
	}

	function calculateTotalHours(schedule) {
		if (!schedule) {
			return 0;
		}
		let totalHours = 0;

		schedule.forEach((entry) => {
			const startTime = new Date(`1970-01-01T${entry.start_time}`);
			const endTime = new Date(`1970-01-01T${entry.end_time}`);
			const timeDifference = (endTime - startTime) / (1000 * 60 * 60); // переводим разницу в часы
			totalHours += timeDifference;
		});

		return totalHours;
	}

	function fullness(schedule, visitHistory) {
		if (!schedule || !visitHistory) {
			return 0;
		}

		return ((calculateTotalDuration(visitHistory) / calculateTotalHours(schedule)) * 100).toFixed(1);
	}

	const columns = [
		{
			name: 'Сотрудник',
			selector: (row) => (
				<>
					<H6>
						{row.employee}
					</H6>
				</>
			),
			sortable: true,
		},
		{
			name: 'Отработано дней',
			selector: (row) => `${row.uniqueDays}`,
			sortable: true,
		},
		{
			name: 'Рабочих часов',
			selector: (row) => `${row.totalHours}`,
			sortable: true,
		},
		{
			name: 'Отработано часов',
			selector: (row) => `${row.totalDuration}`,
			sortable: true,
		},
		{
			name: 'Часов простоя',
			selector: (row) => `${row.downtimeHours}`,
			sortable: true,
		},
		{
			name: 'Заполненность',
			selector: (row) => `${row.fullnessPercent} %`,
			sortable: true,
		},
		{
			name: 'Будущие записи',
			selector: (row) => `${row.futureEntries}`,
			sortable: true,
		},
	];

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return <>{data && <DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />}</>;
};

export default TableFullness;
