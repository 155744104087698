import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, { addEdge, useNodesState, useEdgesState, Controls, Background, Panel, useReactFlow } from "reactflow";
import "reactflow/dist/style.css";
import axios from "axios";
import { Btn } from "../../../AbstractElements";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import CommonModal from "../../UiKits/Modals/common/modal";
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import { Save } from "react-feather";

import Sidebar from "./Sidebar";

import "./index.css";
import CustomNode from "./CustomNode";
import CustomNodeMan from "./CustomNodeMan";

// const flowKey = 'example-flow';

const initialNodes = [
	{
		id: "1",
		type: "input",
		data: { label: "Начало" },
		position: { x: 250, y: 5 },
	},
];

let id = 0;
const getId = () => `dndnode_${id++}`;

const nodeTypes = {
	custom: CustomNode,
	man: CustomNodeMan,
};

const DnDFlow = () => {
	const [data, setData] = useState([]);
	const reactFlowWrapper = useRef(null);
	const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [rfInstance, setRfInstance] = useState(null);
	const { setViewport } = useReactFlow();
	const [Name, setName] = useState("");
	const [modal, setModal] = useState(false);
	// const [dataNode, setDataNode] = useState('');
	const [id, setId] = useState("");

	const location = useLocation();
	useEffect(() => {}, []);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		console.log(queryParams);
		setId(queryParams.get("BPid"));

		// Получаем данные из 'http://localhost:8000/BpDiagram'

		axios
			.get("/api/BpDiagram.json")
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error("Произошла ошибка:", error);
			});

		console.log(id, queryParams.get("BPid"));

		if (queryParams.get("BPid")) {
			axios
				.get(`http://localhost:8000/BpDiagram/${queryParams.get("BPid")}`)
				.then((response) => {
					// setDataNode(response.data);
					console.log(response.data);
					setName(response.data.name);
					// Восстанавливаем состояния после успешной загрузки данных
					const flow = JSON.parse(response.data.nodeList);

					if (flow) {
						const { x = 0, y = 0, zoom = 1 } = flow.viewport;
						setNodes(flow.nodes || []);
						setEdges(flow.edges || []);
						setViewport({ x, y, zoom });
					}
				})
				.catch((error) => {
					console.error("Произошла ошибка:", error);
				});
		}
	}, []);

	const onConnect = useCallback((params) => {
		console.log(params);
		const newEdge = {
			id: `${params.source}-${params.target}`,
			source: params.source,
			target: params.target,
			type: "step", // Добавляем поле type со значением 'step'
		};
		setEdges((eds) => [...eds, newEdge]);
	}, []);

	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
	}, []);

	const toggle = () => setModal(!modal);

	const onSave = useCallback(() => {
		if (rfInstance) {
			const flow = rfInstance.toObject();
			localStorage.setItem(id, JSON.stringify(flow));
			console.log(Name);
			if (!Name) {
				//modal
				setModal(true);
			} else {
				const newRow = {
					id: data.length + 1,
					name: Name,
					nodeList: JSON.stringify(flow),
				};
				console.log(newRow);
				setData([...data, newRow]);
				return axios
					.post(`http://localhost:8000/BpDiagram`, newRow)
					.then((response) => {
						console.log(response.data);
						toast.success("Изменения сохранены!", {
							position: toast.POSITION.TOP_RIGHT,
						});
						setModal(false);
					})
					.catch((error) => {
						console.error(error);
					});
			}
		}
	}, [rfInstance, Name]);

	const onRestore = useCallback(() => {
		const restoreFlow = async () => {
			const flow = JSON.parse(localStorage.getItem(Name));
			console.log(flow);
			if (flow) {
				const { x = 0, y = 0, zoom = 1 } = flow.viewport;
				setNodes(flow.nodes || []);
				setEdges(flow.edges || []);
				setViewport({ x, y, zoom });
			}
		};

		restoreFlow();
	}, [setNodes, setViewport]);

	const onDrop = useCallback(
		(event) => {
			event.preventDefault();

			const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
			const type = event.dataTransfer.getData("application/reactflow");
			const label = event.dataTransfer.getData("text/plain");
			console.log(type);

			// check if the dropped element is valid
			if (typeof type === "undefined" || !type) {
				return;
			}

			const position = reactFlowInstance.project({
				x: event.clientX - reactFlowBounds.left,
				y: event.clientY - reactFlowBounds.top,
			});
			const newNode = {
				id: getId(),
				type,
				position,
				// data: { label: `${type} node` },
				data: { label: label },
			};

			setNodes((nds) => nds.concat(newNode));
		},
		[reactFlowInstance]
	);

	const handleReactFlowInit = (reactFlowInstance) => {
		setReactFlowInstance(reactFlowInstance);
		setRfInstance(reactFlowInstance);
	};

	return (
		<div className="dndflow">
			<CommonModal isOpen={modal} title="Добавить новую диаграмму Бизнес-процесса" toggler={onSave} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
				<CardBody style={{ padding: "10px", paddingLeft: "15px" }}>
					<Form className="form theme-form">
						<Row>
							<FormGroup className="row col-sm-12" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label">Введите название</Label>
								<Col sm="11">
									<Input className="form-control" type="tel" placeholder="Название диаграмы" value={Name} onChange={(e) => setName(e.target.value)} />
								</Col>
							</FormGroup>
						</Row>
					</Form>
				</CardBody>
			</CommonModal>
			<Sidebar />
			<div className="reactflow-wrapper" ref={reactFlowWrapper}>
				<ReactFlow
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChange}
					onEdgesChange={onEdgesChange}
					onConnect={onConnect}
					onDrop={onDrop}
					onDragOver={onDragOver}
					nodeTypes={nodeTypes}
					onInit={handleReactFlowInit}
					fitView
				>
					<Panel position="top-right" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
						<Btn attrBtn={{ color: "info", onClick: onSave }}>
							<Save size={"16px"} style={{ marginRight: "5px" }} />
							Сохранить
						</Btn>
						<button onClick={onRestore} style={{ background: "none", border: "none", color: "#7366ff", fontSize: "16px", marginTop: "5px" }}>
							<i className="fa fa-undo" style={{ marginRight: "5px" }}></i>Вернуться к последнему сохранению
						</button>
					</Panel>
					<Background />
					<Controls position="bottom-right" />
				</ReactFlow>
			</div>
		</div>
	);
};

export default DnDFlow;
