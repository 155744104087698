import React, { Fragment, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from "fabric";
import { PenTool } from "react-feather";
import { TiTick } from "react-icons/ti";
import { Btn } from "../../../../../AbstractElements";
import { BsFileImage } from "react-icons/bs";
import { Item, Menu, Separator, Submenu, useContextMenu } from "react-contexify";
import 'react-contexify/ReactContexify.css';
import { CloseSharp, House, Redo, Save, Undo } from "@mui/icons-material";
import { CardBody, Col, Form, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import CommonModal from "../../../../UiKits/Modals/common/modal";
import { useInteractivePlanMapsContext } from "../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import { useKeycloak } from "@react-keycloak/web";
import managePlanMapObjects from "../ManagePlanMapObjects";
import { useLocation } from "react-router-dom";
import { Render } from "@measured/puck";
import { PuckComponents } from "../PlanMapObjectCharacteristics/Components/PuckComponents";
import { PuffLoader } from "react-spinners";

const BlankViewer = () => {
    const {
        planMap,
        getInteractivePlanMap,
        planMapsObjects,
        getInteractivePlanMapsObjects,
        getInteractivePlanMapsObjectsType,
        planMapsObjectsType,
        getInteractivePlanMapsObjectsCharacteristicsValues,
        planMapsObjectsCharacteristicsValues,
        getInteractivePlanMapsObjectsTypesCharacteristics,
        planMapsObjectsTypesCharacteristics,
        planMapWidget,
        setPlanMapWidget,
        planMapWidgets,
        getInteractivePlanMapsWidgets
    } = useInteractivePlanMapsContext();
    const [planMapCharacteristicObject, setPlanMapCharacteristicObject] = useState({});
    const [planMapObjCharResult, setPlanMapObjCharResult] = useState(null);
    const [planMapObjectIdRedirect, setPlanMapObjectIdRedirect] = useState('');
    const [resultIsLoading, setResultIsLoading] = useState(false);
    const { editor, onReady } = useFabricJSEditor();
    const editorMemo = useMemo(() => editor?.canvas, [editor?.canvas]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mapId = queryParams.get('mapId');
    const widgetId = queryParams.get('widgetId');

    let isDragging;
    let lastPosX;
    let lastPosY;

    const [selectedObjectId, setSelectedObjectId] = useState(null);

    const MENU_ID = 'EditorMenu';

    const [objectSelected, setObjectSelected] = useState(false);

    const [modal, setModal] = useState(false);

    //const editorRef = useRef();

    useEffect(() => {
    }, [planMapObjCharResult])

    useEffect(() => {
        mapId && getInteractivePlanMap(mapId);
        mapId && getInteractivePlanMapsObjects(mapId);
        mapId && getInteractivePlanMapsWidgets();
    }, []);

    useEffect(() => {
        const foundWidget = planMapWidgets.find(widget => widget.id === widgetId);
        if (foundWidget) {
            if (foundWidget) {
                setPlanMapWidget(foundWidget);
            }
            else {
                console.log(`Widget с ID ${widgetId} не найден`);
            }
        }
    }, [planMapWidgets]);

    useEffect(() => {
        if (selectedObjectId !== null) {
            let obj = planMapsObjects.find((item) => item.id === selectedObjectId);
            obj && getInteractivePlanMapsObjectsType(obj.objectTypeId);
            obj && getInteractivePlanMapsObjectsCharacteristicsValues(selectedObjectId);
            obj && getInteractivePlanMapsObjectsTypesCharacteristics(obj.objectTypeId);
            editorMemo.discardActiveObject();
        } else {
            setResultIsLoading(false);
        }
    }, [selectedObjectId]);

    useEffect(() => {
        planMapsObjectsType && planMapsObjectsType?.cardJson && setPlanMapCharacteristicObject(JSON.parse(planMapsObjectsType.cardJson));
    }, [planMapsObjectsType])

    useEffect(() => {
        if
            (
            Array.isArray(planMapsObjectsCharacteristicsValues) &&
            planMapsObjectsTypesCharacteristics &&
            planMapCharacteristicObject
        ) {
            planMapsObjectsCharacteristicsValues.forEach((item) => {
                let char = planMapsObjectsTypesCharacteristics.find((i) => i.id === item.objectTypeCharacteristicId)
                if (char) {
                    let obj = getObject(planMapCharacteristicObject.content, char.id);
                    let objZones = getObject(planMapCharacteristicObject.zones, char.id);
                    if (obj) {
                        changeValueInObject(obj, char.id, item.characteristicValue);
                    } else if (objZones) {
                        changeValueInObject(objZones, char.id, item.characteristicValue);
                    }
                }
            });
            setPlanMapObjCharResult(planMapCharacteristicObject);
            setResultIsLoading(false);
        }
    }, [planMapsObjectsTypesCharacteristics, planMapsObjectsCharacteristicsValues, planMapCharacteristicObject])

    const getObject = (theObject, id) => {
        let result = null;

        if (Array.isArray(theObject)) {
            theObject?.forEach((content) => {
                if (content.props?.ImapsData?.Characteristic.id === id) {
                    result = content;
                }
            })
        } else {
            if (theObject && typeof (theObject) === 'object') {
                for (const [key, value] of Object.entries(theObject)) {
                    value.forEach((content) => {
                        if (content.props?.ImapsData?.Characteristic.id === id) {
                            result = content;
                        }
                    })
                }
            }
        }

        return result;
    }

    const changeValueInObject = (object, id, value) => {
        if (object) {
            planMapCharacteristicObject.content.forEach((item) => {
                if (item.props?.ImapsData?.Characteristic.id === id) {
                    if (item.type === "Button") {
                        item.props.buttons.forEach((button) => {
                            if (button.href !== "#") {
                                button.href = value
                            }
                        })
                    } else if (item.type === "ImageGallery") {
                        item.props.images.forEach((image) => {
                            if (image.image === "" || image.image === "https://sun9-34.userapi.com/impg/5F_nDQ7rkkVx4iF6UPSs6CSPouGI50c6blntwQ/TKqMIZmwthM.jpg?size=631x474&quality=95&sign=60e3c9f13466a7d572e9a0f4a3228f6c&c_uniq_tag=OsJH98B5qOCXS7SmODKjX90GBC19Hmic1_IEdOG_daU&type=album") {
                                item.props.images.splice(item.props.images.indexOf(image), 1);
                            }
                        });
                        if (!item.props.images.find((image) => image.image === value)) {
                            item.props.images.push({
                                label: "",
                                header: "",
                                image: value
                            });
                        }
                    } else {
                        item.props.children = value;
                    }
                }
            })
            for (const [key, item] of Object.entries(planMapCharacteristicObject?.zones)) {
                item.forEach((zone) => {
                    if (zone.props?.ImapsData?.Characteristic.id === id) {
                        if (zone.type === "Button") {
                            zone.props.buttons.forEach((button) => {
                                if (button.href !== "#") {
                                    button.href = value
                                }
                            })
                        } else if (zone.type === "ImageGallery") {
                            zone.props.images.push({
                                label: "",
                                header: "",
                                image: value
                            });
                        } else {
                            zone.props.children = value;
                        }
                    }
                })
            }
        }
    }

    useEffect(() => {
        if (planMap) {
            let height = 1;

            let map = JSON.parse(planMap.mapJson)?.objects.forEach((item) => {
                if (item.type === "image") {
                    height += item.height * item.scaleY;
                }
            })

            editorMemo?.loadFromJSON(JSON.parse(planMap.mapJson));

            editor?.canvas?.setDimensions({ height: height });
            console.log(JSON.parse(planMap.mapJson));
        }
    }, [planMap]);

    const { show } = useContextMenu({
        id: MENU_ID,
    });

    const removeEvent = (eventName) => {
        let listener = editorMemo.__eventListeners[eventName];
        listener.forEach((event) => {
            editorMemo.off(eventName, event);
        });
    };

    const keepPositionInBounds = () => {
        if (editor) {
            let zoom = editorMemo.getZoom();
            let xMin = ((2 - zoom) * editorMemo.getWidth()) / 2;
            let xMax = (zoom * editorMemo.getWidth()) / 2;
            let yMin = ((2 - zoom) * editorMemo.getHeight()) / 2;
            let yMax = (zoom * editorMemo.getHeight()) / 2;

            let point = new fabric.Point(
                editorMemo.getWidth() / 2,
                editorMemo.getHeight() / 2
            );
            let center = fabric.util.transformPoint(
                point,
                editorMemo.viewportTransform
            );

            let clampedCenterX = clamp(center.x, xMin, xMax);
            let clampedCenterY = clamp(center.y, yMin, yMax);

            let diffX = clampedCenterX - center.x;
            let diffY = clampedCenterY - center.y;

            if (diffX !== 0 || diffY !== 0) {
                editorMemo.relativePan(new fabric.Point(diffX, diffY));
            }
        }
    };

    const updateZoom = useCallback((opt) => {
        let delta = opt.e.deltaY;
        let zoom = editorMemo.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 5) zoom = 5;
        if (zoom < 1) zoom = 1;
        editorMemo.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
        keepPositionInBounds();
    }, [editor]);

    function clamp(value, min, max) {
        return Math.max(min, Math.min(value, max));
    }

    useEffect(() => {
        if (editorMemo) {
            editorMemo.backgroundColor = "white";
            fabric.Group.prototype.hasControls = false;
            fabric.Object.prototype.hasControls = false;
            fabric.Object.prototype.lockMovementX = true;
            fabric.Object.prototype.lockMovementY = true;
            editor.canvas.selection = false;
            editor.canvas.selectionColor = "#7366ff"
        }

        if (editor?.canvas) {

            editor?.canvas?.on({
                "mouse:down": onMouseDownPan,
                "mouse:wheel": updateZoom,
                "mouse:up": onMouseUpPan,
                "mouse:move": onMouseMovePan,
                "mouse:over": onMouseHover,
                "selection:created": objectHasSelected,
                "selection:updated": objectHasSelected,
                "selection:cleared": objectHasCleared,
            });
        }
    }, [editorMemo])

    function objectHasSelected(obj) {
        removeEvent("mouse:down");

        let object = editorMemo.getActiveObject();
        if (object?.type === "image") {
            editorMemo.discardActiveObject();
            modal === true && setModal(false);
            return
        }

        setObjectSelected(true);
        setPlanMapCharacteristicObject({});
        !modal && setModal(!modal);
        setResultIsLoading(true);
        console.log(obj.selected[0]?.id);
        setPlanMapObjectIdRedirect(obj.selected[0]?.id ? obj.selected[0]?.id : null);
        setSelectedObjectId(obj.selected[0]?.id ? obj.selected[0]?.id : null);
        setPlanMapObjCharResult(null);
    }

    function objectHasCleared(obj) {
        editor?.canvas?.on({
            "mouse:down": onMouseDownPan
        });
        setObjectSelected(false);
        setSelectedObjectId(obj.target?.id);
    }

    const onMouseHover = useCallback((obj) => {
        //let object = editorMemo.getActiveObject();
        if (obj?.target?.type === "polygon") {
            obj.target.set('hoverCursor', 'pointer');
            //editorMemo.discardActiveObject();
            //return
        }
    }, [editor])

    const config = {
        categories: {
            layout: {
                title: "Разметка",
                components: ["Columns", "Flex", "VerticalSpace"]
            },
            elements: {
                title: "Элементы",
                components: ["Heading", "RegularText", "BoldText", "Button", "ImageGallery"]
            }
        },
        components: PuckComponents,
    };

    function getClientPosition(e) {
        const positionSource = e.touches ? e.touches[0] : e;
        const {
            clientX,
            clientY
        } = positionSource;
        return {
            clientX,
            clientY
        };
    }

    const onMouseDownPan = useCallback((opt) => {
        const {
            e
        } = opt;
        isDragging = true;
        const {
            clientX,
            clientY
        } = getClientPosition(e);
        lastPosX = clientX;
        lastPosY = clientY;
        editor.canvas.discardActiveObject();
    }, [editor]);

    const onMouseMovePan = useCallback((opt) => {
        if (!isDragging) {
            return;
        }
        const {
            e
        } = opt;
        const T = editor.canvas.viewportTransform;
        const {
            clientX,
            clientY
        } = getClientPosition(e);
        T[4] += clientX - lastPosX;
        T[5] += clientY - lastPosY;
        editor.canvas.requestRenderAll();
        lastPosX = clientX;
        lastPosY = clientY;
        keepPositionInBounds();
    }, [editor]);

    const onMouseUpPan = useCallback((opt) => {
        editor.canvas.setViewportTransform(editor.canvas.viewportTransform);
        isDragging = false;
    }, [editor]);

    const toggle = () => {
        //setSelectedObjectId(null);

        setModal(!modal);
    };

    document.addEventListener('click', function (event) {
        let target = event.target;

        if (target.tagName === 'A') {
            if (target.href.includes('new-demand')) {
                event.preventDefault();
                modifyAndRedirect(target.href);
            }
        }
    });

    function modifyAndRedirect(url) {
        const modifiedUrl = new URL(url);
        const newPath = modifiedUrl.pathname.replace('new-demand', 'unauthorized-new-demand');
        modifiedUrl.pathname = newPath;
        modifiedUrl.searchParams.set('objectId', planMapObjectIdRedirect);
        window.location.href = modifiedUrl.href;
    }

    return (
        <Fragment>
            {planMapWidget ? (
                planMapWidget.isPublish === true ? (
                    <>
                        <FabricJSCanvas className="sample-canvas" onReady={onReady} />

                        <div className={`${!modal && 'd-none'} w-25 h-100`} style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            backgroundColor: "white",
                            boxShadow: "-21px 0px 21px 1px rgba(89, 102, 122, 0.1)",
                            overflow: "auto",
                        }}>
                            <div className={"p-2 d-flex"}>
                                <Btn attrBtn={{ color: "white", className: "p-2 m-1 d-flex", onClick: () => toggle() }}>
                                    <CloseSharp style={{ display: "block", width: 24, height: 24 }} />
                                </Btn>
                            </div>

                            <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                                <Form className="form theme-form">
                                    <Row>
                                        {planMapObjCharResult && (
                                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                                <Render config={config} data={planMapObjCharResult} />
                                                <h1 className={`${resultIsLoading === false && planMapObjCharResult?.content && 'd-none'}`}>
                                                    Для данного объекта нет данных
                                                </h1>
                                            </FormGroup>
                                        )}
                                        <PuffLoader
                                            size={80}
                                            color="#7366ff"
                                            className={`${!resultIsLoading && 'd-none'}`}
                                            style={{
                                                margin: "20px auto",
                                                display: "inherit",
                                                position: "relative",
                                                width: "80px",
                                                height: "80px",
                                            }}
                                        />
                                    </Row>
                                </Form>
                            </CardBody>
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                        }}
                    >
                        <h1 style={{ color: "black" }}>Страница не опубликована</h1>
                    </div>
                )
            ) : (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                    }}
                >
                    <h1 style={{ color: "black" }}>Загрузка...</h1>
                </div>
            )}
        </Fragment>
    );


};

export default BlankViewer;