import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { Input, Label, Col, InputGroup, InputGroupText, Row } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

import { P, Btn } from "../../../../../AbstractElements";
import { TwinAuthApi } from "../../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const Publication = ({ src, templateName }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [showName, setShowName] = useState(true);
	const [description, setDescription] = useState("");
	const [showCompany, setShowCompany] = useState(true);
	const [owner, setOwner] = useState({ label: keycloak.tokenParsed.name, value: keycloak.tokenParsed.sub });
	const [selectCategory, setSelectCategory] = useState(null);
	const [cost, setCost] = useState(0);

	const [allUsersNames, setAllUsersNames] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.username,
				}));
				setAllUsersNames(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		axios
			.get(`/api/diplomaCategories.json`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setCategories(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const convertImageToBase64 = (imageUrl) => {
		if (!imageUrl) {
			console.error("URL изображения не указан");
			return;
		}

		fetch(imageUrl)
			.then((response) => {
				if (!response.ok) {
					throw new Error("Ошибка загрузки изображения");
				}
				return response.blob();
			})
			.then((blob) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					return reader.result;
				};
				reader.readAsDataURL(blob);
			})
			.catch((error) => {
				console.error("Ошибка конвертации изображения в Base64:", error);
			});
	};

	const send = () => {
		if (selectCategory) {
			const newTemplate = {
				img: convertImageToBase64(src),
				name: templateName,
				description: description,
				company: "", //подставить компанию
				showAuthorName: showName,
				author: { name: owner.label, id: owner.value },
				price: cost,
				rating: 0,
				category: selectCategory.map((item) => item.label),
				installed: false,
			};
			console.log(newTemplate);
			// return axios
			// 	.post(` http://localhost:8000/EditMenuEnter`, newTemplate)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Изменения сохранены!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 	});
		} else {
			toast.error("Выберите кактегорию!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	};

	return (
		<Fragment>
			<Col className="row col-sm-12 mb-3">
				<Label className="col-sm-9 col-form-label">Описание</Label>
				<Col sm="12">
					<textarea rows={"3"} className="form-control" type="tel" value={description} onChange={(e) => setDescription(e.target.value)} />
				</Col>
			</Col>
			<Row>
				<Col className="row col-sm-6">
					<P>Шаблон картинки</P>
					<img src={src} alt="Картинка не найдена :(" style={{ display: "block" }} className="mt-2 mb-2" />
				</Col>
				<Col className="mt-4 col-sm-6">
					<div className="checkbox checkbox-primary ms-2">
						<Input id={`checkbox-primary-1-name`} type="checkbox" checked={showName} onChange={(e) => setShowName(e.target.checked)} />
						<Label for={`checkbox-primary-1-name`}>Показывать имя автора шаблона</Label>
					</div>
					{showName && (
						<Col>
							<Label className="col-sm-6 col-form-label ">Владелец</Label>
							<Col md="12">
								<Select
									placeholder="Начните вводить..."
									noOptionsMessage={() => "Нет результатов"}
									options={allUsersNames}
									value={owner}
									className="js-example-basic-single col-sm-12"
									onChange={(option) => setOwner(option)}
								/>
							</Col>
						</Col>
					)}
					<div className="checkbox checkbox-primary ms-2 mt-4">
						<Input id={`checkbox-primary-1-company`} type="checkbox" checked={showCompany} onChange={(e) => setShowCompany(e.target.checked)} />
						<Label for={`checkbox-primary-1-company`}>Показывать компанию автора</Label>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md="8">
					<Label className="col-sm-6 col-form-label ">Категория</Label>
					<Col md="12">
						<Select
							isMulti
							placeholder="Начните вводить..."
							noOptionsMessage={() => "Нет результатов"}
							options={categories}
							value={selectCategory}
							className="js-example-basic-single col-sm-12"
							onChange={(option) => setSelectCategory(option)}
						/>
					</Col>
				</Col>
				<Col md="4">
					<Label className="col-sm-9 col-form-label">Стоимость</Label>

					<InputGroup>
						<Input className="form-control" type="number" value={cost} onChange={(e) => setCost(e.target.value)} />
						<InputGroupText>{"₽"}</InputGroupText>
					</InputGroup>
				</Col>
			</Row>
			<div className="modal-footer mt-4">
				<Btn attrBtn={{ color: "primary", onClick: send }}>Сохранить</Btn>
			</div>
		</Fragment>
	);
};
export default Publication;
