import React, { Fragment } from "react";
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import TableTariffManager from "./TableTariffManager";

const TariffManager = () => {
	return (
		<Fragment>
			<Breadcrumbs parent="Работа с файлами" title="Управление тарифами" mainTitle="Управление тарифами" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<TableTariffManager />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default TariffManager;
