import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col, Input, Label, CardFooter } from "reactstrap";
import { TwinAuthApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";

import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { useGroupsContext } from "../../../../_helper/Groups/GroupsProvider";
import { toast } from "react-toastify";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const AddGroup = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const history = useNavigate();

	const { createGroup } = useGroupsContext();

	const [title, setTitle] = useState("");
	const [description, setDescripton] = useState("");
	const [owner, setOwner] = useState({ label: keycloak.tokenParsed.name, value: keycloak.tokenParsed.sub });
	const [typeGroup, setTypeGroup] = useState({ label: "публичная", value: "public" });

	const [allUsersNames, setAllUsersNames] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.username,
				}));
				setAllUsersNames(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [loading, setLoading] = useState(false);

	const send = () => {
		setLoading(true);
		createGroup(title, description, owner.value, typeGroup.value).then(() => {
			setLoading(false);
			toast.success("Группа добавлена!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			history(`${process.env.PUBLIC_URL}/app/list-groups`);
		});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Добавить группу" parent="Группы" title="Добавить группу" />
			<Container fluid={true}>
				<div className="email-wrap">
					<Row>
						<Card>
							<CardBody>
								<Col className=" col-sm-12 mb-3">
									<Label className="col-sm-9 col-form-label">Название</Label>
									<Col sm="12">
										<Input className="form-control" type="tel" value={title} onChange={(e) => setTitle(e.target.value)} />
									</Col>
								</Col>
								<Col className=" col-sm-12 mb-3">
									<Label className="col-sm-9 col-form-label">Описание</Label>
									<Col sm="12">
										<Input className="form-control" type="tel" value={description} onChange={(e) => setDescripton(e.target.value)} />
									</Col>
								</Col>
								<Row>
									<Col className=" col-sm-8 mb-3">
										<Label className="col-sm-6 col-form-label ">Владелец</Label>
										<Col md="12">
											<Select
												placeholder="Начните вводить..."
												noOptionsMessage={() => "Нет результатов"}
												options={allUsersNames}
												value={owner}
												className="js-example-basic-single col-sm-12"
												onChange={(option) => setOwner(option)}
											/>
										</Col>
									</Col>
									<Col className=" col-sm-4 mb-3">
										<Label className="col-sm-6 col-form-label ">Тип группы</Label>
										<Col md="12">
											<Select
												placeholder="Начните вводить..."
												noOptionsMessage={() => "Нет результатов"}
												options={[
													{ label: "публичная", value: "public" },
													{ label: "частная", value: "private" },
												]}
												value={typeGroup}
												className="js-example-basic-single col-sm-12"
												onChange={(option) => setTypeGroup(option)}
											/>
										</Col>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<div className="modal-footer">
									<Btn attrBtn={{ color: "primary", disabled: loading, onClick: () => send() }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
								</div>
							</CardFooter>
						</Card>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};
export default AddGroup;
