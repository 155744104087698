import React, { Fragment, useState, useEffect } from "react";
import { Col, Label, Input } from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { BeatLoader } from "react-spinners";

import { Btn } from "../../../../AbstractElements";
import { useKeycloak } from "@react-keycloak/web";
import { TelephonyApi, TwinAuthApi } from "../../../../api";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const Other = ({ pbxId }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [allowSelect, setAllowSelect] = useState(false);
	const [employees, setEmployees] = useState(null);

	const [allUsersNames, setAllUsersNames] = useState([]);
	const [curItem, setCurItem] = useState("");

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
				}));
				setAllUsersNames(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`/api/pbxlist.json`)
			.then((response) => {
				const foundItem = response.data.find((item) => item.id === pbxId);
				setCurItem(foundItem);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [loading, setLoading] = useState(false);
	const send = () => {
		setLoading(true);
		const updItem = {
			...curItem,
			allow_employees_select_number: allowSelect,
			employees_with_permission: employees,
		};

		// axios
		// 	.put(`${TelephonyApi}update`, updItem, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Изменения сохранены!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setLoading(false);
		//
		// 	})
		// 	.catch((error) => {
		// 		setLoading(false);
		// 		toast.error('Произошла ошибка', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		console.error(error);
		// 	});
	};

	return (
		<Fragment>
			<div className="checkbox checkbox-primary col-md-12">
				<Input id={`checkbox-allowSelect`} type="checkbox" checked={allowSelect} onChange={(e) => setAllowSelect(e.target.checked)} />
				<Label for={`checkbox-allowSelect`}>Разрешить сотрудникам выбирать этот номер для совершения исходящих звонков</Label>
			</div>
			{allowSelect && (
				<Col sm="6" className="mt-2" style={{ paddingLeft: "32px" }}>
					<Label style={{ color: "#898989" }}>Укажите сотрудников, которым разрешено звонить через этот номер</Label>
					<Select
						placeholder="Начните вводить имя пользователя..."
						isMulti
						noOptionsMessage={() => "Нет результатов"}
						options={allUsersNames}
						value={employees}
						onChange={(option) => setEmployees(option)}
						className="js-example-basic-single col-sm-12"
					/>
				</Col>
			)}
			<div className="modal-footer mt-2">
				<Btn attrBtn={{ color: "primary", onClick: send, disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
			</div>
		</Fragment>
	);
};
export default Other;
