import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableCounterpartiesRoles from './TableCounterpartiesRoles';
import { useKeycloak } from "@react-keycloak/web";
import { TwinAuthApi, CounterpartiesApi } from "../../../../api";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const CounterpartiesRoles = () => {
    const [roles, setRoles] = useState([]);
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const [companyId, setCompanyId] = useState("");
    const currentUserId = `${keycloak.tokenParsed.sub}`;
    const [counterpartyHeader, setCounterpartyHeader] = useState('Контрагент')
    const [counterpartyText, setCounterpartyText] = useState('контрагент')

    useEffect(() => {
            companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyHeader('Партнёр')
            companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyText('партнёр')
        }, [companyData]);

    /*useEffect(() => {
        const fetchUserCompanies = async () => {
            try {
                const response = await axios.get(`${TwinAuthApi}get-user-groups-companies/${currentUserId}`, {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                    }
                });
                if (response.data.length > 0) {
                    const companies = JSON.parse(response.data);
                    setCompanyId(companies[0].id);
                }
            } catch (error) {
                console.error("Ошибка получения данных:", error);
            }
        };

        fetchUserCompanies();
    }, [currentUserId, keycloak.token]);*/

    useEffect(() => {
        const fetchCounterpartiesRoles = async () => {
            if (companyData) {
                    companyData[0]?.attributes?.companyId[0] && axios.get(`${CounterpartiesApi}getRoles?companyId=${companyData[0]?.attributes?.companyId[0]}`, {
                        headers: {
                            Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
                            //CompanyId: companyId
                        }
                    }).then((response) => {
                        setRoles(concatResults(response).roles ?? []);
                    })
                        .catch((error) => {
                            console.error("Ошибка получения данных:", error);
                        });
                    //const roles = concatResults(response).roles || [];
                    //console.log("Fetched roles:", roles); // Логируем полученные роли
                    //setRoles(roles);
            }
        };

        fetchCounterpartiesRoles();
    }, [companyData, keycloak.token]);

    return (
        <Fragment>
            <Breadcrumbs mainTitle={`Типы ${counterpartyText}ов`} parent={`${counterpartyHeader}ы`} title={`Типы ${counterpartyText}ов`} />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <TableCounterpartiesRoles roles={roles} setRoles={setRoles} companyId={companyData[0]?.attributes.companyId[0]} />
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};
export default CounterpartiesRoles;
