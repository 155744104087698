import React from "react";

export const VerticalSpace = {
    label: "Вертикальный пропуск",
    fields: {
        size: {
            label: "Высота",
            type: "custom",
            min: 24,
            default: 24,
            render: ({name, onChange }) => {
                return <>
                    <label htmlFor={name} className="form-label">Высота пропуска</label>
                    <input name={name} className="mx-2" type="range" min={0} max={150} defaultValue={24}
                           onChange={(e) => onChange(e.target.value)}/>
                </>
            }
        },
    },
    defaultProps: {
        size: "24px",
    },
    render: ({ size }) => {
        return <div style={{ height: `${size}px`, width: "100%" }} />;
    },
};