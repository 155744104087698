import React from "react";
import {ru} from "date-fns/locale";
import {Col, Input, Label} from "reactstrap";
import DatePicker from "react-datepicker";

export const InputNights = {
    label: "Количество ночей",
    fields: {
        children: {
            label: "Текст",
            type: "text",
            //visible: false
        },
    },
    defaultProps: {
        children: "Количество ночей",
    },
    render: ({ children }) => {
        return <div>
            <Label className="col-sm-9 col-form-label">{children}</Label>
            <div className={"d-flex"}>
                <div>
                    <div className="input-group">
                        <DatePicker locale={ru} className="form-control digits" selected={new Date()}
                                    dateFormat="dd.MM.yyyy"/>
                    </div>
                    <div className="input-group py-2">
                        <DatePicker locale={ru} className="form-control digits" selected={new Date()}
                                    dateFormat="dd.MM.yyyy"/>
                    </div>
                </div>
                <div>
                    <div className={"d-flex px-2"}>
                        <Input size={3} value={0} className="form-control" type="number"/>
                        <b className={"px-2"}>Ночей</b>
                    </div>
                </div>
            </div>
        </div>;
    },
};