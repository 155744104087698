import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileText, LogIn, Mail, User } from 'react-feather';
import man from '../../../assets/images/dashboard/profile.png';

import { LI, UL, Image, P } from '../../../AbstractElements';
//import CustomizerContext from '../../../_helper/Customizer';
import { Account, Admin, Inbox, LogOut, Taskboard } from '../../../Constant';
import url from '../../../config';
import { useKeycloak } from '@react-keycloak/web';

const UserHeader = () => {
	const { keycloak, initialized } = useKeycloak()
	const [roles, setRoles] = useState(
		keycloak.tokenParsed ? keycloak.tokenParsed.realm_access?.roles : []
	);
	//if (!keycloak.authenticated) {
	//	keycloak.login();
	//}

	const navigate = useNavigate();
	//const history = useHistory();
	const [profile, setProfile] = useState('');

	//const { layoutURL } = useContext(CustomizerContext);
	const authenticated = keycloak.authenticated;
	const [name, setName] = useState('John Doe');

	useEffect(() => {
		setProfile(localStorage.getItem('profileURL') || man);
		setName(localStorage.getItem('Name') ? localStorage.getItem('Name') : name);
	}, []);

	const Logout = (auth) => {
		localStorage.removeItem('profileURL');
		localStorage.removeItem('token');
		localStorage.removeItem('auth0_profile');
		localStorage.removeItem('Name');
		localStorage.setItem('authenticated', false);
		//window.location.href = `http://localhost:3001`;
		window.location.href = auth;
	};

	const UserMenuRedirect = (redirect) => {
		navigate(redirect);
	};

	return (
		<li className="profile-nav onhover-dropdown pe-0 py-0">
			<div className="media profile-media">
				<Image
					attrImage={{
						className: 'b-r-10 m-0',
						src: `https://loremflickr.com/32/32`, // заглушка с рандомными картинками
						alt: '',
					}}
				/>
				<div className="media-body">
					<span style={{whiteSpace: 'nowrap'}}>{ authenticated ? keycloak.tokenParsed?.name : name}</span>
					<P attrPara={{ className: 'mb-0 font-roboto' }}>
						{Admin} <i className="middle fa fa-angle-down"></i>
					</P>
				</div>
			</div>
			<UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
				{roles.includes('twinauth-api') && (
					<>
						<LI
							attrLI={{
								onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/profile/`),
							}}
						>
							<User />
							<span>{Account} </span>
						</LI>
					</>
				)}

				{roles.includes('emails-api') && (
					<>
						<LI
							attrLI={{
								onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app/`),
							}}
						>
							<Mail />
							<span>{Inbox}</span>
						</LI>
					</>
				)}

				{roles.includes('tasks-api') && (
					<>
						<LI
							attrLI={{
								onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo/`),
							}}
						>
							<FileText />
							<span>{Taskboard}</span>
						</LI>
					</>
				)}
				<LI attrLI={{ onClick: ()=> keycloak.logout() }}>
					<LogIn />
					<span>{LogOut}</span>
				</LI>
			</UL>
		</li>
	);
};

export default UserHeader;
