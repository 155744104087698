import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container, Col, Label, Input, CardFooter } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { Breadcrumbs, Btn, H5 } from '../../../../AbstractElements';

const ReferralProgram = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get('/api/counterparties.json')
			.then((response) => {
				setFilteredProducts(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [selectType, setSelectType] = useState({ label: null, value: null });

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Реферальная программа" parent="Лояльность" title="Реферальная программа" />
			<Container fluid={true}>
				<Card>
					<CardHeader>
						<H5>Для приглашенного (первый визит)</H5>
						<Col className="row col-sm-12 mb-4 mt-3">
							<Label className="col-sm-9 col-form-label">Акция с фиксированным % скидки</Label>
							<Col sm="12">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={selectType}
									options={[
										{ label: 'Юр. лицо', value: 'Юр. лицо' },
										{ label: 'ИП', value: 'ИП' },
										{ label: 'Физ. лицо', value: 'Физ. лицо' },
									]}
									onChange={(option) => setSelectType(option)}
								/>
							</Col>
						</Col>
					</CardHeader>
					<CardBody>
						<H5>Для пригласившего</H5>
						<Col className="row col-sm-12 mb-4 mt-3">
							<Label className="col-sm-9 col-form-label">Акция с фиксированным % cashback</Label>
							<Col sm="12">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={selectType}
									options={[
										{ label: 'Юр. лицо', value: 'Юр. лицо' },
										{ label: 'ИП', value: 'ИП' },
										{ label: 'Физ. лицо', value: 'Физ. лицо' },
									]}
									onChange={(option) => setSelectType(option)}
								/>
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-4 mt-3">
							<Label className="col-sm-9 col-form-label">Начисление бонуса</Label>
							<Col sm="12">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={selectType}
									options={[
										{ label: 'Первый визит', value: 'FirstVisit' },
										{ label: 'Каждый визит', value: 'EveryVisit' },
									]}
									onChange={(option) => setSelectType(option)}
								/>
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-4 mt-3">
							<Label className="col-sm-9 col-form-label">Тип карты лояльности для начисления бонуса</Label>
							<Col sm="12">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={selectType}
									options={[
										{ label: 'Юр. лицо', value: 'Юр. лицо' },
										{ label: 'ИП', value: 'ИП' },
										{ label: 'Физ. лицо', value: 'Физ. лицо' },
									]}
									onChange={(option) => setSelectType(option)}
								/>
							</Col>
						</Col>
					</CardBody>
					<CardFooter>
						<div className="modal-footer p-r-15">
							<Btn>Сохранить</Btn>
						</div>
					</CardFooter>
				</Card>
			</Container>
		</Fragment>
	);
};
export default ReferralProgram;
