import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Trash2 } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label } from 'reactstrap';
import FilterComponent from '../../FilterComponent';
import Select from 'react-select';
import {useModulesContext} from "../../../../../_helper/Modules/ModulesProvider";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const DataTableManagingUserGroups = () => {
    const { getModuleGroupsRoles, moduleGroupRoles, getGroupRolesRoles, rolesRoles, moduleRules, getModuleRules, createModuleGroupRole, deleteModuleGroupRole } = useModulesContext();
    const { keycloak } = useKeycloak();
    const { getCompanySubgroups } = useCompanyContext();

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState(moduleRules);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const [ruleId, setRuleId] = useState('');
    const [groupRoleRole, setRoleRole] = useState('');
    const [modulesRulesOptions, setModulesRulesOptions] = useState([]);
    const [groupsRolesRolesOptions, setGroupRolesRolesOptions] = useState([]);

    useEffect(() => {
        moduleGroupRoles && setFilteredItems(moduleGroupRoles.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, moduleRules, moduleGroupRoles]);

    useEffect(() => {
        getModuleRules();
        getModuleGroupsRoles();
        getGroupRolesRoles();
        getCompanySubgroups();
    }, [keycloak.tokenParsed]);

    useEffect(() => {
        moduleRules && setModulesRulesOptions(moduleRules.map((item) => ({label: item.displayName, value: item.id})));
    }, [moduleRules]);

    useEffect(() => {
        rolesRoles && setGroupRolesRolesOptions(rolesRoles.map((item) => ({label: item.name, value: item.name})));
    }, [rolesRoles])

    const toggle = () => {
        setRuleId('');
        setRoleRole('');

        setModal(!modal);
    };

    const handleAddRow = () => {
        setModal(!modal);
        if (ruleId !== '' && groupRoleRole !== '') {
            createModuleGroupRole(ruleId, groupRoleRole);
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const deleteRow = (remId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'Данное действие не отменит отправку оповщений!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(() => {
            deleteModuleGroupRole(remId);
        });
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const getModuleRule = (id) => {
        let ruleName = '';
        if (moduleRules) {
            console.log(id);
            let findRuleName = moduleRules.find((item) => item.id === id);
            if (findRuleName) {
                console.log(findRuleName);
                ruleName = findRuleName.displayName;
            }
        }
        return ruleName;
    };

    const tableColumns = [
        {
            name: 'Роль',
            selector: (row) => `${row.groupRole}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Правило',
            selector: (row) => `${getModuleRule(row.ruleId)}`,
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{display: 'flex'}}>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'}/>
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление новой роли группы'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Правило
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={modulesRulesOptions} onChange={(e) => setRuleId(e.value)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Роль
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={groupsRolesRolesOptions} onChange={(e) => setRoleRole(e.value)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTableManagingUserGroups;
