import React, { Fragment, useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';

import OverallBalance from './OverallBalance';

const TwinOverwatch = () => {
	const [data, setData] = useState([]);
	useEffect(() => {
		fetch('/api/TwinOverwatchServices.json')
			.then((response) => response.json())
			.then((jsonData) => {
				setData(jsonData);
			})
			.catch((error) => console.error('Ошибка:', error));
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Мониторинг работы сервисов" parent="Мониторинг работы сервисов" title="" />
			<Container fluid={true}> {data && data.length > 0 && data.map((service, i) => <OverallBalance data={service} key={i} />)}</Container>
		</Fragment>
	);
};

export default TwinOverwatch;
