import React, {useEffect, useState, useContext} from 'react';
import axios from 'axios';
import {useKeycloak} from '@react-keycloak/web';
import DocumentManagementContext from ".";
import {useCompanyContext} from "../Company/CompanyProvider";
import {DocumentManagementApi, TaskApi, AllProjectApi} from "../../api";
import {toast} from "react-toastify";
import uuid from "react-uuid";
import { concatResults } from '../../concatResults';

const DocumentManagementProvider = ({children, props}) => {
    const context = useCreateDocumentManagementContext(props);

    return (
        <DocumentManagementContext.Provider
            value={context}
        >
            {children}
        </DocumentManagementContext.Provider>
    );
};

export function useDocumentManagementContext() {
    const context = useContext(DocumentManagementContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateDocumentManagementContext = function (props) {
    const {keycloak, initialized} = useKeycloak();
    const {companyData} = useCompanyContext();

    const [documentsData, setDocumentsData] = useState(null);
    const [documentData, setDocumentData] = useState(null);
    const [companyRightAccess, setCompanyRightAccess] = useState(null);
    const [documentStatuses, setDocumentStatuses] = useState(null);
    const [documentTemplates, setDocumentTemplates] = useState(null);
    const [documentTemplateData, setDocumentTemplateData] = useState(null);
    const [documentTypes, setDocumentTypes] = useState(null);
    const [documentView, setDocumentViews] = useState(null);
    const [signingDocuments, setSigningDocuments] = useState(null);
    const [signingDocumentData, setSigningDocumentData] = useState(null);

    const getDocuments = async (companyId) => {
        await axios
            .get(`${DocumentManagementApi}get-document-list/${companyId}`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                setDocumentsData(concatResults(response)?.documents);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getDocument = async (id) => {
        await axios
            .get(`${DocumentManagementApi}get-document/${id}`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                setDocumentData(concatResults(response));
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const createDocument = async (documentNumber, documentTitle, statusId, typeId, content, attachment = null) => {
        await axios
            .post(`${DocumentManagementApi}create-document`, {
                documentNumber: documentNumber,
                documentTitle: documentTitle,
                userId: keycloak.tokenParsed.sub,
                statusId: statusId,
                companyId: companyData?.id,
                typeId: typeId,
                content: content,
                attachment: attachment
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocuments(companyData?.id);
                toast.success("Документ успешно создан", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const createDocumentWithSigning = async (documentNumber, documentTitle, statusId, typeId, content, attachment = null) => {
        await axios.get(`${AllProjectApi}get?type=SigningDocuments`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then(async (responseProj) => {
                if (responseProj && companyData) {
                    //console.log(responseProj);
                    //console.log(companyData?.id);
                    let project = concatResults(responseProj).projects.find((item) => item.companyId === companyData?.id);
                    //console.log(project);
                    if (project) {
                        await axios
                            .get(`${AllProjectApi}getProjectStatuses?projectId=${project.id}`, {
                                headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
                            }).then(async (responseStatuses) => {
                                //console.log(responseStatuses);
                                let task = {
                                    id: uuid(),
                                    name: documentTitle,
                                    description: `{"blocks":[{"key":"8be1","text":"Документ №${documentNumber}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
                                    dateStart: (new Date()).toJSON(),
                                    statusId: concatResults(responseStatuses).statuses.find((item) => item.name === "Новые").id ?? concatResults(responseStatuses)?.statuses[0].id,
                                    projectId: project.id,
                                    creatorId: keycloak.tokenParsed.sub,
                                    doerId: keycloak.tokenParsed.sub
                                };
                                await axios
                                    .post(`${TaskApi}createFastTask`, task, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                                    .then(async (responseTask) => {
                                        let doc = {
                                            documentNumber: documentNumber,
                                            documentTitle: documentTitle,
                                            userId: keycloak.tokenParsed.sub,
                                            statusId: statusId,
                                            companyId: companyData?.id,
                                            typeId: typeId,
                                            content: content,
                                            attachment: attachment
                                        };
                                        await axios
                                            .post(`${DocumentManagementApi}create-document`, doc, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                                            .then(async (responseDoc) => {
                                                let signDoc = {
                                                    taskId: concatResults(responseTask),
                                                    documentId: concatResults(responseDoc),
                                                    userId: keycloak.tokenParsed.sub,
                                                    companyId: companyData?.id,
                                                    telegramUsername: null
                                                };
                                                await axios
                                                    .post(`${DocumentManagementApi}create-document-signing`, signDoc, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                                                    .then((response) => {
                                                        getSigningDocuments();
                                                        toast.success("Документ успешно создан", {
                                                            position: toast.POSITION.TOP_RIGHT,
                                                        });
                                                    })
                                                    .catch((error) => {
                                                        console.error("Ошибка получения данных:", error);
                                                    });
                                            })
                                            .catch((error) => {
                                                console.error("Ошибка получения данных:", error);
                                            });
                                    })
                                    .catch((error) => {
                                        console.error("Ошибка получения данных:", error);
                                    });
                            })
                            .catch((error) => {
                                console.error("Ошибка получения данных:", error);
                            });
                    } else {
                        toast.success("Не найден проект согласования документов", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                } else {
                    toast.error("Ошибка при поиске проектов", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
    };

    const updateDocument = async (id, documentNumber, documentTitle, userId, companyId, statusId, typeId, content, attachment = null) => {
        await axios
            .put(`${DocumentManagementApi}update-document`, {
                id: id,
                documentNumber: documentNumber,
                documentTitle: documentTitle,
                userId: userId,
                statusId: statusId,
                companyId: companyId,
                type: typeId,
                content: content,
                attachment: attachment
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocuments(companyId);
                toast.success("Документ успешно обновлён", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getDocumentRightAccess = async (id) => {
        await axios
            .get(`${DocumentManagementApi}get-document-right-accesses-list/${id}`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                setCompanyRightAccess(concatResults(response)?.documentRightAccesses);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const createDocumentRightAccess = async (userId, managingDocumentTemplates) => {
        await axios
            .post(`${DocumentManagementApi}create-document-right-access`, {
                userId: userId,
                companyId: companyData?.id,
                managingDocumentTemplates: managingDocumentTemplates
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentRightAccess(companyData?.id);
                toast.success("Документ успешно создан", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const updateDocumentRightAccess = async (id, userId, companyId, managingDocumentTemplates) => {
        await axios
            .put(`${DocumentManagementApi}update-document-right-access`, {
                id: id,
                userId: userId,
                companyId: companyId,
                managingDocumentTemplates: managingDocumentTemplates
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentRightAccess(companyId);
                toast.success("Документ успешно обновлён", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getDocumentStatuses = async () => {
        await axios
            .get(`${DocumentManagementApi}get-document-statuses-list`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                setDocumentStatuses(concatResults(response)?.documentStatuses);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const createDocumentStatus = async (label) => {
        await axios
            .post(`${DocumentManagementApi}create-document-status`, {
                label: label
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentStatuses();
                toast.success("Документ успешно создан", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const updateDocumentStatus = async (id, userId, companyId, managingDocumentTemplates) => {
        await axios
            .put(`${DocumentManagementApi}update-document-status`, {
                id: id,
                userId: userId,
                companyId: companyId,
                managingDocumentTemplates: managingDocumentTemplates
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentStatuses();
                toast.success("Документ успешно обновлён", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getDocumentTemplates = async () => {
        await axios
            .get(`${DocumentManagementApi}get-document-templates-list`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                setDocumentTemplates(concatResults(response)?.documentTemplates);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getDocumentTemplate = async (id) => {
        await axios
            .get(`${DocumentManagementApi}get-document-template/${id}`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                setDocumentTemplateData(concatResults(response));
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const createDocumentTemplate = async (name, content, userId, companyId) => {
        await axios
            .post(`${DocumentManagementApi}create-document-template`, {
                name: name,
                content: content,
                userId: userId,
                companyId: companyId
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentTemplates();
                toast.success("Документ успешно создан", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const updateDocumentTemplate = async (id, name, content, userId, companyId) => {
        await axios
            .put(`${DocumentManagementApi}update-document-template`, {
                id: id,
                name: name,
                content: content,
                userId: userId,
                companyId: companyId
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentTemplates();
                toast.success("Документ успешно обновлён", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const deleteDocumentTemplate = async (id) => {
        await axios
            .delete(`${DocumentManagementApi}delete-document-template/${id}`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentTemplates();
                toast.success("Документ успешно удалён", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getDocumentTypes = async () => {
        await axios
            .get(`${DocumentManagementApi}get-document-types-list`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                setDocumentTypes(concatResults(response)?.documentTypes);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const createDocumentType = async (label) => {
        await axios
            .post(`${DocumentManagementApi}create-document-type`, {
                label: label
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentTypes();
                toast.success("Документ успешно создан", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const updateDocumentType = async (id, label) => {
        await axios
            .put(`${DocumentManagementApi}update-document-type`, {
                id: id,
                label: label
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentTypes();
                toast.success("Документ успешно обновлён", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const deleteDocumentType = async (id) => {
        await axios
            .delete(`${DocumentManagementApi}delete-document-type/${id}`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentTypes();
                toast.success("Документ успешно удалён", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getDocumentViews = async () => {
        await axios
            .get(`${DocumentManagementApi}get-document-views-list`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                setDocumentViews(concatResults(response)?.documentViews);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const createDocumentView = async (label) => {
        await axios
            .post(`${DocumentManagementApi}create-document-view`, {
                label: label
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentViews();
                toast.success("Документ успешно создан", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const updateDocumentView = async (id, label) => {
        await axios
            .put(`${DocumentManagementApi}update-document-view`, {
                id: id,
                label: label
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentViews();
                toast.success("Документ успешно обновлён", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const deleteDocumentView = async (id) => {
        await axios
            .delete(`${DocumentManagementApi}delete-document-view/${id}`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getDocumentViews();
                toast.success("Документ успешно удалён", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getSigningDocuments = async () => {
        await axios
            .get(`${DocumentManagementApi}get-document-signings-list`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                setSigningDocuments(concatResults(response)?.signingDocuments);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getSigningDocument = async (id) => {
        await axios
            .get(`${DocumentManagementApi}get-document-signing/${id}`, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                setSigningDocumentData(concatResults(response));
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const createSigningDocuments = async (taskId, documentId, userId, companyId, telegramUsername = null) => {
        await axios
            .post(`${DocumentManagementApi}create-document-signing`, {
                taskId: taskId,
                documentId: documentId,
                userId: userId,
                companyId: companyId,
                telegramUsername: telegramUsername
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getSigningDocuments();
                toast.success("Документ успешно создан", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const updateSigningDocuments = async (id, taskId, documentId, userId, companyId, telegramUsername = null) => {
        await axios
            .put(`${DocumentManagementApi}update-document-signing`, {
                id: id,
                taskId: taskId,
                documentId: documentId,
                userId: userId,
                companyId: companyId,
                telegramUsername: telegramUsername
            }, {headers: {Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
                getSigningDocuments();
                toast.success("Документ успешно обновлён", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    return {
        documentsData,
        documentData,
        companyRightAccess,
        documentStatuses,
        documentTemplates,
        documentTemplateData,
        documentTypes,
        documentView,
        signingDocuments,
        signingDocumentData,
        getDocument,
        createDocument,
        createDocumentWithSigning,
        updateDocument,
        getDocuments,
        getDocumentRightAccess,
        createDocumentRightAccess,
        updateDocumentRightAccess,
        getDocumentStatuses,
        createDocumentStatus,
        updateDocumentStatus,
        getDocumentTemplates,
        getDocumentTemplate,
        createDocumentTemplate,
        updateDocumentTemplate,
        deleteDocumentTemplate,
        getDocumentTypes,
        createDocumentType,
        updateDocumentType,
        deleteDocumentType,
        getDocumentViews,
        createDocumentView,
        updateDocumentView,
        deleteDocumentView,
        getSigningDocuments,
        getSigningDocument,
        createSigningDocuments,
        updateSigningDocuments,
    };
}

export default DocumentManagementProvider;