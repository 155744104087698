import React from "react";
import {Col, Label} from "reactstrap";
import {ru} from "date-fns/locale";
import DatePicker from 'react-datepicker';

export const InputTime = {
    label: "Выбрать время",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
    },
    defaultProps: {
        children: "Выбрать время",
    },
    render: ({ children }) => {
        return <div>
            <Label className="col-sm-9 col-form-label">{children}</Label>
            <Col sm="12">
                <div className="input-group">
                    <DatePicker dateFormat="HH:mm" timeCaption="Время" showTimeSelect showTimeSelectOnly timeIntervals={15} locale={ru} selected={new Date()} className="form-control digits" />
                </div>
            </Col>
        </div>;
    },
};