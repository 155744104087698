import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import axios from "axios";
import { Breadcrumbs } from "../../../AbstractElements";
import { Label, Col } from "reactstrap";
import TableSitesList from "./TableSitesList";
import { CmsApi } from "../../../api";
import { useKeycloak } from "@react-keycloak/web";
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../concatResults";

const Sites = () => {
	const [data, setData] = useState([]);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const fetchData = async () => {
		try {
			await axios
				.get(`${CmsApi}getSites`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					setData(response.data.sites);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		} catch (error) {
			console.error("Ошибка при выполнении запроса:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список сайтов" parent="Конструктор сайтов" title="Список сайтов" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardHeader>
							<div className="font-primary f-16">Для перехода в конструктор сайта нажмите на его название</div>
						</CardHeader>
						<CardBody>
							<TableSitesList products={data} fetchData={fetchData} />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default Sites;
