import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row, Collapse, Label } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Plus } from "react-feather";
import { H6, Btn } from "../../../../AbstractElements";

const FilterComponent = ({ filterText, onFilter, toggle, departments, data, setData }) => {
	const [isOpen, setIsOpen] = useState(null);

	const toggleClientCard = (item) => {
		if (isOpen === item) {
			setIsOpen(null);
		} else {
			setIsOpen(item);
		}
	};

	const [checkedDepartments, setCheckedDepartments] = useState([]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const departmentIds = urlParams.getAll("departmentId");
		setCheckedDepartments(departmentIds);

		const filteredEmployees = departmentIds.length === 0 ? data : data.filter((employee) => employee.departmentId.some((id) => departmentIds.includes(id)));
		setData(filteredEmployees);
	}, []);

	const handleCheckboxChange = (e) => {
		const departmentId = e.target.value;
		const isChecked = e.target.checked;

		let updatedDepartments = [...checkedDepartments];

		if (isChecked) {
			updatedDepartments.push(departmentId);
		} else {
			updatedDepartments = updatedDepartments.filter((id) => id !== departmentId);
		}

		setCheckedDepartments(updatedDepartments);
		const filteredEmployees = updatedDepartments.length === 0 ? data : data.filter((employee) => employee.departmentId.some((id) => updatedDepartments.includes(id)));
		setData(filteredEmployees);

		const urlParams = new URLSearchParams(window.location.search);
		if (isChecked) {
			urlParams.append("departmentId", departmentId);
		} else {
			urlParams.delete("departmentId");
		}

		window.history.replaceState({}, "", `${window.location.pathname}?${urlParams}`);
	};

	return (
		<>
			<div style={{ width: "100%" }} className="mb-3">
				<Col md="3">
					<Btn attrBtn={{ color: "primary", className: "me-4", onClick: toggle }}>
						<Plus style={{ margin: "0px 2px -7px 0px" }} /> Добавить сотрудника
					</Btn>
				</Col>
			</div>
			<div className="feature-products" style={{ width: "100%" }}>
				<Row>
					<Col xl="3" md="6">
						<div className={`product-sidebar `}>
							<div className="filter-section">
								<Card>
									<CardHeader className="p-relative" onClick={() => toggleClientCard(1)} style={{ cursor: "pointer", border: "1px solid #e0e0e0b3" }}>
										<H6 attrH6={{ className: "mb-0 f-w-600" }}>
											Фильтры
											<span className="pull-right">{isOpen ? <i className="fa fa-chevron-up toggle-data"></i> : <i className="fa fa-chevron-down toggle-data"></i>}</span>
										</H6>
									</CardHeader>
									<Collapse isOpen={isOpen === 1}>
										<div
											className="left-filter p-absolute"
											style={{
												zIndex: "3",
												width: "100%",
												backgroundColor: "white",
												border: "1px solid #e0e0e0b3",
												borderRadius: "6px",
												borderTop: "none",
												borderTopLeftRadius: "0",
												borderTopRightRadius: "0",
											}}
										>
											<CardBody className="filter-cards-view animate-chk">
												<div className="product-filter">
													<H6 attrH6={{ className: "f-w-600 ms-3" }}>Отделы</H6>
													<div className="checkbox-animated mt-0">
														{departments &&
															departments.map((department, index) => {
																return (
																	<Label className="d-block mt-2 ms-3" key={index}>
																		<Input
																			className="checkbox_animated"
																			onChange={handleCheckboxChange}
																			checked={checkedDepartments.includes(department.id.toString())}
																			value={department.id}
																			id={department.id}
																			type="checkbox"
																			data-original-title=""
																			title=""
																		/>
																		{department.name}
																	</Label>
																);
															})}
													</div>
												</div>
											</CardBody>
										</div>
									</Collapse>
								</Card>
							</div>
						</div>
					</Col>
					<Col xl="9" md="6">
						<div className="product-search">
							<Form>
								<FormGroup className="m-0 form-group search-product">
									<Input className="form-control" type="text" placeholder="Поиск.." value={filterText} onChange={onFilter} style={{ border: "1px solid #e0e0e0b3" }} />
									<i className="fa fa-search"></i>
								</FormGroup>
							</Form>
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default FilterComponent;
