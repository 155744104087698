const api = `${process.env.PUBLIC_URL}/api`; 
const proxy = `https://twinauth-ift.proxy-ift.twinteal.ru`; 
export const TaskApi = `${proxy}/tasks-api/`; 
export const BookmarkApi = `${api}/bookmark.json`; 
export const ChartistApi = `${api}/chartistData.json`; 
export const ChartjsApi = `${api}/chartjsData.json`; 
export const ChatMemberApi = `${api}/chatMember.json`; 
export const ChatApi = `${api}/chat.chats.json`; 
export const RealChatApi = `${proxy}/chat-api/`; 
export const CalendarApi = `${proxy}/calendar-api/`; 
export const ContactApi = `${api}/contacts.json`; 
export const ProductsApi = `${proxy}/products-api/`; 
export const EmailApi = `${proxy}/emails-api/`; 
export const ImageLightApi = `${api}/image-light.json`; 
export const BigImageApi = `${api}/image-big-light.json`; 
export const MasonryApi = `${api}/masonry.json`; 
export const GooglechartApi = `${api}/googleChartData.json`; 
export const AllProjectApi = `${proxy}/projects-api/`; 
export const TwinAuthApi = `${proxy}/twinauth-api/`; 
export const CompaniesApi = `${proxy}/company-api/`; 
export const ModulesApi = `${proxy}/modules-api/`; 
export const ConsecutivePagesApi = `https://conpages-ift.proxy-ift.twinteal.ru/conpages-api/`; 
export const EducationApi = `${proxy}/education-api/`; 
export const ReminderApi = `${proxy}/reminder-api/`; 
export const GroupsApi = `${proxy}/groups-api/`; 
export const DocumentManagementApi = `${proxy}/documents-management-api/`; 
export const SearchAllTabsApi = `${api}/searchAllTabs.json`; 
export const TableDataApi = `${api}/tableData.json`; 
export const HoverableRowsTable = `${api}/hoverableRowsTable.json`; 
export const FlagIconDataApi = `${api}/flagIconData.json`; 
export const FontawsomeDataApi = `${api}/fontawsomeData.json`; 
export const IcoDataApi = `${api}/icoData.json`; 
export const ThemifyDataApi = `${api}/themifyData.json`; 
export const DemandsApi = `https://demands-ift.proxy-ift.twinteal.ru/demands-api/`; 
export const NewsApi = `${proxy}/news-api/`; 
export const ServicesApi = `${proxy}/services-api/`; 
export const AnalyticsApi = `${proxy}/analytics-api/`; 
export const MoneyManagementApi = `${proxy}/moneyManagement-api/`; 
export const DeliveryApi = `${proxy}/delivery-api/`; 
export const TestingApi = `${proxy}/testing-api/`; 
export const TelephonyApi = `${proxy}/telephony-api/`; 
export const CmsApi = `https://cms-ift.proxy-ift.twinteal.ru/cms-api/`; 
export const EmailsApi = `${proxy}/emails-api/`; 
export const TasksPaymentApi = `${proxy}/tasksPayment-api/`; 
export const CounterpartiesApi = `${proxy}/counterparties-api/`; 
export const SpecificationsApi = `${proxy}/specifications-api/`;
export const IMapsApi = `https://imaps-ift.proxy-ift.twinteal.ru/imaps-api/`;
export const UserCardApi = `https://localhost:7003/usercard-api/`;
export const StickyApi = `${proxy}/usercard-api/`;
export const TypeaheadDataApi = `${proxy}/usercard-api/`;
export const FaqApi = `${proxy}/usercard-api/`;
export const KnowledgebaseApi = `${proxy}/usercard-api/`;
export const FeatherDataApi = `${proxy}/usercard-api/`;
export const ExportDataApi = `https://exportdataservice-ift.proxy-ift.twinteal.ru/exportDataService-api/`;
export const ClientsApi = `https://clients-ift.proxy-ift.twinteal.ru/clients-api/`;
export const BitrixDataApi = `https://b25.100tz.ru/Bitrix/`;
