import React, { useState, useContext, useEffect } from "react";
import Context from "./index";
import axios from "axios";
import TaskContext from '.';
import { useKeycloak } from "@react-keycloak/web";
import { TaskApi } from "../../api";
import { useCompanyContext } from "../Company/CompanyProvider";
import uuid from 'react-uuid';

const TaskProvider = ({ children, props }) => {
	const context = useCreateTaskContext(props);

	return (
		<TaskContext.Provider
			value={context}
		>
			{children}
		</TaskContext.Provider>
	);
};

export function useTaskContext() {
	const context = useContext(TaskContext);
	if (!context) throw new Error('Use app context within provider!');
	return context;
}

const useCreateTaskContext = function (props) {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const companyId = companyData.map((item) => (item.attributes.companyId[0]));//"53819cb9-eaf4-48e1-b039-e37b6836d2b3";

	const [task, setTask] = useState({
		id: null,
		name: '',
		description: '',
		isExternal: null,
		dateStart: new Date(),
		dateEnd: null,
		statusId: null,
		projectId: null,
		parentTaskId: null,
		creatorId: null,
		doerId: null,
		companyId: null
	});

	const createTaskDemand = async (demand, typeProject, projectId) => {

		task.id = uuid();
		task.name = typeProject === 'Lead'
			? `��� �� ������ - ${demand.id}`
			: typeProject === 'Project'
				? `������ �� ������ - ${demand.id}`
				: typeProject === 'Deal'
					? `������ �� ������ - ${demand.id}`
					: `������ �� ������ - ${demand.id}`;

		task.statusId = uuid();
		task.projectId = projectId;
		task.creatorId = keycloak.tokenParsed.sub;
		task.doerId = keycloak.tokenParsed.sub;
		task.companyId = companyId;
		await axios
			.post(`${TaskApi}createFastTask`, task, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const createTaskDemandUnauthorized = async (demand, typeProject, projectId) => {

		task.id = uuid();
		task.name = typeProject === 'Lead'
			? `��� �� ������ - ${demand.id}`
			: typeProject === 'Project'
				? `������ �� ������ - ${demand.id}`
				: typeProject === 'Deal'
					? `������ �� ������ - ${demand.id}`
					: `������ �� ������ - ${demand.id}`;

		task.statusId = uuid();
		task.projectId = projectId;
		task.creatorId = uuid();
		task.doerId = uuid();
		await axios
			.post(`${TaskApi}createFastTaskUnauthorized`, task, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return {
		createTaskDemand,
		createTaskDemandUnauthorized
	};
};

export default TaskProvider;
