import React, { Fragment, useEffect, useState, useContext } from "react";
import { Container } from "reactstrap";

import { Breadcrumbs } from "../../../../../AbstractElements";
import ProductTotal from "./ProductTotal";
import ProductSidebar from "./ProductSidebar";
import ProductGrid from "./ProductGrid";
import { getVisibleproducts } from "../../../../../Services/Ecommerce.service";
import ProductContext from "../../../../../_helper/Ecommerce/Product";
import FilterContext from "../../../../../_helper/Ecommerce/Filter";

const TemplateCatalog = () => {
	const { productItem, symbol, fetchProducts } = useContext(ProductContext);

	const context = useContext(FilterContext);
	const products = getVisibleproducts(productItem, context.filter);

	//pagination
	const cardsPerPage = 12;
	const [currentPage, setCurrentPage] = useState(1);

	const indexOfLastCard = currentPage * cardsPerPage;
	const indexOfFirstCard = indexOfLastCard - cardsPerPage;
	const currentCards = products.slice(indexOfFirstCard, indexOfLastCard);

	useEffect(() => {
		console.log(products);
	}, [products]);

	useEffect(() => {
        fetchProducts();
    }, [])

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Каталог шаблонов дипломов" parent="Дипломы" title="Каталог шаблонов дипломов" />
			<Container fluid={true}>
				<div className="product-grid">
					<div className="feature-products">
						<ProductTotal
							len={products.length}
							cardsPerPage={cardsPerPage}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							indexOfFirstCard={indexOfFirstCard}
							indexOfLastCard={indexOfLastCard}
						/>
						<ProductSidebar />
					</div>
					<ProductGrid currentCards={currentCards} productItem={productItem} symbol={symbol} />
				</div>
			</Container>
		</Fragment>
	);
};
export default TemplateCatalog;
