import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Pagination, PaginationItem, PaginationLink, Media, Label, Input, CardBody, InputGroup, InputGroupText } from 'reactstrap';
import Popover from '@mui/material/Popover';
import { MoreHorizontal, Search } from 'react-feather';
import { useKeycloak } from '@react-keycloak/web';
import Select from 'react-select';
import axios from 'axios';
import SweetAlert from 'sweetalert2';

import { H6, P } from '../../../../AbstractElements';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { TwinAuthApi } from '../../../../api';
import { Link } from 'react-router-dom';
import { useGroupsContext } from '../../../../_helper/Groups/GroupsProvider';
import { toast } from 'react-toastify';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const GroupClass = ({ allGroups }) => {
	const { keycloak } = useKeycloak();
	const { 
		addGroupToFavorites, 
		removeGroupFromFavorites, 
		removeUserGromGroup, 
		updateGroup, 
		getUserSubscriptions, 
		addUserToGroup, 
		getFavoriteGroups 
	} = useGroupsContext();
	const { companyData } = useCompanyContext();

	const [favoriteGroups, setFavoriteGroups] = useState([]);
	const [title, setTitle] = useState('');
	const [description, setDescripton] = useState('');
	const [modal, setModal] = useState(false);
	const [edit, setEdit] = useState(null);
	const [groupId, setGroupId] = useState('');
	const [owner, setOwner] = useState({ label: keycloak.tokenParsed.name, value: keycloak.tokenParsed.sub });
	const [groupObject, setGroupObject] = useState();
	const [typeGroup, setTypeGroup] = useState({ label: 'публичная', value: 'public' });
	const [userSubscriptions, setUserSubscriptions] = useState();

	const toggle = (ObjectId) => {
		setAnchorEl(null);
		if (typeof ObjectId === 'object' && ObjectId.hasOwnProperty('id')) {
			setGroupId(ObjectId.id);
			setTitle(ObjectId.name);
			setDescripton(ObjectId.description);
			setGroupObject(ObjectId);
			setTypeGroup(ObjectId.groupType === 'public' ? { label: 'публичная', value: 'public' } : { label: 'частная', value: 'private' });
			setEdit(true);
		}
		setModal(!modal);
	};
	const [allUsersNames, setAllUsersNames] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + ' ' + option.lastName,
					value: option.username,
				}));
				setAllUsersNames(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		if (!userSubscriptions) {
			getUserSubscriptions().then((result) => setUserSubscriptions(result));
		}
	}, [getUserSubscriptions]);

	useEffect(() => {
		getFavoriteGroups().then((result) => {setFavoriteGroups(result)});
	}, [getFavoriteGroups]);

	// пагинация
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	//popover
	const [anchorEl, setAnchorEl] = useState(null);
	const [curEl, setCurEl] = useState({});

	const handleClick = (el, event) => {
		setAnchorEl(event);
		setCurEl(el);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const cancelSubscription = (item) => {
		setAnchorEl(null);
		SweetAlert.fire({
			title: 'Вы уверены, что хотите отменить подписку?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Да',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				removeUserGromGroup(item.id, keycloak.tokenParsed.sub);
			}
		});
	};

	const subscribe = (item) => {
		setAnchorEl(null);
		addUserToGroup(item.id, keycloak.tokenParsed.sub);
	};

	const addFavourites = (item) => {
		addGroupToFavorites(keycloak.tokenParsed.sub, item.id).then(() => {
			toast.success('Группа добавлена в избранное!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		})
	};

	const removeFavourites = (item) => {
		removeGroupFromFavorites(keycloak.tokenParsed.sub, item.id).then(() => {
			toast.success('Группа добавлена в избранное!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		})
	};

	const sendUpdateRequest = () => {
		updateGroup(groupId, title, description, owner.value, typeGroup, groupObject.commentsAllowed, groupObject.postsAllowed)
			.then(() => {
				toast.success('Группа обновлена!', {
					position: toast.POSITION.TOP_RIGHT,
				});
			});
	};

	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState(allGroups);

	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
		const results = allGroups.filter((group) => group.name.toLowerCase().includes(e.target.value.toLowerCase()));
		setSearchResults(results);
	};

	return (
		<Fragment>
			<Col className="col-sm-12 mb-3 mt-3">
				<InputGroup>
					<InputGroupText>
						<Search size={'18px'} />
					</InputGroupText>
					<Input className="form-control" placeholder="Поиск.." value={searchTerm} onChange={handleSearch} />
				</InputGroup>
			</Col>
			<Row>
				{searchResults && searchResults.length > 0
					? searchResults.slice(indexOfFirstItem, indexOfLastItem).map((list) => (
						<div className="p-3 col-xl-6 col-sm-12" key={list.id}>
							<div className="p-3 b-light border-2 b-r-10 ">
								<Row>
									<Col md="2">
										<Media
											src={`https://dummyimage.com/900x900`} //заглушка
											alt="Gallery"
											className="rounded-pill w-100"
										/>
									</Col>
									<Col md="10">
										<div className="d-flex" style={{ justifyContent: 'space-between' }}>
											<Link to={`${process.env.PUBLIC_URL}/app/view-group?groupId=${list.id}`}>
												<H6 attrH6={{ className: 'font-primary' }}>{list.name}</H6>
											</Link>
											<MoreHorizontal style={{ color: '#898989', cursor: 'pointer' }} onClick={(e) => handleClick(list, e.currentTarget)} aria-describedby={id} />
										</div>
										<P>{list.description}</P>
										<P>участники: {list.total_members}</P>
									</Col>
								</Row>
							</div>
						</div>
					))
					: 'Нет результатов'}
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
						{open && curEl.control ? (
							<div className="p-2 b-b-light" style={{ cursor: 'pointer' }} onClick={() => toggle(curEl)}>
								Редактировать
							</div>
						) : (userSubscriptions && userSubscriptions.find((item) => item.groupId === curEl.id) ? (
							<div className="p-2 b-b-light" style={{ cursor: 'pointer' }} onClick={() => cancelSubscription(curEl)}>
								Отписаться
							</div>
						) : (
							<div className="p-2 b-b-light" style={{ cursor: 'pointer' }} onClick={() => subscribe(curEl)}>
								Подписаться
							</div>
						)
						)}
						<div className="p-2" style={{ cursor: 'pointer' }}>
							Уведомлять о записях {/* TODO: нужна адаптация сервиса уведомлений */}
						</div>
						{favoriteGroups && favoriteGroups.map((item) => item.groupId).includes(curEl.id) ? (
							<div className="p-2" style={{ cursor: 'pointer' }} onClick={() => removeFavourites(curEl)}>
								Удалить из избранного
							</div>
						) : (
							<div className="p-2" style={{ cursor: 'pointer' }} onClick={() => addFavourites(curEl)}>
								Сохранить в избранное
							</div>
						)}
						
						<div className="p-2" style={{ cursor: 'pointer' }}>
							Добавить в боковое меню {/* TODO: сделать функционал для добавления в боковое меню */}
						</div>
					</div>
				</Popover>
				{allGroups && allGroups.length > 0 && (
					<Pagination aria-label="Page navigation " className="pagination pagination-primary ms-3 mb-3 justify-content-end">
						<PaginationItem disabled={currentPage === 1}>
							<PaginationLink href="#" onClick={() => paginate(currentPage - 1)} aria-label="Previous">
								<span aria-hidden="true">«</span>
								<span className="sr-only">Previous</span>
							</PaginationLink>
						</PaginationItem>

						{[...Array(Math.ceil(allGroups.length / itemsPerPage)).keys()].map((number) => (
							<PaginationItem key={number} active={number + 1 === currentPage}>
								<PaginationLink onClick={() => paginate(number + 1)} href="#">
									{number + 1}
								</PaginationLink>
							</PaginationItem>
						))}

						<PaginationItem disabled={currentPage === Math.ceil(allGroups.length / itemsPerPage)}>
							<PaginationLink href="#" onClick={() => paginate(currentPage + 1)} aria-label="Next">
								<span aria-hidden="true">»</span>
								<span className="sr-only">Next</span>
							</PaginationLink>
						</PaginationItem>
					</Pagination>
				)}
			</Row>
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={sendUpdateRequest} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className=" col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="12">
							<Input className="form-control" type="tel" value={title} onChange={(e) => setTitle(e.target.value)} />
						</Col>
					</Col>
					<Col className=" col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="12">
							<Input className="form-control" type="tel" value={description} onChange={(e) => setDescripton(e.target.value)} />
						</Col>
					</Col>
					<Row>
						<Col className=" col-sm-8 mb-3">
							<Label className="col-sm-6 col-form-label ">Владелец</Label>
							<Col md="12">
								<Select isDisabled={edit} placeholder="Начните вводить..." noOptionsMessage={() => 'Нет результатов'} options={allUsersNames} value={owner} className="js-example-basic-single col-sm-12" onChange={(option) => setOwner(option)} />
							</Col>
						</Col>
						<Col className=" col-sm-4 mb-3">
							<Label className="col-sm-6 col-form-label ">Тип группы</Label>
							<Col md="12">
								<Select
									placeholder="Начните вводить..."
									noOptionsMessage={() => 'Нет результатов'}
									options={[
										{ label: 'публичная', value: 'public' },
										{ label: 'частная', value: 'private' },
									]}
									value={typeGroup}
									className="js-example-basic-single col-sm-12"
									onChange={(option) => setTypeGroup(option)}
								/>
							</Col>
						</Col>
					</Row>
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};
export default GroupClass;
