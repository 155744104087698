import { Business, Holidays, Newsletter, Notification } from '../../../../Constant';

export const taskData = [
	{
		id: 1,
		activeTab: '1',
		title: 'Все задачи',
	},
	{
		id: 2,
		activeTab: '2',
		title: 'Делаю',
	},
	{
		id: 3,
		activeTab: '3',
		title: 'Помогаю',
	},
	{
		id: 4,
		activeTab: '4',
		title: 'Поручил',
	},
	{
		id: 5,
		activeTab: '5',
		title: 'Наблюдаю',
	},
];

export const tagData = [
	{
		id: 1,
		activeTab: '9',
		title: Notification,
	},
	{
		id: 2,
		activeTab: '10',
		title: Newsletter,
	},
	{
		id: 3,
		activeTab: '11',
		title: Business,
	},
	{
		id: 4,
		activeTab: '12',
		title: Holidays,
	},
];
export const status = [
	{
		value: 'inProgress',
		label: 'В работе',
		color: 'primary',
	},
	{
		value: 'expectation',
		label: 'Ожидание',
		color: 'secondary',
	},
	{
		value: 'completed',
		label: 'Завершено',
		color: 'success',
	},
	{
		value: 'Overdue',
		label: 'Просрочено',
		color: 'danger',
	},
];

export const project = [
	{
		value: 'project1',
		label: 'проект 1',
	},
	{
		value: 'project2',
		label: 'проект 2',
	},
	{
		value: 'project3',
		label: 'проект 3',
	},
];

export const doer = [
	{
		id: 1,
		activeTab: '9',
		value: 'man1',
		label: 'человек 1',
	},
	{
		id: 2,
		activeTab: '10',
		value: 'man2',
		label: 'человек 2',
	},
	{
		id: 3,
		activeTab: '11',
		value: 'man3',
		label: 'человек 3',
	},
];
