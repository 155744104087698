import React, { Fragment, useState, useEffect, useMemo } from 'react';
import axios from 'axios';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ru';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { H4 } from '../../../../../AbstractElements';
import { TwinAuthApi } from '../../../../../api';
import CompanyRoles from './CompanyRoles';
import CompanyGroups from './CompanyGroups';
import CompanyUsers from './CompanyUsers';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const EditCompanyTab = ({companyGroupId}) => {
	const defaultValue = '3';
	const storedValue = localStorage.getItem('primaryCompanyTab');
	const initialPrimaryColorTab = storedValue ? storedValue : defaultValue;
	const [primarycolorTab, setprimarycolorTab] = useState(initialPrimaryColorTab);
    const [groupData, setGroupData] = useState();
    const { keycloak, initialized } = useKeycloak();
	const { companyData } = useCompanyContext();

	const handleCourseTab = (num) => {
		setprimarycolorTab(num);
		localStorage.setItem('primaryCompanyTab', num);
	};

    useEffect(() => {
        axios
            .get(`${TwinAuthApi}get-group-by-id/${companyGroupId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
            .then((response) => {
                setGroupData(JSON.parse(response.data));
                console.log(groupData);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    }, [companyGroupId]);

	return (
		<Fragment>
			<Nav className="nav-primary mt-3" tabs>
                {keycloak.tokenParsed.realm_access?.roles.includes('SuperAdministrator') ??
                    <NavItem>
                        <NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '1' ? 'active' : ''} onClick={() => handleCourseTab('1')}>
                            <i className="icofont icofont-contacts"></i>
                            Роли
                        </NavLink>
                    </NavItem>
                }
				<NavItem>
					<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '2' ? 'active' : ''} onClick={() => handleCourseTab('2')}>
						<i className="icofont icofont-group"></i>
						Группы
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '3' ? 'active' : ''} onClick={() => handleCourseTab('3')}>
						<i className="icofont icofont-people"></i>
						Пользователи
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={primarycolorTab}>
                {//keycloak.tokenParsed.realm_access?.roles.includes('SuperAdministrator') ??
				<TabPane className="fade show" tabId="1">
					<H4 attrH4={{ className: 'mb-2 mt-4' }}>Роли</H4>
					<div className="p-t-20 p-b-30">
                        <CompanyRoles roles={groupData && groupData.realmRoles} companyId={groupData && companyGroupId} groupData={groupData && groupData} />
                    </div>
				</TabPane>
                }
				<TabPane tabId="2">
					<H4 attrH4={{ className: 'mb-2 mt-4' }}>Группы</H4>
					<div className="p-t-20 p-b-30">
                        <CompanyGroups subgroups={groupData && groupData.subGroups} companyId={groupData && companyGroupId} groupData={groupData && groupData} />
                    </div>
				</TabPane>
				<TabPane tabId="3">
					<H4 attrH4={{ className: 'mb-2 mt-4' }}>Пользователи</H4>
					<div className="p-t-20 p-b-30">
                        <CompanyUsers companyId={groupData && companyGroupId} groupData={groupData && groupData} />
                    </div>
				</TabPane>
			</TabContent>
		</Fragment>
	);
};
export default EditCompanyTab;
