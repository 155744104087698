import React, { Fragment } from "react";
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import DataTableInteractivePlanMaps from "./DataTableInteractivePlanMaps";
import {Breadcrumbs} from "../../../../../AbstractElements";

const InteractivePlanMaps = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Список интерактивных карт" parent="Виджеты" title="Интерактивные план карты" />
            <Container fluid className="user-card">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <DataTableInteractivePlanMaps />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default InteractivePlanMaps;