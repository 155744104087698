import {Columns} from "./Columns";
import {Flex} from "./Flex";
import {VerticalSpace} from "./VerticalSpace";
import {Heading} from "./Heading";
import {RegularText} from "./RegularText";
import {BoldText} from "./BoldText";
import {Buttons} from "./Buttons";
import {ImageGallery} from "./ImageGallery";

export const PuckComponents = {
    Columns: Columns,
    Flex: Flex,
    VerticalSpace: VerticalSpace,
    Heading: Heading,
    RegularText: RegularText,
    BoldText: BoldText,
    Button: Buttons,
    ImageGallery: ImageGallery
}