import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit, Clipboard } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import axios from 'axios';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Label, Input, CardBody, Col, Media } from 'reactstrap';

import FilterComponent from './FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { H6, P } from '../../../../AbstractElements';

const TableTechnologicalCard = ({ filteredProducts }) => {
	const columns = [
		{
			name: '',
			selector: (row) => '',
			sortable: true,
			width: '2%',
		},
		{
			name: 'Название',
			selector: (row) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Clipboard size={'28px'} />
					<div className="ms-3">
						<H6>{row.name}</H6>
						<P>{row.comment}</P>
					</div>
				</div>
			),
			sortable: true,
		},

		{
			name: 'Расходники',
			selector: (row) => <div>{row.consumables && row.consumables.map((item) => <div className="mb-2">{item.name}</div>)}</div>,
			sortable: false,
		},
		{
			name: 'Количество',
			selector: (row) => (
				<div>
					{row.consumables &&
						row.consumables.map((item) => (
							<div className="mb-2">
								{item.quantity} {item.unit}{' '}
							</div>
						))}
				</div>
			),
			sortable: false,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/storage/edit-technological-card?cardId=${row.id}`}>
							<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }}>
								<Edit size={'18px'} />
							</div>
						</Link>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {});
	};

	const [edit, setEdit] = useState(null);

	const send = () => {
		// if (editorData == '' || author == '') {
		// 	toast.error('Заполните все поля!', {
		// 		position: toast.POSITION.TOP_RIGHT,
		// 	});
		// } else {
		if (!Id) {
			setId(uuid());
		}
		const newEvent = {
			// id: Id,
			// author: author,
			// date: new Date(),
			// content: editorData,
			// category: selectedCategory,
		};
		console.log(newEvent);
		// axios
		// 	.post(`/api/clientsDemandsForm.json`, newEvent)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Календарь добавлен!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
		//}
	};

	const [modal, setModal] = useState(false);
	const [name, setName] = useState('');
	const [Id, setId] = useState('');
	const [comment, setComment] = useState('');

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.name);
			setId(ObjectId.id);
			setComment(ObjectId.comment);
			setEdit(true);
		} else {
			setName('');
			setId('');
			setComment('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Комментарий</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={comment} onChange={(e) => setComment(e.target.value)} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableTechnologicalCard;
