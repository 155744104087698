const concatResults = (results) => {
    let resultData = null;

    Array.prototype.unique = function() {
        var a = this.concat();
        for(var i=0; i<a.length; ++i) {
            for(var j=i+1; j<a.length; ++j) {
                if(a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
        return a;
    };

    if (results && results.data && Array.isArray(results.data)) {
        results.data.forEach((result) => {
            if (resultData === null) {
                resultData = result.result;
            }
            else {
                let name = Object.keys(result.result)[0];
                if (Array.isArray(result.result[name])) {
                    //if (name === "projects") {
                        resultData[name] = resultData[name].concat(result.result[name]).unique();
                    //}
                }
            }
        })
    } else {
        resultData = results.data;
        if (results.data.hasOwnProperty("suggestions")) {
            return results.data.suggestions;
        } else if (Array.isArray(results)) {
            resultData = results[0].data.result
        }
    }
    return resultData;
    
}

export { concatResults };