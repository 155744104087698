import React from "react";
import styled from "styled-components";
import { Search, Plus } from "react-feather";
import { Btn } from "../../../AbstractElements";

const Input = styled.input.attrs((props) => ({
	type: "text",
	size: props.small ? 5 : undefined,
}))`
	height: 32px;
	width: 44%;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 6%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--theme-default);
	border: none;
	color: var(--bs-btn-hover-color);
`;

const FilterComponent = ({ filterText, onFilter, onClear, toggle }) => (
	<>
		<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
			<Btn attrBtn={{ color: "primary", className: "me-4", onClick: toggle }}>
				<Plus style={{ margin: "0px 2px -4px 0px" }} size={"20px"} /> Добавить
			</Btn>
			<Input style={{ flexGrow: "1" }} className="ms-4" id="search" type="text" placeholder="Поиск.." value={filterText} onChange={onFilter} />
			<ClearButton onClick={onClear}>
				<Search color="white" size={"18px"} />
			</ClearButton>
		</div>
	</>
);

export default FilterComponent;
