// LeftPanel.js
import React, { useState } from 'react';
import { Col, Label, Input } from 'reactstrap';

const ElementComment = ({ setIsOverLeftPanel }) => {
	return (
		<>
			<div
				className="col-sm-11 mb-3"
				draggable="true"
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'comment');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Label className="col-sm-9 col-form-label">Комментарий</Label>
				<Col sm="12">
					<Input className="form-control" />
				</Col>
			</div>
		</>
	);
};

export default ElementComment;
