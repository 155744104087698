import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import axios from "axios";
import { Breadcrumbs } from "../../../../AbstractElements";
import TableCalls from "./TableCalls";
import DiagramCalls from "./DiagramCalls";
import { useKeycloak } from "@react-keycloak/web";

import { TelephonyApi } from "../../../../api";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const CallStatistics = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [IconWithTab, setIconWithTab] = useState("1");

	const [data, setData] = useState([]);

	const getData = async () => {
		try {
			await axios
				.get(`${TelephonyApi}getCalls`, {
					params: { query: "" },
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					setData(concatResults(response).calls);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		} catch (error) {}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Статистика звонков" parent="Телефония" title="Статистика звонков" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Nav tabs className="border-tab nav-primary">
									<NavItem>
										<NavLink className={`${IconWithTab === "1" ? "active" : ""} f-16`} onClick={() => setIconWithTab("1")} style={{ cursor: "pointer" }}>
											{/* <Settings size={'20px'} className="me-2" style={{ marginBottom: '-3px' }} /> */}
											Таблица
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={`${IconWithTab === "2" ? "active" : ""} f-16`} onClick={() => setIconWithTab("2")} style={{ cursor: "pointer" }}>
											{/* <Image size={'20px'} className="me-2" style={{ marginBottom: '-3px' }} /> */}
											График
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent activeTab={IconWithTab}>
									<TabPane className="fade show p-t-10" tabId="1" style={{ padding: "15px" }}>
										<TableCalls products={data} />
									</TabPane>
									<TabPane tabId="2">
										<DiagramCalls />
									</TabPane>
								</TabContent>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default CallStatistics;
