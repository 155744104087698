import GroupsContext from '.';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { GroupsApi } from '../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../Company/CompanyProvider";
import { concatResults } from '../../concatResults';

const GroupsProvider = ({ children, props }) => {
    const context = useCreateGroupsContext(props);

    return (
        <GroupsContext.Provider
            value={context}
        >
            {children}
        </GroupsContext.Provider>
    );
};

export function useGroupsContext() {
    const context = useContext(GroupsContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateGroupsContext = function (props) {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();

    const [groupList, setGroupList] = useState();
    const [groupModerationList, setGroupModerationList] = useState();
    const [groupFavoriteList, setGroupFavoriteList] = useState();
    const [userSubscriptions, setUserSubscriptions] = useState();
    const [groupSubscriptions, setGroupSubscriptions] = useState();
    const [groupInfo, setGroupInfo] = useState();
    const [groupPosts, setGroupPosts] = useState();


    //get requests
    const getAllGroups = useCallback(async () => {
        groupList === undefined &&
            await axios
                .get(`${GroupsApi}get-groups`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                .then((response) => {
                    //console.log(1);
                    //console.log(response);
                    setGroupList(concatResults(response).groups);
                })
                .catch((error) => {
                    setGroupList(null);
                    console.error('Ошибка получения данных:', error);
                });
        return groupList;
    }, [groupList]);

    const getModerateGroups = useCallback(async () => {
        groupModerationList === undefined &&
            await axios
                .get(`${GroupsApi}get-user-groups-moderation/${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                .then((response) => {
                    setGroupModerationList(concatResults(response).groups);
                })
                .catch((error) => {
                    setGroupModerationList(null);
                    console.error('Ошибка получения данных:', error);
                });
        return groupModerationList;
    }, [groupModerationList]);

    const getFavoriteGroups = useCallback(async () => {
        groupFavoriteList === undefined &&
            await axios
                .get(`${GroupsApi}get-user-group-favorites/${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                .then((response) => {
                    setGroupFavoriteList(concatResults(response).favorites);
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });
        return groupFavoriteList;
    }, [groupFavoriteList]);

    const getUserSubscriptions = useCallback(async () => {
        await axios
            .get(`${GroupsApi}get-user-subscriptions/${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                console.log(response);
                setUserSubscriptions(concatResults(response).subscriptions);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
        return userSubscriptions;
    }, [userSubscriptions]);

    const getGroupSubscriptions = useCallback(async (groupId) => {
        await axios
            .get(`${GroupsApi}get-group-members/${groupId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                setGroupSubscriptions(concatResults(response).members);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
        return groupSubscriptions;
    }, [groupSubscriptions]);

    const getGroupInfo = useCallback(async (groupId) => {
        await axios
            .get(`${GroupsApi}get-group/${groupId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                //console.log(response);
                setGroupInfo(concatResults(response));
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
        return groupInfo;
    }, [groupInfo]);

    const getGroupPosts = useCallback(async (groupId) => {
        await axios
            .get(`${GroupsApi}get-group-posts/${groupId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                console.log(response);
                setGroupPosts(concatResults(response).posts);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
        return groupPosts;
    }, [groupPosts]);

    //create requests
    const addUserToGroup = async (groupId, userId) => {
        await axios
            .post(`${GroupsApi}create-group-member`, {
                groupId: groupId,
                userId: userId,
                groupRole: "member"
            }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return concatResults(response);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    };

    const createGroupPost = async (groupId, content) => {
        await axios
            .post(`${GroupsApi}create-group-post`, {
                groupId: groupId,
                content: content,
                createdAt: getDateNow(),
                authorUserId: keycloak.tokenParsed.sub
            }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return concatResults(response);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    }

    const createPostComment = async (groupPostId, content) => {
        await axios
            .post(`${GroupsApi}create-group-post-comment`, {
                groupPostId: groupPostId,
                content: content,
                createdAt: Date.now(),
                authorUserId: keycloak.tokenParsed.sub
            }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return concatResults(response);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    }

    const createGroup = async (name, description, ownerUserId, groupType, commentsAllowed = true, postsAllowed = true) => {
        await axios
            .post(`${GroupsApi}create-group`, {
                name: name,
                description: description,
                ownerUserId: ownerUserId,
                groupType: groupType,
                commentsAllowed: commentsAllowed,
                postsAllowed: postsAllowed
            }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return concatResults(response);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    };

    const addGroupToFavorites = async (userId, groupId) => {
        await axios
            .post(`${GroupsApi}create-user-favourite-group`, {
                groupId: groupId,
                userId: userId,
            }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                console.log(response);
                return concatResults(response);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    };

    //update requests
    const updateGroupUser = async (id, userId, groupId, role) => {
        await axios
            .put(`${GroupsApi}update-group-member`, {
                id: id,
                groupId: groupId,
                userId: userId,
                groupRole: role
            }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return concatResults(response);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    };

    const updateGroup = async (id, name, description, ownerUserId, groupType, commentsAllowed = true, postsAllowed = true) => {
        await axios
            .put(`${GroupsApi}update-group`, {
                id: id,
                name: name,
                description: description,
                ownerUserId: ownerUserId,
                groupType: groupType,
                commentsAllowed: commentsAllowed,
                postsAllowed: postsAllowed
            }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return concatResults(response);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    }
    
    //remove requests
    const removeUserGromGroup = async (groupId, userId) => {
        await axios
            .delete(`${GroupsApi}delete-group-member?groupId=${groupId}&userId=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return concatResults(response);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    };

    const removeGroupFromFavorites = async (userId, groupId) => {
        await axios
            .delete(`${GroupsApi}delete-user-favourite-group`, {
                groupId: groupId,
                userId: userId,
            }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return concatResults(response);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    };

    const removeLikeFromPost = async (postId, userId) => {
        await axios
            .delete(`${GroupsApi}delete-group-member?groupId=${postId}&userId=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return concatResults(response);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    };

    function getDateNow() {
        var date = new Date();
        var time = date.toISOString();

        return time;
    }

    return {
        updateGroup,
        createGroup,
        getAllGroups,
        addUserToGroup,
        createGroupPost,
        createPostComment,
        updateGroupUser,
        removeUserGromGroup,
        addGroupToFavorites,
        removeGroupFromFavorites,
        getModerateGroups,
        getFavoriteGroups,
        getUserSubscriptions,
        getGroupInfo,
        getGroupSubscriptions,
        getGroupPosts,
        removeLikeFromPost,
    };
}

export default GroupsProvider;