// LeftPanel.js
import React, { useState } from "react";
import { Col, Label, Input } from "reactstrap";

const ElementEmail = ({ setIsOverLeftPanel, content = "Email" }) => {
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [email, setEmail] = useState("");

	const handleInputChange = (e) => {
		const inputValue = e.target.value;

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isValid = emailRegex.test(inputValue);

		setEmail(inputValue);
		setIsValidEmail(isValid);
	};

	return (
		<>
			<div
				className="col-sm-11 mb-3"
				draggable="true"
				onDragStart={(e) => {
					e.dataTransfer.setData("text/plain", "email");
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Label className="col-sm-9 col-form-label">{content}</Label>
				<Col sm="12">
					<Input className="form-control" placeholder="Введите Email" value={email} onChange={handleInputChange} />
					{!isValidEmail && <div className="d-block font-danger">Пожалуйста, введите корректный email.</div>}
				</Col>
			</div>
		</>
	);
};

export default ElementEmail;
