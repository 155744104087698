import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Col, Card, CardHeader, CardBody, Collapse, Input, Label, InputGroup, InputGroupText, Row } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Select from "react-select";
import Scrollbars from "react-custom-scrollbars-2";
import { Plus } from "react-feather";

import { H5, H6, Badges, Btn, P } from "../../../AbstractElements";
import CommonModal from "../../UiKits/Modals/common/modal";
import { TwinAuthApi } from "../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";

const VerticalCanbanTasks = ({ data, statuses, type = "project", elements = [] }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const {
		handleSubmit,
		formState: { errors },
	} = useForm();

	// Группировка объектов по статусам
	const groupedData = data.reduce((acc, obj) => {
		const { status, name, id, dateEnd } = obj;
		if (!acc[status.id]) {
			acc[status.id] = [];
		}
		acc[status.id].push({ name, id, dateEnd });
		return acc;
	}, {});

	function formatDateTime(dateTimeStr) {
		const date = new Date(dateTimeStr);
		const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

		const day = date.getDate();
		const month = months[date.getMonth()];
		const hours = date.getHours();
		const minutes = date.getMinutes();

		const formattedDate = `${day} ${month} в ${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

		return formattedDate;
	}

	const [modalLead, setModalLead] = useState(false);
	const [modalTr, setModalTr] = useState(false);

	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [amount, setAmount] = useState(0);
	const [participants, setParticipants] = useState(null);
	const [company, setCompany] = useState(null);

	const toggleLead = () => {
		setModalLead(!modalLead);
		setLoading(false);
	};
	const toggleTr = () => {
		setModalTr(!modalTr);
		setLoading(false);
	};

	const sendFastLead = () => {
		// отправка на сервер
		setLoading(true);

		const newSatus = {
			//id: Id,
		};
		console.log(newSatus);

		// axios
		// 	.post(`/api/status.json`, newSatus)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Статус добавлен!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		tagCallback(false); //закрыть модалку
		//		setLoading(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		//		setLoading(false);
		// 	});
	};
	const [allUsersNames, setAllUsersNames] = useState([]);
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.username,
				}));
				setAllUsersNames(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`${TwinAuthApi}get-groups-companies`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setCompanies(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [isOpen, setIsOpen] = useState(null);
	const toggleClientCard = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

	function getTextColor(backgroundColor) {
		let r = parseInt(backgroundColor.substr(1, 2), 16);
		let g = parseInt(backgroundColor.substr(3, 2), 16);
		let b = parseInt(backgroundColor.substr(5, 2), 16);

		let brightness = (r * 333 + g * 334 + b * 333) / 1000;

		return brightness > 125 ? "#2f2f3b" : "#ffffff";
	}

	return (
		<Fragment>
			<div style={{ padding: "10px 25px 30px" }}>
				<div className="scroll-bar-wrap" style={{ position: "relative" }}>
					<Scrollbars className="vertical-scroll" style={{ width: "100%", height: 450 }} id="ContentBlockScroll">
						<Col style={{ flexWrap: "nowrap", width: "95%", marginLeft: "10px", height: "100%", flexDirection: "column", display: "flex" }}>
							{statuses.map((s, i) => (
								<Col sm="12" key={i} style={{ borderBottom: "1px solid #dddddd80" }} className="mt-4">
									<Card style={{ marginBottom: "2.5rem" }}>
										<CardHeader
											className={"border-bottom d-flex"}
											style={{ backgroundColor: s.color, color: getTextColor(s.color), padding: "20px 35px 20px", cursor: "pointer" }}
											onClick={() => toggleClientCard(s.id)}
										>
											<H5>{s.name}</H5>
											{isOpen === s.id ? <i className="fa fa-angle-up f-24"></i> : <i className="fa fa-angle-down f-24"></i>}
										</CardHeader>
										<Collapse isOpen={isOpen === s.id}>
											<CardBody className="" style={{ padding: "30px 20px" }}>
												{type === "lead" && (
													<Btn attrBtn={{ color: "light", className: "w-100 mb-4", onClick: toggleLead }}>
														<Plus size="18px" style={{ margin: "0 3px -3px 0" }} /> Быстрый лид
													</Btn>
												)}
												{type === "deals" && (
													<Btn attrBtn={{ color: "light", className: "w-100 mb-4", onClick: toggleTr }}>
														<Plus size="18px" style={{ margin: "0 3px -3px 0" }} /> Быстрая сделка
													</Btn>
												)}
												{/* <Btn attrBtn={{ color: 'light', className: 'w-100 mb-4' }}>
												<Plus size="18px" style={{ margin: '0 3px -3px 0' }} /> Быстрая задача
											</Btn> */}
												{/* быстрой задачи пока нет :( */}
												<div>
													{groupedData[s.id] ? (
														groupedData[s.id].map((obj, index) => (
															<Tooltip title="Просмотр задачи" arrow placement="top">
																<Link to={`${process.env.PUBLIC_URL}/app/task/view-task?taskId=${obj.id}`}>
																	<Card
																		id={`clickable-${obj.id}`}
																		style={{ marginBottom: "10px", border: "1px solid #dddddd80", borderLeft: `2px solid ${s.color}`, borderRadius: "8px" }}
																		key={index}
																		className="shadow-none shadow-showcase"
																	>
																		<CardBody style={{ padding: "15px" }}>
																			<Row className="d-flex">
																				<Col md="10">
																					<H6>{obj.name}</H6>
																					{obj.dateEnd ? (
																						<Badges attrBadge={{ className: "badge rounded-pill", color: "info", pill: true }}>
																							{formatDateTime(obj.dateEnd)}
																						</Badges>
																					) : (
																						<Badges attrBadge={{ className: "badge rounded-pill txt-dark", color: "light", pill: true }}>Без срока</Badges>
																					)}
																				</Col>
																				<Col md="2" className="d-flex" style={{ justifyContent: "center", flexDirection: "column", alignItems: "end" }}>
																					<i className="fa fa-bars  me-3" style={{ color: "#898989" }}></i>
																				</Col>
																			</Row>
																		</CardBody>
																	</Card>
																</Link>
															</Tooltip>
														))
													) : (
														<p>Нет задач для этого статуса</p>
													)}
												</div>
											</CardBody>
										</Collapse>
									</Card>
								</Col>
							))}
						</Col>
					</Scrollbars>
					<CommonModal isOpen={modalLead} title={"Добавить лид"} toggler={sendFastLead} togglerClose={toggleLead} closeText="Отмена" saveText="Добавить" size="lg">
						<CardBody style={{ padding: "10px", paddingLeft: "15px", paddingTop: "0" }}>
							<Col className=" col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Название</Label>
								<Col sm="12">
									<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
								</Col>
							</Col>
							<P attrPara={{ className: "mb-0 mt-4" }}>Клиент</P>
							<Col className="b-t-light border-2 col-sm-12 mb-2 pt-2">
								<div className="ms-2 row">
									<Label className="col-sm-9 col-form-label">Контакт</Label>
									<Col sm="12" className="mb-2">
										<Select
											placeholder="Начните вводить имя пользователя"
											isMulti
											noOptionsMessage={() => "Нет результатов"}
											options={allUsersNames}
											value={participants}
											onChange={(option) => setParticipants(option)}
											className="js-example-basic-single col-sm-12"
										/>
									</Col>
									{/* <span className="font-primary user-add">
										<Plus size={'12px'} /> Добавить участника
									</span> */}
									<Label className="col-sm-9 col-form-label mt-2">Компания</Label>
									<Col sm="12">
										<Select
											placeholder="Начните вводить название компании"
											noOptionsMessage={() => "Нет результатов"}
											options={companies}
											value={company}
											onChange={(option) => setCompany(option)}
											className="js-example-basic-single col-sm-12"
										/>
									</Col>
								</div>
							</Col>
						</CardBody>
					</CommonModal>
					<CommonModal isOpen={modalTr} title={"Добавить сделку"} toggler={sendFastLead} togglerClose={toggleTr} closeText="Отмена" saveText="Добавить" size="lg">
						<CardBody style={{ padding: "10px", paddingLeft: "15px", paddingTop: "0" }}>
							<Col className=" col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Название</Label>
								<Col sm="12">
									<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
								</Col>
							</Col>
							<Col className=" col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Сумма</Label>
								<Col sm="12">
									<InputGroup>
										<Input required className="form-control" type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
										<InputGroupText>{"₽"}</InputGroupText>
									</InputGroup>
								</Col>
							</Col>
							<P attrPara={{ className: "mb-0 mt-4" }}>Клиент</P>
							<Col className="b-t-light border-2 col-sm-12 mb-2 pt-2">
								<div className="ms-2 row">
									<Label className="col-sm-9 col-form-label">Контакт</Label>
									<Col sm="12" className="mb-2">
										<Select
											placeholder="Начните вводить имя пользователя"
											isMulti
											noOptionsMessage={() => "Нет результатов"}
											options={allUsersNames}
											value={participants}
											onChange={(option) => setParticipants(option)}
											className="js-example-basic-single col-sm-12"
										/>
									</Col>
									<Label className="col-sm-9 col-form-label mt-2">Компания</Label>
									<Col sm="12">
										<Select
											placeholder="Начните вводить название компании"
											noOptionsMessage={() => "Нет результатов"}
											options={companies}
											value={company}
											onChange={(option) => setCompany(option)}
											className="js-example-basic-single col-sm-12"
										/>
									</Col>
								</div>
							</Col>
						</CardBody>
					</CommonModal>
				</div>
			</div>
		</Fragment>
	);
};

export default VerticalCanbanTasks;
