import React, { Fragment, useCallback, useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { H5 } from '../../../../AbstractElements';

const ClientStatistic = ({ clientId }) => {
	const [pricesArray, setPricesArray] = useState([]);
	const [dateArray, setDateArray] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/clientsVisitHistory.json`)
			.then((response) => {
				const foundObject = response.data.find((item) => item.clientId === Number(clientId));

				const sortedData = foundObject.Orders.sort((a, b) => new Date(a.date) - new Date(b.date));

				const prices = sortedData.map((item) => item.Price);
				const dates = sortedData.map((item) => item.date);
				setPricesArray(prices);
				setDateArray(dates);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	// Функция для форматирования даты и времени
	function formatDateTime(dateTimeStr) {
		const date = new Date(dateTimeStr);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	}

	const CryptocurrencyPricesTitleChartData = {
		options: {
			chart: {
				toolbar: {
					show: false,
				},
				height: 400,
				type: 'area',
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 3,
				curve: 'smooth',
			},
			xaxis: {
				categories: dateArray.map(formatDateTime),
				tickAmount: 5,
				tickPlacement: 'between',
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				tooltip: {
					enabled: false,
				},
			},
			grid: {
				borderColor: 'rgba(196,196,196, 0.3)',
				padding: {
					top: -20,
					right: -16,
					bottom: 0,
				},
			},
			fill: {
				opacity: 0.2,
			},
			colors: ['#7568ff'],

			tooltip: {
				x: {
					format: 'dd/MM/yy HH:mm',
				},
			},
			responsive: [
				{
					breakpoint: 576,
					options: {
						chart: {
							height: 200,
						},
					},
				},
			],
		},
		series: [
			{
				name: 'Выручка',
				data: pricesArray,
			},
		],
	};

	return (
		<Fragment>
			<H5>Выручка по клиенту</H5>
			<div className="mt-4">
				<ReactApexChart type="area" height={300} series={CryptocurrencyPricesTitleChartData.series} options={CryptocurrencyPricesTitleChartData.options} />
			</div>
		</Fragment>
	);
};

export default ClientStatistic;
