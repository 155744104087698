import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Row, CardBody, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import CommonModal from "../../../UiKits/Modals/common/modal";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import {Edit, Trash2} from "react-feather";
import SweetAlert from "sweetalert2";
import {toast} from "react-toastify";
import FilterComponent from "../../Widgets/InteractivePlanMap/FilterComponent";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import Select from "react-select";
import {useCounterpartiesContext} from "../../../../_helper/Counterparties/CounterpartiesProvider";

const CounterpartyRequiredFields = () => {
    const [name, setName] = useState('');
    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [fieldsSelect, setFieldsSelect] = useState([]);
    const {keycloak} = useKeycloak();
    const {companyData} = useCompanyContext();
    const {
        counterpartyRequiredFields,
        getCounterpartyRequiredFields,
        createCounterpartyRequiredField,
        deleteCounterpartyRequiredField
    } = useCounterpartiesContext();

    useEffect(() => {
        counterpartyRequiredFields && setFilteredItems(counterpartyRequiredFields.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, counterpartyRequiredFields]);

    useEffect(() => {
        getCounterpartyRequiredFields();
        setFieldsSelect([
            {value: 'name', label: 'Название'},
            {value: 'inn', label: 'ИНН'},
            {value: 'upp', label: 'КПП'},
            {value: 'contactPerson', label: 'Основное контактное лицо'},
            {value: 'phone', label: 'Номер телефона'},
            {value: 'email', label: 'Email'},
            {value: 'address', label: 'Адрес'},
            {value: 'comment', label: 'Комментарий'},
        ])
    }, [keycloak.tokenParsed, companyData]);

    const toggle = () => {
        setModal(!modal);
    }

    const deleteRow = (fieldId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'После удаления вы не сможете восстановить этот объект!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                deleteCounterpartyRequiredField(fieldId).then(() => {
                    toast.success('Поле удалено!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                })
            }
        });
    };

    const handleSendRequest = () => {
        createCounterpartyRequiredField(name).then(() => {
            setName('');
            toast.success('Поле добавлено', {
                position: toast.POSITION.TOP_RIGHT,
            });
        });

        toggle();
    };

    const tableColumns = [
        {
            name: 'Название',
            selector: (row) => `${fieldsSelect.find((item) => item.value === row?.fieldName)?.label ?? row?.fieldName}`,
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{display: 'flex'}}>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => deleteRow(row?.id)}>
                            <Trash2 size={'18px'}/>
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={() => toggle(null)} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление обязательного для заполнения поля'} toggler={handleSendRequest} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Название поля
                                </Label>
                                <Col sm="11">
                                    <Select
                                        placeholder="Не выбрано"
                                        noOptionsMessage={() => 'Нет результатов'}
                                        options={fieldsSelect}
                                        onChange={(e) => setName(e?.value)}
                                        className="js-example-basic-single col-sm-12"
                                    />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default CounterpartyRequiredFields;