import React, { Fragment, useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import { ReminderApi, CompaniesApi } from "../../../../api";
import FilterComponent from "../../Categories/FilterComponent";
import { useKeycloak } from "@react-keycloak/web";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import Select from "react-select";
import {concatResults} from "../../../../concatResults";

const DataTableReminders = (eventTypes) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [data, setData] = useState();

	const [modal, setModal] = useState(false);

	const [companyUsers, setCompanyUsers] = useState([]);

	const [optionsUsers, setOptionsUsers] = useState();
	const [optionsTypes, setOptionsTypes] = useState();

	const [eventTypeId, setEventTypeId] = useState("");
	const [reminderText, setReminderText] = useState("");
	const [userId, setUserId] = useState("");

	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [filteredItems, setFilteredItems] = useState(data);

	useEffect(() => {
		if (Array.isArray(eventTypes.eventTypes)) {
			setOptionsTypes(
				eventTypes.eventTypes.map((item) => ({
					value: item.id,
					label: item.name,
				}))
			);
		}
	}, [eventTypes]);

	useEffect(() => {
		console.log(companyData);
		companyData &&
			axios
				.get(`${ReminderApi}get-reminders/${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					//console.log(response);
					setData(concatResults(response).reminders);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		companyData &&
			axios
				.get(`${CompaniesApi}get-company-users?companyId=${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					console.log(response);
					setCompanyUsers(JSON.parse(response.data));
					setOptionsUsers(
						JSON.parse(response.data).map((item) => ({
							value: item.id,
							label: `${item.firstName} ${item.lastName}`,
						}))
					);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
	}, [companyData]);

	useEffect(() => {
		data && setFilteredItems(data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
	}, [filterText, setFilterText]);

	useEffect(() => {
		data && setFilteredItems(data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
	}, [data, setData]);

	const toggle = () => {
		setEventTypeId("");
		setReminderText("");
		setUserId("");

		setModal(!modal);
	};

	const getCompanyUser = (userId) => {
		let user = companyUsers.find((u) => u.id === userId);

		if (user) {
			return `${user.firstName} ${user.lastName}`;
		}
	};

	const getEventType = (eventId) => {
		return eventTypes.eventTypes.find((e) => e.id === eventId).name;
	};

	const handleAddRow = () => {
		const newRow = {
			companyId: companyData[0]?.id,
			eventType: eventTypeId,
			reminderText: reminderText,
			userId: userId,
		};
		axios
			.post(`${ReminderApi}create-company-reminder`, newRow, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				toast.success("Уведомление создано!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setModal(!modal);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const deleteRow = (remId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "Данное действие не отменит отправку оповщений!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			axios
				.delete(`${ReminderApi}delete-company-reminder/${remId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then(() => {})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		});
	};

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const tableColumns = [
		{
			name: "Тип уведомления",
			selector: (row) => `${getEventType(row.eventType)}`,
			sortable: true,
			center: true,
		},
		{
			name: "Текст уведомления",
			selector: (row) => `${row.reminderText}`,
			sortable: true,
			center: false,
			width: "40%",
		},
		{
			name: "Пользователь",
			selector: (row) => `${getCompanyUser(row.userId) ?? "Все"}`,
			sortable: true,
			center: false,
			width: "20%",
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "7px", cursor: "pointer" }} onClick={() => deleteRow(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	return (
		<Fragment>
			<CommonModal isOpen={modal} title={"Сделать уведомление"} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
				<CardBody style={{ padding: "10px", paddingLeft: "15px" }}>
					<Form className="form theme-form">
						<Row>
							<FormGroup className="row" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label">Пользователь, которому будет отправлено уведомление</Label>
								<Col sm="11">
									<Select
										placeholder="Не выбрано"
										noOptionsMessage={() => "Нет результатов"}
										options={optionsUsers}
										onChange={(e) => setUserId(e.value)}
										className="js-example-basic-single col-sm-12"
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
									Тип уведомления
								</Label>
								<Col sm="11">
									<Select
										placeholder="Не выбрано"
										noOptionsMessage={() => "Нет результатов"}
										options={optionsTypes}
										onChange={(e) => setEventTypeId(e.value)}
										className="js-example-basic-single col-sm-12"
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label">Текст уведомления</Label>
								<Col sm="11">
									<Input className="form-control" value={reminderText} placeholder="Текст" onChange={(e) => setReminderText(e.target.value)} />
								</Col>
							</FormGroup>
						</Row>
					</Form>
				</CardBody>
			</CommonModal>
			<DataTable
				data={filteredItems}
				columns={tableColumns}
				striped={true}
				center={true}
				pagination
				highlightOnHover
				pointerOnHover
				subHeader
				subHeaderComponent={subHeaderComponent}
				noDataComponent={"В данной таблице ещё нет записей."}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
		</Fragment>
	);
};

export default DataTableReminders;
