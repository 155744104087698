import React, { useState } from 'react';
import CusClass from '../Common/CusClass';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const CardList = ({ cards, setData, setLoading, statuses, users, displayAsCards}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const cardsPerPage = 6;

	const indexOfLastCard = currentPage * cardsPerPage;
	const indexOfFirstCard = indexOfLastCard - cardsPerPage;
	const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);

	const renderCards = currentCards.map((item, i) => <CusClass statuses={statuses} item={item} key={i} setData={setData} setLoading={setLoading} displayAsCards={displayAsCards} />);

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(cards.length / cardsPerPage); i++) {
		pageNumbers.push(i);
	}

	const renderPageNumbers = pageNumbers.map((number) => (
		<PaginationItem key={number} active={currentPage === number ? true : false}>
			<PaginationLink href="#javascript" onClick={() => setCurrentPage(number)}>
				{number}
			</PaginationLink>
		</PaginationItem>
	));

	return (
		<div>
			<div className="card-list" style={displayAsCards ? { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '30px' } : {}}>
				{renderCards}
			</div>

			<Pagination aria-label="Page navigation example" className="pagination justify-content-end pagination-primary">
				<PaginationItem>
					<PaginationLink>
						<span aria-hidden="true">«</span>
					</PaginationLink>
				</PaginationItem>
				{renderPageNumbers}
				<PaginationItem>
					<PaginationLink>
						<span aria-hidden="true">»</span>
					</PaginationLink>
				</PaginationItem>
			</Pagination>
		</div>
	);
};

export default CardList;
