import React, { memo, useState, useEffect, useContext } from "react";
import { CardBody, Col, Label } from "reactstrap";
import { Handle, Position, MarkerType } from "reactflow";
import Select from "react-select";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";

import { Btn, H6 } from "../../../../AbstractElements";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { Plus, Trash2 } from "react-feather";
import TableEmployees from "./TableEmployees";
import DnDContext from "../../../../_helper/DnD";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

function CustomNodeDepartment({ data }) {
	const { toggle, setNodes, setEdges, lastNodePosition, setLastNodePosition, departments, setDepartments, getDepartments } = useContext(DnDContext);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [modalAdd, setModalAdd] = useState(false);

	const toggleAdd = () => {
		setModalAdd(!modalAdd);
	};

	const [selectDepartment, setSelectDepartment] = useState(null);
	const [allDepartments, setAllDepartments] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/departments.json`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				const selectOptions = response.data.departments.map((option) => ({
					label: option.name,
					value: option.id,
				}));
				selectOptions.push({ label: "Без отдела", value: null });
				setAllDepartments(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

			getDepartments();
	}, []);

	// добавление подотдела
	const addNode = () => {
		const newNode = {
			id: selectDepartment.value,
			type: "department",
			data: { name: selectDepartment.label, head: "test" },
			position: { x: lastNodePosition.x, y: lastNodePosition.y + 200 },
		};
		setLastNodePosition({ x: lastNodePosition.x, y: lastNodePosition.y + 200 });
		setNodes((nds) => nds.concat(newNode));

		if (selectDepartment) {
			const newEdge = {
				id: `${selectDepartment.value}-${data.id}`,
				source: data.id,
				target: selectDepartment.value,
				type: "smoothstep",
				markerEnd: {
					type: MarkerType.ArrowClosed,
					width: 20,
					height: 20,
				},
			};
			setEdges((eds) => [...eds, newEdge]);
		}
		setModalAdd(false);

		const newDepartment = {
			id: selectDepartment.value,
			name: data.name,
			description: data.description,
			head: { name: data.head.name, id: data.head.id },
			assistants: [],
			parent_department: data.id,
		};
		// axios
		// 	.put(`${CmsApi}createSite`, newEvent, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 	.then((response) => {
		// 		toast.success("Сайт добавлен!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setEditModal(false);
		// 	})
		// 	.catch((error) => {
		// 		toast.error("Ошибка при добавлении", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 	});
	};

	function isIdInParentDepartment(id) {
		for (let i = 0; i < departments.length; i++) {
			if (departments[i].parent_department === id) {
				return true;
			}
		}
		return false;
	}

	const [modalEmp, setModalEmp] = useState(false);

	const toggleEmp = () => {
		setModalEmp(!modalEmp);
	};

	const deleteDepartment = async (Id) => {
		if (isIdInParentDepartment(Id)) {
			const result = await SweetAlert.fire({
				title: "Отдел содержит подотделы",
				text: "После удаления вы не сможете их восстановить",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "ОК",
				cancelButtonText: "Отмена",
				reverseButtons: true,
			});

			if (result.value) {
				if (data.assistants && data.assistants.length) {
					setModalEmp(true);
				}
				await deleteRequest(Id);
			}
		} else {
			if (data.assistants && data.assistants.length) {
				setModalEmp(true);
			}
			await deleteRequest(Id);
		}
	};

	// Удаление отдела
	const deleteRequest = async (Id) => {
		try {
			const response = await axios.delete(`/api/clientsList.json/${Id}`);
			SweetAlert.fire("Удалено!", "Отдел был удален.", "success");
			setLastNodePosition({ x: lastNodePosition.x, y: lastNodePosition.y - 200 });
			const updatedDepartments = departments.filter((department) => department.id !== Id);
			setDepartments(updatedDepartments);
		} catch (error) {
			toast.error("Произошла ошибка при удалении объекта", {
				position: toast.POSITION.TOP_RIGHT,
			});
			console.error("Произошла ошибка при удалении объекта:", error);
		}
	};

	return (
		<div>
			<Handle type="target" position={Position.Top} className="w-16 !bg-teal-500" />
			<div className="d-flex" style={{ justifyContent: "space-evenly" }}>
				<div onClick={() => toggle(data)}>
					<H6 attrH6={{ className: "text-highlight " }}>{data.name}</H6>
				</div>
				<Trash2 onClick={() => deleteDepartment(data.id)} size={"18px"} style={{ marginBottom: "-3px" }} className="text-highlight-danger" />
			</div>
			<hr />
			<div className="d-flex mb-2" style={{ alignItems: "center" }}>
				<img src="https://dummyimage.com/800x800" className="rounded-circle me-2" style={{ height: "35px", width: "35px" }} />
				<p className="f-12">{data.head && `${data.head.label}`}</p>
			</div>
			{data.assistants && !!data.assistants.length && (
				<>
					<span className="f-w-600 font-primary d-block " style={{ fontSize: "10px", width: "100%", textAlign: "left" }}>
						Сотрудники
					</span>
					<div className="mb-2 mt-2" style={{ textAlign: "left" }}>
						{data.assistants.map((item) => (
							<img src="https://dummyimage.com/800x800" className="rounded-circle " style={{ height: "35px", width: "35px", marginRight: "-5px" }} />
						))}
					</div>
				</>
			)}
			<Btn attrBtn={{ color: "primary", onClick: toggleAdd }}>
				<Plus size={"16px"} style={{ marginBottom: "-3px", marginRight: "2px" }} />
				Добавить подотдел
			</Btn>
			<CommonModal isOpen={modalAdd} title={"Добавить подотдел"} toggler={addNode} closeText="Отмена" saveText="Добавить" togglerClose={toggleAdd}>
				<CardBody style={{ padding: "15px 25px" }}>
					<Col className="mb-3">
						<Label className="col-sm-9 col-form-label">Выберите подотдел</Label>
						<Col sm="12">
							<Select
								placeholder="Выберите..."
								noOptionsMessage={() => "Нет результатов"}
								options={allDepartments}
								value={selectDepartment}
								onChange={(option) => setSelectDepartment(option)}
								className="col-sm-12"
							/>
						</Col>
					</Col>
					<span
						className="font-primary user-add"
						onClick={() => {
							toggle();
							setModalAdd(false);
						}}
					>
						<Plus size={"12px"} /> Добавить новый отдел
					</span>
				</CardBody>
			</CommonModal>
			<CommonModal isOpen={modalEmp} title={"Перенести сотрудников"} toggler={() => deleteRequest(data.id)} closeText="Отмена" saveText="Сохранить" size="xl" togglerClose={toggleEmp}>
				<CardBody style={{ padding: "15px 25px" }}>
					<TableEmployees data={data.assistants} departments={allDepartments} />
				</CardBody>
			</CommonModal>
			<Handle type="source" position={Position.Bottom} className="w-16 !bg-teal-500" />
		</div>
	);
}

export default memo(CustomNodeDepartment);
