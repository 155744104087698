import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { Label, Input, CardBody, Col, InputGroup, InputGroupText } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

import { DeliveryApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const TableResources = ({ deliveryServiceId }) => {
	var theFu;
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const [source, setSource] = useState([]);
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			axios
				.get(`${DeliveryApi}getDeliveryServices`, { params: { query: `${deliveryServiceId}` }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
				.then((response) => {
					const service = concatResults(response).deliveryServices[0];
					setSource(service);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};
	const columns = [
		{
			name: '',
			selector: (row) => '',
			sortable: true,
			width: '1%',
		},
		{
			name: 'Город',
			selector: (row) => `${row.city}`,
			sortable: true,
		},

		{
			name: 'Стоимость',
			selector: (row) => `${row.cost} ₽`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteDeliveryCity(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteDeliveryCity = (id) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${DeliveryApi}deleteDeliveryCity`, {
						data: {
							Id: id
						}, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
					})
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						fetchData();
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении:', error);
					});
			}

		});
	};

	const [edit, setEdit] = useState(null);

	const createDeliveryCity = () => {
		if (city == '' || cost == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {

			const newCity = {
				id: uuid(),
				city: city,
				cost: cost,
				deliveryServiceId: deliveryServiceId
			};

			axios
				.post(`${DeliveryApi}createDeliveryCity`, newCity, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Новый город прикреплен к данной службе!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});

		}
	}

	const updateDeliveryCity = () => {

		if (city == '' || cost == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {

			const updateCity = {
				id: Id,
				city: city,
				cost: cost,
				deliveryServiceId: deliveryServiceId
			};

			axios
				.put(`${DeliveryApi}updateDeliveryCity`, updateCity, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Объект обновлен!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(!modal);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});

		}
	}

	const [modal, setModal] = useState(false);
	const [city, setCity] = useState('');
	const [Id, setId] = useState('');
	const [cost, setCost] = useState('');

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setCity(ObjectId.city);
			setId(ObjectId.id);
			setCost(ObjectId.cost);
			setEdit(true);
		} else {
			setCity('');
			setId('');
			setCost('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = (source.cities !== theFu) ? source.cities.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1) : [];

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={edit ? updateDeliveryCity : createDeliveryCity} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Город</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={city} onChange={(e) => setCity(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Стоимость</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="number" value={cost} onChange={(e) => setCost(e.target.value)} />
								<InputGroupText>{'₽'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableResources;
