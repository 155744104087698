import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import axios from 'axios';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import EmailServers from './EmailServers';
import TableEmailAdressList from './TableEmailAdressList';
import { EmailsApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const MailSettings = () => {
	const [IconWithTab, setIconWithTab] = useState('1');

	const [emailServersInbox, setEmailServersInbox] = useState([]);
	const [emailServersOutgoing, setEmailServersOutgoing] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const responseIncoming = await axios.get(`${EmailsApi}getServers`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { query: "incoming" } })
			const incomingData = concatResults(responseIncoming).servers.map(server => ({
				id: server.id,
				server_address: server.address,
				port: server.port,
				logo: server.logo,
				secureConnection: server.useSSL,
				type: "inbox"
			}));
			setEmailServersInbox(incomingData);

			const responseOutgoing = await axios.get(`${EmailsApi}getServers`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { query: "outgoing" } })
			const outgoingData = concatResults(responseOutgoing).servers.map(server => ({
				id: server.id,
				server_address: server.address,
				port: server.port,
				logo: server.logo,
				secureConnection: server.useSSL,
				type: "outgoing"
			}));
			setEmailServersOutgoing(outgoingData);
		}
		catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	const [serverId, setServerId] = useState(null);
	const [currentServer, setServer] = useState(null);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Настройки почты" parent="Почта" title="Настройки почты" />
			<Container fluid={true}>
				<div className="email-wrap">
					<Row>
						<Card>
							<CardBody>
								<Row>
									<Col md="3">
										<Nav tabs className=" nav-primary">
											<NavItem>
												<NavLink className={`${IconWithTab === '1' ? 'active' : ''} `} onClick={() => setIconWithTab('1')} style={{ cursor: 'pointer' }}>
													Входящие
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={`${IconWithTab === '2' ? 'active' : ''} `} onClick={() => setIconWithTab('2')} style={{ cursor: 'pointer' }}>
													Исходящие
												</NavLink>
											</NavItem>
										</Nav>
										<TabContent activeTab={IconWithTab}>
											<TabPane className="fade show mt-4" tabId="1">
												<EmailServers setServerId={setServerId} setServer={setServer} emailServers={emailServersInbox} type="inbox" />
											</TabPane>
											<TabPane tabId="2" className="mt-4">
												<EmailServers setServerId={setServerId} setServer={setServer} emailServers={emailServersOutgoing} type="outgoing" />
											</TabPane>
										</TabContent>
									</Col>
									<Col md="9">{serverId ? <TableEmailAdressList serverId={serverId} server={currentServer} /> : <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>Выберите сервер для получения информации о подключенных почтовых ящиках</div>}</Col>
								</Row>
							</CardBody>
						</Card>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};
export default MailSettings;
