import {Columns} from "./Columns";
import {Flex} from "./Flex";
import {VerticalSpace} from "./VerticalSpace";
import {Heading} from "./Heading";
import {RegularText} from "./RegularText";
import {BoldText} from "./BoldText";
import {InputCheckbox} from "./InputCheckbox";
import {InputDate} from "./InputDate";
import {InputEmail} from "./InputEmail";
import {InputPhone} from "./InputPhone";
import {InputPrepayment} from "./InputPrepayment";
import {InputSelect} from "./InputSelect";
import {InputText} from "./InputText";
import {InputTextArea} from "./InputTextArea";
import {InputTime} from "./InputTime";
import {InputNights} from "./InputNights";
import {BlockWithHeader} from "./BlockWithHeader";
import {Tabs} from "./Tabs";
import {Table} from "./Table";

export const PuckComponents = {
    Columns: Columns,
    Flex: Flex,
    VerticalSpace: VerticalSpace,
    Heading: Heading,
    RegularText: RegularText,
    BoldText: BoldText,
    InputCheckbox: InputCheckbox,
    InputDate: InputDate,
    InputEmail: InputEmail,
    InputPhone: InputPhone,
    InputPrepayment: InputPrepayment,
    InputSelect: InputSelect,
    InputText: InputText,
    InputTextArea: InputTextArea,
    InputTime: InputTime,
    InputNights: InputNights,
    BlockWithHeader: BlockWithHeader,
    Tabs: Tabs,
    Table: Table,
    //Button: Buttons,
    //ImageGallery: ImageGallery
}