import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Plus, Search, Trash2 } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import SweetAlert from "sweetalert2";
import { Column } from "primereact/column";
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/lara-light-cyan/theme.css";

import CallerNumberFilter from "../../Telephony/CallStatistics/CallerNumberFilter";
import { Btn } from "../../../../AbstractElements";
import { concatResults } from "../../../../concatResults";

const TableQualifications = ({ toggle, products, units }) => {
	const [filters, setFilters] = useState(null);

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters["global"] = { value: value, matchMode: "contains" };

		setFilters(_filters);
		setGlobalFilterValue(value);
	};
	const [globalFilterValue, setGlobalFilterValue] = useState("");

	const renderHeader = () => {
		return (
			<Row>
				<Col md="2" sm="3">
					<Btn attrBtn={{ color: "primary", onClick: toggle }}>
						<Plus size={"18px"} style={{ margin: "0 3px -3px 0" }} />
						Добавить
					</Btn>
				</Col>
				<Col md="10" sm="9">
					<InputGroup>
						<Input className="form-control" type="text" value={globalFilterValue} onChange={onGlobalFilterChange} />
						<InputGroupText className="bg-primary">
							<Search size={"20px"} />
						</InputGroupText>
					</InputGroup>
				</Col>
			</Row>
		);
	};
	const header = renderHeader();

	const handleCallStartFilterChange = (value, name) => {
		let _filters = { ...filters };
		_filters[name] = { value: value, matchMode: "contains" };
		setFilters(_filters);
	};

	const qualificationPeriod = (product) => {
		return `${product.qualification_period} ${getQualificationName(product.qualification_period_unit)}`;
	};

	const isPositiveInteger = (val) => {
		let str = String(val);

		str = str.trim();

		if (!str) {
			return false;
		}

		str = str.replace(/^0+/, "") || "0";
		let n = Math.floor(Number(str));

		return n !== Infinity && String(n) === str && n >= 0;
	};

	const priceEditor = (options) => {
		return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} suffix=" руб/час" onKeyDown={(e) => e.stopPropagation()} />;
	};

	const textEditor = (options) => {
		return <InputText value={options.value} onChange={(e) => options.editorCallback(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
	};

	const periodEditor = (options) => {
		return (
			<InputNumber
				value={options.value}
				onValueChange={(e) => options.editorCallback(e.value)}
				suffix={" " + getQualificationName(options.rowData.qualification_period_unit)}
				onKeyDown={(e) => e.stopPropagation()}
			/>
		);
	};

	const getQualificationName = (unit,) => {
		console.log(unit)
		return unit.text;
	};

	const onCellEditComplete = async (e, type = "text") => {
		let { rowData, newValue, field, originalEvent: event } = e;
		console.log(e);
		if (type === "number" && isPositiveInteger(newValue)) {
			rowData[field] = newValue;
			console.log(rowData);
			await sendDataToServer(rowData);
		} else if (type === "text") {
			rowData[field] = newValue;
			console.log(rowData);
			await sendDataToServer(rowData);
		} else {
			event.preventDefault();
		}
	};

	//Редактирование записи
	const sendDataToServer = async (data) => {
		// try {
		// 	const response = await axios.put("your_server_endpoint", data);
		// 	console.log(concatResults(response));
		// } catch (error) {
		// 	console.error("Error sending data to server:", error);
		// }
	};

	//Удаление данных о квалификации
	const deleteRow = (bonusId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then(function (isConfirm) {
			if (isConfirm) {

			}
		});
	};

	return (
		<>
			<DataTable
				removableSort
				filters={filters}
				onFilter={(e) => setFilters(e.filters)}
				filterDisplay="row"
				header={header}
				globalFilterFields={["number", "callStart", "callerPhone", "receiverPhone", "duration", "conversation"]}
				size="large"
				value={products}
				tableStyle={{ minWidth: "50rem" }}
				scrollable
				paginator
				rows={5}
				rowsPerPageOptions={[5, 10, 25, 50]}
			>
				<Column
					sortable
					field="label"
					header="Квалификация"
					editor={(options) => textEditor(options)}
					onCellEditComplete={(e) => onCellEditComplete(e, "text")}
					filter
					filterElement={<CallerNumberFilter name="label" filterValue={filters && filters["label"] ? filters["label"].value : ""} onFilterChange={handleCallStartFilterChange} />}
				/>
				<Column
					sortable
					field="rate"
					header="Базовая ставка"
					editor={(options) => priceEditor(options)}
					onCellEditComplete={(e) => onCellEditComplete(e, "number")}
					body={(rowData) => `${rowData.rate} руб/час`}
					filter
					filterElement={<CallerNumberFilter name="rate" filterValue={filters && filters["rate"] ? filters["rate"].value : ""} onFilterChange={handleCallStartFilterChange} />}
				/>
				<Column
					sortable
					field="qualification_period"
					header="Период повышения квалификации"
					filter
					filterElement={
						<CallerNumberFilter
							name="formationPeriod"
							filterValue={filters && filters["formationPeriod"] ? filters["formationPeriod"].value : ""}
							onFilterChange={handleCallStartFilterChange}
						/>
					}
					editor={(options) => periodEditor(options)}
					onCellEditComplete={onCellEditComplete}
					body={qualificationPeriod}
				/>
				<Column
					field=""
					header=""
					body={(rowData) => (
						<Tooltip title="Удалить" arrow placement="top">
							<Trash2 className="font-primary c-pointer" size={"20px"} onClick={() => deleteRow(rowData.id)} />
						</Tooltip>
					)}
				/>
			</DataTable>
		</>
	);
};

export default TableQualifications;
