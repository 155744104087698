import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { CardBody, Col, Form, FormGroup, Input, Label, Row, Button } from 'reactstrap';
import FilterComponent from './FilterComponent';
import { useKeycloak } from "@react-keycloak/web";
import { useInteractivePlanMapsContext } from "../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCompanyContext } from "../../../../../_helper/Company/CompanyProvider";
import { SettingsApplications, SettingsApplicationsRounded } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Trash2 } from "react-feather";

const DataTablePlanMapObjectCharacteristicsValues = () => {
    const { keycloak } = useKeycloak();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const objectId = queryParams.get('objectId');

    const navigate = useNavigate();

    const [value, setValue] = useState('');
    const [valueImages, setValueImages] = useState([]);
    const [number, setNumber] = useState('');
    const [charValueId, setCharValueId] = useState('');

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [objectCharacteristicId, setObjectCharacteristicId] = useState("");
    const [objectTypeCharacteristic, setObjectTypeCharacteristic] = useState("");
    const [parsedJson, setParsedJson] = useState({});

    const {
        createInteractivePlanMapsObjectsCharacteristicValue,
        updateInteractivePlanMapsObjectsCharacteristicValue,
        getInteractivePlanMapsObjectsTypesCharacteristics,
        planMapsObjectsTypesCharacteristics,
        getInteractivePlanMapsObjectsCharacteristicsValues,
        planMapsObjectsCharacteristicsValues,
        getInteractivePlanMapsObjectsType,
        planMapsObjectsType,
        getInteractivePlanMapsObject,
        planMapsObject,
        deleteInteractivePlanMapsObjectsCharacteristic
    } = useInteractivePlanMapsContext();

    const { companyData } = useCompanyContext();

    useEffect(() => {
        planMapsObjectsTypesCharacteristics && setFilteredItems(planMapsObjectsTypesCharacteristics.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, planMapsObjectsTypesCharacteristics]);

    useEffect(() => {
        objectId && getInteractivePlanMapsObjectsCharacteristicsValues(objectId);
        objectId && getInteractivePlanMapsObject(objectId);
    }, [keycloak.tokenParsed, objectId, companyData]);

    const readUrl = (event) => {
        if (event.target.files.length === 0) return;
        let mimeType = event.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        let reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            createInteractivePlanMapsObjectsCharacteristicValue(objectId, objectCharacteristicId, reader.result);
            //setValue(reader.result);
            //onChange(reader.result);
        };
    };

    useEffect(() => {
        planMapsObjectsType && planMapsObjectsType?.cardJson && setParsedJson(JSON.parse(planMapsObjectsType?.cardJson));
    }, [planMapsObjectsType])

    useEffect(() => {
        planMapsObject && getInteractivePlanMapsObjectsTypesCharacteristics(planMapsObject.objectTypeId, objectId);
        planMapsObject && getInteractivePlanMapsObjectsType(planMapsObject.objectTypeId);
    }, [planMapsObject])

    const toggle = (id) => {
        setObjectCharacteristicId(id);
        setValue('');
        if (id) {
            let obj = getObject(parsedJson.content, id);
            let objZones = getObject(parsedJson.zones, id);

            if (obj) {
                setObjectTypeCharacteristic(obj);
                if (obj.type === 'ImageGallery') {
                    let images = planMapsObjectsCharacteristicsValues.filter((item) => item.objectTypeCharacteristicId === id);
                    setValueImages(images);
                } else {
                    let findType = planMapsObjectsCharacteristicsValues?.find((item) => item.objectTypeCharacteristicId === id);
                    if (findType) {
                        setCharValueId(findType.id);
                        setValue(findType.characteristicValue);
                    }
                }
            } else if (objZones) {
                setObjectTypeCharacteristic(objZones)
                if (objZones.type === 'ImageGallery') {
                    let images = planMapsObjectsCharacteristicsValues.filter((item) => item.objectTypeCharacteristicId === id);
                    setValueImages(images);
                } else {
                    let findType = planMapsObjectsCharacteristicsValues?.find((item) => item.objectTypeCharacteristicId === id);
                    if (findType) {
                        setCharValueId(findType.id);
                        setValue(findType.characteristicValue);
                    }
                }
            }
        }
        setModal(!modal);
    };

    const handleAddRow = () => {
        if (value !== '') {
            if (charValueId) {
                updateInteractivePlanMapsObjectsCharacteristicValue(charValueId, objectId, objectCharacteristicId, value);
            } else {
                createInteractivePlanMapsObjectsCharacteristicValue(objectId, objectCharacteristicId, value);
            }
            setModal(!modal);
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const deleteValue = (id) => {
        deleteInteractivePlanMapsObjectsCharacteristic(id);
    }

    function getObject(theObject, id) {
        let result = null;

        if (Array.isArray(theObject)) {
            theObject?.forEach((content) => {
                if (content.props?.ImapsData?.Characteristic.id === id) {
                    result = content;
                }
            })
        } else {
            for (const [key, value] of Object.entries(theObject)) {
                value.forEach((content) => {
                    if (content.props?.ImapsData?.Characteristic.id === id) {
                        result = content;
                    }
                })
            }
        }

        return result;
    }

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} planMapsObjectNumber={planMapsObject && planMapsObject.number} />;
    }, [filterText, resetPaginationToggle, planMapsObject]);

    const checkExistValue = (id) => {
        let exist = false;
        if (planMapsObjectsCharacteristicsValues) {
            let findType = planMapsObjectsCharacteristicsValues.find((item) => item.objectTypeCharacteristicId === id);

            if (findType) {
                exist = true;
            }
        }
        return exist;
    };

    const tableColumns = [
        {
            name: 'Название',
            selector: (row) => <span>{row.name}</span>,
            sortable: true,
            center: false,
        },
        {
            name: 'Значение',
            selector: (row) => {
                const value = row.characteristcValue && row.characteristcValue.characteristicValue;
                const truncatedValue = value && value.length > 100 ? value.slice(0, 100) + '...' : value;
                return <span>{truncatedValue}</span>;
            },
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (<div style={{ display: 'flex' }}>
                {checkExistValue(row.id) ?
                    <Tooltip title="Редактировать свойство" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }}
                            onClick={() => toggle(row.id)}>
                            <SettingsApplications size={'18px'} />
                        </div>
                    </Tooltip>
                    :
                    <Tooltip title="Добавить свойство" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }}
                            onClick={() => toggle(row.id)}>
                            <AddIcon size={'18px'} />
                        </div>
                    </Tooltip>
                }
            </div>)
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Изменение значения характеристики'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                {
                                    objectTypeCharacteristic && objectTypeCharacteristic.type === "ImageGallery" ?
                                        <Fragment>
                                            {
                                                Array.isArray(valueImages) && valueImages.map((item) => {
                                                    return (
                                                        <div key={item.id} style={{
                                                            marginBottom: '10px',
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <img src={item.characteristicValue} alt="Изображение"
                                                                style={{ maxWidth: '150px', maxHeight: '150px' }} />
                                                            <Tooltip title="Удалить изображение" arrow placement="top">
                                                                <div className="font-primary"
                                                                    style={{ marginLeft: '7px', cursor: 'pointer' }}
                                                                    onClick={() => deleteValue(item.id)}>
                                                                    <Trash2 size={'18px'} />
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <label htmlFor="formFile" className="form-label">Выберите картинку</label>
                                            <input className="form-control" type="file" id="formFile"
                                                onChange={(e) => readUrl(e)} />
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <Label className="col-sm-9 col-form-label"
                                                htmlFor="exampleFormControlInput1">
                                                Значение
                                            </Label>
                                            <Col sm="11">
                                                <Input
                                                    className="form-control"
                                                    value={value}
                                                    placeholder="Значение"
                                                    onChange={(e) => setValue(e.target.value)} />
                                            </Col>
                                        </Fragment>
                                }

                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTablePlanMapObjectCharacteristicsValues;
