import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { Breadcrumbs } from '../../../AbstractElements';
import { Label, Col } from 'reactstrap';
import TableCategories from './TableCategories';

const Categories = () => {

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список категорий" parent="Предложения" title="Список категорий" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<TableCategories />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default Categories;
