import React, { Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import CustomContext from "../../_helper/Customizer";
import MENUITEMS from "./Menu";
import InputMask from "react-input-mask";
import { useLocation, useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import SidebarIcon from "./SidebarIcon";
import SidebarLogo from "./SidebarLogo";
import SidebarMenu from "./SidebarMenu";
import SidebarPhoneWidget from "./SidebarPhoneWidget";
import { Btn } from "../../AbstractElements";
import { PhoneCall, PhoneOff } from "react-feather";
import {useKeycloak} from "@react-keycloak/web";

const Sidebar = (props) => {
	const customizer = useContext(CustomContext);
	const { toggleIcon } = useContext(CustomContext);
	const id = window.location.pathname.split("/").pop();
	const defaultLayout = Object.keys(customizer.layout);
	const {keycloak} = useKeycloak();
	const [roles, setRoles] = useState(
		keycloak.tokenParsed ? keycloak.tokenParsed.realm_access?.roles : []
	);
	const layout = id ? id : defaultLayout;
	// eslint-disable-next-line
	const [mainmenu, setMainMenu] = useState("");

	const { siteId } = useParams();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const page = queryParams.get("page");

	const [item, setItem] = useState("");

	useEffect(() => {
		if (page) {
			axios
				.get("/api/sitesList.json")
				.then((response) => {
					const foundItem = response.data.find((item) => item.id === siteId);
					setItem(foundItem);
					const menuItems = foundItem.pages.map((item) => ({
						path: `${process.env.PUBLIC_URL}/app/pages/:siteId?page=${item.alias}`,
						type: "link",
						title: item.name,
					}));
					setMainMenu(menuItems);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		} else {
			// console.log('no');
			// axios
			// 	.get(`/api/MenuItems.json`)
			// 	.then((response) => {
			// 		const offersItems = response.data.Items.map((option) => ({ path: `${process.env.PUBLIC_URL}` + option.Link, title: option.Name, type: 'link' }));
			// 		const offers = {
			// 			icon: 'ecommerce',
			// 			type: 'sub',
			// 			title: 'Предложения',
			// 			children: offersItems,
			// 		};
			// 		setMainMenu(MENUITEMS[1].Items.push(offers));
			// 	})
			// 	.catch((error) => {
			// 		console.error('Ошибка получения данных:', error);
			// 	});
		}
	}, []);

	const [width, setWidth] = useState(0);

	const handleScroll = () => {
		if (window.scrollY > 400) {
			// if (
			//   customizer.settings.sidebar.type.split(' ').pop() ===
			//   'material-type' ||
			//   customizer.settings.sidebar.type.split(' ').pop() ===
			//   'advance-layout'
			// )
			document.querySelector(".sidebar-main").className = "sidebar-main hovered";
		} else {
			if (document.getElementById("sidebar-main")) document.querySelector(".sidebar-main").className = "sidebar-main";
		}
	};

	useEffect(() => {
		document.querySelector(".left-arrow").classList.add("d-none");
		window.addEventListener("resize", handleResize);
		handleResize();
		const currentUrl = window.location.pathname;
		// MENUITEMS.map((items) => {
		// 	items.Items.filter((Items) => {
		// 		if (Items.path === currentUrl) setNavActive(Items);
		// 		if (!Items.children) return false;
		// 		Items.children.filter((subItems) => {
		// 			if (subItems.path === currentUrl) setNavActive(subItems);
		// 			if (!subItems.children) return false;
		// 			subItems.children.filter((subSubItems) => {
		// 				if (subSubItems.path === currentUrl) {
		// 					setNavActive(subSubItems);
		// 					return true;
		// 				} else {
		// 					return false;
		// 				}
		// 			});
		// 			return subItems;
		// 		});
		// 		return Items;
		// 	});
		// 	return items;
		// });
		window.addEventListener("scroll", handleScroll);
		handleScroll();
		return () => {
			window.removeEventListener("scroll", handleScroll);
			window.removeEventListener("resize", handleResize);
		};
	}, [layout]);

	const handleResize = () => {
		setWidth(window.innerWidth - 500);
	};

	const activeClass = () => {
		// document.querySelector('.sidebar-link').classList.add('active');
		document.querySelector(".bg-overlay1").classList.add("active");
	};

	const setNavActive = (item) => {
		// MENUITEMS.map((menuItems) => {
		// 	menuItems.Items.filter((Items) => {
		// 		if (Items !== item) {
		// 			Items.active = false;
		// 			document.querySelector(".bg-overlay1").classList.remove("active");
		// 		}
		// 		if (Items.children && Items.children.includes(item)) {
		// 			Items.active = true;
		// 			document.querySelector(".sidebar-links").classList.add("active");
		// 		}
		// 		if (Items.children) {
		// 			Items.children.filter((submenuItems) => {
		// 				if (submenuItems.children && submenuItems.children.includes(item)) {
		// 					Items.active = true;
		// 					submenuItems.active = true;
		// 					return true;
		// 				} else {
		// 					return false;
		// 				}
		// 			});
		// 		}
		// 		return Items;
		// 	});
		// 	return menuItems;
		// });
		item.active = !item.active;
		setMainMenu({ mainmenu: MENUITEMS(keycloak.tokenParsed ? keycloak.tokenParsed?.sub : null) });
	};

	const closeOverlay = () => {
		document.querySelector(".bg-overlay1").classList.remove("active");
		document.querySelector(".sidebar-links").classList.remove("active");
	};

	const [phoneNumber, setPhoneNumber] = useState("");

	// Функция для добавления цифры в номер телефона
	const addDigit = (digit) => {
		const maxLength = 16;

		if (phoneNumber.length < maxLength) {
			setPhoneNumber(phoneNumber + digit);
		}
		console.log(phoneNumber);
	};

	console.log(props);

	return (
		<Fragment>
			<div
				className="bg-overlay1"
				onClick={() => {
					closeOverlay();
				}}
			></div>
			<div className={`sidebar-wrapper ${toggleIcon ? "close_icon" : ""}`} sidebar-layout="stroke-svg">
				<SidebarIcon />
				<SidebarLogo headerHeight={props?.headerHeight ?? 84} />
				{/* sidebartoogle={sidebartoogle} */}
				<SidebarMenu setMainMenu={setMainMenu} props={props} setNavActive={setNavActive} activeClass={activeClass} width={width} />
			</div>

			{/* виджет для набора номера телефона */}
			{roles.includes('telephony-api') && (
				<SidebarPhoneWidget />
			)}
		</Fragment>
	);
};

export default Sidebar;
