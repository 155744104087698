import React, {useEffect, useState} from "react";
import Select from "react-select";
import ObjectTypeCharacteristicSelect from "./CharacteristicsSelect/ObjectTypeCharacteristicSelect";
import CharacteristicTypeSelect from "./CharacteristicsSelect/CharacteristicTypeSelect";

export const BoldText = {
    label: "Жирный текст",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
        align: {
            label: "Выравнивание",
            type: "radio",
            options: [
                { label: "Слева", value: "left" },
                { label: "По центру", value: "center" },
                { label: "Справа", value: "right" },
            ],
        },

        ImapsData: {
            type: "object",
            label: "Характеристики",
            objectFields: {
                Characteristic: {
                    label: "Связать свойство с",
                    type: "object",
                    objectFields: {
                        typeField: {
                            type: "custom",
                            render: ({ onChange, value }) => {

                                return <>
                                    <CharacteristicTypeSelect onChange={(e) => onChange(e.value)} value={value} />
                                </>
                            }
                        },
                        id: {
                            type: "custom",
                            render: ({ onChange, value }) => {

                                return <>
                                    <ObjectTypeCharacteristicSelect onChange={(e) => onChange(e.value)} value={value}/>
                                </>
                            }
                        },
                    }
                }
            }
        }
    },
    render: ({ children, align }) => {
        return <b style={{textAlign: align, display: "block" }}>{children}</b>;
    },
};