import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Trash2, Edit } from "react-feather";
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FilterComponent from '../FilterComponent';
import { useInteractivePlanMapsContext } from "../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import { useCmsContext } from "../../../../../_helper/Sites/CmsProvider";
import Select from "react-select";
import { Switch } from '@mui/material';

const InteractivePlanMapWidgetsTable = () => {
    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const {
        planMapWidgets, planMapsOptions,
        planMapWidgetsPublicTypesOptions, getInteractivePlanMaps,
        getInteractivePlanMapsWidgets, createInteractivePlanMapsWidgets,
        deleteInteractivePlanMapsWidget, setPlanMapWidget, planMapWidget
    } = useInteractivePlanMapsContext();
    
    const { sitesOptions, getSites } = useCmsContext();


    useEffect(() => {
        getInteractivePlanMaps();
        getInteractivePlanMapsWidgets();
        getSites();
    }, []);

    const toggle = () => {

        setPlanMapWidget({
            planMapId: '',
            publicTypeId: '',
            domain: '',
            siteId: null,
            isPublish: false
        });

        setModal(!modal);
    };

    const handleAddRow = () => {
        if ((planMapWidget.domain !== '' || planMapWidget.siteId !== null) && planMapWidget.planMapId !== '')
        {
            setModal(!modal);
            createInteractivePlanMapsWidgets(planMapWidget).then(() => {
                toast.success("Запись успешно добавлена!");
            });
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const deleteRow = (id) => {
        try {
            SweetAlert.fire({
                title: 'Вы уверены?',
                text: 'Данное действие не отменит отправку оповщений!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ОК',
                cancelButtonText: 'Отмена',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    deleteInteractivePlanMapsWidget(id).then(() => {
                        getInteractivePlanMapsWidgets();
                        toast.success("Запись успешно удалена!");
                    });
                }
                
            });
        } catch (error) {
            console.error('Ошибка удаления записи:', error);
            toast.error("Ошибка удаления записи!");
        }
    };

    //const filteredItems = (planMapWidgets || []).filter(item => item.domain && item.domain.toLowerCase().includes(filterText.toLowerCase()));
    const filteredItems = planMapWidgets || [];


    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const tableColumns = [
        {
            name: 'Название сайта',
            selector: (row) => {
                if (row.domain) {
                    return row.domain;
                }
                else if (sitesOptions.length > 0)
                {
                    const site = sitesOptions.find(x => x.value === row.siteId);
                    return site ? site.label : "Не указано";
                }
                else
                {
                    return "Не указано";
                }
            },
            sortable: true,
            center: false,
        },
        {
            name: 'Опубликован',
            selector: (row) => row.isPublish ? "Да" : "Нет",
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{ display: 'flex' }}>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => {window.open(`/widgets/plan-map-widgets-update?id=${row.id}`, '_blank')}}>
                            <Edit size={"18px"} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }}
                            onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'} />
                        </div>
                    </Tooltip>

                </div>
            ),
        },
    ];
    const handleInputChange = (field, value) => {
        setPlanMapWidget(prevState => {
            const updatedState = {
                ...prevState,
                [field]: value
            };

            if (field === 'domain') {
                updatedState.siteId = null;
            }

            if (field === 'siteId') {
                updatedState.domain = '';
            }
            return updatedState;
        });
    };

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление нового объекта'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <Col md="12" xs="12" className="mb-3">
                                <Label>План карта</Label>
                                <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={planMapsOptions} onChange={(e) => handleInputChange('planMapId', e?.value)} className="js-example-basic-single col-sm-12" />
                            </Col>

                            <Col md="12" xs="12" className="mb-3">
                                <Label>Тип публикации</Label>
                                <Select
                                    options={planMapWidgetsPublicTypesOptions}
                                    placeholder="Выберите тип..."
                                    onChange={(selectedOption) => handleInputChange('publicTypeId', selectedOption.value)}
                                />
                            </Col>

                            {planMapWidgetsPublicTypesOptions.find(x => x.value === planMapWidget.publicTypeId)?.type === "external" && (
                                <Col md="12" xs="12" className="mb-3">
                                    <Label>Домен</Label>
                                    <Input
                                        className="form-control"
                                        value={planMapWidget.domain || ""}
                                        onChange={(e) => handleInputChange('domain', e.target.value)}
                                    />
                                </Col>
                            )}

                            {planMapWidgetsPublicTypesOptions.find(x => x.value === planMapWidget.publicTypeId)?.type === "cms" && (
                                <Col md="12" xs="12" className="mb-3">
                                    <Label>Выберите сайт из конструктора</Label>
                                    <Select
                                        options={sitesOptions}
                                        placeholder="Начните вводить..."
                                        noOptionsMessage={() => "Нет результатов"}
                                        value={sitesOptions.find(option => option.value === planMapWidget.cmsSite?.id)}
                                        onChange={(selectedOption) => handleInputChange('siteId', sitesOptions.find(site => site.value === selectedOption.value).value)}
                                    />
                                </Col>
                            )}

                            <Col md="12" xs="12" className="mb-3">
                                <Label>
                                    <Switch
                                        checked={planMapWidget.isPublish || false}
                                        onChange={() => handleInputChange('isPublish', !planMapWidget.isPublish)}
                                        color="primary"
                                    />
                                    <span className="ms-2">Публикация</span>
                                </Label>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default InteractivePlanMapWidgetsTable;
