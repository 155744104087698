import React from "react";
import ObjectTypeCharacteristicSelect from "./CharacteristicsSelect/ObjectTypeCharacteristicSelect";
import {Input} from "reactstrap";
import {Textarea} from "../../../../../Constant";

export const InputText = {
    label: "Поле для вывода",
    fields: {
        children: {
            label: "Текстовое поле",
            type: "text",
        },
        view: {
            label: "Вид поля",
            type: "radio",
            options: [
                { label: "Обычный текст", value: "default" },
                { label: "Многострочный текст", value: "textarea" }
            ],
        },
        DocumentData: {
            type: "object",
            label: "Характеристики",
            objectFields: {
                Characteristic: {
                    label: "Связать поле с",
                    type: "object",
                    objectFields: {
                        id: {
                            type: "custom",
                            render: ({ onChange, value }) => {

                                return <>
                                    <ObjectTypeCharacteristicSelect onChange={(e) => onChange(e.value)} value={value}/>
                                </>
                            }
                        },
                    }
                }
            }
        }
    },
    render: ({ children, view }) => {
        return view === "default" ? <Input
            className={`form-control`}
            value={children} /> : <Textarea value={children} className={'form-control'}>{children}</Textarea>;
    },
};