import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { H5, H6 } from '../../../../AbstractElements';
import { Mail } from 'react-feather';

const ViewMessage = ({ clientId }) => {
	const [data, setData] = useState([]);
	useEffect(() => {
		axios
			.get(`/api/clientsMessage.json`)
			.then((response) => {
				const foundObject = response.data.find((item) => item.clientId === Number(clientId));
				setData(foundObject.Messages);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const typeMes = [
		{
			sms: {
				color: 'warning',
				content: 'SMS',
			},
			all: {
				color: 'primary',
				content: 'Все каналы',
			},
			push: {
				color: 'info',
				content: 'PUSH',
			},
			Email: {
				color: 'success',
				content: 'Email',
			},
		},
	];

	return (
		<Fragment>
			<H5>Отправленные сообщения</H5>
			<div style={{ marginTop: '30px' }}>
				{data.map((item) => (
					<Card className={'b-l-' + typeMes[0][item.Method].color}>
						<CardBody>
							<H6 attrH6={{ className: `font-${typeMes[0][item.Method].color} f-w-600` }}>{typeMes[0][item.Method].content}</H6>
							<Mail className="font-primary" size={'14px'} style={{ marginRight: '5px' }} />
							{item.Text}
						</CardBody>
					</Card>
				))}
			</div>
		</Fragment>
	);
};

export default ViewMessage;
