import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Container, CardHeader, Col } from "reactstrap";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { Breadcrumbs, Btn, H3 } from "../../../../../AbstractElements";
import LessonsCard from "./LessonsCard";
import { EducationApi } from "../../../../../api";
import ModulesCard from "./ModulesCard";
import { useKeycloak } from "@react-keycloak/web";
import CommonModal from "../../../../UiKits/Modals/common/modal";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const ViewCourse = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const courseId = queryParams.get("courseId");

	const moduleId = queryParams.get("moduleId");

	const [pageTitle, setPageTitle] = useState("");

	const [pageDes, setPageDes] = useState("");

	const [data, setData] = useState([]);
	const [lessons, setLessons] = useState([]);
	const [modules, setModules] = useState([]);
	const [showModules, setShowModules] = useState(true);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	let currentUserId = `${keycloak.tokenParsed.sub}`;

	//useEffect(() => {
	//	if (courseId) {
	//		axios
	//			.get(`${EducationApi}get-course/${courseId}`)
	//			.then((response) => {
	//				const foundItem = response.data;
	//				setData(foundItem);
	//				setPageTitle(foundItem.name);
	//				setPageDes(foundItem.description);
	//			})
	//			.catch((error) => {
	//				console.error('Ошибка получения данных:', error);
	//			});

	//		axios
	//			.get(`/api/get-course.json`)
	//			.then((response) => {
	//				const foundItem = response.data;
	//				setShowModules(foundItem.showModules); // в {EducationApi}get-course добавить новое поле
	//			})
	//			.catch((error) => {
	//				console.error('Ошибка получения данных:', error);
	//			});
	//	}

	//	if (moduleId) setShowModules(false);

	//	if (!showModules && courseId) {
	//		// показать курс: только уроки
	//		axios
	//			.get(`/api/get-course-lesson.json`)
	//			.then((response) => {
	//				const foundItem = response.data;
	//				setLessons(foundItem);
	//			})
	//			.catch((error) => {
	//				console.error('Ошибка получения данных:', error);
	//			});
	//	} else if (showModules && courseId) {
	//		// показать курс: модули с уроками
	//		axios
	//			.get(`/api/get-course-modules.json`)
	//			.then((response) => {
	//				const foundItem = response.data;
	//				setModules(foundItem);
	//			})
	//			.catch((error) => {
	//				console.error('Ошибка получения данных:', error);
	//			});
	//	}
	//	if (moduleId) {
	//		//показать уроки модуля
	//		axios
	//			.get(`/api/get-course-modules.json`)
	//			.then((response) => {
	//				const foundItem = response.data.find((item) => item.id === moduleId);
	//				setLessons(foundItem.lessons);
	//			})
	//			.catch((error) => {
	//				console.error('Ошибка получения данных:', error);
	//			});
	//	}
	//}, []);

	const [achievements, setAchievements] = useState([]);

	useEffect(() => {
		axios
			.get(`${EducationApi}get-course/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const foundItem = concatResults(response);
				setData(foundItem);
				setPageTitle(foundItem.name);
				setPageDes(foundItem.description);
				setShowModules(foundItem.haveModules);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`${EducationApi}get-user-course-lessons/${courseId}/${currentUserId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const foundItem = concatResults(response).lessons;
				setLessons(foundItem);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		axios
			.get(`/api/Achievements.json`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setAchievements(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Просмотр курса" subParent="Список курсов" parent="Обучение" title="Просмотр курса" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardHeader>
							<Link to={`${process.env.PUBLIC_URL}/app/course-list`} className="f-18">
								<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
							</Link>
						</CardHeader>
						<CardBody>
							<Col className="mb-3 m-l-10 d-flex" style={{ alignItems: "center" }}>
								<H3 attrH3={{ className: "m-0" }}>{pageTitle}</H3>
								{achievements.length && (
									<Btn attrBtn={{ color: "warning", className: "pe-2 ps-2 ms-3", onClick: toggle }}>
										<i className="icofont icofont-trophy-alt me-2"></i>
										{achievements.length} достижения
									</Btn>
								)}
							</Col>
							<Col className="mb-4 m-l-10">
								<p className="f-22">{pageDes}</p>
							</Col>
							<Col className="mb-4 m-l-10 ">{showModules ? modules && <ModulesCard modules={modules} /> : lessons && <LessonsCard lessons={lessons} />}</Col>
						</CardBody>
					</Card>
				</div>
				<CommonModal isOpen={modal} title={"Ваши достижения"} toggler={toggle} togglerClose={toggle} saveText="Готово">
					<CardBody style={{ padding: "10px", paddingLeft: "15px" }}>
						<Col className="d-flex" style={{ flexWrap: "wrap" }}>
							{achievements &&
								achievements
									.sort((a, b) => b.weight - a.weight)
									.map((item) => (
										<Tooltip title={item.description} arrow placement="top">
											<div className="border-1 b-light b-r-8 me-2 mb-2" style={{ padding: "6px", textWrap: "nowrap", cursor: "pointer" }}>
												{item.image ? (
													<img src={item.image} alt="Uploaded" className="b-r-10" style={{ maxWidth: "30%" }} />
												) : (
													<i className="fa fa-hashtag font-primary me-1"></i>
												)}
												{item.name}
											</div>
										</Tooltip>
									))}
						</Col>
					</CardBody>
				</CommonModal>
			</Container>
		</Fragment>
	);
};
export default ViewCourse;
