import React, { useState } from "react";
import { useCompanyContext } from "../../_helper/Company/CompanyProvider";
import { useKeycloak } from "@react-keycloak/web";

// Создаем кастомный хук для фильтрации ролей
const useFilterRealmRoles = (menuItems) => {
    const { companyData } = useCompanyContext();
    const { keycloak } = useKeycloak();
    const [roles, setRoles] = useState(
        keycloak.tokenParsed ? keycloak.tokenParsed.realm_access?.roles : []
    );

    // Первая функция фильтрации по ролям и подгруппам
    const filterMenu_allUserRoles = (menuItems, companyData, roles) => {
        const companyRoles =
            companyData.length > 0
                ? companyData.flatMap((company) => company.realmRoles || [])
                : [];

        const allRoles = [...roles, ...companyRoles];
        const uniqueRoles = [...new Set(allRoles)];
        const userSubGroups = companyData.flatMap((company) => company.subGroups || []);

        return menuItems
            .map((item) => {
                if (item.subGroups && item.subGroups.length > 0) {
                    const hasMatchingSubGroup = item.subGroups.some((subGroup) => {
                        return userSubGroups.some((userSubGroup) => {
                            return (
                                subGroup.trim().toLowerCase() ===
                                userSubGroup.name.trim().toLowerCase()
                            );
                        });
                    });

                    if (!hasMatchingSubGroup) {
                        return undefined;
                    }
                }

                if (item.Items) {
                    item.Items = filterMenu_allUserRoles(item.Items, companyData, roles);
                }

                if (item.children) {
                    item.children = filterMenu_allUserRoles(item.children, companyData, roles);
                }

                if (!item.roles || item.roles.length === 0) {
                    return item;
                }

                const hasRequiredRoles = item.roles.every((role) =>
                    uniqueRoles.includes(role)
                );

                if (hasRequiredRoles) {
                    return item;
                }

                return undefined;
            })
            .filter((item) => item);
    };

    return filterMenu_allUserRoles(menuItems, companyData, roles);
};

// Вторая функция для примера
const filterMenu_byCustomLogic = (menuItems, customLogic) => {
    return menuItems.filter(customLogic);
};

// Экспортируем все функции
export { useFilterRealmRoles, filterMenu_byCustomLogic };
