import React, { Fragment } from "react";
import { Col, TabContent, TabPane } from "reactstrap";
import ListOfTask from "./ListTask";

const TabClass = ({ activeTab, viewType, statuses }) => {
	return (
		<Fragment>
			<Col xl="12" md="12" className="">
				<div className="email-right-aside bookmark-tabcontent">
					<div className="ps-0">
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<ListOfTask UserType={null} statuses={statuses} title="Все задачи" viewType={viewType} />
							</TabPane>
							{/* <TabPane tabId="2">
									<ListOfTask UserType={"Doer"} title="Делаю" />
								</TabPane>
								<TabPane tabId="3">
									<ListOfTask UserType={"Helper"} title="Помогаю" />
								</TabPane>
								<TabPane tabId="4">
									<ListOfTask UserType={"Creator"} title="Поручил" />
								</TabPane>
								<TabPane tabId="5">
									<ListOfTask UserType={"Watcher"} title="Наблюдаю" />
								</TabPane> */}
						</TabContent>
					</div>
				</div>
			</Col>
		</Fragment>
	);
};

export default TabClass;
