import React from "react";
import {Col, Input, Label} from "reactstrap";
import Select from "react-select";
import DataTable from "react-data-table-component";

export const Table = {
    label: "Таблица",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
        columns: {
            label: "Столбцы таблицы",
            type: "array",
            arrayFields: {
                label: {
                    label: "Название",
                    type: "text",
                },
                value: {
                    label: "Значение",
                    type: "text",
                },
            },
            getItemSummary: (_, id) => `Вариант ${id + 1}`,
        },
        dataSource: {
            label: "Источник данных",
            type: "text",
            data: []
        },
        useToken: {
            label: "С использованием токена доступа",
            type: "custom",
            render: ({ name, onChange, value }) => {
                return <div>
                    <Input id="checkbox-primary-1" checked={value ?? false} className={"checkbox_animated"} type="checkbox"
                           onChange={(e) => onChange(e.target.checked)}/>
                    <Label for="checkbox-primary-1">Использовать токен доступа</Label>
                </div>
            },
        },
        valueMask: {
            label: "Маска для значений",
            type: "text"
        },
        defaultProps: {
            children: "Выпадающий список",
            useToken: false,
            columns: [{label: "Название столбца", value: "value"}]
        },
        data: {
            type: "object",
        }
    },
    render: ({ children, columns, data }) => {
        const tableColumns = columns && columns.map((column) => ({
            name: `${column.label}`,
            selector: (row) => `${row[`${column.value}`] ?? ""}`,
            sortable: true,
        }));

        //const testDataCol = columns && columns.map((column) => ([`${column.value}`, 'Тест.знач.']))
        //const testData = testDataCol && Object.fromEntries(testDataCol);

        return <div>
            <Label className="col-sm-9 col-form-label">{children}</Label>
            {Array.isArray(tableColumns) && tableColumns.some((item) => item.name !== null) ?
            <DataTable
                data={data ?? []}
                columns={tableColumns}
                highlightOnHover={true}
                center={true}
                pagination
                pointerOnHover
                noDataComponent={"В данной таблице ещё нет записей."}
                paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
            /> : <span>В данной таблице ещё нет записей.</span>
            }
        </div>;
    },
};