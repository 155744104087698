import React, { useState, useContext, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { Card, CardBody, CardHeader, Col, Input, Row, Label } from "reactstrap";
import { Plus } from "react-feather";
import Select from "react-select";
import uuid from "react-uuid";
import { MarkerType } from "reactflow";

import DnDContext from "../../../../_helper/DnD";
import { toast } from "react-toastify";

import { Btn, H6 } from "../../../../AbstractElements";
import { Badges, ToolTip } from "../../../../AbstractElements";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const Sidebar = ({ allDepartment, allUsersNames, selectUser, setSelectUser, selectDepartment, setSelectDepartment }) => {
	const [basictooltip, setbasictooltip] = useState(false);
	const toggleTolltip = () => setbasictooltip(!basictooltip);
	const { companyData } = useCompanyContext();

	const { edit, description, setDescription, name, setName, modal, setModal, toggle, setDepartments, departments, setNodes, setEdges, lastNodePosition, setLastNodePosition, getDepartments } =
		useContext(DnDContext);


	useEffect(() => {
		getDepartments();
	}, []);
	// добавление отдела
	const addNode = () => {
		if (name === "" || selectUser === null) {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const id = uuid();
			const newNode = {
				id: id,
				type: "department",
				data: { departmentName: name, head: { name: selectUser.label, id: selectUser.value }, description: description, id: id, assistants: [] },
				position: { x: lastNodePosition.x, y: lastNodePosition.y + 150 },
				selectable: false,
			};
			setNodes((nds) => nds.concat(newNode));
			setModal(false);
			setName("");
			setLastNodePosition({ x: lastNodePosition.x, y: lastNodePosition.y + 150 });

			//если есть родительский
			if (selectDepartment) {
				const newEdge = {
					id: `${selectDepartment.value}-${id}`,
					source: selectDepartment.value,
					target: id,
					type: "smoothstep",
					markerEnd: {
						type: MarkerType.ArrowClosed,
						width: 20,
						height: 20,
					},
				};
				setEdges((eds) => [...eds, newEdge]);
			}

			const newDepartment = {
				id: id,
				name: name,
				description: description,
				head: { name: selectUser.label, id: selectUser.value },
				assistants: [],
				parent_department: selectDepartment ? selectDepartment.value : null,
			};
			// axios
			// 	.post(`${CmsApi}createSite`, newEvent, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 	.then((response) => {
			// 		toast.success("Сайт добавлен!", {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setEditModal(false);
			const updatedDepartments = [...departments, newDepartment];
			setDepartments(updatedDepartments);
			// 	})
			// 	.catch((error) => {
			// 		toast.error("Ошибка при добавлении", {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 	});
		}
	};

	return (
		<Accordion defaultActiveKey="0">
			<Card>
				<CardHeader>
					<H6>
						Управление
						<Badges attrBadge={{ className: "badge rounded-pill txt-dark ms-2 c-pointer", color: "light", pill: true, id: "Tooltip" }}>
							<i className="fa fa-info"></i>
						</Badges>
						<ToolTip
							attrToolTip={{
								placement: "right",
								isOpen: basictooltip,
								target: "Tooltip",
								toggle: toggleTolltip,
								offset: [0, 20],
							}}
						>
							Для удаления блока выделите его и нажмите кнопку "Backspace"
						</ToolTip>
					</H6>
				</CardHeader>
				<CardBody style={{ width: "261px", padding: "20px" }}>
					<Btn attrBtn={{ color: "primary", onClick: toggle }}>
						<Plus style={{ marginBottom: "-3px", marginRight: "3px" }} size="18px" />
						Добавить отдел
					</Btn>
				</CardBody>
				<CommonModal isOpen={modal} title={edit ? "Редактировать отдел" : "Добавить отдел"} toggler={addNode} closeText="Отмена" saveText="Добавить" togglerClose={toggle} size="lg">
					<CardBody style={{ padding: "15px 25px" }}>
						<Col className=" col-sm-11 mb-3">
							<Label className="col-sm-9 col-form-label">Название</Label>
							<Col sm="12">
								<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
							</Col>
						</Col>
						<Col className=" col-sm-11 mb-3">
							<Label className="col-sm-9 col-form-label">Описание</Label>
							<Col sm="12">
								<textarea rows={"3"} className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
							</Col>
						</Col>
						<Row>
							<Col className=" col-sm-7 mb-3">
								<Label className="col-sm-9 col-form-label">Руководитель отдела</Label>
								<Col sm="12">
									<Select
										placeholder="Начните вводить имя пользователя..."
										noOptionsMessage={() => "Нет результатов"}
										options={allUsersNames}
										value={selectUser}
										onChange={(option) => setSelectUser(option)}
										className="col-sm-12"
									/>
								</Col>
							</Col>
							<Col className=" col-sm-4 mb-3">
								<Label className="col-sm-9 col-form-label">Родительский отдел</Label>
								<Col sm="12">
									<Select
										placeholder="Выберите..."
										noOptionsMessage={() => "Нет результатов"}
										options={allDepartment}
										value={selectDepartment}
										onChange={(option) => setSelectDepartment(option)}
										className="col-sm-12"
									/>
								</Col>
							</Col>
						</Row>
					</CardBody>
				</CommonModal>
			</Card>
		</Accordion>
	);
};
export default Sidebar;
