import React, { useRef, useEffect, useState } from "react";
import styles from "./tooltip.module.css";

const Tooltip = ({ task, rowHeight = 50, rtl, svgContainerHeight = 50, svgContainerWidth = 100, arrowIndent = 5, fontSize, fontFamily, headerHeight = 50, taskListWidth = 130, TooltipContent }) => {
	const tooltipRef = useRef(null);
	const [relatedY, setRelatedY] = useState(0);
	const [relatedX, setRelatedX] = useState(0);
	var scrollX = document.documentElement.scrollLeft + 400;
	var scrollY = document.documentElement.scrollTop;
	useEffect(() => {
		if (tooltipRef.current) {
			const tooltipHeight = tooltipRef.current.offsetHeight * 1.1;
			const tooltipWidth = tooltipRef.current.offsetWidth * 1.1;

			let newRelatedY = task.index * rowHeight - scrollY + headerHeight;

			let newRelatedX;

			newRelatedX = task.x2 + arrowIndent * 3 + taskListWidth - scrollX;
			const tooltipLeftmostPoint = tooltipWidth + newRelatedX;
			const fullChartWidth = taskListWidth + svgContainerWidth;
			if (tooltipLeftmostPoint > fullChartWidth) {
				newRelatedX = task.x1 + taskListWidth - arrowIndent * 1.5 - scrollX - tooltipWidth;
			}
			if (newRelatedX < taskListWidth) {
				newRelatedX = svgContainerWidth + taskListWidth - tooltipWidth;
				newRelatedY += rowHeight;
			}

			const tooltipLowerPoint = tooltipHeight + newRelatedY - scrollY;
			if (tooltipLowerPoint > svgContainerHeight - scrollY) {
				newRelatedY = svgContainerHeight - tooltipHeight;
			}
			setRelatedY(newRelatedY);
			setRelatedX(newRelatedX);
		}
	}, [tooltipRef, task, arrowIndent, scrollX, scrollY, headerHeight, taskListWidth, rowHeight, svgContainerHeight, svgContainerWidth, rtl]);

	return (
		<div ref={tooltipRef} className={relatedX ? styles.tooltipDetailsContainer : styles.tooltipDetailsContainerHidden} style={{ left: relatedX, top: relatedY }}>
			<StandardTooltipContent task={task} fontSize={fontSize} fontFamily={fontFamily} />
		</div>
	);
};

const StandardTooltipContent = ({ task, fontSize, fontFamily }) => {
	const style = {
		fontSize,
		fontFamily,
		width: "250px",
	};
	return (
		<div className={styles.tooltipDefaultContainer} style={style}>
			<b style={{ fontSize: fontSize + 6 }}>{`${task.name}: ${task.start.getDate()}.${task.start.getMonth() + 1}.${task.start.getFullYear()} - ${task.end.getDate()}.${
				task.end.getMonth() + 1
			}.${task.end.getFullYear()}`}</b>
			{task.end.getTime() - task.start.getTime() !== 0 && (
				<p className={styles.tooltipDefaultContainerParagraph}>{`Продолжительность: ${~~((task.end.getTime() - task.start.getTime()) / (1000 * 60 * 60 * 24))} дней`}</p>
			)}
			<div className="font-primary f-12">Используйте двойной клик для получения подробной информации о задаче</div>
		</div>
	);
};

export default Tooltip;
