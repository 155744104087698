import React, { useState, useEffect } from "react";

import { Plus } from "react-feather";
import { Col, Row, CardBody, Label } from "reactstrap";
import axios from "axios";
import Select from "react-select";

import CommonModal from "../../../UiKits/Modals/common/modal";
import { Btn } from "../../../../AbstractElements";
import { useKeycloak } from "@react-keycloak/web";
import { TelephonyApi, TwinAuthApi } from "../../../../api";
import { toast } from 'react-toastify';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const TableAccess = ({ roles }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [filteredProducts, setFilteredProducts] = useState([]);

	const getData = async () => {
		try {

			const rightsResponse = await axios.get(`${TelephonyApi}getAccessRights`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const rightsList = concatResults(rightsResponse).rights;

			const allUsersResponse = await axios.get(`${TwinAuthApi}get-all-users`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const allUsers = JSON.parse(allUsersResponse.data).map((option) => ({
				label: `${option.firstName} ${option.lastName}`,
				value: option.id,
			}));

			setAllUsersNames(allUsers);

			const mappedRights = rightsList.map(right => {
				const user = allUsers.find(user => user.value === right.userId);
				return {
					id: right.id,
					user: { name: user.label, id: user.value },
					role: right.role.text,
					roleId: right.roleId
				};
			});

			setFilteredProducts(mappedRights);

		} catch (error) { }
	};

	useEffect(() => {
		getData();
	}, []);

	const [selectedCategory, setSelectedCategory] = useState([]);

	const [allUsersNames, setAllUsersNames] = useState([]);

	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	const [selectRole, setSelectRole] = useState(null);
	const [selectUser, setSelectUser] = useState(null);

	const send = () => {
		const newUser = {
			UserId: selectUser.value,
			RoleId: selectRole.value
		};

		axios
			.post(`${TelephonyApi}creatUserRight`, newUser, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				toast.success('Право доступа добавлено!', {
					position: toast.POSITION.TOP_RIGHT,
				});
				setModal(false);
				getData();
			})
			.catch((error) => {
				console.error(error);
			});
	};


	const handleRolesUpdate = (selectedOption, item) => {
		console.log(selectedOption, item);
		const updatedFilteredProducts = [...filteredProducts];
		const index = updatedFilteredProducts.findIndex((product) => product.id === item.id);
		if (index !== -1) {
			updatedFilteredProducts[index] = {
				...updatedFilteredProducts[index],
				role: selectedOption.label,
				roleId: selectedOption.value,
			};

			setFilteredProducts(updatedFilteredProducts);

			item.role = selectedOption.label;
			item.roleId = selectedOption.value;

			var updatedRole = {
				id: item.id,
				roleId: selectedOption.value,
				userId: item.user.id
			};

			axios
				.put(`${TelephonyApi}updateUserRight`, updatedRole, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					toast.success('Право доступа обновлено!', {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	return (
		<>
			{filteredProducts &&
				roles &&
				filteredProducts.map((item, i) => (
					<div key={i}>
						<Row className="b-b-light border-2 pt-2 pb-2">
							<Col sm="6">{item.user.name && <span> {item.user.name}</span>}</Col>
							<Col sm="6" className="p-0">
								<Select
									placeholder="Выберите..."
									value={{ label: item.role, value: item.roleId }}
									options={roles}
									className="js-example-basic-single col-sm-12"
									onChange={(selected) => handleRolesUpdate(selected, item)}
								/>
							</Col>
						</Row>
					</div>
				))}
			<Btn attrBtn={{ color: "primary", outline: true, className: "mt-3", onClick: toggle }}>
				<Plus size={"20px"} style={{ margin: "0px 5px -5px 0px" }} />
				Добавить право доступа
			</Btn>
			<CommonModal isOpen={modal} title="Добавить право доступа" toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "15px", paddingTop: "0" }}>
					<Col sm="12" className="mb-3">
						<Label>Пользователь</Label>
						<Select
							placeholder="Начните вводить имя пользователя..."
							noOptionsMessage={() => "Нет результатов"}
							options={allUsersNames}
							value={selectUser}
							onChange={(option) => setSelectUser(option)}
							className="js-example-basic-single col-sm-12"
						/>
					</Col>
					<Col sm="12" className="mb-3">
						<Label>Роль</Label>
						<Select placeholder="Выберите..." value={selectRole} options={roles} className="js-example-basic-single col-sm-12" onChange={(selected) => setSelectRole(selected)} />
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableAccess;
