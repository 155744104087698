import React, { Fragment } from 'react';
import { Container, Row, CardBody, Col, Card, CardHeader } from 'reactstrap';
import { Breadcrumbs, H5, H6 } from '../../../../AbstractElements';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-feather';

const Reports = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Отчёты" parent="Аналитика" title="Отчёты" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Row>
							<Col xl="6" sm="12">
								<Card>
									<CardHeader>
										<H5>
											<i className="fa fa-bar-chart-o"></i> Аналитика
										</H5>
									</CardHeader>
									<CardBody>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/returnability`}>
												Возвращаемость
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/fullness`}>
												Заполненность
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
									</CardBody>
								</Card>
								<Card>
									<CardHeader>
										<H5>
											<i className="fa fa-money"></i> Продажи
										</H5>
									</CardHeader>
									<CardBody>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/on-employees`}>
												По сотрудникам
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/dynamics-by-employees`}>
												По сотрудникам в динамике
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/on-services`}>
												По услугам
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/by-clients`}>
												По клиентам
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
									</CardBody>
								</Card>
							</Col>
							<Col xl="6" sm="12">
								<Card>
									<CardHeader>
										<H5>
											<i className="fa fa-rouble"></i> Финансы
										</H5>
									</CardHeader>
									<CardBody>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/accounts-and-cashregisters`}>
												Счета и кассы
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/payment-items`}>
												Статьи расходов/доходов
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/financial-transactions`}>
												Финансовые операции
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/non-fiscal-receipt`}>
												Настройки нефискального чека
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
										<H6 attrH6={{ className: 'mb-3' }}>
											<Link to={`${process.env.PUBLIC_URL}/analytics/reports/non-cash-payment`}>
												Безналичная оплата
												<ExternalLink size={'16px'} style={{ margin: '0 0px -1px 3px' }} />
											</Link>
										</H6>
									</CardBody>
								</Card>
								<Card>
									<CardHeader>
										<H5>
											<i className="fa fa-archive"></i> Склад
										</H5>
									</CardHeader>
									<CardBody></CardBody>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default Reports;
