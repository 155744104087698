import React, {Fragment, useState, useEffect, useReducer, createContext} from "react";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Plus } from "react-feather";
import Carousel from "react-bootstrap/Carousel"
import {
    Container, Form, FormGroup, Input, Label,
    Row,
} from "reactstrap";
import { CardBody, Card, Col } from "reactstrap";
import { Breadcrumbs, Btn, H6 } from "../../../../../AbstractElements";
import {Puck, Render, usePuck, Button, DropZone} from "@measured/puck";
import "@measured/puck/puck.css";
import {PuckComponents} from "./Components/PuckComponents";
import {useInteractivePlanMapsContext} from "../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import CommonModal from "../../../../UiKits/Modals/common/modal";
import Editor from "./Editor";
import {PuffLoader} from "react-spinners";

const PlanMapObjectCharacteristics = () => {
    const {
        getInteractivePlanMapsObjectsTypesCharacteristics,
        getInteractivePlanMapsObjectsType,
        planMapsObjectsType,
        modalCreateTypeChar,
        changeModalCreateTypeCharVisible,
        createInteractivePlanMapsObjectsTypeCharacteristic
    } = useInteractivePlanMapsContext();
    const { companyData } = useCompanyContext();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const typeId = queryParams.get('objectTypeId');
    const [charName, setCharName] = useState("");
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        Array.isArray(companyData) && getInteractivePlanMapsObjectsTypesCharacteristics(typeId);
        Array.isArray(companyData) && getInteractivePlanMapsObjectsType(typeId);
    }, [typeId, companyData]);

    useEffect(() => {
        planMapsObjectsType && planMapsObjectsType?.cardJson && planMapsObjectsType?.id === typeId && setInitialData(JSON.parse(planMapsObjectsType?.cardJson));
        if (planMapsObjectsType && planMapsObjectsType?.id === typeId && !planMapsObjectsType?.cardJson) {
            setInitialData(" ");
        }
    }, [planMapsObjectsType])

    const toggleCreate = () => {
        changeModalCreateTypeCharVisible();
        setCharName('');
    }

    const createTypeChar = () => {
        if (charName !== '' && typeId) {
            createInteractivePlanMapsObjectsTypeCharacteristic(charName, typeId);
            toggleCreate();
        }
    }

    return (

        <Fragment>
            <Breadcrumbs mainTitle="Редактирование характеристик объекта" parent="Виджеты" title="Редактирование характеристик объекта" />
            <Container fluid className="user-card">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                {
                                    initialData ?
                                        <Editor initialData={initialData} /> :
                                        <PuffLoader size={80} color="#7366ff" style={{ margin: "20px auto", display: "inherit", position: "relative", width: "80px", height: "80px" }} />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <CommonModal isOpen={modalCreateTypeChar} title={'Добавление характеристики'} toggler={createTypeChar} togglerClose={toggleCreate}
                         closeText="Отмена" saveText="Сохранить">
                <CardBody style={{padding: '10px', paddingLeft: '15px'}}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{flexDirection: 'column'}}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Название
                                </Label>
                                <Col sm="11">
                                    <Input
                                        className="form-control"
                                        value={charName}
                                        placeholder="Название характеристики"
                                        onChange={(e) => setCharName(e.target.value)} />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
        </Fragment>
    );
};
export default PlanMapObjectCharacteristics;
