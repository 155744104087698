import React, {Fragment, useEffect, useState} from "react";
import {Breadcrumbs} from "../../../../AbstractElements";
import {Card, CardBody, Col, Container, Input, Label, Media, Row} from "reactstrap";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {useCounterpartiesContext} from "../../../../_helper/Counterparties/CounterpartiesProvider";
import Select from "react-select";
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en';

const CounterpartySettings = () => {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const {
        counterpartySettings,
        getCounterpartySettings,
        updateCounterpartySettings,
    } = useCounterpartiesContext();

    const [allowHaveManyContacts, setAllowHaveManyContacts] = useState();
    const [defaultCountryCode, setDefaultCountryCode] = useState();

    useEffect(() => {
        if (counterpartySettings) {
            setAllowHaveManyContacts(counterpartySettings.allowHaveManyContacts);
            setDefaultCountryCode(counterpartySettings.defaultCountryCode);
        }
    }, [counterpartySettings]);

    useEffect(() => {
        if (allowHaveManyContacts !== undefined && defaultCountryCode !== undefined){
            if (
                counterpartySettings?.allowHaveManyContacts !== allowHaveManyContacts ||
                counterpartySettings.defaultCountryCode !== defaultCountryCode
            ) {
                updateCounterpartySettings(counterpartySettings.id, defaultCountryCode, allowHaveManyContacts)
            }
        }
    }, [allowHaveManyContacts, defaultCountryCode])

    useEffect(() => {
        getCounterpartySettings();
    }, [companyData, keycloak]);

    return (
        <Fragment>
            <div className="checkbox checkbox-primary">
                <Input id="checkbox-primary-3" type="checkbox" checked={allowHaveManyContacts}
                       onChange={(e) => setAllowHaveManyContacts(e.target.checked)}/>
                <Label for="checkbox-primary-3">Разрешить добавлять несколько контактов</Label>
            </div>
            <div>
                <Label>Страна номера телефона по умолчанию</Label>
                <Select
                    placeholder="Выберите..."
                    value={counterpartySettings?.defaultCountryCode ? {value: counterpartySettings?.defaultCountryCode, label: `${en[counterpartySettings?.defaultCountryCode]} +${getCountryCallingCode(counterpartySettings?.defaultCountryCode)}`} : null}
                    options={getCountries().map((item) => ({value: item, label: `${en[item]} +${getCountryCallingCode(item)}`}))}
                    className="js-example-basic-single col-sm-6"
                    onChange={(e) => setDefaultCountryCode(e?.value)}/>
            </div>
        </Fragment>
    );
};
export default CounterpartySettings;