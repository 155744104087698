import InteractivePlanMapsContext from '.';
import React, {useState, useContext, useCallback, useReducer, useEffect} from 'react';
import axios from 'axios';
import {CounterpartiesApi} from '../../api';
import {useKeycloak} from '@react-keycloak/web';
import {useCompanyContext} from "../Company/CompanyProvider";
import {concatResults} from '../../concatResults';
import CounterpartiesContext from ".";
import {toast} from "react-toastify";

const CounterpartiesProvider = ({children, props}) => {
    const context = useCreateCounterpartiesContext(props);

    return (
        <CounterpartiesContext.Provider
            value={context}
        >
            {children}
        </CounterpartiesContext.Provider>
    );
};

export function useCounterpartiesContext() {
    const context = useContext(CounterpartiesContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateCounterpartiesContext = function (props) {
    const { keycloak} = useKeycloak();
    const { companyData } = useCompanyContext();
    const [counterpartySettings, setCounterpartySettings] = useState();
    const [counterpartyContacts, setCounterpartyContacts] = useState();
    const [counterpartyRequiredFields, setCounterpartyRequiredField] = useState();
    const [counterpartyDetails, setCounterpartyDetails] = useState();
    const [counterpartyList, setCounterpartyList] = useState();
    const [roles, setRoles] = useState();
    const [counterpartyHeader, setCounterpartyHeader] = useState('Контрагент')
    const [counterpartyText, setCounterpartyText] = useState('контрагент')

    useEffect(() => {
        companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyHeader('Партнёр')
        companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyText('партнёр')
    }, [companyData]);

    const getCounterpartySettings = async () => {
        axios
            .get(`${CounterpartiesApi}get-counterparties-settings/${companyData[0]?.attributes.companyId[0]}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                console.log(concatResults(response));
                setCounterpartySettings(concatResults(response));
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
                console.log(1);
                companyData[0]?.attributes.companyId[0] && createCounterpartySettings(null, false);
            });
    };

    const createCounterpartySettings = async (defaultCountryCode, allowHaveManyContacts) => {
        axios
            .post(`${CounterpartiesApi}create-counterparty-settings`,
                {
                    companyId: companyData[0]?.attributes.companyId[0],
                    defaultCountryCode: defaultCountryCode,
                    allowHaveManyContacts: allowHaveManyContacts,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCounterpartySettings(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteCounterpartySettings = async (id) => {
        axios
            .delete(`${CounterpartiesApi}delete-counterparty-settings/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCounterpartySettings(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateCounterpartySettings = async (id, defaultCountryCode, allowHaveManyContacts) => {
        axios
            .put(`${CounterpartiesApi}update-counterparty-settings`,
                {
                    id: id,
                    companyId: companyData[0]?.attributes.companyId[0],
                    defaultCountryCode: defaultCountryCode,
                    allowHaveManyContacts: allowHaveManyContacts,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCounterpartySettings(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const getCounterpartyContacts = async (id) => {
        axios
            .get(`${CounterpartiesApi}get-counterparty-contacts/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setCounterpartyContacts(concatResults(response).contacts);
                //setCardSettings(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const createCounterpartyContact = async (counterpartyId, name, email, phone, commentary) => {
        axios
            .post(`${CounterpartiesApi}create-counterparty-contacts`,
                {
                    counterpartyId: counterpartyId,
                    name: name,
                    email: email,
                    phone: phone,
                    commentary: commentary
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCounterpartyContacts(counterpartyId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteCounterpartyContact = async (id, counterpartyId) => {
        axios
            .delete(`${CounterpartiesApi}delete-counterparty-contacts/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCounterpartyContacts(counterpartyId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateCounterpartyContact = async (id, counterpartyId, name, email, phone, commentary) => {
        axios
            .put(`${CounterpartiesApi}update-counterparty-contacts`,
                {
                    id: id,
                    counterpartyId: counterpartyId,
                    name: name,
                    email: email,
                    phone: phone,
                    commentary: commentary
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCounterpartyContacts(counterpartyId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const getCounterpartyRequiredFields = async () => {
        axios
            .get(`${CounterpartiesApi}get-counterparties-required-fields/${companyData[0]?.attributes.companyId[0]}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setCounterpartyRequiredField(concatResults(response).requiredFields);
                //setCardSettings(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const createCounterpartyRequiredField = async (fieldName) => {
        axios
            .post(`${CounterpartiesApi}create-counterparty-required-field`,
                {
                    companyId: companyData[0]?.attributes.companyId[0],
                    fieldName: fieldName,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCounterpartyRequiredFields();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteCounterpartyRequiredField = async (id) => {
        axios
            .delete(`${CounterpartiesApi}delete-counterparty-required-field/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCounterpartyRequiredFields();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateCounterpartyRequiredField = async (id, fieldName) => {
        axios
            .put(`${CounterpartiesApi}update-counterparty-required-field`,
                {
                    id: id,
                    companyId: companyData[0]?.attributes.companyId[0],
                    fieldName: fieldName,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCounterpartyRequiredFields();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const getCounterparty = async (id) => {
        axios
            .get(`${CounterpartiesApi}get-counterparty/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                console.log(response);
                setCounterpartyDetails(concatResults(response));
                //setCardSettings(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getCounterpartyRoles = async () => {
        axios.get(`${CounterpartiesApi}getRoles?companyId=${companyData[0]?.attributes?.companyId[0]}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
            }
        }).then((response) => {
            setRoles(concatResults(response).roles);
        })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getCounterpartiesList = async () => {
        console.log(1);
        axios
            .get(`${CounterpartiesApi}getCounterparties`, {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
                }
            }).then((response) => {
                setCounterpartyList(concatResults(response).counterparties ?? [])
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const createCounterparty = async (id, typeId, name, inn, upp, contactPerson, phone, email, address, comment, contractConditions) => {
        const newEvent = {
            id: id,
            typeId: typeId,
            name: name,
            inn: inn,
            upp: upp,
            contactPerson: contactPerson,
            phone: phone,
            email: email,
            address: address,
            comment: comment,
            isDeleted: false,
            baseCompanyId: companyData[0]?.attributes.companyId[0],
            contractConditions: contractConditions,
        };

        const headers = {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
            }
        };

        axios
            .post(`${CounterpartiesApi}createCounterparty`, newEvent, headers)
            .then(response => {
                getCounterpartiesList();
                toast.success(`${counterpartyHeader} успешно добавлен!`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch(error => console.error('Error creating role:', error));
    }

    const updateCounterparty = async(id, typeId, name, inn, upp, contactPerson, phone, email, address, comment, contractConditions) => {
        const newEvent = {
            id: id,
            typeId: typeId,
            name: name,
            inn: inn,
            upp: upp,
            contactPerson: contactPerson,
            phone: phone,
            email: email,
            address: address,
            comment: comment,
            isDeleted: false,
            baseCompanyId: companyData[0]?.attributes.companyId[0],
            contractConditions: contractConditions,
        };

        const headers = {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
            }
        };

        axios.put(`${CounterpartiesApi}updateCounterparty`, newEvent, headers)
            .then(response => {
                getCounterpartiesList();
                toast.success(`${counterpartyHeader} успешно обновлен!`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .catch(error => console.error('Error updating role:', error));
    }



    return {
        getCounterpartySettings,
        getCounterpartyContacts,
        getCounterpartyRequiredFields,
        getCounterparty,
        getCounterpartiesList,
        getCounterpartyRoles,

        createCounterpartySettings,
        createCounterpartyContact,
        createCounterpartyRequiredField,
        createCounterparty,

        updateCounterpartySettings,
        updateCounterpartyRequiredField,
        updateCounterpartyContact,
        updateCounterparty,

        deleteCounterpartySettings,
        deleteCounterpartyRequiredField,
        deleteCounterpartyContact,

        counterpartySettings,
        counterpartyContacts,
        counterpartyRequiredFields,
        counterpartyDetails,
        counterpartyList,
        roles
    };
}

export default CounterpartiesProvider;