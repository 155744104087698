import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, CardBody, Col, Card, CardHeader } from 'reactstrap';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import { Trash2, Edit, Plus } from 'react-feather';
import { Breadcrumbs, H5, H6, Btn } from '../../../../AbstractElements';
import TableAccess from './TableAccess';

const Settings = () => {
	const [roles, setRoles] = useState([]);
	useEffect(() => {
		axios
			.get('/api/roles.json')
			.then((response) => {
				setRoles(response.data);
				console.log(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Права доступа" parent="Аналитика" title="Права доступа" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="6" md="7" xl="8">
						<Card>
							<CardHeader>
								<div className="f-right col-sm-3">
									<H6>Роль CRM</H6>
								</div>
							</CardHeader>
							<CardBody>
								<TableAccess />
							</CardBody>
						</Card>
					</Col>
					<Col sm="6" md="5" xl="4">
						{roles && (
							<Card>
								<CardHeader className="bg-primary">
									<H6>Список ролей</H6>
								</CardHeader>
								<CardBody>
									{roles.map((role) => (
										<div className="mb-3 f-16" style={{ display: 'flex', justifyContent: 'space-between' }}>
											<div>- {role.role}</div>
											<div style={{ display: 'flex' }}>
												<Tooltip title="Редактировать" arrow placement="top">
													<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }}>
														<Edit size={'18px'} />
													</div>
												</Tooltip>
												<Tooltip title="Удалить" arrow placement="top">
													<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }}>
														<Trash2 size={'18px'} />
													</div>
												</Tooltip>
											</div>
										</div>
									))}
									<Btn attrBtn={{ color: 'primary', outline: true, className: 'mt-3' }}>
										<Plus size={'20px'} style={{ margin: '0px 5px -5px 0px' }} />
										Добавить
									</Btn>
								</CardBody>
							</Card>
						)}
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default Settings;
