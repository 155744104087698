import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn, H5, H6 } from '../../../../AbstractElements';

const CommonModal = (props) => {
	return (
		<Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
			<ModalHeader toggle={props.togglerClose} className={props.headerColorClass}>
				{props.title}
				{/* <H6 attrH6={{ className: 'mt-2 font-primary' }}>{props.subTitle}</H6> */}
			</ModalHeader>
			<ModalBody className={props.bodyClass}>{props.children}</ModalBody>
			{(props.closeText || props.saveText) && (
				<ModalFooter>
					{props.closeText ? <Btn attrBtn={{ color: 'secondary', onClick: props.togglerClose }}>{props.closeText}</Btn> : ''}
					{props.saveText ? <Btn attrBtn={{ color: 'primary', onClick: props.toggler }}>{props.saveText}</Btn> : ''}
				</ModalFooter>
			)}
		</Modal>
	);
};

export default CommonModal;
