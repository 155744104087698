import React, { Fragment, useState, useEffect, useContext, useMemo } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useLocation } from "react-router-dom";
import { Printer, Search } from "react-feather";
import { Nav, NavItem, NavLink, TabContent, TabPane, InputGroup, Input, InputGroupText } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import axios from "axios";

import ProjectContext from "../../../../_helper/Project";
import { TaskApi, TwinAuthApi, AllProjectApi } from "../../../../api";
import CanbanTasks from "../../Task/CanbanTasks";
import { useKeycloak } from "@react-keycloak/web";
import GanttTask from "./GanttTask";
import DataTableTasks from "../../Task/DataTableTasks";
import {concatResults} from "../../../../concatResults";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const ProjectView = () => {
	const [IconWithTab, setIconWithTab] = useState("1");
	const [data, setData] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [types, setTypes] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const projectId = queryParams.get("projectId");

	const { allData } = useContext(ProjectContext);
	const [foundItem, setFoundItem] = useState();

	const [allUsersNames, setAllUsersNames] = useState([]);

	const fetchData = async () => {
		try {
			const projectResponse = await axios.get(`${AllProjectApi}getProject/${projectId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			setFoundItem(concatResults(projectResponse).project);

			const projectStatusesResponse = await axios.get(`${AllProjectApi}getProjectStatuses?projectId=${projectId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			setStatuses(concatResults(projectStatusesResponse).statuses);
			console.log(concatResults(projectStatusesResponse).statuses);

			const tasksResponse = await axios.get(`${TaskApi}get?projectId=${projectId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const updatedTasks = [...concatResults(tasksResponse).tasks];

			updatedTasks.forEach((task) => {
				const status = concatResults(projectStatusesResponse).statuses.find((status) => status.id === task.status.id);
				if (status) {
					task.status = status;
					task.projectName = "";
				}
			});

			setData(updatedTasks);

			axios
				.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					const selectOptions = JSON.parse(response.data).map((option) => ({
						label: option.firstName + " " + option.lastName,
						value: option.id,
					}));
					setAllUsersNames(selectOptions);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		} catch (error) { }
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		axios
			.get(`${AllProjectApi}getTypes`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setTypes(concatResults(response).types);
				//console.log(concatResults(response).types);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const generateTitle = () => {
		const targetType = types.find((type) => type.id === foundItem.type.id);
		if (targetType) {
			if (targetType.value.toLowerCase() === "project") {
				return `Задачи в проекте: ${foundItem.name}`;
			} else if (targetType.value.toLowerCase() === "lead") {
				return `Лиды в проекте: ${foundItem.name}`;
			} else {
				return `Сделки в проекте: ${foundItem.name}`;
			}
		} else {
			return `Просмотр проекта`;
		}
	};

	const getType = () => {
	//	console.log("GETTYPE--------------")
	//	console.log(foundItem)
		const targetType = types.find((type) => type.id === foundItem?.type?.id);
		return targetType ? targetType.value.toLowerCase() : null;
	};

	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

	const subHeaderComponent = useMemo(() => {
		return (
			<InputGroup className="ms-4" style={{ width: "40%", flexGrow: "1", float: "right" }}>
				<Input className="form-control" type="text" style={{ height: "32px" }} placeholder="Поиск" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
				<InputGroupText className="bg-primary">
					<Search size={"20px"} />
				</InputGroupText>
			</InputGroup>
		);
	}, [filterText, resetPaginationToggle]);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const [modalTask, setModalTask] = useState(false);
	const toggleTask = () => {
		setModalTask(!modalTask);
	};

	return (
		foundItem && (
			<Fragment>
				<Breadcrumbs parent="Проекты" title={generateTitle()} mainTitle={generateTitle()} />
				<Container fluid={true}>
					<Row className="project-card">
						<Card>
							{/* <CardHeader>
								<Link to={`${process.env.PUBLIC_URL}/app/project/project-list`} className="f-18">
									<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
								</Link>
								<p>Проект ID: {projectId}</p>
							</CardHeader> */}
							<CardBody>
								<Nav tabs className="border-tab nav-primary">
									<NavItem>
										<NavLink className={`${IconWithTab === "3" ? "active" : ""} f-16`} onClick={() => setIconWithTab("3")} style={{ cursor: "pointer" }}>
											Список задач
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={`${IconWithTab === "1" ? "active" : ""} f-16`} onClick={() => setIconWithTab("1")} style={{ cursor: "pointer" }}>
											Доска задач
										</NavLink>
									</NavItem>
									{/*{!(getType() === "agreement") && (*/}
									{/*	<NavItem>*/}
									{/*		<NavLink className={`${IconWithTab === "2" ? "active" : ""} f-16`} onClick={() => setIconWithTab("2")} style={{ cursor: "pointer" }}>*/}
									{/*			Диаграмма Ганта*/}
									{/*		</NavLink>*/}
									{/*	</NavItem>*/}
									{/*)}*/}
									{<NavItem>
										<NavLink className={`${IconWithTab === "2" ? "active" : ""} f-16`} onClick={() => setIconWithTab("2")} style={{ cursor: "pointer" }}>
											Диаграмма Ганта
										</NavLink>
									</NavItem>}
									{IconWithTab === "3" ? subHeaderComponent : ""}
								</Nav>
								<TabContent activeTab={IconWithTab}>
									<TabPane className="fade show p-t-10" tabId="1">
										{data && (
											<CanbanTasks
												data={data}
												setData={setData}
												statuses={statuses}
												toggleTask={toggleTask}
												modalTask={modalTask}
												projectId={projectId}
												setModalTask={setModalTask}
												type={/*getType()*/ foundItem.type}
											/>
										)}
									</TabPane>
									<TabPane tabId="2" style={{ padding: "20px" }}>
										{data && <GanttTask tasks={data} projectId={projectId} toggleTask={toggleTask} />}
									</TabPane>
									<TabPane tabId="3" style={{ padding: "20px" }}>
										{data && <DataTableTasks data={filteredItems} setData={setData} users={allUsersNames} toggleTask={toggleTask} />}
									</TabPane>
								</TabContent>

								{/* {foundItem.typeId == "1" && <CanbanTasks data={data} statuses={statuses} />}
								{foundItem.typeId == "2" && (
									<CanbanTasks
										data={data}
										statuses={statuses}
										type={"lead"}
										//elements={foundItem.canbanElements}
									/>
								)}
								{foundItem.typeId == "3" && (
									<CanbanTasks
										data={data}
										statuses={statuses}
										type={"deals"}
										//elements={foundItem.canbanElements}
									/>
								)} */}
							</CardBody>
						</Card>
					</Row>
				</Container>
			</Fragment>
		)
	);
};

export default ProjectView;
