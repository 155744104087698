import React, {Fragment} from "react";
import {DropZone} from "@measured/puck";

export const Columns =  {

        label: "Колонки",
        fields: {
            columns: {
                label: "Список колонок",
                type: "array",
                getItemSummary: (col, id) =>
                    `Колонка ${id + 1}`,
                arrayFields: {
                },
            },
        },
        defaultProps: {
            distribution: "auto",
            columns: [{}, {}],
        },
        render: ({ columns }) => {
            return (
                <Fragment>
                    <div
                        className="row"
                    >
                        {columns.map(({ span }, idx) => (
                            <div
                                className="col"
                            >
                                <DropZone
                                    zone={`column-${idx}`}
                                    disallow={["Hero", "Logos", "Stats"]}
                                />
                            </div>
                        ))}
                    </div>
                </Fragment>
            );
        }

}