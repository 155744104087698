import React, { Fragment, useState, useEffect } from 'react';
import { Col, CardBody } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { P, H5, H4, LI, UL, Btn, H3 } from '../../../../AbstractElements';
import ProductPlaceOrder from './ProductPlaceOrder';

const BusinessProcessCard = ({ image, title, id, paragraph, serviceList, size = 4 }) => {
	const [total, setTotal] = useState(0);

	useEffect(() => {
		calculateTotal();
	}, [serviceList]);

	const calculateTotal = () => {
		if (serviceList) {
			const totalPrice = serviceList.reduce((acc, product) => acc + product.price, 0);
			setTotal(totalPrice);
		}
	};

	const [authModal, setAuthModal] = useState(false);

	const toggle = () => {
		setAuthModal(!authModal);
	};

	const [payModal, setPayModal] = useState(false);

	const togglePay = () => {
		setPayModal(!payModal);
	};

	const SendPay = () => {
		const newPay = {
			client_id: 1, // по нормальному узнать id пользователя
			package_id: id,
		};

		axios
			.post(`/api/clientsPackageOffers.json`, newPay)
			.then((response) => {
				//console.log(response.data);
				toast.success('Данные отправлены!', {
					position: toast.POSITION.TOP_RIGHT,
				});
				setPayModal(!payModal);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<>
			<Col lg={`${size}`} md="6">
				<div className={'pricingtable'} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '50px 30px' }}>
					<div className="pricingtable-header mb-3" style={{}}>
						<H3 attrH3={{ className: 'title m-b-0' }}>{title}</H3>
						<P attrLI={{ className: 'border-0' }}>{paragraph}</P>
					</div>
					<div className="pricingtable-header font-primary f-w-600 mb-4" style={{ height: '30px', marginBottom: '10px' }}>
						<H3 attrH3={{ className: 'title ' }}>{total} руб.</H3>
					</div>
					{serviceList ? (
						<div style={{ flexGrow: '1' }}>
							<UL attrUL={{ className: 'pricing-content flex-row mt-2' }}>
								{serviceList &&
									serviceList.map((service) => (
										<LI key={service.id} attrLI={{ className: 'border-0' }}>
											{service.name}
										</LI>
									))}
							</UL>
						</div>
					) : null}

					<Btn attrBtn={{ className: 'btn-pill mt-4', color: 'primary', onClick: togglePay }}>Заказать</Btn>
				</div>
			</Col>
			<CommonModal isOpen={authModal} toggler={toggle} togglerClose={toggle} size="md">
				<CardBody style={{ padding: '0 51px' }}>
					<div style={{ textAlign: 'center' }} className="mb-4">
						<H5 attrH5={{ className: 'title m-b-0' }}>Войдите или зарегистрируйтесь, чтобы продолжить</H5>
						<Btn attrBtn={{ className: 'mt-4', color: 'secondary' }}>Зарегистрироваться</Btn>
					</div>
				</CardBody>
			</CommonModal>
			<CommonModal isOpen={payModal} toggler={SendPay} togglerClose={togglePay} size="lg" saveText="Оплатить">
				<CardBody style={{ padding: '0 51px' }}>
					<ProductPlaceOrder serviceList={serviceList} />
				</CardBody>
			</CommonModal>
		</>
	);
};
export default BusinessProcessCard;
