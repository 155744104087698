import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { ReminderApi } from '../../../../api';
import FilterComponent from '../../Categories/FilterComponent';
import { useKeycloak } from '@react-keycloak/web';
import Select from 'react-select';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const DataTableEventType = (eventTypes) => {
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

    const [data, setData] = useState();

	const [modal, setModal] = useState(false);

    const [eventName, setEventName] = useState('');

    const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [filteredItems, setFilteredItems] = useState(data);

    useEffect(() => {
        if (Array.isArray(eventTypes.eventTypes)) { setData(eventTypes.eventTypes) }
    }, [eventTypes])

    useEffect(() => {
		data && setFilteredItems(data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
	}, [filterText, setFilterText]);

    useEffect(() => {
		data && setFilteredItems(data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
	}, [data, setData])

	const toggle = () => {
		setEventName('');

		setModal(!modal);
	};

	const handleAddRow = () => {
			setModal(!modal);
			const newRow = {
				name: eventName,
			};
			axios
				.post(`${ReminderApi}create-event-type`, newRow, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} } )
				.then((response) => {
					toast.success('уведомление создано!', {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
				.catch((error) => {
					console.error(error);
				});
	};

	const deleteRow = (remId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'Данное действие не отменит отправку оповщений!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			axios
				.delete(`${ReminderApi}delete-company-reminder/${remId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
				.then(() => {
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		});
	};

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

    const tableColumns = [
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
			center: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteRow(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	return (
		<Fragment>
			<CommonModal isOpen={modal} title={'Сделать уведомление'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
				<CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
					<Form className="form theme-form">
						<Row>
							<FormGroup className="row" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-9 col-form-label">Вид события</Label>
								<Col sm="11">
									<Input className="form-control" value={eventName} placeholder="Текст" onChange={(e) => setEventName(e.target.value)} />
								</Col>
							</FormGroup>
						</Row>
					</Form>
				</CardBody>
			</CommonModal>
			<DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</Fragment>
	);
};

export default DataTableEventType;
