import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, CardBody, Col, Card } from 'reactstrap';
import axios from 'axios';
import { Breadcrumbs } from '../../../AbstractElements';
import TableTaskReports from './TableTaskReports';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";
import { TasksPaymentApi, TwinAuthApi } from '../../../api';
import { concatResults } from '../../../concatResults';

const TaskReports = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [data, setData] = useState([]);

	const getData = async () => {
		try {
			const allUsersResponse = await axios.get(`${TwinAuthApi}get-all-users`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const allUsers = JSON.parse(allUsersResponse.data).map((option) => ({
				label: `${option.firstName} ${option.lastName}`,
				value: option.id
			}));

			const reportsResponse = await axios.get(`${TasksPaymentApi}getReports`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const reports = concatResults(reportsResponse).reports;

			const mappedReports = reports.map(report => ({
				id: report.id,
				accountNumber: report.number,
				documentTheme: report.title,
				comment: report.comment,
				formationPeriod: {
					startDate: report.startDateFormation,
					endDate: report.endDateFormation
				},
				performer: {
					id: report.performerId,
					name: allUsers.find(x => x.value === report.performerId)?.label || ""
				},
				invoiceDate: report.invoiceDate,
				totalPaymentAmount: report.totalPaymentAmount,
				status: report.reportStatus.text,
				paymentDate: report.paymentDate || false,
				documents: [
					//{
					//"name": "Счет-фактура",
					//"type": "PDF",
					//"link": "https://example.com/docs/invoice_15-r.pdf"
					//}
				]
			}));
			//setData(mappedReports);

			//const removeResponse = await axios.get(`/api/task-reports.json`, {
			//	headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			//});

			//const combinedList = mappedReports.concat(concatResults(response));

			setData(mappedReports);

		}
		catch (error) { }
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Fragment>
			<Breadcrumbs
				mainTitle="Управление счетами и актами по задачам"
				parent="Управление счетами и актами по задачам"
			/>
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<TableTaskReports products={data} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default TaskReports;
