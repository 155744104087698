import React from "react";
import { Row, Col } from "reactstrap";
import { H1, P } from "../../AbstractElements";

const Form = ({ color }) => {
	return (
		<Row style={{ backgroundColor: color, height: "70vh" }}>
			<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "16vh" }}>
				<H1 attrH1={{ className: "f-w-600 mb-3" }}>Оставьте свои пожелания!</H1>
				<P attrPara={{ className: "f-20 mb-4" }}>Заполните форму ниже, чтобы поделиться своими идеями с нами.</P>
			</Col>
		</Row>
	);
};
export default Form;
