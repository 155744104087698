import React, { useState, useEffect } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { CmsApi } from "../../api";
import { useCompanyContext } from "../Company/CompanyProvider";
import { concatResults } from "../../concatResults";
import Context from "./index";

export const CMSTemplatesProvider = ({ children }) => {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();

    const [allSelectTemplates, setAllSelectTemplates] = useState([]);
    const [allSelectForms, setAllSelectForms] = useState([]);
    const [allSelectNews, setAllSelectNews] = useState([]);
    const [allSelectOffers, setAllSelectOffers] = useState([]);

    const [allTemplates, setAllTemplates] = useState([]);
    const [allForms, setAllForms] = useState([]);
    const [allNews, setAllNews] = useState([]);

    const fetchTemplates = () => {
        axios
            .get(`${CmsApi}getTemplates`, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
            })
            .then((response) => {
                setAllTemplates(concatResults(response).templates);
                const selectOptions = concatResults(response).templates.map((option) => ({
                    label: option.name,
                    value: option.id,
                }));
                setAllSelectTemplates(selectOptions);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const fetchAppForms = async () => {
        await axios
            .get(`${CmsApi}getFormsApp`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                setAllForms(response.data.forms);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getAllCmsSelects = async () => {
        fetchTemplates();
        fetchAppForms();
    };

    const getAllAppForms = async () => {
        fetchAppForms();
    };

    useEffect(() => {
        /*getAllCmsSelects();*/
        /*fetchAppForms();*/
        getAllAppForms();
    }, []);

    return (
        <Context.Provider
            value={{
                allTemplates,
                refreshTemplates: fetchTemplates,
                allForms,
                allNews,
                allSelectForms,
                allSelectNews,
                allSelectTemplates,
                allSelectOffers,
                fetchAppForms, // Добавляем fetchAppForms в провайдер
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default CMSTemplatesProvider;
