import React, { useState, useMemo, useEffect } from 'react';
import { Trash2, Edit, Settings, ExternalLink } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import { Label, Input, CardBody, Col } from 'reactstrap';
import FilterComponent from './FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { EducationApi } from '../../../../api';
import { Btn } from '../../../../AbstractElements'
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";


const TableLessons = ({ lessons }) => {
    const [createModal, setCreateModal] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const [data, setData] = useState([]);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [link, setLink] = useState('');

    const [editId, setEditId] = useState('');
    const [editTitle, setEditTitle] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [editLink, setEditLink] = useState('');
    const [periodStart, setPeriodStart] = useState('');
    const [periodEnd, setPeriodEnd] = useState('');
    const [periodTime, setPeriodTime] = useState('');
    const [keyLesson, setKeyLesson] = useState('');
    const [order, setOrder] = useState('');
    const [deadlineType, setDeadlineType] = useState({});
    const [evaluationType, setEvaluationType] = useState({});

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get('courseId');
    const [filterText, setFilterText] = React.useState('');

    const {keycloak} = useKeycloak();
    const { companyData } = useCompanyContext();

    useEffect(() => {
        const sortedLessons = lessons.slice().sort((a, b) => a.order - b.order);
        setData(sortedLessons);
    }, [filterText, lessons]);

    const toggleModal = (type, lessonId) => {

        if (type === 'create') {
            setTitle('');
            setDescription('');
            setLink('');
            setCreateModal(!createModal);
        }
        else if (type === 'edit' && lessonId) {
            const lessonToEdit = data.find((lesson) => lesson.id === lessonId);
            if (lessonToEdit) {
                setEditId(lessonId);
                setEditTitle(lessonToEdit.name);
                setEditDescription(lessonToEdit.description);
                setEditLink(lessonToEdit.hellolink);
                setDeadlineType(lessonToEdit.deadlineType);
                setEvaluationType(lessonToEdit.evaluationType);
                setKeyLesson(lessonToEdit.keyLesson);
                setOrder(lessonToEdit.order);
                setPeriodEnd(lessonToEdit.periodEnd);
                setPeriodStart(lessonToEdit.periodStart);
                setPeriodTime(lessonToEdit.periodTime);
                setEditModal(!editModal);
            }
        }
        else {
            setEditId(null);
            setEditModal(false);
        }

    };


    const handleMove = (id, direction) => {        
        const index = data.findIndex((element) => element.id === id);

        if (index >= 0 && ((direction === 'up' && index > 0) || (direction === 'down' && index < data.length - 1))) {

            [data[index].order, data[direction === 'up' ? index - 1 : index + 1].order] = [
                data[direction === 'up' ? index - 1 : index + 1].order,
                data[index].order
            ];
            updateObject(data[index]);
            updateObject(data[direction === 'up' ? index - 1 : index + 1]);
            toast.success('Порядок обновлен!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        else {
            toast.error('Ошибка изменения данных', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const deleteRow = (lessonId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'После удаления вы не сможете восстановить этот объект!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                axios
                    .delete(`${EducationApi}delete-course-lesson/${lessonId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                    .then((response) => {
                        toast.success('Занятие удалено!', {
                            position: toast.POSITION.TOP_RIGHT,
                        });

                        setData(data.filter((lesson) => lesson.id !== lessonId));
                    })
                    .catch((error) => {
                        toast.error('Ошибка при удалении занятия.', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        console.error(error);
                    });
            }
        });
    };

    const handleSendRequest = (requestData, successMessage, errorMessage, type) => {
        axios
            .request(requestData)
            .then((response) => {
                const createdLesson = concatResults(response) ? JSON.parse(concatResults(response)) : '';
                toggleModal(type);

                if (type === 'edit') {
                    setEditModal(false);
                    var existObj = requestData.data;
                    var objWithIdIndex = data.findIndex((obj) => obj.id === existObj.id);
                    data[objWithIdIndex] = existObj;
                    setData(data.slice().sort((a, b) => a.order - b.order));

                }

                if (type === 'create') {
                    setCreateModal(false);
                    const lessonWithLowercaseKeys = Object.keys(createdLesson).reduce((acc, key) => {
                        acc[key.toLowerCase()] = createdLesson[key];
                        return acc;
                    }, {});
                    data.push(lessonWithLowercaseKeys);
                    setData(data);
                }

                toast.success(successMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });

            })
            .catch((error) => {
                console.error(error);
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };

    const updateObject = (updatedObject) => {
        handleSendRequest(
            {
                method: 'put',
                url: `${EducationApi}update-course-lesson`,
                data: updatedObject,
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
            },
            'Объект обновлен!',
            'Ошибка при обновлении объекта.',
            'edit'
        );
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        const openCreateModal = () => toggleModal('create');

        return (
            <FilterComponent
                toggle={openCreateModal}
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText]);

    const columns = [
        {
            name: 'Название',
            selector: (row) => (
                <Link to={`${process.env.PUBLIC_URL}/app/build-lesson?lessonId=${row.id}`}>{row.name}</Link>
            ),
            sortable: true,
            hide: 'sm',
            width: '25%',
        },
        {
            name: 'Описание',
            selector: (row) => row.description,
            sortable: true,
            width: '25%',
        },
        {
            name: 'Главная страница',
            selector: (row) => (
                <Link to={`${process.env.PUBLIC_URL}${row.helloLink}`}>
                    Перейти на страницу занятия
                    <ExternalLink size={'18px'} style={{ marginLeft: '7px', cursor: 'pointer' }} />
                </Link>
            ),
            sortable: false,
            width: '25%',
        },
        {
            name: '',
            button: true,
            cell: (row) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <div style={{ marginLeft: 'auto' }}>
                        <Btn
                            attrBtn={{
                                onClick: () => handleMove(row.id, 'up'),
                                disabled: row.order === 0,
                                color: 'primary',
                                outline: true,
                                style: { padding: '8px', marginRight: '10px', fontSize: '12px' }
                            }}
                        >
                            <Tooltip title="Переместить наверх" arrow placement="top">
                                <i className="fa fa-arrow-up"></i>
                            </Tooltip>
                        </Btn>
                    </div>
                    <div style={{ marginRight: '5px' }}>
                        <Btn
                            attrBtn={{
                                onClick: () => handleMove(row.id, 'down'),
                                disabled: row.order === data.length - 1,
                                color: 'primary',
                                outline: true,
                                style: { padding: '8px', marginRight: '10px', fontSize: '12px' }
                            }}
                        >
                            <Tooltip title="Переместить вниз" arrow placement="top">
                                <i className="fa fa-arrow-down"></i>
                            </Tooltip>
                        </Btn>
                    </div>
                    <Tooltip title="Перейти в конструктор занятия" arrow placement="top">
                        <Link to={`${process.env.PUBLIC_URL}/app/build-lesson?lessonId=${row.id}`}>
                            <Settings size={'18px'} style={{ cursor: 'pointer', marginRight: '5px' }} />
                        </Link>
                    </Tooltip>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <div
                            className="font-primary"
                            style={{ cursor: 'pointer', marginRight: '5px' }}
                            onClick={() => toggleModal('edit', row.id)}
                        >
                            <Edit size={'18px'} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div
                            className="font-primary"
                            style={{ cursor: 'pointer' }}
                            onClick={() => deleteRow(row.id)}
                        >
                            <Trash2 size={'18px'} />
                        </div>
                    </Tooltip>
                </div>
            ),
            //button: true,
            width: '25%',
        },
    ];

    const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

    return (
        <>
            <DataTable
                columns={columns}
                data={filteredItems}
                noDataComponent={'В данной таблице ещё нет записей.'}
                defaultSortField="name"
                striped
                pagination
                subHeader
                subHeaderComponent={subHeaderComponent}
                paginationComponentOptions={{
                    rowsPerPageText: 'Строк на странице:',
                    rangeSeparatorText: 'из',
                }}
            />
            <CommonModal
                isOpen={createModal}
                title={'Добавить'}
                toggler={() => handleSendRequest(
                    {
                        method: 'post',
                        url: `${EducationApi}create-lesson`,
                        data: {
                            name: title,
                            description: description,
                            helloLink: link,
                            courseId: courseId,
                            order: lessons.length,

                        },
                        headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
                    },
                    'Занятие добавлено!',
                    'Ошибка при добавлении занятия.',
                    'create'
                )}
                togglerClose={() => toggleModal('create')}
                closeText="Отмена"
                saveText="Сохранить"
                size="lg"
            >
                <CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
                    <Col className="row col-sm-12 mb-3">
                        <Label className="col-sm-9 col-form-label">Название</Label>
                        <Col sm="11">
                            <Input className="form-control" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </Col>
                    </Col>
                    <Col className="row col-sm-12 mb-3">
                        <Label className="col-sm-9 col-form-label">Описание</Label>
                        <Col sm="11">
                            <Input className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </Col>
                    </Col>
                    <Col className="row col-sm-12 mb-3">
                        <Label className="col-sm-9 col-form-label">Главная страница</Label>
                        <Col sm="11">
                            <Input className="form-control" type="text" value={link} onChange={(e) => setLink(e.target.value)} />
                        </Col>
                    </Col>
                </CardBody>
            </CommonModal>
            <CommonModal
                isOpen={editModal}
                title={'Редактировать'}
                toggler={() => {
                    var existObject = data.find((lesson) => lesson.id === editId);
                    handleSendRequest(
                        {
                            method: 'put',
                            url: `${EducationApi}update-course-lesson`,
                            data: {
                                id: editId,
                                name: editTitle,
                                description: editDescription,
                                helloLink: existObject.hellolink ? existObject.hellolink : editLink,
                                courseId: courseId,
                                periodStart: periodStart,
                                periodEnd: periodEnd,
                                periodTime: periodTime,
                                keyLesson: existObject.keylesson ? existObject.keylesson : keyLesson,
                                order: existObject.order ? existObject.order : order,
                                deadlineType: existObject.deadlinetype ? existObject.deadlinetype : deadlineType,
                                evaluationType: existObject.evaluationtype ? existObject.evaluationtype : evaluationType
                            },
                            headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
                        },
                        'Занятие обновлено!',
                        'Ошибка при обновлении занятия.',
                        'edit'
                    );
                }}
                togglerClose={() => toggleModal('edit', editId)}
                closeText="Отмена"
                saveText="Сохранить"
                size="lg"
            >
                <CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
                    <Col className="row col-sm-12 mb-3">
                        <Label className="col-sm-9 col-form-label">Название</Label>
                        <Col sm="11">
                            <Input className="form-control" type="text" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} />
                        </Col>
                    </Col>
                    <Col className="row col-sm-12 mb-3">
                        <Label className="col-sm-9 col-form-label">Описание</Label>
                        <Col sm="11">
                            <Input className="form-control" type="text" value={editDescription} onChange={(e) => setEditDescription(e.target.value)} />
                        </Col>
                    </Col>
                    <Col className="row col-sm-12 mb-3">
                        <Label className="col-sm-9 col-form-label">Главная страница</Label>
                        <Col sm="11">
                            <Input className="form-control" type="text" value={editLink} onChange={(e) => setEditLink(e.target.value)} />
                        </Col>
                    </Col>
                </CardBody>
            </CommonModal>
        </>
    );

};
export default TableLessons;