import React, {Fragment} from "react";
import CharacteristicTypeSelect from "./CharacteristicsSelect/CharacteristicTypeSelect";
import ObjectTypeCharacteristicSelect from "./CharacteristicsSelect/ObjectTypeCharacteristicSelect";

export const Buttons = {
    label: "Кнопки",
    fields: {
        buttons: {
            label: "Список кнопок",
            type: "array",
            getItemSummary: (item) => item.label || "Button",
            arrayFields: {
                label: { type: "text", label: "Текст кнопки" },
                href: { type: "text", label: "Ссылка кнопки" },
                variant: {
                    type: "radio",
                    options: [
                        { label: "Основная", value: "primary" },
                        { label: "Вторичная", value: "outline-primary" },
                    ],
                },
            },
            defaultItemProps: {
                label: "Текст",
                href: "#",
                variant: "primary",
            },
        },
        align: {
            type: "radio",
            options: [
                { label: "Слева", value: "left" },
                { label: "По центру", value: "center" },
            ],
        },
        ImapsData: {
            type: "object",
            label: "Характеристики",
            objectFields: {
                Characteristic: {
                    label: "Связать свойство с",
                    type: "object",
                    objectFields: {
                        typeField: {
                            type: "custom",
                            render: ({ onChange, value }) => {

                                return <>
                                    <CharacteristicTypeSelect onChange={(e) => onChange(e.value)} value={value} />
                                </>
                            }
                        },
                        id: {
                            type: "custom",
                            render: ({ onChange, value }) => {

                                return <>
                                    <ObjectTypeCharacteristicSelect onChange={(e) => onChange(e.value)} value={value}/>
                                </>
                            }
                        },
                    }
                }
            }
        }
    },
    defaultProps: {
        buttons: [{ label: "Текст", href: "#", variant: "primary" }],
    },
    render: ({ align, buttons, puck }) => {
        return (
            <Fragment>
                <div style={{display: "flex", justifyContent: align, alignItems: align, gap: "24px", flexWrap: "wrap"}}>
                    {buttons.map((button, i) => (
                        <a
                            key={i}
                            href={button.href}
                            className={`btn btn-${button.variant}`}
                            tabIndex={puck.isEditing ? -1 : undefined}
                        >
                            {button.label}
                        </a>
                    ))}
                </div>
            </Fragment>
        );
    },
};