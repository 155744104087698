import React, { useEffect, useState, useMemo } from 'react';
import { CardHeader } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import { Card, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Trash2, Edit } from 'react-feather';
import { Link } from 'react-router-dom';
import { DemandsApi } from '../../../../api';
import FilterComponent from './FilterComponent';
import { useKeycloak } from '@react-keycloak/web';
import { concatResults } from "../../../../concatResults";
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';
import { useDemandsContext } from "../../../../_helper/Demands/DemandsProvider";

const TableForm = () => {
	const { keycloak } = useKeycloak()
	const { companyData } = useCompanyContext();

	const { getDemandForms, demandForms, deleteDemandForm } = useDemandsContext();

	useEffect(() => {
		getDemandForms();
	}, []);

	const columns = [
		{
			name: '№',
			/*selector: (row) => <Link to={`/app/request/form-builder/visual-editor?formId=${row.id}`}>{row.id}</Link>,*/
			selector: (row) => (
				<a href={`/app/request/form-builder/visual-editor?formId=${row.id}`} target="_blank" rel="noopener noreferrer">
					{row.id}
				</a>
			),
			sortable: true,
		},
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
			hide: 'md',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<span
							onClick={() => window.open(`/app/request/form-builder/visual-editor?formId=${row.id}`, '_blank', 'noopener noreferrer')}
							style={{ marginLeft: '5px', cursor: 'pointer', color: "#7366ff" }}
						>
							<Edit size={'18px'} />
						</span>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteDemandFormHandle(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteDemandFormHandle = (id) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				deleteDemandForm(id).then((response) => {
					getDemandForms();
					SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
				});
			}
		});
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = demandForms && demandForms.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<Card>
				<CardHeader>
					<H5>Формы</H5>
					<p className="font-secondary mt-2">Для запуска визуального редактора, нажмите на номер формы.</p>
				</CardHeader>
				<CardBody>
					<DataTable subHeader subHeaderComponent={subHeaderComponent} columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
				</CardBody>
			</Card>
		</>
	);
};
export default TableForm;
