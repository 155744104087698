import React from "react";
import ViewPage from "../EditPagesList/ViewPage";

export const CodeExecutor = ({ srcDoc, height = "30vh" }) => (
	<div className="email-wrap mt-4">
		<p>Предпросмотр</p>
		<div className="code-executor b-light border-2 b-r-8" style={{ width: "100%", height: height, overflowX: "hidden", overflowY: "auto" }}>
			{/* <iframe srcDoc={srcDoc} title="output" sandbox="allow-scripts" className="code-frame" style={{ width: "100%", height: height }} /> */}
			<ViewPage content={srcDoc} />
		</div>
	</div>
);
