import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
// import dragula from 'react-dragula';
// import { ClickAndDrag, SpillDragNDrop, CopyDragNDrop } from '../../../Constant';
import { Breadcrumbs } from '../../../AbstractElements';
// import HeaderCard from '../../Common/Component/HeaderCard';
import MenuCard from './MenuCard';
import Drag2 from './Drag2';
import Drag3 from './Drag3';

const DragAndDrop = () => {
	const menuItems = [
		{ id: 'item1', title: 'Пункт меню 1' },
		{ id: 'item2', title: 'Пункт меню 2' },
		{ id: 'item3', title: 'Пункт меню 3' },
	];

	return (
		<Fragment>
			<Breadcrumbs parent="Bouns Ui" title="Drag & Drop" mainTitle="Drag & Drop" />
			<Container fluid={true}>
				<Row>
					<MenuCard items={menuItems} />
					<Drag2 />
					<Drag3 />
				</Row>
			</Container>
		</Fragment>
	);
};

export default DragAndDrop;
