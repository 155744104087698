import React, { Fragment, useState, useEffect, useRef } from "react";
import { Card, Col, CardHeader, CardBody, Row, Container, CardFooter, Label, Input, InputGroup, InputGroupText } from "reactstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import draftToHtml from "draftjs-to-html";
import { Link } from "react-router-dom";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { PuffLoader } from "react-spinners";
import Select from "react-select";

import { Btn, Breadcrumbs, H5, H6 } from "../../../AbstractElements";
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";
import { TaskApi, TwinAuthApi, AllProjectApi } from "../../../api";
import AccessError from "./AccessError";
import { Bell, Edit } from "react-feather";
import DataTableTasks from "./DataTableTasks";
import CommonModal from "../../UiKits/Modals/common/modal";
import DataTableModeration from "./DataTableModeration";
import { concatResults } from "../../../concatResults";

const ViewTask = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const taskId = queryParams.get("taskId");
	const { companyData } = useCompanyContext();

	const [complete, setComplete] = useState(false);

	const [item, setItem] = useState(null);

	const { keycloak } = useKeycloak();
	let currentUserId = `${keycloak.tokenParsed.sub}`;

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [allUsers, setAllUsersNames] = useState(null);

	const [childTasks, setChildTasks] = useState([]);

	const [project, setProject] = useState([]);
	const [Statuses, setStatuses] = useState([]);

	const fetchData = async () => {
		try {
			const allUsersResponse = await axios.get(`${TwinAuthApi}get-all-users`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const allUsers = JSON.parse(allUsersResponse.data).map((option) => ({
				label: `${option.firstName} ${option.lastName}`,
				value: option.id,
			}));
			setAllUsersNames(allUsers);

			const taskResponse = await axios.get(`${TaskApi}get`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				params: {
					userId: currentUserId,
					userType: null,
				},
			});

			const allTasks = concatResults(taskResponse).tasks;
			const task = allTasks.find((item) => item.id === taskId);
			console.log(task);
			const childTasks = allTasks.filter((item) => task.childTasksId && task.childTasksId.includes(item.id));
			const parentTask = allTasks.find((item) => item.id === task.parentTaskId);

			if (task.projectId) {
				const projectResponse = await axios.get(`${AllProjectApi}getProject/${task.projectId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
				const project = concatResults(projectResponse).project;
				setProject(project);
				console.log(project);
				const projectStatusesResponse = await axios.get(`${AllProjectApi}getProjectStatuses?projectId=${task.projectId}`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				});
				const projectStatuses = concatResults(projectStatusesResponse).statuses;

				task.childTasks = childTasks;
				task.parentTask = parentTask;

				childTasks.forEach((childTask) => {
					if (childTask.statusId) {
						const status = projectStatuses.find((status) => status.id === childTask.statusId);
						childTask.status = status;
						childTask.projectName = project.name;
					}
				});

				if (parentTask && parentTask.statusId) {
					const status = projectStatuses.find((status) => status.id === parentTask.statusId);
					parentTask.status = status;
					parentTask.projectName = project.name;
				}

				if (task.statusId) {
					const status = projectStatuses.find((status) => status.id === task.statusId);
					task.status = status;
					task.projectName = project.name;
				}
			} else {
				const userStatusesResponse = await axios.get(`${TaskApi}get-user-statuses`, {
					params: { UserId: currentUserId },
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				});
				setStatuses(concatResults(userStatusesResponse).statuses);
			}
			if (task.childTasksId) {
				setChildTasks(childTasks);
			}
			setItem(task);
			setData(concatResults(taskResponse).tasks);
			setLoading(false);


		} catch (error) {
			setLoading(false);
			console.error("Ошибка получения данных:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const [timer, setTimer] = useState(0);
	const [taskState, setTaskState] = useState("");
	const [isRunning, setIsRunning] = useState(false);
	const intervalRef = useRef(null);

	useEffect(() => {
		axios
			.get(`${TaskApi}getTaskTimeTrack/${taskId}`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				const { timeTrack, taskState } = concatResults(response);
				const timeInSeconds = parseInt(timeTrack);
				setTimer(timeInSeconds);

				if (taskState === "Start") {
					setTaskState(taskState);
					setIsRunning(true);
					const startTime = Date.now() - timeInSeconds * 1000;
					const updateTimer = () => {
						const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
						setTimer(elapsedSeconds);
					};

					intervalRef.current = setInterval(updateTimer, 1000);
				} else if (taskState === "Pause") {
					setTaskState(taskState);
					setIsRunning(false);
					clearInterval(intervalRef.current);
				} else if (taskState === "Complete") {
					setTaskState(taskState);
					setIsRunning(false);
					clearInterval(intervalRef.current);
					setComplete(true);
				}
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const startTimer = async () => {
		try {
			if (!isRunning) {
				setIsRunning(true);
				const startTime = Date.now() - timer * 1000;

				const updateTimer = () => {
					const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
					setTimer(elapsedSeconds);
				};

				intervalRef.current = setInterval(updateTimer, 1000);

				const response = await axios.post(
					`${TaskApi}setTaskTimeTracking`,
					{
						taskId: taskId,
						startDateTime: new Date(),
					},
					{
						headers: {
							Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
						},
					}
				);
			}
		} catch (error) {
			console.error("Ошибка при запуске таймера:", error);
		}
	};

	const stopTimer = async () => {
		try {
			setIsRunning(false);
			clearInterval(intervalRef.current);

			const response = await axios.post(
				`${TaskApi}setTaskTimeTracking`,
				{
					taskId: taskId,
					pauseDateTime: new Date(),
				},
				{
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				}
			);
		} catch (error) {
			console.error("Ошибка при остановке таймера:", error);
		}
	};

	const completeTask = async () => {
		try {
			setIsRunning(false);
			clearInterval(intervalRef.current);
			setComplete(true);

			const response = await axios.post(
				`${TaskApi}setTaskTimeTracking`,
				{
					taskId: taskId,
					endDateTime: new Date(),
				},
				{
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				}
			);
		} catch (error) {
			console.error("Ошибка при завершении задачи:", error);
		}
	};

	const handleStart = () => {
		startTimer();
	};

	const handlePause = () => {
		stopTimer();
	};

	const handleComplete = () => {
		completeTask();
	};

	function formatTime(seconds) {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const remainingSeconds = seconds % 60;

		const formattedTime = `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
		return formattedTime;
	}

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const [modalSignature, setModalSignature] = useState(false);
	const toggleSignature = () => setModalSignature(!modalSignature);

	const [signingType, setSigningType] = useState(null);
	const [username, setUsername] = useState("");

	//подписание документа
	const sign = () => {
		const newSign = {
			taskId: taskId,
			documentId: "",
			userData: {
				userId: keycloak.tokenParsed.sub,
				companyId: companyData[0]?.id,
			},
			SendTelegram: {
				signingType,
				username,
			},
		};
		console.log(newSign);

		// axios
		// 	.post(`/api/clientsDemandsForm.json`, newUser)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success("Пользователь добавлен!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setEditModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Просмотр задачи" parent="Задачи" title="Просмотр задачи" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Row>
						<Card>
							<CardHeader className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
								<div>
									{item && <H5 attrH5={{ className: "mb-2 ms-2" }}>{item.name}</H5>}
									<Link to={`${process.env.PUBLIC_URL}/app/task`} className="f-18 me-2">
										<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
									</Link>
								</div>
								{item && (
									<button className="btn btn-info" disabled={!item.accessRequests} style={{ height: "fit-content" }} onClick={toggle}>
										<Bell size={"18px"} style={{ margin: "0 3px -3px 0" }} />
										Запросы {item.accessRequests?.length ? `(${item.accessRequests.length})` : ""}
									</button>
								)}
							</CardHeader>
							{loading ? (
								<PuffLoader size={80} color="#7366ff" style={{ margin: "20px auto", display: "inherit", position: "relative", width: "80px", height: "80px" }} />
							) : item ? (
								<>
									<CardBody>
										<Row>
											<Col sm="12" md="9">
												{item.description ? <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(item.description)) }} /> : <p>Описание отсутствует</p>}
											</Col>
											<Col md="3" sm="12" className="p-0">
												<Card className="shadow-none shadow-showcase b-light">
													<CardHeader className="bg-primary" style={{ padding: "20px 20px 10px" }}>
														Поставлена{" "}
														{new Date(item.dateStart).toLocaleDateString("ru-RU", {
															day: "2-digit",
															month: "2-digit",
															year: "numeric",
														})}
													</CardHeader>
													<CardBody style={{ padding: "20px 20px 10px" }}>
														<Row className="b-b-light">
															<div style={{ display: "flex", justifyContent: "space-between", padding: "15px 10px" }}>
																Крайний срок:{" "}
																<span className="f-w-600">
																	{item.dateEnd
																		? new Date(item.dateEnd).toLocaleDateString("ru-RU", {
																			day: "2-digit",
																			month: "2-digit",
																			year: "numeric",
																		})
																		: "Не указано"}
																</span>
															</div>
														</Row>
														<Row className="b-b-light">
															<div style={{ display: "flex", justifyContent: "space-between", padding: "15px 10px" }}>
																{project && project.type && project.type.value === "agreement" ? "Регистратор:" : "Постановщик:"}
																<span className="f-w-600">{allUsers.find((u) => u.value === item.creatorId)?.label}</span>
															</div>
														</Row>
														<Row className="b-b-light">
															<div style={{ display: "flex", justifyContent: "space-between", padding: "15px 10px" }}>
																{project && project.type && project.type.value === "agreement" ? "Подписант:" : "Ответственный: "}
																<span className="f-w-600">{allUsers.find((u) => u.value === item.doerId)?.label}</span>
															</div>
														</Row>
														<Row className="b-b-light">
															<div style={{ display: "flex", justifyContent: "space-between", padding: "15px 10px" }}>
																{project && project.type && project.type.value === "agreement" ? "Согласующие:" : "Соисполнители:"}
																<span className="f-w-600">{item.coDoers.map((w) => allUsers.find((u) => u.value === w)?.label + ", ")}</span>
															</div>
														</Row>
														<Row className="b-b-light">
															<div style={{ display: "flex", justifyContent: "space-between", padding: "15px 10px" }}>
																Наблюдатели: <span className="f-w-600">{item.watchers.map((w) => allUsers.find((u) => u.value === w)?.label + ", ")}</span>
															</div>
														</Row>
														<Row className="mb-2">
															<div style={{ display: "flex", justifyContent: "space-between", padding: "15px 10px" }}>
																Статус: <span className="f-w-600">{item.status.name}</span>
															</div>
														</Row>
														<Col md="12" style={{ display: "flex", justifyContent: "center" }}>
															<Link to={`${process.env.PUBLIC_URL}/app/task/create-task?taskId=${taskId}`}>
																<Btn attrBtn={{ color: "info" }}>
																	<Edit size={"18px"} style={{ margin: "0 3px -3px 0px" }} />
																	Редактировать
																</Btn>
															</Link>
														</Col>
													</CardBody>
												</Card>
											</Col>
										</Row>
										{item.parentTaskId && (
											<Row>
												<H6 attrH6={{ className: "mb-4 ms-3" }}>Подзадачи</H6>
												<DataTableTasks users={allUsers} data={item.parentTaskId ? data.filter((t) => item.parentTaskId === t.id) : []} setData={setData} />
											</Row>
										)}
										{!!childTasks.length && (
											<Row>
												<H6 attrH6={{ className: "mb-4 ms-3" }}>Предшествующие задачи</H6>
												<DataTableTasks users={allUsers} data={childTasks} setData={setData} />
											</Row>
										)}
									</CardBody>
									<CardFooter>
										{project && project.type && project.type.value === "agreement" ? (
											<Btn attrBtn={{ color: "primary", className: "ms-3", onClick: toggleSignature }}>
												<i className="fa fa-check me-2"></i> Подписать
											</Btn>
										) : (
											<Col style={{ color: "#808092", fontSize: "16px" }}>
												{!complete ? (
													<div style={{ fontSize: "16px" }}>
														<span className={`${isRunning ? "text-success" : ""}`}>{formatTime(timer)} / 00:00:00</span>
														{!isRunning ? (
															<>
																<Btn attrBtn={{ color: "primary", className: "ms-3", onClick: handleStart }}>
																	<div style={{ display: "contents", whiteSpace: "normal" }}>Начать учет моего времени</div>
																</Btn>
															</>
														) : (
															<>
																<Btn attrBtn={{ color: "primary", outline: true, className: "ms-3", onClick: handlePause }}>На паузу</Btn>
															</>
														)}
														<>
															<Btn attrBtn={{ color: "secondary", className: "ms-2", onClick: handleComplete }}>Завершить</Btn>
														</>
													</div>
												) : (
													<div>
														Завершено <i className="fa fa-check"></i>
													</div>
												)}
											</Col>
										)}
									</CardFooter>
								</>
							) : (
								<AccessError taskId={taskId} userId={currentUserId} />
							)}
						</Card>
					</Row>
				</div>
				<CommonModal isOpen={modal} title="Запросы" toggler={toggle} togglerClose={toggle} size="xl" saveText="Готово">
					<CardBody style={{ padding: "10px", paddingLeft: "15px" }}>{item && item.accessRequests && <DataTableModeration allUsers={allUsers} data={item.accessRequests} />}</CardBody>
				</CommonModal>
				<CommonModal isOpen={modalSignature} title="Подписать" toggler={sign} togglerClose={toggleSignature} size="md" saveText="Подписать">
					<CardBody style={{ padding: "10px", paddingLeft: "15px" }}>
						<Col className="row mb-2">
							<Label className="col-sm-9 col-form-label">Вид подписания документа</Label>
							<Col sm="12">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={signingType}
									options={[{ label: "Через Telegram", value: "Telegram" }]}
									onChange={(option) => setSigningType(option)}
								/>
							</Col>
						</Col>
						{signingType && signingType.value === "Telegram" && (
							<Col className="row  mb-2">
								<Label className="col-sm-9 col-form-label">Имя пользователя Telegram</Label>
								<Col sm="12">
									<InputGroup>
										<InputGroupText>{"@"}</InputGroupText>
										<Input className="form-control" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
									</InputGroup>
								</Col>
							</Col>
						)}
					</CardBody>
				</CommonModal>
			</Container>
		</Fragment>
	);
};
export default ViewTask;
