import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Scrollbars from 'react-custom-scrollbars-2';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { Collapse } from 'reactstrap';
import axios from 'axios';
import { X, Star, Search } from 'react-feather';

import { H6 } from '../../../AbstractElements';

const LoyaltyPayment = ({ applicationId }) => {
	const [isOpen, setIsOpen] = useState(1);
	const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

	const [selectType, setSelectType] = useState('');
	const [data, setData] = useState([]);

	useEffect(() => {
		axios
			.get('/api/certificates.json')
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [searchTerm, setSearchTerm] = useState('');
	const [filteredArray, setFilteredArray] = useState([]);

	const handleSearch = () => {
		const filteredResults = data.filter((item) => item.id.toLowerCase().includes(searchTerm.toLowerCase()));
		setFilteredArray(filteredResults);
	};

	return (
		<>
			<Col className="mt-4">
				<H6>В кассу</H6>
				<Row style={{ flexWrap: 'nowrap' }} className="mb-4 mt-4">
					<Col md="6" style={{ cursor: 'pointer' }} className={`border-2 b-r-6 p-15 m-l-15 mt-3 b-light`}>
						<CopyToClipboard text={`${process.env.PUBLIC_URL}/app/request/payment-for-visit?applicationId=${applicationId}`}>
							<div
								style={{ display: 'flex', alignItems: 'center' }}
								onClick={() =>
									toast.success('Ссылка скопирована!', {
										position: toast.POSITION.BOTTOM_RIGHT,
									})
								}
							>
								<div style={{ backgroundColor: '#f7316433', padding: '8px', borderRadius: '5px', width: '38px', height: '32px' }}>
									<i className="fa fa-link f-18 font-secondary"></i>
								</div>
								<p className="f-14 f-w-600 ms-3 me-2 mb-0 txt-dark">Скопировать ссылку на оплату</p>
							</div>
						</CopyToClipboard>
					</Col>
				</Row>
				<H6>
					<Star size={'18px'} className="font-info" style={{ margin: '0 5px -3px 5px' }} />
					Лояльностью
				</H6>
				<div className="scroll-bar-wrap">
					<Scrollbars className="horizontal-scroll" style={{ width: '100%', height: 250 }}>
						<Row style={{ flexWrap: 'nowrap' }}>
							<Col md="5" style={{ cursor: 'pointer' }} className={`border-2 b-r-6 p-15 m-l-15 mt-3 ${selectType === 'certificate' ? 'b-primary shadow shadow-showcase ' : 'b-light'}`}>
								{selectType === 'certificate' ? <X className="f-right " style={{ color: '#ccc' }} onClick={() => setSelectType('')} /> : ''}
								<div onClick={() => setSelectType('certificate')}>
									<div className="mb-2" style={{ backgroundColor: '#7366ff2b', padding: '8px', borderRadius: '5px', width: '38px', height: '32px' }}>
										<i className="fa fa-credit-card-alt f-18 font-primary"></i>
									</div>
									<p className="mb-2 f-14 f-w-600 ">Сертификатом</p>
									<p className="mb-2 f-14">Сертификат на 1500</p>
									<p className="mb-1 f-12">Баланс </p>
									<p className="mb-1 f-12">Спишется</p>
								</div>
							</Col>
							<Col md="5" style={{ cursor: 'pointer' }} className={`border-2 b-r-6 p-15 m-l-15 mt-3 ${selectType === 'card' ? 'b-primary shadow shadow-showcase' : 'b-light'}`}>
								{selectType === 'card' ? <X className="f-right " style={{ color: '#ccc' }} onClick={() => setSelectType('')} /> : ''}
								<div onClick={() => setSelectType('card')}>
									<div className="mb-2" style={{ backgroundColor: '#37cff92e', padding: '8px', borderRadius: '5px', width: '38px', height: '32px' }}>
										<i className="fa fa-credit-card-alt f-18 font-info"></i>
									</div>
									<p className="mb-2 f-14 f-w-600 ">Картой лояльности</p>
									<p className="mb-2 f-14">Золотая карта</p>
									<p className="mb-1 f-12">Баланс </p>
									<p className="mb-1 f-12">Спишется</p>
								</div>
							</Col>
							<Col md="5" style={{ cursor: 'pointer' }} className={`border-2 b-r-6 p-15 m-l-15 mt-3 ${selectType === 'discount' ? 'b-primary shadow shadow-showcase' : 'b-light'}`}>
								{selectType === 'discount' ? <X className="f-right " style={{ color: '#ccc' }} onClick={() => setSelectType('')} /> : ''}
								<div onClick={() => setSelectType('discount')}>
									<div className="mb-2" style={{ backgroundColor: '#54ba4a26', padding: '8px', borderRadius: '5px', width: '38px', height: '32px' }}>
										<i className="fa fa-credit-card-alt f-18 font-success"></i>
									</div>
									<p className="mb-2 f-14 f-w-600 ">Карты и акции</p>
									<p className="mb-2 f-14">Скидка 10%</p>
									<p className="mb-1 f-12">10%</p>
								</div>
							</Col>
						</Row>
					</Scrollbars>
				</div>
				<Card>
					<div onClick={() => toggle(1)} style={{ cursor: 'pointer' }}>
						<H6 attrH6={{ className: 'mb-4 mt-4' }}>Поиск по коду и другие способы оплаты {isOpen === 1 ? <i className="fa fa-angle-up f-18 font-info"></i> : <i className="fa fa-angle-down f-18 font-info"></i>}</H6>
					</div>

					<Collapse isOpen={isOpen === 1}>
						<div className="scroll-bar-wrap">
							<Scrollbars className="horizontal-scroll" style={{ width: '100%', height: 250 }}>
								<Row style={{ flexWrap: 'nowrap' }}>
									<Col md="6" style={{ cursor: 'pointer', height: '186px' }} className={`border-2 b-r-6 p-15 m-l-15 mt-3 b-light`}>
										<div>
											<div className="mb-2" style={{ backgroundColor: '#7366ff2b', padding: '8px', borderRadius: '5px', width: '38px', height: '32px' }}>
												<i className="fa fa-credit-card-alt f-18 font-primary"></i>
											</div>
											<p className="mb-2 f-14 f-w-600 ">Поиск сертификата</p>
											<InputGroup className="mt-4">
												<Input className="form-control" type="tel" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
												<InputGroupText onClick={handleSearch}>
													<Search size={'18px'} />
												</InputGroupText>
											</InputGroup>
										</div>
									</Col>

									{filteredArray.length > 0 && searchTerm !== ''
										? filteredArray.map((item) => (
												<Col key={item.id} md="5" style={{ cursor: 'pointer' }} className={`border-2 b-r-6 p-15 m-l-15 mt-3 ${selectType === item.id ? 'b-primary shadow shadow-showcase ' : 'b-light'}`}>
													{selectType === item.id ? <X className="f-right " style={{ color: '#ccc' }} onClick={() => setSelectType('')} /> : ''}
													<div onClick={() => setSelectType(item.id)}>
														<div className="mb-2" style={{ backgroundColor: '#7366ff2b', padding: '8px', borderRadius: '5px', width: '38px', height: '32px' }}>
															<i className="fa fa-credit-card-alt f-18 font-primary"></i>
														</div>
														<p className="mb-2 f-14 f-w-600 ">Сертификатом</p>
														<p className="mb-2 f-14">{item.certificate_type}</p>
														<p className="mb-1 f-12">Баланс {item.balance} ₽</p>
														<p className="mb-1 f-12">Спишется</p>
													</div>
												</Col>
										  ))
										: ''}
								</Row>
							</Scrollbars>
						</div>
					</Collapse>
				</Card>
			</Col>
		</>
	);
};
export default LoyaltyPayment;
