import React, { useState, useEffect } from 'react';
import { Search } from 'react-feather';
import { Tooltip, Button, IconButton, Checkbox, Switch } from '@mui/material';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Col, Input, InputGroup, InputGroupText, Row, FormGroup, Label, CardBody } from "reactstrap";
import axios from 'axios';
import { Btn } from "../../../../AbstractElements";
import './TableSpecificationPositions.css';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; 
import CommonModal from '../CommonModal';
import Select from 'react-select';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { SpecificationsApi, TwinAuthApi, ProductsApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import SweetAlert from "sweetalert2";

const NestedTable = ({ rowData }) => {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const [products, setProducts] = useState(rowData.specificationProducts || []);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false); 
    const [isLoadingModal, setIsLoadingModal] = useState(false); 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const onSelectProduct = async (row) => {
        try {
            const response = await axios.post(
                `${SpecificationsApi}checkProduct/${row.id}`,
                { id: row.id },
                {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`,
                        CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                    }
                }
            );

            const updatedProducts = products.map(product => {
                if (product.id === row.id) {
                    return { ...product, isChecked: !product.isChecked };
                }
                return product;
            });

            setProducts(updatedProducts);
        } catch (error) {
            console.error('Ошибка при отправке запроса на микросервис', error);
        }
    };

    const onSearchAnalog = async (product) => {
        setIsLoadingModal(true);
        setIsModalOpen(true);

        try {
            const response = await axios.get(
                `${SpecificationsApi}searchAnalogProducts/${product.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`,
                        CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                    }
                }
            );

            setModalData(response.data.products);
        } catch (error) {
            console.error('Ошибка при загрузке аналогов', error);
        } finally {
            setIsLoadingModal(false);
        }
    };

    const onDeleteProduct = async (id) => {
        SweetAlert.fire({
            title: "Вы уверены?",
            text: "После удаления вы не сможете восстановить этот товар!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ОК",
            cancelButtonText: "Отмена",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.value) {
                try {
                    const response = await axios.delete(
                        `${SpecificationsApi}deleteFindedProduct/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${keycloak.token}`,
                                CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                            }
                        }
                    );

                    const updatedProducts = products.filter(product => product.id !== id);

                    setProducts(updatedProducts);
                } catch (error) {
                    console.error('Ошибка при отправке запроса на микросервис', error);
                }
            }
        });
    };


    const onMarkProduct = (product) => {
        // Логика для отметки товара
    };

    const nestedTableFooter = (rowData) => {
        return (
            <div className="footer-container">
                <div className="footer-buttons">
                
                    <Button
                        onClick={() => handleReSelectPosition(rowData)}
                        variant="outlined"
                        startIcon={<RefreshIcon sx={{ fontSize: 28 }} />} 
                        style={{ color: '#7b1fa2' }} 
                    >
                       
                    </Button>

                    <Button
                        onClick={() => handleAddProduct(rowData)}
                        variant="outlined"
                        startIcon={<AddIcon sx={{ fontSize: 28 }} />} 
                        style={{ marginLeft: '10px', color: '#7b1fa2' }} 
                    >
                    </Button>
                </div>
            </div>
        );
    };

    const handleReSelectPosition = async (position) => {
    
        SweetAlert.fire({
            title: "Вы уверены?",
            text: "Совершить переподбор позиции?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ОК",
            cancelButtonText: "Отмена",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.value) {
                setIsLoading(true);
                try {
                    
                    const response = await axios.post(
                        `${SpecificationsApi}startProductSelectionForPosition?posId=${position.id}`,
                        null,
                        {
                            headers: {
                                Authorization: `Bearer ${keycloak.token}`,
                                CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                            }
                        }
                    );
                } catch (error) {
                    console.error('Ошибка при отправке запроса на микросервис', error);
                }
            }
        });
    };

    const handleAddProduct = async (position) => {
        setIsLoadingModal(true);
        setIsModalOpen(true);

        try {
            const response = await axios.get(
                `${SpecificationsApi}searchProducts/${position.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`,
                        CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                    }
                }
            );


            setModalData(response.data.products);
        } catch (error) {
            console.error('Ошибка при загрузке товаров', error);
        } finally {
            setIsLoadingModal(false);
        }
    };

    const handleProductSelect = (product) => {
        const index = selectedProducts.findIndex(p => p.id === product.id);
        if (index === -1) {
            setSelectedProducts([...selectedProducts, product]);
        } else {
            const updatedProducts = [...selectedProducts];
            updatedProducts.splice(index, 1);
            setSelectedProducts(updatedProducts);
        }

    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setModalData([]);
        setSelectedProducts([]);
    };

    const onSaveSelectedProducts = async () => {
        try {

            const response = await axios.post(
                `${SpecificationsApi}saveSelectedProducts`,
                selectedProducts,
                {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`,
                        CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                    }
                }
            );
            setProducts(prevData => [...prevData, ...selectedProducts]);
            handleModalClose();
        } catch (error) {
            console.error('Ошибка при сохранении выбранных товаров', error);
        }
    };


    if (isLoading) {
        return (
            <div className="loading-icon">
                <RefreshIcon className="fa-spin" />
                <span>Подбираются товары...</span>
            </div>
        );
    }


    return (
        <div>
            <DataTable
                value={products}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25]}
                className="no-header"
                footer={nestedTableFooter(rowData)}
                emptyMessage="Нет подобранных товаров"
            >
                <Column field="productName" body={(row) => row.productName} />
                <Column field="productProviderName" body={(row) => row.productProviderName} />
                <Column field="productPrice" body={(row) => `${row.productPrice} ${row.productCurrency.currencyIcon}`} />
                <Column field="cost" body={(row) => `${row.cost} ${row.productCurrency.currencyIcon}`} />
                <Column body={(row) => (
                    <React.Fragment>
                        <Switch
                            checked={row.isChecked}
                            onChange={() => onSelectProduct(row)}
                            inputProps={{ 'aria-label': 'Выбрать товар' }}
                            color="primary"
                        />
                        <IconButton onClick={() => onSearchAnalog(row)} aria-label="Добавить аналог" color="primary">
                            <AddIcon fontSize="large" style={{ color: '#7366ff' }} />
                        </IconButton>
                        <IconButton onClick={() => onDeleteProduct(row.id)} aria-label="Удалить" color="primary">
                            <DeleteOutlineIcon fontSize="large" style={{ color: '#7366ff' }} />
                        </IconButton>
                        <Checkbox
                            checked={row.marked}
                            onChange={() => onMarkProduct(row)}
                            inputProps={{ 'aria-label': 'Отметить' }}
                            color="primary"
                        />
                    </React.Fragment>
                )} />
            </DataTable>
            <CommonModal
                isOpen={isModalOpen}
                togglerClose={handleModalClose}
                title="Подбор товаров"
                closeText="Отмена"
                saveText="Добавить"
                size="lg"
                onSave={onSaveSelectedProducts}
                >
                {isLoadingModal ? (
                    <div className="loading-icon">
                        <RefreshIcon className="fa-spin" />
                        <span>Подбор товаров...</span>
                    </div>
                ) : (
                    <DataTable
                        value={modalData}
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        emptyMessage="Нет товаров для отображения"
                        onRowClick={(e) => {
                            e.originalEvent.currentTarget.classList.toggle('selected-row');
                            handleProductSelect(e.data)
                        }}
                        style={{ width: '100%' }}
                        headerStyle={{ width: '100%' }}
                    >
                            <Column field="productName" header="Наименование" body={(row) => `${row.productName}`} className={(row) => selectedProducts.some(p => p.id === row.id) ? 'selected-row' : ''} />
                            <Column field="productProviderName" header="Поставщик" body={(row) => `${row.productProviderName}`} className={(row) => selectedProducts.some(p => p.id === row.id) ? 'selected-row' : ''} />
                            <Column field="productSettings" header="Настройки товара" className={(row) => selectedProducts.some(p => p.id === row.id) ? 'selected-row' : ''} />
                            <Column field="productPrice" header="Цена по прайсу" body={(row) => `${row.productPrice} ${row.productCurrency.currencyIcon}`} className={(row) => selectedProducts.some(p => p.id === row.id) ? 'selected-row' : ''} />
                            <Column field="cost" header="Валюта" body={(row) => `${row.productCurrency.name}`} className={(row) => selectedProducts.some(p => p.id === row.id) ? 'selected-row' : ''} />

                    </DataTable>
                )}
            </CommonModal>
        </div>
    );
};

export default NestedTable;
