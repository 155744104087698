import React, { useMemo, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import FilterComponent from '../../../Goods/PurchaseHistory/FilterComponent';

import { H6, P } from '../../../../../AbstractElements';

import { AnalyticsApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const TableReturnability = () => {
	const [data, setData] = useState([]);
	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);

	useEffect(() => {
		axios
			.get(`${AnalyticsApi}getReturnabilityReports`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				setData(concatResults(response).returnabilityReports);
				const dateEnd = queryParams.get('dateEnd');
				if (dateEnd) {
					setPeriodEnd(new Date(dateEnd));
				} else {
					setPeriodEnd(new Date());
				}

				const dateStart = queryParams.get('dateStart');
				if (dateStart) {
					setPeriodStart(new Date(dateStart));
				} else {
					setPeriodStart(new Date());
				}
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	function formatDate(date) {
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
		const year = date.getFullYear();

		return `${day}.${month}.${year}`;
	}

	const columns = [
		{
			name: 'Сотрудник',
			selector: (row) => (
				<>
					<H6>{row.employee}</H6>
					<P> {row.service}</P>
				</>
			),
			sortable: true,
			width: '18%',
		},
		{
			name: <div>Всего клиентов за период</div>,
			selector: (row) => `${row.totalClientsPeriod}`,
			sortable: true,
			width: '15%',
		},
		{
			name: <div>Новых клиентов за период</div>,
			selector: (row) => `${row.newClientsPeriod}`,
			sortable: true,
			width: '15%',
		},
		{
			name: <div>Вернушвихся клиентов за период</div>,
			selector: (row) => `${row.returningClientsPeriod}`,
			sortable: true,
			width: '15%',
		},
		{
			name: <div>Возвращаемость клиентов за 30 дней до {formatDate(periodEnd)}</div>,
			selector: (row) => `0`,
			sortable: true,
			width: '15%',
		},
		{
			name: (
				<div>
					Из них вернулись с {formatDate(periodStart)} по {formatDate(periodEnd)}
				</div>
			),
			selector: (row) => `0 %`,
			sortable: true,
			width: '15%',
		},
		{
			name: '%',
			selector: (row) => `0`,
			sortable: true,
		},
	];

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return <>{data && <DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />}</>;
};

export default TableReturnability;
