import React, { Fragment } from 'react';
import { Container, Row, Card, CardBody, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableClients from './TableClients';

const ClientList = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Клиентская база" parent="Приложения" title="Клиентская база" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Row>
						<Col xl="12" md="12" className="box-col-12">
							<Card>
								<CardBody>
									<TableClients />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};
export default ClientList;
