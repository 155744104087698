import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import { Col, Label, Card, Collapse, Row, Input } from "reactstrap";
import Select from "react-select";
import { UserPlus, Trash2, Plus } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import uuid from "react-uuid";
import SweetAlert from "sweetalert2";

import { Btn } from "../../../../AbstractElements";

const TaskManagement = forwardRef(({ data, setData, demandId }, ref) => {
	const [options, setOptions] = useState([]);
	const [employeesArray, setEmployeesArray] = useState([]);

	useEffect(() => {
		axios
			.get("/api/employeesByDepartment.json")
			.then((response) => {
				setEmployeesArray(response.data);
				const outputArray = [];

				response.data.forEach((departmentObj) => {
					outputArray.push({ label: departmentObj.department, isDisabled: true });

					departmentObj.employees.forEach((employee) => {
						outputArray.push(employee);
					});
				});
				setOptions(outputArray);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [isOpen, setIsOpen] = useState(1);
	const [selectAssigner, setSelectAssigner] = useState(null);
	const [selectAssignee, setSelectAssignee] = useState(null);
	const [selectObservers, setSelectObservers] = useState(null);
	const [status, setStatus] = useState(null);
	const [project, setProject] = useState("");
	const [title, setTitle] = useState("");

	const toggleClientCard = (item) => {
		if (isOpen === item.id) {
			setIsOpen(null);
		} else {
			setIsOpen(item.id);
		}
		setTitle(item.title);
		setSelectAssignee(item.assignee);
		setSelectAssigner(item.assigner);
		setSelectObservers(item.observers);
		setStatus(item.status);
		setProject(item.project);
	};

	const handleAddDepartment = (targetEmployeeName) => {
		for (const departmentObj of employeesArray) {
			const foundEmployee = departmentObj.employees.find((employee) => employee.value === targetEmployeeName.value);

			if (foundEmployee) {
				return departmentObj.employees;
			}
		}
		return null;
	};

	const addItem = () => {
		const item = {
			id: uuid(),
			title: "Введите название задачи",
			assigner: [],
			assignee: [],
			status: { label: null, value: null },
			project: { label: null, value: null },
			collaborators: [],
			observers: [],
		};
		setData([...data, item]);
		console.log(data);
	};

	const deleteItem = (task) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				const updatedData = data.filter((item) => item !== task);
				setData(updatedData);
				SweetAlert.fire("Удалено!", "Объект был удален.", "success");
			}
		});
	};

	const handleSave = (itemId) => {
		const itemIndex = data.findIndex((item) => item.id === itemId);

		const updatedItem = {
			...data[itemIndex],
			title: title,
			assigner: selectAssigner,
			assignee: selectAssignee,
			collaborators: selectObservers,
			status: status,
			project: project,
		};

		const updatedData = [...data];
		updatedData[itemIndex] = updatedItem;

		setData(updatedData);
		console.log(updatedData);
	};

	useImperativeHandle(ref, () => ({
		getData: () => {
			return data;
		},
	}));

	return (
		<>
			<Row className="b-r-8 badge-light font-dark p-15 mt-2">
				<Col md="2">Задача</Col>
				<Col md="2">Постановщик </Col>
				<Col md="2">Ответственный</Col>
				<Col md="2">Наблюдатели</Col>
				<Col md="2">Статус заявки</Col>
				<Col md="2">Проект</Col>
			</Row>
			{data.map((item) => (
				<Card key={item.id}>
					<Row className="ps-2 f-14 p-15 mt-1 b-b-light" onClick={() => toggleClientCard(item)} style={{ cursor: "pointer" }}>
						<Col md="2">
							{isOpen === item.id ? <i className="fa fa-angle-down me-2"></i> : <i className="fa fa-angle-right me-2"></i>}
							{item.title}
						</Col>
						<Col md="2">{item.assigner.map((item) => item.label + ", ")}</Col>
						<Col md="2">{item.assignee.map((item) => item.label + ", ")}</Col>
						<Col md="2">{item.collaborators.map((item) => item.label + ", ")}</Col>
						<Col md="2">{item.status.label}</Col>
						<Col md="2" className="">
							<Row>
								<Col sm="9">{item.project.label}</Col>
								<Tooltip title="Удалить элемент" arrow placement="top">
									<a className="col-sm-3 " onClick={() => deleteItem(item)} style={{ width: "32px", height: "32px" }}>
										<Trash2 size={"20px"} />
									</a>
								</Tooltip>
							</Row>
						</Col>
					</Row>

					<Collapse isOpen={isOpen === item.id}>
						<Col md="12" className="mt-2">
							<Label className="col-sm-9 col-form-label">Название</Label>
							<Col sm="12">
								<Input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
							</Col>
						</Col>
						<Col md="12" className="mt-2">
							<Label className="col-sm-9 col-form-label">Постановщик</Label>
							<div style={{ display: "flex" }}>
								<Col sm="11">
									<Select
										placeholder="Выберите.."
										options={options}
										value={selectAssigner}
										isMulti
										className="js-example-basic-single col-sm-12"
										onChange={(option) => setSelectAssigner(option)}
									/>
								</Col>
								<Tooltip title="Добавить весь отдел" arrow placement="top">
									<div
										className="bg-info ms-4 b-r-5"
										style={{ height: "38px", padding: "7px 10px ", cursor: "pointer" }}
										onClick={() => setSelectAssigner(handleAddDepartment(selectAssigner[0]))}
									>
										<UserPlus />
									</div>
								</Tooltip>
							</div>
						</Col>
						<Col md="12" className="mt-2">
							<Label className="col-sm-9 col-form-label">Ответственный</Label>
							<div style={{ display: "flex" }}>
								<Col sm="11">
									<Select
										placeholder="Выберите.."
										options={options}
										value={selectAssignee}
										isMulti
										className="js-example-basic-single col-sm-12"
										onChange={(option) => setSelectAssignee(option)}
									/>
								</Col>
								<Tooltip title="Добавить весь отдел" arrow placement="top">
									<div
										className="bg-info ms-4 b-r-5"
										style={{ height: "38px", padding: "7px 10px ", cursor: "pointer" }}
										onClick={() => setSelectAssignee(handleAddDepartment(selectAssignee[0]))}
									>
										<UserPlus />
									</div>
								</Tooltip>
							</div>
						</Col>
						<Col md="12" className="mt-2">
							<Label className="col-sm-9 col-form-label">Наблюдатели</Label>
							<div style={{ display: "flex" }}>
								<Col sm="11">
									<Select
										placeholder="Выберите.."
										options={options}
										value={selectObservers}
										isMulti
										className="js-example-basic-single col-sm-12"
										onChange={(option) => setSelectObservers(option)}
									/>
								</Col>
								<Tooltip title="Добавить весь отдел" arrow placement="top">
									<div
										className="bg-info ms-4 b-r-5"
										style={{ height: "38px", padding: "7px 10px ", cursor: "pointer" }}
										onClick={() => setSelectObservers(handleAddDepartment(selectObservers[0]))}
									>
										<UserPlus />
									</div>
								</Tooltip>
							</div>
						</Col>
						<Row className="mt-2">
							<Col md="6">
								<Label className="col-sm-9 col-form-label">Статус заявки</Label>
								<Col sm="11">
									<Select
										placeholder="Выберите статус"
										options={[{ label: "status 1", value: "status 1" }]}
										value={status}
										className="js-example-basic-single col-sm-12"
										onChange={(option) => setStatus(option)}
									/>
								</Col>
							</Col>
							<Col md="6">
								<Label className="col-sm-9 col-form-label">Проект</Label>
								<Col sm="11">
									<Select
										placeholder="Выберите проект"
										options={[{ label: "status 1", value: "status 1" }]}
										value={project}
										className="js-example-basic-single col-sm-12"
										onChange={(option) => setProject(option)}
									/>
								</Col>
							</Col>
						</Row>
						<Btn attrBtn={{ className: "f-right mt-4", onClick: () => handleSave(item.id) }}>Сохранить</Btn>
					</Collapse>
				</Card>
			))}
			<Btn attrBtn={{ onClick: addItem, color: "light", className: "mt-3" }}>
				<Plus size={"18px"} style={{ margin: "0 4px -3px 0" }} />
				Добавить задачу
			</Btn>
		</>
	);
});

export default TaskManagement;
