import React, { Fragment, useState, useEffect } from 'react';
import { Container, Input, CardBody, Col, Card, Label, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { Trash2, Edit, LogOut } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import Select from 'react-select';

import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Breadcrumbs, Btn, H6, H5, P } from '../../../../../AbstractElements';
import { Plus } from 'react-feather';

import { MoneyManagementApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const PaymentItems = () => {
	const [paymentItems, setPaymentItems] = useState([]);
	const [paymentItemTypes, setPaymentItemTypes] = useState([]);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	
	useEffect(() => {
		axios
			.get(`${MoneyManagementApi}getPaymentItems`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				setPaymentItems(concatResults(response).paymentItems);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`${MoneyManagementApi}getPaymentItemTypes`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				let arr = [];
				concatResults(response).paymentItemTypes.forEach(type => {
					var item = { label: type.name, value: type.id };
					arr.push(item);
				});
				setPaymentItemTypes(arr);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const columns = [
		{
			name: '',
			selector: (row) => '',
			sortable: true,
			width: '1%',
		},
		{
			name: 'Название',
			selector: (row) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<LogOut size={'28px'} style={{ color: '#898989' }}></LogOut>
					<div className="ms-3">
						<H6>{row.name}</H6>
						<P>{row.comment}</P>
					</div>
				</div>
			),
			sortable: true,
			width: '60%',
		},
		{
			name: 'Тип статьи',
			selector: (row) => `${row.type.name}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deletePaymentItem(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deletePaymentItem = (id) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${MoneyManagementApi}deletePaymentItem`, {
						data: {
							Id: id
						}, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
					})
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении:', error);
					});
			}

		});
	};


	const [modal, setModal] = useState(false);
	const [edit, setEdit] = useState(false);

	const [name, setName] = useState('');
	const [id, setId] = useState('');
	const [comment, setComment] = useState('');
	const [selectedType, setSelectedType] = useState(null);

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.name);
			setId(ObjectId.id);
			setComment(ObjectId.comment);
			setSelectedType({ label: ObjectId.type.name, value: ObjectId.type.value });
			setEdit(true);
		} else {
			setName('');
			setId('');
			setComment('');
			setSelectedType(null);
			setEdit(false);
		}
		setModal(!modal);
	};


	const createPaymentItem = () => {
		if (name == '' || selectedType == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {

			const newItem = {
				id: uuid(),
				name: name,
				type: { Id: selectedType.value, Name: selectedType.label },
				comment: comment,
			};
			axios
				.post(`${MoneyManagementApi}createPaymentItem`, newItem, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Статья платежей создана!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(!modal);
				})
				.catch((error) => {
					console.error(error);
				});

		}
	}

	const updatePaymentItem = () => {

		if (name == '' || selectedType == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {

			const newItem = {
				id: id,
				name: name,
				type: { Id: selectedType.value, Name: selectedType.label },
				comment: comment,
			};

			axios
				.put(`${MoneyManagementApi}updatePaymentItem`, newItem, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Статья платежей обновлена!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(!modal);
				})
				.catch((error) => {
					console.error(error);
				});

		}
	}


	return (
		<Fragment>
			<Breadcrumbs mainTitle="Статьи платежей" parent="Отчёты" subParent="Финансы" title="Статьи платежей" />
			<Container fluid className="user-card">
				<Col md="12">
					<Card>
						<CardHeader>
							<div style={{ justifyContent: 'space-between', display: 'flex' }}>
								<Link to={`${process.env.PUBLIC_URL}/analytics/reports`} className="f-18">
									<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
								</Link>
								<Btn attrBtn={{ color: 'info', onClick: toggle }}>
									<Plus size={'18px'} style={{ margin: '0 3px -3px 0' }} />
									Добавить статью
								</Btn>
							</div>
						</CardHeader>
						<CardBody>
							<DataTable columns={columns} data={paymentItems} defaultSortField="Название" striped pagination paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
						</CardBody>
					</Card>
				</Col>
				<CommonModal isOpen={modal} title={edit ? 'Редактировать статью' : 'Добавить статью'} toggler={edit ? updatePaymentItem : createPaymentItem} togglerClose={toggle} closeText="Отмена" saveText="Добавить" size="md">
					<CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
						<Col className="row col-sm-12 mb-2">
							<Label className="col-sm-9 col-form-label">Название</Label>
							<Col sm="12">
								<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-2">
							<Label className="col-sm-9 col-form-label">Тип</Label>
							<Col sm="12">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={selectedType}
									options={paymentItemTypes}
									onChange={(option) => setSelectedType(option)}
								/>
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-2">
							<Label className="col-sm-9 col-form-label">Комментарий</Label>
							<Col sm="12">
								<textarea rows={'3'} className="form-control" type="tel" value={comment} onChange={(e) => setComment(e.target.value)} />
							</Col>
						</Col>
					</CardBody>
				</CommonModal>
			</Container>
		</Fragment>
	);
};
export default PaymentItems;
