import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit } from 'react-feather';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import uuid from 'react-uuid';
import Select from 'react-select';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Label, Input, CardBody, Col, InputGroup, InputGroupText, Row } from 'reactstrap';

import FilterComponent from './FilterComponent';
import CommonModal from '../../UiKits/Modals/common/modal';

import { ProductsApi } from '../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../concatResults';

const TableCatalog = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [data, setData] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		fetchDataAndFilter();
	}, []);
	const fetchDataAndFilter = async () => {
		try {
			const response = await axios.post(
				`${ProductsApi}getProducts`,
				{
					ProviderId: 0,
					Filter: "",
					Draw: 25,
					NameColumn: ""
				},
				{ headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } }
			);

			const newData = response.data;
			const categoryIds = queryParams.getAll('categoryId');
			if (categoryIds.length > 0) {
				const categoriesResponse = await axios.get(`${ProductsApi}getCategories`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
					params: { query: "", ids: JSON.stringify(categoryIds) }
				});
				const categoryReq = categoriesResponse.categories;
				const filteredProducts = newData.data.filter(
					(product) => categoryReq.some((category) => category.okpd === product.okpd)
				);
				setData(filteredProducts);

			}
			else {
				setData(newData.data);
			}
		}
		catch (error) {
			if (axios.isCancel(error)) {
				console.log('Request canceled:', error.message);
			} else {
				console.error('Ошибка при выполнении запроса:', error);
			}
		}
	};

	const columns = [
		{
			name: '№',
			selector: (row) => <Link>{row.id}</Link>,
			sortable: true,
			width: '130px',
		},
		{
			name: 'Название',
			selector: (row) => `${row.longtitle ? row.longtitle : ''}`,
			sortable: true,
			hide: 'sm',
			width: '200px',
		},
		{
			name: 'Описание',
			selector: (row) => `${row.description ? row.description : ''}`,
			sortable: true,
			width: '200px',
		},
		{
			name: 'Поставщик',
			selector: (row) => `${row.sku ? row.sku : ''}`,
			sortable: true,
			width: '130px',
		},
		{
			name: 'Артикул поставщика',
			selector: (row) => `${row.skuProvider ? row.skuProvider : ''}`,
			sortable: true,
			width: '210px',
		},
		{
			name: 'Артикул производителя',
			selector: (row) => `${row.skuManufacturer ? row.skuManufacturer : ''}`,
			sortable: true,
			width: '220px',
		},
		{
			name: 'Изображение',
			selector: (row) => ``,
			sortable: true,
			width: '150px',
		},
		{
			name: 'Цена',
			selector: (row) => `${row.price ? row.price : ''}`,
			sortable: true,
			width: '100px',
		},
		{
			name: 'Е/и',
			selector: (row) => `${row.unit ? row.unit : ''}`,
			sortable: true,
			width: '100px',
		},
		{
			name: 'Дата создания',
			selector: (row) => `${row.createdon ? row.createdon : ''}`,
			sortable: true,
			width: '160px',
		},
		{
			name: 'Категория(ОКПД)',
			selector: (row) => <div>{row.okpd ? row.okpd : ''}</div>,
			sortable: true,
			width: '180px',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteProduct(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
			width: '100px',
		},
	];

	const [title, setTitle] = useState('');
	const [description, setDescripton] = useState('');
	const [alias, setAlias] = useState('');
	const [okpd, setOkpd] = useState('');
	const [Id, setId] = useState('');
	const [price, setPrice] = useState('');
	const [modal, setModal] = useState(false);
	const [selectType, setSelectType] = useState({ label: null, value: null });
	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const [edit, setEdit] = useState(null);

	const toggle = (ObjectId) => {
		console.log(ObjectId);
		if (typeof ObjectId === 'object' && ObjectId.hasOwnProperty('id')) {
			setTitle(ObjectId.longtitle);
			setDescripton(ObjectId.description);
			setAlias(ObjectId.alias);
			setOkpd(ObjectId.okpd);
			setId(ObjectId.id);
			setPrice(ObjectId.price);
			setSelectType({ label: ObjectId.unit, value: ObjectId.unit });
			setEdit(true);
		} else {
			setTitle('');
			setDescripton('');
			setAlias('');
			setOkpd('');
			setId('');
			setPrice('');
			setSelectType({ label: null, value: null });
			setEdit(false);
		}
		setModal(!modal);
	};

	const deleteProduct = (id) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${ProductsApi}deleteProduct`, {
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
						data: {
							ProductId: id
						}
					})
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						fetchDataAndFilter();
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении:', error);
					});
			}
		});
	};

	const performProductOperation = (operationType) => {
		if (okpd === '' || alias === '' || description === '' || title === '' || price === '' || selectType === '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const product = {
				id: operationType === 'update' ? Id : uuid(),
				longtitle: title,
				alias: alias,
				description: description,
				price: price,
				okpd: okpd,
				unit: selectType.value
			};

			const apiEndpoint = operationType === 'update' ? 'updateProduct' : 'createProduct';
			const httpMethod = operationType === 'update' ? 'put' : 'post';

			axios({
				method: httpMethod,
				url: `${ProductsApi}${apiEndpoint}`,
				data: product,
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
			})
				.then((response) => {
					const successMessage = operationType === 'update' ? 'Товар обновлен!' : 'Товар добавлен!';
					toast.success(successMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(!modal);
					fetchDataAndFilter();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const createProduct = () => {
		performProductOperation('create');
	};

	const updateProduct = () => {
		performProductOperation('update');
	};

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable columns={columns} data={/*filteredItems*/data} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={edit ? updateProduct : createProduct} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Row className="mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
						</Col>
					</Row>
					<Row className="mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="11">
							<Input className="form-control" value={description} onChange={(e) => setDescripton(e.target.value)} />
						</Col>
					</Row>
					<Row>
						<Col className="row col-sm-8 mb-3">
							<Label className="col-sm-9 col-form-label">Цена</Label>
							<Col sm="11">
								<InputGroup>
									<Input className="form-control" value={price} onChange={(e) => setPrice(e.target.value)} />
									<InputGroupText>{'₽'}</InputGroupText>
								</InputGroup>
							</Col>
						</Col>
						<Col className="row col-sm-4 mb-2">
							<Label className="col-sm-9 col-form-label">Единица измерения</Label>
							<Col sm="11">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={selectType}
									options={[
										{ label: 'шт.', value: 'Юр. лицо' },
										{ label: 'уп.', value: 'ИП' },
										{ label: 'мм', value: 'Физ. лицо' },
										{ label: 'см', value: 'Физ. лицо' },
										{ label: 'м', value: 'Физ. лицо' },
										{ label: 'м³', value: 'Физ. лицо' },
										{ label: 'м²', value: 'Физ. лицо' },
										{ label: 'мл', value: 'Физ. лицо' },
										{ label: 'л', value: 'Физ. лицо' },
										{ label: 'г', value: 'Физ. лицо' },
										{ label: 'кг', value: 'Физ. лицо' },
									]}
									onChange={(option) => setSelectType(option)}
								/>
							</Col>
						</Col>
					</Row>
					<Row className="mb-3">
						<Label className="col-sm-9 col-form-label">Алиас</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={alias} onChange={(e) => setAlias(e.target.value)} />
						</Col>
					</Row>
					<Row className="mb-3">
						<Label className="col-sm-9 col-form-label">ОКПД</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={okpd} onChange={(e) => setOkpd(e.target.value)} />
						</Col>
					</Row>
					<Row className="mb-3">
						<div className="checkbox checkbox-primary">
							<Input id="checkbox-primary-1" type="checkbox" />
							<Label for="checkbox-primary-1">Отображать в меню</Label>
						</div>
					</Row>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableCatalog;
