// dashbaord
import Crypto from "../Components/Dashboard/Crypto";
import Default from "../Components/Dashboard/Default";
import Ecommerce from "../Components/Dashboard/Ecommerce";
import OnlineCourse from "../Components/Dashboard/OnlineCourse";
import Social from "../Components/Dashboard/Social";

// //widgets
import Chart from "../Components/Widgets/Chart";
import General from "../Components/Widgets/General";

// //page-layout
// import FooterLight from '../Components/PageLayout/FooterLight';

// import FooterDark from '../Components/PageLayout/FooterDark';
// //ui-kits
import Typography from "../Components/UiKits/Typography";
import Avatars from "../Components/UiKits/Avtars";
import HelperClasses from "../Components/UiKits/HelperClass";
import Grid from "../Components/UiKits/Grid";
import TagAndPills from "../Components/UiKits/TagAndPills";
import Progressbars from "../Components/UiKits/Progress";
import Modals from "../Components/UiKits/Modals";
import Alert from "../Components/UiKits/Alert";
import Popover from "../Components/UiKits/Popover";
import Tooltips from "../Components/UiKits/Tooltip";
import Spinners from "../Components/UiKits/Spinners";
import Dropdowns from "../Components/UiKits/Dropdown";
import Accordian from "../Components/UiKits/Accordian";
import TabBootstrap from "../Components/UiKits/Tabs/BootstrapTab";
import TabLine from "../Components/UiKits/Tabs/LineTab";
import Navs from "../Components/UiKits/Navs";
import Shadow from "../Components/UiKits/Shadows";
import List from "../Components/UiKits/Lists";

// //Bonus-ui
import Scrollable from "../Components/Bonus-Ui/Scrollable";
import TreeView from "../Components/Bonus-Ui/TreeView";
import BootstrapNotify from "../Components/Bonus-Ui/BootstrapNotify";
import RatingClass from "../Components/Bonus-Ui/Rating";
import Dropzones from "../Components/Bonus-Ui/Dropzones";
import Tours from "../Components/Bonus-Ui/Tour";
import SweetAlerts from "../Components/Bonus-Ui/SweetAlert";
import Carousels from "../Components/Bonus-Ui/Carousel";
import Ribbons from "../Components/Bonus-Ui/Ribbons";
import Paginations from "../Components/Bonus-Ui/Pagination";
import Steps from "../Components/Bonus-Ui/Steps";
import BreadcrumbClass from "../Components/Bonus-Ui/Breadcrumb";
import RangeSlider from "../Components/Bonus-Ui/RangeSlider";
import Imagecropper from "../Components/Bonus-Ui/ImageCropper";
import Sticky from "../Components/Bonus-Ui/Sticky";
import DragAndDrop from "../Components/Bonus-Ui/DragAndDrop";
import UploadImage from "../Components/Bonus-Ui/ImageUpload";
import BasicCards from "../Components/Bonus-Ui/Cards/BasicCards/index";
import CreativeCards from "../Components/Bonus-Ui/Cards/CreativeCards";
import TabCard from "../Components/Bonus-Ui/Cards/TabCard";
import Timeline1 from "../Components/Bonus-Ui/Timeline/Timeline1";
// // Icons
import WeatherIcon from "../Components/Icons/WeatherIcon";
import FeatherIcon from "../Components/Icons/FeatherIcon";
import ThemifyIcon from "../Components/Icons/ThemifyIcon";
import IcoIcon from "../Components/Icons/IcoIcon";
import FontawesomeIcon from "../Components/Icons/FontawesomeIcon";
import FlagIcon from "../Components/Icons/FlagIcon";

// // Buttons
import DefaultButton from "../Components/Buttons/Default";
import FlatButton from "../Components/Buttons/FlatBtn/index";
import EdgeButton from "../Components/Buttons/Edge/index";
import RaisedButton from "../Components/Buttons/RaisedBtn/index";
import GroupButton from "../Components/Buttons/GroupBtn/index";

// //chart
import ApexCharts from "../Components/Charts/ApexCharts";
import ChartJs from "../Components/Charts/ChartsJs";
import GoogleCharts from "../Components/Charts/GoogleCharts";

// //forms
import FormValidation from "../Components/Forms/FormControl/FormValidation";
import BaseInput from "../Components/Forms/FormControl/BaseInput";
import CheckboxandRadio from "../Components/Forms/FormControl/CheckRadio";
import InputGroups from "../Components/Forms/FormControl/InputGroups";
import MegaOption from "../Components/Forms/FormControl/MegaOption";
import Datepicker from "../Components/Forms/FormWidget/DatePicker";
import TypeaheadComp from "../Components/Forms/FormWidget/TypeaheadComponent/Typeahead";
import FormDefault from "../Components/Forms/FormLayout/Default";
import FormWizard from "../Components/Forms/FormLayout/FormWizard1/FormWizard1";
import BasicTables from "../Components/Tables/Reactstrap/BasicTable";

import DataTables from "../Components/Tables/DataTable";

// //Application
import Newproject from "../Components/Application/Project/Newproject";
import Project from "../Components/Application/Project/Project";
import ProjectView from "../Components/Application/Project/ProjectView";

import Chat from "../Components/Application/Chat/ChatApp";

// clients
import ClientList from "../Components/Application/Clients/ClientList";
import AccountCard from "../Components/Application/Clients/AccountCard";

// catalog
import Catalog from "../Components/Application/Catalog/Index";
import PackageOffers from "../Components/Application/PackageOffers/Index";
import PackageOffersView from "../Components/Application/PackageOffers/Preview/Index";
import ClientView from "../Components/Application/PackageOffers/ClientView/Index";
import Categories from "../Components/Application/Categories/Index";
import TemplatesApplicationForm from "../Components/Application/PackageOffers/TemplatesApplicationForm";
import CreateForm from "../Components/Application/PackageOffers/TemplatesApplicationForm/CreateForm";

// testing
import AddTesting from "../Components/Application/Testing/AddTesting";
import TestList from "../Components/Application/Testing/TestList";
import TestResults from "../Components/Application/Testing/TestResults";
import ViewTesting from "../Components/Application/Testing/ViewTesting";

//task reports
import TaskReports from "../Components/Application/TaskReports";
import AccountFormation from "../Components/Application/TaskReports/AccountFormation";
import EmployeePaymentDetails from "../Components/Application/TaskReports/EmployeePaymentDetails";
import Bonuses from "../Components/Application/TaskReports/Bonuses";
import ViewReport from "../Components/Application/TaskReports/ViewReport";

//call statistics
import CallStatistics from "../Components/Application/Telephony/CallStatistics";
import PBXList from "../Components/Application/Telephony/PBXList";
import TelephonyUsers from "../Components/Application/Telephony/TelephonyUsers";
import AccessRights from "../Components/Application/Telephony/AccessRights";
import SettingsPBX from "../Components/Application/Telephony/SettingsPBX";

//Services
import Services from "../Components/Application/Services/Index";
import ServiceCategories from "../Components/Application/Services/ServiceCategories/Index";
import AssigningEmployees from "../Components/Application/Services/EditAddService/Index";
import Resources from "../Components/Application/Services/Resources/Index";
import Instances from "../Components/Application/Services/Resources/Instances";

//Loyalty
import TypesCards from "../Components/Application/Loyalty/TypesCards";
import Discounts from "../Components/Application/Loyalty/Discounts";
import ReferralProgram from "../Components/Application/Loyalty/ReferralProgram";
import LoyaltyCards from "../Components/Application/Loyalty/LoyaltyCards";
import Certificates from "../Components/Application/Loyalty/Certificates";
import TypesCertificates from "../Components/Application/Loyalty/TypesCertificates";

//Delivery
import ListDeliveryOrders from "../Components/Application/Delivery/ListDeliveryOrders";
import FormBuilderWithDelivery from "../Components/Application/Delivery/FormBuilderWithDelivery";
import DeliveryParameters from "../Components/Application/Delivery/DeliveryParameters";
import CostDeliveryByCity from "../Components/Application/Delivery/CostDeliveryByCity";
import DeliveryServices from "../Components/Application/Delivery/DeliveryServices";
import AutomaticCalculation from "../Components/Application/Delivery/AutomaticCalculation";
import OptimalPrice from "../Components/Application/Delivery/OptimalPrice";

//learning
import Courses from "../Components/Application/Learning/Courses/Index";
import Lessons from "../Components/Application/Learning/Lessons/Index";
import BuildCourse from "../Components/Application/Learning/Courses/BuildCourse/Index";
import BuildLesson from "../Components/Application/Learning/Lessons/BuildLesson/Index";
import CheckWorks from "../Components/Application/Learning/CheckWorks/Index";
import ViewCourse from "../Components/Application/Learning/Courses/ViewCourse/Index";
import ViewLesson from "../Components/Application/Learning/Lessons/ViewLesson/Index";
import CreateDiploma from "../Components/Application/Learning/Diploma/CreateDiploma";
import DiplomasIssued from "../Components/Application/Learning/Diploma/DiplomasIssued";
import DiplomaTemplate from "../Components/Application/Learning/Diploma/CreateDiploma/DiplomaTemplate";
import TemplateCatalog from "../Components/Application/Learning/Diploma/TemplateCatalog";

// employees
import EmployeesList from "../Components/Application/Employees/EmployeesList";
import Qualifications from "../Components/Application/Employees/Qualifications";
import DepartmentManagement from "../Components/Application/Employees/DepartmentManagement";

//request
import RequestFromClients from "../Components/Application/Request/RequestFromClients";
import FormBuilder from "../Components/Application/Request/FormBuilder/FormBuilder";
import NewDemandForm from "../Components/Application/Request/NewDemandForm";
import DemandForm from "../Components/Application/Request/DemandForm";
import VisualEditorService from "../Components/Application/Request/FormBuilder/VisualEditorService";
import VisualEditorProducts from "../Components/Application/Request/FormBuilder copy/VisualEditorProducts";
import DemandFormProducts from "../Components/Application/Request/DemandFormProducts";

import Task from "../Components/Application/Task";
import NewTaskClass from "../Components/Application/Task/NewTask";
import ViewTask from "../Components/Application/Task/ViewTask";
import TodoContain from "../Components/Application/Todo";

// //Gallary
import ImageGallery from "../Components/Gallery/ImageGallery";
import MesonryDesc from "../Components/Gallery/MesonryDesc";
import MasonryGallery from "../Components/Gallery/MesonryGallery";
import ImageWithDesc from "../Components/Gallery/ImageWithDesc";
import ImageHover from "../Components/Gallery/ImageHover";

// //Blog
import BlogDetail from "../Components/Blog/BlogDetail";
import BlogPost from "../Components/Blog/BlogPost";
import BlogSingle from "../Components/Blog/BlogSingle";

// //Pages
import SamplePage from "../Components/Pages/Sample";
// // import SearchPage from '../Components/Application/SearchResult'

// //Book mark
import BookmarksContain from "../Components/Application/BookMarks";

// //User app
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersProfileContain from "../Components/Application/Users/UsersProfile";

// //Admin
import EditMenuEnter from "../Components/Application/Admin/EditMenuEnter";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import ModerationAccount from "../Components/Application/Admin/ModerationAccount";
import Companies from "../Components/Application/Admin/Companies/index";
import CompanyEdit from "../Components/Application/Admin/Companies/EditCompany/index";
import ProfileSettings from "../Components/Application/Admin/ProfileSettings";
import PlatformSettings from "../Components/Application/Admin/PlatformSettings";
import ModulesInformation from "../Components/Application/Admin/ModulesInformation/Index";
import PluginTemplates from "../Components/Application/Admin/PluginTemplates";
import IndustrySolutions from "../Components/Application/Admin/IndustrySolutions";

//News
import News from "../Components/Application/Admin/News/Index";
import CreateTemplateNews from "../Components/Application/Admin/News/TemplatesNews/CreateTemplateNews";
import TemplatesNews from "../Components/Application/Admin/News/TemplatesNews";

//Storage
import Storage from "../Components/Application/Storage/Index";
import StorageOperations from "../Components/Application/Storage/StorageOperations/Index";
import TechnologicalCard from "../Components/Application/Storage/TechnologicalCard/Index";
import AddEditCard from "../Components/Application/Storage/TechnologicalCard/AddEditCard";

// Analytics
import Reports from "../Components/Application/Analytics/Reports/Index";
import KeyIndicators from "../Components/Application/Analytics/KeyIndicators/Index";
import Settings from "../Components/Application/Analytics/Settings/Index";
import Fullness from "../Components/Application/Analytics/Reports/Fullness";
import OnEmployees from "../Components/Application/Analytics/Reports/OnEmployees";
import ByClients from "../Components/Application/Analytics/Reports/ByClients";
import Returnability from "../Components/Application/Analytics/Reports/Returnability";
import DynamicsByEmployees from "../Components/Application/Analytics/Reports/DynamicsByEmployees";
import OnServices from "../Components/Application/Analytics/Reports/OnServices";

// Analytics finance
import AccountsCashregisters from "../Components/Application/Analytics/Finance/AccountsCashregisters";
import PaymentItems from "../Components/Application/Analytics/Finance/PaymentItems";
import FinancialTransactions from "../Components/Application/Analytics/Finance/FinancialTransactions";
import NonFiscalReceipt from "../Components/Application/Analytics/Finance/NonFiscalReceipt";
import NonCashPayment from "../Components/Application/Analytics/Finance/NonCashPayment";

import SelectTemplate from "../Components/Application/Ecommerce/BusinessProcessCardTemplate/SelectTemplate/Index";
import SelectIndustry from "../Components/Application/Ecommerce/BusinessProcessCardTemplate/SelectIndustry/Index";
import SelectModules from "../Components/Application/Ecommerce/BusinessProcessCardTemplate/SelectModules/Index";
import PaymentOnAccount from "../Components/Application/Ecommerce/BusinessProcessCardTemplate/PaymentOnAccount";

// Document management
import RightsAccess from "../Components/Application/DocumentManagement/RightsAccess";
import Documents from "../Components/Application/DocumentManagement/Documents";
import TypesDocuments from "../Components/Application/DocumentManagement/TypesDocuments";
import KindDocuments from "../Components/Application/DocumentManagement/KindDocuments";
import AddEditDocument from "../Components/Application/DocumentManagement/Documents/AddEditDocument";
import AddTemplate from "../Components/Application/DocumentManagement/KindDocuments/AddTemplate";

// services
import TwinOverwatch from "../Components/Servises/TwinOverwatch";

// //social app
import SocialAppContain from "../Components/Application/SocialApp";

// //calender
import CalenderContain from "../Components/Application/Calender/Index";
import CalendarList from "../Components/Application/Calender/CalendarList";
import CalendarListItem from "../Components/Application/Calender/CalendarListItem";
import PaymentForVisit from "../Components/Application/Calender/PaymentForVisit";
import StatusHistory from "../Components/Application/Calender/StatusHistory";

//goods
import PurchaseHistory from "../Components/Application/Goods/PurchaseHistory/Index";

//groups
import ListGroups from "../Components/Application/Groups/ListGroups";
import AddGroup from "../Components/Application/Groups/AddGroup";
import ViewGroup from "../Components/Application/Groups/ViewGroup";

//Counterparties
import ListCounterparties from "../Components/Application/Counterparties/ListCounterparties/Index";
import CounterpartiesRoles from "../Components/Application/Counterparties/CounterpartiesRoles/Index";
import TransactionsCounterparty from "../Components/Application/Counterparties/TransactionsCounterparty/Index";

//Specifications
import ListSpecifications from "../Components/Application/Specifications/Index";
import SpecificationDetails from "../Components/Application/Specifications/SpecificationDetails/Index";

// Salary
import CalculationCriteria from "../Components/Application/Salary/CalculationCriteria";
import CalculationPerDay from "../Components/Application/Salary/CalculationPerDay";
import CalculationPerPeriod from "../Components/Application/Salary/CalculationPerPeriod";
import CalculationRules from "../Components/Application/Salary/CalculationRules";
import CalculationSchemes from "../Components/Application/Salary/CalculationSchemes";
import AddEditSchemes from "../Components/Application/Salary/CalculationSchemes/AddEditSchemes";

// sites
import Sites from "../Components/Application/Sites/Index";
import InstalledTemplates from "../Components/Application/Sites/InstalledTemplates";
import SiteBuilder from "../Components/Application/Sites/SiteBuilder/Index";
import IndexViewPage from "../Components/Application/Sites/SiteBuilder/EditPagesList/IndexViewPage";
import EditPage from "../Components/Application/Sites/SiteBuilder/EditPagesList/EditPage";
import ClientViewCustomSite from "../Components/Application/Sites/SiteBuilder/ClientViewCustomSite/Index";
import SiteTemplateCatalog from "../Components/Application/Sites/SiteTemplateCatalog";

// //Email
import MailInboxContain from "../Components/Application/Email/MailInbox";
import MailSettings from "../Components/Application/Email/MailSettings";
import CorporateMailAPI from "../Components/Application/Email/CorporateMailAPI";
import Yandex360 from "../Components/Application/Email/Yandex360";
import TokenAccept from "../Components/Application/Email/Yandex360/TokenAccept";

// //File Manager
import FileManagerContain from "../Components/Application/FileManager";
import SearchResultContain from "../Components/Application/SearchResult";
import TariffManager from "../Components/Application/FileManager/TariffManager/Index";

// //E-commerce
import Products from "../Components/Application/Ecommerce/Products";
import ProductCart from "../Components/Application/Ecommerce/ProductCart";
import WishList from "../Components/Application/Ecommerce/Wishlist";
import CheckOut from "../Components/Application/Ecommerce/CheckOut";
import Invoice from "../Components/Application/Ecommerce/Invoice";
import OrderHistory from "../Components/Application/Ecommerce/OrderHistory";
import ProductPage from "../Components/Application/Ecommerce/ProductPage";
import PaymentDetails from "../Components/Application/Ecommerce/PaymentDetails";
import ProductListContain from "../Components/Application/Ecommerce/ProductList";
import DraggableContain from "../Components/Application/Calender/DraggableCalender";

// //Job Search
import JobCards from "../Components/JobSearch/JobCardView";
import ListView from "../Components/JobSearch/ListView";
import JobDetails from "../Components/JobSearch/JobDetail";
import Apply from "../Components/JobSearch/Apply";

// //Learning
import CreateDiagram from "../Components/Learning/CreateDiagram";
import DiagramList from "../Components/Learning/DiagramList";

// //Map
import GoogleMaps from "../Components/Map/GoogleMap";
import PigeonMap from "../Components/Map/Pigeon Map";

// //Editor
import CkEditorContain from "../Components/Editor/CkEditor";
import MdeEDitorContain from "../Components/Editor/MDEEditor/MDEEditor";
import ACEEditorContain from "../Components/Editor/AceCodeEditor/ACECodeEditor";

import KnowledgeBaseContain from "../Components/Knowledgebase/KnowledgeBase";
import KnowledgeCategoryContain from "../Components/Knowledgebase/KnowledgeCategory";
import KnowledgeDetailContain from "../Components/Knowledgebase/KnowledgeDetails";
import SupportTickitContain from "../Components/SupportTicket";
import DateTimeContain from "../Components/Forms/FormWidget/DateandTime";
import FormDateRangepickerContain from "../Components/Forms/FormWidget/FormDateRangePicker";
import FormSelectContain from "../Components/Forms/FormWidget/FormSelect2";
import FormSwitchContain from "../Components/Forms/FormWidget/FormSwitch";
import FormTouchspinContain from "../Components/Forms/FormWidget/FormTouchspin";
import FormClipboardContain from "../Components/Forms/FormWidget/FormClipboard";
import FAQContent from "../Components/FAQ";
import Reminders from "../Components/Application/Admin/Reminders";
import Specifications from "../Components/Application/Specifications/Index";
import ModulesRightsAccess from "../Components/Application/GlobalManagementPlatform/Administrating/ModulesRightsAccess";
import ModuleSectionsAccessRights
	from "../Components/Application/GlobalManagementPlatform/Administrating/ModuleSectionsAccessRights";
import ManagingUserGroups from "../Components/Application/GlobalManagementPlatform/Administrating/ManagingUserGroups";
import ManageCompanyGroups from "../Components/Application/GlobalManagementPlatform/Administrating/ManageCompanyGroups";
import InteractivePlanMapEditor from "../Components/Application/Widgets/InteractivePlanMap/InteractivePlanMapEditor";
import InteractivePlanMaps from "../Components/Application/Widgets/InteractivePlanMap/InteractivePlanMaps";
import ManagePlanMapObjects from "../Components/Application/Widgets/InteractivePlanMap/ManagePlanMapObjects";
import PlanMapObjectTypes from "../Components/Application/Widgets/InteractivePlanMap/PlanMapObjectTypes";
import InteractivePlanMapViewer from "../Components/Application/Widgets/InteractivePlanMap/InteractivePlanMapViewer";
import PlanMapObjectCharacteristics from "../Components/Application/Widgets/InteractivePlanMap/PlanMapObjectCharacteristics";
import PlanMapObjectCharacteristicsValues from "../Components/Application/Widgets/InteractivePlanMap/PlanMapObjectCharacteristicsValues";
import ImportMapObject from "../Components/Application/Widgets/InteractivePlanMap/PlanMapObjectCharacteristicsValues/ImportMapObject";
import InteractivePlanMapWidgets from "../Components/Application/Widgets/InteractivePlanMap/InteractivePlanMapWidgets";
import BlankIMapViewer from "../Components/Application/Widgets/InteractivePlanMap/InteractivePlanMapViewer/BlankViewer";
import InteractivePlanMapWidgetUpdate from "../Components/Application/Widgets/InteractivePlanMap/InteractivePlanMapWidgets/UpdateWidget";


// //ExportData
import ServicesFieldsSettings from "../Components/Application/DataExport/ServicesFieldsSettings/Index";
import ResponsesSettings from "../Components/Application/DataExport/ResponsesSettings/Index";
import LayoutDataTypes from "../Components/Application/DataExport/LayoutDataTypes/Index";
import FileDataTypes from "../Components/Application/DataExport/FileDataTypes/Index";
import FieldsMapsSettings from "../Components/Application/DataExport/FieldsMapsSettings/Index";
import UploadLayout from "../Components/Application/DataExport/LayoutDataTypes/UploadLayout";
import ClientsCardSettings from "../Components/Application/Clients/ClientsCardSettings";
import ClientsTypeList from "../Components/Application/Clients/ClientsTypeList";
import ClientsCategoryList from "../Components/Application/Clients/ClientsCategoryList";
import DocumentTypesList from "../Components/Application/Clients/DocumentTypesList";
import DocumentTypeEditor from "../Components/Application/Clients/DocumentTypeEditor";
import EditClientDocument from "../Components/Application/Clients/EditClientDocument";
import CounterpartyCard from "../Components/Application/Counterparties/CounterpartyCard";
import CounterpartiesSettings from "../Components/Application/Counterparties/CounterpartiesSettings";

export const routes = [
	{ path: `${process.env.PUBLIC_URL}/dashboard/default`, Component: <Default /> },
	{ path: `${process.env.PUBLIC_URL}/dashboard/e-commerce`, Component: <Ecommerce /> },
	{ path: `${process.env.PUBLIC_URL}/dashboard/online-course`, Component: <OnlineCourse /> },
	{ path: `${process.env.PUBLIC_URL}/dashboard/crypto`, Component: <Crypto /> },
	{ path: `${process.env.PUBLIC_URL}/dashboard/social`, Component: <Social /> },

	{ path: `${process.env.PUBLIC_URL}/widgets/general`, Component: <General /> },
	{ path: `${process.env.PUBLIC_URL}/widgets/chart`, Component: <Chart /> },

	{ path: `${process.env.PUBLIC_URL}/ui-kits/typography`, Component: <Typography /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/avatar`, Component: <Avatars /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/helper-class`, Component: <HelperClasses /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/grids`, Component: <Grid /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills`, Component: <TagAndPills /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/progress`, Component: <Progressbars /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/modal`, Component: <Modals /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/alert`, Component: <Alert /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/popover`, Component: <Popover /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/tooltips`, Component: <Tooltips /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/spinner`, Component: <Spinners /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/dropdown`, Component: <Dropdowns /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/accordion`, Component: <Accordian /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/tabs/bootstrap`, Component: <TabBootstrap /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/tabs/line`, Component: <TabLine /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/navs`, Component: <Navs /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/shadow`, Component: <Shadow /> },
	{ path: `${process.env.PUBLIC_URL}/ui-kits/list`, Component: <List /> },

	{ path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`, Component: <Scrollable /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/tree-view`, Component: <TreeView /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`, Component: <BootstrapNotify /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/rating`, Component: <RatingClass /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`, Component: <Dropzones /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/tour`, Component: <Tours /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/sweet-alert`, Component: <SweetAlerts /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`, Component: <Carousels /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`, Component: <Ribbons /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`, Component: <Paginations /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/steps`, Component: <Steps /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`, Component: <BreadcrumbClass /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/rangeslider`, Component: <RangeSlider /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/imagecropper`, Component: <Imagecropper /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/stickynotes`, Component: <Sticky /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/drag_and_drop`, Component: <DragAndDrop /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`, Component: <UploadImage /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/card/basiccards`, Component: <BasicCards /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativecards`, Component: <CreativeCards /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabcard`, Component: <TabCard /> },
	{ path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`, Component: <Timeline1 /> },

	{ path: `${process.env.PUBLIC_URL}/icons/flag_icons`, Component: <FlagIcon /> },
	{ path: `${process.env.PUBLIC_URL}/icons/fontawesome_icon`, Component: <FontawesomeIcon /> },
	{ path: `${process.env.PUBLIC_URL}/icons/ico_icon`, Component: <IcoIcon /> },
	{ path: `${process.env.PUBLIC_URL}/icons/themify_icons`, Component: <ThemifyIcon /> },
	{ path: `${process.env.PUBLIC_URL}/icons/feather_icons`, Component: <FeatherIcon /> },
	{ path: `${process.env.PUBLIC_URL}/icons/weather_icons`, Component: <WeatherIcon /> },

	{ path: `${process.env.PUBLIC_URL}/buttons/simplebutton`, Component: <DefaultButton /> },
	{ path: `${process.env.PUBLIC_URL}/buttons/flat`, Component: <FlatButton /> },
	{ path: `${process.env.PUBLIC_URL}/buttons/edge`, Component: <EdgeButton /> },
	{ path: `${process.env.PUBLIC_URL}/buttons/raised`, Component: <RaisedButton /> },
	{ path: `${process.env.PUBLIC_URL}/buttons/group`, Component: <GroupButton /> },

	{ path: `${process.env.PUBLIC_URL}/charts/apex`, Component: <ApexCharts /> },
	{ path: `${process.env.PUBLIC_URL}/charts/chartJs`, Component: <ChartJs /> },
	{ path: `${process.env.PUBLIC_URL}/charts/google`, Component: <GoogleCharts /> },

	{ path: `${process.env.PUBLIC_URL}/forms/controls/validation`, Component: <FormValidation /> },
	{ path: `${process.env.PUBLIC_URL}/register-company`, Component: <BaseInput /> },
	{
		path: `${process.env.PUBLIC_URL}/forms/controls/radio-checkbox`,
		Component: <CheckboxandRadio />,
	},
	{ path: `${process.env.PUBLIC_URL}/forms/controls/group`, Component: <InputGroups /> },
	{ path: `${process.env.PUBLIC_URL}/forms/controls/megaoption`, Component: <MegaOption /> },
	{ path: `${process.env.PUBLIC_URL}/forms/widget/datepicker`, Component: <Datepicker /> },
	{ path: `${process.env.PUBLIC_URL}/forms/widget/typeahead`, Component: <TypeaheadComp /> },
	{
		path: `${process.env.PUBLIC_URL}/forms/widget/datetimepicker`,
		Component: <DateTimeContain />,
	},
	{
		path: `${process.env.PUBLIC_URL}/forms/widget/rangepicker`,
		Component: <FormDateRangepickerContain />,
	},
	{ path: `${process.env.PUBLIC_URL}/forms/widget/select2`, Component: <FormSelectContain /> },
	{ path: `${process.env.PUBLIC_URL}/forms/widget/switch`, Component: <FormSwitchContain /> },
	{
		path: `${process.env.PUBLIC_URL}/forms/widget/touchspin`,
		Component: <FormTouchspinContain />,
	},
	{
		path: `${process.env.PUBLIC_URL}/forms/widget/clipboard`,
		Component: <FormClipboardContain />,
	},

	{ path: `${process.env.PUBLIC_URL}/forms/layout/formDefault`, Component: <FormDefault /> },
	{ path: `${process.env.PUBLIC_URL}/forms/layout/formWizard`, Component: <FormWizard /> },

	// //Tables
	{
		path: `${process.env.PUBLIC_URL}/table/reactstraptable/basictable`,
		Component: <BasicTables />,
	},
	{ path: `${process.env.PUBLIC_URL}/table/datatable`, Component: <DataTables /> },

	// //Applicatiion
	{ path: `${process.env.PUBLIC_URL}/app/project/project-list`, Component: <Project /> },
	{ path: `${process.env.PUBLIC_URL}/app/project/edit-project`, Component: <Newproject /> },
	{ path: `${process.env.PUBLIC_URL}/app/project/tasks-canban`, Component: <ProjectView /> },
	{ path: `${process.env.PUBLIC_URL}/app/task`, Component: <Task /> },
	{ path: `${process.env.PUBLIC_URL}/app/task/create-task`, Component: <NewTaskClass /> },
	{ path: `${process.env.PUBLIC_URL}/app/task/view-task`, Component: <ViewTask /> },

	{ path: `${process.env.PUBLIC_URL}/app/chat-app/chats`, Component: <Chat /> },

	// clients
	{ path: `${process.env.PUBLIC_URL}/app/clients-app/clients-list`, Component: <ClientList /> },
	{ path: `${process.env.PUBLIC_URL}/app/clients-app/clients-categories-list`, Component: <ClientsCategoryList /> },
	{ path: `${process.env.PUBLIC_URL}/app/clients-app/clients-types-list`, Component: <ClientsTypeList /> },
	{ path: `${process.env.PUBLIC_URL}/app/clients-app/client-card-settings`, Component: <ClientsCardSettings /> },
	{ path: `${process.env.PUBLIC_URL}/app/clients-app/client-documents-types`, Component: <DocumentTypesList /> },
	{ path: `${process.env.PUBLIC_URL}/app/clients-app/client-card`, Component: <AccountCard /> },
	{ path: `${process.env.PUBLIC_URL}/app/clients-app/document-type-edit`, Component: <DocumentTypeEditor /> },
	{ path: `${process.env.PUBLIC_URL}/app/clients-app/client-document-edit`, Component: <EditClientDocument />},

	// Services
	{ path: `${process.env.PUBLIC_URL}/service-app/services`, Component: <Services /> },
	{
		path: `${process.env.PUBLIC_URL}/service-app/service-categories`,
		Component: <ServiceCategories />,
	},
	{
		path: `${process.env.PUBLIC_URL}/service-app/edit-service`,
		Component: <AssigningEmployees />,
	},
	{ path: `${process.env.PUBLIC_URL}/service-app/resources`, Component: <Resources /> },
	{
		path: `${process.env.PUBLIC_URL}/service-app/resources/edit-instances`,
		Component: <Instances />,
	},

	//Loyalty
	{ path: `${process.env.PUBLIC_URL}/loyalty/types-of-cards`, Component: <TypesCards /> },
	{ path: `${process.env.PUBLIC_URL}/loyalty/loyalty-cards`, Component: <LoyaltyCards /> },
	{ path: `${process.env.PUBLIC_URL}/loyalty/discounts`, Component: <Discounts /> },
	{ path: `${process.env.PUBLIC_URL}/loyalty/referral-program`, Component: <ReferralProgram /> },
	{
		path: `${process.env.PUBLIC_URL}/loyalty/types-of-certificates`,
		Component: <TypesCertificates />,
	},
	{ path: `${process.env.PUBLIC_URL}/loyalty/certificates`, Component: <Certificates /> },

	//task reports
	{ path: `${process.env.PUBLIC_URL}/app/task-reports`, Component: <TaskReports /> },
	{ path: `${process.env.PUBLIC_URL}/app/task-reports/view`, Component: <ViewReport /> },
	{ path: `${process.env.PUBLIC_URL}/app/account-formation`, Component: <AccountFormation /> },
	{
		path: `${process.env.PUBLIC_URL}/app/employee-payment-details`,
		Component: <EmployeePaymentDetails />,
	},
	{ path: `${process.env.PUBLIC_URL}/app/bonuses`, Component: <Bonuses /> },

	//Counterparties
	{
		path: `${process.env.PUBLIC_URL}/counterparties/list-counterparties`,
		Component: <ListCounterparties />,
	},
	{
		path: `${process.env.PUBLIC_URL}/counterparties/counterparties-roles`,
		Component: <CounterpartiesRoles />,
	},
	{
		path: `${process.env.PUBLIC_URL}/counterparties/transactions-counterparty`,
		Component: <TransactionsCounterparty />,
	},
	{
		path: `${process.env.PUBLIC_URL}/counterparties/counterparty-info`,
		Component: <CounterpartyCard />,
	},
	{
		path: `${process.env.PUBLIC_URL}/counterparties/counterparty-settings`,
		Component: <CounterpartiesSettings />
	},

	//Specifications
	{
		path: `${process.env.PUBLIC_URL}/specifications/list-specifications`,
		Component: <ListSpecifications />,
	},
	{
		path: `${process.env.PUBLIC_URL}/specifications/specification-details`,
		Component: <SpecificationDetails />,
	},
	// Salary
	{
		path: `${process.env.PUBLIC_URL}/salary/calculation-per-day`,
		Component: <CalculationPerDay />,
	},
	{
		path: `${process.env.PUBLIC_URL}/salary/calculation-per-period`,
		Component: <CalculationPerPeriod />,
	},
	{ path: `${process.env.PUBLIC_URL}/salary/calculation-rules`, Component: <CalculationRules /> },
	{
		path: `${process.env.PUBLIC_URL}/salary/calculation-criteria`,
		Component: <CalculationCriteria />,
	},
	{
		path: `${process.env.PUBLIC_URL}/salary/calculation-schemes`,
		Component: <CalculationSchemes />,
	},
	{
		path: `${process.env.PUBLIC_URL}/salary/calculation-schemes/edit-schemes`,
		Component: <AddEditSchemes />,
	},

	// testing
	{ path: `${process.env.PUBLIC_URL}/app/add-testing`, Component: <AddTesting /> },
	{ path: `${process.env.PUBLIC_URL}/app/test-list`, Component: <TestList /> },
	{ path: `${process.env.PUBLIC_URL}/app/test-results`, Component: <TestResults /> },
	{ path: `${process.env.PUBLIC_URL}/app/view-testing`, Component: <ViewTesting /> },

	//call statistics
	{
		path: `${process.env.PUBLIC_URL}/telephony-app/call-statistics`,
		Component: <CallStatistics />,
	},
	{ path: `${process.env.PUBLIC_URL}/telephony-app/access-rights`, Component: <AccessRights /> },
	{ path: `${process.env.PUBLIC_URL}/telephony-app/users`, Component: <TelephonyUsers /> },
	{ path: `${process.env.PUBLIC_URL}/telephony-app/pbx-list`, Component: <PBXList /> },
	{
		path: `${process.env.PUBLIC_URL}/telephony-app/pbx-connection-settings`,
		Component: <SettingsPBX />,
	},

	// employees
	{ path: `${process.env.PUBLIC_URL}/app/employees`, Component: <EmployeesList /> },
	{ path: `${process.env.PUBLIC_URL}/app/qualifications`, Component: <Qualifications /> },
	{ path: `${process.env.PUBLIC_URL}/app/department-management`, Component: <DepartmentManagement /> },

	//delivery
	{
		path: `${process.env.PUBLIC_URL}/app/delivery/list-of-delivery-orders`,
		Component: <ListDeliveryOrders />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/delivery/form-builder-with-delivery`,
		Component: <FormBuilderWithDelivery />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/delivery/delivery-parameters`,
		Component: <DeliveryParameters />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/delivery/cost-of-delivery-by-city`,
		Component: <CostDeliveryByCity />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/delivery/delivery-services`,
		Component: <DeliveryServices />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/delivery/servers-for-automatic-calculation`,
		Component: <AutomaticCalculation />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/delivery/parameters-for-optimal-delivery-price`,
		Component: <OptimalPrice />,
	},

	//request
	{ path: `${process.env.PUBLIC_URL}/app/request/form-builder`, Component: <FormBuilder /> },
	{
		path: `${process.env.PUBLIC_URL}/app/request/form-builder/visual-editor`,
		Component: <VisualEditorService />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/request/form-builder/visual-editor/product-order`,
		Component: <VisualEditorProducts />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/request/request-from-clients`,
		Component: <RequestFromClients />,
	},
	//{ path: `${process.env.PUBLIC_URL}/app/request/new-demand`, Component: <NewDemandForm /> },
	{ path: `${process.env.PUBLIC_URL}/app/request/new-demand`, Component: <DemandForm /> },
	{
		path: `${process.env.PUBLIC_URL}/app/request/new-demand/ordering-goods`,
		Component: <DemandFormProducts />,
	},

	{ path: `${process.env.PUBLIC_URL}/app/bookmark`, Component: <BookmarksContain /> },
	{ path: `${process.env.PUBLIC_URL}/app/todo-app/todo`, Component: <TodoContain /> },

	{ path: `${process.env.PUBLIC_URL}/app/users/profile`, Component: <UsersProfileContain /> },
	{ path: `${process.env.PUBLIC_URL}/app/users/edit`, Component: <UsersEditContain /> },
	{ path: `${process.env.PUBLIC_URL}/app/social-app`, Component: <SocialAppContain /> },

	//widgets
	{ path: `${process.env.PUBLIC_URL}/widgets/edit-plan-map`, Component: <InteractivePlanMapEditor /> },
	{ path: `${process.env.PUBLIC_URL}/widgets/view-plan-map`, Component: <InteractivePlanMapViewer /> },
	{ path: `${process.env.PUBLIC_URL}/widgets/plan-map-widgets`, Component: <InteractivePlanMapWidgets /> },
	{ path: `${process.env.PUBLIC_URL}/widgets/plan-map-widgets-update`, Component: <InteractivePlanMapWidgetUpdate /> },
	{ path: `${process.env.PUBLIC_URL}/widgets/interactive-plan-maps`, Component: <InteractivePlanMaps /> },
	{ path: `${process.env.PUBLIC_URL}/widgets/interactive-plan-maps-objects`, Component: <ManagePlanMapObjects /> },
	{ path: `${process.env.PUBLIC_URL}/widgets/interactive-plan-maps-objects-types`, Component: <PlanMapObjectTypes /> },
	{ path: `${process.env.PUBLIC_URL}/widgets/interactive-plan-maps-object-characteristics`, Component: <PlanMapObjectCharacteristics /> },
	{ path: `${process.env.PUBLIC_URL}/widgets/interactive-plan-maps-object-values`, Component: <PlanMapObjectCharacteristicsValues />},
	{ path: `${process.env.PUBLIC_URL}/widgets/interactive-plan-maps-object-values/import-map-object`, Component: <ImportMapObject /> },


	// Admin
	{ path: `${process.env.PUBLIC_URL}/editor-master-enter`, Component: <UsersCardssContain /> },
	{ path: `${process.env.PUBLIC_URL}/editor-menu-master-enter`, Component: <EditMenuEnter /> },
	//{ path: `${process.env.PUBLIC_URL}/moderation-account`, Component: <ModerationAccount /> },
	{ path: `${process.env.PUBLIC_URL}/companies`, Component: <Companies /> },
	{ path: `${process.env.PUBLIC_URL}/edit-company`, Component: <CompanyEdit /> },
	{ path: `${process.env.PUBLIC_URL}/reminders`, Component: <Reminders /> },
	{ path: `${process.env.PUBLIC_URL}/profile-settings`, Component: <ProfileSettings /> },
	{ path: `${process.env.PUBLIC_URL}/platform-settings`, Component: <PlatformSettings /> },
	{
		path: `${process.env.PUBLIC_URL}/information-about-modules`,
		Component: <ModulesInformation />,
	},
	{ path: `${process.env.PUBLIC_URL}/industry-solutions`, Component: <IndustrySolutions /> },
	{ path: `${process.env.PUBLIC_URL}/plugin-templates`, Component: <PluginTemplates /> },

	//Administration
	{ path: `${process.env.PUBLIC_URL}/administration/modules-right-access`, Component: <ModulesRightsAccess /> },
	{ path: `${process.env.PUBLIC_URL}/administration/modules-section-access`, Component: <ModuleSectionsAccessRights /> },
	{ path: `${process.env.PUBLIC_URL}/administration/modules-user-groups`, Component: <ManagingUserGroups /> },
	{ path: `${process.env.PUBLIC_URL}/administration/company-user-groups`, Component: <ManageCompanyGroups /> },

	//News
	{ path: `${process.env.PUBLIC_URL}/news`, Component: <News /> },
	{ path: `${process.env.PUBLIC_URL}/news/create-news-template`, Component: <CreateTemplateNews /> },
	{ path: `${process.env.PUBLIC_URL}/news/news-template`, Component: <TemplatesNews /> },

	//Storage
	{ path: `${process.env.PUBLIC_URL}/storage/storage-list`, Component: <Storage /> },
	{
		path: `${process.env.PUBLIC_URL}/storage/technological-card`,
		Component: <TechnologicalCard />,
	},
	{
		path: `${process.env.PUBLIC_URL}/storage/storage-operations`,
		Component: <StorageOperations />,
	},
	{
		path: `${process.env.PUBLIC_URL}/storage/edit-technological-card`,
		Component: <AddEditCard />,
	},

	// Document management
	{ path: `${process.env.PUBLIC_URL}/counterparties/documents`, Component: <Documents /> },
	{
		path: `${process.env.PUBLIC_URL}/counterparties/documents/edit-document`,
		Component: <AddEditDocument />,
	},
	{
		path: `${process.env.PUBLIC_URL}/counterparties/types-documents`,
		Component: <TypesDocuments />,
	},
	{
		path: `${process.env.PUBLIC_URL}/counterparties/kind-documents`,
		Component: <KindDocuments />,
	},
	{ path: `${process.env.PUBLIC_URL}/counterparties/rights-access`, Component: <RightsAccess /> },
	{
		path: `${process.env.PUBLIC_URL}/counterparties/documents/add-template`,
		Component: <AddTemplate />,
	},

	// Analytics
	{ path: `${process.env.PUBLIC_URL}/analytics/key-indicators`, Component: <KeyIndicators /> },
	{ path: `${process.env.PUBLIC_URL}/analytics/reports`, Component: <Reports /> },
	{ path: `${process.env.PUBLIC_URL}/analytics/settings`, Component: <Settings /> },
	{ path: `${process.env.PUBLIC_URL}/analytics/reports/fullness`, Component: <Fullness /> },
	{
		path: `${process.env.PUBLIC_URL}/analytics/reports/on-employees`,
		Component: <OnEmployees />,
	},
	{
		path: `${process.env.PUBLIC_URL}/analytics/reports/returnability`,
		Component: <Returnability />,
	},
	{
		path: `${process.env.PUBLIC_URL}/analytics/reports/dynamics-by-employees`,
		Component: <DynamicsByEmployees />,
	},
	{ path: `${process.env.PUBLIC_URL}/analytics/reports/on-services`, Component: <OnServices /> },
	{ path: `${process.env.PUBLIC_URL}/analytics/reports/by-clients`, Component: <ByClients /> },

	// Analytics finance
	{
		path: `${process.env.PUBLIC_URL}/analytics/reports/accounts-and-cashregisters`,
		Component: <AccountsCashregisters />,
	},
	{
		path: `${process.env.PUBLIC_URL}/analytics/reports/payment-items`,
		Component: <PaymentItems />,
	},
	{
		path: `${process.env.PUBLIC_URL}/analytics/reports/financial-transactions`,
		Component: <FinancialTransactions />,
	},
	{
		path: `${process.env.PUBLIC_URL}/analytics/reports/non-fiscal-receipt`,
		Component: <NonFiscalReceipt />,
	},
	{
		path: `${process.env.PUBLIC_URL}/analytics/reports/non-cash-payment`,
		Component: <NonCashPayment />,
	},

	// Catalog
	{ path: `${process.env.PUBLIC_URL}/app/catalog`, Component: <Catalog /> },
	{ path: `${process.env.PUBLIC_URL}/app/package-offers`, Component: <PackageOffers /> },
	{
		path: `${process.env.PUBLIC_URL}/app/package-offers/preview`,
		Component: <PackageOffersView />,
	},
	//{ path: `${process.env.PUBLIC_URL}/app/package-offers/client-view`, Component: <ClientView /> , role: 'unauthorized' },
	{ path: `${process.env.PUBLIC_URL}/app/categories`, Component: <Categories /> },
	{ path: `${process.env.PUBLIC_URL}/app/request/templates-application-form`, Component: <TemplatesApplicationForm /> },
	{ path: `${process.env.PUBLIC_URL}/app/request/create-application-form`, Component: <CreateForm /> },

	{
		path: `${process.env.PUBLIC_URL}/business-process-template/select-industry`,
		Component: <SelectIndustry />,
	},
	{
		path: `${process.env.PUBLIC_URL}/business-process-template/select-modules`,
		Component: <SelectModules />,
	},
	{
		path: `${process.env.PUBLIC_URL}/business-process-template/select-template`,
		Component: <SelectTemplate />,
	},
	{
		path: `${process.env.PUBLIC_URL}/business-process-template/payment`,
		Component: <PaymentOnAccount />,
	},

	// services
	{ path: `${process.env.PUBLIC_URL}/TwinOverwatch/dashboard`, Component: <TwinOverwatch /> },

	//calendar
	{
		path: `${process.env.PUBLIC_URL}/app/calendar/personal-calendar`,
		Component: <CalenderContain />,
	},
	{ path: `${process.env.PUBLIC_URL}/app/calendar/calendar-list`, Component: <CalendarList /> },
	{
		path: `${process.env.PUBLIC_URL}/app/calendar/calendar-list/view`,
		Component: <CalendarListItem />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/request/payment-for-visit`,
		Component: <PaymentForVisit />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/calendar/personal-calendar/status-history`,
		Component: <StatusHistory />,
	},

	//learning
	{ path: `${process.env.PUBLIC_URL}/app/course-list`, Component: <Courses /> },
	{ path: `${process.env.PUBLIC_URL}/app/build-course`, Component: <BuildCourse /> },
	{ path: `${process.env.PUBLIC_URL}/app/view-course`, Component: <ViewCourse /> },
	{ path: `${process.env.PUBLIC_URL}/app/lesson-list`, Component: <Lessons /> },
	{ path: `${process.env.PUBLIC_URL}/app/build-lesson`, Component: <BuildLesson /> },
	{ path: `${process.env.PUBLIC_URL}/app/view-lesson`, Component: <ViewLesson /> },
	{ path: `${process.env.PUBLIC_URL}/app/check-works`, Component: <CheckWorks /> },
	{ path: `${process.env.PUBLIC_URL}/app/diplomas-issued`, Component: <DiplomasIssued /> },
	{ path: `${process.env.PUBLIC_URL}/app/create-diploma`, Component: <CreateDiploma /> },
	{ path: `${process.env.PUBLIC_URL}/app/diploma-template`, Component: <DiplomaTemplate /> },
	{ path: `${process.env.PUBLIC_URL}/app/diploma-template-catalog`, Component: <TemplateCatalog /> },

	//goods
	{ path: `${process.env.PUBLIC_URL}/app/purchase-history`, Component: <PurchaseHistory /> },

	//groups
	{ path: `${process.env.PUBLIC_URL}/app/list-groups`, Component: <ListGroups /> },
	{ path: `${process.env.PUBLIC_URL}/app/add-group`, Component: <AddGroup /> },
	{ path: `${process.env.PUBLIC_URL}/app/view-group`, Component: <ViewGroup /> },

	//sites
	{ path: `${process.env.PUBLIC_URL}/app/sites-list`, Component: <Sites /> },
	{ path: `${process.env.PUBLIC_URL}/app/installed-templates`, Component: <InstalledTemplates /> },
	{ path: `${process.env.PUBLIC_URL}/app/site-builder/:siteId`, Component: <SiteBuilder /> },
	{ path: `${process.env.PUBLIC_URL}/app/site-builder/:siteId/view`, Component: <IndexViewPage /> },
	{ path: `${process.env.PUBLIC_URL}/app/site-builder/:siteId/edit`, Component: <EditPage /> },
	{ path: `${process.env.PUBLIC_URL}/app/pages/:siteId`, Component: <ClientViewCustomSite />, Unauthorised: true },
	{ path: `${process.env.PUBLIC_URL}/app/sites-template-catalog`, Component: <SiteTemplateCatalog /> },

	//Email
	{ path: `${process.env.PUBLIC_URL}/app/email-app`, Component: <MailInboxContain /> },
	{ path: `${process.env.PUBLIC_URL}/app/mail-settings`, Component: <MailSettings /> },
	{ path: `${process.env.PUBLIC_URL}/app/corporate-mail-api`, Component: <CorporateMailAPI /> },
	{
		path: `${process.env.PUBLIC_URL}/app/corporate-mail-api/Yandex360`,
		Component: <Yandex360 />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/corporate-mail-api/Yandex360/token-accept`,
		Component: <TokenAccept />,
	},

	// //File Manager
	{ path: `${process.env.PUBLIC_URL}/app/file-manager`, Component: <FileManagerContain /> },
	{ path: `${process.env.PUBLIC_URL}/app/tariff-manager`, Component: <TariffManager /> },

	{ path: `${process.env.PUBLIC_URL}/app/search`, Component: <SearchResultContain /> },
	{ path: `${process.env.PUBLIC_URL}/app/ecommerce/product`, Component: <Products /> },
	{
		path: `${process.env.PUBLIC_URL}/app/ecommerce/product-page/:id`,
		Component: <ProductPage />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/ecommerce/payment-details`,
		Component: <PaymentDetails />,
	},
	{ path: `${process.env.PUBLIC_URL}/app/ecommerce/orderhistory`, Component: <OrderHistory /> },
	{ path: `${process.env.PUBLIC_URL}/app/ecommerce/invoice`, Component: <Invoice /> },
	{ path: `${process.env.PUBLIC_URL}/app/ecommerce/cart`, Component: <ProductCart /> },
	{ path: `${process.env.PUBLIC_URL}/app/ecommerce/wishlist`, Component: <WishList /> },
	{ path: `${process.env.PUBLIC_URL}/app/checkout`, Component: <CheckOut /> },
	{
		path: `${process.env.PUBLIC_URL}/app/ecommerce/product-list`,
		Component: <ProductListContain />,
	},
	// //Gallery
	{ path: `${process.env.PUBLIC_URL}/app/gallery/grids`, Component: <ImageGallery /> },
	{ path: `${process.env.PUBLIC_URL}/app/gallery/griddesc`, Component: <ImageWithDesc /> },
	{ path: `${process.env.PUBLIC_URL}/app/gallery/masonrys`, Component: <MasonryGallery /> },
	{ path: `${process.env.PUBLIC_URL}/app/gallery/masonrydesc`, Component: <MesonryDesc /> },
	{ path: `${process.env.PUBLIC_URL}/app/gallery/hover_effect`, Component: <ImageHover /> },

	// //page
	{ path: `${process.env.PUBLIC_URL}/pages/sample-page`, Component: <SamplePage /> },

	// //Blog
	{ path: `${process.env.PUBLIC_URL}/app/blog/blogdetails`, Component: <BlogDetail /> },
	{ path: `${process.env.PUBLIC_URL}/app/blog/blogsingle`, Component: <BlogSingle /> },
	{ path: `${process.env.PUBLIC_URL}/app/blog/blogpost`, Component: <BlogPost /> },

	// //Job Search
	{ path: `${process.env.PUBLIC_URL}/app/jobsearch/cardview`, Component: <JobCards /> },
	{ path: `${process.env.PUBLIC_URL}/app/jobsearch/joblist`, Component: <ListView /> },
	{ path: `${process.env.PUBLIC_URL}/app/jobsearch/jobdetail`, Component: <JobDetails /> },
	{ path: `${process.env.PUBLIC_URL}/app/jobsearch/jobapply`, Component: <Apply /> },

	// //Learning
	{
		path: `${process.env.PUBLIC_URL}/app/business-processes/create-diagram`,
		Component: <CreateDiagram />,
	},
	{ path: `${process.env.PUBLIC_URL}/app/business-processes/list`, Component: <DiagramList /> },

	// //Map
	{ path: `${process.env.PUBLIC_URL}/app/map/googlemap`, Component: <GoogleMaps /> },
	{ path: `${process.env.PUBLIC_URL}/app/map/pigeonmap`, Component: <PigeonMap /> },

	// //Editor
	{ path: `${process.env.PUBLIC_URL}/editor/ckeditor`, Component: <CkEditorContain /> },
	{ path: `${process.env.PUBLIC_URL}/editor/mdeeditor`, Component: <MdeEDitorContain /> },
	{ path: `${process.env.PUBLIC_URL}/editor/aceeditor`, Component: <ACEEditorContain /> },

	// //Knowledge
	{ path: `${process.env.PUBLIC_URL}/app/knowledgebase`, Component: <KnowledgeBaseContain /> },
	{
		path: `${process.env.PUBLIC_URL}/app/knowledgebase-category`,
		Component: <KnowledgeCategoryContain />,
	},
	{
		path: `${process.env.PUBLIC_URL}/app/knowledgebase-detail`,
		Component: <KnowledgeDetailContain />,
	},

	// //Support Ticket
	{ path: `${process.env.PUBLIC_URL}/app/supportticket`, Component: <SupportTickitContain /> },

	// //Faq
	{ path: `${process.env.PUBLIC_URL}/app/faq`, Component: <FAQContent /> },

	// //ExportData
	{ path: `${process.env.PUBLIC_URL}/export-data/services-fields-settings`, Component: <ServicesFieldsSettings /> },
	{ path: `${process.env.PUBLIC_URL}/export-data/responses-settings`, Component: <ResponsesSettings /> },
	{ path: `${process.env.PUBLIC_URL}/export-data/fields-maps-settings`, Component: <FieldsMapsSettings /> },
	{ path: `${process.env.PUBLIC_URL}/export-data/file-datatypes`, Component: <FileDataTypes /> },
	{ path: `${process.env.PUBLIC_URL}/export-data/layout-datatypes`, Component: <LayoutDataTypes /> },
	{ path: `${process.env.PUBLIC_URL}/export-data/layout-datatypes/manage-layout`, Component: <UploadLayout /> },

];
