import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Plus, Check } from 'react-feather';
import { Btn } from '../../../../../../AbstractElements';

const AddButton = ({ student, students }) => {
	const [loading, setLoading] = useState(false);
	const [isAdded, setisAdded] = useState(false);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const courseId = queryParams.get('courseId');

	const addTeacher = (student) => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
			setisAdded(true);
		}, 2000);

		// axios
		// 	.post(`/api/CourseList.json/courseId/Students`, student)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Занятие добавлено!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
	};

	return (
		<>
			{loading ? (
				<div className="loader-box" style={{ height: '16px', marginLeft: '2.5em' }}>
					<div className="loader-34"></div>
				</div>
			) : students.some((item) => item.id === student.id) || isAdded ? (
				<Btn attrBtn={{ color: 'primary', className: 'm-r-50', size: 'xs', disabled: true }}>
					<Check size={'18px'} style={{ margin: '0px 0px -5px 0px' }} /> Добавлен
				</Btn>
			) : (
				<Btn attrBtn={{ color: 'primary', className: 'm-r-50', outline: true, size: 'xs', onClick: () => addTeacher(student) }}>
					<Plus size={'18px'} style={{ margin: '0px 0px -5px 0px' }} /> Добавить
				</Btn>
			)}
		</>
	);
};

export default AddButton;
