import React, { useMemo, useState, useEffect } from 'react';
import { Trash2 } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import SweetAlert from 'sweetalert2';
import { CardBody, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import FilterComponent from '../../FilterComponent';
import CommonModal from '../../../../../UiKits/Modals/common/modal';
import StudentsTableModal from './StudentsTableModal';
import { EducationApi, TwinAuthApi } from '../../../../../../api';
import { Btn } from '../../../../../../AbstractElements';
import { Plus } from 'react-feather';
import { useLocation } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../../concatResults";

const Students = ({ students }) => {
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const [modal, setModal] = useState(false);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const courseId = queryParams.get('courseId');
	const [users, setUsers] = useState([]);
	const [data, setData] = useState([]);
	const [username, setUsername] = useState(null);

	const toggle = () => {
		setModal(!modal);
	};

	if (data !== students) {
		setData(students);
	}

	const columns = [
		{
			name: '№',
			selector: (row) => `${row.rowNumber} `,
			sortable: true,
			width: '5%',
		},
		{
			name: 'Имя',
			selector: (row) => <div>{`${getUser(row.userId).firstName} ${getUser(row.userId).lastName}`}</div>,
			sortable: true,
			hide: 'sm',
			width: '50%',
		},
		{
			name: 'Email',
			selector: (row) => `${getUser(row.userId).email}`,
			sortable: true,
			width: '40%',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteRow(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
			width: '5%',
		},
	];

	const deleteRow = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then(function (isConfirm) {
			if (isConfirm) {
				axios
					.delete(`${EducationApi}delete-course-student/${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
					.then((response) => {
						toast.success('Ученик удалён!', {
							position: toast.POSITION.TOP_RIGHT,
						});

						axios.get(`${EducationApi}get-course/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
							.then((response) => {
								axios
									.delete(`${TwinAuthApi}remove-user-from-subgroup?groupName=${concatResults(response).name}&subgroupName=Student&userId=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
									.then(() => {
										
									})
									.catch((error) => {
										console.error(error);
									});
							})
							.catch((error) => {
								console.error(error);
							});

						let objWithIdIndex = students.findIndex((obj) => obj.id === userId);
						if (objWithIdIndex > -1) {
							students.splice(objWithIdIndex, 1);
							setData(students);
						}
						tryUpdateUsers();
					})
					.catch((error) => {
						toast.error('Ошибка при удалении ученика.', {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error(error);
					});
			}
		});
	};

	function getUser(id) {
		if (users === undefined || users.length === 0) {
			tryUpdateUsers();
		}
		let user = users.find((x) => x.id === id);
		if (user !== undefined) {
			return user;
		}
		return {
			firstName: '',
			lastName: '',
			username: '',
		};
	}

	function tryUpdateUsers() {
		let usersIds = [];

		if (students !== undefined) {
			students.forEach((element) => usersIds.push(element.userId));

			axios
				.post(`${TwinAuthApi}get-users-by-idArray`, usersIds, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
				.then((response) => {
					const courseUsers = JSON.parse(response.data);
					setUsers(courseUsers);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
	}

	const promiseOptions = (inputValue, callback) => {
		axios
				.get(`${TwinAuthApi}search-users?search=${inputValue}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					const selectOptions = JSON.parse(response.data).map((option) => ({
						label: option.firstName + ' ' + option.lastName,
						value: option.username,
					}));
					callback(selectOptions);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
	};

	function isNullOrWhitespace( input ) {
		return !input || !input.trim();
	  }

	function FullNameForm() {
		return (
			<Col className="row col-sm-12 mb-3">
				<Label className="col-form-label">ФИО или логин ученика</Label>
				<Row sm="12">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<AsyncSelect 
							className="me-2 col-xl-10 col-md-8" 
							onChange={(option) => setUsername(option)} 
							value={username} 
							placeholder="Введите ФИО или логин ученика" 
							loadOptions={promiseOptions} 
							noOptionsMessage={() => "Нет подходящих под запрос пользователей"}/>
						<Btn attrBtn={{ color: 'white', className: 'btn btn-primary', outline: true, onClick: () => addUserToCourseByUsername(username.value) }}>
							<Plus size={'18px'} style={{ margin: '0px 0px -5px 0px' }} /> Добавить
						</Btn>
					</div>
				</Row>
			</Col>
		);
	}

	const addUserToCourseByUsername = (username) => {
		axios
			.get(`${TwinAuthApi}get-user?login=${username}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				const user = JSON.parse(response.data);
				console.log(user);
				let courseUser = {
					userId: user.id,
					sales: 0,
					visits: 0,
					discound: 0,
					lastVisit: new Date(),
					firstVisit: new Date(),
					courseId: courseId,
				};

				console.log(courseUser);
				axios
					.post(`${EducationApi}add-student-to-course`, courseUser, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
					.then(() => {
						//const user = JSON.parse(concatResults(response));
						toast.success('Ученик добавлен.', {
							position: toast.POSITION.TOP_RIGHT,
						});
						axios.get(`${EducationApi}get-course/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
							.then((response) => {
								axios
									.post(`${TwinAuthApi}add-user-to-subgroup?groupName=${concatResults(response).name}&subgroupName=Student&userId=${user.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
									.then(() => {
										
									})
									.catch((error) => {
										console.error(error);
									});
							})
							.catch((error) => {
								console.error(error);
							});
						
						//setUsers(courseUsers);
					})
					.catch((error) => {
						toast.error('Ошибка при добавлении ученика.', {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error('Ошибка получения данных:', error);
					});
				//setUsers(courseUsers);
			})
			.catch((error) => {
				toast.error('Ошибка при добавлении ученика.', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error('Ошибка получения данных:', error);
			});
	};

	let numberedData = data;

	if (numberedData !== undefined) {
		numberedData = data.map((row, index) => {
			return {
				...row,
				rowNumber: index + 1,
			};
		});
	}

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	let filteredItems = numberedData;
	if (numberedData !== undefined) {
		filteredItems = numberedData.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable columns={columns} data={filteredItems} noDataComponent={'В данной таблице ещё нет записей.'} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={'Добавить ученика'} toggler={toggle} togglerClose={toggle} saveText="Готово" size="xl">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<FullNameForm />
					<StudentsTableModal students={students} />
				</CardBody>
			</CommonModal>
		</>
	);
};

export default Students;
