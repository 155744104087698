import React, { useMemo, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import FilterComponent from '../../../Goods/PurchaseHistory/FilterComponent';
import { H6, P } from '../../../../../AbstractElements';

import { AnalyticsApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const TableOnEmployees = () => {
	const [data, setData] = useState([]);
	const [revenue, setRevenue] = useState(0);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${AnalyticsApi}getEmployeeReports`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				setData(concatResults(response).employeeReports);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	function calculateAllCost(visitHistory) {
		let totalDuration = 0;
		if (!visitHistory) {
			return 0;
		}
		visitHistory.forEach((entry) => {
			totalDuration += entry.cost;
		});

		return totalDuration;
	}

	function costHour(visitHistory) {
		if (!visitHistory) {
			return 0;
		}

		return (calculateAllCost(visitHistory) / calculateTotalDuration(visitHistory)).toFixed(1);
	}

	function calculateTotalCost(items, type) {
		if (!items) {
			return 0;
		}

		let totalCost = 0;
		if (type === 'goods') {
			items.forEach((item) => {
				if (item.type === 'goods') {
					totalCost += item.cost || 0;
				}
			});
		} else {
			items.forEach((item) => {
				if (item.type === 'service') {
					totalCost += item.cost || 0;
				}
			});
		}

		return totalCost;
	}

	function calculateTotal(items, type) {
		if (!items) {
			return 0;
		}

		let totalCost = 0;
		if (type === 'goods') {
			items.forEach((item) => {
				if (item.type === 'goods') {
					totalCost += 1;
				}
			});
		} else {
			items.forEach((item) => {
				if (item.type === 'service') {
					totalCost += 1;
				}
			});
		}

		return totalCost;
	}

	function calculateTotalDuration(visitHistory) {
		let totalDuration = 0;
		if (!visitHistory) {
			return 0;
		}
		visitHistory.forEach((entry) => {
			totalDuration += entry.duration;
		});

		return totalDuration;
	}

	function percentageRevenue(visitHistory) {
		if (!visitHistory) {
			return 0;
		}

		return ((calculateAllCost(visitHistory) / revenue) * 100).toFixed(1);
	}

	const columns = [
		{
			name: <div>Сотрудник</div>,
			selector: (row) => (
				<>
					<H6>
						{row.name}
					</H6>
				</>
			),
			sortable: true,
			width: '200px',
		},
		{
			name: <div>Выручка</div>,
			selector: (row) => `${row.revenue} ₽`,
			sortable: true,
		},

		{
			name: (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>Сумма</span>
					<span style={{ fontSize: '12px' }}>(Услуги)</span>
				</div>
			),
			selector: (row) => `${row.revenueServices} ₽`,
			sortable: true,
		},
		{
			name: (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>Кол-во</span>
					<span style={{ fontSize: '12px' }}>(Услуги)</span>
				</div>
			),
			selector: (row) => `${row.countServices}`,
			sortable: true,
		},
		{
			name: (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>Сумма</span>
					<span style={{ fontSize: '12px' }}>(Товары)</span>
				</div>
			),
			selector: (row) => `${row.revenueProducts} ₽`,
			sortable: true,
		},
		{
			name: (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>Кол-во</span>
					<span style={{ fontSize: '12px' }}>(Товары)</span>
				</div>
			),
			selector: (row) => `${row.countProducts}`,
			sortable: true,
		},
		{
			name: 'Скидка',
			selector: (row) => `${row.discount}`,
			sortable: true,
		},
		{
			name: 'Баллы',
			selector: (row) => `${row.points}`,
			sortable: true,
		},
		{
			name: <div>Абонементы</div>,
			selector: (row) => `${row.subscriptions}`,
			sortable: true,
			width: '130px',
		},
		{
			name: <div>Сертификаты</div>,
			selector: (row) => `${row.certificates}`,
			sortable: true,
			width: '130px',
		},
		{
			name: <div>Счета клиента</div>,
			selector: (row) => `${row.clientsCheck}`,
			sortable: true,
		},
		{
			name: <div>Будущие записи</div>,
			selector: (row) => `${row.futureEntries}`,
			sortable: true,
		},
		{
			name: <div>Отработано часов</div>,
			selector: (row) => `${row.hoursWorked}`,
			sortable: true,
			width: '130px',
		},
		{
			name: <div>Стоимость часа работы</div>,
			selector: (row) => `${row.hourCost}`,
			sortable: true,
			width: '150px',
		},
		{
			name: <div>% от общей выручки</div>,
			selector: (row) => `${row.totalRevenuePercent} %`,
			sortable: true,
			width: '130px',
		},
	];

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return <>{data && <DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />}</>;
};

export default TableOnEmployees;
