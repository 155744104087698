import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Breadcrumbs } from '../../../../AbstractElements';
import ChatStatus from './ChatStatus';
import Chatting from './Chatting';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const ChatAppContain = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const typeChat = queryParams.get('type');

	const [title, setTitle] = useState('');

	useEffect(() => {
		if (typeChat === 'users') {
			setTitle('Личные чаты');
		} else {
			setTitle('Групповые чаты');
		}
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle={title} parent="Чаты" title={title} />
			<Container fluid={true}>
				<Row>
					<Col className="call-chat-sidebar">
						<Card>
							<CardBody className="chat-body">
								<ChatStatus />
							</CardBody>
						</Card>
					</Col>
					<Col className="call-chat-body">
						<Card>
							<CardBody className="p-0">
								<Chatting />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default ChatAppContain;
