import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Row, CardBody, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import CommonModal from "../../../UiKits/Modals/common/modal";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import {Edit, Trash2} from "react-feather";
import SweetAlert from "sweetalert2";
import {toast} from "react-toastify";
import FilterComponent from "../../Widgets/InteractivePlanMap/FilterComponent";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";
import Select from "react-select";
import {Link, useNavigate} from "react-router-dom";

const ClientDocuments = ({ clientId, setClientDocumentId }) => {
    const [name, setName] = useState('');
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [documentTypesSelect, setDocumentTypesSelect] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState();
    const {keycloak} = useKeycloak();
    const {companyData} = useCompanyContext();
    const {getClientDocuments, clientDocuments, getDocumentTypes, documentTypes, createClientDocument, updateClientDocument, deleteClientDocument} = useClientsContext();

    useEffect(() => {
        clientDocuments && setFilteredItems(clientDocuments.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
        documentTypes && setDocumentTypesSelect(documentTypes.map((item) => ({label: item.name, value: item.id})))
    }, [filterText, setFilterText, clientDocuments, documentTypes]);

    useEffect(() => {
        getClientDocuments(clientId);
        getDocumentTypes();
    }, [keycloak.tokenParsed, companyData]);

    const toggle = (id) => {
        setModal(!modal);
        setId(null);
        if (id) {
            setId(id);
        }
    }

    const deleteRow = (docId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'После удаления вы не сможете восстановить этот объект!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                deleteClientDocument(docId, clientId).then(() => {
                    getClientDocuments(clientId);
                    //setPhone('');
                    toast.success('Документ удалён!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                })
            }
        });
    };

    const handleSendRequest = () => {
        if (id) {
            /*const document = clientDocuments.find((item) => item.id === id);
            updateClientDocument(id, clientId, document.documentTypeId, name).then(() => {
                getClientDocuments(clientId);
                setName('');
                toast.success('Документ обновлён', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })*/
        }
        else {
            createClientDocument(clientId, selectedDocumentType, name).then(() => {
                getClientDocuments(clientId);
                setName('');
                toast.success('Документ добавлен', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }

        toggle();
    };

    const getClientDocumentType = (documentTypeId) => {
        const documentType = documentTypesSelect.find((item) => item.value === documentTypeId);
        return documentType? documentType.label : 'Название не найдено';
    }

    const openDocument = (docId) => {
        setClientDocumentId(docId);
    }

    const tableColumns = [
        {
            name: 'Название',
            selector: (row) => <span style={{color: "#7366ff"}} onClick={() => openDocument(row.id)}>{row.name || row.name === '' ? row.name : getClientDocumentType(row.documentTypeId)}</span> ,
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{display: 'flex'}}>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <Link to={`/app/clients-app/client-document-edit?clientDocumentId=${row.id}`}><Edit size={'18px'} /></Link>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'}/>
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={() => toggle(null)} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление нового документа клиента'} toggler={handleSendRequest} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Название
                                </Label>
                                <Col sm="11">
                                    <Input
                                        className="form-control"
                                        value={name}
                                        placeholder="Название"
                                        onChange={(e) => setName(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Тип документа
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={documentTypesSelect} onChange={(e) => setSelectedDocumentType(e?.value)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default ClientDocuments;