import React, { Fragment } from "react";
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import { Breadcrumbs } from "../../../../../AbstractElements";
import Editor from "./Editor";

const InteractivePlanMapEditor = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Редактирование карты" parent="Виджеты" title="Редактирование карты" />
            <Container fluid className="user-card">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Editor />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default InteractivePlanMapEditor;