import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import axios from "axios";
import { Nav, NavItem, NavLink, TabContent, TabPane, Media } from "reactstrap";
import { Breadcrumbs, H5 } from "../../../../AbstractElements";
import { Link } from "react-router-dom";

const CorporateMailAPI = () => {
	const [emailServers, setEmailServers] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/get-api-services.json`) //для входящих
			.then((response) => {
				setEmailServers(response.data.apiServices);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Управление доменной почтой" parent="Почта" title="Управление доменной почтой" />
			<Container fluid={true}>
				<div className="email-wrap">
					<Row>
						<Card>
							<CardHeader>
								<H5>API корпоративной почты</H5>
							</CardHeader>
							<CardBody>
								<div className="row gallery-with-description mt-4 mb-4">
									{emailServers &&
										emailServers.map((item) => (
											<div className="col-xl-4" key={item.id} style={{ cursor: "pointer" }}>
												<Link to={`${process.env.PUBLIC_URL}/app/corporate-mail-api/${item.SystemName}`}>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															borderTop: "3px solid #f6f7f9",
															marginBottom: "2rem",
															borderRadius: "10px",
															padding: "6px",
															justifyContent: "center",
															flexDirection: "column",
														}}
													>
														<Col style={{ display: "flex", justifyContent: "center" }}>
															<Media src={item.Image} alt="serverImg" className="me-3 mt-2" style={{ maxHeight: "40px", maxWidth: "100px" }} />
														</Col>
														<Col>
															<div className="caption">
																<h4 className="mt-4 mb-2 text-center">{item.DisplayName}</h4>
															</div>
														</Col>
													</div>
												</Link>
											</div>
										))}
								</div>
							</CardBody>
						</Card>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};
export default CorporateMailAPI;
