import React, { Fragment } from "react";
import { Card, CardBody, Container } from "reactstrap";

import { Breadcrumbs } from "../../../../../AbstractElements";
import TableTemplatesNews from "./TableTemplatesNews";

const TemplatesNews = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Шаблоны новостей" parent="Новости" title="Шаблоны новостей" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<TableTemplatesNews />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default TemplatesNews;
