import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Label, Input, CardBody, Col, Row } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

const TableTypesCertificates = ({ filteredProducts }) => {
	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}
	const [types, setTypes] = useState([]);

	useEffect(() => {
		axios
			.get('/api/typesCards.json')
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.name,
					value: option.id,
				}));

				setTypes(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const columns = [
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
			width: '80%',
		},
		{
			name: 'Выпущено',
			selector: (row) => `0`,
			sortable: true,
			center: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {});
	};

	const [edit, setEdit] = useState(null);

	const send = () => {
		if (selectType == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			if (!Id) {
				setId(uuid());
			}
			const newEvent = {
				id: Id,
				type: selectType.value,
			};
			console.log(newEvent);
			// axios
			// 	.post(`/api/clientsDemandsForm.json`, newEvent)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Календарь добавлен!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 	});
		}
	};
	const [name, setName] = useState('');
	const [selectType, setSelectType] = useState({ label: null, value: null });
	const [expirationLimit, setExpirationLimit] = useState({ label: 'Без ограничения срока действия', value: 'Без ограничения срока действия' });
	const [dateEnd, setDateEnd] = useState(new Date());
	const [denomination, setDenomination] = useState('');
	const [usageLimit, setUsageLimit] = useState({ label: 'Без ограничений', value: 'Без ограничений' });
	const [sellWithoutCode, setSellWithoutCode] = useState(false);
	const [onlineSaleAvailable, setOnlineSaleAvailable] = useState(false);
	const [duration, setDuration] = useState(0);
	const [unit, setUnit] = useState('');
	const [Id, setId] = useState('');

	const [modal, setModal] = useState(false);

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setDenomination(ObjectId.denomination);
			setId(ObjectId.id);
			setUsageLimit({ label: ObjectId.usage_limit, value: ObjectId.usage_limit });
			setSelectType({ label: ObjectId.redemption_type, value: ObjectId.redemption_type });

			if (typeof ObjectId.expiration_limit === 'object' && ObjectId.expiration_limit !== null) {
				setExpirationLimit({ label: 'Фиксированный срок действия с момента продажи', value: 'Фиксированный срок действия с момента продажи' });
				setUnit(ObjectId.expiration_limit.unit);
				setDuration(ObjectId.expiration_limit.duration);
			} else if (typeof ObjectId.expiration_limit === 'string') {
				setExpirationLimit({ label: 'Фиксированная дата для всех экземпляров', value: 'Фиксированная дата для всех экземпляров' });
				setDateEnd(ObjectId.expiration_limit);
			} else {
				setExpirationLimit({ label: 'Без ограничения срока действия', value: 'Без ограничения срока действия' });
			}

			setSellWithoutCode(ObjectId.sell_without_code);
			setOnlineSaleAvailable(ObjectId.online_sale_available);
			setName(ObjectId.name);
			setEdit(true);
		} else {
			setSelectType({ label: null, value: null });
			setExpirationLimit({ label: 'Без ограничения срока действия', value: 'Без ограничения срока действия' });
			setDenomination('');
			setId('');
			setUsageLimit({ label: 'Без ограничений', value: 'Без ограничений' });
			setSellWithoutCode(false);
			setOnlineSaleAvailable(false);

			setName('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="xl">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingRight: '0', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Row className="col-sm-12 mb-2">
						<Col className="row col-sm-6 mb-2">
							<Label className="col-sm-9 col-form-label">Тип списания</Label>
							<Col sm="11">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={selectType}
									options={[
										{ label: 'Однократное списание', value: 'Однократное списание' },
										{ label: 'Многократное списание', value: 'Многократное списание' },
									]}
									onChange={(option) => setSelectType(option)}
								/>
							</Col>
						</Col>
						<Col className="row col-sm-6 mb-2">
							<Label className="col-sm-9 col-form-label">Номинал</Label>
							<Col sm="11">
								<Input className="form-control" type="tel" value={denomination} onChange={(e) => setDenomination(e.target.value)} />
							</Col>
						</Col>
					</Row>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Ограничение применения (списание баллов)</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={usageLimit}
								options={[
									{ label: 'Без ограничений', value: 'Без ограничений' },
									{ label: 'Любые услуги без товаров', value: 'Любые услуги без товаров' },
									{ label: 'Любые товары без услуг', value: 'Любые товары без услуг' },
								]}
								onChange={(option) => setUsageLimit(option)}
							/>
						</Col>
					</Col>
					<Row className=" col-sm-12 mb-2">
						<Col className="row col-sm-6 mb-2">
							<Label className="col-sm-9 col-form-label">Ограничение срока действия</Label>
							<Col sm="11">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={expirationLimit}
									options={[
										{ label: 'Без ограничения срока действия', value: 'Без ограничения срока действия' },
										{ label: 'Фиксированная дата для всех экземпляров', value: 'Фиксированная дата для всех экземпляров' },
										{ label: 'Фиксированный срок действия с момента продажи', value: 'Фиксированный срок действия с момента продажи' },
									]}
									onChange={(option) => setExpirationLimit(option)}
								/>
							</Col>
						</Col>
						{expirationLimit.value === 'Фиксированная дата для всех экземпляров' ? (
							<Col className="row col-sm-6 mb-2">
								<Label className="col-sm-9 col-form-label">Дата сгорания</Label>
								<div className="input-group">
									<DatePicker locale={ru} className="form-control digits" value={formatDate(dateEnd)} selected={dateEnd} onChange={(e) => setDateEnd(e.target.value)} />
								</div>
							</Col>
						) : expirationLimit.value === 'Фиксированный срок действия с момента продажи' ? (
							<>
								<Col className="row col-sm-4 mb-2">
									<Label className="col-sm-12 col-form-label">Срок действия с момента продажи</Label>
									<Col sm="11">
										<Input className="form-control" type="tel" value={duration} onChange={(e) => setDuration(e.target.value)} />
									</Col>
								</Col>
								<Col className="row col-sm-2 mb-2">
									<Label className="col-sm-12 col-form-label">Е/и</Label>
									<Col sm="11">
										<Input className="form-control" type="tel" value={unit} onChange={(e) => setUnit(e.target.value)} />
									</Col>
								</Col>
							</>
						) : (
							''
						)}
					</Row>
					<div className="checkbox checkbox-primary">
						<Input id={`checkbox-primary-1-`} type="checkbox" checked={sellWithoutCode} onChange={(e) => setSellWithoutCode(e.target.checked)} />
						<Label for={`checkbox-primary-1-`}>Разрешить продажу сертификата без кода</Label>
					</div>

					<div className="checkbox checkbox-primary">
						<Input id={`checkbox-primary-1-`} type="checkbox" checked={onlineSaleAvailable} onChange={(e) => setOnlineSaleAvailable(e.target.checked)} />
						<Label for={`checkbox-primary-1-`}>Доступность для продажи онлайн</Label>
					</div>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableTypesCertificates;
