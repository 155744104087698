import { H6 } from "../../../../../AbstractElements";
import FilterContext from "../../../../../_helper/Ecommerce/Filter";
import Category from "../Filters/Category";
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row, Collapse } from "reactstrap";
import React, { Fragment, useContext, useState } from "react";

const ProductSidebar = () => {
	const { filterContext, filterSearchBy } = useContext(FilterContext);
	const [sidebaron, setSidebaron] = useState(true);
	const [searchKeyword, setSearchKeyword] = useState("");
	const onClickFilter = () => {
		if (sidebaron) {
			setSidebaron(false);
			document.getElementById("product-wrapper-grid").classList.add("sidebaron");
		} else {
			setSidebaron(true);
			document.getElementById("product-wrapper-grid").classList.remove("sidebaron");
		}
	};
	const handleSearchKeyword = (keyword) => {
		setSearchKeyword(keyword);
		filterSearchBy(searchKeyword);
	};

	const [isOpen, setIsOpen] = useState(null);

	const toggleClientCard = (item) => {
		if (isOpen === item) {
			setIsOpen(null);
		} else {
			setIsOpen(item);
		}
	};
	return (
		<Fragment>
			<Row>
				<Col xl="3" md="6">
					<div className={`product-sidebar `}>
						<div className="filter-section">
							<Card>
								<CardHeader className="p-relative" onClick={() => toggleClientCard(1)} style={{ cursor: "pointer" }}>
									<H6 attrH6={{ className: "mb-0 f-w-600" }}>
										Фильтры
										<span className="pull-right">{isOpen ? <i className="fa fa-chevron-up toggle-data"></i> : <i className="fa fa-chevron-down toggle-data"></i>}</span>
									</H6>
								</CardHeader>
								<Collapse isOpen={isOpen === 1}>
									<div className="left-filter p-absolute" style={{ zIndex: "3", width: "100%", backgroundColor: "white" }}>
										<CardBody className="filter-cards-view animate-chk">
											<Category />
										</CardBody>
									</div>
								</Collapse>
							</Card>
						</div>
					</div>
				</Col>
				<Col xl="9" md="6">
					<div className="product-search">
						<Form>
							<FormGroup className="m-0 form-group search-product">
								<Input className="form-control" type="text" placeholder="поиск" defaultValue={searchKeyword} onChange={(e) => handleSearchKeyword(e.target.value)} />
								<i className="fa fa-search"></i>
							</FormGroup>
						</Form>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};
export default ProductSidebar;
