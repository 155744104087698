import React from 'react';
import { ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';
import { Image } from '../../../../../AbstractElements';

const LessonsCard = ({ lessons }) => {

    let lastKeyLessonName = "";

    const findFirstUncompletedKeyLessonIndex = () => {
        for (let i = 0; i < lessons.length; i++) {
            if (lessons[i].keyLesson && !lessons[i].assignmentCompleted) {
                lastKeyLessonName = lessons[i].name;
                return i;
            }
        }
        return -1;
    };

    const isLessonAvailable = (index) => {
        const firstUncompletedKeyLessonIndex = findFirstUncompletedKeyLessonIndex();

        if (firstUncompletedKeyLessonIndex === -1 || index <= firstUncompletedKeyLessonIndex) {
            return true;
        }

        return false;
    };

    return (
        <>
            <Col md="12" className="mt-4">
                {lessons.map((item, index) => (
                    <div
                        key={index}
                        className={`light-card balance-card mb-4 ${isLessonAvailable(index) && ' widget-hover'}`}
                        style={{
                            alignItems: 'center',
                            border: !isLessonAvailable(index) && '3px solid var(--light-background)',
                            backgroundColor: !isLessonAvailable(index) && 'white',
                            padding: '18px',
                        }}
                    >
                        <Col md="9" xl="10">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Image
                                    attrImage={{
                                        className: 'img-70 b-r-10 m-r-10',
                                        src: `https://loremflickr.com/32/32`, // заглушка с рандомными картинками
                                        alt: '',
                                        height: '70px',
                                    }}
                                />
                                <div style={{ display: 'flex', flexDirection: 'column' }} className="ms-3">
                                    <div style={{ display: 'flex' }}>
                                        <h4 className={` ${!isLessonAvailable(index) ? '' : 'font-primary'}`} style={{ color: '#898989' }}>
                                            {item.name}
                                        </h4>
                                        {isLessonAvailable(index) && (
                                            <>
                                                {item.viewedByCurrentUser && (
                                                    <span className={`badge badge-light-primary rounded-pill mt-2 ms-2`} style={{ height: 'min-content' }}>
                                                        Просмотрено <i className="fa fa-eye"></i>
                                                    </span>
                                                )}
                                                {item.hasAssignment && (
                                                    <span className={`badge badge-light-info rounded-pill mt-2 ms-2`} style={{ height: 'min-content' }}>
                                                        Есть задание <i className="fa fa-book"></i>
                                                    </span>
                                                )}

                                                {item.assignmentCompleted && (
                                                    <span className={`badge badge-light-success rounded-pill mt-2 ms-2`} style={{ height: 'min-content' }}>
                                                        Задание завершено <i className="fa fa-check"></i>
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div style={{ WebkitBoxOrient: 'vertical', wordWrap: 'break-word', color: '#898989', WebkitLineClamp: 2, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box' }} className="f-18">
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                {!isLessonAvailable(index) && (
                                    <Tooltip title={`Необходимо выполнить задание '${lastKeyLessonName}'`} arrow placement="top">
                                        <span className={`badge badge-light-danger rounded-pill mt-2`} style={{ cursor: 'pointer' }}>
                                            Недоступен
                                        </span>
                                    </Tooltip>
                                )}
                            </div>
                        </Col>
                        <Col md="3" xl="2">
                            {isLessonAvailable(index) && (
                                <Tooltip title="Перейти к занятию" arrow placement="top">
                                    <Link
                                        className="f-18 f-right me-4"
                                        to={{
                                            pathname: `${process.env.PUBLIC_URL}/app/view-lesson`,
                                            search: `lessonId=${item.id}&viewed=${item.viewedByCurrentUser}&completed=${item.assignmentCompleted}`
                                        }}
                                    >
                                        <ArrowRight size={'28px'} style={{ margin: '0 0px -8px 5px', fontSize: '28px' }} />
                                    </Link>
                                </Tooltip>
                            )}
                        </Col>
                    </div>
                ))}
            </Col>
        </>
    );
};

export default LessonsCard;
