import React, { Fragment, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

import { TwinAuthApi } from "../../../../api";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const CreateLead = ({ participants, setParticipants, pattern, setPattern, setOwner, setCompany, company }) => {
	const [allUsersNames, setAllUsersNames] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
				}));
				setAllUsersNames(selectOptions);
				const fetchData = async () => {
					const result = await getUserName(keycloak.tokenParsed.sub);
					setOwner({ label: result, value: keycloak.tokenParsed.sub });
				};
				fetchData();
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`${TwinAuthApi}get-groups-companies`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setCompanies(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	async function getUserName(userId) {
		try {
			const response = await axios.get(`${TwinAuthApi}get-user?id=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const userData = JSON.parse(response.data);
			return userData.firstName + userData.lastName;
		} catch (error) {
			console.error("Ошибка получения данных:", error);
			return null;
		}
	}

	const colourStyles = {
		control: (styles) => ({ ...styles, border: "none", boxShadow: "none", fontSize: "14px", padding: "0.22rem" }),
	};

	return (
		<Fragment>
			<Row>
				<Col sm="6">
					<FormGroup>
						<Label>Клиенты</Label>
						<Select
							required
							placeholder="Начните вводить имя пользователя"
							isMulti
							noOptionsMessage={() => "Нет результатов"}
							options={allUsersNames}
							value={participants}
							onChange={(option) => setParticipants(option)}
							className="form-control p-0 col-sm-12"
							styles={colourStyles}
						/>
					</FormGroup>
				</Col>
				<Col sm="6">
					<FormGroup>
						<Label>Компания</Label>
						<Select
							placeholder="Начните вводить название компании"
							noOptionsMessage={() => "Нет результатов"}
							options={companies}
							value={company}
							onChange={(option) => setCompany(option)}
							className="form-control p-0 col-sm-12"
							styles={colourStyles}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col sm="6">
					<FormGroup>
						<Label>Шаблон статусов</Label>
						<Input disabled value={pattern} onChange={(e) => setPattern(e.target.value)} type="select" name="issues" placeholder="Select Issues" className="form-control digits" required>
							<option value={0}>По умолчанию</option>
						</Input>
					</FormGroup>
				</Col>
			</Row>
		</Fragment>
	);
};

export default CreateLead;
