import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import { Btn, H1, H2, H3, H4, P } from "../../../AbstractElements";
import Bg1 from "../../../assets/images/order-system/_-1.png";
import Img1 from "../../../assets/images/social-network/blob.png";
import Icon1 from "../../../assets/images/social-network/megaphone.png";
import Icon2 from "../../../assets/images/social-network/group.png";
import Icon3 from "../../../assets/images/social-network/bubble-chat.png";

import Img3 from "../../../assets/images/order-system/_-2.png";
import Img4 from "../../../assets/images/social-network/front.png";
import Img5 from "../../../assets/images/social-network/right.png";

import StandardPricingcard from "../../Application/PackageOffers/ClientView/StandardProcingCard";
import Form from "../Form";
import NavMenu from "../NavMenu";

const SocialNetwork = () => {
	const [item, setItem] = useState("");

	useEffect(() => {
		axios
			.get("/api/packageOffersList.json")
			.then((response) => {
				const foundItem = response.data.find((item) => item.id === "1");
				setItem(foundItem);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);
	return (
		<Fragment>
			<Container fluid className="user-card">
				<NavMenu />
				<Row style={{ backgroundImage: `url(${Bg1})`, height: "80vh", color: "#ffffff" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "14vh", justifyContent: "space-between" }}>
						<H1 attrH1={{ className: "f-w-600 mb-3" }}>Социальная сеть</H1>
						<P>Откройте новый мир возможностей для общения, обмена информацией и создания сообществ, где вы можете находиться в центре событий</P>
						<div style={{ flex: "1", alignItems: "center", display: "flex" }}>
							<Btn attrBtn={{ size: "lg" }}>Начать сейчас</Btn>
						</div>
						<img src={Img1} alt="bg-image" />
					</Col>
				</Row>
				<Row style={{ backgroundColor: "#f3f3f3", height: "70vh" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "16vh" }}>
						<H1 attrH1={{ className: "f-w-600 mb-3" }}>Что включает в себя наше решение?</H1>
						<div style={{ display: "flex", margin: "4rem 12rem", height: "32vh" }}>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon1} alt="icon1" style={{ width: "120px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Управление новостями</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Следите за актуальными событиями и темами, настраивая ленту под свои интересы.
								</p>
							</Col>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon2} alt="icon1" style={{ width: "120px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Управление группами Пользователей</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Создавайте и управляйте сообществами, обмениваясь опытом и контентом с единомышленниками.
								</p>
							</Col>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon3} alt="icon1" style={{ width: "120px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Общение в реальном Времени</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Будьте на связи с друзьями и близкими, отправляя сообщения и медиафайлы в любое время.
								</p>
							</Col>
						</div>
					</Col>
				</Row>
				<Row style={{ height: "50vh" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16vh 40vh 4vh", justifyContent: "space-between" }}>
						<img src={Img3} alt="Image3" style={{ width: "100px", borderRadius: "50%" }} />
						<H2 attrH2={{ className: "f-w-600 mt-4" }}>Рабочая среда</H2>
						<p className="f-18 text-center">
							Современный и удобный интерфейс, а также высокий уровень безопасности делают наш сервис идеальным местом для эффективного взаимодействия и обмена информацией
						</p>
					</Col>
				</Row>
				<Row>
					<Col sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
						<img src={Img4} alt="Image4" />
					</Col>
				</Row>
				<Row style={{ backgroundColor: "#f3f3f3", height: "90vh", paddingLeft: "20vh", paddingRight: "20vh" }}>
					<Col md="5" sm="12" style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingRight: "2rem" }}>
						<H3 attrH3={{ className: "f-w-600 mb-4" }}>Удобно в любом месте, с любого устройства</H3>
						<p className="f-18">
							Используйте любое удобное устройство. Ноутбук, планшет или телефон — наша платформа работает одинаково хорошо на любом экране. В любой момент вы в курсе происходящего!{" "}
						</p>
					</Col>
					<Col md="7" sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
						<img src={Img5} alt="Image5" style={{ maxHeight: "800px" }} />
					</Col>
				</Row>
				<Row>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16vh 20vh 4vh", justifyContent: "space-between" }}>
						<H1 attrH1={{ className: "f-w-600 mb-4" }}>Тарифы</H1>
						<P attrPara={{ className: "f-20 mb-4" }}>В некоторые планы входит неограниченый доступ сотрудников, общих бордов и сессий</P>
						<Col md="8" className="mt-4">
							<StandardPricingcard data={item.packages} />
						</Col>
					</Col>
				</Row>
				<Form color={"#f3f3f3"} />
			</Container>
		</Fragment>
	);
};
export default SocialNetwork;
