import React, { Fragment, useCallback, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { H5 } from '../../../../AbstractElements';

const VisitHistory = ({ clientId }) => {
	const [data, setData] = useState([]);
	useEffect(() => {
		axios
			.get(`/api/clientsVisitHistory.json`)
			.then((response) => {
				const foundObject = response.data.find((item) => item.clientId === Number(clientId));
				setData(foundObject.Orders);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const tableColumns = [
		{
			name: 'Сотрудник',
			selector: (row) => `${row.Employee}`,
			sortable: true,
			center: false,
		},
		{
			name: 'Статус',
			selector: (row) => `${row.Status}`,
			sortable: true,
			center: false,
		},
		{
			name: 'Услуга',
			selector: (row) => `${row.Service}`,
			sortable: true,
			center: false,
		},
		{
			name: 'Стоимость',
			selector: (row) => `${row.Price}`,
			sortable: true,
			center: false,
		},
		{
			name: 'Оплачено',
			selector: (row) => (row.Paid ? <div>да</div> : <div>нет</div>),
			center: false,
		},
	];

	return (
		<Fragment>
			<H5>История посещений</H5>
			<div style={{ marginTop: '30px' }}>
				<DataTable data={data} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:' }} />
			</div>
		</Fragment>
	);
};

export default VisitHistory;
