import {Columns} from "./Columns";
import {Flex} from "./Flex";
import {VerticalSpace} from "./VerticalSpace";
import {RegularText} from "./RegularText";
import {BoldText} from "./BoldText";
import {InputText} from "./Input";

export const PuckComponents = {
    Columns: Columns,
    Flex: Flex,
    VerticalSpace: VerticalSpace,
    RegularText: RegularText,
    BoldText: BoldText,
    Input: InputText,
}