import React, { Fragment, useState, useEffect } from 'react';
import { Container, Card, CardBody, Row, CardHeader } from 'reactstrap';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { Breadcrumbs, H5 } from '../../../AbstractElements';
import { DemandsApi } from '../../../api';
import ClientForm from './ClientForm';
import NewDemandForm from './NewDemandForm';
import { useKeycloak } from '@react-keycloak/web';
import { concatResults } from "../../../concatResults";
import { useCompanyContext } from '../../../_helper/Company/CompanyProvider';
import { useInteractivePlanMapsContext } from "../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";

const UnauthorizedDemandForm = () => {
	const [demandForm, setDemandForm] = useState('');
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);

	const [formId, setFormId] = useState(queryParams.get('formId'));
	const [mapObjectId, setMapObjectId] = useState(queryParams.get('objectId'));
	const [mapObjectNumber, setMapObjectNumber] = useState('');

	const [urlParams, setUrlParams] = useState([]);

	const { planMapsObject, getInteractivePlanMapsObjectUnauthorized } = useInteractivePlanMapsContext();


	useEffect(() => {
		axios
			.get(`${DemandsApi}getDemandFormsUnauthorized`, {
				params: {
					query: formId//'b77e0ad8-9e26-4c45-bb96-f299a26263b6',
				},
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
			})
			.then((response) => {
				setDemandForm(response.data.demandForms[0]);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		if (!formId) {
			queryParams.append(`formId`, uuid());
			navigate(`?${queryParams.toString()}`);
			setFormId(queryParams.get('formId'));
		}
	}, []);

	useEffect(() => {
		getInteractivePlanMapsObjectUnauthorized(mapObjectId);
	}, [mapObjectId]);


	useEffect(() => {
		if (planMapsObject) {
			setUrlParams(prevParams => [
				...prevParams,
				{ name: "ObjectNumberId", value: planMapsObject.number }
			]);
		}
	}, [planMapsObject]);


	return (
		<Fragment>
			<Container fluid={true}>
				<Row>
					<Card>
						<CardHeader>
							<H5>{demandForm.name}</H5>
						</CardHeader>
						{demandForm &&
							<CardBody>
								{demandForm.menuItems.length > 1 ?
									<NewDemandForm
										taskManagment={demandForm.taskManagment}
										name={demandForm.name}
										formId={formId}
										parameters={demandForm.parameters}
										menuItems={demandForm.menuItems}
										delivery={demandForm.withDelivery}
									/> :
									<ClientForm isUnauthorized={true} urlParams={urlParams} formId={formId} delivery={demandForm.withDelivery} parameters={demandForm.parameters} />
								}
							</CardBody>
						}

					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};
export default UnauthorizedDemandForm;
