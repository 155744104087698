import React, { Fragment, useState, useEffect } from 'react';
import { Container, Input, CardBody, Col, Card, Label, CardHeader, InputGroup, InputGroupText } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import Select from 'react-select';

import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Breadcrumbs, Btn, H6 } from '../../../../../AbstractElements';
import { Plus } from 'react-feather';
import OrderOverview from './OrderOverview';

import { MoneyManagementApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const AccountsCashregisters = () => {
	const [cash, setCash] = useState([]);
	const [cashOptions, setCashOptions] = useState([]);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${MoneyManagementApi}getCashregisters`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				setCash(concatResults(response).cashregisters);
				const selectOptions = concatResults(response).cashregisters.map((option) => ({
					label: option.name + ' (' + option.amount + ' ₽)',
					value: option.id,
				}));
				setCashOptions(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const [curCash, setCurCash] = useState({});
	const [toCash, setToCash] = useState({});
	const [amount, setAmount] = useState('');
	const [comment, setComment] = useState('');

	const [modalTransferFunds, setModalTransferFunds] = useState(false);
	const toggleTransferFunds = (item) => {
		setToCash({});
		setCurCash({ label: item.name + ' (' + item.amount + ' ₽)', value: item.id });
		setAmount(item.amount);
		setModalTransferFunds(!modalTransferFunds);
	};

	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [initialBalance, setInitialBalance] = useState(0);
	const [selectedType, setSelectedType] = useState(null);

	const handleAddRow = () => {
		const cashregister = {
			Id: uuid(),
			name: name,
			CashType: selectedType.value,
			description: description,
			amount: initialBalance
		};

		axios
			.post(`${MoneyManagementApi}createCashregister`, cashregister, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				//console.log(concatResults(response));
				toast.success('Касса создана!', {
					position: toast.POSITION.TOP_RIGHT,
				});
				setModal(!modal);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleTransferFunds = () => {
		const newTransfer = {
			CashregisterSource: curCash.value,
			CashregisterSuccessor: toCash.value,
			amount: amount,
			comment: comment
		};

		axios
			.post(`${MoneyManagementApi}createTransferFunds`, newTransfer, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				//console.log(concatResults(response));
				toast.success('Средства переведены!', {
					position: toast.POSITION.TOP_RIGHT,
				});
				toggleTransferFunds(!modal);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Счета и кассы" parent="Отчёты" subParent="Финансы" title="Счета и кассы" />
			<Container fluid className="user-card">
				<Col md="12">
					<Card>
						<CardHeader>
							<div style={{ justifyContent: 'space-between', display: 'flex' }}>
								<Link to={`${process.env.PUBLIC_URL}/analytics/reports`} className="f-18">
									<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
								</Link>
								<Btn attrBtn={{ color: 'info', onClick: toggle }}>
									<Plus size={'18px'} style={{ margin: '0 3px -3px 0' }} />
									Добавить кассу
								</Btn>
							</div>
						</CardHeader>
						<CardBody>
							{cash.map((item) => (
								<OrderOverview key={item.id} item={item} toggleTransferFunds={toggleTransferFunds} />
							))}
						</CardBody>
					</Card>
				</Col>
				<CommonModal isOpen={modal} title="Добавить новую кассу" toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Добавить">
					<CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Название</Label>
							<Col sm="12">
								<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Тип кассы</Label>
							<Col sm="12">
								<Select
									placeholder="Выберите..."
									value={selectedType}
									options={[
										{ label: 'Наличный расчет', value: 'Наличный расчет' },
										{ label: 'Безналичный расчет', value: 'Безналичный расчет' },
									]}
									className="js-example-basic-single col-sm-12"
									onChange={(selected) => setSelectedType(selected)}
								/>
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Начальный баланс</Label>
							<Col sm="12">
								<InputGroup>
									<Input className="form-control" type="number" value={initialBalance} onChange={(e) => setInitialBalance(e.target.value)} />
									<InputGroupText>{'₽'}</InputGroupText>
								</InputGroup>
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Комментарий</Label>
							<Col sm="12">
								<textarea rows={'3'} className="form-control" type="tel" value={description} onChange={(e) => setDescription(e.target.value)} />
							</Col>
						</Col>
					</CardBody>
				</CommonModal>
				<CommonModal isOpen={modalTransferFunds} title="Движение средств" toggler={handleTransferFunds} togglerClose={toggleTransferFunds} closeText="Отмена" saveText="Перевести">
					<CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Из кассы</Label>
							<Col sm="12">
								<Select placeholder="Выберите..." value={curCash} options={cashOptions} className="js-example-basic-single col-sm-12" onChange={(selected) => setCurCash(selected)} />
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">В кассу</Label>
							<Col sm="12">
								<Select placeholder="Выберите..." value={toCash} options={cashOptions} className="js-example-basic-single col-sm-12" onChange={(selected) => setToCash(selected)} />
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Сумма</Label>
							<Col sm="12">
								<InputGroup>
									<Input className="form-control" type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
									<InputGroupText>{'₽'}</InputGroupText>
								</InputGroup>
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Комментарий</Label>
							<Col sm="12">
								<textarea rows={'3'} className="form-control" type="tel" value={comment} onChange={(e) => setComment(e.target.value)} />
							</Col>
						</Col>
					</CardBody>
				</CommonModal>
			</Container>
		</Fragment>
	);
};
export default AccountsCashregisters;
