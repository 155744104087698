import React, { useState, useEffect } from 'react';
import { Search } from 'react-feather';
import { Tooltip, Button, IconButton, Checkbox, Switch } from '@mui/material';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Col, Input, InputGroup, InputGroupText, Row, FormGroup, Label, CardBody } from "reactstrap";
import axios from 'axios';
import { Btn } from "../../../../AbstractElements";
import './TableSpecificationPositions.css';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CommonModal from '../CommonModal';
import NestedTable from './NestedTable';
import Select from 'react-select';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { SpecificationsApi, TwinAuthApi, ProductsApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import PositionSettingsModal from './PositionSettingsModal';



const TableSpecificationPositions = ({ specification }) => {
    const { keycloak } = useKeycloak();
    let currentUserId = `${keycloak.tokenParsed.sub}`;
    const { companyData } = useCompanyContext();
    const [expandedRows, setExpandedRows] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isProductSelectionState, setProductSelectionState] = useState(specification.status.name === "В процессе подбора");

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    const [modalPosSettings, setModalPosSettings] = useState(false);
    const togglePosSettings = () => setModalPosSettings(!modalPosSettings);





    const [allUnits, setUnits] = useState([]);
    const [allCategories, setCategories] = useState([]);
    const [newPosition, setNewPosition] = useState({
        id: uuid(),
        lineNumber: '',
        positionName: '',
        description: '',
        countProducts: '',
        comparedCost: '',
        specificationId: '',
        unitId: '',
        categoryId: ''
    });
    const handleCloseModalPos = () => {
        setModal(false);
        setNewPosition({
            id: uuid(),
            lineNumber: '',
            positionName: '',
            description: '',
            countProducts: '',
            comparedCost: '',
            specificationId: '',
            unitId: '',
            categoryId: ''
        });
    };

    const commonBtnStyles = {
        backgroundColor: '#7366ff',
        color: '#fff',
        padding: '6px 16px',
        fontSize: '0.875rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        minHeight: '36px',
        borderRadius: '4px',
        lineHeight: '1.75',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        border: 'none',
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '10px',
    };



    const handleAddRow = async () => {

        if (newPosition.positionName === '') {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        else {
            try {
                newPosition.specificationId = specification.id;
                const response = await axios.post(`${SpecificationsApi}createPosition`, newPosition, {
                    headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
                });
                handleCloseModalPos();
                toast.success('Позиция добавлена!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            catch (error) {
                toast.error('Ошибка добавления!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };



    useEffect(() => {
        fetchCategories();
        fetchUnits();
    }, []);

    const fetchUnits = async () => {
        try {
            const response = await axios.get(`${SpecificationsApi}getUnits`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { query: "" } })
            const unitOptions = response.data.units.map((option) => ({
                label: `${option.name}`,
                value: option.id,
            }));

            setUnits(unitOptions);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${ProductsApi}getCategories`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { query: "" } })
            const categoryOptions = response.data.categories.map((option) => ({
                label: `${option.pagetitle} : ${option.okpd}`,
                value: option.id,
            }));

            setCategories(categoryOptions);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    const handleChangeNewPosition = (field, value) => {
        setNewPosition(prevValues => ({
            ...prevValues,
            [field]: value
        }));
    };

    const renderHeader = () => {

        return (
            <div className="table-header">
                <Btn attrBtn={{ color: "primary", style: commonBtnStyles, onClick: toggle }}>
                    <AddIcon style={{ color: 'white' }} />
                    Новая позиция
                </Btn>
                <Btn
                    attrBtn={{
                        color: "primary",
                        style: commonBtnStyles,
                        disabled: isLoading || isProductSelectionState,
                        onClick: handleReSelectAll
                    }}
                >
                    {isLoading || isProductSelectionState ? (
                        <>
                            <RefreshIcon className="fa-spin" />
                            Подбор товаров...
                        </>
                    ) : (
                        <>
                            <RefreshIcon />
                            Запуск подбора товаров
                        </>
                    )}
                </Btn>
            </div>
        );
    };


    const handleReSelectAll = async () => {
        setIsLoading(true);

        const response = await axios.post(
            `${SpecificationsApi}startProductSelection?specId=${specification.id}`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                }
            }
        );
    };

    const nestedTable = (rowData) => {

        if (isProductSelectionState) {
            return (
                <div className="loading-icon">
                    <RefreshIcon className="fa-spin" />
                    <span>Подбираются товары...</span>
                </div>
            );
        }

        return (<NestedTable rowData={rowData} />);

    };

    const header = renderHeader();

    const specificationLines = specification.specificationLines || [];

    const [positionSetting, setPositionSetting] = useState({
        id: '',
        positionName: '',
        description: '',
        countProducts: 0,
        unitId: '',
        categoryId: '',
        characteristics: [], 
    });

    const toggleModalCharacteristics = (rowData) => {
        setPositionSetting({
            id: rowData.id,
            positionName: rowData.positionName,
            description: rowData.description,
            countProducts: rowData.countProducts,
            unitId: rowData.unitId,
            categoryId: rowData.categoryId,
            characteristics: rowData.characteristics,
        });
        setModalPosSettings(true);
    };
    const savePositionSetting = async () => {

        try {

            if (positionSetting.positionName === '') {
                toast.error('Заполните поле: Название позиции!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }
            else {


                const response = await axios.put(`${SpecificationsApi}updatePosition`, positionSetting, {
                    headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
                });
                toast.success('Позиция успешно обновлена!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                togglePosSettings();
            }
        }
        catch (error) {
            console.log("Error: " + error)
        }

    };

    const handleChangeModalData = (field, value) => {
        setPositionSetting(prevValues => ({
            ...prevValues,
            [field]: value
        }));
    };

    return (
        <div style={{ overflowX: 'auto' }}>
            <div style={{ position: 'relative' }}>

                <CommonModal
                    isOpen={modal}
                    title="Новая позиция"
                    toggler={toggle}
                    togglerClose={handleCloseModalPos}
                    closeText="Отмена"
                    saveText="Добавить"
                    size="lg"
                    onSave={handleAddRow}
                >
                    <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                        {[
                            { label: "Наименование позиции", field: "positionName", type: "text" },
                            { label: "Описание позиции", field: "description", type: "text" },
                            { label: "Количество необходимого товара", field: "countProducts", type: "number" },
                            { label: "Единица измерения", field: "unitId", type: "select", options: allUnits },
                            { label: "Категория", field: "categoryId", type: "select", options: allCategories }
                        ].map(({ label, field, type, options }, index) => (
                            <Row key={index} className="mb-3">
                                <Label className="col-sm-9 col-form-label">{label}</Label>
                                <Col sm="12">
                                    {type === "number" && <Input type="number" name={field} value={newPosition[field]} onChange={e => handleChangeNewPosition(field, e.target.value)} />}
                                    {type === "text" && <Input type="text" name={field} value={newPosition[field]} onChange={e => handleChangeNewPosition(field, e.target.value)} />}
                                    {type === "select" &&
                                        <Select
                                            options={options}
                                            value={options.find(option => option.value === newPosition[field])}
                                            onChange={selectedOption => handleChangeNewPosition(field, selectedOption.value)}
                                            placeholder={`Выберите ${label.toLowerCase()}`}
                                        />
                                    }
                                </Col>
                            </Row>
                        ))}
                    </CardBody>
                </CommonModal>



                <PositionSettingsModal
                    isOpen={modalPosSettings}
                    toggle={togglePosSettings}
                    modalData={positionSetting}
                    allUnits={allUnits}
                    allCategories={allCategories}
                    onSave={savePositionSetting}
                    handleChangeModalData={handleChangeModalData}
                />


                <DataTable
                    value={specificationLines}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    scrollable
                    style={{ width: '100%' }}
                    header={header}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={nestedTable}
                    className="p-datatable-customers"
                    footerClassName="p-datatable-tfoot-right"
                    paginatorClassName="custom-paginator-class"
                    emptyMessage="Нет загруженных позиций"
                >
                    <Column expander style={{ width: '3em' }} />
                    <Column field="lineNumber" header="№ позиции" sortable />
                    <Column
                        field="positionName"
                        header="Название позиции"
                        sortable
                        body={(rowData) => (
                            <div>
                                <span>{rowData.positionName}</span>
                                <Btn
                                    attrBtn={{
                                        color: "primary",
                                        style: {
                                            ...commonBtnStyles,
                                            display: 'block',
                                            marginTop: '15px'
                                        },
                                        onClick: () => toggleModalCharacteristics(rowData),
                                    }}
                                >
                                    Настройки позиции
                                </Btn>
                            </div>
                        )}
                    />
                    <Column header="Подобранные товары" body={(rowData) => nestedTable(rowData)} />
                    <Column field="countProducts" header="Количество" sortable bodyStyle={{ textAlign: 'center' }} headerClassName="text-center" />
                    <Column
                        field="comparedCost"
                        header="Сравнительная стоимость"
                        sortable
                        bodyStyle={{ textAlign: 'center' }}
                        body={(rowData) => (
                            isProductSelectionState ? (
                                <RefreshIcon className="fa-spin" />
                            ) : (
                                `${rowData.comparedCost ? rowData.comparedCost : ''}`
                            )
                        )}
                        headerClassName="text-center"
                    />
                </DataTable>
                <div className="pagination-right">
                </div>

            </div>
        </div>
    );


};

export default TableSpecificationPositions;
