import { CKEditorExample } from "../../Constant";
import { Breadcrumbs, H1, H5, P } from "../../AbstractElements";
import ReactQuill from "react-quill";
import React, { Fragment, useState } from "react";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

const CkEditorContain = () => {
	const [content, setContent] = useState("content");
	const onChange = (evt) => {
		const newContent = evt.editor.getData();
		setContent(newContent);
	};

	const [value, setValue] = useState("");

	return (
		<Fragment>
			<Breadcrumbs mainTitle="CK Editor" parent="Editor" title="CK Editor" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<H5>{CKEditorExample}</H5>
							</CardHeader>
							<CardBody>
								{/* <CKEditor
									editor={ClassicEditor}
									data="<p>Контент страницы</p>"
									onChange={(event, editor) => {
										const data = editor.getData();
										console.log({ event, editor, data });
									}}
								/> */}
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<H5>Inline Editor</H5>
							</CardHeader>
							<CardBody>
								<div id="area1">
									<ReactQuill theme="snow" value={value} onChange={setValue} />
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default CkEditorContain;
