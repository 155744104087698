import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, Row, CardBody, Input, Label } from "reactstrap";
import CountUp from "react-countup";
import Tooltip from "@mui/material/Tooltip";
import { BeatLoader } from "react-spinners";
import { Settings } from "react-feather";
import SweetAlert from "sweetalert2";

import CommonModal from "../../../UiKits/Modals/common/modal";
import { H4, Image, Btn, P } from "../../../../AbstractElements";
import { useGroupsContext } from "../../../../_helper/Groups/GroupsProvider";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const UserProfile = ({
	setTotalMembers,
	totalMembers,
	setTotalPosts,
	totalPosts,
	groupInfo,
	setAllowPosts,
	allowPosts,
	setAllowComments,
	allowComments,
	title,
	setTitle,
	description,
	setDescripton,
	sendCreateRequest,
	subscription,
	setSubscription,
}) => {
	const { 
		addUserToGroup,
		removeUserGromGroup
	} = useGroupsContext();
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	const [modal, setModal] = useState(false);
	const [loadingSub, setLoadingSub] = useState(false);

	const toggle = () => {
		setModal(!modal);
	};

	const cancelSubscription = () => {
		SweetAlert.fire({
			title: "Вы уверены, что хотите отменить подписку?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Да",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				setSubscription(false);
				setTotalMembers(totalMembers - 1);
				removeUserGromGroup(groupInfo.id, keycloak.tokenParsed.sub);
			}
		});
	};

	const subscribe = () => {
		setSubscription(true);
		setTotalMembers(totalMembers + 1);
		addUserToGroup(groupInfo.id, keycloak.tokenParsed.sub);
	};

	const sendRequest = () => {
		setLoadingSub(true);
		const updateGroup = {
			name: groupInfo.name,
			description: groupInfo.description,
			owner: groupInfo.owner,
			total_members: groupInfo.total_members,
			members: groupInfo.members,
			subscription: subscription,
			total_posts: groupInfo.total_posts,
			posts: groupInfo.posts,
			group_type: groupInfo.group_type,
			control: groupInfo.group_type,
			favorites: groupInfo.group_type,
			comments_allowed: groupInfo.allowComments,
			posts_allowed: groupInfo.comments_allowed,
		};
		//put - запрос
		// axios
		// 	.put(`${EducationApi}create-course/${groupInfo.id}`, updateGroup, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
		// 	.then((response) => {
		// 		console.log(concatResults(response));
		// 		setModal(false);setLoadingSub(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);setLoadingSub(false);
		// 	});
	};

	return (
		<Fragment>
			<Col sm="12">
				<Card className="hovercard text-center">
					<CardHeader className="cardheader"></CardHeader>
					<div className="user-image">
						<div className="avatar">
							<Image attrImage={{ className: "step1", alt: "", src: `${require("../../../../assets/images/user/7.jpg")}` }} />
						</div>
					</div>
					<div className="info">
						<Row className="step3" data-intro="This is the your details" style={{ justifyContent: "center" }}>
							<div className="d-flex" style={{ justifyContent: "center" }}>
								<H4 attrH4={{ className: "text-center" }}>{groupInfo.name}</H4>
								{groupInfo.ownerUserId === keycloak.tokenParsed.sub && (
									<Tooltip title="Редактировать" arrow placement="top">
										<button className="settings_btn ms-2" style={{ backgroundColor: "transparent", border: "none" }} onClick={toggle}>
											<Settings color="#565b65" className=" gear-icon" />
										</button>
									</Tooltip>
								)}
							</div>
							<P>{groupInfo.description}</P>
							{subscription ? (
								<div>
									<Btn attrBtn={{ color: "primary", outline: true, onClick: cancelSubscription }}>
										{loadingSub ? (
											<BeatLoader size={8} color="#7366ff" />
										) : (
											<>
												<i className="fa fa-check me-1"></i>Вы подписаны
											</>
										)}
									</Btn>
								</div>
							) : (
								<div>
									<Btn attrBtn={{ color: "primary", onClick: subscribe }}> {loadingSub ? <BeatLoader size={8} color="#ffffff" /> : "Подписаться"}</Btn>
								</div>
							)}
						</Row>

						<hr />
						{
							<div className="follow">
								<Row>
									<Col col="6" className="border-end text-center">
										<CountUp end={totalMembers} className="follow-num counter d-grid" />
										<span>Участники</span>
									</Col>
									<Col col="6" className="text-center">
										<CountUp end={totalPosts} className="follow-num counter d-grid" />
										<span>Постов</span>
									</Col>
								</Row>
							</div>
						}
					</div>
				</Card>
			</Col>
			<CommonModal isOpen={modal} title={"Редактировать"} toggler={sendCreateRequest} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="md">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="12">
							<Input className="form-control" type="tel" value={title} onChange={(e) => setTitle(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="12">
							<Input className="form-control" type="tel" value={description} onChange={(e) => setDescripton(e.target.value)} />
						</Col>
					</Col>

					<Col className="row col-sm-12 mb-2">
						<div className="checkbox checkbox-primary">
							<Input id="checkbox-primary-1" type="checkbox" checked={allowPosts} onChange={(e) => setAllowPosts(e.target.checked)} />
							<Label for="checkbox-primary-1">Разрешать оставлять посты участникам</Label>
						</div>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<div className="checkbox checkbox-primary">
							<Input id="checkbox-primary-2" type="checkbox" checked={allowComments} onChange={(e) => setAllowComments(e.target.checked)} />
							<Label for="checkbox-primary-2">Разрешать оставлять комментарии участникам</Label>
						</div>
					</Col>
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};

export default UserProfile;
