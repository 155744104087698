// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-list-header_ganttTable__nJgba {
	display: table;
	border-bottom: #e6e4e4 1px solid;
	border-top: #e6e4e4 1px solid;
	border-left: #e6e4e4 1px solid;
}

.task-list-header_ganttTable_Header__7Ld7H {
	display: table-row;
	list-style: none;
}

.task-list-header_ganttTable_HeaderSeparator__iUI8F {
	border-right: 1px solid rgb(196, 196, 196);
	opacity: 1;
	margin-left: -2px;
}

.task-list-header_ganttTable_HeaderItem__s8ccU {
	display: table-cell;
	vertical-align: -webkit-baseline-middle;
	vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Application/Project/ProjectView/task-list-header.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,gCAAgC;CAChC,6BAA6B;CAC7B,8BAA8B;AAC/B;;AAEA;CACC,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,0CAA0C;CAC1C,UAAU;CACV,iBAAiB;AAClB;;AAEA;CACC,mBAAmB;CACnB,uCAAuC;CACvC,sBAAsB;AACvB","sourcesContent":[".ganttTable {\n\tdisplay: table;\n\tborder-bottom: #e6e4e4 1px solid;\n\tborder-top: #e6e4e4 1px solid;\n\tborder-left: #e6e4e4 1px solid;\n}\n\n.ganttTable_Header {\n\tdisplay: table-row;\n\tlist-style: none;\n}\n\n.ganttTable_HeaderSeparator {\n\tborder-right: 1px solid rgb(196, 196, 196);\n\topacity: 1;\n\tmargin-left: -2px;\n}\n\n.ganttTable_HeaderItem {\n\tdisplay: table-cell;\n\tvertical-align: -webkit-baseline-middle;\n\tvertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ganttTable": `task-list-header_ganttTable__nJgba`,
	"ganttTable_Header": `task-list-header_ganttTable_Header__7Ld7H`,
	"ganttTable_HeaderSeparator": `task-list-header_ganttTable_HeaderSeparator__iUI8F`,
	"ganttTable_HeaderItem": `task-list-header_ganttTable_HeaderItem__s8ccU`
};
export default ___CSS_LOADER_EXPORT___;
