import React, { Fragment, useState, useEffect } from 'react';
import { Row, CardBody } from 'reactstrap';
import BusinessProcessCard from './BusinessProcessCard';
import axios from 'axios';
import { ModulesApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const StandardPricingcard = () => {
	const [data, setData] = useState();
	const industryId = localStorage.getItem('industryId');
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		industryId &&
			axios
				.get(`${ModulesApi}get-templates/${industryId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
				.then((response) => {
					setData(response.data.templates);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
	}, []);

	return (
		<Fragment>
			<CardBody>
				<Row className="pricing-block">
					{data && data.map((item) => (
						<BusinessProcessCard key={item.id} title={item.title} subTitle={item.subTitle} paragraph={item.description} image={item.image} template={item} />
					))}
				</Row>
				{/*<button disabled={true} class="btn btn-primary" style={{ marginTop: '60px', width: '200px', marginLeft: '83%', height: '40px' }}>
					Далее
					</button>*/}
			</CardBody>
		</Fragment>
	);
};
export default StandardPricingcard;
