import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import { Breadcrumbs, Badges, H5, H6, Btn } from '../../../../AbstractElements';
import { Label, Col, Row, Collapse } from 'reactstrap';
import StudentsResponsesCard from './StudentsResponsesCard';
import { EducationApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const CheckWorks = () => {
	const [isOpen, setIsOpen] = useState(1);
	const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const [data, setData] = useState([]);
	const [dataFilter, setDataFilter] = useState([]);
	const [dataFilterStatus, setDataFilterStatus] = useState([]);
	const [course, setCourse] = useState([]);
	const [courseSeleceted, setCourseSeleceted] = useState({ value: 'Все курсы', label: 'Все курсы' });

	useEffect(() => {
		axios
			.get(`${EducationApi}get-courses`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				const modifiedData = concatResults(response).courses.map((item) => ({ value: item.id, label: item.name }));
				modifiedData.unshift({ value: '0', label: 'Все курсы' });
				setCourse(modifiedData);
				SwapCourseResults('0');
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [status, setStatus] = useState({ value: 'allStatuses', label: 'Все статусы' });

	const [len, setLen] = useState(data.length);
	const [studentSort, setStudentSort] = useState('ascending');

	useEffect(() => {
		if (status.value === 'allStatuses') {
			setDataFilterStatus(dataFilter);
			setLen(dataFilter.length);
		} else {
			setDataFilterStatus(dataFilter.filter((course) => course.Status === status.value));
			setLen(dataFilter.filter((course) => course.Status === status.value).length);
		}
	}, [status, courseSeleceted, studentSort]);

	const handleDescending = () => {
		setStudentSort('ascending');
		setDataFilter(dataFilter.sort((a, b) => a.Student_name.localeCompare(b.Student_name)));
	};

	const handleAscending = () => {
		setStudentSort('descending');
		setDataFilter(dataFilter.sort((a, b) => b.Student_name.localeCompare(a.Student_name)));
	};

	const [assignmentSort, setAssignmentSort] = useState('ascending');

	const handleDescendingAssignment = () => {
		setAssignmentSort('ascending');
		setData(data.sort((a, b) => a.Assignment_name.localeCompare(b.Assignment_name)));
	};

	const handleAscendingAssignment = () => {
		setAssignmentSort('descending');
		setData(data.sort((a, b) => b.Assignment_name.localeCompare(a.Assignment_name)));
	};

	const ChangeCourse = (option) => {
		setCourseSeleceted(option);
		const filteredData = data.filter((course) => course.Course_name === option.value);
		console.log(filteredData);
		SwapCourseResults(option.value);
		if (option.value !== '0') {
			setDataFilter(filteredData);
			setLen(filteredData.length);
		} else {
			setDataFilter(data);
			setLen(data.length);
		}
	};

	const SwapCourseResults = (courseId) => {
		let requestPart = '';
		if (courseId !== '0') {
			requestPart = `?courseId=${courseId}`;
		}
		axios
			.get(`${EducationApi}get-course-results${requestPart}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				setDataFilterStatus(concatResults(response).results);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Проверка работ" parent="Обучение" title="Проверка работ" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<Row style={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
								<Col className="mb-3" md="3">
									<div onClick={() => toggle(1)} style={{ cursor: 'pointer' }}>
										<H6 attrH6={{ className: 'mb-4 mt-4' }}>Сортировка {isOpen === 1 ? <i className="fa fa-angle-up f-18 font-primary"></i> : <i className="fa fa-angle-down f-18 font-primary"></i>}</H6>
									</div>
									<Collapse isOpen={isOpen === 1}>
										<Col className="mb-3">
											<Label>ФИО учеников:</Label>
											<div className="btn-showcase">
												{studentSort === 'ascending' ? (
													<Btn attrBtn={{ color: 'primary', onClick: handleAscending }}>
														В алфавитном порядке <i className="fa fa-sort-amount-asc"></i>
													</Btn>
												) : (
													<Btn attrBtn={{ color: 'primary', onClick: handleDescending }}>
														В обратном порядке <i className="fa fa-sort-amount-desc m-l-20"></i>
													</Btn>
												)}
											</div>
										</Col>
										<Col className="mb-3">
											<Label>Названия заданий:</Label>
											<div className="btn-showcase">
												{assignmentSort === 'ascending' ? (
													<Btn attrBtn={{ color: 'primary', onClick: handleAscendingAssignment }}>
														В алфавитном порядке <i className="fa fa-sort-amount-asc"></i>
													</Btn>
												) : (
													<Btn attrBtn={{ color: 'primary', onClick: handleDescendingAssignment }}>
														В обратном порядке <i className="fa fa-sort-amount-desc m-l-20"></i>
													</Btn>
												)}
											</div>
										</Col>
									</Collapse>
								</Col>
								<Col className="mb-3" md="3">
									<Select placeholder="Выберите..." value={courseSeleceted} options={course} className="js-example-basic-single col-sm-12" onChange={(selectedOption) => ChangeCourse(selectedOption)} />
								</Col>
								<Col md="3" className="mb-3">
									<Select
										placeholder="Выберите..."
										value={status}
										options={[
											{ value: 'allStatuses', label: 'Все статусы' },
											{ value: 'checked', label: 'Проверено' },
											{ value: 'awaiting', label: 'Ожидает проверки' },
										]}
										className="js-example-basic-single col-sm-12"
										onChange={(selectedOption) => setStatus(selectedOption)}
									/>
								</Col>
								<Col md="2">
									<H5>
										<Badges attrBadge={{ color: 'primary' }}>{dataFilterStatus.length} ответов</Badges>
									</H5>
								</Col>
							</Row>
							<Fragment>
								<Col>{dataFilterStatus.length ? dataFilterStatus.map((answer) => <StudentsResponsesCard answer={answer} />) : ''}</Col>
							</Fragment>
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default CheckWorks;
