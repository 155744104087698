import React, { Fragment, useState, useEffect } from "react";
import { Col, Label, Input } from "reactstrap";
import axios from "axios";
import { BeatLoader } from "react-spinners";

import { Btn } from "../../../../AbstractElements";
import { useKeycloak } from "@react-keycloak/web";
import { TelephonyApi } from "../../../../api";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const RecordingEvaluation = ({ pbxId }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [curItem, setCurItem] = useState("");

	useEffect(() => {
		axios
			.get(`/api/pbxlist.json`)
			.then((response) => {
				const foundItem = response.data.find((item) => item.id === pbxId);
				setCurItem(foundItem);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [saveAll, setSaveAll] = useState(false);
	const [recordingWarning, setRecordingWarning] = useState(false);
	const [saveTwoChannel, setSaveTwoChannel] = useState(false);
	const [decryptRecording, setDecryptRecording] = useState(false);
	const [serviceAssessment, setServiceAssessment] = useState(false);

	const [loading, setLoading] = useState(false);
	const send = () => {
		setLoading(true);
		const updItem = {
			...curItem,
			keep_all_conversations: saveAll,
			play_recording_alert: recordingWarning,
			save_in_two_channel: saveTwoChannel,
			decrypt_recording: decryptRecording,
			quality_assessment: serviceAssessment,
		};

		// axios
		// 	.put(`${TelephonyApi}update`, updItem, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Изменения сохранены!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setLoading(false);
		//
		// 	})
		// 	.catch((error) => {
		// 		setLoading(false);
		// 		toast.error('Произошла ошибка', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		console.error(error);
		// 	});
	};

	return (
		<Fragment>
			<div className="checkbox checkbox-primary col-md-12 mb-4">
				<Input id={`checkbox-saveAll`} type="checkbox" checked={saveAll} onChange={(e) => setSaveAll(e.target.checked)} />
				<Label for={`checkbox-saveAll`}>Сохранять запись всех разговоров</Label>
			</div>
			{saveAll && (
				<Col className="ms-3 mb-4">
					<div className="mt-3 mb-3 p-4 b-r-8 font-primary" style={{ backgroundColor: "#7366ff20" }}>
						Убедитесь, что использование данной опции не противоречат особенностям законодательства вашей страны и регламенту вашей компании
					</div>
					<div className="checkbox checkbox-primary col-md-12">
						<Input id={`checkbox-recordingWarning`} type="checkbox" checked={recordingWarning} onChange={(e) => setRecordingWarning(e.target.checked)} />
						<Label for={`checkbox-recordingWarning`}>Проигрывать предупреждение о начале записи при входящем звонке</Label>
					</div>
					<div className="checkbox checkbox-primary col-md-12">
						<Input id={`checkbox-saveTwoChannel`} type="checkbox" checked={saveTwoChannel} onChange={(e) => setSaveTwoChannel(e.target.checked)} />
						<Label for={`checkbox-saveTwoChannel`}>Сохранять запись разговоров в двухканальном формате</Label>
					</div>
					<div className="checkbox checkbox-primary col-md-12">
						<Input id={`checkbox-decryptRecording`} type="checkbox" checked={decryptRecording} onChange={(e) => setDecryptRecording(e.target.checked)} />
						<Label for={`checkbox-decryptRecording`}>Расшифровать запись звонка</Label>
					</div>
				</Col>
			)}
			<div className="checkbox checkbox-primary col-md-12">
				<Input id={`checkbox-serviceAssessment`} type="checkbox" checked={serviceAssessment} onChange={(e) => setServiceAssessment(e.target.checked)} />
				<Label for={`checkbox-serviceAssessment`}>Оценка качества обслуживания</Label>
			</div>
			<p style={{ color: "#898989", paddingLeft: "32px" }}>После окончания разговора с оператором, клиенту будет проиграна запись с просьбой оценить качество обслуживания</p>

			<div className="modal-footer mt-2">
				<Btn attrBtn={{ color: "primary", onClick: send, disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
			</div>
		</Fragment>
	);
};
export default RecordingEvaluation;
