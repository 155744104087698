import React, { Fragment, useState, useEffect } from "react";
import { Col, Label, Input } from "reactstrap";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import Select from "react-select";

import { Btn } from "../../../../AbstractElements";
import { useKeycloak } from "@react-keycloak/web";
import { TelephonyApi } from "../../../../api";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const Integration = ({ pbxId }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [automaticallyCreateLead, setAutomaticallyCreateLead] = useState(null);
	const [leadDirections, setLeadDirections] = useState(null);
	const [automaticallyChangePerson, setAutomaticallyChangePerson] = useState(false);

	const [curItem, setCurItem] = useState("");

	const [directionOptions, setDirectionOptions] = useState(null);
	const [creationOptions, setCreationOptions] = useState(null);

	const getData = async () => {
		try {
			const response = await axios.get(`${TelephonyApi}getPbx`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				params: { Id: pbxId }
			});

			if (pbxId) {
				const foundItem = concatResults(response).pbx;
				setCurItem(foundItem);
			}

			const responseLeadDirections = await axios.get(`${TelephonyApi}getLeadDirections`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
			});

			const mappedDirection = concatResults(responseLeadDirections).leadDirections ? concatResults(responseLeadDirections).leadDirections.map(pbx => ({
				label: pbx.text,
				value: pbx.id
			})) : [];


			const responseLeadCreationOptions = await axios.get(`${TelephonyApi}getLeadCreationOptions`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
			});

			const mappedCreationOptions = concatResults(responseLeadCreationOptions).options ? concatResults(responseLeadCreationOptions).options.map(pbx => ({
				label: pbx.text,
				value: pbx.id
			})) : [];


		} catch (error) { }
	};

	useEffect(() => {
		getData();
	}, []);

	const [loading, setLoading] = useState(false);
	const send = () => {
		setLoading(true);
		const updItem = {
			...curItem,
			automatically_create_lead: automaticallyCreateLead.value,
			lead_directions: leadDirections.value,
			automatically_change_person: automaticallyChangePerson,
		};

	};

	return (
		<Fragment>
			<Col className="row col-sm-12 mb-4 ">
				<Label className="col-sm-9 col-form-label">Автоматически создать новый лид</Label>
				<Col sm="12">
					<Select
						type="tel"
						placeholder="Выберите..."
						value={automaticallyCreateLead}
						options={[
							{ label: "Не создавать", value: "NotCreate" },
							{ label: "Если номер не найден в CRM", value: "IfNumberNotFound" },
							{ label: "При каждом звонке", value: "EveryCall" },
						]}
						onChange={(option) => setAutomaticallyCreateLead(option)}
					/>
				</Col>
			</Col>
			<Col className="row col-sm-12 mb-4">
				<Label className="col-sm-9 col-form-label">Создавать лид только по направлению для</Label>
				<Col sm="12">
					<Select
						type="tel"
						placeholder="Выберите..."
						value={leadDirections}
						options={[
							{ label: "Входящих звонков", value: "IncomingCalls" },
							{ label: "Исходящих звонков", value: "OutgoingCalls" },
							{ label: "Всех звонков", value: "AllCalls" },
						]}
						onChange={(option) => setLeadDirections(option)}
					/>
				</Col>
			</Col>
			<div className="checkbox checkbox-primary col-md-12">
				<Input id={`checkbox-automaticallyChangePerson`} type="checkbox" checked={automaticallyChangePerson} onChange={(e) => setAutomaticallyChangePerson(e.target.checked)} />
				<Label for={`checkbox-automaticallyChangePerson`}>Автоматически менять ответственного за лид, при ручном перенаправлении звонка на другого сотрудника</Label>
			</div>
			<div className="modal-footer mt-2">
				<Btn attrBtn={{ color: "primary", onClick: send, disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
			</div>
		</Fragment>
	);
};
export default Integration;
