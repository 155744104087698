import Select, {components} from "react-select";
import React, {useEffect, useState} from "react";
import {ArrowDropDownRounded} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";

export const CompanySelect = (companyData, userCompanies) => {
    const navigate = useNavigate();
    const { setCompanyData } = useCompanyContext();
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);

    const SelectMenuButton = (props) => {
        return (
            <components.MenuList  {...props}>
                {props.children}
                <button className="btn btn-primary w-100" onClick={() => navigate(`${process.env.PUBLIC_URL}/register-company/`)}>Регистрация компаний</button>
            </components.MenuList >
        ) }

    const DropDownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownRounded />
            </components.DropdownIndicator>
        )
    }

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="">Нет компаний для вывода</span>
            </components.NoOptionsMessage>
        );
    };

    const selectCompany = async (company) => {
        if (companyData.companyData && Array.isArray(companyData.companyData) && companyData.companyData.find((companies) => (companies.id === company.id))) {
            let companyDataCopy = companyData.companyData.filter(el => el.id !== company.id);
            setCompanyData(companyDataCopy);
            sessionStorage.setItem('companyData', JSON.stringify(companyDataCopy.map((item) => ({ groupId: item.id, companyId: item.attributes.companyId[0]  }))));
        }
        else {
            let companyDataCopy = companyData.companyData.filter(el => el.id !== null);
            companyDataCopy.push(company);
            setCompanyData(companyDataCopy);
            setSelectedCompanies(companyDataCopy.map((item) => ({label: item.name, value: item.id})))
            sessionStorage.setItem('companyData', JSON.stringify(companyDataCopy.map((item) => ({ groupId: item.id, companyId: item.attributes.companyId[0]  }))));
        }
    };

    useEffect(() => {
        let companies = Array.isArray(companyData.companyData) && companyData.companyData?.map((item) => ({label: item.name, value: item.id}));

        companies && setSelectedCompanies(companies ?? []);
    }, [companyData]);

    useEffect(() => {
        let arr = Array.isArray(companyData.userCompanies) && companyData.userCompanies.map((item) => ({label: item.name, value: item.id}))
        arr && setAllCompanies(arr);
    }, [userCompanies])

    const select = (item) => {
        if (item.length > selectedCompanies.length) {
            let difference = item.filter(x => !selectedCompanies.includes(x));
            if (userCompanies) {
                let companyFind = Array.isArray(companyData.userCompanies) && companyData.userCompanies.find((company) => company.id === difference[0].value);
                companyFind && selectCompany(companyFind);
            }
        }
        else {
            let difference = selectedCompanies.filter(x => !item.includes(x));
            if (userCompanies) {
                let companyFind = Array.isArray(companyData.userCompanies) && companyData.userCompanies.find((company) => company.id === difference[0].value);
                companyFind && selectCompany(companyFind);
            }
        }

    };

    return <Select
        placeholder="Выбор компаний"
        isMulti
        value={selectedCompanies ?? []}
        options={allCompanies ?? []}
        className="js-example-basic-single col-sm-12"
        theme={(theme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: "rgba(115, 102, 255, 0.08)",
                primary: "#7366ff",
            },

        })}
        components={{ NoOptionsMessage: NoOptionsMessage, MenuList: SelectMenuButton, DropdownIndicator: DropDownIndicator }}
        onChange={(item) => select(item)}
    />
}

export default CompanySelect;