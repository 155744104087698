import React, { useMemo, useState } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Label, Input, CardBody, Col, InputGroupText, InputGroup } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

const TableTypesCards = ({ filteredProducts }) => {
	const columns = [
		{
			name: <div className="ms-3">#</div>,
			selector: (row) => (
				// <Tooltip title="Подробная информация о карте" arrow placement="top">
				// 	<Link to={`${process.env.PUBLIC_URL}/loyalty/loyalty-cards/card-information?cardId=${row.id}`}>
				// 		<div className="ms-3">
				// 			<H6>{row.id}</H6>
				// 		</div>
				// 	</Link>
				// </Tooltip>
				<Tooltip title="Редактировать" arrow placement="top">
					<div className="font-primary" style={{ cursor: 'pointer' }} onClick={() => toggle(row)}>
						{row.id}
					</div>
				</Tooltip>
			),
			sortable: true,
			width: '15%',
		},

		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: <div>Сгорание неиспользованных баллов</div>,
			selector: (row) => `${row.expiration_days} дней`,
			sortable: true,
		},
		{
			name: 'Ограничение применения',
			selector: (row) => `${row.usage_limit}`,
			sortable: true,
		},
		{
			name: (
				<div>
					Ограничение оплаты
					<br />
					(фиксированная сумма)
				</div>
			),
			selector: (row) => `${row.points_payment_limit_amount} ₽`,
			sortable: true,
		},
		{
			name: (
				<div>
					Ограничение оплаты <br />
					(% от суммы чека)
				</div>
			),
			selector: (row) => `${row.points_payment_limit_percentage} %`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {});
	};

	const [edit, setEdit] = useState(null);

	const send = () => {
		if (selectType === '' || name === '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			if (!Id) {
				setId(uuid());
			}
			const newEvent = {
				id: Id,
				type: selectType.value,
			};
			console.log(newEvent);
			// axios
			// 	.post(`/api/clientsDemandsForm.json`, newEvent)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Календарь добавлен!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 	});
		}
	};
	const [name, setName] = useState('');
	const [selectType, setSelectType] = useState({ label: null, value: null });
	const [expirationDays, setExpirationDays] = useState({ label: null, value: null });
	const [usageLimit, setUsageLimit] = useState({ label: null, value: null });

	const [maxDiscountPercentage, setMaxDiscountPercentage] = useState('');
	const [maxFixedDiscount, setMaxFixedDiscount] = useState('');
	const [Id, setId] = useState('');

	const [modal, setModal] = useState(false);

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setId(ObjectId.id);
			setSelectType({ label: ObjectId.auto_issue, value: ObjectId.auto_issue });
			setExpirationDays({ label: ObjectId.expiration_days + ' дней', value: ObjectId.expiration_days });
			setUsageLimit({ label: ObjectId.usage_limit, value: ObjectId.usage_limit });
			setMaxDiscountPercentage(ObjectId.points_payment_limit_percentage);
			setMaxFixedDiscount(ObjectId.points_payment_limit_amount);
			setName(ObjectId.name);
			setEdit(true);
		} else {
			setSelectType({ label: null, value: null });
			setExpirationDays({ label: 'Не применять', value: 'Не применять' });
			setUsageLimit({ label: 'Без ограничений', value: 'Без ограничений' });
			setId('');
			setMaxDiscountPercentage('');
			setMaxFixedDiscount('');

			setName('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Автоматический выпуск</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={selectType}
								options={[
									{ label: 'Не выдавать новым клиентам', value: 'Не выдавать новым клиентам' },
									{ label: 'Выдавать в подключенных салонах', value: 'Выдавать в подключенных салонах' },
									{ label: 'Выдавать в любом салоне', value: 'Выдавать в любом салоне' },
								]}
								onChange={(option) => setSelectType(option)}
							/>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Сгорание всех неиспользованных баллов(с последнего посещения)</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={expirationDays}
								options={[
									{ label: 'Не применять', value: 'Не применять' },
									{ label: '1 день', value: '1 день' },
									{ label: '100 дней', value: '100 дней' },
								]}
								onChange={(option) => setExpirationDays(option)}
							/>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Ограничение применения (списание баллов)</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={usageLimit}
								options={[
									{ label: 'Без ограничения', value: 'Без ограничения' },
									{ label: 'Только услуги', value: 'Только услуги' },
									{ label: 'Только товары', value: 'Только товары' },
								]}
								onChange={(option) => setUsageLimit(option)}
							/>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Ограничение оплаты (% от суммы чека)</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={maxDiscountPercentage} onChange={(e) => setMaxDiscountPercentage(e.target.value)} />
								<InputGroupText>{'%'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label"> Ограничение оплаты (фиксированная сумма)</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={maxFixedDiscount} onChange={(e) => setMaxFixedDiscount(e.target.value)} />
								<InputGroupText>{'₽'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableTypesCards;
