import React, { Fragment, useState, useEffect } from 'react';
import { CardHeader, Label } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Card, Col, Container, Row } from 'reactstrap';
import axios from 'axios';
import { ModulesApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useConpagesContext } from '../../../../../_helper/ConsecutivePages/ConpagesProvider';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const SelectIndustry = () => {
	const [options, setOptions] = useState();
	const { setNextPageInCompany, companyConpagesData } = useConpagesContext();
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const navigate = useNavigate();
	//const options = industry.map((item) => ({ value: item.id, label: item.industryName }));

	useEffect(() => {
		axios
			.get(`${ModulesApi}get-industries`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				setOptions(response.data.industries.map((item) => ({ value: item.id, label: item.name })));
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	// Создаем состояние для хранения выбранного значения в селекте
	const [selectedValue, setSelectedValue] = useState(null);

	// Функция-обработчик для обновления состояния при выборе значения в селекте
	const handleSelectChange = (selected) => {
		setSelectedValue(selected); // Обновляем состояние выбранного значения
		console.log(selectedValue);
	};

	// Функция для проверки, выбрано ли значение в селекте
	const isNextButtonDisabled = !selectedValue;

	const handleSubmit = async () => {
		try {
			if (!selectedValue) return; // Если ничего не выбрано, выходим

			//const selectedIndustryId = selectedValue.value;

			//navigate(`/business-process-template/select-template?industryId=${selectedValue.value}`);
			console.log(selectedValue);
			localStorage.setItem('industryId', selectedValue.value);
			console.log(1);
			setNextPageInCompany();
			console.log(2);
			navigate(companyConpagesData.link);


			// Отправка POST-запроса с данными в формате JSON
			//await axios.post('http://localhost:8000/industry', selectedModule);

			// Успешно отправлено
			//alert('Данные успешно отправлены');
		} catch (error) {
			// Обработка ошибок при отправке запроса
			console.error('Ошибка при отправке данных:', error);
		}
	};

	return (
		<Fragment>
			<Container fluid={true} style={{ paddingTop: '50px' }}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h3 style={{ textAlign: 'center' }}>Выберите отрасль компании</h3>
							</CardHeader>
							<div className="mb-2" style={{ padding: '50px' }}>
								<Label style={{ fontSize: '16px' }} className="col-form-label">
									Выберите из списка
								</Label>
								<Select placeholder={<div>Начните вводить...</div>} noOptionsMessage={() => 'Нет результатов'} options={options} onChange={(e) => handleSelectChange(e)} className="js-example-basic-single col-sm-12" />
							</div>

							<button onClick={handleSubmit} disabled={isNextButtonDisabled} class="btn btn-primary" style={{ marginTop: '30px', width: '200px', marginBottom: '30px', marginLeft: '83%', height: '40px' }}>
								Далее
							</button>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default SelectIndustry;
