import React, { useMemo, useState, useContext, useEffect } from "react";
import { Trash2, Edit, Users, ExternalLink } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { toast } from "react-toastify";
import { Label, Input, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import DnDContext from "../../../../_helper/DnD";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TableDepartmentManagement = ({ allDepartment, allUsersNames }) => {
	const { setSelectUser, selectUser, selectDepartment, setSelectDepartment, description, setDescription, name, setName, departments, id, setId, getDepartments } = useContext(DnDContext);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [editModal, setEditModal] = useState(false);
	const [edit, setEdit] = useState(null);

	useEffect(() => {
		getDepartments();
	}, []);

	const toggle = (siteId) => {
		if (typeof siteId === "object" && siteId.hasOwnProperty("id")) {
			setName(siteId.name);
			setId(siteId.id);
			setDescription(siteId.description);
			setEdit(true);
		} else {
			setName("");
			setId("");
			setDescription("");
			setEdit(false);
		}

		setEditModal(!editModal);
	};

	function getDepartmentNameById(id) {
		const department = departments.find((dept) => dept.id === id);
		return department ? department.name : "";
	}

	const columns = [
		{
			name: "Название отдела",
			selector: (row) => `${row.name}`,
			sortable: true,
			width: "15%",
		},
		{
			name: "Описание",
			selector: (row) => `${row.description}`,
			sortable: true,
			width: "40%",
		},
		{
			name: "Руководитель отдела",
			selector: (row) =>
				row.head && (
					<>
						{row.head.name}{" "}
						<Link className="ms-2">
							Помощники <ExternalLink size={"16px"} style={{ margin: "0 0px -2px -2px" }} />
						</Link>
					</>
				),
			sortable: true,
			width: "20%",
		},
		{
			name: "Родительский отдел",
			selector: (row) => (row.parent_department ? `${getDepartmentNameById(row.parent_department)}` : ""),
			sortable: false,
			width: "18%",
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Список сотрудников" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/app/employees?departmentId=${row.id}`}>
							<Users size={"18px"} />
						</Link>
					</Tooltip>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "8px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteRow(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	//удаление отдела
	const deleteRow = (Id) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				// axios
				// 	.delete(`${CmsApi}deleteSite`, {
				// 		data: {
				// 			siteId: Id,
				// 		},
				// 		headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				// 	})
				// 	.then((response) => {
				// 		SweetAlert.fire("Удалено!", "Объект был удален.", "success");
				// 	})
				// 	.catch((error) => {
				// 		console.error("Произошла ошибка при удалении:", error);
				// 	});
			}
		});
	};

	//добавление и редактирование отдела
	const handleAddEditDepartment = () => {
		const newDepartment = {
			name: name,
			description: description,
			head: { name: selectUser.label, id: selectUser.value },
			assistants: [],
			parent_department: selectDepartment ? selectDepartment.value : null,
		};
		if (id === "") {
			// axios
			// 	.post(`${CmsApi}createSite`, newEvent, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 	.then((response) => {
			// 		toast.success("Сайт добавлен!", {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setEditModal(false);
			// 	})
			// 	.catch((error) => {
			// 		toast.error("Ошибка при добавлении", {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 	});
		} else {
			// axios
			// 	.put(`${CmsApi}updateSite`, newEvent, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 	.then((response) => {
			// 		toast.success("Сайт обновлен!", {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setEditModal(false);
			// 	})
			// 	.catch((error) => {
			// 		setEditModal(false);
			// 		toast.error("Ошибка при обновлении", {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 	});
		}
	};
	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = departments.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				noDataComponent={"В данной таблице ещё нет записей."}
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal
				isOpen={editModal}
				title={edit ? "Редактировать отдел" : "Добавить отдел"}
				toggler={handleAddEditDepartment}
				togglerClose={toggle}
				closeText="Отмена"
				saveText="Сохранить"
				size="lg"
			>
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className=" col-sm-11 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="12">
							<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className=" col-sm-11 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="12">
							<textarea rows={"3"} className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
						</Col>
					</Col>
					<Row>
						<Col className=" col-sm-7 mb-3">
							<Label className="col-sm-9 col-form-label">Руководитель отдела</Label>
							<Col sm="12">
								<Select
									placeholder="Начните вводить имя пользователя..."
									noOptionsMessage={() => "Нет результатов"}
									options={allUsersNames}
									value={selectUser}
									onChange={(option) => setSelectUser(option)}
									className="col-sm-12"
								/>
							</Col>
						</Col>
						<Col className=" col-sm-4 mb-3">
							<Label className="col-sm-9 col-form-label">Родительский отдел</Label>
							<Col sm="12">
								<Select
									placeholder="Выберите..."
									noOptionsMessage={() => "Нет результатов"}
									options={allDepartment}
									value={selectDepartment}
									onChange={(option) => setSelectDepartment(option)}
									className="col-sm-12"
								/>
							</Col>
						</Col>
					</Row>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableDepartmentManagement;
