// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fields-container {
    display: flex;
    overflow: hidden;
    max-width: 100%;
}

.fields-left {
    border-right: 1px solid #ddd;
}

.fields-right {
    border-left: 1px solid #ddd;
}

.field-block {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

    .field-block input {
        margin-right: 10px;
        flex: 1 1;
    }

    .field-block button {
        flex-shrink: 0;
    }

.fields-left, .fields-right {
    flex: 1 1;
    padding: 15px;
    max-height: 1000px;
    overflow: auto;
    scrollbar-width: none;
}

    .fields-left::-webkit-scrollbar, .fields-right::-webkit-scrollbar {
        display: none;
    }

body::-webkit-scrollbar {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Application/DataExport/FieldsMapsSettings/Styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;IAEI;QACI,kBAAkB;QAClB,SAAO;IACX;;IAEA;QACI,cAAc;IAClB;;AAEJ;IACI,SAAO;IACP,aAAa;IACb,kBAAkB;IAClB,cAAc;IACd,qBAAqB;AACzB;;IAEI;QACI,aAAa;IACjB;;AAEJ;IACI,aAAa;AACjB","sourcesContent":[".fields-container {\n    display: flex;\n    overflow: hidden;\n    max-width: 100%;\n}\n\n.fields-left {\n    border-right: 1px solid #ddd;\n}\n\n.fields-right {\n    border-left: 1px solid #ddd;\n}\n\n.field-block {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n    .field-block input {\n        margin-right: 10px;\n        flex: 1;\n    }\n\n    .field-block button {\n        flex-shrink: 0;\n    }\n\n.fields-left, .fields-right {\n    flex: 1;\n    padding: 15px;\n    max-height: 1000px;\n    overflow: auto;\n    scrollbar-width: none;\n}\n\n    .fields-left::-webkit-scrollbar, .fields-right::-webkit-scrollbar {\n        display: none;\n    }\n\nbody::-webkit-scrollbar {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
