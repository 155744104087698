import React, { Fragment, useEffect, useState } from 'react';
import { Container, Col } from 'reactstrap';
import axios from 'axios';
import draftToHtml from 'draftjs-to-html';
import { useLocation } from 'react-router-dom';
import { EducationApi } from '../../../../../api';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../../concatResults';

const ViewDiploma = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const diplomaId = queryParams.get('diplomaId');

	const [initialContent, setInitialContent] = useState(EditorState.createEmpty().getCurrentContent());
	const [img, setImg] = useState('');

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const fetchData = async () => {
		try {
			const responseDiploma = await axios.get(`${EducationApi}get-course-diploma-form/${diplomaId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const diploma = concatResults(responseDiploma);
			setImg({ id: diploma.image.id, src: diploma.image.src, width: diploma.image.width, height: diploma.image.height });

			//const convertedBlocks = diploma.blocks.map((block) => {
			//	if (block.data && block.data.length > 0) {
			//		const convertedStyles = convertStylesFromServerFormat(block.data);
			//		return { ...block, ...convertedStyles };
			//	}
			//	return block;
			//});

			const convertedBlocks = JSON.parse(diploma.diplomaContent);

			//const newContentState = convertFromRaw({
			//	blocks: convertedBlocks,
			//	entityMap: {},
			//});

			const newContentState = convertFromRaw(convertedBlocks);

			setInitialContent(newContentState);
		}
		catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	useEffect(() => {

		if (diplomaId && diplomaId !== 'null') {
			fetchData();
		}

	}, []);


	function convertStylesFromServerFormat(stylesFromServer) {
		const convertedStyles = stylesFromServer.map((style) => {
			return {
				item1: style.id,
				item2: style.item2,
				item3: style.item3
			};
		});
		const groupedStyles = convertedStyles.reduce((acc, style) => {
			acc[style.item2] = style.item3;
			return acc;
		}, {});

		return {
			inlineStyleRanges: [],
			data: groupedStyles,
		};
	}

	return (
		<Fragment>
			<Container fluid={true}>
				<Col md="12" style={{ backgroundImage: `url(${img.src})`, backgroundAttachment: 'local', backgroundRepeat: 'no-repeat' }}>
					<div className="view-diplom-box" style={{ width: img.width, height: img.height, padding: '0 130px' }} dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(initialContent)) }} />
				</Col>
			</Container>
		</Fragment>
	);
};
export default ViewDiploma;
