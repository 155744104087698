import React, { Fragment, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { useKeycloak } from "@react-keycloak/web";
import { TaskApi } from "../../../api";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../concatResults";

const StatusButton = ({ userItem }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [comActivated, setActivated] = useState(null);

	const [loading, setLoading] = useState(false);

	const activate = () => {
		const handleError = (error) => {
			console.error("Ошибка получения данных:", error);
			toast.error("Ошибка при активации запроса!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			setLoading(false);
		};

		if (userItem.position === "Исполнитель" || userItem.position === "Постановщик") {
			SweetAlert.fire({
				title: userItem.position === "Исполнитель" ? "Хотите изменить исполнителя?" : userItem.position === "Постановщик" ? "Хотите изменить постановщика?" : "",
				text: `У задачи может быть только 1 ${userItem.position === "Исполнитель" ? "исполнитель" : "постановщик"}. Текущий ${
					userItem.position === "Исполнитель" ? "исполнитель" : "постановщик"
				} будет переведен в наблюдатели.`,

				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "ОК",
				cancelButtonText: "Отмена",
				reverseButtons: true,
			}).then((result) => {
				if (result.value) {
					axios
						.get(`${TaskApi}get`, {
							headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
						})
						.then((response) => {
							const foundItem = concatResults(response).tasks.find((item) => item.id === userItem.userId);
							let updItem = {};
							if (userItem.position === "Исполнитель") {
								updItem = {
									...foundItem,
									doerId: userItem.userId,
									watchers: [...foundItem.watchers, foundItem.doerId],
									accessRequests: [],
								};
							} else {
								updItem = {
									...foundItem,
									creatorId: userItem.userId,
									watchers: [...foundItem.watchers, foundItem.creatorId],
									accessRequests: [],
								};
							}
							axios
								.put(`${TaskApi}update`, updItem, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
								.then((response) => {
									toast.success("Запрос активирован!", {
										position: toast.POSITION.TOP_RIGHT,
									});
									setActivated(true);
									setLoading(false);
								})
								.catch((error) => {
									handleError(error);
								});
						})
						.catch((error) => {
							handleError(error);
						});
				}
			});
		} else {
			axios
				.get(`${TaskApi}get`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					const foundItem = concatResults(response).tasks.find((item) => item.id === userItem.userId);
					let updItem = {};
					if (userItem.position === "Наблюдатель") {
						updItem = {
							...foundItem,
							watchers: [...foundItem.watchers, userItem.userId],
							accessRequests: [],
						};
					} else {
						updItem = {
							...foundItem,
							coDoers: [...foundItem.coDoers, userItem.userId],
							accessRequests: [],
						};
					}
					axios
						.put(`${TaskApi}update`, updItem, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
						.then((response) => {
							toast.success("Запрос активирован!", {
								position: toast.POSITION.TOP_RIGHT,
							});
							setActivated(true);
							setLoading(false);
						})
						.catch((error) => {
							handleError(error);
						});
				})
				.catch((error) => {
					handleError(error);
				});
		}
	};

	const reject = () => {
		setLoading(true);
		axios
			.get(`${TaskApi}get`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				const foundItem = concatResults(response).tasks.find((item) => item.id === userItem.userId);
				const updItem = {
					...foundItem,
					accessRequests: [],
				};
				axios
					.put(`${TaskApi}update`, updItem, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						toast.success("Запрос отклонен!", {
							position: toast.POSITION.TOP_RIGHT,
						});
						setLoading(false);
						setActivated(false);
					})
					.catch((error) => {
						console.error("Ошибка получения данных:", error);
						setLoading(false);
					});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
				toast.error("Ошибка при отклонении запроса!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setLoading(false);
			});
	};

	return (
		<Fragment>
			{loading ? (
				<div className="col-md-12" style={{ marginLeft: "5rem" }}>
					<div className="loader-box" style={{ height: "50px" }}>
						<div className="loader-15"></div>
					</div>
				</div>
			) : (
				<div>
					{comActivated === true ? (
						<span style={{ fontWeight: "600", color: "#54ba4a", marginLeft: "5rem" }}>
							Активировано <i className="icofont icofont-ui-check"></i>
						</span>
					) : comActivated === false ? (
						<span style={{ fontWeight: "600", color: "#fc4438", marginLeft: "5rem" }}>
							Отклонено <i className="icofont icofont-ui-block"></i>
						</span>
					) : (
						<div role="group" className="btn-group-pill btn-group">
							<button className="btn btn-outline-success btn" onClick={activate} disabled={loading || comActivated}>
								Активировать
							</button>
							<button className="btn btn-outline-danger btn" onClick={reject} disabled={loading || comActivated}>
								Отклонить
							</button>
						</div>
					)}
				</div>
			)}
		</Fragment>
	);
};
export default StatusButton;
