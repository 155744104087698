import React, { useLayoutEffect, useState } from "react";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import Editor from "ckeditor5-build-classic-simple-upload";
//import { Context } from '@ckeditor/ckeditor5-core';
/*import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import {SourceEditing} from "@ckeditor/ckeditor5-source-editing";*/

const editorConfiguration = {
	//plugins: [Essentials, Bold, Italic, Paragraph], //SourceEditing],
	toolbar: ["bold", "italic", "sourceEditing"],
};

function MyEditor() {
	// useLayoutEffect(() => {
	// 	const editorElement = document.querySelector("#editor");
	// 	if (!editorElement) {
	// 		console.error('Element with id "editor" not found.');
	// 		return;
	// 	}

	// 	ClassicEditor.create(editorElement, {
	// 		plugins: [SourceEditing],
	// 		toolbar: ["sourceEditing" /* ... */],
	// 	})
	// 		.then((editor) => {
	// 			console.log("Editor was initialized", editor);
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 		});
	// }, []);

	return (
		<div>
			<CKEditor
				editor={ Editor }
				config={{
					htmlSupport: {
						allow: [
							{
								name: 'style',
								styles: true
							},
							{
								name: 'script',
								styles: true
							},
							{
								name: /.*/,
								attributes: true,
								classes: true,
								styles: true
							}
						]
					}
				}}

			/>
			<>
				<style>{`
					.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-powered-by-balloon {
					display: none;
				}`}
				</style>
			</>
		</div>
	);
}

export default MyEditor;
