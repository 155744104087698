import React, { Fragment, useState, useEffect } from 'react';
import { Label, Row, Col, Input, CardHeader, CardBody } from 'reactstrap';
import axios from 'axios';
import { Btn } from '../../../../AbstractElements';

const Notifications = ({ clientId }) => {
	const send = () => {
		// return axios
		// 	.post(` http://localhost:8000/EditMenuEnter`, data)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Изменения сохранены!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
	};
	const [data, setData] = useState([]);

	useEffect(() => {
		axios
			.get('/api/clientsList.json')
			.then((response) => {
				const foundObject = response.data.find((item) => item.id === Number(clientId));
				setData(foundObject);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Row className="mb-2">
				<Col md="12 mb-2">
					<CardHeader style={{ paddingRight: '30%' }}>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Рекламные рассылки</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="advertising-mailings" type="checkbox" />
									<Label for="advertising-mailings">Исключить</Label>
								</div>
							</Col>
						</Row>
						<Row style={{ marginTop: '10px' }}>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Доступные каналы</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="available-channels-push" type="checkbox" />
									<Label for="available-channels-push" style={{ margin: '10px 0 5px 0' }}>
										Push
									</Label>
								</div>
								<div className="checkbox checkbox-primary">
									<Input id="available-channels-vk" type="checkbox" />
									<Label for="available-channels-vk" style={{ margin: '5px 0' }}>
										VK
									</Label>
								</div>
								<div className="checkbox checkbox-primary">
									<Input id="available-channels-sms" type="checkbox" />
									<Label for="available-channels-sms" style={{ margin: '5px 0' }}>
										SMS
									</Label>
								</div>
								<div className="checkbox checkbox-primary">
									<Input id="available-channels-email" type="checkbox" />
									<Label for="available-channels-email" style={{ margin: '5px 0' }}>
										Email
									</Label>
								</div>
							</Col>
						</Row>
					</CardHeader>
					<CardBody style={{ paddingRight: '30%' }}>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Предстоящий визит</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="upcoming-visit" type="checkbox" />
									<Label for="upcoming-visit">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Запрос отзыва после визита, запись на который создана через виджет онлайн-записи</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="review-online-client" type="checkbox" />
									<Label for="review-online-client">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Запрос отзыва после визита, запись на который производилась через журнал записи</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="review-register" type="checkbox" />
									<Label for="review-register">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Содание записи через виджет онлайн-записи</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="create-online-client" type="checkbox" />
									<Label for="create-online-client">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Содание записи через журнал записи</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="create-register" type="checkbox" />
									<Label for="create-register">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Поздравление с днем рождения</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="congratulation-birthday" type="checkbox" />
									<Label for="congratulation-birthday">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Новая скидка</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="new-discount" type="checkbox" />
									<Label for="new-discount">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Окончание действия скидки</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="ending-discount" type="checkbox" />
									<Label for="ending-discount">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Привязка электронной карты</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="attach-email" type="checkbox" />
									<Label for="attach-email">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Приглашение на повторный визит</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="invitation-return-visit" type="checkbox" />
									<Label for="invitation-return-visit">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<div style={{ textAlign: 'right', fontWeight: 600, margin: '10px 0' }}>Приглашение на визит недошедших клиентов</div>
							</Col>
							<Col md="6">
								<div className="checkbox checkbox-primary">
									<Input id="invitation-visit-failed-clients" type="checkbox" />
									<Label for="invitation-visit-failed-clients">Отправлять уведомление</Label>
								</div>
							</Col>
						</Row>
					</CardBody>
				</Col>
			</Row>
			<div className="modal-footer" style={{ paddingTop: '40px' }}>
				<Btn attrBtn={{ color: 'primary', onClick: send }}>Сохранить изменения</Btn>
			</div>
		</Fragment>
	);
};
export default Notifications;
