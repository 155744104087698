import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import { Btn, H1, H2, H3, H4, H5, P } from "../../../AbstractElements";
import Bg1 from "../../../assets/images/order-system/_-1.png";
import Img1 from "../../../assets/images/order-system/blob.png";
import Icon1 from "../../../assets/images/order-system/briefcase_1.png";
import Icon2 from "../../../assets/images/order-system/benchmark.png";
import Icon3 from "../../../assets/images/order-system/ruble.png";
import Img3 from "../../../assets/images/order-system/_-2.png";
import Img4 from "../../../assets/images/order-system/2froooooont.png";
import Img5 from "../../../assets/images/order-system/4riiiiiiiight.png";
import Icon6 from "../../../assets/images/order-system/icon6.png";
import Img6 from "../../../assets/images/order-system/3froooooont.png";

import Icon21 from "../../../assets/images/order-system/shopping-cart_1.png";
import Icon23 from "../../../assets/images/order-system/save-money.png";
import Icon24 from "../../../assets/images/order-system/food-basket.png";
import Icon25 from "../../../assets/images/order-system/delivery.png";
import Icon26 from "../../../assets/images/order-system/calendar_1.png";
import StandardPricingcard from "../../Application/PackageOffers/ClientView/StandardProcingCard";
import Form from "../Form";
import NavMenu from "../NavMenu";

const OrderSystem = () => {
	const [item, setItem] = useState("");

	useEffect(() => {
		axios
			.get("/api/packageOffersList.json")
			.then((response) => {
				const foundItem = response.data.find((item) => item.id === "1");
				setItem(foundItem);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);
	return (
		<Fragment>
			<Container fluid className="user-card">
				<NavMenu />
				<Row style={{ backgroundImage: `url(${Bg1})`, height: "80vh", color: "#ffffff" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "14vh", justifyContent: "space-between" }}>
						<H1 attrH1={{ className: "f-w-600 mb-3" }}>Система заказов</H1>
						<P>Полный спектр инструментов для удобного и эффективного управления вашими заказами</P>
						<div style={{ flex: "1", alignItems: "center", display: "flex" }}>
							<Btn attrBtn={{ size: "lg" }}>Начать сейчас</Btn>
						</div>
						<img src={Img1} alt="bg-image" />
					</Col>
				</Row>
				<Row style={{ backgroundColor: "#f3f3f3", height: "70vh" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "16vh" }}>
						<H1 attrH1={{ className: "f-w-600 mb-3" }}>Что включает в себя наше решение?</H1>
						<div style={{ display: "flex", margin: "4rem 12rem", height: "32vh" }}>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon1} alt="icon1" style={{ width: "120px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Интегрированная платформа</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Все инструменты управления бизнесом в одном месте, экономия времени и упрощение процесса.
								</p>
							</Col>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon2} alt="icon1" style={{ width: "120px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Персонализированный анализ</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Индивидуальные отчеты и аналитика, помогающие принимать обоснованные решения.
								</p>
							</Col>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2rem", textAlign: "center" }}>
								<img src={Icon3} alt="icon1" style={{ width: "120px" }} />
								<H4 attrH4={{ className: "f-w-600 mb-3 mt-4" }}>Гибкая ценовая политика</H4>
								<p className="f-16" style={{ lineHeight: "2" }}>
									Платите только за необходимые функции, легко масштабируйте аккаунт по мере роста бизнеса.
								</p>
							</Col>
						</div>
					</Col>
				</Row>
				<Row style={{ height: "50vh" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16vh 40vh 4vh", justifyContent: "space-between" }}>
						<img src={Img3} alt="Image3" style={{ width: "100px", borderRadius: "50%" }} />
						<H2 attrH2={{ className: "f-w-600 mt-4" }}>Рабочая среда</H2>
						<p className="f-18 text-center">
							Рабочая среда системы заказов обеспечивает безопасное хранение данных и совместную работу. Создавайте и управляйте заказами, (... про аналитику и отслеживание??)
						</p>
					</Col>
				</Row>
				<Row>
					<Col sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
						<img src={Img4} alt="Image4" />
					</Col>
				</Row>
				<Row style={{ backgroundColor: "#f3f3f3", height: "90vh", paddingLeft: "20vh", paddingRight: "20vh" }}>
					<Col md="5" sm="12" style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingRight: "2rem" }}>
						<H3 attrH3={{ className: "f-w-600 mb-4" }}>Удобно в любом месте, с любого устройства</H3>
						<p className="f-18">
							Используйте любое удобное устройство. Ноутбук, планшет или телефон — наша платформа работает одинаково хорошо на любом экране. В любой момент вы в курсе происходящего!{" "}
						</p>
					</Col>
					<Col md="7" sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
						<img src={Img5} alt="Image5" style={{ maxHeight: "800px" }} />
					</Col>
				</Row>
				<Row style={{ height: "50vh" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16vh 40vh 4vh", justifyContent: "space-between" }}>
						<img src={Icon6} alt="Image6" style={{ width: "100px", borderRadius: "50%" }} />
						<H2 attrH2={{ className: "f-w-600 mt-4" }}>Контроль</H2>
						<p className="f-18 text-center">
							Наша система предлагает множество инструментов для аналитики, включая отслеживание динамики сотрудников, анализ выручки, контроль остатка средств в кассах и разнообразные
							отчеты.
						</p>
					</Col>
				</Row>
				<Row>
					<Col sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
						<img src={Img6} alt="Image6" />
					</Col>
				</Row>
				<Row style={{ backgroundColor: "#f3f3f3", height: "100vh" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "16vh" }}>
						<H1 attrH1={{ className: "f-w-600 mb-4" }}>Функциональные сервисы системы заказов ERP</H1>
						<div style={{ display: "flex", margin: "4rem 12rem", flexWrap: "wrap" }}>
							<Col
								md="4"
								sm="6"
								xs="12"
								className="mb-4"
								style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2.5rem", textAlign: "center" }}
							>
								<img src={Icon21} alt="icon1" style={{ width: "100px" }} />
								<H5 attrH5={{ className: "f-w-600 mb-3 mt-4" }}>Сервис управления предложениями</H5>
								<p style={{ lineHeight: "1.8" }}>
									Создавайте, редактируйте и отслеживайте различные предложения и акции, привлекая больше клиентов и увеличивая прибыль вашего бизнеса.
								</p>
							</Col>
							<Col
								md="4"
								sm="6"
								xs="12"
								className="mb-4"
								style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2.5rem", textAlign: "center" }}
							>
								<img src={Icon2} alt="icon2" style={{ width: "100px" }} />
								<H5 attrH5={{ className: "f-w-600 mb-3 mt-4" }}>Сервис аналитики</H5>
								<p style={{ lineHeight: "1.8" }}>
									Получайте ценные данные о вашем бизнесе, включая отчеты, графики и статистику, чтобы лучше понять потребности клиентов и оптимизировать работу предприятия.
								</p>
							</Col>
							<Col
								md="4"
								sm="6"
								xs="12"
								className="mb-4"
								style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2.5rem", textAlign: "center" }}
							>
								<img src={Icon23} alt="icon3" style={{ width: "100px" }} />
								<H5 attrH5={{ className: "f-w-600 mb-3 mt-4" }}>Сервис управления финансами</H5>
								<p style={{ lineHeight: "1.8" }}>Эффективно управляйте финансами вашего бизнеса, ведите учет расходов и доходов, составляйте отчеты и планируйте бюджет.</p>
							</Col>
							<Col
								md="4"
								sm="6"
								xs="12"
								className="mb-4"
								style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2.5rem", textAlign: "center" }}
							>
								<img src={Icon24} alt="icon4" style={{ width: "100px" }} />
								<H5 attrH5={{ className: "f-w-600 mb-3 mt-4" }}>Сервис работы с заявками</H5>
								<p style={{ lineHeight: "1.8" }}>Организуйте и оптимизируйте процесс приема и обработки заказов, делая ваш бизнес более эффективным и клиентоориентированным.</p>
							</Col>
							<Col
								md="4"
								sm="6"
								xs="12"
								className="mb-4"
								style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2.5rem", textAlign: "center" }}
							>
								<img src={Icon25} alt="icon5" style={{ width: "100px" }} />
								<H5 attrH5={{ className: "f-w-600 mb-3 mt-4" }}>Сервис модуля доставки</H5>
								<p style={{ lineHeight: "1.8" }}>Организуйте эффективный процесс доставки товаров или услуг, делая ваш бизнес более доступным для клиентов.</p>
							</Col>

							<Col
								md="4"
								sm="6"
								xs="12"
								className="mb-4"
								style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "0 2.5rem", textAlign: "center" }}
							>
								<img src={Icon26} alt="icon6" style={{ width: "100px" }} />
								<H5 attrH5={{ className: "f-w-600 mb-3 mt-4" }}>Сервис работы с календарем</H5>
								<p style={{ lineHeight: "1.8" }}>Ведите расписание событий, записывайте встречи и назначайте задачи, чтобы эффективно планировать свое рабочее время.</p>
							</Col>
						</div>
					</Col>
				</Row>
				<Row>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16vh 20vh 4vh", justifyContent: "space-between" }}>
						<H1 attrH1={{ className: "f-w-600 mb-4" }}>Тарифы</H1>
						<P attrPara={{ className: "f-20 mb-4" }}>В некоторые планы входит неограниченый доступ сотрудников, общих бордов и сессий</P>
						<Col md="8" className="mt-4">
							<StandardPricingcard data={item.packages} />
						</Col>
					</Col>
				</Row>
				<Form color={"#f3f3f3"} />
			</Container>
		</Fragment>
	);
};
export default OrderSystem;
