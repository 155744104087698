import React, { useMemo, useState } from "react";
import { Trash2, Edit, ExternalLink } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { toast } from "react-toastify";
import { Label, Input, CardBody, Col } from "reactstrap";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import FilterComponent from "../Catalog/FilterComponent";
import CommonModal from "../../UiKits/Modals/common/modal";

import { CmsApi } from "../../../api";
import { useKeycloak } from "@react-keycloak/web";
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";

const TableSitesList = ({ products, fetchData }) => {
	const [editModal, setEditModal] = useState(false);

	const [edit, setEdit] = useState(null);

	const [description, setDescription] = useState("");
	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [isPublish, setIsPublish] = useState(null);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const toggle = (siteId) => {
		console.log(siteId);
		if (typeof siteId === "object" && siteId.hasOwnProperty("id")) {
			setName(siteId.name);
			setId(siteId.id);
			setDescription(siteId.description);
			setIsPublish({ value: siteId.isPublish, label: siteId.isPublish ? "Да" : "Нет" });
			setEdit(true);
		} else {
			setName("");
			setId("");
			setDescription("");
			setIsPublish("");
			setEdit(false);
		}

		setEditModal(!editModal);
	};

	const columns = [
		{
			name: "№",
			selector: (row) => `${row.id}`,
			sortable: true,
			width: "15%",
		},
		{
			name: "Название",
			selector: (row) => <Link to={`/app/site-builder/${row.id}`}>{row.name}</Link>,
			sortable: true,
			width: "30%",
		},
		{
			name: "Описание",
			selector: (row) => `${row.description}`,
			sortable: true,
			width: "28%",
		},
		{
			name: "Опубликован?",
			selector: (row) => <div className="">{row.isPublish ? <i className="fa fa-check font-success f-right"></i> : <i className="fa fa-times font-danger f-right"></i>}</div>,
			sortable: false,
			width: "18%",
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (siteId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${CmsApi}deleteSite`, {
						data: {
							siteId: siteId,
						},
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
					})
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
						fetchData();
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении:", error);
					});
			}
		});
	};

	const handleEditCalendar = async () => {
		const newEvent = {
			id: id,
			name: name,
			description: description,
			isPublish: isPublish.value,
			theme: "",
			pages: [],
		};

		if (id === "") {

			newEvent.id = uuid();

			await axios
				.post(`${CmsApi}createSite`, newEvent, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then(async (response) => {
					toast.success("Сайт добавлен!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setEditModal(false);
					fetchData();

					try {
						await axios.post(`http://localhost:80/createSiteFolder/${newEvent.id}`);

					}
					catch (error) {

					}
				})
				.catch((error) => {
					toast.error("Ошибка при добавлении", {
						position: toast.POSITION.TOP_RIGHT,
					});
				});
		} else {
			await axios
				.put(`${CmsApi}updateSite`, newEvent, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					toast.success("Сайт обновлен!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setEditModal(false);
					fetchData();
				})
				.catch((error) => {
					setEditModal(false);
					toast.error("Ошибка при обновлении", {
						position: toast.POSITION.TOP_RIGHT,
					});
				});
		}
	};
	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = products.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				noDataComponent={"В данной таблице ещё нет записей."}
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={editModal} title={edit ? "Редактировать" : "Добавить"} toggler={handleEditCalendar} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-form-label">Опубликован?</Label>
						<Col sm="11">
							<Select
								placeholder="Выберите..."
								value={isPublish}
								options={[
									{ value: true, label: "Да" },
									{ value: false, label: "Нет" },
								]}
								className="js-example-basic-single col-sm-12"
								onChange={(selected) => setIsPublish(selected)}
							/>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3 mt-4">
						<Link to={`/app/site-builder/${id}`}>
							<ExternalLink size={"18px"} style={{ margin: "0 5px -3px 0px" }} />
							Перейти в конструктор сайтов
						</Link>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableSitesList;
