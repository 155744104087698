import React, { Fragment, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import CustomizerContext from "../../_helper/Customizer";
import MENUITEMS from "./Menu";
import { TwinAuthApi } from "../../api";
import { useCompanyContext } from "../../_helper/Company/CompanyProvider";
import { useFilterRealmRoles } from './RealmRolesFunc';


const SidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive, activeClass }) => {
	const { layout } = useContext(CustomizerContext);
	const layout1 = localStorage.getItem("sidebar_layout") || layout;

	const { keycloak, initialized } = useKeycloak();
	// Роли пользователя
	const [roles, setRoles] = useState([]); //decodedToken.realm_access?.roles || [];
	const [isDeveloper, setIsDeveloper] = useState(false);
	// если понадобится - функционал выбора компаний и подгрузки из локального хранилища расположен в файлах:
	// /Layout/Header/RightHeader/BookmarkHeader.jsx
	// /Layout/Header/RightHeader/CompanySelect.jsx
	// реализованный до этого скрипт в этом файле сломал логику подгрузки выбранных компаний после перезагрузки страницы
	const { companyData, userCompanies } = useCompanyContext();
	const filteredMenu = useFilterRealmRoles(MENUITEMS(keycloak.tokenParsed ? keycloak.tokenParsed?.sub : null));
	const [counterpartyHeader, setCounterpartyHeader] = useState('Контрагент')
	const [counterpartyText, setCounterpartyText] = useState('контрагент')
	const navigate = useNavigate();

	useEffect(() => {
		companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyHeader('Партнёр')
		companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyText('партнёр')
	}, [companyData]);

	const CurrentPath = window.location.pathname;
	const decodedToken = keycloak.tokenParsed;

	useEffect(() => {
		if (initialized && keycloak.authenticated) {
			setRoles(decodedToken.realm_access?.roles || []);
			roles && setIsDeveloper(roles.includes("Developer"));
		}
	}, [initialized, keycloak.tokenParsed]);

	const { t } = useTranslation();
	const toggletNavActive = (item) => {

		console.log("toggletNavActive-------------")
		console.log(item)

		if (window.innerWidth <= 991) {
			document.querySelector(".page-header").className = "page-header close_icon";
			document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon ";
			// document.querySelector('.mega-menu-container').classList.remove('d-block');
			if (item.type === "sub") {
				document.querySelector(".page-header").className = "page-header";
				document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper";
			}
		}
		if (!item.active) {
			MENUITEMS(keycloak.tokenParsed ? keycloak.tokenParsed?.sub : null).map((a) => {
				a.Items.filter((Items) => {
					if (a.Items.includes(item)) Items.active = false;
					if (!Items.children) return false;
					Items.children.forEach((b) => {
						if (Items.children.includes(item)) {
							b.active = false;
						}
						if (!b.children) return false;
						b.children.forEach((c) => {
							if (b.children.includes(item)) {
								c.active = false;
							}
						});
					});
					return Items;
				});
				return a;
			});
		}
		item.active = !item.active;
		setMainMenu({ mainmenu: MENUITEMS(keycloak.tokenParsed ? keycloak.tokenParsed?.sub : null) });
	};

	const { siteId } = useParams();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const page = queryParams.get("page");

	const [menu, setMenu] = useState(null);

	useEffect(() => {
		setMenuItems();
	}, [companyData, userCompanies])

	const setMenuItems = () => {
		if (page) {
			axios
				.get("/api/sitesList.json")
				.then((response) => {
					const foundItem = response.data.find((item) => item.id === siteId);
					const menuItems = foundItem.pages.map((item) => ({
						path: `${process.env.PUBLIC_URL}/app/pages/${siteId}?page=${item.alias}`,
						type: "link",
						title: item.name,
					}));
					const menuItem = [{ menutitle: "Приложения", menucontent: "Ready to use Apps", Items: menuItems }];
					setMenu(menuItem);
					setMainMenu(menuItem);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
		else {
			setMenu(filteredMenu);
		}
	}

	return (
		<>
			{menu &&
				menu.map((Item, i) => (
					<Fragment key={i}>
						{!(Item.role === "Developer" && !isDeveloper) && (
							<li className="sidebar-main-title">
								<div>
									<h6 className="lan-1">{t(Item.menutitle)}</h6>
								</div>
							</li>
						)}
						{Item.Items.map((menuItem, i) =>
							isDeveloper ||
								((menuItem.companyRole === undefined || (companyData && companyData.realmRoles.includes(menuItem.companyRole))) &&
									(menuItem.role === undefined || (roles && roles.includes(menuItem.role)))) ? (
								<li className="sidebar-list" key={i}>
									{menuItem.type === "sub" ? (
										<a
											href="javascript"
											className={`sidebar-link sidebar-title ${CurrentPath.includes(menuItem.title.toLowerCase()) ? "active" : ""} ${menuItem.active && "active"}`}
											onClick={(event) => {
												event.preventDefault();
												setNavActive(menuItem);
												activeClass(menuItem.active);
											}}
										>
											<SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`} />
											<SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`} />
											<span>{t(menuItem.title === "Контрагенты" ? `${counterpartyHeader}ы` : menuItem.title)}</span>
											{menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
											<div className="according-menu">{menuItem.active ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>}</div>
										</a>
									) : (
										""
									)}

									{menuItem.type === "link" ? (
										<Link
											to={menuItem.path}
											className={`sidebar-link sidebar-title link-nav  ${CurrentPath.includes(menuItem.title.toLowerCase()) ? "active" : ""}`}
											onClick={() => toggletNavActive(menuItem)}
										>
											<SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`} />
											<SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`} />
											<span>{t(menuItem.title)}</span>
											{menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
										</Link>
									) : (
										""
									)}

									{menuItem.type === "subLink" ? (
										<a
											href="javascript"
											className={`sidebar-link sidebar-title ${CurrentPath.includes(menuItem.title.toLowerCase()) ? "active" : ""} ${menuItem.active && "active"}`}
											onClick={(event) => {
												event.preventDefault();
												setNavActive(menuItem);
												activeClass(menuItem.active);
												navigate(menuItem.path);
											}}
										>
											<SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`}/>
											<SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`}/>
											<span>{t(menuItem.title === "Контрагенты" ? `${counterpartyHeader}ы` : menuItem.title)}</span>
											{menuItem.badge ?
												<label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
											<div className="according-menu">{menuItem.active ?
												<i className="fa fa-angle-down"></i> :
												<i className="fa fa-angle-right"></i>}</div>
										</a>
									) : (
										""
									)}

									{menuItem.children ? (
										<ul
											className="sidebar-submenu"
											style={
												layout1 !== "compact-sidebar compact-small"
													? menuItem?.active || CurrentPath.includes(menuItem?.title?.toLowerCase())
														? sidebartoogle
															? {opacity: 1, transition: "opacity 500ms ease-in"}
															: {display: "block"}
														: {display: "none"}
													: {display: "none"}
											}
										>
											{menuItem.children.map((childrenItem, index) => {
												if (
													isDeveloper ||
													((childrenItem.companyRole === undefined || (companyData && companyData.realmRoles.includes(childrenItem.companyRole))) &&
														(childrenItem.role === undefined || roles.includes(childrenItem.role)))
												) {
													return (
														<li key={index}>
															{childrenItem.type === "sub" ? (
																<a
																	href="javascript"
																	className={`${CurrentPath.includes(childrenItem?.title?.toLowerCase()) ? "active" : ""}`}
																	// className={`${childrenItem.active ? 'active' : ''}`}
																	onClick={(event) => {
																		event.preventDefault();
																		toggletNavActive(childrenItem);
																	}}
																>
																	{t(childrenItem.title)}
																	<span className="sub-arrow">
																		<i className="fa fa-chevron-right"></i>
																	</span>
																	<div className="according-menu">
																		{childrenItem.active ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>}
																	</div>
																</a>
															) : (
																""
															)}

															{childrenItem.type === "link" ? (
																<Link
																	to={childrenItem.path}
																	className={`${CurrentPath.includes(childrenItem?.title?.toLowerCase()) ? "active" : ""}`}
																	// className={`${childrenItem.active ? 'active' : ''}`} bonusui
																	onClick={() => toggletNavActive(childrenItem)}
																>
																	{t(childrenItem.title.toLowerCase().includes('контрагент') ? `${childrenItem.title.replace('контрагент', 'партнёр')}` : childrenItem.title)}
																</Link>
															) : (
																""
															)}

															{childrenItem.children ? (
																<ul
																	className="nav-sub-childmenu submenu-content"
																	style={CurrentPath.includes(childrenItem?.title?.toLowerCase()) || childrenItem.active ? { display: "block" } : { display: "none" }}
																>
																	{childrenItem.children.map((childrenSubItem, key) => (
																		<li key={key}>
																			{childrenSubItem.type === "link" ? (
																				<Link
																					to={childrenSubItem.path}
																					className={`${CurrentPath.includes(childrenSubItem?.title?.toLowerCase()) ? "active" : ""}`}
																					// className={`${childrenSubItem.active ? 'active' : ''}`}
																					onClick={() => toggletNavActive(childrenSubItem)}
																				>
																					{t(childrenSubItem.title)}
																				</Link>
																			) : (
																				""
																			)}
																		</li>
																	))}
																</ul>
															) : (
																""
															)}
														</li>
													);
												} else {
													return <></>;
												}
											})}
										</ul>
									) : (
										""
									)}
								</li>
							) : (
								""
							)
						)}
					</Fragment>
				))}
		</>
	);
};

export default SidebarMenuItems;
