import React, { Fragment, useState, useEffect } from "react";
import { CardBody, ButtonGroup, Input } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
import { Link, useLocation } from "react-router-dom";
import { Label, Col } from "reactstrap";
import Select from "react-select";
// import { Editor } from "react-draft-wysiwyg";
// import Ckeditor from "react-ckeditor-component";
import draftToHtml from "draftjs-to-html";
import SweetAlert from "sweetalert2";
import axios from "axios";
import uuid from "react-uuid";
import { Plus, ExternalLink } from "react-feather";
import { Btn, P } from "../../../../../AbstractElements";
import CommonModal from "../../../../UiKits/Modals/common/modal";
import { EducationApi, TestingApi } from "../../../../../api";
import { toast } from "react-toastify";

import ViewTesting from "../../../Testing/ViewTesting/TestComponent";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const Content = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const lessonId = queryParams.get("lessonId");

	const [elements, setElements] = useState([]);
	const [elementsCount, setElementsCount] = useState(0);
	const [filesCount, setFilesCount] = useState(0);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		getContent();
	}, []);

	const getContent = async () => {
		try {
			const response = await axios.get(`${EducationApi}get-lessons-content?lessonId=${lessonId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const content = concatResults(response).content.sort((a, b) => a.order - b.order);
			for (const item of content) {
				try {
					if (item.contentType === "video" || item.contentType === "img") {
						await axios
							.get(`${EducationApi}get-content-file/${item.file.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, responseType: "blob" })
							.then((responseFile) => {
								const content = new File([concatResults(responseFile)], item.file.fileName, { type: item.type });
								const fileInfo = {
									id: item.file.id,
									name: item.file.fileName,
									content: content,
									url: URL.createObjectURL(content),
								};
								console.log(fileInfo);
								addElement(item.contentType, fileInfo, item.id, item.order);
							})
							.catch((error) => {
								console.error("Ошибка получения файла:", error);
							});
					} else {
						if (item.contentType === "text") {
							addElement(item.contentType, item.content, item.id, item.order);
						} else {
							if (item.content.length !== 0) {
								addElement(item.contentType, JSON.parse(item.content), item.id, item.order);
							}
						}
					}
				} catch (innerError) {
					console.error("Внутренняя ошибка:", innerError);
				}
			}
		} catch (error) {
			console.error("Ошибка получения данных:", error);
		}
	};

	function addElement(type, content, id, order = elements.length) {
		if (!elements.some((element) => element.id === id)) {
			setElements((prevElements) => {
				const updatedElements = [...prevElements, { type, content, id, order }];
				console.log(updatedElements);
				return updatedElements;
			});

			setElementsCount((prevCount) => prevCount + 1);
		}
	}

	const sendCreateRequest = (type, content) => {
		let contentString = "";
		var idElemContent = uuid();
		if (type === "text") {
			contentString = content;
		} else if (type === "link" || type === "test") {
			contentString = JSON.stringify(content);
		}

		axios
			.post(
				`${EducationApi}create-lesson-content`,
				{
					id: idElemContent,
					lessonId: lessonId,
					contentType: type,
					content: contentString,
					order: elements.length,
				},
				{ headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } }
			)
			.then((response) => {
				if (type === "img" || type === "video" || type === "file") {
					let formData = new FormData();
					formData.append("file", content.file);
					formData.append("resultId", concatResults(response));
					formData.append("fileName", content.name);

					axios
						.post(`${EducationApi}create-content-file/${concatResults(response)}`, formData, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
						.then(() => {
							toast.success("Контент с файлом добавлен!", {
								position: toast.POSITION.TOP_RIGHT,
							});
						})
						.catch((error) => {
							toast.error("Произошла ошибка при загрузке файла.", {
								position: toast.POSITION.TOP_RIGHT,
							});
							console.error(error);
						});

					setElements([...elements, { id: idElemContent, type: type, content: content, order: elements.length }]);
				} else if (type === "text") {
					toast.success("Текст добавлен!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setElements([...elements, { id: idElemContent, type: "text", content: content, order: elements.length }]);
				} else if (type === "link") {
					toast.success("Ссылка добавлена!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setElements([...elements, { id: idElemContent, type: "link", content: JSON.parse(contentString), order: elements.length }]);
				} else if (type === "test") {
					toast.success("Тест добавлен!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setElements([...elements, { id: idElemContent, type: "test", content: JSON.parse(contentString), order: elements.length }]);
				}
			})
			.catch((error) => {
				toast.error("Произошла ошибка при добавлении новой записи.", {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error(error);
			});
	};

	const updateRequest = (updatedObject) => {
		let contentString = "";
		if (updatedObject.type !== "img" && updatedObject.type !== "video" && updatedObject.type !== "file") {
			contentString = JSON.stringify(updatedObject.content);
		}
		axios
			.put(
				`${EducationApi}update-lesson-content`,
				{
					id: updatedObject.id,
					lessonId: lessonId,
					contentType: updatedObject.type,
					content: updatedObject.content,
					order: updatedObject.order,
				},
				{ headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } }
			)
			.then((response) => {
				toast.success("Объект обновлен!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				toast.error("Произошла ошибка при обновлении объекта.", {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error(error);
			});
	};

	//Text Processing ------------
	const [modalText, setModalText] = useState(false);
	const toggleText = () => {
		setModalText(!modalText);
	};

	const [content, setContent] = useState("");

	const handleChange = (evt) => {
		setContent(evt.editor.getData());
	};

	// const onContentStateChange = (newContentState) => {
	// 	setContentState(newContentState);
	// 	console.log(JSON.stringify(contentState, null, 4));
	// };

	const addTextBlock = () => {
		sendCreateRequest("text", draftToHtml(content));
		setModalText(!modalText);
	};
	//-----------

	//Video Processing ------------
	const [modalVideo, setModalVideo] = useState(false);
	const toggleVideo = () => {
		setModalVideo(!modalVideo);
	};
	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileChangeVideo = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
	};

	const addVideoBlock = () => {
		if (selectedFile) {
			const uploadedFile = {
				id: selectedFile.name,
				name: selectedFile.name,
				file: selectedFile,
				url: URL.createObjectURL(selectedFile),
			};

			sendCreateRequest("video", uploadedFile);
			setModalVideo(false);
			setSelectedFile(null);
		}
	};
	// ----------

	//Image Processing ------------
	const allowedFileTypesImg = [".jpg", ".jpeg", ".png"];
	const validateFileType = (file) => {
		const fileType = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

		if (allowedFileTypesImg.includes("." + fileType.toLowerCase())) {
			return true;
		} else {
			alert("Недопустимый тип файла. Разрешены только файлы с расширением: " + allowedFileTypesImg.join(", "));
			return false;
		}
	};
	const [modalImg, setModalImg] = useState(false);
	const toggleImg = () => {
		setModalImg(!modalImg);
	};

	const handleImageFileChange = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
	};

	const addImageFileBlock = () => {
		if (selectedFile) {
			const uploadedFile = {
				id: selectedFile.name,
				name: selectedFile.name,
				file: selectedFile,
				url: URL.createObjectURL(selectedFile),
			};
			sendCreateRequest("img", uploadedFile);
			setModalImg(false);
			setSelectedFile(null);
		}
	};
	// ----------------

	//Link Processing ----------------
	const [modalLink, setModalLink] = useState(false);
	const toggleLink = () => {
		setModalLink(!modalLink);
	};
	const [inputText, setInputText] = useState("");
	const [link, setLink] = useState("");

	const addLinkBlock = () => {
		if (inputText === "" || link === "") {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			sendCreateRequest("link", { url: link, text: inputText });
			setModalLink(false);
		}
	};
	//----------------

	//Test Processing ----------------
	const [testForms, setTestForms] = useState([]);

	useEffect(() => {
		axios
			.get(`${TestingApi}getTestForms`, { params: { query: null }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = concatResults(response).testForms.map((option) => ({
					label: option.title,
					value: option.id,
				}));
				setTestForms(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);
	const [modalTest, setModalTest] = useState(false);
	const toggleTest = () => {
		setModalTest(!modalTest);
	};
	const [selectedTestForm, setSelectedTestForm] = useState(null);
	const addTestBlock = () => {
		sendCreateRequest("test", { id: selectedTestForm.value });
		setModalTest(false);
	};
	//----------------

	//File Processing --------------
	const [modalDoc, setModalDoc] = useState(false);

	const toggleDoc = () => {
		setModalDoc(!modalDoc);
	};
	const allowedFileTypesDoc = [".csv", ".html", ".xml", ".rtf", ".txt", ".odp", ".ods", ".odt", ".pdf", ".pptx", ".ppt", ".xlsx", ".xls", ".docx", ".doc"];
	function getFileExtension(fileName) {
		const parts = fileName.split(".");

		if (parts.length > 1) {
			return parts[parts.length - 1];
		} else {
			return "";
		}
	}
	const handleFileChangeDoc = (event) => {
		const files = event.target.files;
		const filesArray = Array.from(files);
		const uploadedFilesInfo = filesArray.map((file) => ({
			id: file.name,
			name: file.name,
			file,
			url: URL.createObjectURL(file),
		}));
		if (uploadedFilesInfo && validateFileTypeDoc(files[0])) {
			setElements([...elements, { type: "file", content: uploadedFilesInfo[0], order: elements.length }]);

			console.log(uploadedFilesInfo);
			console.log(filesArray);
			sendCreateRequest("file", event.target.files[0]);

			setModalDoc(false);
		}
	};
	const validateFileTypeDoc = (file) => {
		const fileType = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

		if (allowedFileTypesDoc.includes("." + fileType.toLowerCase())) {
			return true;
		} else {
			alert("Недопустимый тип файла. Разрешены только файлы с расширением: " + allowedFileTypesDoc.join(", "));
			return false;
		}
	};

	//

	//Options ----------------
	const handleMoveUp = (id) => {
		const index = elements.findIndex((element) => element.id === id);
		if (index > 0) {
			const updatedElements = [...elements];
			[updatedElements[index].order, updatedElements[index - 1].order] = [updatedElements[index - 1].order, updatedElements[index].order];
			updateRequest(elements[index]);
			updateRequest(elements[index - 1]);
			setElements(updatedElements);
		}
	};
	const handleMoveDown = (id) => {
		const index = elements.findIndex((element) => element.id === id);
		if (index < elements.length - 1) {
			const updatedElements = [...elements];
			[updatedElements[index].order, updatedElements[index + 1].order] = [updatedElements[index + 1].order, updatedElements[index].order];
			updateRequest(updatedElements[index]);
			updateRequest(updatedElements[index + 1]);
			setElements(updatedElements);
		}
	};
	const deleteContent = async (type, id) => {
		try {
			const result = await SweetAlert.fire({
				title: "Вы уверены?",
				text: "После удаления вы не сможете восстановить этот объект!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "ОК",
				cancelButtonText: "Отмена",
				reverseButtons: true,
			});

			if (result.value) {
				axios
					.delete(`${EducationApi}delete-lesson-content/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						console.log(response);
						const updatedElements = elements.filter((element) => element.id !== id);
						updatedElements.forEach((element, index) => {
							element.order = index;
						});
						setElements(updatedElements);
						toast.success("Объект удален!", {
							position: toast.POSITION.TOP_RIGHT,
						});
					})
					.catch((error) => {
						toast.error("Произошла ошибка при удалении записи.", {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error(error);
					});
			}
		} catch (error) {
			toast.error("Произошла ошибка при удалении записи.", {
				position: toast.POSITION.TOP_RIGHT,
			});
			console.error(error);
		}
	};
	//----------------

	return (
		<Fragment>
			<div className="btn-group-showcase mt-4">
				<Col xl="4" md="6" sm="12" className={`xl-50 `}>
					<ButtonGroup>
						<div style={{ width: "130px", display: "flex", alignItems: "center" }} className="m-r-15">
							<Plus size={"18px"} className="m-r-5" />
							Добавить блок:
						</div>
						<Btn attrBtn={{ outline: false, color: "light", onClick: toggleText }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<i className="fa fa-align-left  m-r-10"></i> Текст
							</div>
						</Btn>
						<CommonModal isOpen={modalText} title={"Добавить текст"} toggler={addTextBlock} togglerClose={toggleText} saveText="Готово" size="xl">
							<CardBody style={{ padding: "15px", paddingTop: "0" }}>
								{/* <Ckeditor
									activeclassName="p10"
									content={content}
									events={{
										change: handleChange,
									}}
								/> */}
							</CardBody>
						</CommonModal>
						<Btn attrBtn={{ outline: false, color: "light", onClick: toggleVideo }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<i className="fa fa-video-camera  m-r-10"></i> Видео
							</div>
						</Btn>
						<CommonModal isOpen={modalVideo} title={"Добавить видео"} toggler={addVideoBlock} togglerClose={toggleVideo} saveText="Готово" size="xs">
							<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
								<input type="file" onChange={handleFileChangeVideo} accept="video/mp4" />
							</CardBody>
						</CommonModal>
						<Btn attrBtn={{ outline: false, color: "light", onClick: toggleImg }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<i className="fa fa-image m-r-10"></i> Картинки
							</div>
						</Btn>
						<CommonModal isOpen={modalImg} title={"Добавить картинку"} toggler={addImageFileBlock} togglerClose={toggleImg} saveText="Готово" size="xs">
							<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
								<input type="file" onChange={handleImageFileChange} accept="image/*" />
							</CardBody>
						</CommonModal>
						<Btn attrBtn={{ outline: false, color: "light" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<i className="fa fa-file-text-o m-r-10"></i> Документы
							</div>
						</Btn>
						<CommonModal isOpen={modalDoc} title={"Добавить документ"} toggler={toggleDoc} togglerClose={toggleDoc} saveText="Готово" size="xs">
							<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
								<input type="file" onChange={handleFileChangeDoc} accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .odt, .ods, .odp, .txt, .rtf, .csv, .html, .xml" />
							</CardBody>
						</CommonModal>
						<Btn attrBtn={{ outline: false, color: "light", onClick: toggleLink }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<i className="fa fa-link  m-r-10"></i> Ссылки
							</div>
						</Btn>
						<CommonModal isOpen={modalLink} title={"Добавить ссылку"} toggler={addLinkBlock} togglerClose={toggleLink} saveText="Готово" size="xs">
							<CardBody style={{ paddingTop: "0", padding: "15px" }}>
								<Col className="row col-sm-12 mb-3">
									<Label className="col-sm-9 col-form-label">URL</Label>
									<Col sm="12">
										<Input className="form-control" type="tel" value={link} onChange={(e) => setLink(e.target.value)} />
									</Col>
								</Col>
								<Col className="row col-sm-12 mb-3">
									<Label className="col-sm-9 col-form-label">Текст ссылки</Label>
									<Col sm="12">
										<Input className="form-control" type="tel" value={inputText} onChange={(e) => setInputText(e.target.value)} />
									</Col>
								</Col>
							</CardBody>
						</CommonModal>
						<Btn attrBtn={{ outline: false, color: "light", onClick: toggleTest }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<i className="fa fa-check-square-o  m-r-10"></i> Тестирование
							</div>
						</Btn>
						<CommonModal isOpen={modalTest} title={"Добавить Тестирование"} toggler={addTestBlock} togglerClose={toggleTest} saveText="Готово" size="xs">
							<CardBody style={{ paddingTop: "0", padding: "15px" }}>
								<Col className="row col-sm-12 mb-3">
									<Select
										placeholder="Выберите..."
										value={selectedTestForm}
										options={testForms}
										className="js-example-basic-single col-sm-12"
										onChange={(selected) => setSelectedTestForm(selected)}
									/>
								</Col>
							</CardBody>
						</CommonModal>
					</ButtonGroup>
				</Col>
			</div>
			<Col md="12" className="p-20">
				{elements
					.sort((a, b) => a.order - b.order)
					.map((element) => (
						<div key={element.id} className="col-md-12" style={{ alignItems: "flex-end", display: "flex" }}>
							<div className="mb-3 mt-3" style={{ flexGrow: "1", textAlign: "center" }}>
								{element.type === "text" ? (
									<div dangerouslySetInnerHTML={{ __html: element.content }} />
								) : element.type === "img" ? (
									<img src={element.content.url} alt={element.content.name} style={{ maxWidth: "600px" }} />
								) : element.type === "video" ? (
									<video width="640" height="360" controls>
										<source src={element.content.url} type="video/mp4" />
										Ваш браузер не поддерживает видео в формате HTML5.
									</video>
								) : element.type === "file" ? (
									<Link to={element.content.url} target="_blank">
										{(getFileExtension(element.content.name) === "xls" || getFileExtension(element.content.name) === "xlsx") && <i className="fa fa-file-excel-o me-2"></i>}
										{getFileExtension(element.content.name) === "pdf" && <i className="fa fa-file-pdf-o me-2"></i>}
										{getFileExtension(element.content.name) === "txt" && <i className="fa fa-file-text-o me-2"></i>}
										{(getFileExtension(element.content.name) === "doc" || getFileExtension(element.content.name) === "docx") && <i className="fa fa-file-word-o me-2"></i>}
										{(getFileExtension(element.content.name) === "ppt" || getFileExtension(element.content.name) === "pptx") && <i className="fa fa-file-powerpoint-o me-2"></i>}

										{element.content.name}
									</Link>
								) : element.type === "link" ? (
									<Link to={element.content.url} target="_blank">
										<ExternalLink size={"18px"} style={{ margin: " 0 5px -4px 0" }} />
										{element.content.text}
									</Link>
								) : element.type === "test" ? (
									<ViewTesting idTestParam={element.content.id} />
								) : null}
							</div>
							<div className="f-right">
								{console.log("Current Element:", element)}
								<Btn attrBtn={{ onClick: () => handleMoveUp(element.id), disabled: element.order === 0, color: "primary", outline: true, className: "pe-2 ps-2 me-2" }}>
									<Tooltip title="Переместить наверх" arrow placement="top">
										<i className="fa fa-arrow-up"></i>
									</Tooltip>
								</Btn>
								<Btn
									attrBtn={{
										onClick: () => handleMoveDown(element.id),
										disabled: element.order === elements.length - 1,
										color: "primary",
										outline: true,
										className: "pe-2 ps-2 me-2",
									}}
								>
									<Tooltip title="Переместить вниз" arrow placement="top">
										<i className="fa fa-arrow-down"></i>
									</Tooltip>
								</Btn>
								<Btn attrBtn={{ onClick: () => deleteContent(element.type, element.id), color: "primary", outline: true, className: "pe-2 ps-2 me-2" }}>
									<Tooltip title="Удалить" arrow placement="top">
										<i className="fa fa-trash-o"></i>
									</Tooltip>
								</Btn>
							</div>
						</div>
					))}
			</Col>
		</Fragment>
	);
};
export default Content;
