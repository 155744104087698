import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, CardBody, Col, Card, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import ru from "date-fns/locale/ru";
import axios from "axios";

import { Breadcrumbs, Btn, H6 } from "../../../../../AbstractElements";
import TableOnServices from "./TableOnServices";

const OnServices = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);

	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());

	useEffect(() => {
		const dateEnd = queryParams.get("dateEnd");
		if (dateEnd) {
			setPeriodEnd(new Date(dateEnd));
		} else {
			setPeriodEnd(new Date());
		}

		const dateStart = queryParams.get("dateStart");
		if (dateStart) {
			setPeriodStart(new Date(dateStart));
		} else {
			setPeriodStart(new Date());
		}
	}, []);

	const handleDateStartChange = (selectedOption) => {
		const categoryId = selectedOption;
		setPeriodStart(selectedOption);
		queryParams.delete("dateStart");
		queryParams.append(`dateStart`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};
	const handleDateEndChange = (selectedOption) => {
		const categoryId = selectedOption;
		setPeriodEnd(selectedOption);
		queryParams.delete("dateEnd");
		queryParams.append(`dateEnd`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Отчет по услугам" parent="Отчёты" subParent="Продажи" title="Отчет по услугам" />
			<Container fluid className="user-card">
				<Col md="12">
					<Card>
						<CardBody>
							<Row className="m-l-5">
								<Col xl="4" className="m-r-45 m-l-35">
									<div style={{ display: "flex", alignItems: "flex-end" }}>
										<Label className="m-r-10 f-16">С</Label>
										<div className="customDatePickerWidth ">
											<DatePicker locale={ru} dateFormat="dd.MM.yyyy" className="form-control digits" selected={periodStart} onChange={handleDateStartChange} />
										</div>
									</div>
								</Col>
								<Col xl="4" className="m-r-45">
									<div style={{ display: "flex", alignItems: "flex-end" }}>
										<Label className="m-r-10 f-16">По</Label>
										<div className="customDatePickerWidth">
											<DatePicker locale={ru} dateFormat="dd.MM.yyyy" className="form-control digits" selected={periodEnd} onChange={handleDateEndChange} />
										</div>
									</div>
								</Col>
								<Col xl="2" className="">
									<Btn attrBtn={{ color: "primary", className: "f-right" }}>Показать</Btn>
								</Col>
							</Row>
						</CardBody>
					</Card>
					<Card>
						<CardBody>
							<TableOnServices />
						</CardBody>
					</Card>
				</Col>
			</Container>
		</Fragment>
	);
};
export default OnServices;
