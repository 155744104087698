import React, { useState, useRef, useCallback, useContext, useEffect } from "react";
import ReactFlow, { Controls, Background, Panel, useReactFlow, MiniMap, MarkerType } from "reactflow";

import { Btn } from "../../../../AbstractElements";
import { toast } from "react-toastify";

import { Save } from "react-feather";
import DnDContext from "../../../../_helper/DnD";
import Sidebar from "./Sidebar";

import CustomNodeDepartment from "./CustomNodeDepartment";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const nodeTypes = {
	department: CustomNodeDepartment,
};

const DnDFlow = ({ allDepartment, allUsersNames }) => {
	const reactFlowWrapper = useRef(null);
	const {
		selectUser,
		setSelectUser,
		selectDepartment,
		setSelectDepartment,
		description,
		setDescription,
		name,
		setName,
		departments,
		setDepartments,
		nodes,
		setNodes,
		onNodesChange,
		edges,
		setEdges,
		onEdgesChange,
		lastNodePosition,
		setLastNodePosition,
		getDepartments
	} = useContext(DnDContext);

	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [rfInstance, setRfInstance] = useState(null);
	const { setViewport } = useReactFlow();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		getDepartments();
	}, []);

	const onConnect = useCallback((params) => {
		const newEdge = {
			id: `${params.source}-${params.target}`,
			source: params.source,
			target: params.target,
			type: "smoothstep",
			markerEnd: {
				type: MarkerType.ArrowClosed,
				width: 20,
				height: 20,
			},
		};
		setEdges((eds) => [...eds, newEdge]);

		//добавить родительский отдел
		const updatedDepartments = departments.map((department) => {
			if (department.id === params.target) {
				const updDepartment = {
					...department,
					parent_department: params.source,
				};

				// axios
				// 	.post(`${CmsApi}createSite`, updDepartment, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				// 	.then((response) => {
				// 		toast.success("Сайт добавлен!", {
				// 			position: toast.POSITION.TOP_RIGHT,
				// 		});
				// 		setEditModal(false);
				// 	})
				// 	.catch((error) => {
				// 		toast.error("Ошибка при добавлении", {
				// 			position: toast.POSITION.TOP_RIGHT,
				// 		});
				// 	});

				return updDepartment;
			}
			return department;
		});
	}, []);

	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
		setLastNodePosition({ x: lastNodePosition.x, y: lastNodePosition.y - 150 });
	}, []);

	const onSave = useCallback(() => {
		if (rfInstance) {
			const flow = rfInstance.toObject();
			localStorage.setItem(name, JSON.stringify(flow));
			const newFlow = {
				companyId: companyData[0]?.id,
				flow: JSON.stringify(flow),
			};
			console.log(newFlow);
			//send
			// axios
			// 	.post(`${CmsApi}createSite`, newEvent, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 	.then((response) => {
			// 		toast.success("Сайт добавлен!", {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setEditModal(false);
			// 	})
			// 	.catch((error) => {
			// 		toast.error("Ошибка при добавлении", {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 	});
		}
	}, [rfInstance, name]);

	const onRestore = useCallback(() => {
		const restoreFlow = async () => {
			const flow = JSON.parse(localStorage.getItem(name));
			if (flow) {
				const { x = 0, y = 0, zoom = 1 } = flow.viewport;
				setNodes(flow.nodes || []);
				setEdges(flow.edges || []);
				setViewport({ x, y, zoom });
			}
		};
		restoreFlow();
	}, [setNodes, setViewport]);

	const handleReactFlowInit = (reactFlowInstance) => {
		setReactFlowInstance(reactFlowInstance);
		setRfInstance(reactFlowInstance);
	};

	return (
		<div className="dndflow">
			<div className="reactflow-wrapper" ref={reactFlowWrapper}>
				<ReactFlow
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChange}
					onEdgesChange={onEdgesChange}
					onConnect={onConnect}
					onDragOver={onDragOver}
					nodeTypes={nodeTypes}
					onInit={handleReactFlowInit}
					panOnScroll
					selectionOnDrag
				>
					<Panel position="top-right" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
						<Btn attrBtn={{ color: "info", onClick: onSave }}>
							<Save size={"16px"} style={{ marginRight: "5px" }} />
							Сохранить
						</Btn>
						<button onClick={onRestore} style={{ background: "none", border: "none", color: "#7366ff", fontSize: "16px", marginTop: "5px" }}>
							<i className="fa fa-undo" style={{ marginRight: "5px" }}></i>Вернуться к последнему сохранению
						</button>
					</Panel>
					<Panel position="top-left">
						<Sidebar
							setNodes={setNodes}
							setEdges={setEdges}
							lastNodePosition={lastNodePosition}
							setLastNodePosition={setLastNodePosition}
							description={description}
							setDescription={setDescription}
							name={name}
							setName={setName}
							allDepartment={allDepartment}
							allUsersNames={allUsersNames}
							selectUser={selectUser}
							setSelectUser={setSelectUser}
							selectDepartment={selectDepartment}
							setSelectDepartment={setSelectDepartment}
						/>
					</Panel>
					<Background />
					<MiniMap position="bottom-left" nodeColor={"#7366ff70"} />
					<Controls position="bottom-right" />
				</ReactFlow>
			</div>
		</div>
	);
};

export default DnDFlow;
