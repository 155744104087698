import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { CompaniesApi } from '../../../../api';

const StatusButton = ({ activated, id }) => {
	const [comActivated, setActivated] = useState(activated);

	const [loading, setLoading] = useState(false);

	const activate = () => {
		setLoading(true);
		axios
			.post(`${CompaniesApi}activate-company?companyId=${id}`)
			.then((response) => {
				setActivated(true);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};

	const reject = () => {
		setLoading(true);

		axios
			.post(`${CompaniesApi}deactivate-company?companyId=${id}`)
			.then((response) => {
				setActivated(false);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
			});
	};

	return (
		<Fragment>
			{loading ? (
				<div className="col-md-12" style={{ marginLeft: '5rem' }}>
					<div className="loader-box" style={{ height: '50px' }}>
						<div className="loader-15"></div>
					</div>
				</div>
			) : (
				<div>
					{comActivated === true ? (
						<span style={{ fontWeight: '600', color: '#54ba4a', marginLeft: '5rem' }}>
							Активировано <i className="icofont icofont-ui-check"></i>
						</span>
					) : comActivated === false ? (
						<span style={{ fontWeight: '600', color: '#fc4438', marginLeft: '5rem' }}>
							Отклонено <i className="icofont icofont-ui-block"></i>
						</span>
					) : (
						<div role="group" className="btn-group-pill btn-group">
							<button className="btn btn-outline-success btn" onClick={activate} disabled={loading || comActivated}>
								Активировать
							</button>
							<button className="btn btn-outline-danger btn" onClick={reject} disabled={loading || comActivated}>
								Отклонить
							</button>
						</div>
					)}
				</div>
			)}
		</Fragment>
	);
};
export default StatusButton;
