// LeftPanel.js
import { useState, useEffect } from 'react';
import React from 'react';
import { Col, Label } from 'reactstrap';
import axios from 'axios';

const ElementDeliveryInfo = ({ setIsOverLeftPanel, content = 'Параметры доставки:' }) => {
	return (
		<>
			<div
				className="col-sm-11 mb-3 "
				draggable="true"
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'deliveryInfo');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Label></Label>
				<Col sm="12" style={{ backgroundColor: '#ffaa051f' }} className="p-10 b-r-8">
					<Label className="font-warning">
						<div dangerouslySetInnerHTML={{ __html: content }} />
					</Label>
				</Col>
			</div>
		</>
	);
};

export default ElementDeliveryInfo;
