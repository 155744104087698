import React, { useState, useContext, useEffect } from "react";
import Context from "./index";
import axios from "axios";
import DemandsContext from '.';
import { useKeycloak } from "@react-keycloak/web";
import { DemandsApi } from "../../api";
import { useCompanyContext } from "../Company/CompanyProvider";
import { concatResults } from "../../concatResults";
import uuid from 'react-uuid';

const DemandsProvider = ({ children, props }) => {
	const context = useCreateDemandsContext(props);

	return (
		<DemandsContext.Provider
			value={context}
		>
			{children}
		</DemandsContext.Provider>
	);
};

export function useDemandsContext() {
	const context = useContext(DemandsContext);
	if (!context) throw new Error('Use app context within provider!');
	return context;
}

const useCreateDemandsContext = function (props) {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const companyId = companyData.map((item) => (item.attributes.companyId[0]));//"53819cb9-eaf4-48e1-b039-e37b6836d2b3";

	const [demands, setDemands] = useState([]);
	const [demandsOptions, setDemandsOptions] = useState([]);

	const [demandTypes, setDemandTypes] = useState([]);
	const [demandTypesOptions, setDemandTypesOptions] = useState([]);

	const [demandForms, setDemandForms] = useState([]);
	const [demandForm, setDemandForm] = useState({
		id: null,
		name: '',
		withDelivery: false,
		createLead: false,
		createDeal: false,
		createDealBitrix: false,
		leadProjectDefault: null,
		dealProjectDefault: null,
		dealBitrixCategory: null,
		dealBitrixCategoryStage: null,
		menuItems: [],
		parameters: []
	});
	const [demandFormsOptions, setDemandFormsOptions] = useState([]);

	const getDemands = async (formId) => {
		axios
			.get(`${DemandsApi}getDemands`, {
				params: { query: formId },//"b77e0ad8-9e26-4c45-bb96-f299a26263b6" },
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				const selectOptions = response.data.demands.map((option) => ({
					label: option.id,
					value: option.id,
				}));
				setDemands(response.data.demands);
				setDemandsOptions(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}


	const getDemandTypes = async () => {
		await axios
			.get(`${DemandsApi}getDemandTypes`, { params: { query: '' }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
			.then((response) => {
				const responseData = response.data.demandTypes;
				setDemandTypes(responseData);
				const selectOptions = responseData.length > 0 ? responseData.map((option) => ({
					label: option.title,
					value: option.id,
				})) : [];
				setDemandTypesOptions(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}

	const getDemandForms = async () => {
		await axios
			.get(`${DemandsApi}getDemandForms`, {
				params: {
					query: '',
					CompanyId: companyId
				},
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
			})
			.then((response) => {
				if (response.data.demandForms) {
					const responseData = response.data.demandForms;
					setDemandForms(responseData);
					const selectOptions = responseData.length > 0 ? responseData.map((option) => ({
						label: option.name,
						value: option.id,
					})) : [];
					setDemandFormsOptions(selectOptions);
				}
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}


	const createDemandForm = async (form) => {
		form.id = uuid();
		await axios
			.post(`${DemandsApi}createDemandForm`, form, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const updateDemandForm = async (form) => {
		await axios
			.put(`${DemandsApi}updateDemandForm`, form, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.error(error);
			});
	};


	const deleteDemandForm = async (id) => {
		try {
			await axios.delete(`${DemandsApi}deleteDemandForm`, {
				params: { id: id },
				headers: {
					Authorization: `Bearer ${keycloak.token}`,
					CompanyId: companyId
				}
			});
		} catch (error) {
			console.error(error);
		}
	};


	return {
		demands,
		demandsOptions,
		demandTypes,
		demandTypesOptions,
		demandForms,
		demandForm,
		demandFormsOptions,


		getDemands,
		getDemandTypes,
		getDemandForms,

		createDemandForm,

		updateDemandForm,

		deleteDemandForm,

		setDemandForm
	};
};

export default DemandsProvider;
