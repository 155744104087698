import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col } from "reactstrap";
import SweetAlert from "sweetalert2";
import { Trash2 } from "react-feather";
import axios from "axios";
import { CmsApi } from "../../../../api";

const MenuCard = ({ menuItems, setMenuItems }) => {
	const onDragEnd = (result) => {
		if (!result.destination) return;

		const updatedItems = [...menuItems];
		const [reorderedItem] = updatedItems.splice(result.source.index, 1);
		updatedItems.splice(result.destination.index, 0, reorderedItem);

		setMenuItems(updatedItems);
	};

	const deleteLink = (linkId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${CmsApi}deleteMenuLink`, {
						data: {
							LinkId: linkId,
						},
					})
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении:", error);
					});
			}
		});
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="menu">
				{(provided) => (
					<ul {...provided.droppableProps} ref={provided.innerRef}>
						{menuItems.map((item, index) => (
							<Draggable key={item.id} draggableId={item.id} index={index}>
								{(provided) => (
									<Col sm="7">
										<div style={{ padding: "20px 30px 20px 0" }}>
											<li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
												<div style={{ display: "flex" }}>
													<div style={{ width: "400px" }}>
														<span className="font-primary f-w-600">Название:</span> {item.displayName}
													</div>
													<div style={{ width: "300px" }}>
														<span className="font-primary f-w-600">Ссылка:</span>/{item.link}
													</div>
													<div className="font-primary" onClick={() => deleteLink(item.id)}>
														<Trash2 size={"18px"} style={{ marginLeft: "8px", cursor: "pointer" }} />
													</div>
												</div>
											</li>
										</div>
									</Col>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</ul>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default MenuCard;
