import React, { Fragment, useState, useEffect } from 'react';
import {Label, Row, Col, Input, InputGroup, InputGroupText, CardBody} from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import Tooltip from '@mui/material/Tooltip';
import {Edit, PhoneCall, Trash2} from 'react-feather';
import {toast} from "react-toastify";
import { Btn, Image } from '../../../../AbstractElements';
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {useCounterpartiesContext} from "../../../../_helper/Counterparties/CounterpartiesProvider";
import CommonModal from "../../../UiKits/Modals/common/modal";
import DataTable from "react-data-table-component";
import {CounterpartiesApi} from "../../../../api";
import uuid from "react-uuid";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Textarea} from "../../../../Constant";
import PhoneInput from "react-phone-number-input";

const CounterpartyCardContent = () => {
    const {keycloak} = useKeycloak();
    const {companyData} = useCompanyContext();
    const { getCounterparty, counterpartyDetails, counterpartyRequiredFields, updateCounterparty, createCounterparty, getCounterpartyRoles, roles, counterpartySettings, getCounterpartySettings } = useCounterpartiesContext();
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [modal, setModal] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const counterpartyId = queryParams.get("counterpartyId");

    const [modalContacts, setModalContacts] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [inn, setInn] = useState('');
    const [upp, setUpp] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [comment, setComment] = useState('');
    const [contractConditions, setContractConditions] = useState('');
    const [selectType, setSelectType] = useState(null);
    const [rolesSelect, setRolesSelect] = useState([]);
    const [selectedRole, setSelectedRole] = useState();

    useEffect(() => {
        getCounterparty(counterpartyId);
        getCounterpartyRoles();
        getCounterpartySettings();
        //companyData && keycloak && getCardSettings();
    }, [companyData && keycloak]);

    useEffect(() => {
        roles && setRolesSelect(roles?.map(role => ({
            value: role.id,
            label: role.name
        })));
    }, [roles])

    const toggleContactPersons = () => {
        setModalContacts(!modalContacts);
    }

    useEffect(() => {
        if (counterpartyDetails) {
            setName(counterpartyDetails.name);
            setInn(counterpartyDetails.inn);
            setUpp(counterpartyDetails.upp);
            setPhone(counterpartyDetails.phone);
            setEmail(counterpartyDetails.email);
            setContactPerson(counterpartyDetails.contactPerson);
            setAddress(counterpartyDetails?.address);
            setComment(counterpartyDetails?.comment);
            setContractConditions(counterpartyDetails?.contractConditions ?? "");
            setSelectType(counterpartyDetails?.typeId);
        }
    }, [counterpartyDetails])

    useEffect(() => {
        selectType && rolesSelect && setSelectedRole(rolesSelect && selectType ? rolesSelect?.find((item) => item.value === selectType) : null);

        }, [rolesSelect, selectType])

    const send = () => {
        if ((name === "" && counterpartyRequiredFields.includes('name')) ||
            (inn === "" && counterpartyRequiredFields.includes('inn')) ||
            (upp === "" && counterpartyRequiredFields.includes('upp')) ||
            (contactPerson === "" && counterpartyRequiredFields.includes('contactPerson')) ||
            (phone === "" && counterpartyRequiredFields.includes('phone')) ||
            (email === "" && counterpartyRequiredFields.includes('email')) ||
            (address === "" && counterpartyRequiredFields.includes('address')) ||
            (comment === "" && counterpartyRequiredFields.includes('comment')))
        {
            toast.error("Заполнены не все обязательные поля!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        else {
            if (counterpartyDetails) {
                updateCounterparty(counterpartyDetails.id, selectType, name, inn, upp, contactPerson, phone, email, address, comment, contractConditions);
            }
            else {
                createCounterparty(uuid(), selectType, name, inn, upp, contactPerson, phone, email, address, comment, contractConditions);
            }
        }
    };

    const columns = [
        {
            name: "ФИО контактного лица",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.email ?? "",
            sortable: true,
        },
        {
            name: "Телефон",
            selector: row => row.phone ?? "",
            sortable: true,
        },
        {
            name: "",
            button: true,
            cell: row => (
                <div style={{ display: "flex" }}>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => console.log('click')}>
                            <Trash2 size={"18px"} />
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const testData = [{
        name: "Иванов Иван Иванович",
        phone: "+7777777777",
        email: "ivanov@example.com",
    }]

    return (
        <Fragment>
            <CommonModal isOpen={modalContacts} title={"Список контактных лиц"} toggler={toggleContactPersons} togglerClose={toggleContactPersons} size="lg">
                <Btn attrBtn={{ style: { marginLeft: "15px" }, color: 'primary', onClick: () => console.log('click') }}>Добавить контактное лицо</Btn>
                <CardBody style={{ padding: "10px", paddingLeft: "15px" }}>
                    <DataTable
                        columns={columns}
                        data={testData ?? []}
                        defaultSortField="name"
                        noDataComponent={"В данной таблице ещё нет записей."}
                        striped
                        pagination
                        paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
                    />
                </CardBody>
            </CommonModal>
            {/*<CommonModal isOpen={modal} title={edit ? "Редактировать роль" : "Добавить роль"} toggler={handleSave} togglerClose={toggleModal} closeText="Отмена" saveText="Сохранить" size="lg">
                <CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Наименование роли</Label>
                        <Col sm="11">
                            <Input className="form-control" placeholder="Наименование роли" value={currentRole.name} onChange={(e) => setCurrentRole({ ...currentRole, name: e.target.value })} />
                        </Col>
                    </Col>
                </CardBody>
            </CommonModal>*/}
            <Row className="mb-2">
                <Col md="8 mb-2">
                    <Col className="mb-2">
                        <Label className="form-label" for="validationLastName">
                            Название
                        </Label>
                        <input className="form-control" id="validationLastName" type="text" value={name} name="fName" onChange={(e) => setName(e.target.value)} />
                    </Col>
                    <Col className=" mb-2">
                        <Label className="form-label">Тип</Label>
                        <Col>
                            <Select
                                type="tel"
                                placeholder="Выберите..."
                                value={rolesSelect && selectType ? rolesSelect?.find((item) => item.value === selectType) : null}
                                options={rolesSelect}
                                onChange={(option) => setSelectType(option)}
                            />
                            <div className="mt-2">
                                <Link to={`${process.env.PUBLIC_URL}/counterparties/counterparties-roles`}>
                                    <div className="ms-3">
                                        Добавить тип
                                    </div>
                                </Link>
                            </div>
                        </Col>
                    </Col>
                    <Col className="mb-2">

                        <Label className="form-label" for="validationLastName">
                            Телефон
                        </Label>
                        <PhoneInput
                            international
                            value={phone}
                            onChange={setPhone}
                            numberInputProps={{className: "form-control form-control"}}
                        />
                    </Col>
                    <Col className="mb-2">
                        <Label className="form-label" for="validationLastName">
                            Email
                        </Label>
                        <input className="form-control" id="validationLastName" type="text" value={email} name="fName" onChange={(e) => setEmail(e.target.value)} />
                    </Col>
                    <Col className="mb-2">
                        <Label className="form-label" for="validationLastName">
                            Адрес
                        </Label>
                        <input className="form-control" id="validationLastName" type="text" value={address} name="fName" onChange={(e) => setAddress(e.target.value)} />
                    </Col>
                    {selectedRole &&
                        (selectedRole?.label === "Отель" ||
                            selectedRole?.label === "DMC" ||
                            selectedRole?.label === "Поставщик") &&
                        <Col className="mb-2">
                            <Label className="form-label">Условия контракта/бронирования</Label>
                            <Col>
                                <Textarea
                                    className="form-control"
                                    value={contractConditions}
                                    onChange={(e) => setContractConditions(e.target.value)}
                                >
                                    {contractConditions}
                                </Textarea>
                            </Col>
                        </Col>
                    }
                    <Col className="mb-2">
                        <Label className="form-label" for="validationLastName">
                            Комментарий
                        </Label>
                        <Textarea
                            className="form-control"
                            id="validationLastName"
                            type="text"
                            value={comment}
                            name="fName"
                            onChange={(e) => setComment(e.target.value)}
                        >
                            {comment}
                        </Textarea>
                    </Col>
                    {/*<p>
                        Реквизиты
                    </p>*/}
                    { counterpartySettings && !counterpartySettings?.allowHaveManyContacts &&
                    <Col className="mb-2">
                        <Label className="form-label" for="validationLastName">
                            Основное контактное лицо
                        </Label>
                        <input className="form-control" id="validationLastName" type="text" value={contactPerson} name="fName" onChange={(e) => setContactPerson(e.target.value)} />
                    </Col>
                    }
                    {/*<Row>
                        <Col sm="12" md="6" className="mb-2">
                        <Label className="form-label" for="validationLastName">
                            ИНН
                        </Label>
                        <input className="form-control" id="validationLastName" type="text" value={inn} name="fName" onChange={(e) => setInn(e.target.value)} />
                    </Col>
                    <Col sm="12" md="6" className="mb-2">
                        <Label className="form-label" for="validationLastName">
                            КПП
                        </Label>
                        <input className="form-control" id="validationLastName" type="text" value={upp} name="fName" onChange={(e) => setUpp(e.target.value)} />
                    </Col>
                    </Row>*/}

                </Col>
            </Row>
            <div className="modal-footer" style={{ paddingTop: '40px' }}>
                <Btn attrBtn={{ color: 'primary', onClick: () => send() }}>Сохранить изменения</Btn>
            </div>
        </Fragment>
    );
};
export default CounterpartyCardContent;
