import React, { useContext, useState, useEffect } from "react";
import { Grid } from "react-feather";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { Image } from "../../AbstractElements";
import Icon from "../../assets/images/logo/ERP_logo.png";
import CustomizerContext from "../../_helper/Customizer";
import {useCompanyContext} from "../../_helper/Company/CompanyProvider";

const SidebarLogo = ({headerHeight}) => {
	const { mixLayout, toggleSidebar, toggleIcon, layout, layoutURL } = useContext(CustomizerContext);
	const { companyData, getCompanyLogo, companyLogo } = useCompanyContext();
	const { keycloak } = useKeycloak();

	const openCloseSidebar = () => {
		toggleSidebar(!toggleIcon);
	};

	const layout1 = localStorage.getItem("sidebar_layout") || layout;

	const [uploadedLogo, setUploadedLogo] = useState(null);

	useEffect(() => {
		companyData?.id && getCompanyLogo(companyData[0]?.id);
		/*axios
			.get(`/api/get-logo.json`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setUploadedLogo(response.data.logo.img);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});*/
	}, [companyData]);

	return (
		<div className="logo-wrapper" style={{minHeight: `${headerHeight}px`}}>
			<Link to={`${process.env.PUBLIC_URL}/app/users/profile`} style={{ maxWidth: "120px" }}>
				<Image attrImage={{ className: "img-fluid d-inline", src: `${ companyLogo ? companyLogo.image : Icon }`, alt: "" }} />
			</Link>

			{/* {layout1 !== "compact-wrapper dark-sidebar" && layout1 !== "compact-wrapper color-sidebar" && mixLayout ? (
				<Link to={`${process.env.PUBLIC_URL}/app/users/profile`}>
					<Image attrImage={{ className: "img-fluid d-inline", src: `${CubaIcon}`, alt: "" }} />
				</Link>
			) : (
				<Link to={`${process.env.PUBLIC_URL}/app/users/profile`}>
					<Image attrImage={{ className: "img-fluid d-inline", src: `${require("../../assets/images/logo/ERP_logo.png")}`, alt: "" }} />
				</Link>
			)} */}
			<div className="back-btn" onClick={() => openCloseSidebar()}>
				<i className="fa fa-angle-left"></i>
			</div>
			<div className="toggle-sidebar" onClick={openCloseSidebar}>
				<Grid className="status_toggle middle sidebar-toggle" />
			</div>
		</div>
	);
};

export default SidebarLogo;
