import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane, Form } from "reactstrap";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";
import { Breadcrumbs, H5 } from "../../../../AbstractElements";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import TableDocuments from "./TableDocuments";
import {useDocumentManagementContext} from "../../../../_helper/DocumentManagement/DocumentManagementProvider";

const Documents = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { documentTypes, getDocumentTypes } = useDocumentManagementContext();

	const [primarycolorTab, setprimarycolorTab] = useState("1");

	const [types, setTypes] = useState([]);

	const getData = async () => {
		try {
			/*await axios
				.get(`/api/types-documents.json`)
				.then((response) => {
					setTypes(response.data);
					setprimarycolorTab(response.data[0].id);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});*/
		} catch (error) {}
	};

	useEffect(() => {
		getDocumentTypes();
	}, [keycloak.tokenParsed]);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Документы" parent="Документоборот" title="Документы" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Row>
									<Col sm="3" xs="12">
										<Nav className="nav flex-column nav-pills">
											{documentTypes &&
												documentTypes.map((item, i) => (
													<NavItem key={i}>
														<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === item.id ? "active" : ""} onClick={() => setprimarycolorTab(item.id)}>
															{item.label}
														</NavLink>
													</NavItem>
												))}
										</Nav>
									</Col>
									<Col sm="9" xs="12">
										<TabContent activeTab={primarycolorTab}>
											{documentTypes &&
												documentTypes.map((item, i) => (
													<TabPane tabId={item.id} key={i}>
														<H5 attrH5={{ className: "mb-4" }}>{item.label}</H5>
														<TableDocuments documentId={item.id} />
													</TabPane>
												))}
										</TabContent>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default Documents;
