import React from "react";
import {ru} from "date-fns/locale";
import {Col, Input, Label} from "reactstrap";

export const InputCheckbox = {
    label: "Отметить галочкой",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
    },
    defaultProps: {
        children: "Отметить галочкой",
    },
    render: ({ children }) => {
        return <div>
            <Col sm="12">
                <div className="checkbox checkbox-primary">
                    <Input id="checkbox-primary-1" type="checkbox" />
                    <Label for="checkbox-primary-1">{children}</Label>
                </div>
            </Col>
        </div>;
    },
};