import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import axios from 'axios';
import { Breadcrumbs } from '../../../../AbstractElements';

const CalculationPerPeriod = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get('/api/counterparties.json')
			.then((response) => {
				setFilteredProducts(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Расчет за период" parent="Расчёт зарплат" title="Расчет за период" />
			<Container fluid={true}>
				<Card>
					<CardBody></CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default CalculationPerPeriod;
