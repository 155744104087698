import React from "react";
import {ru} from "date-fns/locale";
import {Col, Label} from "reactstrap";
import DatePicker from "react-datepicker";

export const InputDate = {
    label: "Выбор даты",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
    },
    defaultProps: {
        children: "Выбор даты",
    },
    render: ({ children }) => {
        return <div>
            <Label className="col-sm-9 col-form-label">{children}</Label>
            <Col sm="12">
                <div className="input-group">
                    <DatePicker locale={ru} className="form-control digits" selected={new Date()} dateFormat="dd.MM.yyyy" />
                </div>
            </Col>
        </div>;
    },
};