import React, {Fragment} from "react";
import Carousel from "react-bootstrap/Carousel";
import CharacteristicTypeSelect from "./CharacteristicsSelect/CharacteristicTypeSelect";
import ObjectTypeCharacteristicSelect from "./CharacteristicsSelect/ObjectTypeCharacteristicSelect";

export const ImageGallery = {
    label: "Галерея",
    fields: {
        images: {
            label: "Список картинок",
            type: "array",
            getItemSummary: (item) => item.label || "ImageGallery",
            arrayFields: {
                header: { type: "text", label: "Заголовок слайда" },
                label: { type: "text", label: "Текст слайда" },
                image:
                    {
                        type: "custom",
                        label: "Картинка",
                        render: ({ name, onChange }) => {
                            const readUrl = (event) => {
                                if (event.target.files.length === 0) return;
                                let mimeType = event.target.files[0].type;

                                if (mimeType.match(/image\/*/) == null) {
                                    return;
                                }
                                let reader = new FileReader();

                                reader.readAsDataURL(event.target.files[0]);
                                reader.onload = (_event) => {
                                    onChange(reader.result);
                                };
                            };

                            return <>
                                <label htmlFor="formFile" className="form-label">Выберите картинку</label>
                                <input name={name} className="form-control" type="file" id="formFile" onChange={(e) => readUrl(e)}/>
                            </>
                        }
                    }
            },
            defaultItemProps: {
                label: "Картинка",
                header: "Заголовок"
            },
        },
        ImapsData: {
            type: "object",
            label: "Характеристики",
            objectFields: {
                Characteristic: {
                    label: "Связать свойство с",
                    type: "object",
                    objectFields: {
                        typeField: {
                            type: "custom",
                            render: ({ onChange, value }) => {

                                return <>
                                    <CharacteristicTypeSelect onChange={(e) => onChange(e.value)} value={value} />
                                </>
                            }
                        },
                        id: {
                            type: "custom",
                            render: ({ onChange, value }) => {

                                return <>
                                    <ObjectTypeCharacteristicSelect onChange={(e) => onChange(e.value)} value={value}/>
                                </>
                            }
                        },
                    }
                }
            }
        }
    },
    defaultProps: {
        images: [{ label: "Текст", header: "Заголовок", image: "" }],
    },
    render: ({ align, images, puck }) => {
        return (
            <Fragment>
                <Carousel interval={15000} style={{height: "400px"}}>
                    {images.map((image, i) => (
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={image.image}
                                alt=""
                                style={{objectFit: "contain", height: "400px"}}
                            />
                            <Carousel.Caption>
                                <h5>{image.header}</h5>
                                <p>{image.label}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Fragment>
        );
    },
}