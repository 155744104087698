import React, { useMemo, useState, useEffect } from "react";
import { Edit, Trash2 } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { Label, Input, CardBody, Col, Media, Collapse } from "reactstrap";

import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { useKeycloak } from "@react-keycloak/web";
import TableModules from "./TableModules";
import { ModulesApi } from "../../../../api";
import { Btn, H5 } from "../../../../AbstractElements";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TablePluginTemplates = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [filteredProducts, setFilteredProducts] = useState([]);
	const [availableModules, setAvailableModules] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/plugin-templates.json`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setFilteredProducts(response.data.templates);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		axios
			.get(`${ModulesApi}get-modules`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = response.data.modules.map((option) => ({
					label: option.name,
					value: option.id,
					description: option.description,
					price: option.price,
					keycloakRoleName: option.keycloakRoleName,
					isActive: option.isActive,
				}));
				setAvailableModules(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const columns = [
		{
			name: "Название",
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: "Описание",
			selector: (row) => `${row.description}`,
			sortable: true,
		},
		{
			name: "Активность",
			selector: (row) => (
				<Media>
					<Media body className="text-end icon-state">
						<Label className="switch">
							<Input type="checkbox" id={`checkbox-${row.id}`} checked={row.isActive} onChange={() => updActivity(row)} />
							<span className="switch-state"></span>
						</Label>
					</Media>
				</Media>
			),
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteRow(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	// put для обновления активности
	const updActivity = (item) => {
		const updUser = {
			...item,
			isActive: !item.isActive,
		};
		// axios
		// 	.put(`/api/clientsDemandsForm.json`, updUser)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success("Доступ добавлен!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		const rowIndex = filteredProducts.findIndex((row) => row.id === item.id);
		const newData = [...filteredProducts];
		newData[rowIndex] = {
			...newData[rowIndex],
			isActive: !newData[rowIndex].isActive,
		};
		setFilteredProducts(newData);
		// 	})
		// 	.catch((error) => {
		// 		toast.error("Ошибка при добавлении доступа", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		console.error(error);
		// 	});
	};

	const [modal, setModal] = useState(false);

	const toggle = (item) => {
		if (typeof item === "object" && item.hasOwnProperty("id")) {
			setId(item.id);
			setName(item.name);
			setDescription(item.description);
			setisActive(item.isActive);
			setModules(item.modules);
			setEdit(true);
		} else {
			setId("");
			setName("");
			setDescription("");
			setisActive(true);
			setModules([]);
			setEdit(false);
		}
		setModal(!modal);
	};
	const [edit, setEdit] = useState(null);

	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [description, setDescription] = useState("");
	const [modules, setModules] = useState([]);
	const [isActive, setisActive] = useState(true);

	// put для обновления информации о шаблонах
	const editModule = () => {
		const updmodule = {
			id: id,
			name: name,
			description: description,
			isActive: isActive,
			modules: modules,
		};
		// axios
		// 	.put(`${NewsApi}updateNews`, updmodule, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 	.then((response) => {
		// 		console.log(concatResults(response));
		// 		toast.success("Новость изменена!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error("Ошибка получения данных:", error);
		// 	});
	};

	// post для добавления информации о шаблонах
	const createModule = () => {
		const newmodule = {
			id: id,
			name: name,
			description: description,
			isActive: isActive,
			modules: modules,
		};
		// axios
		// 	.post(`${NewsApi}updateNews`, updmodule, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 	.then((response) => {
		// 		console.log(concatResults(response));
		// 		toast.success("Новость изменена!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error("Ошибка получения данных:", error);
		// 	});
	};

	// delete для удаления информации о шаблонах
	const deleteRow = (idNews) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				// axios
				// 	.delete(`${NewsApi}deleteNews`, {
				// 		data: {
				// 			Id: idNews,
				// 		},
				// 		headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				// 	})
				// 	.then((response) => {
				// 		SweetAlert.fire("Удалено!", "Объект был удален.", "success");
				// 	})
				// 	.catch((error) => {
				// 		console.error("Произошла ошибка при удалении:", error);
				// 	});
			}
		});
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const [showModuleInfo, setShowModuleInfo] = useState(false);

	const addNewModule = () => {
		setShowModuleInfo(!showModuleInfo);
	};

	const [selectModule, setSelectModule] = useState(null);

	// добавить новый модуль в шаблон
	const addModule = () => {
		const newModule = {
			id: selectModule.value,
			name: selectModule.label,
			description: selectModule.description,
			price: selectModule.price,
			keycloakRoleName: selectModule.keycloakRoleName,
			isActive: selectModule.isActive,
		};
		// axios
		// 	.put(`${NewsApi}updateNews`, newUser, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 	.then((response) => {
		// 		console.log(concatResults(response));
		// 		toast.success('Новость изменена!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error('Ошибка получения данных:', error);
		// 	});
	};

	// добавить модуль из шаблона
	const deleteModule = (id) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				// axios
				// 	.delete(`${NewsApi}deleteNews`, {
				// 		data: {
				// 			Id: idNews,
				// 		},
				// 		headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				// 	})
				// 	.then((response) => {
				// 		SweetAlert.fire("Удалено!", "Объект был удален.", "success");
				// 	})
				// 	.catch((error) => {
				// 		console.error("Произошла ошибка при удалении:", error);
				// 	});
			}
		});
	};

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				striped
				pagination
				noDataComponent={"В данной таблице ещё нет записей."}
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={modal} title={edit ? "Редактировать" : "Добавить"} toggler={edit ? editModule : createModule} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "0 30px 10px" }}>
					<Col className=" col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="12">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className=" col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="12">
							<Input className="form-control" type="tel" value={description} onChange={(e) => setDescription(e.target.value)} />
						</Col>
					</Col>

					<Col className=" col-sm-12 mb-4 mt-4">
						<Media>
							<Media body className={"text-start icon-state "} style={{ flexGrow: "0" }}>
								<Label className="switch">
									<Input type="checkbox" checked={isActive} onChange={(e) => setisActive(e.target.checked)} />
									<span className="switch-state"></span>
								</Label>
							</Media>
							<Label className="col-form-label ms-3">Активность</Label>
						</Media>
					</Col>
					<Col className="row mt-4">
						<h5 className="mt-4 mb-0">Модули</h5>
					</Col>
					<hr />
					<Col className="row mt-4">
						<Col
							className=" f-14 p-t-15 p-b-15 b-r-8 b-b-light mb-4 d-flex ps-4"
							onClick={addNewModule}
							style={{ cursor: "pointer", alignItems: "center", backgroundColor: "#7366ff08", margin: "0.25rem 0 0" }}
						>
							{showModuleInfo ? <i className="fa fa-angle-down me-2 f-16"></i> : <i className="fa f-16 fa-angle-right me-2 "></i>} <span>Добавить модуль</span>
						</Col>
						<Collapse isOpen={showModuleInfo}>
							<Col>
								<Col className="mb-4">
									<Col sm="12">
										<Select
											placeholder={"Начните вводить..."}
											noOptionsMessage={() => "Нет результатов"}
											options={availableModules}
											value={selectModule}
											onChange={(e) => setSelectModule(e)}
										/>
									</Col>
								</Col>
								<div style={{ display: "flex", justifyContent: "end" }}>
									<Btn attrBtn={{ color: "primary", className: "mb-4", onClick: addModule }}>Добавить</Btn>
								</div>
							</Col>
						</Collapse>
						<TableModules filteredProducts={modules} setFilteredProducts={setModules} deleteModule={deleteModule} />
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TablePluginTemplates;
