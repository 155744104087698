import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { Label, Input, CardBody, Col, Collapse, Row, InputGroup, InputGroupText } from "reactstrap";
import JSZip from "jszip";
import { Link } from "react-router-dom";

import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { CmsApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import { Btn, H6, P } from "../../../../AbstractElements";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import uuid from "react-uuid";
import {concatResults} from "../../../../concatResults";

const TableSitesList = ({ products, refreshTemplates }) => {
	const [editModal, setEditModal] = useState(false);
	const { companyData } = useCompanyContext();

	const [edit, setEdit] = useState(null);

	const [description, setDescription] = useState("");
	const [sites, setSites] = useState([]);
	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [isPublish, setIsPublish] = useState(false);

	const [price, setPrice] = useState(0);
	const [curItem, setCurItem] = useState(null);

	const { keycloak } = useKeycloak();
	let currentUserId = `${keycloak.tokenParsed.sub}`;

	const toggle = (template) => {
		if (typeof template === "object" && template.hasOwnProperty("id")) {
			setName(template.name);
			setId(template.id);
			setDescription(template.description);
			setSites(template.sites);
			setIsPublish(template.isPublish);
			const selectedCategories = template.categoryId.map((categoryId) => {
				const category = allCategory.find((cat) => cat.value === categoryId);
				return {
					label: category ? category.label : categoryId,
					value: categoryId,
				};
			});
			setSelectCategory(selectedCategories);
			setPrice(template.price);
			setEdit(true);
			setCurItem(template);
		}
		else {
			setName("");
			setId(uuid());
			setSites([]);
			setDescription("");
			setIsPublish(false);
			setSelectCategory([]);
			setPrice(0);
			setEdit(false);
			setCurItem(null);
		}
		setEditModal(!editModal);
	};

	const columns = [
		{
			name: "Название шаблона",
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: "Описание",
			selector: (row) => `${row.description}`,
			sortable: true,
		},
		{
			name: "Список сайтов",
			selector: (row) => `${row.description}`,
			sortable: true,
		},
		{
			name: "Экспорт",
			selector: (row) => (
				<Btn attrBtn={{ color: "light", onClick: () => handleDownload(row.id, row.name) }}>
					<i className="fa fa-upload me-2"></i> Скачать шаблон
				</Btn>
			),
			sortable: false,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTemplate(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTemplate = (templateId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${CmsApi}deleteTemplate/${templateId}`, {
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
					})
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
						refreshTemplates();
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении:", error);
					});
			}
		});
	};

	const deleteSite = (siteId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				const modifiedsites = sites.filter((page) => page.id !== siteId);
				setSites(modifiedsites);
			}
		});
	};

	const addEditTemplate = async () => {
		const newTemplate = {
			id: id,
			name: name,
			description: description,
			dateAddition: new Date(),
			img: null,
			price: price,
			isPublish: isPublish,
			isDeleted: false,
			creatorId: currentUserId,
			categoryId: selectCategory.map(category => category.value),
			sites: sites.map(site => site.id)
		};

		const formData = new FormData();
		formData.append('template', JSON.stringify(newTemplate));
		if (zipArchive) {
			formData.append('archive', zipArchive);
		}

		try {
			const headers = {
				headers: {
					Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
					'Content-Type': 'multipart/form-data'
				}
			};

			if (edit) {
				await axios.put(`${CmsApi}updateTemplate`, formData, headers);
				toast.success("Шаблон обновлен!", { position: toast.POSITION.TOP_RIGHT });
			} else {
				await axios.post(`${CmsApi}createTemplate`, formData, headers);
				toast.success("Шаблон добавлен!", { position: toast.POSITION.TOP_RIGHT });
			}
			setEditModal(false);
			refreshTemplates();
		} catch (error) {
			toast.error("Ошибка при добавлении/обновлении", { position: toast.POSITION.TOP_RIGHT });
		}
	};




	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = (products.length > 0) ? products.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1) : [];

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const dowload = () => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "Ранее созданный шаблон сайта будет удалён без возможности восстановления!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Ок",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				const input = document.createElement("input");
				input.type = "file";
				input.accept = ".zip";
				input.onchange = (event) => {
					const file = event.target.files[0];
					handleImport(file);
				};
				input.click();
			}
		});
	};

	const [jsonData, setJsonData] = useState([]);
	const [zipArchive, setZipArchive] = useState({});

	const handleImport = async (file) => {
		if (!file) return;

		setZipArchive(file);

		const zip = await JSZip.loadAsync(file);

		const jsonFiles = [];

		zip.forEach(async (relativePath, zipEntry) => {
			if (zipEntry.name.endsWith(".json")) {
				const jsonContent = await zip.file(zipEntry.name).async("string");
				const fileName = zipEntry.name.replace(/^.*[\\/]/, "");
				jsonFiles.push({ name: fileName, data: JSON.parse(jsonContent) });
			}
		});

		console.log(jsonFiles, jsonFiles.length);
		setJsonData(jsonFiles);
	};
	async function handleDownload(templateId, templateName) {
		try {
			const response = await axios.get(`${CmsApi}exportTemplate/${templateId}`, {
				responseType: 'blob',
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
			});

			const url = window.URL.createObjectURL(new Blob([concatResults(response)], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${templateName}.zip`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			console.error("Ошибка при экспорте шаблона:", error);
		}
	}



	const [showSiteInfo, setShowSiteInfo] = useState(false);

	const addSite = () => {
		setShowSiteInfo(!showSiteInfo);
	};

	const [selectSite, setSelectSite] = useState(null);
	const [allSites, setAllSites] = useState([]);

	const [selectCategory, setSelectCategory] = useState(null);
	const [allCategory, setAllCategory] = useState([]);

	useEffect(() => {
		axios
			.get(`${CmsApi}getSites`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				const selectOptions = concatResults(response).sites.map((option) => ({
					label: option.name,
					value: option.id,
					description: option.description,
					theme: option.theme,
					isPublish: option.isPublish,
					pages: option.pages,
				}));
				setAllSites(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`/api/templateCategory.json`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setAllCategory(concatResults(response));
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const handleSelectChange = (selectedOption) => {
		setSelectSite(selectedOption);
		sites.push({
			id: selectedOption.value,
			name: selectedOption.label,
			description: selectedOption.description,
			theme: selectedOption.theme,
			isPublish: selectedOption.isPublish,
			pages: selectedOption.pages,
		});
	};

	const createOption = (label) => ({
		label,
		value: label,
	});

	const handleCreate = (inputValue) => {
		const newOption = createOption(inputValue);
		setAllCategory((prev) => [...prev, newOption]);
		setSelectCategory((prev) => [...prev, newOption]);
	};

	//публикация в каталоге
	const publish = () => {
		const updTemplate = {
			...curItem,
			isPublish: true,
		};
		setIsPublish(true);
	};

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				noDataComponent={"В данной таблице ещё нет записей."}
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={editModal} title={edit ? "Редактировать" : "Добавить"} toggler={addEditTemplate} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className=" col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className=" col-sm-12 mb-4">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
						</Col>
					</Col>
					<Row>
						<Col className=" col-sm-7 mb-4">
							<Label className="col-sm-9 col-form-label">Категория</Label>
							<Col sm="12">
								<CreatableSelect
									isClearable
									value={selectCategory}
									onChange={(option) => setSelectCategory(option)}
									options={allCategory}
									isMulti
									onCreateOption={handleCreate}
									placeholder={"Начните вводить..."}
									formatCreateLabel={(inputValue) => `Создать "${inputValue}"`}
								/>
							</Col>
						</Col>
						<Col className=" col-sm-4 mb-4">
							<Label className="col-sm-9 col-form-label">Цена</Label>
							<Col sm="12">
								<InputGroup>
									<Input className="form-control" type="number" placeholder="" aria-label="" value={price} onChange={(e) => setPrice(e.target.value)} />
									<InputGroupText>{"₽"}</InputGroupText>
								</InputGroup>
							</Col>
						</Col>
					</Row>
					<div>
						<Btn attrBtn={{ color: "info", onClick: dowload, className: "me-3" }}>
							<i className="fa fa-folder-open me-2"></i> Загрузить шаблон
						</Btn>
						{isPublish ? (
							<Btn attrBtn={{ color: "primary", onClick: publish, disabled: true, outline: true }}>
								<i className="fa fa-check me-2"></i> Опубликовано в каталоге
							</Btn>
						) : (
							<Btn attrBtn={{ color: "primary", onClick: publish }}>
								<i className="fa fa-book me-2"></i> Опубликовать в каталоге
							</Btn>
						)}
					</div>
					<br />
					<Col className=" col-sm-12 mt-4 mb-2">
						<H6>Сайты, для котрых установлен шаблон:</H6>
					</Col>
					{sites.map((site) => (
						<Col className="mb-2 ms-2 b-b-light col-md-11 pb-2 pt-3">
							<span className="font-primary me-2">Название:</span>
							{site.name}
							<span className="ms-4 font-primary me-2">Описание:</span>
							{site.description}

							<Tooltip title="Редактировать в конструкторе" arrow placement="top">
								<div className="font-primary f-right" style={{ marginLeft: "5px", cursor: "pointer" }}>
									<Link to={`/app/site-builder/${site.id}`}>
										<Edit size={"18px"} />
									</Link>
								</div>
							</Tooltip>
							<Tooltip title="Удалить" arrow placement="top">
								<div className="font-primary f-right" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteSite(site.id)}>
									<Trash2 size={"18px"} />
								</div>
							</Tooltip>
						</Col>
					))}
					<Col
						className=" f-14 p-t-15 p-b-15 b-r-8 b-b-light mb-4 d-flex ps-4 mt-3"
						onClick={addSite}
						md="11"
						style={{ cursor: "pointer", alignItems: "center", backgroundColor: "#7366ff08", margin: "0.25rem 0 0" }}
					>
						{showSiteInfo ? <i className="fa fa-angle-down me-2 f-16"></i> : <i className="fa f-16 fa-angle-right me-2 "></i>} <span>Добавить сайт</span>
					</Col>
					<Collapse isOpen={showSiteInfo}>
						<Col md="11">
							<Select
								placeholder="Введите название сайта"
								noOptionsMessage={() => "Нет результатов"}
								options={allSites}
								value={selectSite}
								theme={(theme) => ({
									...theme,
									colors: {
										...theme.colors,
										primary25: "#7366ff80",
										primary: "#7366ff",
									},
								})}
								className="js-example-basic-single col-md-12 mb-2"
								onChange={handleSelectChange}
							/>
						</Col>
					</Collapse>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableSitesList;
