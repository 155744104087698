import React, { Fragment, useState, useContext } from "react";
import Select from "react-select";

import { Grid, List } from "react-feather";
import { Col, Row } from "reactstrap";
import Sorting from "./Sorting";
import FilterContext from "../../../../../_helper/Ecommerce/Filter";

const ProductTotal = ({ cardsPerPage, currentPage, setCurrentPage, indexOfFirstCard, indexOfLastCard, len }) => {
	const gridLayout = () => {
		document.getElementById("product-wrapper-grid").classList.remove("list-view");

		var elems = document.getElementById("gridRow").childNodes;
		[].forEach.call(elems, function (el) {
			el.className = "";
			el.classList.add("col-xl-3");
			el.classList.add("col-sm-6");
			el.classList.add("xl-4");
		});
	};
	const listLayout = () => {
		document.getElementById("product-wrapper-grid").classList.add("list-view");
		var elems = document.getElementById("gridRow").childNodes;
		[].forEach.call(elems, function (el) {
			el.className = "";
			el.classList.add("col-xl-12");
		});
	};

	const { filterSortBy } = useContext(FilterContext);
	const filterSortFunc = (option) => {
		filterSortBy(option.value);
		setSelectType(option);
	};

	const [selectType, setSelectType] = useState({ label: "по популярности", value: "popularity" });

	return (
		<Fragment>
			<Row className="m-b-10">
				<Col md="6" className="products-total">
					<div className="square-product-setting d-inline-block">
						<a className="icon-grid grid-layout-view" onClick={gridLayout} href="#javascript">
							<Grid />
						</a>
					</div>
					<div className="square-product-setting d-inline-block ">
						<a className="icon-grid m-0 list-layout-view" onClick={listLayout} href="#javascript">
							<List />
						</a>
					</div>
					<div className=" d-inline-block ms-3" onChange={(e) => filterSortFunc(e.target.value)}>
						<Select
							placeholder="Начните вводить..."
							noOptionsMessage={() => "Нет результатов"}
							options={[
								{ label: "по популярности", value: "popularity" },
								{ label: "по убыванию цены", value: "lowestPrices" },
								{ label: "по возрастанию цены", value: "highestPrices" },
							]}
							value={selectType}
							theme={(theme) => ({
								...theme,
								colors: {
									...theme.colors,
									primary25: "#7366ff80",
									primary: "#7366ff",
								},
							})}
							onChange={(option) => filterSortFunc(option)}
						/>
					</div>
				</Col>
				<Sorting len={len} cardsPerPage={cardsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage} indexOfFirstCard={indexOfFirstCard} indexOfLastCard={indexOfLastCard} />
			</Row>
		</Fragment>
	);
};
export default ProductTotal;
