import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import DataTable from 'react-data-table-component';
import Select from 'react-select';

import { Col, Row, Label } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import { useKeycloak } from '@react-keycloak/web';
import { TelephonyApi } from '../../../../api';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const LostCalls = () => {
	const [callStatistics, setCallStatistics] = useState([]);
	const [selectPeriod, setSelectPeriod] = useState({ label: 'Последние 30 дней', value: 'last30Days' });
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const getData = async () => {
		try {
			const response = await axios.get(`${TelephonyApi}getLostCallsStatistics`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const statistics = concatResults(response).statistics;
			setCallStatistics(statistics);

		} catch (error) {
			console.error('Ошибка получения данных:', error);
		}
	}

	useEffect(() => {
		getData()
	}, []);

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getDate();
		const month = dateObject.getMonth() + 1;
		const year = dateObject.getFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	const data = {
		series: [
			{
				name: 'Потерянные',
				data: callStatistics.map((call) => call.lostCalls),
			},
		],
		options: {
			chart: {
				height: 350,
				type: 'line',
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'straight',
			},

			title: {
				text: '',
				align: 'left',
			},
			grid: {
				row: {
					colors: ['#f3f3f3', 'transparent'],
					opacity: 0.5,
				},
			},
			xaxis: {
				categories: callStatistics.map((call) => formatDate(call.date)),
			},
			colors: ['#fb1506', '#ff3364'],
			tooltip: {
				x: {
					format: 'dd/MM/yy HH:mm',
				},
			},
		},
	};

	return (
		<Col xl="12">
			<Label>Отчетный период</Label>
			<Select
				placeholder="Выберите..."
				options={[
					{ label: 'Текущая неделя', value: 'currentWeek' },
					{ label: 'Текущий месяц', value: 'currentMonth' },
					{ label: 'Текущий квартал', value: 'currentQuarter' },
					{ label: 'Последние 7 дней', value: 'last7Days' },
					{ label: 'Последние 30 дней', value: 'last30Days' },
				]}
				className="js-example-basic-single col-sm-12 mb-4"
				value={selectPeriod}
				onChange={(option) => setSelectPeriod(option)}
			/>
			<H5>Потерянные звонки</H5>
			<div className="chart-container crypto-chart mb-4">
				<Row className="row">
					<ReactApexChart type="line" height={400} series={data.series} options={data.options} />
				</Row>
			</div>
		</Col>
	);
};

export default LostCalls;
