import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import axios from 'axios';
import Select from 'react-select';
import { Breadcrumbs, Badges, H5, Btn } from '../../../AbstractElements';
import { Label, Col, Row } from 'reactstrap';
import TableStorage from './TableStorage';
import { concatResults } from '../../../concatResults';

const Storage = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get('/api/storage.json')
			.then((response) => {
				setFilteredProducts(concatResults(response));
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список складов" parent="Склад" title="Список складов" />
			<Container fluid={true}>
				<Card>
					<CardBody>{filteredProducts && <TableStorage filteredProducts={filteredProducts} />}</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default Storage;
