import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Loader from "./Layout/Loader";
import Keycloak from "keycloak-js";

const keycloak = new Keycloak("/keycloak.json");

const Root = () => {
	return (
		<div className="App">
			<ReactKeycloakProvider
				authClient={keycloak}
				initOptions={{
				 	flow: "standard",
				 	enableLogging: true,
				 	onLoad: "check-sso",
				 	pkceMethod: "S256",
					// 	silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
					promiseType: "native",
				}}
				LoadingComponent={<Loader />}
				autoRefreshToken
			>
				<App />
			</ReactKeycloakProvider>
		</div>
	);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

// ReactDOM.createRoot(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
