import React from 'react';
import { Input } from 'reactstrap';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="filter-component">
        <Input type="text" placeholder="Поиск..." value={filterText} onChange={onFilter} />
        <button onClick={onClear}>Очистить</button>
    </div>
);

export default FilterComponent;
