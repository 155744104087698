import React, { Fragment, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, CardBody } from "reactstrap";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Select from "react-select";
import uuid from "react-uuid";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import AsyncCreatableSelect from "react-select/async-creatable";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import ru from "date-fns/locale/ru";
import DatePicker from "react-datepicker";
import { useKeycloak } from "@react-keycloak/web";

import { TwinAuthApi, CompaniesApi, AllProjectApi } from "../../../../api";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { Btn, H5 } from "../../../../AbstractElements";
import { Copy, Link, Mail, RefreshCw } from "react-feather";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

function InputGroup({ data, onChange, setIsValidEmails, isValidEmails }) {
	const handleInputChange = (e, groupIndex, fieldName) => {
		const updatedData = [...data];
		updatedData[groupIndex][fieldName] = e.target.value;
		onChange(updatedData);
	};

	const handleEmailChange = (e, groupIndex) => {
		const inputValue = e.target.value;
		const updatedIsValidEmails = [...isValidEmails];

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isValid = emailRegex.test(inputValue);

		updatedIsValidEmails[groupIndex] = isValid;
		setIsValidEmails(updatedIsValidEmails);
		handleInputChange(e, groupIndex, "email");
	};

	return (
		<div>
			<Row className="mt-4">
				<Col md="4">
					<Label>Email</Label>
				</Col>
				<Col md="4">
					<Label>Имя</Label>
				</Col>
				<Col md="4">
					<Label>Фамилия</Label>
				</Col>
			</Row>
			{data.map((group, groupIndex) => (
				<Row key={groupIndex} className="mt-2">
					<Col md="4">
						<Input type="email" name="email" value={group.email} onChange={(e) => handleEmailChange(e, groupIndex)} placeholder="Email" />
						{!isValidEmails[groupIndex] && <span className="font-danger">Введите корректный email</span>}
					</Col>
					<Col md="4">
						<Input type="text" name="name" value={group.name} onChange={(e) => handleInputChange(e, groupIndex, "name")} placeholder="Имя" />
					</Col>
					<Col md="4">
						<Input type="text" name="surname" value={group.surname} onChange={(e) => handleInputChange(e, groupIndex, "surname")} placeholder="Фамилия" />
					</Col>
				</Row>
			))}
		</div>
	);
}

const CreateProject = ({ projectId, participants, setParticipants, pattern, setPattern, setDateEnd, dateStart, dateEnd, setDateStart, owner, setOwner, secret, setSecret }) => {
	const [allUsersNames, setAllUsersNames] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [data, setData] = useState([]);
	const colourStyles = {
		control: (styles) => ({ ...styles, border: "none", boxShadow: "none", fontSize: "14px", padding: "0.22rem" }),
	};
	const [modal, setModal] = useState(false);
	const [pillWithIconTab, setpillWithIconTab] = useState("1");

	const [domain, setDomain] = useState(window.location.origin.toString())
	const [link, setLink] = useState(`${domain}/?secret=${secret}`);
	const createOption = (label) => ({
		label,
		value: label,
	});

	const [msgForUser, setMsgForUser] = useState("");
	const [login, setLogin] = useState("");
	const [groups, setGroups] = useState([{ name: "", surname: "", email: "" }]);
	const [isValidEmails, setIsValidEmails] = useState(Array(data.length).fill(true));

	useEffect(() => {
		setLink(`${domain}/?secret=${secret}`);
	}, [secret]);

	useEffect(() => {
		axios
			.get(`${CompaniesApi}get-company-users?CompanyId=2f1f09a5-337b-463a-b522-693e28b49920`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
				}));
				setData(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
				}));
				setAllUsersNames(selectOptions);
				const fetchData = async () => {
					const result = await getUserName(keycloak.tokenParsed.sub);
					setOwner({ label: result, value: keycloak.tokenParsed.sub });
				};
				fetchData();
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	async function getUserName(userId) {
		try {
			const response = await axios.get(`${TwinAuthApi}get-user?id=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const userData = JSON.parse(response.data);
			return userData.firstName + userData.lastName;
		} catch (error) {
			console.error("Ошибка получения данных:", error);
			return null;
		}
	}

	const toggle = () => {
		setModal(!modal);
	};

	const refreshLink = () => {
		const secret = uuid().split("-")[0];
		const link = `${domain}/?secret=${secret}`;
		setLink(link);
		setSecret(secret);

		if (projectId !== null) {
			var data = { projectId: projectId, secret: secret };

			axios
				.post(`${AllProjectApi}setLinkInvitation`, data, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					toast.success('Приглашения отправлены!', {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const promiseOptions = (inputValue, callback) => {
		axios
			.get(`${TwinAuthApi}search-users?search=${inputValue}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.username,
				}));
				callback(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};


	const handleCreate = (inputValue) => {
		setMsgForUser("Пользователь не найден на нашей платформе. Приглашение с регистрацией будет отправлено по указанному email адресу");
		setTimeout(() => {
			const newOption = createOption(inputValue);
			setLogin(newOption);
		}, 1000);
	};

	const handleGroupChange = (updatedGroups) => {
		setGroups(updatedGroups);
	};

	//отправить приглашения по login (login-Invitation.json)
	const handleChange = (option) => {
		setLogin(option);
		const newInvation = {
			login: option.value,
		};
		// axios
		// 		.post(`${ServicesApi}createInstance`, newInvation, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
		// 		.then((response) => {
		// 			toast.success('Приглашениe отправлено!', {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		//
		// 		})
		// 		.catch((error) => {
		// 			console.error(error);
		// 		});
	};

	const handleAddGroup = () => {
		setGroups([...groups, { name: "", surname: "", email: "" }]);
	};

	//отправить приглашения по email (email-Invitation.json)
	const handleOutputValues = () => {
		console.log(groups);
		// axios
		// 		.post(`${ServicesApi}createInstance`, groups, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
		// 		.then((response) => {
		// 			toast.success('Приглашения отправлены!', {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 			setModal(false);
		// 		})
		// 		.catch((error) => {
		// 			console.error(error);
		// 		});
	};

	return (
		<Fragment>
			<Row>
				<FormGroup className="col-md-4">
					<Label className="col-sm-12 col-form-label ">Дата начала</Label>
					<Col xl="11">
						<DatePicker
							placeholderText="Дата начала"
							className="form-control digits"
							locale={ru}
							value={dateStart}
							onChange={(date) => setDateStart(date)}
							selected={dateStart}
							dateFormat="dd.MM.yyyy"
						/>
					</Col>
				</FormGroup>
				<FormGroup className="col-md-4">
					<Label className="col-sm-12 col-form-label ">Дата окончания</Label>
					<Col xl="11">
						<DatePicker
							placeholderText="Дата окончания"
							className="form-control digits "
							locale={ru}
							selected={dateEnd}
							value={dateEnd}
							onChange={(date) => setDateEnd(date)}
							dateFormat="dd.MM.yyyy"
						/>
					</Col>
				</FormGroup>
				<FormGroup className="col-md-4">
					<Label className="col-sm-6 col-form-label ">Владелец</Label>
					<Col md="12">
						<Select
							placeholder="Начните вводить..."
							noOptionsMessage={() => "Нет результатов"}
							options={data}
							value={owner}
							className="form-control col-sm-12 p-0"
							styles={colourStyles}
							onChange={(option) => setOwner(option)}
						/>
					</Col>
				</FormGroup>
			</Row>
			<Row className="mt-2">
				<Col sm="6">
					<FormGroup>
						<Label>Участники проекта</Label>
						<Label className="text-end font-primary f-right me-2 c-pointer" onClick={toggle}>
							Пользователь не из вашей компании?
						</Label>
						<Select
							styles={colourStyles}
							placeholder="Начните вводить имя пользователя..."
							isMulti
							noOptionsMessage={() => "Нет результатов"}
							options={allUsersNames}
							value={participants}
							onChange={(option) => setParticipants(option)}
							className="form-control col-sm-12 p-0"
						/>
					</FormGroup>
				</Col>
				<Col sm="6">
					<FormGroup>
						<Label>Шаблон статусов</Label>
						<Input disabled value={pattern} onChange={(e) => setPattern(e.target.value)} type="select" name="issues" className="form-control digits" required>
							<option value={0}>По умолчанию</option>
						</Input>
					</FormGroup>
				</Col>
			</Row>
			<CommonModal isOpen={modal} title={"Пригласить пользователя"} toggler={toggle} togglerClose={toggle} saveText="Готово" size="xl">
				<CardBody style={{ padding: "10px", paddingLeft: "20px", paddingTop: "0" }}>
					<Row>
						<Col sm="3" xs="12">
							<Nav className="nav flex-column nav-pills">
								<NavItem>
									<NavLink className={pillWithIconTab === "1" ? "active" : ""} onClick={() => setpillWithIconTab("1")} style={{ cursor: "pointer" }}>
										Приглашение по ссылке
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={pillWithIconTab === "2" ? "active" : ""} onClick={() => setpillWithIconTab("2")} style={{ cursor: "pointer" }}>
										Приглашение по Email
									</NavLink>
								</NavItem>
							</Nav>
						</Col>
						<Col sm="9" xs="12">
							<TabContent activeTab={pillWithIconTab}>
								<TabPane className="fade show" tabId="1">
									<H5>
										<Link className="font-primary" style={{ margin: "0 3px -3px 0" }} /> Пригласить по ссылке
									</H5>
									<Col sm="12" className="mt-4">
										<Label>Ссылка на быструю регистрацию</Label>
										<Col className="d-flex">
											<Input disabled value={link} type="text" className="form-control me-2" required />
											<CopyToClipboard text={link}>
												<div
													style={{ display: "flex", alignItems: "center" }}
													onClick={() =>
														toast.success("Ссылка скопирована!", {
															position: toast.POSITION.BOTTOM_RIGHT,
														})
													}
												>
													<Btn attrBtn={{ color: "primary", className: "me-2" }}>
														<Tooltip title="Копировать ссылку" arrow placement="top">
															<Copy size={"20px"} style={{ marginBottom: "-3px" }} />
														</Tooltip>
													</Btn>
												</div>
											</CopyToClipboard>
											<Btn attrBtn={{ color: "primary", className: "me-2", onClick: refreshLink }}>
												<Tooltip title="Обновить ссылку" arrow placement="top">
													<RefreshCw size={"20px"} style={{ marginBottom: "-3px" }} />
												</Tooltip>
											</Btn>
										</Col>
									</Col>
								</TabPane>
								<TabPane tabId="2">
									<H5>
										<Mail className="font-primary" style={{ margin: "0 3px -3px 0" }} /> Пригласить по Email
									</H5>
									<AsyncCreatableSelect
										defaultOptions
										cacheOptions
										isClearable
										loadOptions={promiseOptions}
										className="mt-4"
										onChange={handleChange}
										value={login}
										onCreateOption={handleCreate}
										placeholder={"Введите или создайте  логин"}
										formatCreateLabel={(inputValue) => `Создать "${inputValue}"`}
									/>
									{msgForUser && (
										<>
											<Col>
												<div className="mt-4 mb-3 p-3 b-r-8 font-primary" style={{ backgroundColor: "#7366ff20" }}>
													{msgForUser}
												</div>
											</Col>

											<InputGroup data={groups} onChange={handleGroupChange} setIsValidEmails={setIsValidEmails} isValidEmails={isValidEmails} />
											<div
												onClick={handleAddGroup}
												className="font-primary mt-2 c-pointer mb-2"
												style={{ textDecoration: "underline dashed", textDecorationThickness: "1px", textUnderlineOffset: "3px" }}
											>
												<i className="fa fa-plus me-2"></i>Добавить еще
											</div>
											<Btn attrBtn={{ onClick: handleOutputValues, className: "f-right" }}>Отправить</Btn>
										</>
									)}
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};

export default CreateProject;
