import React, { Fragment, useState, useEffect } from 'react';
import { Container, Card, CardBody, Row, CardHeader } from 'reactstrap';
import { Col } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import './DnD.css';
import RightPanel from './RightPanel';
import LeftPanel from './LeftPanel';

import { Breadcrumbs, H5 } from '../../../../AbstractElements';
import { DemandsApi, TaskApi } from '../../../../api';
import LeftPanelMenu from './LeftPanelMenu';
import { concatResults } from "../../../../concatResults";
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';
import {Puck} from "@measured/puck";
import {PuckComponents} from "./Components/PuckComponents";
import { useDemandsContext } from "../../../../_helper/Demands/DemandsProvider";

const VisualEditorService = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const formId = queryParams.get('formId');
	const defaultItems = ['input', 'tel', 'email', 'textarea', 'checkbox', 'text', 'select', 'prepayment', 'date', 'time', 'ProductsOrderTable'];
	const [items, setItems] = useState(defaultItems);
	const [formMenu, setFormMenu] = useState(false);

	const [managementData, setManagementData] = useState([]);

	const { getDemandForms, demandForms, demandForm, setDemandForm } = useDemandsContext();
	useEffect(() => {
		formId && getDemandForms();
	}, []);

	useEffect(() => {

		var currentForm = demandForms.find(obj => obj.id === formId);
		if (currentForm) {
			setDemandForm(currentForm);
		}

	}, [demandForms]);

	useEffect(() => {
		axios
			.get(`${TaskApi}get`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				params: {
					UserId: keycloak.tokenParsed.sub,
					UserType: null,
				},
			})
			.then((response) => {
				setManagementData(concatResults(response).tasks);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`/api/tasks.json`)
			.then((response) => {
				setManagementData(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [isOverLeftPanel, setIsOverLeftPanel] = useState(false);

	const handleDrop = (item) => {
		setDemandForm((prevForm) => ({
			...prevForm,
			parameters: [...prevForm.parameters, item]
		}));

		console.log(demandForm.parameters);
	};

	const addMenuItems = (item) => {
		setDemandForm((prevDemandForm) => ({
			...prevDemandForm,
			menuItems: [...prevDemandForm.menuItems, item]
		}));

		console.log(demandForm.menuItems);
	};

	const removeMenuItems = (index) => {
		setDemandForm((prevDemandForm) => {
			const updatedMenuItems = [...prevDemandForm.menuItems];
			updatedMenuItems.splice(index, 1);

			for (let i = index; i < updatedMenuItems.length; i++) {
				updatedMenuItems[i].order = i + 1;
			}

			return {
				...prevDemandForm,
				menuItems: updatedMenuItems
			};
		});

		console.log(demandForm.menuItems);
	};

	const handleRemove = (index) => {
		setDemandForm((prevForm) => {
			const updatedParameters = [...prevForm.parameters];
			updatedParameters.splice(index, 1);

			for (let i = index; i < updatedParameters.length; i++) {
				updatedParameters[i].order = i + 1;
			}

			return {
				...prevForm,
				parameters: updatedParameters,
			};
		});
	};

	const config = {
		categories: {
			layout: {
				title: "Разметка",
				components: ["Columns", "Flex", "VerticalSpace", "Tabs", "BlockWithHeader"]
			},
			textElements: {
				title: "Текст",
				components: ["Heading", "RegularText", "BoldText"]
			},
			elements: {
				title: "Элементы",
				components: ["InputText", "InputPhone", "InputEmail", "InputTextArea", "InputCheckbox", "InputSelect", "InputPrepayment", "InputDate", "InputTime", "InputNights", "Table" ]
			}
		},
		components: PuckComponents,
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Визуальный редактор форм" subParent="Конструктор форм" parent="Заявки" title="Визуальный редактор форм" />
			<Container fluid={true}>
				<Puck config={config} //data={{
					//content: initialData?.content && initialData?.content,
					//zones: initialData?.zones && initialData?.zones
				//}} onChange={saveState}
				>
					<Row>
					<Col xxl="4" sm="12 box-col-4">
						<Card>
							<CardBody className="">
								<RightPanel setDemandForm={setDemandForm} items={items} demandForm={demandForm} formId={formId} setManagementData={setManagementData} managementData={managementData} setFormMenu={setFormMenu} formMenu={formMenu} setIsOverLeftPanel={setIsOverLeftPanel} />
							</CardBody>
						</Card>
					</Col>
					<Col xxl="8" sm="12 box-col-7">
						{formMenu ? (
							<>
								<Card>
									<CardHeader>
										<H5>{demandForm.name}</H5>
									</CardHeader>
									<CardBody className="">
										<LeftPanelMenu onAdd={addMenuItems} onRemove={removeMenuItems} demandForm={demandForm} setDemandForm={setDemandForm} />
									</CardBody>
								</Card>
								<Card>
									<CardBody>
										<LeftPanel demandForm={demandForm} onDrop={handleDrop} onRemove={handleRemove} isOverLeftPanel={isOverLeftPanel} />
									</CardBody>
								</Card>
							</>
						) : (
							<Card>
								<CardHeader>
									<H5>{demandForm.name}</H5>
								</CardHeader>
								<CardBody>
									<LeftPanel demandForm={demandForm} onDrop={handleDrop} onRemove={handleRemove} isOverLeftPanel={isOverLeftPanel} />
								</CardBody>
							</Card>
						)}
					</Col>
				</Row>
				</Puck>
			</Container>
		</Fragment>
	);
};
export default VisualEditorService;
