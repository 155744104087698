import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Container } from 'reactstrap';
import { useLocation } from 'react-router';
import { Plus, Trash2 } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Select from 'react-select';
import { Label, Input, Col, InputGroup, InputGroupText, FormGroup, Media, Row } from 'reactstrap';
import { Breadcrumbs, H5, Btn, P } from '../../../../AbstractElements';
import CommonModal from '../../../UiKits/Modals/common/modal';
import TeachersTableModal from '../../Learning/Courses/BuildCourse/Teachers/TeachersTableModal';
import uuid from 'react-uuid';
import Commen from '../../../Forms/FormWidget/FormTouchspin/Commen';

import { ServicesApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {concatResults} from "../../../../concatResults";
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';

const EditAddService = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const serviceId = queryParams.get('serviceId');
	const {keycloak} = useKeycloak();
	const {companyData} = useCompanyContext();

	const [val, setVal] = useState(1);

	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);

	const [optionalPrepayment, setOptionalPrepayment] = useState(true);

	useEffect(() => {
		axios
			.get(`/api/optionalPrepayment.json`)
			.then((response) => {
				setOptionalPrepayment(response.data.optional_prepayment);
				console.log(response.data.optional_prepayment);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`${ServicesApi}getServiceCategories`, { params: { query: '' }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				console.log(response);
				const selectOptions = concatResults(response).serviceCategories.map((option) => ({
					label: option.categoryName,
					value: option.id,
				}));
				setCategories(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [name, setName] = useState('');
	const [price, setPrice] = useState('');
	const [id, setId] = useState('');
	const [employees, setEmployees] = useState([]);
	const [prepayment, setPrepayment] = useState(false);
	const [prepaymentAmount, setPrepaymentAmount] = useState(100);
	const [selectedUnit, setSelectedUnit] = useState({ label: '%', value: '%' });
	const [description, setDescription] = useState([]);
	const [isOnline, setOnlineRegistration] = useState([]);

	useEffect(() => {
		axios
			.get(`${ServicesApi}getServices`, { params: { query: '' }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				const foundItem = concatResults(response).services.find((item) => item.id === serviceId);
				if (foundItem) {
					setName(foundItem.name);
					setPrice(foundItem.price);
					setSelectedCategory({ label: foundItem.category.categoryName, value: foundItem.category.id });
					//setEmployees(foundItem.employee);

					setId(foundItem.id);
					setOnlineRegistration(foundItem.isOnlineRegistration);
					setDescription(foundItem.description);
					if (foundItem.prepayment) {
						setPrepayment(foundItem.prepayment);
						setPrepaymentAmount(foundItem.prepaymentAmount);
						setSelectedUnit({ label: foundItem.prepaymentUnit, value: foundItem.prepaymentUnit });
					}
				}
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const columns = [
		{
			name: 'Название',
			selector: (row) => `${row.name} ${row.surname}`,
			sortable: true,
		},

		{
			name: 'Email',
			selector: (row) => `${row.login} `,
			sortable: true,
		},

		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div
							className="font-primary"
							style={{ marginLeft: '5px', cursor: 'pointer' }}
						// onClick={() => deleteTask(row.id)}
						>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const [modal, setModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
	};

	const showToast = (message, type) => {
		toast[type](message, { position: toast.POSITION.TOP_RIGHT });
	};

	const performServiceOperation = (operationType) => {
		if (name === '' || price === '' || selectedCategory === '') {
			showToast('Заполните все поля!', 'error');
		} else {
			const categoryObj = {
				id: selectedCategory.value,
				categoryName: selectedCategory.label,
			};

			const service = {
				Id: operationType === 'update' ? id : uuid(),
				Name: name,
				Price: price,
				Category: categoryObj.id,
				Prepayment: prepayment,
				PrepaymentAmount: prepaymentAmount,
				PrepaymentUnit: selectedUnit.value,
				IsOnlineRegistration: true,
				description: "",
				ServiceType: type,
				MaxParticipants: val
			};

			const apiEndpoint = operationType === 'update' ? 'updateService' : 'createService';
			const httpMethod = operationType === 'update' ? 'put' : 'post';

			axios({
				method: httpMethod,
				url: `${ServicesApi}${apiEndpoint}`,
				data: service,
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
			})
				.then((response) => {
					//console.log(concatResults(response));
					const successMessage = operationType === 'update' ? 'Услуга обновлена!' : 'Услуга добавлена!';
					showToast(successMessage, 'success');
					setModal(false);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
	};

	const createService = () => {
		performServiceOperation('create');
	};

	const updateService = () => {
		performServiceOperation('update');
	};




	const [type, setType] = useState('Индивидуальная');

	return (
		<Fragment>
			<Breadcrumbs mainTitle={name ? `Редактировать услугу` : `Добавить услугу`} parent="Услуги" title={name ? `Редактировать услугу` : `Добавить услугу`} />
			<Container fluid={true}>
				<Card>
					<CardBody className="m-25">
						<Col className="row col-sm-12 mb-3 ">
							<Label className="col-sm-9 col-form-label f-w-600">Название</Label>
							<Col sm="12">
								<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label f-w-600">Категория</Label>
							<Col sm="12">
								<Select placeholder="Выберите..." value={selectedCategory} options={categories} className="js-example-basic-single col-sm-12" onChange={(selected) => setSelectedCategory(selected)} />
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-4">
							<Label className="col-sm-9 col-form-label f-w-600">Цена</Label>
							<Col sm="12">
								<InputGroup>
									<Input className="form-control" type="text" placeholder="" aria-label="" value={price} onChange={(e) => setPrice(e.target.value)} />
									<InputGroupText>{'₽'}</InputGroupText>
								</InputGroup>
							</Col>
						</Col>
						<Row className="mt-4">
							{!optionalPrepayment && (
								<Col className="col-sm-6 mb-4">
									<Media>
										<Col sm="6">
											<Label className="col-form-label f-w-600">Онлайн-предоплата</Label>
											<P attrPara={{ className: '' }}>Для записи на услугу клиенты вносят предоплату</P>
										</Col>
										<Media body className={'text-start icon-state '} style={{ flexGrow: '0' }}>
											<Label className="switch">
												<Input type="checkbox" checked={prepayment} onChange={(e) => setPrepayment(e.target.checked)} />
												<span className="switch-state"></span>
											</Label>
										</Media>
									</Media>
									{prepayment && (
										<Col className="row col-sm-12 mb-3 mt-4">
											<Label className="col-sm-9 col-form-label f-w-600">Настройка предоплаты</Label>
											<Row>
												<Col sm="3">
													<Input className="form-control" type="number" value={prepaymentAmount} onChange={(e) => setPrepaymentAmount(e.target.value)} />
												</Col>
												<Col sm="2">
													<Select
														placeholder="Выберите..."
														value={selectedUnit}
														options={[
															{ label: '%', value: '%' },
															{ label: '₽', value: '₽' },
														]}
														className="js-example-basic-single col-sm-12"
														onChange={(selected) => setSelectedUnit(selected)}
													/>
												</Col>
											</Row>
										</Col>
									)}
								</Col>
							)}

							<Col className="col-sm-6 mb-4">
								<Label className="col-sm-9 col-form-label f-w-600">Тип услуги</Label>
								<FormGroup className="m-t-15 custom-radio-ml">
									<div className="radio radio-primary mb-3 m-l-5">
										<Input id="radio1" type="radio" name="radio1" value="Индивидуальная" checked={type === 'Индивидуальная'} onChange={(e) => setType(e.target.value)} />
										<Label for="radio1">Индивидуальная</Label>
									</div>
									<div className="radio radio-primary mb-3 m-l-5">
										<Input id="radio4" type="radio" name="radio1" value="Групповая" checked={type === 'Групповая'} onChange={(e) => setType(e.target.value)} />
										<Label for="radio4">Групповая</Label>
									</div>
								</FormGroup>
								{type === 'Групповая' && (
									<Col md="3">
										<Commen groupText={true} val={val} setVal={setVal} />
										<Label className="col-sm-9 col-form-label">Максимальное количество человек</Label>
									</Col>
								)}
							</Col>
						</Row>
						<H5 attrH5={{ className: 'mt-4 mb-4' }}>Сотрудники, оказывающие услуги</H5>
						{employees.length ? <DataTable columns={columns} data={employees} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} /> : <P>сотрудники не назначены</P>}
						<Btn attrBtn={{ outline: true, color: 'primary', onClick: toggle }}>
							<Plus size={'20px'} style={{ margin: ' 0 2px -5px 0' }} />
							Добавить сотрудника
						</Btn>
						<CommonModal isOpen={modal} title={'Добавить сотрудника'} toggler={toggle} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="xl">
							<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
								<TeachersTableModal teachers={employees} />
							</CardBody>
						</CommonModal>
					</CardBody>
					<CardFooter>
						<div className="modal-footer">
							<Btn attrBtn={{ color: 'primary', onClick: id === '' ? createService : updateService }}>Сохранить изменения</Btn>
						</div>
					</CardFooter>
				</Card>
			</Container>
		</Fragment>
	);
};
export default EditAddService;
