import React, { Fragment, useContext, useState } from "react";
import { Col, Form, FormGroup, Input, Media, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { BeatLoader } from "react-spinners";

import ChatAppContext from "../../../../_helper/Chat";
import { Following, Follower, MarkJecno } from "../../../../Constant";
import two from "../../../../assets/images/user/2.png";
import { Btn, H5, Image, LI, P, UL } from "../../../../AbstractElements";
import { PhoneIncoming, PhoneOutgoing } from "react-feather";

const ChatMenu = () => {
	const { allMemberss, currentUserr } = useContext(ChatAppContext);
	const [activeTab, setActiveTab] = useState("1");

	function formatDate(datetime) {
		const date = new Date(datetime);

		const now = new Date();
		const diff = (now - date) / 1000;

		const secondsInDay = 86400;

		if (diff < secondsInDay) {
			return `сегодня в ${formatTime(date)}`;
		} else if (diff < 2 * secondsInDay) {
			return `вчера в ${formatTime(date)}`;
		} else if (date.getFullYear() === now.getFullYear()) {
			return `${date.getDate()} ${getMonthName(date.getMonth())} в ${formatTime(date)}`;
		} else {
			return `${date.getDate()} ${getMonthName(date.getMonth())} ${date.getFullYear()} в ${formatTime(date)}`;
		}
	}

	function formatTime(date) {
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		return `${hours}:${minutes}`;
	}

	function getMonthName(month) {
		const months = ["янв", "фев", "мар", "апр", "мая", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"];
		return months[month];
	}

	const [status, setStatus] = useState("");
	const [loading, setLoading] = useState(false);

	const send = () => {
		if (currentUserr) {
			setLoading(true);
			const setStatus = {
				name: currentUserr.name,
				thumb: currentUserr.thumb,
				status: status,
				phone: currentUserr.phone,
				lastSeenDate: currentUserr.lastSeenDate,
				online: currentUserr.online,
				typing: currentUserr.typing,
				lastCallTime: currentUserr.lastCallTime,
				incoming: currentUserr.incoming,
			};

			// axios
			// 	.put(`/api/chatMember.json/${currentUserr.id}`, setStatus)
			// 	.then((response) => {
			// 		console.log(response.data);
			// toast.success('Статус изменен!', {
			// 	position: toast.POSITION.TOP_RIGHT,
			// })
			//		setLoading(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			//		setLoading(false);
			// 	});
		}
	};
	return (
		<Fragment>
			<Nav tabs className="border-tab nav-primary m-0">
				<NavItem role="tablist" style={{ cursor: "pointer" }}>
					<NavLink className={activeTab === "1" ? "active" : ""} onClick={() => setActiveTab("1")}>
						Звонки
					</NavLink>
				</NavItem>
				<NavItem role="tablist" style={{ cursor: "pointer" }}>
					<NavLink className={activeTab === "2" ? "active" : ""} onClick={() => setActiveTab("2")}>
						Статус
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">
					<div className="people-list">
						<UL attrUL={{ className: "simple-list list digits custom-scrollbar" }}>
							{allMemberss
								.sort((a, b) => new Date(b.lastCallTime) - new Date(a.lastCallTime))
								.map((member, i) => {
									return (
										member.lastCallTime && (
											<LI attrLI={{ className: "clearfix border-0" }} key={i}>
												<Image
													attrImage={{
														className: "rounded-circle user-image",
														src: `${require(`../../../../assets/images/${member.thumb}`)}`,
														alt: "",
													}}
												/>
												<div className="about">
													<div className="name">{member.name}</div>
													<div className="status">
														{member.incoming ? (
															<PhoneIncoming style={{ margin: "0 4px -3px 0" }} className="font-success" size={"14px"} />
														) : (
															<PhoneOutgoing style={{ margin: "0 4px -3px 0" }} className="font-danger" size={"14px"} />
														)}
														{formatDate(member.lastCallTime)}
													</div>
												</div>
											</LI>
										)
									);
								})}
						</UL>
					</div>
				</TabPane>
				<TabPane tabId="2">
					<div className="people-list">
						<div className="search mt-4">
							<Form className="theme-form">
								<FormGroup>
									<Input className="form-control" type="text" placeholder="Написать статус..." value={status} onChange={(e) => setStatus(e.target.value)} />
									<i className="fa fa-pencil"></i>
								</FormGroup>
							</Form>
							<Btn attrBtn={{ color: "primary", outline: true, className: "f-right", onClick: send, disabled: loading }}>
								{loading ? <BeatLoader size={8} color="#7366ff" /> : "Сохранить"}
							</Btn>
						</div>
					</div>
					{/* <div className="status">
						<P attrPara={{ className: 'font-dark' }}>{Active}</P> <hr />
						<P>
							{ChataApp_p1}&nbsp;&nbsp;
							<i className="icofont icofont-emo-heart-eyes font-danger f-20"></i>
							<i className="icofont icofont-emo-heart-eyes font-danger f-20 m-l-5"></i>{' '}
						</P>{' '}
						<hr />
						<P>
							{ChataApp_p2} &nbsp;<i className="icofont icofont-emo-rolling-eyes font-success f-20"></i>
						</P>
						<hr />
					</div> */}
				</TabPane>
				<TabPane tabId="3">
					<div className="user-profile">
						<div className="image">
							<div className="avatar text-center">
								<Media body alt="" src={two} />
							</div>
							<div className="icon-wrapper">
								<i className="icofont icofont-pencil-alt-5"></i>
							</div>
						</div>
						<div className="user-content text-center">
							<H5 attrH5={{ className: "text-center text-uppercase" }}>{MarkJecno}</H5>
							<div className="social-media">
								<UL attrUL={{ horizontal: true, className: "list-inline d-flex justify-content-center" }}>
									<LI attrLI={{ className: "list-inline-item" }}>
										<a href="https://www.facebook.com/">
											<i className="fa fa-facebook"></i>
										</a>
									</LI>
									<LI attrLI={{ className: "list-inline-item" }}>
										<a href="https://accounts.google.com/">
											<i className="fa fa-google-plus"></i>
										</a>
									</LI>
									<LI attrLI={{ className: "list-inline-item" }}>
										<a href="https://twitter.com/">
											<i className="fa fa-twitter"></i>
										</a>
									</LI>
									<LI attrLI={{ className: "list-inline-item" }}>
										<a href="https://www.instagram.com/">
											<i className="fa fa-instagram"></i>
										</a>
									</LI>
									<LI attrLI={{ className: "list-inline-item" }}>
										<a href="https://dashboard.rss.com/auth/sign-in/">
											<i className="fa fa-rss"></i>
										</a>
									</LI>
								</UL>
							</div>
							<hr />
							<div className="follow text-center">
								<Row>
									<Col className="border-end">
										<span>{Following}</span>
										<div className="follow-num">{"236k"}</div>{" "}
									</Col>
									<Col>
										<span>{Follower}</span> <div className="follow-num">{"3691k"}</div>
									</Col>
								</Row>
							</div>
							<hr />
							<div className="text-center digits">
								<P attrPara={{ className: "mb-0" }}>{"Mark.jecno23@gmail.com"}</P>
								<P attrPara={{ className: "mb-0" }}>{"+91 365 - 658 - 1236"}</P>
								<P attrPara={{ className: "mb-0" }}>{"Fax: 123-4560"}</P>
							</div>
						</div>
					</div>
				</TabPane>
			</TabContent>
		</Fragment>
	);
};
export default ChatMenu;
