import React, { Fragment, useState } from "react";
import InputMask from "react-input-mask";
import { Tooltip } from "@mui/material";
import { Btn } from "../../AbstractElements";
import { PhoneCall, PhoneOff } from "react-feather";

const SidebarPhoneWidget = () => {
	const [phoneNumber, setPhoneNumber] = useState("");

		// Функция для добавления цифры в номер телефона
	const addDigit = (digit) => {
		const maxLength = 16;

		if (phoneNumber.length < maxLength) {
			setPhoneNumber(phoneNumber + digit);
		}
		console.log(phoneNumber);
	};

	return (
		<Fragment>
			{/* Виджет для набора номера телефона */}
			<div id="slideout">
				<div className="slideout-title">Телефон</div>
				<div id="slideout_inner">
					<div id="callPanel" style={{ marginTop: "-20px" }}>
						<InputMask
							id="callNumberText"
							className="form-control"
							style={{ marginBottom: "10px", position: "relative", marginTop: "10px" }}
							mask="+7 (999) 999-99-99"
							maskChar="_"
							placeholder="+7 (___) ___-__-__"
							alwaysShowMask
							value={phoneNumber}
							onChange={(e) => setPhoneNumber(e.target.value)}
						/>
						<Tooltip title="Очистить" arrow placement="top">
							<button id="keyboard_btn_del" className="font-secondary" onClick={() => setPhoneNumber("")}>
								×
							</button>
						</Tooltip>
						<div className="keyboard">
							{[1, 2, 3, 4, 5, 6, 7, 8, 9, "+", 0, "#"].map((digit) => (
								<button key={"keyboard_btn_" + digit} className="keyboard_btn" onClick={() => addDigit(digit)}>
									{digit}
								</button>
							))}
						</div>
						<Btn attrBtn={{ id: "callNumberButton", color: "success", className: "pe-3 ps-3 me-2" }}>
							<PhoneCall size={"14px"} /> Вызов
						</Btn>
						<Btn attrBtn={{ id: "hangUpButton", color: "danger", className: "pe-3 ps-3" }}>
							<PhoneOff size={"14px"} /> Отбой
						</Btn>
					</div>

					<audio id="localAudio" autoPlay muted></audio>
					<audio id="remoteAudio" autoPlay></audio>
					<audio id="sounds" autoPlay></audio>
				</div>
			</div>
		</Fragment>
	);
};

export default SidebarPhoneWidget;
