import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, CardHeader } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../../../AbstractElements';
import { Card, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Trash2, Check } from 'react-feather';
import { Link } from 'react-router-dom';
import { DemandsApi } from '../../../../api';
import FilterComponent from './FilterComponent';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const FormBuilderWithDelivery = () => {
	const [data, setData] = useState([]);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${DemandsApi}getDemandForms`, {
				params: {
					query: '',
				},
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
			})
			.then((response) => {
				//console.log(response);
				setData(concatResults(response).demandForms);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		axios
			.get('/api/forms.json')
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const columns = [
		{
			name: '№',
			selector: (row) => (
				<Tooltip title="Редактировать форму" arrow placement="top">
					<Link to={`/app/request/form-builder/visual-editor?formId=${row.id}`}>{row.id}</Link>
				</Tooltip>
			),
			sortable: true,
		},
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
			hide: 'md',
		},
		{
			name: 'С доставкой',
			selector: (row) => <div>{row.withDelivery ? <Check className="font-success ms-4" /> : ''}</div>,
			sortable: false,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`/api/forms.json/${userId}`)
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						axios
							.get('/api/clientsList.json')
							.then((response) => {
								setData(response.data);
							})
							.catch((error) => {
								console.error('Произошла ошибка при обновлении списка:', error);
							});
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении объекта:', error);
					});
			}
		});
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<Breadcrumbs mainTitle="Конструктор форм заявок с доставкой" parent="Доставка" title="Конструктор форм заявок с доставкой" />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardHeader>
							<H5>Формы</H5>
							<p className="font-secondary mt-2">Для запуска визуального редактора, нажмите на номер формы.</p>
						</CardHeader>
						<CardBody>
							<DataTable subHeader subHeaderComponent={subHeaderComponent} columns={columns} data={filteredItems} noDataComponent={'В данной таблице ещё нет записей.'} defaultSortField="name" striped pagination paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
						</CardBody>
					</Card>
				</Row>
			</Container>
		</>
	);
};
export default FormBuilderWithDelivery;
