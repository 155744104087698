import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Breadcrumbs, P } from "../../../../AbstractElements";
import UserProfile from "./UserProfile";
import UserDashboard from "./UserDashboard";
import UserDetails from "./UserDetail";
import UserDetails2 from "./UserDetail2";
import UserDetails3 from "./UserDetails3";
import UserDetails4 from "./UserDetail4";
import AddSearchNews from "./AddSearchNews";
import { useKeycloak } from "@react-keycloak/web";
import { useNewsContext } from "../../../../_helper/News/NewsProvider";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const UsersProfileContain = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { getUserNews, userNews } = useNewsContext();
	const [data, setData] = useState([]);
	const [profileSettings, setProfileSettings] = useState([]);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [userId, setUserId] = useState(queryParams.get("userId"));
	console.log(userId);

	useEffect(() => {
		// axios
		// 	.get("/api/news.json")
		// 	.then((response) => {
		// 		const data = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
		// 		setData(data);
		// 		setSearchResults(data);
		// 	})
		// 	.catch((error) => {
		// 		console.error("Ошибка получения данных:", error);
		// 	});
		// axios
		// 	.get("/api/news.json")
		// 	.then((response) => {
		// 		const data = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
		// 		setData(data);
		// 		setSearchResults(data);
		// 	})
		// 	.catch((error) => {
		// 		console.error("Ошибка получения данных:", error);
		// 	});
		axios
			.get("/api/get-profile-settings2.json")
			.then((response) => {
				const data = response.data;
				setProfileSettings(data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	useEffect(() => {
		getUserNews(userId);
	}, [userId]);

	useEffect(() => {
		setSearchResults(userNews);
	}, [userNews]);

	useEffect(() => {
		!userId && keycloak.authenticated && keycloak.tokenParsed && setUserId(keycloak.tokenParsed?.sub)
	}, [keycloak.authenticated, keycloak.tokenParsed])

	//поиск по новостям
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
		const results = userNews.filter((item) => item.content.toLowerCase().includes(e.target.value.toLowerCase()));
		setSearchResults(results);
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Мой профиль" parent="Пользователи" title="Мой профиль" />
			<Container fluid={true}>
				<div className="user-profile">
					<Row>
						<UserProfile userId={userId} profileSettings={profileSettings} />
						<UserDashboard />
						<AddSearchNews userId={userId} searchTerm={searchTerm} handleSearch={handleSearch} />
						{searchResults ? (
							searchResults.map((item, i) => <UserDetails3 name={item.author === keycloak.tokenParsed?.sub ? keycloak.tokenParsed.name : item.author} time={item.date} content={item.content} key={i} />)
						) : (
							<Col sm="12">
								<Card>
									<CardBody>
										<div className="email-wrap">
											<P attrPara={{ className: "text-center" }}>По вашему запросу ничего не найдено</P>
										</div>
									</CardBody>
								</Card>
							</Col>
						)}
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};
export default UsersProfileContain;
