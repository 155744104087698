import React, { Fragment } from 'react';
import { Container, Row, CardBody, Col, Card } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import DataTableModeration from './DataTableModeration';
import HeaderCard from '../../../Common/Component/HeaderCard';

const ModerationAccount = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Запросы на регистрацию" parent="Админ" title="Модерация аккаунтов" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<DataTableModeration />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default ModerationAccount;
