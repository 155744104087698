import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Card, CardBody } from 'reactstrap';

const MenuCard = ({ items }) => {
	const [menuItems, setMenuItems] = useState(items);

	const onDragEnd = (result) => {
		if (!result.destination) return;

		const updatedItems = [...menuItems];
		const [reorderedItem] = updatedItems.splice(result.source.index, 1);
		updatedItems.splice(result.destination.index, 0, reorderedItem);

		setMenuItems(updatedItems);
		console.log(updatedItems);
	};

	return (
		<Card>
			<CardBody>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="menu">
						{(provided) => (
							<ul {...provided.droppableProps} ref={provided.innerRef}>
								{menuItems.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(provided) => (
											<li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
												{item.title}
											</li>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>
				</DragDropContext>
			</CardBody>
		</Card>
	);
};

export default MenuCard;
