import React, { Fragment, useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { Container, Row, CardBody, Col, Card, Label, Input } from "reactstrap";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { Breadcrumbs, Btn, P } from "../../../../AbstractElements";

const ProfileSettings = () => {
	const { 
		companyData, 
		getCompanyProfileSettingsData, 
		companyProfileSettingsData,
		updateCompanyProfileSettings,
		createCompanyProfileSettings } = useCompanyContext();

	const [loading, setLoading] = useState(true);

	const [displayCover, setDisplayCover] = useState(true);
	const [imageBase64, setImageBase64] = useState("");
	const [isPhone, setIsPhone] = useState(true);
	const [isDesc, setIsDesc] = useState(true);
	const [isInterests, setIsInterests] = useState(true);
	const [isAddress, setIsAddress] = useState(true);
	const [isPostcode, setIsPostcode] = useState(true);
	const [isSide, setIsSide] = useState(true);
	const [isCity, setIsCity] = useState(true);
	const [isTelegram, setIsTelegram] = useState(true);
	const [isVk, setIsVk] = useState(true);

	useEffect(() => {
		companyData && getCompanyProfileSettingsData(companyData[0]?.id) && setLoading(false);
	}, [companyData]);

	useEffect(() => {
		companyProfileSettingsData && setSettingsFromData() && setLoading(false);
	}, [companyProfileSettingsData])

	const setSettingsFromData = () => {
		setDisplayCover(companyProfileSettingsData.isBanner);
		setImageBase64(companyProfileSettingsData.image);
		setIsPhone(companyProfileSettingsData.isPhone);
		setIsDesc(companyProfileSettingsData.isDescription);
		setIsInterests(companyProfileSettingsData.isInterests);
		setIsAddress(companyProfileSettingsData.isAddress);
		setIsPostcode(companyProfileSettingsData.isPostcode);
		setIsSide(companyProfileSettingsData.isCountry);
		setIsCity(companyProfileSettingsData.isCity);
		setIsTelegram(companyProfileSettingsData.isTelegram);
		setIsVk(companyProfileSettingsData.isVk);
	}

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (!file) {
			return;
		}

		const reader = new FileReader();
		reader.onloadend = () => {
			setImageBase64(reader.result);
		};

		reader.readAsDataURL(file);
	};

	const send = () => {
		setLoading(true);
		if (!companyProfileSettingsData) {
			createCompanyProfileSettings(
				companyData[0]?.id,
				imageBase64, 
				displayCover, 
				isPhone, 
				isDesc, 
				isInterests,
				isAddress,
				isPostcode,
				isSide,
				isCity,
				isTelegram,
				isVk
				);
		} else {
			updateCompanyProfileSettings(
				companyProfileSettingsData.id,
				companyData[0]?.id,
				imageBase64, 
				displayCover, 
				isPhone, 
				isDesc, 
				isInterests,
				isAddress,
				isPostcode,
				isSide,
				isCity,
				isTelegram,
				isVk
				);
		}
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Настройки отображения профилей пользователей" parent="Админ" title="Настройки отображения профилей пользователей" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody style={{ padding: "30px 40px" }}>
								<div className="checkbox checkbox-primary  mb-3">
									<Input id="checkbox-primary-displayCover" type="checkbox" checked={displayCover} onChange={(e) => setDisplayCover(e.target.checked)} />
									<Label for="checkbox-primary-displayCover">Отображать обложку профиля пользователя</Label>
								</div>
								{displayCover && (
									<Col md="6">
										<Label>Картинка по умолчанию</Label>
										<Input type="file" accept="image/*" className="form-control col-md-6 mb-3" onChange={handleImageChange} />
										<img src={imageBase64 ? imageBase64 : "https://dummyimage.com/800x800"} alt="Uploaded" className="b-r-10" style={{ maxWidth: "30%" }} />
									</Col>
								)}
								<h6 style={{ color: "#898989", marginTop: "3rem" }} className=" mb-3">
									Дополнительная информация о пользователе:
								</h6>
								<Row>
									<div className="checkbox checkbox-primary mb-2 col-md-6">
										<Input id="checkbox-primary-isPhone" type="checkbox" checked={isPhone} onChange={(e) => setIsPhone(e.target.checked)} />
										<Label for="checkbox-primary-isPhone">Номер телефона</Label>
									</div>
									<div className="checkbox checkbox-primary mb-2 col-md-6">
										<Input id="checkbox-primary-isDesc" type="checkbox" checked={isDesc} onChange={(e) => setIsDesc(e.target.checked)} />
										<Label for="checkbox-primary-isDesc">Обо мне</Label>
									</div>
									<div className="checkbox checkbox-primary mb-2 col-md-6">
										<Input id="checkbox-primary-isInterests" type="checkbox" checked={isInterests} onChange={(e) => setIsInterests(e.target.checked)} />
										<Label for="checkbox-primary-isInterests">Интересы</Label>
									</div>
									<div className="checkbox checkbox-primary mb-2 col-md-6">
										<Input id="checkbox-primary-isAddress" type="checkbox" checked={isAddress} onChange={(e) => setIsAddress(e.target.checked)} />
										<Label for="checkbox-primary-isAddress">Адрес</Label>
									</div>
									<div className="checkbox checkbox-primary mb-2 col-md-6">
										<Input id="checkbox-primary-isCity" type="checkbox" checked={isCity} onChange={(e) => setIsCity(e.target.checked)} />
										<Label for="checkbox-primary-isCity">Город</Label>
									</div>
									<div className="checkbox checkbox-primary mb-2 col-md-6">
										<Input id="checkbox-primary-isPostcode" type="checkbox" checked={isPostcode} onChange={(e) => setIsPostcode(e.target.checked)} />
										<Label for="checkbox-primary-isPostcode">Почтовый индекс</Label>
									</div>
									<div className="checkbox checkbox-primary mb-2 col-md-6">
										<Input id="checkbox-primary-isSide" type="checkbox" checked={isSide} onChange={(e) => setIsSide(e.target.checked)} />
										<Label for="checkbox-primary-isSide">Страна</Label>
									</div>
									<div className="checkbox checkbox-primary mb-2 col-md-6">
										<Input id="checkbox-primary-isTelegram" type="checkbox" checked={isTelegram} onChange={(e) => setIsTelegram(e.target.checked)} />
										<Label for="checkbox-primary-isTelegram">Имя пользователя telegram</Label>
									</div>
									<div className="checkbox checkbox-primary mb-2 col-md-6">
										<Input id="checkbox-primary-isVk" type="checkbox" checked={isVk} onChange={(e) => setIsVk(e.target.checked)} />
										<Label for="checkbox-primary-isVk">Имя пользователя ВКонтакте</Label>
									</div>
								</Row>
								<div className="modal-footer">
									<Btn attrBtn={{ color: "primary", disabled: loading, onClick: send }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default ProfileSettings;
