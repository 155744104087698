import React, { useState, useEffect } from 'react';
import './FieldMappingStep.css';
import { CardBody, Col, Label, Button, Input } from 'reactstrap';
import CommonModal from './CommonModal';
import Select from 'react-select';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';

const FieldMappingStep = ({ nextStep, prevStep, fileData, setFileData, objectTypeId }) => {
    const [showModal, setShowModal] = useState(false);
    const [fileFieldsOptions, setFileFieldsOptions] = useState([]);
    const [dbFieldsOptions, setDbFieldsOptions] = useState([]);
    const [selectedFileField, setSelectedFileField] = useState(null);
    const [selectedDbField, setSelectedDbField] = useState(null);
    const [updatedFileData, setUpdatedFileData] = useState(fileData);
    const [requiredFields, setRequiredFields] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [mappedFields, setMappedFields] = useState({});
    const [fieldsToDisplay, setFieldsToDisplay] = useState([]);


    useEffect(() => {
        if (fileData) {

            const allFileFields = getAllFields(fileData.unicFileFields); // Собираем все поля
            const fieldMappings = getMappedFields(allFileFields); // Маппинг полей

            const fileFields = allFileFields
                .filter(field => field.xmlField && !field.dbField && field.fields.length === 0)
                .map(field => ({ value: field.displayName, label: field.displayName }))
                .filter((field, index, self) =>
                    index === self.findIndex(f => f.label === field.label)
                ); // Доступные XML поля

            // Получаем массив dbField из mappedFields
            const mappedDbFields = fileData.mappedFields.map(field => field.dbField);

            // Фильтрация unicDbFields и преобразование оставшихся полей в нужный формат
            const dbFields = fileData.unicDbFields
                .filter(field => !mappedDbFields.includes(field.name)) // Фильтрация по dbField
                .map(field => ({ value: field.id, label: field.name })); // Доступные DB поля

            setFileFieldsOptions(fileFields);
            setDbFieldsOptions(dbFields);
            setMappedFields(fieldMappings);
            setRequiredFields(fileData.required);

            const fieldsForDisplay = prepareFieldsForDisplay(allFileFields, dbFields, fieldMappings);
            setFieldsToDisplay(fieldsForDisplay); // Подготовка полей для отображения
        }
    }, [fileData]);

    const getAllFields = (fields, parentKey = '', isNested = false) => {
        return fields.reduce((acc, field) => {
            acc.push({
                ...field,
                xmlField: parentKey ? `${parentKey}${field.xmlField}` : field.xmlField,
                displayName: field.xmlField,
                isNested
            });

            if (field.typeXmlField === 'related' && field.fields) {
                const nestedFields = getAllFields(field.fields, `${parentKey}${field.xmlField}.`, true);
                return acc.concat(nestedFields); // Рекурсия для вложенных полей
            }
            return acc;
        }, []);
    };


    const prepareFieldsForDisplay = (allFields, dbFields, mappings) => {
        const result = [];

        const processField = (field, nestingLevel = 0, parentKey = '') => {
            const xmlFieldKey = parentKey ? `${parentKey}.${field.xmlField}` : field.xmlField;
            const mappedDbField = mappings[xmlFieldKey];

            if (!result.some(item => item.fileField === xmlFieldKey)) {
                result.push({
                    fileField: xmlFieldKey,
                    dbField: mappedDbField || '',
                    hasNestedFields: field.typeXmlField === 'related' && field.fields && field.fields.length > 0,
                    isNested: nestingLevel > 0,
                    nestingLevel,
                    displayName: field.xmlField
                });
            }

            if (field.fields && field.fields.length > 0) {
                field.fields.forEach(nestedField => processField(nestedField, nestingLevel + 1, xmlFieldKey));
            }
        };

        allFields.forEach(field => processField(field)); // Обрабатываем все поля
        return result;
    };



    const handleShow = () => {
        setShowModal(true);
    };
    const handleClose = () => setShowModal(false);

    const handleSave = async () => {
        if (selectedFileField && selectedDbField) {
            const updatedFieldsToDisplay = fieldsToDisplay.map(field => {
                if (field.displayName === selectedFileField.value) {
                    return { ...field, dbField: selectedDbField.label };
                }
                return field;
            });

            var newMapField = {
                xmlField: selectedFileField.label,
                dbField: selectedDbField.label
            }
            setUpdatedFileData(prevData => ({
                ...prevData,
                mappedFields: [...prevData.mappedFields, newMapField]
            }));

            setFieldsToDisplay(updatedFieldsToDisplay);
            setFileFieldsOptions(prevOptions => {
                const newOptions = prevOptions.filter(option => option.label !== selectedFileField.label);
                return newOptions;
            });
            setSelectedFileField(null);
            setDbFieldsOptions(prevOptions => {
                const newOptions = prevOptions.filter(option => option.label !== selectedDbField.label);
                return newOptions;
            });
            setSelectedDbField(null);
            handleClose();
        } else {
            console.error("Вы должны выбрать оба поля для сопоставления.");
        }
    };

    // Функция для сброса dbField у объекта
    const resetDbField = (fields, xmlField, dbField) => {
        return fields.map(field => {
            if (field.xmlField === xmlField && field.dbField === dbField) {
                // Сбрасываем dbField у найденного объекта
                return { ...field, dbField: '' };
            }

            // Рекурсивно обрабатываем вложенные поля
            if (field.fields && field.fields.length > 0) {
                return {
                    ...field,
                    fields: resetDbField(field.fields, xmlField, dbField),
                };
            }

            return field;
        });
    };

    // Используем useEffect, чтобы проверить сопоставление после обновления dbFieldsOptions
    useEffect(() => {

        const unmappedRequiredFields = requiredFields.filter(requiredField =>
            dbFieldsOptions.some(field => field.label === requiredField)
        );

        if (unmappedRequiredFields.length > 0) {
            setErrorMsg(`Необходимо сопоставить поля: ${unmappedRequiredFields.join(', ')}`);
        } else {
            setErrorMsg('');
        }
    }, [dbFieldsOptions, requiredFields]);

    // Основная функция обработки удаления сопоставления
    const handleDeleteMapping = (xmlField, dbField) => {
        SweetAlert.fire({
            title: "Вы уверены?",
            text: "После удаления вы не сможете восстановить этот объект!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ОК",
            cancelButtonText: "Отмена",
        }).then(async (result) => {
            if (result.value) {

                setUpdatedFileData(prevData => ({
                    ...prevData,
                    mappedFields: [...prevData.mappedFields.filter(option => option.xmlField !== xmlField)]
                }));

                // Добавление новой опции в fileFieldsOptions
                setFileFieldsOptions(prevOptions => {
                    const newOption = { label: xmlField, value: xmlField };
                    const newOptions = [...prevOptions, newOption]; 
                    return newOptions;
                });

                // Добавление новой опции в dbFieldsOptions
                setDbFieldsOptions(prevOptions => {
                    const newOption = { label: dbField, value: updatedFileData.unicDbFields.find(f => f.name === dbField).id };
                    const newOptions = [...prevOptions, newOption]; 
                    return newOptions;
                });

                const updatedFieldsToDisplay = fieldsToDisplay.map(field => {
                    if (field.displayName === xmlField) {
                        return { ...field, dbField: '' };
                    }
                    return field;
                });
                setFieldsToDisplay(updatedFieldsToDisplay);
                toast.success("Сопоставление удалено!");
            }
        });
    };

    const getMappedFields = (fields) => {
        const mappings = {};
        fields.forEach(field => {
            if (field.xmlField && field.dbField) {
                mappings[field.xmlField] = field.dbField;
            }
        });
        return mappings;
    };

    const goNextStep = () => {
        setFileData(updatedFileData);
        nextStep();
    };

    return (
        <div>
            <Button
                color="primary"
                style={{ marginLeft: "20px" }}
                onClick={() => handleShow()}>
                Сопоставить поля
            </Button>
            <div className="fields-container">
                <div className="fieldsLeft">
                    <h5>Поля из файла</h5>
                    {fieldsToDisplay.map((field, index) => (
                        <div
                            className={`mapping-row ${field.isNested ? 'nested' : ''}`}
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                                paddingLeft: `${field.nestingLevel * 25}px`, // Отступ для вложенных элементов
                            }}
                        >
                            {/* Основная часть с полями */}
                            <div style={{ flex: 1, position: 'relative', marginTop: "5px" }}>
                                {field.hasNestedFields ? (
                                    <div className="nested-fields-text">
                                        {field.displayName} {/* Текст для элементов с вложенными полями */}
                                    </div>
                                ) : (
                                    <Input
                                        type="text"
                                        value={field.displayName}
                                        disabled
                                        className="disabled-input"
                                    />
                                )}
                            </div>

                            {/* Линия, прижатая к правой стороне */}
                            {field.dbField ? (
                                <div
                                    className="mapping-line-container"
                                    style={{
                                        position: 'relative',
                                        right: '0',
                                        width: '150px', // Фиксированная ширина контейнера для линии
                                        height: '100%', // Высота контейнера
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <span className="mapping-line"></span>
                                </div>
                            ) : (
                                <div
                                    className="empty-space"
                                    style={{
                                        position: 'relative',
                                        right: '0',
                                        width: '150px',
                                        height: '100%', 
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                    }}
                                ></div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="fieldsRight">
                    <h5>Поля из БД</h5>
                    {fieldsToDisplay.map((field, index) => (
                        <div
                            className={`mapping-row ${field.isNested ? 'nested' : ''}`}
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                            }}
                        >
                            <div style={{ position: 'relative', marginBottom: "5px" }}>
                                {field.hasNestedFields ? (
                                    <div className="nested-fields-text">
                                        {field.dbField || ' '} {/* Текст для элементов с вложенными полями */}
                                    </div>
                                ) : (
                                    <Input
                                        type="text"
                                        value={field.dbField}
                                        disabled
                                        className="disabled-input"
                                    />
                                )}
                            </div>

                            {field.dbField && (
                                <Button
                                    className="delete-button"
                                    onClick={() => handleDeleteMapping(field.displayName, field.dbField)}
                                >
                                    <DeleteOutlineIcon backgroundColor="white" color="red" size="lg" />
                                </Button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="buttons-container">
                {errorMsg && (
                    <p className="error-message" style={{ marginLeft: "20px", color: "red", fontWeight: 700 }} >{errorMsg}</p>
                )}
                <Button
                    color="primary prevStepBtn"
                    style={{ marginLeft: "20px" }}
                    onClick={prevStep}>
                    Назад
                </Button>
                <Button
                    color="primary"
                    className="nextStepBtn"
                    style={{ marginLeft: "20px" }}
                    onClick={goNextStep}
                    disabled={!!errorMsg}
                >
                    К следующему шагу
                </Button>
            </div>
            <CommonModal
                isOpen={showModal}
                title={"Сопоставить"}
                toggler={handleShow}
                togglerClose={handleClose}
                closeText="Отмена"
                saveText="Сохранить"
                size="lg"
                onSave={handleSave}
            >
                <CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Поле из файла</Label>
                        <Col sm="11">
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={fileFieldsOptions}
                                className="js-example-basic-single col-sm-12"
                                value={selectedFileField}
                                onChange={setSelectedFileField}
                            />
                        </Col>
                    </Col>
                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Поле объекта</Label>
                        <Col sm="11">
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={dbFieldsOptions}
                                className="js-example-basic-single col-sm-12"
                                value={selectedDbField}
                                onChange={setSelectedDbField}
                            />
                        </Col>
                    </Col>
                </CardBody>
            </CommonModal>
        </div>
    );
};

export default FieldMappingStep;
