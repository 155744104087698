import React, { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Plus } from "react-feather";
import { Container, Input, Row, Label, Nav, NavItem, NavLink, TabContent, TabPane, InputGroup, FormGroup, CardFooter } from "reactstrap";
import { CardBody, CardHeader, Card, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import uuid from "react-uuid";
import { Breadcrumbs, Btn, H6 } from "../../../../AbstractElements";
import DraggableElement from "./DraggableElement";
import axios from "axios";

import { TestingApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const AddTesting = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [testId, setTestId] = useState(queryParams.get("testId"));
	const [pillWithIconTab, setpillWithIconTab] = useState("1");
	const [itemName, setItemName] = useState("Имя теста");

	const [elements, setElements] = useState([]);
	const [selectedElement, setSelectedElement] = useState(null);
	const [newOption, setNewOption] = useState("");

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const fetchData = async () => {
		if (!testId) {
			setTestId(queryParams.get("testId"));
		}
		try {
			const response = await axios.get(`${TestingApi}getTestForm`, {
				params: {
					query: testId,
				},
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			setItemName(concatResults(response).title);
			setElements(
				concatResults(response).questions.map((question) => ({
					id: question.id,
					type: mapQuestionTypeToElementType(question.answerType.typeName, question.answerType.id),
					question: question.text,
					order: question.order,
					required: question.isRequired,
					correct_answer: convertCorrectAnswer(question.answerType.typeName, question.answerOptions),
					max_points: Number(question.points),
					content: question.answerOptions.map((option) => ({ id: option.id, label: option.text })),
				}))
			);
		} catch (error) {
			console.error("Ошибка при выполнении запроса:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const convertCorrectAnswer = (answerType, answerOptions) => {
		if (answerType === "date") {
			const correctDateOption = answerOptions.find((option) => option.isCorrect);
			return correctDateOption ? new Date(correctDateOption.text) : null;
		} else {
			return answerOptions.filter((option) => option.isCorrect).map((option) => option.text);
		}
	};

	const mapQuestionTypeToElementType = (questionType, id) => {
		switch (questionType) {
			case "list":
				return { label: "Список", value: "list", id: id };
			case "radio":
				return { label: "Радио", value: "radio", id: id };
			case "date":
				return { label: "Дата", value: "date", id: id };
			case "input":
				return { label: "Строка", value: "input", id: id };
			default:
				return { label: "Неизвестный тип", value: "unknown" };
		}
	};

	const handleAddElement = () => {
		setElements([
			...elements,
			{
				id: uuid(),
				type: { label: "Строка", value: "input" },
				question: `Новый вопрос ${elements.length + 1}`,
				required: false,
				correct_answer: [],
				max_points: 0,
				order: elements.length + 1,
				content: [
					{ id: 1, label: "Ответ 1" },
					{ id: 2, label: "Ответ 2" },
				],
			},
		]);
	};

	const handleAddOption = () => {
		if (selectedElement && newOption) {
			const updatedElements = [...elements];
			const index = elements.findIndex((element) => element.id === selectedElement);
			updatedElements[index].content.push({ label: newOption, id: updatedElements[index].content.length + 1 });
			setElements(updatedElements);
			setNewOption("");
		} else {
			toast.error("Введите название!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	};

	const handleDeleteOption = (optionIndex, label) => {
		if (selectedElement) {
			const updatedElements = [...elements];
			const index = elements.findIndex((element) => element.id === selectedElement);
			const labelIndex = updatedElements[index]["correct_answer"].indexOf(label);
			updatedElements[index].content.splice(optionIndex, 1);
			updatedElements[index]["correct_answer"].splice(labelIndex, 1);
			setElements(updatedElements);
		}
	};

	const handleCheckboxChange = (item) => {
		const updatedElements = [...elements];
		const index = elements.findIndex((element) => element.id === selectedElement);
		const labelIndex = updatedElements[index]["correct_answer"].indexOf(item);

		if (labelIndex === -1) {
			updatedElements[index]["correct_answer"].push(item);
		} else {
			updatedElements[index]["correct_answer"].splice(labelIndex, 1);
		}
	};

	const handlePropertyChange = (property, value) => {
		const updatedElements = [...elements];
		const index = elements.findIndex((element) => element.id === selectedElement);
		updatedElements[index][property] = value;

		if (property === "type" && updatedElements[index].type.value === "date") {
			updatedElements[index]["correct_answer"] = undefined;
		}
		if (property === "type" && updatedElements[index].type.value === "list") {
			updatedElements[index]["correct_answer"] = [];
		}
		setElements(updatedElements);
	};

	const handleDeleteElement = (index) => {
		const updatedElements = [...elements];
		updatedElements.splice(index, 1);
		setElements(updatedElements);
	};

	const handleMoveElement = (fromIndex, toIndex) => {
		const updatedElements = [...elements];
		const [movedElement] = updatedElements.splice(fromIndex, 1);
		updatedElements.splice(toIndex, 0, movedElement);
		setElements(updatedElements);
	};

	const handleSelectElement = (index) => {
		setSelectedElement(index);
		console.log(elements);
	};

	const performTestFormOperation = (operationType) => {
		if (itemName === null || itemName === "") {
			toast.error("Заполните наименование теста!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			var questionsReq = elements.map((obj) => {
				let answerOptions = [];
				if (operationType === "update") {
					answerOptions =
						obj.type.value === "input" || obj.type.value === "date"
							? obj.correct_answer
								? [
										{
											id: uuid(),
											text: obj.correct_answer instanceof Date ? obj.correct_answer.toISOString() : obj.correct_answer[0],
											isCorrect: true,
										},
								  ]
								: null
							: obj.content.map((item) => ({
									id: uuid(),
									text: item.label,
									isCorrect: obj.correct_answer && obj.correct_answer.includes(item.label),
							  }));
				} else {
					answerOptions =
						obj.type.value === "input" || obj.type.value === "date"
							? obj.correct_answer
								? [
										{
											id: uuid(),
											text: obj.correct_answer instanceof Date ? obj.correct_answer.toISOString() : obj.correct_answer,
											isCorrect: true,
										},
								  ]
								: null
							: obj.content.map((item) => ({
									id: uuid(),
									text: item.label,
									isCorrect: obj.correct_answer && obj.correct_answer.includes(item.label),
							  }));
				}

				return {
					id: obj.id,
					text: obj.question,
					isRequired: obj.required,
					points: Number(obj.max_points),
					answerType: { id: obj.type.id, typeName: obj.type.value },
					order: obj.order,
					answerOptions: answerOptions,
				};
			});

			const testForm = {
				id: testId,
				title: itemName,
				questions: questionsReq,
			};

			const apiEndpoint = operationType === "update" ? "updateTestForm" : "createTestForm";
			const httpMethod = operationType === "update" ? "put" : "post";

			axios({
				method: httpMethod,
				url: `${TestingApi}${apiEndpoint}`,
				data: testForm,
				headers: {
					Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
					"Content-Type": "application/json",
				},
			})
				.then((response) => {
					const successMessage = operationType === "update" ? "Тест обновлен!" : "Тест добавлен!";
					toast.success(successMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const createTestForm = () => {
		performTestFormOperation("create");
	};

	const updateTestForm = () => {
		performTestFormOperation("update");
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Добавить тестирование или опрос" parent="Тестирование" title="Добавить тестирование или опрос" />
			<Container fluid={true}>
				<Row>
					<Col xxl="4" sm="12 box-col-4">
						<Card>
							<CardHeader className="pb-0">
								<Nav className="nav-primary" tabs>
									<NavItem>
										<NavLink href="#" className={pillWithIconTab === "1" ? "active" : ""} onClick={() => setpillWithIconTab("1")} style={{ padding: "4px 8px" }}>
											Свойства элемента
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink href="#" className={pillWithIconTab === "2" ? "active" : ""} onClick={() => setpillWithIconTab("2")} style={{ padding: "4px 8px" }}>
											Настройки формы
										</NavLink>
									</NavItem>
								</Nav>
							</CardHeader>
							<CardBody>
								<TabContent activeTab={pillWithIconTab}>
									<TabPane className="fade show" tabId="1">
										{selectedElement && elements ? (
											<div>
												{elements
													.filter((item) => item.id === selectedElement)
													.map((selectedItem) => (
														<React.Fragment key={selectedItem.id}>
															<Col md="11" className={`mb-2`}>
																<Label className="col-sm-9 col-form-label">Название вопроса</Label>
																<Input
																	className="form-control"
																	type="text"
																	value={selectedItem.question}
																	onChange={(e) => handlePropertyChange("question", e.target.value)}
																/>
															</Col>
															<Col md="11" className="checkbox checkbox-primary ms-1 mb-2">
																<Input
																	id={`checkbox-primary-required`}
																	type="checkbox"
																	checked={selectedItem.required}
																	onChange={(e) => handlePropertyChange("required", e.target.checked)}
																/>
																<Label for={`checkbox-primary-required`}>Обязательный вопрос</Label>
															</Col>
															<Col md="11" className={`mb-2`}>
																<Label className="col-sm-9 col-form-label">Тип ответа</Label>
																<Col sm="12">
																	<Select
																		placeholder="Выберите..."
																		value={selectedItem.type}
																		options={[
																			{ label: "Строка", value: "input" },
																			{ label: "Дата", value: "date" },
																			{ label: "Список с несколькими вариантами ответа", value: "list" },
																			{ label: "Список с одним вариантом ответа", value: "radio" },
																		]}
																		className="js-example-basic-single col-sm-12"
																		onChange={(selected) => handlePropertyChange("type", selected)}
																	/>
																</Col>
															</Col>
															<H6 attrH6={{ className: "b-b-light pb-2 pt-2 mt-4" }}>Настройки ответа</H6>
															{selectedItem.type.value === "input" && (
																<Col md="11" className={`mb-2`}>
																	<Label className="col-sm-9 col-form-label">Правильный ответ</Label>
																	<Input
																		className="form-control"
																		type="text"
																		value={selectedItem.correct_answer}
																		onChange={(e) => handlePropertyChange("correct_answer", e.target.value)}
																	/>
																</Col>
															)}
															{selectedItem.type.value === "list" && (
																<>
																	<p className="font-primary">
																		<i className="fa fa-chevron-down"></i> Выберите правильные ответы
																	</p>
																	{selectedItem.content.map((item, optionIndex) => (
																		<div className="col-md-11" style={{ display: "flex", justifyContent: "space-between" }} key={item.id}>
																			<div className="checkbox checkbox-primary ms-2">
																				<Input
																					id={`checkbox-${optionIndex}`}
																					type="checkbox"
																					checked={selectedItem.correct_answer.includes(item.label)}
																					onChange={() => handleCheckboxChange(item.label)}
																				/>
																				<Label for={`checkbox-${optionIndex}`}>{item.label}</Label>
																			</div>
																			<Btn
																				attrBtn={{
																					onClick: () => handleDeleteOption(optionIndex, item.label),
																					color: "danger",
																					outline: true,
																					size: "sm",
																					className: "align-self-center",
																				}}
																			>
																				Удалить
																			</Btn>
																		</div>
																	))}
																	<Col md="11" className={`mb-2 mt-2`}>
																		<Label className="col-sm-9 col-form-label">Добавить новый вариант</Label>
																		<InputGroup>
																			<Input className="form-control" value={newOption} onChange={(e) => setNewOption(e.target.value)} />
																			<Btn attrBtn={{ color: "info", onClick: handleAddOption }}>Добавить</Btn>
																		</InputGroup>
																	</Col>
																</>
															)}
															{selectedItem.type.value === "radio" && (
																<>
																	<p className="font-primary">
																		<i className="fa fa-chevron-down"></i> Выберите правильные ответы
																	</p>
																	<FormGroup className="custom-radio-ml">
																		{selectedItem.content.map((item, optionIndex) => (
																			<div className="col-md-11" style={{ display: "flex", justifyContent: "space-between" }} key={item.id}>
																				<div className="radio radio-primary mt-3 ms-3">
																					<Input
																						id={`radio-${item.id}`}
																						type="radio"
																						name="radio1"
																						value={item.label}
																						checked={selectedItem.correct_answer.includes(item.label)}
																						onChange={(e) => handlePropertyChange("correct_answer", e.target.value)}
																					/>
																					<Label for={`radio-${item.id}`}>{item.label}</Label>
																				</div>
																				<Btn
																					attrBtn={{
																						onClick: () => handleDeleteOption(optionIndex),
																						color: "danger",
																						outline: true,
																						size: "sm",
																						className: "align-self-center",
																					}}
																				>
																					Удалить
																				</Btn>
																			</div>
																		))}
																	</FormGroup>
																	<Col md="11" className={`mb-2 mt-2`}>
																		<Label className="col-sm-9 col-form-label">Добавить новый вариант</Label>
																		<InputGroup>
																			<Input className="form-control" value={newOption} onChange={(e) => setNewOption(e.target.value)} />
																			<Btn attrBtn={{ color: "info", onClick: handleAddOption }}>Добавить</Btn>
																		</InputGroup>
																	</Col>
																</>
															)}
															{selectedItem.type.value === "date" && (
																<Col md="11" className={`mb-2`}>
																	<Label className="col-sm-9 col-form-label">Правильный ответ</Label>
																	<DatePicker
																		dateFormat="dd.MM.yyyy"
																		locale={ru}
																		className="form-control digits"
																		value={selectedItem.correct_answer ? selectedItem.correct_answer : new Date()}
																		selected={selectedItem.correct_answer ? selectedItem.correct_answer : new Date()}
																		onChange={(date) => handlePropertyChange("correct_answer", date)}
																	/>
																</Col>
															)}
															<Col md="11" className={`mb-2`}>
																<Label className="col-sm-9 col-form-label">Количество баллов за правильный ответ</Label>
																<Input
																	className="form-control"
																	type="number"
																	value={selectedItem.max_points}
																	onChange={(e) => handlePropertyChange("max_points", e.target.value)}
																/>
															</Col>
														</React.Fragment>
													))}
											</div>
										) : (
											"Выберите элемент для просмотра информации о нем"
										)}
									</TabPane>
									<TabPane tabId="2">
										<Col md="11" className={`mb-2`}>
											<Label className="col-sm-9 col-form-label">ID теста</Label>
											<Input className="form-control" type="text" value={testId} disabled />
										</Col>
										<Col md="11" className={`mb-2`}>
											<Label className="col-sm-9 col-form-label">Название теста</Label>
											<Input className="form-control" type="text" value={itemName} onChange={(e) => setItemName(e.target.value)} />
										</Col>
									</TabPane>
								</TabContent>
							</CardBody>
							<CardFooter>
								<div className="modal-footer">
									<Btn attrBtn={{ color: "primary", onClick: () => (testId === null ? createTestForm() : updateTestForm()) }}>Сохранить </Btn>
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col xxl="8" sm="12 box-col-8">
						<Card>
							<CardHeader style={{ justifyContent: "space-between", display: "flex" }}>
								<h5 style={{ display: "inline-block" }}>{itemName} (предпросмотр)</h5>
								<Btn attrBtn={{ color: "primary", onClick: handleAddElement }}>
									<Plus style={{ margin: "0 2px -7px 0px" }} /> Добавить
								</Btn>
							</CardHeader>
							<CardBody>
								<DndProvider backend={HTML5Backend}>
									<Col>
										{elements.length ? (
											elements.map((element, index) => (
												<DraggableElement
													id={element.id}
													selectedElement={selectedElement}
													required={element.required}
													name={element.question}
													key={index}
													content={element.content}
													element={element}
													index={index}
													moveElement={handleMoveElement}
													selectElement={handleSelectElement}
													deleteElement={handleDeleteElement}
												/>
											))
										) : (
											<>
												Элементы теста отсутствуют.{" "}
												<span className="font-primary" style={{ cursor: "pointer" }} onClick={handleAddElement}>
													Добавьте элемент
												</span>
											</>
										)}
									</Col>
								</DndProvider>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default AddTesting;
