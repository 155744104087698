import React, { useEffect, useState } from 'react';
import { Card, CardBody, Input } from 'reactstrap';
import axios from 'axios';
import { Send, FilePlus, Edit, Edit3 } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import { Btn, Image, Ribbon } from '../../../../AbstractElements';
import { Col, Row } from 'reactstrap';
import { EducationApi, TwinAuthApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CommonModal from '../../../UiKits/Modals/common/modal';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const StudentsResponsesCard = ({ answer }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const getTimeDifference = (specifiedTime) => {
		const specifiedDate = new Date(specifiedTime);
		const currentTime = new Date();
		const difference = currentTime.getTime() - specifiedDate.getTime();
		const minutesDifference = Math.floor(difference / (1000 * 60));

		if (minutesDifference < 60) {
			return `${minutesDifference} минут${minutesDifference === 1 ? 'а' : minutesDifference > 1 && minutesDifference < 5 ? 'ы' : ''} назад`;
		} else {
			const hoursDifference = Math.floor(minutesDifference / 60);
			if (hoursDifference < 24) {
				return `${hoursDifference} час${hoursDifference === 1 ? '' : 'а'} назад`;
			} else {
				const daysDifference = Math.floor(hoursDifference / 24);
				return `${daysDifference} дней назад`;
			}
		}
	};
	const [file, setFile] = useState(null);
	const [textValue, setTextValue] = useState('');
	const [courseName, setCourseName] = useState('');
	const [lessonName, setLessonName] = useState('');
	const [lessonId, setLessonId] = useState('');
	const [courseId, setCourseId] = useState('');
	const [userName, setUserName] = useState('');
	const [resultComments, setResultComments] = useState([]);
	const [requestExecuted, setRequestExecuted] = useState(false);
	const [commentaryFile, setCommentaryFile] = useState([]);
	const [filesCount, setFilesCount] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [assessment, setAssessment] = useState('');
	const [assessmentObj, setAssessmentObj] = useState();

	const allowedFileTypes = ['.pdf', '.doc', '.txt', '.jpg', '.jpeg', '.png'];

	const validateFileType = (file) => {
		const fileType = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);

		if (allowedFileTypes.includes('.' + fileType.toLowerCase())) {
			return true;
		} else {
			alert('Недопустимый тип файла. Разрешены только файлы с расширением: ' + allowedFileTypes.join(', '));
			return false;
		}
	};

	const handleFileChange = (e) => {
		const files = e.target.files;
		const filesArray = Array.from(files);
		const uploadedFilesInfo = filesArray.map((file) => ({
			id: file.name,
			name: file.name,
			file,
			url: URL.createObjectURL(file),
		}));
		if (uploadedFilesInfo && validateFileType(files[0])) {
			setFile(uploadedFilesInfo[0]);
		}
	};
	const handleTextChange = (e) => {
		setTextValue(e.target.value);
	};

	useEffect(() => {
		if (requestExecuted === false) {
			getResultMessages(answer.id);
			setRequestExecuted(true);
		}
	}, []);

	const sendMsg = () => {
		const data = {
			userId: keycloak.tokenParsed.sub,
			courseLessonStudentResultId: answer.id,
			postDate: new Date(),
			text: textValue,
		};

		return axios
			.post(`${EducationApi}create-lesson-result-comment`, data, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				if (file !== null) {
					let formData = new FormData();
					formData.append('file', file.file);
					formData.append('resultId', concatResults(response));
					formData.append('fileName', file.name);

					axios
						.post(`${EducationApi}create-comment-file/${concatResults(response)}`, formData, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
						.then((responseContent) => {
							toast.success('Комментарий отправлен!', {
								position: toast.POSITION.TOP_RIGHT,
							});
						})
						.catch((error) => {
							toast.error('Произошла ошибка при загрузке файла.', {
								position: toast.POSITION.TOP_RIGHT,
							});
							console.error(error);
						});
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const getResultMessages = (resultId) => {
		let comments = undefined;
		axios
			.get(`${EducationApi}get-result-comments?resultId=${resultId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				comments = concatResults(response).comments;

				if (comments !== undefined) {
					let resultObject = [];

					comments.forEach((item) => {
						getCommentFiles(item.id);
						resultObject.push(item);
					});

					setResultComments(resultObject);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	function getCommentFiles(commentId) {
		axios
			.get(`${EducationApi}get-comment-files-list/${commentId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((commentFilesList) => {
				if (concatResults(commentFilesList).files.length !== 0) {
					concatResults(commentFilesList).files.forEach((element) => {
						axios.get(`${EducationApi}get-comment-file/${element.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, responseType: 'blob' }).then((responseFile) => {
							let content = new File([concatResults(responseFile)], element.fileName, { type: concatResults(responseFile).type });
							let commFileCopy = commentaryFile;

							commFileCopy.push({
								id: element.id,
								commentId: commentId,
								url: URL.createObjectURL(content),
								name: element.fileName,
							});

							setCommentaryFile(commFileCopy);

							setFilesCount(filesCount + 1);
						});
					});
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	const getUserName = (courseUserId) => {
		axios
			.get(`${EducationApi}get-course-student/${courseUserId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				axios
					.get(`${TwinAuthApi}get-user?id=${concatResults(response).userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						let result = JSON.parse(response.data);
						setUserName(`${result.firstName} ${result.lastName}`);
					})
					.catch((error) => {
						console.error(error);
					});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const getResultInfo = (result) => {
		axios
			.get(`${EducationApi}get-course-lesson/${result.lessonId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setLessonName(concatResults(response).name);
				setLessonId(result.lessonId);
				setCourseId(concatResults(response).courseId);
				axios
					.get(`${EducationApi}get-course/${concatResults(response).courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((responseCourse) => {
						setCourseName(responseCourse.data.name);
					})
					.catch((error) => {
						console.error(error);
					});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const toggleOpenModal = () => {
		setOpenModal(!openModal);
		setAssessment('');
	};

	const toggleOpenEditModal = () => {
		if (openEditModal === false) {
			axios
				.get(`${EducationApi}get-result-assessment/${answer.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					console.log(response);
					setAssessmentObj(concatResults(response));
					setAssessment(concatResults(response).assessment);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			setAssessmentObj(null);
		}
		setOpenEditModal(!openEditModal);
	};

	const CreateStudentAssessment = () => {
		axios
			.post(
				`${EducationApi}create-student-assessment`,
				{
					courseStudentId: answer.courseStudentId,
					courseLessonId: lessonId,
					courseLessonStudentResultId: answer.id,
					assessment: assessment,
				},
				{ headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } }
			)
			.then((responseContent) => {
				toast.success('Оценка успешно выставлена.', {
					position: toast.POSITION.TOP_RIGHT,
				});
				axios
					.put(
						`${EducationApi}update-lesson-result`,
						{
							id: assessment.id,
							CourseStudentId: answer.courseStudentId,
							LessonId: answer.lessonId,
							CreateDate: answer.createDate,
							Status: 'checked',
							Text: answer.text,
						},
						{ headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } }
					)
					.then((responseContent) => {
						toggleOpenModal();
					})
					.catch((error) => {
						toast.error('Произошла ошибка при обновлении статуса результата.', {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error(error);
					});
			})
			.catch((error) => {
				toast.error('Произошла ошибка при выставлении оценки.', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error(error);
			});
	};

	const UpdateStudentAssessment = () => {
		axios
			.put(
				`${EducationApi}update-student-assessment`,
				{
					id: assessmentObj.id,
					courseStudentId: assessmentObj.courseStudentId,
					courseLessonId: assessmentObj.courseLessonId,
					courseLessonStudentResultId: assessmentObj.courseLessonStudentResultId,
					assessment: assessment,
				},
				{ headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } }
			)
			.then((responseContent) => {
				toast.success('Оценка успешно изменена.', {
					position: toast.POSITION.TOP_RIGHT,
				});
				toggleOpenEditModal();
			})
			.catch((error) => {
				toast.error('Произошла ошибка при выставлении оценки.', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error(error);
			});
	};

	const downloadFile = (url, name) => {
		const downloadLink = document.createElement('a');

		downloadLink.href = url;
		downloadLink.download = name;

		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	return (
		<>
			<CommonModal isOpen={openModal} title={'Выставить оценку'} toggler={CreateStudentAssessment} togglerClose={toggleOpenModal} saveText="Готово" size="m">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					Оценка:
					<Input className="form-control mt-3 mb-3" type="tel" value={assessment} onChange={(e) => setAssessment(e.target.value)} />
				</CardBody>
			</CommonModal>

			<CommonModal isOpen={openEditModal} title={'Изменить оценку'} toggler={UpdateStudentAssessment} togglerClose={toggleOpenEditModal} saveText="Готово" size="m">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					Оценка:
					<Input className="form-control mt-3 mb-3" type="tel" value={assessment} onChange={(e) => setAssessment(e.target.value)} />
				</CardBody>
			</CommonModal>

			<Card className={`b-l-${answer.status === 'checked' ? 'success' : 'primary'} `}>
				<CardBody>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Image
							attrImage={{
								className: 'img-50 rounded-circle m-r-10',
								src: `https://loremflickr.com/32/32`, // заглушка с рандомными картинками
								alt: '',
							}}
						/>
						<Col>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{getResultInfo(answer)}
								<div className="font-primary f-18">
									<Link to={`${process.env.PUBLIC_URL}/app/build-course?courseId=${courseId}`}>{courseName}</Link> <i className="fa fa-caret-right f-14"></i>
								</div>
								<div className="m-l-10">
									<Link style={{ color: '#898989' }} to={`${process.env.PUBLIC_URL}/app/build-lesson?lessonId=${lessonId}`}>
										{lessonName}
									</Link>
								</div>
							</div>
							<div style={{ display: 'flex', alignItems: 'flex-end' }}>
								<div className="f-w-500 f-14">{getUserName(answer.courseStudentId) ? getUserName(answer.courseStudentId) : 'Имя Фамилия'}</div>
								<div style={{ color: '#898989' }} className="m-l-10">
									{getTimeDifference(answer.createDate)}
								</div>
							</div>
							{answer.status === 'checked' ? (
								<>
									<Ribbon attrRibbons={{ className: 'ribbon ribbon-clip-right ribbon-right ribbon-success' }}>
										<i className="fa fa-check"></i> проверено
									</Ribbon>
									<Btn attrBtn={{ color: 'primary', className: 'f-right', outline: true, size: 'xs', onClick: () => toggleOpenEditModal() }}>
										<Edit />
										Изменить оценку
									</Btn>
								</>
							) : (
								<>
									<Ribbon attrRibbons={{ className: 'ribbon ribbon-clip-right ribbon-right ribbon-primary' }}>
										<i className="fa fa-clock-o"></i> ожидает проверки
									</Ribbon>
									<Btn attrBtn={{ color: 'info', className: 'pe-3 ps-3 mt-2', size: 'sm', onClick: () => toggleOpenModal() }}>
										<Edit3 size={'16px'} style={{ margin: '0 3px -3px 0' }} /> Поставить оценку
									</Btn>
								</>
							)}
						</Col>
					</div>
					<Row className="pe-4 ps-4" style={answer.userId === keycloak.tokenParsed.sub ? { justifyContent: 'end' } : {}}>
						<div
							key={answer.id}
							style={{
								width: 'max-content',
							}}
							className={answer.userId !== keycloak.tokenParsed.sub ? 'light-card balance-card mt-4 speech' : 'light-card balance-card mt-4 speech-2'}
						>
							<p>{answer.text}</p>
							{/*value.file && <img src={value.file.url} alt={value.file.name} style={{ maxWidth: '300px' }} />*/}
						</div>
					</Row>
					{resultComments.map((item) => {
						let fileObj = commentaryFile.find((obj) => {
							return obj.commentId === item.id;
						});

						return (
							<Row className="pe-4 ps-4" style={item.userId === keycloak.tokenParsed.sub ? { justifyContent: 'end' } : {}}>
								<div
									key={item.id}
									className={item.userId !== keycloak.tokenParsed.sub ? 'light-card balance-card mt-4 speech pe-4 ps-4' : 'light-card balance-card mt-4 speech-2 pe-4 ps-4'}
									style={{
										width: 'max-content',
									}}
								>
									<p>{item.text}</p>
									{fileObj && (
										// <img src={fileObj.url} alt={fileObj.name} style={{ maxWidth: '300px' }} />
										<Tooltip title="Скачать" arrow placement="top">
											<p className="font-primary" style={{ cursor: 'pointer' }} onClick={() => downloadFile(fileObj.url, fileObj.name)}>
												<i className="fa fa-file-photo-o me-2"></i>
												{fileObj.name}
											</p>
										</Tooltip>
									)}
								</div>
							</Row>
						);
					})}
					<Row style={{ alignItems: 'baseline' }}>
						<Col md="10" style={{ position: 'relative' }}>
							<Input className="mt-2" placeholder="Добавить комментарий к ответу" value={textValue} onChange={handleTextChange} />
						</Col>
						<Col md="2" style={{ display: 'flex' }}>
							<div className="font-primary" style={{ position: 'absolute', left: '-50px', top: ' 3px', cursor: 'pointer' }}>
								<FilePlus size={'26px'} style={{ cursor: 'pointer' }} />
								<input type="file" onChange={handleFileChange} accept=".pdf,.doc,.txt,.jpg,.jpeg,.png" style={{ opacity: '0', position: 'absolute', top: '0px', left: '0' }} />
							</div>
							<Btn attrBtn={{ color: 'primary', onClick: () => sendMsg() }}>
								Отправить <Send id="send-icon" size={'18px'} style={{ margin: '0 0 -3px 3px' }} />
							</Btn>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</>
	);
};
export default StudentsResponsesCard;
