import React, { useMemo, useState, useEffect } from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/ru';
import { Link } from 'react-router-dom';
import { Form, Label, Input } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Card, CardHeader, Collapse } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Plus, Clock, Minus, XCircle } from 'react-feather';

import Commen from '../../Forms/FormWidget/FormTouchspin/Commen';
import CommonModal from '../../UiKits/Modals/common/modal';
import { H5, H6 } from '../../../AbstractElements';
import { Btn } from '../../../AbstractElements';
import { CalendarApi, TwinAuthApi } from '../../../api';
import uuid from 'react-uuid';
import LoyaltyPayment from './LoyaltyPayment';

import { DemandsApi } from '../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCalendarContext } from '../../../_helper/Calendar/CalendarProvider';
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../concatResults';

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const SigningUpCalendar = (calendarType) => {
	const [services, setServices] = useState([]);
	const [resources, setResources] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [allEmployees, setAllEmployees] = useState([]);
	const [clients, setClients] = useState([]);
	const [myEvents, setEvents] = useState([]);
	const [allEvents, setAllEvents] = useState(null);
	const [demandForm, setDemandForm] = useState([]);
	const [StartDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	const {
		calendarData,
		calendarEventsData,
		meetUpEventsData,
		createDemandEvent,
		getCalendarEventsData,
		getCalendarMeetUpEventsData,
	} = useCalendarContext();

	useEffect(() => {
		if (calendarType.calendarType === 'demands') {
			console.log(1);
			console.log(calendarEventsData);
			calendarEventsData && setEvents(calendarEventsData.map((option) => ({
				id: option.id,
				title: option.title,
				start: new Date(option.startDateTime),
				end: new Date(option.endDateTime),
			})));
		}
		else {
			setEvents([]);
		}
		/*calendarEventsData && setEvents(calendarEventsData.map((option) => ({
			id: option.id,
			title: option.title,
			start: new Date(option.startDateTime),
			end: new Date(option.endDateTime),
		})));*/
	}, [calendarEventsData]);

	useEffect(() => {
		axios
			.get(`${DemandsApi}getDemandForms`, {
				params: {
					query: '4ab9c155-261e-46d8-93b5-e030706620b5',
				},
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
			})
			.then((response) => {
				console.log(response);
				setDemandForm(concatResults(response).demandForms[0]);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);


	useEffect(() => {
		axios
			.get(`/api/employeesDemandsForm.json`)
			.then((response) => {
				setAllEmployees(response.data);
				var uniqueServices = {};
				response.data.forEach(function (item) {
					item.services.forEach(function (service) {
						var serviceId = service.service_id;

						if (!uniqueServices[serviceId]) {
							uniqueServices[serviceId] = service;
						}
					});
				});

				const selectEmpl = response.data.map((option) => ({
					label: option.name,
					value: option.id,
				}));

				var allServices = Object.values(uniqueServices);
				const selectOptions = allServices.map((option) => ({
					label: option.service_name,
					value: option.service_id,
				}));

				setServices(selectOptions);
				setEmployees(selectEmpl);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`/api/resources.json`)
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.name,
					value: option.id,
				}));

				setResources(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [allClients, setAllClients] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setAllClients(JSON.parse(response.data));
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
				}));
				setClients(selectOptions);
			})
			.catch((error) => { 
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const { defaultDate, messages } = useMemo(
		() => ({
			defaultDate: new Date(),
			messages: {
				week: 'Неделя',
				work_week: 'Рабочая неделя',
				day: 'День',
				month: 'Месяц',
				previous: 'Предыдуший',
				next: 'Следующий',
				today: 'Сегодня',
				agenda: 'План',
				date: 'Дата',
				time: 'Время',
				event: 'Событие',
				allDay: 'Весь день',
				yesterday: 'Вчера',
				tomorrow: 'Завтра',
				noEventsInRange: 'В этом диапазоне нет событий.',

				showMore: (total) => `+ ещё ${total}`,
			},
		}),
		[]
	);

	const [pillWithIconTab, setpillWithIconTab] = useState('1');

	const [serviceName, setServiceName] = useState({ label: null, value: null });
	const [selectResource, setSelectResource] = useState({ label: null, value: null });
	const [selectEmployee, setSelectEmployee] = useState({ label: null, value: null });
	const [selectClient, setSelectClient] = useState({ label: null, value: null });
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');

	const [statusId, setStatusId] = useState('');

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const [selectedSlot, setSelectedSlot] = useState(null);

	const [formValues, setFormValues] = useState({});

	const handleChange = (elementId, value) => {
		setFormValues((prevValues) => ({
			...prevValues,
			[elementId]: value,
		}));
	};

	useEffect(() => {
		meetUpEventsData && setClientsEvent(meetUpEventsData.map((item) => ({id: item.id, name: getUserName(item.userId), phone: item.phoneNumber, email: item.email})));
	}, [meetUpEventsData])

	const getUserName = (userId) => {
		let client = allClients.find((client) => client.id === userId);
		if (!client) {
			return "";
		}
		//${allClients && allClients.find((client) => client.id === item.userId)?.map((opt) => `${opt.firstName} ${opt.lastName}`)}
		return `${client.firstName} ${client.lastName}`
	};

	const handleAddEvents = () => {
		let idDemand = uuid();

		createDemandEvent(calendarData.find((items) => items.calendarType === "demands").id, note, StartDate, endDate, groupEvent, false, `${serviceName.value}`, idDemand,
		clientsEvent.map((option) => ({
			eventId: uuid(),
			userId: option.id,
			phoneNumber: option.phone,
			email: option.email,
		})), selectResource.value, `${selectEmployee.value}`);
//calendarId, title, startTime, endTime, groupEvent = false, allDay = false, service, demandId, meetups, resource = null
		/*const newEvent = {
			id: uuid(),
			employee: selectEmployee.value,
			service: serviceName.value,
			email: email,
			resourses: selectResource.value,
			date: StartDate,
			start_time: StartDate,
			end_time: endDate,
			note: note,
			day_schedule: daySchedule,
			schedule_completion: scheduleCompletion,
			is_send_notifications: isSendNotifications,
		};*/



		console.log(name, selectedSlot, StartDate);

		if (name && selectedSlot) {
			let newEvent = {};
			if (selectedSlot) {
				newEvent = {
					title: name,
					start: selectedSlot.start,
					end: selectedSlot.end,
				};
			} else if (StartDate) {
				newEvent = {
					title: name,
					start: StartDate,
					end: StartDate.getHours() + 1,
				};
			}

			setEvents((prevEvents) => [...prevEvents, newEvent]);
			setModal(false);
			// setServiceName('');
		}
		//console.log(newEvent);

		let reqParameters = [];
		demandForm.menuItems.map((item, index) => {
			item.parameters.map((parameter, index) => {
				var val = (parameter.type === 'select') ? formValues[parameter.elementId] : document.getElementById(parameter.elementId).value
				reqParameters.push({
					Value: JSON.stringify(val),
					IdDemand: idDemand,
					IdParameterHB: parameter.id
				});
				console.log(document.getElementById(`${parameter.elementId}`));
			})
		})
		var demand = {
			id: idDemand,
			formid: "4ab9c155-261e-46d8-93b5-e030706620b5",
			parameters: reqParameters
		}

		console.log(demand);
		console.log("Форма групповой заявки")
		axios
			.post(`${DemandsApi}createDemand`, demand, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				console.log(concatResults(response));
				toast.success('Событие добавлено!', {
					position: toast.POSITION.TOP_RIGHT,
				});
				setModal(!modal);
			})
			.catch((error) => {
				console.error(error);
			});

	};

	const handleDelEvents = () => { };

	useEffect(() => {
		const foundObject = allEmployees.find((item) => item.id === selectEmployee?.value);

		if (foundObject) {
			const selectOptions = foundObject.services.map((option) => ({
				label: option.service_name,
				value: option.service_id,
			}));
			setServices(selectOptions);
		}
	}, [selectEmployee]);

	const [note, setNote] = useState('');
	const [applicationId, setApplicationId] = useState('');
	const [groupEvent, setGroupEvent] = useState(false);
	const [edit, setEdit] = useState(false);

	const handleSelectSlot = ({ start = new Date(), end = new Date() }) => {
		//add
		setApplicationId(uuid());
		setSelectedSlot({ start, end });
		setStartDate(start);
		setEndDate(end);
		setModal(true);
		setEdit(false);

		setSelectEmployee({ label: null, value: null });
		setServiceName({ label: null, value: null });
		setSelectResource({ label: null, value: null });
		setVal(1);
		setNote('');
		setClientsEvent([]);
		setGroupEvent(false);
		setScheduleCompletion(new Date());
		setIsSendNotifications(false);
		setDaySchedule([]);
	};

	const [clientsEvent, setClientsEvent] = useState([]);
	const [daySchedule, setDaySchedule] = useState([]);

	const handleSelectEvent = (event) => {
		//edit
		const foundObject = calendarEventsData.find((item) => item.id === event.id);
		setApplicationId(event.id);

		if (foundObject) {
			setEdit(true);
			console.log(employees);
			setSelectEmployee(employees.find((item) => item?.value == foundObject.employeeId));
			setServiceName(services.find((item) => item?.value == foundObject.service));
			setSelectResource(resources.find((item) => item?.value == foundObject.resource));
			setStartDate(new Date(foundObject.startDateTime));
			setEndDate(new Date(foundObject.endDateTime));
			setNote(foundObject.title);
			//setClientsEvent(foundObject.clients);
			setGroupEvent(foundObject.groupEvent);
			setScheduleCompletion(new Date(foundObject.scheduleCompletion));
			setIsSendNotifications(foundObject.notifications);
			getCalendarMeetUpEventsData(foundObject.id);

			if (!foundObject.day_schedule) {
				setDaySchedule([
					{
						day_of_week: 'Понедельник',
						active: false,
					},
					{
						day_of_week: 'Вторник',
						active: false,
					},
					{
						day_of_week: 'Среда',
						active: false,
					},
					{
						day_of_week: 'Четверг',
						active: false,
					},
					{
						day_of_week: 'Пятница',
						active: false,
					},
				]);
			} else {
				setDaySchedule(foundObject.day_schedule);
			}
		}
		setModal(true);
	};

	function formatHeaderDate(date) {
		const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();
		const hours = date.getHours();
		const minutes = date.getMinutes();

		const formattedDate = `${day} ${month} ${year}г., ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

		return formattedDate;
	}

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getDate();
		const month = dateObject.getMonth() + 1;
		const year = dateObject.getFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	const [val, setVal] = useState(1);

	const [isValidEmail, setIsValidEmail] = useState(true);

	const handleInputChange = (e) => {
		const inputValue = e.target.value;

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isValid = emailRegex.test(inputValue);

		setEmail(inputValue);
		setIsValidEmail(isValid);
	};

	const [scheduleCompletion, setScheduleCompletion] = useState(new Date());
	const [clientsFromcurrentEvent, setClientsFromcurrentEvent] = useState(false);
	const [loyalty, setLoyalty] = useState('');
	const [isSendNotifications, setIsSendNotifications] = useState(false);

	const [isOpen, setIsOpen] = useState(1);
	const toggleClientCard = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

	useEffect(() => {
		const foundObject = allClients.find((item) => item.id === selectClient.value);
		if (foundObject) {
			setEmail(foundObject.email);
			setPhone(foundObject.phone);
		}
	}, [selectClient]);

	const handleTimeChange = (index, field, value) => {
		const updatedDaySchedule = [...daySchedule];
		updatedDaySchedule[index][field] = value.toISOString(); // Convert date to ISO string
		setDaySchedule(updatedDaySchedule);
		console.log(updatedDaySchedule);
	};

	const handleCheckboxChange = (index) => {
		const updatedDaySchedule = [...daySchedule];
		updatedDaySchedule[index].active = !updatedDaySchedule[index].active;
		setDaySchedule(updatedDaySchedule);
	};

	const addClient = () => {
		const newClient = { id: selectClient.value, name: selectClient.label, phone: phone, email: email };

		if (clientsEvent.length < val) {
			if (!clientsEvent.some((item) => JSON.stringify(item) === JSON.stringify(newClient))) {
				clientsEvent.push(newClient);
			} else {
				toast.error('Клиент уже добавлен!', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else {
			toast.error('Все места заняты!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		}

		console.log(clientsEvent);
	};

	return (
		<>
			<Btn attrBtn={{ color: 'primary', onClick: handleSelectSlot, className: 'mb-4' }}>
				<Plus style={{ margin: '0 2px -7px 0px' }} /> Добавить запись
			</Btn>
			<CommonModal isOpen={modal} title={edit ? `Редактировать запись` : `Добавить запись`} toggler={handleAddEvents} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="xl">
				<CardBody style={{ padding: '10px', paddingLeft: '15px', paddingTop: '0' }}>
					<Row>
						<Col sm="3" xs="12">
							<Nav className="nav flex-column nav-pills">
								<NavItem>
									<NavLink className={pillWithIconTab === '1' ? 'active' : ''} onClick={() => setpillWithIconTab('1')} style={{ cursor: 'pointer' }}>
										<i className="fa fa-edit"></i>
										Текущее событие
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={pillWithIconTab === '2' ? 'active' : ''} onClick={() => setpillWithIconTab('2')} style={{ cursor: 'pointer' }}>
										<i className="fa fa-users"></i>
										Участники{' '}
										{groupEvent && (
											<div style={{ display: 'inline-block' }}>
												({clientsEvent.length}/{val})
											</div>
										)}
									</NavLink>
								</NavItem>
								{serviceName && serviceName.value === 'DeleiveryID' && (
									<NavItem>
										<NavLink className={pillWithIconTab === '7' ? 'active' : ''} onClick={() => setpillWithIconTab('7')} style={{ cursor: 'pointer' }}>
											<i className="fa fa-clock-o"></i>
											Статус заказа
										</NavLink>
									</NavItem>
								)}
								{groupEvent && (
									<NavItem>
										<NavLink className={pillWithIconTab === '3' ? 'active' : ''} onClick={() => setpillWithIconTab('3')} style={{ cursor: 'pointer' }}>
											<i className="fa fa-calendar"></i>
											Расписание
										</NavLink>
									</NavItem>
								)}
								<NavItem>
									<NavLink className={pillWithIconTab === '6' ? 'active' : ''} onClick={() => setpillWithIconTab('6')} style={{ cursor: 'pointer' }}>
										<i className="fa fa-money"></i>
										Оплата
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={pillWithIconTab === '4' ? 'active' : ''} onClick={() => setpillWithIconTab('4')} style={{ cursor: 'pointer' }}>
										<i className="fa fa-info"></i>
										Инструкции
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={pillWithIconTab === '5' ? 'active' : ''} onClick={() => setpillWithIconTab('5')} style={{ cursor: 'pointer' }}>
										<i className="fa fa-bell-o"></i>
										Уведомления
									</NavLink>
								</NavItem>
							</Nav>
						</Col>
						<Col sm="9" xs="12">
							<TabContent activeTab={pillWithIconTab}>
								<TabPane className="fade show" tabId="1">
									<Form className="form theme-form">
										<H5 attrH5={{ className: 'mb-4' }}>
											{formatHeaderDate(StartDate)} {selectEmployee && selectEmployee.label}
										</H5>
										<Row className="mb-3">
											<Col md="12">
												<Label className="col-sm-9 col-form-label">Сотрудник</Label>
												<Col className="row col-sm-12 ">
													<Select placeholder="Выберите сотрудника" options={employees} className="js-example-basic-single col-sm-12" id="EmployeeID" value={selectEmployee} onChange={(option) => { setSelectEmployee(option); handleChange("EmployeeID", option) }} />
												</Col>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col md="6">
												<Label className="col-sm-9 col-form-label">Услуга</Label>
												<Col className="row col-sm-12 ">
													<Select placeholder="Выберите услугу" options={services} className="js-example-basic-single col-sm-12" id="ServiceID" value={serviceName} onChange={(option) => { setServiceName(option); handleChange("ServiceID", option) }} />
												</Col>
											</Col>
											<Col md="6">
												<Label className="col-sm-9 col-form-label">Ресурсы</Label>
												<Col className="row col-sm-12 ">
													<Select placeholder="Выберите ресурс" options={resources} className="js-example-basic-single col-sm-12" id="ResourcesID" value={selectResource} onChange={(option) => { setSelectResource(option); handleChange("ResourcesID", option) }} />
												</Col>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col className=" col-sm-6" md="3">
												<Label className="col-sm-10 col-form-label ">Дата</Label>
												<Col xl="10" sm="9">
													<div className="input-group">
														<DatePicker className="form-control" id="DateID" locale={ru} selected={StartDate} value={formatDate(StartDate)} onChange={(value) => { setStartDate(value); handleChange("DateID", StartDate) }} />
													</div>
												</Col>
											</Col>
											<Col className=" col-sm-6" md="2">
												<Label className="col-sm-10 col-form-label ">Время</Label>
												<Col xl="10" sm="9">
													<DatePicker timeCaption="Время" className="form-control" id="TimeBeginID" selected={StartDate} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="HH:mm" locale={ru} onChange={(value) => { setStartDate(value); /*handleChange("TimeBeginID", document.getElementById("TimeEndID").value)*/ }} />
												</Col>
											</Col>
											<Col md="1" className="p-0">
												<Label className="col-sm-8 col-form-label " style={{ height: '35px' }}>
													{' '}
												</Label>
												<Minus />
											</Col>
											<Col className=" col-sm-6 p-0" md="2">
												<Label className="col-sm-10 col-form-label " style={{ height: '25px' }}>
													{' '}
												</Label>
												<Col xl="10" sm="9">
													<DatePicker timeCaption="Время" className="form-control" id="TimeEndID" selected={endDate} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="HH:mm" locale={ru} onChange={(value) => { setEndDate(value); /*handleChange("TimeEndID", document.getElementById("TimeEndID").value)*/ }} />
												</Col>
											</Col>
										</Row>
										<Row className="">
											<Label>Примечание</Label>
											<Col sm="12">
												<textarea className="form-control" id="NoticeID" rows={'3'} placeholder="" value={note} onChange={(e) => setNote(e.target.value)} />
											</Col>
										</Row>
									</Form>
								</TabPane>
								<TabPane tabId="2">
									<H5 attrH5={{ className: 'mb-4' }}>
										{formatHeaderDate(StartDate)} {selectEmployee && selectEmployee.label}
									</H5>
									{//!serviceName.value === 'DeleiveryID' && (
									}
										<>
											<Row className="mb-3">
												<Col md="12">
													<Label className="col-sm-9 col-form-label">Имя</Label>
													<Col className="row col-sm-12 ">
														<Select placeholder="Выберите клиента" options={clients} className="js-example-basic-single col-sm-12" onChange={(selectedOption) => setSelectClient(selectedOption)} />
													</Col>
												</Col>
											</Row>
											<Row className="mb-3">
												<Col md="6">
													<Label className="col-sm-9 col-form-label">Телефон</Label>
													<Col sm="11">
														<InputMask className="form-control" mask="+7 (999) 999-99-99" maskChar="_" placeholder="+7 (___) ___-__-__" alwaysShowMask value={phone} onChange={(e) => setPhone(e.target.value)} />
													</Col>
												</Col>
												<Col md="6">
													<Label className="col-sm-9 col-form-label">Email</Label>
													<Col sm="11">
														<Input className="form-control" placeholder="Введите Email" value={email} onChange={handleInputChange} />
														{!isValidEmail && <div className="d-block font-danger">Пожалуйста, введите корректный email.</div>}
													</Col>
												</Col>
											</Row>
											<Row className="mb-4">
												<Col md="9">
													<Label className="col-sm-9 col-form-label">Лояльность</Label>
													<Col sm="11">
														<Input className="form-control" placeholder="Введите номер" value={loyalty} onChange={(e) => setLoyalty(e.target.value)} />
													</Col>
												</Col>
												<Col md="2" className="ms-4">
													<Col sm="11">
														<Label className="col-sm-9 col-form-label" style={{ height: '28px' }}></Label>
														<Btn attrBtn={{ color: 'primary', onClick: () => addClient() }}>Записать</Btn>
													</Col>
												</Col>
											</Row>
											<Row className="mt-2 mb-4" style={{ alignItems: 'flex-end' }}>
												<Col md="3" className="checkbox checkbox-primary ">
													<Input id={`checkbox-primary-1`} type="checkbox" checked={groupEvent} onChange={(e) => setGroupEvent(e.target.checked)} />
													<Label for={`checkbox-primary-1`}>Групповое событие</Label>
												</Col>
												{groupEvent ? (
													<Col md="3">
														<Label className="col-sm-9 col-form-label">Вместимость</Label>
														<Commen groupText={true} val={val} setVal={setVal} />
													</Col>
												) : (
													<Col md="3" style={{ height: '74px' }}></Col>
												)}
											</Row>
										</>
									{//)}
									}
									<Card>
										<Row className="mt-4">
											<H6>Участники</H6>
										</Row>
										<Row className="b-r-8 badge-light font-dark p-15 mt-2">
											<Col md="4">Имя</Col>
											<Col md="2">Статус</Col>
											<Col md="2">Статус оплаты</Col>
											<Col md="2">Телефон</Col>
											<Col md="2">Абонемент</Col>
										</Row>
										{clientsEvent?.map((item) => (
											<Card>
												<CardHeader className="ps-0 f-14" onClick={() => toggleClientCard(item.id)} style={{ cursor: 'pointer' }}>
													<Row>
														<Col md="4">
															<H6 attrH6={{ className: 'mb-0' }}>
																{isOpen === item.id ? <i className="fa fa-angle-down me-2"></i> : <i className="fa fa-angle-right me-2"></i>}
																{item.name}
															</H6>
														</Col>
														<Col md="2">
															<Clock size={'16px'} style={{ margin: '0 3px -3px 0', color: '#ababab' }} />
															Ожидание
														</Col>
														<Col md="2" className="">
															<XCircle size={'16px'} style={{ margin: '0 3px -3px 0' }} className="font-danger" />
															Не оплачено
														</Col>
														<Col md="3">{item.phone}</Col>
													</Row>
												</CardHeader>
												{!serviceName.value === 'DeleiveryID' && (
													<Collapse isOpen={isOpen === item.id}>
														<CardBody>
															<Col md="6">
																<Label className="col-sm-9 col-form-label">Статус</Label>
																<Col className="row col-sm-9">
																	<Select placeholder="Выберите статус" options={[{ label: 'Ожидание', value: 'Ожидание' }]} className="js-example-basic-single col-sm-12" />
																</Col>
															</Col>
														</CardBody>
													</Collapse>
												)}
											</Card>
										))}
									</Card>
								</TabPane>
								<TabPane tabId="3">
									<H5 attrH5={{ className: 'mb-4' }}>
										{formatHeaderDate(StartDate)} {serviceName?.label}
									</H5>
									{daySchedule &&
										daySchedule.map((item, index) => (
											<Row>
												<Col md="4">
													<div className="checkbox checkbox-primary">
														<Input id={`checkbox-primary-1-${item.day_of_week}`} type="checkbox" checked={item.active} onChange={() => handleCheckboxChange(index)} /> <Label for={`checkbox-primary-1-${item.day_of_week}`}>{item.day_of_week}</Label>
													</div>
												</Col>
												<Col className=" col-sm-6" md="2">
													<Col xl="10" sm="9">
														<DatePicker disabled={!item.active} timeCaption="Время" className="form-control" selected={item.start_time ? new Date(item.start_time) : new Date(0)} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="HH:mm" locale={ru} onChange={(date) => handleTimeChange(index, 'start_time', date)} />
													</Col>
												</Col>
												<Col md="1" className="p-0">
													<Minus />
												</Col>
												<Col className=" col-sm-6 p-0" md="2">
													<Col xl="10" sm="9">
														<DatePicker disabled={!item.active} timeCaption="Время" className="form-control" selected={item.end_time ? new Date(item.end_time) : new Date(0)} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="HH:mm" locale={ru} onChange={(date) => handleTimeChange(index, 'end_time', date)} />
													</Col>
												</Col>
											</Row>
										))}
									<Card className="mt-3">
										<Row className="mt-4">
											<Label className="col-sm-4 col-form-label ">Дата завершения расписания</Label>
											<Col sm="6">
												<div className="input-group">
													<DatePicker className="form-control" locale={ru} selected={scheduleCompletion} value={formatDate(scheduleCompletion)} onChange={(selectedOption) => setScheduleCompletion(selectedOption)} />
												</div>
											</Col>
										</Row>
										<Row className="mt-2">
											<div className="checkbox checkbox-primary">
												<Input id={`checkbox-primary-1`} type="checkbox" checked={clientsFromcurrentEvent} onChange={(e) => setClientsFromcurrentEvent(e.target.checked)} />
												<Label for={`checkbox-primary-1`}>
													Добавить клиентов из текущего события{' '}
													<div style={{ display: 'inline-block' }}>
														({clientsEvent?.length}/{val})
													</div>
												</Label>
											</div>
										</Row>
									</Card>
								</TabPane>
								<TabPane tabId="4">
									<Row style={{ backgroundColor: '#7366ff33' }} className="font-primary col-sm-11 mb-4 p-10 b-r-8">
										текст текст текст текст текст текст текст текст текст текст
									</Row>
									<Col md="12">
										<Label className="col-sm-9 col-form-label">Ссылка на онлайн событие</Label>
										<Col sm="11">
											<Input className="form-control" placeholder="Введите ссылку" value={loyalty} onChange={(e) => setLoyalty(e.target.value)} />
										</Col>
									</Col>
									<Col md="12">
										<Label className="col-sm-9 col-form-label">Инструкции</Label>
										<Col sm="11">
											<textarea className="form-control" rows={'3'} placeholder="" />
										</Col>
									</Col>
								</TabPane>
								<TabPane tabId="5">
									<H5 attrH5={{ className: 'mb-4' }}>Подтверждение всех записей</H5>
									{!isSendNotifications ? (
										<Col>
											<Btn attrBtn={{ color: 'primary', onClick: () => setIsSendNotifications(true) }}>Отправить уведомления, подтверждающие все записи на событие</Btn>
										</Col>
									) : (
										<Col>
											<Label className="col-sm-12 col-form-label">Уведомления, подтверждающие записи были отправлены </Label>
											<Btn attrBtn={{ color: 'primary' }}>Отправить повторно</Btn>
										</Col>
									)}
								</TabPane>
								<TabPane tabId="6">
									<H5 attrH5={{ className: 'mb-4' }}>Оплата визита</H5>
									{applicationId && <LoyaltyPayment applicationId={applicationId} />}
								</TabPane>
								<TabPane tabId="7">
									<H5 attrH5={{ className: 'mb-4' }}>Статус заказа</H5>
									<Col md="12">
										<Label className="col-sm-9 col-form-label">Статус</Label>
										<Col className="row col-sm-12">
											<Select
												placeholder="Выберите статус"
												options={[
													{ label: 'Принят', value: 'Принят' },
													{ label: 'В обработке', value: 'В обработке' },
													{ label: 'В пути', value: 'В пути' },
													{ label: 'Доставлен', value: 'Доставлен' },
												]}
												className="js-example-basic-single col-sm-12"
											/>
											<div className="mt-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
												<span style={{ color: '#59667a' }}>Последнее обновление: Имя Фамилия в 00:00</span>
												<Link to={`${process.env.PUBLIC_URL}/app/calendar/personal-calendar/status-history?applicationId=${applicationId}`} style={{ letterSpacing: 'normal' }}>
													<Clock size={'16px'} style={{ margin: '0 1px -3px 0' }} /> История статусов
												</Link>
											</div>
										</Col>
									</Col>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</CardBody>
			</CommonModal>
			<Calendar onSelectEvent={handleSelectEvent} onSelectSlot={handleSelectSlot} messages={messages} localizer={localizer} scrollToTime={new Date(1970, 1, 1, 6)} views={allViews} events={myEvents ?? []} eventOverlap dragRevertDuration={500} dragScroll droppable={true} showMultiDayTimes step={60} startAccessor="start" endAccessor="end" selectable />
		</>
	);
};
export default SigningUpCalendar;
