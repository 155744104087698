import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Container, InputGroup, InputGroupText } from "reactstrap";
import { useLocation } from "react-router";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import Select from "react-select";
// import Ckeditor from "react-ckeditor-component";

import { Label, Input, Col, Row } from "reactstrap";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { TwinAuthApi } from "../../../../api";
import {useDocumentManagementContext} from "../../../../_helper/DocumentManagement/DocumentManagementProvider";

const AddTemplate = () => {
	const { keycloak, initialized } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { documentTemplateData, getDocumentTemplate, createDocumentTemplate, updateDocumentTemplate } = useDocumentManagementContext()

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const templateId = queryParams.get("templateId");

	const [name, setName] = useState("");
	const [content, setContent] = useState("");

	const handleChange = (evt) => {
		setContent(evt.editor.getData());
	};

	const [id, setId] = useState("");

	useEffect(() => {
		companyData && templateId && getDocumentTemplate(templateId);
		/*axios
			.get("/api/document-templates.json")
			.then((response) => {
				const foundItem = response.data.templates.find((item) => item.id === templateId);
				console.log(foundItem);
				if (foundItem) {
					setName(foundItem.name);
					setContent(foundItem.content);
					setId(foundItem.id);
				}
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});*/
	}, [templateId, companyData]);

	useEffect(() => {
		console.log(documentTemplateData);
		setName(documentTemplateData?.name);
		setContent(documentTemplateData?.content && JSON.parse(documentTemplateData?.content));
		setId(documentTemplateData?.id);
	}, [documentTemplateData]);

	const send = () => {
		if (templateId) {
			updateDocumentTemplate(templateId, JSON.stringify(content), keycloak.tokenParsed?.sub, companyData?.id);
		} else {
			createDocumentTemplate(name, JSON.stringify(content), keycloak.tokenParsed?.sub, companyData?.id);
		}

		//console.log(JSON.stringify(content));
		// axios
		// 	.post(`/api/clientsDemandsForm.json`, newEvent)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Календарь добавлен!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
	};

	const handleSubmit = (files, allFiles) => {
		// axios
		// 	.post(`${NewsApi}createNews`, newServer, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Логотип установлен!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error('Ошибка получения данных:', error);
		// 	});
		allFiles.forEach((f) => f.remove());
	};

	const getUploadParams = ({ file, meta }) => {
		if (file.size > 2 * 1024 * 1024) {
			toast.error("Максимальный размер файла 2 Мб!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			return { abort: true };
		}

		return { url: "https://httpbin.org/post" };
	};

	const handleChangeStatus = ({ meta, file }, status) => {};

	const [editModal, setEditModal] = useState(false);
	const toggle = () => {
		setEditModal(!editModal);
	};

	const paste = () => {
		if (selectedOption === "Company") {
			if (selectCompany) {
				setContent(content + `<span id="company-name">${selectCompany.label}</span>`);
				setEditModal(false);
			} else {
				toast.error("Выберите компанию!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
		if (selectedOption === "Employee") {
			if (selectCompany) {
				setContent(content + `<span id="employee-name">${selectUser.label}</span>`);
				setEditModal(false);
			} else {
				toast.error("Выберите сотрудника!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		}
	};

	const [selectedOption, setSelectedOption] = useState(null);

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};

	const [selectUser, setSelectUser] = useState(null);
	const [allUsersNames, setAllUsersNames] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
					login: option.login,
				}));
				setAllUsersNames(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		if ((initialized, keycloak.authenticated)) {
			axios
				.get(`${TwinAuthApi}get-user-groups-companies/${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					const selectOptions = JSON.parse(response.data).map((option) => ({
						label: option.name,
						value: option.id,
					}));
					setAllCompany(selectOptions);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
	}, []);

	const [selectCompany, setSelectCompany] = useState(null);
	const [allCompany, setAllCompany] = useState([]);

	return (
		<Fragment>
			<Breadcrumbs mainTitle={name ? `Редактировать шаблон` : `Добавить шаблон`} parent="Документоборот" title={name ? `Редактировать шаблон` : `Добавить шаблон`} />
			<Container fluid={true}>
				<Card>
					<CardBody className="m-25 pt-2">
						<Row style={{ alignItems: "end" }}>
							<Col className="row col-sm-9 mb-2">
								<Label className="col-sm-9 col-form-label">Название документа</Label>
								<Col sm="12">
									<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
								</Col>
							</Col>
							<Col className="row col-sm-3 mb-2">
								<Btn attrBtn={{ color: "info", className: "pb-2 pt-2", onClick: toggle }}>
									<i className="fa fa-file-text-o me-2"></i> Вставить данные
								</Btn>
							</Col>
						</Row>
						<Col className="row col-sm-12 mb-2">
							<Label className="col-sm-9 col-form-label">Содержание</Label>
							<Col sm="12">
								{/* <Ckeditor
									activeclassName="p10"
									content={content}
									events={{
										change: handleChange,
									}}
								/>{" "} */}
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-2 mt-4">
							<Label className="col-sm-9 col-form-label">Вложения</Label>
							<Col sm="12">
								<Dropzone
									getUploadParams={getUploadParams}
									onChangeStatus={handleChangeStatus}
									onSubmit={handleSubmit}
									inputContent="Выбрать файл или перетащить сюда"
									submitButtonContent="Отправить"
								/>
							</Col>
						</Col>
						<Row style={{ alignItems: "end", justifyContent: "space-between" }}>
							<Col className="row col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Автор</Label>
								<Col sm="12">
									<Input className="form-control" type="tel" value={keycloak.tokenParsed.name} disabled />
								</Col>
							</Col>
						</Row>
					</CardBody>
					<CardFooter>
						<div className="modal-footer">
							<Btn attrBtn={{ color: "primary", onClick: send }}>Сохранить изменения</Btn>
						</div>
					</CardFooter>
				</Card>
			</Container>
			<CommonModal isOpen={editModal} title={"Вставить данные"} toggler={paste} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<div className="radio radio-primary  ms-2">
						<Input id={`radio-company`} type="radio" name={`radio`} value="Company" checked={selectedOption === "Company"} onChange={handleOptionChange} />
						<Label for={`radio-company`}>Компания</Label>
					</div>
					<div className="radio radio-primary mt-3 ms-2">
						<Input id={`radio-Employee`} type="radio" name={`radio`} value="Employee" checked={selectedOption === "Employee"} onChange={handleOptionChange} />
						<Label for={`radio-Employee`}>Сотрудник</Label>
					</div>
					<div className="radio radio-primary mt-3 ms-2">
						<Input id={`radio-Counterparty`} type="radio" name={`radio`} value="Counterparty" checked={selectedOption === "Counterparty"} onChange={handleOptionChange} />
						<Label for={`radio-Counterparty`}>Контрагент</Label>
					</div>
					<div className="radio radio-primary mt-3 ms-2 mb-4">
						<Input id={`radio-service`} type="radio" value="Service" checked={selectedOption === "Service"} onChange={handleOptionChange} />
						<Label for={`radio-service`}>Услуга</Label>
					</div>
					{selectedOption === "Employee" && (
						<Col sm="12" className="row col-sm-12 mb-3 mt-4">
							<Label>Сотрудник</Label>
							<Col sm="11">
								<Select
									placeholder="Начните вводить имя пользователя..."
									noOptionsMessage={() => "Нет результатов"}
									options={allUsersNames}
									value={selectUser}
									onChange={(option) => setSelectUser(option)}
									className="js-example-basic-single col-sm-12"
								/>
							</Col>
						</Col>
					)}

					{selectedOption === "Company" && (
						<Col sm="12" className="row col-sm-12 mb-3 mt-4">
							<Label>Компания</Label>
							<Col sm="11">
								<Select
									placeholder="Начните вводить название компании..."
									noOptionsMessage={() => "Нет результатов"}
									options={allCompany}
									value={selectCompany}
									onChange={(option) => setSelectCompany(option)}
									className="js-example-basic-single col-sm-12"
								/>
							</Col>
						</Col>
					)}
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};
export default AddTemplate;
