import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { P } from '../../../../AbstractElements';
import { useLocation } from 'react-router-dom';

import ClientCardContent from './ClientCardContent';
import VisitHistory from './VisitHistory';
import ClientStatistic from './ClientStatistic';
import ViewMessage from './ViewMessage';
import SendMessage from './SendMessage';
import ClientFiles from './ClientFiles';
import Notifications from './Notifications';
import CustomerAccounts from './CustomerAccounts';
import LoyaltyProgram from './LoyaltyProgram';
import ClientEmails from "./ClientEmails";
import ClientPhoneNumbers from "./ClientPhoneNumbers";
import { useClientsContext } from "../../../../_helper/Clients/ClientsProvider";
import { useKeycloak } from "@react-keycloak/web";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import ClientDocumentsContent from "./ClientDocumentsContent";

const ClientCard = () => {
    const [primarycolorTab, setprimarycolorTab] = useState('1');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get('clientId');
    const { cardSettings, getCardSettings } = useClientsContext();
    const { keycloak } = useKeycloak();
    const { companyData, userCompanies } = useCompanyContext();
    const [roles, setRoles] = useState(
        keycloak.tokenParsed ? keycloak.tokenParsed.realm_access?.roles : []
    );


    useEffect(() => {
        companyData && keycloak && getCardSettings();
    }, [companyData, keycloak]);

    return (
        <Fragment>
            <Row>
                <Col sm="3" xs="12">
                    <Nav className="nav flex-column nav-pills">
                        {roles.includes('tasks-api') && <></>}

                        <NavItem>
                            <NavLink
                                style={{ cursor: 'pointer' }}
                                className={primarycolorTab === '1' ? 'active' : ''}
                                onClick={() => setprimarycolorTab('1')}
                            >
                                <i className="fa fa-user"></i> Карточка клиента
                            </NavLink>
                        </NavItem>

                        {cardSettings?.allowUseMultipleEnails && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '11' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('11')}
                                >
                                    <i className="fa fa-list-alt"></i> Дополнительные Email
                                </NavLink>
                            </NavItem>
                        )}

                        {cardSettings?.allowUseMultiplePhoneNumbers && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '12' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('12')}
                                >
                                    <i className="fa fa-list-alt"></i> Дополнительные номера телефона
                                </NavLink>
                            </NavItem>
                        )}

                        {cardSettings?.allowUseDocuments && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '13' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('13')}
                                >
                                    <i className="fa fa-list-alt"></i> Документы клиента
                                </NavLink>
                            </NavItem>
                        )}

                        {roles.includes('services-api') && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '2' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('2')}
                                >
                                    <i className="fa fa-list-alt"></i> История посещений
                                </NavLink>
                            </NavItem>
                        )}

                        {roles.includes('clients-statistics-api') && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '3' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('3')}
                                >
                                    <i className="fa fa-bar-chart-o"></i> Статистика
                                </NavLink>
                            </NavItem>
                        )}

                        {roles.includes('emails-api') && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '4' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('4')}
                                >
                                    <i className="fa fa-envelope"></i> Отправленные сообщения
                                </NavLink>
                            </NavItem>
                        )}

                        {['emails-api', 'sms-api', 'reminder-api'].some(role => roles.includes(role)) && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '5' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('5')}
                                >
                                    <i className="fa fa-paper-plane"></i> Отправить сообщение
                                </NavLink>
                            </NavItem>
                        )}

                        {roles.includes('client-loyalty-api') && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '6' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('6')}
                                >
                                    <i className="fa fa-credit-card"></i> Лояльность
                                </NavLink>
                            </NavItem>
                        )}

                        {roles.includes('client-money-api') && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '7' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('7')}
                                >
                                    <i className="icofont icofont-contacts"></i> Счета клиента
                                </NavLink>
                            </NavItem>
                        )}

                        {roles.includes('telephony-api') && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '8' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('8')}
                                >
                                    <i className="fa fa-phone"></i> История звонков
                                </NavLink>
                            </NavItem>
                        )}

                        {roles.includes('reminder-api') && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '9' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('9')}
                                >
                                    <i className="fa fa-bell-o"></i> Уведомления
                                </NavLink>
                            </NavItem>
                        )}

                        {roles.includes('storage-api') && (
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={primarycolorTab === '10' ? 'active' : ''}
                                    onClick={() => setprimarycolorTab('10')}
                                >
                                    <i className="fa fa-folder-open-o"></i> Файлы
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </Col>
                <Col sm="9" xs="12">
                    <TabContent activeTab={primarycolorTab}>
                        <TabPane className="fade show" tabId="1">
                            <ClientCardContent clientId={clientId} />
                        </TabPane>

                        {cardSettings?.allowUseMultipleEnails && (
                            <TabPane tabId="11">
                                <ClientEmails clientId={clientId} />
                            </TabPane>
                        )}

                        {cardSettings?.allowUseMultiplePhoneNumbers && (
                            <TabPane tabId="12">
                                <ClientPhoneNumbers clientId={clientId} />
                            </TabPane>
                        )}

                        {cardSettings?.allowUseDocuments && (
                            <TabPane tabId="13">
                                <ClientDocumentsContent clientId={clientId} />
                            </TabPane>
                        )}

                        {roles.includes('services-api') && (
                            <TabPane tabId="2">
                                <VisitHistory clientId={clientId} />
                            </TabPane>
                        )}

                        {roles.includes('clients-statistics-api') && (
                            <TabPane tabId="3">
                                <ClientStatistic clientId={clientId} />
                            </TabPane>
                        )}

                        {roles.includes('emails-api') && (
                            <TabPane tabId="4">
                                <ViewMessage clientId={clientId} />
                            </TabPane>
                        )}

                        {['emails-api', 'sms-api', 'reminders-api'].some(role => roles.includes(role)) && (
                            <TabPane tabId="5">
                                <SendMessage clientId={clientId} />
                            </TabPane>
                        )}

                        {roles.includes('client-loyalty-api') && (
                            <TabPane tabId="6">
                                <LoyaltyProgram clientId={clientId} />
                            </TabPane>
                        )}

                        {roles.includes('client-money-api') && (
                            <TabPane tabId="7">
                                <CustomerAccounts clientId={clientId} />
                            </TabPane>
                        )}

                        {roles.includes('telephony-api') && (
                            <TabPane tabId="8">
                                <P attrPara={{ className: 'mb-0 m-t-30' }}>{'Diamant stone encrusted on a silver toned horseshoe semi-precious stones. Iconic style heritage brand outerwear collection lightweight showerproof material full zip fastening adjustable sleeves. Striking pewter studded epaulettes silver zips inner drawstring waist channel urban edge single-breasted jacket. Playful sleepsuit colourful poppers at the neckline and legs easy dressing contrast cuffs to the arms and ankles.'}</P>
                            </TabPane>
                        )}

                        {roles.includes('reminder-api') && (
                            <TabPane tabId="9">
                                <Notifications clientId={clientId} />
                            </TabPane>
                        )}

                        {roles.includes('storage-api') && (
                            <TabPane tabId="10">
                                <ClientFiles clientId={clientId} />
                            </TabPane>
                        )}
                    </TabContent>
                </Col>
            </Row>
        </Fragment>
    );
};
export default ClientCard;
