import SiteContext from '.';
import React, { useEffect, useState, useContext } from "react";
import Context from "./index";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { CmsApi } from "../../api";
import { useCompanyContext } from "../Company/CompanyProvider";
import { concatResults } from "../../concatResults";

const CmsProvider = ({ children, props }) => {

    const context = useCreateCmsContext(props);

    return (
        <SiteContext.Provider
            value={context}
        >
            {children}
        </SiteContext.Provider>
    );


    //    return <Context.Provider value={{ ...props, productItem, getProductItem, symbol }}>{props.children}</Context.Provider>;
};


export function useCmsContext() {
    const context = useContext(SiteContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateCmsContext = function (props) {

    const [productItem, setProductItem] = useState([]);
    const symbol = "₽";
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const companyId = companyData.map((item) => (item.attributes.companyId[0]));

    const [sites, setSites] = useState([]);
    const [sitesOptions, setSitesOptions] = useState([]);

    const getSites = async (id) => {
        axios
            .get(`${CmsApi}getSites`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {

                const responseSites = response.data.sites;
                setSites(responseSites);
                const sitesOptions = responseSites.map(response => ({
                    label: response.name,
                    value: response.id
                }));
                setSitesOptions(sitesOptions);
                return responseSites;
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    //const fetchData = async () => {
    //    try {
    //        const response = await axios.get(`${CmsApi}getTemplates`, {
    //            headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
    //            params: { isPublish: true }
    //        });

    //        const templatesWithImg = concatResults(response).templates.map(template => ({
    //            ...template,
    //            img: null
    //        }));

    //        setProductItem(templatesWithImg);
    //    } catch (error) {
    //        console.error("Ошибка при загрузке шаблонов:", error);
    //    }
    //};

    //const getProductItem = async () => {
    //    fetchData();
    //}


    return {
        sites,
        sitesOptions,

        getSites
    };
}

export default CmsProvider;
