import React from 'react';
import Select from 'react-select';

const StatusFilter = ({ filterValue, onFilterChange, name }) => {
	const handleFilterChange = (e) => {
		const value = e.value;
		console.log(value);
		onFilterChange(value, name);
	};

	return (
		<Select
			options={[
				{ label: 'получен ответ', value: 'получен ответ' },
				{ label: 'занято', value: 'занято' },
				{ label: 'пропущенный', value: 'пропущенный' },
			]}
			value={{ label: filterValue, value: filterValue }}
			onChange={handleFilterChange}
			styles={{ height: '30px' }}
			placeholder="Выберите.."
			noOptionsMessage={() => 'Нет результатов'}
			className=" col-sm-12"
		/>
	);
};

export default StatusFilter;
