import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, CardBody, Col, Card, Input, Label, InputGroup, InputGroupText } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";
import Select from "react-select";
import { Breadcrumbs, H6, P } from "../../../../AbstractElements";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import TableQualifications from "./TableQualifications";
import { TasksPaymentApi } from "../../../../api";
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import { concatResults } from "../../../../concatResults";

const Qualifications = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
	};

	const [qualifications, setQualifications] = useState([]);
	const [units, setUnits] = useState([]);

	const [qualification, setQualification] = useState("");
	const [rate, setRate] = useState("");
	const [period, setPeriod] = useState("");
	const [periodUnit, setPeriodUnit] = useState("");

	const getData = async () => {
		try {
			const qualificationsResponse = await axios.get(`${TasksPaymentApi}getQualifications`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});

			const mappedQualifications = concatResults(qualificationsResponse).qualifications.map(qual => ({
				id: qual.id,
				label: qual.text,
				value: qual.value,
				rate: qual.rate,
				qualification_period: qual.qualificationPeriod,
				qualification_period_unit: qual.qualificationPeriodUnit
			}));
			setQualifications(mappedQualifications);

			const mappedUnits = concatResults(qualificationsResponse).periodUnits.map(unit => ({
				id: unit.id,
				label: unit.text,
				value: unit.value
			}));
			setUnits(mappedUnits);
		}
		catch (error) {
			console.log("Ошибка получения данных");
			console.log(error);
		}
	}

	useEffect(() => {
		getData();
	}, []);

	const createRate = () => {
		const newRate = {
			id: uuid(),
			text: qualification,
			rate: rate,
			qualificationPeriod: period,
			qualificationPeriodUnitId: periodUnit.id,
		};


		console.log("CREATEQUALIFICATION")
		console.log(newRate);

		axios
			.post(`${TasksPaymentApi}createQualification`, newRate, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				//console.log(concatResults(response));
				toast.success('Квалификация добавлена!', {
					position: toast.POSITION.TOP_RIGHT,
				});
				setModal(!modal);
				getData();
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Квалификации" parent="Сотрудники" title="Квалификации" />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardBody className="email-wrap">
							<TableQualifications toggle={toggle} products={qualifications} units={units} />
						</CardBody>
					</Card>
				</Row>
				<CommonModal isOpen={modal} title={"Добавить"} toggler={createRate} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
					<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
						<Row className="mb-2">
							<Col md="11">
								<Label className="col-sm-9 col-form-label">Квалификация</Label>
								<Col sm="12">
									<Input className="form-control" type="text" value={qualification} onChange={(e) => setQualification(e.target.value)} />
								</Col>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col md="4">
								<Label className="col-sm-9 col-form-label">Базовая ставка</Label>
								<Col sm="12">
									<InputGroup>
										<Input className="form-control" type="number" value={rate} onChange={(e) => setRate(e.target.value)} />
										<InputGroupText>{"руб/час"}</InputGroupText>
									</InputGroup>
								</Col>
							</Col>
							<Col md="7">
								<Label className="col-sm-9 col-form-label">Период повышения квалификации</Label>
								<Row>
									<Col sm="5">
										<Input className="form-control" type="number" value={period} onChange={(e) => setPeriod(e.target.value)} />
									</Col>
									<Col sm="7">
										<Select
											placeholder="Выберите..."
											noOptionsMessage={() => "Нет результатов"}
											options={units}
											value={periodUnit}
											onChange={(selectedOption) => setPeriodUnit(selectedOption)}
											className="js-example-basic-single col-sm-12"
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</CardBody>
				</CommonModal>
			</Container>
		</Fragment>
	);
};
export default Qualifications;
