// LeftPanel.js
import React from 'react';
import { Col, Label, Input } from 'reactstrap';

const ElementCheckbox = ({ setIsOverLeftPanel, content = 'Отметить галочкой' }) => {
	return (
		<>
			<div
				className="col-sm-11 mb-3 ms-2"
				draggable="true"
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'checkbox');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Col sm="12">
					<div className="checkbox checkbox-primary">
						<Input id="checkbox-primary-1" type="checkbox" />
						<Label for="checkbox-primary-1">{content}</Label>
					</div>
				</Col>
			</div>
		</>
	);
};

export default ElementCheckbox;
