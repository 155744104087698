import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Container, Row, CardBody, Col, Card, Input, Label, CardFooter, InputGroup, InputGroupText } from "reactstrap";
import axios from "axios";
import ru from "date-fns/locale/ru";
import Tooltip from "@mui/material/Tooltip";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SweetAlert from "sweetalert2";
import { Edit, Plus, Search } from "react-feather";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { TwinAuthApi, AllProjectApi, TaskApi, TasksPaymentApi } from "../../../../api";
import { BeatLoader } from "react-spinners";
import CommonModal from "../../../UiKits/Modals/common/modal";
import TableTask from "./TableTask";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const AccountFormation = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const history = useNavigate();
	const [number, setNumber] = useState("");
	const [theme, setTheme] = useState("");
	const [comment, setComment] = useState("");

	//const [answerable, setAnswerable] = useState(null); // Исполнитель
	const [rate, setRate] = useState(null);
	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());
	const [project, setProject] = useState(null);
	const [disabled, setDisabled] = useState(true);
	const [paymentInfo, setPaymentInfo] = useState({});
	const [bonus, setBonus] = useState(0);

	const [includeTax, setIncludeTax] = useState(false);

	const [allUsersNames, setAllUsersNames] = useState([]);
	const [allProjects, setAllProjects] = useState([]);


	const fetchData = async () => {
		try {
			await axios
				.get(`${TwinAuthApi}get-all-users`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					const selectOptions = JSON.parse(response.data).map((option) => ({
						label: option.firstName + " " + option.lastName,
						value: option.id,
						rate: option.rate,
					}));
					setAllUsersNames(selectOptions);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
			await axios
				.get(`${AllProjectApi}get`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {

					const selectOptions = concatResults(response).projects.map((option) => ({
						label: option.name,
						value: option.id,
					}));

					const defaultProjectOption = { label: "Нет проекта", value: null };
					selectOptions.unshift(defaultProjectOption);
					setProject(defaultProjectOption)
					setAllProjects(selectOptions);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
		catch (error) {

		}
	};

	useEffect(() => {
		fetchData();
	}, []);


	const [tasks, setTasks] = useState([]);
	const [loading, setLoading] = useState(false);
	const [checkedUser, setCheckedUser] = useState(null);

	const setTasksPaymentInfo = async (option) => {
		setDisabled(false);
		setShowTasks(true);
		setLoading(true);

		const infoResponse = await axios.get(`${TasksPaymentApi}getEmployeePaymentInfo/${option.value}`, {
			headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
		});

		setProject({ label: "Нет проекта", value: null });
		const info = concatResults(infoResponse).info;
		setPaymentInfo(info);

		if (info === null) {

			setRate(null);
			setTasks([]);
			setLoading(false);
		}
		else {
			setBonus(info.bonus);
		}
		setCheckedUser(option.value);
	};


	useEffect(() => {
		if (paymentInfo) {
			setProjectInfo(project);
		}
	}, [paymentInfo]);


	const setProjectInfo = async (option) => {

		try {

			const taskResponse = await axios.get(`${TaskApi}getPaymentTasks`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				params: {
					userId: paymentInfo.userId,
					projectId: option.value
				}
			});

			var statuses = [];

			if (option.value) {
				const statusesResponse = await axios.get(`${AllProjectApi}getProjectStatuses?projectId=${option.value}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });

				statuses = concatResults(statusesResponse).statuses.map((option) => ({
					id: option.id,
					name: option.name
				}));

				const selectedProjectRate = paymentInfo.projectRates.find(rate => rate.projectId === option.value);
				setRate(selectedProjectRate.rate);
			}
			else {
				const statusesResponse = await axios.get(`${TaskApi}get-user-statuses`, { params: { UserId: paymentInfo.userId }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
				statuses = concatResults(statusesResponse).statuses.map((option) => ({
					id: option.id,
					name: option.name,
				}));
				setRate(paymentInfo.qualification.rate);
			}

			const mappedTasks = concatResults(taskResponse).tasks
				.map(task => ({
					id: task.id,
					name: task.name,
					creatorId: task.creatorId,
					status: statuses.find(option => option.id === task.status.id),
					spentTime: task.countHours,
					projectId: task.projectId,
					projectName: option.value ? allProjects.find(x => x.value === option.value).label : null
				}));

			setData(mappedTasks)
			setTasks(mappedTasks);
			setLoading(false);
		}
		catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	}



	const deleteTask = (taskId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить эту задачу!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {

				const updatedData = tasks.filter((item) => item.id !== taskId);
				setTasks(updatedData);
			}
		});
	};

	const addTask = (taskId) => {
		// axios
		// 	.post(`${TaskApi}`, {
		// 		headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
		// 	})
		// 	.then((response) => {
		// 		SweetAlert.fire('Удалено!', 'Задача была удалена.', 'success');
		// 		setTasks((prevData) => prevData.filter((item) => item.id !== taskId));
		// 	})
		// 	.catch((error) => {
		// 		console.error('Произошла ошибка при удалении задачи:', error);
		// 	});
	};

	const [modal, setModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
	};

	const [data, setData] = useState([]);
	const [filterText, setFilterText] = useState("");

	const subHeaderComponent = useMemo(() => {
		return (
			<InputGroup className="mb-3">
				<Input className="form-control" type="text" style={{ height: "32px" }} placeholder="Поиск" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
				<InputGroupText className="bg-primary">
					<Search size={"20px"} />
				</InputGroupText>
			</InputGroup>
		);
	}, [filterText]);

	const filteredItems = data ? data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1) : [];

	const [showTasks, setShowTasks] = useState(false);

	function allHours() {
		let sum = 0;
		let sumTasksCost = 0;
		for (let i = 0; i < tasks.length; i++) {
			if (tasks[i].spentTime) {
				sum += tasks[i].spentTime;
			}
		}

		sumTasksCost = sum * rate;
		let vat = sumTasksCost * (paymentInfo !== null ? (paymentInfo.vat / 100) : (6 / 100));
		return { hours: sum, tax: vat };
	}

	const [vatSum, setVatSum] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0)
	const [hours, setHours] = useState(0);

	useEffect(() => {
		const { hours, tax } = allHours();
		setVatSum(tax);
		setHours(hours);
	}, [tasks, paymentInfo]);

	useEffect(() => {
		var total = (hours * rate) + (includeTax ? vatSum : 0);
		total += bonus;
		setTotalAmount(total);
	}, [includeTax, hours]);


	const send = () => {

		const newReport = {
			number: number,
			title: theme,
			comment: comment,
			startDateFormation: periodStart,
			endDateFormation: periodEnd,
			performerId: paymentInfo.userId,
			invoiceDate: new Date(),
			totalPaymentAmount: totalAmount,
			totalHours: hours,
			bonuses: 0,
			vat: vatSum,
			includeVat: includeTax,
			debt: 0,
			tasksId: (() => {
				const tasksId = [];
				tasks.forEach(task => {
					tasksId.push(task.id);
				});
				return tasksId;
			})()
		};

		axios
			.post(`${TasksPaymentApi}createReport`, newReport, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				toast.success('Отчет добавлен!', {
					position: toast.POSITION.TOP_RIGHT,
				});
				history(`${process.env.PUBLIC_URL}/app/task-reports`);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Формирования счёта и акта выполненных работ" parent="Формирования счёта и акта выполненных работ" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Row className="mb-3">
									<Col md="4">
										<Label className="col-sm-9 col-form-label">№ счёта</Label>
										<Col sm="12">
											<Input className="form-control" placeholder="№ счёта" value={number} onChange={(e) => setNumber(e.target.value)} />
										</Col>
									</Col>
									<Col md="8">
										<Label className="col-sm-9 col-form-label">Тема документа</Label>
										<Col sm="12">
											<Input className="form-control" placeholder="Тема документа" value={theme} onChange={(e) => setTheme(e.target.value)} />
										</Col>
									</Col>
								</Row>
								<Row className="mb-3">
									<Label className="col-sm-9 col-form-label">Комментарий</Label>
									<Col sm="12">
										<Input className="form-control" placeholder="Комментарий" value={comment} type="textarea" rows="3" onChange={(e) => setComment(e.target.value)} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Label className="col-sm-9 col-form-label">Период формирования</Label>
									<Col xl="4" className="m-r-45">
										<div style={{ display: "flex", alignItems: "flex-end" }}>
											<Label className="m-r-10">С</Label>
											<div className="customDatePickerWidth ">
												<DatePicker dateFormat="dd.MM.yyyy" locale={ru} className="form-control digits" selected={periodStart} onChange={(option) => setPeriodStart(option)} />
											</div>
										</div>
									</Col>
									<Col xl="4" className="m-r-45">
										<div style={{ display: "flex", alignItems: "flex-end" }}>
											<Label className="m-r-10">По</Label>
											<div className="customDatePickerWidth">
												<DatePicker dateFormat="dd.MM.yyyy" locale={ru} className="form-control digits" selected={periodEnd} onChange={(option) => setPeriodEnd(option)} />
											</div>
										</div>
									</Col>
								</Row>
								<Row className="mb-4">
									<Col md="5">
										<Label>Исполнитель</Label>
										<Col>
											<Select
												placeholder="Начните вводить..."
												noOptionsMessage={() => "Нет результатов"}
												options={allUsersNames}
												//value={answerable}
												onChange={(selectedOption) => {
													setTasksPaymentInfo(selectedOption);
												}}
												className="js-example-basic-single col-sm-12"
											/>
										</Col>
									</Col>
									<Col md="3" style={{ display: "flex", alignItems: "end" }}>
										<Label className="font-primary">
											{paymentInfo && rate ? `Ставка: ${rate} руб/час` : `Ставка не назначена`}
											{checkedUser && (
												<Tooltip title="Редактировать" arrow placement="top">
													<Link to={`${process.env.PUBLIC_URL}/app/employee-payment-details?employeeId=${checkedUser}`}>
														<Edit size={"18px"} className="ms-1 c-pointer" />
													</Link>
												</Tooltip>
											)}
										</Label>
									</Col>
									<Col md="4">
										<Label>Проект</Label>
										<Col>
											<Select
												required
												placeholder="Начните вводить..."
												noOptionsMessage={() => "Нет результатов"}
												options={allProjects}
												value={project}
												isDisabled={disabled}
												onChange={(selectedOption) => {
													setProject(selectedOption);
													setProjectInfo(selectedOption);
												}}
												className="js-example-basic-single col-sm-12"
											/>
										</Col>
									</Col>
								</Row>
								<Row>
									<div className="modal-footer mt-4">
										{!!showTasks && (
											<Btn
												attrBtn={{
													color: "primary",
													outline: true,
													className: "me-3",
													onClick: toggle,
													disabled: loading,
												}}
											>
												<Plus size={"20px"} style={{ margin: "0 4px -4px 0" }} />
												Добавить задачу в оплату
											</Btn>
										)}
										{/* <Btn attrBtn={{ color: "primary", onClick: createTasks }}>Показать задачи на оплату</Btn> */}
									</div>
								</Row>
								{!!showTasks &&
									(loading ? (
										<Col>
											<BeatLoader
												size={20}
												speedMultiplier={0.6}
												color="#7366ff"
												style={{
													margin: "60px auto",
													display: "inherit",
													position: "relative",
													width: "80px",
												}}
											/>
										</Col>
									) : (
										<>
											<Row className="mt-3 mb-4">
												<TableTask users={allUsersNames} data={tasks} deleteTask={deleteTask} addTask={addTask} type="view" rate={rate} />
											</Row>
											<Row className="me-2 ms-2 mt-4">
												<table
													style={{
														borderTopRightRadius: "10px",
														borderTopLeftRadius: "10px",
														overflowX: "auto",
														maxWidth: "130%",
													}}
													className="table"
												>
													<tr
														style={{
															backgroundColor: "#dddddd80",
															borderTopRightRadius: "10px",
															borderTopLeftRadius: "10px",
														}}
													>
														<th
															style={{
																borderTopLeftRadius: "10px",
																width: "20%",
															}}
														>
															Кол-во часов
														</th>
														<th>Бонусы</th>
														<th>Налог</th>
														<th style={{ width: "25%" }}>Включить налог в оплату</th>
														<th>Долг</th>
														<th
															style={{
																borderTopRightRadius: "10px",
																width: "30%",
															}}
														>
															Итоговая стоимость к оплате
														</th>
													</tr>
													<tr>
														{/* Кол-во часов */}
														<td style={{ borderWidth: "1px" }}>{allHours().hours}</td>
														{/* Бонусы */}
														<td style={{ borderWidth: "1px" }}>{bonus}</td>
														{/* Налог */}
														<td style={{ borderWidth: "1px" }}>{includeTax ? Math.round(vatSum) : "0"} ₽</td>
														{/* Включить налог в оплату */}
														<td style={{ borderWidth: "1px" }}>
															<div className="checkbox checkbox-primary">
																<Input id="checkbox-primary-displayCover" type="checkbox" checked={includeTax} onChange={(e) => setIncludeTax(e.target.checked)} />
																<Label for="checkbox-primary-displayCover"></Label>
															</div>
														</td>
														{/* Долг */}
														<td style={{ borderWidth: "1px" }}></td>
														{/* Итоговая стоимость к оплате */}
														<td style={{ borderWidth: "1px" }}>{totalAmount} ₽</td>
													</tr>
												</table>
											</Row>
										</>
									))}
							</CardBody>
							{!!showTasks && (
								<CardFooter>
									<Btn
										attrBtn={{
											color: "primary",
											onClick: send,
											className: "f-right",
											disabled: loading,
										}}
									>
										Сформировать
									</Btn>
								</CardFooter>
							)}
						</Card>
					</Col>
				</Row>
				<CommonModal isOpen={modal} title={"Добавить задачу в оплату"} toggler={toggle} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="xl">
					<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
						{subHeaderComponent}
						<TableTask users={allUsersNames} rate={rate} data={filteredItems} deleteTask={deleteTask} />
					</CardBody>
				</CommonModal>
			</Container>
		</Fragment>
	);
};
export default AccountFormation;
