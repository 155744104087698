import React, { useMemo, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import FilterComponent from '../../../Goods/PurchaseHistory/FilterComponent';
import { H6, P } from '../../../../../AbstractElements';

import { AnalyticsApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const TableOnServices = () => {
	const [data, setData] = useState([]);
	const [revenue, setRevenue] = useState(0);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${AnalyticsApi}getServiceReports`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {

				console.log(response);
				setData(concatResults(response).serviceReports);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	function calculateAllCost(type) {
		let totalCosts = 0;
		data.forEach((person) => {
			const visitHistory = person.visit_history;

			let totalCostForPerson = 0;
			if (visitHistory) {
				visitHistory.forEach((visit) => {
					if (visit.service === type) totalCostForPerson += 1;
				});
			}

			totalCosts += totalCostForPerson;
		});

		return totalCosts;
	}

	function costHour(visitHistory) {
		if (!visitHistory) {
			return 0;
		}

		return (calculateAllCost(visitHistory) / calculateTotalDuration(visitHistory)).toFixed(1);
	}

	function calculateTotalCost(items, type) {
		if (!items) {
			return 0;
		}

		let totalCost = 0;
		if (type === 'goods') {
			items.forEach((item) => {
				if (item.type === 'goods') {
					totalCost += item.cost || 0;
				}
			});
		} else {
			items.forEach((item) => {
				if (item.type === 'service') {
					totalCost += item.cost || 0;
				}
			});
		}

		return totalCost;
	}

	function calculateTotal(items, type) {
		if (!items) {
			return 0;
		}

		let totalCost = 0;
		if (type === 'goods') {
			items.forEach((item) => {
				if (item.type === 'goods') {
					totalCost += 1;
				}
			});
		} else {
			items.forEach((item) => {
				if (item.type === 'service') {
					totalCost += 1;
				}
			});
		}

		return totalCost;
	}

	function calculateTotalDuration(visitHistory) {
		let totalDuration = 0;
		if (!visitHistory) {
			return 0;
		}
		visitHistory.forEach((entry) => {
			totalDuration += entry.duration;
		});

		return totalDuration;
	}

	function percentageRevenue(visitHistory) {
		if (!visitHistory) {
			return 0;
		}

		return ((calculateAllCost(visitHistory) / revenue) * 100).toFixed(1);
	}

	const columns = [
		{
			name: <div>Услуга</div>,
			selector: (row) => (
				<>
					<H6>{row.name}</H6>
				</>
			),
			sortable: true,
			width: '200px',
		},
		{
			name: <div>Количество оказанных услуг</div>,
			selector: (row) => `${row.totalCount} `,
			sortable: true,
			width: '170px',
		},

		{
			name: 'Скидка',
			selector: (row) => `${row.discount} ₽`,
			sortable: true,
		},
		{
			name: 'Баллы',
			selector: (row) => `${row.points} ₽`,
			sortable: true,
		},
		{
			name: <div>Абонементы</div>,
			selector: (row) => `${row.subscriptions} ₽`,
			sortable: true,
			width: '130px',
		},
		{
			name: <div>Сертификаты</div>,
			selector: (row) => `${row.certificates} ₽`,
			sortable: true,
			width: '130px',
		},
		{
			name: <div>Оплачено деньгами</div>,
			selector: (row) => `${row.totalPayments} ₽`,
			sortable: true,
		},
		{
			name: <div>Стоимость расходников</div>,
			selector: (row) => `${row.costConsumables}  ₽`,
			sortable: true,
		},
		{
			name: <div>ЗП сотрудников</div>,
			selector: (row) => `${row.costEmployees} ₽`,
			sortable: true,
			width: '130px',
		},
		{
			name: <div>Прибыль</div>,
			selector: (row) => `${row.revenue} ₽`,
			sortable: true,
			width: '150px',
		},
		{
			name: <div>% от общей выручки</div>,
			selector: (row) => `${row.totalRevenuePercent} %`,
			sortable: true,
			width: '130px',
		},
	];

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return <>{data && <DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />}</>;
};

export default TableOnServices;
