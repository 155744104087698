import React, { useEffect, Fragment } from "react";
import { Col, Label, Input, Button, CardBody } from "reactstrap";
import { Switch } from '@mui/material';
import Select from 'react-select';
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { useInteractivePlanMapsContext } from "../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import { useCompanyContext } from "../../../../../_helper/Company/CompanyProvider";
import { useCmsContext } from "../../../../../_helper/Sites/CmsProvider";
import { useState } from "react";

const EditWidgetPage = () => {
    const {
        planMapWidget, planMapWidgets, planMapsOptions, setPlanMapWidget,
        planMapWidgetsPublicTypesOptions, updateInteractivePlanMapsWidgets,
        getInteractivePlanMaps, getInteractivePlanMapsWidgets
    } = useInteractivePlanMapsContext();
    const { sitesOptions, getSites } = useCmsContext();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const widgetId = queryParams.get("id");
    const { companyData } = useCompanyContext();
    const companyId = companyData.map((item) => item.attributes.companyId[0]);
    const [widgetEmbedCode, setWidgetEmbedCode] = useState("<iframe></iframe>")
   

    useEffect(() => {
        getInteractivePlanMaps();
        getInteractivePlanMapsWidgets();
        getSites();
    }, [widgetId]);

    useEffect(() => {
        const foundWidget = planMapWidgets.find(widget => widget.id === widgetId);
        if (foundWidget) {
            if (foundWidget) {
                setPlanMapWidget(foundWidget);
            }
            else {
                console.log(`Widget с ID ${widgetId} не найден`);
            }
        }
    }, [planMapWidgets]);

    useEffect(() => {
        const currentDomain = window.location.origin;
        const embedCode = `<iframe src="${currentDomain}/widgets/blank-view-plan-map?mapId=${planMapWidget.planMapId}&widgetId=${planMapWidget.id}" width="100%" height="1000px"></iframe>`;

        setWidgetEmbedCode(embedCode);
    }, [planMapWidget]);

    const handleInputChange = (field, value) => {
        setPlanMapWidget(prevState => {
            const updatedState = {
                ...prevState,
                [field]: value
            };

            if (field === 'domain') {
                updatedState.siteId = null;
            }

            if (field === 'siteId') {
                updatedState.domain = '';
            }
            return updatedState;
        });
    };
    const handleUpdateChange = () => {

        updateInteractivePlanMapsWidgets(planMapWidget).then(() => {
            toast.success("Запись успешно обновлена!");
        });
    };

    return (
        <Fragment>
            <CardBody style={{ paddingTop: "45px" }}>
                <Col md="3" xs="12" className="mb-3">
                    <Label>План карта</Label>
                    <Select
                        placeholder="Не выбрано"
                        noOptionsMessage={() => 'Нет результатов'}
                        value={planMapsOptions.find(x => x.value === planMapWidget.planMapId)}
                        options={planMapsOptions}
                        onChange={(e) => handleInputChange('planMapId', e?.value)}
                        className="js-example-basic-single col-sm-12"
                    />
                </Col>

                <Col md="3" xs="12" className="mb-3">
                    <Label>Тип публикации</Label>
                    <Select
                        options={planMapWidgetsPublicTypesOptions}
                        value={planMapWidgetsPublicTypesOptions.find(option => option.value === planMapWidget.publicTypeId)}
                        onChange={(selectedOption) => handleInputChange('publicTypeId', selectedOption.value)}
                    />
                </Col>
                {planMapWidgetsPublicTypesOptions.find(x => x.value === planMapWidget.publicTypeId)?.type === "external" && (
                    <Col md="3" xs="12" className="mb-3">
                        <Label>Домен</Label>
                        <Input
                            className="form-control"
                            value={planMapWidget.domain || ""}
                            onChange={(e) => handleInputChange('domain', e.target.value)}
                        />
                    </Col>
                )}

                {planMapWidgetsPublicTypesOptions.find(x => x.value === planMapWidget.publicTypeId)?.type === "cms" && (
                    <Col md="3" xs="12" className="mb-3">
                        <Label>Выберите сайт из конструктора</Label>
                        <Select
                            options={sitesOptions}
                            placeholder="Начните вводить..."
                            noOptionsMessage={() => "Нет результатов"}
                            value={sitesOptions.find(option => option.value === planMapWidget.siteId)}
                            onChange={(selectedOption) => handleInputChange('siteId', sitesOptions.find(site => site.value === selectedOption.value).value)}
                        />
                    </Col>
                )}

                <Col md="3" xs="12" className="mb-3">
                    <Label>
                        <Switch
                            checked={planMapWidget.isPublish || false}
                            onChange={() => handleInputChange('isPublish', !planMapWidget.isPublish)}
                            color="primary"
                        />
                        <span className="ms-2">Публикация</span>
                    </Label>
                </Col>

                <Col md="12" xs="12" className="mb-3">
                    <h2>Код для вставки</h2>
                    {planMapWidget.isPublish ? (
                        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <textarea
                                readOnly
                                value={widgetEmbedCode}
                                rows="4"
                                cols="50"
                                className="form-control"
                                style={{ marginRight: '10px', flex: '1', width: '50%' }}
                            />
                            <Button
                                color="primary"
                                onClick={() => navigator.clipboard.writeText(widgetEmbedCode)}
                            >
                                Копировать код
                            </Button>
                        </div>
                    ) : (
                        <p>Виджет не опубликован.</p>
                    )}
                </Col>
                <Button
                    color="primary"
                    onClick={handleUpdateChange}
                    style={{ marginTop: '10px' }}
                >
                    Сохранить изменения
                </Button>
            </CardBody>
       </Fragment>
    );
};

export default EditWidgetPage;
