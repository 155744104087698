import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';

import axios from 'axios';
import Select from 'react-select';
import { Breadcrumbs, Badges, H5, Btn } from '../../../../AbstractElements';
import { Label, Col, Row } from 'reactstrap';
import TableResources from './TableResources';

const Resources = () => {

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Ресурсы" parent="Услуги" title="Ресурсы" />
			<Container fluid={true}>
				<Card>
					<CardHeader className="font-secondary">Для добавления экземпляра ранее созданного ресурса, кликните на его название в списке</CardHeader>
					<CardBody>
						<TableResources />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default Resources;
