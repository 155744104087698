import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BlockPicker } from 'react-color';

const ColorPic = ({ expanded, onExpandEvent, onChange, currentState }) => {
	const [color, setColor] = useState(currentState.color);

	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	const handleColorChange = (newColor) => {
		setColor(newColor.hex);
		onChange('color', newColor.hex);
	};

	const renderModal = () => (
		<div onClick={stopPropagation} style={{ position: 'absolute', right: '-69px', top: '40px', zIndex: '2' }}>
			<BlockPicker color={color} onChangeComplete={handleColorChange} />
		</div>
	);

	return (
		<div aria-haspopup="true" aria-expanded={expanded} aria-label="rdw-color-picker" style={{ position: 'relative' }}>
			<div onClick={onExpandEvent} className="rdw-option-wrapper">
				<i className="icofont icofont-color-picker"></i>
			</div>
			{expanded ? renderModal() : undefined}
		</div>
	);
};

ColorPic.propTypes = {
	expanded: PropTypes.bool,
	onExpandEvent: PropTypes.func,
	onChange: PropTypes.func,
	currentState: PropTypes.object,
};

export default ColorPic;
