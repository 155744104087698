import React, { Fragment, useCallback, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
//import data from './accountModerations.json';
//import { Btn, H4 } from '../../../AbstractElements';
import StatusButton from './StatusButton';
import { CompaniesApi } from '../../../../api';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const DataTableModeration = () => {
	const [data, setData] = useState([]);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${CompaniesApi}get`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				//console.log(response);
				setData(response.data.companies);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const tableColumns = [
		{
			name: 'Наименование компании',
			selector: (row) => `${row.shortLegalName}`,
			sortable: true,
			center: true,
		},
		{
			name: 'ИНН',
			selector: (row) => `${row.inn}`,
			sortable: true,
			center: true,
		},
		{
			name: 'Почта',
			selector: (row) => `${row.email}`,
			sortable: true,
			center: true,
		},
		{
			name: 'Телефон',
			selector: (row) => `${row.phone}`,
			sortable: true,
			center: true,
		},
		{
			name: 'Дата регистрации',
			selector: (row) => `${row.dateAdd}`,
			sortable: true,
			center: true,
		},
		{
			name: 'Статус',
			selector: (row) => <StatusButton activated={row.activated} id={row.id} />,
			sortable: true,
			width: '25%',
			allowOverflow: true,
			center: true,
		},
	];

	return (
		<Fragment>
			<DataTable data={data} columns={tableColumns} striped={true} pagination selectableRows highlightOnHover pointerOnHover paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</Fragment>
	);
};
export default DataTableModeration;
