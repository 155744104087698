import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Card, Form, FormGroup, Label, Col, Input, CardBody, Row, Container, CardFooter } from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useKeycloak } from "@react-keycloak/web";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
//import Ckeditor from 'react-ckeditor-component';
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Btn, Breadcrumbs } from "../../../AbstractElements";
import uuid from "react-uuid";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

import { TaskApi, TwinAuthApi, AllProjectApi } from "../../../api";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../concatResults";

const NewTaskClass = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const taskId = queryParams.get("taskId");
	const projectId = queryParams.get("projectId");

	const initialContentState = '{"blocks":[],"entityMap":{}}';
	const initialRawContentState = JSON.parse(initialContentState);

	const [editorState, setEditorState] = useState(() => {
		return EditorState.createWithContent(convertFromRaw(initialRawContentState));
	});
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const history = useNavigate();

	const [Name, setName] = useState("");
	const [IsExternal, setIsExternal] = useState(false);
	const [StartDate, setStartDate] = useState(new Date());
	const [StopDate, setStopDate] = useState(new Date());
	const [Status, setStatus] = useState(null);
	const [Statuses, setStatuses] = useState(null);
	const [userStatuses, setUserStatuses] = useState(null);
	const [Projects, setProjects] = useState([]);
	const [Project, setProject] = useState(null);

	const [parentTask, setParentTask] = useState(null);
	const [previousTasks, setPreviousTasks] = useState([]);
	const [creator, setCreator] = useState({
		label: keycloak.tokenParsed.name,
		value: keycloak.tokenParsed.sub,
	}); // Постановщик
	const [answerable, setAnswerable] = useState({
		label: keycloak.tokenParsed.name,
		value: keycloak.tokenParsed.sub,
	}); // Исполнитель
	const [observers, setObservers] = useState([]); //Наблюдатели
	const [coExecutors, setCoExecutors] = useState([]); //Соисполнители

	const [contentState, setContentState] = useState({
		entityMap: {},
		blocks: [
			{
				key: "637gr",
				text: "Добавьте свой текст.",
				type: "unstyled",
				depth: 0,
				inlineStyleRanges: [],
				entityRanges: [],
				data: {},
			},
		],
	});

	const [dataSend, setData] = useState("");
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	let currentUserId = `${keycloak.tokenParsed.sub}`;
	console.log(currentUserId);

	const [allUsersNames, setAllUsersNames] = useState([]);
	const [allTask, setAllTask] = useState([]);

	const fetchData = async () => {
		try {
			const allUsersResponse = await axios.get(`${TwinAuthApi}get-all-users`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const allUsers = JSON.parse(allUsersResponse.data).map((option) => ({
				label: `${option.firstName} ${option.lastName}`,
				value: option.id,
			}));
			setAllUsersNames(allUsers);

			const projectResponse = await axios.get(`${AllProjectApi}get`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const selectOptionsProjects = concatResults(projectResponse).projects
				? concatResults(projectResponse).projects.map((option) => ({
						label: option.name,
						value: option.id,
				  }))
				: [];
			selectOptionsProjects.unshift({ label: "Нет проекта", value: null });
			setProjects(selectOptionsProjects);

			const userStatusesResponse = await axios.get(`${TaskApi}get-user-statuses`, {
				params: { UserId: currentUserId },
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const selectOptions = concatResults(userStatusesResponse).statuses
				? concatResults(userStatusesResponse).statuses.map((option) => ({
						label: option.name,
						value: option.id,
				  }))
				: [];
			setStatuses(selectOptions);
			setUserStatuses(selectOptions);

			const taskResponse = await axios.get(`${TaskApi}get`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				params: {
					userId: currentUserId,
					userType: null,
				},
			});

			const selectOptionsTasks = concatResults(taskResponse).tasks
				.filter((option) => option.id !== taskId)
				.map((option) => ({
					label: option.name,
					value: option.id,
				}));

			setAllTask(selectOptionsTasks);

			if (taskId !== null) {
				const task = concatResults(taskResponse).tasks.find((item) => item.id === taskId);
				setName(task.name);
				setIsExternal(task.isExternal);

				if (task.description.length > 0) {
					const newContentState = convertFromRaw(JSON.parse(task.description));
					setEditorState(EditorState.createWithContent(newContentState));
				}

				setStartDate(new Date(task.dateStart));
				setStopDate(new Date(task.dateEnd));

				setCreator(allUsers.find((item) => task.creatorId === item.value));
				setAnswerable(allUsers.find((item) => task.doerId === item.value));
				const observersIds = task.watchers.map((item) => item);
				const observers = allUsers.filter((item) => observersIds.includes(item.value));
				setObservers(observers);
				const coDoersIds = task.coDoers.map((item) => item);
				const coDoers = allUsers.filter((item) => coDoersIds.includes(item.value));
				setCoExecutors(coDoers);
				setParentTask(selectOptionsTasks.find((item) => task.parentTaskId === item.value));

				if (task.childTasksId !== null) {
					const prevTasks = task.childTasksId.map((item) => item);
					const filtredPrevTasks = selectOptionsTasks.filter((option) => prevTasks.includes(option.value));
					setPreviousTasks(filtredPrevTasks);
				}

				if (task.projectId !== null) {
					const projectStatusesResponse = await axios.get(`${AllProjectApi}getProjectStatuses?projectId=${task.projectId}`, {
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
					});
					const selectOptionsPrj = concatResults(projectStatusesResponse).statuses
						? concatResults(projectStatusesResponse).statuses.map((option) => ({
								label: option.name,
								value: option.id,
						  }))
						: [];
					setStatuses(selectOptionsPrj);
					setProject(selectOptionsProjects.find((option) => option.value === task.projectId));
					const taskStatus = task.status ? selectOptionsPrj.find((status) => status.value === task.statusId) : null;
					setStatus(taskStatus);
				} else {
					const taskStatus = task.status ? { value: task.status.id, label: task.status.name } : null;
					setStatus(taskStatus);
					setProject(selectOptionsProjects.find((option) => option.value === null));
				}
			}
		} catch (error) {
			console.error("Ошибка получения данных:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const [loading, setLoading] = useState(false);

	const Addtask = (data) => {
		setLoading(true);
		if (data !== "") {
			try {
				var task = {
					id: taskId !== null ? taskId : uuid(),
					name: Name,
					description: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
					isExternal: IsExternal,
					dateStart: StartDate,
					dateEnd: StopDate,
					statusId: Status.value,
					projectId: Project.value,
					parentTaskId: parentTask ? parentTask.value : null,
					childTasksId: previousTasks ? previousTasks.map((task) => task.value) : null,
					creatorId: creator.value,
					doerId: answerable.value,
					watchers: observers,
					coDoers: coExecutors,
					accessRequests: [],
				};

				if (taskId !== null) {
					return axios
						.put(`${TaskApi}update`, task, {
							headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
						})
						.then((response) => {
							setLoading(false);
							toast.success("Задача обновлена!", {
								position: toast.POSITION.TOP_RIGHT,
							});
							history(`${process.env.PUBLIC_URL}/app/task`);
						})
						.catch((error) => {
							setLoading(false);
							toast.error("Ошибка при обновлении", {
								position: toast.POSITION.TOP_RIGHT,
							});
						});
				} else {
					return axios
						.post(`${TaskApi}create`, task, {
							headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
						})
						.then((response) => {
							setLoading(false);
							toast.success("Задача добавлена!", {
								position: toast.POSITION.TOP_RIGHT,
							});
							history(`${process.env.PUBLIC_URL}/app/task`);
						})
						.catch((error) => {
							setLoading(false);
							toast.error("Ошибка при добавлении", {
								position: toast.POSITION.TOP_RIGHT,
							});
						});
				}
			} catch (error) {
				setLoading(false);
				toast.error("Ошибка при добавлении", {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error("Произошла ошибка при отправке данных на сервер:", error);
			}
		} else {
			errors.showMessages();
		}
	};

	async function getUserName(userId) {
		try {
			const response = await axios.get(`${TwinAuthApi}get-user?id=${userId}`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const userData = JSON.parse(response.data);
			return userData.firstName + userData.lastName;
		} catch (error) {
			console.error("Ошибка получения данных:", error);
			return null;
		}
	}

	const [content, setContent] = useState("");

	const handleChange = (evt) => {
		setContent(evt.editor.getData());
		console.log(JSON.stringify(evt.editor.getData()));
	};

	const handleProjectChange = async (selectedOption) => {
		setProject(selectedOption);
		setStatus(null);
		if (selectedOption.value != null) {
			const projectId = selectedOption.value;

			try {
				const projectStatusesResponse = await axios.get(`${AllProjectApi}getProjectStatuses?projectId=${projectId}`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				});

				const selectOptionsPrj = concatResults(projectStatusesResponse).statuses
					? concatResults(projectStatusesResponse).statuses.map((option) => ({
							label: option.name,
							value: option.id,
					  }))
					: [];

				setStatuses(selectOptionsPrj);
			} catch (error) {
				console.error("Ошибка загрузки статусов проекта:", error);
			}
		} else {
			setStatuses(userStatuses);
		}
	};

	const handleSelectChangeStatus = (selectedOption) => {
		setStatus(selectedOption);
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={taskId ? `Редактировать задачу` : `Создать задачу`} parent="Задачи" title={taskId ? `Редактировать задачу` : `Создать задачу`} />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Row>
						<Card>
							<Form className="form-bookmark needs-validation" onSubmit={handleSubmit(Addtask)}>
								<CardBody>
									<div className="form-row">
										<Col md="10" xs="12">
											<FormGroup>
												<Label>Название задачи</Label>
												<Col md="11" xs="12">
													<input className="form-control" type="text" value={Name} onChange={(e) => setName(e.target.value)} required />
												</Col>
											</FormGroup>
										</Col>
										<Col md="2" xs="12">
											<FormGroup>
												<Label>Внешняя задача</Label>
												<div className="checkbox checkbox-primary">
													<Input id="checkbox-primary-1" name="IsExternal" type="checkbox" onChange={(e) => setIsExternal(e.target.checked)} checked={IsExternal} />
													<Label for="checkbox-primary-1">Да</Label>
												</div>
											</FormGroup>
										</Col>
										<FormGroup className="col-md-12">
											<Label>Описание задачи</Label>
											<Col sm="12">
												<Editor
													editorStyle={{
														height: "50vh",
														borderRadius: "5px",
														borderColor: "#dee2e6",
														padding: "0 10px",
													}}
													editorState={editorState}
													wrapperClassName="editor-class"
													onEditorStateChange={setEditorState}
													localization={{ locale: "ru" }}
													toolbarStyle={{
														borderRadius: "5px",
														borderColor: "#dee2e6",
													}}
												/>
											</Col>
										</FormGroup>
										<Col md="6" xs="12">
											<FormGroup>
												<Label className="col-sm-12 col-form-label ">Начать задачу с</Label>
												<Col xl="11" sm="12">
													<DatePicker
														placeholderText="Выберите дату"
														minDate={new Date()}
														isClearable
														locale={ru}
														className="form-control digits"
														selected={StartDate}
														onChange={(date) => setStartDate(date)}
														timeInputLabel="Время:"
														dateFormat="dd.MM.yyyy HH:mm"
														showTimeInput
													/>
												</Col>
											</FormGroup>
										</Col>
										<Col md="6" xs="12">
											<FormGroup>
												<Label className="col-sm-12 col-form-label ">Завершить задачу до</Label>
												<Col xl="11" sm="12">
													<DatePicker
														placeholderText="Выберите дату"
														minDate={new Date()}
														isClearable
														locale={ru}
														className="form-control digits"
														selected={StopDate}
														onChange={(date) => setStopDate(date)}
														timeInputLabel="Время:"
														dateFormat="dd.MM.yyyy HH:mm"
														showTimeInput
													/>
												</Col>
											</FormGroup>
										</Col>
										<Col md="6" xs="12">
											<FormGroup>
												<Label>Статус</Label>
												<Col sm="11">
													<Select
														required
														placeholder="Начните вводить..."
														noOptionsMessage={() => "Нет результатов"}
														options={Statuses}
														value={Status}
														onChange={handleSelectChangeStatus}
														className="js-example-basic-single col-sm-12"
													/>
												</Col>
											</FormGroup>
										</Col>
										<Col md="6" xs="12">
											<FormGroup>
												<Label>Проект</Label>
												<Col sm="11">
													<Select
														required
														placeholder="Начните вводить..."
														noOptionsMessage={() => "Нет результатов"}
														options={Projects}
														value={Project}
														onChange={handleProjectChange}
														className="js-example-basic-single col-sm-12"
													/>
												</Col>
											</FormGroup>
										</Col>
										<Col md="6" xs="12">
											<FormGroup>
												<Label>Постановщик</Label>
												<Col sm="11">
													<Select
														placeholder="Начните вводить..."
														noOptionsMessage={() => "Нет результатов"}
														options={allUsersNames}
														value={creator}
														onChange={(selectedOption) => setCreator(selectedOption)}
														className="js-example-basic-single col-sm-12"
													/>
												</Col>
											</FormGroup>
										</Col>
										<Col md="6" xs="12">
											<FormGroup>
												<Label>Исполнитель</Label>
												<Col sm="11">
													<Select
														placeholder="Начните вводить..."
														noOptionsMessage={() => "Нет результатов"}
														options={allUsersNames}
														value={answerable}
														onChange={(selectedOption) => setAnswerable(selectedOption)}
														className="js-example-basic-single col-sm-12"
													/>
												</Col>
											</FormGroup>
										</Col>
										<Col md="6" xs="12">
											<FormGroup>
												<Label>Наблюдатели</Label>
												<Col sm="11">
													<Select
														placeholder="Начните вводить..."
														noOptionsMessage={() => "Нет результатов"}
														isMulti
														options={allUsersNames}
														value={observers}
														onChange={(selectedOption) => setObservers(selectedOption)}
														className="js-example-basic-single col-sm-12"
													/>
												</Col>
											</FormGroup>
										</Col>
										<Col md="6" xs="12">
											<FormGroup>
												<Label>Соисполнители</Label>
												<Col sm="11">
													<Select
														placeholder="Начните вводить..."
														noOptionsMessage={() => "Нет результатов"}
														isMulti
														options={allUsersNames}
														value={coExecutors}
														onChange={(selectedOption) => setCoExecutors(selectedOption)}
														className="js-example-basic-single col-sm-12"
													/>
												</Col>
											</FormGroup>
										</Col>
										<Col md="6" xs="12">
											<FormGroup>
												<Label>Сделать подзадачей</Label>
												<Col sm="11">
													<Select
														placeholder="Начните вводить..."
														noOptionsMessage={() => "Нет результатов"}
														options={allTask}
														value={parentTask}
														onChange={(selectedOption) => setParentTask(selectedOption)}
														className="js-example-basic-single col-sm-12"
													/>
												</Col>
											</FormGroup>
										</Col>
										<Col md="6" xs="12">
											<FormGroup>
												<Label>Добавить предшествующие задачи</Label>
												<Col sm="11">
													<Select
														placeholder="Начните вводить..."
														isMulti
														noOptionsMessage={() => "Нет результатов"}
														options={allTask}
														value={previousTasks}
														onChange={(selectedOption) => setPreviousTasks(selectedOption)}
														className="js-example-basic-single col-sm-12"
													/>
												</Col>
											</FormGroup>
										</Col>
									</div>
								</CardBody>
								<CardFooter>
									<div className="modal-footer">
										<Btn attrBtn={{ color: "primary", disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
									</div>
								</CardFooter>
							</Form>
						</Card>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};
export default NewTaskClass;
