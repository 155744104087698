import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Trash2 } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import FilterComponent from '../FilterComponent';
import { useKeycloak } from "@react-keycloak/web";
import { useCompanyContext } from "../../../../../_helper/Company/CompanyProvider";
import { useInteractivePlanMapsContext } from "../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import { Link, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { House, HouseSharp } from "@mui/icons-material";
import { BsEye } from "react-icons/bs";

const DataTableInteractivePlanMaps = () => {
    const { keycloak } = useKeycloak();
    const { companyData, companyGroupUsers, getCompanyGroupUsers } = useCompanyContext();
    const navigate = useNavigate();

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const { planMaps, getInteractivePlanMaps, deleteInteractivePlanMap, createInteractivePlanMap } = useInteractivePlanMapsContext();

    const [name, setName] = useState('');

    useEffect(() => {
        planMaps && setFilteredItems(planMaps.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, planMaps]);

    useEffect(() => {
        getInteractivePlanMaps();
        getCompanyGroupUsers();
    }, [keycloak.tokenParsed, companyData]);

    const toggle = () => {
        setName('');
        setModal(!modal);
    };

    const handleAddRow = () => {
        if (name !== '') {
            createInteractivePlanMap(name, keycloak?.tokenParsed.sub, null, companyData[0].attributes.companyId[0], false, null).then(() => {
                getInteractivePlanMaps();
            });
            setModal(!modal);
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const deleteRow = (remId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'Данное действие не отменит отправку оповщений!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(() => {
            deleteInteractivePlanMap(remId).then(() => {
                getInteractivePlanMaps();
            });
            //deleteModuleGroupRole(remId);
        });
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const getCreator = (id) => {
        let userName = '';
        if (companyGroupUsers && companyGroupUsers) {
            console.log(companyGroupUsers);
            let findUserName = companyGroupUsers?.find((item) => item.id === id);
            if (findUserName) {
                userName = `${findUserName.firstName} ${findUserName.lastName}`;
            }
        }
        return userName;
    };

    function formatDate(datetime) {
        const date = new Date(datetime);

        const now = new Date();
        const diff = (now - date) / 1000;

        const secondsInDay = 86400;

        if (diff < secondsInDay) {
            return `сегодня в ${formatTime(date)}`;
        } else if (diff < 2 * secondsInDay) {
            return `вчера в ${formatTime(date)}`;
        } else if (date.getFullYear() === now.getFullYear()) {
            return `${date.getDate()} ${getMonthName(date.getMonth())} в ${formatTime(date)}`;
        } else {
            return `${date.getDate()} ${getMonthName(
                date.getMonth()
            )} ${date.getFullYear()} в ${formatTime(date)}`;
        }
    }

    function formatTime(date) {
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        return `${hours}:${minutes}`;
    }

    function getMonthName(month) {
        const months = [
            "янв",
            "фев",
            "мар",
            "апр",
            "мая",
            "июн",
            "июл",
            "авг",
            "сен",
            "окт",
            "ноя",
            "дек",
        ];
        return months[month];
    }

    const tableColumns = [
        {
            name: 'Название',
            selector: (row) => <Link to={`/widgets/edit-plan-map?mapId=${row.id}`}>{row.name}</Link>,
            sortable: true,
            center: false,
        },
        {
            name: 'Создатель',
            selector: (row) => `${getCreator(row.creatorId)}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Последний раз изменена',
            selector: (row) => formatDate(row.lastChange),
            sortable: true,
            center: false,
        },
        {
            name: 'Активирован модуль',
            selector: (row) => row.activated ? <TiTick></TiTick> : '',
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{ display: 'flex' }}>
                    <Tooltip title="Просмотр карты" arrow placement="top">
                        <div className="font-primary" style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/widgets/view-plan-map?mapId=${row.id}`)}>
                            <BsEye size={'18px'} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Объекты" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }}
                            onClick={() => navigate(`/widgets/interactive-plan-maps-objects?objectId=${row.id}`)}>
                            <House size={'18px'} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }}
                            onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'} />
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление новой карты'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Название
                                </Label>
                                <Col sm="11">
                                    <Input
                                        className="form-control"
                                        value={name}
                                        placeholder="Название карты"
                                        onChange={(e) => setName(e.target.value)} />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <Button
                color="primary"
                style={{ marginLeft: '23px', width: '10%' }}
                onClick={() => {
                    window.open(`/widgets/plan-map-widgets`, '_blank')
                }}>
                Виджеты
            </Button>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTableInteractivePlanMaps;
