import React, { useMemo, useState, useEffect } from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ru';
import { Form, Label, Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Plus, Minus } from 'react-feather';

import CommonModal from '../../UiKits/Modals/common/modal';
import { H5 } from '../../../AbstractElements';
import { Btn } from '../../../AbstractElements';
import uuid from 'react-uuid';
import { useCalendarContext } from '../../../_helper/Calendar/CalendarProvider';
import { useKeycloak } from '@react-keycloak/web';

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const EventsCalendar = (calendarType) => {
	const [myEvents, setEvents] = useState(null);
	const [allEvents, setAllEvents] = useState([]);

	const {keycloak} = useKeycloak();

	const {
		calendarData,
		calendarEventsData,
		getCalendarEventsData,
		createCalendarEvent,
		updateCalendarEvent
	} = useCalendarContext();

	useEffect(() => {
		if (calendarType.calendarType === "events") {
			calendarEventsData && setEvents(calendarEventsData.map((option) => ({
				id: option.id,
				title: option.title,
				start: new Date(option.startDateTime),
				end: new Date(option.endDateTime),
				all_day: option.allDay
			})));
		}
		else {
			setEvents([]);
		}
	}, [calendarEventsData]);

	const { defaultDate, messages } = useMemo(
		() => ({
			defaultDate: new Date(),
			messages: {
				week: 'Неделя',
				work_week: 'Рабочая неделя',
				day: 'День',
				month: 'Месяц',
				previous: 'Предыдуший',
				next: 'Следующий',
				today: 'Сегодня',
				agenda: 'План',
				date: 'Дата',
				time: 'Время',
				event: 'Событие',
				allDay: 'Весь день',
				yesterday: 'Вчера',
				tomorrow: 'Завтра',
				noEventsInRange: 'В этом диапазоне нет событий.',

				showMore: (total) => `+ ещё ${total}`,
			},
		}),
		[]
	);

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const handleAddEvents = () => {
		if (!eventId) {
			createCalendarEvent(calendarData.find((items) => items.calendarType === "events").id, note, StartDate, endDate, false, allDay);
		}
		else {
			updateCalendarEvent(eventId, calendarData.find((items) => items.calendarType === "events").id, note, StartDate, endDate, false, allDay);
		}
		setModal(!modal);
	};

	const handleDelEvents = () => {};

	const [note, setNote] = useState('');

	const [eventId, setEventId] = useState("");
	const [StartDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [allDay, setAllDay] = useState(false);
	const [edit, setEdit] = useState(false);

	const handleSelectSlot = ({ start = new Date(), end = new Date() }) => {
		setAllDay(false);
		setStartDate(start);
		setEndDate(end);
		setModal(true);
		setEdit(false);

		setNote('');
	};

	const handleSelectEvent = (event) => {
		//edit
		//const foundObject = allEvents.find((item) => item.id === event.id);
		//console.log(foundObject);

		if (event) {
			setEventId(event.id);
			setEdit(true);
			setStartDate(new Date(event.start));
			setEndDate(new Date(event.end));
			setNote(event.title);
			setAllDay(event.all_day);
		}
		setModal(true);
	};

	function formatHeaderDate(date) {
		const months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();

		const formattedDate = `${day} ${month} ${year}г. `;

		return formattedDate;
	}

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getDate();
		const month = dateObject.getMonth() + 1;
		const year = dateObject.getFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	const handleCheckboxChange = (e) => {
		const isChecked = e.target.checked;

		const newStartDate = new Date(StartDate);
		const newEndDate = new Date(endDate);

		if (isChecked) {
			newStartDate.setHours(0, 0, 0, 0);

			newEndDate.setDate(newEndDate.getDate() + 1);
			newEndDate.setHours(0, 0, 0, 0);
		}

		setStartDate(newStartDate);
		setEndDate(newEndDate);

		setAllDay(isChecked);
	};

	return (
		<>
			<Btn attrBtn={{ color: 'primary', onClick: handleSelectSlot, className: 'mb-4' }}>
				<Plus style={{ margin: '0 2px -7px 0px' }} /> Добавить событие
			</Btn>
			<CommonModal isOpen={modal} title={eventId ? `Редактировать событие` : `Добавить событие`} toggler={handleAddEvents} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '15px', paddingTop: '0' }}>
					<Form className="form theme-form">
						<H5 attrH5={{ className: 'mb-4' }}>{formatHeaderDate(StartDate)}</H5>
						<Row className="mb-3" style={{ alignItems: 'flex-end' }}>
							<Col className=" col-sm-6" md="3">
								<Label className="col-sm-10 col-form-label ">Дата</Label>
								<Col xl="10" sm="9">
									<div className="input-group">
										<DatePicker className="form-control" locale={ru} selected={StartDate} value={formatDate(StartDate)} onChange={(selectedOption) => setStartDate(selectedOption)} />
									</div>
								</Col>
							</Col>
							<Col className=" col-sm-6" md="2">
								<Label className="col-sm-10 col-form-label ">Время</Label>
								<Col xl="10" sm="9">
									<DatePicker timeCaption="Время" className="form-control" selected={StartDate} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="HH:mm" locale={ru} onChange={(selectedOption) => setStartDate(selectedOption)} />
								</Col>
							</Col>
							<Col md="1" className="">
								<Minus />
							</Col>
							<Col className=" col-sm-6 p-0" md="2">
								<Col xl="10" sm="9">
									<DatePicker timeCaption="Время" className="form-control" selected={endDate} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="HH:mm" locale={ru} onChange={(selectedOption) => setEndDate(selectedOption)} />
								</Col>
							</Col>
							<Col md="3" className="checkbox checkbox-primary ms-4">
								<Input id={`checkbox-primary-1`} type="checkbox" checked={allDay} onChange={(e) => handleCheckboxChange(e)} />
								<Label for={`checkbox-primary-1`}>Весь день</Label>
							</Col>
						</Row>
						<Row className="">
							<Label>Название</Label>
							<Col sm="12">
								<textarea className="form-control" rows={'3'} placeholder="" value={note} onChange={(e) => setNote(e.target.value)} />
							</Col>
						</Row>
					</Form>
				</CardBody>
			</CommonModal>

			<Calendar onSelectEvent={handleSelectEvent} onSelectSlot={handleSelectSlot} messages={messages} localizer={localizer} scrollToTime={new Date(1970, 1, 1, 6)} views={allViews} events={myEvents ?? []} eventOverlap dragRevertDuration={500} dragScroll droppable={true} showMultiDayTimes step={60} startAccessor="start" endAccessor="end" selectable />
		</>
	);
};
export default EventsCalendar;
