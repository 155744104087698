import React, { Fragment, useEffect, useState } from "react";
import { InputGroup, InputGroupText, Input, Row, Collapse } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import uuid from "react-uuid";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { Label, Col } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";

import { Plus, Trash2 } from "react-feather";
import { Btn, Image } from "../../../../../AbstractElements";
import { EducationApi } from "../../../../../api";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const AchievementsList = ({ courseId }) => {
	const [data, setData] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [lessons, setLessons] = useState([]);
	const [modules, setModules] = useState([]);
	const fetchData = async () => {
		try {
			const modulesResponse = await axios.get(`${EducationApi}get-course-modules/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const selectOptions = concatResults(modulesResponse).map((option) => ({
				label: option.name,
				value: option.id,
			}));
			setModules(selectOptions);
		} catch (error) {
			console.error("Ошибка получения данных:", error);
		}
	};

	useEffect(() => {
		fetchData();
		axios
			.get(`${EducationApi}get-course-lessons?courseId=${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = concatResults(response).lessons.map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setLessons(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		axios
			.get(`/api/Achievements.json`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const addItem = () => {
		const item = {
			id: uuid(),
			name: "",
			description: "",
			image: "",
			color: "",
			weight: 0,
			conditionsIssue: { label: "Выдается по окончанию курса", value: "endCourse" },
			endModuleId: "",
			endLessonId: "",
			replacedIssued: true,
		};
		setData([...data, item]);
		console.log(data);
	};

	const [isOpen, setIsOpen] = useState(1);

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [endModule, setEndModule] = useState(null);
	const [endLesson, setEndLesson] = useState(null);
	const [weight, setWeight] = useState(0);
	const [replacedIssued, setReplacedIssued] = useState(true);
	const [conditionsIssue, setConditionsIssue] = useState({ label: "Выдается по окончанию курса", value: "endCourse" });

	const handleSelectChange = (selectedOption) => {
		setConditionsIssue(selectedOption);
	};

	const toggleClientCard = (item) => {
		if (isOpen === item.id) {
			setIsOpen(null);
		} else {
			setIsOpen(item.id);
		}
		setConditionsIssue(item.conditionsIssue);
		setName(item.name);
		setDescription(item.description);
		setWeight(item.weight);
		setReplacedIssued(item.replacedIssued);
		if (item.endLessonId) {
			setEndLesson(lessons.find((i) => i.value === item.endLessonId));
		}
		if (item.endModuleId) {
			setEndLesson(modules.find((i) => i.value === item.endModuleId));
		}
	};

	const deleteItem = (id) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${EducationApi}delete-course-module/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении объекта:", error);
					});
				fetchData();
			}
		});
	};

	const handleSave = (itemId) => {
		const itemIndex = data.findIndex((item) => item.id === itemId);

		const updatedItem = data[itemIndex];

		const updatedData = [...data];
		updatedData[itemIndex] = updatedItem;
		setData(updatedData);

		const module = {
			id: updatedItem.id,
			name: updatedItem.name,
			description: updatedItem.description,
			conditionsIssue: conditionsIssue,
			image: image,
			color: "#000000",
			weight: weight,
			endModuleId: endModule ? endModule.value : "",
			endLessonId: endLesson ? endLesson.value : "",
			replacedIssued: replacedIssued,
		};

		// if (updatedItem.isNew) {
		// 	axios
		// 		.post(`${EducationApi}create-course-module`, module, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 		.then((response) => {
		// 			console.log("Модуль успешно создан:", concatResults(response));
		// 			toast.success("Модуль успешно создан!", {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 			fetchData();
		// 		})
		// 		.catch((error) => {
		// 			console.error("Ошибка при создании модуля:", error);
		// 		});
		// } else {
		// 	axios
		// 		.put(`${EducationApi}update-course-module`, module, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 		.then((response) => {
		// 			console.log("Модуль успешно обновлён:", concatResults(response));
		// 			toast.success("Модуль успешно обновлён!", {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 			fetchData();
		// 		})
		// 		.catch((error) => {
		// 			console.error("Ошибка при обновлении модуля:", error);
		// 		});
		// }
	};

	const [image, setImage] = useState(null);

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onloadend = () => {
			setImage(reader.result);
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	return (
		<Fragment>
			<Row className="b-r-8 badge-light font-dark m-0" style={{ padding: "15px 0" }}>
				<Col md="6">Название достижения</Col>
				<Col md="3">Описание достижения</Col>
				<Col md="2">Вес</Col>
				<Col md="1"></Col>
			</Row>
			{data &&
				data.map((item) => (
					<Col key={item.id}>
						<Row className=" f-14 p-t-15 p-b-15 b-r-3 b-b-light" onClick={() => toggleClientCard(item)} style={{ cursor: "pointer", backgroundColor: "#7366ff08", margin: "0.25rem 0 0" }}>
							<Col md="6">
								{isOpen === item.id ? <i className="fa fa-angle-down me-2"></i> : <i className="fa fa-angle-right me-2"></i>}
								<Image attrImage={{ className: "img-thumbnail rounded-circle me-2 w-40", src: `https://dummyimage.com/800x800`, alt: "profile" }} />
								{item.name ? (
									item.name
								) : (
									<>
										<span className={`badge badge-light-secondary rounded-pill me-2`} style={{ height: "min-content" }}>
											<i className="fa fa-exclamation"></i>{" "}
										</span>
										<span style={{ color: "#9d9d9d" }}>Введите название достижения</span>
									</>
								)}
							</Col>
							<Col md="3">{item.description}</Col>
							<Col md="2">{item.weight}</Col>

							<Col md="1" className="">
								<Row style={{ justifyContent: "center" }}>
									<Tooltip title="Удалить элемент" arrow placement="top">
										<a className="col-sm-3" onClick={() => deleteItem(item.id)} style={{ width: "32px", height: "32px" }}>
											<Trash2 size={"20px"} />
										</a>
									</Tooltip>
								</Row>
							</Col>
						</Row>

						<Collapse isOpen={isOpen === item.id}>
							<Col md="12" className="p-3">
								<Col className="mb-4">
									<Row className="mt-2">
										<Col className="col-sm-8 mb-2">
											<Col sm="12">
												<Input placeholder="Название достижения" className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
											</Col>
										</Col>
										<div className="col-sm-4 mb-2 d-flex" style={{ alignItems: "center" }}>
											<InputGroup>
												<InputGroupText>{"Вес"}</InputGroupText>
												<Input className="form-control" type="number" value={weight} onChange={(e) => setWeight(e.target.value)} />
											</InputGroup>
											<Tooltip title="Сначала выводятся бейджи с большим весом" arrow placement="top">
												<i className="fa fa-info-circle f-22" style={{ color: "#8989a8", fontSize: "18px", marginLeft: "7px", cursor: "pointer" }}></i>
											</Tooltip>
										</div>
									</Row>
									<Input type="textarea" className="form-control" rows="3" placeholder="Описание достижения" value={description} onChange={(e) => setDescription(e.target.value)} />
									<Row className="mt-4">
										<Col md="6">
											<Input type="file" accept="image/*" className="form-control col-md-6 mb-3" onChange={handleImageChange} />
											<img src={image ? image : "https://dummyimage.com/800x800"} alt="Uploaded" className="b-r-10" style={{ maxWidth: "30%" }} />
										</Col>
									</Row>
									<Row className="mt-4">
										<Col md="6">
											<Select
												placeholder="Выберите..."
												options={[
													{ label: "Выдается по окончанию курса", value: "endCourse" },
													{ label: "Выдается по прохождению модуля", value: "endModule" },
													{ label: "Выдается по прохождению занятия", value: "endLesson" },
												]}
												theme={(theme) => ({
													...theme,
													colors: {
														...theme.colors,
														primary25: "#7366ff80",
														primary: "#7366ff",
													},
												})}
												className="js-example-basic-single "
												value={conditionsIssue}
												onChange={handleSelectChange}
											/>
										</Col>
										<Col md="6">
											{conditionsIssue.value === "endModule" && (
												<Select
													placeholder="Выберите модуль"
													options={modules}
													theme={(theme) => ({
														...theme,
														colors: {
															...theme.colors,
															primary25: "#7366ff80",
															primary: "#7366ff",
														},
													})}
													className="js-example-basic-single "
													value={endModule}
													onChange={(option) => setEndModule(option)}
												/>
											)}
											{conditionsIssue.value === "endLesson" && (
												<Select
													placeholder="Выберите занятие"
													options={lessons}
													theme={(theme) => ({
														...theme,
														colors: {
															...theme.colors,
															primary25: "#7366ff80",
															primary: "#7366ff",
														},
													})}
													className="js-example-basic-single "
													value={endLesson}
													onChange={(option) => setEndLesson(option)}
												/>
											)}
										</Col>
									</Row>
									<div className="checkbox checkbox-primary mt-3">
										<Input id="checkbox-primary-showNumberClasses" type="checkbox" checked={replacedIssued} onChange={(e) => setReplacedIssued(e.target.checked)} />
										<Label for="checkbox-primary-showNumberClasses">Заменяется при выдаче следующего</Label>
									</div>
								</Col>

								<Col md="12" className="modal-footer">
									<Btn attrBtn={{ className: "mt-4", onClick: () => handleSave(item.id) }}>Сохранить</Btn>
								</Col>
							</Col>
						</Collapse>
					</Col>
				))}
			<Btn attrBtn={{ onClick: addItem, color: "light", className: "mt-3" }}>
				<Plus size={"18px"} style={{ margin: "0 4px -3px 0" }} />
				Добавить достижение
			</Btn>
		</Fragment>
	);
};
export default AchievementsList;
