import React, { Fragment } from 'react';
import { Col, Card } from 'reactstrap';
import { P } from '../../../../AbstractElements';
import UserTitle from './UserTitle';
import UserFooter from './UserFooter';

const UserDetails3 = ({ name, time, content, likes, comments }) => {
	return (
		<Fragment>
			<Col sm="12">
				<Card>
					<div className="profile-img-style" style={{ padding: '25px' }}>
						<UserTitle name={name} time={time} />
						<hr style={{ margin: '10px 0 20px' }} />
						<P>
							<div dangerouslySetInnerHTML={{ __html: content }} />
						</P>
						<UserFooter likes={likes} comments={comments} />
					</div>
				</Card>
			</Col>
		</Fragment>
	);
};
export default UserDetails3;
