import React from "react";
import ObjectTypeCharacteristicSelect from "./CharacteristicsSelect/ObjectTypeCharacteristicSelect";

export const RegularText = {
    label: "Текст",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
        align: {
            label: "Выравнивание",
            type: "radio",
            options: [
                { label: "Слева", value: "left" },
                { label: "По центру", value: "center" },
                { label: "Справа", value: "right" },
            ],
        },
        DocumentData: {
            type: "object",
            label: "Характеристики",
            objectFields: {
                Characteristic: {
                    label: "Связать поле с",
                    type: "object",
                    objectFields: {
                        id: {
                            type: "custom",
                            render: ({ onChange, value }) => {
                                return <>
                                    <ObjectTypeCharacteristicSelect onChange={(e) => onChange(e.value)} value={value}/>
                                </>
                            }
                        },
                    }
                }
            }
        }
    },
    defaultProps: {
        children: "Текст",
    },
    render: ({ children, align }) => {
        return <span style={{textAlign: align, display: "block" }}>{children}</span>;
    },
};