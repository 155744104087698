import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Input, Label, Col, Container, Row } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Plus } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import { Breadcrumbs, Btn, P } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import CommonModal from "../../../UiKits/Modals/common/modal";
import TableEmailAdressList from "./TableEmailAdressList";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const Yandex360 = () => {
	const [connection, setConnection] = useState([]);
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`/api/connection.json`)
			.then((response) => {
				setConnection(response.data.connection);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [showButton, setShowButton] = useState(false);

	const tokenRefresh = () => {
		window.YaAuthSuggest.init(
			{
				client_id: connection.clientId,
				response_type: "token",
				redirect_uri: connection.redirect_uri,
			},
			`${process.env.PUBLIC_URL}/app/corporate-mail-api/Yandex360/token-accept`,
			{
				view: "button",
				parentId: "buttonContainerId",
				buttonView: "main",
				buttonTheme: "light",
				buttonSize: "m",
				buttonBorderRadius: 8,
			}
		)
			.then(({ handler }) => {
				handler();
			})
			.then((data) => {
				console.log("Сообщение с токеном", data);
				setToken(data);
			})
			.catch((error) => console.log("Обработка ошибки", error));
	};

	const [modal, setModal] = useState(false);
	const [edit, setEdit] = useState(null);

	const toggle = (ObjectId) => {
		if (typeof ObjectId === "object" && ObjectId.hasOwnProperty("clientId")) {
			setEdit(true);
			setName(ObjectId.name);
			setToken(ObjectId.token);
			setIdentifier(ObjectId.clientId);
			setTokenLifetime(ObjectId.tokenLifetime);
			setLastTokenUpdate(ObjectId.lastTokenUpdate);
		} else {
			setEdit(false);
			setName("");
			setToken("");
			setIdentifier("");
		}
		setModal(!modal);
	};

	const createSystem = () => {
		if (name === "" || identifier === "" || token === "") {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const newConnection = {
				name: name,
				clientId: identifier,
				token: token,
			};
			// axios
			// 	.post(`${NewsApi}createNews`, newConnection, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Новость добавлена!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error('Ошибка получения данных:', error);
			// 	});
		}
	};

	const editSystem = () => {
		if (name === "" || identifier === "" || token === "") {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const updConnection = {
				name: name,
				clientId: identifier,
				token: token,
			};
			// axios
			// 	.put(`${NewsApi}updateNews`, updConnection, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Новость изменена!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error('Ошибка получения данных:', error);
			// 	});
		}
	};

	const [token, setToken] = useState("");
	const [name, setName] = useState("");
	const [identifier, setIdentifier] = useState("");
	const [lastTokenUpdate, setLastTokenUpdate] = useState(new Date());
	const [tokenLifetime, setTokenLifetime] = useState(new Date());

	function calculateDaysDifference(date1, date2) {
		const firstDate = new Date(date1);
		const secondDate = new Date(date2);

		const difference = Math.abs(firstDate - secondDate);

		const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));

		let pluralForm = "";
		if (
			differenceInDays !== 1 &&
			(differenceInDays % 10 === 1 || differenceInDays % 10 === 2 || differenceInDays % 10 === 3 || differenceInDays % 10 === 4) &&
			(differenceInDays < 5 || differenceInDays > 20)
		) {
			pluralForm = "дня";
		} else {
			pluralForm = "дней";
		}
		const result = differenceInDays + " " + pluralForm;
		return result;
	}

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	function formatDateTime(dateTimeStr) {
		const date = new Date(dateTimeStr);
		const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

		const day = date.getDate();
		const month = months[date.getMonth()];
		const hours = date.getHours();
		const minutes = date.getMinutes();

		const formattedDate = `${day} ${month} в ${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

		return formattedDate;
	}

	function compareDates(dateString) {
		var currentDate = new Date();
		var receivedDate = new Date(dateString);

		return receivedDate <= currentDate;
	}

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Управление корпоративной почтой в Яндекс API 360" parent="Почта" title="Управление корпоративной почтой в Яндекс API 360" />
			<Container fluid={true}>
				<div className="email-wrap">
					<Card>
						<CardBody style={{ padding: "40px" }}>
							{connection ? (
								<div className="row gallery-with-description mt-4 mb-4">
									<div className="col-xl-4" style={{ cursor: "pointer" }} onClick={() => toggle(connection)}>
										<a>
											<div
												style={{
													display: "flex",
													borderTop: "3px solid #f6f7f9",
													marginBottom: "2rem",
													borderRadius: "10px",
													padding: "16px",
													justifyContent: "center",
													flexDirection: "column",
												}}
											>
												<Col>
													<div className="caption">
														{compareDates(connection.tokenLifetime) ? (
															<span className={`badge badge-light-danger rounded-pill mb-2`} style={{ height: "min-content" }}>
																<i className="fa fa-exclamation-triangle"></i> Неактивный токен
															</span>
														) : (
															<span className={`badge badge-light-success rounded-pill mb-2`} style={{ height: "min-content" }}>
																<i className="fa fa-check"></i> Активный токен
															</span>
														)}

														<h4 className="mt-0">
															{connection.name} <i className="fa fa-desktop ms-2"></i>
														</h4>
														<p>{formatDateTime(connection.lastTokenUpdate)}</p>
													</div>
												</Col>
											</div>
										</a>
									</div>
								</div>
							) : (
								<Btn attrBtn={{ color: "primary", outline: true, onClick: toggle }}>
									<Plus size={"18px"} style={{ margin: "0px 0px -5px 0px" }} />
									Добавить подключение
								</Btn>
							)}
							{connection && (
								<>
									<hr />
									<TableEmailAdressList />
								</>
							)}
						</CardBody>
					</Card>
				</div>
				<CommonModal
					isOpen={modal}
					title={edit ? "Редактировать" : "Добавить"}
					toggler={edit ? editSystem : createSystem}
					togglerClose={toggle}
					closeText="Отмена"
					saveText="Сохранить"
					size="lg"
				>
					<CardBody style={{ paddingBottom: "10px", paddingLeft: "40px", paddingTop: "0", paddingRight: "20px" }}>
						<Col className="  mb-3">
							<Label className="col-sm-9 col-form-label">Название подключения</Label>
							<Row>
								<Col sm="12" className="row">
									<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
								</Col>
							</Row>
						</Col>
						<Col className=" mb-3">
							<Label className="col-sm-9 col-form-label">Идентификатор приложения (ClientID)</Label>
							<Row>
								<Col sm="12" className="row">
									<Input className="form-control" type="text" value={identifier} onChange={(e) => setIdentifier(e.target.value)} />
								</Col>
							</Row>
						</Col>
						<Col className=" mb-4">
							<Label className="col-sm-9 col-form-label">Токен</Label>
							<Row>
								<Col sm="10" className="row me-2">
									<Input className="form-control" type="text" value={token} onChange={(e) => setToken(e.target.value)} />
								</Col>
								<Col sm="2" className="row" onClick={() => setShowButton(true)}>
									<Btn attrBtn={{ color: "primary", onClick: tokenRefresh }}>
										<Tooltip title="Обновить токен" arrow placement="top">
											<i className="fa fa-refresh"></i>
										</Tooltip>
									</Btn>
								</Col>
							</Row>
						</Col>
						{showButton && (
							<Col className="d-flex mb-4" style={{ flexDirection: "column", alignItems: "center" }}>
								<div>Выполните вход с помощью</div>
								<button id="buttonContainerId" style={{ background: "transparent", border: "none" }}></button>
							</Col>
						)}
						<Row className=" mt-4">
							<Col sm="12" className="row">
								<Col className="d-flex mb-3 f-16 mt-4 " style={{ justifyContent: "space-between", color: "#898989" }}>
									<div>Дата последнего обновления токена</div> <div>{formatDate(lastTokenUpdate)}</div>
								</Col>
							</Col>
							<Col sm="12" className="row">
								<Col className="d-flex mb-2 f-16 " style={{ justifyContent: "space-between", color: "#898989" }}>
									<div>Срок жизни токена истекает через</div> <div>{calculateDaysDifference(lastTokenUpdate, tokenLifetime)}</div>
								</Col>
							</Col>
						</Row>
						<Row>
							<div className="mt-4 mb-3 p-3 b-r-8 font-primary" style={{ backgroundColor: "#7366ff20" }}>
								Данные получены из предварительно зарегистрированного приложения в API Яндекса{" "}
								<Link className=" mt-1 d-block" style={{ color: "#0000FF" }} to="https://yandex.ru/dev/api360/doc/concepts/access.html">
									Подробнее <i className="fa fa-external-link"></i>
								</Link>
							</div>
						</Row>
					</CardBody>
				</CommonModal>
			</Container>
		</Fragment>
	);
};
export default Yandex360;
