import React, { useState } from 'react';
import { Search } from 'react-feather';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Col, Input, InputGroup, InputGroupText } from 'reactstrap';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import CallerNumberFilter from './CallerNumberFilter';
import StatusFilter from './StatusFilter';

const TableCalls = ({ products }) => {

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}

	const [filters, setFilters] = useState(null);

	const callStart = (product) => {
		return `${formatDate(product.callStart)}`;
	};

	const audio = (product) => {
		return <audio controls src={product.callRecord.link} style={{ width: '270px', height: '40px' }}></audio>;
	};

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'] = { value: value, matchMode: 'contains' };

		setFilters(_filters);
		setGlobalFilterValue(value);
	};
	const [globalFilterValue, setGlobalFilterValue] = useState('');

	const renderHeader = () => {
		return (
			<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<InputGroup style={{ width: '60%' }}>
					<Input className="form-control" type="text" value={globalFilterValue} onChange={onGlobalFilterChange} />
					<InputGroupText className="bg-primary">
						<Search size={'20px'} />
					</InputGroupText>
				</InputGroup>
			</Col>
		);
	};
	const header = renderHeader();

	const handleCallStartFilterChange = (value, name) => {
		let _filters = { ...filters };
		_filters[name] = { value: value, matchMode: 'contains' };
		setFilters(_filters);
	};

	return (
		<>
			<DataTable removableSort filters={filters} onFilter={(e) => setFilters(e.filters)} filterDisplay="row" header={header} globalFilterFields={['number', 'callStart', 'callerPhone', 'receiverPhone', 'duration', 'conversation']} size="large" value={products} tableStyle={{ minWidth: '50rem' }} scrollable paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
				<Column sortable field="number" header="№" filter filterElement={<CallerNumberFilter name="number" filterValue={filters && filters['number'] ? filters['number'].value : ''} onFilterChange={handleCallStartFilterChange} />} style={{ minWidth: '8rem' }} />
				<Column sortable field={callStart} header="Начало звонка" filter filterElement={<CallerNumberFilter name="callStart" filterValue={filters && filters['callStart'] ? filters['callStart'].value : ''} onFilterChange={handleCallStartFilterChange} />} style={{ minWidth: '14rem' }} />
				<Column sortable field="callerPhone" header="Номер звонящего" filter filterElement={<CallerNumberFilter name="callerPhone" filterValue={filters && filters['callerPhone'] ? filters['callerPhone'].value : ''} onFilterChange={handleCallStartFilterChange} />} style={{ minWidth: '13rem' }} />
				<Column sortable field="receiverPhone" header="Номер принявшего" filter filterElement={<CallerNumberFilter name="receiverPhone" filterValue={filters && filters['receiverPhone'] ? filters['receiverPhone'].value : ''} onFilterChange={handleCallStartFilterChange} />} style={{ minWidth: '13rem' }} />
				<Column field="callRecord" header="Запись звонка" body={audio} style={{ minWidth: '300px' }} />
				<Column sortable field="callStatus" header="Статус" style={{ minWidth: '12rem' }} filter filterElement={<StatusFilter name="callStatus" filterValue={filters && filters['callStatus'] ? filters['callStatus'].value : null} onFilterChange={handleCallStartFilterChange} />} body={(rowData) => rowData.callStatus.text} />
				<Column sortable field="duration" header="Длительность" filter filterElement={<CallerNumberFilter name="duration" filterValue={filters && filters['duration'] ? filters['duration'].value : ''} onFilterChange={handleCallStartFilterChange} />} style={{ minWidth: '10rem' }} />
				<Column sortable field="conversation" header="Разговор" filter filterElement={<CallerNumberFilter name="conversation" filterValue={filters && filters['conversation'] ? filters['conversation'].value : ''} onFilterChange={handleCallStartFilterChange} />} style={{ minWidth: '10rem' }} />
			</DataTable>
		</>
	);
};

export default TableCalls;
