import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import CommonModal from '../CommonModal';
import { Label, Input, CardBody, Col } from "reactstrap";
import Select from "react-select";
import FilterComponent from "../../Catalog/FilterComponent";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { useModulesContext } from "../../../../_helper/Modules/ModulesProvider";
import { useKeycloak } from '@react-keycloak/web';
import { ExportDataApi, ModulesApi } from '../../../../api';
import { useExportDataContext } from "../../../../_helper/ExportData/ExportDataProvider";


const TableServicesFieldsSettings = () => {
    const { keycloak } = useKeycloak();
    const { companyData, allCompanies, getAllCompanies } = useCompanyContext();
    const companyId = companyData.map((item) => (item.attributes.companyId[0]));
    const { servicesRequests, setServicesRequests, getServicesRequests, createServiceRequest, updateServiceRequest, deleteServiceRequest, getDataTypes, dataTypes, dataTypesOptions } = useExportDataContext();
    const { services, getServices } = useModulesContext();
    const [servicesOptions, setServicesOptions] = useState([]);
    const [views, setViews] = useState([]);
    const [viewsOptions, setViewsOptions] = useState([]);
    const [currentViewsOptions, setCurrentViewsOptions] = useState([]);
    const [companiesOptions, setCompaniesOptions] = useState([]);


    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);

    const handleClose = () => {
        setShowModal(false);
        setEdit(false);
        setCurrentServiceRequest({
            id: null,
            serviceId: "",
            viewId: "",
            companyId: "",
            dataTypeId: ""
        });

    };

    const handleChangeService = async (serviceOption) => {
        setCurrentServiceRequest(prevValues => ({
            ...prevValues,
            serviceId: serviceOption.value
        }));

        var service = services.find(obj => obj.id == serviceOption.value);

        const currentDomain = window.location.hostname;
        const isLocalhost = currentDomain === 'localhost';
        const url = `${isLocalhost ? serviceOption.label === "IMaps" ? "https://localhost:7128/" : "https://localhost:7021/" : service.serviceUrl}${service.servicePrefix + "/"}getViews`;
        //const url = `${isLocalhost ? "https://localhost:7021/" : service.serviceUrl}${service.servicePrefix + "/"}getViews`;


        try {
            const response = await axios.get(url, {
                headers: { authorization: `bearer ${keycloak.token}` }
            });
            console.log(`response for service ${service.id}:`, response.data);
            const respviews = JSON.parse(response.data);
            const respviewsoptions = respviews.map(view => ({
                label: view.ViewDisplayName,
                value: view.Id,
                serviceid: service.id
            }));
            setCurrentViewsOptions(respviewsoptions);
        } catch (error) {
            console.error(`error fetching views for service ${service.id}:`, error);
        }
    };

    useEffect(() => {
        getServicesRequests();
        getDataTypes();
    }, []);

    useEffect(() => {
        getServices();
        getAllCompanies();
    }, [servicesRequests]);

    useEffect(() => {
        fetchViewsForServiceRequests();
    }, [services])

    useEffect(() => {
        if (services.length > 0) {
            const servicesOptions = services.map(service => ({
                label: service.name,
                value: service.id
            }));
            setServicesOptions(servicesOptions);
        }
    }, [services]);

    useEffect(() => {
        if (allCompanies && Array.isArray(allCompanies.companies) && allCompanies.companies.length > 0) {
            const respCompaniesOptions = allCompanies.companies.map(company => ({
                label: company.shortLegalName,
                value: company.id
            }));
            setCompaniesOptions(respCompaniesOptions);
        }
    }, [allCompanies]);


    const fetchViewsForServiceRequests = async () => {
        let allViews = [];
        let allViewsOptions = [];

        const filteredServices = services.filter(service => {
            if (companyId.length === 0 && service.companyId === null) {
                return true;
            }
            return companyId.includes(service.companyId);
        });

        for (const service of filteredServices.filter(service => servicesRequests.some(request => request.serviceId === service.id))) {
            const currentDomain = window.location.hostname;
            const isLocalhost = currentDomain === 'localhost';
            //const url = `${isLocalhost ? "https://localhost:7021/" : service.serviceUrl}${service.servicePrefix + "/"}getViews`;
            const url = `${isLocalhost ? service.name === "IMaps" ? "https://localhost:7128/" : "https://localhost:7021/" : service.serviceUrl}${service.servicePrefix + "/"}getViews`;

            try {
                const response = await axios.get(url, {
                    headers: { Authorization: `Bearer ${keycloak.token}` }
                });
                console.log(`Response for service ${service.id}:`, response.data);
                const respViews = JSON.parse(response.data);
                allViews = [...allViews, ...respViews];
                const respViewsOptions = respViews.map(view => ({
                    label: view.ViewDisplayName,
                    value: view.Id,
                    serviceId: service.id
                }));
                allViewsOptions = [...allViewsOptions, ...respViewsOptions];
            } catch (error) {
                console.error(`Error fetching views for service ${service.id}:`, error);
            }
        }


        setViews(allViews);
        setViewsOptions(allViewsOptions);
    };


    const [edit, setEdit] = useState(false);
    const [currentServiceRequest, setCurrentServiceRequest] = useState({
        id: null,
        serviceId: "",
        viewId: "",
        companyId: "",
        dataTypeId: ""
    });
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const toggleModal = (obj) => {
        setCurrentServiceRequest(obj);
        setEdit(obj.id !== null ? true : false);

        if (obj.id !== null) {
            const currentViewsOptionsFilter = viewsOptions.filter(option => option.serviceId === obj.serviceId);
            setCurrentViewsOptions(currentViewsOptionsFilter);
        }

        handleShow();
    };

    const handleSave = async () => {

        try {
            if (edit) {
                updateServiceRequest(currentServiceRequest)
                toast.success("Запись успешно обновлена!");
            }
            else {
                createServiceRequest(currentServiceRequest);
                setServicesRequests(prev => [...prev, currentServiceRequest]);
                toast.success("Запись успешно добавлена!");
            }
        }
        catch (error) {
            console.error('Error creating service request:', error);
            toast.error("Ошибка создания записи!");
        }
        handleClose();
    };

    const deleteSR = async (id) => {
        try {
            SweetAlert.fire({
                title: "Вы уверены?",
                text: "После удаления вы не сможете восстановить этот объект!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "ОК",
                cancelButtonText: "Отмена",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    deleteServiceRequest(id);
                    setServicesRequests(prev => prev.filter(req => req.id !== id));
                    toast.success("Запись успешно удалена!");
                }
            });
        } catch (error) {
            console.error('Ошибка удаления записи:', error);
            toast.error("Ошибка удаления записи!");
        }
    };

    const filteredItems = (servicesRequests || []).filter(item => item.serviceId && item.serviceId.toLowerCase().includes(filterText.toLowerCase()));


    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                toggle={handleShow}
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const columns = [
        {
            name: "Сервис",
            selector: row => {
                if (!Array.isArray(services) || services.length === 0) return 'Не найдено';
                const option = services.find(obj => obj.id === row.serviceId);
                return option ? option.name : 'Не найдено';
            },
            sortable: true,
        },
        {
            name: "Представление",
            selector: row => {
                if (!Array.isArray(views) || views.length === 0) return 'Не найдено';
                const option = views.find(obj => obj.Id === row.viewId);
                return option ? option.ViewDisplayName : 'Не найдено';
            },
            sortable: true,
        },
        {
            name: "Организация",
            selector: row => {
                if (!Array.isArray(allCompanies.companies) || allCompanies.companies.length === 0) return 'Не найдено';
                const option = allCompanies.companies.find(obj => obj.id === row.companyId);
                return option ? option.shortLegalName : 'Не найдено';
            },
            sortable: true,
        },
        {
            name: "Тип файла данных",
            selector: row => {
                if (!Array.isArray(dataTypes) || dataTypes.length === 0) return 'Не найдено';
                const option = dataTypes.find(obj => obj.id === row.dataTypeId);
                return option ? option.typeName : 'Не найдено';
            },
            sortable: true,
        },
        {
            name: "",
            button: true,
            cell: row => (
                <div style={{ display: "flex" }}>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggleModal(row)}>
                            <Edit size={"18px"} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteSR(row.id)}>
                            <Trash2 size={"18px"} />
                        </div>
                    </Tooltip>
                </div>
            ),
        }
    ];


    return (
        <>
            <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortField="serviceId"
                noDataComponent={"В данной таблице ещё нет записей."}
                striped
                pagination
                subHeader
                subHeaderComponent={subHeaderComponent}
                paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
            />
            <CommonModal
                isOpen={showModal}
                title={edit ? "Редактировать запись" : "Добавить запись"}
                toggler={handleShow}
                togglerClose={handleClose}
                closeText="Отмена"
                saveText="Сохранить"
                size="lg"
                onSave={handleSave}
            >
                <CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>

                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Сервис</Label>
                        <Col sm="11">
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={servicesOptions}
                                value={servicesOptions.find(obj => obj.value === currentServiceRequest.serviceId)}
                                onChange={selectedOption => handleChangeService(selectedOption)}
                                className="js-example-basic-single col-sm-12"
                            />
                        </Col>
                    </Col>

                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Представление</Label>
                        <Col sm="11">
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={Array.isArray(currentViewsOptions) ? currentViewsOptions : []}
                                value={
                                    currentServiceRequest.viewId !== ""
                                        ? currentViewsOptions.find(obj => obj.value === currentServiceRequest.viewId)
                                        : { label: "Не выбран сервис", value: "" }
                                }
                                onChange={(selectedOption) => setCurrentServiceRequest({ ...currentServiceRequest, viewId: selectedOption.value })}
                                className="js-example-basic-single col-sm-12"
                            />
                        </Col>
                    </Col>




                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Организация</Label>
                        <Col sm="11">
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={companiesOptions}
                                value={companiesOptions.find(obj => obj.value === currentServiceRequest.companyId)}
                                onChange={(selectedOption) => setCurrentServiceRequest({ ...currentServiceRequest, companyId: selectedOption.value })}
                                className="js-example-basic-single col-sm-12"
                            />
                        </Col>
                    </Col>




                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Тип файла данных</Label>
                        <Col sm="11">
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={dataTypesOptions}
                                value={dataTypesOptions.find(obj => obj.value === currentServiceRequest.dataTypeId)}
                                onChange={(selectedOption) => setCurrentServiceRequest({ ...currentServiceRequest, dataTypeId: selectedOption.value })}
                                className="js-example-basic-single col-sm-12"
                            />
                        </Col>
                    </Col>

                </CardBody>
            </CommonModal>
        </>
    );
};

export default TableServicesFieldsSettings;
