import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Select from 'react-select';
import SweetAlert from 'sweetalert2';
import { CardBody, Col, Label, Input, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import FilterComponent from '../FilterComponent';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Btn } from '../../../../../AbstractElements';
import { Plus } from 'react-feather';
import { TwinAuthApi } from '../../../../../api';
import { useLocation } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const CompanyGroups = ({ subgroups, companyId, groupData }) => {
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const [modal, setModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [data, setData] = useState([]);
	const [groupName, setGroupName] = useState(null);
	const [subgroupEditName, setSubgroupEditName] = useState();
	const [rolesList, setRolesList] = useState([]);
	const [selectedRole, setSelectedRole] = useState();
	const [groupRolesList, setGroupRolesList] = useState([]);

	const toggle = () => {
		setModal(!modal);
	};

	const toggleEdit = (groupName) => {
		setEditModal(!editModal);
		setSubgroupEditName(groupName);
	};
	
	useEffect(() => {
		groupData && groupData.realmRoles !== undefined && setGroupRolesList(groupData.realmRoles.map((role) => ({
			label: role,
			value: role,
		})));
	}, [groupData]);

	useEffect(() => {
        if (editModal){
			axios
            .get(`${TwinAuthApi}get-group-by-path?path=/${groupData.name}/${subgroupEditName}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
				//console.log(response);
                setRolesList(JSON.parse(response.data).realmRoles.map((role) => ({
					label: role,
					value: role,
				})));
				//console.log(rolesList);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
        }
	}, [editModal]);

	if (data !== subgroups) {
		setData(subgroups);
	}

	const columns = [
		{
			name: 'Название',
			selector: (row) => row.name,
			sortable: true,
			hide: 'sm',
			width: '50%',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => toggleEdit(row.name)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteRow(row.name)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
			width: '5%',
		},
	];

	const deleteRow = (groupName) => {
        console.log(groupData);
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then(function (isConfirm) {
			if (isConfirm) {
				axios
                    .delete(`${TwinAuthApi}delete-subgroup?subgroupName=${groupName}&groupName=${groupData.name}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                    .then((response) => {
                    })
                    .catch((error) => {
                        console.error('Ошибка получения данных:', error);
                    });
			}
		});
	};

	function AddGroupForm() {
		return (
			<Col className="row col-sm-12 mb-3">
				<Label className="col-form-label">Название группы</Label>
				<Col sm="11">
					<Input className="form-control" value={groupName} type="tel" placeholder="Название группы" onChange={(e) => setGroupName(e.target.value)} />
				</Col>
			</Col>
		);
	}

	const EditGroupForm = () => {
		return (
			<Col className="row col-sm-12 mb-3">
				<Label className="col-form-label">Название роли</Label>
				<Row sm="12">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Col sm="11">
							<Select type="tel" placeholder="Выберите роль" value={selectedRole} options={groupRolesList.filter((role) => !rolesList.map((roleList) => roleList.value).includes(role.value) && role.value !== "Company")} onChange={(option) => setSelectedRole(option)} />
						</Col>
						<Btn attrBtn={{ color: 'white', className: 'btn btn-primary', outline: true, onClick: () => addSubgroupToRole(selectedRole) }}>
							<Plus size={'18px'} style={{ margin: '0px 0px -5px 0px' }} /> Добавить
						</Btn>
					</div>
				</Row>
				<DataTable columns={tableColumns} data={rolesList} noDataComponent={'В данной таблице ещё нет записей.'} defaultSortField="name" striped/>
			</Col>
		);
	}

	const addSubgroupToCompany = () => {
		axios
            .post(`${TwinAuthApi}create-child-group?groupName=${groupData.name}&groupChildrenName=${groupName}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
				toast.success('Группа добавлена!', {
					position: toast.POSITION.TOP_RIGHT,
				});
            })
            .catch((error) => {
				toast.error('Ошибка при добавлении!', {
					position: toast.POSITION.TOP_RIGHT,
				});
                console.error('Ошибка получения данных:', error);
            });
			setModal(!modal);
	};

	const addSubgroupToRole = () => {
		axios
            .post(`${TwinAuthApi}add-subgroup-to-role?groupName=${groupData.name}&subgroupName=${subgroupEditName}&roleName=${selectedRole.value}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
				toast.success('Роль добавлена!', {
					position: toast.POSITION.TOP_RIGHT,
				});
            })
            .catch((error) => {
				toast.error('Ошибка при добавлении!', {
					position: toast.POSITION.TOP_RIGHT,
				});
                console.error('Ошибка получения данных:', error);
            });
	};

	const removeSubgroupFromRole = (roleName) => {
		axios
            .delete(`${TwinAuthApi}remove-subgroup-from-role?groupName=${groupData.name}&subgroupName=${subgroupEditName}&roleName=${roleName}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
				toast.success('Роль добавлена!', {
					position: toast.POSITION.TOP_RIGHT,
				});
            })
            .catch((error) => {
				toast.error('Ошибка при добавлении!', {
					position: toast.POSITION.TOP_RIGHT,
				});
                console.error('Ошибка получения данных:', error);
            });
	};

	let numberedData = data;

	if (numberedData !== undefined) {
		numberedData = data.map((row, index) => {
			return {
				...row,
				rowNumber: index + 1,
			};
		});
	}

	const tableColumns = [
		{
			name: 'Название',
			selector: (row) => `${row.value}`,
			sortable: true,
			center: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => removeSubgroupFromRole(row.value)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];


	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	let filteredItems = numberedData;
	if (numberedData !== undefined) {
		filteredItems = numberedData.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable columns={columns} data={filteredItems} noDataComponent={'В данной таблице ещё нет записей.'} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={'Добавить группу'} toggler={addSubgroupToCompany} togglerClose={toggle} saveText="Готово" size="xl">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<AddGroupForm />
				</CardBody>
			</CommonModal>
			<CommonModal isOpen={editModal} title={'Редактирование группы'} toggler={toggleEdit} togglerClose={toggleEdit} saveText="Готово" size="xl">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<EditGroupForm />
				</CardBody>
			</CommonModal>
		</>
	);
};

export default CompanyGroups;
