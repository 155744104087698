// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-hover .action-button-panel {
	display: block;
}

.action-button-panel {
	display: none;
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	background-color: white;
	border: 1px solid #ddd;
	padding: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	z-index: 10;
}

.action-buttons .action-icon {
	cursor: pointer;
	color: #007bff;
	margin-left: 5px;
}

	.action-buttons .action-icon:hover {
		color: #0056b3;
	}
`, "",{"version":3,"sources":["webpack://./src/Components/Application/Specifications/TableSpecifications.css"],"names":[],"mappings":"AAAA;CACC,cAAc;AACf;;AAEA;CACC,aAAa;CACb,kBAAkB;CAClB,WAAW;CACX,QAAQ;CACR,2BAA2B;CAC3B,uBAAuB;CACvB,sBAAsB;CACtB,YAAY;CACZ,yCAAyC;CACzC,WAAW;AACZ;;AAEA;CACC,eAAe;CACf,cAAc;CACd,gBAAgB;AACjB;;CAEC;EACC,cAAc;CACf","sourcesContent":[".row-hover .action-button-panel {\n\tdisplay: block;\n}\n\n.action-button-panel {\n\tdisplay: none;\n\tposition: absolute;\n\tright: 10px;\n\ttop: 50%;\n\ttransform: translateY(-50%);\n\tbackground-color: white;\n\tborder: 1px solid #ddd;\n\tpadding: 5px;\n\tbox-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);\n\tz-index: 10;\n}\n\n.action-buttons .action-icon {\n\tcursor: pointer;\n\tcolor: #007bff;\n\tmargin-left: 5px;\n}\n\n\t.action-buttons .action-icon:hover {\n\t\tcolor: #0056b3;\n\t}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
