import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import axios from 'axios';

import { Breadcrumbs } from '../../../../AbstractElements';
import TableResources from './TableResources';

import { DeliveryApi } from '../../../../api';

const DeliveryServices = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Службы доставки" parent="Доставка" title="Службы доставки" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<TableResources />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default DeliveryServices;
