import React, { Fragment, useState, useEffect } from 'react';
import { Btn, P } from '../../../../../AbstractElements';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { ModulesApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from '../../../../../_helper/Company/CompanyProvider';

const DataTablePayment = () => {
	const [data, setData] = useState([]);
	const { companyData } = useCompanyContext()
	const { keycloak } = useKeycloak();
	const download = () => {
		const link = document.createElement('a');
		link.href = '/pay.pdf';
		link.download = 'payment.pdf';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	const tableColumns = [
		{
			name: 'Название модуля',
			selector: (row) => `${row.name}`,
			sortable: true,
			center: true,
		},
		{
			name: 'Цена',
			selector: (row) => `${row.price} ₽`,
			sortable: true,
			center: true,
		},
	];

	useEffect(() => {
		companyData && 
		axios
			.get(`${ModulesApi}get-company-selected-modules/${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				let selected = JSON.parse(response.data.selectedModules);
				console.log(selected);
				selected && setData(selected);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<DataTable data={data} columns={tableColumns} striped={true} center={true} pagination selectableRows highlightOnHover pointerOnHover paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:' }} />
			<div className="modal-footer" style={{ padding: '40px' }}>
				<Btn attrBtn={{ color: 'primary', onClick: download }}>Оплатить по счёту</Btn>
			</div>
		</Fragment>
	);
};

export default DataTablePayment;
