import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Label, Input, Button, CardBody, Col } from "reactstrap";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { useKeycloak } from '@react-keycloak/web';
import { ExportDataApi } from '../../../../api';
import Editor from "@monaco-editor/react";
import { useExportDataContext } from "../../../../_helper/ExportData/ExportDataProvider";

const UploadLayout = () => {
    const { getLayoutDataTypeDetails, updateLayoutDataType, getDataTypes, dataTypesOptions,
        getResponses, responsesOptions, verifyLayoutDataType, setLayoutData, layoutData } = useExportDataContext();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const layoutId = queryParams.get("layoutId");
    const { companyData } = useCompanyContext();
    const companyId = companyData.map((item) => item.attributes.companyId[0]);
    const [verifyResult, setVerifyResult] = useState(null);


    useEffect(() => {
        getLayoutDataTypeDetails(layoutId);
        getResponses();
        getDataTypes();
    }, []);

    const handleSave = async () => {
        try {
            layoutData.companyId = companyId;
            layoutData.id = layoutData.Id;
            handleVerifyLayout().then((result) => {
                if (result === "OK") {
                    updateLayoutDataType(layoutData);
                    toast.success("Шаблон успешно сохранен!");
                }
            })
        } catch (error) {
            console.error('Ошибка сохранения шаблона:', error);
            toast.error("Ошибка сохранения шаблона!");
        }
    };

    const handleVerifyLayout = async () => {
        try {
            verifyLayoutDataType(layoutId, layoutData.LayoutValue, companyId).then((result) => {
                if (result === "Ok") {
                    toast.success("Шаблон успешно проверен!");
                    setVerifyResult(null);
                }
                else {
                    setVerifyResult(result);
                }
                return result;
            });
        } catch (error) {
            console.error('Ошибка проверки шаблона:', error);
            toast.error("Ошибка проверки шаблона!");
        }
    };

    const handleChange = (field, value) => {
        setLayoutData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <Col md="12" xs="12">
                    <Label>Название шаблона</Label>
                    <Input
                        className="form-control"
                        value={layoutData.LayoutName}
                        onChange={(e) => setLayoutData(prevState => ({ ...prevState, LayoutName: e.target.value }))}
                        required
                    />
                </Col>
            </div>
            <CardBody>
                <Col className="mb-3">
                    <Label>Получатель</Label>
                    <Select
                        options={responsesOptions}
                        placeholder="Начните вводить..."
                        noOptionsMessage={() => "Нет результатов"}
                        value={responsesOptions.find(option => option.value === layoutData.ResponseId)}
                        onChange={(selectedOption) => handleChange('ResponseId', selectedOption.value)}
                    />
                </Col>
                <Col className="mb-3">
                    <Label>Тип файла данных</Label>
                    <Select
                        options={dataTypesOptions}
                        placeholder="Начните вводить..."
                        noOptionsMessage={() => "Нет результатов"}
                        value={dataTypesOptions.find(option => option.value === layoutData.DataTypeId)}
                        onChange={(selectedOption) => handleChange('DataTypeId', selectedOption.value)}
                    />
                </Col>
                <Col className="mb-3">
                    <Label>Шаблон файла</Label>
                    <Editor
                        height="400px"
                        defaultLanguage={layoutData.DataTypeId === 'json' ? 'json' : layoutData.DataTypeId === 'xml' ? 'xml' : 'html'}
                        value={layoutData.LayoutValue}
                        onChange={(value) => handleChange('LayoutValue', value)}
                    />
                    <Label style={{ color: 'red' }}>{verifyResult}</Label>
                </Col>
                <Button color="primary" onClick={handleSave}>Сохранить</Button>
                <Button color="secondary" style={{ marginLeft: '15px' }} onClick={handleVerifyLayout}>Проверить</Button>
            </CardBody>
        </div>
    );
};

export default UploadLayout;
