import React, { Fragment } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import { Breadcrumbs } from '../../../../AbstractElements';

import TableServiceCategories from './TableServiceCategories';

const ServiceCategories = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Категории услуг" parent="Услуги" title="Категории услуг" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<TableServiceCategories />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default ServiceCategories;
