import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "reactstrap";
import { useLocation, useParams } from "react-router-dom";
import ViewPage from "../EditPagesList/ViewPage";
import { CmsApi } from "../../../../../api";
import { concatResults } from "../../../../../concatResults";
import { useKeycloak } from "@react-keycloak/web";
import { useCompanyContext } from "../../../../../_helper/Company/CompanyProvider";

const ClientViewCustomSite = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const page = queryParams.get("page");
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { siteId } = useParams();

	const plotNumber = queryParams.get("plotNumber");
	const phoneNumber = queryParams.get("phoneNumber");
	const name = queryParams.get("name");
	const comment = queryParams.get("comment");
	const isAgree = queryParams.get("isAgree") === 'true';

	const [containsModal, setContainsModal] = useState(false);
	const [size, setSize] = useState("");
	const [content, setContent] = useState("");
	const [data, setData] = useState("");

	useEffect(() => {
		axios
			.get(`${CmsApi}getSites`, { params: { query: "" } }, {
				headers: {
					Authorization: `Bearer ${keycloak.token}`,
					CompanyId: companyData.map((item) => item.attributes.companyId[0])
				}
			})
			.then((response) => {
				const foundItem = response.data.sites.find((item) => item.id === siteId);
				let foundObject = {};
				if (page) {
					foundObject = foundItem.pages.find((item) => item.alias === page);
				}
				const letcontainsModal = foundObject.content.includes("<StandardPricingcard");
				setContainsModal(letcontainsModal);
				const outputString = foundObject.content.replace(
					/<p>\/\*Ваше пакетное предложение по (.*?)\*\/<\/p><figure class='table'><table><tbody><tr><td><i>Здесь будет отображен контент пакетного предложения<\/i><\/td><\/tr><\/tbody><\/table><\/figure>/g,
					""
				);
				console.log(foundItem);

				const savedFormContent = JSON.parse(foundObject.content);

				const { html, css, js } = savedFormContent;

				const parser = new DOMParser();
				const doc = parser.parseFromString(html, 'text/html');

				if (plotNumber) {
					const plotNumberInput = doc.getElementById('plotNumber');
					if (plotNumberInput) plotNumberInput.setAttribute('value', plotNumber);
				}
				if (phoneNumber) {
					const phoneNumberInput = doc.getElementById('phone');
					if (phoneNumberInput) phoneNumberInput.setAttribute('value', phoneNumber);
				}
				if (name) {
					const nameInput = doc.getElementById('name');
					if (nameInput) nameInput.setAttribute('value', name);
				}
				if (comment) {
					const commentTextarea = doc.getElementById('comment');
					if (commentTextarea) commentTextarea.innerHTML = comment;
				}
				if (isAgree) {
					const isAgreeCheckbox = doc.querySelector('input[name="consent"]');
					if (isAgreeCheckbox) isAgreeCheckbox.checked = true;
				}

				const updatedHtml = doc.body.innerHTML;

				const content = `
					${css}
					<body>
						${updatedHtml}
					</body>
					${js}
				`;

				setContent(content);
				var match = outputString.match(/<StandardPricingcard data=(\d+) size=(\w+)/);
				if (match) {
					console.log("Значение data:", match[1]);
					setSize(match[2]);
					axios
						.get("/api/packageOffersList.json")
						.then((response) => {
							const foundItem = concatResults(response).find((item) => item.id === match[1]);
							console.log(foundItem.packages);
							setData(foundItem.packages);
						})
						.catch((error) => {
							console.error("Ошибка получения данных:", error);
						});
				}
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	return (
		<>
			<Container fluid={true} className="g-0">
				<ViewPage content={content} containsModal={containsModal} size={size} data={data} />
			</Container>
		</>
	);
};

export default ClientViewCustomSite;
