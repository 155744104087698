import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ExternalLink } from 'react-feather';
import { Card, CardBody, Container, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import axios from 'axios';

import { Link } from 'react-router-dom';

import { Breadcrumbs, H3, Btn, P } from '../../../../../AbstractElements';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { EducationApi } from '../../../../../api';
import TestComponent from '../../../Testing/ViewTesting/TestComponent';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const ViewLesson = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const lessonId = queryParams.get('lessonId');
	const viewed = queryParams.get('viewed') === 'true';
	const completed = queryParams.get('completed') === 'true';

	const [pageTitle, setPageTitle] = useState('');

	const [pageDes, setPageDes] = useState('');

	const [data, setData] = useState([]);

	const [modal, setModal] = useState(false);

	const [elements, setElements] = useState([]);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	let currentUserId = `${keycloak.tokenParsed.sub}`;

	const toggle = () => {
		setModal(!modal);
	};

	useEffect(() => {
		getDetails();
	}, []);

	const getDetails = async () => {
		try {
			const response = await axios.get(`${EducationApi}get-course-lesson/${lessonId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			var lesson = concatResults(response);
			setData(lesson);
			setPageTitle(lesson.name);
			setPageDes(lesson.description)

			if (viewed === false) {
				await axios.post(`${EducationApi}create-lesson-result`,
					{
						courseStudentId: currentUserId,
						lessonId: lessonId,
						status: "",
						text: ""
					},
					{
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
					});
			}


			const content = lesson.content.sort((a, b) => a.order - b.order);
			for (const item of content) {
				try {

					if (item.contentType === 'video' || item.contentType === 'img') {

						await axios.get(`${EducationApi}get-content-file/${item.file.id}`, {
							responseType: 'blob',
							headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },

						}).then((responseFile) => {
							const content = new File([concatResults(responseFile)], item.file.fileName, { type: item.type });
							const fileInfo = {
								id: item.file.id,
								name: item.file.fileName,
								content: content,
								url: URL.createObjectURL(content)
							};
							console.log(fileInfo)
							addElement(item.contentType, fileInfo, item.id, item.order);

						}).catch((error) => {
							console.error('Ошибка получения файла:', error);
						})
					}
					else {
						if (item.contentType === 'text') {
							addElement(item.contentType, item.content, item.id, item.order);
						}
						else {
							if (item.content.length !== 0) {
								addElement(item.contentType, JSON.parse(item.content), item.id, item.order);
							}
						}
					}

				}
				catch (innerError) {
					console.error('Внутренняя ошибка:', innerError);
				}

			}
		}
		catch (error) {
			console.error('Ошибка получения данных:', error);
		}
	}

	function addElement(type, content, id, order = elements.length) {

		if (!elements.some(element => element.id === id)) {
			setElements((prevElements) => {
				const updatedElements = [...prevElements, { type, content, id, order }];
				console.log(updatedElements);
				return updatedElements;
			});
		}
	}

	function getFileExtension(fileName) {
		const parts = fileName.split('.');

		if (parts.length > 1) {
			return parts[parts.length - 1];
		} else {
			return '';
		}
	}

	//useEffect(() => {
	//	axios
	//		.get('/api/lessonList.json')
	//		.then((response) => {
	//			const foundItem = response.data.find((item) => item.Id === lessonId);
	//			console.log(foundItem);
	//			setData(foundItem);
	//			setPageTitle(foundItem.Name);
	//			setPageDes(foundItem.Description);
	//		})
	//		.catch((error) => {
	//			console.error('Ошибка получения данных:', error);
	//		});
	//}, []);



	//useEffect(() => {
	//	axios
	//		.get(`${EducationApi}get-lesson-content/${lessonId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
	//		.then((response) => {
	//			const foundItem = response.data;
	//			setData(foundItem);
	//		})
	//		.catch((error) => {
	//			console.error('Ошибка получения данных:', error);
	//		});
	//}, []);

	function getFileExtension(fileName) {
		const parts = fileName.split('.');

		if (parts.length > 1) {
			return parts[parts.length - 1];
		} else {
			return '';
		}
	}

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Просмотр занятия" subParent="Просмотр курса" parent="Обучение" title="Просмотр занятия" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardHeader>
							<H3>{pageTitle}</H3>
							<div style={{ color: '#898989' }} className="f-18">
								{pageDes}
							</div>
						</CardHeader>
						<CardBody>
							{elements
								.sort((a, b) => a.order - b.order)
								.map((element) => (
									<div key={element.id} className="col-md-12" style={{ alignItems: 'flex-end', display: 'flex' }}>
										<div className="mb-3 mt-3" style={{ flexGrow: '1', textAlign: 'center' }}>
											{element.type === 'text' ? (
												<div dangerouslySetInnerHTML={{ __html: element.content }} />
											) : element.type === 'img' ? (
												<img src={element.content.url} alt={element.content.name} style={{ maxWidth: '600px' }} />
											) : element.type === 'video' ? (
												<video width="640" height="360" controls>
													<source src={element.content.url} type="video/mp4" />
													Ваш браузер не поддерживает видео в формате HTML5.
												</video>
											) : element.type === 'file' ? (
												<Link to={element.content.url} target="_blank">
													{(getFileExtension(element.content.name) === 'xls' || getFileExtension(element.content.name) === 'xlsx') && <i className="fa fa-file-excel-o me-2"></i>}
													{getFileExtension(element.content.name) === 'pdf' && <i className="fa fa-file-pdf-o me-2"></i>}
													{getFileExtension(element.content.name) === 'txt' && <i className="fa fa-file-text-o me-2"></i>}
													{(getFileExtension(element.content.name) === 'doc' || getFileExtension(element.content.name) === 'docx') && <i className="fa fa-file-word-o me-2"></i>}
													{(getFileExtension(element.content.name) === 'ppt' || getFileExtension(element.content.name) === 'pptx') && <i className="fa fa-file-powerpoint-o me-2"></i>}

													{element.content.name}
												</Link>
											) : element.type === 'link' ? (
												<Link to={element.content.url} target="_blank">
													<ExternalLink size={'18px'} style={{ margin: ' 0 5px -4px 0' }} />
													{element.content.text}
												</Link>
											) : element.type === 'test' ? (
												<TestComponent idTestParam={element.content.id} />
											) : (
												null
											)}
										</div>
									</div>
								))}
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default ViewLesson;
