import React from 'react';

const LightCardBox = ({ data }) => {
	const titleColor = {
		Dead: '#fc4438',
		Alive: '#5cbd53',
		LongTimeResponse: '#ffaa05',
	};

	return (
		<div className="light-card balance-card widget-hover" style={{ height: '67px', width: '167px' }}>
			<div className="svg-box" style={{ background: titleColor[data.lastStatus], fontSize: '22px', color: '#fff' }}>
				{data.AvgElapsed}
			</div>
			<div>
				<span className="f-light" style={{ fontSize: '18px', textTransform: 'capitalize' }}>
					{data.lastCheck}
				</span>
				{data.difference && (
					<span className={` font-${data.color}`} style={{ display: 'block', margin: '0', fontSize: '16px' }}>
						{data.difference}
					</span>
				)}
			</div>
		</div>
	);
};

export default LightCardBox;
