import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Media } from 'reactstrap';
import { Plus, Minus } from 'react-feather';
import axios from 'axios';
import { H6 } from '../../../AbstractElements';
import {concatResults} from "../../../concatResults";

const EmployeeList = ({}) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/employeesDemandsForm.json`)
			.then((response) => {
				setData(concatResults(response));
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	var images = require.context('../../../assets/images', true);

	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	const [curEmployee, setCurEmployee] = useState('');

	return (
		<Col className="mt-4">
			{data.length > 0 ? (
				data.map((item) => (
					<Card className={`b-l-info ${item.id === curEmployee.id ? 'b-info ' : ''}`} key={item.id}>
						<CardBody style={{ padding: '15px' }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div className="media-size-email" style={{ position: 'relative', padding: '5px' }}>
									<Media body className="me-3 rounded-circle img-60" src={item.image ? dynamicImage(item.image) : require('../../../assets/images/user/7.jpg')} alt="" />
								</div>
								<div style={{ flexGrow: '1' }}>
									<div className="mb-1" style={{ color: '#808092' }}>
										{item.role}
									</div>
									<H6>{item.name}</H6>
								</div>
								{item.id === curEmployee.id ? (
									<div style={{ fontSize: '32px', border: '3px solid #ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => setCurEmployee('')}>
										<Minus style={{ margin: '0 0px 4px 7px' }} />
									</div>
								) : (
									<div style={{ fontSize: '32px', background: '#ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => setCurEmployee(item)}>
										<Plus style={{ margin: '0 0px 2px 8px' }} />
									</div>
								)}
							</div>
						</CardBody>
					</Card>
				))
			) : (
				<p>Загрузка...</p>
			)}
		</Col>
	);
};

export default EmployeeList;
