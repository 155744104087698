import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { Label, Input, CardBody, Col } from "reactstrap";
import FilterComponent from "../../Catalog/FilterComponent";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { useKeycloak } from '@react-keycloak/web';
import { ExportDataApi } from '../../../../api';
import { useExportDataContext } from "../../../../_helper/ExportData/ExportDataProvider";

const TableFileDataTypes = () => {


    const { dataTypes, setDataTypes, getDataTypes, createDataType, updateDataType, deleteDataType } = useExportDataContext();

    const { companyData } = useCompanyContext();
    const companyId = companyData.map((item) => (item.attributes.companyId[0]));

    useEffect(() => {
        getDataTypes();
    }, []);

    const [modal, setModal] = useState(false);
    const [edit, setEdit] = useState(false);
    const [currentObject, setCurrentObject] = useState({
        id: null,
        typeName: "",
    });
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const toggleModal = (obj) => {
        setCurrentObject(obj);
        setEdit(obj.id !== null ? true : false);
        setModal(!modal);
    };

    const handleShow = () => { setModal(true); setEdit(false); }

    const handleSave = async () => {
        const requestData = {
            ...currentObject,
            CompanyId: companyId
        };

        try {
            if (edit) {
                updateDataType(requestData);
                toast.success("Запись успешно обновлена!");
            } else {
                createDataType(requestData);
                toast.success("Запись успешно добавлена!");
            }
        }
        catch (error) {
            console.error('Ошибка:', error);
        }
        setModal(false);
    };

    const deleteObject = async (id) => {
        try {

            SweetAlert.fire({
                title: "Вы уверены?",
                text: "После удаления вы не сможете восстановить этот объект!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "ОК",
                cancelButtonText: "Отмена",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    deleteDataType(id);
                    setDataTypes(prev => prev.filter(req => req.id !== id));
                    toast.success("Запись успешно удалена!");
                }
            });
        } catch (error) {
            console.error('Ошибка удаления записи:', error);
            toast.error("Ошибка удаления записи!");
        }
    };

    const filteredItems = dataTypes.filter(item => item.typeName && item.typeName.toLowerCase().includes(filterText.toLowerCase()));

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                toggle={handleShow}
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const columns = [
        {
            name: "Id",
            selector: row => row.id,
            sortable: true,
        },
        {
            name: "Наименование",
            selector: row => row.typeName,
            sortable: true,
        },
        {
            name: "",
            button: true,
            cell: row => (
                <div style={{ display: "flex" }}>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggleModal(row)}>
                            <Edit size={"18px"} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteObject(row.id)}>
                            <Trash2 size={"18px"} />
                        </div>
                    </Tooltip>
                </div>
            ),
        }
    ];

    return (
        <>
            <DataTable
                columns={columns}
                data={filteredItems}
                noDataComponent={"В данной таблице ещё нет записей."}
                striped
                pagination
                subHeader
                subHeaderComponent={subHeaderComponent}
                paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
            />
            <CommonModal
                isOpen={modal}
                title={edit ? "Редактировать запись" : "Добавить запись"}
                toggler={handleSave}
                togglerClose={toggleModal}
                closeText="Отмена"
                saveText="Сохранить"
                size="lg"
            >
                <CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Тип файла</Label>
                        <Col sm="11">
                            <Input
                                className="form-control"
                                placeholder="Введите наименование типа"
                                value={currentObject.typeName}
                                onChange={(e) => setCurrentObject({ ...currentObject, typeName: e.target.value })}
                            />
                        </Col>
                    </Col>
                </CardBody>
            </CommonModal>
        </>
    );
};

export default TableFileDataTypes;
