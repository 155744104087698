import React, { useMemo } from "react";
import styles from "./task-list-table.module.css";

const localeDateStringCache = {};
const toLocaleDateStringFactory = (locale) => (date, dateTimeOptions) => {
	const key = date.toString();
	let lds = localeDateStringCache[key];
	if (!lds) {
		lds = date.toLocaleDateString("ru-RU", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
		localeDateStringCache[key] = lds;
	}
	return lds;
};
const dateTimeOptions = {
	weekday: "short",
	year: "numeric",
	month: "long",
	day: "numeric",
};

const TaskListTableDefault = ({ rowHeight = 50, tasks, fontFamily, fontSize, locale, onExpanderClick }) => {
	const toLocaleDateString = useMemo(() => toLocaleDateStringFactory(locale), [locale]);

	return (
		<>
			<div
				className={styles.taskListWrapper}
				style={{
					fontFamily: fontFamily,
					fontSize: fontSize,
				}}
			>
				{tasks.map((t) => {
					let expanderSymbol = "";
					if (t.hideChildren === false) {
						expanderSymbol = "▼";
					} else if (t.hideChildren === true) {
						expanderSymbol = "▶";
					}

					return (
						<div className={styles.taskListTableRow} style={{ height: rowHeight }} key={`${t.id}row`}>
							<div
								className={styles.taskListCell}
								style={{
									minWidth: "170px",
									maxWidth: "170px",
								}}
								title={t.name}
							>
								<div className={styles.taskListNameWrapper}>
									<div className={expanderSymbol ? styles.taskListExpander : styles.taskListEmptyExpander} onClick={() => onExpanderClick(t)}>
										{expanderSymbol}
									</div>
									<div>{t.name}</div>
								</div>
							</div>
							<div
								className={styles.taskListCell}
								style={{
									minWidth: "130px",
									maxWidth: "130px",
								}}
							>
								<div className="ms-3">{toLocaleDateString(t.start, dateTimeOptions)}</div>
							</div>
							<div
								className={styles.taskListCell}
								style={{
									minWidth: "130px",
									maxWidth: "130px",
								}}
							>
								<div className="ms-3">{toLocaleDateString(t.end, dateTimeOptions)}</div>
							</div>
						</div>
					);
				})}
			</div>
			<div style={{ borderLeft: "#e6e4e4 1px solid" }}></div>
		</>
	);
};
export default TaskListTableDefault;
