import React, { Fragment } from 'react';
import { Container, Row, Card, CardBody, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import DataTableClientsCategoryList from "./DataTableClientsCategoryList";

const ClientsCategoryList = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Категории клиентов" parent="Клиенты" title="Категории клиентов" />
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="12" md="12" className="box-col-12">
                            <Card>
                                <CardBody>
                                    <DataTableClientsCategoryList />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default ClientsCategoryList;
