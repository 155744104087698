// LeftPanel.js
import React, { useEffect } from 'react';
import { Col, Label } from 'reactstrap';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import { Btn } from '../../../../../AbstractElements';

const ElementProductTable = ({ setIsOverLeftPanel, content = 'Список товаров', products, onProductsChange }) => {


	useEffect(() => {
		const productsPreLoaded = [
			{
				id: '1',
				name: 'Торт',
				description: 'Торт для дня рождения',
				cost: 1500,
				quantity: 1,
			},
		];

		products = productsPreLoaded;

		if (onProductsChange) {
			onProductsChange(productsPreLoaded);
		}
	}, []);

	const columns = [
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: 'Описание',
			selector: (row) => `${row.description}`,
			sortable: true,
		},
		{
			name: 'Стоимость',
			selector: (row) => `${row.cost}`,
			sortable: true,
		},
		{
			name: 'Кол-во',
			selector: (row) => `${row.quantity}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];
	return (
		<>
			<div
				draggable="true"
				className={`col-sm-11 mb-3`}
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'ProductsOrderTable');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Label className="col-sm-9 col-form-label">{content}</Label>
				<Col sm="12">
					<Btn attrBtn={{ color: 'primary', size: 'sm', className: 'mb-2' }}>Добавить товар</Btn>
					<DataTable columns={columns} data={products} defaultSortField="name" striped pagination paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
				</Col>
			</div>
		</>
	);
};

export default ElementProductTable;
