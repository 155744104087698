import React from "react";
import {Col, Label} from "reactstrap";

export const InputTextArea = {
    label: "Большое окно для ввода текста",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
    },
    defaultProps: {
        children: "Большое окно для ввода текста",
    },
    render: ({ children }) => {
        return <div>
            <Label className="col-sm-9 col-form-label">{children}</Label>
            <Col sm="12">
					<textarea
                        className="form-control"
                        rows={'3'}
                        //placeholder="Подсказка для ввода"
                    />
            </Col>
        </div>;
    },
};