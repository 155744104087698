import { H5 } from '../../../AbstractElements';
import React, { Fragment } from 'react';
import { CardHeader } from 'reactstrap';

const HeaderCard = ({ title, span1, span2, mainClasses, center }) => {
	return (
		<Fragment>
			<CardHeader className={`${mainClasses ? mainClasses : ''}`}>
				<H5 style={center ? { textAlign: 'center' } : {}}>{title}</H5>
				{span1 ? <span style={{ color: '#f73164', fontSize: '13px' }}>{span1}</span> : ''}
				{span2 ? (
					<span>
						Поля, отмеченные знаком <span style={{ color: '#f73164', fontSize: '18px' }}>*</span> являются обязательными для заполнения{' '}
					</span>
				) : (
					''
				)}
			</CardHeader>
		</Fragment>
	);
};

export default HeaderCard;
