import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Row, CardBody, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import CommonModal from "../../../UiKits/Modals/common/modal";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import {Edit, Trash2} from "react-feather";
import SweetAlert from "sweetalert2";
import {toast} from "react-toastify";
import FilterComponent from "../../Widgets/InteractivePlanMap/FilterComponent";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import Select from "react-select";
import {useCounterpartiesContext} from "../../../../_helper/Counterparties/CounterpartiesProvider";
import {useLocation} from "react-router-dom";
import uuid from "react-uuid";
import PhoneInput from "react-phone-number-input";
import {Textarea} from "../../../../Constant";
import axios from "axios";
import {CounterpartiesApi} from "../../../../api";

const ContactPersons = () => {
    const [id, setId] = useState();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [fieldsSelect, setFieldsSelect] = useState([]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const counterpartyId = queryParams.get("counterpartyId");
    const {keycloak} = useKeycloak();
    const {companyData} = useCompanyContext();

    const {
        counterpartyContacts,
        getCounterpartyContacts,
        deleteCounterpartyContact,
        createCounterpartyContact,
        updateCounterpartyContact,
        counterpartySettings,
        getCounterpartySettings,
    } = useCounterpartiesContext();

    useEffect(() => {
        counterpartyContacts && setFilteredItems(counterpartyContacts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, counterpartyContacts]);

    useEffect(() => {
        getCounterpartyContacts(counterpartyId);
        getCounterpartySettings();
    }, [keycloak.tokenParsed, companyData]);

    const toggle = (ObjectId) => {
        if (ObjectId instanceof Object && !ObjectId.preventDefault) {
            setName(ObjectId.name);
            setId(ObjectId.id);
            setComment(ObjectId.comment);
            setPhone(ObjectId.phone);
            setEmail(ObjectId.email);
        } else {
            setName("");
            setId("");
            setComment("");
            setPhone("");
            setEmail("");
        }
        setModal(!modal);
    }

    const deleteRow = (fieldId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'После удаления вы не сможете восстановить этот объект!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                deleteCounterpartyContact(fieldId, counterpartyId).then(() => {
                    toast.success('Поле удалено!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                })
            }
        });
    };

    const send = () => {
        if (name === '') {
            toast.error("Заполнените поле имени!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        else {
            if (phone === "" ||
                email === "")
            {
                toast.error("Заполнените поле email-а или телефона!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else {
                if (id) {
                    updateCounterpartyContact(id, counterpartyId, name, email, phone, comment);
                }
                else {
                    createCounterpartyContact(counterpartyId, name, email, phone, comment);
                }
            }
            toggle();
        }
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={() => toggle(null)} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const columns = [
        {
            name: "ФИО контактного лица",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.email ?? "",
            sortable: true,
        },
        {
            name: "Телефон",
            selector: row => row.phone ?? "",
            sortable: true,
        },
        {
            name: "Комментарий",
            selector: row => row?.commentary ?? "",
            sortable: true,
        },
        {
            name: "",
            button: true,
            cell: row => (
                <div style={{ display: "flex" }}>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
                            <Edit size={"18px"} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteRow(row.id)}>
                            <Trash2 size={"18px"} />
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление обязательного для заполнения поля'} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    ФИО контакта
                                </Label>
                                <Col sm="11">
                                    <Input className="form-control" type="tel" placeholder="ФИО контакта" value={name} onChange={(e) => setName(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Номер телефона
                                </Label>
                                <Col sm="11">
                                    <PhoneInput
                                        international
                                        defaultCountry={counterpartySettings?.defaultCountryCode ? counterpartySettings?.defaultCountryCode : null}
                                        value={phone}
                                        onChange={setPhone}
                                        numberInputProps={{className: "form-control form-control"}}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Email
                                </Label>
                                <Col sm="11">
                                    <Input className="form-control" type="tel" placeholder="example@email.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label">Комментарий</Label>
                                <Col sm="11">=
                                    <Textarea
                                        className="form-control"
                                        placeholder="Комментарий"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    >
                                        {comment}
                                    </Textarea>
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={columns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default ContactPersons;