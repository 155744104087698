import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Trash2 } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import FilterComponent from './FilterComponent';
import { useKeycloak } from "@react-keycloak/web";
import { useInteractivePlanMapsContext } from "../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import { TiTick } from "react-icons/ti";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useCompanyContext } from "../../../../../_helper/Company/CompanyProvider";
import { GiCharacter } from "react-icons/gi";
import { SettingsApplications, SettingsApplicationsRounded } from "@mui/icons-material";

const DataTablePlanMapObjects = () => {
    const { keycloak } = useKeycloak();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mapId = queryParams.get('objectId');

    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [objectTypeId, setObjectTypeId] = useState('');

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [objectTypesOptions, setObjectTypesOptions] = useState([]);
    const { planMapsObjects, getInteractivePlanMapsObjects, planMapsObjectsTypes, getInteractivePlanMapsObjectsTypes, createInteractivePlanMapsObject, deleteInteractivePlanMapsObjects } = useInteractivePlanMapsContext();
    const { companyData } = useCompanyContext();

    useEffect(() => {
        planMapsObjects && setFilteredItems(planMapsObjects.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, planMapsObjects]);

    useEffect(() => {
        planMapsObjectsTypes && setObjectTypesOptions(planMapsObjectsTypes.map((item) => ({ value: item.id, label: item.name })));
    }, [planMapsObjectsTypes]);

    useEffect(() => {
        mapId && getInteractivePlanMapsObjects(mapId);
        getInteractivePlanMapsObjectsTypes();
    }, [keycloak.tokenParsed, mapId, companyData]);

    const toggle = () => {
        //setRuleId('');
        //setRoleRole('');

        setModal(!modal);
    };

    const handleAddRow = () => {
        if (objectTypeId !== '' || name !== '' || number !== '') {
            setModal(!modal);
            createInteractivePlanMapsObject(mapId, name, number, objectTypeId, true).then(() => {
                getInteractivePlanMapsObjects(mapId);
            });
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const deleteRow = (remId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'Данное действие не отменит отправку оповщений!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(() => {
            deleteInteractivePlanMapsObjects(remId).then(() => {
                getInteractivePlanMapsObjects(mapId);
            });
        });
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} mapId={mapId} />;
    }, [filterText, resetPaginationToggle]);

    const getObjectType = (id) => {
        let type = '';
        if (getInteractivePlanMapsObjectsTypes) {
            let findType = planMapsObjectsTypes.find((item) => item.id === id);
            console.log(findType)
            if (findType) {
                type = findType.name;
            }
        }
        return type;
    };

    const tableColumns = [
        {
            name: 'Название',
            selector: (row) => <Link to={`/widgets/interactive-plan-maps-object-values?objectId=${row.id}`}>{row.name}</Link>,
            sortable: true,
            center: false,
        },
        {
            name: 'Номер',
            selector: (row) => `${row.number}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Тип',
            selector: (row) => `${getObjectType(row.objectTypeId)}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Активен',
            selector: (row) => row.activated ? <TiTick></TiTick> : '',
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{ display: 'flex' }}>
                    {/*<Tooltip title="Характеристики" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => deleteRow(row.id)}>
                            <SettingsApplications size={'18px'}/>
                        </div>
                    </Tooltip>*/}
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }}
                            onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'} />
                        </div>
                    </Tooltip>

                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление нового объекта'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Название
                                </Label>
                                <Col sm="11">
                                    <Input
                                        className="form-control"
                                        value={name}
                                        placeholder="Название объекта"
                                        onChange={(e) => setName(e.target.value)} />
                                </Col>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Номер объекта
                                </Label>
                                <Col sm="11">
                                    <Input
                                        className="form-control"
                                        value={number}
                                        placeholder="Номер объекта"
                                        onChange={(e) => setNumber(e.target.value)} />
                                </Col>
                                <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                    <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                        Тип объекта
                                    </Label>
                                    <Col sm="11">
                                        <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={objectTypesOptions} onChange={(e) => setObjectTypeId(e?.value)} className="js-example-basic-single col-sm-12" />
                                    </Col>
                                </FormGroup>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTablePlanMapObjects;
