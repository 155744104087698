import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Link } from 'react-router-dom';
//import FilterComponent from '../../Goods/PurchaseHistory/FilterComponent';
import FilterComponent from "../../Widgets/InteractivePlanMap/FilterComponent";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import CommonModal from "../../../UiKits/Modals/common/modal";
import {toast} from "react-toastify";

const TableClients = () => {
	const [data, setData] = useState([]);
	const [modal, setModal] = useState(false);
	const [fullName, setFullName] = useState('');
	const [name, setName] = useState('');
	const [lastName, setLastName] = useState('');
	const [patronymic, setPatronymic] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const {keycloak} = useKeycloak();
	const {companyData} = useCompanyContext();
	const {getCompanyClients, companyClients, createClient, deleteClient, cardSettings, getCardSettings} = useClientsContext();

	useEffect(() => {
		companyData && keycloak && getCompanyClients();
		companyData && keycloak && getCardSettings();
		console.log(companyData);
	}, [companyData, keycloak]);

	const columns = [
		{
			name: 'Имя',
			selector: (row) => <Link to={`/app/clients-app/client-card?clientId=${row.id}`}>{row.fullName ? row.fullName : `${row.lastName} ${row.name} ${row.patronymic}`}</Link>,
			sortable: true,
			width: '15%',
		},
		{
			name: 'Телефон',
			selector: (row) => `${row.phone}`,
			sortable: false,
			hide: 'sm',
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
		},
		/*{
			name: 'Продано',
			selector: (row) => `${row.sales}`,
			sortable: true,
			hide: 'md',
		},
		{
			name: 'Визиты',
			selector: (row) => `${row.visits}`,
			sortable: true,
			hide: 'md',
		},
		{
			name: 'Скидка',
			selector: (row) => `${row.discount}`,
			sortable: true,
			hide: 'md',
		},
		{
			name: 'Последний визит',
			selector: (row) => `${row.lastVisit}`,
			sortable: true,
			hide: 'md',
			width: '14%',
		},
		{
			name: 'Первый визит',
			selector: (row) => `${row.firstVisit}`,
			sortable: true,
			hide: 'md',
			width: '12%',
		},*/
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					{/* <Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }}>
							<Edit size={'18px'} />
						</div>
					</Tooltip> */}
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				deleteClient(userId).then(() => {
					toast.success('Клиент удалён', {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
				/*axios
					.delete(`/api/clientsList.json/${userId}`)
					.then((response) => {
						console.log(response.data);
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						// Обновить список после удаления
						axios
							.get('/api/clientsList.json')
							.then((response) => {
								setData(response.data);
							})
							.catch((error) => {
								console.error('Произошла ошибка при обновлении списка:', error);
							});
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении объекта:', error);
					});*/
			}
		});
	};

	const toggle = () => {
		setModal(!modal);
	}

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = companyClients && companyClients.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const handleSendRequest = () => {
		//name, lastName, patronymic,
		//email, lastVisit, firstVisit, gender, discount, cardNumber, description, disableOnlineRegistration
		let full = fullName;
		if (cardSettings && cardSettings?.useSeparatedClientName === true) {
			full = `${lastName} ${name} ${patronymic}`;
		}
		createClient(full, name, lastName, patronymic, phone, email, null, null, null, null, null, null, null).then(() => {
			//getClientCategories();
			setFullName('');
			setName('');
			setLastName('');
			setPatronymic('');
			setPhone('');
			setEmail('');
			toast.success('Клиент добавлен', {
				position: toast.POSITION.TOP_RIGHT,
			});
		});

		toggle();
	};

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={() => toggle()} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<CommonModal isOpen={modal} title={`Добавление нового клиента`} toggler={handleSendRequest} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
				<CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
					<Form className="form theme-form">
						<Row>
							{!cardSettings || cardSettings?.useSeparatedClientName === false ?
							<div>
								<FormGroup className="row" style={{ flexDirection: 'column' }}>
									<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
										ФИО
									</Label>
									<Col sm="11">
										<Input
											className="form-control"
											value={fullName}
											placeholder="ФИО клиента"
											onChange={(e) => setFullName(e.target.value)} />
									</Col>
								</FormGroup>
							</div> :
							<div>
								<FormGroup className="row" style={{ flexDirection: 'column' }}>
									<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
										Фамилия
									</Label>
									<Col sm="11">
										<Input
											className="form-control"
											value={lastName}
											placeholder="Фамилия клиента"
											onChange={(e) => setLastName(e.target.value)} />
									</Col>
								</FormGroup>
								<FormGroup className="row" style={{ flexDirection: 'column' }}>
									<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
										Имя
									</Label>
									<Col sm="11">
										<Input
											className="form-control"
											value={name}
											placeholder="Имя клиента"
											onChange={(e) => setName(e.target.value)} />
									</Col>
								</FormGroup>
								<FormGroup className="row" style={{ flexDirection: 'column' }}>
									<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
										Отчество
									</Label>
									<Col sm="11">
										<Input
											className="form-control"
											value={patronymic}
											placeholder="Отчество клиента"
											onChange={(e) => setPatronymic(e.target.value)} />
									</Col>
								</FormGroup>
							</div>}

							<FormGroup className="row" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
									Телефон
								</Label>
								<Col sm="11">
									<Input
										className="form-control"
										value={phone}
										placeholder="Телефон клиента"
										onChange={(e) => setPhone(e.target.value)} />
								</Col>
							</FormGroup>
							<FormGroup className="row" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
									Email
								</Label>
								<Col sm="11">
									<Input
										className="form-control"
										value={email}
										placeholder="Email клиента"
										onChange={(e) => setEmail(e.target.value)} />
								</Col>
							</FormGroup>
						</Row>
					</Form>
				</CardBody>
			</CommonModal>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</>
	);
};

export default TableClients;
