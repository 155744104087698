import React, { useState, useEffect } from 'react';
import { Card, CardBody, Media, Row, Col } from 'reactstrap';
import axios from 'axios';
import { Plus, Minus } from 'react-feather';
import { H6 } from '../../../AbstractElements';

const ProductsList = ({}) => {
	const [products, setProducts] = useState('');
	useEffect(() => {
		axios
			.get(`/api/ProductsResult.json`)
			.then((response) => {
				setProducts(response.data[0].data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	var images = require.context('../../../assets/images', true);

	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	const [curservices, setCurServices] = useState('');

	return (
		<Col className="mt-4">
			{products.length > 0 ? (
				products.map((item) => (
					<Card className={`b-l-success ${item.id === curservices.id ? 'b-success ' : ''}`} key={item.id}>
						<CardBody style={{ padding: '15px' }}>
							<Row>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className="media-size-email" style={{ position: 'relative', padding: '5px' }}>
										<Media body className="me-3 rounded-circle img-60" src={item.image ? dynamicImage(item.image) : require('../../../assets/images/user/7.jpg')} alt="" />
									</div>
									<div style={{ flexGrow: '1' }}>
										<H6>{item.pagetitle}</H6>
										<div className={' m-t-5 mb-2'} style={{ color: '#6c757d' }}>
											{item.description}
										</div>
										<H6 attrH6={{ className: 'font-success f-w-600' }}>{item.ProductPrice.Price} ₽</H6>
									</div>
									{item.id === curservices.id ? (
										<div style={{ fontSize: '32px', border: '3px solid #ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => setCurServices('')}>
											<Minus style={{ margin: '0 0px 4px 7px' }} />
										</div>
									) : (
										<div style={{ fontSize: '32px', background: '#ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => setCurServices(item)}>
											<Plus style={{ margin: '0 0px 2px 8px' }} />
										</div>
									)}
								</div>
							</Row>
						</CardBody>
					</Card>
				))
			) : (
				<p>Загрузка...</p>
			)}
		</Col>
	);
};

export default ProductsList;
