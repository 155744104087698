import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import FilterComponent from '../../FilterComponent';
import Select from 'react-select';
import {useModulesContext} from "../../../../../_helper/Modules/ModulesProvider";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const DataTableModuleSectionAccessRights = () => {
    const { moduleGroups, getModuleGroups, companySelectedModulesRules, getCompanySelectedModulesRules, createModuleGroup, deleteModuleGroup } = useModulesContext();
    const { keycloak } = useKeycloak();
    const { getCompanySubgroups, companySubGroups, companyData, getAllCompanies, allCompanies } = useCompanyContext();

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState(moduleGroups);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [modulesRulesOptions, setModulesRulesOptions] = useState([]);
    const [subgroupsOptions, setSubgroupsOptions] = useState([]);

    const [groupName, setGroupName] = useState('');
    const [selectedRuleId, setSelectedRuleId] = useState('');

    useEffect(() => {
        moduleGroups && setFilteredItems(moduleGroups.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, moduleGroups]);

    useEffect(() => {
        companySubGroups && setSubgroupsOptions(companySubGroups.map((item) => ({label: item.name, value: item.id})));
    }, [companySubGroups]);

    useEffect(() => {
        companySelectedModulesRules && setModulesRulesOptions(companySelectedModulesRules.map((item) => ({label: item.displayName, value: item.id})));
    }, [companySelectedModulesRules]);

    useEffect(() => {
        getModuleGroups();
        getCompanySelectedModulesRules();
        getAllCompanies();
        getCompanySubgroups();
    }, [keycloak.tokenParsed]);

    const toggle = () => {
        setGroupName('');
        setSelectedRuleId('');

        setModal(!modal);
    };

    const handleAddRow = () => {
        if (groupName !== '' && selectedRuleId !== '') {
            setModal(!modal);
            createModuleGroup(selectedRuleId, companyData[0]?.attributes?.companyId[0], groupName);
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const deleteRow = (remId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'Данное действие не отменит отправку оповщений!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(() => {
            deleteModuleGroup(remId);
        });
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const getCompanyName = (id) => {
        let companyName = '';
        if (allCompanies && allCompanies.companies) {
            let findCompanyName = allCompanies.companies.find((item) => item.id === id);
            if (findCompanyName) {
                companyName = findCompanyName.shortLegalName;
            }
        }
        return companyName;
    };

    const getModuleRule = (id) => {
        let ruleName = '';
        if (companySelectedModulesRules) {
            let findRuleName = companySelectedModulesRules.find((item) => item.id === id);
            if (findRuleName) {
                ruleName = findRuleName.displayName;
            }
        }
        return ruleName;
    };

    const tableColumns = [
        {
            name: 'Название',
            selector: (row) => `${row.groupName}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Компания',
            selector: (row) => `${getCompanyName(row.companyId)}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Правило',
            selector: (row) => `${getModuleRule(row.ruleId)}`,
            sortable: true,
            center: true,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{display: 'flex'}}>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'}/>
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление новой группы компании'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column'}}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Правило
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={modulesRulesOptions} onChange={(e) => setSelectedRuleId(e.value)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column'}}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Группа
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={subgroupsOptions} onChange={(e) => setGroupName(e.label)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTableModuleSectionAccessRights;
