import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit, Eye } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import FilterComponent from '../../../Goods/PurchaseHistory/FilterComponent';

const TableDiplomaList = ({ data }) => {
	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} `;

		return formattedDate;
	}

	const columns = [
		{
			name: '№',
			selector: (row) => `${row.id}`,
			sortable: true,
		},
		{
			name: 'Имя ученика',
			selector: (row) => `${row.studentName}`,
			sortable: true,
		},
		{
			name: 'Название курса',
			selector: (row) => `${row.courseName}`,
			sortable: true,
		},
		{
			name: 'Дата выдачи',
			selector: (row) => `${formatDate(row.date)}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Просмотр" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/app/viewing-diploma?diplomaId=${row.diplomaFormId}`} style={{ marginLeft: '7px' }}>
							<Eye size={'18px'} />
						</Link>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => { });
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data ? data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1) : [];

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="Название курса" striped pagination subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</>
	);
};

export default TableDiplomaList;
