const initialContent = {
	blocks: [
		{
			key: '637gr',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: '6rgr4',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: '8n1n4',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: 'a6cia',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: '4g9nk',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: 'ct48m',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: 'asit8',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: '5l73o',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: '95o8c',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: 'course_name',
			text: 'название курса',
			type: 'header-four',
			depth: 0,
			inlineStyleRanges: [
				{
					offset: 0,
					length: 16,
					style: 'fontsize-18',
				},
				{
					offset: 0,
					length: 16,
					style: 'fontfamily-Impact',
				},
				{
					offset: 0,
					length: 16,
					style: 'color-#555555',
				},
			],
			entityRanges: [],
			data: {
				'text-align': 'center',
			},
		},
		{
			key: 'name',
			text: 'Имя Фамилия',
			type: 'header-one',
			depth: 0,
			inlineStyleRanges: [
				{
					offset: 0,
					length: 11,
					style: 'fontfamily-Impact',
				},
				{
					offset: 0,
					length: 11,
					style: 'color-#555555',
				},
			],
			entityRanges: [],
			data: {
				'text-align': 'center',
			},
		},
		{
			key: 'num',
			text: '№12345',
			type: 'header-six',
			depth: 0,
			inlineStyleRanges: [
				{
					offset: 0,
					length: 6,
					style: 'fontfamily-Tahoma',
				},
				{
					offset: 0,
					length: 6,
					style: 'color-#555555',
				},
			],
			entityRanges: [],
			data: {
				'text-align': 'center',
			},
		},
		{
			key: 'e6ean',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: 'b8gqb',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: 'a3rrf',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		},
		{
			key: 'date',
			text: '12.02.2024                          ',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [
				{
					offset: 0,
					length: 36,
					style: 'fontsize-18',
				},
			],
			entityRanges: [],
			data: {
				'text-align': 'right',
			},
		},
		{
			key: '9doq',
			text: '',
			type: 'unstyled',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {
				'text-align': 'right',
			},
		},
	],
	entityMap: {},
};
export default initialContent;
