import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, CardBody, Card, NavItem, Nav, NavLink } from "reactstrap";
import { Btn, H1, H2, H3, H4, H5, P } from "../../AbstractElements";
import logo from "../../assets/images/logo/ERP_logo.png";
import { User } from "react-feather";

const NavMenu = () => {
	const [currentPage, setCurrentPage] = useState(window.location.pathname);

	const navItems = [
		{ path: "/educational-platform", label: "Образовательная платформа" },
		{ path: "/project-management-system", label: "Система управления проектами" },
		{ path: "/order-system", label: "Система заказов" },
		{ path: "/social-network", label: "Социальная сеть" },
	];

	return (
		<Row>
			<Col sm="12" className="d-flex" style={{ padding: "15px 30vh", alignItems: "center", justifyContent: "space-between" }}>
				<img src={logo} alt="logo" style={{ maxHeight: "90%" }} />
				<Nav className="landing-menu d-flex me-4" style={{ flex: "1", justifyContent: "end" }}>
					{navItems.map((item, index) => (
						<NavItem key={index}>
							<NavLink href={item.path} className={currentPage === item.path ? "active" : ""}>
								{item.label}
							</NavLink>
						</NavItem>
					))}
				</Nav>
				<span className="font-primary ms-4">
					<User size={"18px"} style={{ marginBottom: "-3px" }} className="me-2" />
					Вход
				</span>
			</Col>
		</Row>
	);
};
export default NavMenu;
