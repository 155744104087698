import React from 'react';
import { Input } from 'reactstrap';
import DigitFilterInput from './DigitFilterInput'; 

const CallerNumberFilter = ({ filterValue, onFilterChange, name }) => {
    const handleFilterChange = (value) => {
        onFilterChange(value, name);
    };

    const isNumericField = name === "specificationLinesCount"
        || name === "foundProductsCount"
        || name === "checkedProductsCount"
        || name === "costSum";

    if (isNumericField) {
        return (
            <DigitFilterInput
                value={filterValue}
                onChange={handleFilterChange}
            />
        );
    } else {
        return (
            <Input
                placeholder="Поиск.."
                value={filterValue}
                onChange={(e) => handleFilterChange(e.target.value)}
                style={{ height: '30px' }}
            />
        );
    }
};

export default CallerNumberFilter;
