import React, { useMemo, useState, useEffect, useContext } from "react";
import { Trash2, Edit, Plus } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import Select from "react-select";
import { Label, Input, CardBody, Col, Row, Button } from "reactstrap";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { Switch } from '@mui/material';
import FilterComponent from "../../Catalog/FilterComponent";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { useKeycloak } from "@react-keycloak/web";
import { CmsApi } from '../../../../api';
import uuid from "react-uuid";
import { Link } from 'react-router-dom';

const DomainTable = ({ siteId }) => {
    const [editModal, setEditModal] = useState(false);
    const [domains, setDomains] = useState([]);
    const [edit, setEdit] = useState(false);
    const [domain, setDomain] = useState("");
    const [id, setId] = useState("");
    const [isActive, setIsActive] = useState(true);
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const [pages, setPages] = useState([]);
    const [site, setSite] = useState({});

    useEffect(() => {
        fetchDomains();
        fetchOtherData();
    }, [siteId]);

    const fetchDomains = async () => {
        var response = await axios.get(`${CmsApi}getDomains/${siteId}`, {
            headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
        });
        setDomains(response.data.domains);
    };

    const fetchOtherData = async () => {
        var response = await axios.get(`${CmsApi}getPages?siteId=${siteId}`, {
            headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
        });
        setPages(response.data.pages);

        var responseAuth = await axios.get(`${CmsApi}getSite`, { params: { siteId: siteId } }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
        setSite(responseAuth.data);
    };



    const toggle = (domain) => {
        if (domain) {
            setDomain(domain.domainAddress);
            setIsActive(domain.isActive);
            setId(domain.id);
            setEdit(true);
        }
        else {
            setDomain(domain);
            setIsActive(false);
            setId("");
            setEdit(false);
        }
        setEditModal(!editModal);
    };


    const handleDomainClick = (row) => {
        const homePage = pages.find(page => page.isHomePage);

        const alias = site.siteAuth?.serviceType === 'standart service'
            ? 'Auth'
            : homePage?.alias || pages[0]?.alias || 'Index';

        const ws = new WebSocket('ws://localhost:1120');
        ws.onopen = async () => {
            ws.send(JSON.stringify({ domain: row.domainAddress, id: row.siteId, alias: alias }));
        };
        ws.onmessage = (event) => {
            const response = JSON.parse(event.data);
            if (response.status === 'success') {
                window.open(response.url, '_blank');
            } else {
                console.error('Error from server:', response.message);
            }
            ws.close();
        };
    };



    const columns = [
        {
            name: "Домен",
            selector: row => (
                row.isActive ? (
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        handleDomainClick(row);
                    }}>{row.domainAddress}</a>
                ) : (
                    <span>{row.domainAddress}</span>
                )
            ),
            sortable: true,
        },
        {
            name: "Активность",
            selector: row => (
                <Switch
                    checked={row.isActive}
                    onChange={e => setIsActive(e.target.checked)}
                    inputProps={{ 'aria-label': '' }}
                    color="primary"
                />
            ),
            sortable: true,
        },
        {
            name: "",
            button: true,
            selector: row => (
                <div style={{ display: "flex" }}>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
                            <Edit size={"18px"} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteDomain(row.id)}>
                            <Trash2 size={"18px"} />
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const deleteDomain = (id) => {
        SweetAlert.fire({
            title: "Вы уверены?",
            text: "После удаления вы не сможете восстановить этот объект!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ОК",
            cancelButtonText: "Отмена",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                axios.delete(`${CmsApi}deletePage/${id}`)
                    .then(response => {
                        SweetAlert.fire("Удалено!", "Домен был удален.", "success");
                        fetchDomains();
                    })
                    .catch(error => console.error("Произошла ошибка при удалении:", error));
            }
        });
    };

    const saveDomain = async () => {
        const newDomain = {
            id: id,
            siteId: siteId,
            domainAddress: domain,
            isActive: isActive,
            isDelete: false
        };

        if (edit) {
            await axios.put(`${CmsApi}updateDomain`, newDomain, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
            })
                .then(response => {
                    toast.success("Домен обновлен!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setEditModal(false);
                    fetchDomains();
                })
                .catch(error => {
                    setEditModal(false);
                    toast.error("Ошибка при обновлении", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }
        else {
            newDomain.id = uuid();
            await axios.post(`${CmsApi}createDomain`, newDomain, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
            })
                .then(async response => {
                    toast.success("Домен добавлен!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });

                    setEditModal(false);
                    fetchDomains();
                })
                .catch(error => {
                    toast.error("Ошибка при добавлении", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }
    };

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = domains.filter(item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortField="domain"
                noDataComponent={"В данной таблице ещё нет записей."}
                striped
                pagination
                subHeader
                subHeaderComponent={subHeaderComponent}
                paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
            />
            <CommonModal isOpen={editModal} title={edit ? "Редактировать домен" : "Добавить домен"} toggler={saveDomain} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="md">
                <CardBody style={{ padding: "10px", paddingLeft: "20px", paddingTop: "0" }}>
                    <Row>
                        <Col md="12">
                            <Label className="col-form-label">Домен</Label>
                            <Input
                                type="text"
                                value={domain}
                                onChange={e => setDomain(e.target.value)}
                                className="form-control"
                            />
                        </Col>
                        <Col md="4">
                            <Label className="col-form-label">Активен</Label>
                            <Switch
                                checked={isActive}
                                onChange={e => setIsActive(e.target.checked)}
                                inputProps={{ 'aria-label': '' }}
                                color="primary"
                            />
                        </Col>
                    </Row>
                </CardBody>
            </CommonModal>
        </>
    );
};

export default DomainTable;