import React, { Fragment, useState, useEffect } from 'react';
import { Label, Row, Col, Input, InputGroup, InputGroupText } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import Tooltip from '@mui/material/Tooltip';
import { PhoneCall } from 'react-feather';
import {toast} from "react-toastify";
import { Btn, Image } from '../../../../AbstractElements';
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";

const ClientCardContent = ({ clientId }) => {
	const {keycloak} = useKeycloak();
	const {companyData} = useCompanyContext();
	const {getClient, client, cardSettings, getCardSettings, updateClient} = useClientsContext();

	const [data, setData] = useState([]);
	const [fullName, setFullName] = useState('');
	const [name, setName] = useState('');
	const [lastName, setLastName] = useState('');
	const [patronymic, setPatronymic] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [clientCathegory, setClientCathegory] = useState('');
	const [clientGender, setClientGender] = useState('');
	const [discont, setDiscont] = useState('');
	const [cardNumber, setCardNumber] = useState('');
	const [description, setDescription] = useState('');
	const [disableOnlineRegistration, setDisableOnlineRegistration] = useState();

	useEffect(() => {
		companyData && keycloak && getClient(clientId);
		companyData && keycloak && getCardSettings();
	}, [companyData && keycloak]);

	useEffect(() => {
		if (client) {
			console.log(client);
			setFullName(client.fullName);
			setName(client.name);
			setLastName(client.lastName);
			setPatronymic(client.patronymic);
			setPhone(client.phone);
			setEmail(client.email);
			setClientGender(client.gender);
			setDiscont(client.discount);
			setCardNumber(client.cardNumber);
			setDescription(client.description);
			setDisableOnlineRegistration(client.disableOnlineRegistration);
			//set
			//setClientCathegory(client.clientCathegory);
		}
	}, [client])

	const [url, setUrl] = useState('');
	const readUrl = (event) => {
		if (event.target.files.length === 0) return;
		var mimeType = event.target.files[0].type;

		if (mimeType.match(/image\/*/) == null) {
			return;
		}
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = (_event) => {
			setUrl(reader.result);
		};
	};

	const send = () => {
		let full = fullName;
		if (cardSettings && cardSettings?.useSeparatedClientName === true) {
			full = `${lastName} ${name} ${patronymic}`;
		}

		updateClient(clientId, full, name, lastName, patronymic, phone, email, clientGender, discont, cardNumber, description, disableOnlineRegistration)
			.then(() => toast.success('Изменения сохранены!', {
				 			position: toast.POSITION.TOP_RIGHT,
				 		}));
		// return axios
		// 	.post(` http://localhost:8000/EditMenuEnter`, data)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Изменения сохранены!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
	};

	const genders = [
		{ value: 'male', label: 'Мужской' },
		{ value: 'female', label: 'Женский' },
		{ value: 'none', label: 'Не указан' },
	];

	return (
		<Fragment>
			<Row className="mb-2">
				<Col md="8 mb-2">
					{!cardSettings || cardSettings?.useSeparatedClientName === false ? <Col className="mb-2">
						<Label className="form-label" for="validationFullName">
							Имя
						</Label>
						<input className="form-control" id="validationFullName" type="text" value={fullName} name="fName" onChange={(e) => setFullName(e.target.value)} />
					</Col> :
						<div>
							<Col className="mb-2">
								<Label className="form-label" for="validationLastName">
									Фамилия
								</Label>
								<input className="form-control" id="validationLastName" type="text" value={lastName} name="fName" onChange={(e) => setLastName(e.target.value)} />
							</Col>
							<Col className="mb-2">
								<Label className="form-label" for="validationName">
									Имя
								</Label>
								<input className="form-control" id="validationName" type="text" value={name} name="fName" onChange={(e) => setName(e.target.value)} />
							</Col>
							<Col className="mb-2">
								<Label className="form-label" for="validationPatronymic">
									Отчество
								</Label>
								<input className="form-control" id="validationPatronymic" type="text" value={patronymic} name="fName" onChange={(e) => setPatronymic(e.target.value)} />
							</Col>
						</div>
					}
					<Col className="mb-2">
						<Label>Телефон</Label>
						<div className={"d-flex"}>
							<InputGroup>
								<InputGroupText className="bg-success btn-call" style={{ cursor: 'pointer' }}>
									<Tooltip title="Вызов" arrow placement="top">
										<PhoneCall size={'18px'} color="#fff" />
									</Tooltip>
								</InputGroupText>

								<InputMask
									className="form-control"
									mask="+7 (999) 999-99-99"
									maskChar="_"
									placeholder="+7 (___) ___-__-__"
									alwaysShowMask
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
							</InputGroup>
						</div>
					</Col>
					<Col className="mb-2">
						<Label>Email</Label>
						<div className={"d-flex"}>
							<Input className="form-control" type="text" value={email} placeholder="" onChange={(e) => setEmail(e.target.value)} />
						</div>

					</Col>
					<Col className="mb-2">
						<Label className="col-form-label">Категория</Label>
						<Select
							placeholder="Выберите..."
							options={[
								{ value: 'vip', label: 'VIP' },
								{ value: 'loyal', label: 'Лояльный' },
								{ value: 'regular', label: 'Постоянный' },
							]}
							className="js-example-basic-single col-sm-12"
							isMulti
						/>
					</Col>
					<Col className="mb-2">
						<Label className="col-form-label">Пол</Label>
						<Select
							placeholder="Выберите..."
							options={genders}
							className="js-example-basic-single col-sm-12"
							value={clientGender ?
								genders.find((i) => i.value === clientGender) :
								genders.find((i) => i.value === 'none')}
							onChange={(e) => setClientGender(e?.value)}
						/>
					</Col>
					<Col className="mb-2">
						<Label className="col-form-label">Важность</Label>
						<Select
							placeholder="Выберите..."
							options={[
								{ value: 'option1', label: 'Без класса важности' },
								{ value: 'option2', label: 'еще вариант' },
								{ value: 'option3', label: 'и еще вариант' },
							]}
							className="js-example-basic-single col-sm-12"
						/>
					</Col>
					<Col className="mb-2">
						<Label>Скидка</Label>
						<InputGroup>
							<Input className="form-control" type="text" value={discont} onChange={(e) => setDiscont(e.target.value)} />
							<InputGroupText>{'%'}</InputGroupText>
						</InputGroup>
					</Col>
					<Col className="mb-2">
						<Label>Номер карты</Label>
						<Input className="form-control" value={cardNumber} type="number" placeholder="" onChange={(e) => setCardNumber(e.target.value)} />
					</Col>
					<Col className="mb-2">
						<Label>Примечание</Label>
						<textarea className="form-control" rows={'3'} placeholder="" value={description} onChange={(e) => setDescription(e.target.value)} >
							{description}
						</textarea>
					</Col>
					<Col className="mb-2">
						<div className="checkbox checkbox-primary">
							<Input id="checkbox-primary-1" type="checkbox" checked={disableOnlineRegistration ?? false} onChange={(e) => setDisableOnlineRegistration(e.target.value)} />
							<Label for="checkbox-primary-1">Запретить записываться онлайн</Label>
						</div>
					</Col>
					<Col className="mb-2">
						<Row style={{ wrap: 'nowrap' }}>
							<Label style={{ width: '20%' }}>Баланс</Label>
							<div style={{ color: '#7568ff', textDecoration: 'underline', width: '10%' }}>88р</div>
						</Row>
					</Col>
				</Col>
				<Col md="4" style={{ height: '180px' }} className="mb-2">
					<div className="profile-title">
						<div className="media">
							<Image attrImage={{ height: '180px', className: 'img-180 m-auto rounded-circle step1', alt: '', src: `${url ? url : require('../../../../assets/images/user/7.jpg')}` }} />
							<div className="icon-wrapper step2" data-intro="Change Profile image here">
								<i style={{ position: 'absolute', justifyContent: 'center', bottom: '0px', left: '60%', display: 'flex', JustifyContent: 'center', alignItems: 'center', height: '50px', width: '50px', borderRadius: '50%', backgroundColor: '#fff', cursor: 'pointer', overflow: 'hidden', margin: '0 auto', fontSize: '27px', boxShadow: '0 0 6px 3px rgba(68, 102, 242, 0.1)' }} className="icofont icofont-pencil-alt-5" onChange={(e) => readUrl(e)}>
									<input style={{ position: 'absolute', opacity: '0' }} className="upload" type="file" onChange={(e) => readUrl(e)} />
								</i>
							</div>
						</div>
					</div>
				</Col>
			</Row>
			<div className="modal-footer" style={{ paddingTop: '40px' }}>
				<Btn attrBtn={{ color: 'primary', onClick: () => send() }}>Сохранить изменения</Btn>
			</div>
		</Fragment>
	);
};
export default ClientCardContent;
