import NewsContext from '.';
import React, { useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { NewsApi } from '../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../Company/CompanyProvider";
import { concatResults } from '../../concatResults';

const NewsProvider = ({ children, props }) => {
    const context = useCreateNewsContext(props);

    return (
        <NewsContext.Provider
            value={context}
        >
            {children}
        </NewsContext.Provider>
    );
};

export function useNewsContext() {
    const context = useContext(NewsContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateNewsContext = function (props) {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const [categoriesList, setCategoriesList] = useState();
    const [userNews, setUserNews] = useState();

    const getCategories = useCallback(async () => {
        await axios
			.get(`${NewsApi}get-categories`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setCategoriesList(concatResults(response).categories);
                return categoriesList
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
                throw error;
			});
    }, [categoriesList]);

    const getUserNews = useCallback(async (userId) => {
        await axios
			.get(`${NewsApi}get-news?userId=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setUserNews(concatResults(response).news);
                return userNews
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
                //throw error;
			});
    }, [userNews]);

    const createNews = async (userId, content, categoryId) => {
        await axios
            .post(`${NewsApi}create-news`, {
                content: content,
                author: userId,
                categoryId: categoryId,
                date: getDateNow()
            }
            , { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    };

    function getDateNow() {
        var date = new Date();
        var time = date.toISOString();

        return time;
    }

    return {
        getCategories,
        getUserNews,
        userNews,
        createNews
    };
}

export default NewsProvider;