import React from 'react';
import { Col, Row } from 'reactstrap';

import LightCardBox from './LightCardBox';

const LightCard = ({ LightCardData }) => {
	return (
		<Col className="p-0">
			<div className="g-sm-4 g-2" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', gap: '30px' }}>
				{LightCardData.map((data, i) => (
					<Col key={i} xl="12" md="4">
						<LightCardBox data={data} />
					</Col>
				))}
			</div>
		</Col>
	);
};

export default LightCard;
