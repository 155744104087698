// RightPanel.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Col, Input, Nav, NavItem, NavLink, TabContent, TabPane, Label } from 'reactstrap';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { Scrollbars } from 'react-custom-scrollbars-2';

import ElementInputAddress from './Elements/ElementInputAddress';
import ElementTel from './Elements/ElementTel';
import ElementDate from './Elements/ElementDate';
import ElementTime from './Elements/ElementTime';
import { Btn, H6 } from '../../../../AbstractElements';
import { DemandsApi } from '../../../../api';
import ElementInputCity from './Elements/ElementInputCity';
import ElementSelectType from './Elements/ElementSelectType';
import ElementInputName from './Elements/ElementInputName';
import ElementComment from './Elements/ElementComment';
import ElementClient from './Elements/ElementClient';
import ElementPayment from './Elements/ElementPayment';
import ElementProductTable from '../FormBuilder/Elements/ElementProductTable';
import { useKeycloak } from '@react-keycloak/web';
import {concatResults} from "../../../../concatResults";
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';

const RightPanel = ({ items, setIsOverLeftPanel, setItemName, itemName, droppedItems, menuitems, selectProduct, payment }) => {
	const [pillWithIconTab, setpillWithIconTab] = useState('1');
	const [types, setTypes] = useState('');
	const {keycloak} = useKeycloak();
	const {companyData} = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${DemandsApi}getDemandTypes`, { params: { query: '' }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				const selectOptions = concatResults(response).demandTypes.map((option) => ({
					label: option.title,
					value: option.id,
				}));
				setTypes(selectOptions);
				//console.log(concatResults(response).demandTypes);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const send = () => {
		const newForm = {
			id: uuid(),
			name: itemName,
			parameters: droppedItems,
		};

		axios
			.post(`${DemandsApi}getDemandTypes`, newForm, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				//console.log(concatResults(response));
				toast.success('Форма добавлена!', {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error(error);
			});
		console.log(newForm);
	};

	return (
		<div className="right-panel">
			<Nav className="nav-primary" tabs>
				<NavItem>
					<NavLink href="#" className={pillWithIconTab === '1' ? 'active' : ''} onClick={() => setpillWithIconTab('1')} style={{ padding: '4px 8px' }}>
						Элементы формы
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink href="#" className={pillWithIconTab === '2' ? 'active' : ''} onClick={() => setpillWithIconTab('2')} style={{ padding: '4px 8px' }}>
						Настройки формы
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={pillWithIconTab}>
				<TabPane className="fade show" tabId="1">
					<div className="font-primary mb-2">Выберите элемент для создания формы</div>
					<div className="scroll-bar-wrap">
						<Scrollbars className="click-drag-handler  p-0" style={{ width: '100%', height: '60vh' }}>
							<H6 attrH6={{ className: 'f-w-600 mt-4' }}>Основные реквизиты заказа</H6>
							{items.map((item, index) =>
								item === 'address' ? (
									<ElementInputAddress setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : item === 'tel' ? (
									<ElementTel setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : item === 'name' ? (
									<ElementInputName setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : item === 'date' ? (
									<ElementDate setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : item === 'time' ? (
									<ElementTime setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : item === 'selectType' ? (
									<ElementSelectType setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : item === 'city' ? (
									<ElementInputCity setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : item === 'client' ? (
									<ElementClient setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : item === 'comment' ? (
									<ElementComment setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : (
									<li key={index} draggable="true" className="draggable-item" onDragStart={(e) => e.dataTransfer.setData('text/plain', item)}>
										{item}
									</li>
								)
							)}
							<H6 attrH6={{ className: 'f-w-600 mt-4 b-t-light p-t-10' }}>Выбор товара и отображение стоимости</H6>
							{selectProduct.map((item, index) =>
								item === 'ProductsOrderTable' ? (
									<ElementProductTable setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : (
									<li key={index} draggable="true" className="draggable-item" onDragStart={(e) => e.dataTransfer.setData('text/plain', item)}>
										{item}
									</li>
								)
							)}
							<H6 attrH6={{ className: 'f-w-600 mt-4 b-t-light p-t-10' }}>Способ оплаты</H6>
							{payment.map((item, index) =>
								item === 'payment' ? (
									<ElementPayment setIsOverLeftPanel={setIsOverLeftPanel} key={index} />
								) : (
									<li key={index} draggable="true" className="draggable-item" onDragStart={(e) => e.dataTransfer.setData('text/plain', item)}>
										{item}
									</li>
								)
							)}
						</Scrollbars>
					</div>
				</TabPane>
				<TabPane tabId="2">
					<Col className="mt-2">
						<Label className="col-sm-9 col-form-label">Название формы</Label>
						<Col sm="12">
							<Input className="form-control" type="text" placeholder="Введите название" value={itemName} onChange={(e) => setItemName(e.target.value)} />
						</Col>
					</Col>
					<Col className="mt-2">
						<Label className="col-form-label">Тип заявки</Label>
						<Select placeholder="Выберите..." options={types} className="js-example-basic-single col-sm-12" />
					</Col>
					<Col className="mt-2">
						<div className="checkbox checkbox-primary">
							<Input id="checkbox-primary-2" type="checkbox" />
							<Label for="checkbox-primary-2">Отображать в календаре</Label>
						</div>
					</Col>
					<div className="modal-footer" style={{ paddingTop: '40px' }}>
						<Btn attrBtn={{ color: 'primary', onClick: send }}>Сохранить </Btn>
					</div>
				</TabPane>
			</TabContent>
		</div>
	);
};

export default RightPanel;
