import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';

const UpcomingEvents = ({ courses }) => {
	const [coursesArray, setCoursesArray] = useState('');
	useEffect(() => {
		const DailyDropdown = courses && courses.map((item) => ({ x: item.name, y: [new Date(item.dateStart).getTime(), new Date(item.dateEnd).getTime()], strokeColor: 'var(--theme-default)', fillColor: 'var(--white)' }));

		setCoursesArray(DailyDropdown);
	}, []);
	const UpcomingOptionsChartData = {
		series: [
			{
				data: coursesArray,
			},
		],
		options: {
			chart: {
				height: 305,
				type: 'rangeBar',
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					distributed: true,
					barHeight: '50%',
					dataLabels: {
						hideOverflowingLabels: false,
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (val, opts) {
					var label = opts.w.globals.labels[opts.dataPointIndex];
					return label;
				},
				textAnchor: 'middle',
				offsetX: 0,
				offsetY: 0,
				background: {
					enabled: true,
					foreColor: 'var(--chart-text-color)',
					padding: 10,
					borderRadius: 12,
					borderWidth: 1,
					opacity: 0.9,
				},
			},
			xaxis: {
				type: 'datetime',
				position: 'top',
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			yaxis: {
				show: false,
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			grid: {
				row: {
					colors: ['var(--light-background)', 'var(--white)'],
					opacity: 1,
				},
			},
			stroke: {
				width: 2,
			},
			states: {
				normal: {
					filter: {
						type: 'none',
					},
				},
				hover: {
					filter: {
						type: 'none',
					},
				},
				active: {
					allowMultipleDataPointsSelection: false,
					filter: {
						type: 'none',
					},
				},
			},
			responsive: [
				{
					breakpoint: 1661,
					options: {
						chart: {
							height: 295,
						},
					},
				},
				{
					breakpoint: 1200,
					options: {
						chart: {
							height: 370,
						},
					},
				},
				{
					breakpoint: 575,
					options: {
						chart: {
							height: 300,
						},
					},
				},
			],
		},
	};
	return (
		<Card>
			<CardHeader className="card-no-border">
				<div className="header-top">
					<H5>Расписание</H5>
				</div>
			</CardHeader>
			<CardBody className="pt-0">
				<div className="upcoming-event-wrap">
					{
						UpcomingOptionsChartData && Array.isArray(UpcomingOptionsChartData.options) && Array.isArray(UpcomingOptionsChartData.series) &&
							<ReactApexChart
								height={305}
								type="rangeBar"
								options={
									UpcomingOptionsChartData ? Array.isArray(UpcomingOptionsChartData.options) && UpcomingOptionsChartData.options : []
								}
								series={UpcomingOptionsChartData ? Array.isArray(UpcomingOptionsChartData.series) && UpcomingOptionsChartData.series : []}
							/>
					}
				</div>
			</CardBody>
		</Card>
	);
};

export default UpcomingEvents;
