import React, { Fragment, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { Trash2 } from "react-feather";
import Select from "react-select";
import { Label, Input, CardBody, Col, InputGroup, InputGroupText } from "reactstrap";
import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import uuid from 'react-uuid';

const TableProjectRate = ({ data, deleteRow, allProjects, setTableData }) => {
	const tableColumns = [
		{
			name: "Проект",
			selector: (row) => `${row.projectName}`,
			sortable: true,
		},
		{
			name: "Ставка по проекту",
			selector: (row) => `${row.rate}`,
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<Tooltip title="Удалить" arrow placement="top">
					<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteRow(row.projectId)}>
						<Trash2 size={"18px"} />
					</div>
				</Tooltip>
			),
			width: "6%",
		},
	];

	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState("");

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText]);

	const [project, setProject] = useState(null);
	const [rate, setRate] = useState(0);

	//Добавить ставку по проектам
	//const createRate = () => {
	//	const newValue = {
	//		projectId: project.value,
	//		projectName: project.label,
	//		rate
	//	};

	// Функция для добавления новой ставки по проекту
	const createRate = () => {
		// Проверка, что выбран проект и указана ставка
		if (project && rate) {
			const newRate = {
				id: uuid(),
				projectId: project.value,
				projectName: project.label,
				rate: parseFloat(rate) // Преобразуем ставку в число
			};

			// Добавляем новую ставку в таблицу проектных ставок
			setTableData([...data, newRate]);

			// Закрываем модальное окно
			toggle();
		} else {
			// Выводим сообщение об ошибке, если не все данные указаны
			console.error("Необходимо выбрать проект и указать ставку.");
		}
	};


	return (
		<Fragment>
			<DataTable
				data={filteredItems}
				columns={tableColumns}
				highlightOnHover={true}
				subHeader
				subHeaderComponent={subHeaderComponent}
				pagination
				pointerOnHover
				noDataComponent={"В данной таблице ещё нет записей."}
				paginationComponentOptions={{
					rowsPerPageText: "Строк на странице:",
					rangeSeparatorText: "из",
				}}
			/>
			<CommonModal isOpen={modal} title={"Добавить"} toggler={createRate} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Проект</Label>
						<Col sm="11">
							<Select
								required
								placeholder="Начните вводить..."
								noOptionsMessage={() => "Нет результатов"}
								options={allProjects}
								value={project}
								onChange={(selectedOption) => setProject(selectedOption)}
								className="js-example-basic-single col-sm-12"
							/>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Ставка по проекту</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={rate} onChange={(e) => setRate(e.target.value)} />
								<InputGroupText>{"руб/час"}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};

export default TableProjectRate;
