import React, { useState, useEffect } from "react";
import { Trash2, Edit, Download, Copy } from "react-feather";
import { Card, CardBody, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Row } from "reactstrap";
import { Tooltip, IconButton, Checkbox, Switch } from '@mui/material';
import axios from "axios";
import SweetAlert from "sweetalert2";
import uuid from 'react-uuid';
import { toast } from "react-toastify";
import FilterComponent from "../../Catalog/FilterComponent";
import Select from "react-select";
import { useKeycloak } from '@react-keycloak/web';
import { ExportDataApi } from '../../../../api';
import Settings from '@mui/icons-material/Settings';
import CommonModal from '../CommonModal';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { useModulesContext } from "../../../../_helper/Modules/ModulesProvider";
import { useExportDataContext } from "../../../../_helper/ExportData/ExportDataProvider";



const TileResponseSettings = () => {

    const { response, responses, setResponses, getResponses, dataTypes,
        getDataTypes, dataTypesOptions, publicResponse,
        createResponse, updateResponse, deleteResponse,
    } = useExportDataContext();

    const [currentResponse, setCurrentResponse] = useState(null);
    const { service, getService, services, getServices } = useModulesContext();
    const [isEditing, setIsEditing] = useState(false);

    const [edit, setEdit] = useState(false);
    const [modalData, setModalData] = useState({
        id: null,
        name: "",
        dataTypeId: "",
        dateUpdate: new Date().toISOString(),
        isPublic: false
    });

    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);

    const handleClose = () => {
        setShowModal(false);
        setModalData({
            id: null,
            name: "",
            dataTypeId: "",
            dateUpdate: new Date().toISOString(),
            isPublic: false
        });

    };

    useEffect(() => {
        getResponses();
        const fetchData = async () => {
            getService(null, "ExportData");
            getServices();
        }
        fetchData();
    }, [response]);

    useEffect(() => {
        getDataTypes();
    }, []);

    useEffect(() => {
        fetchLinks();
    }, [services]);

    const fetchLinks = async () => {
        try {
            const currentDomain = window.location.hostname;
            const isLocalhost = currentDomain === 'localhost';
            const updatedResponses = responses.map(obj => {
                const baseUrl = `${isLocalhost ? ExportDataApi : service.serviceUrl + service.servicePrefix + "/"}exportData`;
                const responseIdParam = `responseId=${obj.id}`;
                const layoutIdParam = obj.layoutDataTypes.length > 0 ? `&layoutId=${obj.layoutDataTypes[0].id}` : '';
                const currentService = services.find(x => x.id === obj.serviceId);
                var serviceUrlParam = "";
                if (obj.serviceId) {

                    if (isLocalhost) {
                        if (currentService.name === "IMaps") {
                            serviceUrlParam = `https://localhost:7128/${currentService.servicePrefix}`;
                        } else {
                            serviceUrlParam = `https://localhost:7021/${currentService.servicePrefix}`;
                        }
                    } else {
                        serviceUrlParam = `${currentService.serviceUrl}${currentService.servicePrefix}`;
                    }
                    serviceUrlParam += "/getViewFieldValues";
                    serviceUrlParam = `&serviceUrl=${encodeURIComponent(serviceUrlParam)}`;
                }

                return {
                    ...obj,
                    link: `${baseUrl}?${responseIdParam}${layoutIdParam}${serviceUrlParam}`
                };
            });
            setResponses(updatedResponses);
        }
        catch (err) {
            console.log("Error:", err);
        }

    }

    const handleEdit = (response) => {
        setCurrentResponse(response);
        setModalData({
            id: response.id,
            name: response.name,
            dataTypeId: response.dataTypeId,
            dateUpdate: new Date().toISOString(),
            isPublic: response.isPublic || false
        });
        setIsEditing(true);
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        try {
            SweetAlert.fire({
                title: "Вы уверены?",
                text: "После удаления вы не сможете восстановить этот объект!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "ОК",
                cancelButtonText: "Отмена",
                reverseButtons: true,
            }).then(async (result) => {
                if (result.value) {
                    deleteResponse(id);
                    setResponses(prev => prev.filter(response => response.id !== id));
                    toast.success("Запись успешно удалена!");
                }
            });
        } catch (error) {
            console.error('Ошибка удаления записи:', error);
            toast.error("Ошибка удаления записи!");
        }
    };

    const handleSave = async () => {
        try {
            if (isEditing) {
                updateResponse(modalData);
                toast.success("Запись успешно обновлена!");
            }
            else {
                modalData.id = uuid();
                createResponse(modalData);
                toast.success("Запись успешно добавлена!");
            }
            setShowModal(false);
            setCurrentResponse(null);
            setModalData({
                id: null,
                name: "",
                dataTypeId: "",
                dateUpdate: new Date().toISOString(),
                isPublic: false
            });
        } catch (error) {
            console.error('Ошибка сохранения записи:', error);
            toast.error("Ошибка сохранения записи!");
        }
    };

    const handleCopy = (id) => {
        navigator.clipboard.writeText(responses.find(x => x.id === id).link).then(() => {
            toast.success("Ссылка скопирована!");
        }).catch(() => {
            toast.error("Ошибка копирования ссылки!");
        });
    };

    const downloadFile = (layoutDataTypes) => {
        if (!layoutDataTypes || layoutDataTypes.length === 0) {
            console.error("layoutDataTypes is empty or undefined");
            alert("Нет данных для скачивания.");
            return;
        }

        const { layoutValue, layoutName, dataTypeId } = layoutDataTypes[0];

        if (!layoutValue || layoutValue.trim() === "") {
            console.error("layoutValue is empty or undefined");
            alert("Файл пустой. Невозможно скачать.");
            return;
        }

        const dataTypeOption = dataTypesOptions.find(obj => obj.value === dataTypeId);
        const fileType = dataTypeOption ? dataTypeOption.label.toLowerCase() : 'txt';

        let mimeType = 'text/plain';
        if (fileType === 'xml') {
            mimeType = 'application/xml';
        } else if (fileType === 'json') {
            mimeType = 'application/json';
        } else if (fileType === 'html') {
            mimeType = 'text/html';
        }

        const element = document.createElement("a");
        const file = new Blob([layoutValue], { type: mimeType });
        element.href = URL.createObjectURL(file);
        element.download = `${layoutName}.${fileType}`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };


    return (
        <>
            <FilterComponent
                toggle={handleShow}
            />
            <Row>
                {responses && responses.length > 0 ? (
                    responses.map(response => (
                        <Col sm="12" md="6" lg="4" key={response.id} className="mb-3">
                            <Card>
                                <CardBody>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div className="text-start">
                                            <div>
                                                <strong>Формат:</strong>
                                                <div>{dataTypes.find(dt => dt.id === response.dataTypeId)?.typeName || 'Не найдено'}</div>
                                            </div>
                                            <div className="mt-2">
                                                <strong>Обновлен:</strong>
                                                <div>{response.dateUpdate ? new Date(response.dateUpdate).toLocaleDateString() : 'Не указано'}</div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <strong>Объекты:</strong>
                                            <div>{response.name}</div>
                                        </div>
                                    </div>
                                    <hr className="my-3" />
                                    <div className="d-flex align-items-center mb-2">
                                        <input
                                            type="text"
                                            value={response.link}
                                            readOnly
                                            className="form-control me-2"
                                            style={{ flex: 1, fontSize: '12px' }}
                                        />
                                        <Tooltip title="Скопировать ссылку">
                                            <Copy
                                                size={20}
                                                onClick={() => handleCopy(response.id)}
                                                style={{ cursor: 'pointer', marginRight: 10 }}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Скачать файл">
                                            <Download
                                                className="font-primary"
                                                size={20}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => downloadFile(response.layoutDataTypes)}
                                            />
                                        </Tooltip>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <Button
                                            color="primary"
                                            className="me-2 d-flex align-items-center"
                                            onClick={() => {
                                                if (response.layoutDataTypes && response.layoutDataTypes.length > 0) {
                                                    window.open(`layout-datatypes/manage-layout?layoutId=${response.layoutDataTypes[0].id}`, '_blank');
                                                }
                                            }}
                                            disabled={!response.layoutDataTypes || response.layoutDataTypes.length === 0}
                                        >
                                            <span>Настройки шаблона файла</span>
                                        </Button>
                                        <Button
                                            color="primary"
                                            className="d-flex align-items-center"
                                            onClick={() => window.open(`fields-maps-settings?responseId=${response.id}`, '_blank')}
                                        >
                                            <span>Настроить поля</span>
                                        </Button>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="me-3 d-flex align-items-center">
                                            <Switch
                                                checked={response.isPublic || false}
                                                onChange={() => publicResponse(response.id)}
                                                color="primary"
                                            />
                                            <span className="ms-2">Публикация</span>
                                        </div>
                                        <Button
                                            color="primary"
                                            className="me-2 d-flex align-items-center"
                                            onClick={() => handleEdit(response)}
                                        >
                                            <Settings size={20} style={{ marginRight: 5 }} />
                                            <span>Настроить</span>
                                        </Button>
                                        <Button
                                            color="danger"
                                            className="d-flex align-items-center"
                                            onClick={() => handleDelete(response.id || '')}
                                        >
                                            <Trash2 size={20} style={{ marginRight: 5 }} />
                                            <span>Удалить</span>
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))
                ) : (
                    <p>Нет данных для отображения</p>
                )}
            </Row>

            <CommonModal
                isOpen={showModal}
                title={edit ? "Редактировать запись" : "Добавить запись"}
                toggler={handleShow}
                togglerClose={handleClose}
                closeText="Отмена"
                saveText="Сохранить"
                size="lg"
                onSave={handleSave}
            >
                <CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
                    <Col className="row col-sm-12 mb-2">
                        <Col className="mb-3">
                            <Label>Название</Label>
                            <Input
                                type="text"
                                value={modalData.name}
                                onChange={(e) => setModalData({ ...modalData, name: e.target.value })}
                            />
                        </Col>
                    </Col>
                    <Col className="row col-sm-12 mb-2">
                        <Col className="mb-3">
                            <Label>Тип файла данных</Label>
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={dataTypesOptions}
                                value={dataTypesOptions.find(dt => dt.value === modalData.dataTypeId)}
                                onChange={(selectedOption) => setModalData({ ...modalData, dataTypeId: selectedOption.value })}
                                className="js-example-basic-single col-sm-12"
                            />
                        </Col>
                    </Col>
                </CardBody>
            </CommonModal>

        </>
    );
};

export default TileResponseSettings;