import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs, H5 } from '../../../../AbstractElements';
import StandardPricingcard from './StandardProcingCard';

const ClientView = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const offerId = queryParams.get('offerId');

	const [item, setItem] = useState('');

	useEffect(() => {
		axios
			.get('/api/packageOffersList.json')
			.then((response) => {
				const foundItem = response.data.find((item) => item.id === offerId);
				setItem(foundItem);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			{/* <Breadcrumbs mainTitle="Просмотр пакетного предложения" parent="Предложения" subParent="Пакетные предложения" title="Просмотр пакетного предложения" /> */}
			<Container fluid={true}>
				{/* <div className="email-wrap bookmark-wrap">
					<Card>
						<CardHeader>
							<H5>{item.name}</H5>
						</CardHeader>
						<CardBody> */}
				<StandardPricingcard data={item.packages} />
				{/* </CardBody>
					</Card>
				</div> */}
			</Container>
		</Fragment>
	);
};
export default ClientView;
