import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, CardBody, Col, Card, Label, CardFooter, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import ru from 'date-fns/locale/ru';
import Select from 'react-select';
import { useKeycloak } from "@react-keycloak/web";
import { TwinAuthApi, CounterpartiesApi } from "../../../../api";
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import TableTransactions from './TableTransactions';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const TransactionsCounterparty = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const employeeId = queryParams.get('counterpartyId');

	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [selectedEmploye, setSelectedEmploye] = useState({ label: 'Все сотрудники', value: 'allEmployees' });
	const [employees, setEmployees] = useState([]);
	const [data, setData] = useState([]);
	const [companyId, setCompanyId] = useState("");
	const currentUserId = `${keycloak.tokenParsed.sub}`;

	useEffect(() => {
		const fetchUserCompanies = async () => {
			try {
				const response = await axios.get(`${TwinAuthApi}get-user-groups-companies/${currentUserId}`, {
					headers: {
						Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
					}
				});
				if (response.data.length > 0) {
					const companies = JSON.parse(response.data);
					setCompanyId(companies[0].id);
				}
			} catch (error) {
				console.error("Ошибка получения данных:", error);
			}
		};

		fetchUserCompanies();
	}, [currentUserId, keycloak.token]);


	useEffect(() => {
		axios
			.get(`${CounterpartiesApi}getCounterparties`, {
				headers: {
					Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
					//CompanyId: companyId
				}
			})
			.then((response) => {
				const selectOptions = concatResults(response).counterparties.map((option) => ({
					label: option.name,
					value: option.id,
				}));

				console.log("OPTIONS--------------")
				console.log(selectOptions)


				if (employeeId) {
					const foundObject = concatResults(response).counterparties.find((item) => item.id === employeeId);
					setData(foundObject.payments);
					setSelectedEmploye({ label: foundObject.name, value: foundObject.id });
				}
				else {
					setData(concatResults(response).counterparties[0].payments);
					setSelectedEmploye({ label: concatResults(response).counterparties[0].name, value: concatResults(response).counterparties[0].id });
				}
				const dateEnd = queryParams.get('dateEnd');
				if (dateEnd) {
					setPeriodEnd(new Date(dateEnd));
				} else {
					setPeriodEnd(new Date());
				}

				const dateStart = queryParams.get('dateStart');
				if (dateStart) {
					setPeriodStart(new Date(dateStart));
				} else {
					setPeriodStart(new Date());
				}

				setEmployees(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, [companyId, keycloak.token]);

	const handleEmployeesChange = (selectedOption) => {
		const categoryId = selectedOption.value;
		setSelectedEmploye(selectedOption);
		queryParams.delete('counterpartyId');
		queryParams.append(`counterpartyId`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};

	const handleDateStartChange = (selectedOption) => {
		const categoryId = selectedOption;
		setPeriodStart(selectedOption);
		queryParams.delete('dateStart');
		queryParams.append(`dateStart`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};
	const handleDateEndChange = (selectedOption) => {
		const categoryId = selectedOption;
		setPeriodEnd(selectedOption);
		queryParams.delete('dateEnd');
		queryParams.append(`dateEnd`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Просмотр операций с контрагентом" parent="Контрагенты" title="Просмотр операций с контрагентом" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Row className="m-l-5">
									<Col xl="3" sm="9">
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											<Label className="m-r-10 f-16">С</Label>
											<div className="input-group">
												<DatePicker locale={ru} className="form-control digits" value={formatDate(periodStart)} selected={periodStart} onChange={handleDateStartChange} />
											</div>
										</div>
									</Col>
									<Col xl="3" sm="9">
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											<Label className="m-r-10 f-16">По</Label>
											<div className="input-group">
												<DatePicker locale={ru} className="form-control digits" value={formatDate(periodEnd)} selected={periodEnd} onChange={handleDateEndChange} />
											</div>
										</div>
									</Col>
									<Col xl="3" sm="9">
										<Select placeholder="Выберите..." value={selectedEmploye} options={employees} className="js-example-basic-single col-sm-12" onChange={handleEmployeesChange} />
									</Col>
								</Row>
							</CardBody>
							<CardFooter className="p-t-10 p-b-10">
								<div className="modal-footer">
									<Btn attrBtn={{ color: 'primary' }}>Показать</Btn>
								</div>
							</CardFooter>
						</Card>
						<Card>
							<CardBody>
								<TableTransactions filteredProducts={data} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default TransactionsCounterparty;
