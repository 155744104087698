import React, { useState, useEffect } from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import axios from 'axios';
import { UL, LI } from '../../../../AbstractElements';

import LightCard from '../../../Dashboard/Default/LitghtCard';
import ReactApexChart from 'react-apexcharts';

const OverallBalance = () => {
	const [date, setDate] = useState([]);
	const [value, setValue] = useState([]);

	useEffect(() => {
		axios
			.get('/api/revenueData.json')
			.then((response) => {
				setDate(response.data.map((item) => item.date));
				setValue(response.data.map((item) => item.revenue));
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const LightCardData = [
		{
			title: 'Новые клиенты',
			price: '19',
		},
		{
			title: 'Повторные клиенты',
			price: '7',
		},
		{
			title: 'Потерянные клиенты',
			price: '5',
		},
	];
	const CurrencyChartData = {
		series: [
			{
				name: 'Выручка',
				data: value,
			},
		],
		options: {
			chart: {
				type: 'bar',
				height: 300,
				stacked: true,
				toolbar: {
					show: true,
				},
				dropShadow: {
					enabled: true,
					top: 8,
					left: 0,
					blur: 10,
					color: '#7064F5',
					opacity: 0.1,
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '25px',
					borderRadius: 0,
				},
			},
			grid: {
				show: true,
				borderColor: 'var(--chart-border)',
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 2,
				dashArray: 0,
				lineCap: 'butt',
				colors: '#fff',
			},
			fill: {
				opacity: 1,
			},
			legend: {
				show: false,
			},
			states: {
				hover: {
					filter: {
						type: 'darken',
						value: 1,
					},
				},
			},

			colors: ['var(--theme-default)'],
			yaxis: {
				tickAmount: 3,
				labels: {
					show: true,
					style: {
						fontFamily: 'Rubik, sans-serif',
					},
				},
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},
			xaxis: {
				categories: date.map(formatDateTime),
				labels: {
					style: {
						fontFamily: 'Rubik, sans-serif',
					},
				},
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
			},

			responsive: [
				{
					breakpoint: 1661,
					options: {
						chart: {
							height: 290,
						},
					},
				},
				{
					breakpoint: 767,
					options: {
						plotOptions: {
							bar: {
								columnWidth: '35px',
							},
						},
						yaxis: {
							labels: {
								show: false,
							},
						},
					},
				},
				{
					breakpoint: 481,
					options: {
						chart: {
							height: 200,
						},
					},
				},
				{
					breakpoint: 420,
					options: {
						chart: {
							height: 170,
						},
						plotOptions: {
							bar: {
								columnWidth: '40px',
							},
						},
					},
				},
			],
		},
	};

	function formatDateTime(dateTimeStr) {
		const date = new Date(dateTimeStr);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${day}.${month} ${hours}:${minutes}`;
	}

	return (
		<Col xxl="12" lg="12" className="box-col-12">
			<Row className="m-0 overall-card">
				<Col xl="9" md="12" sm="7" className="p-0">
					<div className="chart-right">
						<Row>
							<Col xl="12" className="col-xl-12">
								<CardBody className="p-0">
									<UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
										<LI>
											<span className="circle bg-primary"> </span>
											<span className="f-light ms-1">Выручка</span>
										</LI>
									</UL>
									<div className="current-sale-container">
										<ReactApexChart type="bar" height={300} options={CurrencyChartData.options} series={CurrencyChartData.series} />
									</div>
								</CardBody>
							</Col>
						</Row>
					</div>
				</Col>
				<LightCard LightCardData={LightCardData} />
			</Row>
		</Col>
	);
};

export default OverallBalance;
