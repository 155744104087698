import React, { Fragment, useContext, useEffect } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";

import { Breadcrumbs } from "../../../../AbstractElements";
import TableSitesList from "./TableSitesList";
import CMSTemplatesContext from "../../../../_helper/CMSTemplates";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const InstalledTemplates = () => {
	const { allTemplates, refreshTemplates, getAllCmsSelects } = useContext(CMSTemplatesContext);
	const { companyData } = useCompanyContext();

	useEffect(() => {
		!allTemplates && getAllCmsSelects();
	}, [companyData])

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список установленных шаблонов" parent="Сайты" title="Список установленных шаблонов" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						{/* <CardHeader>
							<div className="font-primary f-16">Для перехода в конструктор сайта нажмите на его название</div>
						</CardHeader> */}
						<CardBody>
							<TableSitesList products={allTemplates} refreshTemplates={refreshTemplates} />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default InstalledTemplates;
