// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip_tooltipDefaultContainer__m8LbC {
	background: #fff;
	padding: 12px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	border-radius: 5px;
}

.tooltip_tooltipDefaultContainerParagraph__mtu7l {
	font-size: 12px;
	margin-bottom: 6px;
	color: #666;
}

.tooltip_tooltipDetailsContainer__RYRoB {
	position: absolute;
	display: flex;
	flex-shrink: 0;
	pointer-events: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	user-select: none;
}

.tooltip_tooltipDetailsContainerHidden__3M5\\+n {
	visibility: hidden;
	position: absolute;
	display: flex;
	pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Application/Project/ProjectView/tooltip.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,aAAa;CACb,wEAAwE;CACxE,kBAAkB;AACnB;;AAEA;CACC,eAAe;CACf,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,kBAAkB;CAClB,aAAa;CACb,cAAc;CACd,oBAAoB;CACpB,2BAA2B;CAC3B,yBAAyB;CAGzB,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;CAClB,kBAAkB;CAClB,aAAa;CACb,oBAAoB;AACrB","sourcesContent":[".tooltipDefaultContainer {\n\tbackground: #fff;\n\tpadding: 12px;\n\tbox-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);\n\tborder-radius: 5px;\n}\n\n.tooltipDefaultContainerParagraph {\n\tfont-size: 12px;\n\tmargin-bottom: 6px;\n\tcolor: #666;\n}\n\n.tooltipDetailsContainer {\n\tposition: absolute;\n\tdisplay: flex;\n\tflex-shrink: 0;\n\tpointer-events: none;\n\t-webkit-touch-callout: none;\n\t-webkit-user-select: none;\n\t-moz-user-select: none;\n\t-ms-user-select: none;\n\tuser-select: none;\n}\n\n.tooltipDetailsContainerHidden {\n\tvisibility: hidden;\n\tposition: absolute;\n\tdisplay: flex;\n\tpointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipDefaultContainer": `tooltip_tooltipDefaultContainer__m8LbC`,
	"tooltipDefaultContainerParagraph": `tooltip_tooltipDefaultContainerParagraph__mtu7l`,
	"tooltipDetailsContainer": `tooltip_tooltipDetailsContainer__RYRoB`,
	"tooltipDetailsContainerHidden": `tooltip_tooltipDetailsContainerHidden__3M5+n`
};
export default ___CSS_LOADER_EXPORT___;
