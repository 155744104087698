import React, { useMemo, useState } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { Label, Input, CardBody, Col, InputGroup, InputGroupText } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

const TableCalculationRules = ({ filteredProducts }) => {
	const columns = [
		{
			name: '',
			selector: (row) => '',
			sortable: false,
			width: '1%',
		},
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
		},

		{
			name: 'За услуги',
			selector: (row) => `${row.service_percentage} %`,
			sortable: true,
		},
		{
			name: 'За товары',
			selector: (row) => `${row.product_percentage} %`,
			sortable: true,
		},
		{
			name: 'Оклад',
			selector: (row) => `${row.base_salary} ₽ в мессяц`,
			sortable: true,
		},

		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {});
	};

	const [edit, setEdit] = useState(null);

	const [name, setName] = useState('');

	const [Id, setId] = useState('');

	const send = () => {
		if (name == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			if (!Id) {
				setId(uuid());
			}
			const newEvent = {
				// id: Id,
				// type: selectType.value,
				// name: name,
				// inn: inn,
				// upp: upp,
				// contact_person: contactPerson,
				// phone: phone,
				// email: email,
				// address: address,
				// comment: comment,
			};
			console.log(newEvent);
			// axios
			// 	.post(`/api/clientsDemandsForm.json`, newEvent)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Календарь добавлен!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 	});
		}
	};

	const [modal, setModal] = useState(false);

	const [service, setService] = useState('');
	const [product, setProduct] = useState('');
	const [promotion, setPromotion] = useState('');
	const [baseSalary, setBaseSalary] = useState('');
	const [guaranteedMinimum, setGuaranteedMinimum] = useState('');
	const [considerPreDiscount, setConsiderPreDiscount] = useState(false);
	const [considerPromotionDiscount, setConsiderPromotionDiscount] = useState(false);
	const [considerBonusPayment, setConsiderBonusPayment] = useState(false);
	const [considerCertificatePayment, setConsiderCertificatePayment] = useState(false);
	const [considerSubscriptionPayment, setConsiderSubscriptionPayment] = useState(false);
	const [considerServiceCost, setConsiderServiceCost] = useState(false);
	const [considerProductCost, setConsiderProductCost] = useState(false);
	const [excludeCustomerDiscount, setExcludeCustomerDiscount] = useState(false);

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.name);
			setId(ObjectId.id);
			setService(ObjectId.service_percentage);
			setProduct(ObjectId.product_percentage);
			setPromotion(ObjectId.promotion_percentage);
			setBaseSalary(ObjectId.base_salary);
			setGuaranteedMinimum(ObjectId.guaranteed_minimum);

			setConsiderPreDiscount(ObjectId.consider_pre_discount);
			setConsiderPromotionDiscount(ObjectId.consider_promotion_discount);
			setConsiderBonusPayment(ObjectId.consider_bonus_payment);
			setConsiderCertificatePayment(ObjectId.consider_certificate_payment);
			setConsiderSubscriptionPayment(ObjectId.consider_subscription_payment);
			setConsiderServiceCost(ObjectId.consider_service_cost);
			setConsiderProductCost(ObjectId.consider_product_cost);
			setExcludeCustomerDiscount(ObjectId.exclude_customer_discount);

			setEdit(true);
		} else {
			setName('');
			setId('');
			setService('');
			setProduct('');
			setPromotion('');
			setBaseSalary('');
			setGuaranteedMinimum('');

			setConsiderPreDiscount(false);
			setConsiderPromotionDiscount(false);
			setConsiderBonusPayment(false);
			setConsiderCertificatePayment(false);
			setConsiderSubscriptionPayment(false);
			setConsiderServiceCost(false);
			setConsiderProductCost(false);
			setExcludeCustomerDiscount(false);
			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Услуги</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={service} onChange={(e) => setService(e.target.value)} />
								<InputGroupText>{'%'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Товары</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={product} onChange={(e) => setProduct(e.target.value)} />
								<InputGroupText>{'%'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Акции</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={promotion} onChange={(e) => setPromotion(e.target.value)} />
								<InputGroupText>{'%'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Оклад</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={baseSalary} onChange={(e) => setBaseSalary(e.target.value)} />
								<InputGroupText>{'в месяц'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Гарантированный минимум</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={guaranteedMinimum} onChange={(e) => setGuaranteedMinimum(e.target.value)} />
								<InputGroupText>{'в месяц'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<div className="checkbox checkbox-primary">
							<Input id={`checkbox-primary-1-considerPreDiscount`} type="checkbox" checked={considerPreDiscount} onChange={(e) => setConsiderPreDiscount(e.target.checked)} />
							<Label for={`checkbox-primary-1-considerPreDiscount`}>Учитывать предварительную скидку клиенту</Label>
						</div>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<div className="checkbox checkbox-primary">
							<Input id={`checkbox-primary-1-considerPromotionDiscount`} type="checkbox" checked={considerPromotionDiscount} onChange={(e) => setConsiderPromotionDiscount(e.target.checked)} />
							<Label for={`checkbox-primary-1-considerPromotionDiscount`}>Учитывать скидку по акциям</Label>
						</div>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<div className="checkbox checkbox-primary">
							<Input id={`checkbox-primary-1-considerBonusPayment`} type="checkbox" checked={considerBonusPayment} onChange={(e) => setConsiderBonusPayment(e.target.checked)} />
							<Label for={`checkbox-primary-1-considerBonusPayment`}>Учитывать оплату бонусами</Label>
						</div>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<div className="checkbox checkbox-primary">
							<Input id={`checkbox-primary-1-considerCertificatePayment`} type="checkbox" checked={considerCertificatePayment} onChange={(e) => setConsiderCertificatePayment(e.target.checked)} />
							<Label for={`checkbox-primary-1-considerCertificatePayment`}>Учитывать оплату сертификатом</Label>
						</div>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<div className="checkbox checkbox-primary">
							<Input id={`checkbox-primary-1-considerSubscriptionPayment`} type="checkbox" checked={considerSubscriptionPayment} onChange={(e) => setConsiderSubscriptionPayment(e.target.checked)} />
							<Label for={`checkbox-primary-1-considerSubscriptionPayment`}>Учитывать оплату абонементом</Label>
						</div>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<div className="checkbox checkbox-primary">
							<Input id={`checkbox-primary-1-considerServiceCost`} type="checkbox" checked={considerServiceCost} onChange={(e) => setConsiderServiceCost(e.target.checked)} />
							<Label for={`checkbox-primary-1-considerServiceCost`}>Учитывать себестоимость услуг при расчете</Label>
						</div>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<div className="checkbox checkbox-primary">
							<Input id={`checkbox-primary-1-considerProductCost`} type="checkbox" checked={considerProductCost} onChange={(e) => setConsiderProductCost(e.target.checked)} />
							<Label for={`checkbox-primary-1-considerProductCost`}>Учитывать себестоимость товаров при расчете</Label>
						</div>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<div className="checkbox checkbox-primary">
							<Input id={`checkbox-primary-1-excludeCustomerDiscount`} type="checkbox" checked={excludeCustomerDiscount} onChange={(e) => setExcludeCustomerDiscount(e.target.checked)} />
							<Label for={`checkbox-primary-1-excludeCustomerDiscount`}>Не учитывать скидку клиенту при вычитании себестоимости</Label>
						</div>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableCalculationRules;
