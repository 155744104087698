import React, { Fragment, useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Trash2, Edit, Settings } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import CommonModal from "../../../Components/UiKits/Modals/common/modal";
import { Row, Col, CardBody, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";
import TelegramLoginButton from "./TelegramLoginButton";
import { ReminderApi } from "../../../api";
import { Btn } from "../../../AbstractElements";
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../concatResults";

const NotificationSettings = () => {
	const [modal, setModal] = useState(false);
	const [email, setEmail] = useState();
	const [userTg, setUserTg] = useState();
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [userEmail, setUserEmail] = useState();

	const toggle = () => {
		setModal(!modal);
	};

	useEffect(() => {
		keycloak &&
			keycloak.authenticated &&
			axios
				.get(`${ReminderApi}get-email-user/${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setUserEmail(concatResults(response));
					setEmail(concatResults(response).email);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});

		keycloak &&
			keycloak.authenticated &&
			axios
				.get(`${ReminderApi}get-telegram-user/${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setUserTg(concatResults(response));
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
	}, [modal, keycloak]);

	useEffect(() => {}, [userTg]);

	const handleTelegramResponse = (response) => {
		console.log(response);
		console.log(1);
		console.log(response.id);
		axios
			.post(`${ReminderApi}create-telegram-user`, { chatId: `${response.id}`, userId: keycloak.tokenParsed.sub }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const handleEmailChange = () => {
		if (!userEmail) {
			axios
				.post(`${ReminderApi}create-email-user`, { userId: keycloak.tokenParsed.sub, email: email }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		} else {
			axios
				.put(`${ReminderApi}update-email-user`, { id: userEmail.id, userId: keycloak.tokenParsed.sub, email: email }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
	};

	return (
		<Fragment>
			<a style={{ position: "absolute", top: 0, right: 0 }} className="mt-3 me-3 font-primary" onClick={toggle}>
				<Settings size={"20px"} />
			</a>
			<CommonModal isOpen={modal} title={"Настройка уведомлений"} togglerClose={toggle}>
				<CardBody style={{ padding: "0 20px 20px" }}>
					<Col>
						<Row style={{ flexDirection: "column" }}>
							<Label className=" col-form-label">{userTg ? "Telegram успешно привязан" : "Привязать Telegram для получения уведомлений в боте"}</Label>
							{!userTg && (
								<Col sm="12">
									<TelegramLoginButton dataOnauth={handleTelegramResponse} botName="prikoluctbot" />
								</Col>
							)}
						</Row>
						<hr />
						<Row style={{ flexDirection: "column" }} className="mb-4">
							<Label className=" col-form-label">Привязать Почту для получения уведомлений на неё</Label>
							<Col sm="12">
								<Input className="form-control" value={email} placeholder="Текст" onChange={(e) => setEmail(e.target.value)} />
							</Col>
						</Row>
						<div>
							<Btn attrBtn={{ color: "primary", onClick: handleEmailChange, className: "f-right" }}>Сохранить</Btn>
						</div>
					</Col>
					<Row>
						{/*<FormGroup className="row" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-9 col-form-label">Пользователь, которому будет отправлено уведомление</Label>
								<Col sm="11">
								    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={optionsUsers} onChange={(e) => setUserId(e.id)} className="js-example-basic-single col-sm-12" />
								</Col>
							</FormGroup>
							<FormGroup className="row" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
									Тип уведомления
								</Label>
								<Col sm="11">
                                <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={optionsTypes} onChange={(e) => setEventTypeId(e.value)} className="js-example-basic-single col-sm-12" />
								</Col>
							</FormGroup>
							<FormGroup className="row" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-9 col-form-label">Текст уведомления</Label>
								<Col sm="11">
									<Input className="form-control" value={reminderText} placeholder="Текст" onChange={(e) => setReminderText(e.target.value)} />
								</Col>
    </FormGroup>*/}
					</Row>
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};

export default NotificationSettings;
