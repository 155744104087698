import React, { useMemo, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import FilterComponent from '../../Goods/PurchaseHistory/FilterComponent';
import { H6, P } from '../../../../AbstractElements';
import { concatResults } from '../../../../concatResults';

const TableStorageOperations = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get('/api/storageOperations.json')
			.then((response) => {
				setFilteredProducts(concatResults(response));
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	function formatDateTime(dateTimeStr) {
		const date = new Date(dateTimeStr);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	}

	const columns = [
		{
			name: <div>Дата</div>,
			selector: (row) => `${formatDateTime(row.date)}`,
			sortable: true,
			width: '180px',
		},
		{
			name: <div>№ документа</div>,
			selector: (row) => `${row.documentNo} `,
			sortable: true,
		},

		{
			name: <div>Поставщик</div>,
			selector: (row) => `${row.provider}`,
			sortable: true,
		},
		{
			name: <div>Тип операции</div>,
			selector: (row) => `${row.typeOperation}`,
			sortable: true,
		},
		{
			name: <div>Склад</div>,
			selector: (row) => `${row.storage} `,
			sortable: true,
		},

		{
			name: 'Комментарий',
			selector: (row) => `${row.comment} `,
			sortable: true,
			width: '150px',
		},
		{
			name: 'Товар',
			selector: (row) => `${row.product} `,
			sortable: true,
		},
		{
			name: <div>Количество</div>,
			selector: (row) => `${row.quantity} `,
			sortable: true,
			width: '130px',
		},
		{
			name: <div>Себестоимость</div>,
			selector: (row) => `${row.cost} `,
			sortable: true,
			width: '140px',
		},
		{
			name: <div>Остаток на складе</div>,
			selector: (row) => `${row.balance} `,
			sortable: true,
		},
		{
			name: <div>Визит</div>,
			selector: (row) => `${formatDateTime(row.date)}`,
			sortable: true,
		},
	];

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return <>{filteredProducts && <DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />}</>;
};

export default TableStorageOperations;
