import React, { Fragment, useState, useContext, useEffect } from "react";
import { Download, PlusSquare, Upload, ArrowRight } from "react-feather";
import { CardBody, CardHeader, Form, Input, Media } from "reactstrap";
import { Grid, List, Trash2 } from "react-feather";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import SweetAlert from "sweetalert2";

import errorImg from "../../../assets/images/search-not-found.png";
import { toast } from "react-toastify";
import { H4, H6, LI, P, UL, Image, Btn } from "../../../AbstractElements";
import FileManagerContext from "../../../_helper/FileManager";
import MovingFilesModal from "./MovingFilesModal";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";

const FileContent = ({ photoIndex, setPhotoIndex }) => {
	const { myfile, setMyFile, folders, getFileManagerData } = useContext(FileManagerContext);
	const { companyData } = useCompanyContext();

	useEffect(() => {
		getFileManagerData();
	}, [companyData])

	//popover
	const [anchorEl, setAnchorEl] = useState(null);
	const [curEl, setCurEl] = useState({});

	const handleClick = (el, event) => {
		setAnchorEl(event);
		setCurEl(el);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const [selectedFile, setSelectedFile] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");

	const handleChange = (event) => {
		event.preventDefault();
		setSearchTerm(event.target.value);
	};

	const filelist = myfile
		.filter((data) => {
			if (searchTerm == null) {
				return data;
			} else if (data.name.toLowerCase().includes(searchTerm.toLowerCase())) {
				return data;
			}
		})
		.map((data, i) => {
			return (
				<LI attrLI={{ className: "file-box col-xl-3 col-sm-6" }} key={i}>
					<div className="file-top">
						{["png", "jpg", "gif"].includes(data.name.split(".").pop().toLowerCase()) ? (
							<i className="fa fa-file-image-o txt-primary"></i>
						) : ["zip"].includes(data.name.split(".").pop().toLowerCase()) ? (
							<i className="fa fa-file-archive-o txt-secondary"></i>
						) : ["csv", "xls", "xlsx", "xlts", "ods", "ots"].includes(data.name.split(".").pop().toLowerCase()) ? (
							<i className="fa fa-file-excel-o txt-success"></i>
						) : (
							<i className="fa fa-file-text-o txt-info"></i>
						)}
						<i className="fa fa-ellipsis-v f-16 ellips" onClick={(e) => handleClick(data, e.currentTarget)} aria-describedby={id}></i>
					</div>
					<div className="file-bottom" onClick={() => setPhotoIndex({ ...photoIndex, isOpen: true, type: data.name.split(".").pop().toLowerCase() })}>
						<H6>{data.name}</H6>
						<P attrPara={{ className: "mb-0 mb-1" }}>{data.size}</P>
						<P>
							<b>{"последнее открытие"} : </b>
							{data.modify}
						</P>
					</div>
				</LI>
			);
		});

	const getFile = () => {
		document.getElementById("upfile").click();
	};

	const [disBtn, setDisBtn] = useState(true);

	const onFileChange = (event) => {
		setDisBtn(false);
		setSelectedFile(event.target.files[0]);
	};

	const onFileUpload = () => {
		let myfiles = [...myfile];
		if (selectedFile !== null) {
			myfiles.push({
				id: myfile.length + 1,
				name: selectedFile.name,
				size: `${selectedFile.size}`,
				modify: `${selectedFile.lastModifiedDate}`,
				icon: "fa fa-file-text-o txt-info",
			});
			setMyFile(myfiles);
			toast.success("Загрузка файла прошла успешно!");
			setDisBtn(true);
		} else {
			toast.error("Пожалуйста, выберите хотя бы один файл!");
		}
	};

	const gridLayout = () => {
		document.getElementById("gridRow").classList.remove("list-view");
		var elems = document.getElementById("gridRow").childNodes;
		[].forEach.call(elems, function (el) {
			el.className = "file-box list-group-item";
			el.classList.add("col-xl-3");
			el.classList.add("col-sm-6");
		});
	};

	const listLayout = () => {
		document.getElementById("gridRow").classList.add("list-view");
		var elems = document.getElementById("gridRow").childNodes;
		[].forEach.call(elems, function (el) {
			el.className = "file-box list-group-item";
			el.classList.add("col-xl-12");
		});
	};

	//удаление файла
	const deleteItem = (Id) => {
		setAnchorEl(null);
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот файл!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			// if (result.value) {
			// 	setLoading(true);
			// 	axios
			// 		.delete(`${AllProjectApi}delete/${Id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 		.then((response) => {
			// 			console.log(concatResults(response));
			// 			axios
			// 				.get(`${AllProjectApi}get`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 				.then((response) => {
			// 					setData(concatResults(response).projects);
			// 					setLoading(false);
			// 				})
			// 				.catch((error) => {
			// 					setLoading(false);
			// 					console.error('Ошибка получения данных:', error);
			// 				});
			// 		})
			// 		.catch((error) => {
			// 			setLoading(false);
			// 			toast.error('Произошла ошибка при удалении проекта', {
			// 				position: toast.POSITION.TOP_RIGHT,
			// 			});
			// 			console.error('Произошла ошибка при удалении проекта:', error);
			// 		});
			// }
		});
	};

	//скачивание файла
	const saveItem = (element) => {
		setAnchorEl(null);
		console.log(element);
	};

	const [modal, setModal] = useState(false);

	const toggle = () => {
		setAnchorEl(null);
		setModal(!modal);
	};

	return (
		<Fragment>
			<CardHeader>
				<Media style={{ alignItems: "center" }}>
					<Form className="search-file form-inline">
						<div className="mb-0 form-group">
							<i className="fa fa-search"></i>
							<input className="form-control-plaintext" type="text" value={searchTerm} onChange={(e) => handleChange(e)} placeholder="Поиск..." />
						</div>
					</Form>
					<Media body className="text-end">
						<Form className="d-inline-flex">
							<div className="btn btn-primary" onClick={getFile}>
								<PlusSquare />
								Добавить новый{" "}
							</div>
							<div style={{ height: "0px", width: "0px", overflow: "hidden" }}>
								<Input id="upfile" multiple type="file" onChange={(e) => onFileChange(e)} />
							</div>
						</Form>
						<Btn attrBtn={{ color: "primary", outline: true, className: "ms-2", onClick: onFileUpload, disabled: disBtn }}>
							<Upload />
							Загрузить
						</Btn>
					</Media>
				</Media>
			</CardHeader>
			{filelist.length ? (
				<CardBody className="file-manager">
					<div className="f-right mt-1">
						<Tooltip title="Сетка" arrow placement="top">
							<div className="square-product-setting d-inline-block">
								<a className="icon-grid grid-layout-view" onClick={gridLayout} href="#javascript">
									<Grid />
								</a>
							</div>
						</Tooltip>{" "}
						<Tooltip title="Список" arrow placement="top">
							<div className="square-product-setting d-inline-block ">
								<a className="icon-grid m-0 list-layout-view" onClick={listLayout} href="#javascript">
									<List />
								</a>
							</div>
						</Tooltip>
					</div>
					<H4 attrH4={{ className: "mb-3" }}>Все файлы</H4>
					<H6>Недавно открытые файлы</H6>
					<ul id="gridRow" class="simple-list files list-group ">
						{filelist}
					</ul>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
					>
						<div className="font-primary" style={{ display: "flex", flexDirection: "column", padding: "5px 10px" }}>
							<div className="p-2 b-b-light" style={{ cursor: "pointer" }} onClick={() => saveItem(curEl)}>
								<Download size={"18px"} style={{ margin: " 0 6px -3px 0" }} />
								Скачать
							</div>
							<div className="p-2 b-b-light" style={{ cursor: "pointer" }} onClick={() => deleteItem(curEl.id)}>
								<Trash2 size={"18px"} style={{ margin: " 0 6px -3px 0" }} />
								Удалить
							</div>
							<div className="p-2" style={{ cursor: "pointer" }} onClick={toggle}>
								<ArrowRight size={"18px"} style={{ margin: " 0 6px -3px 0" }} />
								Переместить
							</div>
						</div>
					</Popover>
					<H6 attrH6={{ className: "mt-4" }}>Папки</H6>
					<UL attrUL={{ className: "simple-list folder" }}>
						{folders.map((item) => {
							return (
								<LI attrLI={{ className: "folder-box" }} key={item.id}>
									<Media>
										<i className="fa fa-folder f-36 txt-warning"></i>
										<Media body className=" ms-3">
											<H6 attrH6={{ className: "mb-0" }}>{item.title}</H6>
											<P>{item.foldersize}</P>
										</Media>
									</Media>
								</LI>
							);
						})}
					</UL>
					<H6 attrH6={{ className: "mt-4" }}>Файлы</H6>
					<UL attrUL={{ className: "simple-list files" }}>{filelist} </UL>
				</CardBody>
			) : (
				<>
					<Image attrImage={{ className: "img-fluid m-auto mt-3", src: errorImg, alt: "" }} />
					<P attrPara={{ className: "text-center mt-2" }}>Ничего не найдено</P>
				</>
			)}
			<MovingFilesModal toggle={toggle} modal={modal} element={curEl} setModal={setModal} />
		</Fragment>
	);
};
export default FileContent;
