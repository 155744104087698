import React, { useState, useEffect } from "react";
import { Plus } from "react-feather";
import { Col, Row, CardBody } from "reactstrap";
import axios from "axios";
import Select from "react-select";

import CommonModal from "../../../UiKits/Modals/common/modal";
import { H6, Btn } from "../../../../AbstractElements";
import StudentsTableModal from "../../Learning/Courses/BuildCourse/Students/StudentsTableModal";

const TableAccess = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get("/api/accessRights.json")
			.then((response) => {
				setFilteredProducts(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [selectedCategory, setSelectedCategory] = useState([]);
	const [roles, setRoles] = useState([]);
	useEffect(() => {
		axios
			.get("/api/roles.json")
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.role,
					value: option.id,
				}));
				setRoles(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	return (
		<>
			{filteredProducts &&
				roles &&
				filteredProducts.map((item) => (
					<div>
						<Row className="b-b-light border-2 pt-2 pb-2">
							<Col sm="7">
								<span className="f-w-600">{item.UserType}:</span>
								<span> {item.Username}</span>
							</Col>
							<Col sm="5" className="p-0">
								<Select
									placeholder="Выберите..."
									value={{ label: item.Role, value: item.RoleId }}
									options={roles}
									className="js-example-basic-single col-sm-12"
									onChange={(selected) => setSelectedCategory(selected)}
								/>
							</Col>
						</Row>
					</div>
				))}
			<Btn attrBtn={{ color: "primary", outline: true, className: "mt-3", onClick: toggle }}>
				<Plus size={"20px"} style={{ margin: "0px 5px -5px 0px" }} />
				Добавить право доступа
			</Btn>
			<CommonModal isOpen={modal} title="Добавить право доступа" toggler={toggle} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "15px" }}>{/* <StudentsTableModal teachers={filteredProducts} /> */}</CardBody>
			</CommonModal>
		</>
	);
};

export default TableAccess;
