import React, { useState, useEffect, useMemo } from 'react';
import { Trash2, Edit, Eye, Search } from 'react-feather';
import { Tooltip, TextField } from '@mui/material';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Col, Input, InputGroup, InputGroupText, Row, FormGroup, Label, CardBody, Button } from "reactstrap";
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";
import { SpecificationsApi, TwinAuthApi, ProductsApi } from '../../../api';
import './TableSpecifications.css';
import FilterComponent from './FilterSettings/FilterComponent';
import { parseISO,parse, format, isEqual } from 'date-fns';
import CallerNumberFilter from "./FilterSettings/CallerNumberFilter";
import { Btn } from "../../../AbstractElements";
import StatusFilter from "./FilterSettings/StatusFilter";
import ActionButtonPanel from './ActionButtonPanel';
import AutoCompleteFilter from './FilterSettings/AutoCompleteFilter';
import Modal from 'react-modal';
import uuid from 'react-uuid';
import CommonModal from './CommonModal';
import DateFilter from './FilterSettings/DateFilter';
import FilterModal from './FilterModal';


const TableSpecifications = () => {
    const { keycloak } = useKeycloak();
    let currentUserId = `${keycloak.tokenParsed.sub}`;
    const { companyData } = useCompanyContext();
    const [selectedRow, setSelectedRow] = useState(null);
    const [specifications, setSpecifications] = useState([]);
    const [filters, setFilters] = useState({});
    const [customFilters, setCustomFilters] = useState([]);
    const [allUsersNames, setAllUsersNames] = useState([]);
    const [allCategories, setCategories] = useState([]);
    const [allUnits, setUnits] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [currentSpecification, setCurrentSpecification] = useState(null);
    const [currentOrderName, setCurrentOrderName] = useState(null);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modalOrder, setModalOrder] = useState(false);
    const toggleMOrder = () => setModalOrder(!modalOrder);

    const [newPosition, setNewPosition] = useState({
        id: uuid(),
        lineNumber: '',
        positionName: '',
        description: '',
        countProducts: '',
        comparedCost: '',
        specificationId: '',
        unitId: '',
        categoryId: ''
    });

    const [newCustomFilter, setCustomFilter] = useState({
        filterName:'',
        specId: '',
        title: '',
        createDatetime: '', 
        selectingProductsDatetime: '',
        customerName: '',
        creatorName: '',
        linesCount: '',
        productsFoundCount: '',
        productsSelectedCount: '',
        costSum: '',
        statusId: ''
    });

    useEffect(() => {
        newPosition.specificationId = currentSpecification;
    }, [currentSpecification]);

    useEffect(() => {
        fetchUsers();
        fetchStatuses();
        fetchData();
        fetchFilters();
        fetchCategories();
        fetchUnits();
    }, []);

    const fetchUsers = async () => {
        try {
            const allUsersResponse = await axios.get(`${TwinAuthApi}get-all-users`, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
            });
            const allUsers = JSON.parse(allUsersResponse.data).map((option) => ({
                label: `${option.firstName} ${option.lastName}`,
                value: option.id,
            }));
            setAllUsersNames(allUsers);
        } catch (error) {
            console.error('Error fetching statuses:', error);
        }
    };

    const fetchStatuses = async () => {
        try {
            const response = await axios.get(`${SpecificationsApi}getStatuses`, {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    CompanyId: companyData.map((item) => item.attributes.companyId[0])
                }
            });
            const allStatuses = response.data.statuses.map((option) => ({
                label: `${option.name}`,
                value: option.id,
            }));
            setStatuses(allStatuses)
        } catch (error) {
            console.error('Error fetching statuses:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${SpecificationsApi}getSpecifications`, {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    CompanyId: companyData.map((item) => item.attributes.companyId[0])
                },
                params: { filters }
            });
            setSpecifications(response.data.specifications);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    const fetchFilters = async () => {
        try {
            const response = await axios.get(`${SpecificationsApi}getCustomFilters/${currentUserId}`, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
            });
            const noneFilterOption = { label: "Без фильтра", value: null, data: "" };
            const customFiltersOptions = [
                noneFilterOption,
                ...response.data.filters.map((option) => ({
                    label: `${option.name}`,
                    value: option.id,
                    data: option.filter 
                }))
            ];
            setCustomFilters(customFiltersOptions);
        } catch (error) {
            console.error('Error fetching statuses:', error);
        }
    };


    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${ProductsApi}getCategories`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { query: "" } })
            const categoryOptions = response.data.categories.map((option) => ({
                label: `${option.pagetitle} : ${option.okpd}`,
                value: option.id,
            }));

            setCategories(categoryOptions);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    const fetchUnits = async () => {
        try {
            const response = await axios.get(`${SpecificationsApi}getUnits`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { query: "" } })
            const unitOptions = response.data.units.map((option) => ({
                label: `${option.name}`,
                value: option.id,
            }));

            setUnits(unitOptions);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

   
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"] = { value: value, matchMode: "contains" };

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => {
        setShowModal(false);
        setCustomFilter({
            filterName: '',
            specId: '',
            title: '',
            createDatetime: new Date(),
            selectingProductsDatetime: new Date(),
            customerName: '',
            creatorName: '',
            linesCount: '',
            productsFoundCount: '',
            productsSelectedCount: '',
            costSum: '',
            statusId: ''
        });
    };

    const handleCloseModalPos = () => {
        toggle();
        setNewPosition({
            id: uuid(),
            lineNumber: '',
            positionName: '',
            description: '',
            countProducts: '',
            comparedCost: '',
            specificationId: '',
            unitId: '',
            categoryId: ''
        });
    };

    const handleCloseUpdateOrder = () => {
        toggleMOrder();
        setCurrentOrderName('');
    };
    

    const renderHeader = () => {
        const customSelectStyles = {
            menu: (provided) => ({
                ...provided,
                zIndex: 9999
            })
        };

        const handleChangeCustomFilter = (field, value) => {
            setCustomFilter(prevValues => ({
                ...prevValues,
                [field]: value
            }));
        };

        const handleSaveCustomFilter = async () => {
            try {

                var cFilterObj = {
                    id: uuid(),
                    name: newCustomFilter.filterName,
                    filter: JSON.stringify(newCustomFilter),
                    creatorId: currentUserId
                }

                const response = await axios.post(`${SpecificationsApi}createCustomFilter`, cFilterObj, {
                    headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
                });
                handleClose();
                toast.success('Фильтр добавлен!', {
                      position: toast.POSITION.TOP_RIGHT,
                });
            }
            catch (error) {
                console.error('Ошибка при сохранении фильтра:', error);
                toast.error('Ошибка!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        };

        const applyCustomFilter = (selectedOption) => {
            try {
                resetFilters();
                if (selectedOption.value !== null) {
                    const filterData = JSON.parse(selectedOption.data);
                    Object.keys(filterData).forEach((key) => {
                        if (filterData[key] !== "") {
                            handleCallStartFilterChange(filterData[key], key);
                        }
                    });
                }
                
            } catch (error) {
                console.error('Error applying custom filter:', error);
            }
        };

        const resetFilters = () => {
            setFilters({});
        };

        return (
            <Row>
                <Col md="3">
                    <Select
                        options={customFilters}
                        placeholder="Выберите фильтр"
                        styles={customSelectStyles}
                        onChange={(selectedOption) => {
                            applyCustomFilter(selectedOption);
                        }}
                    />
                </Col>
                <Col md="4">
                    <Button onClick={handleShow} color="primary">Добавить фильтр</Button>
                </Col>

                <Col md="5">
                    <InputGroup>
                        <Input className="form-control" type="text" value={globalFilterValue} onChange={onGlobalFilterChange} />
                        <InputGroupText className="bg-primary">
                            <Search size={"20px"} />
                        </InputGroupText>
                    </InputGroup>
                </Col>


                <CommonModal
                    isOpen={showModal}
                    title="Новый фильтр"
                    toggler={handleShow}
                    togglerClose={handleClose}
                    closeText="Отмена"
                    saveText="Добавить"
                    size="lg"
                    onSave={handleSaveCustomFilter}
                >
                    <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                        {[
                            { label: "Наименование фильтра", field: "filterName", type: "text" },
                            { label: "Номер спецификации", field: "specId", type: "number" },
                            { label: "Наименование", field: "title", type: "text" },
                            { label: "Дата добавления", field: "createDatetime", type: "date" },
                            { label: "Дата последнего подбора", field: "selectingProductsDatetime", type: "date" },
                            { label: "Заказчик", field: "customerName", type: "select", options: allUsersNames },
                            { label: "Создатель", field: "creatorName", type: "select", options: allUsersNames },
                            { label: "Количество позиций", field: "linesCount", type: "number" },
                            { label: "Найдено товаров", field: "productsFoundCount", type: "number" },
                            { label: "Выбрано товаров", field: "productsSelectedCount", type: "number" },
                            { label: "Сумма", field: "costSum", type: "number" },
                            { label: "Этап", field: "statusId", type: "select", options: statuses }
                        ].map(({ label, field, type, options }, index) => (
                            <Row key={index} className="mb-3">
                                <Label className="col-sm-9 col-form-label">{label}</Label>
                                <Col sm="12">
                                    {type === "number" && <Input type="number" name={field} value={newCustomFilter[field]} onChange={e => handleChangeCustomFilter(field, e.target.value)} />}
                                    {type === "text" && <Input type="text" name={field} value={newCustomFilter[field]} onChange={e => handleChangeCustomFilter(field, e.target.value)} />}
                                    {type === "date" && <DatePicker
                                        placeholderText="Выберите дату и время"
                                        selected={null}
                                        onChange={date => handleChangeCustomFilter(field, date)}
                                        showTimeSelect
                                        className="form-control digits"
                                        locale="ru"
                                        dateFormat="dd.MM.yyyy HH:mm"
                                        timeFormat="HH:mm"
                                        timeCaption="Время"
                                    />}
                                    {type === "select" &&
                                        <Select
                                            options={options}
                                            value={options.find(option => option.value === newCustomFilter[field])}
                                            onChange={selectedOption => handleChangeCustomFilter(field, selectedOption.value)}
                                            placeholder={`Выберите ${label.toLowerCase()}`}
                                            styles={customSelectStyles}
                                        />
                                    }
                                </Col>
                            </Row>
                        ))}
                    </CardBody>
                </CommonModal>
            </Row>
        );
    };

    const header = renderHeader();
    function formatDate(dateTimeInput) {
        let date;

        if (typeof dateTimeInput === 'string') {
            if (dateTimeInput.includes('.')) {
                const [datePart, timePart] = dateTimeInput.split(' ');
                const [day, month, year] = datePart.split('.');
                const [hours, minutes] = timePart.split(':');

                date = new Date(year, month - 1, day, hours, minutes);
            } else {
                date = new Date(dateTimeInput);
            }
        } else if (dateTimeInput instanceof Date) {
            date = dateTimeInput;
        } else {
            throw new Error("Invalid input type. Expected a string or a Date object.");
        }

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    const findUserNameById = (id) => {
        const user = allUsersNames.find(user => user.value === id);
        return user ? user.label : '';
    };

    const handleMouseEnter = (specId) => {
        setHoveredRow(specId);
    };

    const handleMouseLeave = (specId) => {
        setHoveredRow(null);
    };

    const rowClassName = (rowData) => {
        return {
            'row-hover': hoveredRow === rowData.specId,
        };
    };
    

    const handleAddRow = async () => {

        if (newPosition.positionName === '')
        {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        else
        {
            try {

                newPosition.specificationId = currentSpecification;

                const response = await axios.post(`${SpecificationsApi}createPosition`, newPosition, {
                    headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
                });
                handleCloseModalPos();
                toast.success('Позиция добавлена!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            catch (error) {
                toast.error('Ошибка добавления!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const handleUpdateOrder = async () => {

        if (currentOrderName === '') {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        else {
            try {

                var object = {
                    id: currentSpecification,
                    title: currentOrderName
                }

                const response = await axios.put(`${SpecificationsApi}updateSpecification`, object, {
                    headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
                });
                handleCloseUpdateOrder();
                fetchData();
                toast.success('Спецификация обновлена!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
               
            }
            catch (error) {
                toast.error('Ошибка добавления!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };


    const handleCallStartFilterChange = (value, name) => {
        if (name === 'filterName') {
            return;
        }

        setFilters(prevFilters => {
            let updatedFilters = { ...prevFilters };
            updatedFilters[name] = { value: value, matchMode: "contains" };
            return updatedFilters;
        });
    };

    useEffect(() => {
    }, [filters]);

    const isDateWithinFilter = (name, filter) => {
        let _filters = { ...filters };
        _filters[name] = { value: new Date(formatDate(filter)), matchMode: "contains" };
    };


    const handleChangeNewPosition = (field, value) => {
        setNewPosition(prevValues => ({
            ...prevValues,
            [field]: value
        }));
    };


    return (
        <div style={{ overflowX: 'auto' }}>
            <DataTable
                removableSort
                filters={filters}
                onFilter={(e) => setFilters(e.filters)}
                filterDisplay="row"
                value={specifications}
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                scrollable
                style={{ width: '100%' }}
                rowClassName={rowClassName}
                onRowMouseEnter={(e) => handleMouseEnter(e.data.specId)}
                onRowMouseLeave={(e) => handleMouseLeave(e.data.specId)}
                header={header}
                emptyMessage="Нет спецификаций"
            >
                <Column
                    field="specId"
                    header="№"
                    sortable
                    style={{ width: '10%' }}
                    filter
                    filterElement={
                        <CallerNumberFilter
                            name="specId"
                            filterValue={filters && filters["specId"] ? filters["specId"].value : ""}
                            onFilterChange={handleCallStartFilterChange}
                        />
                    }
                    body={(rowData) => (
                        <div
                           
                            style={{ position: 'relative' }}
                        >
                            {hoveredRow === rowData.specId && (
                                <ActionButtonPanel
                                    specificationObject={rowData}
                                    setCurrentSpecification={setCurrentSpecification}
                                    setCurrentOrderName={setCurrentOrderName}
                                    onView={() => { } }
                                    onEdit={toggleMOrder}
                                    onAddPos={toggle}
                                />
                            )}
                            <Link to={`/specifications/view/${rowData.specId}`}>
                                {rowData.specId}
                            </Link>
                        </div>
                    )}
                />
                <Column
                    field="title"
                    header="Название"
                    sortable
                    style={{ width: '15%' }}
                    filter
                    filterElement={
                        <CallerNumberFilter
                            name="title"
                            filterValue={filters && filters["title"] ? filters["title"].value : ""}
                            onFilterChange={handleCallStartFilterChange}
                        />
                    }
                />
                <Column
                    field="createDatetime"
                    header="Дата добавления"
                    sortable
                    style={{ width: '10%' }}
                    filter
                    filterElement={
                        <DateFilter
                            name="createDatetime"
                            filterValue={filters && filters["createDatetime"] ? filters["createDatetime"].value : ""}
                            onFilterChange={isDateWithinFilter}
                        />
                    }
                    body={(rowData) => rowData.createDatetime}
                />
                <Column
                    field="selectingProductsDatetime"
                    header="Дата последнего подбора"
                    sortable
                    style={{ width: '10%' }}
                    filter
                    filterElement={
                        <DateFilter
                            name="selectingProductsDatetime"
                            filterValue={filters && filters["selectingProductsDatetime"] ? filters["selectingProductsDatetime"].value : ""}
                            onFilterChange={isDateWithinFilter}
                        />
                    }
                    body={(rowData) => rowData.selectingProductsDatetime}
                />
                <Column
                    field="customerName"
                    header="Заказчик"
                    sortable
                    style={{ width: '15%' }}
                    filter
                    filterElement={
                        <AutoCompleteFilter
                            name="customerName"
                            filterValue={filters && filters["customerName"] ? filters["customerName"].value : ""}
                            onFilterChange={handleCallStartFilterChange}
                            options={allUsersNames}
                        />
                    }
                    body={(rowData) => {
                        const customerName = findUserNameById(rowData.customerName);
                        return customerName ? `${customerName}` : '';
                    }}
                />
                <Column
                    field="creatorName"
                    header="Создатель"
                    sortable
                    style={{ width: '10%' }}
                    filter
                    filterElement={
                        <AutoCompleteFilter
                            name="creatorName"
                            filterValue={filters && filters["creatorName"] ? filters["creatorName"].value : ""}
                            onFilterChange={handleCallStartFilterChange}
                            options={allUsersNames}
                        />
                    }
                    body={(rowData) => {
                        const creatorName = findUserNameById(rowData.creatorName);
                        return creatorName ? `${creatorName}` : '';
                    }}
                />
                <Column
                    field="linesCount"
                    header="Количество позиций"
                    sortable
                    style={{ width: '5%' }}
                    filter
                    filterElement={
                        <CallerNumberFilter
                            name="linesCount"
                            filterValue={filters && filters["linesCount"] ? filters["linesCount"].value : ""}
                            onFilterChange={handleCallStartFilterChange}
                        />
                    }
                />
                <Column
                    field="productsFoundCount"
                    header="Найдено товаров"
                    sortable
                    style={{ width: '5%' }}
                    filter
                    filterElement={
                        <CallerNumberFilter
                            name="productsFoundCount"
                            filterValue={filters && filters["productsFoundCount"] ? filters["productsFoundCount"].value : ""}
                            onFilterChange={handleCallStartFilterChange}
                        />
                    }
                />
                <Column
                    field="productsSelectedCount"
                    header="Выбрано товаров"
                    sortable
                    style={{ width: '5%' }}
                    filter
                    filterElement={
                        <CallerNumberFilter
                            name="productsSelectedCount"
                            filterValue={filters && filters["productsSelectedCount"] ? filters["productsSelectedCount"].value : ""}
                            onFilterChange={handleCallStartFilterChange}
                        />
                    }
                />
                <Column
                    field="costSum"
                    header="Сумма"
                    sortable
                    style={{ width: '5%' }}
                    filter
                    filterElement={
                        <CallerNumberFilter
                            name="costSum"
                            filterValue={filters && filters["costSum"] ? filters["costSum"].value : ""}
                            onFilterChange={handleCallStartFilterChange}
                        />
                    }
                />
                <Column
                    field="statusId"
                    header="Этап"
                    sortable
                    style={{ width: '20%' }}
                    filter
                    filterElement={
                        <StatusFilter
                            name="statusId"
                            filterValue={filters && filters["statusId"] ? filters["statusId"].value : ""}
                            onFilterChange={handleCallStartFilterChange}
                            options={statuses}
                        />
                    }
                    body={(rowData) => {
                        return rowData.status ? rowData.status.name.toString() : '';
                    }}
                />
            </DataTable>

            <CommonModal
                isOpen={modal}
                title="Новая позиция"
                toggler={showModal}
                togglerClose={handleCloseModalPos}
                closeText="Отмена"
                saveText="Добавить"
                size="lg"
                onSave={handleAddRow}
            >
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    {[
                        { label: "Наименование позиции", field: "positionName", type: "text" },
                        { label: "Описание позиции", field: "description", type: "text" },
                        { label: "Количество необходимого товара", field: "countProducts", type: "number" },
                        { label: "Единица измерения", field: "unitId", type: "select", options: allUnits },
                        { label: "Категория", field: "categoryId", type: "select", options:allCategories }
                    ].map(({ label, field, type, options }, index) => (
                        <Row key={index} className="mb-3">
                            <Label className="col-sm-9 col-form-label">{label}</Label>
                            <Col sm="12">
                                {type === "number" && <Input type="number" name={field} value={newPosition[field]} onChange={e => handleChangeNewPosition(field, e.target.value)} />}
                                {type === "text" && <Input type="text" name={field} value={newPosition[field]} onChange={e => handleChangeNewPosition(field, e.target.value)} />}
                                {type === "select" &&
                                    <Select
                                        options={options}
                                        value={options.find(option => option.value === newPosition[field])}
                                        onChange={selectedOption => handleChangeNewPosition(field, selectedOption.value)}
                                        placeholder={`Выберите ${label.toLowerCase()}`}
                                    />
                                }
                            </Col>
                        </Row>
                    ))}
                </CardBody>
            </CommonModal>


            <CommonModal
                isOpen={modalOrder}
                title="Редактировать спецификацию"
                togglerClose={handleCloseUpdateOrder}
                closeText="Отмена"
                saveText="Добавить"
                size="lg"
                onSave={handleUpdateOrder}
            >
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    {[
                        { label: "Наименование спецификации", field: "specificationName", type: "text" }
                    ].map(({ label, field, type, options }, index) => (
                        <Row key={index} className="mb-3">
                            <Label className="col-sm-9 col-form-label">{label}</Label>
                            <Col sm="12">
                                <Input
                                    type="text"
                                    name={field}
                                    value={currentOrderName}
                                    onChange={(e) => setCurrentOrderName(e.target.value)}
                                />
                            </Col>
                        </Row>
                    ))}
                </CardBody>
            </CommonModal>

        </div>
    );
};

export default TableSpecifications;
