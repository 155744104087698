import React, { Fragment, useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import FolderRounded from "@mui/icons-material/FolderRounded";
import { styled } from "@mui/material/styles";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";

import { CardBody } from "reactstrap";
import FileManagerContext from "../../../_helper/FileManager";
import CommonModal from "../../UiKits/Modals/common/modal";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";

const MovingFilesModal = ({ modal, setModal, toggle, element }) => {
	const { folders, getFileManagerData } = useContext(FileManagerContext);
	const { companyData } = useCompanyContext();
	const [selectFolderId, setSelectFolderId] = useState("");

	useEffect(() => {
		getFileManagerData();
	}, [companyData])

	function convertToHierarchy(data) {
		const map = new Map();
		const root = [];

		data.forEach((item) => {
			map.set(item.id, { id: item.id, label: item.title, children: [] });
		});

		data.forEach((item) => {
			const newItem = map.get(item.id);
			if (item.parentFolderId === null) {
				root.push(newItem);
			} else {
				const parentItem = map.get(item.parentFolderId);
				parentItem.children.push(newItem);
			}
		});

		return root;
	}

	const hierarchyData = convertToHierarchy(folders);

	const CustomTreeItem = styled(TreeItem)({
		[`& .${treeItemClasses.iconContainer}`]: {
			"& .close": {
				opacity: 0.3,
			},
		},
	});

	const handleItemSelectionToggle = (event, itemId, isSelected) => {
		if (isSelected) {
			setSelectFolderId(itemId);
		}
	};

	//перемещение файла
	const move = () => {
		const moveItem = {
			...element,
			folderId: selectFolderId,
		};
		//console.log(moveItem);
		// axios
		// 	.put(`${TelephonyApi}creatUserRight`, moveItem, {
		// 		headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
		// 	})
		// 	.then((response) => {
		// 		toast.success('Право доступа добавлено!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
	};

	return (
		<Fragment>
			<CommonModal isOpen={modal} title={`Переместить '${element.name}' в папку`} toggler={move} togglerClose={toggle} closeText="Отмена" saveText="Переместить">
				<CardBody style={{ padding: "10px", paddingLeft: "15px" }}>
					<Box sx={{ minHeight: 200, flexGrow: 1, maxWidth: 400 }}>
						<RichTreeView
							aria-label="customized"
							defaultExpandedItems={["1"]}
							slots={{
								expandIcon: FolderRounded,
								endIcon: FolderRounded,
								item: CustomTreeItem,
							}}
							items={hierarchyData}
							onItemSelectionToggle={handleItemSelectionToggle}
						/>
					</Box>
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};
export default MovingFilesModal;
