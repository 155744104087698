import React, { Fragment, useState, useContext, useEffect } from "react";
import { Database, Grid } from "react-feather";
import { Card, CardBody, Col } from "reactstrap";
import { H5, H6, LI, P, UL, Btn } from "../../../AbstractElements";
import FileManagerContext from "../../../_helper/FileManager";
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";

const FileSideBar = () => {
	const { usedSpace, totalSpace, tariffs, getFileManagerData } = useContext(FileManagerContext);
	const { companyData } = useCompanyContext();

	useEffect(() => {
		getFileManagerData();
	}, [companyData])

	const [isOpen, setIsOpen] = useState(false);

	return (
		<Fragment>
			<Col xl="3" className="box-col-3 pe-0 file-spacing">
				<div className="md-sidebar job-sidebar">
					<Btn attrBtn={{ onClick: () => setIsOpen(!isOpen), color: "primary", className: "md-sidebar-toggle" }}>File Filter</Btn>
					<div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && "open"}`}>
						<div className="file-sidebar">
							<Card>
								<CardBody>
									<UL attrUL={{ className: "simple-list border-0" }}>
										<LI attrLI={{ className: "border-0" }}>
											<div className="btn btn-outline-primary">
												<Database />
												Хранилище
											</div>
											<div className="m-t-15">
												<div className="progress sm-progress-bar mb-1">
													<div className="progress-bar bg-primary" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<P>{`Используется ${usedSpace} из ${totalSpace}`}</P>
											</div>
										</LI>
									</UL>
									<hr />
									<UL attrUL={{ className: "simple-list" }}>
										<LI attrLI={{ className: "border-0" }}>
											<div className="btn btn-outline-primary">
												<Grid />
												Тарифный план
											</div>
										</LI>
										{tariffs.map((item, i) => (
											<div key={i}>
												<LI attrLI={{ className: "border-0" }}>
													<div className="pricing-plan">
														<H6>{item.name}</H6>
														<H5>{item.price ? `${item.price} ` : "Бесплатно"}</H5>
														<P>{item.storage}</P>
														{item.installed ? (
															<Btn attrBtn={{ color: "primary", outline: true, size: "xs", disabled: true }}>Выбран</Btn>
														) : (
															<Btn attrBtn={{ color: "primary", outline: true, size: "xs" }}>Получить</Btn>
														)}
													</div>
												</LI>
											</div>
										))}
									</UL>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			</Col>
		</Fragment>
	);
};
export default FileSideBar;
