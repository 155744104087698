import React, { useState } from 'react';
import { P, H5, H4 } from '../../../../../AbstractElements';
import { Col } from 'reactstrap';
import axios from 'axios';
import { ModulesApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useConpagesContext } from '../../../../../_helper/ConsecutivePages/ConpagesProvider';
import { useNavigate } from 'react-router';
import { useCompanyContext } from '../../../../../_helper/Company/CompanyProvider';

const BusinessProcessCard = ({ image, title, subTitle, paragraph, template }) => {
	const navigate = useNavigate();

		const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { setNextPageInCompany, companyConpagesData } = useConpagesContext();

	const [isActive, setIsActive] = useState(false);
	
	console.log(companyData);

	const handleCardClick = () => {
		setIsActive(!isActive);

		axios
			.post(`${ModulesApi}create-company-selected-modules`, {
				companyId: companyData[0]?.id,
				selectedModules: template.template
			}, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				localStorage.removeItem('industryId');
				localStorage.setItem('SelectedModules', template.template);

				setNextPageInCompany();
				navigate(companyConpagesData.link);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	};

	const cardClassName = `pricingtable ${isActive ? 'active-card ' : ''}`;

	return (
		<Col lg="3" md="6">
			<div className={cardClassName} onClick={handleCardClick} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '385px', padding: '30px' }}>
				<div className="pricingtable-header" style={{ height: '37px', marginBottom: '18px' }}>
					<H4 attrH3={{ className: 'title' }}>{title}</H4>
				</div>
				<div className="price-value">
					<img alt="" src={image} />
				</div>
				<div className="pricingtable-header" style={{ height: '50px', marginBottom: '10px' }}>
					<H5 attrH3={{ className: 'title' }}>{subTitle}</H5>
				</div>

				<P attrLI={{ className: 'border-0' }}>{paragraph}</P>
			</div>
		</Col>
	);
};
export default BusinessProcessCard;
