import React, {useEffect, useState} from "react";
import { Col } from "reactstrap";
import { Sun, Moon } from "react-feather";
import { H5, Btn } from "../../../../AbstractElements";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const SetTheme = () => {
	const { companyData, companyTheme, getCompanyTheme, createCompanyTheme, deleteCompanyTheme, updateCompanyTheme } = useCompanyContext();

	// const { addMixBackgroundLayout } = useContext(CustomizerContext);

	const [curTheme, setTheme] = useState(localStorage.getItem("mix_background_layout"));

	useEffect(() => {
		companyData && getCompanyTheme(companyData[0]?.id);
	}, [companyData])

	const handleLayoutChange = (newLayout) => {
		setTheme(newLayout);
	};

	const updateSite = () => {
		//установить тему сайта
 		if (companyData) {
			if (companyTheme) {
				updateCompanyTheme(companyTheme.id, companyData[0]?.id, curTheme);
			}
			else {
				createCompanyTheme(companyData[0]?.id, curTheme);
			}
		}
	};

	return (
		<>
			<H5>Установите тему сайта</H5>
			<Col sm="3" className="mt-4" style={{ minHeight: "175px" }}>
				<Btn attrBtn={{ className: "btn-air-primary mb-4", outline: curTheme === "light-only" ? false : true, color: "light", onClick: () => handleLayoutChange("light-only"), size: "md" }}>
					<Sun size={"18px"} style={{ margin: "0 5px -3px 0px", color: "var(--ck-color-widget-hover-border)" }} />
					Светлая тема
				</Btn>
				<Btn attrBtn={{ className: "btn-air-primary mb-4", outline: curTheme === "light-only" ? true : false, color: "dark", onClick: () => handleLayoutChange("dark-only"), size: "md" }}>
					<Moon size={"18px"} style={{ margin: "0 5px -3px 0px", color: "var(--bs-primary-border-subtle)" }} />
					Темная тема
				</Btn>
			</Col>
			<div className="f-right">
				<Btn attrBtn={{ color: "primary", onClick: updateSite, className: "mt-4" }}>Сохранить</Btn>
			</div>
		</>
	);
};

export default SetTheme;
