import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Label, Input, CardBody, Col, InputGroup, InputGroupText } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

const TableLoyaltyCards = ({ filteredProducts }) => {
	const [types, setTypes] = useState([]);

	useEffect(() => {
		axios
			.get('/api/typesCards.json')
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.name,
					value: option.id,
				}));

				setTypes(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}
	const columns = [
		{
			name: <div className="ms-3">#</div>,
			selector: (row) => (
				// <Tooltip title="Подробная информация о карте" arrow placement="top">
				// 	<Link to={`${process.env.PUBLIC_URL}/loyalty/loyalty-cards/card-information?cardId=${row.id}`}>
				// 		<div className="ms-3">
				// 			<H6>{row.id}</H6>
				// 		</div>
				// 	</Link>
				// </Tooltip>
				<Tooltip title="Редактировать" arrow placement="top">
					<div className="font-primary" style={{ cursor: 'pointer' }} onClick={() => toggle(row)}>
						{row.id}
					</div>
				</Tooltip>
			),
			sortable: true,
			width: '15%',
		},

		{
			name: 'Номер',
			selector: (row) => `${row.card_number}`,
			sortable: true,
		},
		{
			name: 'Тип',
			selector: (row) => `${row.type}`,
			sortable: true,
		},
		{
			name: 'Телефон',
			selector: (row) => `${row.phone}`,
			sortable: true,
		},
		{
			name: 'Баланс',
			selector: (row) => `${row.bonus_points}`,
			sortable: true,
		},
		{
			name: 'Дата создания',
			selector: (row) => `${formatDate(row.date)}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {});
	};

	const [edit, setEdit] = useState(null);

	const send = () => {
		if (selectType == '' || date == '' || bonus == '' || phone == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			if (!Id) {
				setId(uuid());
			}
			const newEvent = {
				id: Id,
				type: selectType.value,
			};
			console.log(newEvent);
			// axios
			// 	.post(`/api/clientsDemandsForm.json`, newEvent)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Календарь добавлен!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 	});
		}
	};
	const [cardNumber, setCardNumber] = useState('');
	const [phone, setPhone] = useState('');
	const [selectType, setSelectType] = useState({ label: null, value: null });
	const [sold, setSold] = useState('');
	const [paid, setPaid] = useState('');
	const [visits, setVisits] = useState('');
	const [maxDiscountPercentage, setMaxDiscountPercentage] = useState('');
	const [maxFixedDiscount, setMaxFixedDiscount] = useState('');
	const [bonus, setBonus] = useState('');
	const [Id, setId] = useState('');
	const [date, setDate] = useState('');

	const [modal, setModal] = useState(false);

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setCardNumber(ObjectId.card_number)
			setSold(ObjectId.sold);
			setId(ObjectId.id);
			setPaid(ObjectId.paid);
			setSelectType({ label: ObjectId.type, value: ObjectId.type });
			setMaxDiscountPercentage(ObjectId.max_discount_percentage);
			setMaxFixedDiscount(ObjectId.max_fixed_discount);
			setVisits(ObjectId.visits);
			setBonus(ObjectId.bonus_points);
			setPhone(ObjectId.phone);
			setDate(ObjectId.date);
			setEdit(true);
		} else {
			setSelectType({ label: null, value: null });
			setSold('');
			setId('');
			setPaid('');
			setMaxDiscountPercentage('');
			setMaxFixedDiscount('');
			setVisits('');
			setBonus('');
			setDate(new Date());
			setPhone('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Номер карты</Label>
						<Col sm="11">
							<Input className="form-control" value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Владелец</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Тип</Label>
						<Col sm="11">
							<Select type="tel" placeholder="Выберите..." value={selectType} options={types} onChange={(option) => setSelectType(option)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Продано</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={sold} onChange={(e) => setSold(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Оплачено</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={paid} onChange={(e) => setPaid(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Визиты</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={visits} onChange={(e) => setVisits(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Максимальный процент скидки</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={maxDiscountPercentage} onChange={(e) => setMaxDiscountPercentage(e.target.value)} />
								<InputGroupText>{'%'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>

					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Максимальная фиксированная скидка</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="tel" value={maxFixedDiscount} onChange={(e) => setMaxFixedDiscount(e.target.value)} />
								<InputGroupText>{'₽'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Бонусы</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={bonus} onChange={(e) => setBonus(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Дата создания</Label>
						<Col sm="11">
							<Input className="form-control" disabled type="tel" value={formatDate(date)} onChange={(e) => setDate(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Контрольный вопрос</Label>
						<Col sm="11">
							<Input className="form-control" onChange={(e) => setCardNumber(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Ответ на контрольный вопрос</Label>
						<Col sm="11">
							<Input className="form-control" onChange={(e) => setCardNumber(e.target.value)} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableLoyaltyCards;
