import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit, Eye } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import FilterComponent from './FilterComponent';

import { TestingApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const TableTestList = () => {
	const [data, setData] = useState([]);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const fetchData = async () => {
		try {
			const response = await axios.get(`${TestingApi}getTestForms`, {
				params: {
					query: null,
				}, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
			});

			setData(concatResults(response).testForms);
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}

	const columns = [
		{
			name: '№',
			selector: (row) => `${row.id}`,
			sortable: true,
		},
		{
			name: 'Название',
			selector: (row) => <Link to={`${process.env.PUBLIC_URL}/app/test-results?testId=${row.id}`}>{row.title}</Link>,
			sortable: true,
		},
		{
			name: 'Дата создания',
			selector: (row) => `${formatDate(row.date)}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Просмотр" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/app/view-testing?testId=${row.id}`} style={{ marginLeft: '7px' }}>
							<Eye size={'18px'} />
						</Link>
					</Tooltip>
					<Tooltip title="Редактировать" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/app/add-testing?testId=${row.id}`} style={{ marginLeft: '7px' }}>
							<Edit size={'18px'} />
						</Link>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteTestForm(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
		{
			name: '',
			selector: (row) => (
				<Link to={`${process.env.PUBLIC_URL}/app/test-results?testId=${row.id}`} style={{ whiteSpace: 'break-spaces' }}>
					Просмотр результатов
				</Link>
			),
			sortable: true,
			width: '12%',
		},
	];

	const deleteTestForm = (idForm) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${TestingApi}deleteTestForm`, {
						data: {
							Id: idForm,
						}, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
					})
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						fetchData();
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении:', error);
					});
			}
		});
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</>
	);
};

export default TableTestList;
