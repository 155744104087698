// LeftPanel.js
import React from 'react';
import { Col, Label } from 'reactstrap';

const ElementTextarea = ({ setIsOverLeftPanel, content = 'Большое окно для ввода текста' }) => {
	return (
		<>
			<div
				className="col-sm-11 mb-3"
				draggable="true"
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'textarea');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Label className="col-sm-9 col-form-label">{content}</Label>
				<Col sm="12">
					<textarea
						className="form-control"
						rows={'3'}
						//placeholder="Подсказка для ввода"
					/>
				</Col>
			</div>
		</>
	);
};

export default ElementTextarea;
