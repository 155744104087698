import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { Label, Col } from 'reactstrap';
import TableCatalog from './TableCatalog';

import { Plus, Check } from 'react-feather';
import { Btn, H6 } from '../../../../../AbstractElements';

const Sales = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);

	const [products, setProducts] = useState([]);

	useEffect(() => {
		axios
			.get('/api/ProductsResult.json')
			.then((response) => {
				const selectOptions = response.data[0].data.map((option) => ({
					label: option.pagetitle,
					value: option.id,
				}));
				setProducts(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [selectedCategory, setSelectedCategory] = useState([]);

	useEffect(() => {
		const productIds = queryParams.getAll('productId');
		if (productIds.length > 0) {
			// Преобразуем массив categoryId в формат, который ожидает Select
			const selectedOptions = productIds.map((categoryId) => {
				const category = products.find((category) => category.value === categoryId);
				return category;
			});
			setSelectedCategory(selectedOptions);
		}
	}, [products]);

	const handleCategoryChange = (selectedOption) => {
		// Обновляем query параметр categoryId в URL
		const categoryIds = selectedOption.value;
		queryParams.delete('productId'); // Очищаем существующие значения
		queryParams.append(`productId`, categoryIds);
		navigate(`?${queryParams.toString()}`);
	};

	return (
		<Fragment>
			<Col style={{ padding: '4px 0' }}>
				<Label className="col-form-label">Выберите товар</Label>
				<Select placeholder="Выберите..." value={selectedCategory} options={products} className="js-example-basic-single col-sm-12" onChange={handleCategoryChange} />
			</Col>
			<TableCatalog />
			{/* если не подключен  модуль интернет-магазина */}
			{/* <div className="mb-3">
				<small className="text-muted f-18">Модуль интернет-магазина пока не активирован для вашей компании.</small>
			</div>
			<Link to={`${process.env.PUBLIC_URL}/business-process-template/select-modules`}>
				<Btn attrBtn={{ color: 'primary', className: 'm-r-50', outline: true }}>Подключить</Btn>
			</Link> */}
		</Fragment>
	);
};
export default Sales;
