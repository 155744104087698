import React, { useState, useEffect } from "react";
import Context from "./index";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../Company/CompanyProvider";

const FileManagerProvider = ({ children }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [myfile, setMyFile] = useState([]);
	const [folders, setFolders] = useState([]);
	const [tariffs, setTariffs] = useState([]);

	const [totalSpace, setTotalSpace] = useState([]);
	const [usedSpace, setUsedSpace] = useState([]);

	const getFileManagerData = async () => {
		axios
			.get(`/api/files.json`, {
				params: { query: "" },
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setMyFile(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`/api/tariffs.json`, {
				params: { query: "" },
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setTariffs(response.data.tariffs);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`/api/folders.json`, {
				params: { query: "" },
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setFolders(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`/api/storage_info.json`, {
				params: { query: "" },
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setTotalSpace(response.data.storage_info.total_space);
				setUsedSpace(response.data.storage_info.used_space);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}

	return (
		<Context.Provider
			value={{
				totalSpace,
				usedSpace,
				myfile,
				folders,
				tariffs,
				setMyFile,
				setFolders,
				getFileManagerData
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default FileManagerProvider;
