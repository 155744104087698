import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit, Check } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { Label, Input, CardBody, Col, Media } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

import { DeliveryApi } from '../../../../api/';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const TableResources = () => {
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const [data, setData] = useState([]);
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await axios.get(`${DeliveryApi}getPriceSettings`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			setData(concatResults(response).prices);
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	const columns = [
		{
			name: '',
			selector: (row) => '',
			sortable: true,
			width: '1%',
		},
		{
			name: 'Имя параметра на английском',
			selector: (row) => `${row.engName}`,
			sortable: true,
		},

		{
			name: 'Имя параметра на русском',
			selector: (row) => `${row.rusName}`,
			sortable: true,
		},
		{
			name: 'Параметр - глобальный',
			selector: (row) => <div>{row.isGlobal ? <Check className="font-success ms-2" /> : ''}</div>,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deletePriceSetting(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deletePriceSetting = (id) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${DeliveryApi}deletePriceSetting`, {
						data: {
							Id: id
						}, 
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
					})
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						fetchData();
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении:', error);
					});
			}

		});
	};



	const [edit, setEdit] = useState(null);

	const createPriceSetting = () => {
		if (name == '' || comment == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {

			const newPrice = {
				id: uuid(),
				engName: name,
				rusName: comment,
				isGlobal: status
			};
			axios
				.post(`${DeliveryApi}createPriceSetting`, newPrice, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Прайс добавлен!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});

		}
	}

	const updatePriceSetting = () => {

		if (name == '' || comment == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {

			const price = {
				id: Id,
				engName: name,
				rusName: comment,
				isGlobal: status
			};

			axios
				.put(`${DeliveryApi}updatePriceSetting`, price, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Прайс обновлен!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(!modal);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});

		}
	}

	const [modal, setModal] = useState(false);
	const [name, setName] = useState('');
	const [Id, setId] = useState('');
	const [comment, setComment] = useState('');
	const [status, setStatus] = useState(false);

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.engName);
			setId(ObjectId.id);
			setComment(ObjectId.rusName);
			setStatus(ObjectId.isGlobal);
			setEdit(true);
		} else {
			setName('');
			setId('');
			setComment('');
			setStatus(false);
			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={edit ? updatePriceSetting : createPriceSetting} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Имя параметра на английском</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Имя параметра на русском</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={comment} onChange={(e) => setComment(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3 mt-4">
						<Media>
							<Media body className={'text-start icon-state '} style={{ flexGrow: '0' }}>
								<Label className="switch">
									<Input type="checkbox" checked={status} onChange={(e) => setStatus(e.target.checked)} />
									<span className="switch-state"></span>
								</Label>
							</Media>
							<Label className="col-form-label ms-2 ">Глобальный</Label>
						</Media>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableResources;
