import React, { Fragment, useState, useEffect } from 'react';
import { Col, CardBody, Row, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Edit, Eye, EyeOff, Zap, Trash2 } from 'react-feather';

import { P, H5, H4, LI, UL, Btn, H3, ToolTip, H6 } from '../../../../AbstractElements';
import CommonModal from '../../../UiKits/Modals/common/modal';

const BusinessProcessCard = ({ image, title, subTitle, paragraph, serviceList }) => {
	const [isLabelEditVisible, setLabelEditVisible] = useState(false);

	const handleMouseEnter = () => {
		setLabelEditVisible(true);
	};

	const handleMouseLeave = () => {
		setLabelEditVisible(false);
	};

	const [total, setTotal] = useState(0);
	const [oldPrice, setOldPrice] = useState(0);

	useEffect(() => {
		calculateTotal();
	}, [serviceList]);

	const calculateTotal = () => {
		const totalPrice = serviceList.reduce((acc, product) => acc + product.price, 0);
		setTotal(totalPrice);
		setOldPrice(totalPrice);
	};

	const [editModal, setEditModal] = useState(false);

	const toggle = () => {
		setEditModal(!editModal);
	};

	const [visiblePrice, setVisiblePrice] = useState(true);
	const [visibleService, setVisibleService] = useState(true);
	const [visibleDesc, setVisibleDesc] = useState(true);

	const toggleVisiblePrice = () => {
		setVisiblePrice(!visiblePrice);
	};

	const toggleVisibleService = () => {
		setVisibleService(!visibleService);
	};

	const toggleVisibleDesc = () => {
		setVisibleDesc(!visibleDesc);
	};

	const [basictooltip, setbasictooltip] = useState(false);
	const toggleToolTip = () => setbasictooltip(!basictooltip);
	const [discountFrame, setDiscountFrame] = useState(false);

	const isDiscount = () => {
		setDiscountFrame(!discountFrame);
		setDiscountAmount(0);
		setAmount(0);
		setPercentage(0);
	};

	const [amount, setAmount] = useState(0);
	const [percentage, setPercentage] = useState(0);
	const [discountAmount, setDiscountAmount] = useState(0);

	const handleAmountChange = (e) => {
		const newAmount = parseFloat(e.target.value) || 0;
		setAmount(newAmount);
		setPercentage(((newAmount / total) * 100).toFixed(2));
	};

	const handlePercentageChange = (e) => {
		const newPercentage = parseFloat(e.target.value) || 0;
		setPercentage(newPercentage);
		setAmount(((newPercentage / 100) * total).toFixed(2));
	};

	useEffect(() => {
		const newDiscountAmount = (percentage / 100) * total;
		setDiscountAmount(newDiscountAmount.toFixed(2));
	}, [amount, percentage]);

	return (
		<Col lg="4" md="6">
			<div className={'pricingtable pricingtable-hov'} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '50px 30px', cursor: 'pointer' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={toggle}>
				<div className="pricingtable-header mb-3" style={{}}>
					<H3 attrH3={{ className: 'title m-b-0' }}>{title}</H3>
					{visibleDesc && <P attrLI={{ className: 'border-0' }}>{paragraph}</P>}
				</div>
				{visiblePrice &&
					(discountFrame ? (
						<>
							<div className="pricingtable-header f-w-600 mb-0" style={{ height: '30px', marginBottom: '10px', textDecoration: 'line-through', color: '#ddd' }}>
								<H5>{oldPrice} руб.</H5>
							</div>
							<div className="pricingtable-header font-primary f-w-600 mb-4" style={{ height: '30px', marginBottom: '10px' }}>
								<H3 attrH3={{ className: 'title ' }}>{total - discountAmount} руб.</H3>
							</div>
						</>
					) : (
						<div className="pricingtable-header font-primary f-w-600 mb-4" style={{ height: '30px', marginBottom: '10px' }}>
							<H3 attrH3={{ className: 'title ' }}>{total} руб.</H3>
						</div>
					))}
				{visibleService && (
					<div style={{ flexGrow: '1' }}>
						<UL attrUL={{ className: 'pricing-content flex-row mt-2' }}>{serviceList && serviceList.map((service) => <LI attrLI={{ className: 'border-0' }}>{service.name}</LI>)}</UL>
					</div>
				)}

				<Btn attrBtn={{ className: 'btn-pill mt-4', color: 'primary' }}>Заказать</Btn>
			</div>
			<div style={{ height: '50px', display: 'flex', justifyContent: 'center' }}>
				{isLabelEditVisible && (
					<div className="font-primary mt-3 label-edit" style={{ textAlign: 'center', display: 'inline' }}>
						<Edit size={'20px'} /> Редактировать
					</div>
				)}
			</div>
			<CommonModal isOpen={editModal} title="Редактировать" subTitle="Настройте отображение блоков" toggler={toggle} togglerClose={toggle} size="md" closeText="Сохранить">
				<CardBody style={{ padding: '0 51px' }}>
					<div className={'pricingtable'} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '50px 30px' }}>
						<div className="pricingtable-header mb-3" style={{}}>
							<H3 attrH3={{ className: 'title m-b-0' }}>{title}</H3>
							<div style={{ position: 'relative', padding: '0 6px', border: '1px solid #ddd', borderRadius: '12px' }} className={` ${visibleDesc ? '' : 'unvisible'}`}>
								<P attrLI={{ className: 'border-0' }}>{paragraph}</P>
								{visibleDesc ? <Eye style={{ position: 'absolute', top: '8px', right: '18px', cursor: 'pointer', color: 'var(--theme-default)' }} onClick={toggleVisibleDesc} /> : <EyeOff onClick={toggleVisibleDesc} style={{ position: 'absolute', cursor: 'pointer', top: '8px', right: '18px', color: 'var(--theme-default)' }} />}
							</div>
						</div>
						<div style={{ position: 'relative' }}>
							<div className={`pricingtable-header f-w-600 mb-2 ${visiblePrice ? 'font-primary' : 'unvisible'}`} style={{ marginBottom: '10px', padding: '6px', border: '1px solid #ddd', borderRadius: '12px' }}>
								<div style={{ position: 'relative' }}>
									<H3 attrH3={{ className: ' ' }}>{total - discountAmount} руб.</H3>
									{visiblePrice ? <Eye style={{ position: 'absolute', top: '8px', right: '12px', cursor: 'pointer' }} onClick={toggleVisiblePrice} /> : <EyeOff onClick={toggleVisiblePrice} style={{ position: 'absolute', cursor: 'pointer', top: '8px', right: '12px', color: 'var(--theme-default)' }} />}
								</div>
							</div>
							{discountFrame ? (
								<>
									<Trash2 id={`del-${title}`} style={{ position: 'absolute', top: '14px', right: '-28px', color: 'var(--theme-secondary)', cursor: 'pointer' }} onClick={isDiscount} />
									{/* <ToolTip
										attrToolTip={{
											placement: 'right',
											isOpen: basictooltip,
											target: `del-${title}`,
											toggle: toggleToolTip,
											offset: [0, 20],
										}}
									>
										Удалить скидку
									</ToolTip> */}
								</>
							) : (
								<>
									<Zap id={`info-${title}`} style={{ position: 'absolute', top: '14px', right: '-28px', color: 'var(--theme-secondary)', cursor: 'pointer' }} onClick={isDiscount} />
									<ToolTip
										attrToolTip={{
											placement: 'right',
											isOpen: basictooltip,
											target: `info-${title}`,
											toggle: toggleToolTip,
											offset: [0, 20],
										}}
									>
										Кликните для изменения стоимости
									</ToolTip>
								</>
							)}
						</div>
						{discountFrame && (
							<div className="mb-4">
								Размер скидки:
								<Row className="mt-2">
									<Col sm="6">
										<InputGroup>
											<Input className="form-control" type="text" value={percentage} onChange={handlePercentageChange} />
											<InputGroupText>{'%'}</InputGroupText>
										</InputGroup>
									</Col>
									<Col sm="6">
										<InputGroup>
											<Input className="form-control" type="text" value={amount} onChange={handleAmountChange} />
											<InputGroupText>{'руб'}</InputGroupText>
										</InputGroup>
									</Col>
									<div className="checkbox checkbox-primary">
										<Input id="checkbox-primary-1" type="checkbox" style={{ display: 'none' }} />
										<Label for="checkbox-primary-1">Отображать полную стоимость зачёркнутой</Label>
									</div>
								</Row>
							</div>
						)}
						<div style={{ flexGrow: '1', padding: '6px', border: '1px solid #ddd', borderRadius: '12px' }} className={` ${visibleService ? '' : 'unvisible'}`}>
							<div style={{ position: 'relative' }}>
								<UL attrUL={{ className: 'pricing-content flex-row mt-2' }}>
									{serviceList &&
										serviceList.map((service) => (
											<LI attrLI={{ className: 'border-0' }}>
												<div style={visibleService ? {} : { color: '#ddd' }}>{service.name}</div>
											</LI>
										))}
								</UL>
								{visibleService ? <Eye style={{ position: 'absolute', top: '8px', right: '12px', cursor: 'pointer', color: 'var(--theme-default)' }} onClick={toggleVisibleService} /> : <EyeOff onClick={toggleVisibleService} style={{ position: 'absolute', cursor: 'pointer', top: '8px', right: '12px', color: 'var(--theme-default)' }} />}
							</div>
						</div>
						<Btn attrBtn={{ className: 'btn-pill mt-4', color: 'primary' }}>Заказать</Btn>
					</div>
				</CardBody>
			</CommonModal>
		</Col>
	);
};
export default BusinessProcessCard;
