import React from "react";
import { Input, InputGroup, InputGroupText, Col } from "reactstrap";
import { Search, Plus } from "react-feather";
import { Btn } from "../../../../AbstractElements";
import { Link } from "react-router-dom";

const FilterComponent = ({ filterText, onFilter, typeId = null }) => (
	<>
		<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
			<Col md="4">
				<Link to={`${process.env.PUBLIC_URL}/counterparties/documents/edit-document${typeId ? `?typeId=${typeId}` : ""}`}>
					<Btn attrBtn={{ color: "primary", className: "me-4" }}>
						<Plus style={{ margin: "0px 2px -7px 0px" }} /> Добавить
					</Btn>
				</Link>
			</Col>
			<InputGroup>
				<Input className="" type="text" placeholder="Поиск.." value={filterText} onChange={onFilter} />
				<InputGroupText className="bg-primary">
					<Search size={"20px"} />
				</InputGroupText>
			</InputGroup>
		</div>
	</>
);

export default FilterComponent;
