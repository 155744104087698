import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { useKeycloak } from "@react-keycloak/web";
import { Label, Input, CardBody, Col, Media } from "reactstrap";

import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { TwinAuthApi } from "../../../../api";
import {useDocumentManagementContext} from "../../../../_helper/DocumentManagement/DocumentManagementProvider";
import {useUsersContext} from "../../../../_helper/Users/UsersProvider";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TableRightsAccess = ({ products, setProducts }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { createDocumentRightAccess, updateDocumentRightAccess } = useDocumentManagementContext();
	const { getUsersByIdArray, usersByIdArray } = useUsersContext();

	const [managingDocumentTemplates, setManagingDocumentTemplates] = useState(true);

	const [editModal, setEditModal] = useState(false);
	const toggle = () => {
		setEditModal(!editModal);
	};

	useEffect(() => {
		products && getUsersByIdArray(products.map((item) => item.userId));
	}, [products]);

	const getUserName = (id) => {
		let user = usersByIdArray && usersByIdArray.find(item => item.id === id);

		return user ? `${user.firstName} ${user.lastName}` : "";
	}

	const getUserLogin = (id) => {
		let user = usersByIdArray && usersByIdArray.find(item => item.id === id);

		return user ? user.username : "";
	}

	const columns = [
		{
			name: "Пользователь",
			selector: (row) => `${getUserName(row.userId)}`,
			sortable: true,
		},
		{
			name: "Логин",
			selector: (row) => `${getUserLogin(row.userId)}`,
			sortable: true,
		},
		{
			name: "Управление шаблонами документов",
			selector: (row) => (
				<Media>
					<Media body className="text-end icon-state">
						<Label className="switch">
							<Input type="checkbox" id={`checkbox-${row.id}`} checked={row.managingDocumentTemplates} onChange={() => updAccess(row)} />
							<span className="switch-state"></span>
						</Label>
					</Media>
				</Media>
			),
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (siteId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {});
	};

	const send = () => {
		/*const newUser = {
			user: {
				name: selectUser.label,
				id: selectUser.value,
				login: selectUser.login,
			},
			managingDocumentTemplates: managingDocumentTemplates,
		};*/
		if (selectUser && selectUser.value) {
			createDocumentRightAccess(selectUser.value, managingDocumentTemplates);
		} else {
			//updateDocumentRightAccess(selectUser.value, );
			//id, userId, companyId, managingDocumentTemplates
		}



		// axios
		// 	.post(`/api/clientsDemandsForm.json`, newUser)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success("Пользователь добавлен!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setEditModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
	};

	const updAccess = (item) => {
		updateDocumentRightAccess(item.id, item.userId, item.companyId, !item.managingDocumentTemplates);
		//id, userId, companyId, managingDocumentTemplates
		/*const updUser = {
			...item,
			managingDocumentTemplates: !item.managingDocumentTemplates,
		};
		// axios
		// 	.post(`/api/clientsDemandsForm.json`, updUser)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success("Доступ добавлен!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		const rowIndex = products.findIndex((row) => row.id === item.id);
		const newData = [...products];
		newData[rowIndex] = {
			...newData[rowIndex],
			managingDocumentTemplates: !newData[rowIndex].managingDocumentTemplates,
		};*/
		//setProducts(newData);
		// 	})
		// 	.catch((error) => {
		// 		toast.error("Ошибка при добавлении доступа", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		console.error(error);
		// 	});
	};
	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = products.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const [selectUser, setSelectUser] = useState(null);
	const [allUsersNames, setAllUsersNames] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
					login: option.login,
				}));
				setAllUsersNames(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				noDataComponent={"В данной таблице ещё нет записей."}
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={editModal} title={"Добавить"} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col sm="12" className="row col-sm-12 mb-3">
						<Label>Пользователь</Label>
						<Col sm="11">
							<Select
								placeholder="Начните вводить имя пользователя..."
								noOptionsMessage={() => "Нет результатов"}
								options={allUsersNames}
								value={selectUser}
								onChange={(option) => setSelectUser(option)}
								className="js-example-basic-single col-sm-12"
							/>
						</Col>
					</Col>
					<Col sm="12" className="row col-sm-12 mb-3 mt-4">
						<Col sm="11" className="pt-2 pb-2 pe-3 ps-3 b-r-8 mt-3 font-primary" style={{ backgroundColor: "#7366ff20", marginLeft: "12px", marginRight: "12px" }}>
							<Media>
								<Label className="col-form-label ">Управление шаблонами документов</Label>
								<Media body className="icon-state text-end">
									<Label className="switch">
										<Input type="checkbox" id={`checkbox-1`} checked={managingDocumentTemplates} onChange={(e) => setManagingDocumentTemplates(e.target.checked)} />
										<span className="switch-state"></span>
									</Label>
								</Media>
							</Media>
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableRightsAccess;
