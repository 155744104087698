import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import { H5 } from '../../../../../AbstractElements';

const TableCatalog = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const productId = queryParams.get('productId');

	const [data, setData] = useState([]);
	const [name, setName] = useState('');
	const [price, setPrice] = useState(0);
	const [pricesArray, setPricesArray] = useState([]);
	const [dateArray, setDateArray] = useState([]);

	useEffect(() => {
		axios
			.get('/api/ProductsResult.json')
			.then((response) => {
				const foundItem = response.data[0].data.find((item) => item.id === productId);
				setName(foundItem.pagetitle);
				setPrice(foundItem.ProductPrice.Price);

				const sortedData = foundItem.sales.sort((a, b) => new Date(a.date) - new Date(b.date));
				setData(sortedData);
				console.log();

				const prices = sortedData.map((item) => item.quantity * foundItem.ProductPrice.Price);
				const dates = sortedData.map((item) => item.date);
				setPricesArray(prices);
				setDateArray(dates);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const columns = [
		{
			name: 'Название',
			selector: () => `${name}`,
			sortable: true,
			hide: 'sm',
			// width: '200px',
		},
		{
			name: 'Цена',
			selector: () => `${price} руб`,
			sortable: true,
		},
		{
			name: 'Дата',
			selector: (row) => `${formatDateTime(row.date)}`,
			sortable: true,
		},
		{
			name: 'Количество',
			selector: (row) => `${row.quantity}`,
			sortable: true,
		},
		{
			name: 'Итого',
			selector: (row) => <div>{price * row.quantity} руб</div>,
			sortable: true,
		},
	];
	// Функция для форматирования даты и времени
	function formatDateTime(dateTimeStr) {
		const date = new Date(dateTimeStr);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	}

	const CryptocurrencyPricesTitleChartData = {
		options: {
			chart: {
				toolbar: {
					show: false,
				},
				height: 400,
				type: 'area',
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: 3,
				curve: 'smooth',
			},
			xaxis: {
				categories: dateArray.map(formatDateTime),
				tickAmount: 5,
				tickPlacement: 'between',
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				tooltip: {
					enabled: false,
				},
			},
			grid: {
				borderColor: 'rgba(196,196,196, 0.3)',
				padding: {
					top: -20,
					right: -16,
					bottom: 0,
				},
			},
			fill: {
				opacity: 0.2,
			},
			colors: ['#7568ff'],

			tooltip: {
				x: {
					format: 'dd/MM/yy HH:mm',
				},
			},
			responsive: [
				{
					breakpoint: 576,
					options: {
						chart: {
							height: 200,
						},
					},
				},
			],
		},
		series: [
			{
				name: 'Выручка',
				data: pricesArray,
			},
		],
	};

	return (
		<>
			{data.length ? (
				<>
					<H5 attrH5={{ className: 'mb-4 mt-4' }}>{name} - данные о продажах</H5>
					<div className="mb-4">
						<DataTable columns={columns} data={data} striped pagination noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
					</div>
					<div className="mt-4">
						<ReactApexChart type="area" height={300} series={CryptocurrencyPricesTitleChartData.series} options={CryptocurrencyPricesTitleChartData.options} />
					</div>
				</>
			) : (
				<H5 attrH5={{ className: 'mb-4 mt-4' }}>Данные о продажах отсутствуют</H5>
			)}
		</>
	);
};

export default TableCatalog;
