import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Container } from 'reactstrap';
import { useLocation } from 'react-router';
import { Plus, Trash2 } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import Select from 'react-select';
import { Label, Input, Col } from 'reactstrap';
import { Breadcrumbs, H5, Btn, P } from '../../../../AbstractElements';
import CommonModal from '../../../UiKits/Modals/common/modal';
import uuid from 'react-uuid';

const AddEditCard = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const serviceId = queryParams.get('cardId');

	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);

	useEffect(() => {
		axios
			.get('/api/storage.json')
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setCategories(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [name, setName] = useState('');
	const [desc, setDesc] = useState('');
	const [id, setId] = useState('');
	const [consumables, setConsumables] = useState([]);

	const [nameConsumables, setNameConsumables] = useState('');
	const [quantity, setQuantity] = useState('');

	useEffect(() => {
		axios
			.get('/api/technologicalCardList.json')
			.then((response) => {
				const foundItem = response.data.find((item) => item.id === serviceId);
				if (foundItem) {
					setName(foundItem.name);
					setDesc(foundItem.description);
					setConsumables(foundItem.consumables);
					setId(foundItem.id);
				}
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const columns = [
		{
			name: 'Название',
			selector: (row) => `${row.name} `,
			sortable: true,
		},
		{
			name: 'Склад',
			selector: (row) => `${row.storage} `,
			sortable: true,
		},
		{
			name: 'Количество',
			selector: (row) => `${row.quantity} ${row.unit}`,
			sortable: true,
		},

		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];
	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {});
	};

	const [modal, setModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
	};

	const addConsumables = () => {
		if (nameConsumables == '' || quantity == '' || selectedCategory == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const newEvent = {
				id: uuid(),
				name: nameConsumables,
				storage: selectedCategory.label,
				quantity: quantity,
			};
			console.log(newEvent);
			// axios
			// 	.post(`/api/clientsDemandsForm.json`, newEvent)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Календарь добавлен!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 	});
		}
	};

	const send = () => {
		if (name == '' || desc == '' || selectedCategory == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			if (!id) {
				setId(uuid());
			}
			const newEvent = {
				id: id,
				name: name,
				category: selectedCategory,
				description: desc,
			};
			console.log(newEvent);
			// axios
			// 	.post(`/api/clientsDemandsForm.json`, newEvent)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Календарь добавлен!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 	});
		}
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={name ? `Редактировать технологическую карту` : `Добавить технологическую карту`} parent="Склад" title={name ? `Редактировать технологическую карту` : `Добавить технологическую карту`} />
			<Container fluid={true}>
				<Card>
					<CardBody className="m-25">
						<Col className="row col-sm-12 mb-3 ">
							<Label className="col-sm-9 col-form-label">Название</Label>
							<Col sm="11">
								<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
							</Col>
						</Col>

						<Col className="row col-sm-12 mb-4">
							<Label className="col-sm-9 col-form-label">Описание</Label>
							<Col sm="11">
								<Input className="form-control" type="tel" value={desc} onChange={(e) => setDesc(e.target.value)} />
							</Col>
						</Col>
						<H5 attrH5={{ className: 'mt-4 mb-4' }}>Расходники</H5>
						{consumables.length ? <DataTable columns={columns} data={consumables} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} /> : <P>расходники не добавлены</P>}
						<Btn attrBtn={{ outline: true, color: 'primary', onClick: toggle }}>
							<Plus size={'20px'} style={{ margin: ' 0 2px -5px 0' }} />
							Добавить расходник
						</Btn>
						<CommonModal isOpen={modal} title={'Добавить расходник'} toggler={addConsumables} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
							<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
								<Col className="row col-sm-12 mb-3 ">
									<Label className="col-sm-9 col-form-label">Название</Label>
									<Col sm="11">
										<Input className="form-control" type="tel" value={nameConsumables} onChange={(e) => setNameConsumables(e.target.value)} />
									</Col>
								</Col>
								<Col className="row col-sm-12 mb-3">
									<Label className="col-sm-9 col-form-label">Склад</Label>
									<Col sm="11">
										<Select placeholder="Выберите..." value={selectedCategory} options={categories} className="js-example-basic-single col-sm-12" onChange={(selected) => setSelectedCategory(selected)} />
									</Col>
								</Col>
								<Col className="row col-sm-12 mb-3 ">
									<Label className="col-sm-9 col-form-label">Количество</Label>
									<Col sm="11">
										<Input className="form-control" type="tel" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
									</Col>
								</Col>
							</CardBody>
						</CommonModal>
					</CardBody>
					<CardFooter>
						<div className="modal-footer">
							<Btn attrBtn={{ color: 'primary', onClick: send }}>Сохранить изменения</Btn>
						</div>
					</CardFooter>
				</Card>
			</Container>
		</Fragment>
	);
};
export default AddEditCard;
