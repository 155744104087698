import React, { useMemo, useState } from "react";
import { Trash2, Edit, ExternalLink } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { toast } from "react-toastify";
import { Label, Input, CardBody, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { TelephonyApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TablePBXList = ({ products, getData }) => {
	const [editModal, setEditModal] = useState(false);

	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [serverAddress, setServerAddress] = useState("");
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [username, setUsername] = useState("");
	const [proxy, setProxy] = useState("");
	const [enableIncomingCalls, setEnableIncomingCalls] = useState(false);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const toggle = () => {
		setEditModal(!editModal);
	};

	const columns = [
		{
			name: "Название подключения",
			selector: (row) => `${row.connection_name}`,
			sortable: true,
		},
		{
			name: "Адрес сервера",
			selector: (row) => `${row.server_address}`,
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/telephony-app/pbx-connection-settings?id=${row.id}`}>
							<Edit size={"18px"} />
						</Link>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (siteId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить эту задачу!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${TelephonyApi}deletePbx/${siteId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						SweetAlert.fire("Удалено!", "АТС была удалена.", "success");
						getData();
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении задачи:", error);
					});
			}
		});
	};

	const send = () => {
		const newPBX = {
			name: name,
			serverAddress: serverAddress,
			login: login,
			password: password,
			usernameAuth: username,
			proxyServer: proxy,
			enableIncomingCalls: enableIncomingCalls,
		};

		axios
			.post(`${TelephonyApi}createPbx`, newPBX, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				toast.success("АТС добавлена!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setEditModal(!editModal);
				getData();
			})
			.catch((error) => {
				console.error(error);
			});
	};
	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = products.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				noDataComponent={"В данной таблице ещё нет записей."}
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={editModal} title={"Добавить"} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название подключения</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Адрес сервера</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={serverAddress} onChange={(e) => setServerAddress(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Логин</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={login} onChange={(e) => setLogin(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Пароль</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Имя пользователя для авторизации</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Прокси-сервер</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={proxy} onChange={(e) => setProxy(e.target.value)} />
						</Col>
					</Col>
					<div className="checkbox checkbox-primary col-md-6">
						<Input id={`checkbox`} type="checkbox" checked={enableIncomingCalls} onChange={(e) => setEnableIncomingCalls(e.target.checked)} />
						<Label for={`checkbox`}>Включить входящие звонки</Label>
					</div>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TablePBXList;
