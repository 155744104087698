import React, { useState } from 'react';
import './ImportCompletionStep.css';
import { Button } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { useInteractivePlanMapsContext } from "../../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../../../../../../_helper/Company/CompanyProvider";

// Функция для фильтрации полей
const filterFields = (fields, mappedFields) => {
    const fieldMap = new Map(mappedFields.map(field => [field.xmlField, field.dbField]));

    const traverseFields = (fieldList) => {
        return fieldList.flatMap(field => {
            if (field.xmlField && fieldMap.has(field.xmlField)) {
                return [
                    {
                        ...field,
                        dbField: fieldMap.get(field.xmlField)
                    },
                    ...traverseFields(field.fields)
                ];
            }
            return traverseFields(field.fields);
        });
    };

    return traverseFields(fields);
};

const ImportCompletionStep = ({ prevStep, fileData, mapId }) => {
    const [importProgress, setImportProgress] = useState(0);
    const [importing, setImporting] = useState(false);
    const [currentField, setCurrentField] = useState('');
    const [results, setResults] = useState([]);
    const { sendImportField } = useInteractivePlanMapsContext();
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const companyId = companyData.map((item) => (item.attributes.companyId[0]));
    const [objectTypeId, setObjectTypeId] = useState(fileData.unicDbFields[0].objectTypeId)

    // Функция для получения идентификатора объекта
    const getObjectIdentifier = (ad) => {
        const idField = ad.fields.find(field => field.xmlField === 'Id' || field.xmlField === 'id' || field.xmlField === 'ID');
        return idField && idField.valueXmlField ? idField.valueXmlField : 'без идентификатора';
    };

    // Функция для запуска импорта
    const startImport = async () => {
        if (!fileData) {
            setResults([{ field: 'Все', status: 'Ошибка', error: 'Нет данных для импорта.' }]);
            return;
        }

        setImporting(true);
        setImportProgress(0);
        setCurrentField('');
        setResults([]);

        const totalFieldsCount = fileData.ads.reduce((sum, ad) => {
            const mappedFields = filterFields(ad.fields, fileData.mappedFields);
            return sum + mappedFields.length;
        }, 0);

        try {
            for (const ad of fileData.ads) {
                const objectIdentifier = getObjectIdentifier(ad);

                // Фильтрация полей для текущего объекта
                const allFields = filterFields(ad.fields, fileData.mappedFields);

                if (allFields.length === 0) {
                    setResults(prevResults => [...prevResults, { object: objectIdentifier, field: ad.name, status: 'Ошибка', error: 'Нет полей для импорта.' }]);
                    continue;
                }

                await loadFields(allFields, ad, objectIdentifier, totalFieldsCount);
            }

            // Делаем небольшую задержку, чтобы убедиться, что прогресс-бар заполнен полностью
            setTimeout(() => {
                setCurrentField('');

                SweetAlert.fire({
                    title: 'Импорт завершен!',
                    text: 'Все поля успешно импортированы.',
                    icon: 'success',
                    confirmButtonText: 'ОК'
                });

            }, 1000); // Задержка 1 секунда для завершения всех визуальных обновлений
        } catch (err) {
            console.error('Ошибка импорта:', err);
            setResults(prevResults => [...prevResults, { field: 'Все', status: 'Ошибка', error: 'Произошла ошибка при импорте данных.' }]);
        } finally {
            setImporting(false);
        }
    };

   
    let importedFields = 0; 
     // Функция для загрузки полей
    const loadFields = async (fields, ad, objectIdentifier, totalFields) => {
        for (const field of fields) {
            setCurrentField(`Объект (${objectIdentifier}). Загрузка поля - ${field.xmlField}`);

            let errorResp = null;
            let statusResp = "Загружено успешно";

            try {
                await sendImportFieldShell(field, ad);
            } catch (error) {
                // Логируем и обрабатываем ошибку
                statusResp = 'Ошибка';
                errorResp = `Ошибка при обработке поля: ${error.message}`;
                console.error(`Ошибка при отправке поля ${field.xmlField} объекта ${objectIdentifier}: ${error.message}`);
            } finally {
                // Независимо от успеха или ошибки обновляем прогресс
                importedFields += 1;
                const progress = Math.round((importedFields / totalFields) * 100); // Рассчитываем прогресс
                setImportProgress(progress);
                setResults(prevResults => [
                    ...prevResults,
                    { object: objectIdentifier, field: field.xmlField, status: statusResp, error: errorResp }
                ]);
            }
        }
    };

    // Функция отправки запроса на сервер с задержкой
    const sendImportFieldShell = async (field, ad) => {
        try {
            field.objectId = ad.id;
            field.objectName = fileData.name;
            field.objectNumber = fileData.number;
            field.objectMapId = mapId;
            field.companyId = companyId;
            field.adName = ad.name; 
            field.objectTypeId = objectTypeId;

            // Отправка запроса на сервер
            const response = await sendImportField(field);
            if (response.status !== 200) {
                throw new Error(`Ошибка при отправке поля: ${response.statusText}`);
            }

            await new Promise(resolve => setTimeout(resolve, 1000));

        } catch (error) {
            console.error(`Ошибка при отправке поля: ${error.message}`);
            throw error;
        }
    };

    return (
        <div className="container">
            <div className="import-completion-container">
                <h4>Завершение импорта</h4>
                <div className="progress-container">
                    <div className="progress-bar" style={{ width: `${importProgress}%` }}></div>
                </div>
                {importing && <p className="loading-text">{currentField}</p>}
                <div style={{ marginTop: "30px" }}>
                    {!importing && (
                        <Button
                            className="prevStepBtn"
                            onClick={prevStep}
                        >
                            Назад
                        </Button>
                    )}
                    <Button
                        color="primary"
                        className="import-btn"
                        onClick={startImport}
                        disabled={importing}
                        style={{ marginLeft: importing ? '0' : '20px' }}
                    >
                        {importing ? 'Загрузка...' : 'Загрузить объект'}
                    </Button>
                </div>
            </div>
            <div className="results-container">
                <h5>Результаты загрузки:</h5>
                <ul>
                    {results.map((result, index) => (
                        <li key={index} className={`result-item ${result.status === 'Ошибка' ? 'error' : 'success'}`}>
                            Объект ({result.object}). Поле ({result.field}): {result.status}
                            {result.error && <div className="error-message">{result.error}</div>}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ImportCompletionStep;
