import React, { Fragment, useState } from 'react';

import Language from './Language';
import Searchbar from './Searchbar';
import Notificationbar from './Notificationbar';
import MoonLight from './MoonLight';
import CartHeader from './CartHeader';
import BookmarkHeader from './BookmarkHeader';
import UserHeader from './UserHeader';
import { UL } from '../../../AbstractElements';
import { Col } from 'reactstrap';
import { useKeycloak } from '@react-keycloak/web';


const RightHeader = () => {

	const { keycloak } = useKeycloak();
	const [roles, setRoles] = useState(
		keycloak.tokenParsed ? keycloak.tokenParsed.realm_access?.roles : []
	);

	return (
		<Fragment>
			<Col xxl="7" xl="6" md="7" className="nav-right pull-right right-header p-0 ms-auto">
				{/* <Col md="8"> */}
				<UL attrUL={{ className: 'simple-list nav-menus flex-row' }}>
					{/*<Language />*/}
					{/*<Searchbar />*/}
					<BookmarkHeader />
					<MoonLight />
					{/*<CartHeader />*/}

					{roles.includes('reminder-api') && (
						<>
							<Notificationbar />
						</>
					)}

					<UserHeader />
				</UL>
				{/* </Col> */}
			</Col>
		</Fragment>
	);
};

export default RightHeader;
