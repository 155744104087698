import React, {Fragment, useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import {
    Container, Form, FormGroup, Input, Label,
    Row,
} from "reactstrap";
import { CardBody, Card, Col } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import "@measured/puck/puck.css";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import CommonModal from "../../../UiKits/Modals/common/modal";
import Editor from "./Editor";
import {PuffLoader} from "react-spinners";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";

const DocumentTypeEditor = () => {
    const {
        getDocumentType,
        documentType,
        getDocumentTypeCharacteristcs,
        modalCreateDocChar,
        changeModalCreateDocCharVisible,
        createDocumentTypeCharacteristic
    } = useClientsContext();
    const { companyData } = useCompanyContext();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const typeId = queryParams.get('documentTypeId');
    const [charName, setCharName] = useState("");
    const [initialData, setInitialData] = useState(null);

    useEffect(() => {
        Array.isArray(companyData) && getDocumentTypeCharacteristcs(typeId);
        Array.isArray(companyData) && getDocumentType(typeId);
    }, [typeId, companyData]);

    useEffect(() => {
        documentType && documentType?.formJson && documentType?.id === typeId && setInitialData(JSON.parse(documentType?.formJson));
        if (documentType && documentType?.id === typeId && !documentType?.formJson) {
            setInitialData(" ");
        }
    }, [documentType])

    const toggleCreate = () => {
        changeModalCreateDocCharVisible();
        setCharName('');
    }

    const createTypeChar = () => {
        if (charName !== '' && typeId) {
            createDocumentTypeCharacteristic(typeId, charName);
            toggleCreate();
        }
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Редактирование типа документа" parent="Клиенты" title="Редактирование типа документа" />
            <Container fluid className="user-card">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                {
                                    initialData ?
                                        <Editor initialData={initialData} /> :
                                        <PuffLoader size={80} color="#7366ff" style={{ margin: "20px auto", display: "inherit", position: "relative", width: "80px", height: "80px" }} />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <CommonModal isOpen={modalCreateDocChar} title={'Добавление характеристики'} toggler={createTypeChar} togglerClose={toggleCreate}
                         closeText="Отмена" saveText="Сохранить">
                <CardBody style={{padding: '10px', paddingLeft: '15px'}}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{flexDirection: 'column'}}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Название
                                </Label>
                                <Col sm="11">
                                    <Input
                                        className="form-control"
                                        value={charName}
                                        placeholder="Название характеристики"
                                        onChange={(e) => setCharName(e.target.value)} />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
        </Fragment>
    );
};
export default DocumentTypeEditor;
