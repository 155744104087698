import { Activity, Cast, CheckCircle, FilePlus, Trash } from 'react-feather';

export const TodoCategoriesList = [
  {
    title: 'Все задачи',
    icon: <FilePlus />,
    color: 'primary',
  },
  {
    title: 'Завершенные',
    icon: <CheckCircle />,
    badge: '3',
    color: 'success',
  },
  {
    title: 'В ожидании',
    icon: <Cast />,
    badge: '2',
    color: 'danger',
  },
  {
    title: 'В процессе',
    icon: <Activity />,
    badge: '2',
    color: 'info',
    badgeColor: 'primary',
  },
  {
    title: 'Корзина',
    icon: <Trash />,
    color: 'danger',
  },
];
