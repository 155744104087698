import React from "react";
import {Input, Label, Media} from "reactstrap";

export const InputPrepayment = {
    label: "Оплатить онлайн",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
    },
    defaultProps: {
        children: "Оплатить онлайн",
    },
    render: ({ children }) => {
        return <div>
            <Media>
                <Label className="col-form-label col-sm-4">{children}</Label>
                <Media body className={'text-start icon-state '} style={{ flexGrow: '0' }}>
                    <Label className="switch">
                        <Input type="checkbox" />
                        <span className="switch-state"></span>
                    </Label>
                </Media>
            </Media>
        </div>;
    },
};