import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ru';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import axios from 'axios';
import { H4 } from '../../../../../AbstractElements';
import Teachers from '../../Courses/BuildCourse/Teachers/Teachers';
import Progress from '../../Courses/BuildCourse/Progress';
import Sales from '../../Courses/BuildCourse/Sales';
import Content from './Content';
import { useLocation } from 'react-router';
import { EducationApi } from '../../../../../api';

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const BuildLessonTab = ({ data, teachers, students }) => {
	const defaultValue = '1';
	const storedValue = localStorage.getItem('primaryLessonTab');
	const initialPrimaryColorTab = storedValue ? storedValue : defaultValue;
	const [primarycolorTab, setprimarycolorTab] = useState(initialPrimaryColorTab);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const lessonId = queryParams.get('lessonId');

	const handleCourseTab = (num) => {
		setprimarycolorTab(num);
		localStorage.setItem('primaryLessonTab', num);
	};

	const [events, setEvents] = useState([]);
	useEffect(() => {
		axios
			.get('/api/lessonSheldue.json')
			.then((response) => {
				setEvents(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const { defaultDate, messages } = useMemo(
		() => ({
			defaultDate: new Date(),
			messages: {
				week: 'Неделя',
				work_week: 'Рабочая неделя',
				day: 'День',
				month: 'Месяц',
				previous: 'Предыдуший',
				next: 'Следующий',
				today: 'Сегодня',
				agenda: 'План',
				date: 'Дата',
				time: 'Время',
				event: 'Событие',
				allDay: 'Весь день',
				yesterday: 'Вчера',
				tomorrow: 'Завтра',
				noEventsInRange: 'В этом диапазоне нет событий.',

				showMore: (total) => `+ ещё ${total}`,
			},
		}),
		[]
	);

	return (
		<Fragment>
			<Nav className="nav-primary mt-4" tabs>
				<NavItem>
					<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '1' ? 'active' : ''} onClick={() => handleCourseTab('1')}>
						<i className="icofont icofont-book-alt"></i>
						Содержание
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '2' ? 'active' : ''} onClick={() => handleCourseTab('2')}>
						<i className="icofont icofont-teacher"></i>
						Преподаватели
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '3' ? 'active' : ''} onClick={() => handleCourseTab('3')}>
						<i className="icofont icofont-tasks-alt"></i>
						Расписание
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '4' ? 'active' : ''} onClick={() => handleCourseTab('4')}>
						<i className="icofont icofont-trophy-alt"></i>
						Достижения
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '5' ? 'active' : ''} onClick={() => handleCourseTab('5')}>
						<i className="icofont icofont-growth"></i>
						Продажи
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={primarycolorTab}>
				<TabPane className="fade show" tabId="1">
					<Content />
				</TabPane>
				<TabPane tabId="2">
					<H4 attrH4={{ className: 'mb-2 mt-4' }}>Список преподавателей</H4>
					<div className="p-t-20 p-b-30">{teachers && <Teachers teachers={teachers} />}</div>
				</TabPane>
				<TabPane tabId="3">
					<H4 attrH4={{ className: 'mb-2 mt-4' }}>Расписание занятий</H4>
					<Calendar messages={messages} localizer={localizer} scrollToTime={new Date(1970, 1, 1, 6)} views={allViews} events={events} eventOverlap dragRevertDuration={500} dragScroll droppable={true} showMultiDayTimes step={60} startAccessor="start" endAccessor="end" selectable />
				</TabPane>
				<TabPane tabId="4">
					<H4 attrH4={{ className: 'mb-2 mt-4' }}>Журнал успеваемости</H4>
					<div className="p-t-20 p-b-30">{students && <Progress students={students} />}</div>
				</TabPane>
				<TabPane tabId="5">
					<H4 attrH4={{ className: 'mb-2 mt-4' }}>Продажи</H4>
					<div className="p-t-20 p-b-30">
						<Sales />
					</div>
				</TabPane>
			</TabContent>
		</Fragment>
	);
};
export default BuildLessonTab;
