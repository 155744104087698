import React, { Fragment, useState, useEffect } from 'react';
import { Container, Card, CardBody, Row, CardHeader } from 'reactstrap';
import axios from 'axios';

import { Breadcrumbs, H5 } from '../../../AbstractElements';
import { DemandsApi } from '../../../api';
import ClientFormProducts from './ClientFormProducts';
import { useKeycloak } from '@react-keycloak/web';
import {concatResults} from "../../../concatResults";
import { useCompanyContext } from '../../../_helper/Company/CompanyProvider';

const DemandFormProducts = () => {
	const [demandForm, setDemandForm] = useState('');
	const {keycloak} = useKeycloak()
	const {companyData} = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${DemandsApi}getDemandForms`, {
				params: {
					query: 'f7636026-6042-4715-b486-96a68900710d',
				},
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
			})
			.then((response) => {
				setDemandForm(concatResults(response).demandForms[0]);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Создание заявки на заказ товаров" parent="Заявки" title="Создание заявки на заказ товаров" />
			<Container fluid={true}>
				<Row>
					<Card>
						{demandForm && (
							<CardBody>
								<ClientFormProducts demandForm={demandForm} />
							</CardBody>
						)}
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};
export default DemandFormProducts;
