import ComingSimple from '../Components/Pages/ComingSoon/ComingSimple';
import ErrorPage1 from '../Components/Pages/ErrorPages/ErrorPage400';
import ErrorPage2 from '../Components/Pages/ErrorPages/ErrorPage401';
import ErrorPage3 from '../Components/Pages/ErrorPages/ErrorPage403';
import ErrorPage4 from '../Components/Pages/ErrorPages/ErrorPage404';
import ComingBgImg from '../Components/Pages/ComingSoon/ComingBgImg';
import ComingBgVideo from '../Components/Pages/ComingSoon/ComingBgVideo';
import Error500 from '../Components/Pages/ErrorPages/ErrorPage500';
import Error503 from '../Components/Pages/ErrorPages/ErrorPage503';

export const authRoutes = [

	//Coming-soon
	{ path: `${process.env.PUBLIC_URL}/pages/comingsoon/comingsoon/`, Component: <ComingSimple /> },
	{ path: `${process.env.PUBLIC_URL}/pages/comingsoon/coming-bg-img/`, Component: <ComingBgImg /> },
	{ path: `${process.env.PUBLIC_URL}/pages/comingsoon/coming-bg-video/`, Component: <ComingBgVideo /> },

	//Error
	{ path: `${process.env.PUBLIC_URL}/pages/errors/error400/`, Component: <ErrorPage1 /> },
	{ path: `${process.env.PUBLIC_URL}/pages/errors/error401/`, Component: <ErrorPage2 /> },
	{ path: `${process.env.PUBLIC_URL}/pages/errors/error403/`, Component: <ErrorPage3 /> },
	{ path: `${process.env.PUBLIC_URL}/pages/errors/error404/`, Component: <ErrorPage4 /> },
	{ path: `${process.env.PUBLIC_URL}/pages/errors/error500/`, Component: <Error500 /> },
	{ path: `${process.env.PUBLIC_URL}/pages/errors/error503/`, Component: <Error503 /> },
];
