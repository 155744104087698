import React, { Fragment, useContext, useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import { useKeycloak } from "@react-keycloak/web";
import { AlignJustify, Headphones, Paperclip, Search, Video } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { CardBody, Collapse, Form, FormGroup, Input } from "reactstrap";

import { Image, UL, LI } from "../../../../AbstractElements";
import ChatAppContext from "../../../../_helper/Chat";

const ChatHeader = ({ setSearchResults }) => {
	const { keycloak, initialized } = useKeycloak();

	const [roles, setRoles] = useState();
	const [telephony, setTelephony] = useState(false);

	useEffect(() => {
		if (initialized && keycloak.authenticated) {
			setRoles(keycloak.tokenParsed.realm_access?.roles || []);
			roles && setTelephony(roles.includes("telephony"));
		}
	}, [initialized, keycloak.tokenParsed]);

	const {
		selectedUserr,
		setMenuToggle,
		menuToggle,
		selectedGroup,
		personalChatUsers,
		selectedChat,
		chatMessages
	} = useContext(ChatAppContext);

	const location = useLocation();

	const chatMenuToggle = () => {
		setMenuToggle(!menuToggle);
	};

	function formatDate(datetime) {
		const date = new Date(datetime);

		const now = new Date();
		const diff = (now - date) / 1000;

		const secondsInDay = 86400;

		if (diff < secondsInDay) {
			return `сегодня в ${formatTime(date)}`;
		} else if (diff < 2 * secondsInDay) {
			return `вчера в ${formatTime(date)}`;
		} else if (date.getFullYear() === now.getFullYear()) {
			return `${date.getDate()} ${getMonthName(date.getMonth())} в ${formatTime(date)}`;
		} else {
			return `${date.getDate()} ${getMonthName(
				date.getMonth()
			)} ${date.getFullYear()} в ${formatTime(date)}`;
		}
	}

	function formatTime(date) {
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		return `${hours}:${minutes}`;
	}

	function getMonthName(month) {
		const months = [
			"янв",
			"фев",
			"мар",
			"апр",
			"мая",
			"июн",
			"июл",
			"авг",
			"сен",
			"окт",
			"ноя",
			"дек",
		];
		return months[month];
	}
	const notify = () => {
		SweetAlert.fire({
			title: "Видеозвонок не доступен в текущей конфигурации",
			icon: "warning",
		});
	};

	const notifyTelephony = () => {
		if (!telephony) {
			SweetAlert.fire({
				title: "Аудиовызов не доступен в текущей конфигурации",
				icon: "warning",
			});
		}
	};

	const [isOpen, setIsOpen] = useState(null);
	const toggleClientCard = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

	const [searchTerm, setSearchTerm] = useState("");

	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
		const results = chatMessages.filter((msg) =>
			msg.text.toLowerCase().includes(e.target.value.toLowerCase())
		);
		setSearchResults(results);
	};

	const getUsernameFromArray = (id) => {
		return personalChatUsers && personalChatUsers.find((el) => el.id === id).name;
	};

	const getUserIdFromArray = (id) => {
		return personalChatUsers && personalChatUsers.find((el) => el.id === id).userId;
	};

	return (
		<Fragment>
			<div className="chat-header clearfix">
				<Image
					attrImage={{
						className: "rounded-circle",
						src: `${require(`../../../../assets/images/${
							selectedUserr ? selectedUserr.thumb : "user/7.jpg"
						}`)}`,
						alt: "",
					}}
				/>
				<div className="media-body">
					<div className="about">
						<div className="name">
							{selectedChat && selectedChat.personalChat ? (
								<Link
									style={{ color: "#2c323f" }}
									to={`${process.env.PUBLIC_URL}/app/users/profile/${getUserIdFromArray(selectedChat.id)}`}
								>
									{getUsernameFromArray(selectedChat.id)}
								</Link>
							) : selectedChat && !selectedChat.personalChat ? (
								<Link
									style={{ color: "#2c323f" }}
									to={`${process.env.PUBLIC_URL}/app/view-group?groupId=${selectedChat.id}`}
								>
									{selectedChat.name}
								</Link>
							) : (
								"Имя пользователя"
							)}

							{location.state3 ? (
								<span className="font-primary f-12"> Typing...</span>
							) : (
								""
							)}
						</div>
						<div className="status digits">
							{selectedUserr ? (
								formatDate(selectedUserr.lastSeenDate)
							) : selectedGroup ? (
								<div className="status">
									Участники: {selectedGroup.total_members}
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>
				<UL
					attrUL={{
						className:
							"simple-list list-inline float-start float-sm-end chat-menu-icons d-flex flex-row",
					}}
				>
					<LI attrLI={{ className: "list-inline-item border-0" }}>
						<Search style={{ cursor: "pointer" }} onClick={() => toggleClientCard(1)} />
					</LI>
					<LI attrLI={{ className: "list-inline-item border-0" }}>
						<Paperclip style={{ cursor: "pointer" }} />
					</LI>
					<LI attrLI={{ className: "list-inline-item border-0" }}>
						<Headphones style={{ cursor: "pointer" }} onClick={notifyTelephony} />
					</LI>
					<LI attrLI={{ className: "list-inline-item border-0" }}>
						<Video onClick={notify} style={{ cursor: "pointer" }} />
					</LI>
					<LI attrLI={{ className: "list-inline-item toogle-bar border-0" }}>
						<AlignJustify
							onClick={() => chatMenuToggle()}
							style={{ cursor: "pointer" }}
						/>
					</LI>
				</UL>
			</div>
			<Collapse isOpen={isOpen === 1}>
				<CardBody className="b-b-light" style={{ padding: "13px 13px 0" }}>
					<div className="search">
						<Form className="theme-form">
							<FormGroup className="form-group">
								<Input
									className="form-control"
									type="text"
									placeholder="Поиск по сообщениям.."
									value={searchTerm}
									onChange={handleSearch}
								/>
							</FormGroup>
						</Form>
					</div>
				</CardBody>
			</Collapse>
		</Fragment>
	);
};
export default ChatHeader;
