import React, { useEffect, useState } from 'react';
import Context from './index';
import axios from 'axios';
import { AllProjectApi } from '../../api';
import { useKeycloak } from '@react-keycloak/web';
import { toast } from "react-toastify";
import {useCompanyContext} from "../Company/CompanyProvider";
import {concatResults} from "../../concatResults";

const ProjectProvider = (props) => {
    const [allData, setAllData] = useState([]);
    const [project, setProject] = useState([]);
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();

    // ������� ��� �������� ������� ��������� "secret" � ������� URL
    const checkSecretParameter = () => {
        // �������� ������� URL
        const urlParams = new URLSearchParams(window.location.search);
        // ��������� ������� ��������� "secret"
        return urlParams.has('secret');
    };
    useEffect(() => {

        // �������� ������� checkSecretParameter ��� ������������ ����������
        const hasSecret = checkSecretParameter();
        if (hasSecret) {
            const currentUserId = `${keycloak.tokenParsed.sub}`;
            const urlParams = new URLSearchParams(window.location.search);
            const secret = urlParams.get('secret');
            var data = {
                userId: currentUserId,
                secret: secret
            }

            axios
                .post(`${AllProjectApi}inviteUserProject`, data, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                .then((response) => {

                    if (concatResults(response) && concatResults(response) !== "00000000-0000-0000-0000-000000000000") {
                        window.location.href = `${window.location.origin}/app/project/tasks-canban?projectId=${concatResults(response)}`;
                    } else {
                        window.location.href = `${window.location.origin}/`;
                        toast.success('������ ��������� ������� � �������!', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        // ����� ������ ��������� ����� ������ �������� �� ������ ������� ��������� "secret" � URL
    }, []); // �������� ������ ������ ������������, ����� ������ ��������� ������ ��� ������������ ����������


    const getAllProjectData = async () => {
        try {
            await axios.get(`${AllProjectApi}get`, { headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
                    query: null
                 } }).then((resp) => {
                //console.log(resp);
                setAllData(concatResults(resp).projects);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
    };

    /*useEffect(() => {
        getAllProjectData();
    }, [setAllData, setProject]);*/

    const addNewProject = (projectData) => {
        console.log('test');
        console.log(projectData);
        /*
        const projectTemp = {
            //id: allData.length + 1,
            creatorId: Keycloak,
            name: projectData.title,
            typeId: projectData.badge,
            statusId: 1,
            isExternal: false,
            //img: 'user/3.jpg',
            //sites: 'Themeforest, australia',
            //desc: projectData.desc,
            //issue: projectData.issues,
            //resolved: projectData.resolved,
            //comment: projectData.comment,
            //like: '10',
            //progress: '70',
            //customers_img1: 'user/3.jpg',
            //customers_img2: 'user/5.jpg',
            //customers_img3: 'user/1.jpg'
        };
        setAllData([...allData, projectTemp]);
        try {
            axios.post(`${AllProjectApi}create`, {
                query: null
            }).then((resp) => {
                console.log(resp);
                setAllData(resp.data.projects);
            });
        } catch (error) {
            console.log('cancelled', error);
        }
        */
    };

    return (
        <Context.Provider
            value={{
                ...props,
                addNewProject: addNewProject,
                project,
                allData,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default ProjectProvider;