// LeftPanel.js
import React from 'react';
import { Col, Label } from 'reactstrap';

const ElementText = ({ setIsOverLeftPanel, content = 'Добавить текст' }) => {
	return (
		<>
			<div
				style={{ backgroundColor: '#7366ff33' }}
				className="col-sm-11 mb-3 p-10 b-r-8"
				draggable="true"
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'text');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Col sm="12">
					<Label for="checkbox-primary-1" className="font-primary">
						{content}
					</Label>
				</Col>
			</div>
		</>
	);
};

export default ElementText;
