import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, CardBody, Col, Card, Label, Input, InputGroup, InputGroupText, ButtonGroup, CardFooter } from "reactstrap";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import ru from "date-fns/locale/ru";
import DatePicker from "react-datepicker";
import { BeatLoader } from "react-spinners";
import JSZip from "jszip";

import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { Breadcrumbs, Btn, H6 } from "../../../../AbstractElements";
import { ReminderApi, TasksPaymentApi, TwinAuthApi, MoneyManagementApi } from "../../../../api";
import uuid from 'react-uuid';
import { concatResults } from "../../../../concatResults";

const ViewReport = () => {
	const { companyData } = useCompanyContext();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const billId = queryParams.get("billId");
	const { keycloak } = useKeycloak();
	let currentUserId = `${keycloak.tokenParsed.sub}`;
	const [item, setItem] = useState([]);
	const [domain, setDomain] = useState([]);
	const [companyId, setCompanyId] = useState("");

	const [unmappedPaymentInfo, setUnmappedPaymentInfo] = useState({});

	const getData = async () => {
		try {

			const reportsResponse = await axios.get(`${TasksPaymentApi}getReport/${billId}`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const report = concatResults(reportsResponse).report;

			const allUsersResponse = await axios.get(`${TwinAuthApi}get-all-users`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const allUsers = JSON.parse(allUsersResponse.data).map((option) => ({
				label: `${option.firstName} ${option.lastName}`,
				value: option.id,
			}));

			const user = allUsers.find(x => x.value === report.performerId)

			setUnmappedPaymentInfo(report);

			const mappedReport = {
				id: report.id,
				accountNumber: report.number,
				documentTheme: report.title,
				comment: report.comment,
				formationPeriod: {
					startDate: report.startDateFormation,
					endDate: report.endDateFormation
				},
				performer: {
					id: report.performerId,
					name: user.label
				},
				invoiceDate: report.invoiceDate,
				totalPaymentAmount: report.totalPaymentAmount,
				status: report.reportStatus.text,
				paymentDate: report.paymentDate || false,
				documents: [
					{
						"name": "Счет",
						"type": "PDF",
						"link": "https://example.com/docs/invoice_15-r.pdf"
					},
					{
						"name": "Акт",
						"type": "PDF",
						"link": "https://example.com/docs/invoice_15-r.pdf"
					}

				]
			};

			setItem(mappedReport);

			axios
				.get(`/api/correctDomain.json`, {
					params: { query: "" },
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					setDomain(response.data.domain);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});

			axios
				.get(`${TwinAuthApi}get-user-groups-companies/${currentUserId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {

					if (response.data.length > 0) {
						var companies = JSON.parse(response.data);
						setCompanyId(companies[0].id);
					}
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
		catch (error) { }
	};


	useEffect(() => {
		getData();
	}, []);

	// Проверка соответствия URL правильному домена в ссылке (https://lknpd.nalog.ru).
	const isValidUrl = (url) => {
		const urlPattern = new RegExp(`^https://${domain}/.*$`);
		return urlPattern.test(url);
	};

	//Загрузить чек
	const createRate = async () => {
		setIsDownloadBill(true);
		setModal(false);

		const formData = new FormData();
		if (viewType === "pdf" && selectedFile) {
			formData.append("file", selectedFile);
		}
		else if (viewType === "link"/* && isValidUrl(link)*/) {
			formData.append("link", link);
		}
		else {
			toast.error("Некорректный домен или файл не выбран!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			setIsDownloadBill(false);
			return;
		}

		formData.append("companyId", companyId);
		axios.post(`${ReminderApi}upload-check`, formData, {
			headers: {
				Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
				'Content-Type': 'multipart/form-data'
			}
		})
			.then(response => {
				toast.success("Чек загружен!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setIsDownloadBill(false);
			})
			.catch(error => {
				console.error(error);
				toast.error("Ошибка загрузки!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setIsDownloadBill(false);
			});
	};

	const [selectedFile, setSelectedFile] = useState(null);
	const [loading, setLoading] = useState(false);

	//подтвердить оплату
	const confirm = () => {
		try {
			setLoading(true);
			const newBill = {
				id: billId,
				accountNumber: item.accountNumber,
				performer: item.performer,
				paymentDate: new Date(),
				bill: fileBase64.length ? fileBase64 : link,
			};

			if (companyData !== null) {

				const newRow = {
					companyId: companyData[0]?.id,
					eventType: "",
					reminderText: `Статус счёта "№${item.accountNumber} ${item.documentTheme}" за период с ${item.formationPeriod.startDate} по ${item.formationPeriod.endDate} был изменён на "Оплачено"`,
					userId: item.performer.id,
				};

				axios
					.post(`${ReminderApi}create-company-reminder`, newRow, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						toast.success("Уведомление создано!", {
							position: toast.POSITION.TOP_RIGHT,
						});
						setLoading(false);
					})
					.catch((error) => {
						console.error(error);
						setLoading(false);
					});
			}

			axios.put(`${TasksPaymentApi}registerPaymentReport/${unmappedPaymentInfo.id}`, null, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
				.then((response) => {
					toast.success("Статус изменен!", {
						position: toast.POSITION.TOP_RIGHT,
					});

					const newCash = {
						id: uuid(),
						recipientInitial: item.performer.name,
						amount: unmappedPaymentInfo.totalPaymentAmount,
						comment: unmappedPaymentInfo.comment
					};
					console.log(newCash);

					axios
						.post(`${MoneyManagementApi}createTaskPaymentTransaction`, newCash, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
						.then((response) => {
							//console.log(concatResults(response));
							toast.success('Платеж добавлен!', {
								position: toast.POSITION.TOP_RIGHT,
							});
						})
						.catch((error) => {
							console.error(error);
						});


					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
					setLoading(false);
				});

		}
		catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
	};

	const [isDownloadBill, setIsDownloadBill] = useState(false);

	const [isDisable, setDisable] = useState(false);

	const [link, setLink] = useState("");

	const [viewType, setViewType] = useState("pdf");

	const handleViewChange = (view) => {
		setViewType(view);
	};

	const [fileBase64, setFileBase64] = useState("");

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		if (!file) {
			return;
		}

		const reader = new FileReader();
		reader.onloadend = () => {
			setFileBase64(reader.result);
		};

		reader.readAsDataURL(file);
	};

	const handleDownloadAll = () => {
		const zip = new JSZip();
		const promises = [];

		// Добавляем все документы в ZIP-архив
		item.documents.forEach((document) => {
			if (isValidUrl(document.url)) {
				const filename = document.url.substring(document.url.lastIndexOf("/") + 1);
				const promise = fetch(document.url)
					.then((response) => response.blob())
					.then((blob) => zip.file(filename, blob));
				promises.push(promise);
			}
		});

		// После завершения всех запросов, скачиваем ZIP-архив
		Promise.all(promises).then(() => {
			zip.generateAsync({ type: "blob" }).then((content) => {
				const url = URL.createObjectURL(content);
				const link = document.createElement("a");
				link.href = url;
				link.download = "documents.zip";
				link.click();
				URL.revokeObjectURL(url);
			});
		});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={item && `Просмотра деталей счёта № ${item.accountNumber}`} parent="Просмотра деталей счёта" title={item && item.accountNumber} />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								{item && (
									<>
										<Row className="mb-4">
											<Col md="7">
												<Label className="col-sm-9 col-form-label">Тема документа</Label>
												<Col sm="12">
													<Input className="form-control" value={item.documentTheme} disabled />
												</Col>
											</Col>
											<Col md="5">
												<Label className="col-sm-9 col-form-label">Исполнитель</Label>
												<Col sm="12">
													<Input className="form-control" value={item.performer && item.performer.name} disabled />
												</Col>
											</Col>
										</Row>
										{item.formationPeriod && (
											<Row className="mb-3 mt-4">
												<Col xl="3" className="m-r-45">
													<Label className="col-form-label">Период формирования</Label>
													<div style={{ display: "flex", alignItems: "flex-end" }}>
														<Label className="m-r-10">С</Label>
														<div className="customDatePickerWidth ">
															<DatePicker
																dateFormat="dd.MM.yyyy"
																locale={ru}
																className="form-control digits"
																selected={new Date(item.formationPeriod.startDate)}
																value={new Date(item.formationPeriod.startDate)}
																disabled
															/>
														</div>
													</div>
												</Col>
												<Col xl="3" className="m-r-45">
													<Label className="col-form-label" style={{ height: "26px" }}></Label>
													<div style={{ display: "flex", alignItems: "flex-end" }}>
														<Label className="m-r-10">По</Label>
														<div className="customDatePickerWidth">
															<DatePicker
																dateFormat="dd.MM.yyyy"
																locale={ru}
																className="form-control digits"
																selected={new Date(item.formationPeriod.endDate)}
																value={new Date(item.formationPeriod.endDate)}
																disabled
															/>
														</div>
													</div>
												</Col>
												<Col xl="4" className="m-r-45">
													<Label className=" col-form-label">Дата выставления счета</Label>
													<div style={{ display: "flex", alignItems: "flex-end" }}>
														<div className="customDatePickerWidth ">
															<DatePicker
																dateFormat="dd.MM.yyyy"
																locale={ru}
																className="form-control digits"
																selected={new Date(item.invoiceDate)}
																value={new Date(item.invoiceDate)}
																disabled
															/>
														</div>
													</div>
												</Col>
											</Row>
										)}
										<Row className="mb-4">
											<Col md="6">
												<Label className="col-sm-9 col-form-label">Итоговая сумма оплаты</Label>
												<Col sm="12">
													<InputGroup className="mb-3">
														<Input className="form-control" type="text" disabled value={item.totalPaymentAmount} />
														<InputGroupText>руб</InputGroupText>
													</InputGroup>
												</Col>
											</Col>
											<Col md="6">
												<Label className="col-sm-9 col-form-label">Статус</Label>
												<Col sm="12" className="d-flex" style={{ alignItems: "center" }}>
													{item.status && item.status === "Получено исполнителем" && <i className="fa fa-check me-2 font-success f-18"></i>}
													<Input className="form-control " value={item.status} disabled />
												</Col>
											</Col>
										</Row>
										<H6>Документы:</H6>
										<Row className="mt-3 mb-3">
											{item.documents &&
												item.documents.map((document, index) => (
													<a key={index} href={document.link} target="_blank" rel="noopener noreferrer" className="mb-1">
														{document.type === "PDF" && <i className="fa fa-file-pdf-o f-16 me-1"></i>}
														{document.type === "TXT" && <i className="fa fa-file-word-o f-16 me-1"></i>}
														{document.type === "IMG" && <i className="fa fa-file-photo-o f-16 me-1"></i>}
														{document.type === "EXCEL" && <i className="fa fa-file-excel-o f-16 me-1"></i>}
														{document.name}
													</a>
												))}
										</Row>
										<Btn attrBtn={{ color: "primary", outline: true, onClick: handleDownloadAll }}>
											Скачать все <i className="fa fa-download"></i>
										</Btn>
									</>
								)}
							</CardBody>
							{item.status && item.status === "Оплачено" && (
								<CardFooter>
									<div className="modal-footer">
										<Btn attrBtn={{ color: "info", onClick: toggle, className: "me-3" }}>Загрузить чек</Btn>
										<Btn attrBtn={{ color: "primary", disabled: !isDownloadBill || loading, onClick: confirm }}>
											{loading ? <BeatLoader size={8} color="#ffffff" /> : "Подтвердить оплату"}
										</Btn>
									</div>
								</CardFooter>
							)}
							{item.status && item.status === "Отправлен на оплату" && (
								<CardFooter>
									<div className="modal-footer">
										<Btn attrBtn={{ color: "primary", disabled: isDisable || loading, onClick: confirm }}>
											{loading ? <BeatLoader size={8} color="#ffffff" /> : "Подтвердить оплату"}
										</Btn>
									</div>
								</CardFooter>
							)}
						</Card>
					</Col>
				</Row>
			</Container>
			<CommonModal isOpen={modal} title={"Загрузить чек"} toggler={createRate} togglerClose={toggle} closeText="Отмена" saveText="Загрузить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col>
						<ButtonGroup className="mb-3">
							<Btn attrBtn={{ color: "primary", outline: viewType !== "pdf" && true, onClick: () => handleViewChange("pdf") }}>PDF-файл</Btn>
							<Btn attrBtn={{ color: "primary", outline: viewType !== "link" && true, onClick: () => handleViewChange("link") }}>Ссылка</Btn>
						</ButtonGroup>
					</Col>
					{viewType === "pdf" ? (
						<Col md="8" className="mt-3">
							<Input type="file" accept="image/*" className="form-control col-md-6 mb-3" onChange={handleImageChange} />
						</Col>
					) : (
						<>
							<Tooltip title={`Кабинет налогоплательщика НПД "Мой налог"`} arrow placement="top">
								<Link target="_blank" to={`https://lknpd.nalog.ru/sales`}>
									Перейти в Мой налог <i className="fa fa-external-link"></i>
								</Link>
							</Tooltip>
							<Col className="row col-sm-12 mt-3">
								<Label className="col-sm-9 col-form-label">Загрузить чек в виде ссылки с сайта</Label>
								<Col sm="11">
									<Input className="form-control" placeholder="Ссылка" value={link} onChange={(e) => setLink(e.target.value)} />
								</Col>
							</Col>
						</>
					)}
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};
export default ViewReport;
