import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Card, CardBody, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { PuffLoader } from "react-spinners";
import { Link } from "react-router-dom";

import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import GroupClass from "./GroupClass";
import { useGroupsContext } from "../../../../_helper/Groups/GroupsProvider";
import { useKeycloak } from "@react-keycloak/web";

const ListGroups = () => {
	const [activeTab, setActiveTab] = useState("1");
	const { 
		getAllGroups, 
		getModerateGroups, 
		getFavoriteGroups 
	} = useGroupsContext();
	const {keycloak} = useKeycloak();

	const [allGroups, setAllGroups] = useState([]);
	const [groupManagement, setGroupManagement] = useState([]);
	const [favoriteGroups, setFavoriteGroups] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		getAllGroups().then((result) => {setAllGroups(result)});
		getModerateGroups().then((result) => {setGroupManagement(result)});
		getFavoriteGroups().then((result) => {setFavoriteGroups(result)});
		setLoading(false);
	}, [getAllGroups, getModerateGroups, getFavoriteGroups]);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список групп" parent="Группы" title="Список групп" />
			<Container fluid={true}>
				<div className="email-wrap">
					<Row>
						<Card>
							<CardBody>
								<Nav className={"nav-pills nav-primary"}>
									<NavItem>
										<NavLink className={activeTab === "1" ? "active" : ""} onClick={() => setActiveTab("1")} style={{ cursor: "pointer" }}>
											Все группы ({Array.isArray(allGroups) ? allGroups.length : 0})
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={activeTab === "2" ? "active" : ""} onClick={() => setActiveTab("2")} style={{ cursor: "pointer" }}>
											Управление ({Array.isArray(groupManagement) ? groupManagement.length : 0})
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={activeTab === "3" ? "active" : ""} onClick={() => setActiveTab("3")} style={{ cursor: "pointer" }}>
											Избранное ({Array.isArray(favoriteGroups) ? favoriteGroups.length : 0})
										</NavLink>
									</NavItem>
									<div style={{ flexGrow: "1" }}>
										<Link to={`${process.env.PUBLIC_URL}/app/add-group`}>
											<Btn attrBtn={{ color: "primary", outline: true, className: "f-right" }}>Создать группу</Btn>
										</Link>
									</div>
								</Nav>

								<TabContent activeTab={activeTab}>
									<TabPane tabId="1">
										{loading ? (
											<PuffLoader size={80} color="#7366ff" style={{ margin: "20px auto", display: "inherit", position: "relative", width: "80px", height: "80px" }} />
										) : allGroups ? (
											<GroupClass allGroups={allGroups} />
										) : (
											<p>Результаты не найдены</p>
										)}
									</TabPane>
									<TabPane tabId="2">
										{loading ? (
											<PuffLoader size={80} color="#7366ff" style={{ margin: "20px auto", display: "inherit", position: "relative", width: "80px", height: "80px" }} />
										) : groupManagement ? (
											<GroupClass allGroups={groupManagement} />
										) : (
											<p>Результаты не найдены</p>
										)}
									</TabPane>
									<TabPane tabId="3">
										{loading ? (
											<PuffLoader size={80} color="#7366ff" style={{ margin: "20px auto", display: "inherit", position: "relative", width: "80px", height: "80px" }} />
										) : favoriteGroups ? (
											<GroupClass allGroups={allGroups && favoriteGroups && allGroups.filter((groups) => favoriteGroups.map(fav => fav.groupId).includes(groups.id) )} />
										) : (
											<p>Результаты не найдены</p>
										)}
									</TabPane>
								</TabContent>
							</CardBody>
						</Card>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};
export default ListGroups;
