import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Container } from 'reactstrap';
import { useLocation } from 'react-router';
import { Plus, Trash2 } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Select from 'react-select';
import { Label, Input, Col, InputGroup, InputGroupText } from 'reactstrap';
import { Breadcrumbs, H5, Btn, P } from '../../../../AbstractElements';
import CommonModal from '../../../UiKits/Modals/common/modal';
import TeachersTableModal from '../../Learning/Courses/BuildCourse/Teachers/TeachersTableModal';
import uuid from 'react-uuid';

import { ServicesApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {concatResults} from "../../../../concatResults";
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';

const Instances = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const resourceId = queryParams.get('resourceId');
	const {keycloak} = useKeycloak();
	const {companyData} = useCompanyContext();

	const [name, setName] = useState('');
	const [desc, setDesc] = useState('');
	const [id, setId] = useState('');
	const [instances, setInstances] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
		try {
			axios.get(`${ServicesApi}getResources`, { params: { query: "" } , headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					const foundItem = concatResults(response).resources.find((item) => item.id === resourceId);
					console.log(foundItem.instances);
					if (foundItem) {
						setName(foundItem.name);
						setDesc(foundItem.description);
						setData(foundItem.instances);
						setId(foundItem.id);
					}
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	}, []);

	const fetchData = async () => {
		try {
			const response = await axios.get(`${ServicesApi}getInstances`, { params: { query: resourceId }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			setData(concatResults(response).instances);
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	const columns = [
		{
			name: '',
			selector: (row) => '',
			sortable: true,
			width: '1%',
		},
		{
			name: 'Название',
			selector: (row) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<i className="fa fa-gear f-28" style={{ color: '#898989' }}></i>
					<div className="ms-3">
						<p className="f-16">{row.name}</p>
					</div>
				</div>
			),
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteInstance(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteInstance = (id) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${ServicesApi}deleteInstance`, {
						data: {
							InstanceId: id
						}, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
					})
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						fetchData();
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении:', error);
					});
			}
		});
	};

	const [modal, setModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
	};

	const [instanceName, setInstanceName] = useState('');

	const sendinstanceName = () => {
		if (instanceName == '') {
			toast.error('Заполните название!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const instance = {
				id: uuid(),
				name: instanceName,
				resourceId: resourceId
			};
			axios
				.post(`${ServicesApi}createInstance`, instance, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					toast.success('Ресурс добавлен!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};


	const send = () => {
		if (name == '' || desc == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const resource = {
				id: id,
				name: name,
				description: desc
			};

			axios
				.put(`${ServicesApi}updateResource`, resource, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					toast.success('Ресурс обновлен!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={name} parent="Услуги" subParent="Ресурсы" title={name} />
			<Container fluid={true}>
				<Card>
					<CardBody className="m-25">
						<Col className="row col-sm-12 mb-3 ">
							<Label className="col-sm-9 col-form-label">Название</Label>
							<Col sm="11">
								<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-4">
							<Label className="col-sm-9 col-form-label">Описание</Label>
							<Col sm="11">
								<Input className="form-control" type="tel" value={desc} onChange={(e) => setDesc(e.target.value)} />
							</Col>
						</Col>
						<H5 attrH5={{ className: 'mt-4 mb-4' }}>Экземпляры ресурса</H5>
						<Col sm="11">{data.length ? <DataTable noDataComponent={'В данной таблице ещё нет записей.'} columns={columns} data={data} defaultSortField="name" striped pagination paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} /> : <P>Экземпляры не добавлены</P>}</Col>
						<Btn attrBtn={{ outline: true, color: 'primary', onClick: toggle }}>
							<Plus size={'20px'} style={{ margin: ' 0 2px -5px 0' }} />
							Добавить экземпляр ресурса
						</Btn>
						<CommonModal isOpen={modal} title={'Добавить экземпляр ресурса'} toggler={sendinstanceName} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="md">
							<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
								<Col className="row col-sm-12 mb-3 ">
									<Label className="col-sm-9 col-form-label p-l-0">Название</Label>
									<Input className="form-control" type="tel" value={instanceName} onChange={(e) => setInstanceName(e.target.value)} />
								</Col>
							</CardBody>
						</CommonModal>
					</CardBody>
					<CardFooter>
						<div className="modal-footer">
							<Btn attrBtn={{ color: 'primary', onClick: send }}>Сохранить изменения</Btn>
						</div>
					</CardFooter>
				</Card>
			</Container>
		</Fragment>
	);
};
export default Instances;
