import React from "react";

import "codemirror/theme/neo.css";
import "codemirror/theme/darcula.css";

const themes = ["neo", "darcula"];

export const ThemeSelector = ({ setTheme }) => {
	const selectTheme = ({ target: { value } }) => {
		setTheme(value);
	};

	return (
		<div className="theme-selector">
			<label htmlFor="theme">Theme: </label>
			<select id="theme" name="theme" onChange={selectTheme}>
				{themes.map((t) => (
					<option key={t} value={t}>
						{t}
					</option>
				))}
			</select>
		</div>
	);
};
