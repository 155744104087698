// LeftPanel.js
import React from 'react';
import { Col, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale';

const ElementDate = ({ setIsOverLeftPanel }) => {
	return (
		<>
			<div
				draggable="true"
				className={`col-sm-11 mb-3`}
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'date');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Label className="col-sm-9 col-form-label">Выбрать дату</Label>
				<Col sm="12">
					<div className="input-group">
						<DatePicker locale={ru} className="form-control digits" selected={new Date()} />
					</div>
				</Col>
			</div>
		</>
	);
};

export default ElementDate;
