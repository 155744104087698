import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import axios from "axios";
import { Breadcrumbs } from "../../../../AbstractElements";
import { useKeycloak } from "@react-keycloak/web";
import { TelephonyApi } from "../../../../api";
import TableUsersList from "./TableUsersList";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const TelephonyUsers = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [IconWithTab, setIconWithTab] = useState("1");

	const [data, setData] = useState([]);

	const getData = async () => {
		try {
			//await axios
			//	.get(`/api/TelephonyUsers.json`)
			//	.then((response) => {
			//		setData(concatResults(response));
			//	})
			//	.catch((error) => {
			//		console.error("Ошибка получения данных:", error);
			//	});

			const response = await axios.get(`${TelephonyApi}getPbxUsers`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
			});

			const mappedData = concatResults(response) ? concatResults(response).users.map((option) => ({
				id: option.id,
				internal_number: option.innerPhone,
				user: {
					id: option.userId
				},
				password_sip: option.sipPassword,
				phone_device_status: option.phoneSet,
				outgoing_call_number: option.pbx
			})) : [];

			setData(mappedData);
		}
		catch (error) { }
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Пользователи телефонии" parent="Телефония" title="Пользователи телефонии" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<TableUsersList products={data} mainData={getData} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default TelephonyUsers;
