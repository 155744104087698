import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";

import { Media, Col, Row } from "reactstrap";
import { Btn, P } from "../../../../AbstractElements";
import FilterComponent from "../../Goods/PurchaseHistory/FilterComponent";

const TableEmployees = ({ data, departments }) => {
	var images = require.context("../../../../assets/images", true);

	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	function getDepartmentNameById(id) {
		const department = departments.find((dept) => dept.value === id);
		return department ? department.label : "";
	}

	const columns = [
		{
			name: "Имя",
			selector: (row) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<div className="media-size-email" style={{ position: "relative", padding: "5px" }}>
						<Media body className="me-3 rounded-circle img-40" src={row.image ? dynamicImage(row.image) : require("../../../../assets/images/user/7.jpg")} alt="" />
						{row.position === "Administrator" ? (
							<span className="font-warning admin-icon">
								<i className="fa fa-star"></i>
							</span>
						) : (
							""
						)}
					</div>
					<P>
						{row.firstName} {row.lastName}
					</P>
				</div>
			),
			sortable: true,
		},
		{
			name: "Email",
			selector: (row) => (row.email ? `${row.email}` : ""),
			sortable: false,
		},
		{
			name: "Должность",
			selector: (row) => {
				switch (row.position) {
					case "Administrator":
						return "Администратор";
					case "Manager":
						return "Менеджер";
					case "Developer":
						return "Разработчик";
					case "Teacher":
						return "Преподаватель";
					default:
						return row.position;
				}
			},
			sortable: true,
			hide: "md",
		},
		{
			name: "Отдел",
			selector: (row) => (row.departmentId ? `${getDepartmentNameById(row.departmentId)}` : <p>без отдела</p>),
			sortable: true,
			hide: "md",
		},
	];

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const [selectDepartment, setSelectDepartment] = useState(null);
	const [selectEmployees, setSelectEmployees] = useState([]);

	const handleChange = ({ selectedRows }) => {
		console.log("Selected Rows: ", selectedRows);
		setSelectEmployees(selectedRows);
		if (selectedRows.length) {
			setShowSelect(true);
		} else {
			setShowSelect(false);
		}
	};

	const [showSelect, setShowSelect] = useState(false);

	//перенос сотрудников
	const move = () => {
		const updateEmployeeData = async (employee) => {
			try {
				const updatedEmployee = { ...employee, departmentId: selectDepartment.value };
				//const response = await axios.put(`/employees/${employee.id}`, updatedEmployee);
				toast.success(`Сотрудник ${updatedEmployee.firstName} ${updatedEmployee.lastName} перенесен в отдел ${selectDepartment.label}`, {
					position: toast.POSITION.TOP_RIGHT,
				});
			} catch (error) {
				console.error(`Произошла ошибка при обновлении данных о сотруднике с идентификатором ${employee.id}:`, error);
			}
		};
		selectEmployees.forEach(updateEmployeeData);
	};

	return (
		<>
			{showSelect && (
				<Row style={{ margin: "0 auto", width: "97%" }} className="mb-3">
					<Col md="9">
						<Select
							placeholder="Выберите..."
							noOptionsMessage={() => "Нет результатов"}
							options={departments}
							value={selectDepartment}
							onChange={(option) => setSelectDepartment(option)}
						/>
					</Col>
					<Col md="3">
						<Btn attrBtn={{ className: "f-right", onClick: move }}>Перенести</Btn>
					</Col>
				</Row>
			)}
			<DataTable
				selectableRows
				onSelectedRowsChange={handleChange}
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{
					rowsPerPageText: "Строк на странице:",
					rangeSeparatorText: "из",
				}}
				noDataComponent={"В данной таблице ещё нет записей."}
			/>
		</>
	);
};

export default TableEmployees;
