import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { CardFooter } from "reactstrap";
import AsyncSelect from "react-select/async";
import { Btn } from "../../../../AbstractElements";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import InputMask from "react-input-mask";
import { CompaniesApi, ConsecutivePagesApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";
import { useConpagesContext } from "../../../../_helper/ConsecutivePages/ConpagesProvider";
import { useNavigate } from "react-router";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import {useUsersContext} from "../../../../_helper/Users/UsersProvider";
import { concatResults } from "../../../../concatResults";

const BasicFormControlClass = () => {
	const navigate = useNavigate();

	const { getUserById, userById } = useUsersContext();
	const { toggleUpdated, companyConpagesData } = useConpagesContext();
	const { companyData, setCompanyData } = useCompanyContext();
	const { keycloak } = useKeycloak();

	const [contactNames, setContactNames] = useState([]);

	const [inn, setInn] = useState(null);
	const [fullLegalName, setFullLegalName] = useState("");
	const [shortLegalName, setShortLegalName] = useState("");
	const [directorFullName, setDirectorFullName] = useState("");
	const [postDirector, setPostDirector] = useState("");
	const [contactName, setContactName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [site, setSite] = useState("");
	const [actualStreet, setActualStreet] = useState("");
	const [legalStreet, setLegalStreet] = useState("");
	const [bankAccount, setBankAccount] = useState("");
	const [bankKorrespondent, setBankKorrespondent] = useState("");
	const [bankName, setBankName] = useState("");
	const [bankBic, setBankBic] = useState("");
	const [kpp, setKPP] = useState("");
	const [ogrn, setOGRN] = useState("");
	const [document, setDocument] = useState("");

	useEffect(() => {
		keycloak.tokenParsed && getUserById(keycloak.tokenParsed.sub);
	},[keycloak.tokenParsed]);

	useEffect(() => {
		if (userById) {
			let fname = `${userById.lastName} ${userById.firstName}${userById.attributes?.patronymic ? ` ${userById.attributes?.patronymic}` : ""}`;
			setContactName({label: fname, value: fname});
			setContactNames([{label: fname, value: fname}]);
		}
	}, [userById]);

	const promiseOptionsInn = (inputValue, callback) => {
		setInn({ label: inputValue, value: inputValue });
		if (inputValue.length >= 3) {
			const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
			const token = "dfcc16ce4602751f303a20ef1f956a8f7355d7a1";

			axios
				.post(url, { query: inputValue, count: 25, status: ["ACTIVE"] }, {
					headers: { "Content-Type": "application/json",
						Accept: "application/json",
						Authorization: "Token " + token } })
				.then((response) => {
					const selectOptions = concatResults(response).map((option) => ({
						label: `${option.value} (${option.data.inn} ${option.data.address.value})`,
						value: option.data.inn,
						shortLegalName: option.data.name.short_with_opf,
						fullLegalName: option.value,
						ogrn: option.data.ogrn,
						kpp: option.data.kpp,
						management: option.data.management,
					}));

					callback(selectOptions);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		} else {
			callback(null);
		}
	};

	const promiseOptionsBic = (inputValue, callback) => {
		const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/bank";
		const token = "6416ca728c4d25842e715e4dfcd4dfd859d1200c";

		axios
			.post(url, { query: inputValue }, { headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Token " + token } })
			.then((response) => {
				const selectOptions = concatResults(response).suggestions.map((option) => ({
					label: option.data.bic,
					value: option.value,
				}));
				callback(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const promiseOptionsBankName = (inputValue, callback) => {
		const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
		const token = "6416ca728c4d25842e715e4dfcd4dfd859d1200c";

		axios
			.post(url, { query: inputValue }, { headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Token " + token } })
			.then((response) => {
				const selectOptions = concatResults(response).suggestions.map((option) => ({
					label: option.value,
					value: option.data.bic,
				}));
				callback(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const promiseOptionsContactName = (inputValue, callback) => {
		const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio";
		const token = "6416ca728c4d25842e715e4dfcd4dfd859d1200c";

			axios
				.post(url, { query: inputValue }, { headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Token " + token } })
				.then((response) => {
					const selectOptions = concatResults(response).suggestions.map((option) => ({
						label: option.value,
						value: option.unrestricted_value,
					}));
					callback(selectOptions);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
	};

	const [loading, setLoading] = useState(false);
	const createCompany = () => {
		setLoading(true);
		const company = {
			userId: keycloak.tokenParsed.sub,
			email: email,
			shortLegalName: shortLegalName,
			fullLegalName: fullLegalName,
			INN: inn.label,
			directorFullName: directorFullName.label,
			contactName: contactName.label,
			phone: phone,
			site: site,
			actualStreet: actualStreet,
			legalStreet: legalStreet,
			bankBic: bankBic.label,
			bankName: bankName.label,
			bankAccount: bankAccount,
			bankKorrespondent: bankKorrespondent,
			KPP: kpp,
			postDirector: postDirector,
			ogrn: ogrn,
			document: document,
		};

		axios
			.post(`${CompaniesApi}create`, company, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				toast.success("Заявка на создание компании создана!", {
					position: toast.POSITION.TOP_RIGHT,
				});

				setCompanyData(JSON.parse(response.data));

				axios
					.post(
						`${ConsecutivePagesApi}create-log`,
						{
							userId: keycloak.tokenParsed.sub,
							companyId: JSON.parse(concatResults(response)).id,
							menuId: "45044e84-bf78-4775-a24a-c5eeeb7debdb",
						},
						{
							headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
						}
					)
					.then((response) => {
						toggleUpdated();
						navigate(companyConpagesData.link);
						setLoading(false);
					})
					.catch((error) => {
						toast.error("Ошибка при создании компании.", {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error(error);
						setLoading(false);
					});
			})
			.catch((error) => {
				toast.error("Ошибка при создании компании.", {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error(error);
				setLoading(false);
			});
	};

	const handleChangeInn = (option) => {
		if (option) {
			setInn({ label: option.value, value: option.value });
			setFullLegalName(option.fullLegalName);
			setShortLegalName(option.shortLegalName);
			setOGRN(option.ogrn);
			setKPP(option.kpp);
			if (option.management) {
				setDirectorFullName(option.management.name);
				setPostDirector(option.management.post);

				let newContactNames = contactNames;
				newContactNames = newContactNames.concat({ label: option.management.name, value: option.management.name });

				setContactNames(newContactNames);
			} else {
				setDirectorFullName("Отсутствует");
				setPostDirector("Отсутствует");
			}
		} else {
			setInn(null);
			setFullLegalName("");
			setShortLegalName("");
			setOGRN("");
			setKPP("");
			setDirectorFullName("");
			setPostDirector("");
			setContactNames("");
		}
	};

	const handleChangeBic = (option) => {
		setBankBic(option);
		setBankName({ label: option.value, value: option.label });
	};

	const handleChangeBankName = (option) => {
		setBankName(option);
		setBankBic({ label: option.value, value: option.label });
	};

	const handleChangeContactName = (event) => {
		setContactName(event);
	};

	const handleChangeEmail = (event) => {
		setEmail(event.target.value);
	};

	const handleChangeFullLegalName = (event) => {
		setFullLegalName(event.target.value);
	};

	const handleChangeShortLegalName = (event) => {
		setShortLegalName(event.target.value);
	};

	const handleChangeSite = (event) => {
		setSite(event.target.value);
	};

	const [errorMessageOgrn, setErrorMessageOgrn] = useState(" ");
	const handleChangeOGRN = (e) => {
		const value = e.target.value;
		if (/^\d*$/.test(value)) {
			setOGRN(value);
			if (value.length === 13) {
				setErrorMessageOgrn("");
			} else {
				setErrorMessageOgrn("Поле должно содержать 13 цифр");
			}
		}
	};

	const [errorMessageKpp, setErrorMessageKpp] = useState(" ");
	const handleChangeKPP = (e) => {
		const value = e.target.value;
		if (/^\d*$/.test(value)) {
			setKPP(value);
			if (value.length === 9) {
				setErrorMessageKpp("");
			} else {
				setErrorMessageKpp("Поле должно содержать 9 цифр");
			}
		}
	};

	const [errorMessageBankAcc, setErrorMessageBankAcc] = useState(" ");
	const handleChangeBankAccount = (e) => {
		const value = e.target.value;
		if (/^\d*$/.test(value)) {
			setBankAccount(value);
			if (value.length === 20) {
				setErrorMessageBankAcc("");
			} else {
				setErrorMessageBankAcc("Поле должно содержать 20 цифр");
			}
		}
	};

	const [errorMessageBankKor, setErrorMessageBankKor] = useState(" ");
	const handleChangeBankKorrespondent = (e) => {
		const value = e.target.value;
		if (/^\d*$/.test(value)) {
			setBankKorrespondent(value);
			if (value.length === 20) {
				setErrorMessageBankKor("");
			} else {
				setErrorMessageBankKor("Поле должно содержать 20 цифр");
			}
		}
	};

	const handleChangePostDirector = (event) => {
		setPostDirector(event.target.value);
	};

	const handleChangeDocument = (event) => {
		setDocument(event.target.value);
	};

	const handleChangeActualStreet = (event) => {
		setActualStreet(event.target.value);
	};

	const handleChangeLegalStreet = (event) => {
		setLegalStreet(event.target.value);
	};

	const colourStyles = {
		control: (styles) => ({ ...styles, border: "none", boxShadow: "none", fontSize: "14px", padding: "0.22rem" }),
	};

	return (
		<Fragment>
			<Card>
				<HeaderCard title={"Общие данные"} span2 />
				<Form className="form theme-form">
					<CardBody>
						<Row className="mb-3">
							<span style={{ color: "#f73164", fontSize: "13px" }}>
								Начните вводить ИНН, выберите вашу организацию из списка и большинство данных о Вас появятся автоматически.<br></br> Проверьте правильность данных и заполните
								недостающие поля
							</span>
						</Row>
						<Row>
							<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label">
									ИНН <span style={{ color: "#f73164", fontSize: "18px" }}>*</span>
								</Label>
								<Col sm="11">
									<AsyncSelect
										styles={colourStyles}
										loadOptions={promiseOptionsInn}
										placeholder="ИНН"
										noOptionsMessage={() => "Нет результатов"}
										value={inn}
										loadingMessage={() => "Загрузка.."}
										className="form-control p-0"
										onChange={handleChangeInn}
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
									Полное название <span style={{ color: "#f73164", fontSize: "18px" }}>*</span>
								</Label>
								<Col sm="11">
									<Input className="form-control" type="text" placeholder="Полное название" required={true} value={fullLegalName} onChange={handleChangeFullLegalName} />
								</Col>
							</FormGroup>
						</Row>
						<Row>
							<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label">
									Сокращенное название <span style={{ color: "#f73164", fontSize: "18px" }}>*</span>
								</Label>
								<Col sm="11">
									<Input className="form-control" type="text" placeholder="Сокращенное название" value={shortLegalName} required={true} onChange={handleChangeShortLegalName} />
								</Col>
							</FormGroup>
							<Col className="row col-sm-6" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
									ОГРН <span style={{ color: "#f73164", fontSize: "18px" }}>*</span>
								</Label>
								<Col sm="11" style={{ height: "68px" }}>
									<Input className="form-control" type="text" placeholder="ОГРН" required={true} value={ogrn} onChange={handleChangeOGRN} />
									{errorMessageOgrn && <div className="font-secondary">{errorMessageOgrn}</div>}
								</Col>
							</Col>
						</Row>
						<Row>
							<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label">
									Email
								</Label>
								<Col sm="11">
									<Input
										required={false}
										styles={colourStyles}
										placeholder="name@example.ru"
										pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
										value={email}
										onChange={handleChangeEmail}
										className="form-control"
									/>
								</Col>
							</FormGroup>
							<Col className="row col-sm-6 " style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
									КПП <span style={{ color: "#f73164", fontSize: "18px" }}>*</span>
								</Label>
								<Col sm="11" style={{ height: "68px" }}>
									<Input className="form-control" type="text" placeholder="КПП" required={true} value={kpp} onChange={handleChangeKPP} />
									{errorMessageKpp && <div className="font-secondary">{errorMessageKpp}</div>}
								</Col>
							</Col>
						</Row>
						<Row>
							<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label">
									Телефон
								</Label>
								<Col sm="11">
									<InputMask
										className="form-control"
										mask="+7 (999) 999-99-99"
										maskChar="_"
										placeholder="+7 (___) ___-__-__"
										required={false}
										alwaysShowMask
										value={phone}
										type="tel"
										onChange={(e) => setPhone(e.target.value)}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
									Директор (ФИО) <span style={{ color: "#f73164", fontSize: "18px" }}>*</span>
								</Label>
								<Col sm="11">
									<Input
										className="form-control"
										type="text"
										placeholder="Андрей Андреевич Андреев"
										value={directorFullName}
										required={true}
										onChange={(e) => setDirectorFullName(e.target.value)}
									/>
								</Col>
							</FormGroup>
						</Row>
						<Row>
							<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label">
									Контактное лицо <span style={{ color: "#f73164", fontSize: "18px" }}>*</span>
								</Label>
								<Col sm="11">
									<AsyncSelect
										styles={colourStyles}
										defaultOptions={contactNames}
										loadOptions={promiseOptionsContactName}
										placeholder="Контактное лицо"
										noOptionsMessage={() => "Нет результатов"}
										loadingMessage={() => "Загрузка.."}
										value={contactName}
										className="form-control p-0"
										onChange={handleChangeContactName}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
									Должность директора <span style={{ color: "#f73164", fontSize: "18px" }}>*</span>
								</Label>
								<Col sm="11">
									<Input
										className="form-control"
										type="text"
										placeholder="Директор / Генеральный директор"
										value={postDirector}
										required={true}
										onChange={handleChangePostDirector}
									/>
								</Col>
							</FormGroup>
						</Row>
						<Row>
							<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
								<Label className="col-sm-9 col-form-label">
									Документ подтверждающий полномочия <span style={{ color: "#f73164", fontSize: "18px" }}>*</span>
								</Label>
								<Col sm="11">
									<Input className="form-control" type="tel" placeholder="Свидетельство № / Устав" required={true} value={document} onChange={handleChangeDocument} />
								</Col>
							</FormGroup>
						</Row>
					</CardBody>
					<HeaderCard title={"Адреса"} />
					<CardBody>
						<Form className="form theme-form">
							<Row>
								<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
									<Label className="col-sm-9 col-form-label">Фактический адрес</Label>
									<Col sm="11">
										<Input className="form-control" type="tel" placeholder="Фактический адрес" value={actualStreet} onChange={handleChangeActualStreet} />
									</Col>
								</FormGroup>
								<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
									<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
										Юридический адрес <span style={{ color: "#f73164", fontSize: "18px" }}>*</span>
									</Label>
									<Col sm="11">
										<Input className="form-control" type="text" placeholder="Юридический адрес" required={true} value={legalStreet} onChange={handleChangeLegalStreet} />
									</Col>
								</FormGroup>
							</Row>
							<Row>
								<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
									<Label className="col-sm-9 col-form-label">Адрес сайта</Label>
									<Col sm="11">
										<Input className="form-control" type="tel" placeholder="Адрес сайта" value={site} onChange={handleChangeSite} />
									</Col>
								</FormGroup>
							</Row>
						</Form>
					</CardBody>
					<HeaderCard title={"Банковские реквизиты"} />
					<CardBody>
						<Form className="form theme-form">
							<Row className="mb-3">
								<span style={{ color: "#f73164", fontSize: "13px" }}>
									Данные из этого раздела помогают нам при выставлении счетов.<br></br>Вы можете заполнить его сейчас или позднее в личном кабинете после того, как менеджер
									подтвердит вашу регистрацию.
								</span>
							</Row>
							<Row>
								<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
									<Label className="col-sm-9 col-form-label">БИК</Label>
									<Col sm="11">
										<AsyncSelect
											styles={colourStyles}
											loadOptions={promiseOptionsBic}
											placeholder="БИК"
											noOptionsMessage={() => "Нет результатов"}
											loadingMessage={() => "Загрузка.."}
											value={bankBic}
											className="form-control p-0"
											onChange={handleChangeBic}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row col-sm-6" style={{ flexDirection: "column" }}>
									<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
										Наименование банка
									</Label>
									<Col sm="11">
										<AsyncSelect
											styles={colourStyles}
											loadOptions={promiseOptionsBankName}
											placeholder="Наименование банка"
											noOptionsMessage={() => "Нет результатов"}
											loadingMessage={() => "Загрузка.."}
											value={bankName}
											className="form-control p-0"
											onChange={handleChangeBankName}
										/>
									</Col>
								</FormGroup>
							</Row>
							<Row>
								<Col className="row col-sm-6" style={{ flexDirection: "column" }}>
									<Label className="col-sm-9 col-form-label">Расчетный счет</Label>
									<Col sm="11" style={{ height: "68px" }}>
										<Input className="form-control" type="tel" placeholder="Расчетный счет" value={bankAccount} onChange={handleChangeBankAccount} />
										{errorMessageBankAcc && <div className="font-secondary">{errorMessageBankAcc}</div>}
									</Col>
								</Col>
								<Col className="row col-sm-6" style={{ flexDirection: "column" }}>
									<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
										Корреспонденский счет
									</Label>
									<Col sm="11" style={{ height: "68px" }}>
										<Input className="form-control" type="text" placeholder="Корреспонденский счет" value={bankKorrespondent} onChange={handleChangeBankKorrespondent} />
										{errorMessageBankKor && <div className="font-secondary">{errorMessageBankKor}</div>}
									</Col>
								</Col>
							</Row>
						</Form>
					</CardBody>
					<Fragment>
						<CardFooter className="text-end">
							<Btn
								attrBtn={{
									color: "primary",
									onClick: () => createCompany(),
									disabled: loading /*errorMessageBankAcc !== "" || errorMessageBankKor !== "" || errorMessageKpp !== "" || errorMessageOgrn !== "" ||*/ ,
								}}
							>
								{loading ? <BeatLoader size={8} color="#ffffff" /> : "Далее"}
							</Btn>
						</CardFooter>
					</Fragment>
				</Form>
			</Card>
		</Fragment>
	);
};

export default BasicFormControlClass;
