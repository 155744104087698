import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Container } from 'reactstrap';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import axios from 'axios';
import Select from 'react-select';
import { Label, Input, Col, Nav, NavItem, NavLink, TabContent, TabPane, CardHeader, Row } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import Teachers from '../../Learning/Courses/BuildCourse/Teachers/Teachers';
import uuid from 'react-uuid';

const AddEditSchemes = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const schemeId = queryParams.get('schemeId');

	const [rules, setRules] = useState([]);
	const [criteria, setCriteria] = useState([]);

	useEffect(() => {
		axios
			.get('/api/calculationRules.json')
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setRules(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});

		axios
			.get('/api/calculationCriteria.json')
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setCriteria(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [name, setName] = useState('');

	const [id, setId] = useState('');
	const [employees, setEmployees] = useState([]);

	const [primarycolorTab, setprimarycolorTab] = useState('1');

	const [type, setType] = useState({ label: null, value: null });
	const [selectRule, setSelectRule] = useState({ label: null, value: null });

	const [algoritmCriteria, setAlgoritmCriteria] = useState({ label: null, value: null });
	const [algoritmRule, setAlgoritmRule] = useState({ label: null, value: null });

	useEffect(() => {
		axios
			.get('/api/calculationSchemes.json')
			.then((response) => {
				const foundItem = response.data.find((item) => item.id === schemeId);
				if (foundItem) {
					setName(foundItem.name);

					setType({ label: foundItem.type, value: foundItem.type });
					setSelectRule({ label: foundItem.standard_rule, value: foundItem.standard_rule });
					setEmployees(foundItem.employees);
					if (foundItem.plan_algorithm) {
						setAlgoritmCriteria({ label: foundItem.plan_algorithm.criteria, value: foundItem.plan_algorithm.criteria });
						setAlgoritmRule({ label: foundItem.plan_algorithm.rule, value: foundItem.plan_algorithm.rule });
					}

					console.log(foundItem.employees);
					setId(foundItem.id);
				}
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const send = () => {
		if (name == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			if (!id) {
				setId(uuid());
			}
			const newEvent = {
				// id: id,
				// name: name,
				// price: price,
			};
			console.log(newEvent);
			// axios
			// 	.post(`/api/clientsDemandsForm.json`, newEvent)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Календарь добавлен!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 	});
		}
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={name ? `Редактировать схему` : `Добавить схему`} parent="Расчёт зарплат" title={name ? `Редактировать схему` : `Добавить схему`} />
			<Container fluid={true}>
				<Card>
					<CardHeader>
						<Row>
							<Nav className="nav nav-pills">
								<NavItem>
									<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '1' ? 'active' : ''} onClick={() => setprimarycolorTab('1')}>
										Основные
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '2' ? 'active' : ''} onClick={() => setprimarycolorTab('2')}>
										Сотрудники
									</NavLink>
								</NavItem>
							</Nav>
						</Row>
					</CardHeader>
					<CardBody className="m-25">
						<TabContent activeTab={primarycolorTab}>
							<TabPane className="fade show" tabId="1">
								<Col className="row col-sm-12 mb-2">
									<Label className="col-sm-9 col-form-label">Название</Label>
									<Col sm="11">
										<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
									</Col>
								</Col>
								<Col className="row col-sm-12 mb-2">
									<Label className="col-sm-9 col-form-label">Тип</Label>
									<Col sm="11">
										<Select
											type="tel"
											placeholder="Выберите..."
											value={type}
											options={[
												{ label: 'Стандартная схема расчета', value: 'Стандартная схема расчета' },
												{ label: 'Плановая схема расчета', value: 'Плановая схема расчета' },
											]}
											onChange={(option) => setType(option)}
										/>
									</Col>
								</Col>
								{type.value === 'Плановая схема расчета' ? (
									<>
										<Col className="row col-sm-12 mb-2">
											<Label className="col-sm-9 col-form-label">Алгоритм расчета:</Label>
										</Col>
										<Col className="row col-sm-11 mb-2 ms-4">
											<Label className="col-sm-9 col-form-label">Если сработал критерий</Label>
											<Col sm="11">
												<Select type="tel" placeholder="Выберите..." value={algoritmCriteria} options={criteria} onChange={(option) => setAlgoritmCriteria(option)} />
											</Col>
										</Col>
										<Col className="row col-sm-11 mb-2 ms-4">
											<Label className="col-sm-9 col-form-label">То расчитать по правилу</Label>
											<Col sm="11">
												<Select type="tel" placeholder="Выберите..." value={algoritmRule} options={rules} onChange={(option) => setAlgoritmRule(option)} />
											</Col>
										</Col>
										<Col className="row col-sm-11 mb-2 ms-4">
											<Label className="col-sm-9 col-form-label">Иначе</Label>
											<Col sm="11">
												<Input className="form-control" type="tel" value={'Применить стандартное правило'} disabled />
											</Col>
										</Col>
									</>
								) : (
									''
								)}
								<Col className="row col-sm-12 mb-2">
									<Label className="col-sm-9 col-form-label">Стандартное правило</Label>
									<Col sm="11">
										<Select type="tel" placeholder="Выберите..." value={selectRule} options={rules} onChange={(option) => setSelectRule(option)} />
									</Col>
								</Col>
							</TabPane>
							<TabPane tabId="2">{employees && <Teachers products={employees} />}</TabPane>
						</TabContent>
					</CardBody>
					<CardFooter>
						<div className="modal-footer">
							<Btn attrBtn={{ color: 'primary', onClick: send }}>Сохранить изменения</Btn>
						</div>
					</CardFooter>
				</Card>
			</Container>
		</Fragment>
	);
};
export default AddEditSchemes;
