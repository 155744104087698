import React, { useState, useContext, useEffect } from "react";
import Context from "./index";
import axios from "axios";
import BitrixContext from '.';
import { useKeycloak } from "@react-keycloak/web";
import { BitrixDataApi } from "../../api";
import { useCompanyContext } from "../Company/CompanyProvider";
import uuid from 'react-uuid';

const BitrixProvider = ({ children, props }) => {
	const context = useCreateBitrixContext(props);

	return (
		<BitrixContext.Provider
			value={context}
		>
			{children}
		</BitrixContext.Provider>
	);
};

export function useBitrixContext() {
	const context = useContext(BitrixContext);
	if (!context) throw new Error('Use app context within provider!');
	return context;
}

const useCreateBitrixContext = function (props) {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const companyId = companyData.map((item) => (item.attributes.companyId[0]));//"53819cb9-eaf4-48e1-b039-e37b6836d2b3";

	const [dealCategories, setDealCategories] = useState([]);
	const [dealCategoriesOptions, setDealCategoriesOptions] = useState([]);
	const [dealCategoriesStages, setDealCategoriesStages] = useState([]);
	const [dealCategoriesStagesOptions, setDealCategoriesStagesOptions] = useState([]);
	const [bitrixDeal, setBitrixDeal] = useState({
		dealName: '',
		category: '',
		stage: '',
		commentary: ''
	});


	const getDealCategories = async () => {
		await axios
			.get(`${BitrixDataApi}GetDealCategoriesStatuses`, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				const categoriesOptions = response.data.result.map((option) => ({
					label: option.name,
					value: option.id,
					stages: option.Stages
				}));

				const allStagesOptions = categoriesOptions.flatMap((category) =>
					category.stages.map((stage) => ({
						label: stage.name,
						value: stage.status_id,
						categoryId: category.value,
					}))
				);

				setDealCategories(response.data.result);
				setDealCategoriesOptions(categoriesOptions);
				setDealCategoriesStagesOptions(allStagesOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}

	const createDealFromDemand = async (demand, nameForm, category, stage) => {

		bitrixDeal.dealName = `Заявка Twineo из формы: ${nameForm} №${demand.id}`;
		bitrixDeal.category = category;
		bitrixDeal.stage = stage;
		bitrixDeal.commentary = demand.parameters
			.map(param => `${param.Name}: ${param.Value !== undefined && param.Value !== null ? param.Value : 'Не заполнено'}`)
			.join('\n');

		await axios
			.post(`${BitrixDataApi}CreateDealFromDemand`, bitrixDeal, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	};





	return {
		dealCategories,
		dealCategoriesOptions,
		dealCategoriesStagesOptions,
		dealCategoriesStages,
		bitrixDeal,

		getDealCategories,

		createDealFromDemand,

		setBitrixDeal,
		setDealCategoriesStages,
		setDealCategoriesStagesOptions,
	};
};

export default BitrixProvider;
