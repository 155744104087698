import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, CardBody, Col, Card } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import axios from 'axios';
import DynamicsCalls from './DynamicsCalls';
import LostCalls from './LostCalls';
import ComparisonPeriods from './ComparisonPeriods';
import CallActivity from './CallActivity';
import EmployeeWorkload from './EmployeeWorkload';
import DurationСalls from './DurationCalls';

const DiagramCalls = () => {
	const [primarycolorTab, setprimarycolorTab] = useState('1');

	const [data, setData] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/calls.json`)
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Row>
				<Col sm="3" xs="12">
					<Nav className="nav flex-column nav-pills">
						<NavItem>
							<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '1' ? 'active' : ''} onClick={() => setprimarycolorTab('1')}>
								Динамика звонков
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '2' ? 'active' : ''} onClick={() => setprimarycolorTab('2')}>
								Сравнение периодов
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '3' ? 'active' : ''} onClick={() => setprimarycolorTab('3')}>
								Потерянные звонки
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '4' ? 'active' : ''} onClick={() => setprimarycolorTab('4')}>
								Активность звонков
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '5' ? 'active' : ''} onClick={() => setprimarycolorTab('5')}>
								Нагрузка по сотрудникам
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink style={{ cursor: 'pointer' }} className={primarycolorTab === '6' ? 'active' : ''} onClick={() => setprimarycolorTab('6')}>
								Длительность разговоров
							</NavLink>
						</NavItem>
					</Nav>
				</Col>
				<Col sm="9" xs="12">
					<TabContent activeTab={primarycolorTab}>
						<TabPane className="fade show" tabId="1">
							<DynamicsCalls />
						</TabPane>
						<TabPane tabId="2">
							<ComparisonPeriods />
						</TabPane>
						<TabPane tabId="3">
							<LostCalls />
						</TabPane>
						<TabPane tabId="4">
							<CallActivity />
						</TabPane>
						<TabPane tabId="5">
							<EmployeeWorkload />
						</TabPane>
						<TabPane tabId="6">
							<DurationСalls />
						</TabPane>
					</TabContent>
				</Col>
			</Row>
		</Fragment>
	);
};
export default DiagramCalls;
