import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, CardBody, Col, Card, Label, CardHeader, Input, InputGroup, InputGroupText, FormGroup } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import ru from 'date-fns/locale/ru';
import axios from 'axios';
import Select from 'react-select';
import { Plus } from 'react-feather';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';

import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Breadcrumbs, Btn, H6 } from '../../../../../AbstractElements';
import TableFinancialTransactions from './TableFinancialTransactions';

import { MoneyManagementApi, TwinAuthApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const FinancialTransactions = () => {
	const [transactions, setTransactions] = useState([]);

	const [selectedCash, setSelectedCash] = useState(null);
	const [cashOptions, setCashOptions] = useState([]);
	const [paymentItems, setPaymentItems] = useState([]);
	const [recipientsRadio, setRecipientsRadio] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${MoneyManagementApi}getPaymentItems`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = concatResults(response).paymentItems.map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setPaymentItems(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`${MoneyManagementApi}getCashregisters`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = concatResults(response).cashregisters.map((option) => ({
					label: option.name + ' (' + option.amount + ' ₽)',
					value: option.id,
				}));
				setCashOptions(selectOptions);

				const cashRegister = queryParams.get('cashRegister');
				if (cashRegister) {
					const foundObject = selectOptions.find((item) => item.value === cashRegister);
					setSelectedCash({ label: foundObject.label, value: foundObject.value });
				}
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`${MoneyManagementApi}getRecipientTypes`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setRecipientsRadio(concatResults(response).recipientTypes);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [recipientInitial, setrecipientInitial] = useState(null);

	const [clientOptioins, setClientOptioins] = useState('');
	const [employeeOptioins, setEmployeeOptioins] = useState('');
	const [counterpartyOptioins, setCounterpartyOptioins] = useState('');

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id
				}));
				setClientOptioins(selectOptions);
				setEmployeeOptioins(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		axios
			.get(`${TwinAuthApi}get-groups-companies`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setCounterpartyOptioins(
					JSON.parse(response.data).map((company) => ({
						label: company.name,
						value: company.id,
					}))
				);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});

	}, []);

	useEffect(() => {
		axios
			.get(`${MoneyManagementApi}getTransactions`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setTransactions(concatResults(response).transactions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);

	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());

	useEffect(() => {
		const dateEnd = queryParams.get('dateEnd');
		if (dateEnd) {
			setPeriodEnd(new Date(dateEnd));
		}

		const dateStart = queryParams.get('dateStart');
		if (dateStart) {
			setPeriodStart(new Date(dateStart));
		}
	}, []);

	const handleDateStartChange = (selectedOption) => {
		const categoryId = selectedOption;
		setPeriodStart(selectedOption);
		queryParams.delete('dateStart');
		queryParams.append(`dateStart`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};
	const handleDateEndChange = (selectedOption) => {
		const categoryId = selectedOption;
		setPeriodEnd(selectedOption);
		queryParams.delete('dateEnd');
		queryParams.append(`dateEnd`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};

	const handleCashChange = (selectedOption) => {
		const cashRegister = selectedOption.value;
		setSelectedCash(selectedOption);
		queryParams.delete('cashRegister');
		queryParams.append(`cashRegister`, cashRegister);
		navigate(`?${queryParams.toString()}`);
	};

	const show = () => {

		axios
			.get(`${MoneyManagementApi}getTransactions`, { params: { query: queryParams }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setTransactions(concatResults(response).transactions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	};

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [recipientType, setRecipientType] = useState('');
	const [amount, setAmount] = useState(0);
	const [selectedPayment, setSelectedPayment] = useState(null);
	const [curCash, setCurCash] = useState(null);

	const handleAddRow = () => {

		if (selectedPayment == '' || curCash == '' || recipientType == '' || recipientInitial == ''
			|| amount == '' || description == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
		else {
			const newCash = {
				id: uuid(),
				PaymentTypeId: selectedPayment.value,
				CashregisterId: curCash.value,
				RecipientTypeId: recipientsRadio.find(x => x.name === recipientType).id,
				RecipientInitial: recipientInitial.label,
				amount: amount,
				comment: description
			};
			console.log(newCash);
			axios
				.post(`${MoneyManagementApi}createTransaction`, newCash, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Платеж добавлен!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(!modal);
				})
				.catch((error) => {
					console.error(error);
				});

		}
	};

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Финансовые операции" parent="Отчёты" subParent="Финансы" title="Финансовые операции" />
			<Container fluid className="user-card">
				<Col md="12">
					<Card>
						<CardHeader>
							<div style={{ justifyContent: 'space-between', display: 'flex' }}>
								<Link to={`${process.env.PUBLIC_URL}/analytics/reports`} className="f-18">
									<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
								</Link>
								<Btn attrBtn={{ color: 'info', onClick: toggle }}>
									<Plus size={'18px'} style={{ margin: '0 3px -3px 0' }} />
									Новый платеж
								</Btn>
							</div>
						</CardHeader>
						<CardBody>
							<Row className="m-l-5" style={{ justifyContent: 'space-around' }}>
								<Col xl="3" className="">
									<div style={{ display: 'flex', alignItems: 'flex-end' }}>
										<Label className="m-r-10 f-16">С</Label>
										<div className="customDatePickerWidth ">
											<DatePicker locale={ru} className="form-control digits" selected={periodStart} value={formatDate(periodStart)} onChange={handleDateStartChange} />
										</div>
									</div>
								</Col>
								<Col xl="3" className="">
									<div style={{ display: 'flex', alignItems: 'flex-end' }}>
										<Label className="m-r-10 f-16">По</Label>
										<div className="customDatePickerWidth">
											<DatePicker locale={ru} className="form-control digits" selected={periodEnd} value={formatDate(periodEnd)} onChange={handleDateEndChange} />
										</div>
									</div>
								</Col>
								<Col xl="3" className="">
									<Col sm="12">
										<Select placeholder="Выберите кассу..." value={selectedCash} options={cashOptions} className="js-example-basic-single col-sm-12" onChange={handleCashChange} />
									</Col>
								</Col>
								<Col xl="2" className="">
									<Btn attrBtn={{ color: 'primary', className: 'f-right', onClick: show }}>Показать</Btn>
								</Col>
							</Row>
						</CardBody>
					</Card>
					<Card>
						<CardBody>
							<TableFinancialTransactions filteredProducts={transactions} />
						</CardBody>
					</Card>
				</Col>
				<CommonModal isOpen={modal} title="Новый платеж" toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Добавить" size="lg">
					<CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Статья платежа</Label>
							<Col sm="12">
								<Select placeholder="Выберите..." value={selectedPayment} options={paymentItems} className="js-example-basic-single col-sm-12" onChange={(selected) => setSelectedPayment(selected)} />
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Касса</Label>
							<Col sm="12">
								<Select placeholder="Выберите..." value={curCash} options={cashOptions} className="js-example-basic-single col-sm-12" onChange={(selected) => setCurCash(selected)} />
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Получатель</Label>
							<Col sm="12">
								<FormGroup className="m-t-15 custom-radio-ml">

									{recipientsRadio.map((item, index) => {
										return (
											<div className="radio radio-primary mb-3 m-l-5">
												<Input id={item.id} type="radio" name={item.id} value={item.name} checked={recipientType === item.name} onChange={(e) => setRecipientType(item.name)} />
												<Label for={item.id}>{item.name}</Label>
											</div>
										);
									})}
								</FormGroup>
							</Col>
						</Col>
						{recipientType === 'Контрагент' && (
							<Col className="row col-sm-12 mb-3">
								<Label className="col-sm-9 col-form-label">Контрагент</Label>
								<Col sm="12">
									<Select placeholder="Выберите..." value={recipientInitial} options={counterpartyOptioins} className="js-example-basic-single col-sm-12" onChange={(selected) => setrecipientInitial(selected)} />
								</Col>
							</Col>
						)}
						{recipientType === 'Сотрудник' && (
							<Col className="row col-sm-12 mb-3">
								<Label className="col-sm-9 col-form-label">Сотрудник</Label>
								<Col sm="12">
									<Select placeholder="Выберите..." value={recipientInitial} options={employeeOptioins} className="js-example-basic-single col-sm-12" onChange={(selected) => setrecipientInitial(selected)} />
								</Col>
							</Col>
						)}
						{recipientType === 'Клиент' && (
							<Col className="row col-sm-12 mb-3">
								<Label className="col-sm-9 col-form-label">Клиент</Label>
								<Col sm="12">
									<Select placeholder="Выберите..." value={recipientInitial} options={clientOptioins} className="js-example-basic-single col-sm-12" onChange={(selected) => setrecipientInitial(selected)} />
								</Col>
							</Col>
						)}
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Сумма</Label>
							<Col sm="12">
								<InputGroup>
									<Input className="form-control" type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
									<InputGroupText>{'₽'}</InputGroupText>
								</InputGroup>
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-3">
							<Label className="col-sm-9 col-form-label">Комментарий</Label>
							<Col sm="12">
								<textarea rows={'3'} className="form-control" type="tel" value={description} onChange={(e) => setDescription(e.target.value)} />
							</Col>
						</Col>
					</CardBody>
				</CommonModal>
			</Container>
		</Fragment>
	);
};
export default FinancialTransactions;
