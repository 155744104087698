import ModulesContext from '.';
import React, { useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { ModulesApi, TwinAuthApi } from '../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../Company/CompanyProvider";
import { concatResults } from '../../concatResults';

const ModulesProvider = ({ children, props }) => {
    const context = useCreateModulesContext(props);

    return (
        <ModulesContext.Provider
            value={context}
        >
            {children}
        </ModulesContext.Provider>
    );
};

export function useModulesContext() {
    const context = useContext(ModulesContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateModulesContext = function (props) {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const [moduleGroups, setModuleGroups] = useState([]);
    const [moduleGroupRoles, setModuleGroupRoles] = useState([]);
    const [moduleRules, setModuleRules] = useState([]);
    const [modules, setModules] = useState([]);
    const [services, setServices] = useState([]);
    const [service, setService] = useState([]);
    const [rulesRoles, setRulesRoles] = useState([]);
    const [rolesRoles, setRolesRoles] = useState([]);
    const [companySelectedModulesRules, setCompanySelectedModulesRules] = useState([])

    const getModuleGroups = async () => {
        await axios.get(`${ModulesApi}get-module-groups`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            //console.log(2);
            //console.log(resp);
            setModuleGroups(resp.data.groups);
        }).catch((error) => {
            console.error(error);
        });
    };

    const getModuleGroupsRoles = async () => {
        await axios.get(`${ModulesApi}get-module-group-roles`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(1);
            console.log(resp);
            setModuleGroupRoles(resp.data.groupRoles);
        }).catch((error) => {
            console.error(error);
        });
    };

    const getModuleRules = async () => {
        await axios.get(`${ModulesApi}get-module-rules`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(3);
            console.log(resp);
            setModuleRules(resp.data.rules);
        }).catch((error) => {
            console.error(error);
        });
    };

    const getCompanySelectedModulesRules = async () => {
        await axios.get(`${ModulesApi}get-company-selected-modules-rules/${companyData[0]?.attributes?.companyId[0]}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp);
            setCompanySelectedModulesRules(resp.data.rules);
        }).catch((error) => {
            console.error(error);
        });
    }

    const getModules = async () => {
        await axios.get(`${ModulesApi}get-modules`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp);
            setModules(resp.data.modules);
        }).catch((error) => {
            console.error(error);
        });
    }

    const getGroupRolesRoles = async () => {
        await axios.get(`${ModulesApi}get-module-group-roles-roles`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp.data);
            setRolesRoles(JSON.parse(resp.data));
        }).catch((error) => {
            console.error(error);
        });
    }

    const getRulesRoles = async () => {
        await axios.get(`${ModulesApi}get-module-rules-roles`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(JSON.parse(resp.data));
            setRulesRoles(JSON.parse(resp.data));
        }).catch((error) => {
            console.error(error);
        });
    }

    const getServices = async () => {
        try {
            const resp = await axios.get(`${ModulesApi}get-services`, {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`
                }
            });

            setServices(resp.data.services);
            return resp.data.services;

        } catch (error) {
            console.error('������ ��������� ������:', error);
        }
    };

    const getService = async (serviceId, serviceName) => {
        await axios.get(`${ModulesApi}get-service`,
            {
                params:
                {
                    serviceId: serviceId,
                    serviceName: serviceName
                },
                headers:
                {
                    Authorization: `Bearer ${keycloak.token}`
                }
            }).then((resp) => {
                console.log(resp);
                setService(resp.data);
            }).catch((error) => {
                console.error(error);
            });
    }

    const createModuleGroup = async (ruleId, companyId, groupName) => {
        console.log(ruleId);
        console.log(companyId);
        console.log(groupName);
        await axios.post(`${ModulesApi}create-module-group`, {
            ruleId: ruleId,
            companyId: companyId,
            groupName: groupName
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp);
            getModuleGroups();
        }).catch((error) => {
            console.error(error);
        });
    };

    const createModuleGroupRole = async (ruleId, groupRole) => {
        await axios.post(`${ModulesApi}create-module-group-role`, {
            ruleId: ruleId,
            groupRole: groupRole
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp);
            getModuleGroupsRoles();
        }).catch((error) => {
            console.error(error);
        });
    };

    const createModuleRule = async (moduleId, displayName, roleName) => {
        console.log(123213);
        console.log(moduleId);
        console.log(displayName);
        console.log(roleName);
        await axios.post(`${ModulesApi}create-module-rule`, {
            moduleId: moduleId,
            displayName: displayName,
            roleName: roleName
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
                CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
            }
        }).then((resp) => {
            console.log(resp);
            getModuleRules();
        }).catch((error) => {
            console.error(error);
        });
    };

    const updateModuleGroup = async (id, ruleId, companyId, groupName) => {
        await axios.put(`${ModulesApi}update-module-group`, {
            id: id,
            ruleId: ruleId,
            companyId: companyId,
            groupName: groupName
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp);
            getModuleGroups();
        }).catch((error) => {
            console.error(error);
        });
    }

    const updateModuleGroupRole = async (id, ruleId, groupRole) => {
        await axios.put(`${ModulesApi}update-module-group-role`, {
            id: id,
            ruleId: ruleId,
            groupRole: groupRole
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp);
            getModuleGroupsRoles();
        }).catch((error) => {
            console.error(error);
        });
    }

    const updateModuleRule = async (id, moduleId, displayName, roleName) => {
        await axios.put(`${ModulesApi}update-module-rule`, {
            id: id,
            moduleId: moduleId,
            displayName: displayName,
            roleName: roleName
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp);
            getModuleRules();
        }).catch((error) => {
            console.error(error);
        });
    }

    const deleteModuleGroup = async (id) => {
        await axios.delete(`${ModulesApi}delete-module-group/${id}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp);
            getModuleGroups();
        }).catch((error) => {
            console.error(error);
        });
    }

    const deleteModuleGroupRole = async (id) => {
        await axios.delete(`${ModulesApi}delete-module-group-role/${id}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp);
            getModuleGroupsRoles();
        }).catch((error) => {
            console.error(error);
        });
    }

    const deleteModuleRule = async (id) => {
        await axios.delete(`${ModulesApi}delete-module-rule/${id}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`
            }
        }).then((resp) => {
            console.log(resp);
            getModuleRules();
        }).catch((error) => {
            console.error(error);
        });
    }

    return {
        moduleGroups,
        moduleGroupRoles,
        moduleRules,
        modules,
        rulesRoles,
        rolesRoles,
        companySelectedModulesRules,
        services,
        service,

        getModules,
        getModuleGroupsRoles,
        getModuleRules,
        getModuleGroups,
        getRulesRoles,
        getGroupRolesRoles,
        getCompanySelectedModulesRules,
        getServices,
        getService,

        createModuleGroup,
        createModuleGroupRole,
        createModuleRule,

        updateModuleGroup,
        updateModuleGroupRole,
        updateModuleRule,

        deleteModuleGroup,
        deleteModuleGroupRole,
        deleteModuleRule,

    };
}

export default ModulesProvider;