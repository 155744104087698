import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Label, Input, InputGroup, InputGroupText, CardBody, Col } from "reactstrap";
import { Link } from "react-router-dom";

import FilterComponent from "../Catalog/FilterComponent";
import CommonModal from "../../UiKits/Modals/common/modal";

import { ProductsApi } from "../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../concatResults";

const TableOffersList = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [data, setData] = useState([]);
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await axios.get(`${ProductsApi}getPackageOffers`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { query: "" } });
			setData(concatResults(response).packageOffers);
		} catch (error) {
			console.error("Ошибка при выполнении запроса:", error);
		}
	};

	const [editModal, setEditModal] = useState(false);
	const [edit, setEdit] = useState(null);
	const [description, setDescription] = useState("");
	const [name, setName] = useState("");
	const [cost, setCost] = useState("");
	const [isActive, setIsActive] = useState("");
	const [Id, setId] = useState("");

	const toggle = (offerId) => {
		console.log(offerId);
		if (typeof offerId === "object" && offerId.hasOwnProperty("id")) {
			setName(offerId.name);
			setCost(offerId.cost);
			setDescription(offerId.description);
			setId(offerId.id);
			setIsActive({ value: offerId.isActive, label: offerId.isActive ? "Да" : "Нет" });
			setEdit(true);
		} else {
			setName("");
			setCost("");
			setDescription("");
			setIsActive("");
			setId("");
			setEdit(false);
		}

		setEditModal(!editModal);
	};

	const columns = [
		{
			name: "№",
			selector: (row) => `${row.id}`,
			sortable: true,
			width: "15%",
		},
		{
			name: "Название",
			selector: (row) => <Link to={`/app/package-offers/preview?offerId=1`}>{row.name}</Link>,
			sortable: true,
		},
		{
			name: "Описание",
			selector: (row) => `${row.description}`,
			sortable: true,
			width: "25%",
		},
		{
			name: "Стоимость",
			selector: (row) => `${row.cost} руб.`,
			sortable: true,
			width: "12%",
		},
		{
			name: "Активирован?",
			selector: (row) => <div className="">{row.isActive ? <i className="fa fa-check font-success f-right"></i> : <i className="fa fa-times font-danger"></i>}</div>,
			sortable: false,
			width: "18%",
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deletePackageOffer(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deletePackageOffer = (id) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${ProductsApi}deletePackageOffer`, {
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
						data: {
							PackageId: id,
						},
					})
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
						fetchData();
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении:", error);
					});
			}
		});
	};

	const performPackageOfferOperation = (operationType) => {
		if (name === "" || cost === "" || description === "" || isActive === "") {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const packageOffer = {
				id: operationType === "update" ? Id : uuid(),
				name: name,
				description: description,
				cost: parseFloat(cost),
				isActive: Boolean(isActive.value),
			};

			const apiEndpoint = operationType === "update" ? "updatePackageOffer" : "createPackageOffer";
			const httpMethod = operationType === "update" ? "put" : "post";

			console.log(packageOffer);

			axios({
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				method: httpMethod,
				url: `${ProductsApi}${apiEndpoint}`,
				data: packageOffer,
			})
				.then((response) => {
					const successMessage = operationType === "update" ? "Пакетное предложение обновлено!" : "Пакетное предложение обновлено!";
					toast.success(successMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
					setEditModal(false);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const createPackageOffer = () => {
		performPackageOfferOperation("create");
	};

	const updatePackageOffer = () => {
		performPackageOfferOperation("update");
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal
				isOpen={editModal}
				title={edit ? "Редактировать" : "Добавить"}
				toggler={edit ? updatePackageOffer : createPackageOffer}
				togglerClose={toggle}
				closeText="Отмена"
				saveText="Сохранить"
				size="lg"
			>
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Стоимость</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="text" value={cost} onChange={(e) => setCost(e.target.value)} />
								<InputGroupText>{"руб"}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-form-label">Активирован?</Label>
						<Col sm="11">
							<Select
								placeholder="Выберите..."
								value={isActive}
								options={[
									{ value: "true", label: "Да" },
									{ value: "false", label: "Нет" },
								]}
								className="js-example-basic-single col-sm-12"
								onChange={(selected) => setIsActive(selected)}
							/>
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableOffersList;
