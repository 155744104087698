// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.draggable-container {
	display: flex;
	justify-content: space-between;
	padding: 20px;
}
.highlight {
	border: 2px dashed var(--theme-secondary);
	/* background: var(--theme-secondary);
	opacity: 0.2; */
}

.right-panel {
	width: 100%;
	/* background-color: #f0f0f0; */
}

.left-panel {
	width: 100%;
	padding: 0 20px 40px 20px;
}

.highlight::after {
	padding-top: 30px;
	color: red;
	content: '+';
	font-size: 36px;
	text-align: center;
	display: block;
	align-items: center;
}

.draggable-item {
	cursor: pointer;
	margin-bottom: 10px;
	padding: 5px;
	background-color: #fff;
	border: 1px solid #ccc;
}

ul {
	list-style: none;
	padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Application/Request/FormBuilder/DnD.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,8BAA8B;CAC9B,aAAa;AACd;AACA;CACC,yCAAyC;CACzC;gBACe;AAChB;;AAEA;CACC,WAAW;CACX,+BAA+B;AAChC;;AAEA;CACC,WAAW;CACX,yBAAyB;AAC1B;;AAEA;CACC,iBAAiB;CACjB,UAAU;CACV,YAAY;CACZ,eAAe;CACf,kBAAkB;CAClB,cAAc;CACd,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,mBAAmB;CACnB,YAAY;CACZ,sBAAsB;CACtB,sBAAsB;AACvB;;AAEA;CACC,gBAAgB;CAChB,UAAU;AACX","sourcesContent":[".draggable-container {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tpadding: 20px;\n}\n.highlight {\n\tborder: 2px dashed var(--theme-secondary);\n\t/* background: var(--theme-secondary);\n\topacity: 0.2; */\n}\n\n.right-panel {\n\twidth: 100%;\n\t/* background-color: #f0f0f0; */\n}\n\n.left-panel {\n\twidth: 100%;\n\tpadding: 0 20px 40px 20px;\n}\n\n.highlight::after {\n\tpadding-top: 30px;\n\tcolor: red;\n\tcontent: '+';\n\tfont-size: 36px;\n\ttext-align: center;\n\tdisplay: block;\n\talign-items: center;\n}\n\n.draggable-item {\n\tcursor: pointer;\n\tmargin-bottom: 10px;\n\tpadding: 5px;\n\tbackground-color: #fff;\n\tborder: 1px solid #ccc;\n}\n\nul {\n\tlist-style: none;\n\tpadding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
