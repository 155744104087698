import InteractivePlanMapsContext from '.';
import React, {useState, useContext, useCallback, useReducer} from 'react';
import axios from 'axios';
import {ClientsApi} from '../../api';
import {useKeycloak} from '@react-keycloak/web';
import {useCompanyContext} from "../Company/CompanyProvider";
import {concatResults} from '../../concatResults';
import ClientsContext from ".";

const ClientsProvider = ({children, props}) => {
    const context = useCreateClientsContext(props);

    return (
        <ClientsContext.Provider
            value={context}
        >
            {children}
        </ClientsContext.Provider>
    );
};

export function useClientsContext() {
    const context = useContext(ClientsContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateClientsContext = function (props) {
    const { keycloak} = useKeycloak();
    const { companyData } = useCompanyContext();
    const [cardSettings, setCardSettings] = useState();
    const [clientCategories, setClientCategories] = useState();
    const [clientTypes, setClientTypes] = useState();
    const [client, setClient] = useState();
    const [companyClients, setCompanyClients] = useState();
    const [clientEmails, setClientEmails] = useState();
    const [clientPhones, setClientPhones] = useState();
    const [clientDocument, setClientDocument] = useState();
    const [clientDocuments, setClientDocuments] = useState();
    const [clientType, setClientType] = useState();
    const [clientVisits, setClientVisits] = useState();
    const [clientVisitStatuses, setClientVisitStatuses] = useState();
    const [documentType, setDocumentType] = useState();
    const [documentTypes, setDocumentTypes] = useState();
    const [loyalityCard, setLoyalityCard] = useState();
    const [loyalityCards, setLoyalityCards] = useState();
    const [loyalityCardTypes, setLoyalityCardTypes] = useState();
    const [loyalityCardUsersRestrictions, setLoyalityCardUsersRestrictions] = useState();
    const [clientDocumentValues, setClientDocumentValues] = useState();
    const [documentTypeCharacteristics, setDocumentTypeCharacteristics] = useState();

    const [modalCreateDocChar, changeModalCreateDocCharVisible] = useReducer((m) => !m, false);

    const getCardSettings = async () => {
        axios
            .get(`${ClientsApi}get-card-settings/${companyData[0]?.attributes.companyId[0]}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setCardSettings(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
                companyData[0]?.attributes.companyId[0] && createClientCardSettings(false, false, false, false);
            });
    };

    const getClientCategories = async () => {
        axios
            .get(`${ClientsApi}get-client-categories`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setClientCategories(response.data.categories);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getClientTypes = async () => {
        axios
            .get(`${ClientsApi}get-clients-types-list/${companyData[0]?.attributes.companyId[0]}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setClientTypes(response.data.types);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getClient = async (id) => {
        setClient(null);
        axios
            .get(`${ClientsApi}get-client/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setClient(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getCompanyClients = async () => {
        axios
            .get(`${ClientsApi}get-clients-list/${companyData[0]?.attributes.companyId[0]}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setCompanyClients(response.data.clients);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getClientEmails = async (id) => {
        axios
            .get(`${ClientsApi}get-client-emails/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setClientEmails(response.data.emails);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getClientPhones = async (id) => {
        axios
            .get(`${ClientsApi}get-client-phones/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setClientPhones(response.data.phones);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getClientDocument = async (documentId) => {
        axios
           .get(`${ClientsApi}get-client-document/${documentId}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
           .then((response) => {
                setClientDocument(response.data);
            })
           .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getClientDocuments = async (clientId) => {
        axios
           .get(`${ClientsApi}get-client-documents-list/${clientId}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
           .then((response) => {
                setClientDocuments(response.data.documents);
            })
           .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getClientType = async (id) => {
        axios
           .get(`${ClientsApi}get-clients-type/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
           .then((response) => {
                setClientType(response.data);
            })
           .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getClientVisits = async (clientId) => {
        axios
           .get(`${ClientsApi}get-clients-visits/${clientId}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
           .then((response) => {
                setClientVisits(response.data.visits);
            })
           .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getClientVisitStatuses = async () => {
        axios
           .get(`${ClientsApi}get-clients-visit-statuses`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
           .then((response) => {
                setClientVisitStatuses(response.data.statuses);
            })
           .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getDocumentType = async (id) => {
        axios
            .get(`${ClientsApi}get-document-type/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setDocumentType(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getDocumentTypes = async () => {
        axios
           .get(`${ClientsApi}get-document-types-list/${companyData[0]?.attributes.companyId[0]}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
           .then((response) => {
                setDocumentTypes(response.data.documentTypes);
            })
           .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getLoyalityCard = async (id) => {
        axios
           .get(`${ClientsApi}get-loyality-card/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
           .then((response) => {
                setLoyalityCard(response.data);
            })
           .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getLoyalityCards = async () => {
        axios
           .get(`${ClientsApi}get-loyality-cards-list/${companyData[0]?.attributes.companyId[0]}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
           .then((response) => {
                setLoyalityCards(response.data.cards);
            })
           .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getLoyalityCardTypes = async () => {
        axios
            .get(`${ClientsApi}get-loyalty-card-types-list`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                 setLoyalityCardTypes(response.data.types);
             })
            .catch((error) => {
                 console.error("Ошибка получения данных:", error);
             });
    }

    const getLoyalityCardUsersRestrictions = async () => {
        axios
            .get(`${ClientsApi}get-loyality-card-user-restrictions-list`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setLoyalityCardUsersRestrictions(response.data.restrictions);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getClientDocumentValues = async (id) => {
        axios
            .get(`${ClientsApi}get-client-document-values-list/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setClientDocumentValues(response.data.values);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const getDocumentTypeCharacteristcs = async (id) => {
        axios
            .get(`${ClientsApi}get-document-type-characteristics-list/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                setDocumentTypeCharacteristics(response.data.characteristics);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    }

    const createClientCardSettings = async (useSeparatedClientName, allowUseMultiplePhoneNumbers, allowUseMultipleEnails, allowUseDocuments) => {
        axios
            .post(`${ClientsApi}create-client-card-settings`,
                {
                    companyId: companyData[0]?.attributes.companyId[0],
                    useSeparatedClientName: useSeparatedClientName,
                    allowUseMultiplePhoneNumbers: allowUseMultiplePhoneNumbers,
                    allowUseMultipleEnails: allowUseMultipleEnails,
                    allowUseDocuments: allowUseDocuments,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCardSettings(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createClient = async (fullName, name, lastName, patronymic, phone, email, lastVisit, firstVisit, gender, discount, cardNumber, description, disableOnlineRegistration) => {
        axios
            .post(`${ClientsApi}create-client`,
                {
                    fullName: fullName,
                    name: name,
                    lastName: lastName,
                    patronymic: patronymic,
                    phone: phone,
                    email: email,
                    lastVisit: lastVisit,
                    firstVisit: firstVisit,
                    gender: gender,
                    discount: discount,
                    cardNumber: cardNumber,
                    description: description,
                    disableOnlineRegistration: disableOnlineRegistration,
                    companyId: companyData[0]?.attributes.companyId[0],
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCompanyClients(companyData[0]?.attributes.companyId[0])
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createClientCategory = async (name) => {
        axios
            .post(`${ClientsApi}create-client-category`,
                {
                    name: name,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientCategories()
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createClientDocument = async (clientId, documentTypeId, name) => {
        axios
            .post(`${ClientsApi}create-client-document`,
                {
                    clientId: clientId,
                    documentTypeId: documentTypeId,
                    name: name
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientDocuments(clientId)
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createClientEmail = async (clientId, email) => {
        axios
            .post(`${ClientsApi}create-client-email`,
                {
                    clientId: clientId,
                    email: email,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientEmails(clientId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createClientPhone = async (clientId, phone) => {
        axios
            .post(`${ClientsApi}create-client-phone`,
                {
                    clientId: clientId,
                    phone: phone,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientPhones(clientId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createClientType = async (name, isActive, serviceId) => {
        axios
            .post(`${ClientsApi}create-client-type`,
                {
                    companyId: companyData[0]?.attributes.companyId[0],
                    name: name,
                    isActive: isActive,
                    serviceId: serviceId
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientTypes(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createClientVisit = async (name, serviceName, clientId, clientVisitStatusId, paymentAmount, isPayed) => {
        axios
           .post(`${ClientsApi}create-client-visit`,
                {
                    name: name,
                    serviceName: serviceName,
                    clientId: clientId,
                    clientVisitStatusId: clientVisitStatusId,
                    paymentAmount: paymentAmount,
                    isPayed: isPayed
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
           .then((response) => {
               getClientVisits(clientId);
            })
           .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createClientVisitStatus = async (name) => {
        axios
            .post(`${ClientsApi}create-client-visit-status`,
                {
                    name: name,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientVisitStatuses();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createDocumentType = async (name) => {
        axios
            .post(`${ClientsApi}create-client-document-type`,
                {
                    name: name,
                    companyId: companyData[0]?.attributes.companyId[0],
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getDocumentTypes(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createLoyalityCard = async (cardNumber, loyalityCardTypeId, dateCreation, phone, balance, clientId, pinnedManagerId, secretQuestion, secretQuestionAnswer) => {
        axios
            .post(`${ClientsApi}create-loyality-card`,
                {
                    cardNumber: cardNumber,
                    loyalityCardTypeId: loyalityCardTypeId,
                    dateCreation: dateCreation,
                    phone: phone,
                    balance: balance,
                    clientId: clientId,
                    pinnedManagerId: pinnedManagerId,
                    secretQuestion: secretQuestion,
                    secretQuestionAnswer: secretQuestionAnswer,
                    companyId: companyData[0]?.attributes.companyId[0],
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getLoyalityCards(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createLoyalityCardType = async (name, daysBeforeBonusRemove, loyalityCardUsesRestrictionId, maxPercentOfPayDiscount, maxSumOfDiscound) => {
        axios
            .post(`${ClientsApi}create-loyality-card-type`,
                {
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getLoyalityCardTypes()
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createLoyalityCardUserRestriction = async (name) => {
        axios
            .post(`${ClientsApi}create-loyality-card-user-restriction`,
                {
                    name: name
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getLoyalityCardUsersRestrictions();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createDocumentTypeCharacteristic = async (documentTypeId, name) => {
        axios
            .post(`${ClientsApi}create-document-type-characteristic`,
                {
                    name: name,
                    documentTypeId: documentTypeId
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getDocumentTypeCharacteristcs(documentTypeId)
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const createClientDocumentValue = async (clientDocumentId, documentTypeCharacteristicId, value) => {
        axios
            .post(`${ClientsApi}create-client-document-value`,
                {
                    clientDocumentId: clientDocumentId,
                    documentTypeCharacteristicId: documentTypeCharacteristicId,
                    value: value
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientDocumentValues(clientDocumentId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteClientCardSettings = async (id) => {
        axios
            .delete(`${ClientsApi}delete-client-card-settings/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCardSettings(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteClient = async (id) => {
        axios
            .delete(`${ClientsApi}delete-client/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCompanyClients(companyData[0]?.attributes.companyId[0])
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteClientCategory = async (id) => {
        axios
            .delete(`${ClientsApi}delete-client-category/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientCategories()
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteClientDocument = async (id, clientId) => {
        axios
            .delete(`${ClientsApi}delete-client-document/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientDocuments(clientId)
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteClientEmail = async (id, clientId) => {
        axios
            .delete(`${ClientsApi}delete-client-email/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientEmails(clientId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteClientPhone = async (id, clientId) => {
        axios
            .delete(`${ClientsApi}delete-client-phone/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientPhones(clientId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteClientType = async (id) => {
        axios
            .delete(`${ClientsApi}delete-client-type/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientTypes(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteClientVisit = async (id, clientId) => {
        axios
            .delete(`${ClientsApi}delete-client-visit/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientVisits(clientId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteClientVisitStatus = async (id) => {
        axios
            .delete(`${ClientsApi}delete-client-visit-status/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientVisitStatuses();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteDocumentType = async (id, documentTypeId) => {
        axios
            .delete(`${ClientsApi}delete-client-document-type/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getDocumentTypeCharacteristcs(documentTypeId)
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteLoyalityCard = async (id) => {
        axios
            .delete(`${ClientsApi}delete-loyality-card/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getLoyalityCards();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteLoyalityCardType = async (id) => {
        axios
            .delete(`${ClientsApi}delete-loyality-card-type/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getLoyalityCardTypes();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteLoyalityCardUserRestriction = async (id) => {
        axios
            .delete(`${ClientsApi}delete-loyality-card-user-restriction/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getLoyalityCardUsersRestrictions()
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteClientDocumentValue = async (id, clientId) => {
        axios
            .delete(`${ClientsApi}delete-client-document-value/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientDocumentValues(clientId)
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const deleteDocumentTypeCharacteristic = async (id) => {
        axios
            .delete(`${ClientsApi}delete-document-type-characteristic/${id}`,
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getDocumentTypes(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateClientCardSettings = async (id, useSeparatedClientName, allowUseMultiplePhoneNumbers, allowUseMultipleEnails, allowUseDocuments) => {
        axios
            .put(`${ClientsApi}update-client-card-settings`,
                {
                    id: id,
                    companyId: companyData[0]?.attributes.companyId[0],
                    useSeparatedClientName: useSeparatedClientName,
                    allowUseMultiplePhoneNumbers: allowUseMultiplePhoneNumbers,
                    allowUseMultipleEnails: allowUseMultipleEnails,
                    allowUseDocuments: allowUseDocuments,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCardSettings(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateClient = async (id, fullName, name, lastName, patronymic, phone, email, gender, discount, cardNumber, description, disableOnlineRegistration) => {
        axios
            .put(`${ClientsApi}update-client`,
                {
                    id: id,
                    fullName: fullName,
                    name: name,
                    lastName: lastName,
                    patronymic: patronymic,
                    phone: phone,
                    email: email,
                    gender: gender,
                    discount: discount,
                    cardNumber: cardNumber,
                    description: description,
                    disableOnlineRegistration: disableOnlineRegistration,
                    companyId: companyData[0]?.attributes.companyId[0],
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getCompanyClients(companyData[0]?.attributes.companyId[0])
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateClientCategory = async (id, name) => {
        axios
            .put(`${ClientsApi}update-client-category`,
                {
                    id: id,
                    name: name,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientCategories()
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateClientDocument = async (id, clientId, documentTypeId, name) => {
        axios
            .put(`${ClientsApi}update-client-document`,
                {
                    id: id,
                    clientId: clientId,
                    documentTypeId: documentTypeId,
                    name: name
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientDocuments(clientId)
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateClientEmail = async (id, clientId, email) => {
        axios
            .put(`${ClientsApi}update-client-email`,
                {
                    id: id,
                    clientId: clientId,
                    email: email,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientEmails(clientId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateClientPhone = async (id, clientId, phone) => {
        axios
            .put(`${ClientsApi}update-client-phone`,
                {
                    id: id,
                    clientId: clientId,
                    phone: phone,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientPhones(clientId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateClientType = async (id, name, isActive, serviceId) => {
        axios
            .put(`${ClientsApi}update-client-type`,
                {
                    id: id,
                    companyId: companyData[0]?.attributes.companyId[0],
                    name: name,
                    isActive: isActive,
                    serviceId: serviceId
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientTypes(companyData[0]?.attributes.companyId[0]);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateClientVisit = async (id, name, serviceName, clientId, clientVisitStatusId, paymentAmount, isPayed) => {
        axios
            .put(`${ClientsApi}update-client-visit`,
                {
                    id: id,
                    name: name,
                    serviceName: serviceName,
                    clientId: clientId,
                    clientVisitStatusId: clientVisitStatusId,
                    paymentAmount: paymentAmount,
                    isPayed: isPayed
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientVisits(clientId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateClientVisitStatus = async (id, name) => {
        axios
            .put(`${ClientsApi}update-client-visit-status`,
                {
                    id: id,
                    name: name,
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientVisitStatuses();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateDocumentType = async (id, name, formJson) => {
        axios
            .put(`${ClientsApi}update-client-document-type`,
                {
                    id: id,
                    name: name,
                    companyId: companyData[0]?.attributes.companyId[0],
                    formJson: formJson
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getDocumentType(id);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateLoyalityCard = async (id, cardNumber, loyalityCardTypeId, dateCreation, phone, balance, clientId, pinnedManagerId, secretQuestion, secretQuestionAnswer) => {
        axios
            .put(`${ClientsApi}update-loyality-card`,
                {
                    id: id,
                    cardNumber: cardNumber,
                    loyalityCardTypeId: loyalityCardTypeId,
                    dateCreation: dateCreation,
                    phone: phone,
                    balance: balance,
                    clientId: clientId,
                    pinnedManagerId: pinnedManagerId,
                    secretQuestion: secretQuestion,
                    secretQuestionAnswer: secretQuestionAnswer,
                    companyId: companyData[0]?.attributes.companyId[0],
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getLoyalityCards();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateLoyalityCardType = async (id, name, daysBeforeBonusRemove, loyalityCardUsesRestrictionId, maxPercentOfPayDiscount, maxSumOfDiscound) => {
        axios
            .put(`${ClientsApi}update-loyality-card-type`,
                {
                    id: id,
                    name: name,
                    daysBeforeBonusRemove: daysBeforeBonusRemove,
                    loyalityCardUsesRestrictionId: loyalityCardUsesRestrictionId,
                    maxPercentOfPayDiscount: maxPercentOfPayDiscount,
                    maxSumOfDiscound: maxSumOfDiscound
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getLoyalityCardTypes();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateLoyalityCardUserRestriction = async (id, name) => {
        axios
            .put(`${ClientsApi}update-loyality-card-user-restriction`,
                {
                    id: id,
                    name: name
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getLoyalityCardUsersRestrictions();
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateDocumentTypeCharacteristic = async (id, documentTypeId, name) => {
        axios
            .put(`${ClientsApi}update-document-type-characteristic`,
                {
                    id: id,
                    documentTypeId: documentTypeId,
                    name: name
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getDocumentTypeCharacteristcs(documentTypeId)
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    const updateClientDocumentValue = async (id, clientDocumentId, documentTypeCharacteristicId, value) => {
        axios
            .put(`${ClientsApi}update-client-document-value`,
                {
                    id: id,
                    clientDocumentId: clientDocumentId,
                    documentTypeCharacteristicId: documentTypeCharacteristicId,
                    value: value
                },
                { headers:
                        {
                            Authorization: `Bearer ${keycloak.token}`,
                            CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                        } })
            .then((response) => {
                getClientDocumentValues(clientDocumentId);
            })
            .catch((error) => {
                console.error("Ошибка создания настроек карты:", error);
            });
    }

    return {
        cardSettings,
        clientCategories,
        client,
        companyClients,
        clientEmails,
        clientPhones,
        clientTypes,
        clientDocument,
        clientDocuments,
        clientType,
        clientVisits,
        clientVisitStatuses,
        documentType,
        documentTypes,
        loyalityCard,
        loyalityCards,
        loyalityCardTypes,
        loyalityCardUsersRestrictions,
        clientDocumentValues,
        documentTypeCharacteristics,

        getClient,
        getCardSettings,
        getClientCategories,
        getCompanyClients,
        getClientEmails,
        getClientPhones,
        getClientTypes,
        getClientDocument,
        getClientDocuments,
        getClientVisits,
        getClientVisitStatuses,
        getDocumentType,
        getDocumentTypes,
        getLoyalityCard,
        getLoyalityCards,
        getLoyalityCardTypes,
        getLoyalityCardUsersRestrictions,
        getClientType,
        getClientDocumentValues,
        getDocumentTypeCharacteristcs,

        createClient,
        createClientCardSettings,
        createClientEmail,
        createClientVisit,
        createClientVisitStatus,
        createDocumentType,
        createLoyalityCard,
        createLoyalityCardType,
        createLoyalityCardUserRestriction,
        createClientPhone,
        createClientType,
        createClientCategory,
        createClientDocument,
        createDocumentTypeCharacteristic,
        createClientDocumentValue,

        updateClient,
        updateClientCardSettings,
        updateClientEmail,
        updateClientVisit,
        updateClientVisitStatus,
        updateDocumentType,
        updateLoyalityCard,
        updateLoyalityCardType,
        updateLoyalityCardUserRestriction,
        updateClientPhone,
        updateClientType,
        updateClientCategory,
        updateClientDocument,
        updateDocumentTypeCharacteristic,
        updateClientDocumentValue,

        deleteClient,
        deleteClientCardSettings,
        deleteClientEmail,
        deleteClientVisit,
        deleteClientVisitStatus,
        deleteDocumentType,
        deleteLoyalityCard,
        deleteLoyalityCardType,
        deleteLoyalityCardUserRestriction,
        deleteClientPhone,
        deleteClientType,
        deleteClientCategory,
        deleteClientDocument,
        deleteDocumentTypeCharacteristic,
        deleteClientDocumentValue,

        modalCreateDocChar,
        changeModalCreateDocCharVisible,
    };
}

export default ClientsProvider;