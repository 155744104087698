import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { Label, Input, CardBody, Col, Media } from "reactstrap";

import FilterComponent from "./FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { Link } from "react-router-dom";
import {useDocumentManagementContext} from "../../../../_helper/DocumentManagement/DocumentManagementProvider";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {useUsersContext} from "../../../../_helper/Users/UsersProvider";

const TableDocuments = ({ documentId }) => {
	const [products, setProducts] = useState([]);
	const { companyData } = useCompanyContext();
	const { documentsData, getDocuments, getDocumentStatuses, documentStatuses } = useDocumentManagementContext();
	const { getUsersByIdArray, usersByIdArray } = useUsersContext();

	useEffect(() => {
		console.log(usersByIdArray);
		documentsData && getUsersByIdArray(documentsData.map((item) => (item.userId)));
		//console.log(usersByIdArray);
	}, [documentsData]);

	useEffect(() => {
		companyData && getDocuments(companyData[0]?.id);
		getDocumentStatuses();
	}, [companyData]);

	useEffect(() => {
		documentId && documentsData && setProducts(documentsData.filter((item) => item.typeId === documentId))
	}, [documentId]);

	const { keycloak } = useKeycloak();

	const [editModal, setEditModal] = useState(false);
	const [name, setName] = useState("");
	const [id, setId] = useState("");

	const [edit, setEdit] = useState(null);

	const [documentTable, setDocumentTable] = useState();

	const getDocumentAuthor = (userId) => {
		let author = usersByIdArray && usersByIdArray?.find((it) => it.id === userId);

		console.log(usersByIdArray);

		return author ? `${author.firstName} ${author.lastName}` : "";
	};

	const getDocumentStatus = (id) => {
		let status = documentStatuses && documentStatuses?.find((it) => it.id === id);

		return status ? `${status.label}` : "";
	}

	const toggle = (user) => {
		if (typeof user === "object" && user.hasOwnProperty("id")) {
			setName(user.label);
			setId(user.id);
			setEdit(true);
		} else {
			setName("");
			setId("");
			setEdit(false);
		}

		setEditModal(!editModal);
	};

	const columns = [
		{
			name: "Номер документа",
			selector: (row) => `${row.documentNumber}`,
			sortable: true,
		},
		{
			name: "Название документа",
			selector: (row) => `${row.documentTitle}`,
			sortable: true,
		},
		{
			name: "Автор",
			selector: (row) => `${getDocumentAuthor(row.userId)}`,
			sortable: true,
		},
		{
			name: "Статус",
			selector: (row) => `${getDocumentStatus(row.statusId)}`,
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<Link style={{ marginLeft: "5px" }} to={`${process.env.PUBLIC_URL}/counterparties/documents/edit-document?documentId=${row.id}?typeId=${row.typeId}`}>
							<Edit size={"18px"} />
						</Link>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	useEffect(() => {
		console.log(usersByIdArray);
	}, [usersByIdArray]);

	const deleteTask = (siteId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {});
	};

	const send = () => {
		const newUser = {};

		// axios
		// 	.post(`/api/clientsDemandsForm.json`, newUser)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success("Пользователь добавлен!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setEditModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
	};
	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = documentsData ? documentsData.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1) : [];

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent typeId={documentId} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);



	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				noDataComponent={"В данной таблице ещё нет записей."}
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={editModal} title={edit ? "Редактировать" : "Добавить"} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col sm="12" className="row col-sm-12 mb-3">
						<Label>Тип документа</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableDocuments;
