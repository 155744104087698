import React from 'react';
import './Stepper.css'; // Подключение стилей

const Stepper = ({ currentStep }) => {
    return (
        <div className="stepper">
            <div className={`step ${currentStep >= 0 ? 'active' : ''}`}>
                <div className="circle">
                    <span>Загрузка файла</span>
                </div>
            </div>
            <div className={`step ${currentStep >= 1 ? 'active' : ''}`}>
                <div className="circle">
                    <span>Сопоставление полей</span>
                </div>
            </div>
            <div className={`step ${currentStep >= 2 ? 'active' : ''}`}>
                <div className="circle">
                    <span>Завершение импорта</span>
                </div>
            </div>
        </div>
    );
};

export default Stepper;