import React, {Fragment} from "react";
import Carousel from "react-bootstrap/Carousel";
import {DropZone} from "@measured/puck";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";

export const Tabs = {
    label: "Вкладки",
    fields: {
        tabs: {
            label: "Список вкладок",
            type: "array",
            arrayFields: {
                tabName: {
                    label: "Название вкладки",
                    type: "text",
                }
            },
            getItemSummary: (_, id) => `Вкладка ${id + 1}`,
        }
    },
    defaultProps: {
        tabs: [{ label: "Вкладка 1" }, { label: "Вкладка 2" }],
    },
    render: ({ tabs }) => {
        let pillWithIconTab = '0';

        console.log(tabs);

        return (
            <Fragment>
                <Nav className="nav-primary" tabs>
                {tabs.map((tab, i) => (
                    <NavItem>
                        <NavLink href="#" className={pillWithIconTab === `${i}` ? 'active' : ''} onClick={() => pillWithIconTab=`${i}`} style={{ padding: '14px 18px' }}>
                            {tab.tabName}
                        </NavLink>
                    </NavItem>
                ))}
                </Nav>
                <TabContent activeTab={pillWithIconTab}>
                    {tabs.map((tab, i) => (
                            <TabPane className="fade show" tabId={`${i}`}>
                                <div
                                    key={i}
                                    className={"w-100 p-4"}
                                >
                                    <DropZone zone={`item-${i}`}/>
                                </div>
                            </TabPane>
                    ))}
                </TabContent>
            </Fragment>
        );
    },
}