import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';

import OverallBalance from './OverallBalance';
import GreetingCard from './GreetingCard';
import WidgetsWrapper from './WidgetsWraper';
import RecentOrders from './RecentOrders';
import ActivityCard from './ActivityCard';
import RecentSales from './RecentSales';
import TimelineCard from './TimelineCard';
import PreAccountCard from './PreAccountCard';
import TotalUserAndFollower from './TotalUserAndFollower';
import PaperNote from './PaperNote';

const Dashboard = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="По умолчанию" parent="Панель приборов" title="По умолчанию" />
			<Container fluid={true}>
				<OverallBalance />
				<RecentOrders />
				<Row className="widget-grid">
					<GreetingCard />
					<WidgetsWrapper />

					<ActivityCard />
					<RecentSales />
					<TimelineCard />
					<PreAccountCard />
					<TotalUserAndFollower />
					<PaperNote />
				</Row>
			</Container>
		</Fragment>
	);
};

export default Dashboard;
