import {Btn} from "../../../../AbstractElements";
import {migrate, Puck, Render, resolveAllData, transformProps, usePuck} from "@measured/puck";
import React, {Fragment, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {PuckComponents} from "./Components/PuckComponents";
import {CardBody, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import CommonModal from "../../../UiKits/Modals/common/modal";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";

const Editor = ({initialData}) => {
    const {updateDocumentType, documentType} = useClientsContext();

    const [exportData, setExportData] = useState("");
    const [previewModal, setPreviewModal] = useState(false);


    const saveType = () =>{
        updateDocumentType(
            documentType.id,
            documentType.name,
            exportData)
            .then(() => {
                toast.success('Тип документа обновлён!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    }

    const saveState = (data) => {
        setExportData(JSON.stringify(data));
    };

    const config = {
        categories: {
            layout: {
                title: "Разметка",
                components: ["Columns", "Flex", "VerticalSpace"]
            },
            elements: {
                title: "Элементы",
                components: ["RegularText", "BoldText", "Input"]
            }
        },
        components: PuckComponents,
    };

    const [puckEditor, setPuckEditor] = useState(null);

    const togglePreview = () => {
        setPreviewModal(!previewModal);
    }

    useEffect(() => {
        setPuckEditor(initPuck());
    }, [initialData]);

    const initPuck = () => {
        return (
            <Puck config={config} data={{
                content: initialData?.content && initialData?.content,
                zones: initialData?.zones && initialData?.zones
            }} onChange={saveState}>
                <div
                    className="row"
                >
                    <div className="col-4">
                        <div>
                            {/* Render the component list */}
                            <Puck.Components/>
                        </div>
                        <div>
                            <Puck.Fields/>
                        </div>
                    </div>

                    <div className="col-8 h-auto">
                        <Puck.Preview/>
                    </div>
                </div>
            </Puck>
        )
    }

    return (
        <Fragment>
            <Btn attrBtn={{ color: 'primary', className: "mx-2", onClick: saveType }}>Сохранить</Btn>
            {puckEditor}
            <CommonModal isOpen={previewModal} title={'Предпросмотр карточки'} togglerClose={togglePreview}>
                <CardBody style={{padding: '10px', paddingLeft: '15px'}}>
                    <Render config={config} data={initialData} />
                </CardBody>
            </CommonModal>
        </Fragment>
    )
}

export default Editor;