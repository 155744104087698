import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import axios from "axios";
import Popover from "@mui/material/Popover";
import SweetAlert from "sweetalert2";
import { Link } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";
import { Briefcase, Edit, MoreHorizontal, Plus, Trash2 } from "react-feather";

import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import TableViewsDocuments from "./TableViewsDocuments";
import {useDocumentManagementContext} from "../../../../_helper/DocumentManagement/DocumentManagementProvider";
import {useUsersContext} from "../../../../_helper/Users/UsersProvider";

const KindDocuments = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { documentView, getDocumentViews, documentTemplates, getDocumentTemplates, deleteDocumentTemplate } = useDocumentManagementContext();

	const [data, setData] = useState([]);
	const [templates, setTemplates] = useState([]);

	useEffect(() => {
		/*axios
			.get(`/api/document-templates.json`)
			.then((response) => {
				setTemplates(response.data.templates);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});*/
		/*axios
			.get(`/api/views-documents.json`)
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});*/
		getDocumentTemplates();
		getDocumentViews();
	}, [companyData]);

	const [IconWithTab, setIconWithTab] = useState("1");

	//popover
	const [anchorEl, setAnchorEl] = useState(null);
	const [curEl, setCurEl] = useState({});

	const handleClick = (el, event) => {
		setAnchorEl(event);
		setCurEl(el);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	// удаление шаблона
	const deleteItem = (Id) => {
		setAnchorEl(null);
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот шаблон!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				deleteDocumentTemplate(Id);
			}
			// if (result.value) {
			// 	setLoading(true);
			// 	axios
			// 		.delete(`${AllProjectApi}delete/${Id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 		.then((response) => {
			// 			console.log(response.data);
			// 			axios
			// 				.get(`${AllProjectApi}get`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 				.then((response) => {
			// 					setData(response.data.projects);
			// 					setLoading(false);
			// 				})
			// 				.catch((error) => {
			// 					setLoading(false);
			// 					console.error('Ошибка получения данных:', error);
			// 				});
			// 		})
			// 		.catch((error) => {
			// 			setLoading(false);
			// 			toast.error('Произошла ошибка при удалении проекта', {
			// 				position: toast.POSITION.TOP_RIGHT,
			// 			});
			// 			console.error('Произошла ошибка при удалении проекта:', error);
			// 		});
			// }
		});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Справочник видов документов" parent="Документоборот" title="Справочник видов документов" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Nav tabs className="border-tab nav-primary">
									<NavItem>
										<NavLink className={`${IconWithTab === "1" ? "active" : ""} f-16`} onClick={() => setIconWithTab("1")} style={{ cursor: "pointer" }}>
											Справочник видов документов
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={`${IconWithTab === "2" ? "active" : ""} f-16`} onClick={() => setIconWithTab("2")} style={{ cursor: "pointer" }}>
											Шаблоны документов
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent activeTab={IconWithTab}>
									<TabPane className="fade show p-t-10" tabId="1">
										<TableViewsDocuments products={documentView ?? []} />
									</TabPane>
									<TabPane className="p-t-10 p-r-10 p-l-10" tabId="2">
										<Link to={`${process.env.PUBLIC_URL}/counterparties/documents/add-template`}>
											<Btn attrBtn={{ color: "primary" }}>
												<Plus size={"20px"} style={{ margin: "0px 5px -5px 0px" }} />
												Создать шаблон
											</Btn>
										</Link>
										<Row className="mt-4">
											{documentTemplates &&
												documentTemplates.map((item) => (
													<Col xs="6" sm="6" md="3" xl="2" className="d-flex c-pointer">
														<div className="b-r-8 b-light col-md-10 col-12 d-flex" style={{ flexDirection: "column", alignItems: "center" }}>
															<Col
																sm="11"
																xs="11"
																className="pt-4 pb-4 pe-3 ps-3 b-r-8 mt-3 font-primary text-center p-relative"
																style={{ backgroundColor: "#7366ff20", marginLeft: "12px", marginRight: "12px" }}
															>
																<MoreHorizontal
																	size={"20px"}
																	style={{ top: "6px", right: "12px", position: "absolute" }}
																	onClick={(e) => handleClick(item, e.currentTarget)}
																	aria-describedby={id}
																/>
																<Briefcase size={"34px"} />
															</Col>
															<div className="mt-2 mb-1">{item.name}</div>
														</div>
													</Col>
												))}
											<Popover
												id={id}
												open={open}
												anchorEl={anchorEl}
												onClose={handleClose}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "center",
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: "left",
												}}
											>
												<div className="font-primary" style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
													<div className="p-2 b-b-light" style={{ cursor: "pointer" }}>
														<Link to={`${process.env.PUBLIC_URL}/counterparties/documents/add-template?templateId=${curEl.id}`}>
															<Edit size={"18px"} style={{ margin: " 0 6px -3px 0" }} />
															Редактировать
														</Link>
													</div>
													<div className="p-2 " style={{ cursor: "pointer" }} onClick={() => deleteItem(curEl.id)}>
														<Trash2 size={"18px"} style={{ margin: " 0 6px -3px 0" }} />
														Удалить
													</div>
												</div>
											</Popover>
										</Row>
									</TabPane>
								</TabContent>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default KindDocuments;
