import React, { Fragment, useState, useEffect } from 'react';
import { Label, Col } from 'reactstrap';
import axios from 'axios';
import { H5, Btn } from '../../../../AbstractElements';
import Select from 'react-select';

const SendMessage = ({ clientId }) => {
	const [clientData, setClientData] = useState([]);
	useEffect(() => {
		axios
			.get(`/api/clientsMessage.json`)
			.then((response) => {
				const foundObject = response.data.find((item) => item.clientId === Number(clientId));
				setClientData(foundObject.Messages);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [method, setMethod] = useState('');
	const [text, setText] = useState('');

	//не работает
	const send = () => {
		const newMessage = {
			id: clientData.length + 1,
			Method: method,
			Text: text,
		};
		axios
			.post(`/api/clientsMessage.json`, newMessage)
			.then((response) => {
				console.log('Сообщение успешно добавлено:', response.data);
				setClientData([...clientData, newMessage]);
			})
			.catch((error) => {
				console.error('Ошибка добавления сообщения:', error);
			});
	};

	return (
		<Fragment>
			<H5>Отправить сообщение</H5>
			<div style={{ marginTop: '30px' }}>
				<Col style={{ marginTop: '20px' }}>
					<Label className="col-form-label">Метод отправки</Label>
					<Select
						placeholder="Выберите..."
						options={[
							{ value: 'allChanels', label: 'Отправить по всем каналам' },
							{ value: 'sms', label: 'Отправить SMS' },
							{ value: 'push', label: 'Отправить PUSH' },
							{ value: 'email', label: 'Отправить Email' },
						]}
						className="js-example-basic-single col-sm-12"
						onChange={(selectedOption) => setMethod(selectedOption.value)}
					/>
				</Col>
				<Col style={{ marginTop: '20px' }}>
					<Label>Введите текст сообщения</Label>
					<textarea className="form-control" rows={'3'} placeholder="" onChange={(e) => setText(e.target.value)} />
				</Col>
				<div className="modal-footer" style={{ paddingTop: '40px' }}>
					<Btn attrBtn={{ color: 'primary', onClick: send }}>Отправить</Btn>
				</div>
			</div>
		</Fragment>
	);
};

export default SendMessage;
