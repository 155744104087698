import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Label, Input, CardBody, Col, InputGroup, InputGroupText } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

const TableDiscounts = ({ filteredProducts }) => {
	const [types, setTypes] = useState([]);

	useEffect(() => {
		axios
			.get('/api/typesCards.json')
			.then((response) => {
				setTypes(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}
	const columns = [
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: 'Тип',
			selector: (row) => `${row.discount_type}`,
			sortable: true,
		},
		{
			name: 'Добавлена',
			selector: (row) => `${formatDate(row.date)}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {});
	};

	const [edit, setEdit] = useState(null);

	const send = () => {
		if (selectType === '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			if (!Id) {
				setId(uuid());
			}
			const newEvent = {
				id: Id,
				type: selectType.value,
			};
			console.log(newEvent);
			// axios
			// 	.post(`/api/clientsDemandsForm.json`, newEvent)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Календарь добавлен!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			// 	});
		}
	};
	const [name, setName] = useState('');
	const [selectType, setSelectType] = useState({ label: null, value: null });
	const [discountType, setDiscountType] = useState({ label: null, value: null });
	const [discountMethod, setDiscountMethod] = useState({ label: null, value: null });
	const [maxFixedDiscount, setMaxFixedDiscount] = useState('');
	const [cards, setCards] = useState([]);
	const [Id, setId] = useState('');
	const [date, setDate] = useState('');

	const [modal, setModal] = useState(false);

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setId(ObjectId.id);
			setSelectType({ label: ObjectId.action_type, value: ObjectId.action_type });
			setDiscountType({ label: ObjectId.discount_type, value: ObjectId.discount_type });
			setDiscountMethod({ label: ObjectId.discount_method, value: ObjectId.discount_method });
			setMaxFixedDiscount(ObjectId.discount_amount);
			setName(ObjectId.name);
			setDate(ObjectId.date);
			setCards(ObjectId.applicable_card_types);
			setEdit(true);
		} else {
			setSelectType({ label: null, value: null });
			setDiscountType({ label: null, value: null });
			setDiscountMethod({ label: null, value: null });
			setId('');
			setMaxFixedDiscount('');
			setDate(new Date());
			setName('');
			setCards([]);
			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Название акции</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Тип акции</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={selectType}
								options={[
									{ label: 'Скидки', value: 'Скидки' },
									{ label: 'Бонусы', value: 'Бонусы' },
								]}
								onChange={(option) => setSelectType(option)}
							/>
						</Col>
					</Col>
					{selectType.value === 'Скидки' ? (
						<Col className="row col-sm-12 mb-2">
							<Label className="col-sm-9 col-form-label">Виды скидок</Label>
							<Col sm="11">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={discountType}
									options={[
										{ label: 'Фиксированная скидка', value: 'Фиксированная скидка' },
										{ label: 'Накопительная скидка от суммы', value: 'Накопительная скидка от суммы' },
										{ label: 'Накопительная скидка от количества визитов', value: 'Накопительная скидка от количества визитов' },
										{ label: 'Скидка при условии', value: 'Скидка при условии' },
									]}
									onChange={(option) => setDiscountType(option)}
								/>
							</Col>
						</Col>
					) : (
						<Col className="row col-sm-12 mb-2">
							<Label className="col-sm-9 col-form-label">Виды бонусов</Label>
							<Col sm="11">
								<Select
									type="tel"
									placeholder="Выберите..."
									value={discountType}
									options={[
										{ label: 'Фиксированные бонусы', value: 'Фиксированные бонусы' },
										{ label: 'Накопительные бонусы от суммы', value: 'Накопительные бонусы от суммы' },
										{ label: 'Накопительные бонусы от количества визитов', value: 'Накопительные бонусы от количества визитов' },
										{ label: 'Накопительные бонусы в рамках визита', value: 'Накопительные бонусы в рамках визита' },
									]}
									onChange={(option) => setDiscountType(option)}
								/>
							</Col>
						</Col>
					)}

					<Col className="row col-sm-12 mb-2">
						{selectType.value === 'Скидки' ? <Label className="col-sm-9 col-form-label">Тип скидки</Label> : <Label className="col-sm-9 col-form-label">Тип бонусов</Label>}
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={discountMethod}
								options={[
									{ label: 'Процент от суммы, %', value: 'Процент от суммы' },
									{ label: 'Фиксированная сумма', value: 'Фиксированная сумма' },
								]}
								onChange={(option) => setDiscountMethod(option)}
							/>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Размер скидки</Label>
						<Col sm="11">
							{discountMethod.value === 'Фиксированная сумма' ? (
								<InputGroup>
									<Input className="form-control" type="tel" value={maxFixedDiscount} onChange={(e) => setMaxFixedDiscount(e.target.value)} />
									<InputGroupText>{'₽'}</InputGroupText>
								</InputGroup>
							) : (
								<InputGroup>
									<Input className="form-control" type="tel" value={maxFixedDiscount} onChange={(e) => setMaxFixedDiscount(e.target.value)} />
									<InputGroupText>{'%'}</InputGroupText>
								</InputGroup>
							)}
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">По каким картам будет действовать акция</Label>
						<Col sm="11">
							{types.map((item) => (
								<div className="checkbox checkbox-primary" key={item.id}>
									<Input id={`checkbox-primary-1-${item.id}`} type="checkbox" checked={cards.includes(item.name)} />
									<Label for={`checkbox-primary-1-${item.id}`}>{item.name}</Label>
								</div>
							))}
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Дата создания</Label>
						<Col sm="11">
							<Input className="form-control" disabled type="tel" value={formatDate(date)} onChange={(e) => setDate(e.target.value)} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableDiscounts;
