import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';

import FilterComponent from '../../../Goods/PurchaseHistory/FilterComponent';

const TableFinancialTransactions = ({ filteredProducts }) => {
	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}
	const columns = [
		{
			name: 'Дата',
			selector: (row) => `${formatDate(row.date)}`,
			sortable: true,
		},
		{
			name: <div>Получатель/плательщик</div>,
			selector: (row) => (
				<div>
					{row.recipientType === 'Клиент' ? (
						<div style={{ display: 'flex' }}>
							<i className="fa fa-users me-2"></i>
							<div>{row.recipientInitial}</div>
						</div>
					) : row.recipientType === 'Контрагент' ? (
						<div style={{ display: 'flex' }}>
							<i className="fa fa-building-o me-2"></i>
							<div>{row.recipientInitial}</div>
						</div>
					) : row.recipientType === 'Сотрудник' ? (
						<div style={{ display: 'flex' }}>
							<i className="fa fa-user me-2"></i>
							<div>{row.recipientInitial}</div>
						</div>
					) : (
						''
					)}
				</div>
			),
			width: '15%',
			sortable: true,
		},
		{
			name: 'Назначение',
			selector: (row) => <div style={{ textWrap: 'initial' }}>{row.paymentType}</div>,
			sortable: true,
		},
		{
			name: 'Касса',
			selector: (row) => `${row.cashregister}`,
			sortable: true,
		},
		{
			name: <div>Комментарий</div>,
			selector: (row) => <div style={{ textWrap: 'initial' }}>{row.comment}</div>,
			sortable: true,
		},
		{
			name: 'Автор',
			selector: (row) => ``,
			sortable: true,
		},
		{
			name: 'Сумма',
			selector: (row) => `${row.amount} ₽`,
			sortable: true,
		},
		{
			name: <div>Остаток в кассе</div>,
			selector: (row) => ``,
			sortable: true,
		},
		{
			name: 'Услуга/Товар',
			selector: (row) => ``,
			sortable: true,
		},
		{
			name: 'Визит',
			selector: (row) => ``,
			sortable: true,
		},
	];

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return <>{filteredProducts && <DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />}</>;
};

export default TableFinancialTransactions;
