import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { Label, Input, Media } from "reactstrap";

import FilterComponent from "./FilterComponent";
import { ServicesApi } from "../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {concatResults} from "../../../concatResults";
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";

const TableService = ({ filteredProducts }) => {
	const [data, setData] = useState(filteredProducts);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const fetchData = async () => {
		try {
			const response = await axios.get(`${ServicesApi}getServices`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			setData(concatResults(response).services);
		} catch (error) {
			console.error("Ошибка при выполнении запроса:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const columns = [
		{
			name: "Название",
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: "Онлайн-запись",
			selector: (row) => (
				<Media>
					<Media body className="text-end icon-state">
						<Label className="switch">
							<Input type="checkbox" id={`checkbox-${row.id}`} checked={row.isOnlineRegistration} />
							<span className="switch-state"></span>
						</Label>
					</Media>
				</Media>
			),
			sortable: true,
		},
		{
			name: "Цена",
			selector: (row) => `${row.price} ₽`,
			sortable: true,
		},
		{
			name: "Длительность",
			selector: (row) => (
				<div>
					{row.durationHour !== 0 ? `${row.durationHour} ч` : ""} {row.durationMinute !== 0 ? `${row.durationMinute} мин` : ""}
				</div>
			),
			sortable: true,
		},
		{
			name: "Категория",
			selector: (row) => `${row.category.categoryName}`,
			sortable: true,
		},
		{
			name: "Сотрудники",
			selector: (row) => <div style={{ color: "#9d9d9d" }}>{row.employees ? `${row.employees.length} сотрудника` : "сотрудники не назначены"}</div>,
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/service-app/edit-service?serviceId=${row.id}`}>
							<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }}>
								<Edit size={"18px"} />
							</div>
						</Link>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteService(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteService = (idService) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${ServicesApi}deleteService`, {
						data: {
							ServiceId: idService,
						},
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
					})
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
						fetchData();
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении:", error);
					});
			}
		});
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				noDataComponent={"В данной таблице ещё нет записей."}
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
		</>
	);
};

export default TableService;
