import CompanyContext from ".";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { CompaniesApi, TwinAuthApi } from "../../api";
import { useKeycloak } from "@react-keycloak/web";
import {toast} from "react-toastify";

const CompanyProvider = ({ children, props }) => {
	const context = useCreateCompanyContext(props);

	return <CompanyContext.Provider value={context}>{children}</CompanyContext.Provider>;
};

export function useCompanyContext() {
	const context = useContext(CompanyContext);
	if (!context) throw new Error("Use app context within provider!");
	return context;
}

export const useCreateCompanyContext = function (props) {
	const [companyData, setCompanyData] = useState([]);
	const [companyDataCopy, setCompanyDataCopy] = useState([]);
	const [companyProfileSettingsData, setCompanyProfileSettingsData] = useState(null);
	const [companyLogo, setCompanyLogo] = useState(null);
	const [companyTheme, setCompanyTheme] = useState(null);
	const { keycloak } = useKeycloak();
	const [userCompanies, setUserCompanies] = useState(null);
	const [allCompanies, setAllCompanies] = useState([]);
	const [companySubGroups, setCompanySubGroup] = useState([]);
	const [groupUsers, setGroupUsers] = useState([]);
	const [companyGroupUsers, setCompanyGroupUsers] = useState([]);
	const [companyServices, setCompanyServices] = useState([]);

	/*useEffect (() => {
		console.log(keycloak.tokenParsed);
		keycloak.authenticated && axios
			.get(`${TwinAuthApi}get-user-groups-companies/${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				response && setUserCompanies(JSON.parse(response.data));
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, [keycloak.tokenParsed])*

	/*useEffect(() => {
		if (userCompanies && companyData.length === 0) {
			if (companyDataId === null) {
				userCompanies && selectCompany(userCompanies[0]);
			}
			else {
				let selectedCompanies = JSON.parse(companyDataId);
				if (Array.isArray(selectedCompanies)) {
					selectedCompanies.forEach((item) => {
						let comp = userCompanies.find((company) => company.id === item.groupId);
						comp && selectCompany(comp).then();
					})
				}
			}

			//selectCompany(companies[0]);
		}
	}, [companyDataId, userCompanies]);*/

	useEffect(() => {
		reloadCompanyData();
	}, [userCompanies])

	const reloadCompanyData = () => {
		setCompanyDataCopy(companyData);
	}

	useEffect(() => {
		setCompanyData(companyDataCopy);
	}, [companyDataCopy])

	useEffect(() => {
		console.log(companyData);
	}, [companyData]);

	const getAllCompanies = async () => {
		await axios
            .get(`${CompaniesApi}get`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
            .then((response) => {
				console.log(response.data);
                setAllCompanies(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
	}

	const getCompanySubgroups = async () => {
		//console.log(companyData);
		await axios
			.get(`${CompaniesApi}get-company-custom-subgroups/${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				console.log(response.data);
				setCompanySubGroup(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}

	const getGroupUsers = async (groupId) => {
		await axios
			.get(`${TwinAuthApi}get-group-users/${groupId}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				//console.log(response.data);
				setGroupUsers(JSON.parse(response.data));
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}

	const getCompanyGroupUsers = async () => {
		if (companyData[0]?.id) {
			await axios
				.get(`${TwinAuthApi}get-group-users/${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
				.then((response) => {
					console.log(response.data);
					setCompanyGroupUsers(JSON.parse(response.data));
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
	}

	const getCompanyServices = async () => {
		await axios
            .get(`${CompaniesApi}get-company-services?companyId=${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
            .then((response) => {
				console.log(response.data);
				setCompanyServices(response.data.proxyServicesCompanies);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
	}

	const addUserToGroup = async (groupId, userId) => {
		await axios
			.post(`${TwinAuthApi}add-user-to-group-by-id?groupId=${groupId}&userId=${userId}`, {}, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				//console.log(response.data);
				getGroupUsers(groupId);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}

	const removeUserFromGroup = async (groupId, userId) => {
		await axios
			.delete(`${TwinAuthApi}remove-user-from-group-by-id?groupId=${groupId}&userId=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				//console.log(response.data);
				getGroupUsers(groupId);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}

	const getCompanyProfileSettingsData = async (companyId) => {
		await axios
			.get(`${CompaniesApi}get-company-profile-settings/${companyId}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				setCompanyProfileSettingsData(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const getCompanyTheme = async (companyId) => {
		companyId && await axios
			.get(`${CompaniesApi}get-company-theme?companyId=${companyId}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				setCompanyTheme(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const getCompanyLogo = async (companyId) => {
		await axios
			.get(`${CompaniesApi}get-company-logo?companyId=${companyId}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				setCompanyLogo(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const createCompanyTheme = async (companyId, theme) => {
		await axios
			.post(`${CompaniesApi}create-company-theme`, {companyId: companyId, theme: theme, userId: keycloak.tokenParsed.sub}, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				getCompanyTheme(companyId);
				toast.success("Тема успешно задана", {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const createCompanyLogo = async (companyId, image) => {
		await axios
			.post(`${CompaniesApi}create-company-logo`, {companyId: companyId, image: image, userId: keycloak.tokenParsed.sub}, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				getCompanyLogo(companyId);
				toast.success("Логотип успешно задан", {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const updateCompanyTheme = async (id, companyId, theme) => {
		await axios
			.put(`${CompaniesApi}update-company-theme`, {id: id, companyId: companyId, theme: theme, userId: keycloak.tokenParsed.sub}, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				getCompanyTheme(companyId);
				toast.success("Тема успешно задана", {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const updateCompanyLogo = async (id, companyId, image) => {
		await axios
			.put(`${CompaniesApi}update-company-logo`, {id: id, companyId: companyId, image: image, userId: keycloak.tokenParsed.sub}, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				getCompanyLogo(companyId);
				toast.success("Логотип успешно задан", {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const deleteCompanyLogo = async (id) => {
		await axios
			.delete(`${CompaniesApi}delete-company-logo/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				toast.success("Логотип успешно удалён", {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const deleteCompanyTheme = async (id) => {
		await axios
			.delete(`${CompaniesApi}delete-company-theme/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				toast.success("Тема успешно удалена", {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const createCompanyProfileSettings = async (companyId, image, isBanner, isPhone, isDescription, isInterests, isAddress, isPostcode, isCountry, isCity, isTelegram, isVk) => {
		await axios
			.post(
				`${CompaniesApi}create-company-profile-settings`,
				{
					companyId: companyId,
					image: image,
					isBanner: isBanner,
					isPhone: isPhone,
					isDescription: isDescription,
					isInterests: isInterests,
					isAddress: isAddress,
					isPostcode: isPostcode,
					isCountry: isCountry,
					isCity: isCity,
					isTelegram: isTelegram,
					isVk: isVk,
				},
				{ headers: { Authorization: `Bearer ${keycloak.token}` } }
			)
			.then((response) => {

			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const updateCompanyProfileSettings = async (id, companyId, image, isBanner, isPhone, isDescription, isInterests, isAddress, isPostcode, isCountry, isCity, isTelegram, isVk) => {
		await axios
			.put(
				`${CompaniesApi}update-company-profile-settings`,
				{
					id,
					companyId: companyId,
					image: image,
					isBanner: isBanner,
					isPhone: isPhone,
					isDescription: isDescription,
					isInterests: isInterests,
					isAddress: isAddress,
					isPostcode: isPostcode,
					isCountry: isCountry,
					isCity: isCity,
					isTelegram: isTelegram,
					isVk: isVk,
				},
				{ headers: { Authorization: `Bearer ${keycloak.token}` } }
			)
			.then((response) => {
				
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const deleteCompanyProfileSettingsData = async (companyId) => {
		await axios
			.delete(`${CompaniesApi}delete-company-profile-settings/${companyId}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				response && setCompanyProfileSettingsData(null);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const deleteGroupById = async (groupId) => {
		await axios
			.delete(`${TwinAuthApi}delete-company-profile-settings/${groupId}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				response && setCompanyProfileSettingsData(null);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}

	const selectCompany = async (company) => {
		if (companyData && Array.isArray(companyData) && companyData.find((companies) => (companies.id === company.id))) {

			let companyDataCopy = companyData.filter(el => el.id !== company.id);
			setCompanyData(companyDataCopy);
			sessionStorage.setItem('companyData', JSON.stringify(companyDataCopy.map((item) => ({ groupId: item.id, companyId: item.attributes.companyId[0]  }))));
		}
		else {
			let companyDataCopy = companyData;
			companyDataCopy.push(company);
			setCompanyData(companyDataCopy);
			console.log(companyDataCopy);
			sessionStorage.setItem('companyData', JSON.stringify(companyDataCopy.map((item) => ({ groupId: item.id, companyId: item.attributes.companyId[0]  }))));
		}
	};

	const createCustomSubgroup = async (groupId, groupName) => {
		console.log(groupId);
		console.log(groupName);
		await axios
			.post(`${TwinAuthApi}create-custom-subgroup-by-id?groupId=${groupId}&groupChildrenName=${groupName}`, {}, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				getCompanySubgroups()
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}

	return {
		companyData,
		companyProfileSettingsData,
		setCompanyData,
		setCompanyDataCopy,
		companyTheme,
		companyLogo,
		userCompanies,
		allCompanies,
		companySubGroups,
		groupUsers,
		companyGroupUsers,
		companyServices,
		setUserCompanies,

		getCompanyProfileSettingsData,
		getCompanyLogo,
		getCompanyTheme,
		getAllCompanies,
		getCompanySubgroups,
		getGroupUsers,
		getCompanyGroupUsers,
		getCompanyServices,

		createCompanyProfileSettings,
		createCompanyLogo,
		createCompanyTheme,
		createCustomSubgroup,
		addUserToGroup,

		deleteCompanyProfileSettingsData,
		deleteCompanyLogo,
		deleteCompanyTheme,
		deleteGroupById,
		removeUserFromGroup,

		updateCompanyProfileSettings,
		updateCompanyLogo,
		updateCompanyTheme,

		reloadCompanyData,
		selectCompany
	};
};

export default CompanyProvider;
