import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import CommonModal from '../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import FilterComponent from '../../Application/Categories/FilterComponent';
import { toast } from 'react-toastify';
import { ConsecutivePagesApi } from '../../../api/index';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { concatResults } from '../../../concatResults';
import { useCompanyContext } from '../../../_helper/Company/CompanyProvider';

const DataTableComponent = () => {
	const [data, setData] = useState([]);
	const {keycloak} = useKeycloak();
	const {companyData} = useCompanyContext();
	const [modal, setModal] = useState(false);
	const [MenuId, setMenuId] = useState('');
	const [RoleName, setRoleName] = useState('');
	const [ContentName, setContentName] = useState('');

	const [number, setNumber] = useState('');

	useEffect(() => {
		axios
			.get(`${ConsecutivePagesApi}get-menus`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				setData(concatResults(response).menus);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		data && setFilteredItems(data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
	}, [data, setData])

	const toggle = (ObjectId) => {
		console.log(ObjectId);
		if (typeof ObjectId === 'object' && ObjectId.hasOwnProperty('id')) {
			setMenuId(ObjectId.id);
			setRoleName(ObjectId.roleName);
			setContentName(ObjectId.contentText);
			setEdit(true);
		} else {
			setRoleName('');
			setContentName('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const deleteRow = (menuId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			axios
				.delete(`${ConsecutivePagesApi}delete-menu/${menuId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then(() => {
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		});
	};

	const [edit, setEdit] = useState(null);

	const handleAddRow = () => {
		if(edit === false) {
			setModal(!modal);
			const newRow = {
				roleName: RoleName,
				contentText: ContentName
			};
			axios
				.post(`${ConsecutivePagesApi}create-menu`, newRow, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} } )
				.then((response) => {
					toast.success('Запись добавлена!', {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
				.catch((error) => {
					toast.error('Произошла ошибка при добавлении.', {
						position: toast.POSITION.TOP_RIGHT,
					});
					console.error(error);
				});
		}
		else {
			setModal(!modal);
			const editRow = {
				id: MenuId,
				roleName: RoleName,
				contentText: ContentName
			};
			console.log(editRow);
			axios
				.put(`${ConsecutivePagesApi}update-menu`, editRow, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} } )
				.then((response) => {
					toast.success('Изменения сохранены!', {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
				.catch((error) => {
					toast.error('Произошла ошибка при изменении.', {
						position: toast.POSITION.TOP_RIGHT,
					});
					console.error(error);
				});
		}

		//setData([...data, newRow]);
	};

	const tableColumns = [
		{
			name: 'Номер',
			selector: (row) => `${row.id}`,
			sortable: true,
			center: false,
		},
		{
			name: 'Роль в компании',
			selector: (row) => `${row.roleName}`,
			sortable: true,
			center: true,
		},
		{
			name: 'Контент',
			selector: (row) => <Link to={`${process.env.PUBLIC_URL}/editor-menu-master-enter?menuId=${row.id}`}>{row.contentText}</Link>,
			sortable: true,
			center: false,
			width: '40%',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteRow(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const [filteredItems, setFilteredItems] = useState(data);


	useEffect(() => {
		data && setFilteredItems(data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
	}, [filterText, setFilterText]);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<Fragment>
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
				<CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
					<Form className="form theme-form">
						<Row>
							<FormGroup className="row col-sm-12" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-12 col-form-label" htmlFor="exampleFormControlInput1">
									Роль в компании
								</Label>
								<Col sm="12">
									<Input className="form-control" type="text" placeholder="Роль в компании" required="true" value={RoleName} onChange={(e) => setRoleName(e.target.value)} />
								</Col>
							</FormGroup>
						</Row>
						<Row>
							<FormGroup className="row col-sm-12" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-12 col-form-label">Контент</Label>
								<Col sm="12">
									<Input className="form-control" type="tel" placeholder="Контент" value={ContentName} onChange={(e) => setContentName(e.target.value)} />
								</Col>
							</FormGroup>
						</Row>
					</Form>
				</CardBody>
			</CommonModal>
			<DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} noDataComponent={'В данной таблице ещё нет записей.'} pagination highlightOnHover pointerOnHover />
		</Fragment>
	);
};

export default DataTableComponent;
