import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container, Col, Label } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableCounterparties from './TableCounterparties';
import { useKeycloak } from "@react-keycloak/web";
import { TwinAuthApi, CounterpartiesApi } from "../../../../api";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';
import {useCounterpartiesContext} from "../../../../_helper/Counterparties/CounterpartiesProvider";

const ListCounterparties = () => {
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [allData, setAllData] = useState([]);
    //const [roles, setRoles] = useState([]);
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const [companyId, setCompanyId] = useState("");
    const currentUserId = `${keycloak.tokenParsed.sub}`;
    const [selectedRoles, setSelectedRoles] = useState([]);
    const {getCounterpartiesList, counterpartyList, getCounterpartyRoles, roles} = useCounterpartiesContext();
    const [counterpartyHeader, setCounterpartyHeader] = useState('Контрагент')
    const [counterpartyText, setCounterpartyText] = useState('контрагент')

    useEffect(() => {
        companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyHeader('Партнёр')
        companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyText('партнёр')
    }, [companyData]);

    /*useEffect(() => {
        const fetchUserCompanies = async () => {
            try {
                const response = await axios.get(`${TwinAuthApi}get-user-groups-companies/${currentUserId}`, {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                    }
                });
                if (response.data.length > 0) {
                    const companies = JSON.parse(response.data);
                    setCompanyId(companies[0].id);
                }
            } catch (error) {
                console.error("Ошибка получения данных:", error);
            }
        };

        fetchUserCompanies();
    }, [currentUserId, keycloak.token]);*/

    useEffect(() => {
        const fetchCounterparties = async () => {
            if (companyData[0]?.attributes.companyId[0]) {
                getCounterpartiesList()
            }
        };

        fetchCounterparties();
    }, [companyData, keycloak.token]);

    useEffect(() => {
        counterpartyList && setAllData(counterpartyList)
        counterpartyList && (selectedRoles ? setFilteredProducts(counterpartyList) : handleRoleChange());
    }, [counterpartyList])

    useEffect(() => {
        const fetchCounterpartiesRoles = async () => {
            if (companyData[0]?.attributes.companyId[0]) {
                /*axios.get(`${CounterpartiesApi}getRoles?companyId=${companyData[0]?.attributes?.companyId[0]}`, {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
                        //CompanyId: companyId
                    }
                }).then((response) => {
                    setRoles(concatResults(response).roles.map(role => ({
                        value: role.id,
                        label: role.name
                    })) ?? []);
                })
                    .catch((error) => {
                        console.error("Ошибка получения данных:", error);
                    });*/
                getCounterpartyRoles();
            }
        };

        fetchCounterpartiesRoles();
    }, [companyData, keycloak.token]);


    const handleRoleChange = (selectedRoles) => {
        setSelectedRoles(selectedRoles);
        console.log("SELECTEDROLE-------------------")
        console.log(selectedRoles)
        if (selectedRoles.length === 0) {
            setFilteredProducts(allData);
        } else {
            const selectedRoleIds = selectedRoles.map(role => role.value);
            const filtered = allData.filter(product => selectedRoleIds.includes(product.typeId));
            console.log("ALLPRODUCTS")
            console.log(filteredProducts)
            console.log(filtered)
            setFilteredProducts(filtered);
        }
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle={`Список ${counterpartyText}ов`} parent={`${counterpartyHeader}ы`} title={`Список ${counterpartyText}ов`} />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Col style={{ padding: '4px 16px 4px 24px' }}>
                            <Label className="col-form-label">Выберите тип {counterpartyText}а</Label>
                            <Select
                                placeholder="Выберите..."
                                isMulti
                                value={selectedRoles}
                                options={roles ? roles?.map(role => ({
                                    value: role.id,
                                    label: role.name
                                })) : []}
                                className="js-example-basic-single col-sm-12"
                                onChange={handleRoleChange}
                            />
                        </Col>
                        <TableCounterparties
                            data={filteredProducts ?? []}
                            setData={counterpartyList ?? []}
                            roles={roles ? roles?.map(role => ({
                                value: role.id,
                                label: role.name
                            })) : []}
                            companyId={companyData[0]?.attributes.companyId[0]} />
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};
export default ListCounterparties;
