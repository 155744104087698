import React, { Fragment, useEffect, useState } from "react";
import { InputGroup, InputGroupText, Input, Row, Collapse } from "reactstrap";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import uuid from "react-uuid";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { Label, Col } from "reactstrap";
import { toast } from "react-toastify";

import { Plus, Trash2 } from "react-feather";
import { Btn } from "../../../../../AbstractElements";

import { EducationApi } from "../../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const Modules = ({ courseId }) => {
	const [data, setData] = useState([]);
	const [deadlineTypes, setDeadlineTypes] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const fetchData = async () => {
		try {
			const modulesResponse = await axios.get(`${EducationApi}get-course-modules/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const lessonDeadlineTypesResponse = await axios.get(`${EducationApi}get-lesson-deadline-types`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });

			const selectOptions = concatResults(lessonDeadlineTypesResponse).lessonDeadlineTypes.map((option) => ({
				label: option.value,
				value: option.id,
			}));
			setDeadlineTypes(selectOptions);
			console.log(selectOptions);

			const newData = concatResults(modulesResponse).modules.map((updatedItem) => {
				const deadlineType = selectOptions.find((type) => type.value === updatedItem.deadline.value);

				const module = {
					id: updatedItem.id,
					name: updatedItem.name,
					timeLimit: {
						value: updatedItem.deadline.id,
						label: updatedItem.deadline.value,
					},
					executionTime: updatedItem.periodTime,
					periodStart: updatedItem.periodStart,
					periodEnd: updatedItem.periodEnd,
					buyingSeparately: updatedItem.isBuySeparate,
					cost: updatedItem.cost,
					showNumberClasses: updatedItem.showCountLessons,
					showCompletedClasses: updatedItem.showCountCompleteLessons,
					keyModule: updatedItem.keyModule,
					lessons: updatedItem.lessons,
					description: updatedItem.description,
					isNew: false,
				};

				return module;
			});

			setData(newData);
		} catch (error) {
			console.error("Ошибка получения данных:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const addItem = () => {
		const item = {
			id: uuid(),
			name: "",
			timeLimit: { label: "Без ограничения по времени", value: "Без ограничения по времени" },
			executionTime: 0,
			periodStart: null,
			periodEnd: null,
			buyingSeparately: false,
			cost: 0,
			showNumberClasses: true,
			showCompletedClasses: true,
			lessons: [],
			isNew: true,
		};
		setData([...data, item]);
		console.log(data);
	};

	const [isOpen, setIsOpen] = useState(1);

	const [deadlineType, setDeadlineType] = useState({});

	const handleSelectChange = (selectedOption) => {
		setDeadlineType(selectedOption);
	};

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [executionTime, setExecutionTime] = useState(0);
	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());
	const [buyingSeparately, setBuyingSeparately] = useState(false);
	const [showNumberClasses, setShowNumberClasses] = useState(false);
	const [showCompletedClasses, setShowCompletedClasses] = useState(false);
	const [keyModule, setKeyModule] = useState(false);
	const [cost, setCost] = useState(0);

	const toggleClientCard = (item) => {
		if (isOpen === item.id) {
			setIsOpen(null);
		} else {
			setIsOpen(item.id);
		}
		setDeadlineType(item.timeLimit);
		if (item.periodStart) {
			setPeriodStart(new Date(item.periodStart));
		}
		if (item.periodEnd) {
			setPeriodEnd(new Date(item.periodEnd));
		}
		setName(item.name);
		setDescription(item.description);
		setExecutionTime(item.executionTime);
		setBuyingSeparately(item.buyingSeparately);
		setShowNumberClasses(item.showNumberClasses);
		setShowCompletedClasses(item.showCompletedClasses);
		setKeyModule(item.keyModule);
		setCost(item.cost);
	};

	const deleteItem = (id) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${EducationApi}delete-course-module/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении объекта:", error);
					});
				fetchData();
			}
		});
	};

	const columns = [
		{
			name: "№",
			selector: (row) => `${row.order + 1}`,
			sortable: true,
			width: "8%",
		},
		{
			name: "Название",
			selector: (row) => `${row.name}`,
			sortable: true,
			width: "15%",
		},
		{
			name: "Описание",
			selector: (row) => `${row.description}`,
			sortable: true,
			width: "42%",
		},
		{
			name: "Свойства",
			selector: (row) => (
				<div>
					{row.HasAssignment && (
						<span className={`badge badge-light-info rounded-pill mt-2 ms-2`} style={{ height: "min-content" }}>
							Есть задание <i className="fa fa-book"></i>
						</span>
					)}
					{row.KeyLesson && (
						<span className={`badge badge-light-secondary rounded-pill mt-2 ms-2`} style={{ height: "min-content" }}>
							Ключевое занятие <i className="fa fa-minus-circle"></i>
						</span>
					)}
				</div>
			),
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<Tooltip title="Удалить" arrow placement="top">
					<div className="font-primary" style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => deleteRow(row.id)}>
						<Trash2 size={"18px"} />
					</div>
				</Tooltip>
			),

			width: "8%",
		},
	];

	const deleteRow = (courseId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then(function (isConfirm) {
			
		});
	};

	const handleSave = (itemId) => {
		const itemIndex = data.findIndex((item) => item.id === itemId);

		const updatedItem = {
			...data[itemIndex],
			name: name,
			timeLimit: deadlineType,
			executionTime: executionTime,
			periodStart: periodStart,
			periodEnd: periodEnd,
			buyingSeparately: buyingSeparately,
			cost: cost,
			showNumberClasses: showNumberClasses,
			showCompletedClasses: showCompletedClasses,
			description: description,
			keyModule: keyModule,
			//lessons: [],
		};

		const updatedData = [...data];
		updatedData[itemIndex] = updatedItem;
		setData(updatedData);
		console.log(updatedData);

		const module = {
			id: updatedItem.id,
			courseId: courseId,
			name: updatedItem.name,
			description: updatedItem.description,
			periodTime: updatedItem.executionTime,
			periodStart: updatedItem.periodStart,
			periodEnd: updatedItem.periodEnd,
			isBuySeparate: updatedItem.buyingSeparately,
			cost: updatedItem.cost,
			showCountLessons: updatedItem.showNumberClasses,
			showCountCompleteLessons: updatedItem.showCompletedClasses,
			keyModule: updatedItem.keyModule,
			deadlineId: deadlineType.value,
			lessonsId: [],
		};

		if (updatedItem.isNew) {
			axios
				.post(`${EducationApi}create-course-module`, module, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					console.log("Модуль успешно создан:", concatResults(response));
					toast.success("Модуль успешно создан!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					fetchData();
				})
				.catch((error) => {
					console.error("Ошибка при создании модуля:", error);
				});
		} else {
			axios
				.put(`${EducationApi}update-course-module`, module, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					console.log("Модуль успешно обновлён:", concatResults(response));
					toast.success("Модуль успешно обновлён!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					fetchData();
				})
				.catch((error) => {
					console.error("Ошибка при обновлении модуля:", error);
				});
		}
	};

	const getLessonForm = (count) => {
		if (count === 1) {
			return "занятие";
		} else if (count >= 2 && count <= 4) {
			return "занятия";
		} else {
			return "занятий";
		}
	};
	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	return (
		<Fragment>
			<Row className="b-r-8 badge-light font-dark m-0" style={{ padding: "15px 0" }}>
				<Col md="3">Название модуля</Col>
				<Col md="3">Описание модуля</Col>
				<Col md="2">Занятия</Col>
				<Col md="3">Период действия</Col>
				<Col md="1"></Col>
			</Row>
			{data &&
				data.map((item) => (
					<Col key={item.id}>
						<Row className=" f-14 p-t-15 p-b-15 b-r-3 b-b-light" onClick={() => toggleClientCard(item)} style={{ cursor: "pointer", backgroundColor: "#7366ff08", margin: "0.25rem 0 0" }}>
							<Col md="3">
								{isOpen === item.id ? <i className="fa fa-angle-down me-2"></i> : <i className="fa fa-angle-right me-2"></i>}
								{item.name ? (
									item.name
								) : (
									<>
										<span className={`badge badge-light-secondary rounded-pill me-2`} style={{ height: "min-content" }}>
											<i className="fa fa-exclamation"></i>{" "}
										</span>
										<span style={{ color: "#9d9d9d" }}>Введите название модуля</span>
									</>
								)}
							</Col>
							<Col md="3">{item.description}</Col>
							<Col md="2">
								<span className={`badge badge-light-primary rounded-pill me-2`} style={{ height: "min-content" }}>
									{item.lessons.length} {getLessonForm(item.lessons.length)}
								</span>
							</Col>
							<Col md="3">
								{item.timeLimit && (
									<>
										{item.timeLimit.label === "Задать срок" && (
											<span>
												с&nbsp;{formatDate(item.periodStart)} по&nbsp;{formatDate(item.periodEnd)}
											</span>
										)}
										{item.timeLimit.label === "Без ограничения по времени" && "Без ограничения по времени"}
										{item.timeLimit.label === "Задать время выполнения" && <span>{item.executionTime} ч.</span>}
									</>
								)}
							</Col>
							<Col md="1" className="">
								<Row style={{ justifyContent: "center" }}>
									<Tooltip title="Удалить элемент" arrow placement="top">
										<a className="col-sm-3" onClick={() => deleteItem(item.id)} style={{ width: "32px", height: "32px" }}>
											<Trash2 size={"20px"} />
										</a>
									</Tooltip>
								</Row>
							</Col>
						</Row>

						<Collapse isOpen={isOpen === item.id}>
							<Col md="12" className="p-3">
								<Col className="mb-4">
									<Row className="mt-2">
										<Col className="col-sm-8 mb-2">
											<Col sm="12">
												<Input placeholder="Название модуля" className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
											</Col>
										</Col>
										<div className="col-sm-4 mb-2">
											<Select
												placeholder="Выберите..."
												options={deadlineTypes}
												className="js-example-basic-single col-sm-12"
												value={deadlineType}
												onChange={handleSelectChange}
											/>
										</div>
									</Row>
									<Input type="textarea" className="form-control" rows="3" placeholder="Описание модуля" value={description} onChange={(e) => setDescription(e.target.value)} />
									{deadlineType.label === "Задать срок" && (
										<Row className="m-l-5 mt-4">
											<Col xl="6" sm="9">
												<div style={{ display: "flex", alignItems: "flex-end" }}>
													<Label className="m-r-10">С</Label>
													<div className="input-group">
														<DatePicker
															locale={ru}
															dateFormat="dd.MM.yyyy"
															className="form-control digits"
															selected={periodStart}
															onChange={(selectedOption) => setPeriodStart(selectedOption)}
														/>
													</div>
												</div>
											</Col>
											<Col xl="6" sm="9">
												<div style={{ display: "flex", alignItems: "flex-end" }}>
													<Label className="m-r-10">По</Label>
													<div className="input-group">
														<DatePicker
															locale={ru}
															dateFormat="dd.MM.yyyy"
															className="form-control digits"
															selected={periodEnd}
															onChange={(selectedOption) => setPeriodEnd(selectedOption)}
														/>
													</div>
												</div>
											</Col>
										</Row>
									)}
									{deadlineType.label === "Задать время выполнения" && (
										<Col xl="6" sm="9" className=" mt-4">
											<Label className="m-r-10 col-md-12">На прохождение модуля:</Label>
											<InputGroup>
												<Input className="form-control" type="text" placeholder="" aria-label="" value={executionTime} onChange={(e) => setExecutionTime(e.target.value)} />
												<InputGroupText>{"ч."}</InputGroupText>
											</InputGroup>
										</Col>
									)}
									<Col className="mt-3">
										<Row style={{ alignItems: "baseline" }}>
											<div className="checkbox checkbox-primary ms-2 mt-0 col-sm-4">
												<Input id="checkbox-primary-buyingSeparately" type="checkbox" checked={buyingSeparately} onChange={(e) => setBuyingSeparately(e.target.checked)} />
												<Label for="checkbox-primary-buyingSeparately">Разрешить покупать отдельно от курса</Label>
											</div>
											{buyingSeparately && (
												<Col sm="4">
													<InputGroup>
														<Input className="form-control" type="text" placeholder="" aria-label="" value={cost} onChange={(e) => setCost(e.target.value)} />
														<InputGroupText>{"₽"}</InputGroupText>
													</InputGroup>
												</Col>
											)}
										</Row>
										<div className="checkbox checkbox-primary ms-2 mt-0">
											<Input id="checkbox-primary-showNumberClasses" type="checkbox" checked={showNumberClasses} onChange={(e) => setShowNumberClasses(e.target.checked)} />
											<Label for="checkbox-primary-showNumberClasses">Отображать количество занятий</Label>
										</div>
										<div className="checkbox checkbox-primary ms-2 mt-0">
											<Input
												id="checkbox-primary-showCompletedClasses"
												type="checkbox"
												checked={showCompletedClasses}
												onChange={(e) => setShowCompletedClasses(e.target.checked)}
											/>
											<Label for="checkbox-primary-showCompletedClasses">Отображать пройденные занятия</Label>
										</div>
										<div className="checkbox checkbox-primary ms-2 mt-0">
											<Input id="checkbox-primary-1" type="checkbox" checked={keyModule} onChange={(e) => setKeyModule(e.target.checked)} />
											<Label for="checkbox-primary-1">
												Ключевой модуль
												<Tooltip title="Следующий модуль в курсе не будет доступен, пока не выполнится этот" arrow placement="top">
													<i className="fa fa-info-circle" style={{ color: "#8989a8", fontSize: "18px", marginLeft: "7px", cursor: "pointer" }}></i>
												</Tooltip>
											</Label>
										</div>
									</Col>
								</Col>
								<Btn attrBtn={{ color: "info", className: "mb-2" }}>
									<Plus size={"18px"} style={{ margin: "0 4px -3px 0" }} />
									Добавить занятие
								</Btn>
								<DataTable
									paginationPerPage={5}
									paginationRowsPerPageOptions={[5, 10, 15, 20]}
									columns={columns}
									data={item.lessons}
									striped
									pagination
									noDataComponent={"В данной таблице ещё нет записей."}
									paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
								/>
								<Col md="12" className="modal-footer">
									<Btn attrBtn={{ className: "mt-4", onClick: () => handleSave(item.id) }}>Сохранить</Btn>
								</Col>
							</Col>
						</Collapse>
					</Col>
				))}
			<Btn attrBtn={{ onClick: addItem, color: "light", className: "mt-3" }}>
				<Plus size={"18px"} style={{ margin: "0 4px -3px 0" }} />
				Добавить модуль
			</Btn>
		</Fragment>
	);
};
export default Modules;
