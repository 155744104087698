import React, { Fragment, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';

import Email from './Email';
import MailSidebar from './MailSidebar';

const MailInboxContain = () => {

	const [selectedEmailId, setSelectedEmailId] = useState(null);

	const handleEmailSelection = (emailId) => {
		setSelectedEmailId(emailId);
	};


	return (
		<Fragment>
			<Breadcrumbs mainTitle="Моя почта" parent="Почта" title="Моя почта" />
			<Container fluid={true}>
				<div className="email-wrap">
					<Row>
						<Email selectedEmailId={selectedEmailId} onEmailSelect={handleEmailSelection} />
						<MailSidebar selectedEmailId={selectedEmailId} />
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};
export default MailInboxContain;
