import React from "react";
import { Input, FormGroup, Form, Col } from "reactstrap";

const FilterComponent = ({ filterText, onFilter }) => (
	<>
		<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
			<Col md="12">
				<div className="feature-products">
					<div className="product-search">
						<Form>
							<FormGroup className="m-0 form-group search-product">
								<Input className="form-control" type="text" placeholder="Поиск.." value={filterText} onChange={onFilter} style={{ border: "1px solid #e0e0e0b3", height: "40px" }} />
								<i className="fa fa-search" style={{ top: "13px" }}></i>
							</FormGroup>
						</Form>
					</div>
				</div>
			</Col>
		</div>
	</>
);

export default FilterComponent;
