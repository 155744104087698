import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';
import Select from 'react-select';
import { H3 } from '../../../../AbstractElements';
import { ReminderApi } from '../../../../api';
import {concatResults} from "../../../../concatResults";

const DataTableTelegramBots = (eventTypes) => {
    const { companyData } = useCompanyContext();
    const { keycloak } = useKeycloak();

    const [tgBotLogin, setTgBotLogin] = useState('');
    const [tgToken, setTgToken] = useState('');
    const [eventTypeId, setEventTypeId] = useState('');
    const [optionsTypes, setOptionsTypes] = useState();

    const [companyTg, setCompanyTg] = useState();

    const [modal, setModal] = useState(false);

    const toggle = () => {
        setTgBotLogin('');
        setTgToken('');
        setEventTypeId('');
        setModal(!modal);
    }

    useEffect(() => {
        if (Array.isArray(eventTypes.eventTypes)) {
            setOptionsTypes(eventTypes.eventTypes.map((item) => ({
                value: item.id,
                label: item.name
            })));
        }
    }, [eventTypes])

    useEffect(() => {
        companyData &&
            axios
                .get(`${ReminderApi}get-company-telegram-list/${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                .then((response) => {
                    setCompanyTg(concatResults(response).telegrams);
                    console.log(concatResults(response));
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });
    }, [modal, companyData]);

    const handleAddTgRow = () => {
        companyData &&
            axios
                .post(`${ReminderApi}create-company-telegram`, {
                    companyId: companyData[0]?.id,
                    eventTypeId: eventTypeId,
                    telegramBotKey: tgToken,
                    telegramBotName: tgBotLogin,
                }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                .then((response) => {
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });
    }

    const deleteRow = (comTgId) => {

    }

    const getEventType = (eventId) => {
		return eventTypes.eventTypes.find(e => e.id === eventId).name
	}

    const tableColumns = [
		{
			name: 'Имя бота',
			selector: (row) => `${row.telegramBotName}`,
			sortable: true,
			center: true,
		},
        {
			name: 'Тип события',
			selector: (row) => `${getEventType(row.eventTypeId)}`,
			sortable: true,
			center: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteRow(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

    return (
        <Fragment>
            <Col>
                <H3>Telegram {companyTg ? <i style={{ color: '#54ba4a' }} className="icofont icofont-ui-check"></i> : <i style={{ color: '#fc4438' }} className="icofont icofont-ui-block"></i>}</H3>
                <Button color='primary' onClick={toggle}>{companyTg ? "Изменить" : "Подключить"}</Button>
            </Col>
            <CommonModal isOpen={modal} title={companyTg ? 'Изменить Telegram бота' : 'Привязать Telegram бота'} toggler={toggle} togglerClose={toggle}>
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label">Токен бота Telegram</Label>
                                <Col sm="11">
                                    <Input className="form-control" value={tgToken} placeholder="Текст" onChange={(e) => setTgToken(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label">Логин бота Telegram</Label>
                                <Col sm="11">
                                    <Input className="form-control" value={tgBotLogin} placeholder="Текст" onChange={(e) => setTgBotLogin(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Тип события
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={optionsTypes} onChange={(e) => setEventTypeId(e.value)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row " style={{ flexDirection: 'column' }}>
                                <Button className='col-sm-4 ms-auto me-5' color='primary' onClick={ handleAddTgRow }>Добавить</Button>
                            </FormGroup>
                        </Row>
                    </Form>
                    <DataTable 
                        data={companyTg} 
                        columns={tableColumns} 
                        striped={true} 
                        center={true} 
                        noDataComponent={'В данной таблице ещё нет записей.'} />
                </CardBody>
            </CommonModal>
        </Fragment>
    );
}

export default DataTableTelegramBots;