import React from "react";
import { ButtonGroup } from "reactstrap";
import { Clipboard, Play, FileText, ShoppingBag, Tool } from "react-feather";
import Tooltip from "@mui/material/Tooltip";

import { Btn } from "../../../../../AbstractElements";

const tabs = ["HTML", "CSS", "JS"];

export const Tabs = ({ mode, setMode, runCode, paste = undefined, toggle, toggleForm, toggleWidget, toggleCover, toggleNews, toggleCRM }) => {
	const changeMode = ({ target: { textContent } }) => {
		setMode(textContent.toLowerCase());
	};

	return (
		<div className="tabs mb-2 d-flex" style={{ justifyContent: "space-between" }}>
			<ButtonGroup>
				{tabs.map((item, i) => (
					<Btn
						attrBtn={{
							color: "light",
							active: item.toLowerCase() === mode,
							onClick: changeMode,
							key: i,
						}}
					>
						{item}
					</Btn>
				))}
			</ButtonGroup>
			<div style={{ flex: "1" }}>
				{paste && (
					<Btn attrBtn={{ color: "info", onClick: paste, className: "ms-3" }}>
						<Clipboard size={"18px"} style={{ marginBottom: "-3px" }} /> Вставить меню
					</Btn>
				)}
			</div>
			<div className="d-flex" style={{ alignItems: "center" }}>
				<Tooltip title="Добавить пакетное предложение" arrow>
					<button className="add-btn me-2" style={{ border: "none", padding: "2px 3px", borderRadius: "4px" }} onClick={toggle}>
						<ShoppingBag size={"18px"} style={{ margin: "4px 4px 0" }} />
					</button>
				</Tooltip>
				<Tooltip title="Добавить форму заявки" arrow>
					<button className="add-btn me-2" style={{ border: "none", padding: "2px 3px", borderRadius: "4px" }} onClick={toggleForm}>
						<FileText size={"18px"} style={{ margin: "4px 4px 0" }} />
					</button>
				</Tooltip>
				<Tooltip title="Добавить виджет" arrow>
					<button className="add-btn me-2" style={{ border: "none", padding: "2px 3px", borderRadius: "4px" }} onClick={toggleWidget}>
						<Tool size={"18px"} style={{ margin: "4px 4px 0" }} />
					</button>
				</Tooltip>
				<Tooltip title="Добавить обложку" arrow>
					<button className="add-btn me-2" style={{ border: "none", padding: "5.5px 7px", borderRadius: "4px" }} onClick={toggleCover}>
						Обложка
					</button>
				</Tooltip>
				<Tooltip title="Добавить новости" arrow>
					<button className="add-btn me-2" style={{ border: "none", padding: "5.5px 7px", borderRadius: "4px" }} onClick={toggleNews}>
						Новости
					</button>
				</Tooltip>
				<Tooltip title="Добавить cущность CRM" arrow>
					<button className="add-btn me-2" style={{ border: "none", padding: "5.5px 7px", borderRadius: "4px" }} onClick={toggleCRM}>
						Сущность CRM
					</button>
				</Tooltip>
			</div>
			<Btn attrBtn={{ color: "success", onClick: runCode }}>
				<Play size={"18px"} style={{ marginBottom: "-3px" }} /> Применить
			</Btn>
		</div>
	);
};
