import React, { Fragment, useEffect } from "react";

const TokenAccept = () => {
	useEffect(() => {
		window.YaSendSuggestToken(`${process.env.PUBLIC_URL}/app/corporate-mail-api/Yandex360`, {
			flag: true,
		});
	}, []);

	return <Fragment></Fragment>;
};
export default TokenAccept;
