import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import FilterComponent from '../../FilterComponent';
import Select from 'react-select';
import {useModulesContext} from "../../../../../_helper/Modules/ModulesProvider";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const DataTableGroupUsers = (groupId) => {
    const { keycloak } = useKeycloak();
    const { groupUsers, getCompanyGroupUsers, companyGroupUsers, getGroupUsers, addUserToGroup, removeUserFromGroup } = useCompanyContext();

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState(groupUsers);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [companyUsersOptions, setCompanyUsersOptions] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');

    useEffect(() => {
        groupUsers && setFilteredItems(groupUsers.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, groupUsers]);

    useEffect(() => {
        companyGroupUsers && setCompanyUsersOptions(companyGroupUsers.map((item) => ({value: item.id, label: `${item.firstName} ${item.lastName}`})));
    }, [companyGroupUsers]);

    useEffect(() => {
        getCompanyGroupUsers();
        groupId && getGroupUsers(groupId.groupId);
    }, [keycloak.tokenParsed]);

    const toggle = () => {
        //setGroupName('');

        setModal(!modal);
        setSelectedUserId('');
    };

    const handleAddRow = (userId) => {
        if (userId !== '') {
            setModal(!modal);
            addUserToGroup(groupId.groupId, selectedUserId);
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const deleteRow = (userId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'Пользователь будет удалён из данной группы',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(() => {
            removeUserFromGroup(groupId.groupId, userId);
        });
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const tableColumns = [
        {
            name: 'Имя',
            selector: (row) => `${row.firstName} ${row.lastName}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Email',
            selector: (row) => `${row.email}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Должность',
            selector: (row) => `${row.description}`,
            sortable: true,
            center: true,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{display: 'flex'}}>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'}/>
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление новой группы компании'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Пользователь
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={companyUsersOptions} onChange={(e) => setSelectedUserId(e.value)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTableGroupUsers;
