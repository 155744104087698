import React, { useMemo, useState, useEffect } from "react";
import { Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Label, Input, CardBody, Col, Media, InputGroup, InputGroupText } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";

import FilterComponent from "./FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TableModules = ({ filteredProducts, setFilteredProducts }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const columns = [
		{
			name: "Название",
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: "Описание",
			selector: (row) => `${row.description}`,
			sortable: true,
		},
		{
			name: "Стоимость",
			selector: (row) => `${row.price} ₽`,
			sortable: true,
		},
		{
			name: "Активность",
			selector: (row) => (
				<Media>
					<Media body className="text-end icon-state">
						<Label className="switch">
							<Input type="checkbox" id={`checkbox-${row.id}`} checked={row.isActive} onChange={() => updActivity(row)} />
							<span className="switch-state"></span>
						</Label>
					</Media>
				</Media>
			),
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	// put для обновления активности модуля
	const updActivity = (item) => {
		const updUser = {
			...item,
			isActive: !item.isActive,
		};
		// axios
		// 	.put(`/api/clientsDemandsForm.json`, updUser)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success("Доступ добавлен!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		const rowIndex = filteredProducts.findIndex((row) => row.id === item.id);
		const newData = [...filteredProducts];
		newData[rowIndex] = {
			...newData[rowIndex],
			isActive: !newData[rowIndex].isActive,
		};
		setFilteredProducts(newData);
		// 	})
		// 	.catch((error) => {
		// 		toast.error("Ошибка при добавлении доступа", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		console.error(error);
		// 	});
	};

	const [modal, setModal] = useState(false);

	const toggle = (item) => {
		setModal(!modal);
		setName(item.name);
		setPrice(item.price);
		setDescription(item.description);
		setisActive(item.isActive);
	};

	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [description, setDescription] = useState("");
	const [price, setPrice] = useState(0);
	const [isActive, setisActive] = useState(true);

	// put для обновления информации о модуле
	const editModule = () => {
		const updmodule = {
			id: id,
			name: name,
			description: description,
			price: price,
			isActive: isActive,
		};
		// axios
		// 	.put(`${NewsApi}updateNews`, updmodule, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success("Новость изменена!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error("Ошибка получения данных:", error);
		// 	});
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				striped
				noDataComponent={"В данной таблице ещё нет записей."}
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={modal} title={"Редактировать"} toggler={editModule} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={description} onChange={(e) => setDescription(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Стоимость</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
								<InputGroupText>{"₽"}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3 mt-4">
						<Media>
							<Media body className={"text-start icon-state "} style={{ flexGrow: "0" }}>
								<Label className="switch">
									<Input type="checkbox" checked={isActive} onChange={(e) => setisActive(e.target.checked)} />
									<span className="switch-state"></span>
								</Label>
							</Media>
							<Label className="col-form-label ms-3">Активность</Label>
						</Media>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableModules;
