// LeftPanel.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Trash2, FilePlus } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import { Row, Col, Label, Input, CardBody } from 'reactstrap';

import ElementInput from './Elements/ElementInput';
import ElementTel from './Elements/ElementTel';
import { Btn } from '../../../../AbstractElements';
import CommonModal from '../../../UiKits/Modals/common/modal';
import ElementTextarea from './Elements/ElementTextarea';
import ElementCheckbox from './Elements/ElementCheckbox';
import ElementText from './Elements/ElementText';
import ElementDate from './Elements/ElementDate';
import ElementTime from './Elements/ElementTime';
import ElementSelect from './Elements/ElementSelect';
import ElementEmail from './Elements/ElementEmail';
import uuid from 'react-uuid';
import ElementProductTable from './Elements/ElementProductTable';
import ElementPrepayment from './Elements/ElementPrepayment';
import ElementDeliveryInfo from './Elements/ElementDeliveryInfo';
import {Puck} from "@measured/puck";

const LeftPanel = ({ demandForm, onDrop, onRemove, isOverLeftPanel }) => {
	const handleDrop = (e) => {
		e.preventDefault();
		setElementType(e.dataTransfer.getData('text'));
		console.log(e.dataTransfer.getData('text'));
		setModalAdd(true);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	// <h2>Форма</h2>

	const [elementName, setElementName] = useState('');
	const [elementId, setElementId] = useState('');
	const [elementType, setElementType] = useState('');
	const [modalAdd, setModalAdd] = useState(false);

	const handleModalSubmit = () => {
		setModalAdd(false);

		console.log(selectedProducts);

		const defaultValues = {
			input: 'Ввод текста',
			tel: 'Телефон',
			textarea: 'Большое окно для ввода текста',
			checkbox: 'Отметить галочкой',
			text: 'Добавить текст',
			date: 'Выбрать дату',
			time: 'Выбрать время',
			select: 'Выпадающий список',
			email: 'Email',
			prepayment: 'Оплатить онлайн',
		};

		if (!elementName) {
			setElementName(defaultValues[elementType] || '');
		}

		const item = {
			id: uuid(),
			name: elementName || defaultValues[elementType] || selectedProducts.map((item) => item.option + ' - ' + item.value + '<br/>').join('') || '',
			elementId: elementId,
			type: elementType,
			order: demandForm.parameters.length + 1,
		};

		console.log(item);
		onDrop(item);

		setElementName(undefined);
	};

	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get('/api/deliveryParameters.json')
			.then((response) => {
				setFilteredProducts(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const [selectedProducts, setSelectedProducts] = useState([]);

	const handleCheckboxChange = (item) => {
		const isSelected = selectedProducts.some((selectedItem) => selectedItem.id === item.id);

		if (isSelected) {
			setSelectedProducts(selectedProducts.filter((selectedItem) => selectedItem.id !== item.id));
		} else {
			setSelectedProducts([...selectedProducts, item]);
		}
	};

	const ElementComponent = ({ key, index, content, type, onRemove, additionalProps }) => (
		<Row style={{ alignItems: 'center' }}>
			{createElementByType(type, { key, content, ...additionalProps })}
			<Tooltip title="Удалить элемент" arrow placement="top">
				<a onClick={() => onRemove(index)} style={{ width: '32px', height: '32px', cursor: 'pointer' }}>
					<Trash2 />
				</a>
			</Tooltip>
		</Row>
	);

	const createElementByType = (type, props) => {
		switch (type) {
			case 'input':
				return <ElementInput {...props} />;
			case 'tel':
				return <ElementTel {...props} />;
			case 'textarea':
				return <ElementTextarea {...props} />;
			case 'checkbox':
				return <ElementCheckbox {...props} />;
			case 'text':
				return <ElementText {...props} />;
			case 'date':
				return <ElementDate {...props} />;
			case 'time':
				return <ElementTime {...props} />;
			case 'select':
				return <ElementSelect {...props} />;
			case 'email':
				return <ElementEmail {...props} />;
			case 'ProductsOrderTable':
				return <ElementProductTable {...props} />;
			case 'prepayment':
				return <ElementPrepayment {...props} />;
			case 'deliveryInfo':
				return <ElementDeliveryInfo {...props} />;
			default:
				return (
					<li key={props.index} draggable="true" className="draggable-item" onDragStart={(e) => e.dataTransfer.setData('text/plain', props.item)}>
						{props.item}
					</li>
				);
		}
	};

	return (
		<>
			<div style={{height: '60vh'}}>
				<Puck.Preview/>
			</div>
			{/*
			<div className={`left-panel ${isOverLeftPanel ? 'highlight' : ''}`} onDrop={handleDrop} onDragOver={handleDragOver}>
				{demandForm.parameters.length > 0 ? (
					demandForm.parameters.map((item, index) => <ElementComponent key={index} index={index} content={item.name} type={item.type} onRemove={onRemove} additionalProps={item.type === 'deliveryInfo' ? { filteredProducts: selectedProducts } : {}} />)
				) : (
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '16px', marginTop: '15px' }}>
						<FilePlus color="var(--theme-default)" size={'34px'} className="mb-4" />
						<div>Выберите элементы и перетащите их в данное окно</div>
					</div>
				)}
			</div>*/}
			<CommonModal isOpen={modalAdd} title="Добавить новый элемент" toggler={handleModalSubmit} saveText="Добавить">
				<CardBody style={{ padding: '0 15px 10px' }}>
					{elementType === 'deliveryInfo' ? (
						<Col sm="12">
							<Label className="col-sm-9 col-form-label">Укажите параметры доставки:</Label>
							{filteredProducts.map((item) => (
								<div className="checkbox checkbox-primary">
									<Input id={`checkbox-${item.id}`} type="checkbox" onChange={() => handleCheckboxChange(item)} checked={selectedProducts.some((selectedItem) => selectedItem.id === item.id)} />
									<Label for={`checkbox-${item.id}`}>
										{item.option} - {item.value}
									</Label>
								</div>
							))}
						</Col>
					) : (
						<Col>
							<Label className="col-sm-9 col-form-label">Введите наименование элемента</Label>
							<Col sm="12">
								<Input className="form-control" type="text" value={elementName} onChange={(e) => setElementName(e.target.value)} />
							</Col>
							<Label className="col-sm-9 col-form-label">Введите id элемента</Label>
							<Col sm="12">
								<Input className="form-control" type="text" value={elementId} onChange={(e) => setElementId(e.target.value)} />
							</Col>
						</Col>

					)}
				</CardBody>
			</CommonModal>
		</>
	);
};

export default LeftPanel;
