import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TwinAuthApi } from '../../api';
import CourseContext from '.';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../Company/CompanyProvider";

const CourseProvider = ({ children }) => {
    const [courseData, setCourseData] = useState(null);
    const { keycloak, initialized } = useKeycloak();
    const { companyData } = useCompanyContext();

    const getCourseData = async () => {
        if (initialized && keycloak.authenticated) {
            axios
                .get(`${TwinAuthApi}get-user-groups-courses/${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                .then((response) => {
                    setCourseData(response.data && JSON.parse(response.data));
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });
        }
    }

    /*useEffect(() => {
        if (initialized && keycloak.authenticated) {
            axios
				.get(`${TwinAuthApi}get-user-groups-courses/${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					setCourseData(JSON.parse(response.data);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
        }
    }, [initialized]);*/

    return (
        <CourseContext.Provider
            value={{
                courseData,
                getCourseData
            }}
        >
            {children}
        </CourseContext.Provider>
    );
};
export default CourseProvider;