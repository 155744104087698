import React, {Fragment, useEffect, useState} from "react";
import { Card, CardBody, Row, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane, Form } from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { Breadcrumbs, H5 } from "../../../../AbstractElements";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import SetTheme from "./SetTheme";

const PlatformSettings = () => {
	const { companyData, companyLogo, getCompanyLogo, createCompanyLogo, deleteCompanyLogo, updateCompanyLogo } = useCompanyContext();

	const [primarycolorTab, setprimarycolorTab] = useState("1");
	const [imageBase64, setImageBase64] = useState("");

	useEffect(() => {
		companyData && getCompanyLogo(companyData[0]?.id);
	}, [companyData])

	const handleSubmit = (files, allFiles) => {
		// установить логотип
		if (companyData) {
			if (companyLogo) {
				updateCompanyLogo(companyLogo.id, companyData[0]?.id, imageBase64);
			}
			else {
				createCompanyLogo(companyData[0]?.id, imageBase64);
			}
		}

		allFiles.forEach((f) => f.remove());
	};

	const getUploadParams = ({ file, meta }) => {
		if (file.size > 2 * 1024 * 1024) {
			toast.error("Максимальный размер файла 2 Мб!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			return { abort: true };
		}

		// Если размер файла допустим, возвращаем параметры загрузки
		return { url: "https://httpbin.org/post" };
	};

	const handleChangeStatus = ({ meta, file }, status) => {
		if (file) {
			const reader = new FileReader();

			reader.onloadend = () => {
				const base64String = reader.result;

				setImageBase64(base64String);
			};

			reader.readAsDataURL(file);
		}
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Настройки платформы" parent="Админ" title="Настройки платформы" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Row>
									<Col sm="3" xs="12">
										<Nav className="nav flex-column nav-pills">
											<NavItem>
												<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "1" ? "active" : ""} onClick={() => setprimarycolorTab("1")}>
													Управление логотипом
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "2" ? "active" : ""} onClick={() => setprimarycolorTab("2")}>
													Установка темы
												</NavLink>
											</NavItem>
										</Nav>
									</Col>
									<Col sm="9" xs="12">
										<TabContent activeTab={primarycolorTab}>
											<TabPane className="fade show" tabId="1">
												<H5>Изменить логотип</H5>
												<Form>
													<div className="dz-message needsclick">
														<Dropzone
															getUploadParams={getUploadParams}
															onChangeStatus={handleChangeStatus}
															onSubmit={handleSubmit}
															maxFiles={1}
															multiple={false}
															inputContent="Выбрать файл или перетащить сюда"
															accept="image/*"
															submitButtonContent="Отправить"
														/>
													</div>
												</Form>
											</TabPane>
											<TabPane tabId="2">
												<SetTheme />
											</TabPane>
										</TabContent>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default PlatformSettings;
