import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';
import { Breadcrumbs } from '../../../../../AbstractElements';
import { EducationApi } from '../../../../../api';
import TableDiplomaList from './TableDiplomaList';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";


const CreateDiploma = () => {
	const [data, setData] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		try {
			axios
				.get(`${EducationApi}get-course-diplomas-forms`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setData(concatResults(response));
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
		catch (error) {
			console.log("Ошибка: " + error);
		}

	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Создать диплом" parent="Дипломы" title="Создать диплом" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<TableDiplomaList products={data} />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default CreateDiploma;
