import React, { useMemo, useState } from 'react';
import { Trash2 } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { Label, Input, CardBody, Col } from 'reactstrap';
import { Btn } from '../../../../../../AbstractElements';
import { Plus } from 'react-feather';
import FilterComponent from '../../FilterComponent';
import CommonModal from '../../../../../UiKits/Modals/common/modal';
import TeachersTableModal from './TeachersTableModal';
import { TwinAuthApi } from '../../../../../../api';
import { EducationApi } from '../../../../../../api';
import { useLocation } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../../concatResults";

const Teachers = ({ teachers }) => {
	const [createModal, setCreateModal] = useState(false);
	const [users, setUsers] = useState([]);
	const [data, setData] = useState([]);
	const [username, setUsername] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [addTypeForm, setAddTypeForm] = useState('');
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const courseId = queryParams.get('courseId');

	const toggleCreateModal = () => {
		setCreateModal(!createModal);
	};

	if (data !== teachers) {
		setData(teachers);
	}

	const columns = [
		{
			name: '№',
			selector: (row) => `${row.rowNumber} `,
			sortable: true,
			width: '5%',
		},
		{
			name: 'Имя',
			selector: (row) => <div>{`${getUser(row.userId).firstName} ${getUser(row.userId).lastName}`}</div>,
			sortable: true,
			hide: 'sm',
			width: '50%',
		},
		{
			name: 'Email',
			selector: (row) => `${getUser(row.userId).email}`,
			sortable: true,
			width: '40%',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteRow(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
			width: '5%',
		},
	];

	const deleteRow = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then(function (isConfirm) {
			if (isConfirm) {
				axios
					.delete(`${EducationApi}delete-course-teacher/${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						toast.success('Преподаватель удалён!', {
							position: toast.POSITION.TOP_RIGHT,
						});

						axios
							.get(`${EducationApi}get-course/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
							.then((response) => {
								axios
									.delete(`${TwinAuthApi}remove-user-from-subgroup?groupName=${concatResults(response).name}&subgroupName=Teacher&userId=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
									.then(() => {})
									.catch((error) => {
										console.error(error);
									});
							})
							.catch((error) => {
								console.error(error);
							});

						let objWithIdIndex = teachers.findIndex((obj) => obj.id === userId);
						if (objWithIdIndex > -1) {
							teachers.splice(objWithIdIndex, 1);
							setData(teachers);
						}
						tryUpdateUsers();
					})
					.catch((error) => {
						toast.error('Ошибка при удалении преподавателя.', {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error(error);
					});
			}
		});
	};

	function getUser(id) {
		if (users === undefined || users.length === 0) {
			tryUpdateUsers();
		}
		let user = users.find((x) => x.id === id);
		if (user !== undefined) {
			return user;
		}
		return {
			firstName: '',
			lastName: '',
			username: '',
		};
	}

	function tryUpdateUsers() {
		let usersIds = [];

		if (teachers !== undefined) {
			teachers.forEach((element) => usersIds.push(element.userId));

			axios
				.post(`${TwinAuthApi}get-users-by-idArray`, usersIds, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					const courseUsers = JSON.parse(response.data);
					setUsers(courseUsers);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
	}

	function UsernameForm() {
		return (
			<>
				Логин учителя:
				<Col className="row col-sm-12 mb-3">
					<Col sm="6">
						<Input className="form-control mt-3 mb-3" type="tel" value={username} onChange={(e) => setUsername(e.target.value)} />
						<Btn attrBtn={{ color: 'white', className: 'btn btn-primary', outline: true, onClick: () => addUserToCourseByUsername(username) }}>
							<Plus size={'18px'} style={{ margin: '0px 0px -5px 0px' }} /> Добавить
						</Btn>
					</Col>
				</Col>
			</>
		);
	}

	function FullNameForm() {
		return (
			<>
				<Col className="row col-sm-12 mb-3">
					<Col sm="6">
						Имя учителя:
						<Input className="form-control mt-3 mb-3" type="tel" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
						Фамилия учителя:
						<Input className="form-control mt-3 mb-3" type="tel" value={lastName} onChange={(e) => setLastName(e.target.value)} />
						<Btn attrBtn={{ color: 'white', className: 'btn btn-primary', outline: true, onClick: () => addUserToCourseByFullName(firstName, lastName) }}>
							<Plus size={'18px'} style={{ margin: '0px 0px -5px 0px' }} /> Добавить
						</Btn>
					</Col>
				</Col>
			</>
		);
	}

	function AddUserForm(props) {
		const addType = props.addType;
		if (addType == 'fullName') {
			return <FullNameForm />;
		} else {
			return <UsernameForm />;
		}
	}

	const addUserToCourseByUsername = (username) => {
		axios
			.get(`${TwinAuthApi}get-user?login=${username}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const user = JSON.parse(response.data);

				let courseTeacher = {
					userId: user.id,
					courseId: courseId,
					gender: '0',
				};

				axios
					.post(`${EducationApi}add-teacher-to-course`, courseTeacher, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then(() => {
						toast.success('Учитель добавлен.', {
							position: toast.POSITION.TOP_RIGHT,
						});
						axios
							.get(`${EducationApi}get-course/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
							.then((response) => {
								axios
									.post(`${TwinAuthApi}add-user-to-subgroup?groupName=${concatResults(response).name}&subgroupName=Teacher&userId=${user.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
									.then(() => {})
									.catch((error) => {
										console.error(error);
									});
							})
							.catch((error) => {
								console.error(error);
							});
					})
					.catch((error) => {
						toast.error('Ошибка при добавлении учителя.', {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error('Ошибка получения данных:', error);
					});
			})
			.catch((error) => {
				toast.error('Ошибка при добавлении ученика.', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error('Ошибка получения данных:', error);
			});
	};

	const addUserToCourseByFullName = (firstName, lastName) => {
		axios
			.get(`${TwinAuthApi}get-user?firstName=${firstName}&lastName=${lastName}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const user = JSON.parse(response.data);

				let courseTeacher = {
					userId: user.id,
					courseId: courseId,
					gender: '0',
				};

				axios
					.post(`${EducationApi}add-teacher-to-course`, courseTeacher, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then(() => {
						toast.success('Учитель добавлен.', {
							position: toast.POSITION.TOP_RIGHT,
						});

						axios
							.get(`${EducationApi}get-course/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
							.then((response) => {
								axios
									.post(`${TwinAuthApi}add-user-to-subgroup?groupName=${concatResults(response).name}&subgroupName=Teacher&userId=${user.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
									.then(() => {})
									.catch((error) => {
										console.error(error);
									});
							})
							.catch((error) => {
								console.error(error);
							});
					})
					.catch((error) => {
						toast.error('Ошибка при добавлении учителя.', {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error('Ошибка получения данных:', error);
					});
			})
			.catch((error) => {
				toast.error('Ошибка при добавлении ученика.', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error('Ошибка получения данных:', error);
			});
	};

	let numberedData = data;

	if (numberedData !== undefined) {
		numberedData = data.map((row, index) => {
			return {
				...row,
				rowNumber: index + 1,
			};
		});
	}

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	let filteredItems = numberedData;
	if (numberedData !== undefined) {
		filteredItems = numberedData.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggleCreateModal} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable columns={columns} data={filteredItems} noDataComponent={'В данной таблице ещё нет записей.'} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={createModal} title={'Добавить преподавателя'} toggler={toggleCreateModal} togglerClose={toggleCreateModal} saveText="Готово" size="xl">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					Добавить по
					<div className="radio radio-primary mt-3 mb-3 m-l-5">
						<Input id="radio1" type="radio" name="radio1" value="userName" checked={addTypeForm === 'userName'} onChange={(e) => setAddTypeForm(e.target.value)} />
						<Label for="radio1">Логину</Label>
					</div>
					<div className="radio radio-primary mb-3 m-l-5">
						<Input id="radio2" type="radio" name="radio2" value="fullName" checked={addTypeForm === 'fullName'} onChange={(e) => setAddTypeForm(e.target.value)} />
						<Label for="radio2">Фамилии и имени</Label>
					</div>
					<AddUserForm addType={addTypeForm} />
					<TeachersTableModal teachers={teachers} />
				</CardBody>
			</CommonModal>
		</>
	);
};

export default Teachers;
