import React, { Fragment, useState, useEffect } from "react";
import { Col, Label, Input } from "reactstrap";
import axios from "axios";
import { BeatLoader } from "react-spinners";

import { Btn } from "../../../../AbstractElements";
import { useKeycloak } from "@react-keycloak/web";
import { TelephonyApi } from "../../../../api";
import { toast } from 'react-toastify';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const MainSetting = ({ pbxId }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [name, setName] = useState("");
	const [serverAddress, setServerAddress] = useState("");
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [username, setUsername] = useState("");
	const [proxy, setProxy] = useState("");
	const [enableIncomingCalls, setEnableIncomingCalls] = useState(false);

	const [curItem, setCurItem] = useState("");

	const getData = async () => {
		try {
			const response = await axios.get(`${TelephonyApi}getPbx`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				params: { Id: pbxId }
			});

			if (pbxId) {
				const foundItem = concatResults(response).pbx;
				setCurItem(foundItem);
				setName(foundItem.name);
				setServerAddress(foundItem.serverAddress);
				setLogin(foundItem.login);
				setPassword(foundItem.password);
				setUsername(foundItem.usernameAuth);
				setProxy(foundItem.proxyServer);
				setEnableIncomingCalls(foundItem.enableIncomingCalls);
			}
		} catch (error) { }
	};

	useEffect(() => {
		getData();
	}, []);

	const [loading, setLoading] = useState(false);
	const send = () => {
		setLoading(true);
		const updItem = {
			...curItem,
			id: pbxId,
			name: name,
			serverAddress: serverAddress,
			login: login,
			password: password,
			usernameAuth: username,
			proxyServer: proxy,
			enableIncomingCalls: enableIncomingCalls,
		};

		axios
			.put(`${TelephonyApi}updatePbx`, updItem, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				//console.log(concatResults(response));
				toast.success('Изменения сохранены!', { position: toast.POSITION.TOP_RIGHT, });
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				toast.error('Произошла ошибка', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error(error);
			});
	};

	return (
		<Fragment>
			<Col className="row col-sm-12 mb-3">
				<Label className="col-sm-9 col-form-label">Название подключения</Label>
				<Col sm="12">
					<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
				</Col>
			</Col>
			<Col className="row col-sm-12 mb-3">
				<Label className="col-sm-9 col-form-label">Адрес сервера</Label>
				<Col sm="12">
					<Input className="form-control" type="text" value={serverAddress} onChange={(e) => setServerAddress(e.target.value)} />
				</Col>
			</Col>
			<Col className="row col-sm-12 mb-3">
				<Label className="col-sm-9 col-form-label">Логин</Label>
				<Col sm="12">
					<Input className="form-control" type="text" value={login} onChange={(e) => setLogin(e.target.value)} />
				</Col>
			</Col>
			<Col className="row col-sm-12 mb-3">
				<Label className="col-sm-9 col-form-label">Пароль</Label>
				<Col sm="12">
					<Input className="form-control" type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
				</Col>
			</Col>
			<Col className="row col-sm-12 mb-3">
				<Label className="col-sm-9 col-form-label">Имя пользователя для авторизации</Label>
				<Col sm="12">
					<Input className="form-control" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
				</Col>
			</Col>
			<Col className="row col-sm-12 mb-3">
				<Label className="col-sm-9 col-form-label">Прокси-сервер</Label>
				<Col sm="12">
					<Input className="form-control" type="text" value={proxy} onChange={(e) => setProxy(e.target.value)} />
				</Col>
			</Col>
			<div className="checkbox checkbox-primary col-md-6">
				<Input id={`checkbox`} type="checkbox" checked={enableIncomingCalls} onChange={(e) => setEnableIncomingCalls(e.target.checked)} />
				<Label for={`checkbox`}>Включить входящие звонки</Label>
			</div>
			<div className="modal-footer mt-2">
				<Btn attrBtn={{ color: "primary", onClick: send, disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
			</div>
		</Fragment>
	);
};
export default MainSetting;
