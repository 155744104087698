import React, { useMemo, useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useLocation } from 'react-router';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { Plus, Check } from 'react-feather';
import { Btn, H6 } from '../../../../../../AbstractElements';
import AddButton from './AddButton';
import { EducationApi } from '../../../../../../api';

const StudentsTableModal = ({ students }) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const courseId = queryParams.get('courseId');

	const [users, setUsers] = useState([]);

	const columns = [
		{
			name: 'Имя',
			selector: (row) => (
				<div>
					{row.name} {row.surname ? row.surname : ''}
				</div>
			),
			sortable: true,
			hide: 'sm',
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<>
					<AddButton student={row} students={students} />
				</>
			),
			width: '15%',
		},
	];

	return (
		<>
			<DataTable columns={columns} data={students} noDataComponent={"В данной таблице ещё нет записей."} defaultSortField="name" striped pagination paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</>
	);
};

export default StudentsTableModal;
