import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import { H5, Image } from '../../../../../AbstractElements';
import { JOHANDIO } from '../../../../../Constant';

const UserTitle = ({ name, time }) => {
	const getTimeDifference = (specifiedTime) => {
		const specifiedDate = new Date(specifiedTime);
		const currentTime = new Date();
		const difference = currentTime.getTime() - specifiedDate.getTime();
		const minutesDifference = Math.floor(difference / (1000 * 60));

		if (minutesDifference < 60) {
			return `${minutesDifference} минут${minutesDifference === 1 ? 'а' : minutesDifference > 1 && minutesDifference < 5 ? 'ы' : ''} назад`;
		} else {
			const hoursDifference = Math.floor(minutesDifference / 60);
			if (hoursDifference < 24) {
				return `${hoursDifference} час${hoursDifference === 1 ? '' : 'а'} назад`;
			} else {
				const daysDifference = Math.floor(hoursDifference / 24);
				return `${daysDifference} дней назад`;
			}
		}
	};
	return (
		<Fragment>
			<Row>
				<Col sm="8">
					<div className="media">
						<Image attrImage={{ className: 'img-thumbnail rounded-circle me-3', src: `${require('../../../../../assets/images/user/7.jpg')}`, alt: 'Generic placeholder image' }} />
						<div className="media-body align-self-center">
							<H5 attrH5={{ className: 'mt-0 user-name' }}>{name}</H5>
						</div>
					</div>
				</Col>
				<Col sm="4" className="align-self-center">
					<div className="float-sm-end">
						<small>{getTimeDifference(time)}</small>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

export default UserTitle;
