import React, { useState, useEffect } from 'react';
import { Col, Input, InputGroup, InputGroupText, Row, FormGroup, Label, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Btn } from "../../../../AbstractElements";
import CommonModal from '../CommonModal';
import './PositionSettingsModal.css';
import { Tabs, Tab } from '@mui/material';
import axios from 'axios';
import { SpecificationsApi, TwinAuthApi, ProductsApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import uuid from 'react-uuid';
import { FaEdit, FaTrash, FaSave, FaTimes } from 'react-icons/fa'; // ����������� ������

const PositionSettingsModal = ({
    isOpen,
    toggle,
    modalData,
    allUnits,
    allCategories,
    onSave,
    handleChangeModalData
}) => {
    const { keycloak } = useKeycloak();
    const [activeTab, setActiveTab] = useState('general');
    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const { companyData } = useCompanyContext();
    const [showAddForm, setShowAddForm] = useState(false);

    const handleToggleAddForm = () => {
        setShowAddForm(!showAddForm);
        if (!showAddForm) {
            setNewCharacteristic({ characteristicId: null, value: '', unitId: null });
        }
    };

    const [characteristicsOptions, setCharacteristicsOptions] = useState([]);
    const [unitsOptions, setUnitsOptions] = useState([]);
    const [units, setUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [newCharacteristic, setNewCharacteristic] = useState({ characteristicId: null, value: '', unitId: null });
    const [isUnitSelectDisabled, setIsUnitSelectDisabled] = useState(true); 
    const [data, setData] = useState([]);
    const [editRowId, setEditRowId] = useState(null);
    const [editCharacteristic, setEditCharacteristic] = useState({ characteristicId: null, value: '', unitId: null });
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            fetchParamValues();
            fetchCharacteristicsOptions();
            fetchUnitsOptions();
        }
    }, [isOpen]);


    const fetchParamValues = async () => {
        try {
            const response = await axios.get(`${SpecificationsApi}getSpecificationLineParamValues`, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
            });
            setData(response.data.params)
        } catch (error) {
            console.error('������ ��� �������� ����������� �������������:', error);
        }
    };


    const fetchCharacteristicsOptions = async () => {
        try {
            const response = await axios.get(`${SpecificationsApi}getLinesParamsHB`, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
            });
            const options = response.data.params.map((char) => ({
                label: char.name,
                value: char.id,
                unitTypeId: char.unitTypeId
            }));
            setCharacteristicsOptions(options);

        } catch (error) {
            console.error('������ ��� �������� ����������� �������������:', error);
        }
    };

    const fetchUnitsOptions = async () => {
        try {
            const response = await axios.get(`${SpecificationsApi}getSpecificationUnits`, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
            });
            const options = response.data.units.map(unit => ({
                label: unit.name,
                value: unit.id,
                unitTypeId: unit.unitTypeId
            }));
            setUnits(options);
        } catch (error) {
            console.error('������ ��� �������� ����������� ������ ���������:', error);
        }
    };


    const handleAddCharacteristic = async () => {
        if (newCharacteristic.characteristicId && newCharacteristic.unitId) {

            newCharacteristic.id = uuid();
            var object = {
                id: uuid(),
                paramsId: newCharacteristic.characteristicId,
                paramsValue: newCharacteristic.value,
                unitId: newCharacteristic.unitId
            }

            const response = await axios.post(`${SpecificationsApi}createSpecificationLineParamValue`, object, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
            });

            fetchParamValues();
        } else {
            alert('�������� �������������� � ������� ���������.');
        }
    };



    const handleEditCharacteristic = (row) => {
        setEditRowId(row.id);
        setEditCharacteristic({
            characteristicId: row.params.id,
            value: row.paramsValue,
            unitId: row.unit.id
        });
        const unitTypeId = row.params.unitTypeId;
        const filteredUnits = units.filter(unit => unit.unitTypeId === unitTypeId);
        setUnitsOptions(filteredUnits);
        setIsUnitSelectDisabled(false);
        setIsEditModalOpen(true);
    };

    const handleSaveEditCharacteristic = async () => {
        const updatedCharacteristic = {
            id: editRowId,
            paramsId: editCharacteristic.characteristicId,
            paramsValue: editCharacteristic.value,
            unitId: editCharacteristic.unitId
        };

        await axios.put(`${SpecificationsApi}updateSpecificationLineParamValues`, updatedCharacteristic, {
            headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
        });

        setIsEditModalOpen(false);

        fetchParamValues();
        setEditRowId(null);
        setEditCharacteristic({ characteristicId: null, value: '', unitId: null });
        setIsUnitSelectDisabled(true);
    };

    const handleCancelEdit = () => {
        setEditRowId(null);
        setEditCharacteristic({ characteristicId: null, value: '', unitId: null });
        setIsUnitSelectDisabled(true);
        setIsEditModalOpen(false);
    };


    const handleDeleteCharacteristic = async (row) => {

        const response = await axios.delete(`${SpecificationsApi}deleteSpecificationLineParamValues/${row.id}`, {
            headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
        });

        fetchParamValues();
    };

    const handleCharacteristicSelect = async (selectedOption) => {
        try {



            const unitTypeId = selectedOption.unitTypeId;
            const filteredUnits = units.filter(unit => unit.unitTypeId === unitTypeId);
            setUnitsOptions(filteredUnits);
            const characteristicId = selectedOption.value;
         
            setNewCharacteristic(prev => ({ ...prev, characteristicId }));

            setNewCharacteristic(prev => ({
                ...prev,
                characteristicId,
                unitId: null
            }));
            setIsUnitSelectDisabled(false);
            console.log(newCharacteristic);

        } catch (error) {
            console.error('������ ��� ������ ��������������:', error);
        }
    };


    const handleEditCharacteristicSelect = (selectedOption) => {
        const selectedCharacteristicId = selectedOption.value;
        const selectedUnitTypeId = selectedOption.unitTypeId;

        const filteredUnits = units.filter(unit => unit.unitTypeId === selectedUnitTypeId);

        const isValidUnitId = filteredUnits.some(unit => unit.value === editCharacteristic.unitId);

        const defaultUnitId = isValidUnitId ? editCharacteristic.unitId : (filteredUnits.length > 0 ? filteredUnits[0].value : null);

        setUnitsOptions(filteredUnits);

        setEditCharacteristic(prev => ({
            ...prev,
            characteristicId: selectedCharacteristicId,
            unitId: defaultUnitId
        }));
    };

    const renderGeneralInfoTab = () => (
        <div>
            <Row className="mb-3">
                <Label className="col-sm-9 col-form-label">�������� �������</Label>
                <Col sm="12">
                    <Input
                        type="text"
                        name="positionName"
                        value={modalData.positionName}
                        onChange={(e) => handleChangeModalData('positionName', e.target.value)}
                        placeholder="������� �������� �������"
                        required
                    />
                    {modalData.positionName === '' && (
                        <span className="text-danger">�������� ������� ����������� ��� ����������!</span>
                    )}
                </Col>
            </Row>
            <Row className="mb-3">
                <Label className="col-sm-9 col-form-label">�������� �������</Label>
                <Col sm="12">
                    <Input
                        type="text"
                        name="description"
                        value={modalData.description}
                        onChange={(e) => handleChangeModalData('description', e.target.value)}
                        placeholder="������� �������� �������"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Label className="col-sm-9 col-form-label">����������</Label>
                <Col sm="12">
                    <Input
                        type="number"
                        name="countProducts"
                        value={modalData.countProducts}
                        onChange={(e) => handleChangeModalData('countProducts', e.target.value)}
                        placeholder="������� ����������"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Label className="col-sm-9 col-form-label">������� ���������</Label>
                <Col sm="12">
                    <Select
                        options={allUnits}
                        value={allUnits.find((option) => option.value === modalData.unitId)}
                        onChange={(selectedOption) => handleChangeModalData('unitId', selectedOption.value)}
                        placeholder="�������� ������� ���������"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Label className="col-sm-9 col-form-label">���������</Label>
                <Col sm="12">
                    <Select
                        options={allCategories}
                        value={allCategories.find((option) => option.value === modalData.categoryId)}
                        onChange={(selectedOption) => handleChangeModalData('categoryId', selectedOption.value)}
                        placeholder="�������� ���������"
                    />
                </Col>
            </Row>
        </div>
    );


    const renderCharacteristicsTab = () => (
        <div>
            <DataTable
                value={data}
                className="p-datatable-customers"
                emptyMessage="��� ����������� �������������"
            >
                <Column field="params.name" header="��������������" sortable style={{ width: '50%' }} />
                <Column field="paramsValue" header="��������" sortable style={{ width: '20%' }} />
                <Column field="unit.name" header="������� ���������" style={{ width: '20%' }} />
                <Column
                    style={{ width: '10%' }}
                    header=""
                    body={(rowData) => (
                        <div>
                            <span>
                                <FaEdit style={{ cursor: 'pointer', fontSize: '17px' }} onClick={() => handleEditCharacteristic(rowData)} />
                                <FaTrash style={{ cursor: 'pointer', marginLeft: '6px', fontSize: '17px' }} onClick={() => handleDeleteCharacteristic(rowData)} />
                            </span>
                        </div>
                    )}
                />
            </DataTable>

            <Modal isOpen={isEditModalOpen} toggle={handleCancelEdit} centered>
                <ModalHeader toggle={handleCancelEdit}>�������������� ��������������</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="edit-characteristic">��������������</Label>
                        <Select
                            id="edit-characteristic"
                            options={characteristicsOptions}
                            onChange={handleEditCharacteristicSelect}
                            value={characteristicsOptions.find(option => option.value === editCharacteristic.characteristicId)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="edit-value">��������</Label>
                        <Input
                            id="edit-value"
                            type="text"
                            value={editCharacteristic.value}
                            onChange={(e) => setEditCharacteristic({ ...editCharacteristic, value: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="edit-unit">������� ���������</Label>
                        <Select
                            id="edit-unit"
                            options={unitsOptions}
                            onChange={(selectedOption) => setEditCharacteristic({ ...editCharacteristic, unitId: selectedOption.value })}
                            isDisabled={isUnitSelectDisabled}
                            value={unitsOptions.find(option => option.value === editCharacteristic.unitId)}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{ color: "primary", onClick: handleSaveEditCharacteristic }}>
                        <FaSave className="mr-1" /> ���������
                    </Btn>
                    <Btn attrBtn={{ color: "secondary", onClick: handleCancelEdit }}>
                        <FaTimes className="mr-1" /> ������
                    </Btn>
                </ModalFooter>
            </Modal>


            {showAddForm && (
                <>
                    <FormGroup row className="mb-3">
                        <Col sm={5}>
                            <Select
                                options={characteristicsOptions}
                                value={characteristicsOptions.find(option => option.value === newCharacteristic.characteristicId)}
                                onChange={(selectedOption) => handleCharacteristicSelect(selectedOption)}
                                placeholder="�������� ��������������"
                            />
                        </Col>
                        <Col sm={3}>
                            <Input
                                type="text"
                                name="value"
                                value={newCharacteristic.value}
                                onChange={(e) => setNewCharacteristic(prev => ({ ...prev, value: e.target.value }))}
                                placeholder="������� ��������"
                            />
                        </Col>
                        <Col sm={4}>
                            <Select
                                options={unitsOptions}
                                value={newCharacteristic.unitId ? unitsOptions.find(option => option.value === newCharacteristic.unitId) : null}
                                onChange={(selectedOption) => setNewCharacteristic(prev => ({ ...prev, unitId: selectedOption.value }))}
                                placeholder="�������� ������� ���������"
                                isDisabled={isUnitSelectDisabled} 
                            />
                        </Col>
                    </FormGroup>

                    <Btn
                        attrBtn={{
                            color: 'primary',
                            onClick: handleAddCharacteristic,
                        }}
                    >
                        ��������� ��������������
                    </Btn>
                </>
            )}
            <Btn
                attrBtn={{
                    color: showAddForm ? 'secondary' : 'primary',
                    onClick: handleToggleAddForm,
                    className: 'margin-left'
                }}
            >
                {showAddForm ? '������' : '�������� ��������������'}
            </Btn>
        </div>
    );

    return (
        <CommonModal
            isOpen={isOpen}
            title="�������������� �������"
            toggler={toggle}
            togglerClose={toggle}
            closeText="������"
            saveText="���������"
            size="lg"
            onSave={onSave}
        >
            <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                <div className="tabs">
                    <Tabs
                        value={activeTab}
                        onChange={(e, newValue) => toggleTab(newValue)}
                        aria-label="��������� �������"
                        variant="fullWidth"
                    >
                        <Tab
                            label="����� ����������"
                            value="general"
                            className={activeTab === 'general' ? 'active-tab' : ''}
                        />
                        <Tab
                            label="��������"
                            value="characteristics"
                            className={activeTab === 'characteristics' ? 'active-tab' : ''}
                        />
                    </Tabs>

                </div>

                {activeTab === 'general' && renderGeneralInfoTab()}
                {activeTab === 'characteristics' && renderCharacteristicsTab()}
            </CardBody>
        </CommonModal>
    );
};

export default PositionSettingsModal;
