import React, { Fragment, useCallback, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { TwinAuthApi } from '../../../../api';
import FilterComponent from '../../Categories/FilterComponent';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const DataTableCompanies = () => {
	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleDelet, setToggleDelet] = useState(false);
	const { keycloak, initialized } = useKeycloak();
	const { companyData } = useCompanyContext();
	const navigate = useNavigate();

	const [modal, setModal] = useState(false);
	const [modalDel, setModalDel] = useState(false);

	const [LinkName, setLinkName] = useState('');
	const [Url, setUrl] = useState('');
	const [data, setData] = useState([]);

	useEffect(() => {
		if (initialized && keycloak.authenticated) {
			if (keycloak.tokenParsed.realm_access?.roles.includes('SuperAdministrator')) {
				axios
					.get(`${TwinAuthApi}get-groups-companies`, {
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
					})
					.then((response) => {
						setData(
							JSON.parse(response.data).map((company) => ({
								name: company.name,
								value: company.id,
							}))
						);
					})
					.catch((error) => {
						console.error('Ошибка получения данных:', error);
					});
			} else if (keycloak.tokenParsed.realm_access?.roles.includes('Administrator')) {
				axios
					.get(`${TwinAuthApi}get-admin-groups-companies/${keycloak.tokenParsed.sub}`, {
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
					})
					.then((response) => {
						setData(
							JSON.parse(response.data).map((company) => ({
								name: company.name,
								value: company.id,
							}))
						);
					})
					.catch((error) => {
						console.error('Ошибка получения данных:', error);
					});
			}
		}
	}, [initialized, keycloak.tokenParsed.sub]);

	const handleRowSelected = useCallback((state) => {
		setSelectedRows(state.selectedRows);
	}, []);

	const toggle = (ObjectId) => {
		console.log(ObjectId);
		if (typeof ObjectId === 'object' && ObjectId.hasOwnProperty('id')) {
			setLinkName(ObjectId.linkName);
			setUrl(ObjectId.link);
			setEdit(true);
		} else {
			setLinkName('');
			setUrl('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const [edit, setEdit] = useState(null);

	const handleAddRow = () => {
		setModal(!modal);
		const newRow = {
			id: data.length + 1,
			LinkName: LinkName,
			Url: Url,
		};

		setData([...data, newRow]);
	};

	const deleteRow = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {});
	};

	const tableColumns = [
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
			center: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div
							className="font-primary"
							style={{ marginLeft: '7px', cursor: 'pointer' }}
							onClick={() => navigate(`/edit-company?companyGroupId=${row.value}`)}
						>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];
	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter(
		(item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
	);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent
				toggle={toggle}
				onFilter={(e) => setFilterText(e.target.value)}
				onClear={handleClear}
				filterText={filterText}
			/>
		);
	}, [filterText, resetPaginationToggle]);

	return (
		<Fragment>
			<DataTable
				data={filteredItems}
				columns={tableColumns}
				striped={true}
				center={true}
				pagination
				selectableRows
				onSelectedRowsChange={handleRowSelected}
				clearSelectedRows={toggleDelet}
				highlightOnHover
				pointerOnHover
				subHeader
				subHeaderComponent={subHeaderComponent}
				noDataComponent={'В данной таблице ещё нет записей.'}
				paginationComponentOptions={{
					rowsPerPageText: 'Строк на странице:',
					rangeSeparatorText: 'из',
				}}
			/>
		</Fragment>
	);
};

export default DataTableCompanies;
