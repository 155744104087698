import React, { Fragment, useEffect, useState, useContext } from "react";
import { Card, CardBody, CardHeader, Col, Container, Label, Input, CardFooter } from "reactstrap";
import axios from "axios";
import { useLocation, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Select from "react-select";
import { PuffLoader } from "react-spinners";
import { useKeycloak } from "@react-keycloak/web";

import { Breadcrumbs, Btn } from "../../../../../AbstractElements";
import CommonModal from "../../../../UiKits/Modals/common/modal";
import { CodeExecutor } from "../CodeEditor/CodeExecutor";
import { CodeEditor } from "../CodeEditor/CodeEditor";
import { Tabs } from "../CodeEditor/Tabs";
import { CmsApi, DemandsApi } from "../../../../../api";
import CMSTemplatesContext from "../../../../../_helper/CMSTemplates";
import DemandsContext from "../../../../../_helper/Demands";
import { useCompanyContext } from "../../../../../_helper/Company/CompanyProvider";
import { useInteractivePlanMapsContext } from "../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import { concatResults } from "../../../../../concatResults";

const EditPage = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const alias = queryParams.get("alias");

	const { allSelectNews, allSelectTemplates, allSelectOffers, getAllCmsSelects } = useContext(CMSTemplatesContext);
	const { allSelectDemands, getAllDemands } = useContext(DemandsContext);
	const { allForms, fetchAppForms } = useContext(CMSTemplatesContext);
	const [formSelectOptions, setFormSelectOptions] = useState([]);

	const [planMapCmsWidgetsOptions, setPlanMapCmsWidgetsOptions] = useState([]);

	const {
		getInteractivePlanMapsWidgets, getInteractivePlanMaps, planMaps,
		planMapWidgets, setPlanMapWidgetsOptions, planMapWidgetsOptions,
		planMapsOptions
	} = useInteractivePlanMapsContext();

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const companyId = companyData.map((item) => (item.attributes.companyId[0]));
	const { siteId } = useParams();

	const [item, setItem] = useState("");

	const [editorData, setEditorData] = useState("");
	const [htmlContent, setHtmlContent] = useState("");

	const [loading, setLoading] = useState(true);

	const [selectTemplate, setSelectTemplate] = useState(null);
	const [selectForm, setSelectForm] = useState(null);
	const [selectWidget, setSelectWidget] = useState(null);
	const [selectNews, setSelectNews] = useState(null);

	const [selectDemands, setSelectDemands] = useState([]);

	const [crmTypes, setCrmTypes] = useState([]);
	const [crmSelectType, setSelectCrmType] = useState({
		label: "Заявка",
		value: "request",
		id: "659dfa01-9963-41dc-a50a-c4ef0d4d51b4",
	});

	const [editModal, setEditModal] = useState(false);
	const [offerName, setOfferName] = useState(false);
	const [size, setSize] = useState(false);

	const toggle = () => {
		setEditModal(!editModal);
	};

	const [formModal, setFormModal] = useState(false);
	const toggleForm = () => {
		setFormModal(!formModal);
	};

	const [formModalWidget, setFormModalWidget] = useState(false);
	const toggleWidget = () => {
		setFormModalWidget(!formModalWidget);
	};

	const [coverModal, setCoverModal] = useState(false);
	const toggleCover = () => {
		setCoverModal(!coverModal);
	};

	const [newsModal, setNewsModal] = useState(false);
	const toggleNews = () => {
		setNewsModal(!newsModal);
	};

	const [crmModal, setCrmModal] = useState(false);
	const toggleCRM = () => {
		setCrmModal(!crmModal);
	};

	const [mode, setMode] = useState("html");
	const [html, setHtml] = useState("");
	const [css, setCss] = useState("");
	const [js, setJs] = useState("");
	const [srcDoc, setSrcDoc] = useState("");

	const propsByMode = {
		html: {
			mode: "xml",
			value: html,
			setValue: setHtml,
		},
		css: {
			mode: "css",
			value: css,
			setValue: setCss,
		},
		js: {
			mode: "javascript",
			value: js,
			setValue: setJs,
		},
	};



	useEffect(() => {

		try {
			getInteractivePlanMapsWidgets();

			if (!allForms.length) {
				fetchAppForms();
			}

			!allSelectDemands && getAllDemands();
			getAllCmsSelects();
		}
		catch (err) {

		}
	}, [companyData])


	useEffect(() => {
		try {
			getInteractivePlanMaps();
		}
		catch (err) {

		}
	}, [planMapWidgets])


	useEffect(() => {
		try {
			const widgets = planMapWidgets.filter(widget => widget.siteId !== null /*&& widget.companyId === companyId*/);
			if (widgets.length > 0) {
				if (planMaps && planMaps.length > 0) {

					const widgetOptions = widgets.map(widget => ({
						label: `Виджет для интеркативной карты (${planMaps.find(obj => obj.id === widget.planMapId).name})`,
						value: widget.id,
						mapId: widget.planMapId
					}));

					setPlanMapCmsWidgetsOptions(widgetOptions);
				}
			}

			if (!allForms.length) {
				fetchAppForms();
			}

			!allSelectDemands && getAllDemands();
			getAllCmsSelects();
		}
		catch (err) {

		}
	}, [planMaps])


	useEffect(() => {
		try {
			if (allForms.length > 0) {

				const options = allForms.map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setFormSelectOptions(options);
			}
		} catch (error) {
			console.error("Ошибка при обработке allForms:", error);
		}
	}, [allForms]);


	function extractBodyContent(htmlString) {
		var match = htmlString.match(/<body>([\s\S]*?)<\/body>/);

		if (match && match.length > 1) {
			return match[1];
		} else {
			return "";
		}
	}

	function extractStyleContent(htmlString) {
		var match = htmlString.match(/<style>([\s\S]*?)<\/style>/);

		if (match && match.length > 1) {
			return match[1];
		} else {
			return "";
		}
	}

	function extractScriptContent(htmlString) {
		var match = htmlString.match(/<script>([\s\S]*?)<\/script>/);

		if (match && match.length > 1) {
			return match[1];
		} else {
			return "";
		}
	}

	useEffect(() => {
		setLoading(true);
		axios
			.get(`${CmsApi}getSites`, { params: { query: "" } })
			.then((response) => {
				const foundItem = response.data.sites.find((item) => item.id === siteId);
				const foundObject = foundItem.pages.find((page) => page.alias === alias);
				setItem(foundObject);
				if (foundObject.content) {

					const savedFormContent = JSON.parse(foundObject.content);

					const { html, css, js } = savedFormContent;

					setHtml(html);
					setCss(css);
					setJs(js);

					const content = `
						${css}
						<body>
							${html}
						</body>
						${js}
					`;

					setSrcDoc(content);

					//setSrcDoc(foundObject.content);
					//setHtml(extractBodyContent(foundObject.content));
					//setCss(extractStyleContent(foundObject.content));
					//setJs(extractScriptContent(foundObject.content));
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
				setLoading(false);
			});
	}, []);

	const updatePage = () => {

		const formContent = {
			html: html,
			css: css,
			js: js
		};

		const formContentString = JSON.stringify(formContent);

		const page = {
			id: item.id,
			name: item.name,
			alias: item.alias,
			siteId: item.siteId,
			content: formContentString,
			htmlMarkup: JSON.stringify(html),
			cssStyles: JSON.stringify(css),
			jsScript: JSON.stringify(js)
		};
		console.log(page);

		axios
			.put(`${CmsApi}updatePage`, page, {
				headers: {
					Authorization: `Bearer ${keycloak.token}`,
					CompanyId: companyData.map((item) => item.attributes.companyId[0])
				}
			})
			.then(async (response) => {
				toast.success("Страница изменена!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setEditModal(false);

				try {
					const fullHtml = `
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        ${formContent.css}
                        ${formContent.js}
                    </head>
                    <body>
						${formContent.html}
                    </body>
                    </html>`
					// После успешного добавления страницы, создаем файл
					await axios.put('http://localhost:80/updatePageFile', {
						id: siteId,
						alias: page.alias,
						content: fullHtml // Можно здесь добавить содержимое, если нужно
					});
				}
				catch (error) {

				}

			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	useEffect(() => {
		axios
			.get(`/api/TypesCRMEntity.json`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setCrmTypes(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const handlePaste = () => {
		setEditorData(
			editorData +
			`<p>/*Ваше пакетное предложение по тарифу ${offerName}*/</p><figure class="table"><table><tbody><tr><td><i>Здесь будет отображен контент пакетного предложения</i></td></tr></tbody></table></figure>` +
			`<StandardPricingcard data=` +
			`${offerName}` +
			` size=` +
			`${size}`
		);
		setHtmlContent(
			editorData +
			`<p>/*Ваше пакетное предложение по тарифу ${offerName}*/</p><figure class="table"><table><tbody><tr><td><i>Здесь будет отображен контент пакетного предложения</i></td></tr></tbody></table></figure>` +
			`<StandardPricingcard data=` +
			`${offerName}` +
			` size=` +
			`${size}`
		);
		setEditModal(!editModal);
	};

	const runCode = () => {

		try {
			const content = `
			${css}
			<body>
				${html}
			</body>
			${js}
		`;

			setSrcDoc(content);
		}
		catch (error) {

		}
	};


	const handleSelectChange = (selectedOption) => {
		setSelectTemplate(selectedOption);

		//Добавление и обновление шаблона
		const newTemplate = {
			template: selectTemplate,
			sorting: 0,
			condition_type: { label: "Для страницы сайта", value: "page" },
			condition_value: alias,
		};
		console.log(newTemplate);
		// if (edit) {
		// 	axios
		// 		.put(`${CmsApi}updateSite`, newTemplate, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 		.then((response) => {
		// 			toast.success("Сайт обновлен!", {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 			setEditModal(false);
		// 			fetchData();
		// 		})
		// 		.catch((error) => {
		// 			setEditModal(false);
		// 			toast.error("Ошибка при обновлении", {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 		});
		// } else {
		// 	axios
		// 		.post(`${CmsApi}createSite`, newTemplate, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 		.then((response) => {
		// 			toast.success("Сайт добавлен!", {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 			setEditModal(false);
		// 			fetchData();
		// 		})
		// 		.catch((error) => {
		// 			toast.error("Ошибка при добавлении", {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 		});
		// }
	};

	const [fileBase64, setFileBase64] = useState("");
	const [header, setHeader] = useState("");
	const [description, setDescription] = useState("");

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (!file) {
			return;
		}

		const reader = new FileReader();
		reader.onloadend = () => {
			setFileBase64(reader.result);
		};

		reader.readAsDataURL(file);
	};

	const checkboxItemsRequest = [
		{ name: "ID", value: "id" },
		{ name: "Дата создания", value: "createDate" },
		{ name: "Название", value: "name" },
		{ name: "Описание", value: "description" },
		{ name: "Дата начала", value: "dateStart" },
		{ name: "Дата окончания", value: "dateEnd" },
		{ name: "Владелец", value: "owner" },
		{ name: "Участники", value: "participants" },
	];

	const checkboxItemsLeads = [
		{ name: "ID", value: "id" },
		{ name: "Дата создания", value: "createDate" },
		{ name: "Название", value: "name" },
		{ name: "Описание", value: "description" },
		{ name: "Клиенты", value: "participants" },
		{ name: "Компания", value: "company" },
	];

	const checkboxItemsDeals = [
		{ name: "ID", value: "id" },
		{ name: "Дата создания", value: "createDate" },
		{ name: "Название", value: "name" },
		{ name: "Описание", value: "description" },
		{ name: "Клиенты", value: "participants" },
		{ name: "Компания", value: "company" },
		{ name: "Сумма", value: "amount" },
	];

	const [checkedItems, setCheckedItems] = useState(["name", "createDate"]);
	const [textToCopy, setTextToCopy] = useState("");

	const handleCheckboxChange = (itemName) => {
		const updatedCheckedItems = checkedItems.includes(itemName) ? checkedItems.filter((item) => item !== itemName) : [...checkedItems, itemName];
		setCheckedItems(updatedCheckedItems);

		let copyText = `<CRM request="${selectDemands.value}">`;
		updatedCheckedItems.forEach((item) => {
			copyText += `<entityCRM id="demands-${crmSelectType.id}-${item}"></entityCRM>`;
		});
		copyText += "</CRM>";

		setTextToCopy(copyText);
	};


	const handleFormSelectChange = (selectedOption) => {
		try {
			var currentForm = allForms.find(obj => obj.id === selectedOption.value);
			const savedFormContent = JSON.parse(currentForm.content);
			const { html, css, js } = savedFormContent;

			setSelectForm(selectedOption);

			setHtml((prevHtml) => {
				const parser = new DOMParser();
				const doc = parser.parseFromString(prevHtml, 'text/html');

				const existingForm = doc.querySelector('[formId]');
				if (existingForm) {
					existingForm.remove();
				}

				const newFormElement = doc.createElement('div');
				newFormElement.setAttribute('formId', selectedOption.value);
				newFormElement.innerHTML = css;
				newFormElement.innerHTML += html;
				newFormElement.innerHTML += js;

				doc.body.insertAdjacentElement('afterbegin', newFormElement);

				return doc.body.innerHTML;
			});

			//setCss((prevCss) => `${css}\n${prevCss}`);
			//setJs((prevJs) => `${js}\n${prevJs}`);
			runCode();
		} catch (error) {
			console.error("Error in handleFormSelectChange: ", error);
		}
	};

	const [widgetEmbedCode, setWidgetEmbedCode] = useState("<iframe></iframe>")


	const handleWidgetSelectChange = (selectedOption) => {
		try {
			setSelectWidget(selectedOption);

			const currentDomain = window.location.origin;

			const embedCode = `<iframe src="${currentDomain}/widgets/blank-view-plan-map?mapId=${selectedOption.mapId}&widgetId=${selectedOption.value}" width="100%" height="1000px"></iframe>`;
			setWidgetEmbedCode(embedCode);
			setHtml((prevHtml) => {
				const parser = new DOMParser();
				const doc = parser.parseFromString(prevHtml, 'text/html');

				const existingIframe = doc.querySelector('iframe');
				if (existingIframe) {
					existingIframe.remove();
				}

				const newIframeElement = doc.createElement('div');
				newIframeElement.innerHTML = embedCode;

				doc.body.insertAdjacentElement('afterbegin', newIframeElement);

				return doc.body.innerHTML;
			});
			runCode();
		} catch (error) {
			console.error("Error in handleWidgetSelectChange: ", error);
		}
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={item.name ? item.name : ""} parent="Редактирование страницы" title={item.name ? item.name : ""} />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					{loading ? (
						<Card>
							<CardBody>
								<PuffLoader size={80} color="#7366ff" style={{ margin: "20vh auto", display: "inherit", position: "relative", width: "80px", height: "80px" }} />
							</CardBody>
						</Card>
					) : (
						<Card>
							<CardHeader>
								<Link to={`/app/site-builder/${siteId}`} style={{ fontSize: "20px" }}>
									<i className="fa fa-angle-left" style={{ fontSize: "26px", marginRight: "5px" }}></i>Вернуться к списку
								</Link>
							</CardHeader>
							<CardBody>
								<Col className="row col-sm-12 mb-3">
									<Label className="col-sm-9 col-form-label">Название</Label>
									<Col sm="12">
										<Input disabled className="form-control" type="text" value={item.name} />
									</Col>
								</Col>
								<Col className="row col-sm-12 mb-4">
									<Label className="col-sm-9 col-form-label">Алиас</Label>
									<Col sm="12">
										<Input disabled className="form-control" type="text" value={alias} />
									</Col>
								</Col>
								<Col className="row col-sm-12 mb-4">
									<Label className="col-sm-9 col-form-label">Шаблон</Label>
									<Col sm="12" style={{ zIndex: "6" }}>
										<Select
											placeholder="Выберите..."
											noOptionsMessage={() => "Нет результатов"}
											options={allSelectTemplates}
											value={selectTemplate}
											theme={(theme) => ({
												...theme,
												colors: {
													...theme.colors,
													primary25: "#7366ff80",
													primary: "#7366ff",
												},
											})}
											className="col-md-12 mb-2"
											onChange={handleSelectChange}
										/>
									</Col>
								</Col>
								<Col className="col-sm-12 mb-2 mt-3" style={{ position: "relative" }}>
									{item !== null && item !== undefined && (
										<>
											<Tabs
												mode={mode}
												setMode={setMode}
												runCode={runCode}
												toggle={toggle}
												toggleForm={toggleForm}
												toggleCover={toggleCover}
												toggleNews={toggleNews}
												toggleCRM={toggleCRM}
												toggleWidget={toggleWidget}
											/>
											<CodeEditor {...propsByMode[mode]} />
											<CodeExecutor srcDoc={srcDoc} height="100vh" />
										</>
									)}
								</Col>
								<CommonModal isOpen={editModal} title={"Добавить пакетное предложение"} toggler={handlePaste} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size=" md">
									<CardBody style={{ padding: "15px", paddingLeft: "40px", paddingTop: "0" }}>
										<Col className=" col-sm-12 mb-3">
											<Select
												placeholder="Выберите категорию"
												options={allSelectOffers}
												className="js-example-basic-single col-sm-12"
												onChange={(selectedOption) => setOfferName(selectedOption.value)}
											/>
										</Col>
										<Col className=" col-sm-12 mb-3">
											<Select
												placeholder="Выберите размер блока"
												options={[
													{ label: "маленький", value: "3" },
													{ label: "средний", value: "4" },
													{ label: "большой", value: "5" },
												]}
												className="js-example-basic-single col-sm-12"
												onChange={(selectedOption) => setSize(selectedOption.value)}
											/>
										</Col>
									</CardBody>
								</CommonModal>

								<CommonModal isOpen={formModal} title={"Добавить форму заявки"} toggler={toggleForm} togglerClose={toggleForm} size="md">
									<CardBody style={{ padding: "15px", paddingLeft: "40px", paddingTop: "0" }}>
										<Col className="col-sm-12 mb-3">
											<Select
												placeholder="Выберите шаблон формы завяки"
												options={formSelectOptions}
												className="js-example-basic-single col-sm-12"
												value={selectForm}
												onChange={(selectedOption) => handleFormSelectChange(selectedOption)}
											/>
										</Col>
									</CardBody>
									{selectForm && (
										<CardFooter>
											<div className="b-t-light p-2">
												<CopyToClipboard text={`<applicationForm formId="${selectForm.value}"></applicationForm>`}>
													<div
														className="f-right btn btn-primary"
														onClick={() =>
															SweetAlert.fire("Элемент скопирован!", "Вставьте элемент в нужное место разметки страницы", "success").then((result) => {
																if (result.value) {
																	setFormModal(false);
																}
															})
														}
													>
														Выбрать
													</div>
												</CopyToClipboard>
											</div>
										</CardFooter>
									)}
								</CommonModal>

								<CommonModal isOpen={formModalWidget} title={"Добавить виджет"} toggler={toggleWidget} togglerClose={toggleWidget} size="md">
									<CardBody style={{ padding: "15px", paddingLeft: "40px", paddingTop: "0" }}>
										<Col className="col-sm-12 mb-3">
											<Select
												placeholder="Выберите виджет..."
												options={planMapCmsWidgetsOptions}
												className="js-example-basic-single col-sm-12"
												value={selectWidget}
												onChange={(selectedOption) => handleWidgetSelectChange(selectedOption)}
											/>
										</Col>
									</CardBody>
									{selectWidget && (
										<CardFooter>
											<div className="b-t-light p-2">
												<CopyToClipboard text={widgetEmbedCode}>
													<div
														className="f-right btn btn-primary"
														onClick={() =>
															SweetAlert.fire("Элемент скопирован!", "Вставьте элемент в нужное место разметки страницы", "success").then((result) => {
																if (result.value) {
																	setFormModalWidget(false);
																}
															})
														}
													>
														Выбрать
													</div>
												</CopyToClipboard>
											</div>
										</CardFooter>
									)}
								</CommonModal>

								<CommonModal isOpen={coverModal} title={"Добавить обложку"} toggler={toggleCover} togglerClose={toggleCover} size="md">
									<CardBody style={{ padding: "15px", paddingLeft: "15px", paddingTop: "0" }}>
										<Col className="col-sm-12 mb-3">
											<Label className="col-sm-9 col-form-label">Заголовок</Label>
											<Col sm="12">
												<Input className="form-control" type="text" value={header} onChange={(e) => setHeader(e.target.value)} />
											</Col>
										</Col>
										<Col className="col-sm-12 mb-3">
											<Label className="col-sm-9 col-form-label">Описание</Label>
											<Col sm="12">
												<Input className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
											</Col>
										</Col>
										<Col className="col-sm-12 mb-3">
											<Label className="col-sm-9 col-form-label">Изображение</Label>
											<Col sm="12">
												<Input type="file" accept="image/*" className="form-control col-md-6 mb-3" onChange={handleImageChange} />
											</Col>
										</Col>
									</CardBody>
									{fileBase64 && header && description && (
										<CardFooter>
											<div className="b-t-light p-2">
												<CopyToClipboard
													text={`
												<div class="row" style="background: url('${fileBase64}');height: 70vh;align-items: center;justify-content: center;flex-direction: column;display: flex;background-position: center;background-repeat: no-repeat;background-size: cover;">
												<h1 style="text-align: center;">${header}</h1>
												<p style="text-align: center;">${description}</p>
												</div>`}
												>
													<div
														className="f-right btn btn-primary"
														onClick={() =>
															SweetAlert.fire("Элемент скопирован!", "Вставьте элемент в нужное место разметки страницы", "success").then((result) => {
																if (result.value) {
																	setCoverModal(false);
																}
															})
														}
													>
														Выбрать
													</div>
												</CopyToClipboard>
											</div>
										</CardFooter>
									)}
								</CommonModal>
								<CommonModal isOpen={newsModal} title={"Добавить новости"} toggler={toggleNews} togglerClose={toggleNews} size="md">
									<CardBody style={{ padding: "15px", paddingLeft: "40px", paddingTop: "0" }}>
										<Col className="col-sm-12 mb-3">
											<Select
												placeholder="Выберите шаблон новостей"
												options={allSelectNews}
												className="js-example-basic-single col-sm-12"
												value={selectNews}
												onChange={(selectedOption) => setSelectNews(selectedOption)}
											/>
										</Col>
									</CardBody>
									{selectNews && (
										<CardFooter>
											<div className="b-t-light p-2">
												<CopyToClipboard text={`<newsTemplate templateId="${selectNews.value}"></newsTemplate>`}>
													<div
														className="f-right btn btn-primary"
														onClick={() =>
															SweetAlert.fire("Элемент скопирован!", "Вставьте элемент в нужное место разметки страницы", "success").then((result) => {
																if (result.value) {
																	setNewsModal(false);
																}
															})
														}
													>
														Выбрать
													</div>
												</CopyToClipboard>
											</div>
										</CardFooter>
									)}
								</CommonModal>
								<CommonModal isOpen={crmModal} title={"Добавить сущность CRM"} toggler={toggleCRM} togglerClose={toggleCRM} size="md">
									<CardBody style={{ padding: "15px", paddingLeft: "40px", paddingTop: "0" }}>
										<Col className="col-sm-12 mb-3">
											{crmTypes.map((item, i) => (
												<div className="radio radio-primary ms-2 mb-2">
													<Input
														key={i}
														id={`radio-${i}`}
														type="radio"
														value={item.value}
														onChange={(e) => {
															setSelectCrmType(item);
															setCheckedItems(["name", "createDate"]);
														}}
														name={`radio-1`}
														checked={crmSelectType.value === item.value}
													/>
													<Label for={`radio-${i}`}>{item.label}</Label>
												</div>
											))}
										</Col>
										<hr />
										{crmSelectType.value === "lead" &&
											checkboxItemsLeads.map((item, i) => (
												<div key={i} className={`${checkedItems.includes(item.value) ? `b-primary` : "b-light"} b-r-6 mb-2`}>
													<div className="checkbox checkbox-primary col-md-12 element ms-2">
														<Input id={`checkbox-${i}`} type="checkbox" checked={checkedItems.includes(item.value)} onChange={() => handleCheckboxChange(item.value)} />
														<Label for={`checkbox-${i}`} className="p-2 b-r-6">
															{item.name}
														</Label>
													</div>
												</div>
											))}
										{crmSelectType.value === "request" &&
											checkboxItemsRequest.map((item, i) => (
												<div key={i} className={`${checkedItems.includes(item.value) ? `b-primary` : "b-light"} b-r-6 mb-2`}>
													<div className="checkbox checkbox-primary col-md-12 element ms-2">
														<Input id={`checkbox-${i}`} type="checkbox" checked={checkedItems.includes(item.value)} onChange={() => handleCheckboxChange(item.value)} />
														<Label for={`checkbox-${i}`} className="p-2 b-r-6">
															{item.name}
														</Label>
													</div>
												</div>
											))}
										{crmSelectType.value === "deal" &&
											checkboxItemsDeals.map((item, i) => (
												<div key={i} className={`${checkedItems.includes(item.value) ? `b-primary` : "b-light"} b-r-6 mb-2`}>
													<div className="checkbox checkbox-primary col-md-12 element ms-2">
														<Input id={`checkbox-${i}`} type="checkbox" checked={checkedItems.includes(item.value)} onChange={() => handleCheckboxChange(item.value)} />
														<Label for={`checkbox-${i}`} className="p-2 b-r-6">
															{item.name}
														</Label>
													</div>
												</div>
											))}
										<hr />
										<Select
											placeholder="Выберите заявку"
											options={allSelectDemands}
											className="js-example-basic-single col-sm-12"
											value={selectDemands}
											onChange={(selectedOption) => setSelectDemands(selectedOption)}
										/>
									</CardBody>
									{selectDemands && (
										<CardFooter>
											<div className="b-t-light p-2">
												<CopyToClipboard text={textToCopy}>
													<div
														className="f-right btn btn-primary"
														onClick={() =>
															SweetAlert.fire("Элемент скопирован!", "Вставьте элемент в нужное место разметки страницы", "success").then((result) => {
																if (result.value) {
																	setCrmModal(false);
																}
															})
														}
													>
														Выбрать
													</div>
												</CopyToClipboard>
											</div>
										</CardFooter>
									)}
								</CommonModal>
								<CardFooter>
									<Btn attrBtn={{ color: "primary", onClick: updatePage, className: "f-right" }}>Сохранить изменения</Btn>
								</CardFooter>
							</CardBody>
						</Card>
					)}
				</div>
			</Container>
		</Fragment>
	);
};
export default EditPage;
