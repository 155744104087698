import React, { useMemo, useState, useEffect } from 'react';
import { Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import uuid from 'react-uuid';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { Label, Input, CardBody, Col } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

import { DeliveryApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const TableResources = () => {
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const [data, setData] = useState([]);
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await axios.get(`${DeliveryApi}getDeliveryParameters`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			setData(concatResults(response).deliveryParameters);
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	const columns = [
		{
			name: '',
			selector: (row) => '',
			sortable: true,
			width: '1%',
		},
		{
			name: 'Опция',
			selector: (row) => `${row.option}`,
			sortable: true,
		},
		{
			name: 'Значение',
			selector: (row) => `${row.value}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => { });
	};

	const [edit, setEdit] = useState(null);


	const performDeliveryParameterOperation = (operationType) => {
		if (name == '' || comment == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const parameter = {
				id: operationType === 'update' ? Id : uuid(),
				option: name,
				value: comment
			};

			const apiEndpoint = operationType === 'update' ? 'updateDeliveryParameter' : 'createDeliveryParameter';
			const httpMethod = operationType === 'update' ? 'put' : 'post';

			console.log(parameter)

			axios({
				method: httpMethod,
				url: `${DeliveryApi}${apiEndpoint}`,
				data: parameter, 
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
			})
				.then((response) => {
					const successMessage = operationType === 'update' ? 'Параметр доставки обновлен!' : 'Параметр доставки добавлен!';
					toast.success(successMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const createDeliveryParameter = () => {
		performDeliveryParameterOperation('create');
	};

	const updateDeliveryParameter = () => {
		performDeliveryParameterOperation('update');
	};


	const [modal, setModal] = useState(false);
	const [name, setName] = useState('');
	const [Id, setId] = useState('');
	const [comment, setComment] = useState('');

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.option);
			setId(ObjectId.id);
			setComment(ObjectId.value);
			setEdit(true);
		} else {
			setName('');
			setId('');
			setComment('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={edit ? updateDeliveryParameter : createDeliveryParameter} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Опция</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Значение</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={comment} onChange={(e) => setComment(e.target.value)} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableResources;
