import React, { Fragment, useState, useEffect } from 'react';
import { Row, CardBody, Col, Card, CardHeader } from 'reactstrap';
import axios from 'axios';

import Tooltip from '@mui/material/Tooltip';

import { H3, H5 } from '../../../../AbstractElements';

const KeyIndicatorsCards = ({ periodStart, periodEnd }) => {
	// useEffect(() => {
	// 	axios
	// 		.get('/api/employees.json')
	// 		.then((response) => {})
	// 		.catch((error) => {
	// 			console.error('Ошибка получения данных:', error);
	// 		});
	// }, []);

	return (
		<Fragment>
			<Row>
				<Col md="4" sm="6">
					<Card>
						<CardHeader className="p-t-20">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: '95%' }}>
									<H5>Доход</H5>
								</div>
								<Tooltip title="Описание" arrow placement="top">
									<i className="fa fa-info-circle" style={{ fontSize: '18px', color: '#bebfc3', cursor: 'pointer' }}></i>
								</Tooltip>
							</div>
						</CardHeader>
						<CardBody>
							<H3 attrH3={{ style: { color: '#bebfc3' } }}>0 ₽</H3>
							<p style={{ color: '#bebfc3' }}>Суммарный доход</p>
						</CardBody>
					</Card>
				</Col>
				<Col md="4" sm="6">
					<Card>
						<CardHeader className="p-t-20">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: '95%' }}>
									<H5>Доход по услугам</H5>
								</div>
								<Tooltip title="Описание" arrow placement="top">
									<i className="fa fa-info-circle" style={{ fontSize: '18px', color: '#bebfc3', cursor: 'pointer' }}></i>
								</Tooltip>
							</div>
						</CardHeader>

						<CardBody>
							<H3 attrH3={{ style: { color: '#bebfc3' } }}>0 ₽</H3>
							<p style={{ color: '#bebfc3' }}>Суммарный доход</p>
						</CardBody>
					</Card>
				</Col>
				<Col md="4" sm="6">
					<Card>
						<CardHeader className="p-t-20">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: '95%' }}>
									<H5>Доход по товарам</H5>
								</div>
								<Tooltip title="Описание" arrow placement="top">
									<i className="fa fa-info-circle" style={{ fontSize: '18px', color: '#bebfc3', cursor: 'pointer' }}></i>
								</Tooltip>
							</div>
						</CardHeader>
						<CardBody>
							<H3 attrH3={{ style: { color: '#bebfc3' } }}>0 ₽</H3>
							<p style={{ color: '#bebfc3' }}>Суммарный доход</p>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col md="4" sm="6">
					<Card>
						<CardHeader className="p-t-20">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: '95%' }}>
									<H5>Средний чек</H5>
								</div>
								<Tooltip title="Описание" arrow placement="top">
									<i className="fa fa-info-circle" style={{ fontSize: '18px', color: '#bebfc3', cursor: 'pointer' }}></i>
								</Tooltip>
							</div>
						</CardHeader>

						<CardBody>
							<H3 attrH3={{ style: { color: '#bebfc3' } }}>0 ₽</H3>
							<p style={{ color: '#bebfc3' }}>Средний чек за посещение</p>
						</CardBody>
					</Card>
				</Col>
				<Col md="4" sm="6">
					<Card>
						<CardHeader className="p-t-20">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: '95%' }}>
									<H5>Средний чек по услугам</H5>
								</div>
								<Tooltip title="Описание" arrow placement="top">
									<i className="fa fa-info-circle" style={{ fontSize: '18px', color: '#bebfc3', cursor: 'pointer' }}></i>
								</Tooltip>
							</div>
						</CardHeader>

						<CardBody>
							<H3 attrH3={{ style: { color: '#bebfc3' } }}>0 ₽</H3>
							<p style={{ color: '#bebfc3' }}>Средний чек за посещение</p>
						</CardBody>
					</Card>
				</Col>
				<Col md="4" sm="6">
					<Card>
						<CardHeader className="p-t-20">
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ width: '95%' }}>
									<H5>Средняя заполненность</H5>
								</div>
								<Tooltip title="Описание" arrow placement="top">
									<i className="fa fa-info-circle" style={{ fontSize: '18px', color: '#bebfc3', cursor: 'pointer' }}></i>
								</Tooltip>
							</div>
						</CardHeader>

						<CardBody>
							<H3 attrH3={{ style: { color: '#bebfc3' } }}>0 %</H3>
							<p style={{ color: '#bebfc3' }}>Средняя заполненность рабочего дня</p>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};
export default KeyIndicatorsCards;
