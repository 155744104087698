import React, { Fragment, useState, useEffect } from "react";
import { Gantt, ViewMode } from "gantt-task-react";
import "../../../../../node_modules/gantt-task-react/dist/index.css";
import ru from "date-fns/locale/ru";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TaskApi } from "../../../../api";

import { useKeycloak } from "@react-keycloak/web";
import TaskListHeader from "./TaskListHeader";
import Tooltip from "./Tooltip";
import ViewSwitcher from "./ViewSwitcher";
import TaskListTableDefault from "./TaskListTable";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const GanttTask = ({ tasks, projectId, toggleTask }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const history = useNavigate();
	const currentDate = new Date();
	const [view, setView] = useState(ViewMode.Day);
	const [GantTasks, setGantTasks] = useState([
		// {
		// 	start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
		// 	end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
		// 	name: "Some Project",
		// 	id: "ProjectSample",
		// 	progress: 25,
		// 	type: "project",
		// 	hideChildren: false,
		// },
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
			name: "Idea",
			id: "Task 0",
			progress: 45,
			type: "task",
			project: "ProjectSample",
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
			name: "Research",
			id: "Task 1",
			progress: 25,
			dependencies: ["Task 0"],
			type: "task",
			project: "ProjectSample",
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
			name: "Discussion with team",
			id: "Task 2",
			progress: 10,
			dependencies: ["Task 1"],
			type: "task",
			project: "ProjectSample",
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
			name: "Developing",
			id: "Task 3",
			dependencies: ["Task 2"],
			type: "task",
			project: "ProjectSample",
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
			name: "Review",
			id: "Task 4",
			type: "task",
			dependencies: ["Task 2"],
			project: "ProjectSample",
		},
		// {
		// 	start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
		// 	end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
		// 	name: "Release",
		// 	id: "Task 6",
		// 	progress: currentDate.getMonth(),
		// 	type: "milestone", ///&
		// 	dependencies: ["Task 4"],
		// 	project: "ProjectSample",
		// },
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
			name: "Party Time",
			id: "Task 9",
			progress: 0,
			isDisabled: true,
			type: "task",
			project: "ProjectSample",
		},
	]);

	useEffect(() => {
		if (tasks.length) {
			setGantTasks(
				tasks.map((option) => ({
					start: option.dateStart ? new Date(option.dateStart) : new Date(),
					end: option.dateEnd ? new Date(option.dateEnd) : new Date(),
					name: option.name,
					id: option.id,
					progress: 0,
					dependencies: [option.parentTaskId],
					type: "task",
					project: projectId,
				}))
			);
		}
	}, [tasks]);

	const [isChecked, setIsChecked] = useState(true);
	let columnWidth = 60;
	if (view === ViewMode.Month) {
		columnWidth = 300;
	} else if (view === ViewMode.Week) {
		columnWidth = 250;
	}

	//const getStartEndDateForProject = (tasks, projectId) => {
	//	const projectTasks = tasks.filter((t) => t.project === projectId);
	//	let start = projectTasks[0].start;
	//	let end = projectTasks[0].end;
	//	for (let i = 0; i < projectTasks.length; i++) {
	//		const task = projectTasks[i];
	//		if (start.getTime() > task.start.getTime()) {
	//			start = task.start;
	//		}
	//		if (end.getTime() < task.end.getTime()) {
	//			end = task.end;
	//		}
	//	}
	//	return [start, end];
	//};

	const handleTaskChange = (task) => {

		const foundItem = tasks.find((item) => item.id === task.id);
		const updItem = {
			...foundItem,
			dateEnd: task.end,
			dateStart: task.start,
		};
		axios
			.put(`${TaskApi}update`, updItem, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				toast.success("Срок задачи изменен!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const handleTaskDelete = (task) => {
		const conf = window.confirm("Are you sure about " + task.name + " ?");
		if (conf) {
			setGantTasks(tasks.filter((t) => t.id !== task.id));
		}
		return conf;
	};

	const handleDblClick = (task) => {
		history(`${process.env.PUBLIC_URL}/app/task/view-task?taskId=${task.id}`);
	};

	// const handleSelect = (task, isSelected) => {

	// };

	const handleExpanderClick = (task) => {
		setGantTasks(tasks.map((t) => (t.id === task.id ? task : t)));
		console.log("On expander click Id:" + task.id);
	};

	return (
		<Fragment>
			{GantTasks && (
				<>
					<ViewSwitcher toggleTask={toggleTask} view={view} onViewModeChange={(viewMode) => setView(viewMode)} onViewListChange={setIsChecked} isChecked={isChecked} />
					<Gantt
						tasks={GantTasks}
						viewMode={view}
						onDateChange={handleTaskChange}
						onDelete={handleTaskDelete}
						onDoubleClick={handleDblClick}
						// onSelect={handleSelect}
						onExpanderClick={handleExpanderClick}
						listCellWidth={isChecked ? "155px" : ""}
						columnWidth={columnWidth}
						locale={ru}
						TaskListHeader={TaskListHeader}
						TooltipContent={Tooltip}
						TaskListTable={TaskListTableDefault}
						barBackgroundColor="#cecafc"
						barBackgroundSelectedColor="#7366ff"
					/>
				</>
			)}
		</Fragment>
	);
};

export default GanttTask;
