// LeftPanel.js
import React from 'react';
import { Col, Label } from 'reactstrap';
import InputMask from 'react-input-mask';

const ElementTel = ({ setIsOverLeftPanel }) => {
	return (
		<>
			<div
				className="col-sm-11 mb-3"
				draggable="true"
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'tel');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Label className="col-sm-9 col-form-label">Телефон</Label>
				<Col sm="12">
					<InputMask className="form-control" mask="+7 (999) 999-99-99" maskChar="_" placeholder="+7 (___) ___-__-__" alwaysShowMask />
				</Col>
			</div>
		</>
	);
};

export default ElementTel;
