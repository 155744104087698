import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Container, Row } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";
import { CompaniesApi } from "../../../../api";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Card, CardBody } from "reactstrap";
import TableEmployees from "./TableEmployees";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const EmployeesList = () => {
	const [data, setData] = useState([]);
	const [departments, setDepartments] = useState(null);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		companyData &&
			axios
				.get(`${CompaniesApi}get-company-users?companyId=${companyData[0]?.id}`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					setData(JSON.parse(response.data));
					//console.log(JSON.parse(response.data));
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		axios
			.get(`/api/departments.json`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setDepartments(response.data.departments);
				//console.log(response.data.departments);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список сотрудников" parent="Сотрудники" title="Список сотрудников" />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardBody className="chat-body">{data && departments && <TableEmployees data={data} setData={setData} departments={departments} />}</CardBody>
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};
export default EmployeesList;
