import Select, {components} from "react-select";
import React, {useEffect, useState} from "react";
import {ArrowDropDownRounded} from "@mui/icons-material";

export const CharacteristicTypeSelect = ({onChange, value}) => {
    const [objectTypesCharacteristics, setObjectTypeCharacteristics] = useState([
        {value: "FieldName", label: "Характеристикой"},
        {value: "FieldValue", label: "Значением характеристики"}
    ]);
    const [selectedValue, setSelectedValue] = useState(null);

    const DropDownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownRounded />
            </components.DropdownIndicator>
        )
    }

    useEffect(() => {
        value && objectTypesCharacteristics && setSelectedValue(objectTypesCharacteristics.find((item) => item.value === value));
    }, [value, objectTypesCharacteristics])

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="">Нет типов характеристик для вывода</span>
            </components.NoOptionsMessage>
        );
    };

    const changeValue = (item) => {
        onChange(item);
        setSelectedValue(item);
    }

    return <Select
        placeholder="Выбор типа характеристики"
        value={selectedValue}
        options={objectTypesCharacteristics ?? []}
        className="js-example-basic-single col-sm-12"
        theme={(theme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: "rgba(115, 102, 255, 0.08)",
                primary: "#7366ff",
            },
        })}
        components={{ NoOptionsMessage: NoOptionsMessage, DropdownIndicator: DropDownIndicator }}
        onChange={(item) => changeValue(item)}
    />
}

export default CharacteristicTypeSelect;