import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import axios from 'axios';
import { CardBody, Card } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableTestList from './TableTestList';

const TestList = () => {

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список тестов и опросов" parent="Тестирование" title="Список тестов и опросов" />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardBody>
							<TableTestList />
						</CardBody>
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};
export default TestList;
