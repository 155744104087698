import React, { Fragment } from 'react';
import { Container, Row, Card, CardBody, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import DataTableDocumentTypesList from "./DataTableDocumentTypesList";

const DocumentTypesList = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Типы документов" parent="Клиенты" title="Типы документов" />
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="12" md="12" className="box-col-12">
                            <Card>
                                <CardBody>
                                    <DataTableDocumentTypesList />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default DocumentTypesList;
