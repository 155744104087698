import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { Edit, Eye, Plus } from 'react-feather';

const ActionButtonPanel = ({ specificationObject, setCurrentSpecification, setCurrentOrderName, onView, onEdit, onAddPos }) => {
    return (
        <div className="action-panel" style={{ position: 'absolute', top: '-45px', left: '0', display: 'flex', justifyContent: 'center', width: '100%', zIndex: '1' }}>
            <div style={{ background: '#fff', border: '1px solid #ccc', padding: '5px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Просмотреть" arrow>
                    <Link to={`/specifications/specification-details?id=${specificationObject.id}`} style={{ marginRight: '10px' }}>
                        <Eye size={18} style={{ cursor: 'pointer' }} />
                    </Link>
                </Tooltip>
                <Tooltip title="Редактировать" arrow>
                    <div
                        style={{ cursor: 'pointer', color: '#7366ff', marginRight: '10px' }}
                        onClick={() => {
                            setCurrentSpecification(specificationObject.id); 
                            setCurrentOrderName(specificationObject.title); 
                            onEdit(); 
                        }}
                    >
                        <Edit size={18} style={{ cursor: 'pointer' }} />
                    </div>
                </Tooltip>
                <Tooltip title="Добавить позицию" arrow>
                    <div
                        style={{ cursor: 'pointer', color: '#7366ff' }}
                        onClick={() => {
                            setCurrentSpecification(specificationObject.id); 
                            onAddPos(); 
                        }}
                    >
                        <Plus size={18} style={{ cursor: 'pointer' }} />
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default ActionButtonPanel;
