import React from 'react';
import { Input } from 'reactstrap';

const CallerNumberFilter = ({ filterValue, onFilterChange, name }) => {
	const handleFilterChange = (e) => {
		const value = e.target.value;
		onFilterChange(value, name);
	};

	return <Input placeholder="Поиск.." value={filterValue} onChange={handleFilterChange} style={{ height: '30px' }} />;
};

export default CallerNumberFilter;
