import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';

import axios from 'axios';
import Select from 'react-select';
import { Breadcrumbs } from '../../../AbstractElements';
import { Label, Col } from 'reactstrap';
import TableCatalog from './TableCatalog';

import { ProductsApi } from '../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../concatResults";

const Catalog = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	useEffect(() => {
		axios
			.get(`${ProductsApi}getCategories`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { query: "" } })
			.then((response) => {
				const selectOptions = response.categories.map((option) => ({
					label: option.longtitle,
					value: option.id,
				}));
				setCategories(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		const categoryIds = queryParams.getAll('categoryId');
		if (categoryIds.length > 0) {
			// Преобразуем массив categoryId в формат, который ожидает Select
			const selectedOptions = categoryIds.map((categoryId) => {
				const category = categories.find((category) => category.value === categoryId);
				return category;
			});
			setSelectedCategory(selectedOptions);
		}
	}, [categories]);

	const handleCategoryChange = (selectedOption) => {
		// Обновляем query параметр categoryId в URL
		const categoryIds = selectedOption.map((option) => option.value);
		queryParams.delete('categoryId'); // Очищаем существующие значения
		categoryIds.forEach((categoryId, i) => {
			queryParams.append(`categoryId`, categoryId);
		});
		navigate(`?${queryParams.toString()}`);
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Каталог предложений" parent="Предложения" title="Каталог предложений" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<Col style={{ padding: '4px 16px 4px 24px' }}>
								<Label className="col-form-label">Выберите категорию предложений</Label>
								<Select placeholder="Выберите..." isMulti value={selectedCategory} options={categories} className="js-example-basic-single col-sm-12" onChange={handleCategoryChange} />
							</Col>
							<TableCatalog />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default Catalog;
