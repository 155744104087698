import React, { Fragment, useState, useEffect } from 'react';
import { H4, LI, UL, Image, Btn } from '../../../../AbstractElements';
import paypal from '../../../../assets/images/checkout/paypal.png';
import { Col, Input, Label, Row } from 'reactstrap';
import LoyaltyPayment from '../../Calender/LoyaltyPayment';

const ProductPlaceOrder = ({ serviceList }) => {
	const [total, setTotal] = useState(0);

	useEffect(() => {
		calculateTotal();
	}, [serviceList]);

	const calculateTotal = () => {
		const totalPrice = serviceList.reduce((acc, product) => acc + product.price, 0);
		setTotal(totalPrice);
	};

	return (
		<Fragment>
			<Col xl="12" sm="12">
				<div className="checkout-details">
					<div className="order-box">
						<div className="title-box">
							<div className="checkbox-title">
								<H4>Услуга</H4>
								<span>Итого</span>
							</div>
						</div>
						{serviceList &&
							serviceList.map((item) => {
								return (
									<UL attrUL={{ className: 'simple-list border-x-0 border-t-0 qty' }} key={item.id}>
										<LI attrLI={{ className: 'border-0' }}>
											{item.name}
											<span>{item.price} руб.</span>
										</LI>
									</UL>
								);
							})}
						<UL attrUL={{ className: 'simple-list sub-total total' }}>
							<LI attrLI={{ className: 'border-0 bg-transparent ' }}>
								Итого <span className="count">{total} руб.</span>
							</LI>
						</UL>
						<div className="animate-chk">
							<Row>
								<Col>
									<Label className="d-block" htmlFor="edo-ani1">
										<Input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" data-original-title="" title="" />
										Наложенный платеж
									</Label>
									<Label className="d-flex align-items-center" htmlFor="edo-ani2">
										<Input className="radio_animated" id="edo-ani2" type="radio" name="rdo-ani" data-original-title="" title="" />
										Оплатить онлайн
										<Image attrImage={{ className: 'img-paypal', src: `${paypal}`, alt: '' }} />
									</Label>
								</Col>
							</Row>
						</div>
					</div>
					<LoyaltyPayment />
				</div>
			</Col>
		</Fragment>
	);
};
export default ProductPlaceOrder;
