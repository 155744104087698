import { useDrag, useDrop } from 'react-dnd';
import Tooltip from '@mui/material/Tooltip';
import { Col, Input, Row, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { Trash2 } from 'react-feather';

const DraggableElement = ({ element, index, id, moveElement, selectElement, deleteElement, name, required, selectedElement, content }) => {
	const [, ref] = useDrag({
		type: 'ELEMENT',
		item: { index },
	});

	const [, drop] = useDrop({
		accept: 'ELEMENT',
		hover: (draggedItem) => {
			if (draggedItem.index !== index) {
				moveElement(draggedItem.index, index);
				draggedItem.index = index;
			}
		},
	});

	return (
		<div
			ref={(node) => ref(drop(node))}
			onClick={() => selectElement(id)}
			style={{
				cursor: 'move',
				...(selectedElement === id && { border: '1px solid #ff3364', borderRadius: '10px', boxShadow: '0px 0px 6px 0px #ff3364' }),
			}}
		>
			<Row style={{ alignItems: 'center' }} className="mb-3 p-10">
				<Label className="col-sm-9 col-form-label">
					{name} {required ? <span className="font-danger f-16">*</span> : ''}
				</Label>
				<Col sm="11">
					{element.type.value === 'input' && <Input type="text" disabled className="form-control" />}
					{element.type.value === 'list' &&
						content.map((item) => (
							<div className="checkbox checkbox-primary ms-2" key={item.id}>
								<Input id="checkbox-primary-1" type="checkbox" disabled />
								<Label for="checkbox-primary-1">{item.label}</Label>
							</div>
						))}
					{element.type.value === 'radio' &&
						content.map((item) => (
							<div className="radio radio-primary mt-3 ms-3">
								<Input id={`radio-${item.id}`} type="radio" value={item.label} disabled />
								<Label for={`radio-${item.id}`}>{item.label}</Label>
							</div>
						))}
					{element.type.value === 'date' && <DatePicker dateFormat="dd.MM.yyyy" locale={ru} className="form-control digits" selected={new Date()} disabled />}
				</Col>
				{/* Другие типы элементов */}
				<Tooltip title="Удалить элемент" arrow placement="top">
					<div className="font-primary" onClick={() => deleteElement(index)} style={{ width: '32px', height: '32px', cursor: 'pointer' }}>
						<Trash2 />
					</div>
				</Tooltip>
			</Row>
		</div>
	);
};
export default DraggableElement;
