import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';
import { Label, Input, CardBody, Col } from 'reactstrap';

import FilterComponent from '../../Goods/PurchaseHistory/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

const TableCertificates = ({ filteredProducts }) => {
	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}

	const [modal, setModal] = useState(false);
	const columns = [
		{
			name: '#',
			selector: (row) => (
				<Tooltip title="Просмотр" arrow placement="top">
					<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
						{row.id}
					</div>
				</Tooltip>
			),
			sortable: true,
			width: '15%',
		},
		{
			name: 'Тип',
			selector: (row) => `${row.certificate_type}`,
			sortable: true,
			width: '20%',
		},
		{
			name: 'Баланс',
			selector: (row) => `${row.balance}`,
			sortable: true,
		},
		{
			name: 'Статус',
			selector: (row) => `${row.status}`,
			sortable: true,
		},
		{
			name: 'Покупатель',
			selector: (row) => `${row.phone}`,
			sortable: true,
		},
		{
			name: 'Продажа',
			selector: (row) => `${formatDate(row.sale_date)}`,
			sortable: true,
		},

		{
			name: 'Списания',
			selector: (row) => ``,
			sortable: true,
		},
		{
			name: 'Сумма',
			selector: (row) => `0`,
			sortable: true,
		},
	];

	const [phone, setPhone] = useState('');
	const [selectType, setSelectType] = useState('');
	const [balance, setBalance] = useState('');
	const [status, setStatus] = useState('');
	const [saleDate, setSaleDate] = useState('');
	const [expirationDate, setExpirationDate] = useState('');
	const [denomination, setDenomination] = useState('');

	const toggle = (ObjectId) => {
		setPhone(ObjectId.phone);
		setSelectType(ObjectId.certificate_type);
		setBalance(ObjectId.balance);
		setStatus(ObjectId.status);
		setSaleDate(ObjectId.sale_date);
		setExpirationDate(ObjectId.expiration_date);
		setDenomination(ObjectId.denomination);

		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title="Общая информация" toggler={toggle} togglerClose={toggle} saveText="Готово" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Покупатель</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={phone} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Тип</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={selectType} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Баланс</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={balance} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Статус</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={status} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Номинал</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={denomination} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Дата продажи</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={formatDate(saleDate)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Дата окончания действия</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={formatDate(expirationDate)} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableCertificates;
