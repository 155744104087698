import React, { Fragment, useState, useEffect } from "react";
import { Container, Input, CardBody, Col, Card, Label, CardHeader, Row, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import uuid from "react-uuid";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { Trash2, Edit, LogOut } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import Select from "react-select";

import CommonModal from "../../../../UiKits/Modals/common/modal";
import { Breadcrumbs, Btn, H6, H5, P } from "../../../../../AbstractElements";
import { Plus } from "react-feather";

const NonFiscalReceipt = () => {
	const [editorData, setEditorData] = useState("");

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		console.log(data);
		setEditorData(data);
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Настройки нефискального чека" parent="Отчёты" subParent="Финансы" title="Настройки нефискального чека" />
			<Container fluid className="user-card">
				<Col md="12">
					<Card>
						<CardHeader>
							<div style={{ justifyContent: "space-between", display: "flex" }}>
								<Link to={`${process.env.PUBLIC_URL}/analytics/reports`} className="f-18">
									<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
								</Link>
							</div>
						</CardHeader>
						<CardBody>
							<H6 attrH6={{ className: "f-w-600  mb-4" }}>Настройка печати</H6>
							<Col className="row col-sm-12 mb-3 mt-3">
								<Row>
									<Label className="f-w-600 col-sm-3 col-form-label" style={{ textAlign: "right" }}>
										Формат
									</Label>
									<Col sm="9">
										<Select
											placeholder="Выберите..."
											//value={curCash}
											options={[
												{ label: "А4", value: "А4" },
												{ label: "терминальный принтер 80 мм", value: "терминальный принтер 80 мм" },
												{ label: "терминальный принтер 58 мм", value: "терминальный принтер 58 мм" },
											]}
											className="js-example-basic-single col-sm-12"
											//onChange={(selected) => setCurCash(selected)}
										/>
									</Col>
								</Row>
							</Col>
							<H6 attrH6={{ className: "f-w-600 mt-4 mb-4" }}>Информация о клиенте</H6>
							<Col className="col-sm-12 mb-2 ">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Имя
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className=" col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Телефон
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className=" col-sm-12 mb-2 ">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Почта
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<H6 attrH6={{ className: "f-w-600 mt-4 mb-4" }}>Реквизиты салона</H6>
							<Col className="col-sm-12 mb-2 mt-4">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Название юридического лица
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className="col-sm-12 mb-2 ">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Юридический адрес
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Фактический адрес
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										ИНН
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										КПП
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										БИК
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Название банка
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Корреспондентский счет
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Расчетный счет
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<H6 attrH6={{ className: "f-w-600 mt-4 mb-4" }}>Позиции в чеке</H6>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Выводить НДС по каждой позиции
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<H6 attrH6={{ className: "f-w-600 mt-4 mb-4" }}>Дополнительная информация</H6>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Отображение
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Текст
									</Label>
									{/* <CKEditor editor={ClassicEditor} data={editorData} onChange={handleEditorChange} /> */}
								</Row>
							</Col>
							<Col className="col-sm-12 mb-2">
								<Row>
									<Label className="col-sm-3 col-form-label f-w-600" style={{ textAlign: "right" }}>
										Комментарий
									</Label>
									<div className="checkbox checkbox-primary col-sm-3">
										<Input id="checkbox-primary-1" type="checkbox" />
										<Label for="checkbox-primary-1">Показывать</Label>
									</div>
								</Row>
							</Col>
						</CardBody>
						<CardFooter>
							<div className="modal-footer">
								<Btn attrBtn={{ color: "primary" }}>Сохранить</Btn>
							</div>
						</CardFooter>
					</Card>
				</Col>
			</Container>
		</Fragment>
	);
};
export default NonFiscalReceipt;
