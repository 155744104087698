import React, { Fragment, useContext, useState } from "react";
import { Col, Row } from "reactstrap";
import ChatMessage from "./ChatMessage";
import ChatMenu from "./ChatMenu";
import ChatHeader from "./ChatHeader";
import SendChat from "./SendChat";
import ChatAppContext from "../../../../_helper/Chat";

const Chatting = () => {
	const { menuToggle, connectionOpen } = useContext(ChatAppContext);

	const [searchResults, setSearchResults] = useState([]);

	return (
		<Fragment>
			<Row className="chat-box">
				<Col className="pe-0 chat-right-aside">
					<div className="chat">
						<ChatHeader setSearchResults={setSearchResults} />
						<ChatMessage />
						<SendChat isConnectionOpen={connectionOpen} />
					</div>
				</Col>
				<Col className={`ps-0 chat-menu ${menuToggle ? "show" : ""}`}>
					<ChatMenu />
				</Col>
			</Row>
		</Fragment>
	);
};
export default Chatting;
