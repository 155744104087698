import React, { Fragment } from 'react';
import { Media } from 'reactstrap';
import { H6, P } from '../../../../AbstractElements';

var images = require.context('../../../../assets/images', true);

const AllEmailsClass = ({ list, selectMailCallback, selectedmailCallback, checked }) => {
	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	const months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay} ${months[month]} ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}

	return (
		<Fragment>
			<div>
				<Media onClick={() => selectMailCallback(list)} className="media-emails">
					{/* <Label className="d-block" htmlFor="chk-ani">
						<Input className="checkbox_animated" name="chk-ani" type="checkbox" onChange={(e) => selectedmailCallback(e, list.id)} defaultChecked={checked} />
					</Label> */}
					<div className="media-size-email">
						<Media body className="me-3 rounded-circle img-50" src={list.senderAvatar ? dynamicImage(list.senderAvatar) : ''} alt="" />
					</div>
					<Media body>
						<H6>
							{list.senderName}
							<small>
								<span className="digits"> {formatDate(list.date)}</span>
							</small>
						</H6>
						<P>{list.subject},</P>
					</Media>
				</Media>
			</div>
		</Fragment>
	);
};

export default AllEmailsClass;
