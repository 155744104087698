import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import {Trash2} from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import {Row, Col, CardBody, Form, FormGroup, Label, Input} from 'reactstrap';
import FilterComponent from '../FilterComponent';
import {useKeycloak} from "@react-keycloak/web";
import {useInteractivePlanMapsContext} from "../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import {TiTick} from "react-icons/ti";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const DataTablePlanMapObjectTypes = () => {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const [name, setName] = useState('');

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const {
        planMapsObjectsTypes,
        getInteractivePlanMapsObjectsTypes,
        deleteInteractivePlanMapsObjectsType,
        createInteractivePlanMapsObjectsType,
    } = useInteractivePlanMapsContext();

    useEffect(() => {
        planMapsObjectsTypes && setFilteredItems(planMapsObjectsTypes.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, planMapsObjectsTypes]);

    useEffect(() => {
        getInteractivePlanMapsObjectsTypes();
    }, [keycloak.tokenParsed]);

    const toggle = () => {
        setName('');
        setModal(!modal);
    };

    const handleAddRow = () => {

        if (name !== '') {
            createInteractivePlanMapsObjectsType(name, true, companyData[0].attributes.companyId[0]).then(() => {
                getInteractivePlanMapsObjectsTypes();
            });

            setModal(!modal);
        }
        else {
        toast.error('Заполните все поля!', {
            position: toast.POSITION.TOP_RIGHT,
        });

        }
    }

    const deleteRow = (remId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'Данное действие не отменит отправку оповщений!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(() => {
            deleteInteractivePlanMapsObjectsType(remId).then(() => {
                getInteractivePlanMapsObjectsTypes();
            });
        });
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const tableColumns = [
        {
            name: 'Название',
            selector: (row) => <Link to={`/widgets/interactive-plan-maps-object-characteristics?objectTypeId=${row.id}`}>{row.name}</Link>,
            sortable: true,
            center: false,
        },
        {
            name: 'Активен',
            selector: (row) => row.activated ? <TiTick></TiTick> : '',
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{display: 'flex'}}>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'}/>
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление типа объекта'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Название
                                </Label>
                                <Col sm="11">
                                    <Input
                                        className="form-control"
                                        value={name}
                                        placeholder="Название типа"
                                        onChange={(e) => setName(e.target.value)} />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTablePlanMapObjectTypes;
