import React, { Fragment } from "react";
import DataTable from "react-data-table-component";

import StatusButton from "./StatusButton";

const DataTableModeration = ({ data, allUsers }) => {
	const tableColumns = [
		{
			name: "Пользователь",
			selector: (row) => `${getUserInitialsById(row.userId)}`,
			sortable: true,
		},
		{
			name: "Позиция",
			selector: (row) => `${row.position}`,
			sortable: true,
		},
		{
			name: "Статус",
			selector: (row) => <StatusButton userItem={row} />,
			allowOverflow: true,
			center: true,
		},
	];

	function getUserInitialsById(id) {
		try {
			return allUsers.find((u) => u.value === id).label;
		} catch (error) {
			return "Не удалось загрузить инициалы";
		}
	}

	return (
		<Fragment>
			<DataTable
				data={data}
				columns={tableColumns}
				striped={true}
				pagination
				highlightOnHover
				pointerOnHover
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
		</Fragment>
	);
};
export default DataTableModeration;
