import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Input } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { Btn } from '../../../AbstractElements';
import ElementProductTable from './FormBuilder/Elements/ElementProductTable';
import SelectDateTime from './SelectDateTime';
import axios from 'axios';
import uuid from 'react-uuid';
import { DemandsApi } from '../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {concatResults} from "../../../concatResults";
import { useCompanyContext } from '../../../_helper/Company/CompanyProvider';

const ClientFormProducts = ({ demandForm, toggle = () => { } }) => {
    const [formValues, setFormValues] = useState({});
    const [pillWithIconTab, setpillWithIconTab] = useState(0);
    const [products, setProducts] = useState([]);
    const {keycloak} = useKeycloak();
    const {companyData} = useCompanyContext();

    const handleChange = (elementId, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [elementId]: value,
        }));
    };

    const handleProductsChange = (newProducts) => {
        setProducts(newProducts);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        handleChange("ProductsId", products);

        const idDemand = uuid();
        const reqParameters = [];

        for (const item of demandForm.menuItems) {
            for (const parameter of item.parameters) {
                reqParameters.push({
                    Value: JSON.stringify(formValues[parameter.elementId]),
                    IdDemand: idDemand,
                    IdParameterHB: parameter.id,
                });
            }
        }

        const demand = {
            id: idDemand,
            formid: 'f7636026-6042-4715-b486-96a68900710d',
            parameters: reqParameters,
        };

        console.log(reqParameters);

        try {
            const response = await axios.post(`${DemandsApi}createDemand`, demand, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}});
            //console.log(concatResults(response));
            toast.success('Заявка отправлена!', {
                position: toast.POSITION.TOP_RIGHT,
            });
            toggle();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchData = async (url, setData) => {
            try {
                const response = await axios.get(url);
                const selectOptions = concatResults(response).map((option) => ({
                    label: option.name,
                    value: option.id,
                }));
                setData(selectOptions);
            } catch (error) {
                console.error(`Ошибка получения данных из ${url}:`, error);
            }
        };

        fetchData('/api/employees.json', setEmployees);
        fetchData('/api/services.json', setServices);
    }, []);

    const renderMenuItems = () => {
        const sortedMenuItems = demandForm.menuItems.sort((a, b) => a.order - b.order);

        return sortedMenuItems.map((item) => (
            <NavItem key={item.id}>
                <NavLink
                    href="#"
                    className={item.order === pillWithIconTab ? 'active' : ''}
                    onClick={() => setpillWithIconTab(item.order)}
                    style={{ padding: '4px 8px' }}
                >
                    {item.name}
                </NavLink>
            </NavItem>
        ));
    };

    const renderFormParameters = (item) => {
        const sortedParameters = item.parameters.sort((a, b) => a.order - b.order);

        return (
            <TabPane key={item.id} className={item.order === 0 ? 'fade show' : ''} tabId={item.order}>
                <Row>
                    {sortedParameters.map((parameter) => (
                        <Col md="6" className="mb-3" key={parameter.id}>
                            <Label className="col-sm-9 col-form-label" for={parameter.elementId}>
                                {parameter.type !== 'ProductsOrderTable' && parameter.name}
                            </Label>
                            <Col sm="12">{renderParameterInput(parameter)}</Col>
                        </Col>
                    ))}
                </Row>
            </TabPane>
        );
    };

    const renderParameterInput = (parameter) => {
        switch (parameter.type) {
            case 'tel':
                return (
                    <InputMask
                        className="form-control"
                        mask="+7 (999) 999-99-99"
                        maskChar="_"
                        placeholder="+7 (___) ___-__-__"
                        alwaysShowMask
                        id={parameter.elementId}
                        value={formValues[parameter.elementId] || ''}
                        onChange={(e) => handleChange(parameter.elementId, e.target.value)}
                    />
                );

            case 'select':
                return (
                    <Select
                        id={parameter.elementId}
                        type={parameter.type}
                        placeholder="Выберите..."
                        value={
                            formValues[parameter.elementId]
                                ? { label: formValues[parameter.elementId].label, value: formValues[parameter.elementId].value }
                                : { label: null, value: null }
                        }
                        className="js-example-basic-single col-sm-12"
                        onChange={(option) => handleChange(parameter.elementId, option)}
                        options={JSON.parse(parameter.options)}
                    />
                );

            case 'ProductsOrderTable':
                return <ElementProductTable products={products} onProductsChange={handleProductsChange} />;

            case 'input':
                return (
                    <Input
                        className="form-control"
                        type={parameter.type}
                        id={parameter.elementId}
                        value={formValues[parameter.elementId] || ''}
                        onChange={(e) => handleChange(parameter.elementId, e.target.value)}
                    />
                );

            case 'DateTimeCalendar':
                return <SelectDateTime showBtn={false} />;

            default:
                return null;
        }
    };

    const [services, setServices] = useState([]);
    const [employees, setEmployees] = useState([]);

    return (
        <>
            <Nav className="nav-primary" tabs>
                {renderMenuItems()}
            </Nav>
            <form onSubmit={handleSubmit}>
                <TabContent activeTab={pillWithIconTab}>{demandForm.menuItems.map(renderFormParameters)}</TabContent>
                <div className="modal-footer mt-4">
                    <Btn attrBtn={{ color: 'primary' }}>Отправить</Btn>
                </div>
            </form>
        </>
    );
};

export default ClientFormProducts;