import React, { Fragment, useState } from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import BusinessProcessCard from './BusinessProcessCard';

const StandardPricingcard = ({ data, size }) => {
	return (
		<Fragment>
			<Row className="pricing-block m-25" style={{ justifyContent: ' space-around' }}>
				{data && data.map((item) => <BusinessProcessCard size={size} key={item.id} id={item.id} title={item.title} subTitle={item.cost} paragraph={item.description} serviceList={item.product_catalog} image={item.image} />)}
			</Row>
		</Fragment>
	);
};
export default StandardPricingcard;
