import React, { useState } from 'react';
import { Trash2, Edit, Maximize2 } from 'react-feather';
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import { Label, Input, InputGroup, InputGroupText, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Plus } from 'react-feather';

import CommonModal from '../../../UiKits/Modals/common/modal';
import { Btn } from '../../../../AbstractElements';

const TableOffersListView = ({ products, id }) => {
	const [editModal, setEditModal] = useState(false);

	const [edit, setEdit] = useState(null);

	const [description, setDescription] = useState('');
	const [title, setTitle] = useState('');
	const [cost, setCost] = useState('');
	const [productCatalog, setProductCatalog] = useState('');

	const toggle = (offerId) => {
		console.log(offerId);
		if (typeof offerId === 'object' && offerId.hasOwnProperty('title')) {
			setTitle(offerId.title);
			if (offerId.product_catalog) {
				const totalPrice = offerId.product_catalog.reduce((acc, product) => acc + product.price, 0);
				setCost(totalPrice);
			} else {
				setCost('');
			}

			setDescription(offerId.description);
			setProductCatalog(offerId.product_catalog);
			setEdit(true);
		} else {
			setTitle('');
			setCost('');
			setDescription('');
			setProductCatalog('');
			setEdit(false);
		}

		setEditModal(!editModal);
	};

	const columnsCatalog = [
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: 'Описание',
			selector: (row) => `${row.description}`,
			sortable: true,
			width: '260px',
		},
		{
			name: 'Стоимость',
			selector: (row) => <Link>{row.price} руб.</Link>,
			sortable: true,
			width: '200px',
		},
		{
			name: 'Цвет',
			selector: (row) => `${row.color}`,
			sortable: true,
			width: '160px',
		},
		{
			name: 'Категория',
			selector: (row) => `${row.category}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const columns = [
		{
			name: 'Название пакета',
			selector: (row) => `${row.title}`,
			sortable: true,
		},
		{
			name: 'Описание',
			selector: (row) => `${row.description}`,
			sortable: true,
			width: '25%',
		},
		{
			name: 'Стоимость',
			selector: (row) => `${row.product_catalog ? row.product_catalog.reduce((acc, product) => acc + product.price, 0) : 0} руб.`,
			sortable: true,
			width: '12%',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {});
	};

	const handleEditCalendar = () => {
		const newEvent = {
			// id: id,
			// title: title,
			// creator: creator,
			// events: [],
		};
		console.log(newEvent);
		axios
			.post(`/api/clientsDemandsForm.json`, newEvent)
			.then((response) => {
				//console.log(response.data);
				toast.success('Календарь добавлен!', {
					position: toast.POSITION.TOP_RIGHT,
				});
				setEditModal(false);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<>
			<Btn attrBtn={{ color: 'primary', className: 'm-r-20 mb-4', onClick: toggle }}>
				<Plus style={{ margin: '0px 2px -7px 0px' }} /> Добавить
			</Btn>
			<Link to={`/app/package-offers/client-view?offerId=${id}`}>
				<Btn attrBtn={{ color: 'info', className: 'm-r-50 mb-4' }}>
					<Maximize2 style={{ margin: '0px 2px -7px 0px' }} /> Просмотр
				</Btn>
			</Link>
			<DataTable columns={columns} data={products} defaultSortField="name" striped pagination noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={editModal} title={edit ? 'Редактировать' : 'Добавить'} toggler={handleEditCalendar} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Стоимость</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" disabled={true} type="text" value={cost} onChange={(e) => setCost(e.target.value)} />
								<InputGroupText>{'руб'}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Label className="col-sm-9 col-form-label">Услуги:</Label>
					<Btn attrBtn={{ color: 'primary', className: 'm-r-50 mb-4' }}>
						<Plus style={{ margin: '0px 2px -7px 0px' }} /> Добавить
					</Btn>
					<DataTable columns={columnsCatalog} data={productCatalog} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableOffersListView;
