import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Label, Input, CardBody, Col } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';

import { ServicesApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {concatResults} from "../../../../concatResults";
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';

const TableServiceCategories = () => {
	const {keycloak} = useKeycloak()
	const {companyData} = useCompanyContext();
	const [data, setData] = useState([]);
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await axios.get(`${ServicesApi}getServiceCategories`, { params: { query: '' }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			setData(concatResults(response).serviceCategories);
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	const columns = [
		{
			name: 'Название',
			selector: (row) => `${row.categoryName}`,
			sortable: true,
		},
		{
			name: 'Название для онлайн-записи',
			selector: (row) => `${row.onlineBookingName}`,
			sortable: true,
		},
		{
			name: 'Описание',
			selector: (row) => `${row.description}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteServiceCategory(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const [name, setName] = useState('');
	const [onlineBookingName, setOnlineBookingName] = useState('');
	const [description, setDescription] = useState('');
	const [Id, setId] = useState('');

	const createServiceCategory = () => {
		if (onlineBookingName == '' || name == '' || description == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			var id = uuid();
			const categoryService = {
				Id: id,
				CategoryName: name,
				OnlineBookingName: onlineBookingName,
				Description: description,
			};

			axios
				.post(`${ServicesApi}createServiceCategory`, categoryService, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Категория услуги добавлена!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
					fetchData();
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
	};

	const updateServiceCategory = () => {
		if (onlineBookingName == '' || name == '' || description == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const categoryService = {
				Id: Id,
				CategoryName: name,
				OnlineBookingName: onlineBookingName,
				Description: description,
			};

			axios
				.put(`${ServicesApi}updateServiceCategory`, categoryService, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Категория услуги обновлена!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
					fetchData();
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
	};

	const [modal, setModal] = useState(false);

	const toggle = (ObjectId) => {
		setModal(!modal);
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.categoryName);
			setId(ObjectId.id);
			setOnlineBookingName(ObjectId.onlineBookingName);
			setDescription(ObjectId.description);
			setEdit(true);
		} else {
			setName('');
			setId('');
			setOnlineBookingName('');
			setDescription('');
			setEdit(false);
		}
	};

	const [edit, setEdit] = useState(null);

	const deleteServiceCategory = (idService) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${ServicesApi}deleteServiceCategory`, {
						data: {
							ServiceCategoryId: idService,
						},
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
					})
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						fetchData();
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении:', error);
					});
			}
		});
	};


	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const [otherName, setOtherName] = useState(false);
	const handleCheckboxChange = () => {
		setOtherName(!otherName);
	};

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={edit ? updateServiceCategory : createServiceCategory} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					{/* <Col className="row col-sm-12 mb-3">
						<div className="checkbox checkbox-primary">
							<Input id={`checkbox-primary-1-${Id}`} type="checkbox" checked={otherName} onChange={(e) => setOtherName(e.target.checked)} />
							<Label for={`checkbox-primary-1-${Id}`}>Использовать другое название для онлайн-записи</Label>
						</div>
					</Col> */}
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название для онлайн-записи</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={onlineBookingName} onChange={(e) => setOnlineBookingName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={description} onChange={(e) => setDescription(e.target.value)} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableServiceCategories;
