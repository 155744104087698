import React, { useState } from "react";
import { Trash2, Edit, Home, Eye, Plus } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import uuid from "react-uuid";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Label, Input, CardBody, Col } from "reactstrap";
import { Link, useParams } from "react-router-dom";

import { Btn, H5 } from "../../../../../AbstractElements";
import CommonModal from "../../../../UiKits/Modals/common/modal";
import { CmsApi } from "../../../../../api";

const EditPagesList = ({ products, id, setProducts, loading }) => {
	const { siteId } = useParams();

	const [editModal, setEditModal] = useState(false);

	const [edit, setEdit] = useState(null);

	const [description, setDescription] = useState("");
	const [name, setName] = useState("");

	const [mainPage, setMainPage] = useState("");

	const toggle = (siteId) => {
		console.log(siteId);
		if (typeof siteId === "object" && siteId.hasOwnProperty("id")) {
			setName(siteId.name);
			setDescription(siteId.description);
			setEdit(true);
		} else {
			setName("");
			setDescription("");
			setEdit(false);
		}

		setEditModal(!editModal);
	};

	const columns = [
		{
			name: "Наименование страницы",
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: "Алиас",
			selector: (row) => `${row.alias}`,
			sortable: true,
		},
		{
			name: "",
			button: true,
			width: "12%",
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Назначить главной" arrow placement="top">
						<div className="" onClick={() => setHomePage(row)}>
							<Home
								size={"18px"}
								style={{
									marginLeft: "10px",
									cursor: "pointer",
									color: row.isHomePage === true ? "var(--theme-default)" : "#b7b9be",
								}}
							/>
						</div>
					</Tooltip>
					<Tooltip title="Просмотр" arrow placement="top">
						<Link to={`/app/site-builder/${id}/view?alias=${row.alias}`}>
							<Eye size={"18px"} style={{ marginLeft: "10px", cursor: "pointer" }} />
						</Link>
					</Tooltip>
					<Tooltip title="Редактировать" arrow placement="top">
						<Link to={`/app/site-builder/${id}/edit?alias=${row.alias}`}>
							<Edit size={"18px"} style={{ marginLeft: "10px", cursor: "pointer" }} />
						</Link>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" onClick={() => deletePage(row.id)}>
							<Trash2 size={"18px"} style={{ marginLeft: "10px", cursor: "pointer" }} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deletePage = (pageId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${CmsApi}deletePage`, {
						data: {
							PageId: pageId,
						},
					})
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
						document.getElementById(`row-${pageId}`).remove();
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении:", error);
					});
			}
		});
	};

	const setHomePage = (item) => {
		setMainPage(item.id);
		item.IsHomePage = true;
		axios
			.put(`${CmsApi}updatePage`, item)
			.then((response) => {
				toast.success("Установлена домашняя страница!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setEditModal(false);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	const isHostAvailable = async (url) => {
		try {
			await axios.get(`${url}/status`);
			return true;
		} catch (error) {
			return false;
		}
	};

	const createPage = async () => {
		var id = uuid();
		const page = {
			Id: id,
			Name: name,
			Alias: description,
			Content: "",
			SiteId: siteId,
		};

		try {
			await axios.post(`${CmsApi}createPage`, page);
			toast.success("Страница добавлена!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			setEditModal(false);
			setProducts((prevStatuses) => [...prevStatuses, { id, name, alias: description, content: "", siteId }]);

			const hostUrl = 'http://localhost:80';
			const isAvailable = await isHostAvailable(hostUrl);

			if (isAvailable) {
				const fullHtml = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style></style>
                <script></script>
            </head>
            <body>
            </body>
            </html>`;

				await axios.post(`${hostUrl}/createPageFile`, {
					id: siteId,
					alias: page.Alias,
					content: fullHtml
				});
			} else {
				console.warn("Сервер недоступен, пропускаем отправку запроса.");
			}

		} catch (error) {
			console.warn("Ошибка при создании страницы:", error.message);
		}
	};

	return (
		<>
			<H5>Редактор страниц</H5>
			{loading ? (
				<PuffLoader size={80} color="#7366ff" style={{ margin: "20vh auto", display: "inherit", position: "relative", width: "80px", height: "80px" }} />
			) : (
				<>
					<Btn attrBtn={{ color: "primary", className: "m-r-20 mb-4 mt-4", onClick: toggle }}>
						<Plus style={{ margin: "0px 2px -7px 0px" }} /> Добавить
					</Btn>
					<DataTable
						columns={columns}
						data={products}
						defaultSortField="name"
						striped
						pagination
						paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
					/>
					<CommonModal isOpen={editModal} title={edit ? "Редактировать" : "Добавить"} toggler={createPage} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size=" md">
						<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
							<Col className="row col-sm-12 mb-3">
								<Label className="col-sm-9 col-form-label">Название</Label>
								<Col sm="11">
									<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
								</Col>
							</Col>
							<Col className="row col-sm-12 mb-3">
								<Label className="col-sm-9 col-form-label">Алиас</Label>
								<Col sm="11">
									<Input className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
								</Col>
							</Col>
						</CardBody>
					</CommonModal>
				</>
			)}
		</>
	);
};

export default EditPagesList;
