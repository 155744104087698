import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit, Eye } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';

import SweetAlert from 'sweetalert2';
import uuid from 'react-uuid';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Label, Input, CardBody, Col } from 'reactstrap';

import FilterComponent from './FilterComponent';
import CommonModal from '../../UiKits/Modals/common/modal';

import { ProductsApi } from '../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../concatResults';

const TableCategories = () => {

	const [data, setData] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await axios.get(`${ProductsApi}getCategories`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { query: "" } })
			/*setData(concatResults(response).categories);*/
			setData(response.data.categories);
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	const [modal, setModal] = useState(false);

	const toggle = (ObjectId) => {
		console.log(ObjectId);
		if (typeof ObjectId === 'object' && ObjectId.hasOwnProperty('id')) {
			setTitle(ObjectId.pagetitle);
			setDescripton(ObjectId.description);
			setAlias(ObjectId.alias);
			setOkpd(ObjectId.okpd);
			setId(ObjectId.id);
			setEdit(true);
		} else {
			setTitle('');
			setDescripton('');
			setAlias('');
			setOkpd('');
			setId('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const [edit, setEdit] = useState(null);

	const columns = [
		{
			name: '№',
			selector: (row) => <Link>{row.id}</Link>,
			sortable: true,
			width: '10%',
		},
		{
			name: 'Название',
			selector: (row) => `${row.pagetitle}`,
			sortable: true,
			hide: 'sm',
			width: '20%',
		},
		{
			name: 'Описание',
			selector: (row) => `${row.description}`,
			sortable: true,
			width: '30%',
		},
		{
			name: 'ОКПД',
			selector: (row) => `${row.okpd}`,
			sortable: true,
			width: '20%',
		},
		{
			name: 'Дата создания',
			selector: (row) => `${row.createdon}`,
			sortable: true,
			width: '12%',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Просмотр" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/app/catalog?categoryId=${row.id}`}>
							<Eye size={'18px'} style={{ marginLeft: '7px', cursor: 'pointer' }} />
						</Link>
					</Tooltip>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteCategory(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
			width: '8%',
		},
	];

	const deleteCategory = (id) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${ProductsApi}deleteCategory`, {
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
						data: {
							CategoryId: id
						}
					})
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						fetchData();
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении:', error);
					});
			}
		});
	};

	const [title, setTitle] = useState('');
	const [description, setDescripton] = useState('');
	const [alias, setAlias] = useState('');
	const [okpd, setOkpd] = useState('');
	const [Id, setId] = useState('');

	const performCategoryOperation = (operationType) => {
		if (okpd === '' || alias === '' || description === '' || title === '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const category = {
				id: operationType === 'update' ? Id : uuid(),
				pagetitle: title,
				alias: alias,
				description: description,
				okpd: okpd,
			};

			const apiEndpoint = operationType === 'update' ? 'updateCategory' : 'createCategory';
			const httpMethod = operationType === 'update' ? 'put' : 'post';

			axios({
				method: httpMethod,
				url: `${ProductsApi}${apiEndpoint}`,
				data: category,
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
			})
				.then((response) => {
					const successMessage = operationType === 'update' ? 'Категория обновлена!' : 'Категория добавлена!';
					toast.success(successMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(!modal);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const createCategory = () => {
		performCategoryOperation('create');
	};

	const updateCategory = () => {
		performCategoryOperation('update');
	};


	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={edit ? updateCategory : createCategory} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={title} onChange={(e) => setTitle(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={description} onChange={(e) => setDescripton(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Алиас</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={alias} onChange={(e) => setAlias(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">ОКПД</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={okpd} onChange={(e) => setOkpd(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<div className="checkbox checkbox-primary">
							<Input id="checkbox-primary-1" type="checkbox" />
							<Label for="checkbox-primary-1">Отображать в меню</Label>
						</div>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableCategories;
