import React, { Fragment, useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "moment/locale/ru";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { H4 } from "../../../../../AbstractElements";
import TableLessons from "../../Lessons/TableLessons";
import Teachers from "./Teachers/Teachers";
import Progress from "./Progress";
import Sales from "./Sales";
import myEventsList from "../../../Calender/Events";
import Students from "./Students/Students";
import TableDiplomaIssuedList from "../../Diploma/DiplomasIssued/TableDiplomaIssuedList";
import Modules from "./Modules";
import Achievements from "./Achievements";

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const BuildCourseTab = ({ data, lessons, teachers, students, issuedDiplomas, showModules }) => {
	const defaultValue = "1";
	const storedValue = localStorage.getItem("primaryCourseTab");
	const initialPrimaryColorTab = storedValue ? storedValue : defaultValue;
	const [primarycolorTab, setprimarycolorTab] = useState(initialPrimaryColorTab);

	const handleCourseTab = (num) => {
		setprimarycolorTab(num);
		localStorage.setItem("primaryCourseTab", num);
	};

	useEffect(() => {
		if (!showModules) {
			if (initialPrimaryColorTab === "8") {
				setprimarycolorTab("1");
			}
		}
	}, [showModules]);

	//selectable calenadar

	const { defaultDate, messages } = useMemo(
		() => ({
			defaultDate: new Date(),
			messages: {
				week: "Неделя",
				work_week: "Рабочая неделя",
				day: "День",
				month: "Месяц",
				previous: "Предыдуший",
				next: "Следующий",
				today: "Сегодня",
				agenda: "План",
				date: "Дата",
				time: "Время",
				event: "Событие",
				allDay: "Весь день",
				yesterday: "Вчера",
				tomorrow: "Завтра",
				noEventsInRange: "В этом диапазоне нет событий.",

				showMore: (total) => `+ ещё ${total}`,
			},
		}),
		[]
	);
	return (
		<Fragment>
			<Nav className="nav-primary mt-4" tabs>
				<NavItem>
					<NavLink style={{ cursor: "pointer", padding: "0.5rem 0.9rem" }} className={primarycolorTab === "1" ? "active" : ""} onClick={() => handleCourseTab("1")}>
						<i className="icofont icofont-book-alt"></i>
						Занятия
					</NavLink>
				</NavItem>
				{showModules && (
					<NavItem>
						<NavLink style={{ cursor: "pointer", padding: "0.5rem 0.9rem" }} className={primarycolorTab === "8" ? "active" : ""} onClick={() => handleCourseTab("8")}>
							<i className="icofont icofont-chart-flow-alt-1"></i>
							Модули
						</NavLink>
					</NavItem>
				)}
				<NavItem>
					<NavLink style={{ cursor: "pointer", padding: "0.5rem 0.9rem" }} className={primarycolorTab === "2" ? "active" : ""} onClick={() => handleCourseTab("2")}>
						<i className="icofont icofont-teacher"></i>
						Преподаватели
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: "pointer", padding: "0.5rem 0.9rem" }} className={primarycolorTab === "3" ? "active" : ""} onClick={() => handleCourseTab("3")}>
						<i className="icofont icofont-tasks-alt"></i>
						Расписание
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: "pointer", padding: "0.5rem 0.9rem" }} className={primarycolorTab === "4" ? "active" : ""} onClick={() => handleCourseTab("4")}>
						<i className="icofont icofont-group-students"></i>
						Ученики
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: "pointer", padding: "0.5rem 0.9rem" }} className={primarycolorTab === "5" ? "active" : ""} onClick={() => handleCourseTab("5")}>
						<i className="icofont icofont-trophy-alt"></i>
						Достижения
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: "pointer", padding: "0.5rem 0.9rem" }} className={primarycolorTab === "7" ? "active" : ""} onClick={() => handleCourseTab("7")}>
						<i className="icofont icofont-certificate-alt-1"></i>
						Выданные дипломы
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink style={{ cursor: "pointer", padding: "0.5rem 0.9rem" }} className={primarycolorTab === "6" ? "active" : ""} onClick={() => handleCourseTab("6")}>
						<i className="icofont icofont-growth"></i>
						Продажи
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={primarycolorTab}>
				<TabPane className="fade show" tabId="1">
					<H4 attrH4={{ className: "mb-2 mt-4" }}>Список занятий</H4>
					<div className="p-t-20 p-b-30">{lessons && <TableLessons lessons={lessons} />}</div>
				</TabPane>
				<TabPane tabId="2">
					<H4 attrH4={{ className: "mb-2 mt-4" }}>Список преподавателей</H4>
					<div className="p-t-20 p-b-30">{teachers && <Teachers teachers={teachers} />}</div>
				</TabPane>
				<TabPane tabId="3">
					<H4 attrH4={{ className: "mb-2 mt-4" }}>Расписание занятий</H4>
					<Calendar
						messages={messages}
						localizer={localizer}
						scrollToTime={new Date(1970, 1, 1, 6)}
						views={allViews}
						events={myEventsList}
						eventOverlap
						dragRevertDuration={500}
						dragScroll
						droppable={true}
						showMultiDayTimes
						step={60}
						startAccessor="start"
						endAccessor="end"
						selectable
					/>
				</TabPane>
				<TabPane tabId="4">
					<H4 attrH4={{ className: "mb-2 mt-4" }}>Список учеников</H4>
					<div className="p-t-20 p-b-30">{students && <Students students={students} />}</div>
				</TabPane>
				<TabPane tabId="5">
					<Achievements students={students} />
				</TabPane>
				<TabPane tabId="6">
					<H4 attrH4={{ className: "mb-2 mt-4" }}>Продажи</H4>
					<div className="p-t-20 p-b-30">
						<Sales />
					</div>
				</TabPane>
				<TabPane tabId="7">
					<H4 attrH4={{ className: "mb-2 mt-4" }}>Выданные дипломы</H4>
					<div className="p-t-20 p-b-30">{issuedDiplomas && <TableDiplomaIssuedList data={issuedDiplomas} />}</div>
				</TabPane>
				{showModules && (
					<TabPane tabId="8">
						<H4 attrH4={{ className: "mb-2 mt-4" }}>Модули</H4>
						<div className="p-t-20 p-b-30">
							<Modules courseId={data.id} />
						</div>
					</TabPane>
				)}
			</TabContent>
		</Fragment>
	);
};
export default BuildCourseTab;
