import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { H5 } from '../../../../AbstractElements';

const CustomerAccounts = ({ clientId }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/сustomerAccounts.json`)
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			{data.length === 0 ? (
				<>
					<H5>Счета клиента</H5> <div></div>
				</>
			) : (
				''
			)}
		</Fragment>
	);
};

export default CustomerAccounts;
