import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit, Package } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import axios from 'axios';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Label, Input, CardBody, Col, Media } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { H6, P } from '../../../../AbstractElements';

import { ServicesApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {concatResults} from "../../../../concatResults";
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';

const TableResources = () => {
	const {keycloak} = useKeycloak();
	const {companyData} = useCompanyContext();
	const [data, setData] = useState([]);
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await axios.get(`${ServicesApi}getResources`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			setData(concatResults(response).resources);
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	const columns = [
		{
			name: '',
			selector: (row) => '',
			sortable: true,
			width: '1%',
		},
		{
			name: 'Название',
			selector: (row) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<i className="fa fa-gears f-28" style={{ color: '#898989' }}></i>
					<div className="ms-3">
						<Tooltip title="Экземпляры ресурса" arrow placement="top">
							<Link to={`${process.env.PUBLIC_URL}/service-app/resources/edit-instances?resourceId=${row.id}`}>
								<p className="f-16">{row.name}</p>
							</Link>
						</Tooltip>
					</div>
				</div>
			),
			sortable: true,
		},
		{
			name: 'Описание',
			selector: (row) => `${row.description}`,
			sortable: true,
		},

		{
			name: 'Количество',
			selector: (row) => `${row.instances ? `${row.instances.length} товаров` : '0 товаров'}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteResource(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteResource = (id) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${ServicesApi}deleteResource`, {
						data: {
							ResourceId: id
						}, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
					})
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						fetchData();
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении:', error);
					});
			}
		});
	};

	const [edit, setEdit] = useState(null);

	const performResourceOperation = (operationType) => {
		if (name == '' || comment == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const resource = {
				id: operationType === 'update' ? Id : uuid(),
				name: name,
				description: comment
			};

			const apiEndpoint = operationType === 'update' ? 'updateResource' : 'createResource';
			const httpMethod = operationType === 'update' ? 'put' : 'post';

			axios({
				method: httpMethod,
				url: `${ServicesApi}${apiEndpoint}`,
				data: resource,
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
			})
				.then((response) => {
					const successMessage = operationType === 'update' ? 'Ресурс обновлен!' : 'Ресурс добавлен!';
					toast.success(successMessage, {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(!modal);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const createResource = () => {
		performResourceOperation('create');
	};

	const updateResource = () => {
		performResourceOperation('update');
	};



	const [modal, setModal] = useState(false);
	const [name, setName] = useState('');
	const [Id, setId] = useState('');
	const [comment, setComment] = useState('');

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.name);
			setId(ObjectId.id);
			setComment(ObjectId.description);
			setEdit(true);
		} else {
			setName('');
			setId('');
			setComment('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={edit ? updateResource : createResource} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Комментарий</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={comment} onChange={(e) => setComment(e.target.value)} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableResources;
