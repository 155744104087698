// LeftPanel.js
import React from 'react';
import { Col, Label } from 'reactstrap';
import Select from 'react-select';

const ElementPayment = ({ setIsOverLeftPanel }) => {
	return (
		<>
			<div
				draggable="true"
				className={`col-sm-11 mb-3`}
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'payment');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Label className="col-sm-9 col-form-label">Способ оплаты</Label>
				<Col sm="12">
					<Select
						type="tel"
						placeholder="Выберите..."
						options={[
							{ label: 'Наличные', value: '1 вариант' },
							{ label: 'Безналичный расчет', value: '2 вариант' },
							{ label: 'QR-код', value: '2 вариант' },
						]}
					/>
				</Col>
			</div>
		</>
	);
};

export default ElementPayment;
