import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, CardBody, Col, Card, Input, Label, InputGroup, InputGroupText } from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { useKeycloak } from "@react-keycloak/web";

import { Breadcrumbs, H6 } from "../../../../AbstractElements";
import TableBonuses from "./TableBonuses";
import { AllProjectApi, TasksPaymentApi } from "../../../../api";
import CommonModal from "../../../UiKits/Modals/common/modal";
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const Bonuses = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
	};

	const [qualification, setQualification] = useState([]);
	const [selectQualification, setSelectQualification] = useState(null);
	const [comment, setComment] = useState("");
	const [amount, setAmount] = useState("");
	const [amountType, setAmountType] = useState("");
	const [project, setProject] = useState({ label: "Нет проекта", value: "" });
	const [allProjects, setAllProjects] = useState([]);

	const getData = async () => {
		try {
			const qualificationsResponse = await axios.get(`${TasksPaymentApi}getQualifications`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});

			const mappedQualifications = concatResults(qualificationsResponse).qualifications.map(qual => ({
				id: qual.id,
				label: qual.text,
				value: qual.value,
				rate: qual.rate,
				qualification_period: qual.qualificationPeriod,
				qualification_period_unit: qual.qualificationPeriodUnit
			}));
			setQualification(mappedQualifications);

			axios
				.get(`${AllProjectApi}get`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					const selectOptions = concatResults(response).projects.map((option) => ({
						label: option.name,
						value: option.id,
					}));
					const defaultProjectOption = { label: "Нет проекта", value: "" };
					selectOptions.unshift(defaultProjectOption);
					setAllProjects(selectOptions);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
		catch (error) {
			console.log("Ошибка получения данных");
			console.log(error);
		}
	}

	useEffect(() => {
		getData();
	}, []);

	//Добавление записи
	const createBonus = () => {
		const newBonus = {
			id: uuid(),
			comment: comment,
			totalTasksPlanned: 0,
			totalTasksCompleted: 0,
			basicRequirementsMet: 0,
			workedMoreThan40Hours: 0,
			fixedAmount: amountType === "fixed" ? true : false,
			totalAmount: amount,
			projectId: project.value !== "" ? project.value : null,
			qualificationId: selectQualification.id,
		};

		axios
			.post(`${TasksPaymentApi}createBonus`, newBonus, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				console.log(concatResults(response));
				toast.success('Бонус добавлен!', {
					position: toast.POSITION.TOP_RIGHT,
				});
				setModal(!modal);
				getData();
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Расчёт бонусов" parent="Расчёт бонусов" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Col md="4" className="mb-4">
									<Label>Проект</Label>
									<Col>
										<Select
											required
											placeholder="Начните вводить..."
											noOptionsMessage={() => "Нет результатов"}
											options={allProjects}
											value={project}
											onChange={(selectedOption) => setProject(selectedOption)}
											className="js-example-basic-single col-sm-12"
										/>
									</Col>
								</Col>
								<TableBonuses toggle={toggle} projectId={project.value} />
							</CardBody>
						</Card>
					</Col>
				</Row>
				<CommonModal isOpen={modal} title={"Добавить"} toggler={createBonus} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
					<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
						<Row className="mb-2">
							<Col md="8">
								<Label className="col-sm-9 col-form-label">Квалификация</Label>
								<Col sm="12">
									<Select
										placeholder="Начните вводить..."
										noOptionsMessage={() => "Нет результатов"}
										options={qualification}
										value={selectQualification}
										onChange={(selectedOption) => setSelectQualification(selectedOption)}
										className="js-example-basic-single col-sm-12"
									/>
								</Col>
							</Col>
							<Col md="3">
								<Label className="col-sm-9 col-form-label">Базовая ставка</Label>
								<Col sm="12">
									<InputGroup>
										<Input className="form-control" type="number" value={selectQualification ? selectQualification.rate : ""} disabled />
										<InputGroupText>{"руб/час"}</InputGroupText>
									</InputGroup>
								</Col>
							</Col>
						</Row>
						<Row className="mb-4">
							<Label className="col-sm-9 col-form-label">Примечание</Label>
							<Col sm="11">
								<Input className="form-control" placeholder="Примечание" value={comment} type="textarea" rows="3" onChange={(e) => setComment(e.target.value)} />
							</Col>
						</Row>
						<H6>Бонусы</H6>
						<div className="radio radio-primary mt-4 ms-2">
							<Input id={`radio-1`} type="radio" value={"percentage"} name={`radio-1`} onChange={(e) => setAmountType(e.target.value)} />
							<Label for={`radio-1`}>% от общей суммы</Label>
						</div>
						<div className="radio radio-primary mt-2 ms-2">
							<Input id={`radio-2`} type="radio" value={"fixed"} onChange={(e) => setAmountType(e.target.value)} name={`radio-1`} />
							<Label for={`radio-2`}>фиксированная сумма</Label>
						</div>
						<Row className="mt-3">
							<Label className="col-sm-9 col-form-label">Сумма</Label>
							<Col sm="11">
								<Input className="form-control" placeholder="Сумма" value={amount} type="number" onChange={(e) => setAmount(e.target.value)} />
							</Col>
						</Row>
						<Row className="mt-2">
							<div className="checkbox checkbox-primary">
								<Input
									id="checkbox-primary"
									type="checkbox"
								//checked={secureConnection} onChange={(e) => setSecureConnection(e.target.checked)}
								/>
								<Label for="checkbox-primary">Рассчитывать средний показатель эффективности</Label>
							</div>
						</Row>
					</CardBody>
				</CommonModal>
			</Container>
		</Fragment>
	);
};
export default Bonuses;
