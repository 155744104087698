import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

import { Breadcrumbs } from "../../../../AbstractElements";
import TableModules from "./TableModules";
import { ModulesApi } from "../../../../api";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const ModulesInformation = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get(`${ModulesApi}get-modules`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setFilteredProducts(response.data.modules);
				console.log(response.data.modules);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Управление информацией о модулях платформы" parent="Админ" title="Управление информацией о модулях платформы" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<TableModules filteredProducts={filteredProducts} setFilteredProducts={setFilteredProducts} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default ModulesInformation;
