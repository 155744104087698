import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import DataTable from 'react-data-table-component';
import { Col, Row } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import { useKeycloak } from '@react-keycloak/web';
import { TelephonyApi, TwinAuthApi } from '../../../../api';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const EmployeeWorkload = () => {
	const [callStatisticsByEmployees, setCallStatisticsByEmployees] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const getData = async () => {
		try {
			const response = await axios.get(`${TelephonyApi}getDynamicStatistics`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const callStatistics = concatResults(response).statistics;

			const userResponse = await axios.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const users = JSON.parse(userResponse.data);

			const selectOptionsUsers = users.reduce((acc, user) => {
				acc[user.id] = { label: `${user.firstName} ${user.lastName}`, value: user.id };
				return acc;
			}, {});

			const updatedStatistics = callStatistics.map(statistic => ({
				...statistic,
				employee: {
					id: statistic.userId,
					name: selectOptionsUsers[statistic.userId].label
				}
			}));

			setCallStatisticsByEmployees(updatedStatistics);
		} catch (error) {
			console.error('Ошибка получения данных:', error);
		}
	}

	useEffect(() => {
		getData()
	}, []);

	const data = {
		series: [
			{
				name: 'Входящие',
				data: callStatisticsByEmployees.map((call) => call.incomingCalls),
			},
			{
				name: 'Исходящие',
				data: callStatisticsByEmployees.map((call) => call.outgoingCalls),
			},
			{
				name: 'Пропущенные',
				data: callStatisticsByEmployees.map((call) => call.missedCalls),
			},
		],
		options: {
			chart: {
				height: 350,
				type: 'line',
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'straight',
			},

			title: {
				text: '',
				align: 'left',
			},
			grid: {
				row: {
					colors: ['#f3f3f3', 'transparent'],
					opacity: 0.5,
				},
			},
			xaxis: {
				categories: callStatisticsByEmployees.map((call) => call.employee.name),
			},
			colors: ['#54ba4a', '#ff3364', '#fb1506', '#d18a00'],
			tooltip: {
				x: {
					format: 'dd/MM/yy HH:mm',
				},
			},
		},
	};

	const columns = [
		{
			name: 'Сотрудник',
			selector: (row) => `${row.employee.name}`,
			sortable: true,
		},
		{
			name: 'Входящие',
			selector: (row) => `${row.incomingCalls}`,
			sortable: true,
		},
		{
			name: 'Исходящие',
			selector: (row) => `${row.outgoingCalls}`,
			sortable: true,
		},
		{
			name: 'Пропущенные',
			selector: (row) => `${row.missedCalls}`,
			sortable: true,
			width: '18%',
		},
		{
			name: 'Обратные',
			selector: (row) => `${row.callbackCalls}`,
			sortable: true,
		},
		{
			name: <div>Общее число звонков</div>,
			selector: (row) => `${row.totalCalls}`,
			sortable: true,
			width: '18%',
		},
	];

	return (
		<Col xl="12">
			<H5>Нагрузка по сотрудникам</H5>
			<div className="chart-container crypto-chart mb-4">
				<Row className="row">
					<ReactApexChart type="bar" height={400} series={data.series} options={data.options} />
				</Row>
			</div>
			<Col>
				<H5 attrH5={{ className: 'mb-4' }}>Распределение по сотрудникам</H5>
				<DataTable columns={columns} data={callStatisticsByEmployees} defaultSortField="name" striped pagination noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			</Col>
		</Col>
	);
};

export default EmployeeWorkload;
