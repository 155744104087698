import React, { Fragment, useState } from 'react';
import { Plus } from 'react-feather';
import { Media, Col, Label, Input, CardBody, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Btn } from '../../../../AbstractElements';
import CommonModal from '../../../UiKits/Modals/common/modal';
import axios from 'axios';
import { EmailsApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const EmailServers = ({ emailServers, type, setServerId, setServer }) => {
	const [modal, setModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
	};

	const [serverAddress, setServerAddress] = useState('');
	const [port, setPort] = useState('');
	const [logo, setLogo] = useState('');
	const [secureConnection, setSecureConnection] = useState(false);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const send = () => {
		if (port == '' || serverAddress == '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const newServer = {
				address: serverAddress,
				port: port,
				logo: logo,
				useSSL: secureConnection,
				state: type === "inbox" ? "incoming" : type,
			};

			axios
				.post(`${EmailsApi}createServer`, newServer, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Сервер добавлен!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
	};

	return (
		<Fragment>
			<Btn attrBtn={{ color: 'primary', outline: true, onClick: toggle }}>
				<Plus style={{ margin: '0 2px -3px 0px' }} size={'18px'} />
				Добавить сервер
			</Btn>
			<div className="row gallery-with-description mt-4 mb-4">
				{emailServers &&
					emailServers.map((item) => (
						<div className="col-xl-12" key={item.id} onClick={() => { setServerId(item.id); setServer(item) }} style={{ cursor: 'pointer' }}>
							<a>
								<div style={{ display: 'flex', alignItems: 'center', borderTop: '3px solid #f6f7f9', marginBottom: '15px', borderRadius: '6px', padding: '6px' }}>
									<Col md="4" style={{ display: 'flex', justifyContent: 'center' }}>
										<Media src={item.logo} alt="serverImg" className=" me-3" style={{ maxHeight: '40px', maxWidth: '80px' }} />
									</Col>
									<Col md="8">
										<div className="caption">
											<h6 className="mt-2 mb-2">{item.server_address}</h6>
										</div>
									</Col>
								</div>
							</a>
						</div>
					))}
			</div>
			<CommonModal isOpen={modal} title={'Добавить сервер'} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Адрес сервера</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={serverAddress} onChange={(e) => setServerAddress(e.target.value)} />
						</Col>
					</Col>
					<Row>
						<Col className=" col-sm-6 mb-3">
							<Label className="col-sm-9 col-form-label">Порт</Label>
							<Col sm="11">
								<Input className="form-control" type="tel" value={port} onChange={(e) => setPort(e.target.value)} />
							</Col>
						</Col>
						<Col className=" col-sm-6 mb-3">
							<div className="checkbox checkbox-primary m-t-35">
								<Input id="checkbox-primary" type="checkbox" checked={secureConnection} onChange={(e) => setSecureConnection(e.target.checked)} />
								<Label for="checkbox-primary">Использовать защищенное соединение</Label>
							</div>
						</Col>
					</Row>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Лого</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={logo} onChange={(e) => setLogo(e.target.value)} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};
export default EmailServers;
