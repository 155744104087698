import React, {Fragment, useEffect, useState} from "react";
import {Breadcrumbs} from "../../../../AbstractElements";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Media,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {useCounterpartiesContext} from "../../../../_helper/Counterparties/CounterpartiesProvider";
import {DropZone} from "@measured/puck";
import {Edit} from "react-feather";
import CounterpartySettings from "./CounterpartySettings";
import CounterpartyRequiredFields from "./CounterpartyRequiredFields";

const CounterpartiesSettings = () => {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const [counterpartyHeader, setCounterpartyHeader] = useState('Контрагент')
    const [counterpartyText, setCounterpartyText] = useState('контрагент')

    useEffect(() => {
        companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyHeader('Партнёр')
        companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyText('партнёр')
    }, [companyData]);

    const [pillWithIconTab, setpillWithIconTab] = useState("1");

    return (
        <Fragment>
            <Breadcrumbs mainTitle={`Настройка карточки ${counterpartyText}а`} parent={`${counterpartyHeader}ы`} title={`Настройка карточки ${counterpartyText}а`} />
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="12" md="12" className="box-col-12">
                            <Card>
                                <CardBody>
                                    <Nav className="nav-primary" tabs>
                                        <NavItem>
                                            <NavLink className={pillWithIconTab === "1" ? "active" : ""} onClick={() => setpillWithIconTab("1")} style={{ cursor: "pointer" }}>
                                                <Edit size={"18px"} style={{ margin: "0 5px -3px 5px" }} />
                                                Основные настройки
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={pillWithIconTab === "2" ? "active" : ""} onClick={() => setpillWithIconTab("2")} style={{ cursor: "pointer" }}>
                                                <Edit size={"18px"} style={{ margin: "0 5px -3px 5px" }} />
                                                Обязательные поля для ввода
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={pillWithIconTab}>
                                        <TabPane className="fade show" tabId='1'>
                                            <div
                                                key='1'
                                                className={"w-100 p-4"}
                                            >
                                                <CounterpartySettings />
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                    <TabContent activeTab={pillWithIconTab}>
                                        <TabPane className="fade show" tabId='2'>
                                            <div
                                                key='1'
                                                className={"w-100 p-4"}
                                            >
                                                <CounterpartyRequiredFields />
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default CounterpartiesSettings;