import React, { useState, useEffect } from 'react';
import { Card, CardBody, Media, Row, Col } from 'reactstrap';
import axios from 'axios';
import { Plus, Minus } from 'react-feather';
import { H6 } from '../../../AbstractElements';

const ServicesList = ({}) => {
	const [services, setServices] = useState('');
	useEffect(() => {
		axios
			.get(`/api/employeesDemandsForm.json`)
			.then((response) => {
				var uniqueServices = {};
				response.data.forEach(function (item) {
					item.services.forEach(function (service) {
						var serviceId = service.service_id;

						if (!uniqueServices[serviceId]) {
							uniqueServices[serviceId] = service;
						}
					});
				});

				var allServices = Object.values(uniqueServices);

				setServices(allServices);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	var images = require.context('../../../assets/images', true);

	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	const [curservices, setCurServices] = useState('');

	return (
		<Col className="mt-4">
			{services.length > 0 ? (
				services.map((item) => (
					<Card className={`b-l-secondary ${item.service_id === curservices.service_id ? 'b-secondary ' : ''}`} key={item.service_id}>
						<CardBody style={{ padding: '15px' }}>
							<Row>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div className="media-size-email" style={{ position: 'relative', padding: '5px' }}>
										<Media body className="me-3 rounded-circle img-60" src={item.image ? dynamicImage(item.image) : require('../../../assets/images/user/7.jpg')} alt="" />
									</div>
									<div style={{ flexGrow: '1' }}>
										<Row>
											<div className="f-16" style={{ width: 'inherit' }}>
												{item.service_name}
											</div>
											{item.prepayment && (
												<div style={{ width: 'inherit', backgroundColor: '#54ba4a40', padding: '2px 8px', color: '#214c1d' }} className="b-r-8">
													{item.prepayment_amount}
													{item.prepayment_unit} предоплата
												</div>
											)}
										</Row>
										<div className={' m-t-5 mb-2'} style={{ color: '#6c757d' }}>
											{item.description}
										</div>
										<H6 attrH6={{ className: 'font-secondary f-w-600' }}>{item.price} ₽</H6>
									</div>
									{item.service_id === curservices.service_id ? (
										<div style={{ fontSize: '32px', border: '3px solid #ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => setCurServices('')}>
											<Minus style={{ margin: '0 0px 4px 7px' }} />
										</div>
									) : (
										<div style={{ fontSize: '32px', background: '#ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => setCurServices(item)}>
											<Plus style={{ margin: '0 0px 2px 8px' }} />
										</div>
									)}
								</div>
							</Row>
						</CardBody>
					</Card>
				))
			) : (
				<p>Загрузка...</p>
			)}
		</Col>
	);
};

export default ServicesList;
