import React, { Fragment, useState } from 'react';
import { Container, Input, CardBody, Col, Card, Label, CardHeader, Media, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';

import { Breadcrumbs, Btn, H5, P, H4 } from '../../../../../AbstractElements';

const NonCashPayment = () => {
	const [optionalPrepayment, setOptionalPrepayment] = useState(true);

	const send = () => {
		const newEvent = {
			id: uuid(), //поставить что-то нормальное
			optional_prepayment: optionalPrepayment,
		};
		axios
			.post(`/api/optionalPrepayment.json`, newEvent)
			.then((response) => {
				//console.log(response.data);
				toast.success('Изменения сохранены!', {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Безналичная оплата" parent="Отчёты" subParent="Финансы" title="Безналичная оплата" />
			<Container fluid className="user-card">
				<Col md="12">
					<Card>
						<CardHeader>
							<div style={{ justifyContent: 'space-between', display: 'flex' }}>
								<Link to={`${process.env.PUBLIC_URL}/analytics/reports`} className="f-18">
									<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
								</Link>
							</div>
						</CardHeader>
						<CardBody>
							<H4 attrH4={{ className: 'mb-4' }}>Онлайн-предоплата</H4>
							<CardBody>
								<Col md="6">
									<Media>
										<Col sm="10">
											<H5>Необязательная предоплата</H5>
										</Col>
										<Media body className={'text-start icon-state '} style={{ flexGrow: '0' }}>
											<Label className="switch">
												<Input type="checkbox" checked={optionalPrepayment} onChange={(e) => setOptionalPrepayment(e.target.checked)} />
												<span className="switch-state"></span>
											</Label>
										</Media>
									</Media>
									<P>Будет включена для всех услуг. Клиент сможет выбрать, оплатить запись онлайн или на месте</P>
								</Col>
							</CardBody>
						</CardBody>
						<CardFooter>
							<div className="modal-footer">
								<Btn attrBtn={{ color: 'primary', onClick: send }}>Сохранить</Btn>
							</div>
						</CardFooter>
					</Card>
				</Col>
			</Container>
		</Fragment>
	);
};
export default NonCashPayment;
