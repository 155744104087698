import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import SweetAlert from 'sweetalert2';
import { MoreVertical } from 'react-feather';
import { Trash2, Edit, Eye } from 'react-feather';
import { toast } from 'react-toastify';

import { AllProjectApi, TwinAuthApi } from '../../../../api';
import { Image, P, H5 } from '../../../../AbstractElements';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const CusClass = ({ item, setData, setLoading, statuses, displayAsCards }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [curId, setCurId] = useState(null);
	const [curTypeId, setCurTypeId] = useState(null);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const handleClick = (id, typeId, event) => {
		setAnchorEl(event);
		setCurId(id);
		setCurTypeId(item.type.value);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const deleteProject = (Id) => {
		setAnchorEl(null);
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот проект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				setLoading(true);
				axios
					.delete(`${AllProjectApi}delete/${Id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						console.log(concatResults(response));
						axios
							.get(`${AllProjectApi}get`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
							.then((response) => {
								setData(concatResults(response).projects);
								setLoading(false);
							})
							.catch((error) => {
								setLoading(false);
								console.error('Ошибка получения данных:', error);
							});
					})
					.catch((error) => {
						setLoading(false);
						toast.error('Произошла ошибка при удалении проекта', {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error('Произошла ошибка при удалении проекта:', error);
					});
			}
		});
	};

	const [creator, setCreator] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			const result = await getUserName(item.creatorId);
			setCreator(result);
		};
		fetchData();
	}, []);

	async function getUserName(userId) {
		try {
			const response = await axios.get(`${TwinAuthApi}get-user?id=${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const userData = JSON.parse(response.data);
			return userData.firstName + userData.lastName;
		} catch (error) {
			console.error('Ошибка получения данных:', error);
			return null;
		}
	}

	return (
		<>
			<Col className="col-xxl-12" md="6" id={`tooltip-target-${item.id}`}>
				<div className={displayAsCards ? "project-box" : "project-box p-4 m-2"} style={displayAsCards ? { position: 'relative' } : { position: 'relative', display: 'flex' }}>
					{/* <span className={`badge badge-${typeBadge[item.typeId].color} ${typeBadge[item.typeId].color === 'light' ? 'txt-dark' : ''}`}>{typeBadge[item.typeId].title}</span> */}
					<div className={displayAsCards ? "" : "col-2"} style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Link to={`${process.env.PUBLIC_URL}/app/project/tasks-canban?projectId=${item.id}`} className="b-b-light">
							<H5>{item.name}</H5>
						</Link>
						{displayAsCards && <MoreVertical size={'18px'} onClick={(e) => handleClick(item.id, item.type.id, e.currentTarget)} aria-describedby={id} style={{ cursor: 'pointer' }} />}
					</div>
					<p className={displayAsCards ? "" : "col-4"} style={displayAsCards ? { minHeight: '60px' } : {}}>{item.description}</p>
					<Col xs="4" style={{ display: 'flex' }}>
						<P attrPara={{ className: 'm-0' }}>Статус:
							{statuses &&
								statuses.map(
									(status, i) =>
										item.state.id === status.id && (
											<span key={i} className={`badge-light-${status.color ? status.color : 'success'} rounded-pill ms-2 f-12 f-w-500`} style={{ padding: '2px 8px', backgroundColor: 'deepskyblue', color: 'white' }}>
											{status.text}
										</span>
										)
								)}
						</P>
					</Col>
					<div className="customers"></div>
					<div className="project-status mt-2">
						<div style={{ display: 'flex' }}>
							{/*<Image attrImage={{ className: 'img-20 me-1 rounded-circle', src: item.avatar ? `${require(`../../../../assets/images/user.jpg`)}` : `${item.avatar}`, alt: '' }} />{' '}*/}
							<Image attrImage={{ className: 'img-20 me-1 rounded-circle', src: `${require(`../../../../assets/images/user.jpg`)}`, alt: '' }} />{' '}
							<div className="media-body" style={{ marginLeft: '10px' }}>
								<span>{item.creatorId ? creator : 'Имя Пользователя'}</span>
							</div>
						</div>
					</div>
					{!displayAsCards && <MoreVertical size={'18px'} onClick={(e) => handleClick(item.id, item.type.id, e.currentTarget)} aria-describedby={id} style={{ cursor: 'pointer', marginLeft: 'auto' }} />}
				</div>
			</Col>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
					<Link to={`${process.env.PUBLIC_URL}/app/project/tasks-canban?projectId=${curId}`} className="pb-2 b-b-light">
						<Eye size={'18px'} style={{ margin: '0 2px -2px 4px' }} /> Просмотр задач
					</Link>
					<Link to={`${process.env.PUBLIC_URL}/app/project/edit-project?projectId=${curId}&type=${curTypeId}`} className="pb-2 mt-2 b-b-light">
						<Edit size={'18px'} style={{ margin: '0 2px -2px 4px' }} /> Редактировать проект
					</Link>
					<a className="font-primary mt-2" style={{ cursor: 'pointer' }} onClick={() => deleteProject(curId)}>
						<Trash2 size={'18px'} style={{ margin: '0 2px -2px 4px' }} /> Удалить проект
					</a>
					{/* проверить на роль */}
				</div>
			</Popover>
		</>
	);
};

export default CusClass;
