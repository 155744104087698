import React, { Fragment } from 'react';
import { Container, Row, Card, CardBody, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import DataTableClientsTypeList from "./DataTableClientsTypeList";

const ClientsTypeList = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Типы клиентов" parent="Клиенты" title="Типы клиентов" />
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="12" md="12" className="box-col-12">
                            <Card>
                                <CardBody>
                                    <DataTableClientsTypeList />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default ClientsTypeList;
