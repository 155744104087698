import React, { Fragment } from "react";
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import TableNews from "./TableNews";

const News = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Управление новостями" parent="Новости" title="Управление новостями" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<TableNews />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default News;
