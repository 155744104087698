import React, { Fragment, useState, useEffect, useMemo } from "react";
import ReactToPrint from "react-to-print";
import axios from "axios";
import { Printer, Search } from "react-feather";
import { useKeycloak } from "@react-keycloak/web";
import { Card, CardBody, CardHeader, InputGroup, Input, InputGroupText } from "reactstrap";
import { H5, Btn } from "../../../AbstractElements";
import DataTableTasks from "./DataTableTasks";
import CanbanTasks from "./CanbanTasks";
import { TaskApi, TwinAuthApi, AllProjectApi } from "../../../api";
import VerticalCanbanTasks from "./VerticalCanbanTasks";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../concatResults";

const ListOfTask = ({ UserType, title, viewType, statuses }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	let currentUserId = `${keycloak.tokenParsed.sub}`;

	const [data, setData] = useState([]);
	const [allUsersNames, setAllUsersNames] = useState([]);

	const fetchData = async () => {
		try {
			const taskResponse = await axios.get(`${TaskApi}get`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				params: {
					UserId: currentUserId,
					UserType: UserType,
				},
			});

			const tasks = concatResults(taskResponse).tasks;

			await axios
				.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					const selectOptions = JSON.parse(response.data).map((option) => ({
						label: option.firstName + " " + option.lastName,
						value: option.id,
					}));
					setAllUsersNames(selectOptions);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});

			const projectsResponse = await axios.get(`${AllProjectApi}get`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const projects = concatResults(projectsResponse).projects;

			const statuses = {};

			const statusRequests = tasks.map(async (task) => {
				if (task.projectId) {
					const project = projects.find((project) => project.id === task.projectId);
					if (project) {
						task.projectName = project.name;
					}

					if (statuses[task.statusId]) {
						task.status = statuses[task.statusId];
					} else {
						try {
							const response = await axios.get(`${AllProjectApi}getProjectStatus?statusId=${task.statusId}`, {
								headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
							});
							const projectStatuses = concatResults(response).status;
							if (projectStatuses) {
								task.status = projectStatuses;
								statuses[task.statusId] = projectStatuses;
							}
						} catch (error) {
							console.error("Ошибка получения данных:", error);
						}
					}
				}
			});

			await Promise.all(statusRequests);

			setData(tasks);
		} catch (error) {
			console.error("Ошибка получения данных:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const [filterText, setFilterText] = useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

	const subHeaderComponent = useMemo(() => {
		return (
			<InputGroup style={{ width: "60%" }}>
				<Input className="form-control" type="text" style={{ height: "32px" }} placeholder="Поиск" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
				<InputGroupText className="bg-primary">
					<Search size={"20px"} />
				</InputGroupText>
			</InputGroup>
		);
	}, [filterText, resetPaginationToggle]);

	const filteredItems = data ? data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1) : [];

	const [modalTask, setModalTask] = useState(false);
	const toggleTask = () => {
		setModalTask(!modalTask);
	};

	return (
		<Fragment>
			<Card className="">
				<CardHeader className="d-flex" style={{ paddingTop: "22px" }}>
					<H5 attrH5={{ className: "mb-0" }}>{title}</H5>
					{/* <ReactToPrint
						trigger={() => (
							<a href="#javascript">
								<Printer className="me-2" />
								Печать
							</a>
						)}
						content={() => componentRef.current}
					/> */}
					{viewType === "list" ? subHeaderComponent : ""}
				</CardHeader>
				<CardBody>
					{viewType === "list" && <DataTableTasks UserType={UserType} users={allUsersNames} data={filteredItems} setData={setData} />}
					{viewType === "kanban" && <CanbanTasks data={data} statuses={statuses} toggleTask={toggleTask} modalTask={modalTask} setModalTask={setModalTask} />}
					{viewType === "vertical-kanban" && <VerticalCanbanTasks data={data} statuses={statuses} />}
				</CardBody>
			</Card>
		</Fragment>
	);
};

export default ListOfTask;
