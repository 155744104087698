import React, { Fragment } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import TableOffersList from "./TableOffersList";

const PackageOffers = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Пакетные предложения" parent="Предложения" title="Пакетные предложения" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<TableOffersList />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default PackageOffers;
