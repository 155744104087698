import React, { Fragment, useCallback, useContext, useState, useEffect } from 'react';
import { Card, Col, Row, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import EmailContext from '../../../../_helper/Email';
import EmailTopToggle from './EmailTop';
import EmptyClass from './EmptyMail';
import axios from 'axios';
import AllEmailsClass from './AllEmailClass';
import MailContain from './MailContain';
import { EmailsApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import uuid from "react-uuid";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const MailSidebar = ({ selectedEmailId }) => {
	const { types, allEmails, type, setCompose, getAllEmailsAsyn, getAllType, groupBy, setSingleMailRecord, fetchAllEmailAsyn } = useContext(EmailContext);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [emailIds, setEmailIds] = useState([]);
	const [checked, setchecked] = useState(false);
	const mailData = [];
	const TypesOfData = types;
	console.log(types);

	useEffect(() => {
		fetchAllEmailAsyn();
	}, [companyData]);

	const selectedCompose = (email) => {
		setCompose(false);
		setSingleMailRecord(email);
	};

	const selectMailCallback = useCallback((val) => {
		selectedCompose(val);
	});

	// пагинация
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;

	const [currentItems, setCurrentItems] = useState(TypesOfData[type.id] ? TypesOfData[type.id] : type.id === 'b56cd72c-8362-4782-844a-a68ab7e6ce62' ? allEmails : []);

	useEffect(() => {
		fetchData();
	}, [selectedEmailId]);

	const fetchData = async () => {
		try {
			const response = await axios.get(`${EmailsApi}getMessages`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }, params: { emailId: selectedEmailId } })

			var allMessages = getAllMessages(concatResults(response).messages);
			setCurrentItems(allMessages);
		}
		catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	function getAllMessages(foldersData) {
		const allMessages = [];

		foldersData.forEach(folder => {
			folder.messages.forEach(message => {
				const transformedMessage = {
					id: message.id, // ID сообщения
					senderAvatar: "user/9.jpg", // Путь к аватарке отправителя
					senderName: "",//message.sender, // Имя отправителя
					senderEmail: message.fromAddress, // Email отправителя
					recipientEmail: "",//message.to, // Email получателя
					date: message.date, // Дата сообщения
					subject: message.subject, // Тема сообщения
					type: folder.name, // Тип папки, например "Inbox"
					text: {
						blocks: [
							// Преобразование текста сообщения в блоки
							{
								key: "block1", // Уникальный ключ блока
								text: message.text, // Тело сообщения
								type: "unstyled", // Тип блока
								depth: 0,
								inlineStyleRanges: [],
								entityRanges: [],
								data: {}
							}
						],
						entityMap: {}
					},
					favourite: false, // Избранное
					attachments: [] // Вложения, если они есть
				};

				allMessages.push(transformedMessage);
			});
		});

		return allMessages;
	}

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<Fragment>
			<Col xl="9" className="box-col-6">
				<div className="email-right-aside email-page">
					<Card className="email-body">
						<Row>
							<Col xl="4" md="12" className="box-md-12 pr-0">
								<div className="pe-0 b-r-light"></div>
								<EmailTopToggle emailIds={emailIds} />
								<div className="inbox custom-scrollbar">
									{currentItems.length > 0 ? currentItems.slice(indexOfFirstItem, indexOfLastItem).map((list, index) => <AllEmailsClass list={list} selectMailCallback={selectMailCallback} key={index} checked={checked} />) : <EmptyClass />}
									{currentItems.length > 0 && (
										<Pagination aria-label="Page navigation " className="pagination pagination-primary ms-3 mb-3 justify-content-end">
											<PaginationItem disabled={currentPage === 1}>
												<PaginationLink href="#" onClick={() => paginate(currentPage - 1)} aria-label="Previous">
													<span aria-hidden="true">«</span>
													<span className="sr-only">Previous</span>
												</PaginationLink>
											</PaginationItem>

											{[...Array(Math.ceil(currentItems.length / itemsPerPage)).keys()].map((number) => (
												<PaginationItem key={number} active={number + 1 === currentPage}>
													<PaginationLink onClick={() => paginate(number + 1)} href="#">
														{number + 1}
													</PaginationLink>
												</PaginationItem>
											))}

											<PaginationItem disabled={currentPage === Math.ceil(currentItems.length / itemsPerPage)}>
												<PaginationLink href="#" onClick={() => paginate(currentPage + 1)} aria-label="Next">
													<span aria-hidden="true">»</span>
													<span className="sr-only">Next</span>
												</PaginationLink>
											</PaginationItem>
										</Pagination>
									)}
								</div>
							</Col>
							<MailContain />
						</Row>
					</Card>
				</div>
			</Col>
		</Fragment>
	);
};
export default MailSidebar;
