import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { CardBody, Input, Label, Col } from "reactstrap";
import { Check } from "react-feather";
import Select from "react-select";
import InputMask from "react-input-mask";
import uuid from "react-uuid";
import { toast } from "react-toastify";

import { Btn } from "../../../../AbstractElements";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { DemandsApi } from "../../../../api";
import DemandsContext from "../../../../_helper/Demands";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const TableRequestFromClients = ({ demandFormId }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const { allDemands, getAllDemands } = useContext(DemandsContext);
	const [ demands, setDemands ] = useState(allDemands);

	const [formValues, setFormValues] = useState({});
	const handleChange = (elementId, value) => {
		setFormValues((prevValues) => ({
			...prevValues,
			[elementId]: value,
		}));
	};
	const [modal, setModal] = useState(false);
	const [demandForm, setDemandForm] = useState({});

	const [selectOptions, setSelectOptions] = useState([]);
	const [demandObject, setDemandObject] = useState({});

	useEffect(() => {
		!allDemands && getAllDemands()
	}, [companyData])

	useEffect(() => {
		setDemands(allDemands);
	}, [allDemands])

	const fetchData = async () => {
		try {
			const response = await axios.get(`${DemandsApi}getDemandForms`, {
				params: { query: demandFormId },
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const form = response.demandForms[0];
			setDemandForm(form);
		} catch (error) {
			console.error("Ошибка получения данных:", error);
		}
	};
	useEffect(() => {
		fetchData();
	}, [demandFormId]);

	const confirm = async (e) => {
		e.preventDefault();
		const idDemand = edit ? demandObject.id : uuid();
		const reqParameters = [];

		for (const item of demandForm.menuItems) {
			for (const parameter of item.parameters) {
				reqParameters.push({
					Value: JSON.stringify(formValues[parameter.elementId]),
					IdDemand: idDemand,
					IdParameterHB: parameter.id,
				});
			}
		}

		const demand = {
			id: idDemand,
			formid: demandFormId,
			parameters: reqParameters,
		};

		try {
			if (edit) {
				const parametersMap = new Map(demandObject.parametersDto.map((parameter) => [parameter.parameterHBId, parameter.id]));

				for (const parameter of reqParameters) {
					const existingId = parametersMap.get(parameter.IdParameterHB);

					if (existingId !== undefined) {
						parameter.id = existingId;
					}
				}
				const response = await axios.put(`${DemandsApi}updateDemand`, demand, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
				//console.log(concatResults(response));
				toast.success("Заявка обновлена!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			} else {
				const response = await axios.post(`${DemandsApi}createDemand`, demand, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
				//console.log(concatResults(response));
				toast.success("Заявка отправлена!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
			fetchData();
		} catch (error) {
			console.error(error);
		}

		setModal(!modal);
	};

	const columns = [
		{
			name: "№",
			selector: (row) => (
				<div className="font-primary" style={{ cursor: "pointer" }} onClick={() => toggle(row)}>
					{row.id}
				</div>
			),
			sortable: true,
		},
		{
			name: "Тип",
			selector: (row) => `${row.type}`,
			sortable: true,
		},
		{
			name: "Дата",
			selector: (row) => `${formatDate(row.date)}`,
			sortable: true,
		},
		{
			name: "Заказчик",
			selector: (row) => (
				<div style={{ fontSize: "14px" }}>
					{row.name} {row.surname}
				</div>
			),
			sortable: true,
		},
		{
			name: "Статус",
			selector: (row) => `${row.status}`,
			sortable: true,
		},
		{
			name: "С доставкой",
			selector: (row) => <div>{row.withDelivery ? <Check className="font-success ms-4" /> : ""}</div>,
			sortable: false,
		},
	];

	const [edit, setEdit] = useState(null);

	const toggle = (demandObject) => {
		console.log(demandObject);
		if (typeof demandObject === "object" && demandObject.hasOwnProperty("id")) {
			setEdit(true);
			const initialValues = {};
			for (const parameter of demandObject.parametersDto) {
				if (parameter.type === "select") {
					try {
						initialValues[parameter.elementId] = JSON.parse(parameter.value);
					} catch (error) {
						console.error(`Ошибка разбора JSON для параметра ${parameter.elementId}:`, error);
					}
				} else {
					initialValues[parameter.elementId] = parameter.value;
				}
			}
			setFormValues(initialValues);
			setDemandObject(demandObject);
		} else {
			setEdit(false);
			setFormValues({});
			setDemandObject(null);
		}
		setModal(!modal);
	};

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}

	const renderFormParameters = () => {
		if (!demandForm || !demandForm.parameters) {
			return <div>Загрузка формы...</div>;
		}

		const sortedParameters = demandForm.parameters.sort((a, b) => a.order - b.order);

		return (
			<>
				{sortedParameters.map((parameter) => (
					<Col md="6" className="mb-3" key={parameter.id}>
						<Label className="col-sm-9 col-form-label" htmlFor={parameter.elementId}>
							{parameter.name}
						</Label>
						<Col sm="12">{renderParameterInput(parameter)}</Col>
					</Col>
				))}
			</>
		);
	};

	const getSelectParameters = async (url) => {
		try {
			const response = await axios.get(url);
			const selectOptions = concatResults(response).map((option) => ({
				label: option.name,
				value: option.id,
			}));
			//console.log("Select Options:", selectOptions);
			return selectOptions;
		} catch (error) {
			console.error(`Ошибка получения данных из ${url}:`, error);
			throw error;
		}
	};

	useEffect(() => {
		const loadOptions = async () => {
			if (demandForm.parameters) {
				const selectOptionsPromises = [];

				for (const parameter of demandForm.parameters) {
					if (parameter.type === "select" && parameter.options && typeof parameter.options === "string") {
						try {
							const options = await getSelectParameters(parameter.options);
							selectOptionsPromises.push({ [parameter.elementId]: options });
						} catch (error) {
							console.error(`Ошибка загрузки опций для ${parameter.elementId}:`, error);
							selectOptionsPromises.push({ [parameter.elementId]: [] });
						}
					}
				}

				const loadedOptions = await Promise.all(selectOptionsPromises);
				const mergedOptions = Object.assign({}, ...loadedOptions);
				setSelectOptions((prevOptions) => ({ ...prevOptions, ...mergedOptions }));
			}
		};

		loadOptions();
	}, [demandForm.parameters]);

	const renderParameterInput = (parameter) => {
		if (!parameter || !parameter.elementId) {
			return null;
		}

		switch (parameter.type) {
			case "tel":
				return (
					<InputMask
						className="form-control"
						mask="+7 (999) 999-99-99"
						maskChar="_"
						placeholder="+7 (___) ___-__-__"
						alwaysShowMask
						id={parameter.elementId}
						value={formValues[parameter.elementId] || ""}
						onChange={(e) => handleChange(parameter.elementId, e.target.value)}
					/>
				);

			case "select":
				return (
					<Select
						id={parameter.elementId}
						type={parameter.type}
						placeholder="Выберите..."
						value={formValues[parameter.elementId]}
						className="js-example-basic-single col-sm-12"
						onChange={(option) => handleChange(parameter.elementId, option)}
						options={(selectOptions && selectOptions[parameter.elementId]) || []}
					/>
				);

			case "input":
				return (
					<Input
						className="form-control"
						type={parameter.type}
						id={parameter.elementId}
						value={formValues[parameter.elementId] || ""}
						onChange={(e) => handleChange(parameter.elementId, e.target.value)}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<>
			<Btn attrBtn={{ color: "primary", onClick: toggle, className: "mb-4" }}>
				<i className="fa fa-plus" style={{ fontSize: "16px", marginRight: "5px" }}></i> Добавить
			</Btn>
			<CommonModal isOpen={modal} title={edit ? "Просмотр" : "Добавить"} toggler={confirm} togglerClose={toggle} closeText={edit ? null : "Отмена"} saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>{renderFormParameters()}</CardBody>
			</CommonModal>
			<DataTable
				columns={columns}
				data={demands}
				striped
				pagination
				noDataComponent={"В данной таблице ещё нет записей."}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
		</>
	);
};

export default TableRequestFromClients;
