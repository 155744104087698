import React, { Fragment, useEffect, useState } from "react";
import { Btn, H4 } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import { BeatLoader } from "react-spinners";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { useKeycloak } from "@react-keycloak/web";
import { Row, Col, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, InputGroup, InputGroupText } from "reactstrap";
import { EditProfile, Company, Username, UsersCountryMenu, FirstName, LastName, Patronymic, Address, EmailAddress, PostalCode, Country, City } from "../../../../Constant";
import { useUsersContext } from "../../../../_helper/Users/UsersProvider";
import { toast } from 'react-toastify';

const EditMyProfile = ({ profileData }) => {
	const { companyData } = useCompanyContext();
	const { updateUserAttributes } = useUsersContext();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const onEditSubmit = (data) => {
		alert(data);
	};
	
	const { keycloak } = useKeycloak();

	const [patronymic, setPatronymic] = useState();
	const [address, setAddress] = useState();
	const [city, setCity] = useState();
	const [postcode, setPostcode] = useState();
	const [country, setCountry] = useState();
	const [telegram, setTelegram] = useState();
	const [vk, setVk] = useState();

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setPatronymic(profileData && profileData.attributes?.patronymic);
		setAddress(profileData && profileData.attributes?.address);
		setCity(profileData && profileData.attributes?.city);
		setPostcode(profileData && profileData.attributes?.postcode);
		setCountry(profileData && profileData.attributes?.country);
		setTelegram(profileData && profileData.attributes?.telegram);
		setVk(profileData && profileData.attributes?.vk);
	}, [profileData]);

	const send = () => {
		setLoading(true);
		updateUserAttributes(keycloak.tokenParsed.sub, {
			attributes: {
				patronymic: patronymic,
				address: address,
				city: city,
				postcode: postcode,
				country: country,
				telegram: telegram,
				companyId: companyData && companyData[0]?.id,
				vk: vk,
				phone: profileData && profileData.attributes?.phone,
				description: profileData && profileData.attributes?.description,
				interests: profileData && profileData.attributes?.interests
			}
		}).then(() => {
			setLoading(false);
			toast.success('Профиль обновлён!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		}).catch(() => {
			setLoading(false);
			toast.error('Ошибка при обновлении профиля', {
				position: toast.POSITION.TOP_RIGHT,
			});
		});
	};

	return (
		<Fragment>
			<Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
				<CardHeader>
					<H4 attrH4={{ className: "card-title mb-0" }}>{EditProfile}</H4>
					<div className="card-options">
						<a className="card-options-collapse" href="#javascript">
							<i className="fe fe-chevron-up"></i>
						</a>
						<a className="card-options-remove" href="#javascript">
							<i className="fe fe-x"></i>
						</a>
					</div>
				</CardHeader>
				<CardBody>
					<Row>
						<Col sm="6" md="6">
							<FormGroup>
								<Label className="form-label">{FirstName}</Label>
								<Input className="form-control" type="text" placeholder="Имя" value={keycloak.tokenParsed.given_name} disabled {...register("FirstName", { required: true })} />
								<span style={{ color: "red" }}>{errors.FirstName && "FirstName is required"} </span>
							</FormGroup>
						</Col>
						<Col sm="6" md="6">
							<FormGroup>
								<Label className="form-label">{LastName}</Label>
								<Input className="form-control" type="text" placeholder="Фамилия" disabled value={keycloak.tokenParsed.family_name} {...register("LastName", { required: true })} />
								<span style={{ color: "red" }}>{errors.LastName && "LastName is required"} </span>
							</FormGroup>
						</Col>
						<Col sm="12" md="12">
							<FormGroup>
								<Label className="form-label">{Patronymic}</Label>
								{
									profileData && profileData.attributes?.patronymic ? 
										<Input className="form-control" type="text" placeholder="Отчество" value={patronymic} disabled {...register("Patronymic", { required: false })} />
										: 
										<Input className="form-control" type="text" placeholder="Отчество" value={patronymic} onChange={(e) => setPatronymic(e.target.value)} />

								}
								<span style={{ color: "red" }}>{errors.LastName && "LastName is required"} </span>
							</FormGroup>
						</Col>
						<Col md="5">
							<FormGroup className="mb-3">
								<Label className="form-label">{Company}</Label>
								<Input className="form-control" type="text" placeholder="Организация" value={companyData && companyData.name} disabled {...register("company", { required: true })} />
								<span style={{ color: "red" }}>{errors.company && "Company is required"} </span>
							</FormGroup>
						</Col>
						<Col sm="6" md="3">
							<FormGroup>
								{" "}
								<Label className="form-label">{Username}</Label>
								<Input
									className="form-control"
									type="text"
									placeholder="Имя пользователя"
									value={keycloak.tokenParsed.preferred_username}
									disabled
									{...register("Username", { required: true })}
								/>
								<span style={{ color: "red" }}>{errors.Username && "Username is required"} </span>
							</FormGroup>
						</Col>
						<Col sm="6" md="4">
							<FormGroup>
								{" "}
								<Label className="form-label">{EmailAddress}</Label>
								<Input
									className="form-control"
									type="email"
									placeholder="your-email@domain.com"
									{...register("EmailAddress", { required: true })}
									value={keycloak.tokenParsed?.email ?? ""}
									disabled
								/>
								<span style={{ color: "red" }}>{errors.EmailAddress && "EmailAddress is required"} </span>
							</FormGroup>
						</Col>
						{
							<Col md="12">
								<FormGroup>
									<Label className="form-label">{Address}</Label>
									<Input
										className="form-control"
										type="text"
										placeholder="Домашний адрес"
										{...register("Address", { required: true })}
										value={address}
										onChange={(e) => setAddress(e.target.value)}
									/>
									<span style={{ color: "red" }}>{errors.Address && "Address is required"} </span>
								</FormGroup>
							</Col>
						}
						{
							<Col sm="6" md="4">
								<FormGroup>
									{" "}
									<Label className="form-label">{City}</Label>
									<Input className="form-control" type="text" placeholder="Город" {...register("City", { required: true })} value={city} onChange={(e) => setCity(e.target.value)} />
									<span style={{ color: "red" }}>{errors.City && "City is required"} </span>
								</FormGroup>
							</Col>
						}
						{
							<Col sm="6" md="3">
								<FormGroup>
									<Label className="form-label">{PostalCode}</Label>
									<Input className="form-control" type="number" placeholder="Почтовый индекс" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
								</FormGroup>
							</Col>
						}
						{
							<Col md="5">
								<FormGroup>
									<Label className="form-label">{Country}</Label>
									<Input type="select" name="select" className="form-control " value={country} onChange={(e) => setCountry(e.target.value)}>
										{UsersCountryMenu.map((items, i) => (
											<option key={i}>{items}</option>
										))}
									</Input>
								</FormGroup>
							</Col>
						}

						{
							<Col sm="6" md="6">
								<FormGroup>
									<Label className="form-label">
										<i className="icofont icofont-social-telegram f-18" style={{ color: "#0088cc" }}></i> Имя пользователя telegram
									</Label>
									<InputGroup>
										<InputGroupText>{"@"}</InputGroupText>
										<Input className="form-control" type="text" value={telegram} onChange={(e) => setTelegram(e.target.value)} />
									</InputGroup>
								</FormGroup>
							</Col>
						}

						{
							<Col sm="6" md="6">
								<FormGroup>
									<Label className="form-label">
										<i className="fa fa-vk f-18" style={{ color: "#4C75A3" }}></i> Имя пользователя ВКонтакте
									</Label>
									<InputGroup>
										<InputGroupText>{"@"}</InputGroupText>
										<Input className="form-control" type="text" value={vk} onChange={(e) => setVk(e.target.value)} />
									</InputGroup>
								</FormGroup>
							</Col>
						}
					</Row>
				</CardBody>
				<CardFooter className="text-end">
					<Btn attrBtn={{ color: "primary", disabled: loading, onClick: send }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
				</CardFooter>
			</Form>
		</Fragment>
	);
};
export default EditMyProfile;
