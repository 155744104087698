import React from "react";
import { ViewMode } from "gantt-task-react";
import { Plus } from "react-feather";
import { ButtonGroup, Label, Media, Input } from "reactstrap";
import { Btn } from "../../../../AbstractElements";

const ViewSwitcher = ({ onViewModeChange, onViewListChange, isChecked, view, toggleTask }) => {
	return (
		<div className="d-flex mb-4" style={{ justifyContent: "end" }}>
			<div style={{ flexGrow: "1" }}>
				<Btn attrBtn={{ color: "light", className: "ms-3 me-3", onClick: toggleTask }}>
					<Plus size="18px" style={{ margin: "0 3px -3px 0" }} /> Быстрая задача
				</Btn>
			</div>

			<ButtonGroup>
				<Btn
					attrBtn={{
						color: "light",
						active: view === ViewMode.Hour,
						onClick: () => onViewModeChange(ViewMode.Hour),
					}}
				>
					Час
				</Btn>
				<Btn
					attrBtn={{
						color: "light",
						active: view === ViewMode.QuarterDay,
						onClick: () => onViewModeChange(ViewMode.QuarterDay),
					}}
				>
					Четверть дня
				</Btn>
				<Btn
					attrBtn={{
						color: "light",
						active: view === ViewMode.HalfDay,
						onClick: () => onViewModeChange(ViewMode.HalfDay),
					}}
				>
					Половина дня
				</Btn>
				<Btn
					attrBtn={{
						color: "light",
						active: view === ViewMode.Day,
						onClick: () => onViewModeChange(ViewMode.Day),
					}}
				>
					День
				</Btn>
				<Btn
					attrBtn={{
						color: "light",
						active: view === ViewMode.Week,
						onClick: () => onViewModeChange(ViewMode.Week),
					}}
				>
					Неделя
				</Btn>
				<Btn
					attrBtn={{
						color: "light",
						active: view === ViewMode.Month,
						onClick: () => onViewModeChange(ViewMode.Month),
					}}
				>
					Месяц
				</Btn>
			</ButtonGroup>

			<Media className="ms-4">
				<Media body className={"text-start icon-state "} style={{ flexGrow: "0" }}>
					<Label className="switch">
						<Input type="checkbox" checked={isChecked} onChange={(e) => onViewListChange(e.target.checked)} />
						<span className="switch-state"></span>
					</Label>
				</Media>
				<Label className="col-form-label ms-2 ">Показать список задач</Label>
			</Media>
		</div>
	);
};
export default ViewSwitcher;
