import ExportDataContext from '.';
import React, { useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { ExportDataApi, TwinAuthApi } from '../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../Company/CompanyProvider";
import { concatResults } from '../../concatResults';
import uuid from 'react-uuid';

const ExportDataProvider = ({ children, props }) => {
    const context = useCreateExportDataContext(props);

    return (
        <ExportDataContext.Provider
            value={context}
        >
            {children}
        </ExportDataContext.Provider>
    );
};
export function useExportDataContext() {
    const context = useContext(ExportDataContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateExportDataContext = function (props) {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const companyId = companyData.map((item) => (item.attributes.companyId[0]));

    const [response, setResponse] = useState(null);
    const [responses, setResponses] = useState([]);
    const [responsesOptions, setResponsesOptions] = useState([]);

    const [serviceRequest, setServiceRequest] = useState(null);
    const [servicesRequests, setServicesRequests] = useState([]);

    const [dataTypes, setDataTypes] = useState([]);
    const [dataTypesOptions, setDataTypesOptions] = useState([]);

    const [layoutDataTypes, setLayoutDataTypes] = useState([]);
    const [layoutDataTypesOptions, setLayoutDataTypesOptions] = useState([]);
    const [layoutData, setLayoutData] = useState([]);

    const getResponses = useCallback(async (responseId) => {
        try {
            await axios
                .get(`${ExportDataApi}getResponses`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
                .then((response) => {

                    const responsesData = response.data.responses;
                    setResponses(responsesData);
                    const responseOptions = responsesData.map(response => ({
                        label: response.name,
                        value: response.id
                    }));
                    setResponsesOptions(responseOptions);

                    if (responseId) {
                        const currentResponse = responsesData.find(obj => obj.id === responseId);
                        setResponse(currentResponse);
                    }
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                    throw error;
                });
        }
        catch (error) {
            console.error('Ошибка получения данных:', error);
        }
    }, [responses]);

    const publicResponse = useCallback(async (id) => {
        await axios
            .put(`${ExportDataApi}publicResponse/${id}`, { id: id },
                { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                getResponses();
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    });


    const createResponse = async (modalData) => {
        await axios
            .post(`${ExportDataApi}createResponse`, modalData,
                { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                getResponses();
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    };

    const updateResponse = async (modalData) => {
        await axios
            .put(`${ExportDataApi}updateResponse`, modalData,
                { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                getResponses();
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    };


    const deleteResponse = async (id) => {
        try {
            await axios.delete(`${ExportDataApi}deleteResponse/${id}`, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
            });
        }
        catch (error) {
            console.error('Error deleting service request:', error);
        }
    };

    const getDataTypes = useCallback(async () => {
        try {
            await axios.get(`${ExportDataApi}getDataTypes`, {
                params: { CompanyId: companyId },
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
            }).then((response) => {
                setDataTypes(response.data.dataTypes);
                const dataTypeOptions = response.data.dataTypes.map(dataType => ({
                    label: dataType.typeName,
                    value: dataType.id
                }));
                setDataTypesOptions(dataTypeOptions);
            });
        } catch (error) {
            console.error('Ошибка получения данных:', error);
        }
    }, [dataTypes]);


    const createDataType = async (modelData) => {
        await axios
            .post(`${ExportDataApi}createDataType`, modelData,
                { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                getDataTypes();
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    };

    const updateDataType = async (modelData) => {
        await axios
            .put(`${ExportDataApi}editDataType/${modelData.id}`, modelData,
                { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                getDataTypes();
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    };

    const deleteDataType = async (id) => {
        try {
            await axios.delete(`${ExportDataApi}deleteDataType/${id}`, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
            });
        }
        catch (error) {
            console.error('Error deleting service request:', error);
        }
    };

    //const getServicesRequests = useCallback(async () => {
    //    try {
    //        await axios.get(`${ExportDataApi}getServicesRequests`, {
    //            params: { CompanyId: companyId },
    //            headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
    //        }).then(async (response) => {
    //            setServicesRequests(response.data.serviceRequests)
    //        });
    //    } catch (error) {
    //        console.error('Ошибка получения данных:', error);
    //    }
    //}, [servicesRequests]);
    const getServicesRequests = async () => {
        try {
            const response = await axios.get(`${ExportDataApi}getServicesRequests`, {
                params: { CompanyId: companyId },
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
            });

            setServicesRequests(response.data.serviceRequests);
            return response.data.serviceRequests;

        } catch (error) {
            console.error('Ошибка получения данных:', error);
        }
    };

    const createServiceRequest = async (currentServiceRequest) => {
        currentServiceRequest.id = uuid();
        await axios
            .post(`${ExportDataApi}createServicesRequests`, currentServiceRequest,
                { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    };

    const updateServiceRequest = async (currentServiceRequest) => {
        await axios
            .put(`${ExportDataApi}updateServicesRequests`, currentServiceRequest,
                { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    };

    const deleteServiceRequest = async (id) => {
        try {
            axios.delete(`${ExportDataApi}deleteServicesRequests/${id}`, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
            });
        }
        catch (error) {
            console.error('Error deleting service request:', error);
        }
    };

    const getLayoutDataTypes = useCallback(async () => {
        try {
            await axios.get(`${ExportDataApi}getLayoutDataTypes`, {
                params: { CompanyId: companyId },
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
            }).then((response) => {
                setLayoutDataTypes(response.data.layoutDataTypes);
                const layoutDataTypeOptions = response.data.layoutDataTypes.map(obj => ({
                    label: obj.layoutName,
                    value: obj.id
                }));
                setLayoutDataTypesOptions(layoutDataTypeOptions);
            });
        } catch (error) {
            console.error('Ошибка получения данных:', error);
        }
    }, [layoutDataTypes]);

    const getLayoutDataTypeDetails = useCallback(async (layoutId) => {
        try {
            await axios.get(`${ExportDataApi}getLayoutDataTypeDetails/${layoutId}`, {
                params: { CompanyId: companyId },
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
            }).then((response) => {
                var respLayout = JSON.parse(response.data);
                setLayoutData(respLayout);
            });
        } catch (error) {
            console.error('Ошибка получения данных:', error);
        }
    }, []);

    const verifyLayoutDataType = useCallback(async (layoutId, layoutValue, companyId) => {
        try {
            const response = await axios.get(`${ExportDataApi}verifyLayoutDataType`, {
                params: {
                    Id: layoutId,
                    Layout: layoutValue,
                    CompanyId: companyId
                },
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
            });
            return response.data.result;
        } catch (error) {
            console.error('Ошибка получения данных:', error);
            throw error;
        }
    }, []);

    const createLayoutDataType = async (modelData) => {
        modelData.id = uuid();
        await axios
            .post(`${ExportDataApi}createLayoutDataType`, modelData,
                { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                getLayoutDataTypes();
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    };

    const updateLayoutDataType = async (modelData) => {
        await axios
            .put(`${ExportDataApi}updateLayoutDataType/${modelData.id}`, modelData,
                { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                getLayoutDataTypes();
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    };

    const deleteLayoutDataType = async (id) => {
        try {
            await axios.delete(`${ExportDataApi}deleteLayoutDataType/${id}`, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
            });
        }
        catch (error) {
            console.error('Error deleting service request:', error);
        }
    };


    //const getMappingData = useCallback(async (responseId) => {
    //    try {
    //        await axios.get(`${ExportDataApi}getMappingData`, {
    //            params: {
    //                responseId: response.id,
    //                companyId: companyId ? companyId : undefined
    //            },
    //            headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
    //        }).then((result) => {
    //            return result;
    //        });
    //    } catch (error) {
    //        console.error('Ошибка получения данных:', error);
    //    }
    //}, []);
    const getMappingData = async (responseId) => {
        try {
            const result = await axios.get(`${ExportDataApi}getMappingData`, {
                params: {
                    responseId: response.id,
                    companyId: companyId ? companyId : undefined
                },
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId }
            });
            return result.data;
        } catch (error) {
            console.error('Ошибка получения данных:', error);
        }
    };

    //const getMappingData = await axios.get(`${ExportDataApi}getMappingData`, {
    //    params: {
    //        responseId: response.id,
    //        companyId: companyId.length > 0 ? companyId[0] : undefined
    //    },
    //    headers: { Authorization: `Bearer ${keycloak.token}` }
    //});


    return {
        getResponses,
        publicResponse,
        getServicesRequests,
        getDataTypes,
        getLayoutDataTypes,
        getLayoutDataTypeDetails,
        getMappingData,
        verifyLayoutDataType,

        setResponse,
        setResponses,
        setServiceRequest,
        setServicesRequests,
        setDataTypes,
        setDataTypesOptions,
        setLayoutDataTypes,
        setLayoutDataTypesOptions,
        setLayoutData,

        response,
        responses,
        responsesOptions,
        serviceRequest,
        servicesRequests,
        dataTypes,
        dataTypesOptions,
        layoutDataTypes,
        layoutDataTypesOptions,
        layoutData,

        createServiceRequest,
        updateServiceRequest,
        deleteServiceRequest,

        createResponse,
        updateResponse,
        deleteResponse,

        createDataType,
        updateDataType,
        deleteDataType,

        createLayoutDataType,
        updateLayoutDataType,
        deleteLayoutDataType
    };
}

export default ExportDataProvider;