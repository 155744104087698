// LeftPanel.js
import React, { useState } from 'react';
import { Trash2, FilePlus } from 'react-feather';
import { Row } from 'reactstrap';
import uuid from 'react-uuid';
import ElementTel from './Elements/ElementTel';
import { Btn } from '../../../../AbstractElements';
import ElementDate from './Elements/ElementDate';
import ElementTime from './Elements/ElementTime';
import ElementSelectType from './Elements/ElementSelectType';
import ElementInputName from './Elements/ElementInputName';
import ElementInputCity from './Elements/ElementInputCity';
import ElementInputAddress from './Elements/ElementInputAddress';
import ElementComment from './Elements/ElementComment';
import ElementClient from './Elements/ElementClient';
import ElementProductTable from '../FormBuilder/Elements/ElementProductTable';
import ElementPayment from './Elements/ElementPayment';

const LeftPanel = ({ droppedItems, onDrop, onRemove, isOverLeftPanel }) => {
	const handleDrop = (e) => {
		e.preventDefault();
		setElementType(e.dataTransfer.getData('text'));
		console.log(e.dataTransfer.getData('text'));

		const item = {
			elementId: uuid(),
			type: e.dataTransfer.getData('text'),
			order: droppedItems.length + 1,
		};

		onDrop(item);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	// <h2>Форма</h2>

	const [elementType, setElementType] = useState('');

	return (
		<>
			<div className={`left-panel ${isOverLeftPanel ? 'highlight' : ''}`} onDrop={handleDrop} onDragOver={handleDragOver}>
				{droppedItems.length > 0 ? (
					droppedItems.map((item, index) =>
						item.type === 'selectType' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementSelectType key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : item.type === 'name' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementInputName key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : item.type === 'city' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementInputCity key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : item.type === 'date' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementDate key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : item.type === 'time' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementTime key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : item.type === 'address' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementInputAddress key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : item.type === 'comment' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementComment key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : item.type === 'tel' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementTel key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : item.type === 'client' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementClient key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : item.type === 'ProductsOrderTable' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementProductTable key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : item.type === 'payment' ? (
							<Row style={{ alignItems: 'center' }}>
								<ElementPayment key={index} />
								<a href="#" onClick={() => onRemove(index)} style={{ width: '32px', height: '32px' }}>
									<Trash2 />
								</a>
							</Row>
						) : (
							<li key={index} draggable="true" className="draggable-item" onDragStart={(e) => e.dataTransfer.setData('text/plain', item)}>
								{item}
							</li>
						)
					)
				) : (
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '16px', marginTop: '15px' }}>
						<FilePlus color="var(--theme-default)" size={'34px'} className="mb-4" />
						<div>Выберите элементы и перетащите их в данное окно</div>
					</div>
				)}
			</div>

			<div className="modal-footer" style={{ paddingTop: '40px' }}>
				<Btn attrBtn={{ color: 'primary', disabled: true }}>Отправить</Btn>
			</div>
		</>
	);
};

export default LeftPanel;
