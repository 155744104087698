import React, { Fragment } from 'react';
import { Card, CardBody, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableCalendarList from './TableCalendarList';

const CalendarList = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список календарей" parent="Календари" title="Список календарей" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<TableCalendarList />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default CalendarList;
