import React from "react";
import { Trash2 } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";

const TableModules = ({ filteredProducts, setFilteredProducts, deleteModule }) => {
	const columns = [
		{
			name: "Название",
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: "Описание",
			selector: (row) => `${row.description}`,
			sortable: true,
		},
		{
			name: "Стоимость",
			selector: (row) => `${row.price} ₽`,
			sortable: true,
		},
		// {
		// 	name: "Активность",
		// 	selector: (row) => (
		// 		<Media>
		// 			<Media body className="text-end icon-state">
		// 				<Label className="switch">
		// 					<Input type="checkbox" id={`checkbox-${row.id}`} checked={row.isActive} onChange={() => updActivity(row)} />
		// 					<span className="switch-state"></span>
		// 				</Label>
		// 			</Media>
		// 		</Media>
		// 	),
		// 	sortable: true,
		// },
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteModule(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	// // put для обновления активности модуля
	// const updActivity = (item) => {
	// 	const updUser = {
	// 		...item,
	// 		isActive: !item.isActive,
	// 	};
	// 	// axios
	// 	// 	.put(`/api/clientsDemandsForm.json`, updUser)
	// 	// 	.then((response) => {
	// 	// 		console.log(response.data);
	// 	// 		toast.success("Доступ добавлен!", {
	// 	// 			position: toast.POSITION.TOP_RIGHT,
	// 	// 		});
	// 	const rowIndex = filteredProducts.findIndex((row) => row.id === item.id);
	// 	const newData = [...filteredProducts];
	// 	newData[rowIndex] = {
	// 		...newData[rowIndex],
	// 		isActive: !newData[rowIndex].isActive,
	// 	};
	// 	setFilteredProducts(newData);
	// 	// 	})
	// 	// 	.catch((error) => {
	// 	// 		toast.error("Ошибка при добавлении доступа", {
	// 	// 			position: toast.POSITION.TOP_RIGHT,
	// 	// 		});
	// 	// 		console.error(error);
	// 	// 	});
	// };

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredProducts}
				defaultSortField="name"
				striped
				noDataComponent={"Модули не добавлены"}
				pagination
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
		</>
	);
};

export default TableModules;
