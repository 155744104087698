import { Breadcrumbs } from '../../../AbstractElements';
import React, { Fragment } from 'react';
import { CardBody, Container, Row, Card } from 'reactstrap';
import DataTableDiagramList from './DataTableDiagramList';

const DetailCourse = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список бизнес-процессов" parent="Бизнес-процессы" title="Список бизнес-процессов" />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardBody>
							<DataTableDiagramList />
						</CardBody>
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};

export default DetailCourse;
