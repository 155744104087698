import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, Container, Button, Row, Col } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import TileResponseSettings from './TableResponsesSettings';

const ResponseSettings = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Настройка файлов экспорта" parent="Экспорт данных" title="Настройка файлов экспорта для получателей" />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <TileResponseSettings />
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default ResponseSettings;
