import React from 'react';
import DataTable from 'react-data-table-component';

const Progress = ({ products }) => {
	const columns = [
		{
			name: 'Имя',
			selector: (row) => (
				<div>
					{row.name} {row.surname ? row.surname : ''}
				</div>
			),
			sortable: true,
			hide: 'sm',
			width: '50%',
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: true,
			width: '40%',
		},
		{
			name: 'Баллы',
			selector: (row) => `${row.assessment}`,
			sortable: true,
			width: '10%',
		},
	];

	return (
		<>
			<DataTable columns={columns} data={products} defaultSortField="name" striped pagination noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</>
	);
};

export default Progress;
