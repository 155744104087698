import UsersContext from '.';
import React, { useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { TwinAuthApi } from '../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../Company/CompanyProvider";

const UsersProvider = ({ children, props }) => {
    const context = useCreateUsersContext(props);

    return (
        <UsersContext.Provider
            value={context}
        >
            {children}
        </UsersContext.Provider>
    );
};

export function useUsersContext() {
    const context = useContext(UsersContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateUsersContext = function (props) {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();

    const [usersByIdArray, setUsersByIdArray] = useState();
    const [userById, setUserById] = useState();

    const getUsersByIdArray = useCallback(async (idArray) => {
        await axios
            .post(`${TwinAuthApi}get-users-by-idArray`, idArray, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                setUsersByIdArray(JSON.parse(response.data));
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
        return usersByIdArray;
    }, [usersByIdArray]);

    const getUserById = useCallback(async (id) => {
        await axios
            .get(`${TwinAuthApi}get-user?id=${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                //console.log(id);
                setUserById(JSON.parse(response.data));
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                //throw error;
            });
        return userById;

    }, [userById])

    const updateUserAttributes = async (userId, attributes, image) => {
        console.log(image);
        await axios
            .put(`${TwinAuthApi}update-user-attributes`, {
                userId: userId,
                image: image,
                json: JSON.stringify(attributes),
            }
            , { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
                throw error;
            });
    };

    return {
        getUsersByIdArray,
        getUserById,
        userById,
        usersByIdArray,
        updateUserAttributes
    };
}

export default UsersProvider;