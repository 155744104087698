import React, { Fragment, useState, useEffect, useContext } from "react";
import axios from "axios";
import { Container, Row, CardBody, Card, I } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import { TwinAuthApi } from "../../../../api";
import TableDepartmentManagement from "./TableDepartmentManagement";
import DnDFlow from "./DnDFlow";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import DnDContext from "../../../../_helper/DnD";

const DepartmentManagement = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { departments, getDepartments } = useContext(DnDContext);

	// const [selectUser, setSelectUser] = useState(null);
	// const [selectDepartment, setSelectDepartment] = useState(null);

	const [allUsersNames, setAllUsersNames] = useState([]);
	const [allDepartment, setAllDepartment] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/departments.json`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				const selectOptions = response.data.departments.map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setAllDepartment(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
					login: option.login,
				}));
				setAllUsersNames(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

			getDepartments();
	}, []);

	const [IconWithTab, setIconWithTab] = useState("1");

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Управление отделами" parent="Сотрудники" title="Управление отделами" />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardBody className="email-wrap">
							<Nav tabs className="border-tab nav-primary">
								<NavItem>
									<NavLink className={`${IconWithTab === "1" ? "active" : ""} f-16`} onClick={() => setIconWithTab("1")} style={{ cursor: "pointer" }}>
										Список отделов
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={`${IconWithTab === "2" ? "active" : ""} f-16`} onClick={() => setIconWithTab("2")} style={{ cursor: "pointer" }}>
										Структура компании
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={IconWithTab}>
								<TabPane className="fade show p-t-10" tabId="1">
									<TableDepartmentManagement allDepartment={allDepartment} allUsersNames={allUsersNames} />
								</TabPane>
								<TabPane tabId="2" style={{ padding: "20px", height: "80vh" }}>
									{departments && <DnDFlow companyData={companyData} allDepartment={allDepartment} allUsersNames={allUsersNames} />}
								</TabPane>
							</TabContent>
						</CardBody>
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};
export default DepartmentManagement;
