import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane, Form } from "reactstrap";
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Breadcrumbs, H5 } from "../../../../AbstractElements";
import EditPages from "./EditPagesList/Index";
import SetThemeSidebar from "./SetThemeSidebar";
import SetTheme from "./SetTheme";
import SettingsAuth from "./SettingsAuth";
import { CmsApi } from "../../../../api";
import FooterEditor from "./FooterEditor";
import BodyEditor from "./BodyEditor";
import HeaderEditor from "./HeaderEditor";
import ExportImport from "./ExportImport";
import TemplateEditor from "./TemplateEditor";
import DomenEditor from "./DomainEditor";
import { concatResults } from "../../../../concatResults";
import { useKeycloak } from "@react-keycloak/web";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const SiteBuilder = () => {
	const [primarycolorTab, setprimarycolorTab] = useState("1");

	const { siteId } = useParams();

	const [item, setItem] = useState("");
	const [pages, setPages] = useState([]);
	const [loading, setLoading] = useState(false);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		setLoading(true);
		try {
			axios
				.get(`${CmsApi}getSites`, { params: { query: "" } }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					const foundItem = response.data.sites.find((item) => item.id === siteId);
					setItem(foundItem);
					setPages(foundItem.pages);
					setLoading(false);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
					setLoading(false);
				});
		}
		catch (error) {

		}
	}, []);

	const getUploadParams = ({ meta }) => {
		return { url: "https://httpbin.org/post" };
	};
	const handleChangeStatus = ({ meta, file }, status) => { };
	const handleSubmit = (files, allFiles) => {
		allFiles.forEach((f) => f.remove());
		toast.success("Файл загружен!");
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={`${item.name} - конструткор сайта`} parent="Список сайтов" title="Конструткор сайта" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<Row>
								<Col sm="3" xs="12">
									<Nav className="nav flex-column nav-pills">
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "1" ? "active" : ""} onClick={() => setprimarycolorTab("1")}>
												Редактор страниц
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "2" ? "active" : ""} onClick={() => setprimarycolorTab("2")}>
												Редактор меню
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "8" ? "active" : ""} onClick={() => setprimarycolorTab("8")}>
												Редактор шапки сайта
											</NavLink>
										</NavItem>
										{/* <NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "3" ? "active" : ""} onClick={() => setprimarycolorTab("3")}>
												Редактор тела сайта
											</NavLink>
										</NavItem> */}
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "4" ? "active" : ""} onClick={() => setprimarycolorTab("4")}>
												Редактор подвала сайта
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "5" ? "active" : ""} onClick={() => setprimarycolorTab("5")}>
												Управление логотипом
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "6" ? "active" : ""} onClick={() => setprimarycolorTab("6")}>
												Установка темы
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "7" ? "active" : ""} onClick={() => setprimarycolorTab("7")}>
												Настройки авторизации
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "9" ? "active" : ""} onClick={() => setprimarycolorTab("9")}>
												Экспорт и импорт
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "10" ? "active" : ""} onClick={() => setprimarycolorTab("10")}>
												Шаблон сайта
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "11" ? "active" : ""} onClick={() => setprimarycolorTab("11")}>
												Домены
											</NavLink>
										</NavItem>
									</Nav>
								</Col>
								<Col sm="9" xs="12">
									<TabContent activeTab={primarycolorTab}>
										<TabPane className="fade show" tabId="1">
											<EditPages loading={loading} products={pages} id={item.id} setProducts={setPages} />
										</TabPane>
										<TabPane tabId="2">
											<SetThemeSidebar products={item.pages} />
										</TabPane>
										<TabPane tabId="3">
											<BodyEditor />
										</TabPane>
										<TabPane tabId="4">
											<FooterEditor />
										</TabPane>
										<TabPane tabId="5">
											<H5>Управление логотипом</H5>
											<Form>
												<ToastContainer />
												<div className="dz-message needsclick">
													<Dropzone
														getUploadParams={getUploadParams}
														onChangeStatus={handleChangeStatus}
														onSubmit={handleSubmit}
														inputContent="Выбрать файл или перетащить сюда"
														accept="image/*"
														submitButtonContent="Отправить"
													/>
												</div>
											</Form>
										</TabPane>
										<TabPane tabId="6">
											<SetTheme />
										</TabPane>
										<TabPane tabId="7">
											<SettingsAuth />
										</TabPane>
										<TabPane tabId="8">
											<HeaderEditor />
										</TabPane>
										<TabPane tabId="9">
											<ExportImport siteName={item.name} siteDescription={item.description} pages={pages} />
										</TabPane>
										<TabPane tabId="10">
											<TemplateEditor />
										</TabPane>
										<TabPane tabId="11">
											<DomenEditor siteId={siteId} />
										</TabPane>
									</TabContent>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default SiteBuilder;
