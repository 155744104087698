import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Calendar, UserCheck, Users } from "react-feather";

import { Breadcrumbs, H5, H6 } from "../../../AbstractElements";
import EventsCalendar from "./EventsCalendar";
import MeetingCalendar from "./MeetingCalendar";
import SigningUpCalendar from "./SigningUpCalendar";
import { useCalendarContext } from "../../../_helper/Calendar/CalendarProvider";
import { useKeycloak } from "@react-keycloak/web";

const CalenderContain = () => {
	const {keycloak} = useKeycloak();
	const [IconWithTab, setIconWithTab] = useState("1");
	const [calendarType, setCalendarType] = useState("events");

	const {
        getCalendarData,
		calendarData,
		getCalendarEventsData,
		dropCalendarData
	} = useCalendarContext();

	useEffect(() => {
		setCalendarType(IconWithTab === "1" ? "events" : IconWithTab === "2" ? "demands" : "meetings");
		calendarData && getCalendarEventsData(calendarData.find((items) => items.calendarType === (IconWithTab === "1" ? "events" : IconWithTab === "2" ? "demands" : "meetings")).id);
		//dropCalendarData();
	}, [IconWithTab, calendarData])

	useEffect(() => {
		if (keycloak.authenticated && keycloak.tokenParsed.sub) {
			getCalendarData();
		}
	}, [keycloak]);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Личный календарь" parent="Календари" title="Личный календарь" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Nav tabs>
									<NavItem>
										<NavLink style={{ cursor: "pointer" }} className={`${IconWithTab === "1" ? "active" : ""} f-16`} onClick={() => setIconWithTab("1")}>
											<UserCheck size={"20px"} className="me-2" />
											Событие
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink style={{ cursor: "pointer" }} className={`${IconWithTab === "2" ? "active" : ""} f-16`} onClick={() => setIconWithTab("2")}>
											<Calendar size={"20px"} className="me-2" />
											Запись на услугу
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink style={{ cursor: "pointer" }} className={`${IconWithTab === "3" ? "active" : ""} f-16`} onClick={() => setIconWithTab("3")}>
											<Users size={"20px"} className="me-2" />
											Встреча
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent activeTab={IconWithTab}>
									<TabPane className="fade show p-t-30" tabId="1">
										<EventsCalendar calendarType={calendarType} />
									</TabPane>
									<TabPane tabId="2" className="fade show p-t-30">
										<SigningUpCalendar calendarType={calendarType} />
									</TabPane>
									<TabPane tabId="3" className="fade show p-t-30">
										<MeetingCalendar calendarType={calendarType} />
									</TabPane>
								</TabContent>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default CalenderContain;
