import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Row, CardBody, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import CommonModal from "../../../UiKits/Modals/common/modal";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import {Edit, Trash2} from "react-feather";
import SweetAlert from "sweetalert2";
import {toast} from "react-toastify";
import FilterComponent from "../../Widgets/InteractivePlanMap/FilterComponent";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";

const DataTableClientsCategoryList = () => {
    const [name, setName] = useState();
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const {keycloak} = useKeycloak();
    const {companyData} = useCompanyContext();
    const {getClientCategories, clientCategories, createClientCategory, updateClientCategory, deleteClientCategory} = useClientsContext();

    useEffect(() => {
        clientCategories && setFilteredItems(clientCategories.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, clientCategories]);

    useEffect(() => {
        getClientCategories();
    }, [keycloak.tokenParsed, companyData]);

    const toggle = (id) => {
        setModal(!modal);
        setId(null);
        if (id) {
            let category = clientCategories.find(c => c.id === id);
            category && setName(category?.name ?? "");
            setId(id);
        }
    }

    const deleteRow = (categoryId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'После удаления вы не сможете восстановить этот объект!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                deleteClientCategory(categoryId).then(() => {
                    getClientCategories();
                    setName('');
                    toast.success('Категория удалена!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
            }
        });
    };

    const handleSendRequest = () => {
        if (id) {
            updateClientCategory(id, name).then(() => {
                getClientCategories();
                setName('');
                toast.success('Категория обновлена', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }
        else {
            createClientCategory(name).then(() => {
                getClientCategories();
                setName('');
                toast.success('Категория добавлена', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }

        toggle();
    };

    const tableColumns = [
        {
            name: 'Категория',
            selector: (row) => `${row.name}`,
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{display: 'flex'}}>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <div
                            className="font-primary"
                            style={{ cursor: 'pointer', marginRight: '5px' }}
                            onClick={() => toggle(row.id)}
                        >
                            <Edit size={'18px'} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'}/>
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={() => toggle(null)} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={`${id ? 'Редактирование' : 'Добавление новой'} категории клиента`} toggler={handleSendRequest} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Название
                                </Label>
                                <Col sm="11">
                                    <Input
                                        className="form-control"
                                        value={name}
                                        placeholder="Название категории"
                                        onChange={(e) => setName(e.target.value)} />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTableClientsCategoryList;