import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Container, Col, Label, Input, Row, CardHeader, CardFooter } from "reactstrap";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";

import { Breadcrumbs, Btn } from "../../../../../AbstractElements";
import { Tabs } from "./Tabs";
import { CodeExecutor } from "../../../Sites/SiteBuilder/CodeEditor/CodeExecutor";
import { CodeEditor } from "../../../Sites/SiteBuilder/CodeEditor/CodeEditor";

const CreateTemplateNews = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const templateId = queryParams.get("templateId");

	const [initialContent, setInitialContent] = useState("");

	const [item, setItem] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	useEffect(() => {
		axios
			.get(`/api/initialHtml_news.json`, { params: { query: "" } })
			.then((response) => {
				setInitialContent(response.data.content);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`/api/news_templates.json`, { params: { query: "" } })
			.then((response) => {
				const foundItem = response.data.news.find((item) => item.id === templateId);
				setItem(foundItem);
				if (foundItem.content) {
					handleReturn(foundItem.content);
				} else {
					handleReturn(initialContent);
				}
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, [templateId]);

	const [mode, setMode] = useState("html");
	const [html, setHtml] = useState("");
	const [css, setCss] = useState("");
	const [srcDoc, setSrcDoc] = useState("");

	const runCode = () => {
		const content = `<style>${css}</style>
        <body> ${html}</body>`;
		setSrcDoc(content);

		console.log(JSON.stringify(content));
	};

	const propsByMode = {
		html: {
			mode: "xml",
			value: html,
			setValue: setHtml,
		},
		css: {
			mode: "css",
			value: css,
			setValue: setCss,
		},
	};

	const handleReturn = (content) => {
		setSrcDoc(content);
		setHtml(extractBodyContent(content));
		setCss(extractStyleContent(content));
	};

	function extractBodyContent(htmlString) {
		var match = htmlString.match(/<body>([\s\S]*?)<\/body>/);

		if (match && match.length > 1) {
			return match[1];
		} else {
			return "";
		}
	}

	function extractStyleContent(htmlString) {
		var match = htmlString.match(/<style>([\s\S]*?)<\/style>/);

		if (match && match.length > 1) {
			return match[1];
		} else {
			return "";
		}
	}

	//обновление шаблона
	const updateForm = () => {
		const form = {
			id: templateId,
			name: item.description,
			description: item.description,
			isActive: false,
			content: JSON.stringify(srcDoc),
		};
		console.log(form);

		// axios
		// 	.put(`${CmsApi}updatePage`, form)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success("Страница изменена!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setEditModal(false);
		// 		fetchData();
		// 	})
		// 	.catch((error) => {
		// 		console.error("Ошибка получения данных:", error);
		// 	});
	};

	//добавление шаблона
	const createForm = () => {
		const form = {
			name: description,
			description: description,
			isActive: false,
			content: JSON.stringify(srcDoc),
		};
		console.log(form);

		// axios
		// 	.put(`${CmsApi}updatePage`, form)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success("Страница изменена!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setEditModal(false);
		// 		fetchData();
		// 	})
		// 	.catch((error) => {
		// 		console.error("Ошибка получения данных:", error);
		// 	});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={item ? `Редактировать шаблон новостей` : `Добавить шаблон новостей`} parent="Новости" title={item ? `Редактировать шаблон новостей` : `Добавить шаблон новостей`} />
			<Container fluid={true}>
				<div className="email-wrap">
					<Card>
						<CardHeader>
							<Link to={`${process.env.PUBLIC_URL}/news/news-template`} className="f-18">
								<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
							</Link>
							<button onClick={() => handleReturn(initialContent)} style={{ background: "none", border: "none", color: "#7366ff", fontSize: "16px", marginTop: "5px", float: "right" }}>
								<i className="fa fa-undo" style={{ marginRight: "5px" }}></i>Вернуться к стандартному шаблону
							</button>
						</CardHeader>
						<CardBody>
							<Col className="row col-sm-12 mb-3">
								<Label className="col-sm-9 col-form-label">Название</Label>
								<Col sm="12">
									{item ? (
										<Input disabled className="form-control" type="text" value={item.name} />
									) : (
										<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
									)}
								</Col>
							</Col>
							<Col className="row col-sm-12 mb-4">
								<Label className="col-sm-9 col-form-label">Описание</Label>
								<Col sm="12">
									{item ? (
										<Input disabled className="form-control" type="text" value={item.description} />
									) : (
										<Input className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
									)}
								</Col>
							</Col>

							<Col md="12">
								<Tabs mode={mode} setMode={setMode} runCode={runCode} />
								<CodeEditor {...propsByMode[mode]} />
							</Col>
							<Col md="12">
								<CodeExecutor srcDoc={srcDoc} height="40vh" />
							</Col>
						</CardBody>

						<CardFooter>
							<Btn attrBtn={{ color: "primary", className: "f-right", onClick: item ? updateForm : createForm }}>Сохранить изменения</Btn>
						</CardFooter>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default CreateTemplateNews;
