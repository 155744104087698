import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { Label, Input, CardBody, Col } from "reactstrap";

import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { TelephonyApi, TwinAuthApi } from "../../../../api";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const TableUsersList = ({ products, mainData }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [editModal, setEditModal] = useState(false);

	const [name, setName] = useState("");
	const [id, setId] = useState("");

	const [deviceStatus, setDeviceStatus] = useState(null);
	const [outgoingNumber, setOutgoingNumber] = useState("");
	const [internalNumber, setInternalNumber] = useState("");
	const [password, setPassword] = useState("");
	const [edit, setEdit] = useState(null);

	const toggle = (user) => {
		if (typeof user === "object" && user.hasOwnProperty("id")) {
			setName(user.user.name);
			user.phone_device_status ? setDeviceStatus({ label: "Подключен", value: true }) : setDeviceStatus({ label: "Не подключен", value: false });
			setOutgoingNumber(user.outgoing_call_number);
			setInternalNumber(user.internal_number);
			setId(user.id);
			setPassword(user.password_sip);
			setEdit(true);
		} else {
			setName("");
			setId("");
			setDeviceStatus(null);
			setOutgoingNumber("");
			setInternalNumber("");
			setPassword("");
			setEdit(false);
		}

		setEditModal(!editModal);
	};

	const [pbxlist, setPbxlist] = useState([]);

	const getData = async () => {
		try {
			const response = await axios.get(`${TelephonyApi}getPbx`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});

			const selectOptions = concatResults(response).pbxList.map((option) => ({
				label: option.name,
				value: option.id,
			}));

			setPbxlist(selectOptions);
		} catch (error) {}
	};

	useEffect(() => {
		getData();
	}, []);

	const columns = [
		{
			name: "Пользователь",
			selector: (row) => `${row.user.name}`,
			sortable: true,
		},
		{
			name: "Внутренний номер",
			selector: (row) => `${row.internal_number}`,
			sortable: true,
		},
		{
			name: "Номер для исходящего звонка",
			selector: (row) => `${row.outgoing_call_number.name}`,
			sortable: true,
		},
		{
			name: "Телефонный аппарат",
			selector: (row) => `${row.phone_device_status ? "Подключен" : "Не подключен"}`,
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (siteId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить эту задачу!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${TelephonyApi}deletePbxUser/${siteId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						SweetAlert.fire("Удалено!", "Пользователь телефонии был удален.", "success");
						mainData();
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении задачи:", error);
					});
			}
		});
	};

	const send = () => {
		if (id === "") {
			const newUser = {
				userId: selectUser.value,
				innerPhone: internalNumber,
				pbxId: outgoingNumber.value ? outgoingNumber.value : outgoingNumber.id,
				phoneSet: deviceStatus.label,
				sipPassword: password,
			};

			axios
				.post(`${TelephonyApi}createPbxUser`, newUser, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					toast.success("Пользователь телефонии добавлен!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setEditModal(false);
					mainData();
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			const newUser = {
				id: id,
				userId: selectUser.value,
				innerPhone: internalNumber,
				pbxId: outgoingNumber.value ? outgoingNumber.value : outgoingNumber.id,
				phoneSet: deviceStatus.label,
				sipPassword: password,
			};

			axios
				.put(`${TelephonyApi}updatePbxUser`, newUser, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					toast.success("Пользователь телефонии обновлен!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setEditModal(false);
					mainData();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};
	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = products.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const [selectUser, setSelectUser] = useState(null);
	const [allUsersNames, setAllUsersNames] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
				}));
				setAllUsersNames(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				noDataComponent={"В данной таблице ещё нет записей."}
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={editModal} title={edit ? "Редактировать" : "Добавить"} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col sm="12" className="row col-sm-12 mb-3">
						<Label>Пользователь</Label>
						<Col sm="11">
							<Select
								placeholder="Начните вводить имя пользователя..."
								noOptionsMessage={() => "Нет результатов"}
								options={allUsersNames}
								value={selectUser}
								onChange={(option) => setSelectUser(option)}
								className="js-example-basic-single col-sm-12"
							/>
						</Col>
					</Col>

					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Внутренний номер</Label>
						<Col sm="11">
							<Input className="form-control" type="text" value={internalNumber} onChange={(e) => setInternalNumber(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Пароль Sip</Label>
						<Col sm="11">
							<Input className="form-control" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Номер для исходящего звонка</Label>
						<Col sm="11">
							<Select
								placeholder="Выберите..."
								noOptionsMessage={() => "Нет результатов"}
								options={pbxlist}
								value={outgoingNumber}
								onChange={(option) => setOutgoingNumber(option)}
								className="js-example-basic-single col-sm-12"
							/>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Телефонный аппарат</Label>
						<Col sm="11">
							<Select
								placeholder="Выберите..."
								noOptionsMessage={() => "Нет результатов"}
								options={[
									{ label: "Подключен", value: true },
									{ label: "Не подключен", value: false },
								]}
								value={deviceStatus}
								onChange={(option) => setDeviceStatus(option)}
								className="js-example-basic-single col-sm-12"
							/>
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableUsersList;
