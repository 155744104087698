import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-feather';
import { UL, LI } from '../../AbstractElements';
import LightCard from './LitghtCard';

import { ToolTip } from '../../AbstractElements';

const OverallBalance = ({ data }) => {
	const [elapsed, setElapsedArray] = useState([]);
	const [lastCheck, setLastCheck] = useState([]);
	const [colors, setColors] = useState([]);
	const [lightCardData, setLightCardData] = useState([]);

	useEffect(() => {
		console.log(data);
		const elapsedArray = [];
		const lastCheckArray = [];
		for (let i = 0; i < data.logs.length; i++) {
			elapsedArray.push(data.logs[i].elapsed);
			lastCheckArray.push(data.logs[i].lastCheck);
		}

		const statusArray = [];
		data.logs.forEach((log) => {
			if (log.status === 'Dead') {
				statusArray.push('#fc4438');
			} else if (log.status === 'Alive') {
				statusArray.push('#5cbd53');
			} else {
				statusArray.push('#ffaa05');
			}
		});

		const cardsArray = data.Chronicles.map((item, index) => {
			const date = new Date(item.lastCheck);
			const month = date.toLocaleString('ru-RU', { month: 'long' });

			// Вычисляем разницу с предыдущим элементом
			let difference = null;
			let color = null;
			if (index > 0) {
				const prevAvgElapsed = data.Chronicles[index - 1].AvgElapsed;
				difference = item.AvgElapsed - prevAvgElapsed;
				color = difference > 0 ? 'danger' : 'success';
				difference = (difference > 0 ? '+' : '') + difference;
			}

			// Создаем объект с учетом наличия разницы и цвета
			const newObj = {
				AvgElapsed: item.AvgElapsed,
				lastStatus: item.lastStatus,
				lastCheck: month,
			};

			if (difference !== 0) {
				newObj.difference = difference;
				newObj.color = color;
			}

			return newObj;
		});

		setLightCardData(cardsArray);
		setColors(statusArray);
		setElapsedArray(elapsedArray);
		setLastCheck(lastCheckArray);
		console.log(cardsArray);
	}, []);

	const titleColor = {
		Dead: '#fc4438',
		Alive: '#5cbd53',
		LongTimeResponse: '#ffaa05',
	};

	// Функция для форматирования даты и времени
	function formatDateTime(dateTimeStr) {
		const date = new Date(dateTimeStr);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${day}.${month} ${hours}:${minutes}`;
	}

	const CurrencyChartData = {
		series: [
			{
				name: 'Скорость ответа сервера',
				data: elapsed,
			},
		],
		options: {
			chart: {
				height: 350,
				type: 'bar',
			},

			colors: colors,
			plotOptions: {
				bar: {
					columnWidth: '65%',
					distributed: true,
				},
			},
			dataLabels: {
				enabled: false,
			},
			legend: {
				show: false,
			},
			xaxis: {
				categories: lastCheck.map(formatDateTime),
				labels: {
					style: {
						colors: colors,
						fontSize: '12px',
					},
				},
			},
		},
	};

	const [basictooltip, setbasictooltip] = useState(false);
	const toggle = () => setbasictooltip(!basictooltip);

	return (
		<Col xxl="12" lg="12" className="box-col-12">
			<Card>
				<CardHeader className="card-no-border" style={{ color: titleColor[data.lastStatus], padding: '30px' }}>
					<h5 id={`header-${data.serviceId}`} style={{ fontWeight: '600', display: 'inline' }}>
						{data.name}
					</h5>
					<a href={data.link} style={{ display: 'block', marginTop: '5px' }}>
						<Link size={'14px'} style={{ marginRight: '5px' }} />
						Перейти
					</a>
					<ToolTip
						attrToolTip={{
							placement: 'right',
							isOpen: basictooltip,
							target: `header-${data.serviceId}`,
							toggle: toggle,
							offset: [0, 20],
						}}
					>
						{data.description}
					</ToolTip>
				</CardHeader>
				<CardBody className="pt-0">
					<Row className="m-0 overall-card" style={{ flexDirection: 'column' }}>
						<Col xl="12" md="12" sm="7" className="p-0">
							<div className="chart-right mb-3">
								<Row>
									<Col xl="12" className="col-xl-12">
										<CardBody className="p-0">
											<UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
												<LI>
													<span className="circle bg-success"> </span>
													<span className="f-light ms-1">Работает</span>
												</LI>
												<LI>
													<span className="circle bg-warning"> </span>
													<span className="f-light ms-1">Долгий ответ</span>
												</LI>
												<LI>
													<span className="circle bg-danger"> </span>
													<span className="f-light ms-1">Не работает</span>
												</LI>
											</UL>
											<div className="current-sale-container">
												<ReactApexChart type="bar" height={300} options={CurrencyChartData.options} series={CurrencyChartData.series} />
											</div>
										</CardBody>
									</Col>
								</Row>
							</div>
						</Col>
						<LightCard LightCardData={lightCardData} />
					</Row>
				</CardBody>
			</Card>
		</Col>
	);
};

export default OverallBalance;
