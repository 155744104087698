import React, { useEffect, useState } from "react";
import Context from "./index";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../Company/CompanyProvider";

const ProductProvider = (props) => {
	const [productItem, setProductItem] = useState([]);
	const symbol = "₽";
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	// const [singleItem, setSingleItems] = useState([]);

	const fetchProducts = async () => {
		try {
			await axios.get(`/api/product.json`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } }).then((resp) => {
				setProductItem(resp.data);
			});
		} catch (error) {}
	};

	// const fetchProducts = async () => {
	// 	try {
	// 		await axios.get(`/api/Templates.json`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } }).then((resp) => {
	// 			setProductItem(resp.data.templates);
	// 		});
	// 	} catch (error) {}
	// };

	// const getSingleItem = () => {
	//     // setSingleItems(productItem[0]);
	// };

	//useEffect(() => {
	//	fetchProducts();
		// getSingleItem();
	//}, [setProductItem]);

	return <Context.Provider value={{ ...props, productItem, symbol, fetchProducts }}>{props.children}</Context.Provider>;
};

export default ProductProvider;
