import React, { Fragment, useContext, useState, useEffect } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

import ChatAppContext from "../../../../_helper/Chat";
import { Image, LI, UL } from "../../../../AbstractElements";
import errorImg from "../../../../assets/images/search-not-found.png";
import SearchChatList from "./SearchChatList";
import CurrentUser from "./CurrentUser";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const ChatStatus = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const {
		changeChat,
		allUserChats,
		getUserChats,
		personalChatUsers,
		getUserPersonalChatsUsers,
		selectedChat
	} = useContext(ChatAppContext);

	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const typeChat = queryParams.get("type");

	const [activeTab, setActiveTab] = useState("");

	var images = require.context("../../../../assets/images", true);
	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	const changeChatClick = (e, selectedChatId) => {
		changeChat(selectedChatId);
	};

	useEffect(() => {
		keycloak.authenticated && getUserChats(keycloak.tokenParsed.sub);
		keycloak.authenticated && typeChat && typeChat === 'users' && getUserPersonalChatsUsers(keycloak.tokenParsed.sub);
	}, [keycloak.tokenParsed])

	useEffect(() => {
		if (typeChat) {
			setActiveTab(typeChat);
		}
	}, []);

	useEffect(() => {
		if (!selectedChat) {
			selectChatToDefault();
		}
	}, [allUserChats]);

	const selectChatToDefault = () => {
		if (typeChat === 'users')
		{
			let chatArr = allUserChats.filter((item) => item.personalChat === true);
			chatArr && chatArr.length > 0 && changeChat(chatArr[0].id)
		 } else {
			let chatArr = allUserChats.filter((item) => item.personalChat === false);
			chatArr && chatArr.length > 0 && changeChat(chatArr[0].id)
		 } 
	}

	const handleTypeChange = (selectedOption) => {
		setActiveTab(selectedOption);
		queryParams.delete("type");
		queryParams.append(`type`, selectedOption);
		navigate(`?${queryParams.toString()}`);
	};

	const getUsernameFromArray = (id) => {
		return personalChatUsers && personalChatUsers.find((el) => el.id === id).name;
		//return `${user.firstName}`;
	};

	return (
		<Fragment>
			<div className="chat-box">
				<div className="chat-left-aside">
					<CurrentUser />
					<div className="people-list" id="people-list">
						<SearchChatList />
						<Nav tabs className="border-tab nav-primary" style={{ overflow: "hidden" }}>
							<NavItem role="tablist" style={{ cursor: "pointer", padding: "0" }}>
								<NavLink
									tag="a"
									className={activeTab === "users" ? "active" : ""}
									onClick={() => handleTypeChange("users")}
								>
									Пользователи
								</NavLink>
							</NavItem>
							<NavItem role="tablist" style={{ cursor: "pointer", padding: "0" }}>
								<NavLink
									tag="a"
									className={activeTab === "groups" ? "active" : ""}
									onClick={() => handleTypeChange("groups")}
								>
									Группы
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="users">
								{allUserChats && allUserChats.filter((item) => item.personalChat === true).length > 0 ? ( 
									<UL attrUL={{ className: "simple-list list custom-scrollbar" }}>
										{allUserChats.filter((item) => item.personalChat === true)
											.map((item) => {
												return (
													<LI
														attrLI={{
															className: `clearfix border-0 ${
																selectedChat && selectedChat.id === item.id
																	? "active"
																	: ""
															} member-item`,
															onClick: (e) =>
																changeChatClick(e, item.id),
														}}
														key={item.id}
													>
														<Image
															attrImage={{
																src: `{/*dynamicImage(item.thumb)*/}`,
																className:
																	"rounded-circle user-image",
																alt: "",
															}}
														/>
														{/*<div
															className={`status-circle ${
																item.online === true
																	? "online"
																	: "offline"
															}`}
														></div>*/}
														<div className="about">
															<div className="name">{/*item.name*/getUsernameFromArray(item.id)}</div>
															<div className="status">
																{//item.status}
																}
															</div>
														</div>
													</LI>
												);
											})}
									</UL>
								) : (
									<Image
										attrImage={{
											className: "img-fluid m-auto",
											src: { errorImg },
											alt: "",
										}}
									/>
								)}
							</TabPane>
							<TabPane tabId="groups">
								{allUserChats && allUserChats.filter((item) => item.personalChat === false).length > 0 ? (
									<UL attrUL={{ className: "simple-list list custom-scrollbar" }}>
										{allUserChats.filter((item) => item.personalChat === false).map((item) => {
											return (
												<LI
													attrLI={{
														className: `clearfix border-0 ${
															selectedChat && selectedChat.id === item.id
																? "active"
																: ""
														} member-item`,
														onClick: (e) =>
															changeChatClick(e, item.id),
													}}
													key={item.id}
												>
													<Image
														attrImage={{
															className: "img-fluid m-auto",
															src: { errorImg },
															alt: "",
														}}
													/>
													{/*<Image
														attrImage={{
															src: `${dynamicImage(item.thumb)}`,
															className: "rounded-circle user-image",
															alt: "",
														}}
													/>*/}
													<div className="about">
														<div className="name">{item.name}</div>
														{/*<div className="status">
															Участники: {/*item.total_members
														</div>*/}
													</div>
												</LI>
											);
										})}
									</UL>
								) : (
									<Image
										attrImage={{
											className: "img-fluid m-auto",
											src: { errorImg },
											alt: "",
										}}
									/>
								)}
							</TabPane>
						</TabContent>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
export default ChatStatus;
