import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Button, Modal, Form, Input, Table } from 'reactstrap';
import axios from 'axios';
import { ExportDataApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import Select from 'react-select';
import CommonModal from '../CommonModal';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { useModulesContext } from "../../../../_helper/Modules/ModulesProvider";
import { Label, Col } from "reactstrap";
import { toast } from "react-toastify";
import Styles from './Styles.css';
import { useExportDataContext } from "../../../../_helper/ExportData/ExportDataProvider";

const TableFieldsMapsSettings = ({ response }) => {

    const { getServicesRequests, servicesRequests, serviceRequest, setServiceRequest, getMappingData } = useExportDataContext();

    const [data, setData] = useState(null);
    const [fields1, setFields1] = useState([]);
    const [fields2, setFields2] = useState([]);
    const { companyData } = useCompanyContext();
    const companyId = companyData.map((item) => (item.attributes.companyId[0]));
    const { services, getServices } = useModulesContext();
    const [serviceName, setServiceName] = useState(null);
    const [servicesOptions, setServicesOptions] = useState([]);
    const [changedService, setChangedService] = useState({});
    const [changedField, setChangedField] = useState({});
    const [viewFieldsOption, setViewFieldsOption] = useState([]);
    const { keycloak } = useKeycloak();

    useEffect(() => {
        const fetchDataAsync = async () => {
            try {
                const gettedServices = await getServices();
                const currentServiceRequest = await fetchMapping();
                await getServicesRequests();
                await fetchFields(gettedServices, currentServiceRequest);
            } catch (error) {
                console.error('Ошибка в цепочке вызовов:', error);
            }
        };

        fetchDataAsync();
    }, [response]);

    const fetchFields = async (gettedServices, currentServiceRequest) => {
        try {
            const currentService = gettedServices.find(obj => obj.id === currentServiceRequest.serviceId);

            if (!currentService) {
                throw new Error('Текущий сервис не найден');
            }

            const currentDomain = window.location.hostname;
            const isLocalhost = currentDomain === 'localhost';
            const url = `${isLocalhost ? (currentService.name === "IMaps" ? "https://localhost:7128/" : "https://localhost:7021/") : currentService.serviceUrl}${currentService.servicePrefix + "/"}getViewFields/${currentServiceRequest.viewId}`;

            setServiceName(currentService.displayName);

            const result = await axios.get(url, {
                headers: { Authorization: `Bearer ${keycloak.token}` }
            });

            const objResp = JSON.parse(result.data);
            const respViewsOptions = objResp.map(view => ({
                label: view.Field,
                value: view.Field,
            }));

            setViewFieldsOption(respViewsOptions);
        } catch (error) {
            console.error('Ошибка обновления записи:', error);
        }
    };

    const fetchMapping = async () => {
        try {
            var serviceReq = await getMappingData(response.id).then((resultData) => {
                setData(resultData);
                setFields1(resultData.fields1 || []);
                setFields2(resultData.fields2 || []);
                setServiceRequest(resultData.serviceRequests || null);
                setServiceRequest(resultData.serviceRequests[0])
                return resultData.serviceRequests[0];
            })
            return serviceReq;
        } catch (error) {
            console.error('Ошибка загрузки данных:', error);
        }
    }

    const handleRemoveField = async (fieldId) => {
        try {
            await axios.delete(`${ExportDataApi}deleteField/${fieldId}`, {
                headers: { Authorization: `Bearer ${keycloak.token}` }
            });
            fetchMapping();
        } catch (error) {
            console.error('Ошибка удаления поля:', error);
        }
    };

    const [showResponseModal, setShowResponseModal] = useState(false);
    const handleResponseShow = () => setShowResponseModal(true);
    const handleResponseClose = () => {
        setShowResponseModal(false);
    };
    const handleResponseSave = async (option) => {
        try {
            const sendedObject = {
                Name: changedField.value,
                ResponseId: response.id,
                RequestId: serviceRequest.id
            }
            await axios.post(`${ExportDataApi}createFieldMap`, sendedObject, {
                headers: { Authorization: `Bearer ${keycloak.token}` }
            });
            fetchMapping();
        }
        catch (error) {
            console.error('Ошибка обновления записи:', error);
            toast.error("Ошибка обновления записи!");
        }

        handleResponseClose();
    };

    const [showServiceModal, setShowServiceModal] = useState(false);
    const handleServiceShow = () => {
        setShowServiceModal(true);

        if (servicesRequests.length > 0 && services.length > 0) {
            const serviceIdsInRequests = new Set(servicesRequests.map(req => req.serviceId));

            const filteredServices = services.filter(service => serviceIdsInRequests.has(service.id));

            const servicesOptions = filteredServices.map(service => ({
                label: service.name,
                value: service.id,
                serviceUrl: service.serviceUrl,
                servicePrefix: service.servicePrefix,
                serviceViewId: servicesRequests.find(obj => obj.serviceId === service.id).viewId,
                requestId: servicesRequests.find(obj => obj.serviceId === service.id).id,
                serviceDisplayName: service.displayName
            }));

            setServicesOptions(servicesOptions);
        }
        else {
            setServicesOptions([]);
        }
    }
    const handleServiceClose = () => {
        setShowServiceModal(false);
    };

    const handleServiceSave = async (option) => {

        try {
            const currentDomain = window.location.hostname;
            const isLocalhost = currentDomain === 'localhost';
            const url = `${isLocalhost ? changedService.label === "IMaps" ? "https://localhost:7128/" : "https://localhost:7021/" : changedService.serviceUrl}${changedService.servicePrefix + "/"}getViewFields/${changedService.serviceViewId}`;

            const responseGetView = await axios.get(url, {
                headers: { Authorization: `Bearer ${keycloak.token}` }
            });

            var objResp = JSON.parse(responseGetView.data);
            const respViewsOptions = objResp.map(view => ({
                label: view.Field,
                value: view.Field,
            }));


            const sendedObject = {
                ServiceId: changedService.value,
                ResponseId: response.id,
                RequestId: changedService.requestId,
                fields: respViewsOptions.map(field => (field.value)),
            }

            await axios.put(`${ExportDataApi}updateServiceFieldsMapping`, sendedObject, {
                headers: { Authorization: `Bearer ${keycloak.token}` }
            });

            toast.success("Сервис успешно сохранен!");
            setServiceName(changedService.serviceDisplayName);
            setViewFieldsOption(respViewsOptions);
            fetchMapping();
        } catch (error) {
            console.error('Ошибка сохранения:', error);
            toast.error("Ошибка сохранения!");
        }

        handleServiceClose();
    };


    return (
        <Container fluid={true}>
            <Card>
                <CardBody>

                    <div className="fields-container">
                        <div className="fields-left">

                        </div>

                        <div className="fields-right">
                            <div className="service-section">
                                <h5>Подключенный сервис</h5>
                                {serviceRequest ? (
                                    <div>
                                        <span>Выбранный сервис: {serviceName}</span><Button
                                            color="warning"
                                            style={{ marginLeft: '15px' }}
                                            onClick={() => handleServiceShow(true)}
                                        >
                                            Изменить сервис
                                        </Button>
                                    </div>
                                ) : (
                                    <Button color="success" onClick={() => handleServiceShow(true)}>Добавить сервис</Button>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="fields-container">
                        <div className="fields-left">
                            {/* Поля получателя */}
                            <h5>Поля получателя</h5>
                            {fields2.map(field => (
                                <div key={field.id} className="field-block col-md-9">
                                    <Input type="text" value={field.fieldName} disabled />
                                    <Button color="danger" onClick={() => handleRemoveField(field.id)}>Удалить </Button>
                                </div>
                            ))}
                            <Button color="primary" onClick={() => handleResponseShow(true)}>Добавить поле</Button>
                        </div>

                        <div className="fields-right">
                            {/* Поля для сервиса отправителя */}
                            <h5>Поля сервиса отправителя</h5>
                            {fields1.map(field => (
                                <div key={field.id} className="field-block col-md-8">
                                    <Input type="text" value={field.fieldName} disabled />
                                </div>
                            ))}
                        </div>
                    </div>


                </CardBody>
            </Card>

            <CommonModal
                isOpen={showResponseModal}
                title={"Добавить поле"}
                toggler={handleResponseShow}
                togglerClose={handleResponseClose}
                closeText="Отмена"
                saveText="Сохранить"
                size="lg"
                onSave={handleResponseSave}
            >
                <CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Наименование</Label>
                        <Col sm="11">
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={viewFieldsOption}
                                className="js-example-basic-single col-sm-12"
                                onChange={(selectedOption) => setChangedField(selectedOption)}
                            />
                        </Col>
                    </Col>
                </CardBody>
            </CommonModal>

            <CommonModal
                isOpen={showServiceModal}
                title={"Подключить сервис"}
                toggler={handleServiceShow}
                togglerClose={handleServiceClose}
                closeText="Отмена"
                saveText="Сохранить"
                size="lg"
                onSave={handleServiceSave}
            >
                <CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Наименование сервиса</Label>
                        <Col sm="11">
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={servicesOptions}
                                className="js-example-basic-single col-sm-12"
                                onChange={(selectedOption) => setChangedService(selectedOption)}
                            />
                        </Col>
                    </Col>
                </CardBody>
            </CommonModal>

            {/* CSS стили */}
            <style jsx>{`
                .fields-container {
                    display: flex;
                    justify-content: space-between;
                }
                .fields-left, .fields-right {
                    flex: 1;
                    margin: 10px;
                }
                .field-block {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                }
                .field-block input {
                    margin-right: 10px;
                    flex-grow: 1;
                }
            `}</style>
        </Container>
    );
};

export default TableFieldsMapsSettings;
