import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Input, CardBody, Card } from 'reactstrap';
import { useKeycloak } from '@react-keycloak/web';
import { toast } from 'react-toastify';
import { PuffLoader } from 'react-spinners';
import { Send, FilePlus } from 'react-feather';
import { useLocation } from 'react-router-dom';

import { Breadcrumbs, Btn, P } from '../../../../AbstractElements';
import UserProfile from './UserProfile';
import UserDetails3 from './UserDetails3';
import { useGroupsContext } from '../../../../_helper/Groups/GroupsProvider';
import { useUsersContext } from '../../../../_helper/Users/UsersProvider';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const ViewGroup = () => {
	const { 
		getGroupInfo, 
		getGroupSubscriptions, 
		getGroupPosts, 
		updateGroup,
		createGroupPost
	} = useGroupsContext();

	const {
		getUsersByIdArray
	} = useUsersContext();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const groupId = queryParams.get('groupId'); // id группы

	const { keycloak } = useKeycloak();
	const [file, setFile] = useState(null);
	const [groupInfo, setGroupInfo] = useState(null);
	const [textValue, setTextValue] = useState('');
	const [posts, setPosts] = useState();

	const [allowPosts, setAllowPosts] = useState(true);
	const [allowComments, setAllowComments] = useState(true);
	const [title, setTitle] = useState('');
	const [description, setDescripton] = useState('');
	const [totalMembers, setTotalMembers] = useState(0);
	const [totalPosts, setTotalPosts] = useState(0);
	const [subscription, setSubscription] = useState();
	const [usersArray, setUsersArray] = useState();

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!groupInfo) {
			setLoading(true);
			groupId && getGroupInfo(groupId).then((result) => {
				setGroupInfo(result);
				result && setTitle(result.name);
				result && setDescripton(result.description);
				result && setAllowPosts(result.commentsAllowed);
				result && setAllowComments(result.postsAllowed);
			});
		}
		else {
			setLoading(false);
		}
	}, [getGroupInfo]);

	useEffect(() => {
		if (subscription === undefined) {
			getGroupSubscriptions(groupId).then((result) => {
				setSubscription(result && result.map((subs) => subs.userId).includes(keycloak.tokenParsed.sub));
				result && setTotalMembers(result.length)
			});
		}
	}, [getGroupSubscriptions]);

	useEffect(() => {
		if (!posts) {
			getGroupPosts(groupId).then((result) => {
				result && setPosts(result);
				result && setTotalPosts(result.length);
			});
		}
	}, [getGroupPosts]);

	useEffect(() => {
		posts && !usersArray && getUsersByIdArray(posts.map((post) => post.authorUserId).filter(onlyUnique)).then((result) => {
			result && setUsersArray(result);
		});
	}, [posts, getUsersByIdArray])

	const allowedFileTypes = ['.pdf', '.doc', '.txt', '.jpg', '.jpeg', '.png'];

	const validateFileType = (file) => {
		const fileType = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);

		if (allowedFileTypes.includes('.' + fileType.toLowerCase())) {
			return true;
		} else {
			alert('Недопустимый тип файла. Разрешены только файлы с расширением: ' + allowedFileTypes.join(', '));
			return false;
		}
	};

	const sendMsg = () => {
		createGroupPost(groupId, textValue).then(
				toast.success('Пост опубликован.', {
					position: toast.POSITION.TOP_RIGHT,
				})
		)
	};

	const handleFileChange = (e) => {
		const files = e.target.files;
		const filesArray = Array.from(files);
		const uploadedFilesInfo = filesArray.map((file) => ({
			id: file.name,
			name: file.name,
			file,
			url: URL.createObjectURL(file),
		}));
		if (uploadedFilesInfo && validateFileType(files[0])) {
			setFile(uploadedFilesInfo[0]);
		}
	};

	const sendUpdateRequest = () => {
		updateGroup(groupId, title, description, groupInfo.ownerUserId, groupInfo.groupType, allowComments, allowPosts).then(
				toast.success('Группа успешно изменена', {
					position: toast.POSITION.TOP_RIGHT,
				})
				)
	};

	function getUserName(userId) {
		let user = usersArray.find((users) => users.id === userId)

		return `${user.firstName} ${user.lastName}`;
	}

	function onlyUnique(value, index, array) {
		return array.indexOf(value) === index;
	}

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Просмотр группы" parent="Группы" title="Просмотр группы" />
			<Container fluid={true}>
				<div className="user-profile">
					<Row>
						{groupInfo && <UserProfile setTotalMembers={setTotalMembers} totalMembers={totalMembers} setTotalPosts={setTotalPosts} totalPosts={totalPosts} subscription={subscription} setSubscription={setSubscription} sendCreateRequest={sendUpdateRequest} title={title} setTitle={setTitle} description={description} setDescripton={setDescripton} groupInfo={groupInfo} allowPosts={groupInfo.postsAllowed} setAllowPosts={setAllowPosts} allowComments={groupInfo.commentsAllowed} setAllowComments={setAllowComments} />}
						{allowPosts && (
							<Col md="12">
								<Card>
									<CardBody>
										<Row style={{ alignItems: 'baseline' }}>
											<Col md="10" style={{ position: 'relative' }}>
												<Input className="mt-2" placeholder="Предложите новость" value={textValue} onChange={(e) => setTextValue(e.target.value)} />
											</Col>
											<Col md="2" style={{ display: 'flex' }}>
												<div className="font-primary" style={{ position: 'absolute', left: '-50px', top: ' 3px', cursor: 'pointer' }}>
													<FilePlus size={'26px'} style={{ cursor: 'pointer' }} />
													<input type="file" onChange={handleFileChange} accept=".pdf,.doc,.txt,.jpg,.jpeg,.png" style={{ opacity: '0', position: 'absolute', top: '0px', left: '0' }} />
												</div>
												<Btn attrBtn={{ color: 'primary', onClick: () => sendMsg() }}>
													Отправить <Send id="send-icon" size={'18px'} style={{ margin: '0 0 -3px 3px' }} />
												</Btn>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						)}

						{loading ? (
							<PuffLoader size={80} color="#7366ff" style={{ margin: '20px auto', display: 'inherit', position: 'relative', width: '80px', height: '80px' }} />
						) : posts && posts.length ? (
							posts.sort((a, b) => {return b.createdAt.localeCompare(a.createdAt)}).map((item) => <UserDetails3 name={usersArray && getUserName(item.authorUserId)} time={item.createdAt} content={item.content} likes={0} comments={0} />)
						) : (
							<Col md="12">
								<Card>
									<CardBody>
										<P attrPara={{ className: 'text-center' }}>Нет постов</P>
									</CardBody>
								</Card>
							</Col>
						)}
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};
export default ViewGroup;
