import React, { useMemo, useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { Plus, Check } from 'react-feather';
import { Btn, H6 } from '../../../../../../AbstractElements';
import { EducationApi } from '../../../../../../api';
import { toast } from 'react-toastify';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../../concatResults";

const AddButton = ({ teacher, teachers }) => {
	const [loading, setLoading] = useState(false);
	const [isAdded, setisAdded] = useState(false);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const courseId = queryParams.get('courseId');
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	const addTeacher = (teacher) => {
		console.log(teacher);
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
			setisAdded(true);
		}, 2000);

		axios
		 	.post(`${EducationApi}add-teacher-to-course`, teacher, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
		 	.then((response) => {
		 		console.log(concatResults(response));
		 		toast.success('Преподаватель добавлен!', {
		 			position: toast.POSITION.TOP_RIGHT,
		 		});
		 		//setModal(false);
		 	})
		 	.catch((error) => {
		 		console.error(error);
		 	});
	};

	return (
		<>
			{/*loading ? (
				<div className="loader-box" style={{ height: '16px', marginLeft: '2.5em' }}>
					<div className="loader-34"></div>
				</div>
			) : teachers.some((item) => item.id === teacher.id) || isAdded ? ( */
				<Btn attrBtn={{ color: 'primary', className: 'm-r-50', size: 'xs', disabled: true }}>
					<Check size={'18px'} style={{ margin: '0px 0px -5px 0px' }} /> Добавлен
				</Btn>
			/*) : (
				<Btn attrBtn={{ color: 'primary', className: 'm-r-50', outline: true, size: 'xs', onClick: () => addTeacher(teacher) }}>
					<Plus size={'18px'} style={{ margin: '0px 0px -5px 0px' }} /> Добавить
				</Btn>
			)*/}
		</>
	);
};

export default AddButton;
