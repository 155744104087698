import React, { useMemo, useState, useContext, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import uuid from "react-uuid";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { Label, Input, CardBody, Col, InputGroup, InputGroupText } from "reactstrap";

import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import FileManagerContext from "../../../../_helper/FileManager";
import { NewsApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TableTariffManager = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { tariffs, getFileManagerData } = useContext(FileManagerContext);
	const filteredProducts = tariffs;

	useEffect(() => {
		getFileManagerData();
	}, [companyData])

	const columns = [
		{
			name: "Название",
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: "Цена",
			selector: (row) => `${row.price} ₽`,
			sortable: true,
		},
		{
			name: "Размер хранилища",
			selector: (row) => `${row.storage} ГБ`,
			sortable: true,
		},

		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTariff(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	//создание нового тарифа
	const createTariff = () => {
		if (name === "" || storage === 0) {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const newTariff = {
				name,
				price,
				storage,
				installed: false,
			};
			axios
				.post(`${NewsApi}createNews`, newTariff, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					//console.log(concatResults(response));
					toast.success("Новость добавлена!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
	};

	//редактивароние тарифа
	const editTariff = () => {
		if (name === "" || storage === 0) {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const updTariff = { id, name, price, storage, installed: false };
			// axios
			// 	.put(`${NewsApi}updateNews`, updTariff, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 	.then((response) => {
			// 		console.log(concatResults(response));
			// 		toast.success("Новость изменена!", {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error("Ошибка получения данных:", error);
			// 	});
		}
	};

	const [modal, setModal] = useState(false);
	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [price, setPrice] = useState(0);
	const [storage, setStorage] = useState(0);

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.name);
			setId(ObjectId.id);
			setPrice(ObjectId.price);
			setStorage(ObjectId.storage);
			setEdit(true);
		} else {
			setName("");
			setId("");
			setPrice(0);
			setStorage("");
			setEdit(false);
		}
		setModal(!modal);
	};

	const [edit, setEdit] = useState(null);

	//удаление тарифа
	const deleteTariff = (id) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				// axios
				// 	.delete(`${NewsApi}deleteNews`, {
				// 		data: {
				// 			Id: idN,
				// 		},
				// 		headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				// 	})
				// 	.then((response) => {
				// 		SweetAlert.fire("Удалено!", "Объект был удален.", "success");
				// 	})
				// 	.catch((error) => {
				// 		console.error("Произошла ошибка при удалении:", error);
				// 	});
			}
		});
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={modal} title={edit ? "Редактировать" : "Добавить"} toggler={edit ? editTariff : createTariff} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className=" col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className=" col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Цена</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="number" placeholder="" aria-label="" value={price} onChange={(e) => setPrice(e.target.value)} />
								<InputGroupText>{"₽"}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
					<Col className=" col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Размер хранилища</Label>
						<Col sm="11">
							<InputGroup>
								<Input className="form-control" type="number" placeholder="" aria-label="" value={storage} onChange={(e) => setStorage(e.target.value)} />
								<InputGroupText>{"ГБ"}</InputGroupText>
							</InputGroup>
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableTariffManager;
