import React, { useState } from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { Btn } from "../../../../AbstractElements";

const CommonPopover = ({ item }) => {
	const [popover, setPopover] = useState(false);
	const Toggle = () => setPopover(!popover);
	return (
		<>
			<Popover placement={item.placement} isOpen={popover} target={"Popover-" + item.id} toggle={Toggle} offset={[0, 15]} trigger={item.trigger}>
				<PopoverHeader>{item.Popoverheader}</PopoverHeader>
				<PopoverBody>{item.Popoverbody}</PopoverBody>
			</Popover>
		</>
	);
};

export default CommonPopover;
