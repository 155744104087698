import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import { Breadcrumbs } from '../../../../AbstractElements';
import TableResources from './TableResources';

import { DeliveryApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const CostDeliveryByCity = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const deliveryServiceId = queryParams.get('deliveryServiceId'); // id заявки
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const [item, setItem] = useState([]);

	useEffect(() => {
		axios
			.get(`${DeliveryApi}getDeliveryServices`, { params: { query: `${deliveryServiceId}` }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				const service = concatResults(response).deliveryServices[0];
				setItem(service);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle={`Настройка стоимости доставки по городам для службы доставки "${item.name}"`} parent="Доставка" title="Настройка стоимости доставки по городам" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<TableResources deliveryServiceId={deliveryServiceId} />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default CostDeliveryByCity;
