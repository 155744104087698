import React, {Fragment, useEffect, useState} from "react";
import {Breadcrumbs} from "../../../../AbstractElements";
import {Card, CardBody, Col, Container, Input, Label, Media, Row} from "reactstrap";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const ClientsCardSettings = () => {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const { cardSettings, getCardSettings, createClientCardSettings, updateClientCardSettings } = useClientsContext();
    const [useSeparatedClientName, setUseSeparatedClientName] = useState();
    const [allowUseMultiplePhoneNumbers, setAllowUseMultiplePhoneNumbers] = useState();
    const [allowUseMultipleEmails, setAllowUseMultipleEmails] = useState();
    const [allowUseDocuments, setAllowUseDocuments] = useState();

    useEffect(() => {
        if (cardSettings) {
            setUseSeparatedClientName(cardSettings.useSeparatedClientName);
            setAllowUseMultiplePhoneNumbers(cardSettings.allowUseMultiplePhoneNumbers);
            setAllowUseMultipleEmails(cardSettings.allowUseMultipleEnails);
            setAllowUseDocuments(cardSettings.allowUseDocuments);
        }
    }, [cardSettings]);

    useEffect(() => {
        if (useSeparatedClientName !== undefined && allowUseMultiplePhoneNumbers !== undefined && allowUseMultipleEmails !== undefined && allowUseDocuments !== undefined){
            if (
                cardSettings?.useSeparatedClientName !== useSeparatedClientName ||
                cardSettings?.allowUseMultipleEnails !== allowUseMultipleEmails ||
                cardSettings?.allowUseDocuments !== allowUseDocuments ||
                cardSettings?.allowUseMultiplePhoneNumbers !== allowUseMultiplePhoneNumbers
            ) {
                updateClientCardSettings(cardSettings.id, useSeparatedClientName, allowUseMultiplePhoneNumbers, allowUseMultipleEmails, allowUseDocuments)
            }
        }
        /*if (!cardSettings) {
            //createClientCardSettings(useSeparatedClientName, allowUseMultiplePhoneNumbers, allowUseMultipleEmails, allowUseDocuments);
        } else {

        }*/
    }, [useSeparatedClientName, allowUseMultiplePhoneNumbers, allowUseMultipleEmails, allowUseDocuments])

    useEffect(() => {
        getCardSettings();
    }, [companyData, keycloak]);

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Настройка карточки клиента" parent="Клиенты" title="Настройка карточки клиента" />
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="12" md="12" className="box-col-12">
                            <Card>
                                <CardBody>
                                    <div className={"d-flex"}>
                                        <Label className="col-form-label">Использовать только одно поле с вводом имени клиента</Label>
                                        <Media body className={'text-end icon-state'}>
                                            <Label className="switch">
                                                <Input type="checkbox" checked={useSeparatedClientName} onChange={(e) => setUseSeparatedClientName(e.target.checked)}/><span className={'switch-state bg-primary'} ></span>
                                            </Label>
                                        </Media>
                                        <Label className="col-form-label">Разделить ФИО на отдельные поля</Label>
                                    </div>


                                    <div className="checkbox checkbox-primary">
                                        <Input id="checkbox-primary-1" type="checkbox" checked={allowUseMultiplePhoneNumbers} onChange={(e) => setAllowUseMultiplePhoneNumbers(e.target.checked)}/>
                                        <Label for="checkbox-primary-1">Разрешить добавлять несколько телефонов</Label>
                                    </div>
                                    <div className="checkbox checkbox-primary">
                                    <Input id="checkbox-primary-2" type="checkbox" checked={allowUseMultipleEmails} onChange={(e) => setAllowUseMultipleEmails(e.target.checked)}/>
                                        <Label for="checkbox-primary-2">Разрешить добавлять несколько адресов
                                            электронной почты</Label>
                                    </div>
                                    <div className="checkbox checkbox-primary">
                                        <Input id="checkbox-primary-3" type="checkbox" checked={allowUseDocuments} onChange={(e) => setAllowUseDocuments(e.target.checked)}/>
                                        <Label for="checkbox-primary-3">Разрешить добавлять документы клиента</Label>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
};
export default ClientsCardSettings;