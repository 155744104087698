import React, {Fragment, useState, useEffect, useRef} from "react";
import {Link, useLocation, useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";
import Select from "react-select";
import { components } from "react-select";
import {ArrowDropDownCircle, ArrowDropDownRounded, ArrowDropDownSharp} from "@mui/icons-material";
import {TwinAuthApi} from "../../../api";
import CompanySelect from "./CompanySelect";

const BookmarkHeader = () => {
	const { companyData, setCompanyData, userCompanies, setUserCompanies } = useCompanyContext();
	const { keycloak, initialized } = useKeycloak();
	const [headerComponent, setHeaderComponent] = useState("");

	const companyDataId = sessionStorage.getItem("companyData");

	useEffect(() => {
		if (userCompanies) {
			if (companyDataId === null) {
				userCompanies && selectCompany(userCompanies[0]);
			}
			else {
				let selectedCompanies = JSON.parse(companyDataId);
				if (Array.isArray(selectedCompanies)) {
					selectedCompanies.forEach((item) => {
						let comp = userCompanies.find((company) => company.id === item.groupId);
						comp && selectCompany(comp).then();
					})
				}
			}
		}
	}, [userCompanies, companyDataId]);

	const selectCompany = async (company) => {
		if (companyData && Array.isArray(companyData) && companyData.find((companies) => (companies.id === company.id))) {}
		else {
			let companyDataCopy = companyData.filter(el => el.id !== null) ?? [];
			companyDataCopy.push(company);
			setCompanyData(companyDataCopy);
			sessionStorage.setItem('companyData', JSON.stringify(companyDataCopy.map((item) => ({ groupId: item.id, companyId: item.attributes.companyId[0]  }))));
		}
	};

	useEffect (() => {
		keycloak.authenticated && axios
			.get(`${TwinAuthApi}get-user-groups-companies/${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}` } })
			.then((response) => {
				response && setUserCompanies(JSON.parse(response.data));
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, [keycloak.tokenParsed])

	useEffect (() => {
		setHeaderComponent(HeaderCompanyComponent);
	}, [companyData, userCompanies])

	const HeaderCompanyComponent = () => {
		if (initialized) {
			if (!userCompanies) {
				return (
					<>
						<Link to={`${process.env.PUBLIC_URL}/register-company/`}>
							<span>Регистрация компаний</span>
						</Link>
					</>
				);
			} else {
				return (
					<>
						<CompanySelect companyData={companyData} userCompanies={userCompanies} />
						{/*<Dropdown isOpen={dropdownOpen} toggle={toggle}>
							<DropdownToggle caret color="" style={{ width: "200px" }}>
								Выбор компаний
							</DropdownToggle>
							<DropdownMenu style={{ width: "200px" }}>
								{userCompanies.map((item) => (
									<DropdownItem key={item.id} value={item} onClick={() => select(item)}>
										{item.name}
										{companyData.find((companies) => (companies.id === item.id)) && (<i className={"icofont icofont-tick-mark"} style={{float: "right"}}></i>)}
									</DropdownItem>
								))}
								<DropdownItem onClick={() => navigate(`${process.env.PUBLIC_URL}/register-company/`)}>
									Регистрация компаний
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>*/}
					</>
				);
			}
		}
	};

	return (
		<Fragment>
			<li className="onhover-dropdown">
				<div className="media-body">
					{headerComponent}
				</div>
			</li>
		</Fragment>
	);
};

export default BookmarkHeader;
