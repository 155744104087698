import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit, Users, Eye, EyeOff, Check } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { Label, Input, CardBody, Col, Collapse } from "reactstrap";

import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";

import { TwinAuthApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import { Btn, H5 } from "../../../../AbstractElements";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TableEmailAdressList = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [filteredProducts, setFilteredProducts] = useState([]);

	const [usersData, setUsersData] = useState([]);
	const [curDepartment, setCurDepartment] = useState("");

	useEffect(() => {
		axios
			.get("/api/DepartmentManagement.json")
			.then((response) => {
				setFilteredProducts(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	useEffect(() => {
		//curDepartment - id теккущего подразделения
		axios
			.get("/api/usersData.json")
			.then((response) => {
				setUsersData(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, [curDepartment]);

	const columns = [
		{
			name: "Название отдела",
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: "Кол-во подотделов",
			selector: (row) => `${row.password}`,
			sortable: true,
		},
		{
			name: "Кол-во пользователей",
			selector: (row) => `${row.membersCount}`,
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Просмотр" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggleUsers(row.departmentId)}>
							<Users size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteDepartment(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	// Обновление пользователя
	const appointHead = (currentUser) => {
		const updateObj = {
			...currentUser,
			isDirector: !currentUser.isDirector,
		};
		// axios
		// 	.put(`/api/emails.json/${singleMailRecord.id}`, updateMsg)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Изменения сохранены!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setLoading(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		//    setLoading(false);
		// 	});
	};

	const columnsUsers = [
		{
			name: "ФИО",
			selector: (row) => (
				<div>
					{row.name} {row.surname}
					<Tooltip title={` ${row.isDirector ? "Отозвать права руководителя" : "Назначить руководителем"} `} arrow placement="top">
						<i className={`fa fa-star f-18 ms-2 c-pointer  ${row.isDirector ? "starred" : "star-hover"} `} onClick={() => appointHead(row)}></i>
					</Tooltip>
				</div>
			),
			sortable: true,
		},
		{
			name: "Логин",
			selector: (row) => `${row.login}`,
			sortable: true,
		},
		{
			name: <div>Наличие учетной записи</div>,
			selector: (row) => (row.hasAccount ? <Check className="font-success ms-2" /> : ""),
			sortable: true,
			center: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteUser(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
			width: "6%",
		},
	];

	// добавление отдела
	const createDepartment = () => {
		if (departmentName === "") {
			toast.error("Укажите название!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const newDepartment = {
				name: departmentName,
				description: description,
				label: label,
			};
			// axios
			// 	.post(`${NewsApi}createNews`, newDepartment, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Новость добавлена!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error('Ошибка получения данных:', error);
			// 	});
		}
	};

	// редактирование отдела
	const editDepartment = () => {
		if (departmentName === "") {
			toast.error("Укажите название!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const updDepartment = {
				name: departmentName,
				description: description,
				label: label,
			};
			// axios
			// 	.put(`${NewsApi}updateNews`, news, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Новость изменена!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setModal(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error('Ошибка получения данных:', error);
			// 	});
		}
	};

	const [modal, setModal] = useState(false);

	const toggle = (ObjectId) => {
		console.log(ObjectId);
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setDepartmentName(ObjectId.departmentName);
			setLabel(ObjectId.label);
			setDescription(ObjectId.description);
			setEdit(true);
		} else {
			setDepartmentName("");
			setLabel("");
			setDescription("");
			setEdit(false);
		}
		setModal(!modal);
	};

	const [modalUsers, setModalUsers] = useState(false);

	const toggleUsers = (item) => {
		setModalUsers(!modalUsers);
		setCurDepartment(item);
	};

	const [edit, setEdit] = useState(null);

	// удаление отдела
	const deleteDepartment = (id) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				// axios
				// 	.delete(`${NewsApi}deleteNews`, {
				// 		data: {
				// 			Id: id,
				// 		},
				// 		headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				// 	})
				// 	.then((response) => {
				// 		SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
				// 	})
				// 	.catch((error) => {
				// 		console.error('Произошла ошибка при удалении:', error);
				// 	});
			}
		});
	};

	// удаление пользователя
	const deleteUser = (userId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				// axios
				// 	.delete(`${NewsApi}deleteNews`, {
				// 		data: {
				// 			Id: userId,
				// 		},
				// 		headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				// 	})
				// 	.then((response) => {
				// 		SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
				// 	})
				// 	.catch((error) => {
				// 		console.error('Произошла ошибка при удалении:', error);
				// 	});
			}
		});
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const [departmentName, setDepartmentName] = useState("");
	const [description, setDescription] = useState("");
	const [label, setLabel] = useState("");

	const [showMailInfo, setShowMailInfo] = useState(false);

	const addMail = () => {
		setShowMailInfo(!showMailInfo);
	};

	const [allUsers, setAllUsers] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.username + " (" + option.firstName + " " + option.lastName + ")",
					value: option.username,
					name: option.firstName,
					surname: option.lastName,
				}));
				setAllUsers(selectOptions);
				console.log(JSON.parse(response.data));
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [login, setLogin] = useState(null);
	const [userPassword, setUserPassword] = useState("");
	const [userRepPassword, setUserRepPassword] = useState("");
	const [post, setPost] = useState("");
	const [gender, setGender] = useState({ label: "-", value: null });
	const [hasAccount, setHasAccount] = useState(true);

	const handleChangeLogin = (option) => {
		if (option) {
			setLogin(option);
			setName(option.name);
			setSurname(option.surname);
		}
	};

	const createOption = (label) => ({
		label,
		value: label,
	});

	const handleCreate = (inputValue) => {
		setHasAccount(false);
		setTimeout(() => {
			const newOption = createOption(inputValue);
			setAllUsers((prev) => [...prev, newOption]);
			setLogin(newOption);
		}, 1000);
	};

	const [errorMessage, setErrorMessage] = useState("");
	// добавление пользователя в отдел
	const addUser = () => {
		if (userPassword !== userRepPassword) {
			setErrorMessage("Пароли не совпадают");
		} else {
			setErrorMessage("");

			if (name === "" || surname === "" || login === "" || post === "" || userPassword == "") {
				toast.error("Заполните все поля!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			} else {
				const newUser = {
					departmentId: curDepartment,
					name,
					surname,
					middleName,
					login,
					password: userPassword,
					post,
					gender,
					isDirector: false,
					hasAccount,
				};
				// axios
				// 	.put(`${NewsApi}updateNews`, newUser, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				// 	.then((response) => {
				// 		console.log(response.data);
				// 		toast.success('Новость изменена!', {
				// 			position: toast.POSITION.TOP_RIGHT,
				// 		});
				// 		setModal(false);
				// 	})
				// 	.catch((error) => {
				// 		console.error('Ошибка получения данных:', error);
				// 	});
			}
		}
	};

	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const [showRepPassword, setShowRepPassword] = useState(false);

	const toggleRepPasswordVisibility = () => {
		setShowRepPassword(!showRepPassword);
	};

	return (
		<Col className="mt-4">
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal
				isOpen={modal}
				title={edit ? "Редактировать" : "Добавить"}
				toggler={edit ? editDepartment : createDepartment}
				togglerClose={toggle}
				closeText="Отмена"
				saveText="Сохранить"
				size="lg"
			>
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="12">
							<Input className="form-control" value={departmentName} onChange={(e) => setDepartmentName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="12">
							<Input type="text" className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Рассылка</Label>
						<Col sm="12">
							<Input type="text" className="form-control" value={label} onChange={(e) => setLabel(e.target.value)} />
						</Col>
						<span style={{ color: "#898989" }}>С этого адреса будут отправляться рассылки этого подразделения</span>
					</Col>
				</CardBody>
			</CommonModal>
			<CommonModal isOpen={modalUsers} title={`Список сотрудников`} togglerClose={toggleUsers} size="xl">
				<CardBody style={{ padding: "10px", paddingLeft: "20px", paddingTop: "0" }} className="email-wrap">
					<Col
						className=" f-14 p-t-15 p-b-15 b-r-8 b-b-light mb-4 d-flex ps-4"
						onClick={addMail}
						style={{ cursor: "pointer", alignItems: "center", backgroundColor: "#7366ff08", margin: "0.25rem 0 0" }}
					>
						{showMailInfo ? <i className="fa fa-angle-down me-2 f-16"></i> : <i className="fa f-16 fa-angle-right me-2 "></i>} <span>Добавить сотрудника</span>
					</Col>
					<Collapse isOpen={showMailInfo}>
						<Col md="12">
							<Col className="mb-2">
								<Label className="col-sm-9 col-form-label m-0">Фамилия</Label>
								<Col sm="12">
									<Input className="form-control" type="text" value={surname} onChange={(e) => setSurname(e.target.value)} />
								</Col>
							</Col>
							<Col className="mb-2">
								<Label className="col-sm-9 col-form-label m-0">Имя</Label>
								<Col sm="12">
									<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
								</Col>
							</Col>
							<Col className="mb-2">
								<Label className="col-sm-9 col-form-label m-0">Отчество</Label>
								<Col sm="12">
									<Input className="form-control" type="text" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
								</Col>
							</Col>
							<Col className="mb-2">
								<Label className="col-sm-9 col-form-label m-0">Логин</Label>
								<Col sm="12">
									<CreatableSelect
										isClearable
										value={login}
										onChange={handleChangeLogin}
										options={allUsers}
										onCreateOption={handleCreate}
										placeholder={"Начните вводить..."}
										formatCreateLabel={(inputValue) => `Создать "${inputValue}"`}
									/>
								</Col>
							</Col>
							<Col className="mb-2">
								<Label className="col-sm-9 col-form-label m-0">Пароль</Label>
								<Col sm="12" style={{ position: "relative" }}>
									<Input className="form-control" type={showPassword ? "text" : "password"} value={userPassword} onChange={(e) => setUserPassword(e.target.value)} />
									{showPassword ? (
										<EyeOff
											className="font-primary"
											onClick={togglePasswordVisibility}
											size={"18px"}
											style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer" }}
										/>
									) : (
										<Eye
											className="font-primary"
											onClick={togglePasswordVisibility}
											size={"18px"}
											style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer" }}
										/>
									)}
								</Col>
							</Col>
							<Col className="mb-2">
								<Label className="col-sm-9 col-form-label m-0">Пароль еще раз</Label>
								<Col sm="12" style={{ position: "relative" }}>
									<Input className="form-control" type={showRepPassword ? "text" : "password"} value={userRepPassword} onChange={(e) => setUserRepPassword(e.target.value)} />
									{showRepPassword ? (
										<EyeOff
											className="font-primary"
											onClick={toggleRepPasswordVisibility}
											size={"18px"}
											style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer" }}
										/>
									) : (
										<Eye
											className="font-primary"
											onClick={toggleRepPasswordVisibility}
											size={"18px"}
											style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer" }}
										/>
									)}
									{errorMessage && <div className="font-danger">{errorMessage}</div>}
								</Col>
							</Col>
							<Col className="mb-2">
								<Label className="col-sm-9 col-form-label m-0">Должность</Label>
								<Col sm="12">
									<Input className="form-control" type="text" value={post} onChange={(e) => setPost(e.target.value)} />
								</Col>
							</Col>
							<Col className="mb-4">
								<Label className="col-sm-9 col-form-label m-0">Пол</Label>
								<Col sm="12">
									<Select
										placeholder={"Начните вводить..."}
										noOptionsMessage={() => "Нет результатов"}
										options={[
											{ label: "Женский", value: "female" },
											{ label: "Мужской", value: "male" },
											{ label: "-", value: null },
										]}
										value={gender}
										onChange={(e) => setGender(e)}
									/>
								</Col>
							</Col>
							<div style={{ display: "flex", justifyContent: "end" }}>
								<Btn attrBtn={{ color: "primary", className: "mb-4", onClick: addUser }}>Добавить</Btn>
							</div>
						</Col>
					</Collapse>

					<DataTable
						columns={columnsUsers}
						data={usersData}
						defaultSortField="name"
						striped
						pagination
						paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
					/>
				</CardBody>
			</CommonModal>
		</Col>
	);
};

export default TableEmailAdressList;
