import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane, Media, Label, Input } from 'reactstrap';
import axios from 'axios';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { ChevronLeft, ChevronRight, Plus, Minus, ArrowRight, ArrowRightCircle, Users, Calendar, CheckSquare, ShoppingBag, Truck } from 'react-feather';
import {
	addDays,
	subDays,
	format,
	startOfWeek,
	isSameDay,
	parse, //  для разбора времени
	isBefore, //  для сравнения времени
	addMinutes,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import CommonModal from '../../UiKits/Modals/common/modal';
import { H4, H6, H5, Btn } from '../../../AbstractElements';
import { TaskApi } from '../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { concatResults } from "../../../concatResults";
import { useCompanyContext } from '../../../_helper/Company/CompanyProvider';

const NewDemandForm = ({ parameters, menuItems, delivery, formId, name, taskManagment }) => {
	const [primarycolorTab, setprimarycolorTab] = useState(1);

	const [data, setData] = useState('');
	const [services, setServices] = useState('');
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	var images = require.context('../../../assets/images', true);

	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	//useEffect(() => {
	//	axios
	//		.get(`/api/employeesDemandsForm.json`)
	//		.then((response) => {
	//			setData(concatResults(response));

	//			var uniqueServices = {};
	//			concatResults(response).forEach(function (item) {
	//				item.services.forEach(function (service) {
	//					var serviceId = service.service_id;

	//					if (!uniqueServices[serviceId]) {
	//						uniqueServices[serviceId] = service;
	//					}
	//				});
	//			});

	//			var allServices = Object.values(uniqueServices);

	//			setServices(allServices);
	//		})
	//		.catch((error) => {
	//			console.error('Ошибка получения данных:', error);
	//		});
	//}, []);

	const [products, setProducts] = useState('');
	//useEffect(() => {
	//	axios
	//		.get(`/api/ProductsResult.json`)
	//		.then((response) => {
	//			setProducts(concatResults(response)[0].data);
	//		})
	//		.catch((error) => {
	//			console.error('Ошибка получения данных:', error);
	//		});
	//}, []);

	const [prepayment, setPrepayment] = useState(true);
	//useEffect(() => {
	//	axios
	//		.get(`/api/optionalPrepayment.json`)
	//		.then((response) => {
	//			setPrepayment(concatResults(response).optional_prepayment);
	//		})
	//		.catch((error) => {
	//			console.error('Ошибка получения данных:', error);
	//		});
	//}, []);

	const [curEmployee, setCurEmployee] = useState('');
	const [timesheet, setTimesheet] = useState('');
	const [curservices, setCurServices] = useState('');
	const [curProducts, setCurProducts] = useState('');

	const handleClickEmployee = (item, i) => {
		setprimarycolorTab(i + 1);
		console.log(item);
		setServices(item.services);
		setTimesheet(item.schedule);
		setCurEmployee(item);
	};
	const handleClickServices = (item) => {
		setCurServices(item);
		console.log(curservices);
	};

	//selectDate
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedTime, setSelectedTime] = useState(undefined);
	const [currentStartDate, setCurrentStartDate] = useState(new Date());

	const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

	const serviceDuration = 60;

	const generateAvailableTimeSlots = (date, timesheet) => {
		const dayOfWeek = format(date, 'EEEE');

		if (!timesheet) {
			return 'not select';
		}

		const scheduleEntry = timesheet.find((entry) => entry.day_of_week === dayOfWeek);
		console.log(scheduleEntry);

		if (scheduleEntry) {
			const startTime = parse(scheduleEntry.start_time, 'HH:mm', new Date());
			const endTime = parse(scheduleEntry.end_time, 'HH:mm', new Date());

			const timeSlots = [];
			let currentTime = startTime;
			while (isBefore(currentTime, endTime)) {
				timeSlots.push(format(currentTime, 'HH:mm'));
				currentTime = addMinutes(currentTime, serviceDuration); // Увеличиваем время
			}

			return timeSlots;
		}

		return [];
	};

	const calculateStartOfWeek = (date) => {
		const firstDayOfWeek = startOfWeek(date, { weekStartsOn: 1 }); // Пн - 1 день недели
		return firstDayOfWeek;
	};

	const renderDaysOfWeek = () => {
		return daysOfWeek.map((day, index) => (
			<Col md="1" key={index} className="dayOfWeek p-0">
				{day}
			</Col>
		));
	};

	const renderCalendarDays = () => {
		const startOfWeekDate = calculateStartOfWeek(currentStartDate);
		const days = [];

		for (let i = 0; i < 7; i++) {
			const day = addDays(startOfWeekDate, i);
			days.push(day);
		}

		return days.map((day) => (
			<Col md="1" style={{ padding: '4px', cursor: 'pointer', textAlign: 'center' }} key={day} className={`calendarDay  ${isSameDay(day, selectedDate) ? 'selected' : ''}`} onClick={() => handleDateClick(day)}>
				{format(day, 'd', { locale: ru })}
			</Col>
		));
	};

	const renderTimeSlots = () => {
		if (!selectedDate) {
			return null;
		}

		const slotsForSelectedDate = generateAvailableTimeSlots(selectedDate, timesheet);

		if (slotsForSelectedDate === 'not select') {
			return (
				<div style={{ margin: '20px auto' }}>
					Для записи необходимо выбрать{' '}
					<a
						href="#"
					//onClick={() => setprimarycolorTab('1')}
					>
						специалиста
					</a>
				</div>
			);
		} else {
			return (
				<Col xxl="8" sm="10" className="mt-4" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '15px' }}>
					{slotsForSelectedDate.map((timeSlot) => (
						<Btn attrBtn={{ color: 'primary', outline: true, className: 'mb-3 btn-pill btn-air-primary', onClick: () => handleTimeClick(timeSlot), active: timeSlot === selectedTime ? true : false }} key={timeSlot}>
							{timeSlot}
						</Btn>
					))}
				</Col>
			);
		}
	};

	const handleDateClick = (day) => {
		setSelectedDate(day);
		//setSelectedTime(null);
	};

	const handleTimeClick = (time) => {
		setSelectedTime(time);
	};
	const handlePreviousWeek = () => {
		const newStartDate = subDays(currentStartDate, 7);
		setCurrentStartDate(newStartDate);
	};

	const handleNextWeek = () => {
		const newStartDate = addDays(currentStartDate, 7);
		setCurrentStartDate(newStartDate);
	};

	//modal
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	function addOneHourToDate(inputDate) {
		let currentDate = inputDate || new Date();

		let newDate = new Date(currentDate.getTime());

		newDate.setTime(newDate.getTime() + 60 * 60 * 1000);

		return newDate;
	}

	const handleSubmit = () => {
		const newApplication = {
			...formValues,
			id: formId,
			service_name: curservices.service_name,
			service_id: curservices.service_id,
			service_cost: curservices.price,
			date: selectedDate,
			time: selectedTime,
			employee_name: curEmployee.name,
			employee_role: curEmployee.role,
			employee_id: curEmployee.id,
		};

		axios
			.post(`/api/clientsRequest.json`, newApplication)
			.then((response) => {
				console.log(concatResults(response));
				SweetAlert.fire({ title: 'Заявка отправлена!', text: `Ждем Вас ${format(selectedDate, 'd MMMM', { locale: ru })} в ${selectedTime}`, icon: 'success' });
			})
			.catch((error) => {
				console.error(error);
			});
		console.log(newApplication);

		//добавление в календарь если выбрана доставка
		if (delivery) {
			const newEvent = {
				id: formId,
				employee: { value: curEmployee.id, label: curEmployee.name },
				service: { value: 'DeleiveryID', label: curservices.service_name + ' - доставка' },
				email: '',
				resources: null,
				capacity: 1,
				date: selectedDate,
				start: selectedDate,
				end: addOneHourToDate(selectedDate),
				note: '',
				group_event: false,
				clients: [{ id: uuid(), name: formValues['NameID'] + ' ' + formValues['SurnameID'], phone: formValues['PhoneID'] }],
				day_schedule: [],
				schedule_completion: null,
				is_send_notifications: false,
			};

			axios
				.post(`/api/signingUpService.json`, newEvent)
				.then((response) => {
					console.log(concatResults(response));
				})
				.catch((error) => {
					console.error(error);
				});

			console.log(newEvent);
		}

		//добавление задачи на ответсвенного сотрудника
		if (taskManagment && taskManagment.length > 0) {
			try {
				taskManagment.forEach((task) => {
					axios
						.post(`${TaskApi}create`, {
							name: task.title,
							description: task.description,
							creatorId: task.assigner,
							startDate: selectedDate,
							stopDate: addOneHourToDate(selectedDate),
							doerId: task.assignee,
							projectId: task.project.value,
							createDate: new Date(),
							priorityId: 0,
							isExternal: false,
							deleted: false,
							ownerId: task.assigner,
							demandId: 0,
						}, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
						.then((response) => {
							//console.log(concatResults(response).Send);
						})
						.catch((error) => {
							console.error(error);
							throw error;
						});
				});
			} catch (error) {
				toast.error('Ошибка при добавлении', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.error('Произошла ошибка при отправке данных на сервер:', error);
			}
		}
	};

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	function formatTime(inputDate) {
		const dateObject = new Date(inputDate);

		const minute = dateObject.getMinutes();
		const hour = dateObject.getHours();
		const formattedminute = minute === 0 ? `0${minute}` : minute;

		const formattedDate = `${hour}:${formattedminute}`;

		return formattedDate;
	}

	const [formValues, setFormValues] = useState({});

	const [isValidEmail, setIsValidEmail] = useState(true);

	const handleChange = (elementId, value) => {
		setFormValues((prevValues) => ({
			...prevValues,
			[elementId]: value,
		}));

		if (elementId === 'EmailID') {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			const isValid = emailRegex.test(value);
			setIsValidEmail(isValid);
		}
	};

	return (
		<Row>
			<Col sm="4" xs="12">
				<Nav className="nav flex-column nav-pills nav-light txt-dark">
					{menuItems.length > 0
						? menuItems
							.sort((a, b) => a.order - b.order)
							.map((item, i) => (
								<NavItem key={i}>
									<NavLink style={{ cursor: 'pointer', borderRadius: '15px', fontSize: '18px' }} className={`${primarycolorTab === item.order ? 'active' : ''} txt-dark mb-1`} onClick={() => setprimarycolorTab(item.order)}>
										<Row style={{ alignItems: 'center', justifyContent: 'space-between' }} className="p-r-20 p-l-20 p-10">
											<Col md="10" className="p-0">
												{item.type === 'DateTimeMenu' ? <Calendar className="m-r-20" /> : item.type === 'EmployeesMenu' ? <Users className="m-r-20" /> : item.type === 'ServicesMenu' ? <CheckSquare className="m-r-20" /> : item.type === 'ProductsMenu' ? <ShoppingBag className="m-r-20" /> : ''}
												{item.name}
											</Col>
										</Row>
									</NavLink>
								</NavItem>
							))
						: ''}

					{selectedTime && curservices && curEmployee ? (
						<NavItem>
							<NavLink style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', padding: '20px', borderRadius: '15px', fontSize: '18px', marginBottom: '20px' }} className="badge-primary" onClick={toggle}>
								Продолжить <ArrowRightCircle size={'30px'} />
							</NavLink>
						</NavItem>
					) : null}
					<CommonModal isOpen={modal} title="Ваши данные" toggler={handleSubmit} togglerClose={toggle} closeText="Отмена" saveText="Записаться" size="lg">
						<Card>
							<CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
								<Row>
									{parameters
										.sort((a, b) => a.order - b.order)
										.map((parameter, index) => (
											<>
												{parameter.type === 'input' && (
													<Col md="12" className="mb-3" key={index}>
														<Label className="col-sm-9 col-form-label" for={parameter.elementId}>
															{parameter.name}
														</Label>
														<Col sm="12">
															<Input className="form-control" type="text" id={parameter.elementId} value={formValues[parameter.elementId] || ''} onChange={(e) => handleChange(parameter.elementId, e.target.value)} />
														</Col>
													</Col>
												)}
												{parameter.type === 'Date' && (
													<Col sm="6" className="mb-3" key={index}>
														<Label className="m-r-10 f-16">{parameter.name}</Label>
														<div className="input-group">
															<DatePicker id={parameter.elementId} locale={ru} value={formValues[parameter.elementId] ? formatDate(formValues[parameter.elementId]) : formatDate(new Date())} className="form-control digits" selected={new Date()} onChange={(date) => handleChange(parameter.elementId, date)} />
														</div>
													</Col>
												)}
												{parameter.type === 'Time' && (
													<Col sm="6" className="mb-3" key={index}>
														<Label className="m-r-10 f-16">{parameter.name}</Label>
														<div className="input-group">
															<DatePicker timeCaption="Время" showTimeSelect showTimeSelectOnly timeIntervals={15} locale={ru} value={formValues[parameter.elementId] ? formatTime(formValues[parameter.elementId]) : formatTime(new Date())} id={parameter.elementId} selected={new Date()} onChange={(date) => handleChange(parameter.elementId, date)} className="form-control digits" />
														</div>
													</Col>
												)}
												{parameter.type === 'phone' && (
													<Col sm="6" className="mb-3" key={index}>
														<Label className="m-r-10 f-16">{parameter.name}</Label>
														<div className="input-group">
															<InputMask className="form-control" mask="+7 (999) 999-99-99" maskChar="_" placeholder="+7 (___) ___-__-__" alwaysShowMask id={parameter.elementId} value={formValues[parameter.elementId] || ''} onChange={(e) => handleChange(parameter.elementId, e.target.value)} />
														</div>
													</Col>
												)}
												{parameter.type === 'email' && (
													<Col sm="6" className="mb-3" key={index}>
														<Label className="m-r-10 f-16">{parameter.name}</Label>
														<Col sm="12">
															<Input className="form-control" placeholder="Введите Email" id={parameter.elementId} value={formValues[parameter.elementId] || ''} onChange={(e) => handleChange(parameter.elementId, e.target.value)} />
															{!isValidEmail && <div className="d-block font-danger">Пожалуйста, введите корректный email.</div>}
														</Col>
													</Col>
												)}
												{parameter.type === 'deliveryInfo' && (
													<Col sm="12" style={{ backgroundColor: '#ffaa051f' }} className="p-10 b-r-8">
														<Label className="font-warning">
															<div dangerouslySetInnerHTML={{ __html: parameter.name }} />
														</Label>
													</Col>
												)}
												{parameter.type === 'prepayment' && (
													<Col sm="6" className="mb-3 mt-4" key={index}>
														<Media>
															<Label className="col-form-label col-sm-4">{parameter.name}</Label>
															<Media body className={'text-start icon-state '} style={{ flexGrow: '0' }}>
																<Label className="switch">
																	{!prepayment && curservices.prepayment ? (
																		<>
																			<Input type="checkbox" id={parameter.elementId} checked={formValues[parameter.elementId] || true} disabled onChange={(e) => handleChange(parameter.elementId, e.target.checked)} />
																			<span className="switch-state"></span>
																		</>
																	) : (
																		<>
																			<Input type="checkbox" id={parameter.elementId} checked={formValues[parameter.elementId] || false} onChange={(e) => handleChange(parameter.elementId, e.target.checked)} />
																			<span className="switch-state"></span>
																		</>
																	)}
																</Label>
															</Media>
														</Media>
													</Col>
												)}
											</>
										))}
								</Row>
								<H5 attrH5={{ className: 'mt-4' }}>Детали записи</H5>
								<div style={{ display: 'flex', alignItems: 'center', marginTop: '25px' }}>
									<div className="media-size-email" style={{ position: 'relative', padding: '5px' }}>
										<Media body className="me-3 rounded-circle img-60" src={curEmployee.image ? dynamicImage(curEmployee.image) : require('../../../assets/images/user/7.jpg')} alt="" />
									</div>
									<div>
										<div className="mb-1" style={{ color: '#808092' }}>
											{curEmployee.role}
										</div>
										<H6>{curEmployee.name}</H6>

										<div className="mb-1" style={{ color: '#808092' }}>
											{selectedDate && (
												<>
													{format(selectedDate, 'd MMMM', { locale: ru })}, {selectedTime}
												</>
											)}
										</div>
									</div>
								</div>
								{curservices && prepayment ? (
									<>
										<div className="mt-4">
											<H6 attrH6={{ className: 'f-w-600' }}>{curservices.price} ₽</H6>
											<H6>{curservices.service_name}</H6>
										</div>
									</>
								) : (
									<>
										<Row className="mt-4">
											<div className="f-16 f-w-600 " style={{ width: 'inherit' }}>
												{curservices.price} ₽
											</div>
											{curservices.prepayment && (
												<div style={{ width: 'inherit', backgroundColor: '#54ba4a40', padding: '2px 8px', color: '#214c1d' }} className="b-r-8">
													{curservices.prepayment_amount}
													{curservices.prepayment_unit} предоплата
												</div>
											)}
										</Row>
										<H6>{curservices.service_name}</H6>
									</>
								)}

								{curProducts && (
									<Row className="b-t-light">
										<div style={{ marginTop: '25px' }}>
											<Row className="mb-3">
												<div className="f-16 " style={{ width: 'inherit' }}>
													Товары
												</div>
												{delivery && (
													<Col md="2" style={{ display: 'flex', backgroundColor: '#7366ff30' }} className="font-primary b-r-8 p-1">
														<Truck size={'18px'} className="ms-2 me-2" />
														<div className="f-14">Доставка</div>
													</Col>
												)}
											</Row>
											<Label className="f-16">{curProducts.pagetitle}</Label> - <Label className="f-16 f-w-600">{curProducts.ProductPrice.Price} ₽</Label>
										</div>
									</Row>
								)}
							</CardBody>
						</Card>
					</CommonModal>
				</Nav>
			</Col>
			<Col sm="8" xs="12">
				<TabContent activeTab={primarycolorTab}>
					{menuItems.length > 0
						? menuItems.map((item, i) => (
							<TabPane key={i} tabId={item.order}>
								<H4 attrH4={{ className: 'mb-4' }}>{item.name}</H4>
								{item.type === 'DateTimeMenu' ? (
									<Col sm="12" xxl="8">
										<H5 attrH5={{ className: 'mb-4 text-center text-capitalize' }}>{format(currentStartDate, 'LLLL yyyy', { locale: ru })}</H5>

										<Row style={{ justifyContent: 'space-around' }}>
											<a href="#" style={{ width: 'auto' }} onClick={handlePreviousWeek}>
												<ChevronLeft />
											</a>
											{renderDaysOfWeek()}
											<a href="#" style={{ width: 'auto' }} onClick={handleNextWeek}>
												<ChevronRight />
											</a>
										</Row>
										<div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
											<Row className="m-l-10 m-r-40" style={{ justifyContent: 'space-around', flexWrap: 'nowrap', width: '80%' }}>
												{renderCalendarDays()}
											</Row>
											{renderTimeSlots()}
											{selectedTime ? (
												<Btn attrBtn={{ color: 'primary', className: 'mt-4', onClick: () => setprimarycolorTab(item.order + 1) }}>
													Продолжить <ArrowRight style={{ margin: '0px 0px -4px 7px' }} size={'20px'} />
												</Btn>
											) : (
												''
											)}
										</div>
									</Col>
								) : item.type === 'EmployeesMenu' ? (
									<>
										{data.length ? (
											data.map((item) => (
												<Card className={`b-l-info ${item.id === curEmployee.id ? 'b-info ' : ''}`} key={item.id}>
													<CardBody style={{ padding: '15px' }}>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<div className="media-size-email" style={{ position: 'relative', padding: '5px' }}>
																<Media body className="me-3 rounded-circle img-60" src={item.image ? dynamicImage(item.image) : require('../../../assets/images/user/7.jpg')} alt="" />
															</div>
															<div style={{ flexGrow: '1' }}>
																<div className="mb-1" style={{ color: '#808092' }}>
																	{item.role}
																</div>
																<H6>{item.name}</H6>
															</div>
															{item.id === curEmployee.id ? (
																<div style={{ fontSize: '32px', border: '3px solid #ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => setCurEmployee('')}>
																	<Minus style={{ margin: '0 0px 4px 7px' }} />
																</div>
															) : (
																<div style={{ fontSize: '32px', background: '#ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => handleClickEmployee(item, i)}>
																	<Plus style={{ margin: '0 0px 2px 8px' }} />
																</div>
															)}
														</div>
													</CardBody>
												</Card>
											))
										) : (
											<p>Загрузка...</p>
										)}
									</>
								) : item.type === 'ServicesMenu' ? (
									<>
										{data.length > 0 ? (
											services.map((item) => (
												<Card className={`b-l-secondary ${item.service_id === curservices.service_id ? 'b-secondary ' : ''}`} key={item.service_id}>
													<CardBody style={{ padding: '15px' }}>
														<Row>
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<div className="media-size-email" style={{ position: 'relative', padding: '5px' }}>
																	<Media body className="me-3 rounded-circle img-60" src={item.image ? dynamicImage(item.image) : require('../../../assets/images/user/7.jpg')} alt="" />
																</div>
																<div style={{ flexGrow: '1' }}>
																	{prepayment ? (
																		<H6>{item.service_name}</H6>
																	) : (
																		<Row>
																			<div className="f-16" style={{ width: 'inherit' }}>
																				{item.service_name}
																			</div>
																			{item.prepayment && (
																				<div style={{ width: 'inherit', backgroundColor: '#54ba4a40', padding: '2px 8px', color: '#214c1d' }} className="b-r-8">
																					{item.prepayment_amount}
																					{item.prepayment_unit} предоплата
																				</div>
																			)}
																		</Row>
																	)}

																	<div className={' m-t-5 mb-2'} style={{ color: '#6c757d' }}>
																		{item.description}
																	</div>
																	<H6 attrH6={{ className: 'font-secondary f-w-600' }}>{item.price} ₽</H6>
																</div>
																{item.service_id === curservices.service_id ? (
																	<div style={{ fontSize: '32px', border: '3px solid #ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => setCurServices('')}>
																		<Minus style={{ margin: '0 0px 4px 7px' }} />
																	</div>
																) : (
																	<div style={{ fontSize: '32px', background: '#ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => handleClickServices(item)}>
																		<Plus style={{ margin: '0 0px 2px 8px' }} />
																	</div>
																)}
															</div>
														</Row>
													</CardBody>
												</Card>
											))
										) : (
											<p>Загрузка...</p>
										)}
									</>
								) : item.type === 'ProductsMenu' ? (
									<>
										{products.length > 0 ? (
											products.map((item) => (
												<Card className={`b-l-success ${item.id === curProducts.id ? 'b-success ' : ''}`} key={item.id}>
													<CardBody style={{ padding: '15px' }}>
														<Row>
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<div className="media-size-email" style={{ position: 'relative', padding: '5px' }}>
																	<Media body className="me-3 rounded-circle img-60" src={item.image ? dynamicImage(item.image) : require('../../../assets/images/user/7.jpg')} alt="" />
																</div>
																<div style={{ flexGrow: '1' }}>
																	<H6>{item.pagetitle}</H6>
																	<div className={' m-t-5 mb-2'} style={{ color: '#6c757d' }}>
																		{item.description}
																	</div>
																	<H6 attrH6={{ className: 'font-success f-w-600' }}>{item.ProductPrice.Price} ₽</H6>
																</div>
																{item.id === curProducts.id ? (
																	<div style={{ fontSize: '32px', border: '3px solid #ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => setCurProducts('')}>
																		<Minus style={{ margin: '0 0px 4px 7px' }} />
																	</div>
																) : (
																	<div style={{ fontSize: '32px', background: '#ddd', width: '42px', height: '42px', borderRadius: '50%', cursor: 'pointer' }} onClick={() => setCurProducts(item)}>
																		<Plus style={{ margin: '0 0px 2px 8px' }} />
																	</div>
																)}
															</div>
														</Row>
													</CardBody>
												</Card>
											))
										) : (
											<p>Загрузка...</p>
										)}
									</>
								) : (
									''
								)}
							</TabPane>
						))
						: ''}
				</TabContent>
			</Col>
		</Row>
	);
};
export default NewDemandForm;
