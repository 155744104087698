import React, { useState, useEffect } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { InputNumber } from "primereact/inputnumber";
import { Plus, Search, Trash2 } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import SweetAlert from "sweetalert2";
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { TasksPaymentApi } from "../../../../api";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const TableBonuses = ({ toggle, projectId }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [bonuses, setBonuses] = useState([]);

	const getData = async () => {
		try {
			const bonusesResponse = await axios.get(`${TasksPaymentApi}getBonuses/${projectId}`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});

			const mappedBonuses = concatResults(bonusesResponse).bonuses.map(bonus => ({
				id: bonus.id,
				note: bonus.comment,
				totalTasksPlanned: bonus.totalTasksPLanned,
				totalTasksCompleted: bonus.totalTasksCompleted,
				basicRequirementsMet: bonus.basicRequirementsMet,
				workedMoreThan40Hours: bonus.workedMoreThan40Hours,
				fixedAmount: bonus.fixedAmount,
				totalAmount: bonus.totalAmount,
				qualification: bonus.qualification.text,
				qualificationId: bonus.qualification.id,
				baseRate: bonus.qualification.rate
			}));
			setBonuses(mappedBonuses);
		}
		catch (error) {
			console.log("Ошибка получения данных");
			console.log(error);
		}
	}

	useEffect(() => {
		getData();
	}, []);

	const workedMoreThan40Hours = (rowData) => {
		if (!rowData.fixedAmount) {
			return `${rowData.workedMoreThan40Hours}%`;
		}
	};

	const basicRequirementsMet = (rowData) => {
		if (!rowData.fixedAmount) {
			return `${rowData.basicRequirementsMet}%`;
		}
	};

	const totalTasksPlanned = (rowData) => {
		if (rowData.fixedAmount) {
			return (
				<span style={{ position: "absolute", marginTop: "-10px", marginLeft: "10vh" }}>
					Фиксированная сумма <span className="f-w-600">{rowData.totalAmount} руб</span>
				</span>
			);
		} else {
			return `${rowData.totalTasksPlanned}%`;
		}
	};

	const totalTasksCompleted = (rowData) => {
		if (!rowData.fixedAmount) {
			return `${rowData.totalTasksCompleted}%`;
		}
	};

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="Квалификация" rowSpan={2} sortable field="qualification" />
				<Column header="Базовая ставка" rowSpan={2} sortable field="baseRate" />
				<Column header="Примечание" rowSpan={2} />
				<Column header="Бонусы" colSpan={5} style={{ width: "60%" }} />
			</Row>
			<Row>
				<Column header="Общее кол-во задач по плану" field="lastYearSale" />
				<Column header="Общее кол-во выполненных задач(задач подчиненных у TeamLead)" field="thisYearSale" />
				<Column header="Выполнены базовые требования" field="lastYearProfit" />
				<Column header="Отработано >= 40 часов" field="thisYearProfit" />
				<Column header="" />
			</Row>
		</ColumnGroup>
	);

	const isPositiveInteger = (val) => {
		let str = String(val);

		str = str.trim();

		if (!str) {
			return false;
		}

		str = str.replace(/^0+/, "") || "0";
		let n = Math.floor(Number(str));

		return n !== Infinity && String(n) === str && n >= 0;
	};

	const priceEditor = (options) => {
		return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} suffix="%" onKeyDown={(e) => e.stopPropagation()} />;
	};

	const onCellEditComplete = (e) => {
		let { rowData, newValue, field, originalEvent: event } = e;
		console.log(e);
		if (isPositiveInteger(newValue)) {
			rowData[field] = newValue;
			console.log(rowData);

			//Редактирование записи
			// axios
			// 	.put(`/api/bonuses.json`, {
			// 		headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			// 	}, rowData)
			// 	.then((response) => {
			// 		setBonuses(response.data.bonusCalculation);
			// 	})
			// 	.catch((error) => {
			// 		console.error('Ошибка получения данных:', error);
			// 	});
		} else event.preventDefault();
	};

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters["global"] = { value: value, matchMode: "contains" };

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const [filters, setFilters] = useState(null);
	const [globalFilterValue, setGlobalFilterValue] = useState("");
	const renderHeader = () => {
		return (
			<Row>
				<Col md="2" sm="3">
					<Btn attrBtn={{ color: "primary", onClick: toggle }}>
						<Plus size={"18px"} style={{ margin: "0 3px -3px 0" }} />
						Добавить
					</Btn>
				</Col>
				<Col md="10" sm="9">
					<InputGroup>
						<Input className="form-control" type="text" value={globalFilterValue} onChange={onGlobalFilterChange} />
						<InputGroupText className="bg-primary">
							<Search size={"20px"} />
						</InputGroupText>
					</InputGroup>
				</Col>
			</Row>
		);
	};
	const header = renderHeader();

	//Удаление данных о бонусах
	const deleteRow = (bonusId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then(function (isConfirm) {
			if (isConfirm) {

			}
		});
	};

	return (
		<DataTable
			value={bonuses}
			headerColumnGroup={headerGroup}
			editMode="cell"
			tableStyle={{ minWidth: "50rem" }}
			header={header}
			globalFilterFields={["qualification", "baseRate", "note", "totalTasksPlanned", "totalTasksCompleted", "basicRequirementsMet"]}
			paginator
			rows={5}
			rowsPerPageOptions={[5, 10, 25, 50]}
		>
			<Column field="qualification" />
			<Column field="baseRate" />
			<Column field="note" />

			{/* bonuses */}
			<Column field="totalTasksPlanned" body={totalTasksPlanned} editor={(options) => priceEditor(options)} onCellEditComplete={onCellEditComplete} />
			<Column field="totalTasksCompleted" body={totalTasksCompleted} editor={(options) => priceEditor(options)} onCellEditComplete={onCellEditComplete} />
			<Column field="basicRequirementsMet" editor={(options) => priceEditor(options)} onCellEditComplete={onCellEditComplete} body={basicRequirementsMet} />
			<Column field="workedMoreThan40Hours" body={workedMoreThan40Hours} editor={(options) => priceEditor(options)} onCellEditComplete={onCellEditComplete} />
			<Column
				field=""
				body={(rowData) => (
					<Tooltip title="Удалить" arrow placement="top">
						<Trash2 className="font-primary c-pointer" size={"20px"} onClick={() => deleteRow(rowData.id)} />
					</Tooltip>
				)}
			/>
		</DataTable>
	);
};
export default TableBonuses;
