import React, { useState, Fragment, useEffect } from 'react';
import FileUploadStep from './FileUploadStep';
import FieldMappingStep from './FieldMappingStep';
import ImportCompletionStep from './ImportCompletionStep';
import Stepper from './Stepper';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Container } from 'reactstrap';
import { useLocation } from 'react-router-dom';


const ImportMapObject = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [fileData, setFileData] = useState(null);
    const [objectTypeId, setObjectTypeId] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mapId = queryParams.get('mapId');

    const nextStep = () => setCurrentStep((prevStep) => prevStep + 1);
    const prevStep = () => setCurrentStep((prevStep) => prevStep - 1);

    return (

        <Container fluid={true}>
            <Card>
                <CardBody>
                    <div style={{}}>
                        <div className="d-flex justify-content-between align-items-center">
                            <Col md="12" xs="12">
                                <h2>Загрузчик</h2>
                            </Col>
                        </div>
                        <div>
                            <Stepper currentStep={currentStep} />
                            {currentStep === 0 && <FileUploadStep nextStep={nextStep} setFileData={setFileData} setObjectTypeId={setObjectTypeId} />}
                            {currentStep === 1 && <FieldMappingStep nextStep={nextStep} prevStep={prevStep} fileData={fileData} setFileData={setFileData} objectTypeId={objectTypeId} />}
                            {currentStep === 2 && <ImportCompletionStep prevStep={prevStep} fileData={fileData} mapId={mapId} />}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Container>
    );
};

export default ImportMapObject;