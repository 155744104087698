import React, { Fragment, useState } from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import BusinessProcessCard from './BusinessProcessCard';

const StandardPricingcard = ({ data }) => {
	return (
		<Fragment>
			<CardBody>
				<Row className="pricing-block" style={{ justifyContent: ' space-around' }}>
					{data && data.map((item) => <BusinessProcessCard key={item.id} title={item.title} subTitle={item.cost} paragraph={item.description} serviceList={item.product_catalog} image={item.image} />)}
				</Row>
			</CardBody>
		</Fragment>
	);
};
export default StandardPricingcard;
