// RightPanel.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Col, Input, Nav, NavItem, NavLink, TabContent, TabPane, Label, Media, CardBody } from 'reactstrap';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ElementInput from './Elements/ElementInput';
import ElementTel from './Elements/ElementTel';
import ElementTextarea from './Elements/ElementTextarea';
import ElementCheckbox from './Elements/ElementCheckbox';
import ElementText from './Elements/ElementText';
import ElementDate from './Elements/ElementDate';
import ElementTime from './Elements/ElementTime';
import ElementSelect from './Elements/ElementSelect';
import { Btn } from '../../../../AbstractElements';
import { DemandsApi, AllProjectApi } from '../../../../api';
import ElementEmail from './Elements/ElementEmail';
import ElementProductTable from './Elements/ElementProductTable';
import ElementPrepayment from './Elements/ElementPrepayment';
import ElementDeliveryInfo from './Elements/ElementDeliveryInfo';
import { Settings } from 'react-feather';
import TaskManagement from './TaskManagement';
import FilterComponent from './FilterComponentTask';
import CommonModal from '../../../UiKits/Modals/common/modal';
import DataTableTasks from '../../Task/DataTableTasks';
import { useKeycloak } from '@react-keycloak/web';
import { concatResults } from "../../../../concatResults";
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';
import { Puck } from "@measured/puck";
import { useDemandsContext } from "../../../../_helper/Demands/DemandsProvider";
import { useBitrixContext } from "../../../../_helper/Bitrix/BitrixProvider";

const RightPanel = ({ setManagementData, managementData, setIsOverLeftPanel, setFormMenu, formMenu, formId, demandForm, setDemandForm, items }) => {
	const [pillWithIconTab, setpillWithIconTab] = useState('1');

	const { getDemandTypes, demandTypesOptions, createDemandForm, updateDemandForm } = useDemandsContext();
	const { getDealCategories, dealCategoriesOptions, dealCategoriesStages, dealCategoriesStagesOptions } = useBitrixContext();
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [roles, setRoles] = useState(
		keycloak.tokenParsed ? keycloak.tokenParsed.realm_access?.roles : []
	);

	const [projectsOptions, setProjectOptions] = useState([]);

	useEffect(() => {
		getDemandTypes();

	}, []);

	useEffect(() => {
		if (companyData.length > 0) {
			fetchProjectOptions();
			getDealCategories();
		}

	}, [companyData]);

	const fetchProjectOptions = async () => {
		const projectResponse = await axios.get(`${AllProjectApi}get?companyId=${companyData.map((item) => (item.attributes.companyId[0]))}`, {
			headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
		});
		const selectOptionsProjects = projectResponse.data.projects
			? projectResponse.data.projects.map((option) => ({
				label: option.name,
				value: option.id,
				type: option.type.value
			}))
			: [];
		setProjectOptions(selectOptionsProjects);
	}

	const send = () => {
		const hasTimeAndDate = demandForm.parameters.some((item) => item.type === 'time') && demandForm.parameters.some((item) => item.type === 'date');

		if (demandForm.withDelivery && !hasTimeAndDate) {
			toast.error('Для доставки необходимо добавить поля даты и времени!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
		else {
			if (formId) {
				demandForm.id = formId;
				updateDemandForm(demandForm).then((response) => {
					toast.success('Форма обновлена!', {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
			}
			else {
				createDemandForm(demandForm).then((response) => {
					toast.success('Форма добавлена!', {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
			}
		}
	};

	const [modal, setModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
	};

	return (
		<div className="right-panel">
			<Nav className="nav-primary" tabs>
				<NavItem>
					<NavLink href="#" className={pillWithIconTab === '1' ? 'active' : ''} onClick={() => setpillWithIconTab('1')} style={{ padding: '4px 8px' }}>
						Элементы формы
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink href="#" className={pillWithIconTab === '2' ? 'active' : ''} onClick={() => setpillWithIconTab('2')} style={{ padding: '4px 8px' }}>
						Настройки формы
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={pillWithIconTab}>
				<TabPane className="fade show" tabId="1">
					<div className="scroll-bar-wrap" >
						<Scrollbars className="p-0" style={{ width: '100%', height: '60vh' }}>
							<div>
								<Puck.Fields />
							</div>
							<div>
								{/* Render the component list */}
								<Puck.Components />
							</div>
						</Scrollbars>

					</div>
					{/*<div className="font-primary mb-2">Выберите элемент для создания формы</div>
					<div className="scroll-bar-wrap">
						<Scrollbars className="click-drag-handler  p-0" style={{width: '100%', height: '60vh'}}>
							{items.map((item, index) =>
								item === 'input' ? (
									<ElementInput setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'tel' ? (
									<ElementTel setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'textarea' ? (
									<ElementTextarea setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'checkbox' ? (
									<ElementCheckbox setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'text' ? (
									<ElementText setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'date' ? (
									<ElementDate setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'time' ? (
									<ElementTime setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'select' ? (
									<ElementSelect setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'email' ? (
									<ElementEmail setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'ProductsOrderTable' ? (
									<ElementProductTable setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'prepayment' ? (
									<ElementPrepayment setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : item === 'deliveryInfo' ? (
									<ElementDeliveryInfo setIsOverLeftPanel={setIsOverLeftPanel} key={index}/>
								) : (
									<li key={index} draggable="true" className="draggable-item"
										onDragStart={(e) => e.dataTransfer.setData('text/plain', item)}>
										{item}
									</li>
								)
							)}
						</Scrollbars>
					</div>*/}
				</TabPane>
				<TabPane tabId="2">
					<Col className="mt-4">
						<Media>
							<Media body className={'text-start icon-state '} style={{ flexGrow: '0' }}>
								<Label className="switch">
									<Input type="checkbox" checked={formMenu} onChange={(e) => setFormMenu(e.target.checked)} />
									<span className="switch-state"></span>
								</Label>
							</Media>
							<Label className="col-form-label ms-2 ">Добавить меню с виджетами</Label>
						</Media>
					</Col>
					<Col className="mt-2">
						<Label className="col-sm-9 col-form-label">Название формы</Label>
						<Col sm="12">
							<Input
								className="form-control"
								type="text"
								placeholder="Введите название"
								value={demandForm && demandForm.name}
								onChange={(e) => setDemandForm(prevForm => ({
									...prevForm,
									name: e.target.value
								}))}
							/>
						</Col>
					</Col>
					<Col className="mt-2">
						<Label className="col-form-label">Тип заявки</Label>
						<Select
							placeholder="Выберите..."
							options={demandTypesOptions}
							className="js-example-basic-single col-sm-12"
						/>
					</Col>
					{roles.includes('tasks-api') && (
						<Col className="mt-4">
							<Btn attrBtn={{ color: 'info', className: 'settings_btn', onClick: toggle }}>
								<Settings size={'18px'} style={{ margin: '0 3px -3px 0' }} className="gear-icon" />
								Управление задачами
							</Btn>
							<CommonModal isOpen={modal} title="Управление задачами" toggler={toggle} saveText="Готово" togglerClose={toggle} size="xl">
								<CardBody>
									<DataTableTasks data={managementData} setData={setManagementData} FilterComponent={FilterComponent} />
								</CardBody>
							</CommonModal>
						</Col>
					)}
					<Col className="mt-4">
						<div className="checkbox checkbox-primary ms-2">
							<Input
								id="checkbox-primary-delivery"
								type="checkbox"
								checked={demandForm && demandForm.withDelivery}
								onChange={(e) => setDemandForm(prevForm => ({
									...prevForm,
									withDelivery: e.target.checked
								}))}
							/>
							<Label for="checkbox-primary-delivery">Подключить доставку</Label>
						</div>
					</Col>
					{roles.includes('tasks-api') && (
						<>
							<Col className="mt-2">
								<div className="checkbox checkbox-primary ms-2">
									<Input
										id="checkbox-primary-lead"
										type="checkbox"
										checked={demandForm && demandForm.createLead}
										onChange={(e) => setDemandForm(prevForm => ({
											...prevForm,
											createLead: e.target.checked
										}))}
									/>
									<Label for="checkbox-primary-lead">Создавать лид</Label>
									<Select
										required
										placeholder="Выберите проект..."
										noOptionsMessage={() => "Нет результатов"}
										options={projectsOptions.filter(x => x.type === "Lead")}
										value={demandForm && projectsOptions.find(x => x.value === demandForm.leadProjectDefault)}
										onChange={(selectedOption) => setDemandForm(prevForm => ({
											...prevForm,
											leadProjectDefault: selectedOption.value
										}))}
										className="js-example-basic-single col-sm-12"
									/>
								</div>
							</Col>
							<Col className="mt-2">
								<div className="checkbox checkbox-primary ms-2">
									<Input
										id="checkbox-primary-deal"
										type="checkbox"
										checked={demandForm && demandForm.createDeal}
										onChange={(e) => setDemandForm(prevForm => ({
											...prevForm,
											createDeal: e.target.checked
										}))}
									/>
									<Label for="checkbox-primary-deal">Создавать сделку</Label>
									<Select
										required
										placeholder="Выберите проект..."
										noOptionsMessage={() => "Нет результатов"}
										options={projectsOptions.filter(x => x.type === "Deal")}
										value={demandForm && projectsOptions.find(x => x.value === demandForm.dealProjectDefault)}
										onChange={(selectedOption) => setDemandForm(prevForm => ({
											...prevForm,
											dealProjectDefault: selectedOption.value
										}))}
										className="js-example-basic-single col-sm-12"
									/>
								</div>
							</Col>
							<Col className="mt-2">
								<div className="checkbox checkbox-primary ms-2">
									<Input
										id="checkbox-primary-deal-bitrix"
										type="checkbox"
										checked={demandForm && demandForm.createDealBitrix}
										onChange={(e) =>
											setDemandForm((prevForm) => ({
												...prevForm,
												createDealBitrix: e.target.checked,
											}))
										}
									/>
									<Label for="checkbox-primary-deal-bitrix">Создавать сделку в Битрикс24</Label>
								</div>

								<Select
									required
									placeholder="Выберите воронку..."
									noOptionsMessage={() => "Нет результатов"}
									options={dealCategoriesOptions}
									value={demandForm && dealCategoriesOptions.find(x => x.value === demandForm.dealBitrixCategory)}
									onChange={(selectedOption) => {
										const filteredStages = dealCategoriesStagesOptions.filter(x => x.categoryId === selectedOption.value);
										const firstStage = filteredStages.length > 0 ? filteredStages[0].value : null;

										setDemandForm((prevForm) => ({
											...prevForm,
											dealBitrixCategory: selectedOption.value,
											dealBitrixCategoryStage: firstStage,
										}));
									}}
									className="js-example-basic-single col-sm-12"
								/>
								{demandForm.dealBitrixCategory && (
									<>
										<Label className="col-sm-9 col-form-label">Этап сделки</Label>
										<Select
											required
											placeholder="Выберите этап сделки..."
											noOptionsMessage={() => "Нет результатов"}
											options={dealCategoriesStagesOptions && dealCategoriesStagesOptions
												.filter(x => x.categoryId === demandForm.dealBitrixCategory)}
											value={demandForm.dealBitrixCategoryStage
												? dealCategoriesStagesOptions.find(x => x.value === demandForm.dealBitrixCategoryStage)
												: dealCategoriesStagesOptions.find(x => x.categoryId === demandForm.dealBitrixCategory) || null
											}
											onChange={(selectedOption) =>
												setDemandForm((prevForm) => ({
													...prevForm,
													dealBitrixCategoryStage: selectedOption.value,
												}))
											}
											className="js-example-basic-single col-sm-12"
										/>
									</>
								)}
							</Col>
						</>
					)}
					<div className="modal-footer" style={{ paddingTop: '40px' }}>
						<Btn attrBtn={{ color: 'primary', onClick: send }}>Сохранить </Btn>
					</div>
				</TabPane>
			</TabContent>
		</div>
	);
};

export default RightPanel;
