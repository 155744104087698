import React, { useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';

const FilterModal = ({ isOpen, onRequestClose, onSave }) => {
    const [field, setField] = useState('');
    const [value, setValue] = useState('');

    const handleSave = () => {
        onSave({ field, value });
        onRequestClose();
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
            <h2>�������� ������</h2>
            <Select
                options={[
                    { value: 'specId', label: '�' },
                    { value: 'title', label: '��������' },
                    { value: 'createDatetime', label: '���� ����������' },
                    { value: 'customerName', label: '��������' },
                ]}
                value={field}
                onChange={(selectedOption) => setField(selectedOption.value)}
            />
            <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            <button onClick={handleSave}>���������</button>
        </Modal>
    );
};

export default FilterModal;
