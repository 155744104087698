import React from 'react';
import { Row, Media } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';

import { UL, LI } from '../../../../../AbstractElements';

const ModulesCard = ({ modules }) => {
	const isLessonAvailable = (lesson) => {
		const viewedLessons = modules.filter((lesson) => lesson.ViewedByCurrentUser);
		if (lesson.KeyLesson && viewedLessons.length) {
			// Если занятие ключевое, всегда доступно
			return true;
		}

		if (viewedLessons.includes(lesson)) {
			// Если занятие ключевое, всегда доступно
			return true;
		}

		const keyLessons = modules.filter((l) => l.KeyLesson);
		let lastViewedKeyLesson = null;

		for (let i = keyLessons.length - 1; i >= 0; i--) {
			if (viewedLessons.includes(keyLessons[i])) {
				lastViewedKeyLesson = keyLessons[i];
				break;
			}
		}

		if (!lastViewedKeyLesson) {
			// Если не просмотрено ни одно ключевое занятие, то только первое занятие доступно
			return lesson === modules[0];
		}

		const lastViewedKeyLessonIndex = modules.findIndex((l) => l.Id === lastViewedKeyLesson.Id); //индекс последнего просмотренного ключевого урока

		const currentLessonIndex = modules.findIndex((l) => l.Id === lesson.Id); //индекс текущего урока
		let lastViewedLessonIndex = null; //индекс последнего просмотренного урока

		for (let i = modules.length - 1; i >= 0; i--) {
			if (modules[i].ViewedByCurrentUser) {
				lastViewedLessonIndex = i;
				break;
			}
		}

		return currentLessonIndex <= lastViewedKeyLessonIndex + 1 || currentLessonIndex <= lastViewedLessonIndex + 1;
	};

	const completeLessons = (lessons) => {
		let count = 0;
		lessons.map((item) => {
			if (item.ViewedByCurrentUser) count += 1;
		});
		return count;
	};

	return (
		<>
			<Row className="mt-4 gallery" style={{ justifyContent: 'space-between' }}>
				{modules.map((item, index) => (
					<div className="my-gallery row gallery-with-description col-xl-4 col-sm-6 mb-2" key={index}>
						<figure itemProp="associatedMedia" className="product-box" style={{ display: 'flex' }}>
							<a href={isLessonAvailable(item) && ` ${process.env.PUBLIC_URL}/app/view-course?moduleId=${item.id}`} data-size="1600x950" style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
								<span className="faq-image product-img" style={{ display: 'block' }}>
									<Media
										src={`https://dummyimage.com/320x180`} //заглушка
										alt="Gallery"
										className="img-thumbnail p-0"
										width="100%"
										//onClick={() => setPhotoIndex({ ...photoIndex, index: 0, isOpen: true })}
									/>
									<div
										className={`${isLessonAvailable(item) && ' product-hover'}`}
										style={{
											display: !isLessonAvailable(item) && 'none',
										}}
									>
										<h4 style={{ color: '#fff', fontSize: '22px' }}>
											Перейти к модулю <i className="fa fa-arrow-right"></i>
										</h4>
									</div>
								</span>
								<div className="caption" style={{ flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: isLessonAvailable(item) && ' #f6f7f9' }}>
									<h4 className={` ${!isLessonAvailable(item) ? '' : 'font-primary'}`} style={{ color: '#898989' }}>
										{item.name}{' '}
										{!isLessonAvailable(item) && (
											<Tooltip title={`Необходимо пройти модуль ${index}`} arrow placement="top">
												<i className="fa fa-lock" style={{ cursor: 'pointer' }}></i>
											</Tooltip>
										)}
									</h4>
									{item.keyModule && (
										<span className={`badge badge-light-warning rounded-pill `} style={{ width: 'min-content' }}>
											Ключевой модуль <i className="fa fa-bolt"></i>
										</span>
									)}
									<div style={{ flexGrow: '1' }}>
										{item.showNumberClasses && (
											<UL attrUL={{ className: 'pricing-content flex-row mt-2' }}>
												{item.lessons &&
													item.lessons.map((lesson) => (
														<LI attrLI={{ className: 'border-0 mb-1' }}>
															<p>
																<i className="fa fa-chevron-right f-12"></i> {lesson.Name}
															</p>
														</LI>
													))}
											</UL>
										)}
									</div>
									<div className={`mt-3 ${!isLessonAvailable(item) && 'txt-dark'}`}>
										Пройдено:{' '}
										<span className="txt-dark">
											{completeLessons(item.lessons)} из {item.lessons.length} занятий
										</span>
									</div>
								</div>
							</a>
						</figure>
					</div>

					// <Col
					// 	key={index}
					// 	className={`light-card balance-card mb-4 me-3 ms-3 ${isLessonAvailable(item) && ' widget-hover'}`}
					// 	md="3"
					// 	style={{
					// 		alignItems: 'center',
					// 		border: !isLessonAvailable(item) && '3px solid var(--light-background)',
					// 		backgroundColor: !isLessonAvailable(item) && 'white',
					// 		padding: '18px',
					// 		width: '30%',
					// 	}}
					// >
					// 	<Col md="12">
					// 		<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
					// 			<Image
					// 				attrImage={{
					// 					className: 'b-r-10 m-r-10',
					// 					src: `https://dummyimage.com/320x180`, // заглушка
					// 					alt: '',
					// 					width: '70%',
					// 				}}
					// 			/>

					// 			<div style={{ display: 'flex', flexDirection: 'column' }} className="ms-3">
					// 				<div style={{ display: 'flex' }}>
					// 					<h5 className={`text-center f-22 mt-2 ${!isLessonAvailable(item) ? '' : 'font-primary'}`} style={{ color: '#898989' }}>
					// 						{item.name}
					// 					</h5>
					// 					{isLessonAvailable(item) && (
					// 						<>
					// 							{item.ViewedByCurrentUser && (
					// 								<span className={`badge badge-light-primary rounded-pill mt-2 ms-2`} style={{ height: 'min-content' }}>
					// 									Просмотрено <i className="fa fa-eye"></i>
					// 								</span>
					// 							)}
					// 						</>
					// 					)}
					// 				</div>
					// 			</div>
					// 			<UL attrUL={{ className: 'pricing-content flex-row mt-2' }}>
					// 				{item.lessons &&
					// 					item.lessons.map((lesson) => (
					// 						<LI attrLI={{ className: 'border-0' }}>
					// 							<div>{lesson.Name}</div>
					// 						</LI>
					// 					))}
					// 			</UL>
					// 		</div>
					// 		{/* <div style={{ display: 'flex', alignItems: 'flex-end' }}>
					// 			{!isLessonAvailable(item) && (
					// 				<Tooltip title={`Необходимо выполнить задание ${index}`} arrow placement="top">
					// 					<span className={`badge badge-light-danger rounded-pill mt-2`} style={{ cursor: 'pointer' }}>
					// 						Недоступен
					// 					</span>
					// 				</Tooltip>
					// 			)}
					// 		</div> */}
					// 	</Col>
					// 	<Col md="12">
					// 		{isLessonAvailable(item) && (
					// 			<Tooltip title="Перейти к занятию" arrow placement="top">
					// 				<Link className="f-18 f-right me-4" to={`${process.env.PUBLIC_URL}/app/view-lesson?lessonId=${item.Id}`}>
					// 					<ArrowRight size={'28px'} style={{ margin: '0 0px -8px 5px', fontSize: '28px' }} />
					// 				</Link>
					// 			</Tooltip>
					// 		)}
					// 	</Col>
					// </Col>
				))}
			</Row>
		</>
	);
};

export default ModulesCard;
