import React from "react";
import Routers from "./Route";
import ChartistProvider from "./_helper/Chartist/ChartistProvider";
import ChartjsProvider from "./_helper/Chartjs/ChartProvider";
import GoogleChartProvider from "./_helper/GoogleChart/GoogleChartProvider";
import ProjectProvider from "./_helper/Project/ProjectProvider";
import ChatProvider from "./_helper/Chat/ChatProvider";
import ContactProvider from "./_helper/Contact/ContactProvider";
import TaskProvider from "./_helper/Task/TaskProvider";
import GalleryProvider from "./_helper/Gallery/GalleryProvider";
import TableProvider from "./_helper/Table/TableProvider";
import BookmarkProvider from "./_helper/Bookmark/BookmarkProvider";
import EmailProvider from "./_helper/Email/EmailProvider";
import SearchResultProvider from "./_helper/SearchResult/SearchResult";
import ProductProvider from "./_helper/Ecommerce/Product/ProductProvider";
import CartProvider from "./_helper/Ecommerce/Cart/CardProvider";
import FilterProvider from "./_helper/Ecommerce/Filter/FilterProvider";
import WishListProvider from "./_helper/Ecommerce/Wishlist/WishlistProvider";
import JobSearchProvider from "./_helper/JobSearch/JobSearchProvider";
import LearningProvider from "./_helper/Learning/LearningProvider";
import FaqProvider from "./_helper/Faq/FaqProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import CompanyProvider from "./_helper/Company/CompanyProvider";
import { useKeycloak } from "@react-keycloak/web";
import Loader from "./Layout/Loader";
import CourseProvider from "./_helper/Course/CourseContext";
import ConpagesProvider from "./_helper/ConsecutivePages/ConpagesProvider";
import GroupsProvider from "./_helper/Groups/GroupsProvider";
import UsersProvider from "./_helper/Users/UsersProvider";
import NewsProvider from "./_helper/News/NewsProvider";
import CalendarProvider from "./_helper/Calendar/CalendarProvider";
import CmsProvider from "./_helper/Sites/CmsProvider";
import { ReactFlowProvider } from "reactflow";
import DnDProvider from "./_helper/DnD/DnDProvider";
import CMSTemplatesProvider from "./_helper/CMSTemplates/CMSTemplatesProvider";
import DemandsProvider from "./_helper/Demands/DemandsProvider";
import FileManagerProvider from "./_helper/FileManager/FileManagerProvider";
import DocumentManagementProvider from "./_helper/DocumentManagement/DocumentManagementProvider";
import ModulesProvider from "./_helper/Modules/ModulesProvider";
import InteractivePlanMapsProvider from "./_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import Error503 from "./Components/Pages/ErrorPages/ErrorPage503";
import ExportDataProvider from "./_helper/ExportData/ExportDataProvider";
import ClientsProvider from "./_helper/Clients/ClientsProvider";
import CounterpartiesProvider from "./_helper/Counterparties/CounterpartiesProvider";
import BitrtixProvider from "./_helper/Bitrix/BitrixProvider";


const App = () => {
	const { initialized } = useKeycloak();

	if (!initialized) {
	}

	return (
		<div className="App">
			<CompanyProvider>
				<FileManagerProvider>
					<DemandsProvider>
						<CMSTemplatesProvider>
							<DnDProvider>
								<ReactFlowProvider>
									<FaqProvider>
										<LearningProvider>
											<JobSearchProvider>
												<WishListProvider>
													<FilterProvider>
														<CartProvider>
															<ProductProvider>
																<CmsProvider>
																	<SearchResultProvider>
																		<EmailProvider>
																			<BookmarkProvider>
																				<TableProvider>
																					<GalleryProvider>
																						<TaskProvider>
																							<ContactProvider>
																								<ChatProvider>
																									<ProjectProvider>
																										<GoogleChartProvider>
																											<ChartjsProvider>
																												<ChartistProvider>
																													<AnimationThemeProvider>
																														<DocumentManagementProvider>
																															<CustomizerProvider>
																																<CourseProvider>
																																	<ConpagesProvider>
																																		<GroupsProvider>
																																			<UsersProvider>
																																				<NewsProvider>
																																					<CalendarProvider>
																																						<ModulesProvider>
																																							<InteractivePlanMapsProvider>
																																								<ExportDataProvider>
																																									<ClientsProvider>
																																										<CounterpartiesProvider>
																																											<BitrtixProvider>
																																												<Routers />
																																											</BitrtixProvider>
																																										</CounterpartiesProvider>
																																									</ClientsProvider>
																																								</ExportDataProvider>
																																							</InteractivePlanMapsProvider>
																																						</ModulesProvider>
																																					</CalendarProvider>
																																				</NewsProvider>
																																			</UsersProvider>
																																		</GroupsProvider>
																																	</ConpagesProvider>
																																</CourseProvider>
																															</CustomizerProvider>
																														</DocumentManagementProvider>
																													</AnimationThemeProvider>
																												</ChartistProvider>
																											</ChartjsProvider>
																										</GoogleChartProvider>
																									</ProjectProvider>
																								</ChatProvider>
																							</ContactProvider>
																						</TaskProvider>
																					</GalleryProvider>
																				</TableProvider>
																			</BookmarkProvider>
																		</EmailProvider>
																	</SearchResultProvider>
																</CmsProvider>
															</ProductProvider>
														</CartProvider>
													</FilterProvider>
												</WishListProvider>
											</JobSearchProvider>
										</LearningProvider>
									</FaqProvider>
								</ReactFlowProvider>
							</DnDProvider>
						</CMSTemplatesProvider>
					</DemandsProvider>
				</FileManagerProvider>
			</CompanyProvider>
		</div>
	);
};

export default App;
