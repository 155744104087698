import React, { Fragment, useContext } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import ChatAppContext from '../../../../_helper/Chat';
import { Link } from 'react-router-dom';
import { Image } from '../../../../AbstractElements';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const CurrentUser = () => {
	const { currentUserr } = useContext(ChatAppContext);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	var images = require.context('../../../../assets/images', true);
	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	return (
		<Fragment>
			{currentUserr && (
				<div className="media">
					<Image
						attrImage={{
							src: `${dynamicImage(currentUserr.thumb)}`,
							className: 'rounded-circle user-image',
							alt: '',
						}}
					/>
					<div className="about">
						<Link to={`${process.env.PUBLIC_URL}/app/users/profile`}>
							<div className="name f-w-600">{keycloak.tokenParsed.name}</div>
						</Link>
						<div className="status">{currentUserr.status}</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default CurrentUser;
