import React, { useContext, useState } from "react";
import { Col } from "reactstrap";
import { Sun, Moon } from "react-feather";
import { H5, Btn } from "../../../../AbstractElements";

import CustomizerContext from "../../../../_helper/Customizer";
import axios from "axios";
import { useParams } from "react-router-dom";
import { CmsApi } from "../../../../api";
import { toast } from "react-toastify";

const SetTheme = () => {
	const { siteId } = useParams();

	const { addMixBackgroundLayout } = useContext(CustomizerContext);

	const [curTheme, setTheme] = useState(localStorage.getItem("mix_background_layout"));

	const handleLayoutChange = (newLayout) => {
		addMixBackgroundLayout(newLayout);
		setTheme(newLayout);
	};

	const updateSite = () => {
		console.log(siteId);
		const site = {
			Id: siteId,
			Theme: curTheme,
		};
		axios
			.put(`${CmsApi}updateSiteTheme`, site)
			.then((response) => {
				//console.log(concatResults(response));
				toast.success("Тема сайта обновлена!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	return (
		<>
			<H5>Установка темы</H5>
			<Col sm="3" className="mt-4" style={{ minHeight: "175px" }}>
				<Btn attrBtn={{ className: "btn-air-primary mb-4", outline: curTheme === "light-only" ? false : true, color: "light", onClick: () => handleLayoutChange("light-only"), size: "md" }}>
					<Sun size={"18px"} style={{ margin: "0 5px -3px 0px", color: "var(--ck-color-widget-hover-border)" }} />
					Светлая тема
				</Btn>
				<Btn attrBtn={{ className: "btn-air-primary mb-4", outline: curTheme === "light-only" ? true : false, color: "dark", onClick: () => handleLayoutChange("dark-only"), size: "md" }}>
					<Moon size={"18px"} style={{ margin: "0 5px -3px 0px", color: "var(--bs-primary-border-subtle)" }} />
					Темная тема
				</Btn>
				{/* <Btn attrBtn={{ className: 'btn-air-primary mb-4', outline: true, color: 'info', onClick: () => handleLayoutChange('dark-sidebar'), size: 'md' }}>
					<RefreshCw size={'18px'} style={{ margin: '0 5px -3px 0px' }} />
					Смешанная
				</Btn> */}
			</Col>
			<div className="f-right">
				<Btn attrBtn={{ color: "primary", onClick: updateSite, className: "mt-4" }}>Сохранить</Btn>
			</div>
		</>
	);
};

export default SetTheme;
