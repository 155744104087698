import React, { Fragment, useContext, useState, useEffect } from "react";
import { Label, Input } from "reactstrap";
import { H6, UL } from "../../../../../AbstractElements";
import { getBrands, getColors } from "../../../../../Services/Ecommerce.service";
import FilterContext from "../../../../../_helper/Ecommerce/Filter";
import ProductContext from "../../../../../_helper/Ecommerce/Product";
import { Range, getTrackBackground } from "react-range";

const Category = () => {
	const { productItem, fetchProducts } = useContext(ProductContext);
	const { filter, filterColor, filterCategory, filterBrand, filterPrice } = useContext(FilterContext);
	const colors = getColors(productItem);
	const step = 20;
	const min = 0;
	const max = 2000;
	const [values, setValues] = useState([0, 2000]);

	useEffect(() => {
        fetchProducts();
    }, [])

	const colorHandle = (event, color) => {
		var elems = document.querySelectorAll(".color-selector ul li");
		[].forEach.call(elems, function (el) {
			el.classList.remove("active");
		});
		event.target.classList.add("active");
		filterColor(color);
	};

	const priceHandle = (value) => {
		setValues(value);
		filterPrice(value);
	};

	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;

		if (isChecked) {
			setValues([0, 0]);
		} else {
			setValues([0, 2000]);
		}
	};
	return (
		<Fragment>
			<div className="product-filter">
				<H6 attrH6={{ className: "f-w-600" }}>Категория</H6>
				<div className="checkbox-animated mt-0">
					{colors.map((brand, index) => {
						return (
							<Label className="d-block mt-2" key={index}>
								<Input
									className="checkbox_animated"
									// onClick={(e) => clickBrandHendle(e, filter.brand)}
									value={brand}
									defaultChecked={filter.brand.includes(brand) ? true : false}
									id={brand}
									type="checkbox"
									data-original-title=""
									title=""
								/>
								{brand}
							</Label>
						);
					})}
				</div>
			</div>
			{/* <div className="product-filter mb-2">
				<H6 attrH6={{ className: "f-w-600" }}>Цвета</H6>
				<div className="color-selector mb-4">
					<UL attrUL={{ className: "simple-list" }}>
						{colors.map((color, i) => {
							return (
								<Fragment key={i}>
									<li className={`${color} ms-1`} title={color} onClick={(e) => colorHandle(e, color)}></li>
								</Fragment>
							);
						})}
					</UL>
				</div>
			</div> */}
			<div className="product-filter pb-0">
				<H6 attrH6={{ className: "f-w-600" }}>Цена</H6>
				<Range
					values={values}
					step={step}
					min={min}
					max={max}
					onChange={(values) => {
						priceHandle(values);
					}}
					renderTrack={({ props, children }) => (
						<div
							onMouseDown={props.onMouseDown}
							onTouchStart={props.onTouchStart}
							style={{
								...props.style,
								height: "36px",
								display: "flex",
								width: "100%",
							}}
						>
							<output style={{ marginTop: "30px" }}>{values[0]}₽</output>
							<div
								ref={props.ref}
								style={{
									height: "5px",
									width: "100%",
									borderRadius: "4px",
									background: getTrackBackground({
										values,
										colors: ["#ccc", "#7366ff", "#ccc"],
										min: min,
										max: max,
									}),
									alignSelf: "center",
								}}
							>
								{children}
							</div>
							<output style={{ marginTop: "30px" }}>{values[1]}₽</output>
						</div>
					)}
					renderThumb={({ props }) => (
						<div
							{...props}
							style={{
								...props.style,
								height: "16px",
								width: "16px",
								borderRadius: "60px",
								backgroundColor: "#7366ff",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								boxShadow: "0px 2px 6px #AAA",
							}}
						></div>
					)}
				/>
			</div>
			<div className="checkbox checkbox-primary mt-4 ms-1">
				<Input id="checkbox-primary-2" type="checkbox" onChange={handleCheckboxChange} />
				<Label for="checkbox-primary-2">Только бесплатные</Label>
			</div>
		</Fragment>
	);
};

export default Category;
