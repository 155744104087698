import React, { useState } from "react";
import axios from "axios";
import { Input, Label, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import JSZip from "jszip";

import { Btn, H5, H6, P } from "../../../../AbstractElements";
import CommonModal from "../../../UiKits/Modals/common/modal";
import CommonPopover from "../../../UiKits/Popover/common/CommonPopover";

import { CmsApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const ExportImport = ({ siteName, siteDescription, pages }) => {
	const { siteId } = useParams();
	const [primarycolorTab, setprimarycolorTab] = useState("1");
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
	};

	const [header, setHeader] = useState(true);
	const [body, setBody] = useState(true);
	const [footer, setFooter] = useState(true);

	const [menu, setMenu] = useState(false);
	const [links, setLinks] = useState(false);
	const [logo, setLogo] = useState(false);
	const [theme, setTheme] = useState(false);
	const [auth, setAuth] = useState(false);

	const download = () => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "Ранее созданный шаблон сайта будет удалён без возможности восстановления!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Ок",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				const input = document.createElement("input");
				input.type = "file";
				input.accept = ".zip";
				input.onchange = async (event) => {
					const file = event.target.files[0];
					const formData = new FormData();
					formData.append("file", file);
					formData.append("siteId", siteId);
					try {
						const response = await axios.post(`${CmsApi}importTemplate`, formData, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
						});
					} catch (error) {
					}
				};
				input.click();
			}
		});
	};

	const [jsonData, setJsonData] = useState([]);

	const handleImport = async (file) => {
		if (!file) return;

		const zip = await JSZip.loadAsync(file);
		const jsonFiles = [];

		zip.forEach(async (relativePath, zipEntry) => {
			if (zipEntry.name.endsWith(".json")) {
				const jsonContent = await zip.file(zipEntry.name).async("string");
				const fileName = zipEntry.name.replace(/^.*[\\/]/, "");
				jsonFiles.push({ name: fileName, data: JSON.parse(jsonContent) });
			}
		});

		console.log(jsonFiles, jsonFiles.length);
		setJsonData(jsonFiles);
	};

	//export
	async function handleExport() {
		const zip = new JSZip();
		const folder = zip.folder(siteName);

		if (header) {
			await axios
				.get(`${CmsApi}getHeader`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					folder.file("header.json", JSON.stringify(concatResults(response).header));
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
		if (footer) {
			await axios
				.get(`${CmsApi}getFooter`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					//folder.file("header.json", JSON.stringify(concatResults(response).header));
					folder.file("footer.json", JSON.stringify(concatResults(response).footer));
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
		//тело страниц??
		if (checkedPages.length) {
			await axios
				.get(`${CmsApi}getSites`, { params: { query: "" } })
				.then((response) => {
					const foundItem = concatResults(response).sites.find((item) => item.id === siteId);
					if (foundItem && foundItem.pages) {
						const filteredPages = foundItem.pages.filter((page) => checkedPages.includes(page.alias));
						const nestedFolder = folder.folder("pages");

						filteredPages.forEach((page) => {
							nestedFolder.file(`${page.alias}.json`, JSON.stringify(page));
						});
					} else {
						console.error("Не удалось найти страницы или объект сайта.");
					}
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}

		if (menu) {
			await axios
				.get(`${CmsApi}getMenuLinks`, { params: { siteId: siteId + "" } })
				.then((response) => {
					folder.file("menu.json", JSON.stringify(concatResults(response)));
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
		if (links) {
		} //запрос на получение ссылок
		if (logo) {
		} //запрос на получение логотипа
		if (theme) {
		} //запрос на получение темы
		if (auth) {
		} //запрос на получение настройек авторизации

		const jsonData2 = {
			Name: siteName,
			Description: siteDescription,
			HeaderManifest: "header.json",
			BodyManifest: "body.json",
			FooterManifest: "footer.json",
			MenuManifest: "menu.json",
		};
		folder.file("manifest-template.json", JSON.stringify(jsonData2));

		const content = await zip.generateAsync({ type: "blob" });

		const blob = new Blob([content]);
		const url = URL.createObjectURL(blob);

		const link = document.createElement("a");
		link.href = url;
		link.download = "websiteTemplate.zip";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const [checkedPages, setCheckedPages] = useState(pages.map((item) => item.alias));

	const handleCheckboxChange = (itemName) => {
		const updatedCheckedItems = checkedPages.includes(itemName) ? checkedPages.filter((item) => item !== itemName) : [...checkedPages, itemName];

		setCheckedPages(updatedCheckedItems);
	};

	return (
		<>
			<Row>
				<Col md="12">
					<Nav tabs className="border-tab nav-primary">
						<NavItem>
							<NavLink className={`${primarycolorTab === "1" ? "active" : ""} f-14`} onClick={() => setprimarycolorTab("1")} style={{ cursor: "pointer" }}>
								<i className="fa fa-upload me-2"></i> Экспорт
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink className={`${primarycolorTab === "2" ? "active" : ""} f-14`} onClick={() => setprimarycolorTab("2")} style={{ cursor: "pointer" }}>
								<i className="fa fa-download me-2"></i> Импорт
							</NavLink>
						</NavItem>
						<i className="fa fa-question-circle f-18 c-pointer" id={"Popover-1"} style={{ color: "#898989" }}></i>
						<CommonPopover
							item={{
								placement: "right",
								id: "1",
								trigger: "hover",
								Popoverbody: "Загрузка архива с ранее созданным шаблоном",
							}}
						/>
					</Nav>
				</Col>
				<Col md="12">
					<TabContent activeTab={primarycolorTab}>
						<TabPane className="fade show" tabId="1">
							<Btn attrBtn={{ color: "info", onClick: toggle }}>
								<i className="fa fa-file-zip-o me-2"></i> Скачать шаблон
							</Btn>
						</TabPane>
						<TabPane tabId="2">
							<Btn attrBtn={{ color: "info", onClick: download }}>
								<i className="fa fa-download me-2"></i> Загрузить шаблон
							</Btn>
						</TabPane>
					</TabContent>
				</Col>
			</Row>
			<CommonModal isOpen={modal} title={"Выберите объекты для скачивания"} toggler={handleExport} togglerClose={toggle} closeText="Отмена" saveText="Скачать" size="md">
				<CardBody style={{ padding: "10px", paddingLeft: "20px", paddingTop: "0" }} className="email-wrap">
					<P>Основные данные шаблона:</P>
					<div className="checkbox checkbox-primary ms-2">
						<Input id={`checkbox-primary-header`} type="checkbox" checked={header} onChange={(e) => setHeader(e.target.checked)} />
						<Label for={`checkbox-primary-header`}>Шапка сайта</Label>
					</div>
					<div className="checkbox checkbox-primary ms-2">
						<Input id={`checkbox-primary-footer`} type="checkbox" checked={footer} onChange={(e) => setFooter(e.target.checked)} />
						<Label for={`checkbox-primary-footer`}>Подвал сайта</Label>
					</div>
					<div className="mt-2">
						<Label for={`checkbox-primary-body`}>Тело страниц сайта:</Label>
					</div>
					{pages.map((item, i) => (
						<div key={i} className="checkbox checkbox-primary col-md-6 ms-4">
							<Input id={`checkbox-${i}`} type="checkbox" checked={checkedPages.includes(item.alias)} onChange={() => handleCheckboxChange(item.alias)} />
							<Label for={`checkbox-${i}`}>
								{item.name}
								<span className="font-primary ms-2">/{item.alias}</span>
							</Label>
						</div>
					))}

					<hr />
					<P>Дополнительные данные шаблона:</P>
					<div className="checkbox checkbox-primary ms-2">
						<Input id={`checkbox-primary-menu`} type="checkbox" checked={menu} onChange={(e) => setMenu(e.target.checked)} />
						<Label for={`checkbox-primary-menu`}>Меню</Label>
					</div>
					<div className="checkbox checkbox-primary ms-2">
						<Input id={`checkbox-primary-links`} type="checkbox" checked={links} onChange={(e) => setLinks(e.target.checked)} />
						<Label for={`checkbox-primary-links`}>Ссылки</Label>
					</div>
					<div className="checkbox checkbox-primary ms-2">
						<Input id={`checkbox-primary-logo`} type="checkbox" checked={logo} onChange={(e) => setLogo(e.target.checked)} />
						<Label for={`checkbox-primary-logo`}>Логотип</Label>
					</div>
					<div className="checkbox checkbox-primary ms-2">
						<Input id={`checkbox-primary-theme`} type="checkbox" checked={theme} onChange={(e) => setTheme(e.target.checked)} />
						<Label for={`checkbox-primary-theme`}>Тема</Label>
					</div>
					<div className="checkbox checkbox-primary ms-2">
						<Input id={`checkbox-primary-auth`} type="checkbox" checked={auth} onChange={(e) => setAuth(e.target.checked)} />
						<Label for={`checkbox-primary-auth`}>Настройки авторизации</Label>
					</div>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default ExportImport;
