import React, { Fragment, useContext, useEffect, useState } from "react";
import { Modal, Col, CardBody } from "reactstrap";
import { ShoppingCart } from "react-feather";

import ProductPlaceOrder from "../../../PackageOffers/ClientView/ProductPlaceOrder";
import { Btn, H4, H6, Image, P } from "../../../../../AbstractElements";
import CommonModal from "../../../../UiKits/Modals/common/modal";

const ProductModal = (props) => {
	const [open, setOpen] = useState(props.value);

	const [singleProduct, setSingleProduct] = useState([]);

	useEffect(() => {
		props.productItem &&
			props.productItem.forEach((product, i) => {
				if (product.id === props.dataid) {
					setSingleProduct(product);
				}
			});
		console.log(props.productItem);
	}, [props.productItem, props.dataid]);

	const onCloseModal = () => {
		setOpen(false);
		props.setOpenModal(false);
	};

	const [payModal, setPayModal] = useState(false);

	const togglePay = (product) => {
		setOpen(false);
		setPayModal(!payModal);
		setName(product.name);
		setPrice(product.price);
	};

	const [price, setPrice] = useState(0);
	const [name, setName] = useState("");

	const SendPay = () => {
		// const newPay = {
		// 	client_id: 1, // по нормальному узнать id пользователя
		// 	package_id: id,
		// };
		// axios
		// 	.post(`/api/clientsPackageOffers.json`, newPay)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Данные отправлены!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setPayModal(!payModal);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
	};

	const installFunc = () => {
		props.handleInstall(singleProduct.id);
		onCloseModal();
	};

	return (
		<Fragment>
			<Modal className="modal-dialog modal-lg modal-dialog-centered product-modal" isOpen={open}>
				<div className="modal-body">
					<div className="modal-header">
						<div className="modal-title">
							<div className="product-box row">
								<Col lg="6" className="me-2">
									<Image
										attrImage={{
											className: "img-fluid",
											src: singleProduct.img ? require("../../../../../assets/images/" + singleProduct.img) : `https://dummyimage.com/900x900`,
											alt: "",
										}}
									/>
								</Col>
								<Col lg="5" className="product-details text-start d-flex pb-0" style={{ flexDirection: "column" }}>
									<H4 attrH4={{ className: "mb-4" }}>{singleProduct.name}</H4>
									{singleProduct.price !== 0 ? (
										<div className="product-price">
											{props.symbol}
											{singleProduct.price}
											{singleProduct.discountPrice && (
												<del>
													{props.symbol}
													{singleProduct.discountPrice}
												</del>
											)}
										</div>
									) : (
										<div className="product-price">Бесплатно</div>
									)}
									<div className="product-view">
										<H6 attrH6={{ className: "f-w-600" }}>Описание</H6>
										<P attrPara={{ className: "mb-0" }}>{singleProduct.description}</P>
									</div>
									{singleProduct.showAuthorName && (
										<Col className="d-flex mt-3">
											<H6 attrH6={{ className: "f-w-600 mb-0 mt-0 me-2" }}>Автор: </H6>
											{singleProduct.author && <P attrPara={{ className: "m-0 " }}>{singleProduct.author.name}</P>}
										</Col>
									)}
									<Col className="d-flex mt-3">
										<H6 attrH6={{ className: "f-w-600 mb-0 mt-0 me-2" }}>Компания: </H6>
										<P attrPara={{ className: "m-0 " }}>{singleProduct.company}</P>
									</Col>
									{singleProduct.installed ? (
										<div className="product-qnty mt-4 d-flex" style={{ flexGrow: "1", alignItems: "end" }}>
											<Btn attrBtn={{ color: "success", className: "pe-3 ps-3", outline: true, disabled: true }}>
												<i className="fa fa-check"></i> Установлено
											</Btn>
										</div>
									) : singleProduct.price !== 0 ? (
										<div className="product-qnty mt-4 d-flex" style={{ flexGrow: "1", alignItems: "end" }}>
											<Btn attrBtn={{ color: "primary", onClick: () => togglePay(singleProduct) }}>
												<ShoppingCart size={"18px"} style={{ margin: "0 8px -3px 0" }} />
												Купить
											</Btn>
										</div>
									) : (
										<div className="product-qnty mt-4 d-flex" style={{ flexGrow: "1", alignItems: "end" }}>
											<Btn attrBtn={{ color: "success", className: "", onClick: () => installFunc() }}>Установить</Btn>
										</div>
									)}
								</Col>
							</div>
						</div>
						<Btn attrBtn={{ color: "transprant", className: "btn-close", onClick: onCloseModal, type: "button", databsdismiss: "modal", arialabel: "Close" }}></Btn>
					</div>
				</div>
			</Modal>
			<CommonModal isOpen={payModal} toggler={SendPay} togglerClose={togglePay} size="lg" saveText="Оплатить">
				<CardBody style={{ padding: "0 51px" }}>
					<ProductPlaceOrder
						serviceList={[
							{
								name: name,
								price: price,
							},
						]}
					/>
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};
export default ProductModal;
