import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Image, LI, UL, P } from "../../../../AbstractElements";
import ChatAppContext from "../../../../_helper/Chat";
import { useKeycloak } from "@react-keycloak/web";

const ChatMessage = () => {
	const {
		selectedChat,
		chatMessages
	} = useContext(ChatAppContext);

	const [messagesObject, setMessagesObject] = useState();

	const {keycloak} = useKeycloak();

	var images = require.context("../../../../assets/images", true);


	useEffect(() => {
		setMessagesObject(chatMessages && chatMessages.map((item, index) => {
			return (
				<LI attrLI={{ className: "clearfix border-0" }} key={index}>
					<div
						className={`message  ${
							item.senderUserId === keycloak.tokenParsed.sub
								? "my-message"
								: "other-message pull-right "
						}`}
					>
						<Image
							attrImage={{
								src: `https://dummyimage.com/800x800`,
								className: `rounded-circle ${
									item.senderUserId === keycloak.tokenParsed.sub
										? "float-start"
										: "float-end"
								} chat-user-img img-30`,
								alt: "",
							}}
						/>

						{item.text}
						<div className="message-data text-end">
							<span className="message-data-time">
								{formatTime(item.sendTime)}
							</span>
						</div>
					</div>
				</LI>
			);
		}));

		var container = document.querySelector(".chat-history");
		setTimeout(function () {
			container.scrollBy({ top: 9007199254740992, behavior: "smooth" });
		}, 310);
	}, [chatMessages])

	function formatTime(time) {
		const date = new Date(time);
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		return `${hours}:${minutes}`;
	}

	return (
		<Fragment>
				<div className="chat-history chat-msg-box custom-scrollbar">
					{selectedChat && chatMessages && chatMessages.length > 0 ? (
						<UL attrUL={{ className: "simple-list" }}>
							{messagesObject}
						</UL>
					) : (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								height: "100%",
								color: "#898989",
							}}
						>
							<P>Диалог пуст..</P>
						</div>
					)}
				</div>
		</Fragment>
	);
};
export default ChatMessage;
