// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-bottom: none;
    cursor: pointer;
    margin-right: 10px;
}

    .tab-btn:last-child {
        margin-right: 0;
    }

    .tab-btn.active {
        background-color: #fff;
        border-bottom: 1px solid #fff;
        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    }

.css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #7366ff !important;
}

.css-1aquho2-MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #7366ff !important;
}

.margin-left {
    margin-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Application/Specifications/SpecificationDetails/PositionSettingsModal.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;IAEI;QACI,eAAe;IACnB;;IAEA;QACI,sBAAsB;QACtB,6BAA6B;QAC7B,yCAAyC;IAC7C;;AAEJ;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,WAAW;IAEX,sDAAsD;IACtD,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".tab-btn {\n    display: inline-block;\n    padding: 10px 20px;\n    background-color: #f0f0f0;\n    border: 1px solid #ccc;\n    border-bottom: none;\n    cursor: pointer;\n    margin-right: 10px;\n}\n\n    .tab-btn:last-child {\n        margin-right: 0;\n    }\n\n    .tab-btn.active {\n        background-color: #fff;\n        border-bottom: 1px solid #fff;\n        box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);\n    }\n\n.css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {\n    color: #7366ff !important;\n}\n\n.css-1aquho2-MuiTabs-indicator {\n    position: absolute;\n    height: 2px;\n    bottom: 0;\n    width: 100%;\n    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    background-color: #7366ff !important;\n}\n\n.margin-left {\n    margin-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
