import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import DataTable from 'react-data-table-component';
import { Col, Row, Label } from 'reactstrap';
import Select from 'react-select';
import { H5 } from '../../../../AbstractElements';
import { useKeycloak } from '@react-keycloak/web';

import { TelephonyApi } from '../../../../api';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const ComparisonPeriods = () => {
	const [callStatistics, setCallStatistics] = useState([]);
	const [callStatisticsByEmployees, setCallStatisticsByEmployees] = useState([]);
	const [selectCurPeriod, setSelectCurPeriod] = useState({ label: 'Текущий месяц', value: 'currentMonth' });
	const [selectLastPeriod, setSelectLastPeriod] = useState({ label: 'Педыдущий месяц', value: 'lastMonth' });

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const getData = async () => {
		try {
			await axios
				.get(`${TelephonyApi}getDynamicStatistics`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setCallStatistics(concatResults(response).statistics);

				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});

			await axios
				.get(`${TelephonyApi}getComparisonStatistics`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setCallStatisticsByEmployees(concatResults(response).statistics);

				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
		catch (error) {

		}

	}

	useEffect(() => {
		getData()
	}, []);

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getDate();
		const month = dateObject.getMonth() + 1;
		const year = dateObject.getFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	const data = {
		series: [
			{
				name: 'Звонки',
				data: callStatistics.map((call) => call.incomingCalls),
			},
		],
		options: {
			chart: {
				height: 350,
				type: 'line',
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'straight',
			},

			title: {
				text: '',
				align: 'left',
			},
			grid: {
				row: {
					colors: ['#f3f3f3', 'transparent'],
					opacity: 0.5,
				},
			},
			xaxis: {
				categories: callStatistics.map((call) => formatDate(call.date)),
			},
			colors: ['#54ba4a', '#ff3364', '#fb1506', '#d18a00'],
			tooltip: {
				x: {
					format: 'dd/MM/yy HH:mm',
				},
			},
		},
	};

	const columns = [
		{
			name: 'Дата',
			selector: (row) => `${formatDate(row.date)}`,
			sortable: true,
		},
		{
			name: <div>Общее число звонков за текущий период</div>,
			selector: (row) => `${row.totalCallsCurrentPeriod}`,
			sortable: true,
		},
		{
			name: <div>Общее число звонков за предыдущий период</div>,
			selector: (row) => `${row.totalCallsPrevPeriod}`,
			sortable: true,
		},
	];

	return (
		<Col xl="12">
			<Row>
				<Col md="6">
					<Label>Отчетный период</Label>
					<Select
						placeholder="Выберите..."
						options={[
							{ label: 'Текущая неделя', value: 'currentWeek' },
							{ label: 'Текущий месяц', value: 'currentMonth' },
							{ label: 'Текущий квартал', value: 'currentQuarter' },
							{ label: 'Последние 7 дней', value: 'last7Days' },
							{ label: 'Последние 30 дней', value: 'last30Days' },
						]}
						className="js-example-basic-single col-sm-12 mb-4"
						value={selectCurPeriod}
						onChange={(option) => setSelectCurPeriod(option)}
					/>
				</Col>
				<Col md="6">
					<Label>Предыдущий период</Label>
					<Select
						placeholder="Выберите..."
						options={[
							{ label: 'Предыдущая неделя', value: 'lastWeek' },
							{ label: 'Предыдущий месяц', value: 'lastMonth' },
							{ label: 'Предыдущий квартал', value: 'lastQuarter' },
							{ label: 'Последние 7 дней', value: 'last7Days' },
							{ label: 'Последние 30 дней', value: 'last30Days' },
						]}
						className="js-example-basic-single col-sm-12 mb-4"
						value={selectLastPeriod}
						onChange={(option) => setSelectLastPeriod(option)}
					/>
				</Col>
			</Row>
			<H5>Динамика звонков</H5>
			<div className="chart-container crypto-chart mb-4">
				<Row className="row">
					<ReactApexChart type="line" height={400} series={data.series} options={data.options} />
				</Row>
			</div>
			<Col>
				<H5 attrH5={{ className: 'mb-4' }}>Сравнение периодов</H5>
				<DataTable columns={columns} data={callStatisticsByEmployees} defaultSortField="name" striped pagination noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			</Col>
		</Col>
	);
};

export default ComparisonPeriods;
