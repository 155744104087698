import React, { Fragment, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { routes } from "./Routes";
import AppLayout from "../Layout/Layout";
import EmptyLayout from "../Layout/EmptyLayout";  // ����������� EmptyLayout
import { useConpagesContext } from "../_helper/ConsecutivePages/ConpagesProvider";
import { useCompanyContext } from "../_helper/Company/CompanyProvider";

const LayoutRoutes = () => {
	const { keycloak, initialized } = useKeycloak();
	const navigate = useNavigate();

	const { conpagesData, companyConpagesData, conpagesDataStart } = useConpagesContext();
	const { reloadCompanyData } = useCompanyContext();

	useEffect(() => {
		if (conpagesDataStart === true) {
			navigate("/app/users/edit");
		} else if (conpagesData) {
			navigate(conpagesData.link);
		} else if (companyConpagesData) {
			navigate(companyConpagesData.link);
		}
	}, [conpagesData, companyConpagesData, conpagesDataStart]);

	const isAuthorized = (role) => {
		if (keycloak && role) {
			return keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role);
		}
		return false;
	};

	const keycloakLogin = () => {
		if (initialized) {
			keycloak.login();
		}
		// ��� ���������� ����� ������� ��������� ������ ��� ���-��, ��� ��������� �� ��������
		return <></>;
	};

	const navigateTo = (path) => {
		navigate(path);
		return <></>;
	};

	return (
		<Routes>

			{routes.map(({ path, Component, role, unauthorised, layout }, i) => {
				return (
					<Fragment key={i}>
						<Route element={layout === "empty" ? <EmptyLayout /> : <AppLayout />} key={i}>
							{path === "/widgets/blank-view-plan-map" ? (
								<Route path={path} element={Component} />
							) : (
								keycloak.authenticated ? (
									role === undefined || role === null || role === "admin" || isAuthorized(role) ? (
										<Route path={path} element={Component} />
									) : (
										<Route path={path} element={navigateTo("/")} />
									)
								) : (
									<Route path={path} element={keycloakLogin()} />
								)
							)}
						</Route>
					</Fragment>
				);
			})}




		</Routes>
	);
};

export default LayoutRoutes;
