import React, { Fragment } from "react";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import { Trash2, Plus } from "react-feather";

import { Btn } from "../../../../AbstractElements";

const TableTask = ({ users, data, deleteTask, addTask, type = "view", rate }) => {
	const tableColumns = [
		{
			name: "Задача",
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: <div>Постановщик</div>,
			selector: (row) => `${getUserInitialsById(row.creatorId)}`,
			sortable: true,
			center: false,
		},
		{
			name: <div>Статус</div>,
			selector: (row) => `${row.status ? row.status.name : ""}`,
			sortable: true,
			center: false,
		},
		{
			name: <div>Кол-во часов</div>,
			selector: (row) => `${row.spentTime ? `${row.spentTime} ч` : ""}`,
			sortable: true,
			center: false,
		},
		{
			name: <div>Стоимость задачи</div>,
			selector: (row) => `${row.spentTime && rate ? `${row.spentTime * rate} ₽` : ""}`,
			sortable: true,
			center: false,
		},
		{
			name: <div>Проект</div>,
			selector: (row) => `${row.projectId ? row.projectName : ""}`,
			sortable: true,
			center: true,
		},
		{
			name: "",
			button: true,
			selector: (row) =>
				type === "view" ? (
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				) : (
					<Btn
						attrBtn={{
							size: "xs",
							outline: true,
							className: "me-2",
							onClick: () => addTask(row.id),
						}}
					>
						<Plus size={"14px"} style={{ margin: "0 2px -3px 0" }} />
						Добавить
					</Btn>
				),
			width: type === "view" ? "6%" : "12%",
		},
	];

	function getUserInitialsById(id) {
		try {
			return users.find((u) => u.value === id).label;
		} catch (error) {
			return "Не удалось загрузить инициалы";
		}
	}

	return (
		<Fragment>
			<DataTable
				data={data}
				columns={tableColumns}
				highlightOnHover={true}
				center={true}
				pagination
				pointerOnHover
				noDataComponent={"В данной таблице ещё нет записей."}
				paginationComponentOptions={{
					rowsPerPageText: "Строк на странице:",
					rangeSeparatorText: "из",
				}}
			/>
		</Fragment>
	);
};

export default TableTask;
