import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Container, InputGroup, InputGroupText } from "reactstrap";
import { useLocation } from "react-router";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";
import axios from "axios";
import Dropzone from "react-dropzone-uploader";
import Select from "react-select";
// import Ckeditor from "react-ckeditor-component";

import { Label, Input, Col, Row } from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import {useDocumentManagementContext} from "../../../../_helper/DocumentManagement/DocumentManagementProvider";

const AddEditDocument = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { documentData, getDocument, documentStatuses, getDocumentStatuses, createDocument, createDocumentWithSigning, updateDocument } = useDocumentManagementContext();
	const [addSigningDocument, setAddSigningDocument] = useState(false);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const documentId = queryParams.get("documentId");
	const typeId = queryParams.get("typeId");
	//typeId

	const [statuses, setStatuses] = useState([]);

	useEffect(() => {
		documentId && getDocument(documentId);
		getDocumentStatuses();
		/*axios
			.get("/api/directory-statuses.json")
			.then((response) => {
				const selectOptions = response.data.statuses.map((option) => ({
					label: option.label,
					value: option.id,
				}));
				setStatuses(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});*/
	}, [companyData]);

	useEffect(() => {
		if (documentData) {
			setName(documentData.documentTitle);
			setNumber(documentData.documentNumber);
			setSelectStatus(documentData.statusId);
			setContent(JSON.parse(documentData.content));
			setId(documentData.id);
		}
	}, [documentData])

	const [name, setName] = useState("");
	const [content, setContent] = useState("");

	const handleChange = (evt) => {
		setContent(evt.editor.getData());
	};

	const [id, setId] = useState("");

	const [number, setNumber] = useState("");
	const [selectStatus, setSelectStatus] = useState(null);

	useEffect(() => {
		/*axios
			.get("/api/documents.json")
			.then((response) => {
				const foundItem = response.data.documents.find((item) => item.id === documentId);
				console.log(foundItem);
				if (foundItem) {
					setName(foundItem.documentTitle);
					setNumber(foundItem.documentNumber);
					setSelectStatus({ label: foundItem.status, value: foundItem.status });
					setContent(foundItem.content);
					setId(foundItem.id);
				}
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});*/
	}, []);

	const send = () => {
		console.log(123);
		console.log(selectStatus);
		console.log(typeId);
		if (id) {
			updateDocument(id, number, name, keycloak.tokenParsed.sub, companyData[0]?.id, selectStatus.id, typeId, JSON.stringify(content), null);
		} else {
			if (addSigningDocument === false) {
				createDocument(number, name, selectStatus.id, typeId, JSON.stringify(content), null);
			} else {
				createDocumentWithSigning(number, name, selectStatus.id, typeId, JSON.stringify(content), null);
			}
		}
		/*const newEvent = {
			id: id,
			documentNumber: number,
			documentTitle: name,
			author: {
				name: keycloak.tokenParsed.name,
				id: keycloak.tokenParsed.sub,
			},
			status: selectStatus.value,
			content: JSON.stringify(content),
			attachments: [],
		};
		console.log(JSON.stringify(content));*/
		// axios
		// 	.post(`/api/clientsDemandsForm.json`, newEvent)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Календарь добавлен!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
	};

	const handleSubmit = (files, allFiles) => {
		// axios
		// 	.post(`${NewsApi}createNews`, newServer, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Логотип установлен!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setModal(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error('Ошибка получения данных:', error);
		// 	});
		allFiles.forEach((f) => f.remove());
	};

	const getUploadParams = ({ file, meta }) => {
		if (file.size > 2 * 1024 * 1024) {
			toast.error("Максимальный размер файла 2 Мб!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			return { abort: true };
		}

		return { url: "https://httpbin.org/post" };
	};

	const handleChangeStatus = ({ meta, file }, status) => {};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={name ? `Редактировать документ` : `Добавить документ`} parent="Документоборот" title={name ? `Редактировать документ` : `Добавить документ`} />
			<Container fluid={true}>
				<Card>
					<CardBody className="m-25 pt-2">
						<Row>
							<Col md="8" className="row mb-2">
								<Label className="col-sm-9 col-form-label">Номер документа</Label>
								<Col sm="12">
									<InputGroup>
										<InputGroupText>{"№"}</InputGroupText>
										<Input className="form-control" type="number" value={number} onChange={(e) => setNumber(e.target.value)} />
									</InputGroup>
								</Col>
							</Col>
							<Col md="4" className="row mb-2">
								<Label className="col-sm-9 col-form-label">Статус</Label>
								<Col sm="12">
									<Select type="tel" placeholder="Выберите..." value={selectStatus} options={documentStatuses} onChange={(option) => setSelectStatus(option)} />
								</Col>
							</Col>
						</Row>
						<Col className="row col-sm-12 mb-2">
							<Label className="col-sm-9 col-form-label">Название документа</Label>
							<Col sm="12">
								<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-2">
							<Label className="col-sm-9 col-form-label">Содержание</Label>
							<Col sm="12">
								{/* <Ckeditor
									activeclassName="p10"
									content={content}
									events={{
										change: handleChange,
									}}
								/>{" "} */}
							</Col>
						</Col>
						<Col className="row col-sm-12 mb-2 mt-4">
							<Label className="col-sm-9 col-form-label">Вложения</Label>
							<Col sm="12">
								<Dropzone
									getUploadParams={getUploadParams}
									onChangeStatus={handleChangeStatus}
									onSubmit={handleSubmit}
									inputContent="Выбрать файл или перетащить сюда"
									submitButtonContent="Отправить"
								/>
							</Col>
						</Col>
						<Row style={{ alignItems: "end", justifyContent: "space-between" }}>
							<Col className="row col-sm-8 mb-2">
								<Label className="col-sm-9 col-form-label">Автор</Label>
								<Col sm="12">
									<Input className="form-control" type="tel" value={keycloak.tokenParsed.name} disabled />
								</Col>
							</Col>
							<Col className="col-sm-4 mb-2">
								<Btn attrBtn={{ className: "pt-2 pb-2 f-right me-4", onClick: () => setAddSigningDocument(!addSigningDocument) }}>{ addSigningDocument === false ? "Добавить маршрут согласования" : "Удалить маршрут согласования"}</Btn>
							</Col>
						</Row>
					</CardBody>
					<CardFooter>
						<div className="modal-footer">
							<Btn attrBtn={{ color: "primary", onClick: send }}>Сохранить изменения</Btn>
						</div>
					</CardFooter>
				</Card>
			</Container>
		</Fragment>
	);
};
export default AddEditDocument;
