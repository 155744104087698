import { Breadcrumbs } from '../../../AbstractElements';
import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

import DnDFlow from './DnDFlow';

const LearningList = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Добавить диаграмму БП" parent="Бизнес-процессы" title="Добавить диаграмму БП" />
			<Container fluid={true}>
				<Row className="learning-block">
					<Card>
						<CardBody style={{ height: '100vh' }}>
							<DnDFlow />
						</CardBody>
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};

export default LearningList;
