import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';
import { Breadcrumbs } from '../../../AbstractElements';
import TableSpecifications from './TableSpecifications';

const Specifications = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Список спецификаций" parent="ERP" title="Список спецификаций" />
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Card>
                        <CardBody>
                            <TableSpecifications />
                        </CardBody>
                    </Card>
                </div>
            </Container>
        </Fragment>
    );
};

export default Specifications;
