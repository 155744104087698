import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const DropdownCommon = ({ dropdownMain, icon = true, iconName, btn, options, name }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown {...dropdownMain} isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle {...btn} style={{ width: '200px' }}>
				{icon && <i className={iconName}></i>}
				{!icon && name}
			</DropdownToggle>
			<DropdownMenu>
				{options.map((item) => (
					<DropdownItem key={item.value}>
						<Link to={`${process.env.PUBLIC_URL}/app/users/profile?courseId=${item.Id}`}>{item.Name}</Link>
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
};

export default DropdownCommon;
