import React, { useMemo, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import FilterComponent from '../../../Goods/PurchaseHistory/FilterComponent';
import { H6, P } from '../../../../../AbstractElements';

import { AnalyticsApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const TableByClients = () => {
	const [data, setData] = useState([]);
	const [revenue, setRevenue] = useState(0);
	const [report, setReport] = useState([]);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${AnalyticsApi}getClientReports`, { params: { query: '' }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				console.log(response);
				setReport(concatResults(response).clientReports);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	function calculateAllCost(visitHistory) {
		let totalDuration = 0;
		if (!visitHistory) {
			return 0;
		}
		visitHistory.forEach((entry) => {
			totalDuration += entry.cost;
		});

		return totalDuration;
	}

	function costHour(visitHistory) {
		if (!visitHistory) {
			return 0;
		}

		return (calculateAllCost(visitHistory) / calculateTotalDuration(visitHistory)).toFixed(1);
	}

	function calculateTotalCost(items, type) {
		if (!items) {
			return 0;
		}

		let totalCost = 0;
		if (type === 'goods') {
			items.forEach((item) => {
				if (item.type === 'goods') {
					totalCost += item.cost || 0;
				}
			});
		} else {
			items.forEach((item) => {
				if (item.type === 'service') {
					totalCost += item.cost || 0;
				}
			});
		}

		return totalCost;
	}

	function calculateTotal(items, type) {
		if (!items) {
			return 0;
		}

		let totalCost = 0;
		if (type === 'goods') {
			items.forEach((item) => {
				if (item.type === 'goods') {
					totalCost += 1;
				}
			});
		} else {
			items.forEach((item) => {
				if (item.type === 'service') {
					totalCost += 1;
				}
			});
		}

		return totalCost;
	}

	function calculateTotalDuration(visitHistory) {
		let totalDuration = 0;
		if (!visitHistory) {
			return 0;
		}
		visitHistory.forEach((entry) => {
			totalDuration += entry.duration;
		});

		return totalDuration;
	}

	function percentageRevenue(visitHistory) {
		if (!visitHistory) {
			return 0;
		}

		return ((calculateAllCost(visitHistory) / revenue) * 100).toFixed(1);
	}

	const columns = [
		{
			name: <div>Клиент</div>,
			selector: (row) => <H6>{row.name}</H6>,
			sortable: true,
		},
		{
			name: <div>Выручка</div>,
			selector: (row) => `${row.revenue} ₽`,
			sortable: true,
		},
		{
			name: <div>% от общей выручки</div>,
			selector: (row) => `${row.totalRevenuePercent} %`,
			sortable: true,
		},
		{
			name: <div>Средний чек</div>,
			selector: (row) => `${row.averageBill} ₽`,
			sortable: true,
		},
		{
			name: <div>Количество посещений</div>,
			selector: (row) => `${row.countVisits}`,
			sortable: true,
		},
	];

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = report.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return <>{data && <DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />}</>;
};

export default TableByClients;
