import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Select from "react-select";
import { useKeycloak } from "@react-keycloak/web";
import { TaskApi } from "../../../api";
import { Btn } from "../../../AbstractElements";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../concatResults";

const AccessError = ({ taskId, userId }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const handleGoBack = () => {
		window.history.back();
	};

	const { handleSubmit } = useForm();

	const [selectedType, setSelectedType] = useState(null);

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const [loading, setLoading] = useState(false);

	const requestAccess = () => {
		setLoading(true);
		const newRequest = {
			userId: userId,
			position: selectedType.label,
		};

		axios
			.get(`${TaskApi}get`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				const foundItem = concatResults(response).tasks.find((item) => item.id === taskId);
				const updItem = {
					...foundItem,
					accessRequests: [...foundItem.accessRequests, newRequest],
				};
				axios
					.put(`${TaskApi}update`, updItem, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						toast.success("Запрос отправлен!", {
							position: toast.POSITION.TOP_RIGHT,
						});
						setLoading(false);
					})
					.catch((error) => {
						console.error("Ошибка получения данных:", error);
						setLoading(false);
					});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
				setLoading(false);
			});
	};

	return (
		<Fragment>
			<Col md="7" style={{ margin: "3rem auto 4rem" }}>
				<div className="badge-light-danger text-center mt-4 p-3 pt-4 pb-4 b-r-5 mb-4 f-16">
					<i className="fa fa-warning"></i> Ошибка доступа
				</div>
				<div className="m-b-20 m-t-40 f-16">У вашего пользователя нет прав для просмотра страницы.</div>
				<div className="f-16">
					<span style={{ cursor: "pointer" }} className="font-primary" onClick={toggle}>
						Запросите доступ
					</span>{" "}
					для получения прав или
					<span style={{ cursor: "pointer" }} className="font-primary" onClick={handleGoBack}>
						{" "}
						вернитесь на предыдущую страницу
					</span>
				</div>
			</Col>
			<Modal isOpen={modal} toggle={toggle} size="md">
				<ModalHeader toggle={toggle}>Запросить доступ</ModalHeader>
				<ModalBody style={{ padding: "0 25px 25px" }}>
					<Form className="form-bookmark needs-validation" onSubmit={handleSubmit(requestAccess)}>
						<Col className="row col-sm-12 mb-4">
							<Label className="col-sm-9 col-form-label">Выберите позицию</Label>
							<Col sm="12" className="mb-2">
								<Select
									placeholder="Выберите..."
									value={selectedType}
									options={[
										{ label: "Наблюдатель", value: "watchers" },
										{ label: "Исполнитель", value: "doer" },
										{ label: "Соисполнитель", value: "coDoers" },
										{ label: "Постановщик", value: "creator" },
									]}
									className="js-example-basic-single col-sm-12"
									onChange={(selected) => setSelectedType(selected)}
								/>
							</Col>
						</Col>
						<Btn attrBtn={{ color: "primary", type: "submit", disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Запросить"}</Btn>&nbsp;&nbsp;
						<Btn attrBtn={{ color: "secondary", onClick: toggle }}>Отмена </Btn>
					</Form>
				</ModalBody>
			</Modal>
		</Fragment>
	);
};
export default AccessError;
