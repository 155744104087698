import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Trash2, Edit, Eye, ExternalLink } from 'react-feather';
import { Card, CardBody, Container, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import ru from 'date-fns/locale/ru';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Btn } from '../../../../../AbstractElements';
import { CompaniesApi } from '../../../../../api';
import { toast } from 'react-toastify';
import EditCompanyTab from './EditCompanyTab';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const CompanyEdit = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const companyGroupId = queryParams.get('companyGroupId');
    const [company, setCompany] = useState();

    const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

    useEffect(() => {
        axios
            .get(`${CompaniesApi}get-company-by-groupid/${companyGroupId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
            .then((response) => {
                setCompany(response.data);
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
    }, []);

	const [link, setLink] = useState([]);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Редактирование компании" subParent="Список компаний" parent="Админ" title="Редактирование компании" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardHeader>
							<Link to={`${process.env.PUBLIC_URL}/companies`} className="f-18">
								<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
							</Link>
						</CardHeader>
                        {company &&
                            <CardBody>
                                <Col className="mb-3 m-l-10">
                                    <h3>{company.shortLegalName}</h3>
                                </Col>
                                <Col className="mb-3 m-l-10">
                                    <h4>{company.fullLegalName}</h4>
                                </Col>
                                <Col className="mb-3 m-l-10">
                                    <h4>ИНН: {company.inn}</h4>
                                </Col>
                                <Col className="mb-3 m-l-10">
                                    <h4>Директор: {company.directorFullName}</h4>
                                </Col>
                                <EditCompanyTab companyGroupId={companyGroupId}/>
                            </CardBody>
                        }
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default CompanyEdit;
