import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { H5, Btn } from '../../../../AbstractElements';
import { EducationApi } from '../../../../api';
import { ArrowRight } from 'react-feather';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const MyCourse = ({ courses, userCourse, curCourseId }) => {
	const [curCourse, setCurCourse] = useState(null);
	const [completedNumberLessons, setCompletedNumberLessons] = useState(0);
	const [totalNumberLessons, setTotalNumberLessons] = useState(0);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [value, setValue] = useState('');
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	useEffect(() => {
		if (courses) {
			setCurCourse(courses.find((course) => course.Id == curCourseId) ?? courses[0]);
		}

		if (curCourse && userCourse) {
			let userCourseFind = userCourse.find((ucourse) => ucourse.courseId === curCourse.id);
			setValue(curCourse.name);
			changeNumbers(userCourseFind.id);
		}

	}, [curCourse]);

	const changeNumbers = (ucId) => {
		//console.log(ucId);
		ucId &&
		axios
			.get(`${EducationApi}get-studentcourse-lessons-count/${ucId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				setCompletedNumberLessons(concatResults(response).completedLessonsCount ?? 0);
				setTotalNumberLessons(concatResults(response).lessonsCount ?? 0);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}

	const select = (item) => {
		if (userCourse) {
			let userCourseFind = userCourse.find((ucourse) => ucourse.courseId === item.id);
			setValue(item.name);
			changeNumbers(userCourseFind.id);
		};
	}

	const DropdownCommon = ({ dropdownMain, icon = true, iconName, btn, options, name }) => {

		return (
			<Dropdown {...dropdownMain} isOpen={dropdownOpen} toggle={toggle}>
				<DropdownToggle {...btn} style={{ width: '200px' }}>
					{icon && <i className={iconName}></i>}
					{!icon && value}
				</DropdownToggle>
				<DropdownMenu>
					{options.map((item) => (
						<DropdownItem key={item.id} onClick={() => select(item)}>
							{item.name}
							{/*<Link to={`${process.env.PUBLIC_URL}/app/users/profile?courseId=${item.id}`}>{item.name}</Link>*/}
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		);
	};

	const todayProgressData = {
		series: [completedNumberLessons != 0 && totalNumberLessons != null ? ((completedNumberLessons / totalNumberLessons) * 100).toFixed(1) : 0],
		options: {
			chart: {
				height: 300,
				type: 'radialBar',
			},
			plotOptions: {
				radialBar: {
					hollow: {
						size: '70%',
					},
				},
			},

			labels: ['Пройдено'],
			colors: ['#7366ff'],
		},
	};
	return (
		<Card className="course-card">
			<CardHeader className="card-no-border">
				<div className="header-top">
					<H5 className="m-0">{value}</H5>
					<div className="card-header-right-icon">
						<DropdownCommon icon={false} name={value} options={courses} btn={{ caret: true }} />
					</div>
				</div>
			</CardHeader>
			<CardBody className="pt-0">
				<Row className="mt-2">
					<Col md="6">
						<div className="progress-chart-wrap">
							{
								todayProgressData && Array.isArray(todayProgressData.options) && Array.isArray(todayProgressData.series) &&
									<ReactApexChart
										type="radialBar"
										height={300}
										options={
											todayProgressData ? Array.isArray(todayProgressData.options) && todayProgressData.options : []
										}
										series={todayProgressData ? Array.isArray(todayProgressData.series) && todayProgressData.series : []}
									/>
							}
						</div>
					</Col>
					<Col md="6">
						<div className="light-card balance-card mb-4">
							<div>
								<span className="f-light f-18">Всего занятий:</span>
								<span className="ms-2 f-w-600 f-18">{totalNumberLessons}</span>
							</div>
						</div>
						<div className="light-card balance-card mb-4">
							<div>
								<span className="f-light f-18">Осталось пройти:</span>
								<span className=" ms-2 f-w-600 f-18">{totalNumberLessons - completedNumberLessons}</span>
							</div>
						</div>
						<div className="modal-footer">
							<Btn attrBtn={{ color: 'primary', outline: true }}>
								Продолжить занятие <ArrowRight size={'20px'} style={{ margin: '0 0 -5px 2px' }} />
							</Btn>
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

export default MyCourse;
