import React, { Fragment } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableLayoutDataTypes from './TableLayoutDataTypes';

const LayoutDataTypes = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Настройка справочника шаблонов экспортируемых файлов" parent="Экспорт данных" title="Настройка справочника шаблонов экспортируемых файлов" />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <TableLayoutDataTypes />
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default LayoutDataTypes;