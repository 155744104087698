import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ChevronLeft, ChevronRight, ArrowRight } from 'react-feather';
import {
	addDays,
	subDays,
	format,
	startOfWeek,
	isSameDay,
	parse, //  для разбора времени
	isBefore, //  для сравнения времени
	addMinutes,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { H5, Btn } from '../../../AbstractElements';

const SelectDateTime = ({ showBtn }) => {
	const timesheet = [
		{
			day_of_week: 'Monday',
			start_time: '08:30',
			end_time: '17:30',
		},
		{
			day_of_week: 'Tuesday',
			start_time: '08:30',
			end_time: '17:30',
		},
		{
			day_of_week: 'Wednesday',
			start_time: '08:30',
			end_time: '17:30',
		},
		{
			day_of_week: 'Thursday',
			start_time: '08:30',
			end_time: '17:30',
		},
		{
			day_of_week: 'Friday',
			start_time: '08:30',
			end_time: '17:30',
		},
	];

	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedTime, setSelectedTime] = useState(new Date());
	const [currentStartDate, setCurrentStartDate] = useState(new Date());

	const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

	const serviceDuration = 60;

	const generateAvailableTimeSlots = (date, timesheet) => {
		const dayOfWeek = format(date, 'EEEE');

		if (!timesheet) {
			return 'not select';
		}

		const scheduleEntry = timesheet.find((entry) => entry.day_of_week === dayOfWeek);

		if (scheduleEntry) {
			const startTime = parse(scheduleEntry.start_time, 'HH:mm', new Date());
			const endTime = parse(scheduleEntry.end_time, 'HH:mm', new Date());

			const timeSlots = [];
			let currentTime = startTime;
			while (isBefore(currentTime, endTime)) {
				timeSlots.push(format(currentTime, 'HH:mm'));
				currentTime = addMinutes(currentTime, serviceDuration); // Увеличиваем время
			}

			return timeSlots;
		}

		return [];
	};

	const calculateStartOfWeek = (date) => {
		const firstDayOfWeek = startOfWeek(date, { weekStartsOn: 1 }); // Пн - 1 день недели
		return firstDayOfWeek;
	};

	const renderDaysOfWeek = () => {
		return daysOfWeek.map((day, index) => (
			<Col md="1" key={index} className="dayOfWeek p-0">
				{day}
			</Col>
		));
	};

	const renderCalendarDays = () => {
		const startOfWeekDate = calculateStartOfWeek(currentStartDate);
		const days = [];

		for (let i = 0; i < 7; i++) {
			const day = addDays(startOfWeekDate, i);
			days.push(day);
		}

		return days.map((day) => (
			<Col md="1" style={{ padding: '4px', cursor: 'pointer', textAlign: 'center' }} key={day} className={`calendarDay  ${isSameDay(day, selectedDate) ? 'selected' : ''}`} onClick={() => handleDateClick(day)}>
				{format(day, 'd', { locale: ru })}
			</Col>
		));
	};

	const renderTimeSlots = () => {
		if (!selectedDate) {
			return null;
		}

		const slotsForSelectedDate = generateAvailableTimeSlots(selectedDate, timesheet);

		if (slotsForSelectedDate === 'not select') {
			return <div style={{ marginLeft: '12%', marginTop: '20px' }}>Для записи необходимо выбрать специалиста</div>;
		} else {
			return (
				<Col xxl="8" sm="10" className="mt-4" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '15px' }}>
					{slotsForSelectedDate.map((timeSlot) => (
						<Btn attrBtn={{ color: 'primary', outline: true, className: 'mb-3 btn-pill btn-air-primary', onClick: () => handleTimeClick(timeSlot), active: timeSlot === selectedTime ? true : false }} key={timeSlot}>
							{timeSlot}
						</Btn>
					))}
				</Col>
			);
		}
	};

	const handleDateClick = (day) => {
		setSelectedDate(day);
		//setSelectedTime(null);
	};

	const handleTimeClick = (time) => {
		setSelectedTime(time);
	};
	const handlePreviousWeek = () => {
		const newStartDate = subDays(currentStartDate, 7);
		setCurrentStartDate(newStartDate);
	};

	const handleNextWeek = () => {
		const newStartDate = addDays(currentStartDate, 7);
		setCurrentStartDate(newStartDate);
	};

	return (
		<Col sm="12" className="mt-4">
			<H5 attrH5={{ className: 'mb-4 text-center text-capitalize' }}>{format(currentStartDate, 'LLLL yyyy', { locale: ru })}</H5>

			<Row style={{ justifyContent: 'space-around' }}>
				<a href="#" style={{ width: 'auto' }} onClick={handlePreviousWeek}>
					<ChevronLeft />
				</a>
				{renderDaysOfWeek()}
				<a href="#" style={{ width: 'auto' }} onClick={handleNextWeek}>
					<ChevronRight />
				</a>
			</Row>
			<div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
				<Row className="m-l-10 m-r-40" style={{ justifyContent: 'space-around', flexWrap: 'nowrap', width: '80%' }}>
					{renderCalendarDays()}
				</Row>
				{renderTimeSlots()}
				{selectedTime && showBtn ? (
					<Btn attrBtn={{ color: 'primary', className: 'mt-4' }}>
						Продолжить <ArrowRight style={{ margin: '0px 0px -4px 7px' }} size={'20px'} />
					</Btn>
				) : (
					''
				)}
			</div>
		</Col>
	);
}

export default SelectDateTime;
