import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Col, Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import EditMyProfile from "./EditmyProfile";
import MyProfileEdit from "./MyProfile";
import { useUsersContext } from "../../../../_helper/Users/UsersProvider";
import { useKeycloak } from "@react-keycloak/web";

const UsersEditContain = () => {
	const { keycloak } = useKeycloak();
	const { getUserById, userById } = useUsersContext();


	useEffect(() => {
		keycloak.authenticated && keycloak.tokenParsed && getUser();
	}, [keycloak.authenticated]);

	const getUser = useCallback(() => {
		getUserById(keycloak.tokenParsed.sub);
	}, [keycloak.tokenParsed.sub, getUserById]);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Редактировать профиль" parent="Пользователи" title="Редактировать профиль" />
			<Container fluid={true}>
				<div className="edit-profile">
					<Row>
						<Col xl="4">
							<MyProfileEdit profileData={userById} />
						</Col>
						<Col xl="8">
							<EditMyProfile profileData={userById} />
						</Col>
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};
export default UsersEditContain;
