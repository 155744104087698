import React, { useState, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { CardBody } from "reactstrap";
import uuid from "react-uuid";
import { Btn } from "../../../AbstractElements";
import CommonModal from "../../UiKits/Modals/common/modal";
import ClientForm from "./ClientForm";
import { useDemandsContext } from "../../../_helper/Demands/DemandsProvider";


const TableRequestFromClients = () => {
	const [modal, setModal] = useState(false);
	const [demandObject, setDemandObject] = useState("");
	const [edit, setEdit] = useState(null);
	const { getDemands, demands } = useDemandsContext();

	useEffect(() => {
		const formId = "";//"b77e0ad8-9e26-4c45-bb96-f299a26263b6";
		getDemands(formId);
	}, [])

	const confirm = () => {
		setModal(!modal);
	};

	const columns = [
		{
			name: "№",
			selector: (row) => (
				<div className="font-primary" style={{ cursor: "pointer" }} onClick={() => toggle(row)}>
					{row.id}
				</div>
			),
			sortable: true,
		},
		{
			name: "Тип",
			selector: (row) => row.type && `${row.type}`,
			sortable: true,
		},
		{
			name: "Дата",
			selector: (row) => `${formatDate(row.date)}`,
			sortable: true,
		},
		{
			name: "Заказчик",
			selector: (row) => <div style={{ fontSize: "14px" }}>{row.NameId}</div>,
			sortable: true,
		},
		{
			name: "Статус",
			selector: (row) => row.status && `${row.status}`,
			sortable: true,
		},
	];

	const toggle = async (ObjectD) => {
		setEdit(ObjectD ? true : false);
		setDemandObject(ObjectD);
		setModal(!modal);
	};

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}

	return (
		<>
			<Btn attrBtn={{ color: "primary", onClick: () => toggle(""), className: "mb-4" }}>
				<i className="fa fa-plus" style={{ fontSize: "16px", marginRight: "5px" }}></i> Добавить
			</Btn>
			<CommonModal isOpen={modal} title={edit ? "Просмотр заявки" : "Добавить новую заявку"} togglerClose={toggle} size="xl">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<ClientForm formId={demandObject.formHBId} onFormSubmit={confirm} demandObject={demandObject} edit={edit} />
				</CardBody>
			</CommonModal>
			<DataTable
				columns={columns}
				data={demands && demands.length > 0 ? demands : []}
				striped
				pagination
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
				noDataComponent={"В данной таблице ещё нет записей."}
			/>
		</>
	);
};

export default TableRequestFromClients;
