import React, { Fragment, useContext, useState, useEffect } from "react";
import { Col } from "reactstrap";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { getVisibleproducts } from "../../../../../Services/Ecommerce.service";
import ProductContext from "../../../../../_helper/Ecommerce/Product";
import FilterContext from "../../../../../_helper/Ecommerce/Filter";

const Sorting = ({ cardsPerPage, currentPage, setCurrentPage, indexOfFirstCard, indexOfLastCard, len }) => {
	const { productItem, fetchProducts } = useContext(ProductContext);

	const context = useContext(FilterContext);
	const products = getVisibleproducts(productItem, context.filter);

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(products.length / cardsPerPage); i++) {
		pageNumbers.push(i);
	}

	useEffect(() => {
        fetchProducts();
    }, [])

	const renderPageNumbers = pageNumbers.map((number) => (
		<PaginationItem key={number} active={currentPage === number ? true : false}>
			<PaginationLink href="#javascript" onClick={() => setCurrentPage(number)}>
				{number}
			</PaginationLink>
		</PaginationItem>
	));

	return (
		<Fragment>
			<Col md="6" className="d-flex" style={{ justifyContent: "end", alignItems: "center" }}>
				{products && (
					<span className="f-w-600 me-4">
						Результатов на странице: {indexOfFirstCard + 1}-{indexOfLastCard} из {len}
					</span>
				)}
				<Pagination aria-label="Page navigation example" className="pagination justify-content-end pagination-primary">
					<PaginationItem>
						<PaginationLink>
							<span aria-hidden="true">«</span>
						</PaginationLink>
					</PaginationItem>
					{renderPageNumbers}
					<PaginationItem>
						<PaginationLink>
							<span aria-hidden="true">»</span>
						</PaginationLink>
					</PaginationItem>
				</Pagination>
			</Col>
		</Fragment>
	);
};
export default Sorting;
