import React, { Fragment, useState, useCallback, useContext } from "react";
import { Card, Col, Container, Row } from "reactstrap";

import { Breadcrumbs } from "../../../AbstractElements";
import FileContent from "./FileContent";
import FileSideBar from "./FileSidebar";
import SingleImage from "./SingleImage";

const FileManagerContain = () => {
	const initilindex = {
		content: "https://img.freepik.com/free-vector/tropical-leaves-background-for-zoom_23-2148580778.jpg?size=626&ext=jpg&ga=GA1.1.2116175301.1714089600&semt=ais",
		isOpen: false,
		type: "jpg",
	};
	const [photoIndex, setPhotoIndex] = useState(initilindex);

	const callback = useCallback((photo) => {
		setPhotoIndex(photo);
	});

	return (
		<Fragment>
			<Breadcrumbs parent="Работа с файлами" title="Управление файлами" mainTitle="Управление файлами" />
			<Container fluid={true}>
				<Row>
					<FileSideBar />
					<Col xl="9" md="12" className="box-col-9">
						<div className="file-content">
							<Card>
								<FileContent photoIndex={photoIndex} setPhotoIndex={callback} />
							</Card>
						</div>
					</Col>
				</Row>
			</Container>
			<SingleImage photoIndex={photoIndex} setPhotoIndex={callback} />
		</Fragment>
	);
};
export default FileManagerContain;
