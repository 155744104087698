import React from "react";
import { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
//import Signin from '../Auth/Signin';
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import ViewPage from "../Components/Application/Sites/SiteBuilder/EditPagesList/ViewPage";
import ClientView from "../Components/Application/PackageOffers/ClientView/Index";
import ViewDiploma from "../Components/Application/Learning/Diploma/ViewDiploma";
import OrderSystem from "../Components/Landing/OrderSystem";
import EducationalPlatform from "../Components/Landing/EducationalPlatform";
import ProjectManagement from "../Components/Landing/ProjectManagement";
import SocialNetwork from "../Components/Landing/SocialNetwork";
import BlankIMapViewer from "../Components/Application/Widgets/InteractivePlanMap/InteractivePlanMapViewer/BlankViewer";
import UnauthorizedDemandForm from "../Components/Application/Request/UnauthorizedDemandForm";


// setup fake backend

const Routers = () => {
	//const login = useState(JSON.parse(localStorage.getItem('login')))[0];
	//const [authenticated, setAuthenticated] = useState(false);
	// const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === 'compact-wrapper dark-sidebar');
	// const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

	useEffect(() => {
		//console.log(localStorage);
		let abortController = new AbortController();
		//setAuthenticated(JSON.parse(localStorage.getItem('authenticated')));
		console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
		console.disableYellowBox = true;
		return () => {
			abortController.abort();
		};
	}, []);

	const checkSecretParameter = () => {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.has('secret');
	};

	return (
		<BrowserRouter basename={"/"}>
			<Suspense fallback={<Loader />}>
				<Routes>
					<Route path={"/"}>
						{
							<>
								{!checkSecretParameter() && (
									<>
										<Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/app/users/profile`} />} />
										<Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/app/users/profile`} />} />
									</>
								)}
							</>
						}
						<Route path={`/*`} element={<LayoutRoutes />} />
					</Route>

					<Route path={`${process.env.PUBLIC_URL}/widgets/blank-view-plan-map`} element={<BlankIMapViewer />} />
					<Route path={`${process.env.PUBLIC_URL}/app/request/unauthorized-new-demand`} element={<UnauthorizedDemandForm />} />


					<Route path={`${process.env.PUBLIC_URL}/app/package-offers/client-view`} element={<ClientView />} />
					<Route path={`${process.env.PUBLIC_URL}/app/viewing-diploma`} element={<ViewDiploma />} />

					<Route path={`${process.env.PUBLIC_URL}/order-system`} element={<OrderSystem />} />
					<Route path={`${process.env.PUBLIC_URL}/educational-platform`} element={<EducationalPlatform />} />
					<Route path={`${process.env.PUBLIC_URL}/project-management-system`} element={<ProjectManagement />} />
					<Route path={`${process.env.PUBLIC_URL}/social-network`} element={<SocialNetwork />} />

					{/* <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} /> */}

					{authRoutes.map(({ path, Component }, i) => (
						<Route path={path} element={Component} key={i} />
					))}
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};

export default Routers;
