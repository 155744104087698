import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableLessons from './TableLessons';
import { EducationApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web'
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const Lessons = () => {
	const [data, setData] = useState([]);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${EducationApi}get-course-lesson/`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				setData(concatResults(response));
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список занятий" parent="Обучение" title="Список занятий" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<TableLessons lessons={data} />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default Lessons;
