import React, {useEffect, useMemo, useState} from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import axios from "axios";
import uuid from "react-uuid";
import { BeatLoader } from "react-spinners";
import AsyncSelect from "react-select/async";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import Select from "react-select";
import { useKeycloak } from "@react-keycloak/web";
import { Label, Input, CardBody, Col, Media } from "reactstrap";

import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { Btn, H6, P } from "../../../../AbstractElements";
import { CounterpartiesApi } from "../../../../api";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";
import {Textarea} from "../../../../Constant";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {useCounterpartiesContext} from "../../../../_helper/Counterparties/CounterpartiesProvider";

const TableCounterparties = ({ data, setData, roles, companyId }) => {
	const { companyData } = useCompanyContext();

	const [counterpartyHeader, setCounterpartyHeader] = useState('Контрагент')
	const [counterpartyText, setCounterpartyText] = useState('контрагент')

	useEffect(() => {
		companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyHeader('Партнёр')
		companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyText('партнёр')
	}, [companyData]);
	const columns = [
		{
			name: "",
			selector: (row) => "",
			sortable: true,
			width: "2%",
		},
		{
			name: "Название",
			selector: (row) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<i className="fa fa-building-o f-28" style={{ color: "#898989" }}></i>
					<Tooltip title={`Просмотр информации о ${counterpartyText}е`} arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/counterparties/counterparty-info?counterpartyId=${row.id}`}>
							<div className="ms-3">
								<H6>{row.name}</H6>
							</div>
						</Link>
					</Tooltip>
				</div>
			),
			sortable: true,
		},
		{
			name: "Тип",
			selector: (row) => row?.type?.name,
			sortable: true,
		},
		{
			name: "Контакты",
			selector: (row) => (
				<>
					<H6>{row.contactPerson}</H6>
					<P>{row.phone}</P>
				</>
			),
			sortable: true,
		},
		{
			name: "Реквизиты",
			selector: (row) => (
				<>
				{row.inn && <H6>ИНН: {row.inn}</H6>}
				{row.upp && <P>КПП: {row.upp}</P>}
				</>
			),
			sortable: true,
		},
		{
			name: "Адрес",
			selector: (row) => `${row.address}`,
			sortable: true,
		},
		{
			name: "Баланс",
			selector: (row) => `${row.goods ? `${row.goods.length} товаров` : "0"} ₽`,
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteTask = (id) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				axios.delete(`${CounterpartiesApi}deleteCounterparty/${id}`, {
					headers: {
						companyId: companyId,
						Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
					}
				})
					.then(response => {
						setData(obj => obj.filter(role => role.id !== id));
						toast.success(`${counterpartyHeader} успешно удален!`, {
							position: toast.POSITION.TOP_RIGHT,
						});
					})
					.catch(error => console.error('Error deleting role:', error));
			}
		});
	};

	const [edit, setEdit] = useState(null);

	const [name, setName] = useState("");
	const [selectType, setSelectType] = useState(null);
	const [inn, setInn] = useState("");
	const [innOption, setInnOption] = useState(null);
	const [upp, setUpp] = useState("");
	const [contactPerson, setContactPerson] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [address, setAddress] = useState("");
	const [Id, setId] = useState("");
	const [comment, setComment] = useState("");
	const [contractConditions, setContractConditions] = useState("");
	const { keycloak } = useKeycloak();

	const {
		counterpartyRequiredFields,
		getCounterpartyRequiredFields,
		counterpartySettings,
		getCounterpartySettings,
		createCounterparty,
		updateCounterparty
	} = useCounterpartiesContext();

	useEffect(() => {
		keycloak && companyData && getCounterpartyRequiredFields();
		keycloak && companyData && getCounterpartySettings();
	}, [keycloak, companyData])

	const send = () => {
		if ((name === "" && counterpartyRequiredFields?.includes('name')) ||
			(selectType === "") ||
			(inn === "" && counterpartyRequiredFields?.includes('inn')) ||
			(upp === "" && counterpartyRequiredFields?.includes('upp')) ||
			(contactPerson === "" && counterpartyRequiredFields?.includes('contactPerson')) ||
			(phone === "" && counterpartyRequiredFields?.includes('phone')) ||
		    (email === "" && counterpartyRequiredFields?.includes('email')) ||
            (address === "" && counterpartyRequiredFields?.includes('address')) ||
		    (comment === "" && counterpartyRequiredFields?.includes('comment')))
		{
			toast.error("Заполнены не все обязательные поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
		else {
			if (Id) {
				updateCounterparty(Id, selectType.value, name, inn, upp, contactPerson, phone, email, address, comment, contractConditions);
			}
			else {
				createCounterparty(uuid(), selectType.value, name, inn, upp, contactPerson, phone, email, address, comment, contractConditions);
			}
		}
	};

	const [modal, setModal] = useState(false);

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.name);
			setId(ObjectId.id);
			setComment(ObjectId.comment);
			setSelectType({ label: ObjectId.type.name, value: ObjectId.type.id });
			setInn(ObjectId.inn);
			setInnOption({ label: ObjectId.inn, value: ObjectId.inn });
			setUpp(ObjectId.upp);
			setContactPerson(ObjectId.contactPerson);
			setPhone(ObjectId.phone);
			setEmail(ObjectId.email);
			setAddress(ObjectId.address);
			setEdit(true);
			console.log(ObjectId.type);
		} else {
			setName("");
			setId("");
			setComment("");
			setSelectType(null);
			setInn("");
			setInnOption(null);
			setUpp("");
			setContactPerson("");
			setPhone("");
			setEmail("");
			setAddress("");
			setEdit(false);
		}
		setModal(!modal);
	};

	console.log(selectType);

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const promiseOptionsInn = (inputValue, callback) => {
		const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
		const token = "6416ca728c4d25842e715e4dfcd4dfd859d1200c";
		setInnOption({ label: inputValue, value: inputValue });
		setInn(inputValue);

		if (inputValue.length === 10) {
			axios
				.post(url, { query: inputValue }, { headers: { "Content-Type": "application/json", Accept: "application/json", Authorization: "Token " + token } })
				.then((response) => {
					const selectOptions = response.data.suggestions.map((option) => ({
						label: option.value,
						value: option.data.inn,
						kpp: option.data.kpp,
						address: option.data.address.value,
					}));
					callback(selectOptions);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
	};

	const handleChangeInn = (option) => {
		if (option) {
			setInnOption({ label: option.value, value: option.label });
			setInn(option.value);
			setName(option.label);
			setUpp(option.kpp);
			setAddress(option.address);
		}
	};

	const colourStyles = {
		control: (styles) => ({ ...styles, border: "1px solid #dee2e6", boxShadow: "none" }),
	};

	const [selfEmployed, setSelfEmployed] = useState("none");

	const [loading, setLoading] = useState(false);
	const promiseSelfEmployed = () => {
		if (inn) {
			setLoading(true);
			const date = new Date();
			const dateStr = date.toISOString().substring(0, 10);
			const url = "https://statusnpd.nalog.ru/api/v1/tracker/taxpayer_status";
			const data = {
				inn: inn,
				requestDate: dateStr,
			};
			axios
				.post(url, data, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((response) => {
					setSelfEmployed(concatResults(response).status);
					setLoading(false);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
					toast.error("Несуществующий ИНН!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setLoading(false);
				});
		} else {
			toast.error("Введите ИНН!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	};

	const [errorMessageKpp, setErrorMessageKpp] = useState(" ");
	const handleChangeKPP = (e) => {
		const value = e.target.value;
		if (/^\d*$/.test(value)) {
			setUpp(value);
			if (value.length === 9) {
				setErrorMessageKpp("");
			} else {
				setErrorMessageKpp("Поле должно содержать 9 цифр");
			}
		}
	};

	const [errorMessageInn, setErrorMessageInn] = useState(" ");
	const handleChangeInnInput = (e) => {
		const value = e.target.value;
		if (/^\d*$/.test(value)) {
			setInn(value);
			if (value.length === 12) {
				setErrorMessageInn("");
			} else {
				setErrorMessageInn("Поле должно содержать 12 цифр");
			}
		}
	};

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				noDataComponent={"В данной таблице ещё нет записей."}
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={modal} title={edit ? "Редактировать" : "Добавить"} toggler={send} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" placeholder="Название" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-2">
						<Label className="col-sm-9 col-form-label">Тип</Label>
						<Col sm="11">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={selectType}
								styles={colourStyles}
								options={roles}
								onChange={(option) => setSelectType(option)}
							/>
							<div className="mt-2">
								<Link to={`${process.env.PUBLIC_URL}/counterparties/counterparties-roles`}>
									<div className="ms-3">
										Добавить тип
									</div>
								</Link>
							</div>
						</Col>
					</Col>
					{selectType && (
						<>
							<Col className="row col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Телефон</Label>
								<Col sm="11">
									{/*<InputMask
										className="form-control"
										mask="+7 (999) 999-99-99"
										maskChar="_"
										placeholder="+7 (___) ___-__-__"
										required={true}
										alwaysShowMask
										value={phone}
										type="tel"
										onChange={(e) => setPhone(e.target.value)}
									/>{" "})*/}
									<PhoneInput
										international
										defaultCountry={counterpartySettings?.defaultCountryCode ? counterpartySettings?.defaultCountryCode : null}
										value={phone}
										onChange={setPhone}
										numberInputProps={{className: "form-control form-control"}}
									/>
								</Col>
							</Col>
							<Col className="row col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Email</Label>
								<Col sm="11">
									<Input className="form-control" type="tel" placeholder="example@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
									{/*
									<AsyncSelect
										styles={colourStyles}
										loadOptions={promiseOptionsEmail}
										placeholder="name@example.ru"
										noOptionsMessage={() => "Нет результатов"}
										value={email}
										onChange={(option) => setEmail(option)}
										loadingMessage={() => "Загрузка.."}
									/>*/}
								</Col>
							</Col>
							<Col className="row col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Адрес</Label>
								<Col sm="11">
									<Input className="form-control" type="tel" placeholder="Адрес" value={address} onChange={(e) => setAddress(e.target.value)} />
								</Col>
							</Col>
							{
								(selectType.label === "Отель" || selectType.label === "Отели" || selectType.label === "DMC" || selectType.label === "Поставщик") &&
								<Col className="row col-sm-12 mb-2">
									<Label className="col-sm-9 col-form-label">Условия контракта/бронирования</Label>
									<Col sm="11">
										<Textarea
											className="form-control"
											placeholder="Условия контракта/бронирования"
											value={contractConditions}
											onChange={(e) => setContractConditions(e.target.value)}
										>
											{contractConditions}
										</Textarea>
									</Col>
								</Col>
							}
							<Col className="row col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Комментарий</Label>
								<Col sm="11">
									<Textarea
										className="form-control"
										placeholder="Комментарий"
										value={comment}
										onChange={(e) => setComment(e.target.value)}
									>
										{comment}
									</Textarea>
								</Col>
							</Col>
							{/*<Col className="row col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">ИНН</Label>
								<Col sm="11">
									{selectType.value === "Физ. лицо" ? (
										<>
											<Input className="form-control" placeholder="ИНН" value={inn} onChange={handleChangeInnInput} />
											{errorMessageInn && <div className="font-secondary">{errorMessageInn}</div>}
										</>
									) : (
										<AsyncSelect
											styles={colourStyles}
											loadOptions={promiseOptionsInn}
											placeholder="ИНН"
											cacheOptions
											noOptionsMessage={() => "Нет результатов"}
											value={innOption}
											loadingMessage={() => "Загрузка.."}
											className="p-0"
											onChange={handleChangeInn}
											isClearable
										/>
									)}
								</Col>
							</Col>
							{selectType.value === "Физ. лицо" && (
								<Col className="row col-sm-12 mb-4">
									<Col sm="11" className="d-flex" style={{ alignItems: "baseline", justifyContent: "space-between" }}>
										<Btn attrBtn={{ color: "success", className: "mt-2", onClick: promiseSelfEmployed, disabled: loading }}>
											{loading ? <BeatLoader size={8} color="#ffffff" /> : "Проверить статус самозанятого"}
										</Btn>
										{selfEmployed !== "none" &&
											(selfEmployed ? (
												<span className="font-success">
													<i className="fa fa-check"></i> ИНН принадлежит самозанятому
												</span>
											) : (
												<span className="font-warning">
													<i className="fa fa-warning"></i> ИНН не принадлежит самозанятому
												</span>
											))}
									</Col>
								</Col>
							)}
							<Col className="row col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">КПП</Label>
								<Col sm="11">
									<Input className="form-control" type="tel" placeholder="КПП" value={upp} onChange={handleChangeKPP} />
									{errorMessageKpp && <div className="font-secondary">{errorMessageKpp}</div>}
								</Col>
							</Col>
							*/}
							{ counterpartySettings && !counterpartySettings?.allowHaveManyContacts &&
							<Col className="row col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Контакное лицо</Label>
								<Col sm="11">
									<Input className="form-control" type="tel" placeholder="Контактное лицо" value={contactPerson} onChange={(e) => setContactPerson(e.target.value)} />
									{/*<AsyncSelect
										styles={colourStyles}
										loadOptions={promiseOptionsContactName}
										placeholder="Контактное лицо"
										noOptionsMessage={() => "Нет результатов"}
										loadingMessage={() => "Загрузка.."}
										value={contactPerson}
										onChange={(e) => setContactPerson(e)}
									/>*/}
								</Col>
							</Col>
							}
						</>
					)}
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableCounterparties;
