import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';

import FilterComponent from '../../Goods/PurchaseHistory/FilterComponent';

const TableTransactions = ({ filteredProducts }) => {
	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}
	const columns = [
		{
			name: 'Дата',
			selector: (row) => `${formatDate(row.date)}`,
			sortable: true,
			width: '10%',
		},
		{
			name: <div>№ док-та</div>,
			selector: (row) => `${row.docNumber}`,
			sortable: true,
		},
		{
			name: <div>Получатель/Плательщик</div>,
			selector: (row) => `${row.recipient}`,
			sortable: true,
			width: '12%',
		},
		{
			name: 'Назначение',
			selector: (row) => <div style={{ whiteSpace: 'initial' }}>{row.purpose}</div>,
			sortable: true,
			width: '12%',
		},
		{
			name: 'Касса',
			selector: (row) => ``,
			sortable: true,
		},
		{
			name: <div>Комментарий</div>,
			selector: (row) => <div style={{ whiteSpace: 'initial' }}>{row.comment}</div>,
			sortable: true,
		},
		{
			name: 'Автор',
			selector: (row) => <div style={{ whiteSpace: 'initial' }}>{row.author}</div>,
			sortable: true,
		},
		{
			name: 'Сумма',
			selector: (row) => `${row.amount} ₽`,
			sortable: true,
		},
		{
			name: <div>Остаток в кассе</div>,
			selector: (row) => `${row.cashBalance} ₽`,
			sortable: true,
		},
		{
			name: <div>Улуга/Товар</div>,
			selector: (row) => <div style={{ whiteSpace: 'initial' }}>{row.service}</div>,
			sortable: true,
		},
		{
			name: 'Визит',
			selector: (row) => ``,
			sortable: true,
		},
	];

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</>
	);
};

export default TableTransactions;
