import React, { useMemo, useState, useEffect, useContext } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import FilterComponent from "./FilterComponent";
import { ProductsApi, CmsApi } from "../../../../api";
import CMSTemplatesContext from "../../../../_helper/CMSTemplates";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { CMSTemplatesProvider } from "../../../../_helper/CMSTemplates/CMSTemplatesProvider";


const TableApplicationForm = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { allForms, fetchAppForms } = useContext(CMSTemplatesContext);

	useEffect(() => {
		if (allForms && allForms.length) {
			fetchAppForms();
		}
	}, []);

	const columns = [
		{
			name: "Название",
			selector: (row) => <Link to={`${process.env.PUBLIC_URL}/app/request/create-application-form?formId=${row.id}`}>{row.name}</Link>,
			sortable: true,
		},
		{
			name: "Описание",
			selector: (row) => `${row.description}`,
			sortable: true,
		},
		{
			name: "Активирован?",
			selector: (row) => <div className="">{row.isActive ? <i className="fa fa-check font-success f-right"></i> : <i className="fa fa-times font-danger"></i>}</div>,
			sortable: false,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/app/request/create-application-form?formId=${row.id}`}>
							<Edit size={"18px"} />
						</Link>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteForm(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteForm = (id) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${CmsApi}deleteFormApp/${id}`, {
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
					})
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
						fetchAppForms();
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении:", error);
					});
			}
		});
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = useMemo(() => {
		return allForms && allForms.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}, [allForms, filterText]);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
		</>
	);
};

export default TableApplicationForm;
