import React, {Fragment} from "react";
import {DropZone} from "@measured/puck";

export const Flex = {
    label: "Блоки",
    fields: {
        items: {
            label: "Список блоков",
            type: "array",
            arrayFields: {
                minItemWidth: {
                    label: "Минимальная ширина блока",
                    type: "number",
                    min: 0,
                },
                maxWidthBool: {
                    label: "Блок на всю ширину",
                    type: "custom",
                    default: false,
                    render: ({name, onChange}) => {
                        return <>
                            <label htmlFor={name} className="form-label">Блок на всю ширину</label>
                            <input type="checkbox" className="mx-2" name={name} onChange={(e) => onChange(e.target.value)}/>
                        </>
                    }
                },
                radius: {
                    label: "Закругление границ блока",
                    type: "custom",
                    min: 0,
                    default: 0,
                    render: ({name, onChange }) => {
                        return <>
                            <label htmlFor={name} className="form-label">Закругление границ блока</label>
                            <input name={name} className="mx-2" type="range" min={0} max={70} defaultValue={0}
                                   onChange={(e) => onChange(e.target.value)}/>
                        </>
                    }
                },
                color: {
                    label: "Цвет фона блока",
                    type: "custom",
                    default: "#fff",
                    render: ({ name, onChange }) => {
                        return <>
                            <label htmlFor="formColor" className="form-label">Выбор цвета</label>
                            <input name={name} className="form-control" type="color" id="formColor" onChange={(e) => onChange(e.target.value)}/>
                        </>
                    }
                }
            },
            getItemSummary: (_, id) => `Блок ${id + 1}`,
        },
        minItemWidth: {
            label: "Минимальная ширина блока по умолчанию",
            type: "number",
            min: 0,
        },
    },
    defaultProps: {
        items: [{}, {}],
        minItemWidth: 356,
    },
    render: ({ items, minItemWidth }) => {
        return (
            <Fragment>
                <div style={{
                    display: "flex",
                    minHeight: 0,
                    minWidth: 0,
                    flexWrap: "wrap",
                }}>
                    {items.map((item, idx) => (
                        <div
                            key={idx}
                            className={(item.maxWidthBool && "w-100") + " p-4"}
                            style={{ minWidth: item.minItemWidth || minItemWidth, backgroundColor: item.color, borderRadius: item.radius+"px" }}
                        >
                            <DropZone zone={`item-${idx}`} />
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    },
};