import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Trash2, Edit, Check } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import uuid from 'react-uuid';
import SweetAlert from 'sweetalert2';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Label, Input, CardBody, Col, Media } from 'reactstrap';

import FilterComponent from '../../Catalog/FilterComponent';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { Link } from 'react-router-dom';

import { DeliveryApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const TableResources = () => {
	const [data, setData] = useState([]);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		try {
			const response = await axios.get(`${DeliveryApi}getDeliveryServices`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			setData(concatResults(response).deliveryServices);
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};
	const [edit, setEdit] = useState(null);
	const [modal, setModal] = useState(false);
	const [name, setName] = useState('');
	const [Id, setId] = useState('');
	const [comment, setComment] = useState('');
	const [site, setSite] = useState('');
	const [selectedServer, setSelectedServer] = useState(null);
	const [status, setStatus] = useState(false);
	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);


	const columns = [
		{
			name: '',
			selector: (row) => '',
			sortable: true,
			width: '1%',
		},
		{
			name: 'Наименование',
			selector: (row) => (
				<Tooltip title="Просмотр стоимости доставки" arrow placement="top">
					<Link to={`${process.env.PUBLIC_URL}/app/delivery/cost-of-delivery-by-city?deliveryServiceId=${row.id}`}>{row.name}</Link>
				</Tooltip>
			),
			sortable: true,
		},
		{
			name: 'Описание',
			selector: (row) => `${row.description}`,
			sortable: true,
		},
		{
			name: 'Сайт',
			selector: (row) => `${row.website}`,
			sortable: true,
		},
		{
			name: 'Сервер',
			selector: (row) => `${row.server}`,
			sortable: true,
		},
		{
			name: 'Статус',
			selector: (row) => <div>{row.status ? <Check className="font-success ms-2" /> : ''}</div>,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteDelivery(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteDelivery = (id) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${DeliveryApi}deleteDeliveryService`, {
						data: {
							Id: id
						}, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
					})
					.then((response) => {
						SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
						fetchData();
					})
					.catch((error) => {
						console.error('Произошла ошибка при удалении:', error);
					});
			}

		});
	};

	const createDeliveryService = () => {
		if (name === '' || comment === '' || site === '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {

			const newDelivery = {
				id: uuid(),
				name: name,
				description: comment,
				website: site,
				server: selectedServer.value,
				status: status
			};
			axios
				.post(`${DeliveryApi}createDeliveryService`, newDelivery, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Служба доставки добавлена!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});

		}
	}

	const updateDeliveryService = () => {

		if (name === '' || comment === '' || site === '') {
			toast.error('Заполните все поля!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {

			const delivery = {
				id: Id,
				name: name,
				description: comment,
				website: site,
				server: selectedServer.value,
				status: status
			};

			axios
				.put(`${DeliveryApi}updateDeliveryService`, delivery, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
					//console.log(concatResults(response));
					toast.success('Служба доставки обновлена!', {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(!modal);
					fetchData();
				})
				.catch((error) => {
					console.error(error);
				});

		}
	}

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setName(ObjectId.name);
			setId(ObjectId.id);
			setComment(ObjectId.description);
			setSite(ObjectId.website);
			setSelectedServer({ label: ObjectId.server, value: ObjectId.server });
			setStatus(ObjectId.status);
			setEdit(true);
		} else {
			setName('');
			setId('');
			setComment('');
			setSite('');
			setSelectedServer(null);
			setStatus(false);
			setEdit(false);
		}
		setModal(!modal);
	};

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const filterComponents = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);


	return (
		<>
			<DataTable columns={columns} data={filterComponents} searchable defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={edit ? updateDeliveryService : createDeliveryService} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Наименование</Label>
						<Col sm="12">
							<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="12">
							<Input className="form-control" type="tel" value={comment} onChange={(e) => setComment(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Сайт</Label>
						<Col sm="12">
							<Input className="form-control" type="tel" value={site} onChange={(e) => setSite(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-4">
						<Label className="col-sm-9 col-form-label">Сервер</Label>
						<Col sm="12">
							<Select placeholder="Выберите сервер" value={selectedServer} options={[{ label: 'ERP', value: 'ERP' }]} className="js-example-basic-single col-sm-12" onChange={(selected) => setSelectedServer(selected)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3 mt-4">
						<Media>
							<Media body className={'text-start icon-state '} style={{ flexGrow: '0' }}>
								<Label className="switch">
									<Input type="checkbox" checked={status} onChange={(e) => setStatus(e.target.checked)} />
									<span className="switch-state"></span>
								</Label>
							</Media>
							<Label className="col-form-label ms-2 ">Активный</Label>
						</Media>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableResources;
