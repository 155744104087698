import React, { useMemo, useState, useEffect } from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/ru';
import { Label, Input } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Card, CardHeader, Collapse } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Plus, Clock, Minus, XCircle } from 'react-feather';

import Commen from '../../Forms/FormWidget/FormTouchspin/Commen';
import CommonModal from '../../UiKits/Modals/common/modal';
import { H5, H6 } from '../../../AbstractElements';
import { Btn } from '../../../AbstractElements';
import { TwinAuthApi } from '../../../api';
import uuid from 'react-uuid';
import { useCalendarContext } from '../../../_helper/Calendar/CalendarProvider';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const MeetingCalendar = (calendarType) => {
	const {
		calendarData,
		calendarEventsData,
		createMeetUpEvent,
		getCalendarMeetUpEventsData,
		meetUpEventsData
	} = useCalendarContext();

	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	const [resources, setResources] = useState(null);
	const [clients, setClients] = useState([]);
	const [myEvents, setEvents] = useState([]);

	const [allEvents, setAllEvents] = useState([]);
	const [userByIdArray, setUserByIdArray] = useState([]);
	const [clientsObject, setClientsObject] = useState();
	const [allClients, setAllClients] = useState([]);


	const [note, setNote] = useState('');
	const [StartDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [groupEvent, setGroupEvent] = useState(false);
	const [edit, setEdit] = useState(false);
	
	const [pillWithIconTab, setpillWithIconTab] = useState('1');

	const [selectResource, setSelectResource] = useState({ label: null, value: null });
	const [selectClient, setSelectClient] = useState({ label: null, value: null });
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');

	const [modal, setModal] = useState(false);

	const [clientsEvent, setClientsEvent] = useState([]);

	const [allDay, setAllDay] = useState(false);

	const [eventId, setEventId] = useState();

	const [val, setVal] = useState(1);
	const [isValidEmail, setIsValidEmail] = useState(true);

	useEffect(() => {
		console.log(calendarType.calendarType === 'meetings')
		if (calendarType.calendarType === 'meetings') {
			console.log(1);
			calendarEventsData && setEvents(calendarEventsData.map((option) => ({
				id: option.id,
				title: option.title,
				start: new Date(option.startDateTime),
				end: new Date(option.endDateTime),
			})));
		}
		else {
			setEvents([]);
		}
	}, [calendarEventsData]);

	useEffect(() => {
		axios
			.get(`/api/resources.json`)
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.name,
					value: option.id,
				}));

				setResources(selectOptions);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setAllClients(JSON.parse(response.data));
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.id,
				}));
				setClients(selectOptions);
			})
			.catch((error) => { 
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const { defaultDate, messages } = useMemo(
		() => ({
			defaultDate: new Date(),
			messages: {
				week: 'Неделя',
				work_week: 'Рабочая неделя',
				day: 'День',
				month: 'Месяц',
				previous: 'Предыдуший',
				next: 'Следующий',
				today: 'Сегодня',
				agenda: 'План',
				date: 'Дата',
				time: 'Время',
				event: 'Событие',
				allDay: 'Весь день',
				yesterday: 'Вчера',
				tomorrow: 'Завтра',
				noEventsInRange: 'В этом диапазоне нет событий.',

				showMore: (total) => `+ ещё ${total}`,
			},
		}),
		[]
	);

	const getUsersByIdArray = async (ids) => {
		axios
			.get(`${TwinAuthApi}get-users-by-idArray`, { ids: ids }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setUserByIdArray(JSON.parse(response.data).map((item) => ({
					name: `${item.firstName} ${item.lastName}`,
					userId: item.id
				})));
			})
			.catch((error) => { 
				console.error("Ошибка получения данных:", error);
			});
	}

	const toggle = () => setModal(!modal);

	const handleAddEvents = async () => {
		console.log(clientsEvent.map((option) => ({
			eventId: uuid(),
			userId: option.id,
			phoneNumber: option.email,
			email: option.phone,
		})));
		createMeetUpEvent(calendarData.find((items) => items.calendarType === "meetings").id, note, StartDate, endDate, groupEvent, allDay,
		clientsEvent.map((option) => ({
			eventId: uuid(),
			userId: option.id,
			phoneNumber: option.phone,
			email: option.email,
		})), selectResource.value)
	};

	const handleDelEvents = () => {};

	

	const handleSelectSlot = ({ start = new Date(), end = new Date() }) => {
		//add
		setStartDate(start);
		setEndDate(end);
		setModal(true);
		setEdit(false);
		setAllDay(false);
		setSelectResource({ label: null, value: null });
		setVal(1);
		setNote('');
		setClientsEvent([]);
		setGroupEvent(false);
	};

	

	const handleSelectEvent = (event) => {
		//edit
		const foundObject = calendarEventsData.find((item) => item.id === event.id);
		console.log(foundObject);

		if (foundObject) {
			setEdit(true);
			setEventId(foundObject.id);
			setStartDate(new Date(foundObject.startDateTime));
			setEndDate(new Date(foundObject.endDateTime));
			setNote(foundObject.title);
			setAllDay(foundObject.allDay);
			setSelectResource(foundObject.resource);
			getCalendarMeetUpEventsData(foundObject.id);
			//setSelectResource(foundObject.resources);
		}
		setModal(true);
	};

	useEffect(() => {
		meetUpEventsData && setClientsEvent(meetUpEventsData.map((item) => ({id: item.id, name: getUserName(item.userId), phone: item.phoneNumber, email: item.email})));
	}, [meetUpEventsData])

	const getUserName = (userId) => {
		let client = allClients.find((client) => client.id === userId);
		if (!client) {
			return "";
		}
		//${allClients && allClients.find((client) => client.id === item.userId)?.map((opt) => `${opt.firstName} ${opt.lastName}`)}
		return `${client.firstName} ${client.lastName}`
	};

	function formatHeaderDate(date) {
		const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();
		const hours = date.getHours();
		const minutes = date.getMinutes();

		const formattedDate = `${day} ${month} ${year}г., ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

		return formattedDate;
	}

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getDate();
		const month = dateObject.getMonth() + 1;
		const year = dateObject.getFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	

	const handleInputChange = (e) => {
		const inputValue = e.target.value;

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isValid = emailRegex.test(inputValue);

		setEmail(inputValue);
		setIsValidEmail(isValid);
	};

	const [isOpen, setIsOpen] = useState(1);
	const toggleClientCard = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

	useEffect(() => {
		const foundObject = allClients.find((item) => item.id === selectClient.value);
		if (foundObject) {
			setEmail(foundObject.email);
			setPhone(foundObject.attributes?.phone);
		}
	}, [selectClient]);

	const addClient = () => {
		const newClient = { id: selectClient.value, name: selectClient.label, phone: phone, email: email };

		if (clientsEvent.length < val) {
			if (!clientsEvent.some((item) => JSON.stringify(item) === JSON.stringify(newClient))) {
				setClientsEvent(clientsEvent.concat(newClient));
			} else {
				toast.error('Клиент уже добавлен!', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}
		} else {
			toast.error('Все места заняты!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		}

		console.log(clientsEvent);
	};

	useEffect(() => {
		Array.isArray(clientsEvent) && setClientsObject(clientsEvent.map((item) => (
			<Card>
				<CardHeader className="ps-0 f-14" onClick={() => toggleClientCard(item.id)} style={{ cursor: 'pointer' }}>
					<Row>
						<Col md="4">
							<H6 attrH6={{ className: 'mb-0' }}>
								{isOpen === item.id ? <i className="fa fa-angle-down me-2"></i> : <i className="fa fa-angle-right me-2"></i>}
								{item.name}
							</H6>
						</Col>
						<Col md="2">
							<Clock size={'16px'} style={{ margin: '0 3px -3px 0', color: '#ababab' }} />
							Ожидание
						</Col>
						<Col md="2" className="">
							<XCircle size={'16px'} style={{ margin: '0 3px -3px 0' }} className="font-danger" />
							Не оплачено
						</Col>
						<Col md="3">{item.phone}</Col>
					</Row>
				</CardHeader>
				<Collapse isOpen={isOpen === item.id}></Collapse>
			</Card>
		)));
	}, [clientsEvent])

	const handleCheckboxChange = (e) => {
		const isChecked = e.target.checked;

		const newStartDate = new Date(StartDate);
		const newEndDate = new Date(endDate);

		if (isChecked) {
			newStartDate.setHours(0, 0, 0, 0);

			newEndDate.setDate(newEndDate.getDate() + 1);
			newEndDate.setHours(0, 0, 0, 0);
		}

		setStartDate(newStartDate);
		setEndDate(newEndDate);

		setAllDay(isChecked);
	};

	return (
		<>
			<Btn attrBtn={{ color: 'primary', onClick: handleSelectSlot, className: 'mb-4' }}>
				<Plus style={{ margin: '0 2px -7px 0px' }} /> Добавить встречу
			</Btn>
			<CommonModal isOpen={modal} title={edit ? `Редактировать встречу` : `Добавить встречу`} toggler={handleAddEvents} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="xl">
				<CardBody style={{ padding: '10px', paddingLeft: '15px', paddingTop: '0' }}>
					<Row>
						<Col sm="3" xs="12">
							<Nav className="nav flex-column nav-pills">
								<NavItem>
									<NavLink className={pillWithIconTab === '1' ? 'active' : ''} onClick={() => setpillWithIconTab('1')} style={{ cursor: 'pointer' }}>
										<i className="fa fa-edit"></i>
										Текущее событие
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={pillWithIconTab === '2' ? 'active' : ''} onClick={() => setpillWithIconTab('2')} style={{ cursor: 'pointer' }}>
										<i className="fa fa-users"></i>
										Участники{' '}
										{groupEvent && (
											<div style={{ display: 'inline-block' }}>
												({clientsEvent.length}/{val})
											</div>
										)}
									</NavLink>
								</NavItem>
							</Nav>
						</Col>
						<Col sm="9" xs="12">
							<TabContent activeTab={pillWithIconTab}>
								<TabPane className="fade show" tabId="1">
									<H5 attrH5={{ className: 'mb-4' }}>
										{formatHeaderDate(StartDate)} <span className="font-primary text-capitalize">{note}</span>
									</H5>
									<Row className="mb-3">
										<Col md="8">
											<Label className="col-sm-9 col-form-label">Название</Label>
											<Col sm="12">
												<Input className="form-control" placeholder="" value={note} onChange={(e) => setNote(e.target.value)} />
											</Col>
										</Col>
										<Col md="4">
											<Label className="col-sm-9 col-form-label">Ресурсы</Label>
											<Col className="row col-sm-12 ">
												<Select placeholder="Выберите ресурс" options={resources} className="js-example-basic-single col-sm-12" value={selectResource} onChange={(selectedOption) => setSelectResource(selectedOption)} />
											</Col>
										</Col>
									</Row>
									<Row className="mb-3" style={{ alignItems: 'flex-end' }}>
										<Col className=" col-sm-6" md="3">
											<Label className="col-sm-10 col-form-label ">Дата</Label>
											<Col xl="10" sm="9">
												<div className="input-group">
													<DatePicker className="form-control" locale={ru} selected={StartDate} value={formatDate(StartDate)} onChange={(selectedOption) => setStartDate(selectedOption)} />
												</div>
											</Col>
										</Col>
										<Col className=" col-sm-6" md="2">
											<Label className="col-sm-10 col-form-label ">Время</Label>
											<Col xl="10" sm="9">
												<DatePicker timeCaption="Время" className="form-control" selected={StartDate} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="HH:mm" locale={ru} onChange={(selectedOption) => setStartDate(selectedOption)} />
											</Col>
										</Col>
										<Col md="1" className="p-0">
											<Minus />
										</Col>
										<Col className=" col-sm-6 p-0" md="2">
											<Col xl="10" sm="9">
												<DatePicker timeCaption="Время" className="form-control" selected={endDate} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="HH:mm" locale={ru} onChange={(selectedOption) => setEndDate(selectedOption)} />
											</Col>
										</Col>
										<Col md="3" className="checkbox checkbox-primary ms-4">
											<Input id={`checkbox-primary-1-allDay`} type="checkbox" checked={allDay} onChange={(e) => handleCheckboxChange(e)} />
											<Label for={`checkbox-primary-1-allDay`}>Весь день</Label>
										</Col>
									</Row>
								</TabPane>
								<TabPane tabId="2">
									<H5 attrH5={{ className: 'mb-4' }}>
										{formatHeaderDate(StartDate)} <span className="font-primary text-capitalize">{note}</span>
									</H5>
									<Row className="mb-3">
										<Col md="12">
											<Label className="col-sm-9 col-form-label">Имя</Label>
											<Col className="row col-sm-12 ">
												<Select placeholder="Выберите клиента" options={clients} className="js-example-basic-single col-sm-12" onChange={(selectedOption) => setSelectClient(selectedOption)} />
											</Col>
										</Col>
									</Row>
									<Row className="mb-3">
										<Col md="4">
											<Label className="col-sm-9 col-form-label">Телефон</Label>
											<Col sm="12">
												<InputMask className="form-control" mask="+7 (999) 999-99-99" maskChar="_" placeholder="+7 (___) ___-__-__" alwaysShowMask value={phone} onChange={(e) => setPhone(e.target.value)} />
											</Col>
										</Col>
										<Col md="5">
											<Label className="col-sm-9 col-form-label">Email</Label>
											<Col sm="12">
												<Input className="form-control" placeholder="Введите Email" value={email} onChange={handleInputChange} />
												{!isValidEmail && <div className="d-block font-danger">Пожалуйста, введите корректный email.</div>}
											</Col>
										</Col>
										<Col md="2">
											<Col sm="12">
												<Label className="col-sm-9 col-form-label" style={{ height: '28px' }}></Label>
												<Btn attrBtn={{ color: 'primary', onClick: () => addClient() }}>Записать</Btn>
											</Col>
										</Col>
									</Row>

									<Row className="mt-2 mb-4" style={{ alignItems: 'flex-end' }}>
										<Col md="3" className="checkbox checkbox-primary ">
											<Input id={`checkbox-primary-1`} type="checkbox" checked={groupEvent} onChange={(e) => setGroupEvent(e.target.checked)} />
											<Label for={`checkbox-primary-1`}>Групповое событие</Label>
										</Col>
										{groupEvent ? (
											<Col md="3">
												<Label className="col-sm-9 col-form-label">Вместимость</Label>
												<Commen groupText={true} val={val} setVal={setVal} />
											</Col>
										) : (
											<Col md="3" style={{ height: '74px' }}></Col>
										)}
									</Row>
									<Card>
										<Row className="mt-4">
											<H6>Участники</H6>
										</Row>
										<Row className="b-r-8 badge-light font-dark p-15 mt-2">
											<Col md="4">Имя</Col>
											<Col md="2">Статус</Col>
											<Col md="2">Статус оплаты</Col>
											<Col md="2">Телефон</Col>
											<Col md="2">Абонемент</Col>
										</Row>
										{clientsObject}
									</Card>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</CardBody>
			</CommonModal>

			<Calendar onSelectEvent={handleSelectEvent} onSelectSlot={handleSelectSlot} messages={messages} localizer={localizer} scrollToTime={new Date(1970, 1, 1, 6)} views={allViews} events={myEvents ?? []} eventOverlap dragRevertDuration={500} dragScroll droppable={true} showMultiDayTimes step={60} startAccessor="start" endAccessor="end" selectable />
		</>
	);
};
export default MeetingCalendar;
