import React, { useState } from "react";
import { Eye, Search } from "react-feather";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import "primereact/resources/themes/lara-light-cyan/theme.css";

import CallerNumberFilter from "../Telephony/CallStatistics/CallerNumberFilter";
import StatusFilter from "./StatusFilter";
import { Btn } from "../../../AbstractElements";
import CommonPopover from "../../UiKits/Popover/common/CommonPopover";

const TableTaskReports = ({ products }) => {
	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	const [filters, setFilters] = useState(null);

	const formationPeriod = (product) => {
		return `с ${formatDate(product.formationPeriod.startDate)} по 
		${formatDate(product.formationPeriod.endDate)}`;
	};

	const documentTheme = (product) => {
		return (
			<>
				<span className="c-pointer" id={"Popover-" + product.id}>
					{product.documentTheme}
				</span>
				<CommonPopover
					key={product.id}
					item={{
						placement: "right",
						id: product.id,
						trigger: "hover",
						Popoverbody: product.comment,
					}}
				/>
			</>
		);
	};

	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters["global"] = { value: value, matchMode: "contains" };

		setFilters(_filters);
		setGlobalFilterValue(value);
	};
	const [globalFilterValue, setGlobalFilterValue] = useState("");

	const renderHeader = () => {
		return (
			<Row>
				<Col md="4">
					<Link target="_blank" to={`${process.env.PUBLIC_URL}/app/account-formation`}>
						<Btn attrBtn={{ color: "primary" }}>Добавить счет</Btn>
					</Link>
				</Col>
				<Col md="8">
					<InputGroup>
						<Input className="form-control" type="text" value={globalFilterValue} onChange={onGlobalFilterChange} />
						<InputGroupText className="bg-primary">
							<Search size={"20px"} />
						</InputGroupText>
					</InputGroup>
				</Col>
			</Row>
		);
	};
	const header = renderHeader();

	const handleCallStartFilterChange = (value, name) => {
		let _filters = { ...filters };
		_filters[name] = { value: value, matchMode: "contains" };
		setFilters(_filters);
	};

	return (
		<>
			<DataTable
				removableSort
				filters={filters}
				onFilter={(e) => setFilters(e.filters)}
				filterDisplay="row"
				header={header}
				globalFilterFields={["number", "callStart", "callerPhone", "receiverPhone", "duration", "conversation"]}
				size="large"
				value={products}
				tableStyle={{ minWidth: "50rem" }}
				scrollable
				paginator
				rows={5}
				rowsPerPageOptions={[5, 10, 25, 50]}
			>
				<Column
					sortable
					field="accountNumber"
					header="№ счёта"
					filter
					filterElement={
						<CallerNumberFilter name="accountNumber" filterValue={filters && filters["accountNumber"] ? filters["accountNumber"].value : ""} onFilterChange={handleCallStartFilterChange} />
					}
					style={{ minWidth: "8rem" }}
				/>
				<Column
					sortable
					field="documentTheme"
					header="Тема документа"
					filter
					filterElement={
						<CallerNumberFilter name="documentTheme" filterValue={filters && filters["documentTheme"] ? filters["documentTheme"].value : ""} onFilterChange={handleCallStartFilterChange} />
					}
					style={{ minWidth: "14rem" }}
					body={documentTheme}
				/>
				<Column
					sortable
					field="formationPeriod"
					header="Период формирования"
					filter
					filterElement={
						<CallerNumberFilter
							name="formationPeriod"
							filterValue={filters && filters["formationPeriod"] ? filters["formationPeriod"].value : ""}
							onFilterChange={handleCallStartFilterChange}
						/>
					}
					body={formationPeriod}
					style={{ minWidth: "17rem" }}
				/>
				<Column
					sortable
					field="performer"
					header="Исполнитель"
					filter
					filterElement={<CallerNumberFilter name="performer" filterValue={filters && filters["performer"] ? filters["performer"].value : ""} onFilterChange={handleCallStartFilterChange} />}
					style={{ minWidth: "13rem" }}
					body={(rowData) => rowData.performer.name}
				/>
				<Column
					field="invoiceDate"
					header="Дата выставления счёта"
					style={{ minWidth: "11rem" }}
					body={(rowData) => `${formatDate(rowData.invoiceDate)}`}
					filter
					filterElement={
						<CallerNumberFilter name="invoiceDate" filterValue={filters && filters["invoiceDate"] ? filters["invoiceDate"].value : ""} onFilterChange={handleCallStartFilterChange} />
					}
				/>
				<Column
					sortable
					field="totalPaymentAmount"
					header="Итоговая сумма оплаты"
					filter
					filterElement={
						<CallerNumberFilter
							name="totalPaymentAmount"
							filterValue={filters && filters["totalPaymentAmount"] ? filters["totalPaymentAmount"].value : ""}
							onFilterChange={handleCallStartFilterChange}
						/>
					}
					style={{ minWidth: "10rem" }}
					body={(rowData) => `${rowData.totalPaymentAmount} ₽`}
				/>
				<Column
					sortable
					field="status"
					header="Статус"
					style={{ minWidth: "12rem" }}
					filter
					filterElement={<StatusFilter name="status" filterValue={filters && filters["status"] ? filters["status"].value : null} onFilterChange={handleCallStartFilterChange} />}
				/>

				<Column
					sortable
					field="conversation"
					header="Дата оплаты"
					filter
					filterElement={
						<CallerNumberFilter name="conversation" filterValue={filters && filters["conversation"] ? filters["conversation"].value : ""} onFilterChange={handleCallStartFilterChange} />
					}
					style={{ minWidth: "10rem" }}
				/>
				<Column sortable field="conversation" header="Документы" style={{ minWidth: "10rem" }} />
				<Column
					header=""
					style={{ minWidth: "4rem" }}
					body={(rowData) => (
						<Tooltip title={`Просмотр деталей счёта`} arrow placement="top">
							<Link to={`${process.env.PUBLIC_URL}/app/task-reports/view?billId=${rowData.id}`}>
								<Eye />
							</Link>
						</Tooltip>
					)}
				/>
			</DataTable>
		</>
	);
};

export default TableTaskReports;
