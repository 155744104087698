import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from "react";
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from "fabric";
import {PenTool} from "react-feather";
import {TiTick} from "react-icons/ti";
import {Btn} from "../../../../../AbstractElements";
import {BsEye, BsFileImage} from "react-icons/bs";
import {Item, Menu, Separator, Submenu, useContextMenu} from "react-contexify";
import 'react-contexify/ReactContexify.css';
import {House, Redo, Save, Undo} from "@mui/icons-material";
import {CardBody, Col, Form, FormGroup, Label, Row} from "reactstrap";
import Select from "react-select";
import CommonModal from "../../../../UiKits/Modals/common/modal";
import {useInteractivePlanMapsContext} from "../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import {useKeycloak} from "@react-keycloak/web";
import {useLocation, useNavigate} from "react-router-dom";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const Editor = () => {
    const { keycloak } = useKeycloak();
    const { getInteractivePlanMapsObjects, planMapsObjects, planMap, getInteractivePlanMap, updateInteractivePlanMap } = useInteractivePlanMapsContext();
    const { editor, onReady } = useFabricJSEditor();
    const { companyData } = useCompanyContext();
    const navigate = useNavigate();
    const editorMemo = useMemo(() => editor?.canvas, [editor?.canvas]);
    let is_creating;
    const [isCreating, setIsCreating] = useState(false);
    var shape;

    const [shapeState, setShapeState] = useState({});

    let mouseCoords;
    let isDragging;
    let lastPosX;
    let lastPosY;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mapId = queryParams.get('mapId');

    //undo redo
    const [state, setState] = useState();
    const [undoArr, setUndoArr] = useState([]);
    const [redoArr, setRedoArr] = useState([]);

    const [undoArrShape, setUndoArrShape] = useState([]);
    const [redoArrShape, setRedoArrShape] = useState([]);

    const [planMapsObjectsOptions, setPlanMapsObjectsOptions] = useState([]);
    const [selectedObjectId, setSelectedObjectId] = useState(null);

    const MENU_ID = 'EditorMenu';

    const fileInputRef= useRef();
    const fileInputObjRef= useRef();

    const [objectSelected, setObjectSelected] = useState(false);

    const [modal, setModal] = useState(false);

    const editorRef = useRef();

    const [mapHaveBackground, setMapHaveBackground] = useState(false);

    useEffect(() => {
        console.log(planMapsObjects);
        planMapsObjects && setPlanMapsObjectsOptions(planMapsObjects.map((item) => ({label: item.name, value: item.id})));
    }, [planMapsObjects]);

    useEffect(() => {
        console.log(companyData);
        mapId && getInteractivePlanMapsObjects(mapId);
        mapId && getInteractivePlanMap(mapId);
    }, [companyData, mapId]);

    useEffect(() => {
        if (planMap) {
            console.log(planMap.mapJson !== null);
            if (planMap.mapJson !== null) {
                setMapHaveBackground(true);
            }
            console.log(mapHaveBackground);
            editorMemo?.loadFromJSON(planMap.mapJson);
            let height = 1;
            let width = editorRef?.current?.offsetWidth;

            JSON.parse(planMap.mapJson)?.objects.forEach((item) => {
                if (item.type === "image") {
                    height += item.height * item.scaleY;
                    width = item.width * item.scaleX;
                }
            })

            let objects = editorMemo?.getObjects();

            objects?.forEach((object) => {
                if (object.type === "image") {
                    console.log(object);
                    object.selectable = false;
                }
            })

            editor?.canvas?.setDimensions({height: height, width: width});

            editorMemo?.renderAll();

            //scaleEditorCanvas();

        }
    }, [planMap]);

    const scaleEditorCanvas = () => {
        console.log(editorMemo);
        if (editorMemo?.width !== editorRef?.current?.offsetWidth) {
            let scale = editorRef?.current?.offsetWidth / editorMemo?.width;
            let objects = editorMemo?.getObjects();
            console.log(scale);
            objects?.forEach((object) => {
                object.scaleX = object.scaleX * scale;
                object.scaleY = object.scaleY * scale;
                object.left = object.left * scale;
                object.top = object.top * scale;
                object.setCoords();
            });

            console.log(editorMemo?.getObjects());
            //editor?.canvas?.setWidth(editor?.canvas?.getWidth() * scale);
            //editor?.canvas?.setHeight(editor?.canvas?.getHeight() * scale);
            editorMemo?.setDimensions({height: editorMemo?.height * scale, width: editorMemo?.width * scale});
            editorMemo?.renderAll();
            editorMemo?.calcOffset();
        }
    }

    const { show } = useContextMenu({
        id: MENU_ID,
    });

    const [url, setUrl] = useState("");
    const [urlObject, setUrlObject] = useState("");

    const readUrl = (event) => {
        if (event.target.files.length === 0) return;
        var mimeType = event.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            setUrl({img: reader.result, file: event.target.files[0]});
        };
    };

    const readUrlObject = (event) => {
        if (event.target.files.length === 0) return;
        var mimeType = event.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            setUrlObject({img: reader.result, file: event.target.files[0]});
        };
    };

    const createPolygon = () => {
        removeEvent("mouse:down");
        removeEvent("mouse:up");
        removeEvent("mouse:down");
        removeEvent("mouse:up");
        removeEvent("mouse:move");
        removeEvent("mouse:over");

        setIsCreating(true);

        editor?.canvas?.on("mouse:over", onMouseHoverEdit);
        createEventPolygonShape({});
        editor?.canvas?.on("mouse:down", function (evt) {});

    };

    const createEventPolygonShape = (shapeInit) => {
        shape = shapeInit;

        console.log(shape);
        editor?.canvas?.on("mouse:up", function (evt) {
            let pointer = editor?.canvas?.getPointer(evt.e);
            mouseCoords = {
                x: pointer.x,
                y: pointer.y
            };

            if (!is_creating) {
                is_creating = true;
                shape = new fabric.Polygon(
                    [
                        {
                            x: mouseCoords.x,
                            y: mouseCoords.y
                        }
                    ],
                    {
                        fill: 'green',
                        opacity: 0.4,
                        borderColor: 'grey',
                        borderOpacityWhenMoving: 1,
                        selectable: true,
                        stroke: "black",
                        hasControls: true,
                        strokeWidth: 4,
                    }
                );

                editor?.canvas?.add(shape);
                editor?.canvas?.setActiveObject(shape);
                saveState();
            } else {
                shape.points.push({
                    x: mouseCoords.x,
                    y: mouseCoords.y
                });
                editorMemo.remove(shape);
                var obj = shape.toObject();
                obj.hasControls = true;
                delete obj.top;
                delete obj.left;
                shape = new fabric.Polygon(shape.points, obj);
                editorMemo.add(shape);
                editor?.canvas?.setActiveObject(shape);
                saveState();
            }
        });
    }

    const removeEvent = (eventName) => {
        let listener = editorMemo.__eventListeners[eventName];
        listener.forEach((event) => {
            editorMemo.off(eventName, event);
        });
    };

    const saveState = () => {
        if (editorMemo) {
            setRedoArr([]);
            if (state) {
                let undoCopy = undoArr;
                undoCopy.push(editorMemo.toJSON(['id', 'name']))
                setUndoArr(undoCopy);
            }
            setState(editorMemo.toJSON(['id', 'name']));

            if (is_creating) {
                let actObj = editorMemo.getActiveObject();
                setShapeState(actObj);
                setRedoArrShape([]);
                let undoCopy = undoArrShape;
                undoCopy.push(actObj);
                setUndoArrShape(undoCopy);
                console.log(undoArrShape);
            }
        }

    }

    const confirmPolygon = () => {
        removeEvent("mouse:down");
        removeEvent("mouse:up");
        removeEvent("mouse:over");

        let object = editorMemo.getActiveObject();
        object?.set({strokeWidth: 0, hasControls: false});

        setIsCreating(false);

        editor?.canvas?.on("mouse:over", onMouseHover);

        editor?.canvas?.on({
            "mouse:down": onMouseDownPan,
            "mouse:up": onMouseUpPan,
            "mouse:move": onMouseMovePan,
        });
        setUndoArrShape([]);
        setRedoArrShape([]);
        onMouseUpPan();
        saveState();
    };

    const deleteSelected = () => {
        editor?.deleteSelected();
        saveState();
    }

    const updateBackground = () => {
        let bg = new fabric.Image();
        bg.setSrc(url.img, function () {
            let scale = 1;
            if (bg.width > bg.height) {
                scale = editor?.canvas.width/bg.width
            }
            else {
                scale = editor?.canvas.height/bg.height
            }
            bg.set({
                top: 0,
                left: 0,
                //scaleX: editor?.canvas.width/bg.width,
                name: "background",
                scaleY: scale,
                scaleX: scale
            });
            editor?.canvas?.setDimensions({height: bg.height * scale, width: bg.width * scale});
            editor?.canvas?.renderAll();
            editor?.canvas?.sendToBack(bg);
            url.img && setMapHaveBackground(true);
            console.log(editor?.canvas);
        })

        bg.selectable = false;
        bg.draggable = false;
        bg.pointer = false;

        removeBackground().then(() => {
            let a = editor?.canvas?.add(bg);

            saveState();
        });
    }

    const addObjectOnMap = () => {
        let img = new fabric.Image();
        img.setSrc(urlObject.img, function () {
            let scale = 1;
            if (img.width > img.height) {
                scale = editor?.canvas.width/img.width
            }
            else {
                scale = editor?.canvas.height/img.height
            }

            img.set({
                top: editor?.canvas.height,
                left: 0,
                //scaleX: editor?.canvas.width/bg.width,
                scaleY: scale,
                scaleX: scale
            });
            editor?.canvas?.setDimensions({height: editor?.canvas.height + img.height* scale});
            editor?.canvas?.renderAll();
        })

        img.selectable = false;
        img.draggable = false;
        img.pointer = false;

        editor?.canvas?.add(img);
        saveState();



        /*removeBackground().then(() => {
            let a = editor?.canvas?.add(img);

            saveState();
        });*/
    }

    const removeBackground = async () => {
        let objects = editorMemo?.getObjects();

        let obj = objects?.find(object => object.name === "background")

        obj && editorMemo?.remove(obj);

        return obj;
    }

    const keepPositionInBounds = () => {
        if (editor) {
            let zoom = editorMemo.getZoom();
            let xMin = ((2 - zoom) * editorMemo.getWidth()) / 2;
            let xMax = (zoom * editorMemo.getWidth()) / 2;
            let yMin = ((2 - zoom) * editorMemo.getHeight()) / 2;
            let yMax = (zoom * editorMemo.getHeight()) / 2;

            let point = new fabric.Point(
                editorMemo.getWidth() / 2,
                editorMemo.getHeight() / 2
            );
            let center = fabric.util.transformPoint(
                point,
                editorMemo.viewportTransform
            );

            let clampedCenterX = clamp(center.x, xMin, xMax);
            let clampedCenterY = clamp(center.y, yMin, yMax);

            let diffX = clampedCenterX - center.x;
            let diffY = clampedCenterY - center.y;

            if (diffX !== 0 || diffY !== 0) {
                editorMemo.relativePan(new fabric.Point(diffX, diffY));
            }
        }
    };

    const updateZoom = useCallback((opt) => {
        let delta = opt.e.deltaY;
        let zoom = editorMemo.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 5) zoom = 5;
        if (zoom < 1) zoom = 1;
        editorMemo.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
        keepPositionInBounds();
    }, [editor]);

    function clamp(value, min, max) {
        return Math.max(min, Math.min(value, max));
    }

    useEffect(() => {
        if (editorMemo) {
            editorMemo.backgroundColor = "white";
            fabric.Group.prototype.hasControls = false;
            fabric.Object.prototype.hasControls = false;
            editor.canvas.selection = false;
            editor.canvas.selectionColor = "#7366ff"
            //scaleEditorCanvas();

            //editor.canvas.setDimensions({height: 1});

        }

        if (editor?.canvas) {
            editor?.canvas?.on({
                "mouse:down": onMouseDownPan,
                "mouse:wheel": updateZoom,
                "mouse:up": onMouseUpPan,
                "mouse:move": onMouseMovePan,
                "mouse:over": onMouseHover,
                "selection:created": objectHasSelected,
                "selection:updated": objectHasSelected,
                "selection:cleared": objectHasCleared,
            });

            fabric.Canvas.prototype.toJSON = (function(toJSON) {
                return function(propertiesToInclude) {
                    return fabric.util.object.extend(
                        toJSON.call(this, propertiesToInclude), {
                            id: this.id,
                            name: this.name
                        }
                    );
                };
            })(fabric.Canvas.prototype.toJSON);
        }
    }, [editorMemo])

    useEffect(() => {
        updateBackground();
    }, [url])

    useEffect(() => {
        addObjectOnMap();
    }, [urlObject])

    useEffect(() => {
        if (!state) {
            saveState();
        }
    }, [onReady])

    const objectHasSelected = (obj) =>{
        removeEvent("mouse:down");

        let object = editorMemo.getActiveObject();
        if (object?.type === "image") {
            editorMemo.discardActiveObject();
            return
        }
        setObjectSelected(true);
    }

    function objectHasCleared(obj){
        editor?.canvas?.on({
            "mouse:down": onMouseDownPan
        });
        setObjectSelected(false);
    }

    function handleContextMenu(event){
        show({
            event,
            props: {
                key: 'value'
            }
        })
    }

    const handleToJSON = () => {
        const jsonObj = editorMemo.toJSON(['id', 'name']);
        const output = JSON.stringify(jsonObj, null, "\t");
        updateInteractivePlanMap(planMap.id, planMap.name, planMap.creatorId, planMap.lastChange, planMap.companyId, planMap.activated, output)

        editor.canvas.loadFromJSON(output);
    };

    function getClientPosition(e) {
        const positionSource = e.touches ? e.touches[0] : e;
        const {
            clientX,
            clientY
        } = positionSource;
        return {
            clientX,
            clientY
        };
    }

    const onMouseHover = useCallback((obj) => {
        if (obj?.target?.type === "polygon") {
            obj.target.set('hoverCursor', 'pointer');
        } else if (obj?.target?.type === "image") {
            obj?.target?.set('hoverCursor', 'move');
        }
        editorMemo.renderAll();
    }, [editor])

    const onMouseHoverEdit = useCallback((obj) => {
        obj?.target?.set('hoverCursor', 'default')
        editorMemo.renderAll();
    }, [editor])

    const onMouseDownPan = useCallback( (opt) => {
        const {
            e
        } = opt;
        isDragging = true;
        const {
            clientX,
            clientY
        } = getClientPosition(e);
        lastPosX = clientX;
        lastPosY = clientY;
        editor.canvas.discardActiveObject();
    }, [editor]);

    const onMouseMovePan = useCallback((opt) => {
        if (!isDragging) {
            return;
        }
        const {
            e
        } = opt;
        const T = editor.canvas.viewportTransform;
        const {
            clientX,
            clientY
        } = getClientPosition(e);
        T[4] += clientX - lastPosX;
        T[5] += clientY - lastPosY;
        editor.canvas.requestRenderAll();
        lastPosX = clientX;
        lastPosY = clientY;
        keepPositionInBounds();
    }, [editor]);

    const onMouseUpPan = useCallback((opt) => {
        editor.canvas.setViewportTransform(editor.canvas.viewportTransform);
        isDragging = false;
    }, [editor]);

    const undoChanges = () => {
        let redoCopy = redoArr;
        redoCopy.push(editorMemo.toJSON(['id', 'name']))
        setRedoArr(redoCopy);

        let undoArrCopy = undoArr;
        let stateJson;
        setState(undoArrCopy[undoArrCopy.length-1]);
        stateJson = undoArrCopy[undoArrCopy.length-1];
        undoArrCopy.pop();
        setUndoArr(undoArrCopy);

        console.log(is_creating);
        console.log(isCreating);

        if (isCreating) {
            let redoShapeCopy = redoArrShape;
            redoShapeCopy.push(shapeState);
            setRedoArrShape(redoShapeCopy);

            let undoArrShapeCopy = undoArrShape;
            editorMemo?.remove(shapeState);
            //shape = undoArrShapeCopy[undoArrShapeCopy.length[-1]];
            console.log(undoArrShapeCopy);
            let shapeCopy = undoArrShapeCopy[undoArrShapeCopy.length-1];
            console.log(shapeCopy);
            setShapeState(shapeCopy);
            undoArrShapeCopy.pop();
            setUndoArrShape(undoArrShapeCopy);
            console.log(shape);

            removeEvent("mouse:up");
            console.log(shapeState);
            createEventPolygonShape(shapeState);
        }

        editor?.canvas?.clear();
        editor?.canvas?.loadFromJSON(stateJson, () => {
            editor?.canvas?.renderAll();
        });
    };

    const redoChanges = () => {
        let undoCopy = undoArr;
        undoCopy.push(editorMemo.toJSON(['id', 'name']))
        setUndoArr(undoCopy);
        let redoArrCopy = redoArr;
        let stateJson;
        setState(redoArrCopy[redoArrCopy.length-1]);
        stateJson=redoArrCopy[redoArrCopy.length-1];
        redoArrCopy.pop();
        setRedoArr(redoArrCopy);

        if (isCreating) {
            let undoShapeCopy = undoArrShape;
            undoShapeCopy.push(shape);
            setUndoArrShape(undoShapeCopy);

            let redoArrShapeCopy = redoArrShape;
            let shapeCopy = redoArrShapeCopy[redoArrShapeCopy.length-1];
            setShapeState(shapeCopy);
            redoArrShapeCopy.pop();
            setRedoArrShape(redoArrShapeCopy);

            console.log(shape);
            removeEvent("mouse:up");
            console.log(shapeState);
            createEventPolygonShape(shapeState);
        }

        editor?.canvas?.clear();
        editor?.canvas?.loadFromJSON(stateJson, () => {
            editor?.canvas?.renderAll();
        });
    };

    const isObjectSelected = () => {
        return !objectSelected;
    };

    const addObjectIdToObject = () => {
        let obj = editorMemo.getActiveObject();
        obj.id = selectedObjectId;
        editor.canvas.renderAll();
        console.log(obj);
        toggle();
    }

    const toggle = () => {
        setSelectedObjectId(null);

        setModal(!modal);
    };

    return (
        <Fragment>
            <div className={"d-flex"}>
                <Btn attrBtn={{color: "primary", className: `p-2 m-1 ${mapHaveBackground ? 'd-flex' : 'd-none'}`, disabled: isCreating, onClick: createPolygon}}>
                    <PenTool style={{display: "block", width: 24, height: 24}}></PenTool>
                    <b className={"d-none d-md-block"}>Создать область</b>
                </Btn>
                <Btn
                    attrBtn={{className: `btn-outline-primary p-2 m-1 ${mapHaveBackground ? 'd-flex' : 'd-none'}`, disabled: !isCreating, color: 'transperant', onClick: confirmPolygon}}>
                    <TiTick style={{display: "block", width: 24, height: 24}}></TiTick>
                    <b className={"d-none d-md-block"}>Сохранить созданную область</b>
                </Btn>
                <Btn attrBtn={{color: "primary", className: "p-2 m-1 d-flex", disabled: isCreating, onClick: () => fileInputRef.current.click()}}>
                    <BsFileImage style={{display: "block", width: 24, height: 24}}>
                    </BsFileImage>
                    <b className={"d-none d-md-block"}>Загрузить основную карту</b>
                </Btn>
                <input
                    style={{width: "30px", left: "70px", bottom: "30px"}}
                    className="upload"
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => readUrl(e)}
                    multiple={false}
                    accept="image/*"
                    hidden
                />
                <Btn attrBtn={{color: "primary", className: `p-2 m-1 ${mapHaveBackground ? 'd-flex' : 'd-none'}`, disabled: isCreating, onClick: () => handleToJSON()}}>
                    <Save style={{display: "block", width: 24, height: 24}}>
                    </Save>
                    <b className={"d-none d-md-block"}>Сохранить карту</b>
                </Btn>
                <button color="primary" disabled={undoArr.length === 0} className={`p-2 m-1 btn btn-primary ${mapHaveBackground ? 'd-flex' : 'd-none'}`} onClick={undoChanges}>
                    <Undo style={{display: "block", width: 24, height: 24}}>
                    </Undo>
                </button>
                <button color="primary" disabled={redoArr.length === 0} className={`p-2 m-1 btn btn-primary ${mapHaveBackground ? 'd-flex' : 'd-none'}`} onClick={redoChanges}>
                    <Redo style={{display: "block", width: 24, height: 24}}>
                    </Redo>
                </button>
                <Btn attrBtn={{ color: "primary", className: `p-2 ms-auto m-1 ${mapHaveBackground ? 'd-flex' : 'd-none'}`, disabled: isCreating, onClick: () => window.open(`/widgets/plan-map-widgets`, "_blank") }}>
                    <b>Виджеты</b>
                </Btn>
                <Btn attrBtn={{ color: "primary", className: `p-2 m-1 ${mapHaveBackground ? 'd-flex' : 'd-none'}`, disabled: isCreating, onClick: () => window.open(`/widgets/view-plan-map?mapId=w${mapId}`, "_blank") }}>
                    <BsEye style={{ display: "block", width: 24, height: 24 }} />
                    <b>Просмотр карты</b>
                </Btn>
                <Btn attrBtn={{ color: "primary", className: `p-2 m-1 ${mapHaveBackground ? 'd-flex' : 'd-none'}`, disabled: isCreating, onClick: () => navigate(`/widgets/interactive-plan-maps-objects?objectId=${mapId}`) }}>
                    <House style={{ display: "block", width: 24, height: 24 }} />
                    <b>Список объектов</b>
                </Btn>
            </div>

            <div ref={editorRef} onClick={handleContextMenu} onContextMenu={handleContextMenu}>
                <FabricJSCanvas className="sample-canvas" onReady={onReady}/>
            </div>
            <Btn attrBtn={{color: "primary", className: `p-2 m-1 ${mapHaveBackground ? 'd-flex' : 'd-none'}`, disabled: isCreating, onClick: () => fileInputObjRef.current.click()}}>
                <BsFileImage style={{display: "block", width: 24, height: 24}}>
                </BsFileImage>
                <b className={"d-none d-md-block"}>Загрузить дополнительный объект</b>
            </Btn>
            <input
                style={{width: "30px", left: "70px", bottom: "30px"}}
                className="upload"
                type="file"
                ref={fileInputObjRef}
                onChange={(e) => readUrlObject(e)}
                multiple={false}
                accept="image/*"
                hidden
            />
            <Menu hidden={isObjectSelected() || isCreating} animation="slide" data-pr-showevent="onClick" id={MENU_ID}>
                <Item disabled={isObjectSelected()} onClick={toggle}>Привязать объект</Item>
                <Item disabled={isObjectSelected()} onClick={deleteSelected}>Удалить область</Item>
            </Menu>

            <CommonModal isOpen={modal} title={'Привязка объекта'} toggler={addObjectIdToObject} togglerClose={toggle}
                         closeText="Отмена" saveText="Сохранить">
                <CardBody style={{padding: '10px', paddingLeft: '15px'}}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{flexDirection: 'column'}}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Объект
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'}
                                            options={planMapsObjectsOptions}
                                            onChange={(e) => setSelectedObjectId(e?.value)}
                                            className="js-example-basic-single col-sm-12"/>
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
        </Fragment>
    );
};

export default Editor;
