import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {TabContent, TabPane} from "reactstrap";
import ClientCardContent from "./ClientCardContent";
import ClientDocuments from "./ClientDocuments";
import ClientDocument from "./ClientDocument";

const ClientEmails = ({ clientId }) => {
    const [clientDocumentId, setClientDocumentId] = useState();
    const [primarycolorTab, setprimarycolorTab] = useState('1');

    useEffect(() => {
        if (clientDocumentId && setClientDocumentId !== '') {
            setprimarycolorTab('2');
        } else {
            setprimarycolorTab('1');
        }
    }, [clientDocumentId]);

    const changeClientDocumentId = (id) => {
        if (id) {
            setClientDocumentId(id);
            setprimarycolorTab('2');
        }
        else {
            setClientDocumentId('');
            setprimarycolorTab('1');
        }
    }

    return (
        <Fragment>
            <TabContent activeTab={primarycolorTab}>
                <TabPane className="fade show" tabId="1">
                    <ClientDocuments clientId={clientId} setClientDocumentId={changeClientDocumentId} />
                </TabPane>
                <TabPane tabId="2">
                    <ClientDocument clientDocumentId={clientDocumentId} setClientDocumentId={changeClientDocumentId} />
                </TabPane>
            </TabContent>
        </Fragment>
    );
};

export default ClientEmails;