import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container, Row, Col, Label, CardFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import ru from 'date-fns/locale/ru';
import axios from 'axios';
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import TableCertificates from './TableCertificates';

const Certificates = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);

	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());

	useEffect(() => {
		axios
			.get('/api/certificates.json')
			.then((response) => {
				setFilteredProducts(response.data);

				const dateEnd = queryParams.get('dateEnd');
				if (dateEnd) {
					setPeriodEnd(new Date(dateEnd));
				} else {
					setPeriodEnd(new Date());
				}

				const dateStart = queryParams.get('dateStart');
				if (dateStart) {
					setPeriodStart(new Date(dateStart));
				} else {
					setPeriodStart(new Date());
				}
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const handleDateStartChange = (selectedOption) => {
		const categoryId = selectedOption;
		setPeriodStart(selectedOption);
		queryParams.delete('dateStart');
		queryParams.append(`dateStart`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};
	const handleDateEndChange = (selectedOption) => {
		const categoryId = selectedOption;
		setPeriodEnd(selectedOption);
		queryParams.delete('dateEnd');
		queryParams.append(`dateEnd`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Сертификаты" parent="Лояльность" title="Сертификаты" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Row className="m-l-5">
									<Col xl="3" sm="9">
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											<Label className="m-r-10 f-16">С</Label>
											<div className="input-group">
												<DatePicker locale={ru} className="form-control digits" value={formatDate(periodStart)} selected={periodStart} onChange={handleDateStartChange} />
											</div>
										</div>
									</Col>
									<Col xl="3" sm="9">
										<div style={{ display: 'flex', alignItems: 'flex-end' }}>
											<Label className="m-r-10 f-16">По</Label>
											<div className="input-group">
												<DatePicker locale={ru} className="form-control digits" value={formatDate(periodEnd)} selected={periodEnd} onChange={handleDateEndChange} />
											</div>
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter className="p-t-10 p-b-10">
								<div className="modal-footer">
									<Btn attrBtn={{ color: 'primary' }}>Показать</Btn>
								</div>
							</CardFooter>
						</Card>
						<Card>
							<CardBody>
								<TableCertificates filteredProducts={filteredProducts} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default Certificates;
