import React from "react";
import { useState } from "react";
import { Controlled } from "react-codemirror2";
import { ThemeSelector } from "./ThemeSelector";

import "codemirror/lib/codemirror.css";

import "codemirror/mode/xml/xml";
import "codemirror/mode/css/css";
import "codemirror/mode/javascript/javascript";

import "codemirror/addon/edit/closetag";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/matchtags";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/theme/darcula.css";

export const CodeEditor = ({ mode, value, setValue }) => {
	const [theme, setTheme] = useState("darcula");

	const changeCode = (editor, data, value) => {
		setValue(value);
	};

	const onDrop = (editor, e) => {
		e.preventDefault();
	};

	return (
		<div className="code-editor">
			<ThemeSelector setTheme={setTheme} />
			<Controlled
				value={value}
				onBeforeChange={changeCode}
				onDrop={onDrop}
				options={{
					mode,
					theme: "darcula",
					lint: true,
					lineNumbers: false,
					lineWrapping: true,
					spellcheck: true,
					autoCloseTags: true,
					autoCloseBrackets: true,
					matchTags: true,
					matchBrackets: true,
				}}
			/>
		</div>
	);
};
