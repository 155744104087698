import React, { Fragment, useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Container, Row } from "reactstrap";
import { Card, CardBody, CardHeader, Collapse, NavItem, Nav } from "reactstrap";
import { Link } from "react-router-dom";
import { CheckCircle, Plus, Server, Settings } from "react-feather";
import { useKeycloak } from "@react-keycloak/web";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import CreateTag from "./CreateTag";
import { Breadcrumbs, P } from "../../../AbstractElements";
import NavClass from "./NavClass";
import { TaskApi } from "../../../api";
import { H6, Btn } from "../../../AbstractElements";
import { ButtonGroup } from "reactstrap";
import TabClass from "./TabClass";
import { Layout, List } from "react-feather";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../concatResults";

const Task = () => {
	const [activeTab, setActiveTab] = useState("1");

	const [loading, setLoading] = useState(false);

	const [viewType, setViewType] = useState("list");

	const handleViewChange = (view) => {
		setViewType(view);
	};
	const {
		handleSubmit,
		formState: { errors },
	} = useForm();

	const [isOpen, setIsOpen] = useState(null);

	const toggleClientCard = (item) => {
		if (isOpen === item) {
			setIsOpen(null);
		} else {
			setIsOpen(item);
		}
	};

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	let currentUserId = `${keycloak.tokenParsed.sub}`;

	const [statuses, setStatuses] = useState([]);

	const fetchData = async () => {
		try {
			const response = await axios.get(`${TaskApi}get-user-statuses`, {
				params: { UserId: currentUserId },
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			setStatuses(concatResults(response).statuses);
		} catch (error) {
			console.error("Ошибка получения данных:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const deleteTask = (statusId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот статус!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${TaskApi}deleteStatus/${statusId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						//console.log(concatResults(response));
						SweetAlert.fire("Удалено!", "Статус был удален", "success");
						fetchData();
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении задачи:", error);
					});
			}
		});
	};

	const [tagModal, setTagModal] = useState(false);
	const tagToggle = () => setTagModal(!tagModal);

	const [editTagModal, setEditTagModal] = useState(false);
	const [color, setColor] = useState("#7366ff");
	const [name, setName] = useState("");
	const [statusId, setStatusId] = useState(null);

	const editTagToggle = (item) => {
		setEditTagModal(!editTagModal);
		setColor(item.color);
		setName(item.name);
		setStatusId(item.id);
	};

	const tagCallback = useCallback((modal) => {
		setTagModal(modal);
	});

	const sendStatus = () => {
		setLoading(true);

		const updStatus = {
			id: statusId,
			name: name,
			userId: currentUserId,
			color: color,
		};

		axios
			.put(`${TaskApi}updateStatus`, updStatus, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setLoading(false);
				setEditTagModal(false);
				toast.success("Статус изменен!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setColor("#7366ff");
				setName("");
				setStatuses((prevStatuses) => [...prevStatuses, updStatus]);
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
				toast.error("Ошибка при редактировании", {
					position: toast.POSITION.TOP_RIGHT,
				});
			});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список задач" parent="Задачи" title="Список задач" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<div className="d-flex">
						<Col xl="3">
							<Card>
								<CardHeader
									className="p-relative d-flex"
									onClick={() => toggleClientCard(1)}
									style={{ cursor: "pointer", padding: "7px 28px ", borderRadius: "5px", justifyContent: "space-between" }}
								>
									<P attrPara={{ className: "mb-0 txt-dark" }}>Статусы</P>
									{isOpen ? <i className="fa fa-angle-up ms-2 mt-1"></i> : <i className="fa fa-angle-down ms-2 mt-1"></i>}
								</CardHeader>
								<Collapse isOpen={isOpen === 1}>
									<div
										className="left-filter p-absolute shadow shadow-showcase"
										style={{ zIndex: "3", width: "100%", backgroundColor: "white", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}
									>
										<CardBody className="filter-cards-view animate-chk" style={{ padding: "16px" }}>
											<div className="email-app-sidebar left-bookmark">
												<Nav className="main-menu" role="tablist">
													{statuses && statuses.length > 0 ? (
														statuses.map((item) => (
															<NavItem key={item.id}>
																<a className="p-r-10">
																	<span className="title"> {item.name}</span>
																	<Tooltip title="Редактировать" arrow placement="top">
																		<Settings className="font-primary me-2" style={{ cursor: "pointer" }} size={"18px"} onClick={() => editTagToggle(item)} />
																	</Tooltip>
																	<Tooltip title="Удалить статус" arrow placement="top">
																		<Trash2 className="font-primary" style={{ cursor: "pointer" }} size={"18px"} onClick={() => deleteTask(item.id)} />
																	</Tooltip>
																</a>
															</NavItem>
														))
													) : (
														<p>Нет доступных статусов.</p>
													)}
													<NavItem>
														<button className="badge-light-primary d-block w-100 btn-mail mb-0 mt-2" onClick={tagToggle}>
															<Plus className="me-2" />
															Создать статус
														</button>
													</NavItem>
												</Nav>
											</div>
										</CardBody>
									</div>
								</Collapse>
							</Card>
						</Col>
						<CreateTag tagCallback={tagCallback} tagModal={tagModal} setStatuses={setStatuses} />
						<div className="mb-4 ms-4" style={{ flexGrow: "1" }}>
							<button className="badge-light-primary f-left" style={{ border: "none", padding: "7px 20px", borderRadius: "5px" }}>
								<Link to={`${process.env.PUBLIC_URL}/app/task/create-task`} className="p-0">
									<CheckCircle className="me-2" size={"18px"} style={{ margin: "0 3px -3px 0" }} />
									Добавить задачу
								</Link>
							</button>
						</div>
						<ButtonGroup className="mb-4 task-wrap d-block">
							<Btn attrBtn={{ color: "light", active: viewType === "list", onClick: () => handleViewChange("list") }}>
								<Tooltip title="Список" arrow placement="top">
									<List size={"18px"} style={{ margin: "0 3px -3px 0" }} />
								</Tooltip>
							</Btn>

							<Btn attrBtn={{ color: "light", active: viewType === "kanban", onClick: () => handleViewChange("kanban") }}>
								<Tooltip title="Канбан" arrow placement="top">
									<Layout size={"18px"} style={{ margin: "0 3px -3px 0" }} />
								</Tooltip>
							</Btn>

							<Btn attrBtn={{ color: "light", active: viewType === "vertical-kanban", onClick: () => handleViewChange("vertical-kanban") }}>
								<Tooltip title="Вертикальный канбан" arrow placement="top">
									<Server size={"18px"} style={{ margin: "0 3px -3px 0" }} />
								</Tooltip>
							</Btn>
						</ButtonGroup>
					</div>
					<Modal className="fade show modal-bookmark" isOpen={editTagModal} toggle={editTagToggle} size="lg">
						<Form className="form-bookmark needs-validation" onSubmit={handleSubmit(sendStatus)}>
							<ModalHeader className="modal-title" toggle={editTagToggle}>
								Редактировать статус
							</ModalHeader>
							<ModalBody>
								<div className="form-row">
									<FormGroup className="col-md-12">
										<Label>Название статуса</Label>
										<input className="form-control" name="name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
									</FormGroup>
									<FormGroup className="col-md-12 mb-0">
										<Label>Цвет статуса</Label>
										<input className="form-control fill-color" value={color} onChange={(e) => setColor(e.target.value)} name="color" type="color" defaultValue="#563d7c" />
									</FormGroup>
								</div>
							</ModalBody>
							<ModalFooter>
								<Btn attrBtn={{ color: "primary", disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
								<Btn attrBtn={{ color: "secondary primary m-l-5", onClick: tagToggle }}>Отмена</Btn>
							</ModalFooter>
						</Form>
					</Modal>
					<Row>
						{/* <NavClass newtaskdata={newtaskdata} activeToggle={activeToggle} /> */}
						<TabClass activeTab={activeTab} viewType={viewType} statuses={statuses} />
					</Row>
				</div>
			</Container>
		</Fragment>
	);
};

export default Task;
