import React, { Fragment, useEffect, useState } from "react";
import { InputGroup, InputGroupText, Input, Row, Collapse } from "reactstrap";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import uuid from "react-uuid";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { Label, Col } from "reactstrap";
import { toast } from "react-toastify";

import { Plus, Trash2 } from "react-feather";
import { H4 } from "../../../../../AbstractElements";
import Progress from "./Progress";
import { EducationApi } from "../../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import AchievementsList from "./AchievementsList";

const Achievements = ({ students }) => {
	const [isOpen, setIsOpen] = useState(2);

	const toggleClientCard = (item) => {
		if (isOpen === item) {
			setIsOpen(null);
		} else {
			setIsOpen(item);
		}
	};
	return (
		<Fragment>
			<Col onClick={() => toggleClientCard(1)} className="d-flex" style={{ alignItems: "baseline", cursor: "pointer" }}>
				<H4 attrH4={{ className: "mb-2 mt-4" }}>Журнал успеваемости</H4>
				{isOpen === 1 ? <i className="fa fa-angle-up font-primary ms-4 f-26"></i> : <i className="fa fa-angle-down font-primary ms-4 f-26"></i>}
			</Col>
			<Collapse isOpen={isOpen === 1}>
				<div className="p-t-20 p-b-30">{students && <Progress products={students} />}</div>
			</Collapse>

			<Col onClick={() => toggleClientCard(2)} className="d-flex" style={{ alignItems: "baseline", cursor: "pointer" }}>
				<H4 attrH4={{ className: "mb-2 mt-4" }}>Достижения</H4>
				{isOpen === 2 ? <i className="fa fa-angle-up font-primary ms-4 f-26"></i> : <i className="fa fa-angle-down font-primary ms-4 f-26"></i>}
			</Col>
			<Collapse isOpen={isOpen === 2}>
				<div className="p-t-20 p-b-30">
					<AchievementsList />
				</div>
			</Collapse>
		</Fragment>
	);
};
export default Achievements;
