import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Col, Card, CardHeader, Row, Collapse } from "reactstrap";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { H6, Image, LI, UL } from "../../../../AbstractElements";
import { ContactUs } from "../../../../Constant";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { useUsersContext } from "../../../../_helper/Users/UsersProvider";

const UserProfile = ({ userId, profileSettings }) => {
	const { companyData, companyProfileSettingsData, getCompanyProfileSettingsData } = useCompanyContext();
	const [url, setUrl] = useState("");
	const { keycloak, initialized } = useKeycloak();
	const { getUserById, userById } = useUsersContext();
	const [rerender, setRerender] = useState(false);
	const [profileObject, setProfileObject] = useState();

	//const decodedToken = keycloak.tokenParsed;

	// Роли пользователя
	const [roles, setRoles] = useState(); //decodedToken.realm_access?.roles || [];

	const [isSocial, setIsSocial] = useState(false);

	useEffect(() => {
		if (initialized && keycloak.authenticated) {
			setRoles(keycloak.tokenParsed.realm_access?.roles || []);
			roles && setIsSocial(roles.includes("social"));
		}
	}, [initialized, keycloak.tokenParsed]);

	useEffect(() => {
		//try {
		if (userId) {
			getUserById(userId);
		}
		else {
			getUserById(keycloak.tokenParsed.sub);
		}
		//}
		//catch (error) {}
	}, [keycloak.tokenParsed, userId]);

	useEffect(() => {
		console.log(userById);
		try {
			userById && userById.attributes?.companyId && getCompanyProfileSettingsData(userById.attributes?.companyId);
		}
		catch (error) {}
	}, [userById])

	useEffect(() => {
		console.log(companyProfileSettingsData);
		setProfileObject(
			<Card className="hovercard text-center">
					{companyProfileSettingsData && companyProfileSettingsData.isBanner === true && (
						<>
							<CardHeader className="cardheader" style={{ background: `url(${companyProfileSettingsData.image})`, backgroundPosition: "center", backgroundSize: "cover" }}></CardHeader>{" "}
							{/*<div className="user-image">
								<div className="avatar">
									<Image attrImage={{ className: "step1", alt: "", src: companyProfileSettingsData.image ? companyProfileSettingsData.image : "https://dummyimage.com/800x800" }} />
								</div>
							</div>*/}
						</>
					)}

					<div className="info">
						<Row className="step3" data-intro="This is the your details">
							<Col sm="6" lg="4" className="order-sm-1 order-xl-0 d-flex" style={{ alignItems: "end" }}>
								<Row style={{ flex: "auto" }}>
									<Col md="6">
										<div className="ttl-info text-start">
											<H6>
												<i className="fa fa-envelope me-2"></i> Email
											</H6>
											<span>{userById && userById.email}</span>
										</div>
									</Col>
									<Col md="6">
										<div className="ttl-info text-start ttl-sm-mb-0">
											<H6>
												<i className="fa fa-briefcase me-2"></i>
												Организация
											</H6>
											<span>{companyData && companyData.name}</span>
										</div>
									</Col>
								</Row>
							</Col>
							<Col sm="12" lg="4" className="order-sm-0 order-xl-1">
								<div className="user-designation d-flex" style={{ flexDirection: "column", alignItems: "center" }}>
										<div style={{ width: "100px", height: "100px", marginTop: "-20px", marginBottom: "15px" }}>
											<Image attrImage={{ className: "rounded-pill w-100 ", alt: "", src: userById && userById.image ? userById.image : `https://dummyimage.com/800x800` }} />
										</div>
									<div className="d-flex" style={{ justifyContent: "center" }}>
										<div className="title mb-0">
											{userById && userById.lastName} {userById && userById.firstName} {userById && userById.attributes && userById.attributes?.patronymic}
										</div>
										<div className="icon-wrapper step2 ms-3" data-intro="Edit profile information here" style={{ alignItems: "center", display: "flex" }}>
											<Link to={`${process.env.PUBLIC_URL}/app/users/edit`}>
												<i className="icofont icofont-pencil-alt-5"></i>
											</Link>
										</div>
									</div>
									<div className="desc mt-2">{/*profile.postDirector*/}</div>
								</div>
							</Col>
							<Col sm="6" lg="4" className="order-sm-2 order-xl-2 d-flex" style={{ alignItems: "end" }}>
								<Row style={{ flex: "auto" }}>
									<Col md="6">
									{(!companyProfileSettingsData || companyProfileSettingsData.isPhone === true) &&
										<div className="ttl-info text-start ttl-xs-mt">
											<H6>
												<i className="fa fa-phone me-2"></i>
												{ContactUs}
											</H6>
											<span>{userById && userById.attributes ? userById.attributes?.phone : "отсутствует"}</span>
										</div>
									}
									</Col>
									<Col md="6">
										<div className="ttl-info text-start ttl-sm-mb-0">
											<H6>
												<i className="fa fa-user me-2"></i>
												Имя пользователя
											</H6>
											<span className="font-primary">@</span>
											<span> {userById && userById.username}</span>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<p className="text-start mt-4 font-primary " style={{ cursor: "pointer" }} onClick={() => toggleClientCard(1)}>
								Дополнительная информация {isOpen === 1 ? <i className="fa fa-angle-up "></i> : <i className="fa fa-angle-down"></i>}
							</p>
						</Row>
						<Collapse isOpen={isOpen === 1} className="text-start">
							<Row>
							{ userById &&
							<Row>
								{(!companyProfileSettingsData || companyProfileSettingsData.isDescription === true) && userById.attributes?.description && (
									<div className="col-md-4 mb-2">
										<span style={{ color: "#59667a" }}>Обо мне: </span>
										{userById.attributes?.description}
									</div>
								)}
								{(!companyProfileSettingsData || companyProfileSettingsData.isInterests === true) && userById.attributes?.interests && (
									<div className="col-md-4 mb-2">
										<span style={{ color: "#59667a" }}>Интересы: </span>
										{userById.attributes?.interests}
									</div>
								)}
								{(!companyProfileSettingsData || companyProfileSettingsData.isAddress === true) && userById.attributes?.address && (
									<div className="col-md-4 mb-2">
										{" "}
										<span style={{ color: "#59667a" }}>Адрес: </span>
										{userById.attributes?.address}
									</div>
								)}
								{(!companyProfileSettingsData || companyProfileSettingsData.isCity === true) && userById.attributes?.city && (
									<div className="col-md-4 mb-2">
										{" "}
										<span style={{ color: "#59667a" }}>Город: </span>
										{userById.attributes?.city}
									</div>
								)}
								{(!companyProfileSettingsData || companyProfileSettingsData.isPostcode === true) && userById.attributes?.postcode && (
									<div className="col-md-4 mb-2">
										{" "}
										<span style={{ color: "#59667a" }}>Почтовый индекс: </span>
										{userById.attributes?.postcode}
									</div>
								)}
								{(!companyProfileSettingsData || companyProfileSettingsData.isCountry === true) && userById.attributes?.country && (
									<div className="col-md-4 mb-2">
										{" "}
										<span style={{ color: "#59667a" }}>Страна: </span>
										{userById.attributes?.country}
									</div>
								)}
							</Row>
							}
							</Row>
						</Collapse>

						<hr />
						<div className="social-media step4" data-intro="This is your Social details">
							{ userById &&
							<UL attrUL={{ horizontal: true, className: "simple-list list-inline d-flex justify-content-center simple-list" }}>
								{(!companyProfileSettingsData || companyProfileSettingsData.isTelegram === true) && userById.attributes?.telegram &&  (
									<LI attrLI={{ className: "border-0 list-inline-item" }}>
										<a href={`https://t.me/${userById.attributes?.telegram}`}>
											<i className="icofont icofont-social-telegram"></i>
										</a>
									</LI>
								)}
								{(!companyProfileSettingsData || companyProfileSettingsData.isVk === true) && userById.attributes?.vk && (
									<LI attrLI={{ className: "border-0  list-inline-item" }}>
										<a href={`https://vk.com/${userById.attributes?.telegram}`}>
											<i className="fa fa-vk"></i>
										</a>
									</LI>
								)}
							</UL>
							}
						</div>
						{isSocial && (
							<div className="follow">
								<Row>
									<Col col="6" className="border-end text-center">
										<CountUp end={25869} className="follow-num counter d-grid" />
										<span>Подписчики</span>
									</Col>
									<Col col="6" className="text-center">
										<CountUp end={659887} className="follow-num counter d-grid" />
										<span>Подписки</span>
									</Col>
								</Row>
							</div>
						)}
					</div>
				</Card>
		)
	}, [companyProfileSettingsData, userById])

	const readUrl = (event) => {
		if (event.target.files.length === 0) return;
		var mimeType = event.target.files[0].type;

		if (mimeType.match(/image\/*/) == null) {
			return;
		}
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = (_event) => {
			setUrl(reader.result);
		};
	};

	const [profileInfo, setProfileInfo] = useState({});
	useEffect(() => {
		axios
			.get("/api/get-profile-info.json")
			.then((response) => {
				const data = response.data;
				setProfileInfo(data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [isOpen, setIsOpen] = useState(2);

	const toggleClientCard = (item) => {
		if (isOpen === item) {
			setIsOpen(null);
		} else {
			setIsOpen(item);
		}
	};
	return (
		<Fragment>
			<Col sm="12">
				{profileObject}
			</Col>
		</Fragment>
	);
};

export default UserProfile;
