import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import ProductTotal from "../../Learning/Diploma/TemplateCatalog/ProductTotal";
import ProductSidebar from "../../Learning/Diploma/TemplateCatalog/ProductSidebar";
import ProductGrid from "../../Learning/Diploma/TemplateCatalog/ProductGrid";
import SiteContext from "../../../../_helper/Sites";
import FilterContext from "../../../../_helper/Ecommerce/Filter";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { toast } from "react-toastify";
import { CmsApi } from "../../../../api";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const SiteTemplateCatalog = () => {
    const { productItem, getProductItem, symbol } = useContext(SiteContext);
    const context = useContext(FilterContext);

    //pagination
    const cardsPerPage = 12;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = productItem.slice(indexOfFirstCard, indexOfLastCard);

    const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
    let currentUserId = `${keycloak.tokenParsed.sub}`;
    let currentCompanyId = ``;

    useEffect(() => {
        !productItem && getProductItem();
    }, [companyData])

    const installTemplate = async (templateId) => {
        try {
            const response = await axios.post(`${CmsApi}install-template`, { templateId, userId: currentUserId, companyId: currentCompanyId }, {
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
            });
            //console.log('Установка шаблона:', concatResults(response));
            toast.success('Шаблон успешно установлен');
        } catch (error) {
            console.error('Ошибка при установке шаблона:', error);
            toast.error('Ошибка при установке шаблона');
        }
    };

    const payForTemplate = async (template) => {
        try {
            const response = await axios.post(`${CmsApi}pay-template`, { templateId: template.id, userId: currentUserId, companyId: currentCompanyId, price: template.price }, 
                {
                    headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
                }
            );
            //console.log('Оплата шаблона:', concatResults(response));
            toast.success('Шаблон успешно оплачен');
        } catch (error) {
            console.error('Ошибка при оплате шаблона:', error);
            toast.error('Ошибка при оплате шаблона');
        }
    };


    return (
        <Fragment>
            <Breadcrumbs mainTitle="Каталог шаблонов сайтов" parent="Конструктор сайтов" title="Каталог шаблонов сайтов" />
            <Container fluid={true}>
                <div className="product-grid">
                    <div className="feature-products">
                        <ProductTotal
                            len={productItem.length}
                            cardsPerPage={cardsPerPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            indexOfFirstCard={indexOfFirstCard}
                            indexOfLastCard={indexOfLastCard}
                        />
                        <ProductSidebar />
                    </div>
                    <ProductGrid
                        currentCards={currentCards}
                        symbol={symbol}
                        productItem={productItem}
                        installFunc={installTemplate}
                        payFunc={payForTemplate}
                    />
                </div>
            </Container>
        </Fragment>
    );
};
export default SiteTemplateCatalog;
