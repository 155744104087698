import React, { Fragment, useMemo, useState, useEffect, useCallback } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "moment/locale/ru";
import { useLocation } from "react-router-dom";
import { Form, FormGroup, Label, Input, InputGroup, InputGroupText } from "reactstrap";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import axios from "axios";
import { toast } from "react-toastify";
import { Plus, Edit, Clock, Trash2 } from "react-feather";

import CommonModal from "../../../UiKits/Modals/common/modal";
import { Breadcrumbs, H5 } from "../../../../AbstractElements";
import myEventsList from "../Events";
import HeaderCard from "../../../Common/Component/HeaderCard";
import { Btn, P } from "../../../../AbstractElements";

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const CalendarListItem = () => {
	const [services, setServices] = useState("");
	const [status, setStatus] = useState("");

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const calendarId = queryParams.get("calendarId");

	useEffect(() => {
		axios
			.get(`/api/employeesDemandsForm.json`)
			.then((response) => {
				var uniqueServices = {};
				response.data.forEach(function (item) {
					item.services.forEach(function (service) {
						var serviceId = service.service_id;

						if (!uniqueServices[serviceId]) {
							uniqueServices[serviceId] = service;
						}
					});
				});

				var allServices = Object.values(uniqueServices);
				const selectOptions = allServices.map((option) => ({
					label: option.service_name,
					value: option.service_id,
				}));

				setServices(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`/api/eventStatusClient.json`)
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.title,
					value: option.id,
				}));

				setStatus(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [item, setItem] = useState("");
	const [myEvents, setEvents] = useState("");

	useEffect(() => {
		axios
			.get("/api/calendarList.json")
			.then((response) => {
				const foundItem = response.data.find((item) => item.id === calendarId);
				setItem(foundItem);
				setEvents(foundItem.events);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const { defaultDate, messages } = useMemo(
		() => ({
			defaultDate: new Date(),
			messages: {
				week: "Неделя",
				work_week: "Рабочая неделя",
				day: "День",
				month: "Месяц",
				previous: "Предыдуший",
				next: "Следующий",
				today: "Сегодня",
				agenda: "План",
				date: "Дата",
				time: "Время",
				event: "Событие",
				allDay: "Весь день",
				yesterday: "Вчера",
				tomorrow: "Завтра",
				noEventsInRange: "В этом диапазоне нет событий.",

				showMore: (total) => `+ ещё ${total}`,
			},
		}),
		[]
	);

	const [pillWithIconTab, setpillWithIconTab] = useState("1");

	const [modal, setModal] = useState(false);
	const [serviceName, setServiceName] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");

	const [statusId, setStatusId] = useState("");

	const toggle = () => {
		setModal(!modal);
	};

	const [selectedSlot, setSelectedSlot] = useState(null);

	const handleAddEvents = () => {
		const newEvent = {
			id: myEvents.length + 1,
			name: name,
			phone: phone,
			email: email,
			service_id: serviceName,
			date: StartDate,
			time: StartDate,
			status: statusId,
		};

		console.log(name, selectedSlot, StartDate);

		if (name && selectedSlot) {
			let newEvent = {};
			if (selectedSlot) {
				newEvent = {
					title: name,
					start: selectedSlot.start,
					end: selectedSlot.end,
				};
			} else if (StartDate) {
				newEvent = {
					title: name,
					start: StartDate,
					end: StartDate.getHours() + 1,
				};
			}

			setEvents((prevEvents) => [...prevEvents, newEvent]);
			setModal(false);
			setSelectedSlot("");
			// setServiceName('');
		}
		console.log(newEvent);
		axios
			.post(`/api/clientsDemandsForm.json`, newEvent)
			.then((response) => {
				console.log(response.data);
				toast.success("Событие добавлено!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setModal(!modal);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const [StartDate, setStartDate] = useState(new Date());

	const [modalDel, setModalDel] = useState(false);
	const toggleDel = () => setModalDel(!modalDel);
	const handleDelEvents = () => {};

	//selectable calenadar

	const handleSelectSlot = useCallback(({ start, end }) => {
		setSelectedSlot({ start, end });
		setModal(true);
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Редактировать календарь" parent="Календари" title="Редактировать календарь" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<HeaderCard title={item ? item.title : " "} />
							<CardBody>
								<Btn attrBtn={{ color: "primary", onClick: toggle, className: "mb-4" }}>
									<Plus style={{ margin: "0 2px -7px 0px" }} /> Добавить событие
								</Btn>
								<CommonModal isOpen={modal} title="Добавить новый пункт меню" toggler={handleAddEvents} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
									<CardBody style={{ padding: "10px", paddingLeft: "15px", paddingTop: "0" }}>
										<Row>
											<Col sm="4" xs="12">
												<Nav className="nav flex-column nav-pills">
													<NavItem>
														<NavLink className={pillWithIconTab === "1" ? "active" : ""} onClick={() => setpillWithIconTab("1")} style={{ cursor: "pointer" }}>
															<Edit size={"18px"} style={{ margin: "0 5px -3px 5px" }} />
															Детали записи
														</NavLink>
													</NavItem>
													<NavItem>
														<NavLink className={pillWithIconTab === "2" ? "active" : ""} onClick={() => setpillWithIconTab("2")} style={{ cursor: "pointer" }}>
															<Clock size={"18px"} style={{ margin: "0 5px -3px 5px" }} />
															Статус визита
														</NavLink>
													</NavItem>
												</Nav>
											</Col>
											<Col sm="8" xs="12">
												<TabContent activeTab={pillWithIconTab}>
													<TabPane className="fade show" tabId="1">
														<Form className="form theme-form">
															<H5>Клиент</H5>
															<Col className="row col-sm-10">
																<Label className="col-sm-9 col-form-label">Имя</Label>
																<Col sm="11">
																	<Input className="form-control" type="tel" placeholder="Введите имя клиента" onChange={(e) => setName(e.target.value)} />
																</Col>
															</Col>
															<Col className="row col-sm-10">
																<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
																	Телефон
																</Label>
																<Col sm="11">
																	<InputGroup>
																		<InputGroupText>{"+"}</InputGroupText>
																		<Input className="form-control" type="tel" placeholder="Введите телефон клиента" onChange={(e) => setPhone(e.target.value)} />
																	</InputGroup>
																</Col>
															</Col>
															<Col className="row col-sm-10">
																<Label className="col-sm-9 col-form-label">Email</Label>
																<Col sm="11">
																	<Input className="form-control" type="tel" placeholder="Введите email клиента" onChange={(e) => setEmail(e.target.value)} />
																</Col>
															</Col>
															<H5 attrH5={{ className: "mt-4" }}>Услуга</H5>
															<Col className="row col-sm-9 mt-2">
																<Select
																	placeholder="Выберите название услуги"
																	options={services}
																	className="js-example-basic-single col-sm-12"
																	onChange={(selectedOption) => setServiceName(selectedOption.value)}
																/>
															</Col>
															<Col className=" col-sm-9 mt-2">
																<Row>
																	<Col className=" col-sm-6">
																		<Label className="col-sm-10 col-form-label ">Дата записи</Label>
																		<Col xl="10" sm="9">
																			<div className="input-group">
																				<DatePicker
																					className="form-control digits"
																					dateFormat="dd.MM.yyyy"
																					selected={StartDate}
																					onChange={(selectedOption) => setStartDate(selectedOption)}
																				/>
																			</div>
																		</Col>
																	</Col>
																	<Col className=" col-sm-6">
																		<Label className="col-sm-10 col-form-label ">Время записи</Label>
																		<Col xl="10" sm="9">
																			<DatePicker
																				className="form-control digits"
																				selected={StartDate}
																				showTimeSelect
																				showTimeSelectOnly
																				timeIntervals={15}
																				timeCaption="Time"
																				dateFormat="HH:mm"
																				locale={ru}
																				onChange={(selectedOption) => setStartDate(selectedOption)}
																			/>
																		</Col>
																	</Col>
																</Row>
															</Col>
															<Col className=" col-sm-9 mt-3">
																<Label>Примечание</Label>
																<Col sm="11">
																	<textarea className="form-control" rows={"3"} placeholder="" />
																</Col>
															</Col>
														</Form>
														<Btn attrBtn={{ color: "danger", onClick: toggleDel, className: "mt-4 ", outline: true }}>
															<Trash2 size={"18px"} style={{ margin: "0 4px -3px 0px" }} /> Удалить событие
														</Btn>
														<CommonModal isOpen={modalDel} title="Удалить событие" toggler={handleDelEvents} togglerClose={toggleDel} closeText="Отмена" saveText="Удалить">
															<CardBody style={{ padding: "10px", paddingLeft: "15px", paddingTop: "0" }}>
																<Col>
																	<div className="checkbox checkbox-primary">
																		<Input id="checkbox-primary-1" type="checkbox" />
																		<Label for="checkbox-primary-1">Уведомить участников об удалении</Label>
																	</div>
																</Col>
															</CardBody>
														</CommonModal>
													</TabPane>
													<TabPane tabId="2">
														<H5>Статус события</H5>
														<Col className="row col-sm-9 mt-4">
															<Select
																placeholder="Выберите статус"
																options={status}
																className="js-example-basic-single col-sm-12"
																onChange={(selectedOption) => setStatusId(selectedOption.value)}
															/>
														</Col>
													</TabPane>
												</TabContent>
											</Col>
										</Row>
									</CardBody>
								</CommonModal>
								<Calendar
									onSelectSlot={handleSelectSlot}
									messages={messages}
									localizer={localizer}
									scrollToTime={new Date(1970, 1, 1, 6)}
									views={allViews}
									events={myEvents}
									eventOverlap
									dragRevertDuration={500}
									dragScroll
									droppable={true}
									showMultiDayTimes
									step={60}
									startAccessor="start"
									endAccessor="end"
									selectable
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default CalendarListItem;
