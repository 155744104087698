import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import axios from 'axios';

import { Breadcrumbs } from '../../../../AbstractElements';
import TableResources from './TableResources';

import { DeliveryApi } from '../../../../api/index';
const AutomaticCalculation = () => {

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Серверы для автоматического расчёта доставки" parent="Доставка" title="Серверы для автоматического расчёта доставки" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<TableResources />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default AutomaticCalculation;
