import React, { Fragment, useEffect, useState, useSyncExternalStore } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Eye, Settings } from "react-feather";
import DatePicker from "react-datepicker";
import { ru } from "date-fns/locale";
import Select from "react-select";
import "./react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
// import Ckeditor from "react-ckeditor-component";
import axios from "axios";
import { Breadcrumbs, Btn, P } from "../../../../../AbstractElements";
import ColorPic from "./ColorPic";
import initialContent from "./initialContent";
import CommonModal from "../../../../UiKits/Modals/common/modal";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { EducationApi } from "../../../../../api";
import uuid from "react-uuid";
import { useKeycloak } from "@react-keycloak/web";
import Publication from "./Publication";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../../concatResults";

const DiplomaTemplate = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const diplomaId = queryParams.get("diplomaId");
	const [editorState, setEditorState] = useState(() => {
		return EditorState.createWithContent(convertFromRaw(initialContent));
	});
	const [modal, setModal] = useState(false);
	const [IconWithTab, setIconWithTab] = useState("1");
	const [initialContentDefault, setInitialContent] = useState(initialContent);
	const [templateName, setTemplateName] = useState("Диплом по информационным технологиям");
	const [course, setCourse] = useState(null);
	const [courseList, setCourseList] = useState(null);
	const [showDate, setShowDate] = useState(true);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [showName, setShowName] = useState(true);
	const [showNumber, setShowNumber] = useState(true);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [image, setImage] = useState({ src: "https://art-apple.ru/albums/userpics/10001/129/diploma-vector-school.jpg", width: 1290, height: 905 });
	const [imageSize, setImageSize] = useState({ width: 1290, height: 905 });
	const courseChange = (option) => {
		setCourse(option);
	};
	const toggle = () => {
		setModal(!modal);
	};

	const [content, setContent] = useState("");

	const handleChange = (evt) => {
		setContent(evt.editor.getData());
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const responseCourses = await axios.get(`${EducationApi}get-courses`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
				let selectOptions = [];
				if (concatResults(responseCourses).courses.length > 0) {
					selectOptions = concatResults(responseCourses).courses.map((option) => ({
						label: option.name,
						value: option.id,
					}));
					setCourseList(selectOptions);
				} else {
					axios
						.get(`/api/CourseList.json`)
						.then((response) => {
							selectOptions = response.data.map((option) => ({
								label: option.Name,
								value: option.Id,
							}));
							setCourseList(selectOptions);
						})
						.catch((error) => {
							console.error("Ошибка получения данных:", error);
						});

					console.error("Курсы отсутствуют");
				}

				if (diplomaId) {
					const responseDiploma = await axios.get(`${EducationApi}get-course-diploma-form/${diplomaId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
					const diploma = concatResults(responseDiploma);

					const convertedBlocks = JSON.parse(diploma.diplomaContent);

					const newContentState = convertFromRaw(convertedBlocks);

					setEditorState(EditorState.createWithContent(newContentState));
					setTemplateName(diploma.name);
					const selectedCourse = selectOptions.find((course) => course.value === diploma.courseId);
					if (selectedCourse) {
						setCourse(selectedCourse);
						courseChange(selectedCourse);
					}
					setSelectedDate(new Date(diploma.date));
					setShowNumber(diploma.diplomaNumber);
					setImage({ id: diploma.image.id, src: diploma.image.src, width: diploma.image.width, height: diploma.image.height });
				}
			} catch (error) {
				console.error("Ошибка при получении данных:", error);
			}
		};
		fetchData();
	}, [diplomaId]);

	function convertStylesFromServerFormat(stylesFromServer) {
		const convertedStyles = stylesFromServer.map((style) => {
			return {
				item1: style.id,
				item2: style.item2,
				item3: style.item3,
			};
		});
		const groupedStyles = convertedStyles.reduce((acc, style) => {
			acc[style.item2] = style.item3;
			return acc;
		}, {});

		return {
			inlineStyleRanges: [],
			data: groupedStyles,
		};
	}

	const setImg = () => {
		setModal(!modal);
		const img = new Image();
		img.src = image.src;

		img.onload = () => {
			setImage({ ...image, width: img.width, height: img.height });
		};
	};

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} `;

		return formattedDate;
	}

	const send = () => {
		if (course) {
			let convertContentState = convertToRaw(editorState.getCurrentContent());
			const courseNameBlock = convertContentState.blocks.find((block) => block.key === "course_name");
			if (courseNameBlock) {
				const updatedContentState = {
					...convertContentState,
					blocks: convertContentState.blocks.map((block) => (block.key === "course_name" ? { ...block, text: course.label } : block)),
				};
				convertContentState = updatedContentState;
			}

			const DateBlock = convertContentState.blocks.find((block) => block.key === "date");
			if (DateBlock && !showDate) {
				const updatedContentState = {
					...convertContentState,
					blocks: convertContentState.blocks.map((block) => (block.key === "date" ? { ...block, text: "" } : block)),
				};
				convertContentState = updatedContentState;
			} else {
				const updatedContentState = {
					...convertContentState,
					blocks: convertContentState.blocks.map((block) => (block.key === "date" ? { ...block, text: formatDate(selectedDate) } : block)),
				};
				convertContentState = updatedContentState;
			}

			const NumBlock = convertContentState.blocks.find((block) => block.key === "num");
			if (NumBlock && !showNumber) {
				const updatedContentState = {
					...convertContentState,
					blocks: convertContentState.blocks.map((block) => (block.key === "num" ? { ...block, text: "" } : block)),
				};
				convertContentState = updatedContentState;
			}

			const NameBlock = convertContentState.blocks.find((block) => block.key === "name");
			if (NameBlock && !showName) {
				const updatedContentState = {
					...convertContentState,
					blocks: convertContentState.blocks.map((block) => (block.key === "name" ? { ...block, text: "" } : block)),
				};
				convertContentState = updatedContentState;
			}

			const newTemp = {
				id: diplomaId,
				courseId: course.value,
				name: templateName,
				date: selectedDate,
				diplomaNumber: 0,
				image: image,
				blocks: convertContentState.blocks.map((block) => {
					if (block.data && Object.keys(block.data).length > 0) {
						const newData = Object.entries(block.data).map(([key, value]) => {
							return { item1: uuid(), item2: key, item3: value };
						});
						return { ...block, data: newData, id: block.id || uuid() };
					} else {
						return { ...block, data: [], id: block.id || uuid() };
					}
				}),
			};

			if (diplomaId) {
				axios
					.put(`${EducationApi}update-diploma-form`, newTemp, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						toast.success("Диплом обновлен!", {
							position: toast.POSITION.TOP_RIGHT,
						});
					})
					.catch((error) => {
						console.error("Ошибка получения данных:", error);
					});
			} else {
				newTemp.id = uuid();
				axios
					.post(`${EducationApi}create-diploma-form`, newTemp, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						toast.success("Диплом добавлен!", {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.log("RESPONSE - " + response);
					})
					.catch((error) => {
						console.error("Ошибка получения данных:", error);
					});
			}
		} else {
			toast.error("Выберите курс!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
	};

	const isValidGuid = (guid) => {
		const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
		return guidPattern.test(guid);
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={diplomaId ? `Редактировать шаблон диплома` : `Добавить шаблон диплома`} parent="Дипломы" title="Добавить шаблон диплома" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<Nav tabs className="border-tab nav-primary">
								<NavItem>
									<NavLink className={`${IconWithTab === "1" ? "active" : ""} f-16`} onClick={() => setIconWithTab("1")} style={{ cursor: "pointer" }}>
										<Settings size={"20px"} className="me-2" style={{ marginBottom: "-3px" }} />
										Настройка шаблона
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={`${IconWithTab === "2" ? "active" : ""} f-16`} onClick={() => setIconWithTab("2")} style={{ cursor: "pointer" }}>
										Шаблон картинки
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink className={`${IconWithTab === "3" ? "active" : ""} f-16`} onClick={() => setIconWithTab("3")} style={{ cursor: "pointer" }}>
										Настройка публикации в каталоге
									</NavLink>
								</NavItem>
								<div style={{ flexGrow: "1" }}>
									{isValidGuid(diplomaId) && (
										<Link to={`${process.env.PUBLIC_URL}/app/viewing-diploma?diplomaId=${diplomaId}`}>
											<Btn attrBtn={{ color: "info", className: "f-right" }}>
												<Eye size={"18px"} className="me-2" style={{ marginBottom: "-4px" }} />
												Просмотреть диплом
											</Btn>
										</Link>
									)}
								</div>
							</Nav>
							<TabContent activeTab={IconWithTab}>
								<TabPane className="fade show p-t-10" tabId="1">
									<Col className="row col-sm-12 mb-3">
										<Label className="col-sm-9 col-form-label">Название шаблона</Label>
										<Col sm="12">
											<Input value={templateName} className="form-control" onChange={(event) => setTemplateName(event.target.value)} />
										</Col>
									</Col>
									<Row className="mt-4">
										<Col md="8">
											{/* <Ckeditor
												activeclassName="p10"
												content={content}
												events={{
													change: handleChange,
												}}
												config={{
													extraAllowedContent: "style;*[id,rel](*){*}",
													stylesSet: [
														{
															name: "customStyles",
															element: "body",
															styles: {
																"background-image": `url(${image.src})`,
																"background-color": "red",
															},
														},
													],
												}}
											/> */}
											<Editor
												editorStyle={{ backgroundImage: `url(${image.src})`, backgroundAttachment: "local", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}
												editorState={editorState}
												wrapperClassName="editor-class"
												onEditorStateChange={setEditorState}
												localization={{ locale: "ru" }}
												toolbar={{
													colorpicker: { component: ColorPic },
												}}
											/>
										</Col>
										<Col md="4">
											<Col className="row col-sm-12 mb-3">
												<Label className="col-sm-9 col-form-label">Курс</Label>
												<Col sm="12">
													<Select
														placeholder="Выберите..."
														value={course}
														options={courseList}
														className="js-example-basic-single col-sm-12"
														onChange={(selected) => courseChange(selected)}
													/>
												</Col>
											</Col>

											<div className="checkbox checkbox-primary ms-2">
												<Input id={`checkbox-date`} type="checkbox" checked={showDate} onChange={(e) => setShowDate(e.target.checked)} />
												<Label for={`checkbox-date`}>Отображать дату</Label>
											</div>
											{showDate && (
												<Col className="row col-sm-12 mb-3">
													<Label className="col-sm-9 col-form-label">Дата</Label>
													<Col sm="12">
														<DatePicker
															locale={ru}
															value={selectedDate}
															className="form-control digits"
															selected={selectedDate}
															dateFormat="dd.MM.yyyy"
															onChange={(selected) => setSelectedDate(selected)}
														/>
													</Col>
												</Col>
											)}

											<div className="checkbox checkbox-primary ms-2">
												<Input id={`checkbox-name`} type="checkbox" checked={showName} onChange={(e) => setShowName(e.target.checked)} />
												<Label for={`checkbox-name`}>Показывыть имя ученика</Label>
											</div>
											<div className="checkbox checkbox-primary ms-2">
												<Input id={`checkbox-number`} type="checkbox" checked={showNumber} onChange={(e) => setShowNumber(e.target.checked)} />
												<Label for={`checkbox-number`}>Отображать номер диплома</Label>
											</div>
										</Col>
									</Row>
									<div className="modal-footer">
										<Btn attrBtn={{ color: "primary", onClick: send }}>Сохранить</Btn>
									</div>
								</TabPane>
								<TabPane tabId="2" style={{ padding: "20px" }}>
									<Col>
										<P>Шаблон картинки</P>
										<img src={`${image.src}`} alt="Картинка не найдена :(" style={{ maxWidth: "460px", display: "block" }} className="mt-2 mb-2" />
										<Btn attrBtn={{ color: "primary", onClick: toggle }}>Изменить</Btn>
									</Col>
								</TabPane>
								<TabPane tabId="3" style={{ padding: "0 20px 20px" }}>
									<Col className="row col-sm-12 mb-3">
										<Label className="col-sm-9 col-form-label">Название шаблона</Label>
										<Col sm="12">
											<Input value={templateName} className="form-control" onChange={(event) => setTemplateName(event.target.value)} />
										</Col>
									</Col>

									<Publication src={image.src} templateName={templateName} />
								</TabPane>
							</TabContent>
						</CardBody>
						{/* <CardFooter>
							<div className="modal-footer">
								<Btn attrBtn={{ color: "primary", onClick: send }}>Сохранить</Btn>
							</div>
						</CardFooter> */}
					</Card>
				</div>
			</Container>
			<CommonModal isOpen={modal} title="Изменить картинку" toggler={setImg} togglerClose={toggle} saveText="Готово" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">URL картинки</Label>
						<Col sm="12">
							<Input placeholder="Выберите..." value={image.src} className="form-control" onChange={(e) => setImage({ ...image, src: e.target.value })} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</Fragment>
	);
};

export default DiplomaTemplate;
