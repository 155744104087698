import React, { Fragment } from "react";
import { Card, CardBody, Container } from "reactstrap";

import { Breadcrumbs } from "../../../../AbstractElements";
import TableApplicationForm from "./TableApplicationForm";

const TemplatesApplicationForm = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Шаблоны формы заявки" parent="Заявки" title="Шаблоны формы заявки" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<TableApplicationForm />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default TemplatesApplicationForm;
