// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-list-table_taskListWrapper__j3U7J {
	display: table;
	border-left: #e6e4e4 1px solid;
}

.task-list-table_taskListTableRow__gGgOf {
	display: table-row;
	text-overflow: ellipsis;
}

.task-list-table_taskListTableRow__gGgOf:nth-of-type(even) {
	background-color: #f5f5f5;
}

.task-list-table_taskListCell__T0fDS {
	display: table-cell;
	vertical-align: middle;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.task-list-table_taskListNameWrapper__rB-Zj {
	display: flex;
}

.task-list-table_taskListExpander__p514g {
	color: rgb(86 86 86);
	font-size: 0.6rem;
	padding: 0.15rem 0.2rem 0rem 0.2rem;
	-webkit-user-select: none;
	        user-select: none;
	cursor: pointer;
}
.task-list-table_taskListEmptyExpander__ponVY {
	font-size: 0.6rem;
	padding-left: 1rem;
	-webkit-user-select: none;
	        user-select: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Application/Project/ProjectView/task-list-table.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,8BAA8B;AAC/B;;AAEA;CACC,kBAAkB;CAClB,uBAAuB;AACxB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,mBAAmB;CACnB,sBAAsB;CACtB,mBAAmB;CACnB,gBAAgB;CAChB,uBAAuB;AACxB;AACA;CACC,aAAa;AACd;;AAEA;CACC,oBAAoB;CACpB,iBAAiB;CACjB,mCAAmC;CACnC,yBAAiB;SAAjB,iBAAiB;CACjB,eAAe;AAChB;AACA;CACC,iBAAiB;CACjB,kBAAkB;CAClB,yBAAiB;SAAjB,iBAAiB;AAClB","sourcesContent":[".taskListWrapper {\n\tdisplay: table;\n\tborder-left: #e6e4e4 1px solid;\n}\n\n.taskListTableRow {\n\tdisplay: table-row;\n\ttext-overflow: ellipsis;\n}\n\n.taskListTableRow:nth-of-type(even) {\n\tbackground-color: #f5f5f5;\n}\n\n.taskListCell {\n\tdisplay: table-cell;\n\tvertical-align: middle;\n\twhite-space: nowrap;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n}\n.taskListNameWrapper {\n\tdisplay: flex;\n}\n\n.taskListExpander {\n\tcolor: rgb(86 86 86);\n\tfont-size: 0.6rem;\n\tpadding: 0.15rem 0.2rem 0rem 0.2rem;\n\tuser-select: none;\n\tcursor: pointer;\n}\n.taskListEmptyExpander {\n\tfont-size: 0.6rem;\n\tpadding-left: 1rem;\n\tuser-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taskListWrapper": `task-list-table_taskListWrapper__j3U7J`,
	"taskListTableRow": `task-list-table_taskListTableRow__gGgOf`,
	"taskListCell": `task-list-table_taskListCell__T0fDS`,
	"taskListNameWrapper": `task-list-table_taskListNameWrapper__rB-Zj`,
	"taskListExpander": `task-list-table_taskListExpander__p514g`,
	"taskListEmptyExpander": `task-list-table_taskListEmptyExpander__ponVY`
};
export default ___CSS_LOADER_EXPORT___;
