// LeftPanel.js
import React from 'react';
import { Col, Label, Input, Media } from 'reactstrap';

const ElementPrepayment = ({ setIsOverLeftPanel, content = 'Оплатить онлайн' }) => {
	return (
		<>
			<div
				className="col-sm-11 mb-3"
				draggable="true"
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'prepayment');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Col sm="12">
					<Media>
						<Label className="col-form-label col-sm-4">{content}</Label>
						<Media body className={'text-start icon-state '} style={{ flexGrow: '0' }}>
							<Label className="switch">
								<Input type="checkbox" />
								<span className="switch-state"></span>
							</Label>
						</Media>
					</Media>
				</Col>
			</div>
		</>
	);
};

export default ElementPrepayment;
