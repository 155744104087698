import Select, {components} from "react-select";
import React, {useEffect, useState} from "react";
import {ArrowDropDownRounded} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {useClientsContext} from "../../../../../../_helper/Clients/ClientsProvider";

export const ObjectTypeCharacteristicSelect = ({value, onChange}) => {
    const { documentTypeCharacteristics, changeModalCreateDocCharVisible } = useClientsContext();
    const [objectTypesCharacteristics, setObjectTypeCharacteristics] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    const DropDownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownRounded />
            </components.DropdownIndicator>
        )
    }

    useEffect(() => {
        Array.isArray(documentTypeCharacteristics) && setObjectTypeCharacteristics(documentTypeCharacteristics.map((item) => ({label: item.name, value: item.id})))
    }, [documentTypeCharacteristics]);

    useEffect(() => {
        value && objectTypesCharacteristics && setSelectedValue(objectTypesCharacteristics.find((item) => item.value === value));
    }, [value, objectTypesCharacteristics])

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="">Нет характеристик для вывода</span>
            </components.NoOptionsMessage>
        );
    };

    const changeValue = (item) => {
        onChange(item);
        setSelectedValue(item);
    }

    return <>
        <div className="row">
            <Select
                placeholder="Выбор характеристики"
                value={selectedValue}
                options={objectTypesCharacteristics ?? []}
                className="js-example-basic-single col col-sm-10"
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: "rgba(115, 102, 255, 0.08)",
                        primary: "#7366ff",
                    },
                })}
                components={{NoOptionsMessage: NoOptionsMessage, DropdownIndicator: DropDownIndicator}}
                onChange={(item) => changeValue(item)}
            />
            <div className="col-sm-2">
                <button className="btn btn-primary p-2" onClick={() => changeModalCreateDocCharVisible()}><AddIcon style={{display: "block", width: 24, height: 24}}/></button>
            </div>
        </div>
    </>
}

export default ObjectTypeCharacteristicSelect;