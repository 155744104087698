import React, { Fragment, useState } from 'react';
import { CardHeader, Label } from 'reactstrap';
import StandardPricingcard from './StandardProcingCard';
import { Card, Col, Container, Row } from 'reactstrap';

const SelectTemplate = () => {
	return (
		<Fragment>
			<Container fluid={true} style={{ paddingTop: '50px' }}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h3 style={{ textAlign: 'center' }}>Выберите, с чем вы планируете работать чаще всего</h3>
							</CardHeader>
							<StandardPricingcard />
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default SelectTemplate;
