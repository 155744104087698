import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { ConsecutivePagesApi, TwinAuthApi } from '../../api';
import ConpagesContext from '.';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from '../Company/CompanyProvider';
import { concatResults } from '../../concatResults';

const ConpagesProvider = ({ children, props }) => {
    const context = useCreateConpagesContext(props);
    
    return (
        <ConpagesContext.Provider
            value={context}
        >
            {children}
        </ConpagesContext.Provider>
    );
};

export function useConpagesContext() {
    const context = useContext(ConpagesContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateConpagesContext = function(props) {
    const { keycloak, initialized } = useKeycloak();
    const { companyData } = useCompanyContext();

    const [companyConpagesData, setCompanyConpagesData] = useState(null);
    const [companyConpagesDataLog, setCompanyConpagesDataLog] = useState(null);
    const [conpagesData, setConpagesData] = useState(null);
    const [conpagesDataLog, setConpagesDataLog] = useState(null);
    const [conpagesDataStart, setConpagesDataStart] = useState(false);
    const [updated, setUpdated] = useState(true);
    
    useEffect(() => {
        refreshConpages();
    }, [initialized, updated]);

    useEffect(() => {
        refreshCompanyConpages();
    }, [companyData, updated]);

    const refreshCompanyConpages = async () => {
        if (initialized && keycloak.authenticated && companyData){
            await axios
				.get(`${ConsecutivePagesApi}get-company-log?id=${companyData[0]?.id}&userId=${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then(async (response) => {
                    if (concatResults(response) && concatResults(response).pageId) {
                        //console.log(concatResults(response));
                        setCompanyConpagesDataLog(concatResults(response));
                        await axios
                            .get(`${ConsecutivePagesApi}get-page?id=${concatResults(response).pageId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                            .then((responsePage) => {
                                setCompanyConpagesData(concatResults(responsePage));
                            })
                            .catch((error) => {
                                console.error('Ошибка получения данных:', error);
                            });
                    }
                    else {
                        setCompanyConpagesDataLog(null);
                        setCompanyConpagesData(null);
                    }
				})
				.catch((error) => {
					//console.error('Ошибка получения данных:', error);
				});
        }
    } ;

    const refreshConpages = async () => {
        if (initialized && keycloak.authenticated) {
            await axios
				.get(`${ConsecutivePagesApi}get-log?id=${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then(async (response) => {
                    if (concatResults(response)) {
                        setConpagesDataLog(concatResults(response));
                        await axios
                            .get(`${ConsecutivePagesApi}get-page?id=${concatResults(response).pageId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                            .then((responsePage) => {
					            setConpagesData(concatResults(responsePage));
                            })
                            .catch((error) => {
                                console.error('Ошибка получения данных:', error);
                            });
                    }
                    else {
                        setConpagesDataLog(null);
                        setConpagesData(null);
                        await axios
                            .get(`${TwinAuthApi}get-user?id=${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                            .then((response) => {
                                if (JSON.parse(response).attributes?.patronymic !== undefined) {
                                    setConpagesDataStart(true);
                                }
                                else {
                                    setConpagesDataStart(false);
                                }
                            })
                            .catch((error) => {
                                console.error('Ошибка получения данных:', error);
                            });
                        
                    }
				})
				.catch((error) => {
					//console.error('Ошибка получения данных:', error);
				});
        }
    }

    const setNextPageInCompany = () => {
        if (initialized && keycloak.authenticated) {
            console.log(companyConpagesDataLog);
            axios
				.post(`${ConsecutivePagesApi}setnextpage-log/${companyConpagesDataLog.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
				.then((response) => {
                    setUpdated(!updated);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
        }
    }

    const setNextPage = () => {
        if (initialized && keycloak.authenticated) {
            axios
				.post(`${ConsecutivePagesApi}setnextpage-log/${conpagesDataLog.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
                    setUpdated(!updated);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
        }
    }

    const toggleUpdated = () => {
        setUpdated(!updated);
    }
  
    return {
      companyConpagesData,
      conpagesData,
      conpagesDataStart,
      toggleUpdated,
      setNextPage,
      setNextPageInCompany
    };
}

export default ConpagesProvider;