import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Container, Row, Card, CardBody, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CommonModal from "../../../UiKits/Modals/common/modal";
import DataTable from "react-data-table-component";
import SweetAlert from "sweetalert2";
import {toast} from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import {Edit, Trash2} from "react-feather";
import FilterComponent from "../../Widgets/InteractivePlanMap/FilterComponent";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";
import {TiTick} from "react-icons/ti";
import Select from "react-select";

const DataTableClientsTypeList = () => {
    const [name, setName] = useState();
    const [isActive, setIsActive] = useState(false);
    const [serviceId, setServiceId] = useState(null);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const {keycloak} = useKeycloak();
    const {companyData, companyServices, getCompanyServices, getAllCompanies, allCompanies} = useCompanyContext();
    const {getClientTypes, clientTypes, updateClientType, createClientType, deleteClientType} = useClientsContext();
    const [companyServicesSelect, setCompanyServicesSelect] = useState([]);

    useEffect(() => {
        clientTypes && setFilteredItems(clientTypes.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
        console.log(clientTypes);
    }, [filterText, setFilterText, clientTypes]);

    useEffect(() => {
        getClientTypes();
        getCompanyServices();
        getAllCompanies();
    }, [keycloak.tokenParsed, companyData]);

    useEffect(() => {
        companyServices && setCompanyServicesSelect(companyServices.map((item) => ({label: item.displayName ?? item.serviceUrl, value: item.id})))
    }, [companyServices]);

    const toggle = (id) => {
        setModal(!modal);
        setId(null);
        if (id) {
            let clientType = clientTypes.find((item) => item.id === id);
            clientType && setName(clientType?.name ?? "");
            clientType && setIsActive(clientType?.isActive ?? "");
            clientType && setServiceId(clientType?.serviceId ?? "");
            setId(id);
        }
    }

    const deleteRow = (typeId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'После удаления вы не сможете восстановить этот объект!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                deleteClientType(typeId).then(() => {
                    getClientTypes();
                    toast.success('Тип удалён!', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
            }
        });
    };

    const handleSendRequest = () => {
        if (id) {
            updateClientType(id, name, isActive, serviceId).then(() => {
                getClientTypes();
                setName('');
                toast.success('Тип обновлён', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
        }
        else {
            createClientType(name, isActive, serviceId).then(() => {
                getClientTypes();
                setName('');
                toast.success('Тип добавлен', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
        }

        toggle();
    };

    const getCompanyName = (companyId) => {
        let resultSearch = '';
        if (Array.isArray(allCompanies?.companies)) {
            resultSearch = allCompanies?.companies?.find((company) => company.id === companyId)?.shortLegalName ?? 'Компания не найдена'
        }
        return resultSearch;
    }

    const getServiceName = (serviceId) => {
        let resultSearch = '';
        console.log(companyServices);

        if (Array.isArray(companyServices)) {
            resultSearch = companyServices?.find((service) => service.id === serviceId);
            resultSearch = resultSearch?.displayName && resultSearch?.displayName !== '' ? resultSearch?.displayName : resultSearch?.serviceUrl;
        }
        return resultSearch;
    }

    const tableColumns = [
        {
            name: 'Тип клиента',
            selector: (row) => `${row.name}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Активность',
            selector: (row) => row.isActive ? <TiTick></TiTick> : '',
            sortable: true,
            center: false,
        },
        {
            name: 'Компания',
            selector: (row) => `${row.companyId === null || row.companyId === '' ? '' : getCompanyName(row.companyId)}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Сервис',
            selector: (row) => `${row.serviceId === null || row.serviceId === '' ? '' : getServiceName(row.serviceId)}`,
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                (row.companyId === null && keycloak.tokenParsed.realm_access?.roles.includes('SuperAdministrator')) || row.companyId  &&
                    <div style={{display: 'flex'}}>
                        <Tooltip title="Редактировать" arrow placement="top">
                            <div
                                className="font-primary"
                                style={{cursor: 'pointer', marginRight: '5px'}}
                                onClick={() => toggle(row.id)}
                            >
                                <Edit size={'18px'}/>
                            </div>
                        </Tooltip>
                        <Tooltip title="Удалить" arrow placement="top">
                            <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                                 onClick={() => deleteRow(row.id)}>
                                <Trash2 size={'18px'}/>
                            </div>
                        </Tooltip>
                    </div>
            ),
        },
    ];

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={() => toggle(null)} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear}
                                filterText={filterText}/>;
    }, [filterText, resetPaginationToggle]);

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={`${id ? 'Редактирование' : 'Добавление нового'} типа клиента`} toggler={handleSendRequest}
                         togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{padding: '10px', paddingLeft: '15px'}}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{flexDirection: 'column'}}>
                                <Label className="col-sm-9 col-form-label">
                                    Название
                                </Label>
                                <Col sm="11">
                                    <Input
                                        className="form-control"
                                        value={name}
                                        placeholder="Название типа"
                                        onChange={(e) => setName(e.target.value)} />
                                </Col>
                                <Label className="col-sm-9 col-form-label">
                                    Активен ли тип
                                </Label>
                                <Col sm="11">
                                    <Input id="checkbox-primary-1" type="checkbox" checked={isActive} onChange={(e) => setIsActive(e.target.checked)}/>
                                </Col>
                                <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                    <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                        Сервис
                                    </Label>
                                    <Col sm="11">
                                        <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} value={serviceId && companyServicesSelect.find((i) => i.value === serviceId)} options={companyServicesSelect} onChange={(e) => setServiceId(e?.value)} className="js-example-basic-single col-sm-12" />
                                    </Col>
                                </FormGroup>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTableClientsTypeList;