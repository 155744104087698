import React, { Fragment, useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import Popover from "@mui/material/Popover";
import SweetAlert from "sweetalert2";
import {Trash2, Edit, Eye, Plus} from "react-feather";
import { useKeycloak } from "@react-keycloak/web";
import { TaskApi } from "../../../api";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";
import {Btn} from "../../../AbstractElements";

const DataTableTasks = ({ UserType = null, users, data, setData, toggleTask }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [anchorEl, setAnchorEl] = useState(null);
	const [curId, setCurId] = useState(null);
	const handleClick = (id, event) => {
		setAnchorEl(event);
		setCurId(id);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const deleteTask = (taskId) => {
		setAnchorEl(null);
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить эту задачу!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${TaskApi}delete/${taskId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						SweetAlert.fire("Удалено!", "Задача была удалена.", "success");
						setData((prevData) => prevData.filter((item) => item.id !== taskId));
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении задачи:", error);
					});
			}
		});
	};

	const tableColumns = [
		{
			name: "Задача",
			selector: (row) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<div id={`clickable-${row.id}`} style={{ padding: " 7px" }}>
						{row.name}
					</div>
					<div className="btn-light btn btn-xs ms-2" style={{ cursor: "pointer", height: "fit-content" }} onClick={(e) => handleClick(row.id, e.currentTarget)} aria-describedby={id}>
						<i className="fa fa-reorder"></i>
					</div>
				</div>
			),
			sortable: true,
			width: "14%",
		},
		{
			name: <div>Постановщик</div>,
			selector: (row) => `${getUserInitialsById(row.creatorId)}`,
			sortable: true,
			center: false,
		},
		{
			name: <div>Ответственный</div>,
			selector: (row) => `${getUserInitialsById(row.doerId)}`,
			sortable: true,
			center: false,
		},
		{
			name: <div>Наблюдатели</div>,
			selector: (row) => <div style={{ whiteSpaceCollapse: "collapse" }}>{/*row.watchers.map((item) => getUserInitialsById(item) + ', ')*/}</div>,
			sortable: true,
			center: false,
			width: "22%",
		},
		{
			name: <div>Статус</div>,
			selector: (row) => `${row.status ? row.status.name : ""}`,
			sortable: true,
			center: false,
		},
		{
			name: <div>Проект</div>,
			selector: (row) => `${row.projectId ? row.projectName : ""}`,
			sortable: true,
			center: true,
		},
	];

	function getUserInitialsById(id) {
		try {
			return users.find((u) => u.value === id).label;
		} catch (error) {
			return "Не удалось загрузить инициалы";
		}
	}

	return (
		<Fragment>
			<Btn attrBtn={{ color: "primary", className: "ms-3 me-3", onClick: toggleTask }}>
				<Plus size="18px" style={{ margin: "0 3px -3px 0" }} /> Быстрая задача
			</Btn>
			<DataTable
				data={data}
				columns={tableColumns}
				highlightOnHover={true}
				center={true}
				pagination
				pointerOnHover
				noDataComponent={"В данной таблице ещё нет записей."}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
					<Link to={`${process.env.PUBLIC_URL}/app/task/view-task?taskId=${curId}`} className="pb-2 b-b-light">
						<Eye size={"18px"} style={{ margin: "0 2px -2px 4px" }} /> Просмотр
					</Link>
					<Link to={`${process.env.PUBLIC_URL}/app/task/create-task?taskId=${curId}`} className="pb-2 mt-2 b-b-light">
						<Edit size={"18px"} style={{ margin: "0 2px -2px 4px" }} /> Редактировать
					</Link>
					<a className="font-primary mt-2" style={{ cursor: "pointer" }} onClick={() => deleteTask(curId)}>
						<Trash2 size={"18px"} style={{ margin: "0 2px -2px 4px" }} /> Удалить
					</a>
					{/* проверить на роль */}
				</div>
			</Popover>
		</Fragment>
	);
};

export default DataTableTasks;
