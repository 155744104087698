import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableServicesFieldsSettings from './TableServicesFieldsSettings';
import axios from 'axios';

const ServiceRequests = () => {

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Настройка сервисов и полей для экспорта данных" parent="Экспорт данных" title="Настройка сервисов и полей для экспорта данных" />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <TableServicesFieldsSettings />
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default ServiceRequests;
