// LeftPanel.js
import React from 'react';
import { Col, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale';

const ElementTime = ({ setIsOverLeftPanel, content = 'Выбрать время' }) => {
	return (
		<>
			<div
				draggable="true"
				className={`col-sm-11 mb-3`}
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'time');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Label className="col-sm-9 col-form-label">{content}</Label>
				<Col sm="12">
					<div className="input-group">
						<DatePicker dateFormat="HH:mm" timeCaption="Время" showTimeSelect showTimeSelectOnly timeIntervals={15} locale={ru} selected={new Date()} className="form-control digits" />
					</div>
				</Col>
			</div>
		</>
	);
};

export default ElementTime;
