import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import {Trash2} from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../UiKits/Modals/common/modal';
import {CardBody, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import FilterComponent from "../../Widgets/InteractivePlanMap/FilterComponent";
import {useKeycloak} from "@react-keycloak/web";
import {useInteractivePlanMapsContext} from "../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import {TiTick} from "react-icons/ti";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Select from "react-select";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {SettingsApplications} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";

const DataTableEditClientDocument = () => {
    const { keycloak } = useKeycloak();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const clientDocumentId = queryParams.get('clientDocumentId');
    const [charValueId, setCharValueId] = useState('');
    const [docCharacteristicId, setDocCharacteristicId] = useState("");
    const [value, setValue] = useState('');

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const {
        createClientDocumentValue,
        updateClientDocumentValue,
        getDocumentTypeCharacteristcs,
        getClientDocument,
        clientDocument,
        getClientDocumentValues,
        clientDocumentValues,
        documentTypeCharacteristics
    } = useClientsContext();

    const {companyData} = useCompanyContext();

    useEffect(() => {
        documentTypeCharacteristics && setFilteredItems(documentTypeCharacteristics.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, documentTypeCharacteristics]);

    useEffect(() => {
        clientDocumentId && getClientDocument(clientDocumentId);
        clientDocumentId && getClientDocumentValues(clientDocumentId);
    }, [keycloak.tokenParsed, clientDocumentId, companyData]);

    useEffect(() => {
        clientDocument && getDocumentTypeCharacteristcs(clientDocument.documentTypeId);
    }, [clientDocument]);

    useEffect(() => {
        documentTypeCharacteristics && getDocumentTypeCharacteristcs();

    }, [documentTypeCharacteristics]);

    const toggle = (id) => {
        setDocCharacteristicId(id);
        setValue('');
        if (id) {
            let findType = clientDocumentValues?.find((item) => item.documentTypeCharacteristicId === id);
            console.log(findType);
            if (findType) {
                setCharValueId(findType.id);
                setValue(findType.value);
            }
        }
        setModal(!modal);
    };

    const handleAddRow = () => {
        if (value !== '') {
            if (charValueId) {
                updateClientDocumentValue(charValueId, clientDocumentId, docCharacteristicId, value);
            } else {
                createClientDocumentValue(clientDocumentId, docCharacteristicId, value);
            }
            setModal(!modal);
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const checkExistValue = (id) => {
        let exist = false;
        if (clientDocumentValues) {
            console.log(1);
            let findType = clientDocumentValues.find((item) => item.documentTypeCharacteristicId === id);

            if (findType) {
                exist = true;
            }
        }
        return exist;
    };

    const tableColumns = [
        {
            name: 'Название',
            selector: (row) => <span>{row.name}</span>,
            sortable: true,
            center: false,
        },
        {
            name: '',
            button: true,
            selector: (row) => (<div style={{ display: 'flex' }}>
                {checkExistValue(row.id) ?
                    <Tooltip title="Редактировать свойство" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }}
                             onClick={() => toggle(row.id)}>
                            <SettingsApplications size={'18px'} />
                        </div>
                    </Tooltip>
                    :
                    <Tooltip title="Добавить свойство" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }}
                             onClick={() => toggle(row.id)}>
                            <AddIcon size={'18px'} />
                        </div>
                    </Tooltip>
                }
            </div>)
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Редактирование значения поля'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Значение
                                </Label>
                                <Col sm="11">
                                    <Input
                                        className="form-control"
                                        value={value}
                                        placeholder="Значение"
                                        onChange={(e) => setValue(e.target.value)} />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTableEditClientDocument;
