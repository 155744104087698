import React, { useMemo, useState, useEffect } from 'react';
import { Trash2, Edit, Eye } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import FilterComponent from './FilterComponent';
import { EducationApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const TableDiplomaList = ({ products }) => {
	const [data, setData] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		setData(products);
	}, [products]);

	const columns = [
		{
			name: '№',
			selector: (row) => `${row.id}`,
			sortable: true,
		},
		{
			name: 'Название',
			selector: (row) => <Link to={`${process.env.PUBLIC_URL}/app/diploma-template?diplomaId=${row.id}`}>{row.name}</Link>,
			sortable: true,
		},
		{
			name: 'Выдано',
			selector: (row) => `${row.issued}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Просмотр" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/app/viewing-diploma?diplomaId=${row.id}`} style={{ marginLeft: '7px' }}>
							<Eye size={'18px'} />
						</Link>
					</Tooltip>
					<Tooltip title="Редактировать" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/app/diploma-template?diplomaId=${row.id}`} style={{ marginLeft: '7px' }}>
							<Edit size={'18px'} />
						</Link>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteDiplomaForm(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const deleteDiplomaForm = (formId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {

			axios
				.delete(`${EducationApi}delete-course-diploma-form/${formId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then(() => {
					setData((prevData) => prevData.filter((item) => item.id !== formId));
					SweetAlert.fire('Удалено!', 'Объект был удален.', 'success');
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});

		});
	};

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</>
	);
};

export default TableDiplomaList;
