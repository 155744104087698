import React, { useMemo, useState, useEffect, useContext } from "react";
import { Trash2, Edit, Plus } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import ru from "date-fns/locale/ru";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Label, Input, CardBody, Col, Row } from "reactstrap";
import JSZip from "jszip";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import CMSTemplatesContext from "../../../../_helper/CMSTemplates";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TableTemplateEdit = ({ products, fetchData }) => {
	const [editModal, setEditModal] = useState(false);
	const { allTemplates, getAllCmsSelects } = useContext(CMSTemplatesContext);
	const allSites = allTemplates;

	const [edit, setEdit] = useState(null);

	const [condition, setCondition] = useState("");
	const [sorting, setSorting] = useState(0);
	const [id, setId] = useState("");

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		!allTemplates && getAllCmsSelects();
	}, [companyData])

	const toggle = (siteId) => {
		if (typeof siteId === "object" && siteId.hasOwnProperty("template")) {
			setSelectSite(siteId.template);
			setId(siteId.id);
			setEdit(true);
			setSorting(siteId.sorting);
			setSelectCondition(siteId.condition_type);

			if (siteId.condition_type && (siteId.condition_type.value === "page" || siteId.condition_type.value === "url_parameter")) {
				setCondition(siteId.condition_value);
			} else if (siteId.condition_type && siteId.condition_type.value === "time_period") {
				setStartDate(new Date(siteId.condition_value.start_date));
				setEndDate(new Date(siteId.condition_value.end_date));
			} else {
				setSelectGroups(siteId.condition_value);
			}
		} else {
			setSorting(0);
			setId("");
			setEdit(false);
			setSelectCondition(null);
		}
		setEditModal(!editModal);
	};

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);
		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	const columns = [
		{
			name: "Шаблон",
			selector: (row) => (row.template ? `${row.template.label}` : ""),
			sortable: true,
		},
		{
			name: "Сортировка",
			selector: (row) => `${row.sorting}`,
			sortable: true,
		},
		{
			name: "Тип условия",
			selector: (row) => (row.condition_type ? `${row.condition_type.label}` : <p>Без условия</p>),
			sortable: true,
		},
		{
			name: "Условие",
			selector: (row) =>
				row.condition_value ? (
					row.condition_type.value === "url_parameter" || row.condition_type.value === "page" ? (
						`${row.condition_value}`
					) : row.condition_type.value === "time_period" ? (
						`с ${formatDate(row.condition_value.start_date)} по  ${formatDate(row.condition_value.end_date)}`
					) : (
						row.condition_value.map((item) => item.label).join(", ")
					)
				) : (
					<p>Без условия</p>
				),
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTemplate(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	//удаление шаблона
	const deleteTemplate = (siteId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				// axios
				// 	.delete(`${CmsApi}deleteSite`, {
				// 		data: {
				// 			siteId: siteId,
				// 		},
				// 		headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				// 	})
				// 	.then((response) => {
				// 		SweetAlert.fire("Удалено!", "Объект был удален.", "success");
				// 		fetchData();
				// 	})
				// 	.catch((error) => {
				// 		console.error("Произошла ошибка при удалении:", error);
				// 	});
			}
		});
	};

	//Добавление и обновление шаблона
	const addEditTemplate = () => {
		const newTemplate = {
			template: selectSite,
			sorting: sorting,
			condition_type: selectCondition,
			condition_value:
				selectCondition && selectCondition.value !== "no_condition"
					? selectCondition.value === "url_parameter" || selectCondition.value === "page"
						? condition
						: selectCondition.value === "time_period"
						? {
								start_date: startDate,
								end_date: endDate,
						  }
						: selectGroups
					: null,
		};
		console.log(newTemplate);
		// if (edit) {
		// 	axios
		// 		.put(`${CmsApi}updateSite`, newTemplate, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 		.then((response) => {
		// 			toast.success("Сайт обновлен!", {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 			setEditModal(false);
		// 			fetchData();
		// 		})
		// 		.catch((error) => {
		// 			setEditModal(false);
		// 			toast.error("Ошибка при обновлении", {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 		});
		// } else {
		// 	axios
		// 		.post(`${CmsApi}createSite`, newTemplate, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
		// 		.then((response) => {
		// 			toast.success("Сайт добавлен!", {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 			setEditModal(false);
		// 			fetchData();
		// 		})
		// 		.catch((error) => {
		// 			toast.error("Ошибка при добавлении", {
		// 				position: toast.POSITION.TOP_RIGHT,
		// 			});
		// 		});
		// }
	};
	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = products.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const [selectSite, setSelectSite] = useState(null);

	const [selectCondition, setSelectCondition] = useState(null);
	const [allConditions, setAllConditions] = useState([]);

	const [selectGroups, setSelectGroups] = useState(null);
	const [allRoles, setAllRoles] = useState([]);

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	useEffect(() => {
		axios
			.get(`/api/condition_type.json`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setAllConditions(response.data);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		axios
			.get(`/api/roles.json`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.role,
					value: option.id,
				}));
				setAllRoles(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				noDataComponent={"В данной таблице ещё нет записей."}
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={editModal} title={edit ? "Редактировать" : "Добавить"} toggler={addEditTemplate} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="xl">
				<CardBody style={{ padding: "10px", paddingLeft: "20px", paddingTop: "0" }}>
					<Row>
						<Col md="3">
							<Label className="col-sm-9 col-form-label">Шаблон</Label>
							<Col sm="11">
								<Select
									placeholder="Выберите..."
									noOptionsMessage={() => "Нет результатов"}
									options={allSites}
									value={selectSite}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											primary25: "#7366ff80",
											primary: "#7366ff",
										},
									})}
									className="js-example-basic-single col-md-12 mb-2"
									onChange={(option) => setSelectSite(option)}
								/>
							</Col>
						</Col>
						<Col md="2">
							<Label className="col-sm-9 col-form-label">Сортировка</Label>
							<Col sm="11">
								<Input className="form-control" type="number" value={sorting} onChange={(e) => setSorting(e.target.value)} />
							</Col>
						</Col>
						<Col md="3">
							<Label className="col-sm-9 col-form-label">Тип условия</Label>
							<Col sm="11">
								<Select
									placeholder="Выберите..."
									noOptionsMessage={() => "Нет результатов"}
									options={allConditions}
									value={selectCondition}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											primary25: "#7366ff80",
											primary: "#7366ff",
										},
									})}
									className="js-example-basic-single col-md-12 mb-2"
									onChange={(option) => setSelectCondition(option)}
								/>
							</Col>
						</Col>
						<Col md="4">
							<Label className="col-sm-9 col-form-label">Условие</Label>
							<Col sm="11">
								{selectCondition && selectCondition.value === "time_period" ? (
									<Row>
										<Col md="6">
											<div style={{ display: "flex", alignItems: "flex-end" }}>
												<Label className="m-r-10">С</Label>
												<div className="customDatePickerWidth ">
													<DatePicker
														dateFormat="dd.MM.yyyy"
														locale={ru}
														className="form-control digits"
														selected={startDate}
														value={startDate}
														onChange={(date) => setStartDate(date)}
													/>
												</div>
											</div>
										</Col>
										<Col md="6">
											<div style={{ display: "flex", alignItems: "flex-end" }}>
												<Label className="m-r-10">По</Label>
												<div className="customDatePickerWidth">
													<DatePicker
														dateFormat="dd.MM.yyyy"
														locale={ru}
														className="form-control digits"
														selected={endDate}
														value={endDate}
														onChange={(date) => setEndDate(date)}
													/>
												</div>
											</div>
										</Col>
									</Row>
								) : selectCondition && selectCondition.value === "user_group" ? (
									<Select
										placeholder="Выберите..."
										noOptionsMessage={() => "Нет результатов"}
										options={allRoles}
										value={selectGroups}
										isMulti
										theme={(theme) => ({
											...theme,
											colors: {
												...theme.colors,
												primary25: "#7366ff80",
												primary: "#7366ff",
											},
										})}
										className="js-example-basic-single col-md-12 mb-2"
										onChange={(option) => setSelectGroups(option)}
									/>
								) : (
									<Input
										disabled={(selectCondition && selectCondition.value === "no_condition") || !selectCondition}
										className="form-control"
										type="text"
										value={condition}
										onChange={(e) => setCondition(e.target.value)}
									/>
								)}
							</Col>
						</Col>
					</Row>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableTemplateEdit;
