import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { Btn, H6, P } from "../../../../AbstractElements";
import { Label, Input, CardBody, Col } from "reactstrap";
import FilterComponent from "../../Catalog/FilterComponent";
import { useKeycloak } from "@react-keycloak/web";
import uuid from "react-uuid";
import { CounterpartiesApi } from "../../../../api";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TableCounterpartiesRoles = ({ roles, setRoles, companyId }) => {
    const [modal, setModal] = useState(false);
    const [edit, setEdit] = useState(false);
    const [currentRole, setCurrentRole] = useState({ id: null, name: "" });
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();

    const columns = [
        {
            name: "Название типа",
            selector: row => row.name,
            sortable: true,
        },
        {
            name: "",
            button: true,
            cell: row => (
                <div style={{ display: "flex" }}>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggleModal(row)}>
                            <Edit size={"18px"} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteRole(row.id)}>
                            <Trash2 size={"18px"} />
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const toggleModal = (role = { id: null, name: "" }) => {
        setCurrentRole(role);
        setEdit(!!role.id);
        setModal(!modal);
    };

    const handleSave = () => {
        if (!currentRole.name) {
            toast.error("Введите наименование типа!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const headers = {
            headers: {
                //companyId: companyId,
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
            }
        };

        const role = {
            id: currentRole.id,
            name: currentRole.name,
            baseCompanyId: companyId
        };

        if (edit) {
            axios.put(`${CounterpartiesApi}updateCounterpartyRole`, role, headers)
                .then(response => {
                    setRoles(prevRoles => prevRoles.map(r => r.id === currentRole.id ? { ...r, ...currentRole } : r));
                    console.log("Updated roles:", roles); // Логируем обновленные роли
                    toast.success("Тип успешно обновлён!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                })
                .catch(error => console.error('Error updating role:', error));
        } else {
            role.id = uuid(); // Генерация нового id для новой роли
            axios.post(`${CounterpartiesApi}createCounterpartyRole`, role, headers)
                .then(response => {
                    setRoles(prevRoles => [...prevRoles, role]);
                    console.log("Added role:", role); // Логируем добавленную роль
                    toast.success("Тип успешно создан!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                })
                .catch(error => console.error('Error creating role:', error));
        }

        setModal(false);
    };

    const deleteRole = (roleId) => {
        SweetAlert.fire({
            title: "Вы уверены?",
            text: "После удаления вы не сможете восстановить этот объект!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ОК",
            cancelButtonText: "Отмена",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${CounterpartiesApi}deleteCounterpartyRole/${roleId}`, {
                    headers: {
                        companyId: companyId,
                        Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
                    }
                })
                .then(response => {
                    setRoles(prevRoles => prevRoles.filter(role => role.id !== roleId));
                    toast.success("Тип успешно удалён!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                })
                .catch(error => console.error('Error deleting role:', error));
            }
        });
    };

    const filteredItems = roles.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return <FilterComponent toggle={toggleModal} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortField="name"
                noDataComponent={"В данной таблице ещё нет записей."}
                striped
                pagination
                subHeader
                subHeaderComponent={subHeaderComponent}
                paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
            />
            <CommonModal isOpen={modal} title={edit ? "Редактировать тип" : "Добавить тип"} toggler={handleSave} togglerClose={toggleModal} closeText="Отмена" saveText="Сохранить" size="lg">
                <CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Наименование Типа</Label>
                        <Col sm="11">
                            <Input className="form-control" placeholder="Наименование типа" value={currentRole.name} onChange={(e) => setCurrentRole({ ...currentRole, name: e.target.value })} />
                        </Col>
                    </Col>
                </CardBody>
            </CommonModal>
        </>
    );
};

export default TableCounterpartiesRoles;
