import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { Breadcrumbs } from "../../../../AbstractElements";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import TableTypesDocuments from "./TableTypesDocuments";
import {useDocumentManagementContext} from "../../../../_helper/DocumentManagement/DocumentManagementProvider";

const TypesDocuments = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const { documentTypes, getDocumentTypes } = useDocumentManagementContext();

	const [data, setData] = useState([]);

	const getData = async () => {
		/*try {
			await axios
				.get(`/api/types-documents.json`)
				.then((response) => {
					setData(response.data);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		} catch (error) {}*/

	};

	useEffect(() => {
		getDocumentTypes();
		//getData();
	}, [keycloak.tokenParsed]);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Справочник типов документов" parent="Документоборот" title="Справочник типов документов" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<TableTypesDocuments products={documentTypes ?? []} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default TypesDocuments;
