import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';

import { Breadcrumbs } from '../../../../AbstractElements';
import TableResources from './TableResources';

import { DeliveryApi } from '../../../../api';

const OptimalPrice = () => {

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Настройка параметров выбора оптимальной цены доставки" parent="Доставка" title="Настройка параметров выбора оптимальной цены доставки" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<TableResources />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default OptimalPrice;
