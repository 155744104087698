import React, {useState} from "react";
import {Col, Input, Label} from "reactstrap";

export const InputEmail = {
    label: "Email",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        }
    },
    defaultProps: {
        children: "Email",
    },
    render: ({ children }) => {
        //const [isValidEmail, setIsValidEmail] = useState(true);
        //const [email, setEmail] = useState("");
        let email = "";
        let isValidEmail = true;

        const handleInputChange = (e) => {
            const inputValue = e.target.value;

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValid = emailRegex.test(inputValue);

            email = inputValue;
            isValidEmail = isValid;

            //setEmail(inputValue);
            //setIsValidEmail(isValid);
        };

        return <div>
            <Label className="col-sm-9 col-form-label">{children}</Label>
            <Col sm="12">
                <Input className="form-control" placeholder="Введите Email" value={email} onChange={handleInputChange} />
                {!isValidEmail && <div className="d-block font-danger">Пожалуйста, введите корректный email.</div>}
            </Col>
        </div>;
    },
};