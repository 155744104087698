import React, { useEffect, useState } from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import { Btn, H5, H6, LI, P } from '../../../../../AbstractElements';
import ReactApexChart from 'react-apexcharts';
import LightCardBox from './LightCardBox';

const OrderOverview = ({ item, toggleTransferFunds }) => {
	const [amounts, setAmounts] = useState([]);
	const [timestamps, setTimestamps] = useState([]);
	useEffect(() => {
		setTimestamps(item.operationsHistory.map((operation) => operation.timestamp));
		setAmounts(item.operationsHistory.map((operation) => operation.amount));
	}, []);

	const OptionsOverView = {
		series: [
			{
				name: 'Доходы',
				type: 'area',
				data: amounts,
			},
		],
		options: {
			chart: {
				height: 300,
				type: 'line',
				stacked: false,
				toolbar: {
					show: true,
				},
				dropShadow: {
					enabled: true,
					top: 2,
					left: 0,
					blur: 4,
					color: '#000',
					opacity: 0.08,
				},
			},
			stroke: {
				width: [2, 2, 2],
				curve: 'smooth',
			},
			grid: {
				show: true,
				borderColor: 'var(--chart-border)',
				strokeDashArray: 0,
				position: 'back',
				xaxis: {
					lines: {
						show: true,
					},
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
				},
			},
			plotOptions: {
				bar: {
					columnWidth: '50%',
				},
			},
			colors: ['#a7accc'],
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'light',
					type: 'vertical',
					opacityFrom: 0.4,
					opacityTo: 0,
					stops: [0, 100],
				},
			},
			labels: timestamps.map(formatDateTime),
			markers: {
				hover: {
					size: 5,
					sizeOffset: 0,
				},
			},
			xaxis: {
				type: 'category',
				tickAmount: 4,
				//tickPlacement: 'between',
				tooltip: {
					enabled: true,
					style: {
						fontFamily: 'Rubik, sans-serif',
					},
				},
				labels: {
					style: {
						fontFamily: 'Rubik, sans-serif',
					},
				},
				axisBorder: {
					color: 'var(--chart-border)',
				},
				axisTicks: {
					show: true,
				},
			},
			legend: {
				show: false,
			},
			yaxis: {
				min: 0,
				tickAmount: 6,
				tickPlacement: 'between',
				labels: {
					show: true,
					style: {
						fontFamily: 'Rubik, sans-serif',
					},
				},
			},

			responsive: [
				{
					breakpoint: 1200,
					options: {
						chart: {
							height: 250,
						},
					},
				},
			],
		},
	};

	function formatDateTime(dateTimeStr) {
		const date = new Date(dateTimeStr);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');

		return `${day}.${month} `;
	}

	function getLastThreeElements(arr) {
		const length = arr.length;

		if (length <= 3) {
			return arr.slice();
		}

		return arr.slice(length - 3, length);
	}

	return (
		<Col className="b-light b-r-8 p-25 mb-4">
			<Col xl="3" md="4" sm="5" className="mb-3">
				<Row>
					<Col md="7">
						<H5>{item.name}</H5>
						<H6>{item.description}</H6>
					</Col>
					<Col md="5">
						<H5 attrH5={{ className: 'f-w-600 text-center' }}>{item.amount} ₽</H5>
						<P attrPara={{ className: 'text-center' }}>Сейчас в кассе</P>
					</Col>
				</Row>
			</Col>
			<Col className="pt-0">
				<Row className="m-0 overall-card">
					<Col xl="3" md="4" sm="5" className="box-col-5 p-0">
						<Row className="g-sm-3 g-2">
							{getLastThreeElements(item.operationsHistory).map((data, i) => (
								<Col key={i} md="12">
									<LightCardBox data={data} />
								</Col>
							))}
							<Btn attrBtn={{ color: 'primary', className: 'pe-3 ps-3', onClick: () => toggleTransferFunds(item) }}>Перевести средства</Btn>
						</Row>
					</Col>
					<Col xl="9" md="8" sm="7" className="box-col-7 p-0">
						<div className="chart-right">
							<Col xl="12">
								<CardBody className="p-0">
									<div className="current-sale-container order-container">
										<ReactApexChart className="overview-wrapper ms-2 me-2" type="line" height={300} options={OptionsOverView.options} series={OptionsOverView.series} />
									</div>
								</CardBody>
							</Col>
						</div>
					</Col>
				</Row>
			</Col>
		</Col>
	);
};

export default OrderOverview;
