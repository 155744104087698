import React from "react";

export const BoldText = {
    label: "Жирный текст",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
        align: {
            label: "Выравнивание",
            type: "radio",
            options: [
                { label: "Слева", value: "left" },
                { label: "По центру", value: "center" },
                { label: "Справа", value: "right" },
            ],
        },
    },
    defaultProps: {
        children: "Жирный текст",
    },
    render: ({ children, align }) => {
        return <b style={{textAlign: align, display: "block" }}>{children}</b>;
    },
};