import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Label, Input, CardBody, Col } from "reactstrap";
import CommonModal from "../../../UiKits/Modals/common/modal";
import FilterComponent from "../../Categories/FilterComponent";
import { CalendarApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const TableCalendarList = () => {
	const [data, setData] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const [filteredItems, setFilteredItems] = useState([]);

	useEffect(() => {
		axios
			.get(`${CalendarApi}get-calendars`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				params: {
					creatorId: keycloak.tokenParsed.sub
				},
			})
			.then((response) => {
				setData(concatResults(response));
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);
	useEffect(() => {
		if (data) {
			if (data.calendars && Array.isArray(data.calendars)) {
			  const filtered = data.calendars.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
			  setFilteredItems(filtered);
			}
		}
	}, [data, filterText]);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [editModal, setEditModal] = useState(false);

	const [edit, setEdit] = useState(null);
	const [creatorId, setCreator] = useState("");
	const [title, setTitle] = useState("");
	const [newCalendarId, setId] = useState("");
	const [calendarTypeId, setType] = useState("");
	const [calendarCompanyId, setCompany] = useState("");

	const toggle = (calendar) => {
		if (typeof calendar === "object" && calendar.hasOwnProperty("id")) {
			setTitle(calendar.title);
			setCreator(calendar.creatorId);
			setId(calendar.id);
			setType(calendar.calendarTypeId);
			setCompany(calendar.companyId);
			setEdit(true);
		} else {
			setId("");
			setTitle("");
			setCreator("");
			setType("");
			setCompany("");
			setEdit(false);
		}
		setEditModal(!editModal);
	};

	const columns = [
		{
			name: "№",
			selector: (row) => <Link to={`/app/calendar/calendar-list/view?calendarId=${row.id}`}>{row.id}</Link>,
			sortable: true,
		},
		{
			name: "Название",
			selector: (row) => (
				<div>
					{row.title}
					<p style={{fontSize: "12px", marginTop: "5px"}} title={`Относится к компании: ${row.companyId}`}>{row.companyId}</p>
				</div>
			),
			sortable: true,
			hide: "sm",
		},
		{
			name: "Создатель",
			selector: (row) => `${row.creatorId}`,
			sortable: true,
		},
		{
			name: "Участники",
			selector: (row) => (
				<div>
					{Array.isArray(row.calendarUsers) && row.calendarUsers.length > 0 ? (
						row.calendarUsers.map((item, index) => (
							<span key={index} title={`Роли: ${item.calendarRoles.map(role => `${role.title}`).reduce((prev, curr) => [prev, ', ', curr])}`}>
								{item.userId}
							</span>
						)).reduce((prev, curr) => [prev, ', ', curr])
					) : (
						<span>Нет участников</span>
					)}
				</div>
			),
			sortable: true,
			hide: "md",
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteCalendar(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const smallColumns = [
		{
			name: "Участник",
			selector: (row) => `${row.userId}`,
			sortable: true,
			hide: "sm",
		},
		{
			name: "Роли",
			selector: (row) => `${row.roles}`,
			sortable: true,
		},
	];

	const deleteCalendar = (calendarId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				const updatedData = data.calendars.map((obj) => {
					if (obj.id === calendarId) {
						return { ...obj, deleted: true };
					}
					return obj;
				});

				axios
					.delete(`${CalendarApi}delete-calendar/${calendarId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						setData(updatedData);
						toast.success("Календарь удалён!", {
							position: toast.POSITION.TOP_RIGHT,
						});
					})
					.catch((error) => {
						console.error("Ошибка при удалении объекта", error);
					});
			}
		});
	};

	const handleEditCalendar = () => {
		let id = "";
		if (selectedUserId) {
			id = selectedUserId.id;
		} else {
			id = newCalendarId;
		}
		const newEvent = {
			id: id,
			title: title,
			creatorId: creatorId,
			calendarType: calendarTypeId,
			companyId: calendarCompanyId
		};
		axios
			.put(`${CalendarApi}update-calendar`, newEvent, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				toast.success("Календарь обновлён!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setEditModal(false);
			})
			.catch((error) => {
				console.error(error);
			});
	};
	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const selectedCalendar = filteredItems.find(calend => calend.id === newCalendarId);

	return (
		<>
			{filteredItems.length > 0 ? (
				<DataTable
					columns={columns}
					data={filteredItems}
					defaultSortField="name"
					subHeader
					subHeaderComponent={subHeaderComponent}
					noDataComponent={"В данной таблице ещё нет записей."}
					striped
					pagination
					paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
				/>
			) : (
				<p>В данной таблице ещё нет записей.</p>
			)}
			<CommonModal isOpen={editModal} title={edit ? "Редактировать" : "Добавить"} toggler={handleEditCalendar} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={title} onChange={(e) => setTitle(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Создатель</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={creatorId} onChange={(e) => setCreator(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Тип календаря</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={calendarTypeId} onChange={(e) => setType(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-11 mb-3">
						<Label className="col-sm-9 col-form-label">Участники</Label>
						{selectedCalendar ? (
							<DataTable
							noDataComponent={"В данной таблице ещё нет записей."}
							columns={smallColumns}
							data={selectedCalendar.calendarUsers.map(user => ({
								userId: user.userId,
								roles: user.calendarRoles.map(role => role.title).join(', '),
							}))}
							defaultSortField="userId"
							striped
							pagination
							paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
							/>
						) : (
							<p>В данной таблице ещё нет записей.</p>
						)}
						
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableCalendarList;
