import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import {Trash2, Edit, Settings} from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import FilterComponent from '../../FilterComponent';
import Select from 'react-select';
import {useModulesContext} from "../../../../../_helper/Modules/ModulesProvider";
import {useKeycloak} from "@react-keycloak/web";
import {Link} from "react-router-dom";

const DataTableModulesRightsAccess = () => {
    const { moduleRules, getModuleRules, getModules, modules, createModuleRule, updateModuleRule, deleteModuleRule } = useModulesContext();
    const { keycloak } = useKeycloak();

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState(moduleRules);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const [ruleId, setRuleId] = useState('')
    const [displayName, setDisplayName] = useState('');
    const [moduleId, setModuleId] = useState('');
    const [roleName, setRoleName] = useState('');
    const [modulesOptions, setModulesOptions] = useState([]);

    useEffect(() => {
        moduleRules && setFilteredItems(moduleRules.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, moduleRules, modules]);

    useEffect(() => {
        getModuleRules();
        getModules();
        //getAllCompanies();
    }, [keycloak.tokenParsed]);

    useEffect(() => {
        console.log(modules)
        modules && setModulesOptions(modules.map((item) => ({label: item.name, value: item.id})));
    }, [modules]);

    const toggle = (ruleEditId) => {
        setRuleId('');
        typeof(ruleEditId) !== 'object' && setRuleId(ruleEditId);
        setDisplayName('');
        setModuleId('');
        setRoleName('');

        setModal(!modal);
    };

    const handleAddRow = () => {
        if (moduleId !== '' && displayName !== '' && roleName !== '') {
            setModal(!modal);

            if (ruleId === '') {
                console.log(123);
                createModuleRule(moduleId, displayName, roleName);
            }
            else {
                console.log(123);
                updateModuleRule(ruleId, moduleId, displayName, roleName);
            }
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const deleteRow = (remId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'Данное действие не отменит отправку оповщений!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(() => {
            deleteModuleRule(remId);
        });
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);


    const getModuleName = (id) => {
        let moduleName = '';
        if (modules) {
            let findModuleName = modules.find((item) => item.id === id);
            if (findModuleName) {
                moduleName = findModuleName.name;
            }
        }
        return moduleName;
    };

    const tableColumns = [
        {
            name: 'Название',
            selector: (row) => `${row.displayName}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Роль',
            selector: (row) => `${row.roleName}`,
            sortable: true,
            center: false,
            width: '20%',
        },
        {
            name: 'Модуль',
            selector: (row) => `${getModuleName(row.moduleId)}`,
            sortable: true,
            center: false,
            width: '20%',
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{display: 'flex'}}>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => toggle(row.id)}>
                            <Edit size={'18px'}/>
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'}/>
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}>
                            <Link to={`${process.env.PUBLIC_URL}/administration/modules-user-groups`}>
                                <Settings size={"20px"} />
                            </Link>
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление нового права доступа к сервисам'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label">Название</Label>
                                <Col sm="11">
                                    <Input className="form-control" value={displayName} placeholder="Отображаемое название" onChange={(e) => setDisplayName(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Роль
                                </Label>
                                <Col sm="11">
                                    <Input className="form-control" value={roleName} placeholder="Название роли" onChange={(e) => setRoleName(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Модуль
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={modulesOptions} onChange={(e) => setModuleId(e.value)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTableModulesRightsAccess;
