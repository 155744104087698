import React, { Fragment, useCallback, useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { ConsecutivePagesApi } from '../../../../api';
import FilterComponent from '../../Categories/FilterComponent';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const DataTableEditMenu = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const menuId = queryParams.get('menuId');
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleDelet, setToggleDelet] = useState(false);

	const [modal, setModal] = useState(false);
	const [modalDel, setModalDel] = useState(false);

	const [PageId, setPageId] = useState('');
	const [LinkName, setLinkName] = useState('');
	const [Url, setUrl] = useState('');
	const [Order, setOrder] = useState(0);
	const [data, setData] = useState();

	useEffect(() => {
		axios
			.get(`${ConsecutivePagesApi}get-pages/${menuId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				setData(concatResults(response).pages);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const handleRowSelected = useCallback((state) => {
		setSelectedRows(state.selectedRows);
	}, []);

	const toggle = (ObjectId) => {
		console.log(ObjectId);
		if (typeof ObjectId === 'object' && ObjectId.hasOwnProperty('id')) {
			setPageId(ObjectId.id);
			setLinkName(ObjectId.linkName);
			setUrl(ObjectId.link);
			setOrder(ObjectId.order);
			setEdit(true);
		} else {
			setLinkName('');
			setUrl('');
			setOrder('');
			setEdit(false);
		}
		setModal(!modal);
	};

	const [edit, setEdit] = useState(null);

	const handleAddRow = () => {
		if(edit === false) {
			setModal(!modal);
			const newRow = {
				link: Url,
				linkName: LinkName,
				menuId: menuId,
				order: Order
			};
			axios
				.post(`${ConsecutivePagesApi}create-page`, newRow, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} } )
				.then((response) => {
					toast.success('Изменения сохранены!', {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
				.catch((error) => {
					console.error(error);
				});
		}
		else {
			setModal(!modal);
			const editRow = {
				id: PageId,
				link: Url,
				linkName: LinkName,
				menuId: menuId,
				order: Order
			};
			console.log(editRow);
			axios
				.put(`${ConsecutivePagesApi}update-page`, editRow, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} } )
				.then((response) => {
					toast.success('Изменения сохранены!', {
						position: toast.POSITION.TOP_RIGHT,
					});
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	useEffect(() => {
		data && setFilteredItems(data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
	}, [data, setData])

	const deleteRow = (menuId) => {
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then((result) => {
			axios
				.delete(`${ConsecutivePagesApi}delete-page/${menuId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
				.then(() => {
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		});
	};

	const tableColumns = [
		{
			name: 'Название раздела',
			selector: (row) => `${row.linkName}`,
			sortable: true,
			center: true,
		},
		{
			name: 'Ссылка',
			selector: (row) => `${row.link}`,
			sortable: true,
			center: false,
			width: '40%',
		},
		{
			name: 'Порядок',
			selector: (row) => `${row.order}`,
			sortable: true,
			center: false,
			width: '20%',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => toggle(row)}>
							<Edit size={'18px'} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteRow(row.id)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];
	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const [filteredItems, setFilteredItems] = useState(data);

	useEffect(() => {
		data && setFilteredItems(data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
	}, [filterText, setFilterText]);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<Fragment>
			<CommonModal isOpen={modal} title={edit ? 'Редактировать' : 'Добавить'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
				<CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
					<Form className="form theme-form">
						<Row>
							<FormGroup className="row col-sm-6" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-9 col-form-label">Название раздела</Label>
								<Col sm="11">
									<Input className="form-control" value={LinkName} type="tel" placeholder="Название раздела" onChange={(e) => setLinkName(e.target.value)} />
								</Col>
							</FormGroup>
							<FormGroup className="row col-sm-7" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
									Ссылка
								</Label>
								<Col sm="11">
									<Input className="form-control" value={Url} type="text" placeholder="Ссылка" required="true" onChange={(e) => setUrl(e.target.value)} />
								</Col>
							</FormGroup>
							<FormGroup className="row col-sm-6" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-9 col-form-label">Порядковый номер</Label>
								<Col sm="11">
									<Input className="form-control" value={Order} type="number" placeholder="Порядковый номер" onChange={(e) => setOrder(e.target.value)} />
								</Col>
							</FormGroup>
						</Row>
					</Form>
				</CardBody>
			</CommonModal>
			<DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination selectableRows onSelectedRowsChange={handleRowSelected} clearSelectedRows={toggleDelet} highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</Fragment>
	);
};

export default DataTableEditMenu;
