import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import axios from 'axios';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableTypesCertificates from './TableTypesCertificates';

const TypesCertificates = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get('/api/typesCertificates.json')
			.then((response) => {
				setFilteredProducts(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Типы сертификатов" parent="Лояльность" title="Типы сертификатов" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<TableTypesCertificates filteredProducts={filteredProducts} />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default TypesCertificates;
