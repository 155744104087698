import React from 'react';
import styled from 'styled-components';
import { Search, Plus } from 'react-feather';
import { Btn } from '../../../../AbstractElements';
import { Link } from 'react-router-dom';

const Input = styled.input.attrs((props) => ({
	type: 'text',
	size: props.small ? 5 : undefined,
}))`
	height: 32px;
	width: 46%;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 4%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--theme-default);
	border: none;
	color: var(--bs-btn-hover-color);
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
			<Link to={`/app/request/form-builder/visual-editor`}>
				<Btn attrBtn={{ color: 'primary', className: 'm-r-50' }}>
					<Plus style={{ margin: '0px 2px -7px 0px' }} /> Добавить
				</Btn>
			</Link>
			<Input style={{ flexGrow: '1' }} className="m-l-50" id="search" type="text" placeholder="Поиск.." value={filterText} onChange={onFilter} />
			<ClearButton onClick={onClear}>
				<Search color="white" size={'18px'} />
			</ClearButton>
		</div>
	</>
);

export default FilterComponent;
