import React from 'react';
import { Input } from 'reactstrap';

const DigitFilterInput = ({ value, onChange }) => {
    const handleChange = (e) => {
        const inputValue = e.target.value;
        if (/^\d*$/.test(inputValue) || inputValue === '') {
            onChange(inputValue);
        }
    };

    return (
        <Input type="text" value={value} onChange={handleChange} placeholder="Только цифры" />
    );
};

export default DigitFilterInput;
