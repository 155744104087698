import React, { Fragment, useCallback, useState } from "react";
import EmojiPicker from "emoji-picker-react";
import { useKeycloak } from "@react-keycloak/web";
import { Col, Card, CardBody, Input, CardHeader, Collapse } from "reactstrap";
import { H6, Btn, Image, LI, UL } from "../../../../AbstractElements";
import { P } from "../../../../AbstractElements";
import { UserProfileDesc4 } from "../../../../Constant";
import UserFooter from "./Common/UserFooter";
import UserTitle from "./Common/UserTitle";
import { Search } from "react-feather";
import { useNewsContext } from "../../../../_helper/News/NewsProvider";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const AddSearchNews = ({ userId, searchTerm, handleSearch }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);
	const [messageInput, setMessageInput] = useState("");
	const { createNews } = useNewsContext();
	const toggleEmojiPicker = () => {
		setShowEmojiPicker(!showEmojiPicker);
	};

	const addEmoji = (emoji) => {
		const text = `${messageInput}${emoji.emoji}`;
		setShowEmojiPicker(false);
		setMessageInput(text);
	};

	const [isOpen, setIsOpen] = useState(null);

	const toggleClientCard = (item) => {
		if (isOpen === item) {
			setIsOpen(null);
		} else {
			setIsOpen(item);
		}
	};

	const sendCreate = useCallback(() => {
		console.log(1);
		createNews(userId, messageInput, "963e67dc-e5e6-4e43-b8d2-7be3767e7a04");
	}, [userId]);

	return (
		<Fragment>
			<Col sm="12" className="p-relative">
				{userId === keycloak.tokenParsed?.sub ? (
					<>
						<div style={{ width: "350px", zIndex: "2", right: "15px", top: "60px" }} className="p-absolute f-right">
							{showEmojiPicker ? <EmojiPicker emojiStyle="native" width={350} onEmojiClick={addEmoji} /> : null}
						</div>
						<Card>
							<CardHeader
								className="p-relative"
								onClick={() => toggleClientCard(1)}
								style={{
									cursor: "pointer",
									borderRadius: isOpen !== 1 ? "15px" : "",
									padding: "16px 30px",
								}}
							>
								<Col className="d-flex user-profile">
									<div className="profile-img-style p-0">
										<div className="media">
											<Image attrImage={{ className: "img-thumbnail rounded-circle me-3", alt: "", src: `https://dummyimage.com/800x800` }} />
										</div>
									</div>
									<textarea
										rows={"1"}
										style={{ border: "none", paddingTop: "0.75rem", resize: "none", overflow: "hidden" }}
										placeholder="Что у вас нового?"
										className="form-control"
										value={messageInput}
										onChange={(e) => setMessageInput(e.target.value)}
									/>
									<div className="smiley-box d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={toggleEmojiPicker}>
										<i className="fa fa-smile-o f-24" style={{ color: "#898989" }}></i>
									</div>
								</Col>
							</CardHeader>
							<Collapse isOpen={isOpen === 1 || messageInput != ""}>
								<CardBody style={{ padding: "16px 30px" }}>
									<div className="modal-footer">
										<Btn attrBtn={{ color: "primary", onClick: () => sendCreate()}}>Опубликовать</Btn>
									</div>
								</CardBody>
							</Collapse>
						</Card>
					</>
				) : (
					<Card>
						<CardBody>
							<Col className="d-flex" style={{ alignItems: "center" }}>
								<Search className="me-3" style={{ color: "#898989" }} />
								<Input style={{ border: "none" }} placeholder="Введите фразу или слово" className="form-control p-0" value={searchTerm} onChange={handleSearch} />
							</Col>
						</CardBody>
					</Card>
				)}
			</Col>
		</Fragment>
	);
};
export default AddSearchNews;
