import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit } from "react-feather";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import uuid from "react-uuid";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import Select from "react-select";
import { Label, Input, CardBody, Col } from "reactstrap";

import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";

import { NewsApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const TableNews = () => {
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get("/api/Categories.json")
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.longtitle,
					value: option.id,
				}));
				setCategories(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get(`${NewsApi}getNews`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setFilteredProducts(concatResults(response).news);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const columns = [
		{
			name: "Контент",
			selector: (row) => <div dangerouslySetInnerHTML={{ __html: row.content }} />,
			sortable: true,
		},
		{
			name: "Дата",
			selector: (row) => `${row.date}`,
			sortable: true,
		},
		{
			name: "Категория",
			selector: (row) => `${row.category}`,
			sortable: true,
		},
		{
			name: "E-mail",
			selector: (row) => `${row.author}`,
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteNews(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const [author, setAuthor] = useState("");
	const [Id, setId] = useState("");

	const createNews = () => {
		if (editorData == "" || author == "") {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			var id = uuid();
			const news = {
				Id: id,
				Author: author,
				Date: new Date(),
				Content: editorData,
				Category: selectedCategory.length === 0 ? "" : selectedCategory,
			};
			axios
				.post(`${NewsApi}createNews`, news, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					console.log(concatResults(response));
					toast.success("Новость добавлена!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
	};

	const editNews = () => {
		if (editorData == "" || author == "") {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const news = {
				Id: Id,
				Author: author,
				Date: new Date(),
				Content: editorData,
				Category: selectedCategory.length === 0 ? "" : selectedCategory,
			};
			axios
				.put(`${NewsApi}updateNews`, news, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					console.log(concatResults(response));
					toast.success("Новость изменена!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
	};

	const [modal, setModal] = useState(false);

	const toggle = (ObjectId) => {
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setAuthor(ObjectId.author);
			setId(ObjectId.id);
			setEditorData(ObjectId.content);
			setEdit(true);
		} else {
			setAuthor("");
			setId("");
			setEditorData("");
			setEdit(false);
		}
		setModal(!modal);
	};

	const [edit, setEdit] = useState(null);

	const deleteNews = (idNews) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${NewsApi}deleteNews`, {
						data: {
							Id: idNews,
						},
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
					})
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении:", error);
					});
			}
		});
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const [editorData, setEditorData] = useState("");

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		console.log(data);
		setEditorData(data);
	};
	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={modal} title={edit ? "Редактировать" : "Добавить"} toggler={edit ? editNews : createNews} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Категория</Label>
						<Col sm="11">
							<Select
								placeholder="Выберите..."
								value={selectedCategory}
								options={categories}
								className="js-example-basic-single col-sm-12"
								onChange={(selected) => setSelectedCategory(selected)}
							/>
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Пользователь</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={author} onChange={(e) => setAuthor(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Контент</Label>
						<Col sm="11">{/* <CKEditor editor={ClassicEditor} data={editorData} onChange={handleEditorChange} /> */}</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableNews;
