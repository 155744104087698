import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { Breadcrumbs, Badges, H5, Btn } from "../../../../AbstractElements";
import { Label, Col, Row } from "reactstrap";
import TableCatalog from "./TableCatalog";
import { ProductsApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const PurchaseHistory = () => {
	const [data, setData] = useState([]);
	const [filteredPurchases, setFilteredPurchases] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${ProductsApi}getPurchaseHistory`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				params: {
					query: "",
				},
			})
			.then((response) => {
				//console.log(response);
				setData(concatResults(response).purchaseHistories.sort((a, b) => new Date(b.date) - new Date(a.date)));
				setFilteredPurchases(concatResults(response).purchaseHistories.sort((a, b) => new Date(b.date) - new Date(a.date)));
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());

	const handleFilter = () => {
		const filtered = data.filter((purchase) => {
			const purchaseDate = new Date(purchase.date);
			return purchaseDate >= new Date(periodStart) && purchaseDate <= new Date(periodEnd);
		});
		setFilteredPurchases(filtered);
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="История покупок" parent="Товары" title="История покупок" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardHeader>
							<Row className="m-l-5" style={{ justifyContent: "center" }}>
								<Col xl="2" sm="9">
									<Label className="m-r-10 f-16 mt-2">Выберите период:</Label>
								</Col>
								<Col xl="3" sm="9">
									<div style={{ display: "flex", alignItems: "flex-end" }}>
										<Label className="m-r-10 f-16">С</Label>
										<div className="input-group">
											<DatePicker
												locale={ru}
												dateFormat="dd.MM.yyyy"
												className="form-control digits"
												selected={periodStart}
												onChange={(selectedOption) => setPeriodStart(selectedOption)}
											/>
										</div>
									</div>
								</Col>
								<Col xl="3" sm="9">
									<div style={{ display: "flex", alignItems: "flex-end" }}>
										<Label className="m-r-10 f-16">По</Label>
										<div className="input-group">
											<DatePicker
												locale={ru}
												dateFormat="dd.MM.yyyy"
												className="form-control digits"
												selected={periodEnd}
												onChange={(selectedOption) => setPeriodEnd(selectedOption)}
											/>
										</div>
									</div>
								</Col>
								<Col xl="1" sm="9">
									<Btn attrBtn={{ color: "primary", onClick: handleFilter }}>Показать</Btn>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<Col style={{ padding: "4px 16px 4px 24px" }}></Col>
							{data && <TableCatalog products={filteredPurchases} />}
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default PurchaseHistory;
