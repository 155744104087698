import React from "react";
import { ButtonGroup } from "reactstrap";
import { Clipboard, Play, FileText, ShoppingBag } from "react-feather";
import Tooltip from "@mui/material/Tooltip";

import { Btn } from "../../../../../AbstractElements";

const tabs = ["HTML", "CSS"];

export const Tabs = ({ mode, setMode, runCode, paste = undefined, toggle, toggleForm, toggleCover, toggleNews }) => {
	const changeMode = ({ target: { textContent } }) => {
		setMode(textContent.toLowerCase());
	};

	return (
		<div className="tabs mb-2 d-flex" style={{ justifyContent: "space-between" }}>
			<ButtonGroup>
				{tabs.map((item, i) => (
					<Btn
						attrBtn={{
							color: "light",
							active: item.toLowerCase() === mode,
							onClick: changeMode,
							key: i,
						}}
					>
						{item}
					</Btn>
				))}
			</ButtonGroup>
			<Btn attrBtn={{ color: "success", onClick: runCode }}>
				<Play size={"18px"} style={{ marginBottom: "-3px" }} /> Применить
			</Btn>
		</div>
	);
};
