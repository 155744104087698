import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import { Breadcrumbs } from '../../../AbstractElements';
import TableStatusHistory from './TableStatusHistory';

const StatusHistory = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const applicationId = queryParams.get('applicationId'); // id заявки

	const [data, setData] = useState([]);

	useEffect(() => {
		axios
			.get('/api/statusHistory.json')
			.then((response) => {
				setData(response.data.history);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle={`История статусов заказа № ${applicationId}`} parent="Редактировать событие" title="История статусов заказа" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<TableStatusHistory products={data} />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default StatusHistory;
