import React, { useMemo, useState, useContext, useEffect } from "react";
import { Trash2, Edit, ExternalLink, Settings } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Label, Input, CardBody, Col } from "reactstrap";
import FilterComponent from "./FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { EducationApi, TwinAuthApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import CourseContext from "../../../../_helper/Course";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const TableCourses = ({ courses }) => {
	const [modal, setModal] = useState(false);
	const [data, setData] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { courseData, getCourseData } = useContext(CourseContext);
	const [columns, setCloumns] = useState([]);

	const toggle = (ObjectId) => {
		console.log(ObjectId);
		if (typeof ObjectId === "object" && ObjectId.hasOwnProperty("Id")) {
			setTitle(ObjectId.name);
			setDescripton(ObjectId.description);
			setLink(ObjectId.helloLink);
			setId(ObjectId.id);
			setEdit(true);
		} else {
			setTitle("");
			setDescripton("");
			setLink("");
			setId("");
			setEdit(false);
		}
		setModal(!modal);
	};

	useEffect(() => {
		if (!courseData) {
			getCourseData();
		}
	}, [keycloak.tokenParsed]);

	const [edit, setEdit] = useState(null);

	useEffect(() => {
		setCloumns([
			{
				name: "Название",
				selector: (row) => (
					<Tooltip title="Просмотр курса" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/app/view-course?courseId=${row.id}`}>{row.name}</Link>
					</Tooltip>
				),
				sortable: true,
				hide: "sm",
				width: "25%",
			},
			{
				name: "Описание",
				selector: (row) => `${row.description}`,
				sortable: true,
				width: "40%",
			},
			{
				name: "Главная страница",
				selector: (row) => (
					<Link to={`${process.env.PUBLIC_URL}${row.helloLink}`}>
						Перейти на страницу курса
						<ExternalLink size={"18px"} style={{ marginLeft: "7px", cursor: "pointer" }} />
					</Link>
				),
				sortable: false,
				width: "27%",
			},
			{
				name: "",
				button: true,
				selector: (row) => (
					<div style={{ display: "flex" }}>
						<Tooltip title="Перейти в конструктор курса" arrow placement="top">
							<Link to={`${process.env.PUBLIC_URL}/app/build-course?courseId=${row.id}`}>
								<Settings size={"18px"} style={{ marginLeft: "10px", cursor: "pointer" }} />
							</Link>
						</Tooltip>
						{/* <Tooltip title="Редактировать" arrow placement="top">
							<div className="font-primary" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => toggle(row)}>
								<Edit size={'18px'} />
							</div>
						</Tooltip> */}
						<Tooltip title="Удалить" arrow placement="top">
							<div className="font-primary" style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => deleteRow(row.id)}>
								<Trash2 size={"18px"} />
							</div>
						</Tooltip>
					</div>
				),
				width: "8%",
			},
		]);
	}, [courseData]);

	const [title, setTitle] = useState("");
	const [description, setDescripton] = useState("");
	const [link, setLink] = useState("");

	const [Id, setId] = useState("");

	const sendCreateRequest = () => {
		if (link == "" || description == "" || title == "") {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const newCourse = {
				name: title,
				description: description,
				helloLink: link,
			};
			axios
				.post(`${EducationApi}create-course`, newCourse, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					console.log(concatResults(response));
					toast.success("Курс добавлен!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
					courses.push({
						id: concatResults(response),
						name: title,
						description: description,
						helloLink: link,
					});
					setData(courses);
				})
				.catch((error) => {
					console.error(error);
				});

			axios
				.post(`${TwinAuthApi}create-course-group?courseName=${title}&creatorId=${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then(() => {})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const deleteRow = (courseId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then(function (isConfirm) {
			if (isConfirm) {
				axios
					.get(`${EducationApi}get-course/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						console.log(response);
						axios
							.delete(`${TwinAuthApi}delete-group?groupName=${concatResults(response).name}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
							.then(() => {})
							.catch((error) => {
								toast.error("Ошибка при удалении группы курса.", {
									position: toast.POSITION.TOP_RIGHT,
								});
								console.error(error);
							});
					})
					.catch((error) => {
						console.error(error);
					});

				axios
					.delete(`${EducationApi}delete-course/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						toast.success("Курс удалён!", {
							position: toast.POSITION.TOP_RIGHT,
						});

						let objWithIdIndex = courses.findIndex((obj) => obj.id === courseId);
						if (objWithIdIndex > -1) {
							courses = courses.splice(objWithIdIndex, 1);
							setData(courses);
						}
					})
					.catch((error) => {
						toast.error("Ошибка при удалении курса.", {
							position: toast.POSITION.TOP_RIGHT,
						});
						console.error(error);
					});
			}
		});
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	if (data !== courses) {
		setData(courses);
	}

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				noDataComponent={"В данной таблице ещё нет записей."}
				defaultSortField="name"
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={modal} title={edit ? "Редактировать" : "Добавить"} toggler={sendCreateRequest} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Название</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={title} onChange={(e) => setTitle(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Описание</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={description} onChange={(e) => setDescripton(e.target.value)} />
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Главная страница</Label>
						<Col sm="11">
							<Input className="form-control" type="tel" value={link} onChange={(e) => setLink(e.target.value)} />
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
		</>
	);
};

export default TableCourses;
