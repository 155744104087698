import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import axios from "axios";
import { Btn, H1, H2, H3, H4, H5, P } from "../../../AbstractElements";

import Img1 from "../../../assets/images/project-management-system/bg_1.jpg";

import Img2 from "../../../assets/images/project-management-system/1.png";
import Img3 from "../../../assets/images/project-management-system/2.png";
import Img4 from "../../../assets/images/project-management-system/3.png";

import Icon1 from "../../../assets/images/project-management-system/wall-clock.png";
import Icon2 from "../../../assets/images/project-management-system/briefcase.png";
import Icon3 from "../../../assets/images/project-management-system/settings.png";
import Form from "../Form";
import NavMenu from "../NavMenu";

const ProjectManagement = () => {
	return (
		<Fragment>
			<Container fluid={true} className="g-0">
				<NavMenu />
				<Row style={{ height: "80vh", paddingLeft: "20vh", paddingRight: "20vh" }}>
					<Col md="5" sm="12" style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingRight: "2rem" }}>
						<H1 attrH1={{ className: "f-w-600 mb-4" }}>Система управления проектами</H1>
						<p className="f-18">Новый инструмент по проектному менеджменту помогает в планировании, коммуникации и управлении временем </p>
					</Col>
					<Col md="7" sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
						<img src={Img1} alt="Image" style={{ maxHeight: "800px" }} />
					</Col>
				</Row>
				<Row style={{ height: "50vh", paddingLeft: "20vh", paddingRight: "20vh" }}>
					<Col md="7" sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center" }}>
						<img src={Img2} alt="Image" style={{ maxHeight: "400px" }} />
					</Col>
					<Col md="5" sm="12" style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingRight: "2rem" }}>
						<H3 attrH3={{ className: "f-w-600 mb-4" }}>Управление задачами</H3>
						<p className="f-18">
							Эффективно планируйте, отслеживайте и управляйте всеми аспектами вашего проекта. Наш сервис управления проектами и задачами обеспечивает прозрачность, организацию и
							синхронизацию ваших действий, помогая вам достигать целей быстрее и эффективнее.{" "}
						</p>
					</Col>
				</Row>
				<Row style={{ height: "50vh", paddingLeft: "20vh", paddingRight: "20vh" }}>
					<Col md="5" sm="12" style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingRight: "2rem" }}>
						<H3 attrH3={{ className: "f-w-600 mb-4" }}>Управление временем</H3>
						<p className="f-18">
							Следите за задачами, над которыми вы ежедневно работаете и анализируйте, сколько времени вы потратили на каждый проект. Вы можете использовать автоматический таймер или
							управлять им вручную.
						</p>
					</Col>
					<Col md="7" sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "end", justifyContent: "center" }}>
						<img src={Img3} alt="Image" style={{ maxHeight: "400px" }} />
					</Col>
				</Row>
				<Row style={{ height: "50vh", paddingLeft: "20vh", paddingRight: "20vh" }}>
					<Col md="7" sm="12" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center" }}>
						<img src={Img4} alt="Image" style={{ maxHeight: "400px" }} />
					</Col>
					<Col md="5" sm="12" style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingRight: "2rem" }}>
						<H3 attrH3={{ className: "f-w-600 mb-4" }}>Коммуникация команды</H3>
						<p className="f-18">
							Интегрируйте свою электронную почту непосредственно в нашу систему управления. Получайте, отправляйте и организуйте свою рабочую переписку, не покидая нашего приложения.
						</p>
					</Col>
				</Row>
				<Row style={{ backgroundColor: "#f3f3f3" }}>
					<Col sm="12" className="" style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "16vh" }}>
						<H1 attrH1={{ className: "f-w-600 mb-3" }}>Мы поможем вам работать более эффективно</H1>
						<P attrPara={{ className: "f-18" }}>Мы предлагаем вашей команде полный сет инструментов для создания лучших проектных решений</P>
						<div style={{ display: "flex", margin: "4rem 12rem" }}>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "0 2rem" }}>
								<Card>
									<CardBody style={{ alignItems: "start", display: "flex", flexDirection: "column" }}>
										<img src={Icon1} alt="icon1" style={{ width: "60px" }} />
										<H5 attrH5={{ className: "f-w-600 mb-3 mt-4" }}>Эффективное планирование и отслеживание</H5>
										<p className="f-16" style={{ lineHeight: "2" }}>
											Наш сервис управления проектами позволяет создавать задачи, устанавливать сроки, определять приоритеты и отслеживать прогресс в реальном времени, что
											помогает сохранить контроль над проектом.
										</p>
									</CardBody>
								</Card>
							</Col>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "0 2rem" }}>
								<Card>
									<CardBody style={{ alignItems: "start", display: "flex", flexDirection: "column" }}>
										<img src={Icon2} alt="icon2" style={{ width: "60px" }} />
										<H5 attrH5={{ className: "f-w-600 mb-3 mt-4" }}>Поддержка роста бизнеса</H5>
										<p className="f-16" style={{ lineHeight: "2" }}>
											Наше решение масштабируется вместе с вашим бизнесом, позволяя добавлять новых пользователей, расширять функционал и адаптировать систему под изменяющиеся
											потребности компании.
										</p>
									</CardBody>
								</Card>
							</Col>
							<Col md="4" sm="6" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "0 2rem" }}>
								<Card>
									<CardBody style={{ alignItems: "start", display: "flex", flexDirection: "column" }}>
										<img src={Icon3} alt="icon3" style={{ width: "60px" }} />
										<H5 attrH5={{ className: "f-w-600 mb-3 mt-4" }}>Централизованное управление</H5>
										<p className="f-16" style={{ lineHeight: "2" }}>
											Наше решение объединяет все необходимые инструменты для управления проектами в одной системе, что обеспечивает централизованное управление и легкий доступ
											ко всей информации.
										</p>
									</CardBody>
								</Card>
							</Col>
						</div>
					</Col>
				</Row>
				<Form color={"#ffffff"} />
			</Container>
		</Fragment>
	);
};
export default ProjectManagement;
