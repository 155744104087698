import React from "react";
import styles from "./task-list-header.module.css";

const TaskListHeader = ({ headerHeight = 100, fontFamily = "Iter", fontSize = "14px", rowWidth = 10 }) => {
	return (
		<div
			className={styles.ganttTable}
			style={{
				fontFamily: fontFamily,
				fontSize: fontSize,
			}}
		>
			<div
				className={styles.ganttTable_Header}
				style={{
					height: headerHeight - 2,
				}}
			>
				<div
					className={styles.ganttTable_HeaderItem}
					style={{
						minWidth: "170px",
					}}
				>
					<div className="ms-3">Имя</div>
				</div>
				<div
					className={styles.ganttTable_HeaderSeparator}
					style={{
						height: headerHeight * 0.5,
						marginTop: headerHeight * 0.2,
					}}
				/>
				<div
					className={styles.ganttTable_HeaderItem}
					style={{
						minWidth: "130px",
					}}
				>
					<div className="ms-3">Начало</div>
				</div>
				<div
					className={styles.ganttTable_HeaderSeparator}
					style={{
						height: headerHeight * 0.5,
						marginTop: headerHeight * 0.25,
					}}
				/>
				<div
					className={styles.ganttTable_HeaderItem}
					style={{
						minWidth: "130px",
					}}
				>
					<div className="ms-3">Конец</div>
				</div>
			</div>
		</div>
	);
};

export default TaskListHeader;
