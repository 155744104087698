import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Container } from "reactstrap";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";
import { Breadcrumbs, H5 } from "../../../../AbstractElements";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import TableRightsAccess from "./TableRightsAccess";
import {useDocumentManagementContext} from "../../../../_helper/DocumentManagement/DocumentManagementProvider";

const RightsAccess = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { companyRightAccess, getDocumentRightAccess } = useDocumentManagementContext();

	const [data, setData] = useState([]);

	const getData = async () => {
		/*try {
			await axios
				.get(`/api/documents-rights-access.json`)
				.then((response) => {
					setData(response.data);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		} catch (error) {}*/
	};

	useEffect(() => {
		companyData && getDocumentRightAccess(companyData[0]?.id);
		if (!companyData) {

		}
		//getData();
	}, [companyData]);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Права и доступ к модулю" parent="Документоборот" title="Права и доступ к модулю" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<TableRightsAccess products={companyRightAccess ?? []} setProducts={setData} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default RightsAccess;
