import React, { Fragment, useCallback, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import { Btn, P } from "../../../AbstractElements";
import { Trash2, Edit } from "react-feather";
import SweetAlert from "sweetalert2";

const DataTableDiagramList = () => {
	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleDelet, setToggleDelet] = useState(false);

	const [data, setData] = useState([]);

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить эту диаграму",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`http://localhost:8000/BpDiagram/${userId}`)
					.then((response) => {
						console.log(response.data);
						SweetAlert.fire("Удалено!", "Диаграма была удалена.", "success");
						// Обновить список после удаления
						axios
							.get("http://localhost:8000/BpDiagram")
							.then((response) => {
								setData(response.data);
							})
							.catch((error) => {
								console.error("Произошла ошибка при обновлении списка:", error);
							});
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении диаграмы:", error);
					});
			}
		});
	};

	const tableColumns = [
		{
			name: "Название диаграммы",
			selector: (row) => `${row.name}`,
			sortable: true,
			center: false,
			width: "70%",
		},
		{
			name: "",
			selector: (row) => (
				<div className="btn-showcase mb-0">
					<Link to={`/app/business-processes/create-diagram?BPid=${row.id}`} style={{ color: "rgb(47, 47, 59)" }}>
						<Btn attrBtn={{ color: "primary", outline: true, size: "sm" }}>
							<Edit size={"14px"} style={{ marginRight: "5px" }} />
							Редактировать
						</Btn>
					</Link>
					<Btn attrBtn={{ color: "secondary", outline: true, onClick: () => deleteTask(row.id), size: "sm" }}>
						<Trash2 size={"14px"} style={{ marginRight: "5px" }} />
						Удалить
					</Btn>
				</div>
			),
			sortable: false,
			center: true,
		},
	];

	useEffect(() => {
		axios
			.get("/api/BpDiagram.json")
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error("Произошла ошибка:", error);
			});
	}, []);

	const handleRowSelected = useCallback((state) => {
		setSelectedRows(state.selectedRows);
	}, []);

	return (
		<Fragment>
			<DataTable
				data={data}
				columns={tableColumns}
				striped={true}
				center={true}
				pagination
				onSelectedRowsChange={handleRowSelected}
				clearSelectedRows={toggleDelet}
				pointerOnHover
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
				noDataComponent={"В данной таблице ещё нет записей."}
			/>
		</Fragment>
	);
};

export default DataTableDiagramList;
