import React from "react";
import {Col, Label} from "reactstrap";
import InputMask from "react-input-mask";

export const InputPhone = {
    label: "Телефон",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
    },
    defaultProps: {
        children: "Телефон",
    },
    render: ({ children }) => {
        return <div>
            <Label className="col-sm-9 col-form-label">{children}</Label>
            <Col sm="12">
                <InputMask className="form-control" mask="+7 (999) 999-99-99" maskChar="_" placeholder="+7 (___) ___-__-__" alwaysShowMask />
            </Col>
        </div>;
    },
};