import React, { Fragment } from "react";
import { CardHeader } from "reactstrap";
import { Card, Col, Container, Row } from "reactstrap";
import AnimatedCheckboxButtonsClass from "./AnimatedCheckboxButtons";

const SelectModules = () => {
	return (
		<Fragment>
			<Container fluid={true} style={{ paddingTop: "50px" }}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h4 style={{ textAlign: "center" }}>Выберите модули</h4>
							</CardHeader>
							<div className="mb-2" style={{ padding: "50px 50px 30px" }}>
								<AnimatedCheckboxButtonsClass />
							</div>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default SelectModules;
