import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import axios from 'axios';
import Select from 'react-select';
import { Breadcrumbs, Badges, H5, Btn } from '../../../../AbstractElements';
import { Label, Col, Row } from 'reactstrap';
import TableTechnologicalCard from './TableTechnologicalCard';

const TechnologicalCard = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get('/api/technologicalCardList.json')
			.then((response) => {
				setFilteredProducts(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Технологические карты" parent="Склад" title="Технологические карты" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<TableTechnologicalCard filteredProducts={filteredProducts} />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default TechnologicalCard;
