import React, { Fragment } from "react";
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import { Breadcrumbs } from "../../../../../AbstractElements";
import DataTableModulesRightsAccess from "./DataTableModulesRightsAccess";

const ModulesRightsAccess = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Установка прав доступа к разделам модулей" parent="Администрирование " title="Установка прав доступа к разделам модулей" />
            <Container fluid className="user-card">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <DataTableModulesRightsAccess />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default ModulesRightsAccess;