import React, { Fragment, useCallback, useState, useEffect } from "react";
import { Col, Card, CardBody, Nav, NavItem } from "reactstrap";
import axios from "axios";
import { PlusCircle } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import SweetAlert from "sweetalert2";
import { Link } from "react-router-dom";
import { CheckCircle } from "react-feather";
import { Trash2 } from "react-feather";
import CreateTag from "./CreateTag";
import { Btn } from "../../../AbstractElements";
import { useKeycloak } from "@react-keycloak/web";

import { TaskApi, TwinAuthApi } from "../../../api";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../concatResults";

const NavClass = ({ activeToggle }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	let currentUserId = `${keycloak.tokenParsed.sub}`;
	const [activeTab, setActiveTab] = useState("1");
	const [tagModal, setTagModal] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const tagToggle = () => setTagModal(!tagModal);

	const tagCallback = useCallback((modal) => {
		setTagModal(modal);
	});

	const [statuses, setStatuses] = useState([]);

	const fetchData = async () => {
		try {
			const response = await axios.get(`${TaskApi}get-user-statuses`, {
				params: { UserId: currentUserId },
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			setStatuses(concatResults(response).statuses);
		} catch (error) {
			console.error("Ошибка получения данных:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const deleteTask = (statusId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот статус!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${TaskApi}deleteStatus/${statusId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						//console.log(concatResults(response));
						SweetAlert.fire("Удалено!", "Статус был удален", "success");
						fetchData();
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении задачи:", error);
					});
			}
		});
	};

	return (
		<Fragment>
			<Col xl="3" className="box-col-3">
				<div className="md-sidebar">
					<div className={`md-sidebar-aside job-left-aside custom-scrollbar ${isOpen && "open"}`}>
						<div className="email-sidebar">
							<div className={`email-left-aside`}>
								<Card>
									<CardBody>
										<div className="email-app-sidebar left-bookmark">
											<Nav className="main-menu" role="tablist">
												<NavItem>
													<Link to={`${process.env.PUBLIC_URL}/app/task/create-task`} className="p-0" style={{ display: "block" }}>
														<button className="badge-light-primary d-block w-100 btn-mail">
															<CheckCircle className="me-2" />
															Новая задача
														</button>
													</Link>
												</NavItem>
												{/* <NavItem>
													<span className="main-title">Задачи</span>
												</NavItem>
												{taskData.map((item) => (
													<NavItem key={item.id}>
														<a
															href="#javaScript"
															className={activeTab === item.activeTab ? 'active' : ''}
															onClick={() => {
																setActiveTab(item.activeTab);
																activeToggle(item.activeTab);
															}}
														>
															<span className="title"> {item.title}</span>
														</a>
													</NavItem>
												))} */}
												{/* <li>
													<hr />
												</li> */}
												<NavItem>
													<span className="main-title">
														Статусы
														<Tooltip title="Создать статус" arrow placement="top">
															<span className="pull-right" onClick={tagToggle} style={{ cursor: "pointer" }}>
																<PlusCircle />
															</span>
														</Tooltip>
													</span>
												</NavItem>
												<CreateTag tagCallback={tagCallback} tagModal={tagModal} setStatuses={setStatuses} />
												{statuses && statuses.length > 0 ? (
													statuses.map((item) => (
														<Fragment key={item.id}>
															<NavItem>
																<a className="p-r-10">
																	<span className="title"> {item.name}</span>
																	<Tooltip title="Удалить статус" arrow placement="top">
																		<Trash2 className="font-primary" style={{ cursor: "pointer" }} size={"18px"} onClick={() => deleteTask(item.id)} />
																	</Tooltip>
																</a>
															</NavItem>
														</Fragment>
													))
												) : (
													<p>Нет доступных статусов.</p>
												)}
											</Nav>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</Col>
		</Fragment>
	);
};

export default NavClass;
