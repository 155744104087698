import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { useKeycloak } from "@react-keycloak/web";
import CMSTemplatesContext from "../../../../../_helper/CMSTemplates";
import DemandsContext from "../../../../../_helper/Demands";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import { useDemandsContext } from "../../../../../_helper/Demands/DemandsProvider";


const ViewPage = ({ content }) => {
	const { allForms, allNews, getAllCmsSelects } = useContext(CMSTemplatesContext);
	const { demands, getDemands } = useDemandsContext();

	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [roles, setRoles] = useState(
		keycloak.tokenParsed ? keycloak.tokenParsed.realm_access?.roles : []
	);
	const [formContent, setFormContent] = useState(null);
	const [formId, setFormId] = useState(null);
	const tempElementRef = useRef(document.createElement("div"));

	const [newsContent, setNewsContent] = useState(null);
	const [newsId, setNewsId] = useState(null);

	const [requestId, setRequestId] = useState(null);
	const [crmIds, setCrmIds] = useState(null);
	const [crmContents, setCrmContents] = useState(null);

	const tempElement = tempElementRef.current;
	tempElement.innerHTML = content;

	useEffect(() => {
		//!allDemands && getAllDemands();
		!demands && getDemands();
		!allForms && getAllCmsSelects();
	}, [companyData]);

	useEffect(() => {
		if (roles.includes('demands-api')) {
			!demands && getDemands();
		}
	}, [roles]);


	useEffect(() => {
		const applicationFormElement = tempElement.querySelector("applicationForm");
		if (applicationFormElement) {
			const formId = applicationFormElement.getAttribute("formId");
			setFormId(formId);
		}

		const newsElement = tempElement.querySelector("newsTemplate");
		if (newsElement) {
			const newsId = newsElement.getAttribute("templateId");
			setNewsId(newsId);
		}
	}, [content]);

	useEffect(() => {
		const crmElement = tempElement.querySelector("CRM");
		if (crmElement) {
			const requestId = crmElement.getAttribute("request");
			// queryParams.append(`requestId`, requestId);
			// navigate(`?${queryParams.toString()}`);
			setRequestId(requestId);
			const ids = crmElement.outerHTML.match(/(?<=id=")(.*?)(?=")/g) || [];
			setCrmIds(ids);
		}
	}, []);

	useEffect(() => {
		if (formId) {
			const foundItem = allForms.find((item) => item.id === formId);
			if (foundItem) setFormContent(foundItem.content);
		}
		if (newsId) {
			const foundItem = allNews.find((item) => item.id === newsId);
			if (foundItem) setNewsContent(foundItem.content);
		}
		if (crmIds && requestId) {
			const foundItem = demands.find((item) => item.id === requestId);
			if (foundItem) {
				const transformedData = crmIds.map(
					(id) =>
						`<div key="${id}" id="${id}">
				  ${foundItem[id.replace("demands-", "")]}
				</div>`
				);
				const htmlString = transformedData.join("");
				setCrmContents(htmlString);
			}
		}
	}, [formId, newsId, crmIds, requestId, keycloak.token]);

	const parseMarkup = () => {
		const applicationFormElement = tempElement.querySelector("applicationForm");
		if (applicationFormElement && formContent) {
			applicationFormElement.innerHTML = formContent;
		}

		const newsElement = tempElement.querySelector("newsTemplate");
		if (newsElement && newsContent) {
			newsElement.innerHTML = newsContent;
		}

		const crmElement = tempElement.querySelector("CRM");
		if (crmElement && crmContents) {
			crmElement.innerHTML = crmContents;
		}

		return tempElement.innerHTML;
	};

	useEffect(() => {
		if (formContent) {
			document.getElementById("myForm").addEventListener("submit", function (event) {
				event.preventDefault(); // Prevent default form submission

				var formData = new FormData(this); // Get form data
				console.log(formData);

				var formObject = {};
				formData.forEach(function (value, key) {
					formObject[key] = value;
				});

				var jsonData = JSON.stringify(formObject);
				console.log(formObject);

				//отправка данных формы

				// fetch("/your-server-endpoint", {
				// 	method: "POST",
				// 	headers: {
				// 		"Content-Type": "application/json",
				// 	},
				// 	body: jsonData,
				// })
				// 	.then((response) => response.json())
				// 	.then((data) => {
				// 		console.log("Success:", data);
				// 		// Do something with the response from the server
				// 	})
				// 	.catch((error) => {
				// 		console.error("Error:", error);
				// 		// Handle any errors that occur during the fetch
				// 	});
			});
		}
	}, [formContent]);

	return (
		<Fragment>
			<div dangerouslySetInnerHTML={{ __html: parseMarkup() }} />
		</Fragment>
	);
};

export default ViewPage;
