import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import axios from "axios";
import { Breadcrumbs } from "../../../../AbstractElements";
import { useKeycloak } from "@react-keycloak/web";
import { TelephonyApi } from "../../../../api";
import TablePBXList from "./TablePBXList";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";


const PBXList = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [data, setData] = useState([]);

	const getData = async () => {
		try {

			const pbx = await axios.get(`${TelephonyApi}getPbx`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});
			const pbxListFromServer = concatResults(pbx).pbxList;

			const mappedPBXList = pbxListFromServer.map(pbx => ({
				id: pbx.id,
				connection_name: pbx.name,
				server_address: pbx.serverAddress,
				login: pbx.login,
				password: pbx.password,
				username_for_authentication: pbx.usernameAuth,
				proxy_server: pbx.proxyServer,
				enable_incoming_calls: pbx.enableIncomingCalls,
				allow_employees_select_number: pbx.allowEmployeesSelectNumber || false,
				employees_with_permission: [],
				automatically_create_lead: null,
				lead_directions: null,
				automatically_change_person: pbx.automaticalySwapPerson || false,
				keep_all_conversations: pbx.keepAllCalls || false,
				play_recording_alert: pbx.playRecordingAlert || false,
				save_in_two_channel: pbx.saveInTwoChannel || false,
				decrypt_recording: pbx.decryptRecording || false,
				quality_assessment: pbx.qualityAssessment || true,
			}));

			setData(mappedPBXList);

		} catch (error) { }
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список АТС" parent="Телефония" title="Список АТС" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>{data && <TablePBXList products={data} getData={getData} />}</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default PBXList;
