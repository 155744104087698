import React, { Fragment, useContext, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Card, CardBody, Form, CardHeader, FormGroup, Label, Input } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";
import { BeatLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { Settings } from "react-feather";

import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import ProjectContext from "../../../../_helper/Project";
import CreateProject from "./CreateProject";
import CreateLead from "./CreateLead";
import { AllProjectApi, TwinAuthApi } from "../../../../api";
import CreateTransactions from "./CreateTransactions";
import CommonModal from "../../../UiKits/Modals/common/modal";
import CreateAgreement from "./CreateAgreement";
import uuid from 'react-uuid';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const Newproject = () => {
	const history = useNavigate();
	const { keycloak } = useKeycloak();
	const currentUserId = `${keycloak.tokenParsed.sub}`;
	const currentUserName = `${keycloak.tokenParsed.name}`;
	const { companyData } = useCompanyContext();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const projectId = queryParams.get("projectId");

	const { register, handleSubmit } = useForm();

	const [states, setStates] = useState([]);
	const [selectState, setSelectState] = useState({});
	const [types, setTypes] = useState([]);
	const [type, setType] = useState({});

	const [title, seTitle] = useState("");
	const [description, setDescription] = useState("");
	const [dateCreate, setDateCreate] = useState(null);
	const [dateStart, setDateStart] = useState(null);
	const [dateEnd, setDateEnd] = useState(null);
	const [creator, setCreator] = useState(currentUserId);
	const [owner, setOwner] = useState({ label: currentUserName, value: currentUserId });
	const [participants, setParticipants] = useState(null);
	const [pattern, setPattern] = useState(2);
	const [company, setCompany] = useState(null);
	const [amount, setAmount] = useState(0);
	const [secret, setSecret] = useState("");

	const [kindDocument, setKindDocument] = useState(null);
	const [typeDocument, setTypeDocument] = useState(null);

	const fetchData = async () => {
		try {
			const statesResponse = await axios.get(`${AllProjectApi}getStates`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const selectOptionsState = concatResults(statesResponse).states.map((option) => ({
				label: option.text,
				value: option.id,
			}));
			setStates(selectOptionsState);

			const typesResponse = await axios.get(`${AllProjectApi}getTypes`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const selectOptionsTypes = concatResults(typesResponse).types.map((option) => ({
				label: option.text,
				value: option.id,
				key: option.value,
			}));
			console.log(selectOptionsTypes);
			/*selectOptionsTypes.push({
				label: "Согласование документов",
				value: "123",
				key: "234",
			}); //Добавить новый тип в сервис*/

			setTypes(selectOptionsTypes);
			var selectedType = queryParams.get("type");
			if (selectedType) {
				var currentType = selectOptionsTypes.find((obj) => obj.key === selectedType);
				if (!currentType) {
					currentType = selectOptionsTypes.find((obj) => obj.value === selectedType);
				}
				setType(currentType);
			} else {
				setType(selectOptionsTypes.find((obj) => obj.label === "Проект"));
			}

			if (projectId != null) {
				let currentProject
				await axios
					.get(`${AllProjectApi}getProject/${projectId}`,
						{
							headers:
								{
									Authorization: `Bearer ${keycloak.token}`,
									CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
								}
						}).then((response) => {
							currentProject = concatResults(response).project;
							seTitle(currentProject.name);
							setDescription(currentProject.description);
							setCheckedItems(JSON.parse(currentProject.canbanElements));
							//setDateStart(currentProject.dateStart);
							//setDateEnd(currentProject.dateEnd);
							setDateCreate(currentProject.dateCreate);
							setCreator(currentProject.creatorId);
							setAmount(currentProject.amount);
							setSelectState(selectOptionsState.find((obj) => obj.value === currentProject.state.id));
							setSecret(currentProject.secret);

						axios
							.get(`${TwinAuthApi}get-all-users`,
								{ headers:
										{
											Authorization: `Bearer ${keycloak.token}`,
											CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
										}
								}).then((usersResponse) => {
									let users = JSON.parse(concatResults(usersResponse));

							if (currentProject.ownerId !== null) {
								const owner = users.find((option) => option.id === currentProject.ownerId);
								setOwner({ label: `${owner.firstName} ${owner.lastName}`, value: owner.id });
							}

							if (currentProject.members.length > 0) {
								const members = currentProject.members.map((member) => {
									const user = users.find((user) => user.id === member.userId);
									return user ? { label: `${user.firstName} ${user.lastName}`, value: user.id } : null;
								});
								setParticipants(members);
							}
								});

						if (currentProject.companyId !== null) {
							axios
								.get(`${TwinAuthApi}get-groups-companies`,
									{
										headers:
											{
												Authorization: `Bearer ${keycloak.token}`,
												CompanyId: companyData.map((item) => (item.attributes.companyId[0]))
											}
									}).then((companiesResponse) => {
								const companies = JSON.parse(companiesResponse.data);
								const company = companies.find((option) => option.id === currentProject.companyId);
								setCompany({ label: `${company.name}`, value: company.id });
							});

						}
						});
				//var currentProject = concatResults(projectResponse).project;

			}
			else {
				const newSecret = uuid().split("-")[0];
				setSecret(newSecret);
				setSelectState(selectOptionsState.find((obj) => obj.label === "Новые"));
			}
		} catch (error) {
			console.error("Ошибка получения данных:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const [loading, setLoading] = useState(false);

	const send = () => {
		setLoading(true);
		const newProject = {
			name: title,
			description: description,
			stateId: selectState.value,
			typeId: type.value,
			creatorId: currentUserId,
			ownerId: owner.value,
			avatar: "string",
			isExternal: false,
			dateCreate: new Date(),
			dateStart: dateStart,
			dateEnd: dateEnd,
			deleted: false,
			amount: amount,
			companyId: companyData ? companyData[0]?.id : null,
			members: participants ? participants.map((option) => option.value) : null,
			canbanElements: JSON.stringify(checkedItems),
			typesDocuments: typeDocument,
			kindsDocuments: kindDocument,
			secret: secret
		};

		if (!projectId) {
			console.log(newProject);
			/*
			* public string Name { get; set; }
        public string? Description { get; set; }
        public string CanbanElements {  get; set; }
        public Guid StateId { get; set; }
        public Guid TypeId { get; set; }
        public Guid CreatorId { get; set; }
        public Guid? OwnerId { get; set; }
        public Guid? CompanyId { get; set; }
        public DateTime? DateCreate { get; set; }
        public DateTime? DateStart { get; set; }
        public DateTime? DateEnd { get; set; }
        public decimal? Amount { get; set; }
        public List<Guid> Members { get; set; } = new List<Guid>();
			* */
			axios
				.post(`${AllProjectApi}createProject`, newProject, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					//console.log(concatResults(response));
					toast.success("Проект добавлен!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setLoading(false);
					history(`${process.env.PUBLIC_URL}/app/project/project-list/`);
				})
				.catch((error) => {
					setLoading(false);
					toast.error("Произошла ошибка при добавлении проекта", {
						position: toast.POSITION.TOP_RIGHT,
					});
					console.error(error);
				});
		} else {
			const updateProject = { ...newProject, id: projectId };
			axios
				.put(`${AllProjectApi}updateProject`, updateProject, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					toast.success("Изменения сохранены!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setLoading(false);
					history(`${process.env.PUBLIC_URL}/app/project/project-list/`);
				})
				.catch((error) => {
					setLoading(false);
					toast.error("Произошла ошибка при обновлении проекта", {
						position: toast.POSITION.TOP_RIGHT,
					});
					console.error(error);
				});
		}
	};

	const handleTypeChange = (selectedOption) => {
		queryParams.delete("type");
		queryParams.append(`type`, selectedOption.value);
		history(`?${queryParams.toString()}`);
	};

	const generateTitle = (type, projectId) => {
		if (!!type) {
			if (!!projectId) {
				if (type.key === "Project") {
					return "Редактирование проекта";
				} else if (type.key === "Lead") {
					return "Редактирование лида";
				} else if (type.key === "Deal") {
					return "Редактирование сделки";
				} else {
					return "Редактирование согласования документов";
				}
			} else {
				if (type.key === "Project" || !type) {
					return "Создание проекта";
				} else if (type.key === "Lead") {
					return "Создание лида";
				} else if (type.key === "Deal") {
					return "Создание сделки";
				} else {
					return "Создание согласования документов";
				}
			}
		}
	};

	const [modal, setModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
	};

	const checkboxItemsProject = [
		{ name: "ID", value: "id" },
		{ name: "Дата создания", value: "createDate" },
		{ name: "Название", value: "name" },
		{ name: "Описание", value: "description" },
		{ name: "Дата начала", value: "dateStart" },
		{ name: "Дата окончания", value: "dateEnd" },
		{ name: "Владелец", value: "owner" },
		{ name: "Участники", value: "participants" },
	];

	const checkboxItemsLeads = [
		{ name: "ID", value: "id" },
		{ name: "Дата создания", value: "createDate" },
		{ name: "Название", value: "name" },
		{ name: "Описание", value: "description" },
		{ name: "Клиенты", value: "participants" },
		{ name: "Компания", value: "company" },
	];

	const checkboxItemsDeals = [
		{ name: "ID", value: "id" },
		{ name: "Дата создания", value: "createDate" },
		{ name: "Название", value: "name" },
		{ name: "Описание", value: "description" },
		{ name: "Клиенты", value: "participants" },
		{ name: "Компания", value: "company" },
		{ name: "Сумма", value: "amount" },
	];

	const [checkedItems, setCheckedItems] = useState(["name", "createDate"]);

	const handleCheckboxChange = (itemName) => {
		const updatedCheckedItems = checkedItems.includes(itemName) ? checkedItems.filter((item) => item !== itemName) : [...checkedItems, itemName];

		setCheckedItems(updatedCheckedItems);
		console.log(updatedCheckedItems);
	};

	const getNameByValue = (targetValue) => {
		const targetType = types.find((type) => type.value === targetValue.value);
		return targetType ? targetType.label.toLowerCase() : null;
	};

	const colourStyles = {
		control: (styles) => ({ ...styles, border: "none", boxShadow: "none", fontSize: "14px", padding: "0.22rem" }),
	};

	return (
		<Fragment>
			<Breadcrumbs parent="Проекты" title={generateTitle(type, projectId)} mainTitle={generateTitle(type, projectId)} />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<FormGroup className="col-md-12">
									<Label>Статус</Label>
									<Col md="12" style={{ display: "flex", justifyContent: "space-between" }}>
										<Select
											theme={(theme) => ({
												...theme,
												colors: {
													...theme.colors,
													primary25: "#7366ff80",
													primary: "#7366ff",
												},
											})}
											placeholder="Начните вводить..."
											noOptionsMessage={() => "Нет результатов"}
											options={states}
											value={selectState}
											className="js-example-basic-single col-sm-3"
											onChange={(option) => setSelectState(option)}
										/>
										<Btn attrBtn={{ color: "primary", className: "settings_btn", onClick: toggle }}>
											<Settings size={"16px"} className="gear-icon" style={{ margin: "0 4px -2px 0" }} />
											Настройки канбана
										</Btn>
									</Col>
								</FormGroup>
							</CardHeader>
							<CardBody>
								<Form className="theme-form" onSubmit={handleSubmit(send)}>
									<Row>
										<Col sm="9">
											<FormGroup>
												<Label>Название {getNameByValue(type) === "согласование документов" ? "маршрута" : "проекта"}</Label>
												<Input
													className="form-control"
													type="text"
													value={title}
													onChange={(e) => seTitle(e.target.value)}
													placeholder="Название проекта"
													required
												/>
											</FormGroup>
										</Col>
										<Col sm="3">
											<FormGroup>
												<Label>Тип проекта</Label>
												<Select
													noOptionsMessage={() => "Нет результатов"}
													options={types}
													value={type}
													className="form-control p-0 col-sm-12"
													styles={colourStyles}
													required
													onChange={(option) => handleTypeChange(option)}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<FormGroup>
											<Label>Описание</Label>
											<textarea
												className="form-control"
												value={description}
												onChange={(e) => setDescription(e.target.value)}
												name="description"
												rows="3"
												placeholder="Описание проекта"
											/>
										</FormGroup>
									</Row>
									{(getNameByValue(type) === "проект" || !type) && (
										<CreateProject
											projectId={projectId}
											statuses={states}
											setSelectStatus={setSelectState}
											selectStatus={setSelectState}
											handleTypeChange={handleTypeChange}
											title={title}
											seTitle={seTitle}
											selectType={type}
											setSelectType={setType}
											description={description}
											setDescription={setDescription}
											participants={participants}
											setParticipants={setParticipants}
											pattern={pattern}
											setPattern={setPattern}
											register={register}
											owner={owner}
											setOwner={setOwner}
											dateStart={dateStart}
											dateEnd={dateEnd}
											setDateEnd={setDateEnd}
											setDateStart={setDateStart}
											secret={secret}
											setSecret={setSecret}
										/>
									)}
									{getNameByValue(type) === "лид" && (
										<CreateLead
											statuses={states}
											setSelectStatus={setSelectState}
											selectStatus={setSelectState}
											handleTypeChange={handleTypeChange}
											company={company}
											setCompany={setCompany}
											title={title}
											seTitle={seTitle}
											selectType={type}
											setSelectType={setType}
											description={description}
											setDescription={setDescription}
											participants={participants}
											setParticipants={setParticipants}
											pattern={pattern}
											setPattern={setPattern}
											register={register}
											owner={owner}
											setOwner={setOwner}
											dateStart={dateStart}
											dateEnd={dateEnd}
											setDateEnd={setDateEnd}
											setDateStart={setDateStart}
										/>
									)}
									{getNameByValue(type) === "сделка" && (
										<CreateTransactions
											amount={amount}
											setAmount={setAmount}
											statuses={states}
											setSelectStatus={setSelectState}
											selectStatus={setSelectState}
											handleTypeChange={handleTypeChange}
											company={company}
											setCompany={setCompany}
											title={title}
											seTitle={seTitle}
											selectType={type}
											setSelectType={setType}
											description={description}
											setDescription={setDescription}
											participants={participants}
											setParticipants={setParticipants}
											pattern={pattern}
											setPattern={setPattern}
											register={register}
											owner={owner}
											setOwner={setOwner}
											dateStart={dateStart}
											dateEnd={dateEnd}
											setDateEnd={setDateEnd}
											setDateStart={setDateStart}
										/>
									)}

									{getNameByValue(type) === "согласование документов" && (
										<CreateAgreement
											kindDocument={kindDocument}
											setKindDocument={setKindDocument}
											typeDocument={typeDocument}
											setTypeDocument={setTypeDocument}
											amount={amount}
											setAmount={setAmount}
											statuses={states}
											setSelectStatus={setSelectState}
											selectStatus={setSelectState}
											handleTypeChange={handleTypeChange}
											company={company}
											setCompany={setCompany}
											title={title}
											seTitle={seTitle}
											selectType={type}
											setSelectType={setType}
											description={description}
											setDescription={setDescription}
											participants={participants}
											setParticipants={setParticipants}
											pattern={pattern}
											setPattern={setPattern}
											register={register}
											owner={owner}
											setOwner={setOwner}
											dateStart={dateStart}
											dateEnd={dateEnd}
											setDateEnd={setDateEnd}
											setDateStart={setDateStart}
										/>
									)}

									<Row>
										<Col>
											<div className="text-end">
												<Btn attrBtn={{ color: "success", className: "me-3", disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
												<Link to={`${process.env.PUBLIC_URL}/app/project/project-list`}>
													<Btn attrBtn={{ color: "danger" }}>Отмена</Btn>
												</Link>
											</div>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<CommonModal isOpen={modal} title={"Настройки канбана"} toggler={toggle} togglerClose={toggle} saveText="Готово" size="lg">
					<CardBody style={{ padding: "10px", paddingLeft: "15px" }}>
						<Col className="b-b-light border-2 pb-1 mb-2">Настройка полей просмотра карточки</Col>
						<Row className="ms-2">
							{(getNameByValue(type) === "проект" || !type) &&
								checkboxItemsProject.map((item, i) => (
									<div key={item.name} className="checkbox checkbox-primary col-md-6">
										<Input id={`checkbox-${i}`} type="checkbox" checked={checkedItems.includes(item.value)} onChange={() => handleCheckboxChange(item.value)} />
										<Label for={`checkbox-${i}`}>{item.name}</Label>
									</div>
								))}
							{getNameByValue(type) === "лид" &&
								checkboxItemsLeads.map((item, i) => (
									<div key={item.name} className="checkbox checkbox-primary col-md-6">
										<Input id={`checkbox-${i}`} type="checkbox" checked={checkedItems.includes(item.value)} onChange={() => handleCheckboxChange(item.value)} />
										<Label for={`checkbox-${i}`}>{item.name}</Label>
									</div>
								))}
							{getNameByValue(type) === "сделка" &&
								checkboxItemsDeals.map((item, i) => (
									<div key={item.name} className="checkbox checkbox-primary col-md-6">
										<Input id={`checkbox-${i}`} type="checkbox" checked={checkedItems.includes(item.value)} onChange={() => handleCheckboxChange(item.value)} />
										<Label for={`checkbox-${i}`}>{item.name}</Label>
									</div>
								))}
						</Row>
					</CardBody>
				</CommonModal>
			</Container>
		</Fragment>
	);
};

export default Newproject;
