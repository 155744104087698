import React, { useState, useEffect } from 'react';
import { Card, CardBody, Container, Spin, Button, Modal, Form, Input } from 'reactstrap';
import axios from 'axios';
import Select from "react-select";
import TableFieldsMapsSettings from './TableFieldsMapsSettings';
import { Breadcrumbs } from '../../../../AbstractElements';
import { ExportDataApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { PuffLoader } from "react-spinners";
import { useExportDataContext } from "../../../../_helper/ExportData/ExportDataProvider";


const FieldsMapsSettings = () => {
    const { response, responses, responsesOptions, setResponse, getResponses } = useExportDataContext();
    const [selectedOption, setSelectedOption] = useState({});

    const urlParams = new URLSearchParams(window.location.search);
    const responseId = urlParams.get('responseId');

    useEffect(() => {
        setResponse(null);
        getResponses(responseId);
    }, []);

    useEffect(() => {
        setSelectedOption(responsesOptions.find(x => x.value === responseId))
    }, [responses])

    const handleResponseChange = (option) => {
        setSelectedOption(option);
        setResponse(responses.find(obj => obj.id === option.value));
    };

    return (
        <Container fluid={true}>
            <Breadcrumbs mainTitle="Настройка маппинга полей сервисов и получателей" parent="Экспорт данных" title="Настройка маппинга полей сервисов и получателей" />
            <Card>
                <CardBody>
                    <div style={{ marginBottom: '10px', paddingLeft: '13px' }}>
                        <span>Выбрать получателя:</span>
                        <Select
                            required
                            placeholder="Начните вводить..."
                            noOptionsMessage={() => "Нет результатов"}
                            options={responsesOptions}
                            value={selectedOption}
                            onChange={(selectedOption) => handleResponseChange(selectedOption)}
                            className="js-example-basic-single col-sm-12 col-md-4"
                        />
                    </div>
                    {response ? <TableFieldsMapsSettings response={response} /> : (<></>)}
                </CardBody>
            </Card>
        </Container>
    );
};

export default FieldsMapsSettings;