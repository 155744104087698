import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { P } from '../../../../AbstractElements';

const LoyaltyProgram = ({ clientId }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/loyaltyProgram.json`)
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			{data.length === 0 ? (
				<>
					<P>У данного клиента нет карт</P>
				</>
			) : (
				''
			)}
		</Fragment>
	);
};

export default LoyaltyProgram;
