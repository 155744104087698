import React from 'react';
import Select from 'react-select';

const StatusFilter = ({ filterValue, onFilterChange, name, options }) => {
    const handleChange = (selectedOption) => {
        const value = selectedOption ? selectedOption.value : '';
        onFilterChange(value, name);
    };

    const selectedOption = options.find(option => option.value === filterValue) || null;

    return (
        <Select
            options={options}
            value={selectedOption}
            onChange={handleChange}
            placeholder="Выберите.."
            isClearable
            className="col-sm-12"
        />
    );
};

export default StatusFilter;
