import React, { Fragment, useCallback, useState, useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { ToastContainer, toast } from 'react-toastify';
import { H5 } from '../../../../AbstractElements';

const ClientFiles = () => {
	const getUploadParams = ({ meta }) => {
		return { url: 'https://httpbin.org/post' };
	};
	const handleSubmit = (files, allFiles) => {
		allFiles.forEach((f) => f.remove());
		toast.success('Dropzone successfully submitted !');
	};
	return (
		<Fragment>
			<H5>Загрузка дополнительных файлов</H5>
			<div className="dz-message needsclick">
				<Dropzone getUploadParams={getUploadParams} onSubmit={handleSubmit} inputContent="Выберите файлы или перетащите сюда" />
			</div>
		</Fragment>
	);
};

export default ClientFiles;
