import InteractivePlanMapsContext from '.';
import React, { useState, useContext, useCallback, useReducer } from 'react';
import axios from 'axios';
import { ModulesApi, IMapsApi } from '../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from "../Company/CompanyProvider";
import { concatResults } from '../../concatResults';
import uuid from 'react-uuid';
import DataTablePlanMapObjectTypes
    from "../../Components/Application/Widgets/InteractivePlanMap/PlanMapObjectTypes/DataTablePlanMapObjectTypes";

const InteractivePlanMapsProvider = ({ children, props }) => {
    const context = useCreateInteractivePlanMapsContext(props);

    return (
        <InteractivePlanMapsContext.Provider
            value={context}
        >
            {children}
        </InteractivePlanMapsContext.Provider>
    );
};

export function useInteractivePlanMapsContext() {
    const context = useContext(InteractivePlanMapsContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateInteractivePlanMapsContext = function (props) {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();

    const companyId = companyData.map((item) => (item.attributes.companyId[0]));//;"53819cb9-eaf4-48e1-b039-e37b6836d2b3"


    const [planMaps, setPlanMaps] = useState([]);
    const [planMapsOptions, setPlanMapsOptions] = useState([]);

    const [planMapsObjects, setPlanMapsObjects] = useState([]);
    const [planMapsObjectsCharacteristics, setPlanMapsObjectsCharacteristics] = useState([]);
    const [planMapsObjectsTypes, setPlanMapsObjectsTypes] = useState([]);
    const [planMapsObjectsTypesOptions, setPlanMapsObjectsTypesOptions] = useState([]);

    const [planMap, setPlanMap] = useState(null);
    const [planMapsObject, setPlanMapsObject] = useState(null);
    const [planMapsObjectsCharacteristicsValues, setPlanMapsObjectsCharacteristicsValues] = useState(null);
    const [planMapsObjectsTypesCharacteristics, setPlanMapsObjectsTypesCharacteristics] = useState(null);

    const [planMapsObjectsType, setPlanMapsObjectsType] = useState(null);

    const [planMapWidgets, setPlanMapWidgets] = useState([]);
    const [planMapWidgetsPublicTypes, setPlanMapWidgetsPublicTypes] = useState([]);
    const [planMapWidgetsPublicTypesOptions, setPlanMapWidgetsPublicTypesOptions] = useState([]);
    const [planMapWidget, setPlanMapWidget] = useState({
        id: '',
        planMapId: '',
        publicTypeId: '',
        domain: '',
        siteId: null,
        isPublish: false
    });
    const [planMapWidgetsOptions, setPlanMapWidgetsOptions] = useState([]);

    const [planMapObectsDemands, setPlanMapObectsDemands] = useState([]);

    const [modalCreateTypeChar, changeModalCreateTypeCharVisible] = useReducer((m) => !m, false);

    const getInteractivePlanMaps = async () => {
        axios
            .get(`${IMapsApi}get-interactive-maps?companyId=${companyId}`,
                {
                    headers:
                    {
                        Authorization: `Bearer ${keycloak.token}`,
                        CompanyId: companyId
                    }
                })
            .then((response) => {

                const responsesData = response.data.maps;
                setPlanMaps(responsesData);
                const responseOptions = responsesData.map(response => ({
                    label: response.name,
                    value: response.id
                }));
                setPlanMapsOptions(responseOptions);
                return responsesData;
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getInteractivePlanMap = async (id) => {
        axios
            .get(`${IMapsApi}get-interactive-map/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                setPlanMap(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getInteractivePlanMapsObjects = async (id) => {
        axios
            .get(`${IMapsApi}get-map-objects?interactiveMapId=${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                setPlanMapsObjects(response.data.objects);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };


    const getInteractivePlanMapsObject = async (id) => {
        axios
            .get(`${IMapsApi}get-map-object/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                setPlanMapsObject(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getInteractivePlanMapsObjectUnauthorized = async (id) => {
        axios
            .get(`${IMapsApi}get-map-object-unauthorized/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                setPlanMapsObject(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getInteractivePlanMapsObjectsCharacteristicsValues = async (id) => {
        axios
            .get(`${IMapsApi}get-map-object-characteristics-values?objectId=${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                setPlanMapsObjectsCharacteristicsValues(response.data.characteristics);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getInteractivePlanMapsObjectsTypes = async () => {
        axios
            .get(`${IMapsApi}get-map-types?companyId=${companyId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                const responsesData = response.data.types;
                setPlanMapsObjectsTypes(responsesData);
                const responseOptions = responsesData.map(response => ({
                    label: response.name,
                    value: response.id
                }));
                setPlanMapsObjectsTypesOptions(responseOptions);
                return responsesData;
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getInteractivePlanMapsObjectsTypesCharacteristics = async (typeId, objectId) => {
        let objId = objectId && `&objectId=${objectId}`;
        axios
            .get(`${IMapsApi}get-map-type-characteristics?objectTypeId=${typeId}${objId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                setPlanMapsObjectsTypesCharacteristics(response.data.characteristics);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getInteractivePlanMapsObjectsType = async (id) => {
        axios
            .get(`${IMapsApi}get-map-object-type/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                setPlanMapsObjectsType(response.data);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getInteractivePlanMapsWidgets = async (id) => {
        axios
            .get(`${IMapsApi}get-interactive-map-widget`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {

                //const responsesData = response.data.widgets;
                const responseWidgets = response.data.widgets;
                setPlanMapWidgets(responseWidgets);
                const widgetOptions = responseWidgets.map(response => ({
                    label: response.domain ? response.domain : "cms",
                    value: response.id
                }));
                setPlanMapWidgetsOptions(widgetOptions);

                const responsePublicTypes = response.data.publicTypes;
                setPlanMapWidgetsPublicTypes(responsePublicTypes);
                const publicTypesOptions = responsePublicTypes.map(response => ({
                    label: response.text,
                    value: response.id,
                    type: response.value
                }));
                setPlanMapWidgetsPublicTypesOptions(publicTypesOptions);

                return responseWidgets;
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };

    const getPlanMapObjectDemands = async (id) => {
        axios
            .get(`${IMapsApi}get-plan-map-object-demand/${id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId } })
            .then((response) => {
                setPlanMapObectsDemands(response.data.objectsDemands);
            })
            .catch((error) => {
                console.error("Ошибка получения данных:", error);
            });
    };


    const createInteractivePlanMap = async (name, creatorId, lastChange, companyId, activated, mapJson) => {
        await axios.post(`${IMapsApi}create-interactive-map`, {
            name: name,
            creatorId: creatorId,
            lastChange: lastChange,
            companyId: companyId,
            activated: activated,
            mapJson: mapJson
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMaps(companyId);
        }).catch((error) => {
            console.error(error);
        });
    };

    const createInteractivePlanMapsObject = async (planMapId, name, number, objectTypeId, activated) => {
        await axios.post(`${IMapsApi}create-map-object`, {
            planMapId: planMapId,
            name: name,
            number: number,
            objectTypeId: objectTypeId,
            activated: activated
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsObjects(planMapId);
        }).catch((error) => {
            console.error(error);
        });
    };

    const createInteractivePlanMapsObjectsCharacteristicValue = async (planMapObjectId, objectTypeCharacteristicId, characteristicValue) => {
        await axios.post(`${IMapsApi}create-map-object-characteristic-value`, {
            planMapObjectId: planMapObjectId,
            objectTypeCharacteristicId: objectTypeCharacteristicId,
            characteristicValue: characteristicValue
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsObjectsCharacteristicsValues(planMapObjectId);
        }).catch((error) => {
            console.error(error);
        });
    };

    const createInteractivePlanMapsObjectsType = async (name, activated, companyId) => {
        await axios.post(`${IMapsApi}create-map-type`, {
            name: name,
            companyId: companyId,
            activated: activated
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsObjectsTypes(companyId);
        }).catch((error) => {
            console.error(error);
        });
    };

    const createInteractivePlanMapsObjectsTypeCharacteristic = async (name, objectTypeId) => {
        await axios.post(`${IMapsApi}create-map-type-characteristic`, {
            name: name,
            objectTypeId: objectTypeId
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsObjectsTypesCharacteristics(objectTypeId);
        }).catch((error) => {
            console.error(error);
        });
    };

    const createInteractivePlanMapsWidgets = async (object) => {
        object.id = uuid();
        object.companyId = companyId;
        await axios.post(`${IMapsApi}create-interactive-map-widget`, object, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsWidgets();
        }).catch((error) => {
            console.error(error);
        });
    };

    const createPlanMapObjectDemand = async (planMapObjectNumber, demandId) => {

        await axios.post(`${IMapsApi}create-plan-map-object-demand`, {
            planMapObjectNumber: planMapObjectNumber,
            demandId: demandId
        },
            {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
                }
            }).then((resp) => {

            }).catch((error) => {
                console.error(error);
            });
    };


    const generateJsonFromImportedXml = async (file, planMapObjectTypeId) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            return axios.post(`${IMapsApi}generate-json-from-imported-xml?planMapObjectTypeId=${planMapObjectTypeId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${keycloak.token}`,
                    CompanyId: companyData.map(item => item.attributes.companyId[0])
                }
            });
        } catch (err) {
            console.error(err);
        }
    };


    const sendImportField = async (field) => {
        try {
            field.companyId = companyId;//companyData.map((item) => item.attributes.companyId[0]);

            const response = await axios.post(`${IMapsApi}send-import-field`, field, {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    CompanyId: companyData.map((item) => item.attributes.companyId[0])
                }
            });

            return response;
        } catch (error) {
            console.error(`Ошибка при отправке поля: ${error.message}`);
            throw error;
        }
    };

    const updateInteractivePlanMap = async (id, name, creatorId, lastChange, companyId, activated, mapJson) => {
        await axios.put(`${IMapsApi}update-interactive-map`, {
            id: id,
            name: name,
            creatorId: creatorId,
            lastChange: lastChange,
            companyId: companyId,
            activated: activated,
            mapJson: mapJson
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMaps(companyId);
        }).catch((error) => {
            console.error(error);
        });
    }

    const updateInteractivePlanMapsObjects = async (id, planMapId, name, number, objectTypeId, activated) => {
        await axios.put(`${IMapsApi}update-map-object`, {
            id: id,
            planMapId: planMapId,
            name: name,
            number: number,
            objectTypeId: objectTypeId,
            activated: activated
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsObjects(planMapId);
        }).catch((error) => {
            console.error(error);
        });
    }

    const updateInteractivePlanMapsObjectsCharacteristicValue = async (id, planMapObjectId, objectTypeCharacteristicId, characteristicValue) => {
        await axios.post(`${IMapsApi}update-map-object-characteristic-value`, {
            id: id,
            planMapObjectId: planMapObjectId,
            objectTypeCharacteristicId: objectTypeCharacteristicId,
            characteristicValue: characteristicValue
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsObjectsCharacteristicsValues(planMapObjectId);
        }).catch((error) => {
            console.error(error);
        });
    };

    const updateInteractivePlanMapsObjectsCharacteristic = async (id, planMapObjectId, characteristicsJson) => {
        await axios.put(`${IMapsApi}update-map-object-characteristic`, {
            id: id,
            planMapObjectId: planMapObjectId,
            characteristicsJson: characteristicsJson
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsObjectsTypesCharacteristics(planMapObjectId);
        }).catch((error) => {
            console.error(error);
        });
    }

    const updateInteractivePlanMapsObjectsType = async (id, name, activated, companyId, cardJson) => {
        await axios.put(`${IMapsApi}update-map-object-type`, {
            id: id,
            name: name,
            companyId: companyId,
            activated: activated,
            cardJson: cardJson
        }, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsObjectsTypes(companyId);
        }).catch((error) => {
            console.error(error);
        });
    }

    const updateInteractivePlanMapsWidgets = async (object) => {
        await axios.put(`${IMapsApi}update-interactive-map-widget`, object, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsWidgets();
        }).catch((error) => {
            console.error(error);
        });
    };

    const deleteInteractivePlanMap = async (id) => {
        await axios.delete(`${IMapsApi}delete-interactive-map/${id}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
        }).catch((error) => {
            console.error(error);
        });
    }

    const deleteInteractivePlanMapsObjects = async (id) => {
        await axios.delete(`${IMapsApi}delete-map-object/${id}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
        }).catch((error) => {
            console.error(error);
        });
    }

    const deleteInteractivePlanMapsObjectsCharacteristic = async (id) => {
        await axios.delete(`${IMapsApi}delete-map-characteristic-value/${id}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            console.log(resp);
            //getModuleGroups();
        }).catch((error) => {
            console.error(error);
        });
    }

    const deleteInteractivePlanMapsObjectsType = async (id) => {
        await axios.delete(`${IMapsApi}delete-map-object-type/${id}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            //console.log(resp);
            //getModuleGroups();
        }).catch((error) => {
            console.error(error);
        });
    }


    const deleteInteractivePlanMapsWidget = async (id) => {
        await axios.delete(`${IMapsApi}delete-interactive-map-widget/${id}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`, CompanyId: companyId
            }
        }).then((resp) => {
            getInteractivePlanMapsWidgets();
        }).catch((error) => {
            console.error(error);
        });
    }

    return {
        planMaps,
        planMapsOptions,
        planMapsObjects,
        planMapsObjectsCharacteristics,
        planMapsObjectsTypes,
        planMapsObjectsTypesOptions,
        planMapsObjectsTypesCharacteristics,

        planMap,
        planMapsObject,
        planMapsObjectsCharacteristicsValues,
        planMapsObjectsType,
        planMapWidgets,
        planMapWidget,
        planMapWidgetsOptions,
        planMapWidgetsPublicTypes,
        planMapWidgetsPublicTypesOptions,
        planMapObectsDemands,

        getInteractivePlanMaps,
        getInteractivePlanMapsObjects,
        getInteractivePlanMapsObjectsCharacteristicsValues,
        getInteractivePlanMapsObjectsTypesCharacteristics,
        getInteractivePlanMapsObjectsTypes,
        getInteractivePlanMapsObjectUnauthorized,

        getInteractivePlanMapsObjectsType,
        getInteractivePlanMapsObject,
        getInteractivePlanMap,
        getInteractivePlanMapsWidgets,
        getPlanMapObjectDemands,

        createInteractivePlanMap,
        createInteractivePlanMapsObject,
        createInteractivePlanMapsObjectsCharacteristicValue,
        createInteractivePlanMapsObjectsType,
        createInteractivePlanMapsObjectsTypeCharacteristic,
        createInteractivePlanMapsWidgets,
        createPlanMapObjectDemand,

        updateInteractivePlanMap,
        updateInteractivePlanMapsObjects,
        updateInteractivePlanMapsObjectsCharacteristicValue,
        updateInteractivePlanMapsObjectsCharacteristic,
        updateInteractivePlanMapsObjectsType,
        updateInteractivePlanMapsWidgets,

        deleteInteractivePlanMap,
        deleteInteractivePlanMapsObjects,
        deleteInteractivePlanMapsObjectsCharacteristic,
        deleteInteractivePlanMapsObjectsType,
        deleteInteractivePlanMapsWidget,

        modalCreateTypeChar,
        changeModalCreateTypeCharVisible,
        generateJsonFromImportedXml,
        sendImportField,
        setPlanMapWidget,
        setPlanMapObectsDemands
    };
}

export default InteractivePlanMapsProvider;