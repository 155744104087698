import React, {Fragment, useEffect, useState} from "react";
import {Breadcrumbs} from "../../../../AbstractElements";
import {Card, CardBody, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import CounterpartyCardContent from "./CounterpartyCardContent";
import ContactPersons from "./ContactPersons";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {useCounterpartiesContext} from "../../../../_helper/Counterparties/CounterpartiesProvider";

const CounterpartyCard = () => {
    const [counterpartyHeader, setCounterpartyHeader] = useState('Контрагент')
    const [counterpartyText, setCounterpartyText] = useState('контрагент')
    const {companyData} = useCompanyContext();
    const {counterpartySettings} = useCounterpartiesContext();

    useEffect(() => {
        companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyHeader('Партнёр')
        companyData && companyData[0] && companyData[0].realmRoles.includes('counterparties-to-partners') && setCounterpartyText('партнёр')
    }, [companyData]);
    const [pillWithIconTab, setpillWithIconTab] = useState("1");
    return (
        <Fragment>
            <Breadcrumbs mainTitle={`Карточка ${counterpartyText}а`} subParent={`${counterpartyHeader}ы`} parent="Приложения" title={`Карточка ${counterpartyText}а`} />
            <Container fluid={true}>
                <Row>
                    <Card>
                        <CardBody className="chat-body">
                            <Nav className="nav-primary" tabs>
                                <NavItem>
                                    <NavLink className={pillWithIconTab === "1" ? "active" : ""} onClick={() => setpillWithIconTab("1")} style={{ cursor: "pointer" }}>
                                        Основные данные
                                    </NavLink>
                                </NavItem>
                                { counterpartySettings && counterpartySettings?.allowHaveManyContacts &&
                                <NavItem>
                                    <NavLink className={pillWithIconTab === "2" ? "active" : ""} onClick={() => setpillWithIconTab("2")} style={{ cursor: "pointer" }}>
                                        Контактные лица
                                    </NavLink>
                                </NavItem>
                                }
                            </Nav>
                            <TabContent activeTab={pillWithIconTab}>
                                <TabPane className="fade show" tabId='1'>
                                    <div
                                        key='1'
                                        className={"w-100 p-4"}
                                    >
                                        <CounterpartyCardContent />
                                    </div>
                                </TabPane>
                            </TabContent>
                            <TabContent activeTab={pillWithIconTab}>
                                <TabPane className="fade show" tabId='2'>
                                    <div
                                        key='2'
                                        className={"w-100 p-4"}
                                    >
                                        <ContactPersons />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
        </Fragment>
    );
};
export default CounterpartyCard;