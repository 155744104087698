import React from "react";
import {Col, Input, Label} from "reactstrap";

export const InputText = {
    label: "Ввод текста",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
    },
    defaultProps: {
        children: "Ввод текста",
    },
    render: ({ children }) => {
        return <div>
            <Label className="col-sm-9 col-form-label">{children}</Label>
            <Col sm="12">
                <Input
                    className="form-control"
                    type="text"
                    //placeholder="Подсказка для ввода"
                />
            </Col>
        </div>;
    },
};