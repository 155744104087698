import React, { Fragment, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { H5, H4, H6, P, Image, Btn } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { BeatLoader } from "react-spinners";
import { MyProfile, EmailAddress } from "../../../../Constant";
import { useUsersContext } from "../../../../_helper/Users/UsersProvider";
import { toast } from 'react-toastify';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const MyProfileEdit = ({ profileData }) => {
	const [url, setUrl] = useState("");

	const readUrl = (event) => {
		if (event.target.files.length === 0) return;
		var mimeType = event.target.files[0].type;

		if (mimeType.match(/image\/*/) == null) {
			return;
		}
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = (_event) => {
			setUrl(reader.result);
		};
	};

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { updateUserAttributes } = useUsersContext();

	const [phone, setPhone] = useState(profileData && profileData.attributes?.phone);
	const [description, setDescription] = useState(profileData && profileData.attributes?.description);
	const [interests, setInterestsl] = useState(profileData && profileData.attributes?.interests);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setPhone(profileData && profileData.attributes?.phone);
		setDescription(profileData && profileData.attributes?.description);
		setInterestsl(profileData && profileData.attributes?.interests)
	}, [profileData]);

	const send = () => {
		setLoading(true);
		updateUserAttributes(keycloak.tokenParsed.sub, {
			attributes: {
				patronymic: profileData && profileData.attributes?.patronymic,
				address: profileData && profileData.attributes?.address,
				city: profileData && profileData.attributes?.city,
				postcode: profileData && profileData.attributes?.postcode,
				country: profileData && profileData.attributes?.country,
				telegram: profileData && profileData.attributes?.telegram,
				vk: profileData && profileData.attributes?.vk,
				phone: phone,
				description: description,
				interests: interests
			}
		}, url).then(() => {
			setLoading(false);
			toast.success('Профиль обновлён!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		}).catch(() => {
			setLoading(false);
			toast.error('Ошибка при обновлении профиля', {
				position: toast.POSITION.TOP_RIGHT,
			});
		});
	};

	return (
		<Fragment>
			<Card>
				<CardHeader>
					<H4 attrH4={{ className: "card-title mb-0" }}>{MyProfile}</H4>
					<div className="card-options">
						<a className="card-options-collapse" href="#javascript">
							<i className="fe fe-chevron-up"></i>
						</a>
						<a className="card-options-remove" href="#javascript">
							<i className="fe fe-x"></i>
						</a>
					</div>
				</CardHeader>
				<CardBody>
					<Form>
						<Row className="mb-2">
							<div className="profile-title">
								<div className="media">
									<Image
										attrImage={{ height: "70px", className: "img-70 m-0 rounded-circle step1", alt: "", src: `${url ? url : require("../../../../assets/images/user/7.jpg")}` }}
									/>
									<div className="icon-wrapper step2" data-intro="Change Profile image here" style={{}}>
										<i
											style={{
												position: "absolute",
												justifyContent: "center",
												bottom: "25px",
												left: "15%",
												display: "flex",
												JustifyContent: "center",
												alignItems: "center",
												height: "30px",
												width: "30px",
												borderRadius: "50%",
												backgroundColor: "#fff",
												margin: "0 auto",
												fontSize: "17px",
												boxShadow: "0 0 6px 3px rgba(68, 102, 242, 0.1)",
												cursor: "pointer",
											}}
											className="icofont icofont-pencil-alt-5"
											onChange={(e) => readUrl(e)}
										></i>
										<input
											style={{ position: "absolute", opacity: "0", width: "30px", left: "70px", bottom: "30px" }}
											className="upload"
											type="file"
											onChange={(e) => readUrl(e)}
										/>
									</div>
									<div className="media-body">
										<Link to={`${process.env.PUBLIC_URL}/app/users/profile?userId=${keycloak.tokenParsed.sub}`}>
											<H5 attrH5={{ className: "mb-1" }}>{keycloak.tokenParsed.name}</H5>
										</Link>
										{/* <P>DESIGNER</P> */}
									</div>
								</div>
							</div>
						</Row>
						<FormGroup className="mb-3">
							<Label className="form-label">{EmailAddress}</Label>
							<Input className="form-control" placeholder="your-email@domain.com" value={keycloak.tokenParsed.email} disabled />
						</FormGroup>
						{/* <FormGroup className="mb-3">
							<Label className="form-label">{Password}</Label>
							<Input className="form-control" type="password" defaultValue="password" />
						</FormGroup> */}
						{
							<FormGroup className="mb-3">
								<Label className="form-label">Номер телефона</Label>
								<InputMask
									className="form-control"
									mask="+7 (999) 999-99-99"
									maskChar="_"
									placeholder="+7 (___) ___-__-__"
									alwaysShowMask
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
							</FormGroup>
						}
						{
							<FormGroup className="mb-3">
								<H6 attrH6={{ className: "form-label" }}>Обо мне</H6>
								<Input type="textarea" className="form-control" rows="5" value={description} onChange={(e) => setDescription(e.target.value)} />
							</FormGroup>
						}

						{
							<FormGroup className="mb-3">
								<Label className="form-label">Интересы</Label>
								<Input type="text" className="form-control" placeholder="Ваши интересы" value={interests} onChange={(e) => setInterestsl(e.target.value)} />
							</FormGroup>
						}
						<div className="form-footer" style={{ marginTop: "2rem" }}>
							<Btn attrBtn={{ color: "primary", disabled: loading, onClick: send }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
						</div>
					</Form>
				</CardBody>
			</Card>
		</Fragment>
	);
};
export default MyProfileEdit;
