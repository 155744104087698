import React, { Fragment, useContext, useState, useEffect } from "react";
import { Row, Media, Label, Input, Col, Form, FormGroup } from "reactstrap";
import { Btn, H5, H6, Image, LI, P, UL } from "../../../../AbstractElements";
import { DownloadAll, Reply, ReplyAll, Forward } from "../../../../Constant";
import { toast } from "react-toastify";
import axios from "axios";

// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
// //import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// //import { SourceEditing } from "@ckeditor/ckeditor5-source-editing";

// import { CKEditor } from "@ckeditor/ckeditor5-react";

// // NOTE: Use the editor from source (not a build)!
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

// import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
// import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
// import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";

import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import { useKeycloak } from "@react-keycloak/web";
import { BeatLoader } from "react-spinners";

import { TwinAuthApi } from "../../../../api";
import email1 from "../../../../assets/images/email/1.jpg";
import email2 from "../../../../assets/images/email/2.jpg";
import email3 from "../../../../assets/images/email/3.jpg";
import EmailContext from "../../../../_helper/Email";
import MyEditor from "./MyEditor";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

var images = require.context("../../../../assets/images", true);

// const editorConfiguration = {
// 	plugins: [, Bold, Italic, SourceEditing],
// 	toolbar: ["bold", "italic", "sourceEditing"],
// };

const MailContain = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const { singleMailRecord, compose, fetchAllEmailAsyn } = useContext(EmailContext);

	useEffect(() => {
		fetchAllEmailAsyn();
	}, [companyData]);

	// useEffect(() => {
	// 	ClassicEditor.create(document.querySelector("#editor"), {
	// 		plugins: [SourceEditing],
	// 		toolbar: ["sourceEditing"],
	// 	})
	// 		.then((editor) => {
	// 			console.log("Editor was initialized", editor);
	// 		})
	// 		.catch((error) => {
	// 			console.error(error.stack);
	// 		});
	// }, []);

	const [isFavourite, setIsFavourite] = useState(singleMailRecord.favourite);

	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	const [contentState, setContentState] = useState({ entityMap: {}, blocks: [{ key: "637gr", text: "", type: "unstyled", depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} }] });

	const onContentStateChange = (newContentState) => {
		setContentState(newContentState);
		console.log(JSON.stringify(contentState, null, 4));
	};

	const months = ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"];

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay} ${months[month]} ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}

	const [emails, setEmails] = useState([]);
	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName + " (" + option.email + ")",
					value: option.email,
				}));
				setEmails(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [addressRecipient, setAddressRecipient] = useState(null);
	const [subject, setSubject] = useState("");
	const [loading, setLoading] = useState(false);

	const send = () => {
		if (addressRecipient === null) {
			toast.error("Укажите получателя!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			setLoading(true);
			const newMsg = {
				senderAvatar: "user/9.jpg", // это должна быть аватарка текущего пользователя
				senderName: keycloak.tokenParsed.name,
				senderEmail: keycloak.tokenParsed.email,
				recipientEmail: addressRecipient.value,
				date: new Date(),
				subject: subject,
				type: { id: "d3a732a7-2e9e-4c0c-8560-5777dfea1c2f", label: "Отправленные" },
				text: contentState,
				favourite: false,
			};
			console.log(newMsg);
			// axios
			// 	.post(`/api/clientsDemandsForm.json`, newMsg)
			// 	.then((response) => {
			// 		console.log(response.data);
			// 		toast.success('Сообщение отправлено!', {
			// 			position: toast.POSITION.TOP_RIGHT,
			// 		});
			// 		setLoading(false);
			// 	})
			// 	.catch((error) => {
			// 		console.error(error);
			//    setLoading(false);
			// 	});
		}
	};

	const putFavourite = () => {
		const updateMsg = {
			id: singleMailRecord.id,
			senderAvatar: singleMailRecord.senderAvatar,
			senderName: singleMailRecord.senderName,
			senderEmail: singleMailRecord.senderEmail,
			recipientEmail: singleMailRecord.recipientEmail,
			date: singleMailRecord.date,
			subject: singleMailRecord.subject,
			type: singleMailRecord.type,
			text: singleMailRecord.text,
			favourite: !isFavourite,
		};
		console.log(updateMsg);
		// axios
		// 	.put(`/api/emails.json/${singleMailRecord.id}`, updateMsg)
		// 	.then((response) => {
		// 		console.log(response.data);
		// 		toast.success('Изменения сохранены!', {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 		setLoading(false);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		//    setLoading(false);
		// 	});
	};

	const [content, setContent] = useState("");

	const handleChange = (evt) => {
		setContent(evt.editor.getData());
	};

	return (
		<Fragment>
			<Col xl="8" md="12" className="box-md-12 pl-0">
				<div className="email-right-aside">
					<div className="email-body radius-left">
						<div className="ps-0">
							<div className="tab-content">
								<div className={`tab-pane fade ${compose ? "active show" : ""}`} id="pills-darkhome" role="tabpanel" aria-labelledby="pills-darkhome-tab">
									<div className="email-compose">
										<div className="email-top" style={{ paddingBottom: "21px" }}>
											<Row>
												<Col sm="8 xl-50">
													<h5 className="mb-0">Новое сообщение</h5>
												</Col>
												<Col sm="4 xl-50" className="btn-middle text-end">
													<Btn attrBtn={{ color: "primary", className: "btn-mail text-center mb-0 mt-0", onClick: send, disabled: loading }}>
														{loading ? (
															<BeatLoader size={8} color="#ffffff" />
														) : (
															<>
																<i className="fa fa-paper-plane me-2" id="send-icon"></i>Отправить
															</>
														)}
													</Btn>
												</Col>
											</Row>
										</div>
										<div className="email-wrapper">
											<FormGroup>
												<Label className="col-form-Label pt-0">Кому</Label>
												<Select
													placeholder="Начните вводить..."
													noOptionsMessage={() => "Нет результатов"}
													options={emails}
													value={addressRecipient}
													theme={(theme) => ({
														...theme,
														colors: {
															...theme.colors,
															primary25: "#7366ff80",
															primary: "#7366ff",
														},
													})}
													className="js-example-basic-single col-md-12"
													onChange={(option) => setAddressRecipient(option)}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Тема</Label>
												<Input className="form-control" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
											</FormGroup>
											<div>
												<Label className="text-muted">Сообщение</Label>
												<MyEditor />
												{/* <div id="editor">
													<p>This is the initial content of the editor.</p>
												</div> */}
												{/* <Ckeditor
													activeclassName="p10"
													content={content}
													events={{
														change: handleChange,
													}}
												/> */}
												{/* <div id="editor">
													<CKEditor
														editor={ClassicEditor}
														config={editorConfiguration}
														data="<p>Hello from CKEditor 5!</p>"
														onReady={(editor) => {
															// You can store the "editor" and use when it is needed.
															console.log("Editor is ready to use!", editor);
															console.log("toolbar: ", Array.from(editor.ui.componentFactory.names()));
															console.log(
																"plugins: ",
																ClassicEditor.builtinPlugins.map((plugin) => plugin.pluginName)
															);
														}}
														onChange={(event, editor) => {
															const data = editor.getData();
															console.log({ event, editor, data });
														}}
														onBlur={(event, editor) => {
															console.log("Blur.", editor);
														}}
														onFocus={(event, editor) => {
															console.log("Focus.", editor);
														}}
													/>
												</div> */}
											</div>
										</div>
									</div>
								</div>
								<div className={`tab-pane fade ${compose !== true ? "active show" : ""}`}>
									<div className="email-content">
										<div className="email-top">
											<Row>
												<Col md="6" sm="12">
													<Media className="align-items-center">
														<Image
															attrImage={{
																className: "me-3 rounded-circle img-50",
																src: `${singleMailRecord.senderAvatar ? dynamicImage(singleMailRecord.senderAvatar) : ""}`,
																alt: "",
																height: "50px",
															}}
														/>
														<Media body>
															<H6>
																{singleMailRecord.senderName}{" "}
																<small>
																	<span className="digits"> {formatDate(singleMailRecord.date)}</span>
																</small>
															</H6>
															<P attrPara={{ className: "" }}>{singleMailRecord.senderEmail}</P>
														</Media>
													</Media>
												</Col>
												<Col md="6" sm="12">
													<div className="float-end d-flex">
														<P attrPara={{ className: "user-emailid h-20" }}>{singleMailRecord.subject}</P>
														<Tooltip title={` ${singleMailRecord.favourite ? "Удалить из избранного" : "Добавить в избранное"} `} arrow placement="top">
															<i className={`fa fa-star f-18 mt-1  ${singleMailRecord.favourite ? "starred" : "star-hover"} `} onClick={putFavourite}></i>
														</Tooltip>
													</div>
												</Col>
											</Row>
										</div>
										<div className="email-wrapper">
											<div className="mb-3" dangerouslySetInnerHTML={{ __html: draftToHtml(singleMailRecord.text) }} />
											<hr />
											<div className="d-inline-block">
												<H6 className="text-muted">
													<i className="icofont icofont-clip"></i> Вложения
												</H6>
												<a className=" text-end right-download" href="#javascript">
													<i className="fa fa-download me-1"></i>
													{DownloadAll}
												</a>
												<div className="clearfix"></div>
											</div>
											<div className="attachment">
												<UL attrUL={{ className: "list-inline d-flex flex-row" }}>
													<LI attrLI={{ className: "list-inline-item border-0" }}>
														<Image attrImage={{ className: "img-fluid", src: `${email1}`, alt: "" }} />
													</LI>
													<LI attrLI={{ className: "list-inline-item border-0" }}>
														<Image attrImage={{ className: "img-fluid", src: `${email2}`, alt: "" }} />
													</LI>
													<LI attrLI={{ className: "list-inline-item border-0" }}>
														<Image attrImage={{ className: "img-fluid", src: `${email3}`, alt: "" }} />
													</LI>
												</UL>
											</div>
											<hr />
											<div className="action-wrapper">
												<UL attrUL={{ className: "actions flex-row" }}>
													<LI arreLI={{ className: "border-0" }}>
														<a className="text-muted" href="#javascript">
															<i className="fa fa-reply me-2"></i>
															{Reply}
														</a>
													</LI>
													<LI arreLI={{ className: "border-0" }}>
														<a className="text-muted" href="#javascript">
															<i className="fa fa-reply-all me-2"></i>
															{ReplyAll}
														</a>
													</LI>
													<LI arreLI={{ className: "border-0" }}>
														<a className="text-muted" href="#javascript">
															<i className="fa fa-share me-2"></i>
														</a>
														{Forward}
													</LI>
												</UL>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Col>
		</Fragment>
	);
};
export default MailContain;
