import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, CardBody, Col, Card, Label, CardFooter, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import ru from "date-fns/locale/ru";
import Select from "react-select";

import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import KeyIndicatorsCards from "./KeyIndicatorsCards";
import OverallBalance from "./OverallBalance";

const KeyIndicators = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const employeeId = queryParams.get("employeeId");

	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());

	const [selectedEmploye, setSelectedEmploye] = useState({ label: "Все сотрудники", value: "allEmployees" });
	const [employees, setEmployees] = useState([]);
	const [primarycolorTab, setprimarycolorTab] = useState("1");

	useEffect(() => {
		axios
			.get("/api/employees.json")
			.then((response) => {
				const selectOptions = response.data.map((option) => ({
					label: option.name + " " + option.surname,
					value: option.id,
				}));
				selectOptions.unshift({ label: "Все сотрудники", value: "allEmployees" });

				if (employeeId && employeeId !== "allEmployees") {
					const foundObject = response.data.find((item) => item.id === employeeId);
					setSelectedEmploye({ label: foundObject.name + " " + foundObject.surname, value: foundObject.id });
				}
				const dateEnd = queryParams.get("dateEnd");
				if (dateEnd) {
					setPeriodEnd(new Date(dateEnd));
				} else {
					setPeriodEnd(new Date());
				}

				const dateStart = queryParams.get("dateStart");
				if (dateStart) {
					setPeriodStart(new Date(dateStart));
				} else {
					setPeriodStart(new Date());
				}

				setEmployees(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const handleEmployeesChange = (selectedOption) => {
		const categoryId = selectedOption.value;
		setSelectedEmploye(selectedOption);
		queryParams.delete("employeeId");
		queryParams.append(`employeeId`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};

	const handleDateStartChange = (selectedOption) => {
		const categoryId = selectedOption;
		setPeriodStart(selectedOption);
		queryParams.delete("dateStart");
		queryParams.append(`dateStart`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};
	const handleDateEndChange = (selectedOption) => {
		const categoryId = selectedOption;
		setPeriodEnd(selectedOption);
		queryParams.delete("dateEnd");
		queryParams.append(`dateEnd`, categoryId);
		navigate(`?${queryParams.toString()}`);
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Основные показатели" parent="Аналитика" title="Основные показатели" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Row className="m-l-5">
									<Col xl="3" sm="9">
										<div style={{ display: "flex", alignItems: "flex-end" }}>
											<Label className="m-r-10 f-16">С</Label>
											<div className="input-group">
												<DatePicker locale={ru} dateFormat="dd.MM.yyyy" className="form-control digits" selected={periodStart} onChange={handleDateStartChange} />
											</div>
										</div>
									</Col>
									<Col xl="3" sm="9">
										<div style={{ display: "flex", alignItems: "flex-end" }}>
											<Label className="m-r-10 f-16">По</Label>
											<div className="input-group">
												<DatePicker locale={ru} dateFormat="dd.MM.yyyy" className="form-control digits" selected={periodEnd} onChange={handleDateEndChange} />
											</div>
										</div>
									</Col>
									<Col xl="3" sm="9">
										<Select placeholder="Выберите..." value={selectedEmploye} options={employees} className="js-example-basic-single col-sm-12" onChange={handleEmployeesChange} />
									</Col>
								</Row>
							</CardBody>
							<CardFooter className="p-t-10 p-b-10">
								<div className="modal-footer">
									<Btn attrBtn={{ color: "primary" }}>Показать</Btn>
								</div>
							</CardFooter>
						</Card>
						<KeyIndicatorsCards />
						<Card>
							<CardHeader>
								<Row>
									<Nav className="nav nav-pills">
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "1" ? "active" : ""} onClick={() => setprimarycolorTab("1")}>
												Выручка
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "2" ? "active" : ""} onClick={() => setprimarycolorTab("2")}>
												Записи
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: "pointer" }} className={primarycolorTab === "3" ? "active" : ""} onClick={() => setprimarycolorTab("3")}>
												Заполненность
											</NavLink>
										</NavItem>
									</Nav>
								</Row>
							</CardHeader>
							<CardBody>
								<TabContent activeTab={primarycolorTab}>
									<TabPane className="fade show" tabId="1">
										<OverallBalance />
									</TabPane>
									<TabPane tabId="2"></TabPane>
									<TabPane tabId="3"></TabPane>
								</TabContent>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default KeyIndicators;
