import React, { useState, useEffect } from "react";
import { Col, Input, Label } from "reactstrap";
import Select from "react-select";
import { Btn, H5 } from "../../../../AbstractElements";

import uuid from "react-uuid";
import axios from "axios";
import { useParams } from "react-router-dom";
import { CmsApi } from "../../../../api";
import { toast } from "react-toastify";

import { useKeycloak } from "@react-keycloak/web";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const SettingsAuth = () => {
	const { siteId } = useParams();
	const [displayAuth, setDisplayAuth] = useState(false);
	const [serviceType, setServiceType] = useState({ value: "standart service", label: "Стандартный сервис авторизации" });
	const [serviceLink, setServiceLink] = useState("");
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [site, setSite] = useState({});

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (site.siteAuth) {
			setDisplayAuth(site.siteAuth.isDisplayAuth);
			setServiceLink(site.siteAuth.serviceLink);
		}

	}, [site]);

	const fetchData = async () => {
		try {

			var response = await axios
				.get(`${CmsApi}getSite`,
					{ params: { siteId: siteId } },
					{ headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } }
				)
				.then((response) => {
					setSite(response.data);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);

				});
		}
		catch (error) {

		}

	}


	const handleCheckboxChange = () => {
		setDisplayAuth(!displayAuth);
	};

	const handleSelectChange = (selectedOption) => {
		setServiceType(selectedOption);
		console.log(selectedOption);
	};

	const setSiteAuth = () => {
		console.log(siteId);
		const data = {
			Id: uuid(),
			isDisplayAuth: displayAuth,
			serviceType: serviceType.value,
			serviceLink: serviceLink,
			SiteId: siteId,
		};
		axios
			.put(`${CmsApi}updateSiteAuth`, data, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				toast.success("Настройки авторизации сайта сохранены!", {
					position: toast.POSITION.TOP_RIGHT,
				});
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	return (
		<>
			<H5>Настройки авторизации</H5>
			<div className="checkbox checkbox-primary m-l-30 mb-3 mt-4">
				<Input id="checkbox-primary-1" type="checkbox" style={{ display: "none" }} checked={displayAuth} onChange={handleCheckboxChange} />
				<Label for="checkbox-primary-1">Отображать панель входа</Label>
			</div>
			{displayAuth && (
				<>
					<Col sm="4" md="6" className="m-l-10 mb-3" style={{ minHeight: "150px" }}>
						<div className="mb-4">
							<Select
								placeholder="Выберите..."
								options={[
									{ value: "standart service", label: "Стандартный сервис авторизации" },
									{ value: "custom service", label: "Собственный сервис" },
								]}
								className="js-example-basic-single col-sm-12"
								value={serviceType}
								onChange={handleSelectChange}
							/>
						</div>
						{serviceType.value === "custom service" && (
							<div className="mb-4">
								<Label>Установите ссылку на внешний сервис авторизации</Label>
								<Input className="form-control" type="text" placeholder="" value={serviceLink} onChange={(e) => setServiceLink(e.target.value)} />
							</div>
						)}
					</Col>
					<div className="f-right">
						<Btn attrBtn={{ color: "primary", onClick: setSiteAuth, className: "mt-4" }}>Сохранить</Btn>
					</div>
				</>
			)}
		</>
	);
};

export default SettingsAuth;
