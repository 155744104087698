import React from 'react';

import { H6 } from '../../../../../AbstractElements';

const LightCardBox = ({ data }) => {
	function getDayOfWeek(dateString) {
		const date = new Date(dateString);
		if (isNaN(date.getTime())) {
			return 'error';
		}

		const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
		const dayIndex = date.getDay();

		return daysOfWeek[dayIndex];
	}

	function formattedDate(dateString) {
		const date = new Date(dateString);
		if (isNaN(date.getTime())) {
			return 'Некорректная дата';
		}

		const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
		const day = date.getDate();
		const monthIndex = date.getMonth();
		const year = date.getFullYear();

		const formattedDate = `${day} ${months[monthIndex]} ${year}`;

		return formattedDate;
	}
	return (
		<div className="light-card balance-card widget-hover" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
			<div className="svg-box">
				<H6 attrH6={{ className: 'mt-1 mb-0' }}>{getDayOfWeek(data.timestamp)}</H6>
			</div>
			<div style={{ flexGrow: '1' }}>
				<span className="font-primary">{formattedDate(data.timestamp)}</span>
			</div>
			<div>
				<span className="f-light">{data.comment}</span>
			</div>
			<div>
				<span className="f-light">{data.amount} ₽</span>
			</div>
		</div>
	);
};

export default LightCardBox;
