import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs, P, H5 } from '../../../AbstractElements';
import { CardBody, CardHeader, Card } from 'reactstrap';
import TableRequestFromClients from './TableRequestFromClients';

const RequestFromClients = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Заявки от клиентов" parent="Заявки" title="Заявки от клиентов" />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardHeader>
							<H5 attrH5={{ className: 'mb-2' }}>Заявки</H5>
							<p className="font-secondary mt-2">Для просмотра подробной информации по заявке, нажмите на её номер.</p>
						</CardHeader>
						<CardBody className="chat-body">
							<TableRequestFromClients />
						</CardBody>
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};
export default RequestFromClients;
