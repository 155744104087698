import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import {Trash2, Edit, Settings} from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import FilterComponent from '../../FilterComponent';
import {useModulesContext} from "../../../../../_helper/Modules/ModulesProvider";
import {useKeycloak} from "@react-keycloak/web";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import DataTableGroupUsers from "./DataTableGroupUsers";
import {People} from "@mui/icons-material";
import Select from "react-select";

const DataTableManageCompanyGroups = () => {
    const { keycloak } = useKeycloak();
    const { getCompanySubgroups, companySubGroups, deleteGroupById, createCustomSubgroup, companyData } = useCompanyContext();
    const { companySelectedModulesRules, getCompanySelectedModulesRules, createModuleGroup } = useModulesContext();

    const [modal, setModal] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState(companySubGroups);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [groupId, setGroupId] = useState('');
    const [groupModuleModal, setGroupModuleModal] = useState(false);
    const [groupModal, setGroupModal] = useState(false);
    const [moduleRuleId, setModuleRuleId] = useState('');
    const [selectGroupName, setSelectGroupName] = useState('');

    const [modulesRulesOptions, setModulesRulesOptions] = useState([]);

    const [groupName, setGroupName] = useState('');

    useEffect(() => {
        companySubGroups && setFilteredItems(companySubGroups.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText, companySubGroups]);

    useEffect(() => {
        companySelectedModulesRules && setModulesRulesOptions(companySelectedModulesRules.map((item) => ({label: item.displayName, value: item.id})));
    }, [companySelectedModulesRules]);

    useEffect(() => {
        getCompanySubgroups();
        getCompanySelectedModulesRules();
    }, [keycloak.tokenParsed]);

    const toggle = () => {
        setGroupName('');

        setModal(!modal);
    };

    const toggleGroup = (groupId) => {
        setGroupModal(!groupModal);
        setGroupId(groupId);
    }

    const toggleModuleGroup = (groupName) => {
        setGroupModuleModal(!groupModuleModal);
        setSelectGroupName(groupName);
        setModuleRuleId('');
    }

    const handleAddRow = () => {
        if (groupName !== '') {
            setModal(!modal);
            createCustomSubgroup(companyData[0].id, groupName);
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const handleAddRuleRow = () => {
        if (selectGroupName !== '') {
            toggleModuleGroup('');
            createModuleGroup(moduleRuleId, companyData[0]?.attributes?.companyId[0], selectGroupName);
        }
        else {
            toast.error('Заполните все поля!', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const deleteRow = (remId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'Данное действие не отменит отправку оповщений!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then(() => {
            deleteGroupById(remId);
        });
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const tableColumns = [
        {
            name: 'Название',
            selector: (row) => `${row.name}`,
            sortable: true,
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{display: 'flex'}}>
                    <Tooltip title="Установить правило" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => toggleModuleGroup(row.name)}>
                            <Settings size={'18px'}/>
                        </div>
                    </Tooltip>
                    <Tooltip title="Управление пользователями" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => toggleGroup(row.id)}>
                            <People size={'18px'}/>
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{marginLeft: '7px', cursor: 'pointer'}}
                             onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'}/>
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавление новой группы компании'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Название группы
                                </Label>
                                <Col sm="11">
                                    <Input className="form-control" value={groupName} placeholder="Название группы" onChange={(e) => setGroupName(e.target.value)} />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <CommonModal isOpen={groupModuleModal} title={'Добавление правила для группы'} toggler={handleAddRuleRow} togglerClose={toggleModuleGroup} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column'}}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Правило
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={modulesRulesOptions} onChange={(e) => setModuleRuleId(e.value)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <CommonModal isOpen={groupModal} title={'Пользователи компании'} toggler={handleAddRow} togglerClose={toggleGroup} closeText="Отмена" saveText="Сохранить">
                <DataTableGroupUsers groupId={groupId}/>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTableManageCompanyGroups;
