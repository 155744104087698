import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import axios from 'axios';
import Select from 'react-select';
import { Breadcrumbs, Badges, H5, Btn } from '../../../AbstractElements';
import { Label, Col, Row } from 'reactstrap';
import TableService from './TableService';

import { ServicesApi } from '../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {concatResults} from "../../../concatResults";
import { useCompanyContext } from '../../../_helper/Company/CompanyProvider';

const Services = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);
	const {keycloak} = useKeycloak();
	const {companyData} = useCompanyContext

	useEffect(() => {
		axios
			.get(`${ServicesApi}getServices`, { params: { query: "" }, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
			.then((response) => {
				console.log(response);
				setFilteredProducts(concatResults(response).services);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список услуг" parent="Услуги" title="Список услуг" />
			<Container fluid={true}>
				<Card>
					<CardBody>{filteredProducts && <TableService filteredProducts={filteredProducts} />}</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default Services;
