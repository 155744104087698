const MENUITEMS = (userId) => {

	const menuitems = [
		{
			menutitle: "Главная",
			menucontent: "Dashboards,Widgets",
			role: "Developer",
			Items: [
				{
					title: "Панель приборов",
					icon: "home",
					type: "sub",
					badge: "badge badge-light-primary",
					badgetxt: "5",
					active: false,
					role: "Developer",
					children: [
						{
							path: `${process.env.PUBLIC_URL}/dashboard/default`,
							title: "По умолчанию",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/dashboard/e-commerce`,
							title: "Электронная коммерция",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/dashboard/online-course`,
							title: "Онлайн-курс",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/dashboard/crypto`,
							title: "Крипто",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/dashboard/social`,
							title: "Социальное",
							type: "link",
						},
					],
				},

				{
					title: "Виджеты",
					icon: "widget",
					type: "sub",
					active: false,
					role: "Developer",
					children: [
						{
							path: `${process.env.PUBLIC_URL}/widgets/general`,
							title: "Главная",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/widgets/chart`,
							title: "Диаграмма",
							type: "link",
						},
					],
				},
			],
		},
		{
			menutitle: "Личный кабинет",
			menucontent: "Ready to use Apps",
			roles: [
				"twinauth-api"
			],
			Items: [
				{
					title: "Профиль",
					icon: "user",
					path: `${process.env.PUBLIC_URL}/app/users/profile${userId ? `?userId=${userId}` : ""}`,
					type: "sub",
					bookmark: true,
					active: false,
					roles: [
						"twinauth-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/users/profile${userId ? `?userId=${userId}` : ""}`,
							type: "link",
							title: "Мой профиль",
							roles: [
								"twinauth-api",
								"education-api",
								"calendar-api",
								"news-api"
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/app/users/edit`,
							type: "link",
							title: "Редактировать профиль",
							roles: [
								"twinauth-api"
							]
						},
						//{ path: `${process.env.PUBLIC_URL}/app/users/cards`, type: "link", title: "Карты пользователя" },
					],
				},
				{
					title: "Проекты",
					icon: "project",
					type: "sub",
					// badge: 'badge badge-light-secondary',
					// badgetxt: 'New',
					active: false,
					roles: [
						"twinauth-api",
						"projects-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/project/project-list`,
							type: "link",
							title: "Список проектов",
							roles: [
								"twinauth-api",
								"projects-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/project/edit-project`,
							type: "link",
							title: "Создать новый проект",
							roles: [
								"twinauth-api",
								"projects-api"
							]
						},
					],
				},
				{
					title: "Лиды",
					icon: "project",
					type: "sub",
					// badge: 'badge badge-light-secondary',
					// badgetxt: 'New',
					active: false,
					roles: [
						"twinauth-api",
						"projects-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/project/project-list?type=Lead`,
							type: "link",
							title: "Список лидов",
							roles: [
								"twinauth-api",
								"projects-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/project/edit-project?type=Lead`,
							type: "link",
							title: "Создать новый лид",
							roles: [
								"twinauth-api",
								"projects-api"
							]
						},
					],
				},
				{
					title: "Сделки",
					icon: "project",
					type: "sub",
					// badge: 'badge badge-light-secondary',
					// badgetxt: 'New',
					active: false,
					roles: [
						"twinauth-api",
						"projects-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/project/project-list?type=Deal`,
							type: "link",
							title: "Список сделок",
							roles: [
								"twinauth-api",
								"projects-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/project/edit-project?type=Deal`,
							type: "link",
							title: "Создать новую сделку",
							roles: [
								"twinauth-api",
								"projects-api"
							]
						},
					],
				},
				{
					path: `${process.env.PUBLIC_URL}/app/task`,
					icon: "task",
					type: "link",
					title: "Задачи",
					"roles": [
						"twinauth-api",
						"projects-api",
						"tasks-api"
					]
				},
				{
					icon: "calendar",
					type: "sub",
					title: "Календари",
					"roles": [
						"twinauth-api",
						"calendar-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/calendar/calendar-list`,
							title: "Список календарей",
							type: "link",
							"roles": [
								"twinauth-api",
								"calendar-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/calendar/personal-calendar`,
							title: "Личный календарь",
							type: "link",
							"roles": [
								"twinauth-api",
								"calendar-api",
								"demands-api"
							],
						},
					],
				},
				{
					title: "Чат",
					icon: "chat",
					type: "sub",
					"roles": [
						"twinauth-api",
						"chat-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/chat-app/chats?type=users`,
							type: "link",
							title: "Личные чаты",
							"roles": [
								"twinauth-api",
								"chat-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/chat-app/chats?type=groups`,
							type: "link",
							title: "Групповые чаты",
							"roles": [
								"twinauth-api",
								"chat-api"
							]
						},
					],
				},
				{
					title: "Почта",
					icon: "email",
					type: "sub",
					"roles": [
						"twinauth-api",
						"emails-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/email-app`,
							title: "Моя почта",
							type: "link",
							"roles": [
								"twinauth-api",
								"emails-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/mail-settings`,
							title: "Настройки обработки почты",
							type: "link",
							"roles": [
								"twinauth-api",
								"emails-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/corporate-mail-api`,
							title: "Управление доменной почтой",
							type: "link",
							"roles": [
								"twinauth-api",
								"emails-api"
							]
						},
					],
				},
				{
					icon: "clients",
					type: "sub",
					title: "Группы",
					"roles": [
						"twinauth-api",
						"groups-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/list-groups`,
							title: "Список групп",
							type: "link",
							"roles": [
								"twinauth-api",
								"groups-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/add-group`,
							title: "Добавить группу",
							type: "link",
							"roles": [
								"twinauth-api",
								"groups-api"
							]
						},
					],
				},
				{
					icon: "file",
					type: "sub",
					title: "Работа с файлами",
					"roles": [
						"twinauth-api",
						"storage-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/file-manager`,
							title: "Управление файлами",
							type: "link",
							"roles": [
								"twinauth-api",
								"storage-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/tariff-manager`,
							title: "Управление тарифами",
							type: "link",
							"roles": [
								"twinauth-api",
								"storage-api",
								"news-api"
							]
						},
					],
				},
			],
		},
		{
			menutitle: "Пользователи",
			menucontent: "Ready to use Apps",
			roles: [
				"twinauth_api"
			],
			Items: [
				{
					title: "Админ",
					icon: "admin",
					type: "sub",
					bookmark: true,
					active: false,
					"subGroups": [
						"Admin"
					],
					"roles": [
						"twinauth-api"
					],
					children: [
						{
							title: "Мастер входа",
							type: "sub",
							"roles": [
								"twinauth-api",
								"conpages-api"
							],
							children: [
								{
									path: `${process.env.PUBLIC_URL}/editor-master-enter`,
									type: "link",
									title: "Редактор мастера входа",
									"roles": [
										"twinauth-api",
										"conpages-api"
									]
								},
								{
									path: `${process.env.PUBLIC_URL}/editor-menu-master-enter`,
									type: "link",
									title: "Редактор меню мастера входа",
									"roles": [
										"twinauth-api",
										"conpages-api"
									]
								},
							],
						},
						{
							title: "Управление платформой компании",
							type: "sub",
							"roles": [
								"twinauth-api"
							],
							children: [
								{
									path: `${process.env.PUBLIC_URL}/platform-settings`,
									type: "link",
									title: "Настройки платформы",
									"roles": [
										"twinauth-api"
									]
								},
								{
									path: `${process.env.PUBLIC_URL}/profile-settings`,
									type: "link",
									title: "Настройки отображения профилей пользователей",
									"roles": [
										"twinauth-api"
									]
								},
								{
									path: `${process.env.PUBLIC_URL}/business-process-template/select-modules`,
									type: "link",
									title: "Управления подключенными модулями",
									"roles": [
										"twinauth-api",
										"modules-api"
									]
								},
								{
									path: `${process.env.PUBLIC_URL}/reminders`,
									type: "link",
									title: "Уведомления",
									"roles": [
										"twinauth-api",
										"reminder-api"
									]
								},
							],
						},
						{
							title: "Экспорт данных",
							type: "sub",
							"roles": [
								"twinauth-api",
								"exportDataService-api"
							],
							children: [
								{
									path: `${process.env.PUBLIC_URL}/export-data/services-fields-settings`,
									type: "link",
									title: "Настройка сервисов и полей для экспорта",
									"roles": [
										"twinauth-api",
										"exportDataService-api"
									]
								},
								{
									path: `${process.env.PUBLIC_URL}/export-data/responses-settings`,
									type: "link",
									title: "Настройка файлов экспорта для получателей",
									"roles": [
										"twinauth-api",
										"exportDataService-api"
									]
								},
								{
									path: `${process.env.PUBLIC_URL}/export-data/fields-maps-settings`,
									type: "link",
									title: "Настройка маппинга полей сервисов и получателей",
									"roles": [
										"twinauth-api",
										"exportDataService-api"
									]
								},
								{
									path: `${process.env.PUBLIC_URL}/export-data/file-datatypes`,
									type: "link",
									title: "Справочник типов файлов данных",
									"roles": [
										"twinauth-api",
										"exportDataService-api"
									]
								},
								{
									path: `${process.env.PUBLIC_URL}/export-data/layout-datatypes`,
									type: "link",
									title: "Справочник шаблонов экспортируемых файлов",
									"roles": [
										"twinauth-api",
										"exportDataService-api"
									]
								}
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/information-about-modules`,
							type: "link",
							title: "Управление информацией о модулях платформы",
							"roles": [
								"twinauth-api",
								"modules-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/industry-solutions`,
							type: "link",
							title: "Управление информацией об отраслевых решениях платформы",
							"roles": [
								"twinauth-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/plugin-templates`,
							type: "link",
							title: "Управление информацией о шаблонах подключаемых модулей",
							"roles": [
								"twinauth-api",
								"modules-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/companies`,
							type: "link",
							title: "Компании",
							"roles": [
								"twinauth-api",
								"company-api"
							]
						},
					],
				},
				{
					title: "Сотрудники",
					icon: "staff",
					type: "sub",
					"roles": [
						"twinauth-api",
						"employee-management-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/employees`,
							type: "link",
							title: "Список сотрудников",
							"roles": [
								"twinauth-api",
								"employee-management-api",
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/department-management`,
							type: "link",
							title: "Управление отделами",
							"roles": [
								"twinauth-api",
								"employee-management-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/qualifications`,
							type: "link",
							title: "Квалификации",
							"roles": [
								"twinauth-api",
								"employee-management-api",
								"taskspayment-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/task-reports`,
							type: "link",
							title: "Управление счетами и актами по задачам",
							"roles": [
								"twinauth-api",
								"employee-management-api",
								"taskspayment-api"
							]
						},
					],
				},
				{
					icon: "phone",
					type: "sub",
					title: "Телефония",
					"roles": [
						"twinauth-api",
						"telephony-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/telephony-app/pbx-list`,
							title: "Список АТС",
							type: "link",
							"roles": [
								"twinauth-api",
								"telephony-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/telephony-app/users`,
							title: "Пользователи телефонии",
							type: "link",
							"roles": [
								"twinauth-api",
								"telephony-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/telephony-app/call-statistics`,
							title: "Статистика звонков",
							type: "link",
							"roles": [
								"twinauth-api",
								"telephony-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/telephony-app/access-rights`,
							title: "Права доступа",
							type: "link",
							"roles": [
								"twinauth-api",
								"telephony-api"
							]
						},
					],
				},
				{
					title: "Новости",
					icon: "news",
					type: "sub",
					"roles": [
						"twinauth-api",
						"news-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/news`,
							type: "link",
							title: "Управление новостями",
							"roles": [
								"twinauth-api",
								"news-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/news/news-template`,
							type: "link",
							title: "Шаблоны новостей",
							"roles": [
								"twinauth-api",
								"news-api"
							]
						},
					],
				},
				{
					title: "Расчёт зарплат",
					icon: "money",
					type: "sub",
					active: false,
					"roles": [
						"twinauth-api",
						"employee-payrolls-api"
					],
					children: [
						//{ path: `${process.env.PUBLIC_URL}/salary/types-of-cards`, title: 'Взаиморасчеты', type: 'link' },
						{
							path: `${process.env.PUBLIC_URL}/salary/calculation-per-day`,
							title: "Расчет за день",
							type: "link",
							"roles": [
								"twinauth-api",
								"employee-payrolls-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/salary/calculation-per-period`,
							title: "Расчет за период",
							type: "link",
							"roles": [
								"twinauth-api",
								"employee-payrolls-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/salary/calculation-rules`,
							title: "Правила расчета",
							type: "link",
							"roles": [
								"twinauth-api",
								"employee-payrolls-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/salary/calculation-criteria`,
							title: "Критерии расчета",
							type: "link",
							"roles": [
								"twinauth-api",
								"employee-payrolls-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/salary/calculation-schemes`,
							title: "Схемы расчета",
							type: "link",
							"roles": [
								"twinauth-api",
								"education-api",
								"employee-payrolls-api"
							]
						},
					],
				},
			],
		},
		{
			menutitle: "Сайты",
			menucontent: "Сайты",
			"roles": [
				"twinauth-api",
				"cms-api"
			],
			Items: [
				{
					title: "Конструктор сайтов",
					icon: "world",
					type: "sub",
					"roles": [
						"twinauth-api",
						"cms-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/sites-list`,
							type: "link",
							title: "Список сайтов",
							"roles": [
								"twinauth-api",
								"cms-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/installed-templates`,
							type: "link",
							title: "Список установленных шаблонов",
							"roles": [
								"twinauth-api",
								"cms-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/sites-template-catalog`,
							type: "link",
							title: "Каталог шаблонов сайтов",
							"roles": [
								"twinauth-api",
								"cms-api"
							]
						},
					],
				}
			]
		},
		{
			menutitle: "Виджеты",
			menucontent: "Виджеты",
			"roles": [
				"twinauth-api",
				"imaps-api"
			],
			Items: [
				{
					title: "Интерактивные план-карты",
					icon: "world",
					type: "sub",
					"roles": [
						"twinauth-api",
						"imaps-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/widgets/interactive-plan-maps`,
							type: "link",
							title: "Интерактивные план-карты",
							"roles": [
								"twinauth-api",
								"imaps-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/widgets/interactive-plan-maps-objects-types`,
							type: "link",
							title: "Список типов объектов",
							"roles": [
								"twinauth-api",
								"imaps-api"
							]
						},
					]
				}
			]
		},
		{
			menutitle: "Глобальное управление платформой",
			menucontent: "Ready to use Apps",
			"roles": [
				"twinauth-api",
				"global_admin"
			],
			Items: [
				{
					icon: "admin",
					type: "sub",
					title: "Администрирование",
					"roles": [
						"twinauth-api",
						"global_admin"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/administration/modules-right-access`,
							title: "Права доступа к разделам модулей",
							type: "link",
							"roles": [
								"twinauth-api",
								"global_admin"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/administration/modules-user-groups`,
							title: "Управление группами пользователей",
							type: "link",
							"roles": [
								"twinauth-api",
								"global_admin"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/administration/modules-section-access`,
							title: "Установка прав доступа к разделам модулей",
							type: "link",
							"roles": [
								"twinauth-api",
								"global_admin"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/administration/company-user-groups`,
							title: "Управление группами пользователей в компании",
							type: "link",
							"roles": [
								"twinauth-api",
								"global_admin"
							]
						},
					],
				}
			]
		},
		{
			menutitle: "Образовательные ресурсы",
			menucontent: "Ready to use Apps",
			"roles": [
				"twinauth-api",
				"education-api"
			],
			Items: [
				{
					icon: "learning",
					type: "sub",
					title: "Обучение",
					"roles": [
						"twinauth-api",
						"education-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/course-list`,
							title: "Список курсов",
							type: "link",
							"roles": [
								"twinauth-api",
								"education-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/lesson-list`,
							title: "Список занятий",
							type: "link",
							"roles": [
								"twinauth-api",
								"education-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/check-works`,
							title: "Проверка работ",
							type: "link",
							"roles": [
								"twinauth-api",
								"education-api"
							]
						},
					],
				},
				{
					icon: "salary",
					type: "sub",
					title: "Тестирование",
					"roles": [
						"twinauth-api",
						"education-api",
						"testing-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/add-testing`,
							title: "Добавить тестирование или опрос",
							type: "link",
							"roles": [
								"twinauth-api",
								"education-api",
								"testing-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/test-list`,
							title: "Список тестов и опросов",
							type: "link",
							"roles": [
								"twinauth-api",
								"education-api",
								"testing-api"
							]
						},
					],
				},
				{
					icon: "diploma",
					type: "sub",
					title: "Дипломы",
					"roles": [
						"twinauth-api",
						"education-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/diploma-template-catalog`,
							title: "Каталог шаблонов дипломов",
							type: "link",
							"roles": [
								"twinauth-api",
								"education-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/diplomas-issued`,
							title: "Выданные дипломы",
							type: "link",
							"roles": [
								"twinauth-api",
								"education-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/create-diploma`,
							title: "Создать диплом",
							type: "link",
							"roles": [
								"twinauth-api",
								"education-api"
							]
						},
					],
				},
			],
		},
		{
			menutitle: "Продажи",
			menucontent: "Ready to use Apps",
			"roles": [
				"twinauth-api"
			],
			Items: [
				{
					title: "Контрагенты",
					icon: "partner",
					type: "subLink",
					active: false,
					path: `${process.env.PUBLIC_URL}/counterparties/list-counterparties`,
					"roles": [
						"twinauth-api",
						"counterparties_api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/counterparties/list-counterparties`,
							type: "link",
							title: "Список контрагентов",
							"roles": [
								"twinauth-api",
								"counterparties_api"
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/counterparties/counterparties-roles`,
							type: "link",
							title: "Типы контрагентов",
							"roles": [
								"twinauth-api",
								"counterparties_api"
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/counterparties/counterparty-settings`,
							type: "link",
							title: "Настройки карточки контрагента",
							"roles": [
								"twinauth-api",
								"counterparties_api"
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/counterparties/transactions-counterparty`,
							type: "link",
							title: "Просмотр операций с контрагентом",
							"roles": [
								"twinauth-api",
								"counterparties_api",
								"couterparties-statistic-api"
							],
						},
					],
				},
				{
					title: "Спецификации",
					icon: "table",
					type: "sub",
					active: false,
					"roles": [
						"twinauth-api",
						"products-api",
						"specifications-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/specifications/list-specifications`,
							type: "link",
							title: "Список сформированных спецификаций",
							"roles": [
								"twinauth-api",
								"products-api",
								"specifications-api"
							],
						}
					],
				},
				{
					title: "Документооборот",
					icon: "form",
					type: "sub",
					active: false,
					"roles": [
						"twinauth-api",
						"documents-management-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/counterparties/documents`,
							type: "link",
							title: "Документы",
							"roles": [
								"twinauth-api",
								"documents-management-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/counterparties/types-documents`,
							type: "link",
							title: "Справочник типов документов",
							"roles": [
								"twinauth-api",
								"documents-management-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/counterparties/kind-documents`,
							type: "link",
							title: "Справочник видов документов",
							"roles": [
								"twinauth-api",
								"documents-management-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/project/project-list?type=SigningDocuments`,
							type: "link",
							title: "Маршруты согласования",
							"roles": [
								"twinauth-api",
								"documents-management-api",
								"projects-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/counterparties/rights-access`,
							type: "link",
							title: "Права и доступ к модулю",
							"roles": [
								"twinauth-api",
								"documents-management-api",
								"modules-api"
							]
						},
					],
				},
				{
					title: "Клиенты",
					icon: "clients",
					type: "subLink",
					active: false,
					"roles": [
						"twinauth-api",
						"clients-api"
					],
					path: `${process.env.PUBLIC_URL}/app/clients-app/clients-list`,
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/clients-app/clients-list`,
							type: "link",
							title: "Список клиентов",
							"roles": [
								"twinauth-api",
								"clients-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/clients-app/clients-categories-list`,
							type: "link",
							title: "Категории клиентов",
							"roles": [
								"twinauth-api",
								"clients-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/clients-app/clients-types-list`,
							type: "link",
							title: "Типы клиентов",
							"roles": [
								"twinauth-api",
								"clients-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/clients-app/client-card-settings`,
							type: "link",
							title: "Настройки карточки клиента",
							"roles": [
								"twinauth-api",
								"clients-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/clients-app/client-documents-types`,
							type: "link",
							title: "Типы документов",
							"roles": [
								"twinauth-api",
								"clients-api"
							]
						},
					]
				},
				{
					icon: "cart",
					type: "sub",
					title: "Предложения",
					"roles": [
						"twinauth-api",
						"products-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/categories`,
							title: "Список категорий",
							type: "link",
							"roles": [
								"twinauth-api",
								"products-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/catalog`,
							title: "Каталог предложений",
							type: "link",
							"roles": [
								"twinauth-api",
								"products-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/package-offers`,
							title: "Пакетные предложения",
							type: "link",
							"roles": [
								"twinauth-api",
								"products-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/purchase-history`,
							title: "История покупок",
							type: "link",
							"roles": [
								"twinauth-api",
								"products-api"
							]
						},
					],
				},
				{
					title: "Услуги",
					icon: "services",
					type: "sub",
					active: false,
					"roles": [
						"twinauth-api",
						"services-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/service-app/services`,
							type: "link",
							title: "Список услуг",
							"roles": [
								"twinauth-api",
								"services-api"
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/service-app/service-categories`,
							type: "link",
							title: "Категории услуг",
							"roles": [
								"twinauth-api",
								"services-api"
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/service-app/resources`,
							type: "link",
							title: "Ресурсы",
							"roles": [
								"twinauth-api",
								"services-api"
							],
						},
						// { path: `${process.env.PUBLIC_URL}/service-app/assigning-employees`, type: 'link', title: 'Назначение сотрудников на услугу' },
					],
				},
				{
					title: "Заявки",
					icon: "ecommerce",
					type: "sub",
					active: false,
					"roles": [
						"twinauth-api",
						"demands-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/request/form-builder`,
							title: "Конструктор форм",
							type: "link",
							"roles": [
								"twinauth-api",
								"demands-api",
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/app/request/templates-application-form`,
							title: "Шаблоны для формы заявки",
							type: "link",
							"roles": [
								"twinauth-api",
								"demands-api",
								"cms-api"
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/app/request/request-from-clients`,
							title: "Заявки от клиентов",
							type: "link",
							"roles": [
								"twinauth-api",
								"demands-api"
							],
						},
					],
				},
				{
					title: "Доставка",
					icon: "delivery",
					type: "sub",
					active: false,
					"roles": [
						"twinauth-api",
						"delivery-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/delivery/list-of-delivery-orders`,
							title: "Заказы на доставку",
							type: "link",
							"roles": [
								"twinauth-api",
								"delivery-api",
								"demands-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/delivery/form-builder-with-delivery`,
							title: "Конструктор форм заявок c доставкой",
							type: "link",
							"roles": [
								"twinauth-api",
								"delivery-api",
								"demands-api",
								"tasks-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/delivery/delivery-parameters`,
							title: "Параметры доставки",
							type: "link",
							"roles": [
								"twinauth-api",
								"delivery-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/delivery/delivery-services`,
							title: "Стоимость доставки по городам",
							type: "link",
							"roles": [
								"twinauth-api",
								"delivery-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/delivery/delivery-services`,
							title: "Службы доставки",
							type: "link",
							"roles": [
								"twinauth-api",
								"delivery-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/delivery/servers-for-automatic-calculation`,
							title: "Серверы для автоматического расчёта доставки",
							type: "link",
							"roles": [
								"twinauth-api",
								"delivery-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/delivery/parameters-for-optimal-delivery-price`,
							title: "Настройка параметров выбора оптимальной цены доставки",
							type: "link",
							"roles": [
								"twinauth-api",
								"delivery-api"
							]
						},
					],
				},
				{
					title: "Лояльность",
					icon: "loyalty",
					type: "sub",
					active: false,
					"roles": [
						"twinauth-api",
						"client-loyalty-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/loyalty/types-of-cards`,
							title: "Типы карт",
							type: "link",
							"roles": [
								"twinauth-api",
								"client-loyalty-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/loyalty/loyalty-cards`,
							title: "Карты лояльности",
							type: "link",
							"roles": [
								"twinauth-api",
								"client-loyalty-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/loyalty/discounts`,
							title: "Акции",
							type: "link",
							"roles": [
								"twinauth-api",
								"client-loyalty-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/loyalty/referral-program`,
							title: "Реферальная программа",
							type: "link",
							"roles": [
								"twinauth-api",
								"client-loyalty-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/loyalty/certificates`,
							title: "Сертификаты",
							type: "link",
							"roles": [
								"twinauth-api",
								"client-loyalty-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/loyalty/types-of-certificates`,
							title: "Типы сертификатов",
							type: "link",
							"roles": [
								"twinauth-api",
								"client-loyalty-api"
							]
						},
					],
				},
				{
					title: "Склад",
					icon: "others",
					type: "sub",
					active: false,
					"roles": [
						"twinauth-api",
						"stockrooms-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/storage/storage-list`,
							type: "link",
							title: "Список складов",
							"roles": [
								"twinauth-api",
								"stockrooms-api"
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/storage/technological-card`,
							type: "link",
							title: "Технологические карты",
							"roles": [
								"twinauth-api",
								"stockrooms-api"
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/storage/storage-operations`,
							type: "link",
							title: "Складские операции",
							"roles": [
								"twinauth-api",
								"stockrooms-api"
							],
						},
					],
				}
			],
		},
		{
			menutitle: "Бизнес-процессы",
			menucontent: "Ready to use Apps",
			"roles": [
				"twinauth-api",
				"business-processes-api"
			],
			Items: [
				{
					title: "Аналитика",
					icon: "analytics",
					type: "sub",
					active: false,
					"roles": [
						"twinauth-api",
						"analytics-api",
						"business-processes-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/analytics/key-indicators`,
							type: "link",
							title: "Основные показатели",
							"roles": [
								"twinauth-api",
								"analytics-api",
								"business-processes-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/analytics/reports`,
							type: "link",
							title: "Отчёты",
							"roles": [
								"twinauth-api",
								"analytics-api",
								"moneyManagement-api",
								"business-processes-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/analytics/settings`,
							type: "link",
							title: "Настройки",
							"roles": [
								"twinauth-api",
								"analytics-api",
								"business-processes-api"
							]
						},
					],
				},
				{
					title: "Диаграммы Бизнес-Процессов",
					icon: "learn",
					type: "sub",
					active: false,
					"roles": [
						"twinauth-api",
						"business-processes-api"
					],
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/business-processes/create-diagram`,
							title: "Добавить диаграмму БП",
							type: "link",
							"roles": [
								"twinauth-api",
								"business-processes-api"
							]
						},
						{
							path: `${process.env.PUBLIC_URL}/app/business-processes/list`,
							title: "Список Бп",
							type: "link",
							"roles": [
								"twinauth-api",
								"business-processes-api"
							]
						},
					],
				},
			],
		},
		{
			menutitle: "Приложения",
			menucontent: "Ready to use Apps",
			role: "Developer",
			Items: [
				{
					title: "Ecommerce",
					icon: "ecommerce",
					role: "Developer",
					type: "sub",
					active: false,
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/ecommerce/product`,
							title: "Products",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/ecommerce/product-page/1`,
							title: "Product-Page",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/ecommerce/product-list`,
							title: "Product-List",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/ecommerce/payment-details`,
							title: "Payment-Detail",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/ecommerce/orderhistory`,
							title: "OrderHistory",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/ecommerce/invoice`,
							title: "Invoice",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/ecommerce/cart`,
							title: "Cart",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/ecommerce/wishlist`,
							title: "Wishlist",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/checkout`,
							title: "checkout",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/ecommerce/pricing`,
							title: "Pricing",
							type: "link",
						},
					],
				},
				{
					path: `${process.env.PUBLIC_URL}/app/email-app`,
					role: "Developer",
					icon: "email",
					title: "Email",
					type: "link",
				},

				{
					path: `${process.env.PUBLIC_URL}/app/bookmark`,
					role: "Developer",
					icon: "bookmark",
					type: "link",
					title: "Закладка",
				},
				{
					path: `${process.env.PUBLIC_URL}/app/social-app`,
					role: "Developer",
					icon: "social",
					type: "link",
					title: "Социальные приложения",
					bookmark: true,
				},
				{
					path: `${process.env.PUBLIC_URL}/app/todo-app/todo`,
					icon: "to-do",
					type: "link",
					role: "Developer",
					title: "Список дел",
				},
				{
					path: `${process.env.PUBLIC_URL}/app/search`,
					role: "Developer",
					icon: "search",
					type: "link",
					title: "Результат поиска",
				},
			],
		},
		{
			menutitle: "Формы и таблицы",
			role: "Developer",
			menucontent: "Ready to use froms & tables",
			Items: [
				{
					title: "Forms",
					icon: "form",
					role: "Developer",
					type: "sub",
					menutitle: "Forms & Table",
					menucontent: "Ready to use froms & tables",
					active: false,
					children: [
						{
							title: "Controls",
							type: "sub",
							children: [
								{
									title: "Validation",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/controls/validation`,
								},
								{
									title: "Input",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/controls/input`,
								},
								{
									title: "Radio-Checkbox",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/controls/radio-checkbox`,
								},
								{
									title: "Group",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/controls/group`,
								},
								{
									title: "MegaOption",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/controls/megaoption`,
								},
							],
						},
						{
							title: "Widget",
							type: "sub",
							children: [
								{
									title: "Datepicker",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/widget/datepicker`,
								},
								{
									title: "Datetimepicker",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/widget/datetimepicker`,
								},
								{
									title: "Touchspin",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/widget/touchspin`,
								},
								{
									title: "Select2",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/widget/select2`,
								},
								{
									title: "Switch",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/widget/switch`,
								},
								{
									title: "Typeahead",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/widget/typeahead`,
								},
								{
									title: "Clipboard",
									type: "link",
									path: `${process.env.PUBLIC_URL}/forms/widget/clipboard`,
								},
							],
						},
						{
							title: "Layout",
							type: "sub",
							children: [
								{
									path: `${process.env.PUBLIC_URL}/forms/layout/formdefault`,
									title: "FormDefault",
									type: "link",
								},
								{
									path: `${process.env.PUBLIC_URL}/forms/layout/formwizard`,
									title: "FormWizard",
									type: "link",
								},
							],
						},
					],
				},

				{
					title: "Table",
					role: "Developer",
					icon: "table",
					type: "sub",
					children: [
						{
							title: "ReactstrapTable",
							type: "link",
							path: `${process.env.PUBLIC_URL}/table/reactstraptable/basictable`,
						},
						{
							title: "DataTable",
							path: `${process.env.PUBLIC_URL}/table/datatable`,
							type: "link",
						},
					],
				},
			],
		},

		{
			menutitle: "Компоненты",
			menucontent: "UI Components & Elements",
			role: "Developer",
			Items: [
				{
					title: "Ui-Kits",
					icon: "ui-kits",
					type: "sub",
					active: false,
					role: "Developer",
					children: [
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/typography`,
							title: "Typography",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/avatar`,
							title: "Avatar",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/helper-class`,
							title: "Helper-Class",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/grids`,
							title: "Grids",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills`,
							title: "Tag-Pills",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/progress`,
							title: "Progress",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/modal`,
							title: "Modal",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/alert`,
							title: "Alert",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/popover`,
							title: "Popover",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/tooltips`,
							title: "Tooltips",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/spinner`,
							title: "Spinner",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/dropdown`,
							title: "Dropdown",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/accordion`,
							title: "Accordion",
							type: "link",
						},
						{
							title: "Tabs",
							type: "sub",
							children: [
								{
									title: "Bootstrap",
									type: "link",
									path: `${process.env.PUBLIC_URL}/ui-kits/tabs/bootstrap`,
								},
								{
									title: "Line",
									type: "link",
									path: `${process.env.PUBLIC_URL}/ui-kits/tabs/line`,
								},
							],
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/shadow`,
							title: "Shadow",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/ui-kits/list`,
							title: "List",
							type: "link",
						},
					],
				},

				{
					title: "Bonus-Ui",
					icon: "bonus-kit",
					type: "sub",
					badge1: true,
					active: false,
					role: "Developer",
					children: [
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`,
							title: "Scrollable",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`,
							title: "Bootstrap-Notify",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/tree-view`,
							title: "Tree View",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/rating`,
							title: "Rating",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`,
							title: "Dropzone",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/tour`,
							title: "Tour ",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/sweet-alert`,
							title: "Sweet-Alert",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`,
							title: "Carousel",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`,
							title: "Ribbons",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`,
							title: "Pagination",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`,
							title: "Breadcrumb",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/rangeslider`,
							title: "RangeSlider",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/imagecropper`,
							title: "ImageCropper",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/stickynotes`,
							title: "StickyNotes",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/drag_and_drop`,
							title: "Drag_and_Drop",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`,
							title: "Image-Upload",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/card/basiccards`,
							title: "BasicCards",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativecards`,
							title: "CreativeCards",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabcard`,
							title: "TabCard",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`,
							title: "Timeline1",
							type: "link",
						},
					],
				},

				{
					title: "Icons",
					icon: "icons",
					path: `${process.env.PUBLIC_URL}/icons/flag_icons`,
					type: "sub",
					active: false,
					role: "Developer",
					bookmark: true,
					children: [
						{
							path: `${process.env.PUBLIC_URL}/icons/flag_icons`,
							title: "Flag Icon",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/icons/fontawesome_icon`,
							title: "Fontawesome Icon",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/icons/ico_icon`,
							title: "Ico Icon",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/icons/themify_icons`,
							title: "Themify Icon",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/icons/feather_icons`,
							title: "Feather Icon",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/icons/weather_icons`,
							title: "Weather Icons",
							type: "link",
						},
					],
				},

				{
					title: "Buttons",
					icon: "button",
					type: "sub",
					role: "Developer",
					active: false,
					children: [
						{
							path: `${process.env.PUBLIC_URL}/buttons/simplebutton`,
							title: "SimpleButton",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/buttons/flat`,
							title: "Flat",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/buttons/edge`,
							title: "Edge",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/buttons/raised`,
							title: "Raised",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/buttons/group`,
							title: "Group",
							type: "link",
						},
					],
				},

				{
					title: "Charts",
					icon: "charts",
					type: "sub",
					role: "Developer",
					active: false,
					children: [
						{
							path: `${process.env.PUBLIC_URL}/charts/apex`,
							type: "link",
							title: "Apex",
						},
						{
							path: `${process.env.PUBLIC_URL}/charts/google`,
							type: "link",
							title: "Google",
						},
						{
							path: `${process.env.PUBLIC_URL}/charts/chartjs`,
							type: "link",
							title: "Chartjs",
						},
					],
				},
			],
		},
		{
			menutitle: "Pages",
			menucontent: "All neccesory pages added",
			role: "Developer",
			Items: [
				{
					icon: "sample-page",
					badge2: true,
					active: false,
					path: `${process.env.PUBLIC_URL}/pages/sample-page`,
					title: "Sample-Page",
					role: "Developer",
					type: "link",
				},
				{
					title: "Others",
					icon: "others",
					type: "sub",
					role: "Developer",
					children: [
						{
							title: "Error Pages",
							type: "sub",
							children: [
								{
									title: "Error Page 1",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/errors/error400`,
								},
								{
									title: "Error Page 2",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/errors/error401`,
								},
								{
									title: "Error Page 3",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/errors/error403`,
								},
								{
									title: "Error Page 4",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/errors/error404`,
								},
								{
									title: "Error Page 5",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/errors/error500`,
								},
								{
									title: "Error Page 6",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/errors/error503`,
								},
							],
						},
						{
							title: "Authentication",
							type: "sub",
							children: [
								{
									title: "Login Simple",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/login-simple`,
								},
								{
									title: "Login with bg image",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/login-bg-img`,
								},
								{
									title: "Login with image two",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/login-img`,
								},
								{
									title: "Login with validation",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/login-validation`,
								},
								{
									title: "Login with tooltip",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/login-tooltip`,
								},
								{
									title: "Login with sweetalert",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/login-sweetalert`,
								},
								{
									title: "Register Simple",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/register-simple`,
								},
								{
									title: "Register with Bg Image",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/register-bg-img`,
								},
								{
									title: "Register with Bg Video",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/register-video`,
								},
								{
									title: "Unloack User",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/unlock-user`,
								},
								{
									title: "Forget Password",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`,
								},
								{
									title: "Reset Password",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/create-pwd`,
								},
								{
									title: "Maintenance",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/authentication/maintenance`,
								},
							],
						},
						{
							title: "Coming Soon",
							type: "sub",
							children: [
								{
									title: "Coming Simple",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/comingsoon/comingsoon`,
								},
								{
									title: "Coming with Bg Video",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/comingsoon/coming-bg-video`,
								},
								{
									title: "Coming with bg Image",
									type: "link",
									path: `${process.env.PUBLIC_URL}/pages/comingsoon/coming-bg-img`,
								},
							],
						},
					],
				},
			],
		},

		{
			menutitle: "Miscellaneous",
			menucontent: "Bouns Pages & Apps",
			role: "Developer",
			Items: [
				{
					title: "Gallery",
					icon: "gallery",
					role: "Developer",
					type: "sub",
					active: false,
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/gallery/grids`,
							title: "Grids",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/gallery/griddesc`,
							title: "GridDesc",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/gallery/masonrys`,
							title: "Masonrys",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/gallery/masonrydesc`,
							title: "MasonryDesc",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/gallery/hover_effect`,
							title: "Hover_Effect",
							type: "link",
						},
					],
				},

				{
					title: "Blog",
					role: "Developer",
					icon: "blog",
					type: "sub",
					active: false,
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/blog/blogdetails`,
							title: "BlogDetails",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/blog/blogsingle`,
							title: "BlogSingle",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/blog/blogpost`,
							title: "BlogPost",
							type: "link",
						},
					],
				},
				{
					path: `${process.env.PUBLIC_URL}/app/faq`,
					role: "Developer",
					icon: "faq",
					type: "link",
					active: false,
					title: "FAQ",
				},
				{
					title: "JobSearch",
					icon: "job-search",
					role: "Developer",
					type: "sub",
					active: false,
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/jobsearch/cardview`,
							title: "CardView",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/jobsearch/joblist`,
							title: "JobList",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/jobsearch/jobdetail`,
							title: "JobDetail",
							type: "link",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/jobsearch/jobapply`,
							title: "JobApply",
							type: "link",
						},
					],
				},

				{
					title: "Map",
					icon: "maps",
					type: "sub",
					role: "Developer",
					active: false,
					children: [
						{
							path: `${process.env.PUBLIC_URL}/app/map/googlemap`,
							type: "link",
							title: "GoogleMap",
						},
						{
							path: `${process.env.PUBLIC_URL}/app/map/pigeonmap`,
							type: "link",
							title: "PigeonMap",
						},
					],
				},
				{
					title: "Editor",
					icon: "editors",
					type: "sub",
					active: false,
					role: "Developer",
					children: [
						{
							path: `${process.env.PUBLIC_URL}/editor/ckeditor`,
							type: "link",
							title: "CKEditor",
						},
						{
							path: `${process.env.PUBLIC_URL}/editor/mdeeditor`,
							type: "link",
							title: "MDEEditor",
						},
						{
							path: `${process.env.PUBLIC_URL}/editor/aceeditor`,
							type: "link",
							title: "ACEEditor",
						},
					],
				},

				{
					path: `${process.env.PUBLIC_URL}/app/knowledgebase`,
					role: "Developer",
					icon: "knowledgebase",
					type: "link",
					active: false,
					title: "Knowledgebase",
				},
				{
					path: `${process.env.PUBLIC_URL}/app/supportticket`,
					role: "Developer",
					icon: "support-tickets",
					type: "link",
					active: false,
					title: "SupportTicket",
				},
			],
		}
	];

	return menuitems;
};

export default MENUITEMS;
