import React, {Fragment, useCallback, useEffect, useMemo, useReducer, useRef, useState} from "react";
import 'react-contexify/ReactContexify.css';
import {CloseSharp, House, Redo, Save, Undo} from "@mui/icons-material";
import {CardBody, Col, Form, FormGroup, Label, Row} from "reactstrap";
import {useKeycloak} from "@react-keycloak/web";
import {useLocation} from "react-router-dom";
import {Render} from "@measured/puck";
import {PuckComponents} from "../DocumentTypeEditor/Components/PuckComponents";
import {useClientsContext} from "../../../../_helper/Clients/ClientsProvider";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {Btn} from "../../../../AbstractElements";

const ClientDocument = ({clientDocumentId, setClientDocumentId}) => {
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const {
        getDocumentTypeCharacteristcs,
        getClientDocument,
        getDocumentType,
        documentType,
        clientDocument,
        getClientDocumentValues,
        clientDocumentValues,
        documentTypeCharacteristics
    } = useClientsContext();
    const [documentTypeObject, setDocumentTypeObject] = useState({});
    const [clientDocumentResult, setClientDocumentResult] = useState(null);
    const [resultIsLoading, setResultIsLoading] = useState(false);

    useEffect(() => {
        clientDocumentId && getClientDocumentValues(clientDocumentId);
        clientDocumentId && getClientDocument(clientDocumentId);
    }, [keycloak.tokenParsed, clientDocumentId, companyData]);

    useEffect(() => {
        clientDocument && getDocumentType(clientDocument.documentTypeId);
        clientDocument && getDocumentTypeCharacteristcs(clientDocument.documentTypeId);
    }, [clientDocument]);

    useEffect(() => {
        documentType && documentType?.formJson && setDocumentTypeObject(JSON.parse(documentType.formJson));
    }, [documentType])

    useEffect( () => {
        if
        (
            Array.isArray(clientDocumentValues) &&
            documentTypeCharacteristics &&
            documentTypeObject
        )
        {
            clientDocumentValues.forEach((item) => {
                let char = documentTypeCharacteristics.find((i) => i.id === item.documentTypeCharacteristicId);
                console.log(char);
                if (char) {
                    let obj = getObject(documentTypeObject.content, char.id);
                    let objZones = getObject(documentTypeObject.zones, char.id);
                    console.log(obj);
                    console.log(objZones);
                    if (obj) {
                        changeValueInObject(obj, char.id, item.value);
                    } else if (objZones) {
                        changeValueInObject(objZones, char.id, item.value);
                    }
                }
            });
            setClientDocumentResult(documentTypeObject);
            setResultIsLoading(false);
        }
    }, [documentTypeCharacteristics, clientDocumentValues, documentTypeObject])

    const getObject = (theObject, id) => {
        let result = null;

        if (Array.isArray(theObject)) {
            theObject?.forEach((content) => {
                if (content.props?.DocumentData?.Characteristic.id === id) {
                    result = content;
                }
            })
        } else {
            if (theObject && typeof(theObject) === 'object') {
                for (const [key, value] of Object.entries(theObject)) {
                    value.forEach((content) => {
                        if (content.props?.DocumentData?.Characteristic.id === id) {
                            result = content;
                        }
                    })
                }
            }
        }

        return result;
    }

    const changeValueInObject = (object, id, value) => {
        console.log(value);
        if (object) {
            documentTypeObject.content.forEach((item) => {
                if (item.props?.DocumentData?.Characteristic.id === id) {
                    item.props.children = value;
                }
            })
            for (const [key, item] of Object.entries(documentTypeObject?.zones)) {
                item.forEach((zone) => {
                    if (zone.props?.DocumentData?.Characteristic.id === id) {
                        zone.props.children = value;
                    }
                })
            }
        }
    }

    const config = {
        categories: {
            layout: {
                title: "Разметка",
                components: ["Columns", "Flex", "VerticalSpace"]
            },
            elements: {
                title: "Элементы",
                components: ["Heading", "RegularText", "BoldText", "Button", "ImageGallery"]
            }
        },
        components: PuckComponents,
    };

    const backToList = () => {
        setClientDocumentId(null);
    }

    return (
        <Fragment>
            <Btn attrBtn={{color: "white", className: "p-2 m-1 d-flex", onClick: () => backToList()}}>
                <CloseSharp style={{display: "block", width: 24, height: 24}}/>
            </Btn>
            {clientDocumentResult && <Render config={config} data={clientDocumentResult}/>}
        </Fragment>
    );
};

export default ClientDocument;