import React, { useEffect, useState } from 'react';
import { Col, Card, CardBody, Row } from 'reactstrap';
import axios from 'axios';
import { useLocation } from 'react-router';
import CourseBox from '../../../Common/CommonWidgets/CourseBox';
import MyCourse from './MyCourse';
import Calender from '../../../Common/CommonWidgets/Calender';
import UpcomingEvents from './UpcomingEvents';
import { EducationApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';
import { concatResults } from '../../../../concatResults';

const UserDashboard = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const { keycloak, initialized } = useKeycloak();
	const { companyData } = useCompanyContext();
	const courseId = queryParams.get('courseId');
	const [courses, setCourses] = useState('');
	const [userCourses, setUserCourses] = useState('');
	const [number, setNumber] = useState(0);
	const [isExecuted, setExecuted] = useState(false);
	const [roles, setRoles] = useState([]);
	const decodedToken = keycloak.tokenParsed;

	useEffect(() => {
		if (isExecuted === false) {
			getStudentCourses();
			getStudentCoursesList();
			setExecuted(true);
		}
		setRoles(decodedToken.realm_access?.roles || []);
	}, []);

	function getStudentCourses() {
		if (initialized && keycloak.authenticated) {
			axios
				.get(`${EducationApi}get-student-courses/${keycloak.tokenParsed?.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setCourses(concatResults(response).courses);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}

	}

	function getStudentCoursesList() {
		if (initialized && keycloak.tokenParsed !== undefined) {
			axios
				.get(`${EducationApi}get-studentcourses-list/${keycloak.tokenParsed?.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setUserCourses(concatResults(response).courses);
					setNumber(number + 1);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
	}

	const CourseBoxData = [
		{
			title: 'Купленные курсы',
			course: courses,
			icon: 'new-order',
			color: 'primary',
		},
		{
			title: 'Текущие курсы',
			course: courses && courses.filter((course) => userCourses && userCourses.find((ucourse) => ucourse.courseId == course.id).passed === false),
			icon: 'course-2',
			color: 'warning',
		},
		{
			title: 'Пройденные курсы',
			course: courses && courses.filter((course) => userCourses && userCourses.find((ucourse) => ucourse.courseId == course.id).passed === true),
			icon: 'course-1',
			color: 'danger',
		},
	];

	return (
		<>
			{!roles.includes('education-api') && !roles.includes('calendar-api') ? null : (
				<Col sm="12">
					<Card>
						<CardBody>
							<Row>
								<Col xxl="8">
									<Row>
										{roles.includes('education-api') &&
											CourseBoxData.map((item, i) => (
												<Col md="12" xl="4" key={i}>
													<CourseBox data={item} />
												</Col>
											))
										}
									</Row>
									{roles.includes('education-api') && courses && (
										<MyCourse courses={courses} userCourse={userCourses} curCourseId={courseId} />
									)}
								</Col>
								<Col xxl="4">
									{roles.includes('calendar-api') && <Calender />}
									{roles.includes('calendar-api') && courses && <UpcomingEvents courses={courses} />}
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			)}
		</>
	);
};

export default UserDashboard;
