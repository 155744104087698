import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../../../AbstractElements';
import { CardBody, CardHeader, Card } from 'reactstrap';
import TableRequestFromClients from './TableRequestFromClients';

const ListDeliveryOrders = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список заказов на доставку" parent="Доставка" title="Список заказов на доставку" />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardHeader>
							<H5 attrH5={{ className: 'mb-2' }}>Заявки</H5>
							<p className="font-secondary mt-2">Для просмотра подробной информации по заявке, нажмите на её номер.</p>
						</CardHeader>
						<CardBody className="chat-body">
							<TableRequestFromClients demandFormId="be8d2463-8945-4b32-b586-5f201521083f" />
						</CardBody>
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};
export default ListDeliveryOrders;
