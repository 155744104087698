import React, { useMemo, useState } from "react";
import { Trash2, Edit } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { Media } from "reactstrap";
import { Link } from "react-router-dom";
import { P } from "../../../../AbstractElements";
import FilterComponent from "./FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";
import { useKeycloak } from "@react-keycloak/web";
import { CompaniesApi } from "../../../../api";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TableEmployees = ({ data, setData, departments }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	var images = require.context("../../../../assets/images", true);

	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	const columns = [
		{
			name: "Имя",
			selector: (row) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<div className="media-size-email" style={{ position: "relative", padding: "5px" }}>
						<Media body className="me-3 rounded-circle img-40" src={row.image ? dynamicImage(row.image) : require("../../../../assets/images/user/7.jpg")} alt="" />
						{row.position === "Administrator" ? (
							<span className="font-warning admin-icon">
								<i className="fa fa-star"></i>
							</span>
						) : (
							""
						)}
					</div>
					<P>
						{row.firstName} {row.lastName}
					</P>
				</div>
			),
			sortable: true,
		},
		{
			name: "Email",
			selector: (row) => `${row.email}`,
			sortable: false,
		},
		{
			name: "Должность",
			selector: (row) => {
				switch (row.position) {
					case "Administrator":
						return "Администратор";
					case "Manager":
						return "Менеджер";
					case "Developer":
						return "Разработчик";
					case "Teacher":
						return "Преподаватель";
					default:
						return row.position;
				}
			},
			sortable: true,
			hide: "md",
		},
		{
			name: "Услуги",
			selector: (row) => (
				<>
					<div style={{ color: "#9d9d9d" }}>
						{row.services ? (
							<div className="font-primary" style={{ cursor: "pointer" }} onClick={() => toggle("row.services")}>
								{row.services.length} услуги
							</div>
						) : (
							"услуги не назначены"
						)}
					</div>
				</>
			),
			sortable: false,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактирование платёжных данных сотрудника" arrow placement="top">
						<Link to={`${process.env.PUBLIC_URL}/app/employee-payment-details?employeeId=${row.id}`}>
							<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }}>
								<Edit size={"18px"} />
							</div>
						</Link>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteTask(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const [modal, setModal] = useState(false);
	const [services, setServices] = useState(false);
	const toggle = (data) => {
		setModal(!modal);
		setServices(data);
	};

	const deleteTask = (userId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${CompaniesApi}delete-user-from-company`, {
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
						params: {
							UserId: keycloak.tokenParsed.sub,
							CompanyId: "2f1f09a5-337b-463a-b522-693e28b49920",
						},
					})
					.then((response) => {
						SweetAlert.fire("Удалено!", "Пользователь был удален из компании.", "success");
						axios
							.get(`${CompaniesApi}get-company-users`, {
								headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
								params: {
									CompanyId: "2f1f09a5-337b-463a-b522-693e28b49920",
								},
							})
							.then((response) => {
								setData(response.data);
							})
							.catch((error) => {
								console.error("Произошла ошибка при обновлении списка:", error);
							});
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении объекта:", error);
					});
			}
		});
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent data={data} setData={setData} departments={departments} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{
					rowsPerPageText: "Строк на странице:",
					rangeSeparatorText: "из",
				}}
				noDataComponent={"В данной таблице ещё нет записей."}
			/>
			<CommonModal isOpen={modal} title="Просмотр услуг" toggler={() => toggle(services)} togglerClose={() => toggle(services)} saveText="Готово" size="xl">
				{/* <CardBody style={{ padding: "0 15px 10px" }}>{services && <TableService filteredProducts={services} />}</CardBody> */}
			</CommonModal>
		</>
	);
};

export default TableEmployees;
