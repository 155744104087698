import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Edit, ExternalLink } from "react-feather";
import { Card, CardBody, Container, CardHeader, Col, Input, Label, Row, InputGroup, InputGroupText } from "reactstrap";
import DatePicker from "react-datepicker";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import ru from "date-fns/locale/ru";
import Select from "react-select";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Breadcrumbs, Btn } from "../../../../../AbstractElements";
import BuildLessonTab from "./BuildLessonTab";
import { EducationApi } from "../../../../../api";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const BuildLesson = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const lessonId = queryParams.get("lessonId");

	const [pageTitle, setPageTitle] = useState("");
	const [editingTitle, setEditingTitle] = useState(false);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const handleClickTitle = () => {
		setEditingTitle(true);
	};

	const handleInputChangeTitle = (event) => {
		setPageTitle(event.target.value);
	};

	const handleInputBlurTitle = () => {
		setEditingTitle(false);
	};

	const [pageDes, setPageDes] = useState("");
	const [editingDes, setEditingDes] = useState(false);

	const handleClickDes = () => {
		setEditingDes(true);
	};

	const handleInputChangeDes = (event) => {
		setPageDes(event.target.value);
	};

	const handleInputBlurDes = () => {
		setEditingDes(false);
	};

	const [data, setData] = useState([]);
	const [link, setLink] = useState([]);
	const [courseId, setCourseId] = useState([]);
	const [keyLesson, setKeyLesson] = useState(false);
	const [periodTime, setPeriodTime] = useState(0);
	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());
	const [order, setOrder] = useState("");

	const [teachers, setTeachers] = useState([]);
	const [students, setStudents] = useState([]);
	const [deadlineTypes, setDeadlineTypes] = useState([]);
	const [deadlineType, setDeadlineType] = useState({});
	const [evaluationTypes, setEvaluationTypes] = useState([]);
	const [evaluationType, setEvaluationType] = useState({});
	const isDeadlineType = (selectedLabel, expectedLabel) => selectedLabel === expectedLabel;
	const handleSelectChange = (selectedOption) => {
		setDeadlineType(selectedOption);
	};
	const handleSelectEvaluation = (selectedOption) => {
		setEvaluationType(selectedOption);
	};

	useEffect(() => {
		axios
			.get(`${EducationApi}get-course-lesson/${lessonId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const foundItem = concatResults(response);
				setData(foundItem);
				setPageTitle(foundItem.name);
				setPageDes(foundItem.description);
				setLink(foundItem.helloLink);
				setCourseId(foundItem.courseId);
				setDeadlineType({ value: foundItem.deadlineType.id, label: foundItem.deadlineType.value });
				setKeyLesson(foundItem.keyLesson);
				setPeriodTime(foundItem.periodTime);
				setEvaluationType({ value: foundItem.evaluationType.id, label: foundItem.evaluationType.value });
				setPeriodStart(new Date(foundItem.periodStart));
				setPeriodEnd(new Date(foundItem.periodEnd));
				setOrder(foundItem.order);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		axios
			.get(`${EducationApi}get-course-teachers?courseId=${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setTeachers(concatResults(response).teachers);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		axios
			.get(`${EducationApi}get-course-students?courseId=${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setStudents(concatResults(response).students);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		axios
			.get(`${EducationApi}get-lesson-deadline-types`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = concatResults(response).lessonDeadlineTypes.map((option) => ({
					label: option.value,
					value: option.id,
				}));
				setDeadlineTypes(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

		axios
			.get(`${EducationApi}get-lesson-evaluation-types`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = concatResults(response).lessonEvaluationTypes.map((option) => ({
					label: option.value,
					value: option.id,
				}));
				setEvaluationTypes(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных(get-lesson-evaluation-types):", error);
			});
	}, []);

	const [loading, setLoading] = useState(false);

	const sendEditRequest = async () => {
		setLoading(true);
		const lesson = {
			id: lessonId,
			name: pageTitle,
			description: pageDes,
			helloLink: link,
			courseId: courseId,
			keyLesson: keyLesson,
			periodTime: periodTime,
			periodStart: periodStart,
			periodEnd: periodEnd,
			order: order,
			deadlineType: {
				Id: deadlineType.value,
				Value: deadlineType.label,
			},
			evaluationType: {
				Id: evaluationType.value,
				Value: evaluationType.label,
			},
		};

		try {
			const response = await axios.put(`${EducationApi}update-course-lesson`, lesson, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			setLoading(false);
			toast.success("Изменения сохранены!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Конструктор занятия" subParent="Список занятий" parent="Обучение" title="Конструктор занятия" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardHeader>
							<Link to={`/app/build-course?courseId=${courseId}`} style={{ fontSize: "20px" }}>
								<i className="fa fa-angle-left" style={{ fontSize: "26px", marginRight: "5px" }}></i>Вернуться к курсу
							</Link>
						</CardHeader>
						<CardBody>
							<Col className="mb-3 m-l-10">
								{editingTitle ? (
									<input
										style={{ width: "100%", height: "34px", fontSize: "28px", padding: "0", margin: "0" }}
										type="text"
										value={pageTitle}
										onChange={handleInputChangeTitle}
										onBlur={handleInputBlurTitle}
										autoFocus
									/>
								) : (
									<h3 onClick={handleClickTitle}>
										{pageTitle}
										<Tooltip title="Для изменения названия, кликните на текст" arrow placement="top">
											<Edit style={{ margin: "0 0 -2px 10px", color: "var(--theme-default)", cursor: "pointer" }} />
										</Tooltip>
									</h3>
								)}
							</Col>
							<Col className="mb-4 m-l-10 mt-4">
								{editingDes ? (
									<input
										style={{ width: "100%", height: "24px", fontSize: "22px", color: "#898989", padding: "0", margin: "0" }}
										type="text"
										value={pageDes}
										onChange={handleInputChangeDes}
										onBlur={handleInputBlurDes}
										autoFocus
									/>
								) : (
									<p className="f-22" onClick={handleClickDes}>
										{pageDes}
										<Tooltip title="Для изменения описания, кликните на текст" arrow placement="top">
											<Edit style={{ margin: "0 0 -2px 10px", color: "var(--theme-default)", cursor: "pointer" }} size={"20px"} />
										</Tooltip>
									</p>
								)}
							</Col>

							<Col sm="4" md="6" className="m-l-10 mb-4">
								<div className="mb-4">
									<Select placeholder="Выберите..." options={deadlineTypes} className="js-example-basic-single col-sm-12" value={deadlineType} onChange={handleSelectChange} />
								</div>
								{deadlineType && isDeadlineType(deadlineType.label, "Задать срок") && (
									<Row className="m-l-5  mt-4">
										<Col xl="6" sm="9">
											<div style={{ display: "flex", alignItems: "flex-end" }}>
												<Label className="m-r-10">С</Label>
												<div className="input-group">
													<DatePicker
														locale={ru}
														className="form-control digits"
														selected={periodStart}
														onChange={(selectedOption) => setPeriodStart(selectedOption)}
														dateFormat="dd.MM.yyyy"
													/>
												</div>
											</div>
										</Col>
										<Col xl="6" sm="9">
											<div style={{ display: "flex", alignItems: "flex-end" }}>
												<Label className="m-r-10">По</Label>
												<div className="input-group">
													<DatePicker
														locale={ru}
														className="form-control digits"
														selected={periodEnd}
														onChange={(selectedOption) => setPeriodEnd(selectedOption)}
														dateFormat="dd.MM.yyyy"
													/>
												</div>
											</div>
										</Col>
									</Row>
								)}
								{deadlineType && isDeadlineType(deadlineType.label, "Задать время выполнения") && (
									<Col xl="6" sm="9" className=" mt-4">
										<Label className="m-r-10 col-md-12">На прохождение задания:</Label>
										<InputGroup>
											<Input className="form-control" type="text" placeholder="" aria-label="" value={periodTime} onChange={(e) => setPeriodTime(e.target.value)} />
											<InputGroupText>{"сек"}</InputGroupText>
										</InputGroup>
									</Col>
								)}
								<Row style={{ alignItems: "center", justifyContent: " space-between" }}>
									<Col className="mb-4 mt-4 " xl="7" sm="9">
										<Label className="m-r-10">Тип оценки</Label>
										<Select
											placeholder="Выберите..."
											options={evaluationTypes}
											className="js-example-basic-single col-sm-12"
											value={evaluationType}
											onChange={handleSelectEvaluation}
										/>
									</Col>
									<Col className="mb-4 mt-4 m-t-30 m-l-10" xl="4" sm="3">
										<div className="checkbox checkbox-primary">
											<Input id="checkbox-primary-1" type="checkbox" checked={keyLesson} onChange={() => setKeyLesson(!keyLesson)} />
											<Label for="checkbox-primary-1">
												Ключевое занятие
												<Tooltip title="Следующее занятие в курсе не будет доступно, пока не выполнится это" arrow placement="top">
													<i className="fa fa-info-circle" style={{ color: "#8989a8", fontSize: "18px", marginLeft: "7px", cursor: "pointer" }}></i>
												</Tooltip>
											</Label>
										</div>
									</Col>
								</Row>
							</Col>

							<Col className="mb-2 m-l-10">
								<Link to={`${process.env.PUBLIC_URL}${link}`}>
									Перейти на страницу занятия
									<ExternalLink size={"18px"} style={{ marginLeft: "7px", cursor: "pointer" }} />
								</Link>
							</Col>

							<Col style={{ height: "60px" }}>
								<div className="f-right">
									<Btn attrBtn={{ color: "primary", onClick: sendEditRequest, className: "mt-4", disabled: loading }}>
										{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить изменения"}
									</Btn>
								</div>
							</Col>
							{data && <BuildLessonTab data={data} teachers={teachers} students={students} />}
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default BuildLesson;
