import React, { Fragment, useState, useEffect } from "react";
import { Label, Input, Row, Col, Media } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Btn, P } from "../../../../../AbstractElements";
import CommonPopover from "../../../../UiKits/Popover/common/CommonPopover";
import { ModulesApi } from "../../../../../api";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { useConpagesContext } from "../../../../../_helper/ConsecutivePages/ConpagesProvider";
import { useCompanyContext } from "../../../../../_helper/Company/CompanyProvider";

const AnimatedCheckboxButtonsClass = () => {
	const navigate = useNavigate();

		const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { setNextPageInCompany, companyConpagesData } = useConpagesContext();

	const [selectedModules, setSelectedModules] = useState([]);
	const [modules, setModules] = useState([]);
	const [companySelectedModules, setCompanySelectedModules] = useState();

	useEffect(() => {
		axios
			.get(`${ModulesApi}get-modules`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const options = response.data.modules;
				setModules(options);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	useEffect(() => {
		companyData &&
			axios
				.get(`${ModulesApi}get-company-selected-modules/${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					let selected = JSON.parse(response.data.selectedModules);
					selected && setSelectedModules(selected.filter((item) => modules.includes(item)));
					setCompanySelectedModules(response.data);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
	}, [modules, setModules]);

	const handleCheckboxChange = (event, moduleId) => {
		const isChecked = event.target.checked;

		if (isChecked) {
			const selectedModule = modules.find((item) => item.id === moduleId);
			setSelectedModules([...selectedModules, selectedModule]);
		} else {
			setSelectedModules(selectedModules.filter((item) => item.id !== moduleId));
		}
	};

	// отправкa POST-запроса с выбранными модулями
	const handleSubmit = async () => {
		try {
			if (companySelectedModules) {
				let updateModules = companySelectedModules;
				updateModules.selectedModules = JSON.stringify(selectedModules);
				axios
					.put(`${ModulesApi}update-company-selected-modules`, updateModules, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						localStorage.setItem("SelectedModules", updateModules.selectedModules);
						setNextPageInCompany();
						navigate(companyConpagesData.link);
					})
					.catch((error) => {
						console.error("Ошибка получения данных:", error);
					});
			} else {
				let createModules = {
					companyId: companyData[0]?.id,
					selectedModules: JSON.stringify(selectedModules),
				};
				axios
					.post(`${ModulesApi}create-company-selected-modules`, createModules, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
					.then((response) => {
						localStorage.setItem("SelectedModules", createModules.selectedModules);
						setNextPageInCompany();
						navigate(companyConpagesData.link);
					})
					.catch((error) => {
						console.error("Ошибка получения данных:", error);
					});
			}
		} catch (error) {
			console.error("Ошибка при отправке данных:", error);
		}
	};

	return (
		<div className="email-wrap">
			<Row className="g-sm-4 g-3">
				{!!modules.length ? (
					modules.map((item) => (
						<Col key={item.id} xl="4" md="6">
							<div className="prooduct-details-box">
								<div className="d-flex media pe-3 ps-2" style={{ justifyContent: "space-between", alignItems: "center" }}>
									<div className="d-flex" style={{ flexDirection: "column" }}>
										<div style={{ display: "flex", alignItems: "center" }}>
											<Label className="ms-2 mb-0 f-18">{item.name}</Label>
											{/* <a
												className="example-popover badge rounded-pill txt-dark badge bg-light rounded-pill"
												id={"Popover-" + item.id}
												style={{ cursor: "pointer", marginLeft: "8px" }}
											>
												<i className="fa fa-info"></i>
											</a>
											<CommonPopover key={item.id} item={{ placement: "right", id: item.id, trigger: "hover", Popoverbody: item.description }} /> */}
										</div>
										<span className="mb-1 ms-2" style={{ color: "#4d44ab" }}>
											{item.description}
										</span>
										<div className="product-price mt-0 f-16" style={{ display: "initial", marginLeft: "9px" }}>
											{item.price} ₽
										</div>
									</div>{" "}
									<Input className="checkbox_animated mb-2" id={item.id} type="checkbox" onChange={(e) => handleCheckboxChange(e, item.id)} />
								</div>
							</div>
						</Col>
					))
				) : (
					<P attrPara={{ className: "text-center" }}>Доступных модулей нет</P>
				)}
			</Row>
			{!!modules.length && (
				<div className="modal-footer">
					<Link to={`${process.env.PUBLIC_URL}/business-process-template/payment`}>
						<Btn attrBtn={{ color: "primary", className: "m-r-50", onClick: handleSubmit }}>Далее</Btn>
					</Link>
				</div>
			)}
		</div>
	);
};

export default AnimatedCheckboxButtonsClass;
