import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Input, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Label } from "reactstrap";
import { Plus } from "react-feather";
import { Btn, H6, P } from "../../../AbstractElements";
import CommonModal from "../../UiKits/Modals/common/modal";
import { Badges, ToolTip } from "../../../AbstractElements";
import { concatResults } from "../../../concatResults";

export default () => {
	const [LinkName, setLinkName] = useState("");
	const [data, setData] = useState([]);

	const onDragStart = (event, nodeType, label) => {
		event.dataTransfer.setData("application/reactflow", nodeType);
		event.dataTransfer.setData("text/plain", label);
		event.dataTransfer.effectAllowed = "move";
	};
	const addTemp = async () => {
		console.log(LinkName);
		const newRow = {
			id: data.length + 1,
			title: LinkName,
		};
		setData([...data, newRow]);
		setModal(false);
		try {
			const response = await axios.post(` http://localhost:8000/node_templates`, newRow);
			console.log(concatResults(response));
			toast.success("Изменения сохранены!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} catch (error) {
			console.error(error);
		}
	};

	const [pillWithIconTab, setpillWithIconTab] = useState("1");

	useEffect(() => {
		axios
			.get("http://localhost:8000/node_templates")
			.then((response) => {
				setData(concatResults(response));
			})
			.catch((error) => {
				console.error("Произошла ошибка:", error);
			});
	}, []);

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [basictooltip, setbasictooltip] = useState(false);
	const toggleTolltip = () => setbasictooltip(!basictooltip);

	return (
		<Accordion defaultActiveKey="0">
			<Card>
				<CardHeader>
					<H6>
						Управление
						<Badges attrBadge={{ className: "badge rounded-pill txt-dark", color: "light", pill: true, id: "Tooltip" }}>
							<i className="fa fa-info"></i>
						</Badges>
						<ToolTip
							attrToolTip={{
								placement: "right",
								isOpen: basictooltip,
								target: "Tooltip",
								toggle: toggleTolltip,
								offset: [0, 20],
							}}
						>
							Для удаления блока выделите его и нажмите кнопку "Backspace"
						</ToolTip>
					</H6>
				</CardHeader>
				<CardBody style={{ width: "261px", padding: "20px" }}>
					<Nav className="nav-primary" tabs>
						<NavItem>
							<NavLink href="#javascript" className={pillWithIconTab === "1" ? "active" : ""} onClick={() => setpillWithIconTab("1")} style={{ padding: "4px 8px" }}>
								Модель
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#javascript" className={pillWithIconTab === "2" ? "active" : ""} onClick={() => setpillWithIconTab("2")} style={{ padding: "4px 8px" }}>
								Дизайн
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#javascript" className={pillWithIconTab === "3" ? "active" : ""} onClick={() => setpillWithIconTab("3")} style={{ padding: "4px 8px" }}>
								Шаблон
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={pillWithIconTab}>
						<TabPane className="fade show" tabId="1">
							<P attrPara={{ className: "mb-0 m-t-20" }}>models</P>
						</TabPane>
						<TabPane tabId="2">
							<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", marginTop: "20px" }}>
								<div className="dndnode input" onDragStart={(event) => onDragStart(event, "input", "Начало")} draggable>
									Начало
								</div>
								<div className="dndnode custom" onDragStart={(event) => onDragStart(event, "custom", "Сущность")} draggable>
									Сущность
								</div>
								<div className="dndnode man" onDragStart={(event) => onDragStart(event, "man", "Человек")} draggable>
									Человек
								</div>
								<div className="dndnode def" onDragStart={(event) => onDragStart(event, "default", "Процесс")} draggable>
									Процесс
								</div>
								<div className="dndnode input" onDragStart={(event) => onDragStart(event, "output", "Конец")} draggable>
									Конец
								</div>
							</div>
						</TabPane>
						<TabPane tabId="3">
							<div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
								{data.map((item) => (
									<div key={item.id} className="dndnode temp def" onDragStart={(event) => onDragStart(event, "default", `${item.title}`)} draggable>
										{item.title}
									</div>
								))}
								<Btn attrBtn={{ color: "primary", onClick: toggle }}>
									<Plus style={{ verticalAlign: "middle" }} size="18px" />
									Добавить шаблон
								</Btn>
								<CommonModal isOpen={modal} title="Добавить шаблон" toggler={addTemp} closeText="Отмена" saveText="Добавить" togglerClose={toggle}>
									<CardBody style={{ padding: "15px 25px" }}>
										<FormGroup className="row col-sm-11" style={{ flexDirection: "column" }}>
											<Label className="col-sm-9 col-form-label">Название шаблона</Label>
											<Col sm="11">
												<Input className="form-control" type="tel" placeholder="Название шаблона" onInput={(e) => setLinkName(e.target.value)} />
											</Col>
										</FormGroup>
									</CardBody>
								</CommonModal>
							</div>
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
		</Accordion>
	);
};
