import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import DataTable from 'react-data-table-component';
import { Col, Row, ButtonGroup } from 'reactstrap';
import { H5, Btn } from '../../../../AbstractElements';
import { useKeycloak } from '@react-keycloak/web';
import { TelephonyApi } from '../../../../api';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const CallActivity = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [viewType, setViewType] = useState('day');

	const handleViewChange = (view) => {
		setViewType(view);
	};
	const [callStatistics, setCallStatistics] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/CallActivityPerHour.json`)
			.then((response) => {
				setCallStatistics(response.data.calls_by_hour);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);
	const [callStatisticsPerDay, setCallStatisticsPerDay] = useState([]);
	const [callStatisticsDayHour, setCallStatisticsDayHour] = useState([]);

	useEffect(() => {
		axios
			.get(`/api/CallActivityDayHour.json`)
			.then((response) => {
				setCallStatisticsDayHour(response.data.calls_day_hour);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const getData = async () => {
		try {
			// Получение статистики звонков
			const response = await axios.get(`${TelephonyApi}getCallActivity`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } });
			const callStatistics = concatResults(response).statistics;
			setCallStatisticsPerDay(callStatistics);

		} catch (error) {
			console.error('Ошибка получения данных:', error);
		}
	}

	useEffect(() => {
		getData()
	}, []);

	const dayHour = {
		series: callStatisticsDayHour.map((item) => ({ name: formatDate(item.date), data: item.incoming_calls })),
		options: {
			chart: {
				height: 350,
				type: 'heatmap',
				toolbar: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			colors: ['#008FFB'],

			xaxis: {
				categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
				tickAmount: 4,
			},
		},
	};

	const dataPerHour = {
		series: [
			{
				name: 'Входящие',
				data: callStatistics.map((call) => call.received_calls),
			},
			{
				name: 'Пропущенные',
				data: callStatistics.map((call) => call.missed_calls),
			},
		],
		options: {
			chart: {
				type: 'bar',
				height: 350,
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					endingShape: 'rounded',
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 1,
				colors: ['transparent'],
			},
			xaxis: {
				categories: callStatistics.map((call) => formatTime(call.datetime)),
				tickAmount: 4,
			},
			fill: {
				opacity: 1,
			},
		},
	};

	const dataPerDay = {
		series: [
			{
				name: 'Входящие',
				data: callStatisticsPerDay.map((call) => call.receivedCalls),
			},
			{
				name: 'Пропущенные',
				data: callStatisticsPerDay.map((call) => call.missedCalls),
			},
		],
		options: {
			chart: {
				type: 'bar',
				height: 350,
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
					columnWidth: '55%',
					endingShape: 'rounded',
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 1,
				colors: ['transparent'],
			},
			xaxis: {
				categories: callStatisticsPerDay.map((call) => formatDate(call.date)),
				tickAmount: 4,
			},
			fill: {
				opacity: 1,
			},
		},
	};

	function formatTime(inputDate) {
		const dateObject = new Date(inputDate);

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getDate();
		const month = dateObject.getMonth() + 1;

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}`;

		return formattedDate;
	}

	const columnsperday = [
		{
			name: 'Дата',
			selector: (row) => `${formatDate(row.date)}`,
			sortable: true,
		},
		{
			name: 'Входящие',
			selector: (row) => `${row.receivedCalls}`,
			sortable: true,
		},
		{
			name: 'Пропущенные',
			selector: (row) => `${row.missedCalls}`,
			sortable: true,
		},
	];

	return (
		<Col xl="12">
			<div style={{ padding: '30px' }}>
				<H5 attrH5={{ className: 'mb-2' }}>Динамика звонков</H5>
				<ButtonGroup>
					<Btn
						attrBtn={{
							color: 'primary',
							outline: !(viewType === 'dayhour') ? true : false,
							onClick: () => handleViewChange('dayhour'),
						}}
					>
						День и час
					</Btn>
					<Btn
						attrBtn={{
							color: 'primary',
							outline: !(viewType === 'hour') ? true : false,
							onClick: () => handleViewChange('hour'),
						}}
					>
						Час
					</Btn>
					<Btn
						attrBtn={{
							color: 'primary',
							outline: !(viewType === 'day') ? true : false,
							onClick: () => handleViewChange('day'),
						}}
					>
						День
					</Btn>
				</ButtonGroup>

				<div className="chart-container crypto-chart mb-4">
					<Row className="row">
						{viewType === 'dayhour' && <ReactApexChart type="heatmap" height={400} series={dayHour.series} options={dayHour.options} />}
						{viewType === 'hour' && <ReactApexChart options={dataPerHour.options} series={dataPerHour.series} type="bar" height={350} />}
						{viewType === 'day' && <ReactApexChart options={dataPerDay.options} series={dataPerDay.series} type="bar" height={350} />}
					</Row>
				</div>
				<DataTable columns={columnsperday} data={callStatisticsPerDay} defaultSortField="name" striped pagination noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			</div>
		</Col>
	);
};

export default CallActivity;
