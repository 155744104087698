import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import { Draft, More, Spam, Trash } from '../../../../Constant';
import EmailContext from '../../../../_helper/Email';
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';

const EmailTopToggle = () => {
	const { type, fetchAllEmailAsyn } = useContext(EmailContext);
	const { companyData } = useCompanyContext();

	useEffect(() => {
		fetchAllEmailAsyn();
	}, [companyData]);

	const [dropdownOpen, setOpen] = useState(false);
	const toggle = () => setOpen(!dropdownOpen);

	return (
		<Fragment>
			<div className="email-top" style={{ paddingBottom: '22px' }}>
				<Row>
					<Col>
						<h5>{type.label}</h5>
					</Col>
				</Row>
			</div>
		</Fragment>
	);
};

export default EmailTopToggle;
