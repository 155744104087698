import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, CardBody, Col, Card } from "reactstrap";
import { Nav, NavItem, NavLink, TabContent, TabPane, Label, Input } from "reactstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Select from "react-select";

import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { useKeycloak } from "@react-keycloak/web";
import { TelephonyApi } from "../../../../api";
import MainSetting from "./MainSetting";
import Integration from "./Integration";
import RecordingEvaluation from "./RecordingEvaluation";
import Other from "./Other";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const SettingsPBX = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const pbxId = queryParams.get("id");

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const [IconWithTab, setIconWithTab] = useState("1");

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Настройки подключения АТС" parent="Телефония" title="Настройки подключения АТС" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Nav tabs className="border-tab nav-primary">
									<NavItem>
										<NavLink className={`${IconWithTab === "1" ? "active" : ""} f-16`} onClick={() => setIconWithTab("1")} style={{ cursor: "pointer" }}>
											Настройки подключения
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={`${IconWithTab === "2" ? "active" : ""} f-16`} onClick={() => setIconWithTab("2")} style={{ cursor: "pointer" }}>
											Интеграция с CRM
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={`${IconWithTab === "3" ? "active" : ""} f-16`} onClick={() => setIconWithTab("3")} style={{ cursor: "pointer" }}>
											Запись и оценка
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink className={`${IconWithTab === "4" ? "active" : ""} f-16`} onClick={() => setIconWithTab("4")} style={{ cursor: "pointer" }}>
											Прочее
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent activeTab={IconWithTab}>
									<TabPane className="fade show p-t-10" tabId="1" style={{ padding: "10px 25px 20px" }}>
										<MainSetting pbxId={pbxId} />
									</TabPane>
									<TabPane tabId="2" style={{ padding: "10px 25px 20px" }}>
										<Integration pbxId={pbxId} />
									</TabPane>
									<TabPane tabId="3" style={{ padding: "10px 25px 20px" }}>
										<RecordingEvaluation pbxId={pbxId} />
									</TabPane>
									<TabPane tabId="4" style={{ padding: "10px 25px 20px" }}>
										<Other pbxId={pbxId} />
									</TabPane>
								</TabContent>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default SettingsPBX;
