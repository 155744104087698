import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, CardBody, Col, Card, Input, Label, CardFooter, InputGroup, InputGroupText } from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import SweetAlert from "sweetalert2";
import { useKeycloak } from "@react-keycloak/web";
import Tooltip from "@mui/material/Tooltip";
import { Edit } from "react-feather";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Btn, H6 } from "../../../../AbstractElements";
import { TwinAuthApi, AllProjectApi, TaskApi, TasksPaymentApi } from "../../../../api";
import TableProjectRate from "./TableProjectRate";
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../../concatResults";

const EmployeePaymentDetails = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const employeeId = queryParams.get("employeeId");

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const history = useNavigate();
	const [number, setNumber] = useState("");
	const [tax, setTax] = useState(0);

	const [qualification, setQualification] = useState([]);
	const [employment, setEmployment] = useState([]);
	const [allProjects, setAllProjects] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [infoId, setInfoId] = useState("");

	const [selectQualification, setSelectQualification] = useState(null);
	const [selectEmployment, setSelectEmployment] = useState(null);


	const getData = async () => {
		try {
			const typesResponse = await axios.get(`${TasksPaymentApi}getTypesEmployment`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});

			const mappedTypes = concatResults(typesResponse).types.map(type => ({
				label: type.text,
				value: type.id
			}));

			setEmployment(mappedTypes);

			const qualificationsResponse = await axios.get(`${TasksPaymentApi}getQualifications`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});

			const mappedQual = concatResults(qualificationsResponse).qualifications.map(qual => ({
				id: qual.id,
				label: qual.text,
				value: qual.id,
				rate: qual.rate,
				qualification_period: qual.qualificationPeriod
			}));

			setQualification(mappedQual);

			const infoResponse = await axios.get(`${TasksPaymentApi}getEmployeePaymentInfo/${employeeId}`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			});

			const info = concatResults(infoResponse).info;
			const mappedProjectsRates = info ? info.projectRates.map(ratePrj => ({
				id: ratePrj.id,
				projectId: ratePrj.projectId,
				rate: ratePrj.rate
			})) : [];

			setTableData(mappedProjectsRates);

			if (info !== null) {
				setInfoId(info.id);
				setSelectEmployment({ label: info.typeEmployment.text, value: info.typeEmployment.id });
				setSelectQualification({
					id: info.qualification.id,
					label: info.qualification.text,
					value: info.qualification.id,
					rate: info.qualification.rate,
					qualification_period: info.qualification.qualificationPeriod
				});
				setTax(info.vat);
			}

			axios
				.get(`${AllProjectApi}get`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {

					const selectOptions = concatResults(response).projects.map((option) => ({
						label: option.name,
						value: option.id,
					}));
					setAllProjects(selectOptions);

					if (mappedProjectsRates.length > 0) {

						const updatedMappedProjectsRates = mappedProjectsRates.map((ratePrj) => {
							const projectOption = selectOptions.find(
								(option) => option.value === ratePrj.projectId
							);
							if (projectOption) {
								return {
									...ratePrj,
									projectName: projectOption.label,
								};
							}
							else {
								return ratePrj;
							}
						});

						setTableData(updatedMappedProjectsRates);
					}
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});

			axios
				.get(`${TwinAuthApi}get-user?id=${employeeId}`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					let result = JSON.parse(response.data);
					setEmployeeName(`${result.firstName} ${result.lastName}`);
					result.typeEmployment && setSelectEmployment(result.typeEmployment);
					result.qualification && setSelectQualification(result.qualification);
					result.dateAppointmentQualification && setDateAppointmentQualification(result.dateAppointmentQualification);
				})
				.catch((error) => {
					console.error(error);
				});

		}
		catch (error) { }
	};


	useEffect(() => {
		getData();
	}, []);

	const [employeeName, setEmployeeName] = useState("");
	const [dateAppointmentQualification, setDateAppointmentQualification] = useState("");

	const [tasks, setTasks] = useState([]);

	//Сохранить изменения
	const send = () => {

		try {

			var id = infoId.length > 0 ? infoId : uuid()

			const projectRatesDto = tableData.map(item => ({
				id: item.id,
				projectId: item.projectId,
				employeePaymentInfoId: id,
				rate: item.rate
			}));

			const newValue = {
				id: id,
				userId: employeeId,
				qualificationId: selectQualification.value,
				typeEmploymentId: selectEmployment.value,
				vat: tax,
				projectRates: projectRatesDto
			};

			if (infoId === "") {
				axios.post(`${TasksPaymentApi}createEmployeePaymentInfo`, newValue, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
					.then((response) => {
						toast.success('Платежные данные добавлены!', {
							position: toast.POSITION.TOP_RIGHT,
						});
						history(`${process.env.PUBLIC_URL}/app/task-reports`);
					})
					.catch((error) => {
						toast.error('Ошибка при добавлении', {
							position: toast.POSITION.TOP_RIGHT,
						});
					});
			}
			else {
				axios.put(`${TasksPaymentApi}updateEmployeePaymentInfo`, newValue, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
					.then((response) => {
						toast.success('Задача добавлена!', {
							position: toast.POSITION.TOP_RIGHT,
						});
						history(`${process.env.PUBLIC_URL}/app/task-reports`);
					})
					.catch((error) => {
						toast.error('Ошибка при добавлении', {
							position: toast.POSITION.TOP_RIGHT,
						});
					});
			}
		}
		catch (error) {
			toast.error('Ошибка', {
				position: toast.POSITION.TOP_RIGHT,
			});
			console.error('Произошла ошибка при отправке данных на сервер:', error);
		}

	};

	//удалить ставку по проектам
	const deleteRow = (projId) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				const updatedData = tableData.filter((item) => item.projectId !== projId);
				setTableData(updatedData);
			}
		});
	};

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	//// Функция для добавления новой ставки в таблицу проектных ставок
	//const addRateHandler = (projectId, rate) => {
	//	const newRate = {
	//		id: uuid(), // Генерируем уникальный идентификатор для новой ставки
	//		projectId,
	//		rate
	//	};

	//	// Обновляем состояние таблицы проектных ставок
	//	setTableData([...tableData, newRate]);
	//};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Управление платёжными данными сотрудников" parent="Управление платёжными данными сотрудников" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Row className="mb-3">
									<Col md="7">
										<Label className="col-sm-9 col-form-label">Сотрудник</Label>
										<Col sm="12">
											<Input className="form-control" value={employeeName} disabled />
										</Col>
									</Col>
									<Col md="3">
										<Label className="col-sm-9 col-form-label">Тип занятости</Label>
										<Col sm="12">
											<Select
												placeholder="Начните вводить..."
												noOptionsMessage={() => "Нет результатов"}
												options={employment}
												value={selectEmployment}
												onChange={(selectedOption) => {
													setSelectEmployment(selectedOption);
													selectedOption.value === "ТК РФ" || selectedOption.value === "ГПХ" ? setTax(13) : setTax(6);
												}}
												className="js-example-basic-single col-sm-12"
											/>
										</Col>
									</Col>
									<Col md="2">
										<Label className="col-sm-9 col-form-label">Налог</Label>
										<Col sm="12">
											<InputGroup>
												<Input
													className="form-control"
													type="number"
													value={tax}
													disabled={selectEmployment && (selectEmployment.value === "ТК РФ" || selectEmployment.value === "ГПХ")}
													onChange={(e) => setTax(e.target.value)}
												/>

												<InputGroupText>{"%"}</InputGroupText>
											</InputGroup>
										</Col>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col md="4">
										<Label className="col-sm-12 col-form-label">
											Квалификация
											<Tooltip title="Редактировать" arrow placement="top">
												<Link to={`${process.env.PUBLIC_URL}/app/qualifications`}>
													<Edit size={"18px"} className="ms-2 c-pointer" />
												</Link>
											</Tooltip>
										</Label>
										<Col sm="12">
											<Select
												placeholder="Начните вводить..."
												noOptionsMessage={() => "Нет результатов"}
												options={qualification}
												value={selectQualification}
												onChange={(selectedOption) => {
													setSelectQualification(selectedOption);
													setDateAppointmentQualification(new Date());
												}}
												className="js-example-basic-single col-sm-12"
											/>
										</Col>
									</Col>
									<Col md="3">
										<Label className="col-sm-9 col-form-label">Базовая ставка</Label>
										<Col sm="12">
											<InputGroup>
												<Input className="form-control" type="number" value={selectQualification ? selectQualification.rate : ""} disabled />
												<InputGroupText>{"руб/час"}</InputGroupText>
											</InputGroup>
										</Col>
									</Col>
									{dateAppointmentQualification && (
										<Col md="5" style={{ display: "flex", alignItems: "end" }}>
											<Label className="font-primary">Дата назначения квалификации: {formatDate(dateAppointmentQualification)}</Label>
										</Col>
									)}
								</Row>
								<Row className="mb-4 mt-4">
									<H6>Ставка по проектам</H6>
									<TableProjectRate data={tableData} deleteRow={deleteRow} allProjects={allProjects} setTableData={setTableData} />
								</Row>
								<Row className="mb-3">
									<Col md="4">
										<Label className="col-sm-9 col-form-label">
											Бонусы
											<Link target="_blank" to={`${process.env.PUBLIC_URL}/app/bonuses`}>
												<i className="fa fa-info-circle f-18 ms-2"></i>
											</Link>
										</Label>
										<Col sm="12">
											<Input className="form-control" value={number} onChange={(e) => setNumber(e.target.value)} />
										</Col>
									</Col>
									<Col md="4">
										<Label className="col-sm-9 col-form-label">Зачтено критериев</Label>
										<Col sm="12">
											<InputGroup>
												<Input className="form-control" type="number" />
												<InputGroupText>{"%"}</InputGroupText>
											</InputGroup>
										</Col>
									</Col>
									<Col md="4">
										<Label className="col-sm-9 col-form-label">Средний показатель эффективности</Label>
										<Col sm="12">
											<InputGroup>
												<Input className="form-control" type="number" />
												<InputGroupText>{"%"}</InputGroupText>
											</InputGroup>
										</Col>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<div className="modal-footer">
									<Btn attrBtn={{ color: "primary", onClick: send }}>Сохранить</Btn>
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default EmployeePaymentDetails;
