import React, { Fragment, useState, useEffect } from 'react';
import { Container, Card, CardBody, Row, CardHeader } from 'reactstrap';
import { Col } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../FormBuilder/DnD.css';
import RightPanel from './RightPanel';
import LeftPanel from './LeftPanel';

import { Breadcrumbs, H5 } from '../../../../AbstractElements';
import { DemandsApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {concatResults} from "../../../../concatResults";
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';

const VisualEditorProducts = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const formId = queryParams.get('formId');
	const {companyData} = useCompanyContext();

	const items = ['selectType', 'name', 'tel', 'city', 'address', 'comment', 'date', 'time', 'client'];
	const selectProduct = ['ProductsOrderTable'];
	const payment = ['payment'];
	const [droppedItems, setDroppedItems] = useState([]);
	const [isOverLeftPanel, setIsOverLeftPanel] = useState(false);

	const {keycloak} = useKeycloak();

	const handleDrop = (item) => {
		setDroppedItems([...droppedItems, item]);
		console.log(droppedItems);
	};

	const [menuItems, setMenuItems] = useState([]);

	const addMenuItems = (item) => {
		setMenuItems([...menuItems, item]);
		console.log(menuItems);
	};

	const removeMenuItems = (index) => {
		const updatedDroppedItems = [...menuItems];
		updatedDroppedItems.splice(index, 1);
		for (let i = index; i < updatedDroppedItems.length; i++) {
			updatedDroppedItems[i].order = i + 1;
		}
		setMenuItems(updatedDroppedItems);
	};

	const handleRemove = (index) => {
		const updatedDroppedItems = [...droppedItems];
		updatedDroppedItems.splice(index, 1);
		for (let i = index; i < updatedDroppedItems.length; i++) {
			updatedDroppedItems[i].order = i + 1;
		}
		setDroppedItems(updatedDroppedItems);
	};

	const [itemName, setItemName] = useState('Заказ товаров');

	useEffect(() => {
		axios
			.get(`${DemandsApi}getDemandForms`, {
				params: {
					query: '',
				},
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
			})
			.then((response) => {
				//console.log(response);
				const foundItem = concatResults(response).demandForms.find((item) => item.id === formId);
				setItemName(foundItem.name);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Визуальный редактор форм" subParent="Конструктор форм" parent="Заявки" title="Визуальный редактор форм" />
			<Container fluid={true}>
				<Row>
					<Col xxl="5" sm="12 box-col-4">
						<Card>
							<CardBody className="">
								<RightPanel payment={payment} menuitems={menuItems} itemName={itemName} setItemName={setItemName} droppedItems={droppedItems} items={items} selectProduct={selectProduct} setIsOverLeftPanel={setIsOverLeftPanel} />
							</CardBody>
						</Card>
					</Col>
					<Col xxl="7" sm="12 box-col-7">
						<Card>
							<CardHeader>
								<H5>{itemName}</H5>
							</CardHeader>
							<CardBody>
								<LeftPanel droppedItems={droppedItems} onDrop={handleDrop} onRemove={handleRemove} isOverLeftPanel={isOverLeftPanel} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default VisualEditorProducts;
