import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import axios from "axios";
import { Breadcrumbs } from "../../../../../AbstractElements";
import { EducationApi } from "../../../../../api";
import TableDiplomaIssuedList from "./TableDiplomaIssuedList";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const DiplomasIssued = () => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	let currentUserId = `${keycloak.tokenParsed.sub}`;

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	let courseId = queryParams.get("courseId");
	courseId = courseId || "";

	const [data, setData] = useState([]);

	useEffect(() => {
		const apiUrl = courseId ? `${EducationApi}get-course-diplomas/${courseId}` : `${EducationApi}get-course-diplomas/`;
		axios
			.get(apiUrl, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setData(concatResults(response).diplomas);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, [courseId]);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Выданные дипломы" parent="Дипломы" title="Выданные дипломы" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<TableDiplomaIssuedList data={data} />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default DiplomasIssued;
