// LeftPanel.js
import React from 'react';
import { Col, Label } from 'reactstrap';

const ElementClient = ({ setIsOverLeftPanel }) => {
	return (
		<>
			<div
				className="col-sm-11 mb-3"
				draggable="true"
				onDragStart={(e) => {
					e.dataTransfer.setData('text/plain', 'client');
					setIsOverLeftPanel(true);
				}}
				onDragEnd={() => setIsOverLeftPanel(false)}
			>
				<Col sm="12" style={{ backgroundColor: '#7366ff33' }} className="p-10 b-r-8">
					<Label>Оператор: </Label>
					<Label className="f-w-600">Сотрудник</Label>
				</Col>
			</div>
		</>
	);
};

export default ElementClient;
