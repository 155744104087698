import React from 'react';
import Select from 'react-select';

const AutoCompleteFilter = ({ name, filterValue, onFilterChange, options }) => {
    const handleChange = (selectedOption) => {
        onFilterChange(selectedOption ? selectedOption.value : '', name);
    };

    const selectedOption = options.find(option => option.value === filterValue) || null;

    return (
        <Select
            name={name}
            value={selectedOption}
            onChange={handleChange}
            options={options}
            isClearable
            placeholder="Введите имя..."
        />
    );
};

export default AutoCompleteFilter;
