import React, {Fragment} from "react";
import {DropZone} from "@measured/puck";

export const BlockWithHeader = {
    label: "Блок с заголовком",
    fields: {
        header: {
            label: "Заголовок",
            type: "text",
        }
    },
    defaultProps: {
        header: "Заголовок"
    },
    render: ({ header, id }) => {
        return (
            <Fragment>
                <div style={{
                    display: "flex",
                    minHeight: 0,
                    minWidth: 0,
                    flexWrap: "wrap",
                }}>
                    <div
                        key={id}
                        className={"w-100 p-4"}
                    >
                        <h1>{header}</h1>
                        <DropZone zone={`item-${id}`}/>
                    </div>
                </div>
            </Fragment>
        );
    },
};