import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { Card, CardBody } from 'reactstrap';
import TableForm from './TableForm';

const FormBuilder = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Конструктор форм" parent="Заявки" title="Конструктор форм" />
			<Container fluid={true}>
				<Row>
					<TableForm />
				</Row>
			</Container>
		</Fragment>
	);
};
export default FormBuilder;
