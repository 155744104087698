import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';

import FilterComponent from './FilterComponent';

const TableCatalog = ({ products }) => {
	const columns = [
		{
			name: 'Название',
			selector: (row) => `${row.name}`,
			sortable: true,
			hide: 'sm',
			// width: '200px',
		},
		{
			name: 'Цена',
			selector: (row) => `${row.cost} руб`,
			sortable: true,
		},
		{
			name: 'Дата',
			selector: (row) => (
				<Tooltip title={`${formatDateTimeInMoscow(row.date)} МСК`} arrow placement="top">
					<div style={{ cursor: 'pointer' }}>{formatDateTime(row.date)}</div>
				</Tooltip>
			),
			sortable: true,
		},
		{
			name: 'Количество',
			selector: (row) => `${row.quantity}`,
			sortable: true,
		},
		{
			name: 'Итого',
			selector: (row) => <div>{row.cost * row.quantity} руб</div>,
			sortable: true,
			width: '10%',
		},
	];
	// Функция для форматирования даты и времени
	function formatDateTime(dateTimeStr) {
		const date = new Date(dateTimeStr);
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	}
	function formatDateTimeInMoscow(dateTimeString) {
		const options = { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' };
		const dateTime = new Date(dateTimeString);
		const optionsMoscow = { ...options, timeZone: 'Europe/Moscow' };
		return dateTime.toLocaleString('ru-RU', optionsMoscow);
	}
	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = products.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable columns={columns} data={filteredItems} defaultSortField="name" noDataComponent={'В данной таблице ещё нет записей.'} striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</>
	);
};

export default TableCatalog;
