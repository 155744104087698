import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import axios from 'axios';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableCalculationRules from './TableCalculationRules';

const CalculationRules = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get('/api/calculationRules.json')
			.then((response) => {
				setFilteredProducts(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Правила расчета" parent="Расчёт зарплат" title="Правила расчета" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<TableCalculationRules filteredProducts={filteredProducts} />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default CalculationRules;
