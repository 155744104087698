import React, { Fragment, useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Col, Card, CardHeader, CardBody, Row, Input, Label, InputGroup, InputGroupText } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import Select from "react-select";
import Scrollbars from "react-custom-scrollbars-2";
import { Plus } from "react-feather";
import { toast } from "react-toastify";
import uuid from "react-uuid";

import { H5, H6, Badges, Btn, P } from "../../../AbstractElements";
import CommonModal from "../../UiKits/Modals/common/modal";
import { TwinAuthApi, TaskApi } from "../../../api";
import { useKeycloak } from "@react-keycloak/web";
import { project } from "../../Common/Data/Task/index";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";
import { concatResults } from "../../../concatResults";

const CanbanTasks = ({ data, statuses, type = "project", toggleTask, modalTask, projectId, setModalTask, setData }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	let currentUserId = `${keycloak.tokenParsed.sub}`;
	const {
		handleSubmit,
		formState: { errors },
	} = useForm();

	// Группировка объектов по статусам
	const groupedData = data.reduce((acc, obj) => {
		const { status, name, id, dateEnd } = obj;
		if (!acc[status.id]) {
			acc[status.id] = [];
		}
		acc[status.id].push({ name, id, dateEnd });
		return acc;
	}, {});

	const scrollbarsRef = useRef(null);
	const scrollTimeout = useRef(null); // Реф для requestAnimationFrame
	const isScrollingRef = useRef(false);
	const [hideScrollRightButton, setHideScrollRightButton] = useState(false);
	const [hideScrollLeftButton, setHideScrollLeftButton] = useState(true);
	const [selectedStatus, setSelectedStatus] = useState(null);

	// Функция для обработки скролла влево
	const handleScrollLeft = () => {
		if (scrollbarsRef.current) {
			if (!isScrollingRef.current) {
				isScrollingRef.current = true;
				scrollLeft();
			}
		}
	};

	// Функция для обработки скролла вправо
	const handleScrollRight = () => {
		if (scrollbarsRef.current) {
			if (!isScrollingRef.current) {
				isScrollingRef.current = true;
				scrollRight();
			}
		}
	};

	// Функция для скролла влево
	const scrollLeft = () => {
		if (scrollbarsRef.current) {
			const currentScrollLeft = scrollbarsRef.current.getScrollLeft();
			scrollbarsRef.current.scrollLeft(currentScrollLeft - 8);
			scrollTimeout.current = requestAnimationFrame(scrollLeft);
		}
	};

	// Функция для скролла вправо
	const scrollRight = () => {
		if (scrollbarsRef.current) {
			const currentScrollLeft = scrollbarsRef.current.getScrollLeft();
			scrollbarsRef.current.scrollLeft(currentScrollLeft + 8);
			scrollTimeout.current = requestAnimationFrame(scrollRight);
		}
	};

	// Функция для остановки скролла
	const stopScroll = () => {
		if (scrollTimeout.current) {
			cancelAnimationFrame(scrollTimeout.current);
			isScrollingRef.current = false;
		}
	};

	const handleScroll = () => {
		if (scrollbarsRef.current) {
			const currentScrollLeft = scrollbarsRef.current.getScrollLeft();
			const scrollWidth = scrollbarsRef.current.getScrollWidth();
			const clientWidth = scrollbarsRef.current.getClientWidth();

			if (currentScrollLeft + clientWidth >= scrollWidth) {
				setHideScrollRightButton(true);
			} else {
				setHideScrollRightButton(false);
			}

			if (currentScrollLeft <= 0) {
				setHideScrollLeftButton(true);
			} else {
				setHideScrollLeftButton(false);
			}
		}
	};

	function formatDateTime(dateTimeStr) {
		const date = new Date(dateTimeStr);
		const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

		const day = date.getDate();
		const month = months[date.getMonth()];
		const hours = date.getHours();
		const minutes = date.getMinutes();

		const formattedDate = `${day} ${month} в ${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

		return formattedDate;
	}

	const [modalLead, setModalLead] = useState(false);
	const [modalTr, setModalTr] = useState(false);

	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [amount, setAmount] = useState(0);
	const [participants, setParticipants] = useState(null);
	const [doer, setDoer] = useState({ label: keycloak.tokenParsed.name, value: keycloak.tokenParsed.sub });
	const [company, setCompany] = useState(null);
	const [StartDate, setStartDate] = useState(new Date());
	const [StopDate, setStopDate] = useState(new Date());

	const toggleLead = () => {
		setModalLead(!modalLead);
		setLoading(false);
	};
	const toggleTr = () => {
		setModalTr(!modalTr);
		setLoading(false);
	};

	const sendFastLead = async () => {
		// отправка на сервер
		setLoading(true);

		const fastTask = {
			id: uuid(),
			name: name,
			doerId: doer != null ? doer.value : null,
			creatorId: currentUserId,
			dateStart: StartDate,
			dateEnd: StopDate,
			isExternal: false,
			statusId: selectedStatus ? selectedStatus : statuses[0].id,
			projectId: projectId,
			parentTaskId: null,
		};
		await axios
			.post(`${TaskApi}createFastTask`, fastTask, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				//console.log(concatResults(response));
				toast.success("Задача добавлена!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setLoading(false);
				setModalLead(false);
				// setData((prevTask) => [...prevTask, fastTask]);
			})
			.catch((error) => {
				console.error(error);
				setLoading(false);
			});
	};
	const [allUsersNames, setAllUsersNames] = useState([]);
	const [companies, setCompanies] = useState([]);

	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName,
					value: option.username,
				}));
				setAllUsersNames(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
		axios
			.get(`${TwinAuthApi}get-groups-companies`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setCompanies(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	function getTextColor(backgroundColor) {
		let r = parseInt(backgroundColor.substr(1, 2), 16);
		let g = parseInt(backgroundColor.substr(3, 2), 16);
		let b = parseInt(backgroundColor.substr(5, 2), 16);

		let brightness = (r * 333 + g * 334 + b * 333) / 1000;

		return brightness > 125 ? "#2f2f3b" : "#ffffff";
	}

	return (
		<Fragment>
			<div style={{ padding: "0 30px 30px" }}>
				<div className="task-gantt-timeline-inner" id="TasksKanbanGantt">
					<div>
						{!hideScrollLeftButton && (
							<button className="btn backward arrow-nav-left arrows-navs-el" onMouseEnter={handleScrollLeft} onMouseLeave={stopScroll}>
								<i className="fa fa-angle-left" style={{ fontSize: "40px", color: "#fff", marginRight: "5px" }}></i>
							</button>
						)}
						{!hideScrollRightButton && (
							<button className="btn forward arrow-nav-right arrows-navs-el" onMouseEnter={handleScrollRight} onMouseLeave={stopScroll}>
								<i className="fa fa-angle-right" style={{ fontSize: "40px", color: "#fff", marginLeft: "5px" }}></i>
							</button>
						)}
					</div>
				</div>

				<div className="scroll-bar-wrap" style={{ position: "relative" }}>
					<Scrollbars className="horizontal-scroll" style={{ width: "100%", height: "60vh" }} id="ContentBlockScroll" ref={scrollbarsRef} onScroll={handleScroll}>
						<Row style={{ flexWrap: "nowrap", width: "100%", marginLeft: "10px" }}>
							{statuses.map((s, i) => (
								<Col sm="6" xl="3" md="4" key={i} style={{ borderRight: "1px solid #dddddd80" }}>
									<Card>
										<CardHeader className={"border-bottom"} style={{ backgroundColor: s.color, color: getTextColor(s.color) }}>
											<H5>{s.name}</H5>
										</CardHeader>
										<CardBody className="" style={{ padding: "30px 20px" }}>
											{type === "lead" ? (
												<Btn attrBtn={{ color: "light", className: "w-100 mb-4", onClick: toggleLead }}>
													<Plus size="18px" style={{ margin: "0 3px -3px 0" }} /> Быстрый лид
												</Btn>
											) : type === "deals" ? (
												<Btn attrBtn={{ color: "light", className: "w-100 mb-4", onClick: toggleTr }}>
													<Plus size="18px" style={{ margin: "0 3px -3px 0" }} /> Быстрая сделка
												</Btn>
											) : (
												<Btn
													attrBtn={{
														color: "light",
														className: "w-100 mb-4",
														onClick: () => {
															toggleTask();
															setSelectedStatus(s.id);
														},
													}}
												>
													<Plus size="18px" style={{ margin: "0 3px -3px 0" }} /> Быстрая задача
												</Btn>
											)}
											<div>
												{groupedData[s.id] ? (
													groupedData[s.id].map((obj, index) => (
														<Tooltip title="Просмотр задачи" arrow placement="top" key={index}>
															<Link to={`${process.env.PUBLIC_URL}/app/task/view-task?taskId=${obj.id}`}>
																<Card
																	id={`clickable-${obj.id}`}
																	style={{ marginBottom: "10px", border: "1px solid #dddddd80", borderLeft: `2px solid ${s.color}`, borderRadius: "8px" }}
																	key={index}
																	className="shadow-none shadow-showcase"
																>
																	<CardBody style={{ padding: "15px" }}>
																		<H6>{obj.name}</H6>
																		{obj.dateEnd ? (
																			<Badges attrBadge={{ className: "badge rounded-pill", color: "light-info", pill: true }}>
																				{formatDateTime(obj.dateEnd)}
																			</Badges>
																		) : (
																			<Badges attrBadge={{ className: "badge rounded-pill txt-dark", color: "light", pill: true }}>Без срока</Badges>
																		)}
																	</CardBody>
																</Card>
															</Link>
														</Tooltip>
													))
												) : (
													<p>Нет задач для этого статуса</p>
												)}
											</div>
										</CardBody>
									</Card>
								</Col>
							))}
						</Row>
					</Scrollbars>
					<CommonModal isOpen={modalLead} title={"Добавить лид"} toggler={sendFastLead} togglerClose={toggleLead} closeText="Отмена" saveText="Добавить" size="lg">
						<CardBody style={{ padding: "10px", paddingLeft: "15px", paddingTop: "0" }}>
							<Col className=" col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Название</Label>
								<Col sm="12">
									<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
								</Col>
							</Col>
							<P attrPara={{ className: "mb-0 mt-4" }}>Клиент</P>
							<Col className="b-t-light border-2 col-sm-12 mb-2 pt-2">
								<div className="ms-2 row">
									<Label className="col-sm-9 col-form-label">Контакт</Label>
									<Col sm="12" className="mb-2">
										<Select
											placeholder="Начните вводить имя пользователя"
											isMulti
											noOptionsMessage={() => "Нет результатов"}
											options={allUsersNames}
											value={participants}
											onChange={(option) => setParticipants(option)}
											className="js-example-basic-single col-sm-12"
										/>
									</Col>
									{/* <span className="font-primary user-add">
										<Plus size={'12px'} /> Добавить участника
									</span> */}
									<Label className="col-sm-9 col-form-label mt-2">Компания</Label>
									<Col sm="12">
										<Select
											placeholder="Начните вводить название компании"
											noOptionsMessage={() => "Нет результатов"}
											options={companies}
											value={company}
											onChange={(option) => setCompany(option)}
											className="js-example-basic-single col-sm-12"
										/>
									</Col>
								</div>
							</Col>
						</CardBody>
					</CommonModal>
					<CommonModal isOpen={modalTr} title={"Добавить сделку"} toggler={sendFastLead} togglerClose={toggleTr} closeText="Отмена" saveText="Добавить" size="lg">
						<CardBody style={{ padding: "10px", paddingLeft: "15px", paddingTop: "0" }}>
							<Col className=" col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Название</Label>
								<Col sm="12">
									<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
								</Col>
							</Col>
							<Col className=" col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Сумма</Label>
								<Col sm="12">
									<InputGroup>
										<Input required className="form-control" type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
										<InputGroupText>{"₽"}</InputGroupText>
									</InputGroup>
								</Col>
							</Col>
							<P attrPara={{ className: "mb-0 mt-4" }}>Клиент</P>
							<Col className="b-t-light border-2 col-sm-12 mb-2 pt-2">
								<div className="ms-2 row">
									<Label className="col-sm-9 col-form-label">Контакт</Label>
									<Col sm="12" className="mb-2">
										<Select
											placeholder="Начните вводить имя пользователя"
											isMulti
											noOptionsMessage={() => "Нет результатов"}
											options={allUsersNames}
											value={participants}
											onChange={(option) => setParticipants(option)}
											className="js-example-basic-single col-sm-12"
										/>
									</Col>
									<Label className="col-sm-9 col-form-label mt-2">Компания</Label>
									<Col sm="12">
										<Select
											placeholder="Начните вводить название компании"
											noOptionsMessage={() => "Нет результатов"}
											options={companies}
											value={company}
											onChange={(option) => setCompany(option)}
											className="js-example-basic-single col-sm-12"
										/>
									</Col>
								</div>
							</Col>
						</CardBody>
					</CommonModal>
					<CommonModal isOpen={modalTask} title={"Добавить задачу"} toggler={sendFastLead} togglerClose={toggleTask} closeText="Отмена" saveText="Добавить" size="lg">
						<CardBody style={{ padding: "10px", paddingLeft: "15px", paddingTop: "0" }}>
							<Col className=" col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Название</Label>
								<Col sm="12">
									<Input className="form-control" type="tel" value={name} onChange={(e) => setName(e.target.value)} />
								</Col>
							</Col>

							<Col className="col-sm-12 mb-2">
								<Label className="col-sm-9 col-form-label">Ответственный</Label>
								<Col sm="12" className="mb-2">
									<Select
										placeholder="Начните вводить имя пользователя"
										noOptionsMessage={() => "Нет результатов"}
										options={allUsersNames}
										value={doer}
										onChange={(option) => setDoer(option)}
										className="js-example-basic-single col-sm-12"
									/>
								</Col>
							</Col>
							<Row>
								<Col md="6" xs="12">
									<Label className="col-sm-12 col-form-label ">Начать задачу с</Label>
									<Col sm="12">
										<DatePicker
											placeholderText="Выберите дату"
											minDate={new Date()}
											isClearable
											locale={ru}
											className="form-control digits"
											selected={StartDate}
											onChange={(date) => setStartDate(date)}
											timeInputLabel="Время:"
											dateFormat="dd.MM.yyyy HH:mm"
											showTimeInput
										/>
									</Col>
								</Col>
								<Col md="6" xs="12">
									<Label className="col-sm-12 col-form-label ">Завершить задачу до</Label>
									<Col sm="12">
										<DatePicker
											placeholderText="Выберите дату"
											minDate={new Date()}
											isClearable
											locale={ru}
											className="form-control digits"
											selected={StopDate}
											onChange={(date) => setStopDate(date)}
											timeInputLabel="Время:"
											dateFormat="dd.MM.yyyy HH:mm"
											showTimeInput
										/>
									</Col>
								</Col>
							</Row>
						</CardBody>
					</CommonModal>
				</div>
			</div>
		</Fragment>
	);
};

export default CanbanTasks;
