import React, { useState } from "react";
import uuid from "react-uuid";
import axios from "axios";
import { toast } from "react-toastify";

import { Btn, H5 } from "../../../../AbstractElements";
import { useParams } from "react-router-dom";
import { CodeExecutor } from "./CodeEditor/CodeExecutor";
import { CodeEditor } from "./CodeEditor/CodeEditor";
import { Tabs } from "./CodeEditor/Tabs";
import { CmsApi } from "../../../../api";

const FooterEditor = () => {
	const { siteId } = useParams();

	const initialHTML = `<footer class="footer"><div class="container-fluid"><div class="row"><div class="footer-copyright text-center col-md-12"><p class="mb-0">Copyright 2023 © Cuba theme by pixelstrap.</p></div></div></div></footer>`;

	const initialCSS = `
    .footer {
        background-color: #fff;
        box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
        padding: 15px;
        bottom: 0;
        left: 0;
        transition: 0.5s;
    }
    footer {
        margin: 0;
        padding: 0;
        font-size: 14px;
        overflow-x: hidden;
        font-family: "Inter", sans-serif;
        color: #2f2f3b;
    }
    .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        margin-right: auto;
        margin-left: auto;
    }
    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1* var(--bs-gutter-y));
        margin-right: calc(-0.5* var(--bs-gutter-x));
        margin-left: calc(-0.5* var(--bs-gutter-x));
    }
    .text-center {
        text-align: center !important;
    }
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    p {
        font-size: 14px;
        line-height: 1.7;
        letter-spacing: 0.7px;
        margin-top: 0;
    }
    .mb-0 {
        margin-bottom: 0 !important;
    }
    
    `;

	const [mode, setMode] = useState("html");
	const [html, setHtml] = useState(initialHTML);
	const [css, setCss] = useState(initialCSS.trim());
	const [srcDoc, setSrcDoc] = useState("");

	const runCode = () => {
		setSrcDoc(
			`<html>
		  <style>${css}</style>
		  <body>${html}</body>
		</html>`
		);
	};

	const propsByMode = {
		html: {
			mode: "xml",
			value: html,
			setValue: setHtml,
		},
		css: {
			mode: "css",
			value: css,
			setValue: setCss,
		},
	};

	const createFooter = () => {
		const newFooter = {
			Type: "footer",
			SiteId: siteId,
			content: srcDoc,
		};
		console.log(newFooter);
		// axios
		// 	.post(`${CmsApi}createMenuLink`, newFooter)
		// 	.then((response) => {
		// 		console.log(concatResults(response));
		// 		toast.success("Ссылка добавлена!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 	})
		// 	.catch((error) => {
		// 		console.error("Ошибка получения данных:", error);
		// 	});
	};

	return (
		<>
			<H5>Редактор подвала сайта</H5>
			<Tabs mode={mode} setMode={setMode} runCode={runCode} />
			<CodeEditor {...propsByMode[mode]} />
			<CodeExecutor srcDoc={srcDoc} />
			<div className="modal-footer mt-3">
				<Btn attrBtn={{ color: "primary", onClick: createFooter }}>Сохранить</Btn>
			</div>
		</>
	);
};

export default FooterEditor;
