import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, CardBody, Col, Card, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Breadcrumbs, H3, H4 } from '../../../../AbstractElements';
import DataTableReminders from './DataTableReminders';
import axios from 'axios';
import { ReminderApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import DataTableEventType from './DataTableEventType';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';
import DataTableTelegramBots from './DataTableTelegramBots';
import DataTableEmailUsers from './DataTableEmailUsers';
import DataTableTelegramUsers from './DataTableTelegramUsers';
import {concatResults} from "../../../../concatResults";

const Reminders = () => {
    const [primarycolorTab, setprimarycolorTab] = useState('1');
    const [eventTypes, setEventTypes] = useState();
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();

    const [companyTg, setCompayTg] = useState();
    const [companyEmail, setCompanyEmail] = useState();

    const [tgBotLogin, setTgBotLogin] = useState('');
    const [tgToken, setTgToken] = useState('');

    const [smtpAddress, setSmtpAddress] = useState('');
    const [companyEmailAddress, setCompanyEmailAddress] = useState('')
    const [companyEmailPassword, setCompanyEmailPassword] = useState('')

    const [tgModal, setTgModal] = useState(false);
    const [emailModal, setEmailModal] = useState(false);

    useEffect(() => {
        axios
			.get(`${ReminderApi}get-event-types`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				setEventTypes(concatResults(response).eventTypes);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
    }, []);

    const toggleEmailModal = () => {
        setSmtpAddress('');
        setCompanyEmailAddress('');
        setCompanyEmailPassword('');
        setEmailModal(!emailModal);
    }

    const toggleTgModal = () => {
        setTgBotLogin('');
        setTgToken('');
        setTgModal(!tgModal);
    }

    const handleAddTgRow = () => {
        if (companyTg) {
            companyData &&
            axios
                .put(`${ReminderApi}update-company-telegram`, {
                    id: companyTg.id,
                    telegramBotKey: tgToken,
                    telegramBotName: tgBotLogin
                }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                .then((response) => {
                    setEventTypes(concatResults(response).eventTypes);
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });
        } else {
            companyData &&
            axios
                .post(`${ReminderApi}create-company-telegram`, {
                    telegramBotKey: tgToken,
                    telegramBotName: tgBotLogin
                }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                .then((response) => {
                    setEventTypes(concatResults(response).eventTypes);
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });
        }
    }

    const handleAddEmailRow = () => {
        if (companyEmail) {
            axios
                .put(`${ReminderApi}update-company-email`, {
                    id: companyEmail.id,
                    smtpAddress: smtpAddress,
                    email: companyEmailAddress,
                    emailPassword: companyEmailPassword
                }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                .then((response) => {
                    setEventTypes(concatResults(response).eventTypes);
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });
        } else {
            axios
                .post(`${ReminderApi}create-company-email`, {
                    smtpAddress: smtpAddress,
                    email: companyEmailAddress,
                    emailPassword: companyEmailPassword
                }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                .then((response) => {
                    setEventTypes(concatResults(response).eventTypes);
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });
        }
    }

    useEffect(() => {
        companyData &&
            axios
                .get(`${ReminderApi}get-company-telegram/${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                .then((response) => {
                    setCompayTg(concatResults(response));
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });

        console.log(companyTg);

        companyData &&
            axios
                .get(`${ReminderApi}get-company-email/${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                .then((response) => {
                    setCompanyEmail(concatResults(response));
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });

    }, [companyData]);

    const handleCourseTab = (num) => {
		setprimarycolorTab(num);
		localStorage.setItem('primaryCompanyTab', num);
	};

    return (
		<Fragment>
			<Breadcrumbs mainTitle="Меню управления уведомлениями" parent="Админ" title="Меню управления уведомлениями" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
                            <Fragment>
                                <H4>Уведомления в компании {companyData && companyData.name}</H4>
                                <Row className='my-5'>
                                    <DataTableTelegramBots eventTypes={eventTypes} />
                                    <Col>
                                        <H3>Email {companyEmail ? <i style={{color: '#54ba4a'}} className="icofont icofont-ui-check"></i> : <i style={{color: '#fc4438'}} className="icofont icofont-ui-block"></i>}</H3>
                                        <Button color='primary' onClick={toggleEmailModal}>{companyEmail ? "Изменить" : "Подключить"}</Button>
                                    </Col>
                                </Row>
                                
                                <Nav className="nav-primary mt-4" tabs>
                                    <NavItem>
                                        <NavLink style={{ cursor: 'pointer', padding: '0.5rem 0.9rem' }} className={primarycolorTab === '1' ? 'active' : ''} onClick={() => handleCourseTab('1')}>
                                            <i className="icofont icofont-book-alt"></i>
                                            Уведомления
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ cursor: 'pointer', padding: '0.5rem 0.9rem' }} className={primarycolorTab === '2' ? 'active' : ''} onClick={() => handleCourseTab('2')}>
                                            <i className="icofont icofont-teacher"></i>
                                            Типы уведомлений
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ cursor: 'pointer', padding: '0.5rem 0.9rem' }} className={primarycolorTab === '3' ? 'active' : ''} onClick={() => handleCourseTab('3')}>
                                            <i className="icofont icofont-teacher"></i>
                                            Email пользователи
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ cursor: 'pointer', padding: '0.5rem 0.9rem' }} className={primarycolorTab === '4' ? 'active' : ''} onClick={() => handleCourseTab('4')}>
                                            <i className="icofont icofont-teacher"></i>
                                            Telegram пользователи
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={primarycolorTab}>
                                    <TabPane className="fade show" tabId="1">
                                        <H4 attrH4={{ className: 'mb-2 mt-4' }}>Уведомления</H4>
                                        <div className="p-t-20 p-b-30">{<DataTableReminders eventTypes={eventTypes} />}</div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <H4 attrH4={{ className: 'mb-2 mt-4' }}>Типы уведомлений</H4>
                                        <div className="p-t-20 p-b-30">{<DataTableEventType eventTypes={eventTypes} />}</div>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <H4 attrH4={{ className: 'mb-2 mt-4' }}>Пользователи, подписанные на рассылку по Email</H4>
                                        <div className="p-t-20 p-b-30">{<DataTableEmailUsers eventTypes={eventTypes} />}</div>
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <H4 attrH4={{ className: 'mb-2 mt-4' }}>Пользователи, подписанные на рассылку по Telegram</H4>
                                        <div className="p-t-20 p-b-30">{<DataTableTelegramUsers eventTypes={eventTypes} />}</div>
                                    </TabPane>
                                </TabContent>
                            </Fragment>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
            
            <CommonModal isOpen={emailModal} title={companyEmail ? 'Изменить Email компании' : 'Привязать Email компании'} toggler={handleAddEmailRow} togglerClose={toggleEmailModal} closeText="Отмена" saveText="Сохранить">
				<CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
					<Form className="form theme-form">
						<Row>
							<FormGroup className="row" style={{ flexDirection: 'column' }}>
								<Label className="col-sm-9 col-form-label">SMTP адрес почты</Label>
								<Col sm="11">
									<Input className="form-control" value={smtpAddress} placeholder="Текст" onChange={(e) => setSmtpAddress(e.target.value)} />
								</Col>
                                <Label className="col-sm-9 col-form-label">Email</Label>
								<Col sm="11">
									<Input className="form-control" value={companyEmailAddress} placeholder="Текст" onChange={(e) => setCompanyEmailAddress(e.target.value)} />
								</Col>
                                <Label className="col-sm-9 col-form-label">Пароль от Email</Label>
								<Col sm="11">
									<Input className="form-control" value={companyEmailPassword} placeholder="Текст" onChange={(e) => setCompanyEmailPassword(e.target.value)} />
								</Col>
							</FormGroup>
						</Row>
					</Form>
				</CardBody>
			</CommonModal>
		</Fragment>
	);
}

export default Reminders;