import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit, Download } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import Select from "react-select";
import CommonModal from '../CommonModal';
import { Label, Input, CardBody, Col } from "reactstrap";
import FilterComponent from "../../Catalog/FilterComponent";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { useKeycloak } from '@react-keycloak/web';
import { ExportDataApi } from '../../../../api';
import { useExportDataContext } from "../../../../_helper/ExportData/ExportDataProvider";
import uuid from 'react-uuid';

const TableLayoutDataTypes = () => {
    const { layoutDataTypes, setLayoutDataTypes, getLayoutDataTypes, createLayoutDataType, updateLayoutDataType, deleteLayoutDataType,
        getResponses, responsesOptions, getDataTypes, dataTypesOptions } = useExportDataContext();
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const companyId = companyData.map((item) => (item.attributes.companyId[0]));


    useEffect(() => {
        getLayoutDataTypes();
        getResponses();
        getDataTypes();
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [edit, setEdit] = useState(false);
    const [currentObject, setCurrentObject] = useState({
        id: null,
        layoutName: "",
        responseId: "",
        dataTypeId: "",
        layoutValue: "",
        companyId: companyId
    });

    const toggleModal = (obj) => {

        setCurrentObject(obj);
        setEdit(obj.id !== null ? true : false);
        handleShow();

    };
    const handleShow = () => setShowModal(true);

    const handleClose = () => {
        setShowModal(false);
        setCurrentObject({
            id: null,
            layoutName: '',
            responseId: '',
            dataTypeId: '',
            layoutValue: ''
        });

    };

    const handleSave = async () => {

        const headers = {
            headers: { Authorization: `Bearer ${keycloak.token}` }
        };

        currentObject.companyId = companyId;

        if (currentObject.id !== null) {
            try {
                updateLayoutDataType(currentObject);
                toast.success("Запись успешно обновлена!");
            } catch (error) {
                console.error('Ошибка обновления записи:', error);
                toast.error("Ошибка обновления записи!");
            }
        } else {
            try {
                createLayoutDataType(currentObject)
                toast.success("Запись успешно добавлена!");
            } catch (error) {
                console.error('Ошибка создания записи:', error);
                toast.error("Ошибка создания записи!");
            }
        }
        handleClose();
    };

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const deleteObject = async (id) => {
        try {
            SweetAlert.fire({
                title: "Вы уверены?",
                text: "После удаления вы не сможете восстановить этот объект!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "ОК",
                cancelButtonText: "Отмена",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    deleteLayoutDataType(id);
                    setLayoutDataTypes(prev => prev.filter(req => req.id !== id));
                    toast.success("Запись успешно удалена!");
                }
            });
        } catch (error) {
            console.error('Ошибка удаления записи:', error);
            toast.error("Ошибка удаления записи!");
        }
    };

    const downloadFile = (layoutValue, layoutName, dataTypeId) => {
        if (!layoutValue || layoutValue.trim() === "") {
            console.error("layoutValue is empty or undefined");
            alert("Файл пустой. Невозможно скачать.");
            return;
        }

        const dataTypeOption = dataTypesOptions.find(obj => obj.value === dataTypeId);
        const fileType = dataTypeOption ? dataTypeOption.label.toLowerCase() : 'txt';

        let mimeType = 'text/plain';
        if (fileType === 'xml') {
            mimeType = 'application/xml';
        } else if (fileType === 'json') {
            mimeType = 'application/json';
        } else if (fileType === 'html') {
            mimeType = 'text/html';
        }

        const element = document.createElement("a");
        const file = new Blob([layoutValue], { type: mimeType });
        element.href = URL.createObjectURL(file);
        element.download = `${layoutName}.${fileType}`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const filteredItems = (layoutDataTypes || []).filter(item => item.layoutName && item.layoutName.toLowerCase().includes(filterText.toLowerCase()));

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                toggle={handleShow}
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const columns = [
        {
            name: "Id",
            selector: row => row.id,
            sortable: true,
        },
        {
            name: "Наименование",
            selector: row => row.layoutName,
            sortable: true,
            cell: row => (
                <a
                    href={`layout-datatypes/manage-layout?layoutId=${row.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: "pointer", color: "blue" }}
                >
                    {row.layoutName}
                </a>
            ),
        },
        {
            name: "Получатель",
            selector: row => {
                const responseOption = responsesOptions.find(obj => obj.value === row.responseId);
                return responseOption ? responseOption.label : 'Не найдено';
            },
            sortable: true,
        },
        {
            name: "Тип файла данных",
            selector: row => {
                const dataTypeOption = dataTypesOptions.find(obj => obj.value === row.dataTypeId);
                return dataTypeOption ? dataTypeOption.label : 'Не найдено';
            },
            sortable: true,
        },
        {
            name: "Скачивание файла",
            selector: row => row.layoutValue,
            sortable: true,
            cell: row => (
                <Tooltip title="Скачать шаблон" arrow placement="top">
                    <Download className="font-primary" size={"18px"} onClick={() => downloadFile(row.layoutValue, row.layoutName, row.dataTypeId)} style={{ cursor: "pointer" }} />
                </Tooltip>
            ),
        },
        {
            name: "",
            button: true,
            cell: row => (
                <div style={{ display: "flex" }}>
                    <Tooltip title="Редактировать" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggleModal(row)}>
                            <Edit size={"18px"} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteObject(row.id)}>
                            <Trash2 size={"18px"} />
                        </div>
                    </Tooltip>
                </div>
            ),
        }
    ];

    return (
        <>
            <DataTable
                columns={columns}
                data={filteredItems}
                noDataComponent={"В данной таблице ещё нет записей."}
                striped
                pagination
                subHeader
                subHeaderComponent={subHeaderComponent}
                paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
            />
            <CommonModal
                isOpen={showModal}
                title={edit ? "Редактировать запись" : "Добавить запись"}
                toggler={handleShow}
                togglerClose={handleClose}
                closeText="Отмена"
                saveText="Сохранить"
                size="lg"
                onSave={handleSave}
            >
                <CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Наименование</Label>
                        <Col sm="11">
                            <Input
                                className="form-control"
                                placeholder="Введите наименование"
                                value={currentObject.layoutName}
                                onChange={(e) => setCurrentObject({ ...currentObject, layoutName: e.target.value })}
                            />
                        </Col>
                    </Col>
                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Получатель</Label>
                        <Col sm="11">
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={responsesOptions}
                                value={responsesOptions ? responsesOptions.find(obj => obj.value === currentObject.responseId) : "Не найдено"}
                                onChange={(selectedOption) => setCurrentObject({ ...currentObject, responseId: selectedOption.value })}
                                className="js-example-basic-single col-sm-12"
                            />
                        </Col>
                    </Col>
                    <Col className="row col-sm-12 mb-2">
                        <Label className="col-sm-9 col-form-label">Тип файла данных</Label>
                        <Col sm="11">
                            <Select
                                required
                                placeholder="Начните вводить..."
                                noOptionsMessage={() => "Нет результатов"}
                                options={dataTypesOptions}
                                value={responsesOptions ? dataTypesOptions.find(obj => obj.value === currentObject.dataTypeId) : "Не найдено"}
                                onChange={(selectedOption) => setCurrentObject({ ...currentObject, dataTypeId: selectedOption.value })}
                                className="js-example-basic-single col-sm-12"
                            />
                        </Col>
                    </Col>
                </CardBody>
            </CommonModal>
        </>
    );
};

export default TableLayoutDataTypes;