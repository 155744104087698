import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

function CustomNodeMan({ data }) {
	return (
		<div>
			{data.label}
			<Handle type="target" position={Position.Top} className="w-16 !bg-teal-500" />
			<Handle type="source" position={Position.Bottom} className="w-16 !bg-teal-500" />
		</div>
	);
}

export default memo(CustomNodeMan);
