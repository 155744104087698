import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableCourses from './TableCourses';
import { EducationApi } from '../../../../api';
import CourseContext from '../../../../_helper/Course';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";

const Courses = () => {
	const [data, setData] = useState([]);
	const { courseData, getCourseData } = useContext(CourseContext);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${EducationApi}get-courses`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				//console.log(response);
				setData(concatResults(response).courses);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, [courseData]);

	useEffect(() => {
		if (!courseData) {
			getCourseData();
		}
	}, [keycloak.tokenParsed]);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список курсов" parent="Обучение" title="Список курсов" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardBody>
							<TableCourses courses={data} />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default Courses;
