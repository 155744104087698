import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Row, Card, CardBody, Col, Button } from "reactstrap";

import ProductContext from "../../../../../_helper/Ecommerce/Product";
import ProductPlaceOrder from "../../../PackageOffers/ClientView/ProductPlaceOrder";
import CommonModal from "../../../../UiKits/Modals/common/modal";
import { H4, LI, P, Image, UL, Btn } from "../../../../../AbstractElements";
import ProductModal from "./ProductModal";

const ProductGrid = ({ currentCards, productItem, symbol, installFunc, payFunc }) => {
	const layoutColumns = 3;

	const [dataid, setDataid] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const history = useNavigate();

	const onOpenModal = (productId) => {
		setOpenModal(true);
		setDataid(productId);
	};

	const addWishList = () => {
		history(`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`);
	};

	var images = require.context("../../../../../assets/images", true);
	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	const showBase64 = (image) => {
		try {
			if (btoa(atob(image)) === image) {
				return `data:image/png;base64,${image}`;
			} else {
				return `https://dummyimage.com/900x900`;
			}
		} catch (e) {
			return `https://dummyimage.com/900x900`;
		}
	};

	const renderStars = (rating) => {
		const stars = [];
		for (let i = 1; i <= 5; i++) {
			if (i <= rating) {
				stars.push(<i key={i} className="fa fa-star font-warning"></i>);
			} else {
				stars.push(<i key={i} className="fa fa-star font-light"></i>);
			}
		}
		return stars;
	};

	const [payModal, setPayModal] = useState(false);

	const togglePay = (product) => {
		setPayModal(!payModal);
		setName(product.name);
		setPrice(product.price);
	};

	//const SendPay = () => {
	//	// const newPay = {
	//	// 	client_id: 1, // по нормальному узнать id пользователя
	//	// 	package_id: id,
	//	// };
	//	// axios
	//	// 	.post(`/api/clientsPackageOffers.json`, newPay)
	//	// 	.then((response) => {
	//	// 		console.log(response.data);
	//	// 		toast.success('Данные отправлены!', {
	//	// 			position: toast.POSITION.TOP_RIGHT,
	//	// 		});
	//	// 		setPayModal(!payModal);
	//	// 	})
	//	// 	.catch((error) => {
	//	// 		console.error(error);
	//	// 	});

	//};

	const handleInstall = (id) => {
		installFunc(id);
		setOpenModal(false);
	};

	const handlePay = (id) => {
		payFunc(id);
		setPayModal(false);
	};

	const [price, setPrice] = useState(0);
	const [name, setName] = useState("");

	return (
		<Fragment>
			<div className="product-wrapper-grid" id="product-wrapper-grid">
				<Row className="gridRow" id="gridRow">
					{currentCards &&
						currentCards.map((item, i) => (
							<div id="gridId" className={`${layoutColumns === 3 ? "col-xl-3 col-lg-6 col-sm-6 xl-4 box-col-4" : "col-xl-" + layoutColumns}`} key={i}>
								<Card>
									<div className="product-box">
										<div className="product-img">
											{/* {item.installed ? <span className="ribbon ribbon-success ribbon-right">Установлено</span> : ""} */}

											{/* <Image attrImage={{ className: "img-fluid", src: `${dynamicImage(item.img)}`, alt: "" }} /> */}
											<Image attrImage={{ className: "img-fluid", src: `${showBase64(item.img)}`, alt: "" }} />
											<div className="product-hover">
												<UL attrUL={{ className: "simple-list d-flex flex-row" }}>
													<LI attrLI={{ className: "border-0" }}>
														<Button color="default" data-toggle="modal" onClick={() => onOpenModal(item.id)}>
															<i className="icon-eye"></i>
														</Button>
													</LI>
													{/* <LI attrLI={{ className: "border-0" }}>
														<Link to={`${process.env.PUBLIC_URL}/app/ecommerce/wishlist`}>
															<Button color="default" onClick={() => addWishList(item)}>
																<i className="icon-heart"></i>
															</Button>
														</Link>
													</LI> */}
												</UL>
											</div>
										</div>
										<div className="product-details">
											<div className="rating">
												{item.rating ? (
													renderStars(item.rating)
												) : (
													<>
														<i className="fa fa-star font-light"></i>
														<i className="fa fa-star font-light"></i>
														<i className="fa fa-star font-light"></i>
														<i className="fa fa-star font-light"></i>
														<i className="fa fa-star font-light"></i>
													</>
												)}
											</div>
											<Link to={`${process.env.PUBLIC_URL}/app/ecommerce/product-page/${item.id}`}>
												<H4>{item.name}</H4>
											</Link>
											<P>{item.note}</P>
											<Col className="d-flex mt-2" style={{ justifyContent: "space-between" }}>
												{item.price !== 0 ? (
													item.discountPrice ? (
														<div className="product-price">
															{item.price}
															{symbol}{" "}
															<del>
																{item.discountPrice}
																{symbol}
															</del>
														</div>
													) : (
														<div className="product-price" style={{ color: "#2c323fcc" }}>
															{item.price}
															{symbol}
														</div>
													)
												) : (
													<div className="product-price"></div>
												)}
												{item.installed ? (
													<Btn attrBtn={{ color: "success", className: "pe-3 ps-3", outline: true, disabled: true }}>
														<i className="fa fa-check"></i> Установлено
													</Btn>
												) : item.price === 0 ? (
													<Btn attrBtn={{ color: "success", className: "pe-3 ps-3 mt-2 f-right mb-3", onClick: () => onOpenModal(item.id) }}>Установить</Btn>
												) : (
													<Btn attrBtn={{ color: "success", className: "pe-3 ps-3", onClick: () => togglePay(item) }}>Купить</Btn>
												)}
											</Col>{" "}
										</div>
									</div>
								</Card>
							</div>
						))}
					{openModal && <ProductModal value={openModal} setOpenModal={setOpenModal} dataid={dataid} productItem={productItem} symbol={symbol} handleInstall={handleInstall} />}
					<CommonModal isOpen={payModal} toggler={handlePay} togglerClose={togglePay} size="lg" saveText="Оплатить">
						<CardBody style={{ padding: "0 51px" }}>
							<ProductPlaceOrder
								serviceList={[
									{
										name: name,
										price: price,
									},
								]}
							/>
						</CardBody>
					</CommonModal>
				</Row>
			</div>
		</Fragment>
	);
};
export default ProductGrid;
