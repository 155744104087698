import React, {Fragment, useState} from "react";
import { ToastContainer } from "react-toastify";
import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Taptop from "./TapTop";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ThemeCustomize from "../Layout/ThemeCustomizer";
import Footer from "./Footer";
import CustomizerContext from "../_helper/Customizer";
import AnimationThemeContext from "../_helper/AnimationTheme";
import ConfigDB from "../Config/ThemeConfig";
import Loader from "./Loader";
const AppLayout = ({ children, classNames, ...rest }) => {
	const { layout } = useContext(CustomizerContext);
	const { sidebarIconType } = useContext(CustomizerContext);
	const [headerHeight, setHeaderHeight] = useState(84);

	const layout1 = localStorage.getItem("sidebar_layout") || layout;
	const sideBarIcon = localStorage.getItem("sidebar_icon_type") || sidebarIconType;
	const location = useLocation();
	const { animation } = useContext(AnimationThemeContext);
	const animationTheme = localStorage.getItem("animation") || animation || ConfigDB.data.router_animation;

	const queryParams = new URLSearchParams(location.search);
	const page = queryParams.get("page");

	return (
		<Fragment>
			<Loader />
			<Taptop />
			<div className={`page-wrapper ${page ? "horizontal-wrapper" : "compact-wrapper"}`} sidebar-layout={sideBarIcon} id="pageWrapper">
				<Header setHeaderHeight={setHeaderHeight} />
				<div className="page-body-wrapper">
					{!page && <Sidebar headerHeight={headerHeight} />}
					<div className="page-body" style={{marginTop: "95px"}}>
						<TransitionGroup {...rest}>
							<CSSTransition key={location.key} timeout={100} classNames={animationTheme} unmountOnExit>
								<div>
									<div>
										<Outlet />
									</div>
								</div>
							</CSSTransition>
						</TransitionGroup>
					</div>
					{!page && <Footer />}
				</div>
			</div>
			<ThemeCustomize />
			<ToastContainer />
		</Fragment>
	);
};
export default AppLayout;
