import React, { Fragment, useEffect } from "react";
import Lightbox from "react-18-image-lightbox";
import SweetAlert from "sweetalert2";

const SingleImage = ({ photoIndex, setPhotoIndex }) => {
	useEffect(() => {
		if (photoIndex.isOpen && !["png", "jpg", "gif"].includes(photoIndex.type)) {
			SweetAlert.fire({
				title: "Формат файла не поддерживается",
				text: "Но вы можете просмотреть его на компьютере",
				icon: "info",
				showCancelButton: true,
				confirmButtonText: "Скачать файл",
				cancelButtonText: "Отмена",
				reverseButtons: true,
			}).then((result) => {
				if (result.value) {
					//  скачивание файла
				}
			});
		}
	}, [photoIndex]);

	return (
		<Fragment>
			{photoIndex.isOpen &&
				(["png", "jpg", "gif"].includes(photoIndex.type) ? <Lightbox mainSrc={photoIndex.content} onCloseRequest={() => setPhotoIndex({ ...photoIndex, isOpen: false })} /> : null)}
		</Fragment>
	);
};

export default SingleImage;
