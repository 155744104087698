import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { BeatLoader } from "react-spinners";
import { Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useKeycloak } from "@react-keycloak/web";
import { TaskApi } from "../../../api";
import axios from "axios";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import {useCompanyContext} from "../../../_helper/Company/CompanyProvider";

const CreateTag = ({ tagCallback, tagModal, setStatuses }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	let currentUserId = `${keycloak.tokenParsed.sub}`;
	const {
		handleSubmit,
		formState: { errors },
	} = useForm();
	const tagToggle = () => {
		tagCallback(false);
		setLoading(false);
	};

	const [color, setColor] = useState("#7366ff");
	const [name, setName] = useState(null);

	const [loading, setLoading] = useState(false);

	const sendStatus = () => {
		setLoading(true);

		const newStatus = {
			id: uuid(),
			name: name,
			userId: currentUserId,
			color: color,
		};

		axios
			.post(`${TaskApi}createStatus`, newStatus, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setLoading(false);
				tagCallback(false);
				toast.success("Статус добавлен!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setStatuses((prevStatuses) => [...prevStatuses, newStatus]);
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
				toast.error("Ошибка при добавлении", {
					position: toast.POSITION.TOP_RIGHT,
				});
			});
	};

	return (
		<Fragment>
			<Modal className="fade show modal-bookmark" isOpen={tagModal} toggle={tagToggle} size="lg">
				<Form className="form-bookmark needs-validation" onSubmit={handleSubmit(sendStatus)}>
					<ModalHeader className="modal-title" toggle={tagToggle}>
						Добавить статус
					</ModalHeader>
					<ModalBody>
						<div className="form-row">
							<FormGroup className="col-md-12">
								<Label>Название статуса</Label>
								<input className="form-control" name="name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
							</FormGroup>
							<FormGroup className="col-md-12 mb-0">
								<Label>Цвет статуса</Label>
								<input className="form-control fill-color" value={color} onChange={(e) => setColor(e.target.value)} name="color" type="color" defaultValue="#563d7c" />
							</FormGroup>
						</div>
					</ModalBody>
					<ModalFooter>
						<Btn attrBtn={{ color: "primary", disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : "Сохранить"}</Btn>
						<Btn attrBtn={{ color: "secondary primary m-l-5", onClick: tagToggle }}>Отмена</Btn>
					</ModalFooter>
				</Form>
			</Modal>
		</Fragment>
	);
};
export default CreateTag;
