import React from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import { Search } from "react-feather";

const FilterComponent = ({ filterText, onFilter, toggle }) => (
	<>
		<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }} className="mb-3">
			<InputGroup>
				<Input className="" type="text" placeholder="Поиск.." value={filterText} onChange={onFilter} />
				<InputGroupText className="bg-primary">
					<Search size={"20px"} />
				</InputGroupText>
			</InputGroup>
		</div>
	</>
);

export default FilterComponent;
