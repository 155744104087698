import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, Media, CardHeader, CardFooter } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import axios from 'axios';
import SweetAlert from 'sweetalert2';

import { Breadcrumbs, Btn, H5, H6 } from '../../../AbstractElements';

const PaymentForVisit = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const applicationId = queryParams.get('applicationId');

	const [item, setItem] = useState('Клиентская заявка');

	useEffect(() => {
		axios
			.get(`/api/clientsRequest.json`)
			.then((response) => {
				const foundItem = response.data.find((item) => item.id === applicationId);
				setItem(foundItem);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const pay = () => {
		//оплата
		axios
			.post(`/api/clientsRequest.json`)
			.then((response) => {
				console.log(response.data);

				SweetAlert.fire({ title: 'Оплата успешна!', text: `Ждем Вас ${formatDate(item.date)}`, icon: 'success' });
			})
			.catch((error) => {
				console.error(error);
			});
	};

	var images = require.context('../../../assets/images', true);

	const dynamicImage = (image) => {
		return images(`./${image}`);
	};

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Оплата визита" parent="Заявки" title="Оплата визита" />
			<Container fluid={true}>
				<Row>
					{item && (
						<Col sm="12">
							<Card>
								<CardBody>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className="media-size-email" style={{ position: 'relative', padding: '5px' }}>
											<Media body className="me-3 rounded-circle img-60" src={item.image ? dynamicImage(item.image) : require('../../../assets/images/user/7.jpg')} alt="" />
										</div>
										<div style={{ flexGrow: '1' }}>
											<Row>
												<div className="f-16" style={{ width: 'inherit' }}>
													{item.NameID} {item.SurnameID}
												</div>
											</Row>
											<div className={' m-t-5 mb-2'} style={{ color: '#6c757d' }}>
												{item.description}
											</div>
											<H6 attrH6={{ className: 'font-primary f-w-600' }}>{item.PhoneID}</H6>
										</div>
									</div>
								</CardBody>
							</Card>
							<Card>
								<CardHeader>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className="media-size-email" style={{ position: 'relative', padding: '5px' }}>
											<Media body className="me-3 rounded-circle img-60" src={item.image ? dynamicImage(item.image) : require('../../../assets/images/user/7.jpg')} alt="" />
										</div>
										<div style={{ flexGrow: '1' }}>
											<p className="mb-0">{item.employee_role}</p>
											<Row>
												<div className="f-16 mb-2" style={{ width: 'inherit' }}>
													{item.employee_name}
												</div>
												{item.prepayment && (
													<div style={{ width: 'inherit', backgroundColor: '#54ba4a40', padding: '2px 8px', color: '#214c1d' }} className="b-r-8">
														{item.prepayment_amount}
														{item.prepayment_unit} предоплата
													</div>
												)}
											</Row>
											<H6 attrH6={{ className: ' f-w-600' }}>{formatDate(item.date)}</H6>
										</div>
									</div>
								</CardHeader>
								<CardBody>
									<p>Услуги</p>
									<Col md="6" className="mt-2">
										<H6 attrH6={{ className: 'f-w-600 font-primary' }}>{item.service_cost} ₽</H6>
										<H6>{item.service_name}</H6>
									</Col>
								</CardBody>
								<CardFooter>
									<Row style={{ justifyContent: 'space-between' }}>
										<Col md="6">
											<Row>
												<Col md="6">
													<H6>Итого: {item.service_cost} ₽</H6>
												</Col>
											</Row>
										</Col>
										<Col md="3" sm="12">
											<Row>
												<Btn attrBtn={{ color: 'primary', onClick: pay, className: 'f-right' }}>Оплатить</Btn>
											</Row>
										</Col>
									</Row>
								</CardFooter>
							</Card>
						</Col>
					)}
				</Row>
			</Container>
		</Fragment>
	);
};
export default PaymentForVisit;
