import React, { useState, useEffect } from "react";
import Context from "./index";
import { useNodesState, useEdgesState, MarkerType } from "reactflow";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../Company/CompanyProvider";

const DnDProvider = ({ children }) => {
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [departments, setDepartments] = useState([]);

	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [lastNodePosition, setLastNodePosition] = useState({ x: 250, y: 5 });

	useEffect(() => {
		setNodes(convertToGraphNodes(departments));
		setEdges(createEdges(departments));
	}, [departments]);

	/*useEffect(() => {
		axios
			.get(`/api/departments.json`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setDepartments(response.data.departments);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);*/

	const getDepartments = async () => {
		axios
			.get(`/api/departments.json`, {
				headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			})
			.then((response) => {
				setDepartments(response.data.departments);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}

	function createEdges(departments) {
		const initialEdges = [];

		departments.forEach((department) => {
			if (department.parent_department) {
				initialEdges.push({
					id: `${department.parent_department}-${department.id}`,
					source: department.parent_department,
					target: department.id,
					type: "smoothstep",
					markerEnd: {
						type: MarkerType.ArrowClosed,
						width: 20,
						height: 20,
					},
				});
			}
		});

		return initialEdges;
	}

	function convertToGraphNodes(departments) {
		const initialNodes = [];

		departments.forEach((department, i) => {
			const departmentNode = {
				id: department.id,
				type: "department",
				data: {
					name: department.name,
					head: department.head,
					description: department.description,
					id: department.id,
					assistants: department.assistants,
					parent_department: department.parent_department,
				},
				position: { x: 140 + (-1) ** i * (i * 200), y: i * 140 },
				selectable: false,
			};

			initialNodes.push(departmentNode);
		});

		return initialNodes;
	}

	const [modal, setModal] = useState(false);
	const [edit, setEdit] = useState(null);

	const toggle = (data) => {
		if (typeof data === "object" && data.hasOwnProperty("id")) {
			setName(data.name);
			setDescription(data.description);
			setSelectUser(data.head);
			setSelectDepartment(data.parent_department);
			setEdit(true);
		} else {
			setName("");
			setDescription("");
			setSelectUser(null);
			setSelectDepartment(null);
			setEdit(false);
		}
		setModal(!modal);
	};

	//data
	const [description, setDescription] = useState("");
	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [selectUser, setSelectUser] = useState(null);
	const [selectDepartment, setSelectDepartment] = useState(null);

	return (
		<Context.Provider
			value={{
				setSelectUser,
				selectUser,
				selectDepartment,
				setSelectDepartment,
				edit,
				description,
				setDescription,
				id,
				setId,
				name,
				setName,
				modal,
				setModal,
				toggle,
				departments,
				setDepartments,
				nodes,
				setNodes,
				onNodesChange,
				edges,
				setEdges,
				onEdgesChange,
				lastNodePosition,
				setLastNodePosition,
				getDepartments
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default DnDProvider;
