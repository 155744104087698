import React, { useEffect, useState } from 'react';
import './FileUploadStep.css';
import { toast } from 'react-toastify';
import { useInteractivePlanMapsContext } from "../../../../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import Select from "react-select";
import { Label } from 'reactstrap';



const FileUploadStep = ({ nextStep, setFileData, setObjectTypeId }) => {
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { generateJsonFromImportedXml, getInteractivePlanMapsObjectsTypes, planMapsObjectsTypesOptions } = useInteractivePlanMapsContext();
    const [planMapObjectType, setPlanMapObjectType] = useState(null);

    useEffect(() => {
        getInteractivePlanMapsObjectsTypes();
    }, []);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            setFile(file);
            setIsFileSelected(true);
        }
    };

    const handleNextStep = async () => {
        if (!isFileSelected) {
            toast.error('Выберите файл!', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else if (!planMapObjectType) {
            toast.error('Выберите тип объекта!', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            setIsLoading(true);

            try {
                const response = await generateJsonFromImportedXml(file, planMapObjectType);
                await setFileData(response.data);
                await setObjectTypeId(planMapObjectType);
                nextStep();
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
                toast.error('Ошибка при загрузке данных!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            finally {
                setIsLoading(false);
            }

        }
    };

    return (
        <div className="file-upload-container">
            <h4>Загрузка файла</h4>
            <div className="file-upload-wrapper">
                <input
                    type="text"
                    value={fileName}
                    placeholder="Выберите файл..."
                    readOnly
                    className="file-name-display"
                />
                <label htmlFor="file-upload" className="custom-file-upload">
                    Выбрать...
                </label>
                <input id="file-upload" type="file" accept=".xml" onChange={handleFileUpload} />
            </div>
            <p style={{ fontSize: '13px' }}>Допустимые расширения файла: .xml</p>

            <div className="col-md-4 form-row" style={{ paddingBottom: '15px' }}>
                <Label className="col-sm-9 col-form-label" style={{ fontWeight: '700' }}>
                    Тип загружаемых объектов
                </Label>
                <Select
                    placeholder="Не выбрано"
                    noOptionsMessage={() => 'Нет результатов'}
                    options={planMapsObjectsTypesOptions}
                    onChange={(e) => setPlanMapObjectType(e?.value)}
                    className="js-example-basic-single col-sm-12"
                />
            </div>



            <button
                className="next-step-btn"
                onClick={handleNextStep}
                style={{ padding: "10px 20px;" }}
                disabled={isLoading}
            >
                {isLoading ? 'Загрузка...' : 'К следующему шагу'}
            </button>
            {isLoading && <div className="loader"></div>}

        </div>
    );
};

export default FileUploadStep;