// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* neo theme for codemirror */

/* Color scheme */

.cm-s-neo.CodeMirror {
  background-color:#ffffff;
  color:#2e383c;
  line-height:1.4375;
}
.cm-s-neo .cm-comment { color:#75787b; }
.cm-s-neo .cm-keyword, .cm-s-neo .cm-property { color:#1d75b3; }
.cm-s-neo .cm-atom,.cm-s-neo .cm-number { color:#75438a; }
.cm-s-neo .cm-node,.cm-s-neo .cm-tag { color:#9c3328; }
.cm-s-neo .cm-string { color:#b35e14; }
.cm-s-neo .cm-variable,.cm-s-neo .cm-qualifier { color:#047d65; }


/* Editor styling */

.cm-s-neo pre {
  padding:0;
}

.cm-s-neo .CodeMirror-gutters {
  border:none;
  border-right:10px solid transparent;
  background-color:transparent;
}

.cm-s-neo .CodeMirror-linenumber {
  padding:0;
  color:#e0e2e5;
}

.cm-s-neo .CodeMirror-guttermarker { color: #1d75b3; }
.cm-s-neo .CodeMirror-guttermarker-subtle { color: #e0e2e5; }

.cm-s-neo .CodeMirror-cursor {
  width: auto;
  border: 0;
  background: rgba(155,157,162,0.37);
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./vendor/codemirror/theme/neo.css"],"names":[],"mappings":"AAAA,6BAA6B;;AAE7B,iBAAiB;;AAEjB;EACE,wBAAwB;EACxB,aAAa;EACb,kBAAkB;AACpB;AACA,wBAAwB,aAAa,EAAE;AACvC,gDAAgD,aAAa,EAAE;AAC/D,0CAA0C,aAAa,EAAE;AACzD,uCAAuC,aAAa,EAAE;AACtD,uBAAuB,aAAa,EAAE;AACtC,iDAAiD,aAAa,EAAE;;;AAGhE,mBAAmB;;AAEnB;EACE,SAAS;AACX;;AAEA;EACE,WAAW;EACX,mCAAmC;EACnC,4BAA4B;AAC9B;;AAEA;EACE,SAAS;EACT,aAAa;AACf;;AAEA,qCAAqC,cAAc,EAAE;AACrD,4CAA4C,cAAc,EAAE;;AAE5D;EACE,WAAW;EACX,SAAS;EACT,kCAAkC;EAClC,UAAU;AACZ","sourcesContent":["/* neo theme for codemirror */\n\n/* Color scheme */\n\n.cm-s-neo.CodeMirror {\n  background-color:#ffffff;\n  color:#2e383c;\n  line-height:1.4375;\n}\n.cm-s-neo .cm-comment { color:#75787b; }\n.cm-s-neo .cm-keyword, .cm-s-neo .cm-property { color:#1d75b3; }\n.cm-s-neo .cm-atom,.cm-s-neo .cm-number { color:#75438a; }\n.cm-s-neo .cm-node,.cm-s-neo .cm-tag { color:#9c3328; }\n.cm-s-neo .cm-string { color:#b35e14; }\n.cm-s-neo .cm-variable,.cm-s-neo .cm-qualifier { color:#047d65; }\n\n\n/* Editor styling */\n\n.cm-s-neo pre {\n  padding:0;\n}\n\n.cm-s-neo .CodeMirror-gutters {\n  border:none;\n  border-right:10px solid transparent;\n  background-color:transparent;\n}\n\n.cm-s-neo .CodeMirror-linenumber {\n  padding:0;\n  color:#e0e2e5;\n}\n\n.cm-s-neo .CodeMirror-guttermarker { color: #1d75b3; }\n.cm-s-neo .CodeMirror-guttermarker-subtle { color: #e0e2e5; }\n\n.cm-s-neo .CodeMirror-cursor {\n  width: auto;\n  border: 0;\n  background: rgba(155,157,162,0.37);\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
