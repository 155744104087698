import React, { Fragment } from 'react';
import { CardBody, CardHeader } from 'reactstrap';
import { Card, Col, Container, Row } from 'reactstrap';
import DataTablePayment from './DataTablePayment';

const PaymentOnAccount = () => {
	return (
		<Fragment>
			<Container fluid={true} style={{ paddingTop: '50px' }}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h3 style={{ textAlign: 'center' }}>Оплата по счёту</h3>
							</CardHeader>
							<CardBody>
								<DataTablePayment />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default PaymentOnAccount;
