import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { CalendarApi, TwinAuthApi } from '../../api';
import CalendarContext from '.';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from '../Company/CompanyProvider';
import { concatResults } from '../../concatResults';

const CalendarProvider = ({ children, props }) => {
    const context = useCreateCalendarContext(props);
    
    return (
        <CalendarContext.Provider value={context}>{children}</CalendarContext.Provider>
    );
};

export function useCalendarContext() {
    const context = useContext(CalendarContext);
    if (!context) throw new Error('Use app context within provider!');
    return context;
}

export const useCreateCalendarContext = function(props) {
    const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

    const [calendarData, setCalendarData] = useState();
    const [calendarEventsData, setCalendarEventsData] = useState();
	const [meetUpEventsData, setMeetUpEventsData] = useState();
  
    const getCalendarData = async () => {
		keycloak.authenticated === true &&
			await axios
				.get(`${CalendarApi}get-calendars?creatorId=${keycloak.tokenParsed.sub}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					if (concatResults(response).calendars.length !== 0) {
						setCalendarData(concatResults(response).calendars);
						console.log(concatResults(response));
					}
					else {
						createCalendar("События", "events");
						createCalendar("Записи на услугу", "demands");
						createCalendar("Встречи", "meetings");
					}
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
	};

    //get-calendar-events
    const getCalendarEventsData = async (calendarId) => {
		keycloak.authenticated === true &&
			await axios
				.get(`${CalendarApi}get-calendar-events?calendarId=${calendarId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setCalendarEventsData(concatResults(response).events);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
	};

	const getCalendarMeetUpEventsData = async (eventId) => {
		keycloak.authenticated === true &&
			await axios
				.get(`${CalendarApi}get-meetup-events?eventId=${eventId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setMeetUpEventsData(concatResults(response).meetUps);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
	}

    const createCalendar = async (title, type) => {
        await axios
				.post(`${CalendarApi}create-calendar`, {title: title, creatorId: keycloak.tokenParsed.sub, calendarType: type}, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					getCalendarData();
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
    };

    const createCalendarEvent = async (calendarId, title, startTime, endTime, groupEvent = false, allDay = false, resource = null, ) => {
        await axios
				.post(`${CalendarApi}create-calendar-event`, {calendarId: calendarId, title: title, startDateTime: startTime, endDateTime: endTime, groupEvent: groupEvent, allDay: allDay, resource: resource}, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					//console.log(response);
					getCalendarEventsData(calendarId);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
    };

	const createMeetUpEvent = async (calendarId, title, startTime, endTime, groupEvent = false, allDay = false, meetups, resource = null) => {
		console.log(allDay);
        await axios
				.post(`${CalendarApi}create-meetup-events`, {calendarId: calendarId, Title: title, startDateTime:startTime.toJSON(), endDateTime: endTime.toJSON(), groupEvent: groupEvent, allDay: allDay, resource: resource, meetups: meetups }, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					getCalendarEventsData(calendarId);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
    };

	const createDemandEvent = async (calendarId, title, startTime, endTime, groupEvent = false, allDay = false, service, demandId, meetups, resource = null, employeeId) => {
		await axios
				.post(`${CalendarApi}create-meetup-events`, {
					calendarId: calendarId, 
					Title: title, 
					startDateTime:startTime.toJSON(), 
					endDateTime: endTime.toJSON(), 
					groupEvent: groupEvent, 
					allDay: allDay, 
					resource: resource, 
					demandId: demandId, 
					service: service, 
					meetups: meetups,
					employeeId: employeeId }, 
					{ headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					getCalendarEventsData(calendarId);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
	}

	const updateCalendarEvent = async (id, calendarId, title, startTime, endTime) => {
        await axios
				.put(`${CalendarApi}update-calendar-event`, {id: id, calendarId: calendarId, title: title, startDateTime: startTime, endDateTime: endTime}, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					//console.log(response);
					getCalendarEventsData(calendarId);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
    };

	const dropCalendarData = () => {
		setCalendarData(null);
	};

    return {
        calendarData,
        calendarEventsData,
		meetUpEventsData,
        getCalendarData,
		getCalendarEventsData,
		getCalendarMeetUpEventsData,
        createCalendar,
        createCalendarEvent,
		createMeetUpEvent,
		createDemandEvent,
		updateCalendarEvent,
		dropCalendarData
    };
}

export default CalendarProvider;