import React, {Fragment, useState} from 'react';
import {Container, Row, CardBody, Col, Card, Button, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import {Breadcrumbs, H3, H4} from '../../../../AbstractElements';
import DataTableCompanies from "./DataTableCompaniesMenu";
import DataTableModeration from "../ModerationAccount/DataTableModeration";

const Companies = () => {
	const [primarycolorTab, setprimarycolorTab] = useState('1');

	const handleCompanyTab = (num) => {
		setprimarycolorTab(num);
		localStorage.setItem('primaryCompanyTab', num);
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Список зарегистрированных компаний" parent="Админ" title="Список зарегистрированных компаний" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Fragment>
									<Nav className="nav-primary mt-4" tabs>
										<NavItem>
											<NavLink style={{ cursor: 'pointer', padding: '0.5rem 0.9rem' }} className={primarycolorTab === '1' ? 'active' : ''} onClick={() => handleCompanyTab('1')}>
												<i className="icofont icofont-book-alt"></i>
												Список зарегистрированных компаний
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink style={{ cursor: 'pointer', padding: '0.5rem 0.9rem' }} className={primarycolorTab === '2' ? 'active' : ''} onClick={() => handleCompanyTab('2')}>
												<i className="icofont icofont-teacher"></i>
												Модерация акккаунтов
											</NavLink>
										</NavItem>
									</Nav>
									<TabContent activeTab={primarycolorTab}>
										<TabPane className="fade show" tabId="1">
											<H4 attrH4={{ className: 'mb-2 mt-4' }}>Список зарегистрированных компаний</H4>
											<div className="p-t-20 p-b-30">{<DataTableCompanies/>}</div>
										</TabPane>
										<TabPane tabId="2">
											<H4 attrH4={{ className: 'mb-2 mt-4' }}>Модерация акккаунтов</H4>
											<div className="p-t-20 p-b-30">{<DataTableModeration/>}</div>
										</TabPane>
									</TabContent>
								</Fragment>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default Companies;