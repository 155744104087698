import React, { Fragment, useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Trash2, Edit } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import CommonModal from '../../../UiKits/Modals/common/modal';
import { Row, Col, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { ReminderApi, CompaniesApi } from '../../../../api';
import FilterComponent from '../../Categories/FilterComponent';
import { useKeycloak } from '@react-keycloak/web';
import { useCompanyContext } from '../../../../_helper/Company/CompanyProvider';
import Select from 'react-select';
import {concatResults} from "../../../../concatResults";

const DataTableTelegramUsers = (eventTypes) => {
    const { companyData } = useCompanyContext();
    const { keycloak } = useKeycloak();

    const [data, setData] = useState();
    const [userTgs, setUserTgs] = useState();

    const [modal, setModal] = useState(false);

    const [companyUsers, setCompanyUsers] = useState([]);

    const [optionsUsers, setOptionsUsers] = useState();
    const [optionsTypes, setOptionsTypes] = useState();

    const [eventTypeId, setEventTypeId] = useState('');
    const [userId, setUserId] = useState('');

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filteredItems, setFilteredItems] = useState(data);

    useEffect(() => {
        if (Array.isArray(eventTypes.eventTypes)) {
            setOptionsTypes(eventTypes.eventTypes.map((item) => ({
                value: item.id,
                label: item.name
            })));
        }
    }, [eventTypes])

    useEffect(() => {
        companyData &&
            axios
                .get(`${ReminderApi}get-company-telegram-users/${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                .then((response) => {
                    setData(concatResults(response).companyTelegramUsers);
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });

        companyData &&
            axios
                .get(`${ReminderApi}get-company-users-telegram/${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                .then((response) => {
                    setUserTgs(concatResults(response).telegramUsers);
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });

        companyData &&
            axios
                .get(`${CompaniesApi}get-company-users?companyId=${companyData[0]?.id}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                .then((response) => {
                    setCompanyUsers(JSON.parse(response.data));
                    setOptionsUsers(JSON.parse(response.data).map((item) => ({
                        value: item.id,
                        label: `${item.firstName} ${item.lastName}`
                    })))
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });
    }, [companyData]);

    useEffect(() => {
        data && setFilteredItems(data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [filterText, setFilterText]);

    useEffect(() => {
        data && setFilteredItems(data.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1));
    }, [data, setData])

    const toggle = () => {
        setEventTypeId('');
        setUserId('');

        setModal(!modal);
    };

    const getCompanyUser = (userId) => {
        let tgUser = userTgs.find(u => u.id === userId);

        if (tgUser) {
            let user = companyUsers.find(u => u.id === tgUser.userId);
            console.log(companyUsers);
            if (user) {
                return (`${user.firstName} ${user.lastName}`);
            }
        }
    }

    const getEventType = (eventId) => {
        let type = eventTypes.eventTypes.find(e => e.id === eventId);
        if (type) {
            return type.name
        }
    }

    const handleAddRow = () => {
        setModal(!modal);

        axios
            .get(`${ReminderApi}get-telegram-user/${userId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
            .then((response) => {
                const newRow = {
                    companyId: companyData[0]?.id,
                    eventTypeId: eventTypeId,
                    telegramUserId: concatResults(response).id
                };
                axios
                    .post(`${ReminderApi}create-company-telegram-user`, newRow, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                    .then((response) => {
                        toast.success('пользователь добавлен!', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    })
                    .catch((error) => {
                        toast.error('произошла ошибка при добавлении.', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        console.error(error);
                    });
            })
            .catch((error) => {
                toast.error('произошла ошибка при добавлении.', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.error(error);
            });

    };

    const deleteRow = (remId) => {
        SweetAlert.fire({
            title: 'Вы уверены?',
            text: 'Данное действие не отменит отправку оповщений!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'ОК',
            cancelButtonText: 'Отмена',
            reverseButtons: true,
        }).then((result) => {
            axios
                .delete(`${ReminderApi}delete-company-telegram-user/${remId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
                .then(() => {
                })
                .catch((error) => {
                    console.error('Ошибка получения данных:', error);
                });
        });
    };

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const tableColumns = [
        {
            name: 'Тип уведомления',
            selector: (row) => `${getEventType(row.eventTypeId)}`,
            sortable: true,
            center: true,
        },
        {
            name: 'Пользователь',
            selector: (row) => `${getCompanyUser(row.telegramUserId)}`,
            sortable: true,
            center: false,
            width: '20%',
        },
        {
            name: '',
            button: true,
            selector: (row) => (
                <div style={{ display: 'flex' }}>
                    <Tooltip title="Удалить" arrow placement="top">
                        <div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteRow(row.id)}>
                            <Trash2 size={'18px'} />
                        </div>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <CommonModal isOpen={modal} title={'Добавить пользователя к рассылке'} toggler={handleAddRow} togglerClose={toggle} closeText="Отмена" saveText="Сохранить">
                <CardBody style={{ padding: '10px', paddingLeft: '15px' }}>
                    <Form className="form theme-form">
                        <Row>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label">Пользователь, которому будут отправляться уведомления</Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={optionsUsers} onChange={(e) => setUserId(e.value)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row" style={{ flexDirection: 'column' }}>
                                <Label className="col-sm-9 col-form-label" htmlFor="exampleFormControlInput1">
                                    Тип уведомления
                                </Label>
                                <Col sm="11">
                                    <Select placeholder="Не выбрано" noOptionsMessage={() => 'Нет результатов'} options={optionsTypes} onChange={(e) => setEventTypeId(e.value)} className="js-example-basic-single col-sm-12" />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </CommonModal>
            <DataTable data={filteredItems} columns={tableColumns} striped={true} center={true} pagination highlightOnHover pointerOnHover subHeader subHeaderComponent={subHeaderComponent} noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
        </Fragment>
    );
};

export default DataTableTelegramUsers;
