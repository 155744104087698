import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Btn, H5 } from "../../../../AbstractElements";
import { CodeExecutor } from "./CodeEditor/CodeExecutor";
import { CodeEditor } from "./CodeEditor/CodeEditor";
import { Tabs } from "./CodeEditor/Tabs";
import { CmsApi } from "../../../../api";

const BodyEditor = () => {
	const { siteId } = useParams();

	const initialHTML = `<div class="page-body"><div><div><div><div class="container-fluid"><div class="page-title"><div class="row"><div class="col-6"><h3>Заголовок страницы</h3></div><div class="col-6"><ol class="breadcrumb"><li class="breadcrumb-item"><a href="/app/users/profile"><svg><use href="/svg/sprite.svg#stroke-home"></use></svg></a></li><li class="breadcrumb-item">Раздел страницы</li><li class="breadcrumb-item active">Заголовок страницы</li></ol></div></div></div></div><div class="container-fluid"><div class="row"><div class="col-sm-12"><div class="card"><div class="card-header"><h5 class="m-b-0">Название карточки</h5></div><div class="card-body">Тело карточки<div class="icon-lists feather-icons row"></div></div></div></div></div></div><div class="icon-hover-bottom p-fixed fa-fa-icon-show-div"><div class="container-fluid"><div class="row"><div class="icon-popup"><div class="close-icon"><i class="icofont icofont-close"></i></div><div class="icon-first me-2"><i id="icon_main"></i></div><div class="icon-class"><label class="icon-title form-label"></label><span id="fclass1"></span></div><div class="icon-last icon-last"></div></div></div></div></div></div></div></div></div></div></div>`;

	const initialCSS = `
    .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        margin-right: auto;
        margin-left: auto;
        font-family: "Inter", sans-serif;
        color: #2f2f3b;
    }
    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1* var(--bs-gutter-y));
        margin-right: calc(-0.5* var(--bs-gutter-x));
        margin-left: calc(-0.5* var(--bs-gutter-x));
    }
    .text-center {
        text-align: center !important;
    }
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .page-wrapper .page-body-wrapper .page-title {
        padding: 15px 30px;
        margin: 0 -27px 30px;
        background-color: #fff;}
    .page-wrapper .page-body-wrapper .page-title .row {
        align-items: center;
    }
    .page-wrapper .page-body-wrapper .page-title > .row {
        margin: 0;
    }
    .mb-0 {
        margin-bottom: 0 !important;
    }
    .col-6 {
        flex: 0 0 auto;
        width: 50%;
    }
.page-title > .row .col-6 {
        padding: 0;
    }
    h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
       
    }
 .page-title .row .h3 {
        font-size: 24px;
        margin-bottom: 0;

    }
    .row > div {
        position: relative;
    }
 .page-title .breadcrumb .breadcrumb-item a svg {
        width: 16px;
        height: 16px;
        stroke: #2c323f;
        vertical-align: text-top;
    }
 .breadcrumb {
        background-color: transparent;
        padding: 0;
        margin-bottom: 0;
        align-items: center;
        justify-content: flex-end;
        list-style: none;
        display: flex;
    flex-wrap: wrap;
    }
.page-title .breadcrumb li {
        font-size: 13px;
        letter-spacing: 1px;
        font-family: Roboto, sans-serif;
    }
.page-title .breadcrumb .breadcrumb-item.active {
        color: #7366ff;
        font-weight: 500;
    }
    * a {
    color: #7366ff;
    text-decoration: none;
    letter-spacing: 1px;}
    .card {
        margin-bottom: 30px;
        border: none;
        transition: all 0.3s ease;
        letter-spacing: 0.5px;
        border-radius: 15px;
        box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
    }
    .page-wrapper .page-body-wrapper .page-title .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
        content: "/";
    }
    .breadcrumb-item + .breadcrumb-item {
        padding-left:0.5rem;
    }
    .breadcrumb-item + .breadcrumb-item::before {
        float: left;
        padding-right: 0.5rem;
        color: var(--bs-breadcrumb-divider-color);
        content: var(--bs-breadcrumb-divider, "/") 
    }
    .card .card-header {
        background-color: #fff;
        padding: 30px 30px 10px;
        border-bottom: 1px solid #ecf3fa;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        position: relative;    font-family: "Inter", sans-serif;
    color: #2f2f3b;
    }
    .card .card-body {
        padding: 30px;
        background-color: transparent;
        flex: 1 1 auto;
    }


    `;

	const [mode, setMode] = useState("html");
	const [html, setHtml] = useState(initialHTML);
	const [css, setCss] = useState(initialCSS.trim());
	const [srcDoc, setSrcDoc] = useState("");

	const runCode = () => {
		setSrcDoc(
			`<style>${css}</style>
		<body>${html}</body>`
		);
	};

	const propsByMode = {
		html: {
			mode: "xml",
			value: html,
			setValue: setHtml,
		},
		css: {
			mode: "css",
			value: css,
			setValue: setCss,
		},
	};

	const createBody = () => {
		const newBody = {
			Type: "body",
			SiteId: siteId,
			content: srcDoc,
		};
		console.log(newBody);

		// axios
		// 	.post(`${CmsApi}createMenuLink`, newBody)
		// 	.then((response) => {
		// 		console.log(concatResults(response));
		// 		toast.success("Ссылка добавлена!", {
		// 			position: toast.POSITION.TOP_RIGHT,
		// 		});
		// 	})
		// 	.catch((error) => {
		// 		console.error("Ошибка получения данных:", error);
		// 	});
	};

	return (
		<>
			<H5>Редактор тела сайта</H5>
			<Tabs mode={mode} setMode={setMode} runCode={runCode} />
			<CodeEditor {...propsByMode[mode]} />
			<CodeExecutor srcDoc={srcDoc} height="60vh" />
			<div className="modal-footer mt-3">
				<Btn attrBtn={{ color: "primary", onClick: createBody }}>Сохранить</Btn>
			</div>
		</>
	);
};

export default BodyEditor;
