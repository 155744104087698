import React, { useMemo, useState, useEffect } from "react";
import { Trash2, Edit, Users, Eye, EyeOff, Check, Plus } from "react-feather";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "react-data-table-component";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import Select from "react-select";
import { Label, Input, CardBody, Col, ButtonGroup, Row, Collapse } from "reactstrap";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import FilterComponent from "../../Catalog/FilterComponent";
import CommonModal from "../../../UiKits/Modals/common/modal";

import { TwinAuthApi, EmailsApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";
import { Btn, H5 } from "../../../../AbstractElements";
import { concatResults } from "../../../../concatResults";

const TableEmailAdressList = ({ serverId, server }) => {
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [filteredProducts, setFilteredProducts] = useState([]);
	const [name, setName] = useState("");
	const [usersData, setUsersData] = useState([]);

	useEffect(() => {
		fetchData();
	}, [serverId]);

	const fetchData = async () => {
		try {
			getEmailsData()
				.then((emailsData) => {
					console.log(emailsData);
				})
				.catch((error) => {
					console.error("Error:", error);
				});

			setName(server.server_address);
		} catch (error) {
			console.error("Ошибка при выполнении запроса:", error);
		}
	};

	async function getEmailRoles(emailId) {
		const response = await axios.get(`${EmailsApi}getServerEmailRoles`, {
			params: { EmailId: emailId },
		});

		const allUsersResponse = await axios.get(`${TwinAuthApi}get-all-users`, {
			headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
		});
		const allUsers = JSON.parse(response.data).map((option) => ({
			label: `${option.firstName} ${option.lastName}`,
			value: option.id,
		}));

		const filteredUsers = allUsers.filter((user) => concatResults(response).serverEmailRoles.some((role) => role.userId === user.value));

		const users = filteredUsers.map((user) => ({
			name: user.label,
			company: "",
			role: "",
			usedDefault: false,
		}));

		return users;
	}

	async function getEmailsData() {
		const response = await axios.get(`${EmailsApi}getServerEmails`, {
			headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
			params: { serverId: serverId },
		});

		const emailsData = await Promise.all(
			concatResults(response).serverEmails.map(async (email) => {
				const usersData = await getEmailRoles(email.id);
				return {
					id: email.id,
					token: email.token,
					password: email.password,
					email: email.email,
					users: usersData,
				};
			})
		);
		setFilteredProducts(emailsData);
	}

	const columns = [
		{
			name: "E-mail",
			selector: (row) => `${row.email}`,
			sortable: true,
		},
		{
			name: "Пароль",
			selector: (row) => `${row.password}`,
			sortable: true,
		},
		{
			name: "Токен",
			selector: (row) => `${row.token}`,
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteNews(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Список пользователей" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggleUsers(row)}>
							<Users size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const columnsUsers = [
		{
			name: "Пользователь",
			selector: (row) => `${row.name}`,
			sortable: true,
		},
		{
			name: "Компания",
			selector: (row) => `${row.company}`,
			sortable: true,
		},
		{
			name: <div>Роль пользователя</div>,
			selector: (row) => `${row.company}`,
			sortable: true,
		},
		{
			name: <div>Используется по умолчанию</div>,
			selector: (row) => (row.usedDefault ? <Check className="font-success" /> : ""),
			sortable: true,
		},
		{
			name: "",
			button: true,
			selector: (row) => (
				<div style={{ display: "flex" }}>
					<Tooltip title="Редактировать" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => toggle(row)}>
							<Edit size={"18px"} />
						</div>
					</Tooltip>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => deleteNews(row.id)}>
							<Trash2 size={"18px"} />
						</div>
					</Tooltip>
				</div>
			),
		},
	];

	const createEmail = () => {
		if (mail == "" && (password == "" || token == "")) {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const newMail = {
				email: mail,
				password: password,
				token: token,
				serverId: serverId,
			};

			axios
				.post(`${EmailsApi}createServerEmail`, newMail, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					toast.success("Почта добавлена!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
	};

	const editEmail = () => {
		if (mail == "" && (password == "" || token == "")) {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		} else {
			const newMail = {
				id: id,
				email: mail,
				password: password,
				token: token,
				serverId: serverId,
			};

			axios
				.put(`${EmailsApi}updateServerEmail`, newMail, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					toast.success("Почта обновлена!", {
						position: toast.POSITION.TOP_RIGHT,
					});
					setModal(false);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		}
	};

	const [modal, setModal] = useState(false);

	const toggle = (ObjectId) => {
		console.log(ObjectId);
		if (ObjectId instanceof Object && !ObjectId.preventDefault) {
			setMail(ObjectId.email);
			setToken(ObjectId.token);
			setPassword(ObjectId.password);
			setId(ObjectId.id);
			setEdit(true);
		} else {
			setMail("");
			setToken("");
			setPassword("");
			setId("");
			setEdit(false);
		}
		setModal(!modal);
	};

	const [modalUsers, setModalUsers] = useState(false);

	const toggleUsers = (obj) => {
		setEmailId(obj.id);
		setModalUsers(!modalUsers);
		setUsersData(obj.users);
	};

	const [edit, setEdit] = useState(null);

	const deleteNews = (id) => {
		SweetAlert.fire({
			title: "Вы уверены?",
			text: "После удаления вы не сможете восстановить этот объект!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "ОК",
			cancelButtonText: "Отмена",
			reverseButtons: true,
		}).then((result) => {
			if (result.value) {
				axios
					.delete(`${EmailsApi}deleteServerEmail/${id}`, {
						headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
					})
					.then((response) => {
						SweetAlert.fire("Удалено!", "Объект был удален.", "success");
					})
					.catch((error) => {
						console.error("Произошла ошибка при удалении:", error);
					});
			}
		});
	};

	const [filterText, setFilterText] = React.useState("");
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = filteredProducts.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText("");
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);

	const [id, setId] = useState("");
	const [mail, setMail] = useState("");
	const [token, setToken] = useState("");
	const [password, setPassword] = useState("");
	const [useDefault, setUseDefault] = useState(true);
	const [emailId, setEmailId] = useState(null);

	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const [showMailInfo, setShowMailInfo] = useState(false);

	const addMail = () => {
		setShowMailInfo(!showMailInfo);
	};

	const [showToken, setShowToken] = useState(false);

	const toggleTokenVisibility = () => {
		setShowToken(!showToken);
	};

	const [addressRecipient, setAddressRecipient] = useState(null);
	const [emails, setEmails] = useState([]);
	useEffect(() => {
		axios
			.get(`${TwinAuthApi}get-all-users`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const selectOptions = JSON.parse(response.data).map((option) => ({
					label: option.firstName + " " + option.lastName + " (" + option.email + ")",
					value: option.id,
				}));
				setEmails(selectOptions);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	}, []);

	const [viewType, setViewType] = useState("user");

	const handleViewChange = (view) => {
		setViewType(view);
	};

	const sendRole = () => {
		console.log(addressRecipient);
		console.log(emailId);

		var newRole = {
			emailId: emailId,
			userId: addressRecipient.value,
		};

		axios
			.post(`${EmailsApi}createServerEmailRole`, newRole, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				//console.log(concatResults(response));
				toast.success("Роль добавлена!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				setModal(false);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	return (
		<Col className="m-t-10 ms-4">
			<H5 attrH5={{ className: "mb-4" }}>Подключенные почтовые ящики на сервере {name}</H5>
			<DataTable
				columns={columns}
				data={filteredItems}
				defaultSortField="name"
				striped
				pagination
				subHeader
				subHeaderComponent={subHeaderComponent}
				paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
			/>
			<CommonModal isOpen={modal} title={edit ? "Редактировать" : "Добавить"} toggler={edit ? editEmail : createEmail} togglerClose={toggle} closeText="Отмена" saveText="Сохранить" size="md">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Почта</Label>
						<Col sm="12">
							<Input className="form-control" value={mail} onChange={(e) => setMail(e.target.value)} />{" "}
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Пароль</Label>
						<Col sm="12">
							<Input type={showPassword ? "text" : "password"} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} style={{ position: "relative" }} />
							{showPassword ? (
								<EyeOff className="font-primary" onClick={togglePasswordVisibility} size={"18px"} style={{ position: "absolute", top: "10px", right: "30px", cursor: "pointer" }} />
							) : (
								<Eye className="font-primary" onClick={togglePasswordVisibility} size={"18px"} style={{ position: "absolute", top: "10px", right: "30px", cursor: "pointer" }} />
							)}
						</Col>
					</Col>
					<Col className="row col-sm-12 mb-3">
						<Label className="col-sm-9 col-form-label">Токен</Label>
						<Col sm="12">
							<Input type={showToken ? "text" : "password"} className="form-control" value={token} onChange={(e) => setToken(e.target.value)} style={{ position: "relative" }} />
							{showToken ? (
								<EyeOff className="font-primary" onClick={toggleTokenVisibility} size={"18px"} style={{ position: "absolute", top: "10px", right: "30px", cursor: "pointer" }} />
							) : (
								<Eye className="font-primary" onClick={toggleTokenVisibility} size={"18px"} style={{ position: "absolute", top: "10px", right: "30px", cursor: "pointer" }} />
							)}
						</Col>
					</Col>
				</CardBody>
			</CommonModal>
			<CommonModal isOpen={modalUsers} title={`Список назначенных пользователей для почты ${name}`} togglerClose={toggleUsers} size="lg">
				<CardBody style={{ padding: "10px", paddingLeft: "40px", paddingTop: "0" }}>
					<Col
						className=" f-14 p-t-15 p-b-15 b-r-8 b-b-light mb-4 d-flex ps-4"
						onClick={addMail}
						style={{ cursor: "pointer", alignItems: "center", backgroundColor: "#7366ff08", margin: "0.25rem 0 0" }}
					>
						{showMailInfo ? <i className="fa fa-angle-down me-2 f-16"></i> : <i className="fa f-16 fa-angle-right me-2 "></i>} <span>Добавить почту</span>
					</Col>

					<Collapse isOpen={showMailInfo}>
						<Col md="12">
							<ButtonGroup className="mb-3">
								<Btn attrBtn={{ color: "primary", outline: viewType !== "user" && true, onClick: () => handleViewChange("user") }}>Пользователь</Btn>
								<Btn attrBtn={{ color: "primary", outline: viewType !== "role" && true, onClick: () => handleViewChange("role") }}>Выбрать роль</Btn>
								<Btn attrBtn={{ color: "primary", outline: viewType !== "company" && true, onClick: () => handleViewChange("company") }}>Компания</Btn>
							</ButtonGroup>
							{viewType === "user" ? (
								<Select
									placeholder="Введите имя пользователя"
									noOptionsMessage={() => "Нет результатов"}
									options={emails}
									value={addressRecipient}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											primary25: "#7366ff80",
											primary: "#7366ff",
										},
									})}
									className="js-example-basic-single col-md-12 mb-2"
									onChange={(option) => setAddressRecipient(option)}
								/>
							) : viewType === "company" ? (
								<Select
									placeholder="Введите название компании"
									noOptionsMessage={() => "Нет результатов"}
									options={emails}
									value={addressRecipient}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											primary25: "#7366ff80",
											primary: "#7366ff",
										},
									})}
									className="js-example-basic-single col-md-12 mb-2"
									onChange={(option) => setAddressRecipient(option)}
								/>
							) : (
								<Select
									placeholder="Введите роль пользователя"
									noOptionsMessage={() => "Нет результатов"}
									options={emails}
									value={addressRecipient}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											primary25: "#7366ff80",
											primary: "#7366ff",
										},
									})}
									className="js-example-basic-single col-md-12 mb-2"
									onChange={(option) => setAddressRecipient(option)}
								/>
							)}

							<div className="checkbox checkbox-primary mb-3 ms-2">
								<Input id="checkbox-primary-2" type="checkbox" checked={useDefault} onChange={(e) => setUseDefault(e.target.checked)} />
								<Label for="checkbox-primary-2">Использовать по умолчанию</Label>
							</div>
							<div className="modal-footer mb-3">
								<Btn attrBtn={{ color: "primary", onClick: () => sendRole() }}>Добавить</Btn>
							</div>
						</Col>
					</Collapse>

					<DataTable
						columns={columnsUsers}
						data={usersData}
						defaultSortField="name"
						striped
						pagination
						paginationComponentOptions={{ rowsPerPageText: "Строк на странице:", rangeSeparatorText: "из" }}
					/>
				</CardBody>
			</CommonModal>
		</Col>
	);
};

export default TableEmailAdressList;
