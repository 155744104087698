// LeftPanel.js
import React, { useState } from 'react';
import { Trash2, PlusCircle, ShoppingBag } from 'react-feather';
import { Plus, Users, Calendar, CheckSquare } from 'react-feather';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Btn, H4 } from '../../../../AbstractElements';

import { Row, Col, Label, Input, CardBody } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import CommonModal from '../../../UiKits/Modals/common/modal';

import SelectDateTime from '../SelectDateTime';
import EmployeeList from '../EmployeeList';
import ServicesList from '../ServicesList';
import uuid from 'react-uuid';
import ProductsList from '../ProductsList';

const LeftPanelMenu = ({ onAdd, onRemove, demandForm, setDemandForm }) => {
	const [primarycolorTab, setprimarycolorTab] = useState(1);

	// <h2>Форма</h2>

	const [elementName, setElementName] = useState(undefined);
	const [elementType, setElementType] = useState({ label: null, value: null });
	const [modalAdd, setModalAdd] = useState(false);
	const [curItem, setCurItem] = useState('');

	const toggle = () => {
		setModalAdd(!modalAdd);
	};

	const [modalWidget, setModalWidget] = useState(false);

	const toggleWidget = (item) => {
		setModalWidget(!modalWidget);
		setCurItem(item);
	};

	const handleClick = () => {
		if (elementName) {
			const item = {
				name: elementName,
				elementId: uuid(),
				type: elementType.value,
				order: demandForm.menuItems.length + 1,
				parameters: null,
			};
			onAdd(item);
			console.log(item);
			setElementName(undefined);
			setModalAdd(false);
		} else {
			toast.error('Введите название элемента!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
		console.log(demandForm.menuItems);
	};

	const handleAddWidget = () => {
		const updatedItems = demandForm.menuItems.map((item) => {
			if (item.elementId === curItem) {
				if (elementType.value === 'DateTimeMenu') {
					return {
						...item,
						type: elementType.value,
						parameters: [
							{ name: 'Время', elementId: uuid(), type: 'TimeCalendar', order: 1 },
							{ name: 'Дата записи', elementId: uuid(), type: 'DateCalendar', order: 2 },
						],
					};
				} else if (elementType.value === 'EmployeesMenu') {
					return {
						...item,
						type: elementType.value,
						parameters: [{ name: 'Список специалистов', elementId: uuid(), type: 'EmployeesBar', order: 1 }],
					};
				} else {
					return {
						...item,
						type: elementType.value,
						parameters: [{ name: 'Список услуг', elementId: uuid(), type: 'ServicesBar', order: 1 }],
					};
				}
			}
			return item;
		});

		// Обновляем состояние demandForm, устанавливая новый массив menuItems
		setDemandForm((prevDemandForm) => ({
			...prevDemandForm,
			menuItems: updatedItems,
		}));
		// Закрываем модальное окно и сбрасываем выбранный тип элемента
		setModalWidget(false);
		setElementType({ label: null, value: null });
		console.log(updatedItems);
	};

	return (
		<Row>
			<Col sm="4" xs="12">
				<Nav className="nav flex-column nav-pills nav-light txt-dark">
					{demandForm.menuItems.length > 0
						? demandForm.menuItems.map((item, i) => (
							<NavItem key={i}>
								<NavLink style={{ cursor: 'pointer', borderRadius: '15px', fontSize: '16px' }} className={`${primarycolorTab === i ? 'active' : ''} txt-dark mb-1`} onClick={() => setprimarycolorTab(i)}>
									<Row style={{ alignItems: 'center', justifyContent: 'space-between' }} className="p-r-20 p-l-20 p-10">
										<Col md="10">
											{item.type === 'DateTimeMenu' ? <Calendar className="m-r-20" /> : item.type === 'EmployeesMenu' ? <Users className="m-r-20" /> : item.type === 'ServicesMenu' ? <CheckSquare className="m-r-20" /> : item.type === 'ProductsMenu' ? <ShoppingBag className="m-r-20" /> : ''}
											{item.name}
										</Col>
										<Col md="2">
											<Trash2 onClick={() => onRemove(i)} className="font-primary" />
										</Col>
									</Row>
								</NavLink>
							</NavItem>
						))
						: ''}

					<NavItem>
						<NavLink style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', padding: '20px', borderRadius: '15px', marginBottom: '20px' }} className="badge-light font-dark f-16" onClick={toggle}>
							Добавить пункт меню <PlusCircle size={'26px'} />
						</NavLink>
					</NavItem>
				</Nav>
			</Col>
			<CommonModal isOpen={modalAdd} title="Добавить новый элемент" toggler={handleClick} togglerClose={toggle} closeText="Отмена" saveText="Добавить">
				<CardBody style={{ padding: '0 15px 10px' }}>
					<Label className="col-sm-9 col-form-label">Введите название элемента</Label>
					<Col sm="12">
						<Input className="form-control" type="text" value={elementName} onChange={(e) => setElementName(e.target.value)} />
					</Col>
				</CardBody>
			</CommonModal>
			<Col sm="8" xs="12">
				<TabContent activeTab={primarycolorTab}>
					{demandForm.menuItems.length > 0
						? demandForm.menuItems.map((item, i) => (
							<TabPane tabId={i}>
								<H4>{item.name}</H4>
								{item.type === null ? (
									<Btn attrBtn={{ color: 'primary', className: 'mt-3', onClick: () => toggleWidget(item.elementId) }}>
										<Plus size={'20px'} style={{ margin: '0 3px -5px 0px' }} />
										Добавить виджет
									</Btn>
								) : item.type === 'DateTimeMenu' ? (
									<SelectDateTime />
								) : item.type === 'EmployeesMenu' ? (
									<EmployeeList />
								) : item.type === 'ServicesMenu' ? (
									<ServicesList />
								) : item.type === 'ProductsMenu' ? (
									<ProductsList />
								) : (
									''
								)}
							</TabPane>
						))
						: ''}
				</TabContent>
				<CommonModal isOpen={modalWidget} title="Добавить новый виджет" toggler={handleAddWidget} saveText="Добавить">
					<CardBody style={{ padding: '0 15px 10px' }}>
						<Label className="col-sm-9 col-form-label">Выберите виджет</Label>
						<Col sm="12">
							<Select
								type="tel"
								placeholder="Выберите..."
								value={elementType}
								options={[
									{ label: 'Выбор даты и времени', value: 'DateTimeMenu' },
									{ label: 'Список сотрудников', value: 'EmployeesMenu' },
									{ label: 'Список услуг', value: 'ServicesMenu' },
									{ label: 'Список товаров', value: 'ProductsMenu' },
								]}
								onChange={(option) => setElementType(option)}
							/>
						</Col>
					</CardBody>
				</CommonModal>
			</Col>
		</Row>
	);
};

export default LeftPanelMenu;
