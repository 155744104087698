import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Label, Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { Btn } from '../../../AbstractElements';
import TaskManagement from './FormBuilder/TaskManagement';
import axios from 'axios';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';

import { DemandsApi, IMapsApi } from '../../../api';
import { TaskApi } from '../../../api';
import { useKeycloak } from '@react-keycloak/web';
import { concatResults } from '../../../concatResults';
import { useCompanyContext } from "../../../_helper/Company/CompanyProvider";

import { useInteractivePlanMapsContext } from "../../../_helper/InteractivePlanMaps/InteractivePlanMapsProvider";
import { useTaskContext } from "../../../_helper/Task/TaskProvider";
import { useBitrixContext } from "../../../_helper/Bitrix/BitrixProvider";

const ClientForm = ({ isUnauthorized = false, onFormSubmit, demandObject, urlParams, formId, toggle = () => 0 }) => {
    const [demandForm, setDemandForm] = useState({});
    const [demand, setDemand] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [selectOptions, setSelectOptions] = useState([]);
    const [managementData, setManagementData] = useState([]);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isCreate, setCreateState] = useState(true);
    const taskManagementRef = useRef();
    const { keycloak } = useKeycloak();
    const { companyData } = useCompanyContext();
    const { createPlanMapObjectDemand } = useInteractivePlanMapsContext();
    const { createTaskDemand, createTaskDemandUnauthorized } = useTaskContext();
    const { createDealFromDemand } = useBitrixContext();

    const fetchData = async () => {
        try {
            var getDemandUrl = isUnauthorized ? `${DemandsApi}getDemandFormsUnauthorized` : `${DemandsApi}getDemandForms`;

            await axios.get(getDemandUrl, {
                params: { query: formId ? formId : 'b77e0ad8-9e26-4c45-bb96-f299a26263b6' },//'b77e0ad8-9e26-4c45-bb96-f299a26263b6' },
                headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
            }).then(async (response) => {
                const form = response.data.demandForms[0];
                setDemandForm(form);
                if ((typeof demandObject === 'object' && demandObject.hasOwnProperty('id'))) {
                    setCreateState(false);
                    const initialValues = {};
                    for (const parameter of demandObject.parametersDto) {
                        if (parameter.type === 'select') {
                            try {
                                initialValues[parameter.elementId] = JSON.parse(parameter.value);
                            }
                            catch (error) {
                                console.error(`Ошибка разбора JSON для параметра ${parameter.elementId}:`, error);
                            }
                        }
                        else if (parameter.type === "TaskManagement") {
                            try {
                                await axios.get(`${TaskApi}getTasksDemand`, {
                                    headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
                                    params: { IdDemand: demandObject.id }
                                }).then((responseTasks) => {
                                    setManagementData(concatResults(responseTasks).tasks)
                                });
                            }
                            catch (error) {
                                console.error(`Ошибка разбора JSON для параметра ${parameter.elementId}:`, error);
                            }
                        }
                        else {
                            const urlParam = urlParams && urlParams.find(param => param.name === parameter.name);

                            if (urlParam) {
                                initialValues[parameter.elementId] = urlParam.value;
                            } else {
                                initialValues[parameter.elementId] = parameter.value;
                            }
                        }
                    }

                    setFormValues(initialValues);
                }

            });
        } catch (error) {
            console.error('Ошибка получения данных:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const loadOptions = async () => {
            const selectOptionsPromises = [];

            for (const parameter of demandForm.parameters) {
                if (parameter.type === 'select' && parameter.options && typeof parameter.options === 'string') {
                    try {
                        const response = await axios.get(parameter.options);
                        const options = response.data.map((option) => ({
                            label: option.name,
                            value: option.id,
                        }));
                        selectOptionsPromises.push({ [parameter.elementId]: options });
                    } catch (error) {
                        console.error(`Ошибка загрузки опций для ${parameter.elementId}:`, error);
                        selectOptionsPromises.push({ [parameter.elementId]: [] });
                    }
                }
            }

            const loadedOptions = await Promise.all(selectOptionsPromises);
            const mergedOptions = Object.assign({}, ...loadedOptions);
            setSelectOptions((prevOptions) => ({ ...prevOptions, ...mergedOptions }));
        };

        if (demandForm && demandForm.parameters) {
            loadOptions();
        }
    }, [demandForm]);

    const handleChange = (elementId, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [elementId]: value,
        }));

        if (elementId === 'EmailID') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValid = emailRegex.test(value);
            setIsValidEmail(isValid);
        }
    };

    const renderFormParameters = () => {
        if (!demandForm || !demandForm.parameters) {
            return <div>Загрузка формы...</div>;
        }

        const sortedParameters = demandForm.parameters.sort((a, b) => a.order - b.order);

        return (
            <>
                {sortedParameters.map((parameter) => (
                    <Col md="12" className="mb-3" key={parameter.id}>
                        {parameter.elementId === "InteractivePlanMapObjectNumber" ? (
                            urlParams && urlParams.some(param => param.name === parameter.elementId) ? (
                                <>
                                    <Label className="col-sm-9 col-form-label" htmlFor={parameter.elementId}>
                                        {parameter.name}
                                    </Label>
                                    <Col sm="12">{renderParameterInput(parameter)}</Col>
                                </>
                            ) : null
                        ) : (
                            <>
                                <Label className="col-sm-9 col-form-label" htmlFor={parameter.elementId}>
                                    {parameter.name}
                                </Label>
                                <Col sm="12">{renderParameterInput(parameter)}</Col>
                            </>
                        )}
                    </Col>
                ))}
            </>
        );
    };

    const renderParameterInput = (parameter) => {
        if (!parameter || !parameter.elementId) {
            return null;
        }
        const urlParam = urlParams && urlParams.find(param => param.name === parameter.elementId);

        switch (parameter.type) {
            case 'tel':
                return (
                    <InputMask
                        className="form-control"
                        mask="+7 (999) 999-99-99"
                        maskChar="_"
                        placeholder="+7 (___) ___-__-__"
                        alwaysShowMask
                        id={parameter.elementId}
                        value={formValues[parameter.elementId] || ''}
                        onChange={(e) => handleChange(parameter.elementId, e.target.value)}
                    />
                );

            case 'select':
                return (
                    <Select
                        id={parameter.elementId}
                        type={parameter.type}
                        placeholder="Выберите..."
                        value={formValues[parameter.elementId]}
                        className="js-example-basic-single col-sm-12"
                        onChange={(option) => handleChange(parameter.elementId, option)}
                        options={(selectOptions && selectOptions[parameter.elementId]) || []}
                    />
                );
            case 'input':
                return (
                    <Input
                        className="form-control"
                        type={parameter.type}
                        id={parameter.elementId}
                        value={urlParam ? urlParam.value : (formValues[parameter.elementId] || '')}
                        onChange={(e) => handleChange(parameter.elementId, e.target.value)}
                    />
                );
            case 'TaskManagement':

                return (
                    <TaskManagement
                        data={managementData ? managementData : []}
                        setData={setManagementData ? setManagementData : []}
                        ref={taskManagementRef}
                    />

                );
            case 'label':
                var value = '';
                if (urlParam) {
                    value = urlParam.value;
                }

                return (
                    <Label style={{ fontWeight: "800" }} id={parameter.elementId}>
                        {value}
                    </Label>
                );
            case 'email':
                return (
                    <Input
                        className="form-control"
                        placeholder="Введите Email"
                        type={parameter.type}
                        value={formValues[parameter.elementId] || ''}
                        onChange={(e) => handleChange(parameter.elementId, e.target.value)}
                    />
                );
            case 'textarea':
                return (
                    <textarea
                        className="form-control"
                        type={parameter.type}
                        id={parameter.elementId}
                        rows={'3'}
                        value={formValues[parameter.elementId] || ''}
                        onChange={(e) => handleChange(parameter.elementId, e.target.value)}
                    />
                );
            default:
                return null;
        }
    };

    const performDemandOperation = async (e, operationType) => {
        e.preventDefault();

        const idDemand = isCreate ? uuid() : demandObject.id;
        const reqParameters = [];

        for (const item of demandForm.menuItems) {
            for (const parameter of item.parameters) {

                if (parameter.type === 'TaskManagement') {
                    reqParameters.push({
                        Value: JSON.stringify(taskManagementRef.current.getData()),
                        IdDemand: idDemand,
                        IdParameterHB: parameter.id,
                    });
                }
                else {
                    const urlParam = urlParams && urlParams.find(param => param.name === parameter.elementId);
                    var value = JSON.stringify(formValues[parameter.elementId]);
                    if (urlParam) {
                        value = urlParam.value;
                    }

                    reqParameters.push({
                        Value: value,
                        IdDemand: idDemand,
                        IdParameterHB: parameter.id,
                        Name: parameter.name
                    });
                }
            }
        }

        const demand = {
            id: idDemand,
            formId: formId,//formid: "b77e0ad8-9e26-4c45-bb96-f299a26263b6",
            parameters: reqParameters,
        };

        if (!isCreate) {
            const parametersMap = new Map(demandObject.parametersDto.map(parameter => [parameter.parameterHBId, parameter.id]));

            for (const parameter of demand.parameters) {
                const existingId = parametersMap.get(parameter.IdParameterHB);

                if (existingId !== undefined) {
                    parameter.id = existingId;
                }
            }
        }

        const apiEndpoint = operationType === 'update' ? 'updateDemand' : 'createDemand';
        const httpMethod = operationType === 'update' ? 'put' : 'post';

        axios({
            method: httpMethod,
            url: `${DemandsApi}${apiEndpoint}`,
            data: demand,
            headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) }
        })
            .then(async (response) => {

                toast.success("Заявка добавлена!", {
                    position: toast.POSITION.TOP_RIGHT,
                });

                if (taskManagementRef.current) {
                    const taskManagementData = taskManagementRef.current.getData();
                    //console.log('TaskManagement Data:', taskManagementData);

                    var tasksDemand = {
                        IdDemand: idDemand,
                        Tasks: taskManagementData
                    };

                    await axios.post(`${TaskApi}setTasksDemand`, tasksDemand, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } }).then(() => {
                        const successMessage = operationType === 'update' ? 'Заявка обновлена!' : 'Заявка добавлена!';
                        toast.success(successMessage, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
                }

                //const urlParam = urlParams && urlParams.find(param => param.name === "InteractivePlanMapObjectNumber");
                if (demandForm.id === "fc87e0e2-6a9d-6bca-7d4c-cc39ecca6fae") {

                    const planNumberParam = demandForm.parameters.find(obj => obj.elementId === "ObjectNumberId" || obj.name === "Номер участка")
                    await createPlanMapObjectDemand(/*formValues[planNumberParam.elementId]*/urlParams.find(x => x.name === "ObjectNumberId").value, idDemand);
                }

                if (apiEndpoint === 'createDemand') {
                    const createTask = isUnauthorized ? createTaskDemandUnauthorized : createTaskDemand;

                    if (demandForm.createLead === true && demandForm.leadProjectDefault) {
                        createTask(demand, "Lead", demandForm.leadProjectDefault);
                    }

                    if (demandForm.createDeal === true && demandForm.dealProjectDefault) {
                        createTask(demand, "Deal", demandForm.dealProjectDefault);
                    }

                    if (demandForm.createDealBitrix === true && demandForm.dealBitrixCategory) {
                        createDealFromDemand(demand, demandForm.name, demandForm.dealBitrixCategory, demandForm.dealBitrixCategoryStage);
                    }
                }

                onFormSubmit();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const createDemand = (e) => {
        performDemandOperation(e, 'create');
    };

    const updateDemand = (e) => {
        performDemandOperation(e, 'update');
    };


    return (
        <form onSubmit={isCreate ? createDemand : updateDemand}>
            <Row>{renderFormParameters()}</Row>
            <div className="modal-footer">
                <Btn attrBtn={{ color: 'primary' }}>Отправить</Btn>
            </div>
        </form>
    );
};
export default ClientForm;
