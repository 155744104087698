import React, { useEffect, useState } from "react";
import Context from "./index";
import axios, { all } from "axios";
import { toast } from 'react-toastify';
import { useKeycloak } from "@react-keycloak/web";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";

import { ChatApi, RealChatApi, TwinAuthApi } from "../../api";
import {useCompanyContext} from "../Company/CompanyProvider";
import { concatResults } from "../../concatResults";

const ChatProvider = (props) => {
	const [allMemberss, setAllMembers] = useState([]);
	const [allGroups, setAllGroups] = useState([]);
	const [allUserChats, setAllUserChats] = useState([]);
	const [chatMessages, setChatMessages] = useState([]);
	const [selectedChat, setSelectedChat] = useState();

	const [menuToggle, setMenuToggle] = useState(false);
	const [memberss, setMembers] = useState();
	const [groups, setGroups] = useState();
	const [chatss, setChats] = useState([]);
	const [currentUserr, setCurrentUser] = useState();
	const [selectedGroup, setSelectedGroup] = useState();
	const [selectedUserr, setSelectedUser] = useState();
	const [connection, setConnection] = useState(null);
	const [connectionOpen, setConnectionOpen] = useState();

	const [personalChatUsers, setPersonalChatUsers] = useState();

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		keycloak.token &&
		setConnection(new HubConnectionBuilder().withUrl(`${RealChatApi}hub?userId=${keycloak.tokenParsed.sub}`, {
			skipNegotiation: true,
			transport: HttpTransportType.WebSockets,
			accessTokenFactory: () => keycloak.token
		}).withAutomaticReconnect().build())
	}, [keycloak.token]);

	useEffect( () => {
		if (connection && connection.state !== "Connected") {
			connection.start().then(() => {
				connection.on("newMessage", data => {
					if (data.senderUserId !== keycloak.tokenParsed.sub) {
						toast.success("Получено новое сообщение", {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
				});

				setConnectionOpen(true);
				
				
			}).catch(() => {
				console.log('error');
			});
		}
	}, [connection])

	connection && connection.on("newMessage", data => {
		setChatMessages(chatMessages.concat(data));
	});


	const getChatMembersData = async (id) => {
		try {
			await axios.get(`${RealChatApi}get-chat-members?chatId=${id}`).then((resp) => {
				setAllMembers(resp.data.users);
			});
		} catch (error) {
			console.log("error", error);
		}
	};

	const getChatMessagesData = async (id) => {
		try {
			await axios.get(`${RealChatApi}get-chat-messages?chatId=${id}`).then((resp) => {
				setChatMessages(resp.data.messages);
			});
		} catch (error) {
			console.log("error", error);
		}
	}

	const getUserChats = async (id) => {
		try {
			await axios.get(`${RealChatApi}get-user-chats?userId=${id}`).then((resp) => {
				setAllUserChats(resp.data.chats);
			});
		} catch (error) {
			console.log("error", error);
		}
	}

	const getUserPersonalChatsUsers = async (id) => {
		try {
			await axios.get(`${RealChatApi}get-personal-chats-users?userId=${id}`).then(async (resp) => {
				let chatUsersId = concatResults(resp).users.map((item) => item.userId);
				await axios.post(`${TwinAuthApi}get-users-by-idArray`, chatUsersId, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setPersonalChatUsers(JSON.parse(response.data).map((item) => ({
						id: response.data.users.find((user) => user.userId === item.id).chatId,
						name: `${item.firstName} ${item.lastName}`,
						userId: item.id
					})
					));
				})
			});
		} catch (error) {
			console.log("error", error);
		}
	}

	const getChatGroupsData = async () => {
		try {
			await axios.get(`/api/chatGroups.json`).then((resp) => {
				setAllGroups(resp.data);
			});
		} catch (error) {
			console.log("error", error);
		}
	};

	const sendMessage = async (chatId, text) => {
		connection && connection.invoke("SendMessage", {
			chatId: chatId,
			senderUserId: keycloak.tokenParsed.sub,
			text: text,
			sendTime: new Date()
		})
	}

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location);
		if (urlParams.has('chat')) {
			//getChatMembersData();
			getChatGroupsData();
		}
	}, [setAllMembers, setMembers, setSelectedUser, setCurrentUser, setChats, setSelectedGroup, setAllGroups]);

	const getMembersSuccess = (chats) => {
		setCurrentUser(chats[0]);
		setMembers(chats);
	};

	const fetchChatMemberAsyn = () => {
		if (allMemberss.length > 0) getMembersSuccess(allMemberss);
	};

	const getGroupSuccess = (chats) => {
		setSelectedGroup(chats[0]);
		setGroups(chats);
	};

	const fetchChatGroupAsyn = () => {
		if (allGroups.length > 0) getGroupSuccess(allGroups);
	};

	/*useEffect(() => {
		const getChatData = async () => {
			try {
				await axios.get(ChatApi).then((resp) => {
					setChats(resp.data);
				});
			} catch (error) {
				console.log("error", error);
			}
		};
		getChatData();
	}, [setChats]);*/

	const getChatsSuccess = (chats, selectedUser) => {
		if (allMemberss.length > 0) {
			setChats(chats);
			setSelectedUser(allMemberss.find((x) => x.id === selectedUser));
		}
	};

	const updateSelectedUser = (selectedUser, online) => {
		if (allMemberss.length > 0) return allMemberss.find((x) => x.id === selectedUser);
	};

	const fetchChatAsyn = () => {
		if (chatss?.data?.length > 0) {
			const currentUserId = keycloak.tokenParsed.sub;
			const online = true;

			const chat = chatss.data.filter((x) => x.users.includes(currentUserId));
			const selectedUser = chatss.data[0].users.find((x) => x !== currentUserId);

			getChatsSuccess(chat, selectedUser, online);
			updateSelectedUser(selectedUser, online);
		}
	};

	const sendMessageToChat = async (chatID, chats) => {
		try {
			await axios.put(
				`${ChatApi}/${chats.data[chatID].id}`, // ???????
				chats.data[chatID]
			);
		} catch (error) {
			console.log("error", error);
		}
	};

	const sendMessageAsyn = (currentUserId, selectedUserId, messageInput, chats, online) => {
		let chat = chats.find((x) => x.users.includes(currentUserId) && x.users.includes(selectedUserId));
		const now = new Date();
		if (chat) {
			chat.messages.push({
				sender: currentUserId,
				time: now,
				text: messageInput,
			});
			chat.lastMessageTime = now;

			let chats_data = chats.filter((x) => x.id !== chat.id);
			chats_data.splice(0, 0, chat);
			const chatID = chats.indexOf(chat);
			getChatsSuccess(chats, selectedUserId, online);
			sendMessageToChat(chatID, chats);
		}
	};

	const replyByUserAsyn = (currentUserId, selectedUserId, replyMessage, chats, online) => {
		let chat = chats.find((x) => x.users.includes(currentUserId) && x.users.includes(selectedUserId));
		const now = new Date();
		if (chat) {
			chat.messages.push({
				sender: selectedUserId,
				time: now,
				text: replyMessage,
				status: true,
			});
			chat.lastMessageTime = now;
			let chats_data = chats.filter((x) => x.id !== chat.id);
			chats_data.splice(0, 0, chat);

			getChatsSuccess(chats_data, selectedUserId, online);
		}

		sendMessageToChat(currentUserId, chats);
	};

	const createNewChatAsyn = (currentUserId, selectedUserId, chats) => {
		let conversation = {
			id: chats.length + 1,
			users: [currentUserId, selectedUserId],
			lastMessageTime: "-",
			messages: [],
		};
		chats.splice(0, 0, conversation);
		getChatsSuccess(chats, selectedUserId);
	};

	const changeChat = (id) => {
		setSelectedChat(allUserChats.find((x) => x.id === id));
		setChatMessages(null);
		getChatMessagesData(id);
	};
	const changeGroupChat = (groupID) => {
		setSelectedGroup(allGroups.find((x) => x.id === groupID));
	};

	const searchMember = (keywords) => {
		if (keywords === "") {
			setMembers(allMemberss);
		} else {
			const keyword = keywords.toLowerCase();
			const searchedMembers = allMemberss.filter((member) => member.name.toLowerCase().indexOf(keyword) > -1);
			setMembers(searchedMembers);
		}
	};

	return (
		<Context.Provider
			value={{
				...props,
				allMemberss,
				chatss,
				selectedUserr,
				currentUserr,
				selectedGroup,
				memberss,
				menuToggle,
				allGroups,
				allUserChats,
				chatMessages,
				connectionOpen,
				selectedChat,
				personalChatUsers,
				getUserChats,
				getChatMessagesData,
				getUserPersonalChatsUsers,
				sendMessage,
				setMenuToggle,
				getChatsSuccess: getChatsSuccess,
				updateSelectedUserr: updateSelectedUser,
				fetchChatAsyn: fetchChatAsyn,
				fetchChatMemberAsyn: fetchChatMemberAsyn,
				fetchChatGroupAsyn: fetchChatGroupAsyn,
				sendMessageAsyn: sendMessageAsyn,
				replyByUserAsyn: replyByUserAsyn,
				createNewChatAsyn: createNewChatAsyn,
				changeChat: changeChat,
				changeGroupChat: changeGroupChat,
				searchMember: searchMember,
			}}
		>
			{props.children}
		</Context.Provider>
	);
};

export default ChatProvider;
