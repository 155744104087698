import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { H5 } from "../../../../AbstractElements";
import TableDomenEditor from "./TableDomainEditor";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const TemplateEditor = ({ siteId }) => {

	return (
		<>
			<H5 attrH5={{ className: "mb-4" }}>Шаблон сайта</H5>
			<TableDomenEditor siteId={siteId} />
		</>
	);
};

export default TableDomenEditor;
