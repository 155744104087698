import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import { BeatLoader } from 'react-spinners';
import { Container, Input, Row, Label, CardFooter } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import { CardBody, Card, Col } from 'reactstrap';

import { TestingApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';

const ViewTesting = ({ idTestParam }) => {
	const [name, setName] = useState('');
	const [TestElements, setTestElements] = useState([]);
	const [userAnswers, setUserAnswers] = useState({});
	const [testId, setTestId] = useState('');
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const id = idTestParam || queryParams.get('testId');
		setTestId(id);
		fetchData(id);
	}, []);

	const fetchData = async (id) => {
		try {
			const response = await axios.get(`${TestingApi}getTestForm`, {
				params: {
					query: id,
				}, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
			});

			setName(concatResults(response).title);

			const updatedTestElements = concatResults(response).questions.map((question) => {
				const updatedAnswerOptions = question.answerOptions.map((option) => ({
					...option,
					isCorrect: false,
				}));

				return {
					id: question.id,
					type: mapQuestionTypeToElementType(question.answerType.typeName, question.answerType.id),
					question: question.text,
					order: question.order,
					required: question.isRequired,
					max_points: Number(question.points),
					content: updatedAnswerOptions.map((option) => ({
						id: option.id,
						label: option.text,
					})),
				};
			});

			setTestElements(updatedTestElements);
		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	const handleInputChange = (questionId, value) => {
		const formattedValue = value instanceof Date ? value.toISOString() : value;
		setUserAnswers((prevState) => ({
			...prevState,
			[questionId]: formattedValue,
		}));
	};

	const handleCheckboxChange = (questionId, optionId) => {
		setUserAnswers((prevState) => {
			const selectedOptions = prevState[questionId] || [];
			const updatedOptions = selectedOptions.includes(optionId) ? selectedOptions.filter((id) => id !== optionId) : [...selectedOptions, optionId];

			return {
				...prevState,
				[questionId]: updatedOptions,
			};
		});
	};

	const mapQuestionTypeToElementType = (questionType, id) => {
		switch (questionType) {
			case 'list':
				return { label: 'Список', value: 'list', id: id };
			case 'radio':
				return { label: 'Радио', value: 'radio', id: id };
			case 'date':
				return { label: 'Дата', value: 'date', id: id };
			case 'input':
				return { label: 'Строка', value: 'input', id: id };
			default:
				return { label: 'Неизвестный тип', value: 'unknown' };
		}
	};

	const [loading, setLoading] = useState(false);

	const sendResult = () => {
		setLoading(true);
		const userAnswersReq = TestElements.map((element) => {
			let answerText = '';
			let selectedAnswerOptions = [];

			if (element.type.value === 'input' || element.type.value === 'date') {
				answerText = userAnswers[element.id] || '';
			} else if (element.type.value === 'list' || element.type.value === 'radio') {
				selectedAnswerOptions = element.content.filter((item) => userAnswers[element.id] && userAnswers[element.id].includes(item.id)).map((item) => item.id);
			}

			return {
				id: uuid(),
				questionId: element.id,
				answerText: answerText,
				selectedAnswerOptions: selectedAnswerOptions,
			};
		});

		const createTestResultDto = {
			id: uuid(),
			testFormId: testId,
			userId: uuid(),
			submissionDate: new Date().toISOString(),
			userAnswers: userAnswersReq,
		};

		axios({
			method: 'post',
			url: `${TestingApi}createTest`,
			data: createTestResultDto, 
			headers: { 
				Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])),
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				setLoading(false);
				toast.success('Результаты отправлены!', {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.log(concatResults(response));
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
			});
	};

	return (
		<Fragment>
			<Breadcrumbs mainTitle={name} parent="Тестирование" title={name} />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardBody>
							{TestElements.map((element) => (
								<Row style={{ alignItems: 'center' }} className="mb-3 p-10" key={element.id}>
									<Col sm="12">
										<Label className="col-sm-9 col-form-label">
											{element.question} {element.required ? <span className="font-danger f-16">*</span> : ''}
										</Label>
										{element.type.value === 'input' && (
											<div style={{ display: 'flex', alignItems: 'flex-start' }}>
												<Col>
													<Input type="text" className="form-control" required={element.required} onChange={(e) => handleInputChange(element.id, e.target.value)} />
												</Col>
											</div>
										)}
										{element.type.value === 'list' &&
											element.content.map((item) => (
												<div className="checkbox checkbox-primary ms-2" key={item.id}>
													<Input id={`checkbox-${item.id}`} type="checkbox" onChange={() => handleCheckboxChange(element.id, item.id)} />
													<Label for={`checkbox-${item.id}`}>{item.label}</Label>
												</div>
											))}
										{element.type.value === 'radio' &&
											element.content.map((item) => (
												<div className="radio radio-primary mt-3 ms-3" key={item.id}>
													<Input id={`radio-${item.id}`} type="radio" value={item.label} name={`radio-${element.id}`} onChange={() => handleInputChange(element.id, item.id)} />
													<Label for={`radio-${item.id}`}>{item.label}</Label>
												</div>
											))}
										{element.type.value === 'date' && <DatePicker dateFormat="dd.MM.yyyy" locale={ru} className="form-control digits" selected={userAnswers[element.id] ? new Date(userAnswers[element.id]) : null} onChange={(date) => handleInputChange(element.id, date)} />}
									</Col>
								</Row>
							))}
						</CardBody>
						<CardFooter>
							<div className="modal-footer">
								<Btn attrBtn={{ color: 'primary', onClick: () => sendResult(), disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : 'Отправить результаты'}</Btn>
							</div>
						</CardFooter>
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};

export default ViewTesting;
