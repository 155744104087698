import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import ClientCard from './ClientCard';
import { Card, CardBody } from 'reactstrap';

const VideoChatContain = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Карточка клиента" subParent="Клиентская база" parent="Приложения" title="Карточка клиента" />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardBody className="chat-body">
							<ClientCard />
						</CardBody>
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};
export default VideoChatContain;
