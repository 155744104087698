import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import axios from 'axios';
import { useLocation, useParams, Link } from 'react-router-dom';
import { Maximize2 } from 'react-feather';
import { Breadcrumbs, Btn } from '../../../../../AbstractElements';
import ViewPage from './ViewPage';
import { CmsApi } from '../../../../../api';
import { concatResults } from "../../../../../concatResults";
import { useKeycloak } from "@react-keycloak/web";
import { useCompanyContext } from "../../../../../_helper/Company/CompanyProvider";

const IndexViewPage = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const alias = queryParams.get('alias');
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const { siteId } = useParams();

	const [item, setItem] = useState('');
	const [containsModal, setContainsModal] = useState(false);
	const [containsFormApp, setContainsFormApp] = useState(false);
	const [size, setSize] = useState('');
	const [content, setContent] = useState('');
	const [data, setData] = useState('');

	const [formParams, setFormParams] = useState({
		plotNumber: '',
		phoneNumber: '',
		name: '',
		comment: '',
		isAgree: ''
	});

	useEffect(() => {
		axios
			.get(`${CmsApi}getSites`, {
				headers: {
					Authorization: `Bearer ${keycloak.token}`,
					CompanyId: companyData.map((item) => item.attributes.companyId[0])
				}
			})
			.then((response) => {
				const foundItem = response.data.sites.find((item) => item.id === siteId);
				let foundObject = {};
				if (alias) {
					foundObject = foundItem.pages.find((page) => page.alias === alias);
				}
				const letcontainsModal = foundObject.content.includes('<StandardPricingcard');
				setContainsModal(letcontainsModal);
				const outputString = foundObject.content.replace(/<p>\/\*Ваше пакетное предложение по (.*?)\*\/<\/p><figure class='table'><table><tbody><tr><td><i>Здесь будет отображен контент пакетного предложения<\/i><\/td><\/tr><\/tbody><\/table><\/figure>/g, '');
				setItem(foundObject);
				console.log(foundItem);

				const savedFormContent = JSON.parse(foundObject.content);

				const { html, css, js } = savedFormContent;

				const content = `
					${css}
					<body>
						${html}
					</body>
					${js}
				`;

				setContent(content);

				const parser = new DOMParser();
				const doc = parser.parseFromString(html, 'text/html');
				const form = doc.querySelector('form');
				if (form) {
					const plotNumber = form.querySelector('input[name="plotNumber"]')?.value || '';
					const phoneNumber = form.querySelector('input[name="phone"]')?.value || '';
					const name = form.querySelector('input[name="name"]')?.value || '';
					const comment = form.querySelector('textarea[name="comment"]')?.value || '';
					const isAgree = form.querySelector('input[name="consent"]')?.checked || false;

					setFormParams({
						plotNumber,
						phoneNumber,
						name,
						comment,
						isAgree
					});

					setContainsFormApp(true);
				}

				var match = outputString.match(/<StandardPricingcard data=(\d+) size=(\w+)/);
				if (match) {
					console.log('Значение data:', match[1]);
					setSize(match[2]);
					axios
						.get('/api/packageOffersList.json')
						.then((response) => {
							const foundItem = response.data.find((item) => item.id === match[1]);
							console.log(foundItem.packages);
							setData(foundItem.packages);
						})
						.catch((error) => {
							console.error('Ошибка получения данных:', error);
						});
				}
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	const { plotNumber, phoneNumber, name, comment, isAgree } = formParams;
	const additionalParams = `plotNumber=${plotNumber}&phoneNumber=${phoneNumber}&name=${name}&comment=${comment}&isAgree=${isAgree}`;


	return (
		<Fragment>
			<Breadcrumbs mainTitle={`${item.name}`} parent="Просмотр страницы" title={`${item.name}`} />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardHeader>
							<Link to={`/app/site-builder/${siteId}`} style={{ fontSize: '20px' }}>
								<i className="fa fa-angle-left" style={{ fontSize: '26px', marginRight: '5px' }}></i>Вернуться к списку
							</Link>
							<div className="f-right">
								<Link to={`${process.env.PUBLIC_URL}/app/pages/${siteId}?page=${alias}${containsFormApp ? `&${additionalParams}` : ''}`}>
									<Btn attrBtn={{ color: 'info', className: ' mb-4' }}>
										<Maximize2 size={'18px'} style={{ margin: '0px 4px -4px 0px' }} /> Просмотр
									</Btn>
								</Link>
							</div>
						</CardHeader>
						<CardBody>
							<ViewPage content={content} data={data} size={size} containsModal={containsModal} />
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default IndexViewPage;
