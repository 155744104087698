import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import axios from 'axios';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableCalculationCriteria from './TableCalculationCriteria';

const CalculationCriteria = () => {
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		axios
			.get('/api/calculationCriteria.json')
			.then((response) => {
				setFilteredProducts(response.data);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Критерии расчета" parent="Расчёт зарплат" title="Критерии расчета" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<TableCalculationCriteria filteredProducts={filteredProducts} />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};
export default CalculationCriteria;
