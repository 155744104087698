import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Edit, ExternalLink } from 'react-feather';
import { Card, CardBody, Container, CardHeader, Col, Input, Label, Row, Media } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import ru from 'date-fns/locale/ru';
import Select from 'react-select';
import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Breadcrumbs, Btn } from '../../../../../AbstractElements';
import BuildCourseTab from './BuildCourseTab';
import { EducationApi } from '../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../concatResults";

const BuildCourse = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const courseId = queryParams.get('courseId');

	const [pageTitle, setPageTitle] = useState('');
	const [editingTitle, setEditingTitle] = useState(false);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	let currentUserId = `${keycloak.tokenParsed.sub}`;

	const handleClickTitle = () => {
		setEditingTitle(true);
	};

	const handleInputChangeTitle = (event) => {
		setPageTitle(event.target.value);
	};

	const handleInputBlurTitle = () => {
		setEditingTitle(false);
	};

	const [pageDes, setPageDes] = useState('');
	const [editingDes, setEditingDes] = useState(false);

	const handleClickDes = () => {
		setEditingDes(true);
	};

	const handleInputChangeDes = (event) => {
		setPageDes(event.target.value);
	};

	const handleInputBlurDes = () => {
		setEditingDes(false);
	};

	const [deadlineType, setDeadlineType] = useState({ value: 'noDeadline', label: 'Без срока' });

	const handleSelectChange = (selectedOption) => {
		setDeadlineType(selectedOption);
		//console.log(selectedOption);
	};

	const [periodStart, setPeriodStart] = useState(new Date());
	const [periodEnd, setPeriodEnd] = useState(new Date());
	const [data, setData] = useState([]);
	const [link, setLink] = useState([]);

	const [lessons, setLessons] = useState();
	const [teachers, setTeachers] = useState();
	const [students, setStudents] = useState();
	const [issuedDiplomas, setIssuedDiplomas] = useState();

	useEffect(() => {
		axios
			.get(`${EducationApi}get-course/${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				const foundItem = concatResults(response);
				setData(foundItem);
				setPageTitle(foundItem.name);
				setPageDes(foundItem.description);
				setLink(foundItem.helloLink);
				//setDeadlineType(foundItem.deadlineType)
				setPeriodStart(new Date(foundItem.dateStart));
				setPeriodEnd(new Date(foundItem.dateEnd));
				setShowModules(foundItem.haveModules);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});

		axios
			.get(`${EducationApi}get-course-lessons?courseId=${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setLessons(concatResults(response).lessons);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});

		axios
			.get(`${EducationApi}get-course-teachers?courseId=${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setTeachers(concatResults(response).teachers);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});

		axios
			.get(`${EducationApi}get-course-students?courseId=${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setStudents(concatResults(response).students);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});

		axios
			.get(`${EducationApi}get-course-diplomas?id=${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
			.then((response) => {
				setIssuedDiplomas(concatResults(response).diplomas);
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});


	}, []);

	const [loading, setLoading] = useState(false);

	const sendEditRequest = async () => {
		setLoading(true);
		const data = {
			Id: `${courseId}`,
			Name: pageTitle,
			Description: pageDes,
			HelloLink: link,
			PeriodStart: periodStart,
			PeriodEnd: periodEnd,
			HaveModules: showModules
		};
		try {
			const response = await axios.put(`${EducationApi}update-course`, data);
			setLoading(false);
			toast.success('Изменения сохранены!', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	const [showModules, setShowModules] = useState(false);

	return (
		<Fragment>
			<Breadcrumbs mainTitle="Конструктор курса" subParent="Список курсов" parent="Обучение" title="Конструктор курса" />
			<Container fluid={true}>
				<div className="email-wrap bookmark-wrap">
					<Card>
						<CardHeader>
							<Link to={`${process.env.PUBLIC_URL}/app/course-list`} className="f-18">
								<i className="fa fa-angle-left f-20 me-2"></i>Вернуться к списку
							</Link>
						</CardHeader>
						<CardBody>
							<Col className="mb-3 m-l-10">
								{editingTitle ? (
									<input style={{ width: '100%', height: '34px', fontSize: '28px', padding: '0', margin: '0' }} type="text" value={pageTitle} onChange={handleInputChangeTitle} onBlur={handleInputBlurTitle} autoFocus />
								) : (
									<h3 onClick={handleClickTitle}>
										{pageTitle}
										<Tooltip title="Для изменения названия, кликните на текст" arrow placement="top">
											<Edit style={{ margin: '0 0 -2px 10px', color: 'var(--theme-default)', cursor: 'pointer' }} />
										</Tooltip>
									</h3>
								)}
							</Col>
							<Col className="mb-4 m-l-10 mt-4">
								{editingDes ? (
									<input style={{ width: '100%', height: '24px', fontSize: '22px', color: '#898989', padding: '0', margin: '0' }} type="text" value={pageDes} onChange={handleInputChangeDes} onBlur={handleInputBlurDes} autoFocus />
								) : (
									<p className="f-22" onClick={handleClickDes}>
										{pageDes}
										<Tooltip title="Для изменения описания, кликните на текст" arrow placement="top">
											<Edit style={{ margin: '0 0 -2px 10px', color: 'var(--theme-default)', cursor: 'pointer' }} size={'20px'} />
										</Tooltip>
									</p>
								)}
							</Col>
							<Col sm="4" md="6" className="m-l-10 mb-4">
								<div className="mb-4">
									<Select
										placeholder="Выберите..."
										options={[
											{ value: 'noDeadline', label: 'Без срока' },
											{ value: 'withDeadline', label: 'Задать срок' },
										]}
										className="js-example-basic-single col-sm-12"
										value={deadlineType}
										onChange={handleSelectChange}
									/>
								</div>
								{deadlineType.value === 'withDeadline' && (
									<Row className="m-l-5  mt-4">
										<Col xl="6" sm="9">
											<div style={{ display: 'flex', alignItems: 'flex-end' }}>
												<Label className="m-r-10">С</Label>
												<div className="input-group">
													<DatePicker dateFormat="dd.MM.yyyy" locale={ru} className="form-control digits" selected={periodStart} onChange={(selectedOption) => setPeriodStart(selectedOption)} />
												</div>
											</div>
										</Col>
										<Col xl="6" sm="9">
											<div style={{ display: 'flex', alignItems: 'flex-end' }}>
												<Label className="m-r-10">По</Label>
												<div className="input-group">
													<DatePicker dateFormat="dd.MM.yyyy" locale={ru} className="form-control digits" selected={periodEnd} onChange={(selectedOption) => setPeriodEnd(selectedOption)} />
												</div>
											</div>
										</Col>
									</Row>
								)}
							</Col>
							<Col className="mt-3">
								<Media style={{ alignItems: 'center' }} className="ms-2 mb-3">
									<Media body className={'text-start icon-state '} style={{ flexGrow: '0' }}>
										<Label className="switch">
											<Input type="checkbox" checked={showModules} onChange={(e) => setShowModules(e.target.checked)} />
											<span className="switch-state"></span>
										</Label>
									</Media>
									<Label className="col-form-label ms-2 ">Объединять занятия в модули</Label>
								</Media>
							</Col>
							<Col className="mb-2 m-l-10">
								<Link to={`${process.env.PUBLIC_URL}${link}`}>
									Перейти на страницу курса
									<ExternalLink size={'18px'} style={{ marginLeft: '7px', cursor: 'pointer' }} />
								</Link>
							</Col>
							<Col style={{ height: '60px' }}>
								<div className="f-right">
									<Btn attrBtn={{ color: 'primary', onClick: sendEditRequest, className: 'mt-4', disabled: loading }}>{loading ? <BeatLoader size={8} color="#ffffff" /> : 'Сохранить изменения'}</Btn>
								</div>
							</Col>
							{data && <BuildCourseTab data={data} lessons={lessons} teachers={teachers} students={students} issuedDiplomas={issuedDiplomas} showModules={showModules} />}
						</CardBody>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default BuildCourse;
