import React, { useState, useEffect } from "react";
import uuid from "react-uuid";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";

import { Btn, H5 } from "../../../../AbstractElements";
import { useParams } from "react-router-dom";
import { CodeExecutor } from "./CodeEditor/CodeExecutor";
import { CodeEditor } from "./CodeEditor/CodeEditor";
import { Tabs } from "./CodeEditor/Tabs";
import { CmsApi } from "../../../../api";
import TableTemplateEdit from "./TableTemplateEdit";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";

const TemplateEditor = () => {
	const { siteId } = useParams();
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const [data, setData] = useState([]);
	const fetchData = async () => {
		try {
			await axios
				.get(`/api/templateEdit.json`, {
					headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) },
				})
				.then((response) => {
					setData(response.data.templates);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});
		} catch (error) {
			console.error("Ошибка при выполнении запроса:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<H5 attrH5={{ className: "mb-4" }}>Шаблон сайта</H5>
			<TableTemplateEdit products={data} fetchData={fetchData} />
		</>
	);
};

export default TemplateEditor;
