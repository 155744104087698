import React, { useMemo, useState, useEffect } from 'react';
import { Trash2 } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import DataTable from 'react-data-table-component';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Select from 'react-select';
import SweetAlert from 'sweetalert2';
import { CardBody, Col, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import FilterComponent from '../FilterComponent';
import CommonModal from '../../../../UiKits/Modals/common/modal';
import { Btn } from '../../../../../AbstractElements';
import { Plus } from 'react-feather';
import { TwinAuthApi } from '../../../../../api';
import { useLocation } from 'react-router';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../_helper/Company/CompanyProvider";

const CompanyRoles = ({ roles, companyId, groupData }) => {
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const [modal, setModal] = useState(false);
	const [users, setUsers] = useState([]);
	const [username, setUsername] = useState(null);
    const [roleArray, setRoleArray] = useState([]);
	const [roleValue, setRoleValue] = useState();

    useEffect(() => {
        setRoleArray(roles && roles.map((role) => ({name: role})))
    }, [roles])

	const toggle = () => {
		setModal(!modal);
	};

	const columns = [
		{
			name: 'Название',
			sortable: true,
            selector: (row) => `${row.name}`,
			hide: 'sm',
			width: '50%',
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<div style={{ display: 'flex' }}>
					<Tooltip title="Удалить" arrow placement="top">
						<div className="font-primary" style={{ marginLeft: '7px', cursor: 'pointer' }} onClick={() => deleteRow(`${row.name}`)}>
							<Trash2 size={'18px'} />
						</div>
					</Tooltip>
				</div>
			),
			width: '5%',
		},
	];

	const deleteRow = (roleName) => {
        console.log(groupData);
		SweetAlert.fire({
			title: 'Вы уверены?',
			text: 'После удаления вы не сможете восстановить этот объект!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'ОК',
			cancelButtonText: 'Отмена',
			reverseButtons: true,
		}).then(function (isConfirm) {
			if (isConfirm) {
				axios
                    .delete(`${TwinAuthApi}remove-group-from-role?roleName=${roleName}&groupName=${groupData.name}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
                    .then((response) => {
                    })
                    .catch((error) => {
                        console.error('Ошибка получения данных:', error);
                    });
			}
		});
	};

	function getUser(id) {
		if (users === undefined || users.length === 0) {
			tryUpdateUsers();
		}
		let user = users.find((x) => x.id === id);
		if (user !== undefined) {
			return user;
		}
		return {
			firstName: '',
			lastName: '',
			username: '',
		};
	}

	function tryUpdateUsers() {
		let usersIds = [];

		if (roles !== undefined) {
			
		}
	}

	const promiseOptions = (inputValue, callback) => {
		
	};

	function isNullOrWhitespace( input ) {
		return !input || !input.trim();
	  }

	function RoleForm() {
		return (
			<Col className="row col-sm-12 mb-3">
				<Label className="col-form-label">Роль</Label>
				<Col sm="11">
					<Input className="form-control" value={roleValue} type="tel" placeholder="Название роли в keycloak" onChange={(e) => setRoleValue(e.target.value)} />
				</Col>
			</Col>
		);
	}

	const addCompanyToRole = () => {
		axios
            .post(`${TwinAuthApi}add-group-to-role?groupName=${groupData.name}&role=${roleValue}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
            .then((response) => {
            })
            .catch((error) => {
                console.error('Ошибка получения данных:', error);
            });
		
			setModal(!modal);
	};

	let numberedData = roleArray;

	if (numberedData !== undefined) {
		numberedData = roleArray.map((row, index) => {
			return {
				...row,
				rowNumber: index + 1,
			};
		});
	}

	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	let filteredItems = numberedData;
	if (numberedData !== undefined) {
		filteredItems = numberedData.filter((item) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
	}

	const subHeaderComponent = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return <FilterComponent toggle={toggle} onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
	}, [filterText, resetPaginationToggle]);
	return (
		<>
			<DataTable columns={columns} data={filteredItems} noDataComponent={'В данной таблице ещё нет записей.'} defaultSortField="name" striped pagination subHeader subHeaderComponent={subHeaderComponent} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
			<CommonModal isOpen={modal} title={'Добавить роль'} toggler={addCompanyToRole} togglerClose={toggle} saveText="Готово" size="xl">
				<CardBody style={{ padding: '10px', paddingLeft: '40px', paddingTop: '0' }}>
					<RoleForm />
				</CardBody>
			</CommonModal>
		</>
	);
};

export default CompanyRoles;
