import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DateFilter = ({ filterValue, onFilterChange, name }) => {
    const handleDateChange = (date) => {
        
        onFilterChange(name,date);
    };

    const customStyles = {
        zIndex: 1000
    };

    return (
        <div style={customStyles}>
            <DatePicker
                placeholderText="Выберите дату и время"
                selected={filterValue ? new Date(filterValue) : null}
                onChange={handleDateChange}
                isClearable
                locale="ru"
                className="form-control digits"
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeSelect
                timeFormat="HH:mm"
                timeCaption="Время"
            />
        </div>
    );
};

export default DateFilter;
