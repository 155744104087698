import React, { Fragment } from 'react';
import { Container, Row, CardBody, Col, Card } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import DataTableEditMenu from './DataTableEditMenu';

const EditMenuEnter = () => {
	return (
		<Fragment>
			<Breadcrumbs mainTitle="Редактор меню мастера последовательного вывода страниц" parent="Админ" title="Редактор меню мастера последовательного вывода страниц" />
			<Container fluid className="user-card">
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<DataTableEditMenu />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};
export default EditMenuEnter;
