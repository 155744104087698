import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import axios from 'axios';
import { Breadcrumbs } from '../../../../AbstractElements';
import TableSpecificationPositions from './TableSpecificationPositions';
import { useLocation } from 'react-router-dom';
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { SpecificationsApi, TwinAuthApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';

const SpecificationDetail = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id'); 
    const [specification, setSpecification] = useState(null);
    const { companyData } = useCompanyContext();
    const { keycloak } = useKeycloak();

    useEffect(() => {
        fetchSpecification();
    }, [id]);

    const fetchSpecification = async () => {
        try {
            const response = await axios.get(`${SpecificationsApi}getSpecification/${id}`, {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                    CompanyId: companyData.map((item) => item.attributes.companyId[0])
                }
            });
            setSpecification(response.data);
        } catch (error) {
            console.error('Error fetching specification:', error);
        }
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle={`Список позиций спецификации: ${specification?.title || ''}`} parent="ERP" title="Список позиций спецификации" />
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Card>
                        <CardBody>
                            {specification && <TableSpecificationPositions specification={specification} />}
                        </CardBody>
                    </Card>
                </div>
            </Container>
        </Fragment>
    );
};

export default SpecificationDetail;
