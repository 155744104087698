import React, { Fragment, useState, useEffect, useContext } from "react";
import { Card, CardBody, Container, Col, Label, Input, Row, CardHeader, CardFooter } from "reactstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { Tabs } from "../../Sites/SiteBuilder/CodeEditor/Tabs";
import { CodeExecutor } from "../../Sites/SiteBuilder/CodeEditor/CodeExecutor";
import { CodeEditor } from "../../Sites/SiteBuilder/CodeEditor/CodeEditor";
import CMSTemplatesContext from "../../../../_helper/CMSTemplates";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";
import { ContentBlock } from "draft-js";
import { toast } from "react-toastify";
import { CmsApi, TwinAuthApi } from "../../../../api";
import { useKeycloak } from "@react-keycloak/web";

const CreateForm = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const formId = queryParams.get("formId");
	const { keycloak } = useKeycloak();
	const { allForms, getAllAppForms } = useContext(CMSTemplatesContext);


	const [id, setId] = useState("");
	const [item, setItem] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const { companyData } = useCompanyContext();

	const [mode, setMode] = useState("html");
	const [html, setHtml] = useState("");
	const [css, setCss] = useState("");
	const [js, setJs] = useState("");
	const [srcDoc, setSrcDoc] = useState("");

	const propsByMode = {
		html: {
			mode: "xml",
			value: html,
			setValue: setHtml,
		},
		css: {
			mode: "css",
			value: css,
			setValue: setCss,
		},
		js: {
			mode: "javascript",
			value: js,
			setValue: setJs,
		},
	};

	const fetchDate = async () => {
		try {
			!allForms && getAllAppForms();

			const foundItem = allForms.find((item) => item.id === formId);
			setItem(foundItem);
			if (foundItem && Object.keys(foundItem).length > 0 && foundItem.content) {
				setId(foundItem.id);
				setName(foundItem.name);
				setDescription(foundItem.description);
				handleSet(foundItem);
			}
			else {
				handleReturn();
			}
		}
		catch (error) {
			console.log("Error: " + error)
		}
	}

	useEffect(() => {
		fetchDate();
	}, [allForms]);



	const runCode = () => {

		try {
			const content = `
			${css}
			<body>
				${html}
			</body>
			${js}
		`;

			setSrcDoc(content);
		}
		catch (error) {

		}
	};



	const handleSet = (item) => {

		try {
			const savedFormContent = JSON.parse(item.content);

			const { html, css, js } = savedFormContent;

			setHtml(html);
			setCss(css);
			setJs(js);

			const content = `
			${css}
			<body>
				${html}
			</body>
			${js}
		`;

			setSrcDoc(content);
		}
		catch (error) {

		}
	};

	const handleReturn = async () => {
		try {
			const response = await axios.get('/api/initial_form_data.json');
			const savedFormContentJson = response.data;

			const savedFormContent = {
				html: savedFormContentJson.content.match(/<body[^>]*>([\s\S]*?)<\/body>/i)[1],
				css: savedFormContentJson.content.match(/<style[^>]*>([\s\S]*?)<\/style>/i)[0],
				js: savedFormContentJson.content.match(/<script[^>]*>([\s\S]*?)<\/script>/i)[0],
			};

			setHtml(savedFormContent.html);
			setCss(savedFormContent.css);
			setJs(savedFormContent.js);

			const content = `
				${savedFormContent.css}
			<body>
				${savedFormContent.html}
			</body>
				${savedFormContent.js}
		`;

			setSrcDoc(content);
		} catch (error) {
			console.error("Ошибка при загрузке данных: ", error);
		}
	};

	const updateForm = async () => {

		if (name === '' || description === '') {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			return;
		}

		const formContent = {
			html: html,
			css: css,
			js: js
		};

		const formContentString = JSON.stringify(formContent);

		const form = {
			id: id,
			name: name,
			description: description,
			isActive: false,
			content: formContentString,
			htmlMarkup: JSON.stringify(html),
			cssStyles: JSON.stringify(css),
			jsScript: JSON.stringify(js)
		};
		await axios
			.put(`${CmsApi}updateFormApp`, form, {
				headers: {
					Authorization: `Bearer ${keycloak.token}`,
					CompanyId: companyData.map((item) => item.attributes.companyId[0])
				}
			})
			.then((response) => {
				console.log(response.data);
				toast.success("Форма обновлена!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				window.location.replace('/app/request/templates-application-form');
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});

	};

	const createForm = async () => {
		if (name === '' || description === '') {
			toast.error("Заполните все поля!", {
				position: toast.POSITION.TOP_RIGHT,
			});
			return;
		}

		const formContent = {
			html: html,
			css: css,
			js: js
		};

		const formContentString = JSON.stringify(formContent);

		const form = {
			name: name,
			description: description,
			isActive: false,
			content: formContentString,
			htmlMarkup: JSON.stringify(html),
			cssStyles: JSON.stringify(css),
			jsScript: JSON.stringify(js)
		};

		await axios
			.post(`${CmsApi}createFormApp`, form, {
				headers: {
					Authorization: `Bearer ${keycloak.token}`,
					CompanyId: companyData.map((item) => item.attributes.companyId[0])
				}
			})
			.then((response) => {
				console.log(response.data);
				toast.success("Форма добавлена!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				window.location.replace('/app/request/templates-application-form');
			})
			.catch((error) => {
				console.error("Ошибка получения данных:", error);
			});
	};

	return (
		<Fragment>
			<Breadcrumbs
				mainTitle={item ? `Редактировать шаблон формы заявки` : `Добавить шаблон формы заявки`}
				parent="Заявки"
				title={item ? `Редактировать шаблон формы заявки` : `Добавить шаблон формы заявки`}
			/>
			<Container fluid={true}>
				<div className="email-wrap">
					<Card>
						<CardHeader>
							<button onClick={handleReturn} style={{ background: "none", border: "none", color: "#7366ff", fontSize: "16px", marginTop: "5px", float: "right" }}>
								<i className="fa fa-undo" style={{ marginRight: "5px" }}></i>Вернуться к стандартному шаблону
							</button>
						</CardHeader>

						<CardBody>
							<Col className="row col-sm-12 mb-3">
								<Label className="col-sm-9 col-form-label">Название</Label>
								<Col sm="12">
									{item ? (
										<Input disabled className="form-control" type="text" value={item.name} />
									) : (
										<Input className="form-control" type="text" value={name} onChange={(e) => setName(e.target.value)} />
									)}
								</Col>
							</Col>
							<Col className="row col-sm-12 mb-4">
								<Label className="col-sm-9 col-form-label">Описание</Label>
								<Col sm="12">
									{item ? (
										<Input disabled className="form-control" type="text" value={item.description} />
									) : (
										<Input className="form-control" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
									)}
								</Col>
							</Col>
							<Row>
								<Col md="8">
									<Tabs mode={mode} setMode={setMode} runCode={runCode} />
									<CodeEditor {...propsByMode[mode]} />
								</Col>
								<Col md="4">
									<CodeExecutor srcDoc={srcDoc} height="60vh" />
								</Col>
							</Row>
						</CardBody>

						<CardFooter>
							<Btn attrBtn={{ color: "primary", className: "f-right", onClick: formId === null ? createForm : updateForm }}>Сохранить изменения</Btn>
						</CardFooter>
					</Card>
				</div>
			</Container>
		</Fragment>
	);
};
export default CreateForm;
