import React from "react";
import {Col, Input, Label} from "reactstrap";
import Select from "react-select";

export const InputSelect = {
    label: "Выпадающий список",
    fields: {
        children: {
            label: "Текст",
            type: "text",
        },
        options: {
            label: "Варианты выбора",
            type: "array",
            arrayFields: {
                label: {
                    label: "Текст",
                    type: "text",
                },
                value: {
                    label: "Значение",
                    type: "text",
                },
            },
            getItemSummary: (_, id) => `Вариант ${id + 1}`,
        },
        dataSource: {
            label: "Источник данных",
            type: "text"
        },
        useToken: {
            label: "С использованием токена доступа",
            type: "custom",
            render: ({ name, onChange, value }) => {
                return <div>
                    <Input id="checkbox-primary-1" checked={value ?? false} className={"checkbox_animated"} type="checkbox"
                           onChange={(e) => onChange(e.target.checked)}/>
                    <Label for="checkbox-primary-1">Использовать токен доступа</Label>
                </div>
            },
        },
        valueMask: {
            label: "Маска для значений",
            type: "text"
        },
        defaultProps: {
            children: "Выпадающий список",
            useToken: false,
        },
    },
    render: ({ children, options }) => {
        return <div>
            <Label className="col-sm-9 col-form-label">{children}</Label>
            <Col sm="12">
                <Select
                    type="tel"
                    placeholder="Выберите..."
                    options={options ? options.map((item) => ({label: item.label, value: item.value})) :[
                        { label: '1 вариант', value: '1 вариант' },
                        { label: '2 вариант', value: '2 вариант' },
                    ]}
                />
            </Col>
        </div>;
    },
};