import React, { useContext, useState } from "react";
import { Col, Input, InputGroup, Row } from "reactstrap";
import EmojiPicker from "emoji-picker-react";
import { useKeycloak } from "@react-keycloak/web";

import { Btn } from "../../../../AbstractElements";
import ChatAppContext from "../../../../_helper/Chat";
import { useCompanyContext } from "../../../../_helper/Company/CompanyProvider";

const SendChat = ({ isConnectionOpen }) => {
	const [messageInput, setMessageInput] = useState("");
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);

	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();

	const toggleEmojiPicker = () => {
		setShowEmojiPicker(!showEmojiPicker);
	};
	const { selectedUserr, sendMessage, selectedChat, sendMessageAsyn } = useContext(ChatAppContext);

	const addEmoji = (emoji) => {
		const text = `${messageInput}${emoji.emoji}`;
		setShowEmojiPicker(false);
		setMessageInput(text);
	};

	const handleMessageChange = (message) => {
		setMessageInput(message);
	};

	const handleMessagePress = (e) => {
		if (e.key === "Enter" || e === "send") {
			var container = document.querySelector(".chat-history");
			setTimeout(function () {
				container.scrollBy({ top: 200, behavior: "smooth" });
			}, 310);

			if (messageInput.length > 0) {
				console.log(selectedChat);
				sendMessage(selectedChat.id, messageInput);
				setMessageInput("");
			}
		}
	};

	return (
		<div className="chat-message clearfix">
			<div style={{ width: "350px" }}>{showEmojiPicker ? <EmojiPicker emojiStyle="native" width={350} onEmojiClick={addEmoji} /> : null}</div>
			<Row>
				<Col xl="12" className="d-flex">
					<div className="smiley-box bg-primary d-flex" style={{ alignItems: "center", cursor: "pointer" }} onClick={toggleEmojiPicker}>
						<i className="fa fa-smile-o f-24"></i>
					</div>
					<InputGroup className="text-box">
						<Input
							type="text"
							className="form-control input-txt-bx"
							placeholder="Напишите сообщение"
							value={messageInput}
							onKeyPress={(e) => handleMessagePress(e)}
							onChange={(e) => handleMessageChange(e.target.value)}
						/>
						<Btn
							attrBtn={{
								color: "primary",
								onClick: () => handleMessagePress("send"),
								disabled: !isConnectionOpen,
							}}
						>
							Отправить
						</Btn>
					</InputGroup>
				</Col>
			</Row>
		</div>
	);
};

export default SendChat;
