import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import Scrollbars from 'react-custom-scrollbars-2';
import { Check, X } from 'react-feather';
import { Container, Input, Row, Label, TabContent, TabPane } from 'reactstrap';
import { Breadcrumbs, P, H6 } from '../../../../AbstractElements';
import { CardBody, Card, Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import { TestingApi } from '../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import { concatResults } from '../../../../concatResults';


const TestResults = () => {
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();
	const [name, setName] = useState('');
	const [MaxPoints, setMaxPoints] = useState('');
	const [answers, setAnswers] = useState([]);
	const [TestElements, setTestElements] = useState([]);
	const [testId, setTestId] = useState('');

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const hours = dateObject.getUTCHours();
		const minutes = dateObject.getUTCMinutes();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedHours = hours < 10 ? `0${hours}` : hours;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year} в ${formattedHours}:${formattedMinutes}`;

		return formattedDate;
	}

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const id = queryParams.get('testId');
		setTestId(id);
		fetchData(id);
	}, []);

	const [formattedAnswers, setFormattedAnswers] = useState([]);


	const fetchData = async (id) => {
		try {
			const response = await axios.get(`${TestingApi}getTestResults`, {
				params: {
					query: id,
				}, headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}
			}).then((response) => {


				const newFormattedAnswers = concatResults(response).sessions.map((session) => {
					const questionAnswers = session.params.map((param) => {
						const question = concatResults(response).testElements.find((element) => element.id === param.questionId);

						const answer = param.selectedAnswerOptions.length > 0
							? param.selectedAnswerOptions.map((optionId) => {
								const option = question.content.find((contentItem) => contentItem.id === optionId);
								return option.text;
							})
							: [param.answerText];

						return {
							questionId: param.questionId,
							answer: answer.join(', '),
						};
					});

					return {
						sessionId: session.id,
						recordDate: session.recordDate,
						userId: session.userId,
						testFormId: session.testFormId,
						totalCountMarkResult: session.totalCountMarkResult,
						questionAnswers,
					};
				});
				setFormattedAnswers(newFormattedAnswers);
				setName(concatResults(response).name);
				setAnswers(concatResults(response).sessions);
				setMaxPoints(concatResults(response).maxCountMarkResult);
				setTestElements(concatResults(response).testElements);
			});

		} catch (error) {
			console.error('Ошибка при выполнении запроса:', error);
		}
	};

	const getScoreColor = (obtainedScore) => {
		const percentage = (obtainedScore / MaxPoints) * 100;

		if (percentage < 40) {
			return 'danger';
		} else if (percentage >= 40 && percentage <= 60) {
			return 'warning';
		} else {
			return 'success';
		}
	};

	const [primarycolorTab, setprimarycolorTab] = useState('1');

	function formatDate(inputDate) {
		const dateObject = new Date(inputDate);

		const day = dateObject.getUTCDate();
		const month = dateObject.getUTCMonth() + 1;
		const year = dateObject.getUTCFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		const formattedDate = `${formattedDay}.${formattedMonth}.${year}`;

		return formattedDate;
	}

	return (
		<Fragment>
			<Breadcrumbs mainTitle={`${name} - Результаты теста`} parent="Тестирование" title="Результаты теста" />
			<Container fluid={true}>
				<Row>
					<Card>
						<CardBody>
							<Row>
								<Col xxl="4" sm="12 box-col-5" className="b-r-light">
									<H6 attrH6={{ className: 'mb-4 font-primary' }}>Загружено результатов: {answers.length}</H6>
									<div className="scroll-bar-wrap">
										<Scrollbars className="click-drag-handler p-0" style={{ width: '100%', height: '70vh' }}>
											{answers.map((item) => (
												<Card key={item.id} style={{ cursor: 'pointer' }} className={` border-2 ${primarycolorTab === item.id ? 'b-primary' : 'b-light'}`} onClick={() => setprimarycolorTab(item.id)}>
													<CardBody>
														<div className="mb-1">Пользователь: {item.userId} </div>
														<div className="mb-1">Дата: {formatDate(item.recordDate)} </div>
														<div className="mb-1">
															Баллов получено <span className={`font-${getScoreColor(item.totalCountMarkResult)}`}>{item.totalCountMarkResult}</span> / {MaxPoints}
														</div>
													</CardBody>
												</Card>
											))}
										</Scrollbars>
									</div>
								</Col>
								<Col xxl="8" sm="12 box-col-7">
									<TabContent activeTab={primarycolorTab}>
										{answers.map((answer) => (
											<TabPane tabId={answer.id} key={answer.id}>
												<H6>Ответ № {answer.id}</H6>
												<div className="scroll-bar-wrap">
													<Scrollbars className="click-drag-handler p-0" style={{ width: '100%', height: '70vh' }}>
														{TestElements.map((el) => (
															<Row style={{ alignItems: 'center' }} className="mb-3 p-10 col-md-11">
																<Col sm="12">
																	<Label className="col-sm-9 col-form-label">
																		{el.question} {el.required ? <span className="font-danger f-16">*</span> : ''}
																	</Label>

																	{el.type.typeName === 'input' && (
																		<>
																			{formattedAnswers.map((formattedAnswer) => {
																				if (formattedAnswer.sessionId === answer.id) {
																					const userAnswer = formattedAnswer.questionAnswers.find(
																						(qa) => qa.questionId === el.id
																					);
																					const correctAnswer = el.correctAnswer.join(', ');
																					const isCorrect =
																						userAnswer &&
																						correctAnswer === userAnswer.answer;

																					return (
																						<div
																							key={formattedAnswer.sessionId}
																							style={{ display: 'flex', alignItems: 'flex-start' }}
																						>
																							{el.correctAnswer && (
																								isCorrect ? (
																									<Check className="font-success me-2 mt-2" />
																								) : (
																									<X className="font-danger me-2 mt-2" />
																								)
																							)}
																							<Col>
																								<Input
																									type="text"
																									value={userAnswer ? userAnswer.answer : ''}
																									readOnly
																									className="form-control"
																								/>
																								{el.correctAnswer && (
																									<Label>
																										Правильный ответ: {correctAnswer}
																									</Label>
																								)}
																							</Col>
																						</div>
																					);
																				}
																			})}
																		</>
																	)}

																	{el.type.typeName === 'date' && (
																		<>
																			{formattedAnswers.map((formattedAnswer) => {
																				if (formattedAnswer.sessionId === answer.id) {
																					const userAnswer = formattedAnswer.questionAnswers.find(
																						(qa) => qa.questionId === el.id
																					);
																					const correctAnswer = el.correctAnswer.length > 0 ? el.correctAnswer[0] : null;
																					const userAnswerDate = userAnswer ? new Date(userAnswer.answer) : null;
																					const formattedCorrectAnswer = correctAnswer
																						? formatDate(new Date(correctAnswer))
																						: null;
																					const isCorrect =
																						userAnswerDate && formattedCorrectAnswer === formatDate(userAnswerDate);
																					return (
																						<div
																							key={formattedAnswer.sessionId}
																							style={{ display: 'flex', alignItems: 'flex-start' }}
																						>
																							{el.correctAnswer && (
																								isCorrect ? (
																									<Check className="font-success me-2 mt-2" />
																								) : (
																									<X className="font-danger me-2 mt-2" />
																								)
																							)}
																							<Col>
																								<DatePicker
																									dateFormat="dd.MM.yyyy"
																									locale={ru}
																									className="form-control digits"
																									selected={userAnswerDate}
																									readOnly
																								/>
																								{el.correctAnswer && (
																									<Label>
																										Правильный ответ: {formattedCorrectAnswer}
																									</Label>
																								)}
																							</Col>
																						</div>
																					);
																				}
																			})}
																		</>
																	)}

																	{el.type.typeName === 'radio' && (
																		<>
																			{formattedAnswers.map((formattedAnswer) => {
																				if (formattedAnswer.sessionId === answer.id) {
																					const userAnswer = formattedAnswer.questionAnswers.find(
																						(qa) => qa.questionId === el.id
																					);
																					const correctAnswer = el.correctAnswer.length > 0 ? el.correctAnswer[0] : null;

																					const isCorrect =
																						userAnswer &&
																						correctAnswer &&
																						userAnswer.answer === correctAnswer;

																					return (
																						<div
																							key={formattedAnswer.sessionId}
																							style={{ display: 'flex', alignItems: 'flex-start' }}
																						>
																							{el.correctAnswer && (
																								isCorrect ? (
																									<Check className="font-success me-2 mt-2" />
																								) : (
																									<X className="font-danger me-2 mt-2" />
																								)
																							)}
																							<Col>
																								{el.content.map((item) => (
																									<div className="radio radio-primary mt-3 ms-2" key={item.id}>
																										<Input
																											id={`radio-${item.id}`}
																											type="radio"
																											value={item.text}
																											checked={userAnswer ? userAnswer.answer === item.text : false}
																											readOnly
																										/>
																										<Label for={`radio-${item.id}`}>{item.text}</Label>
																									</div>
																								))}
																								{el.correctAnswer && (
																									<Label className="mt-2">
																										Правильный ответ: {correctAnswer}
																									</Label>
																								)}
																							</Col>
																						</div>
																					);
																				}
																			})}
																		</>
																	)}

																	{el.type.typeName === 'list' && (
																		<>
																			{formattedAnswers.map((formattedAnswer) => {
																				if (formattedAnswer.sessionId === answer.id) {
																					const userAnswer = formattedAnswer.questionAnswers.find((qa) => qa.questionId === el.id);
																					const correctAnswer = el.correctAnswer;

																					const isCorrect =
																						userAnswer &&
																						correctAnswer &&
																						JSON.stringify(userAnswer.answer.split(',').sort()) === JSON.stringify(correctAnswer.sort());

																					return (
																						<div
																							key={formattedAnswer.sessionId}
																							style={{ display: 'flex', alignItems: 'flex-start' }}
																						>
																							{el.correctAnswer && (
																								isCorrect ? (
																									<Check className="font-success me-2 mt-2" />
																								) : (
																									<X className="font-danger me-2 mt-2" />
																								)
																							)}
																							<Col>
																								{el.content.map((item) => (
																									<div className="checkbox checkbox-primary ms-2" key={item.id}>
																										<Input
																											id={`checkbox-${item.id}`}
																											type="checkbox"
																											checked={userAnswer.answer.split(',').map(item => item.trim()).includes(item.text)}
																											readOnly
																										/>
																										<Label for={`checkbox-${item.id}`}>{item.text}</Label>
																									</div>
																								))}
																								{el.correctAnswer && (
																									<Label className="mt-2">
																										Правильный ответ: {correctAnswer.join(', ')}
																									</Label>
																								)}
																							</Col>
																						</div>
																					);
																				}
																			})}
																		</>
																	)}

																</Col>
															</Row>
														))}
													</Scrollbars>
												</div>
											</TabPane>
										))}
										<TabPane tabId="1">
											<P attrPara={{ className: 'mt-4 ms-3 ' }}>Выберите элемент для просмотра результатов</P>
										</TabPane>
									</TabContent>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Row>
			</Container>
		</Fragment>
	);
};
export default TestResults;
