import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import AddButton from './AddButton';
import { EducationApi } from '../../../../../../api';
import { TwinAuthApi } from '../../../../../../api';
import { useKeycloak } from '@react-keycloak/web';
import {useCompanyContext} from "../../../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../../../concatResults";

const TeachersTableModal = ({ teachers }) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const courseId = queryParams.get('courseId');

	const [data, setData] = useState([]);
	const [users, setUsers] = useState([]);
	const {keycloak} = useKeycloak();
	const { companyData } = useCompanyContext();

	useEffect(() => {
		axios
			.get(`${EducationApi}get-course-teachers?courseId=${courseId}`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))}})
			.then((response) => {
				const foundItem = concatResults(response).teachers;
				setData(foundItem);
			})
			.catch((error) => {
				console.error('Ошибка получения данных:', error);
			});
	}, []);

	function tryUpdateUsers() {
		let usersIds = [];

		if (data !== undefined) {
			data.forEach((element) => usersIds.push(element.userId));

			axios
				.post(`${TwinAuthApi}get-users-by-idArray`, usersIds, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0]))} })
				.then((response) => {
					const courseUsers = JSON.parse(response.data);
					setUsers(courseUsers);
				})
				.catch((error) => {
					console.error('Ошибка получения данных:', error);
				});
		}
	}

	function getUser(id) {
		if (users === undefined || users.length === 0) {
			tryUpdateUsers();
		}
		let user = users.find((x) => x.id === id);
		if (user !== undefined) {
			return user;
		}
		return {
			firstName: '',
			lastName: '',
			username: '',
		};
	}

	const columns = [
		{
			name: 'Имя',
			selector: (row) => `${getUser(row.userId).firstName} ${getUser(row.userId).lastName}`,
			sortable: true,
			hide: 'sm',
		},
		{
			name: 'Логин',
			selector: (row) => `${getUser(row.userId).username}`,
			sortable: true,
		},
		{
			name: '',
			button: true,
			selector: (row) => (
				<>
					<AddButton teacher={row} teachers={teachers} />
				</>
			),
			width: '15%',
		},
	];

	return (
		<>
			<DataTable columns={columns} data={data} defaultSortField="name" striped pagination noDataComponent={'В данной таблице ещё нет записей.'} paginationComponentOptions={{ rowsPerPageText: 'Строк на странице:', rangeSeparatorText: 'из' }} />
		</>
	);
};

export default TeachersTableModal;
