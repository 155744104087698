import React, {Fragment, useState, useEffect, useReducer} from "react";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Target, PlusCircle } from "react-feather";
import { Link } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";

import { Breadcrumbs } from "../../../../AbstractElements";
import CardList from "./CardList";
import { AllProjectApi } from "../../../../api";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import {useCompanyContext} from "../../../../_helper/Company/CompanyProvider";
import {concatResults} from "../../../../concatResults";
import {FormatListBulleted, ListAlt, SpaceDashboard, Undo} from "@mui/icons-material";
import ListContext from "@mui/material/List/ListContext";
import Tooltip from "@mui/material/Tooltip";

const Project = () => {
	const [activeTab, setActiveTab] = useState(0);
	const [allData, setAllData] = useState([]);
	const { keycloak } = useKeycloak();
	const { companyData } = useCompanyContext();
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const [states, setStates] = useState([]);
	const [types, setTypes] = useState([]);
	const [data, setData] = useState(allData);
	let currentUserId = `${keycloak.tokenParsed.sub}`;
	const [displayAsCards, setDisplayAsCards] = useState(true);

	const handleChangeTab = (statusId) => {
		setActiveTab(statusId);
		if (statusId === 0) {
			setData(allData);
		} else {
			setData(allData.filter((item) => item.state.id === statusId));
		}
	};

	const [loading, setLoading] = useState(true);

	const fetchData = async () => {
		try {
			setLoading(true);
			const type = queryParams.get("type");
			var request = `${AllProjectApi}get?type=${type}&userId=${currentUserId}&companyId=${companyData.map((item) => (item.attributes.companyId[0]))}`;
			if (type === null || type === "allProjects") {
				request = `${AllProjectApi}get?userId=${currentUserId}&companyId=${companyData.map((item) => (item.attributes.companyId[0]))}`;
			}
			await axios
				.get(request, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					//console.log(concatResults(response));
					setAllData(concatResults(response).projects);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});

			await axios
				.get(`${AllProjectApi}getTypes`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					//console.log(concatResults(response));
					//concatResults(response).map((item) => ({}))

					const selectOptions = concatResults(response).types.map((option) => ({
						label: option.text,
						value: option.value,
					}));
					selectOptions.unshift({ label: "Все проекты", value: "allProjects" });
					/*selectOptions.push({
						label: "Согласование документов",
						value: "agreement",
						key: "234",
					}); //Добавить новый тип в сервис*/
					if (type) {
						const foundObject = selectOptions.find((item) => item.value === type);
						setSelectType({ label: foundObject.label, value: foundObject.value });
					}
					setTypes(selectOptions);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});

			await axios
				.get(`${AllProjectApi}getStates`, { headers: { Authorization: `Bearer ${keycloak.token}`, CompanyId: companyData.map((item) => (item.attributes.companyId[0])) } })
				.then((response) => {
					setStates(concatResults(response).states);
				})
				.catch((error) => {
					console.error("Ошибка получения данных:", error);
				});

			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error("Ошибка получения данных:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, [companyData]);

	const [selectType, setSelectType] = useState({ label: "Все проекты", value: "allProjects" });

	const handleTypeChange = (selectedOption) => {
		const type = selectedOption.value;
		setSelectType(selectedOption);
		queryParams.delete("type");
		queryParams.append(`type`, type);
		navigate(`?${queryParams.toString()}`);
	};

	return (
		<Fragment>
			<Breadcrumbs parent="Проекты" title="Список проектов" mainTitle="Список проектов" />
			<Container fluid={true}>
				<Row className="project-card">
					<Col md="12" className="project-list">
						<Card>
							<Row>
								<Col md="9">
									<Nav tabs className="border-tab">
										<NavItem>
											<NavLink className={activeTab === 0 ? "active" : ""} onClick={() => handleChangeTab(0)} style={{ cursor: "pointer" }}>
												<Target />
												Все
											</NavLink>
										</NavItem>
										{states && states.map((status) => (
											<NavItem key={status.id}>
												<NavLink className={activeTab === status.id ? "active" : ""} onClick={() => handleChangeTab(status.id)} style={{ cursor: "pointer" }}>
													{status.text}
												</NavLink>
											</NavItem>
										))}
										<Select
											placeholder="Начните вводить..."
											noOptionsMessage={() => "Нет результатов"}
											options={types}
											value={selectType}
											theme={(theme) => ({
												...theme,
												colors: {
													...theme.colors,
													primary25: "#7366ff80",
													primary: "#7366ff",
												},
											})}
											className="js-example-basic-single col-md-3"
											onChange={handleTypeChange}
										/>
									</Nav>
								</Col>
								<Col md="3">
									<div className="text-end">
										<Link className="btn btn-primary" style={{ color: "white" }} to={`${process.env.PUBLIC_URL}/app/project/edit-project`}>
											<PlusCircle style={{ marginBottom: "3px" }} />
											Создать проект
										</Link>
									</div>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col sm="12">
						<Card>
							<CardBody>
								<TabContent activeTab={activeTab}>
									{loading ? (
										<PuffLoader size={80} color="#7366ff" style={{ margin: "20px auto", display: "inherit", position: "relative", width: "80px", height: "80px" }} />
									) : allData && allData.length > 0 ? (
										<>
											<div className={"d-flex"}>
												<Tooltip title="Вывод карточками" arrow placement="top">
													<button color="primary"
															className={`p-2 m-1 btn ${displayAsCards === true ? "btn-primary" : "btn-outline-primary"}`}
															onClick={() => setDisplayAsCards(true)}>
														<SpaceDashboard style={{display: "block", width: 24, height: 24}}>
														</SpaceDashboard>
													</button>
												</Tooltip>
												<Tooltip title="Вывод списком" arrow placement="top">
													<button color="primary"
															className={`p-2 m-1 btn ${displayAsCards === false ? "btn-primary" : "btn-outline-primary"}`}
															onClick={() => setDisplayAsCards(false)}>
														<FormatListBulleted style={{display: "block", width: 24, height: 24}}>
														</FormatListBulleted>
													</button>
												</Tooltip>
											</div>
											<TabPane tabId={0}>
												<Row>{allData &&
													<CardList statuses={states} cards={allData} setData={setAllData}
															  setLoading={setLoading}
															  displayAsCards={displayAsCards}/>}</Row>
											</TabPane>
											{states && states.map((status) => (
												<TabPane tabId={status.id}>
													<Row>{data.length ? <CardList statuses={states} cards={data} setData={setAllData} setLoading={setLoading} displayAsCards={displayAsCards} /> : <p>Результаты не найдены</p>}</Row>
												</TabPane>
											))}
										</>
									) : (
										<p>Результаты не найдены</p>
									)}
								</TabContent>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Project;
